
import { updatePassword } from "aws-amplify/auth";
import { defineComponent } from "vue";
import {
  defaultPasswordRequirements,
  PasswordRequirements,
} from "../../../common/src/models/Organization";
import { User } from "../../../common/src/models/User";
import {
  passwordValidation,
  UserValidationResult,
} from "../../../common/src/models/User.validation";
import router from "../../src/router";

export default defineComponent({
  data() {
    const isSubmitted: boolean = false;
    const newPassword1: string = "";
    const newPassword2: string = "";
    const currentPassword: string = "";

    return {
      currentPassword,
      newPassword1,
      newPassword2,
      isSubmitted,
    };
  },
  computed: {
    user(): User {
      return this.$store.getters["profile/profile"];
    },
    passwordRequirements(): PasswordRequirements {
      let passwordRequirements = defaultPasswordRequirements;
      if (this.user.organization) {
        passwordRequirements = this.user.organization.passwordRequirements;
      }
      return passwordRequirements;
    },
    newPasswordValidationResult(): UserValidationResult {
      return passwordValidation(this.newPassword1, this.passwordRequirements);
    },
    newPasswordSameAsCurrent() {
      return this.currentPassword === this.newPassword1;
    },
    confirmPasswordSameAsNewPassword() {
      return this.newPassword1 === this.newPassword2;
    },
  },
  methods: {
    async confirm() {
      this.isSubmitted = true;
      if (
        this.newPasswordValidationResult.success &&
        !this.newPasswordSameAsCurrent &&
        this.confirmPasswordSameAsNewPassword
      ) {
        try {
          await updatePassword({
            oldPassword: this.currentPassword,
            newPassword: this.newPassword1,
          });

          if (this.$route.query.next) {
            router.push(this.$route.query.next as string).then(() =>
              (this as any).$bvToast.toast("Password change succeeded", {
                variant: "success",
              }),
            );
          } else {
            router.push({ name: "home" }).then(() =>
              (this as any).$bvToast.toast("Password change succeeded", {
                variant: "success",
              }),
            );
          }
        } catch (error: any) {
          console.error("error changing password", error.message);
          (this as any).$bvToast.toast(error.message, {
            title: "Password Change Error",
            variant: "danger",
            solid: true,
          });
        }
      }
    },
  },
});
