import { CoreContext } from "../../../common/src/api/calculations/types";
import DrawableStore from "../htmlcanvas/lib/drawableStore";
import store from "./store";

export const globalStore = new DrawableStore();

// sigh. This is what it comes down to after all huh
export function getGlobalContext(): CoreContext {
  return {
    globalStore: globalStore,
    catalog: store.getters["catalog/default"],
    drawing: store.getters["document/document"].drawing,
    locale: store.getters["document/document"].locale,
    nodes: store.getters["customEntity/nodes"],
    priceTable: store.getters["document/priceTable"],
    featureAccess: store.getters["profile/featureAccess"],
    featureFlags: store.getters["featureFlags/all"],
  };
}
