import {
  isHeating,
  StandardFlowSystemUids,
} from "../../../../../common/src/api/config";
import { Workflows } from "../../../../../common/src/api/document/drawing";
import {
  ExtendedFlowSystemType,
  FlowSystem,
} from "../../../../../common/src/api/document/flow-systems";
import {
  assertUnreachable,
  assertUnreachableAggressive,
} from "../../../../../common/src/lib/utils";
import { inclusiveWorkflowsCurried } from "../../../lib/workflows-utils";

// this file is very jank, half-ported from legacy code.
// effectively just determines which flow systems are applicable for the user's current workflows.

export const getExtendedFlowSystemType = (
  fs: FlowSystem,
): ExtendedFlowSystemType => {
  switch (fs.type) {
    case "mechanical":
      if (isHeating(fs)) {
        return "heating";
      } else {
        return "cooling";
      }
    case "pressure":
    case "sewer":
    case "fire":
    case "gas":
    case "stormwater":
    case "ventilation":
    case "underfloor":
      return fs.type;
  }
  assertUnreachable(fs);
};

export const getSystemByType = (
  flowSystems: FlowSystem[],
): { [key in ExtendedFlowSystemType]: FlowSystem[] } => {
  const sbt = flowSystems.reduce((acc: any, fs: FlowSystem) => {
    const extendedType = getExtendedFlowSystemType(fs);
    if (!acc[extendedType]) {
      acc[extendedType] = [];
    }
    acc[extendedType].push(fs);
    return acc;
  }, {});

  // srt sbt keys based on how long their flow system array is
  Object.keys(sbt).forEach((key) => {
    const value = sbt[key];
    delete sbt[key];
    sbt[key] = value;
  });

  return sbt;
};

export const filterSystemTypeByWorkflows = (
  workflows: Workflows,
  systemType: ExtendedFlowSystemType,
) => {
  const inclusiveWorkflows = inclusiveWorkflowsCurried(workflows);
  switch (systemType) {
    case "pressure":
      return inclusiveWorkflows("plumbing_water");
    case "sewer":
      return inclusiveWorkflows("plumbing_wastewater");
    case "ventilation":
      return inclusiveWorkflows("mech_ventilation");
    case "fire":
      return inclusiveWorkflows(
        "fire_hydrants",
        "fire_sprinklers",
        "fire_hosereels",
      );
    case "cooling":
      return inclusiveWorkflows("mech_chilled");
    case "gas":
      return inclusiveWorkflows("plumbing_gas");
    case "stormwater":
      return inclusiveWorkflows("plumbing_stormwater");
    case "heating":
      return inclusiveWorkflows("mech_heating");
    case "underfloor":
      return inclusiveWorkflows("mech_underfloor_heating");
    default:
      return assertUnreachableAggressive(systemType);
  }
};

export const filterSystemByWorkflows = (
  workflows: Workflows,
  system: FlowSystem,
) => {
  const inclusiveWorkflows = inclusiveWorkflowsCurried(workflows);
  switch (system.name) {
    case "Fire Hydrant":
      return inclusiveWorkflows("fire_hydrants");
    case "Fire Sprinkler":
      return inclusiveWorkflows("fire_sprinklers");
    case "Fire Hose Reel":
      return inclusiveWorkflows("fire_hosereels");
    default:
      return true;
  }
};

export const filterFlowSystemsByWorkflows = (
  workflows: Workflows,
  flowSystems: FlowSystem[],
  flowSystemUidsInOrder: Array<StandardFlowSystemUids | string>,
) => {
  const raw = getSystemByType(flowSystems);
  const entries = Object.entries(raw);
  const filteredGroups = entries.filter(([key, _]) =>
    filterSystemTypeByWorkflows(workflows, key as ExtendedFlowSystemType),
  ) as [ExtendedFlowSystemType, FlowSystem[]][];
  const filteredValues: [ExtendedFlowSystemType, FlowSystem[]][] =
    filteredGroups.map(([flowSystemType, flowSystems]) => {
      const filteredSystems = flowSystems.filter((fs) =>
        filterSystemByWorkflows(workflows, fs),
      );
      return [flowSystemType, filteredSystems];
    }) as [ExtendedFlowSystemType, FlowSystem[]][];

  // tl;dr this orders the groups based on flowSystemUidsInOrder.
  const order: Map<ExtendedFlowSystemType, number> = new Map(
    filteredValues.map(([k, fs]) => [
      k,
      getFlowSystemIdx(fs, flowSystemUidsInOrder),
    ]),
  );

  return filteredGroups.sort((a, b) => {
    if (order.has(a[0]) && order.has(b[0])) {
      return order.get(a[0])! - order.get(b[0])!;
    }

    if (order.has(a[0])) {
      return 1;
    }

    return -1;
  });
};

function getFlowSystemIdx(
  fs: FlowSystem[],
  flowSystemUidsInOrder: Array<StandardFlowSystemUids | string>,
): number {
  const numbers = fs
    .map((f) => flowSystemUidsInOrder.indexOf(f.uid))
    .filter((x) => x !== -1);

  // None of the flow systems are in the array
  if (numbers.length === 0) {
    return -1;
  }
  return Math.min(...numbers);
}
