import CoreBaseBackedObject from "../../../../../common/src/api/coreObjects/lib/coreBaseBackedObject";
import { DrawableEntityConcrete } from "../../../../../common/src/api/document/entities/concrete-entity";
import { LinkPosition } from "../../../../../common/src/api/document/entities/vertices/vertex-types";
import { Coord } from "../../../../../common/src/lib/coord";
import { coordsWithinRadius } from "../../../../../common/src/lib/mathUtils/mathutils";
import { MouseMoveResult } from "../../types";
import CanvasContext from "../canvas-context";
import { DrawingArgs } from "../drawable-object";
import { DrawingContext } from "../types";
import { IDrawableObject } from "./core2drawable";

export function AttachableObject<
  T extends abstract new (
    ...args: any[]
  ) => CoreBaseBackedObject<DrawableEntityConcrete> & IDrawableObject,
>(constructor: T) {
  abstract class Attachable extends constructor {
    attachActive = false;
    lastMouseCoord: Coord | null = null;
    attachmentOffset = 40;

    abstract getAttachCoords(): [Coord, Coord, Coord, Coord];

    onMouseMove(event: MouseEvent, context: CanvasContext): MouseMoveResult {
      this.lastMouseCoord = this.document.uiState.viewPort!.toWorldCoord({
        x: event.clientX,
        y: event.clientY,
      });
      return super.onMouseMove(event, context);
    }

    draw(context: DrawingContext, args: DrawingArgs): void {
      super.draw(context, args);

      if (!this.attachActive) {
        return;
      }

      const coords = this.getAttachCoords();
      const ctx = context.ctx;

      for (const coord of coords) {
        ctx.beginPath();
        const oldAlpha = ctx.globalAlpha;
        ctx.globalAlpha = 1;
        let radius = 20;
        let lineWidth = 3;

        const worldCoord = this.toWorldCoord(coord);
        if (
          this.lastMouseCoord &&
          coordsWithinRadius(worldCoord, this.lastMouseCoord, 50)
        ) {
          radius = 30;
          lineWidth = 5;
        }

        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = "red";
        ctx.fillStyle = "white";
        ctx.arc(coord.x, coord.y, radius, 0, 2 * Math.PI);

        ctx.fill();
        ctx.stroke();
        ctx.globalAlpha = oldAlpha;
      }
      console.log("drawing attach points");
    }

    hoveredAttachSide(): LinkPosition | null {
      if (!this.attachActive) {
        return null;
      }

      const coords = this.getAttachCoords();
      const sides: LinkPosition[] = ["left", "right", "top", "bottom"];
      let i = 0;

      for (const coord of coords) {
        const worldCoord = this.toWorldCoord(coord);
        if (
          this.lastMouseCoord &&
          coordsWithinRadius(worldCoord, this.lastMouseCoord, 50)
        ) {
          return sides[i];
        }
        i++;
      }

      return null;
    }
  }

  return Attachable;
}
