import {
  Units,
  UnitsContext,
  chooseByUnitsMetric,
} from "../../../../lib/measurements";
import { assertUnreachable, cloneSimple } from "../../../../lib/utils";
import {
  DEFAULT_AVERAGE_RETURN_C,
  DEFAULT_HEATING_RETURN_DELTA_C,
  ReturnCalculations,
} from "../../../calculations/returns";
import { CoreContext } from "../../../calculations/types";
import {
  UFH_DEFAULT_INLET_HEIGHT_MM,
  UFH_DEFAULT_OUTLET_HEIGHT_MM,
} from "../../../calculations/underfloor-heating/consts";
import { getManufacturerRecord } from "../../../catalog/manufacturers/utils";
import { isChilled, isCooling, isHeatingPlantSystem } from "../../../config";
import {
  AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA,
  AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA,
  DEFAULT_DIARY_DOMESTIC_WATER,
  DEFAULT_LEGIONELLA_PURGE_TEMPERATURE_C,
  getAHUHeatingChilledHeightAboveFloorMM,
  getAHUSupplyExtractHeightAboveFloorMM,
} from "../../drawing";
import { getFlowSystem } from "../../utils";
import {
  PLANT_HEIGHT_IN,
  PLANT_HEIGHT_MM,
  VOLUMISER_DEFAULT_PRESSURE_DROP_KPA,
  VOLUMISER_DEFAULT_VOLUME_L,
} from "./consts";
import { getCustomManufFields } from "./customManufFields";
import PlantEntity, {
  isRatingDerived,
  plantDefaultName,
  plantRatingIsProvided,
} from "./plant-entity";
import {
  DHWCylinderPlant,
  NoiseReportSetting,
  PlantConcrete,
  PlantManufacturers,
  PlantShapes,
  PlantType,
  PressureMethod,
  PumpConfiguration,
  ReturnSystemPlant,
  RheemVariantValues,
} from "./plant-types";
import {
  getDatasheetByModel,
  getDefaultPlantManufacturer,
  getManifoldRangeOptions,
  getTankPumpModelSize,
  isCIBSEDiversifiedPlant,
  isDHWCylinderPlant,
  isHeatPumpPlant,
  isHotWaterRheem,
  isMultiOutlets,
  isPlantMVHR,
  isVentilationPlant,
} from "./utils";

export const DEFAULT_PEAK_FLOW_RATE_STORAGE_MINUTES = 20;

export const DEFAULT_PUMP_CONFIGURATION_SIZES: Record<
  PumpConfiguration,
  { widthMM: number; heightMM: number; widthIN: number; heightIN: number }
> = {
  duty: { widthMM: 500, heightMM: 300, widthIN: 20, heightIN: 12 },
  "duty-standby": { widthMM: 500, heightMM: 800, widthIN: 20, heightIN: 30 },
  "duty-assist": { widthMM: 500, heightMM: 800, widthIN: 20, heightIN: 30 },
  "duty-assist-standby": {
    widthMM: 500,
    heightMM: 1300,
    widthIN: 20,
    heightIN: 50,
  },
  "duty-assist-assist": {
    widthMM: 500,
    heightMM: 1300,
    widthIN: 20,
    heightIN: 50,
  },
  "duty-assist-assist-assist": {
    widthMM: 500,
    heightMM: 1800,
    widthIN: 20,
    heightIN: 75,
  },
  "duty-assist-assist-standby": {
    widthMM: 500,
    heightMM: 1800,
    widthIN: 20,
    heightIN: 75,
  },
};

export const DEFAULT_HEAT_PUMP_NOISE_REPORT_SETTING: NoiseReportSetting = {
  soundPowerLevelDB: null,
  directivity: null,
  distanceMM: null,
  barrier: null,
};

export function fillPlantDefaults<T extends PlantEntity>(
  context: CoreContext,
  entity: T,
  ignoreCalculatedDefaults?: boolean,
): T {
  const result = cloneSimple(entity);

  const { drawing, catalog, globalStore } = context;
  const calculations = ignoreCalculatedDefaults
    ? undefined
    : globalStore.getCalculation(entity);
  const liveCalculations = ignoreCalculatedDefaults
    ? undefined
    : globalStore.getLiveCalculation(entity);

  const isRheem =
    entity.plant.type === PlantType.RETURN_SYSTEM &&
    isHotWaterRheem(drawing, entity.plant.returnType);

  const hasSetGasRequirements = isRheem;

  if (!isMultiOutlets(result.plant)) {
    if (!isVentilationPlant(result.plant, drawing.metadata.flowSystems)) {
      switch (result.plant.pressureLoss.pressureMethod) {
        case PressureMethod.PUMP_DUTY:
          if (result.plant.pressureLoss.pumpPressureKPA === null) {
            result.plant.pressureLoss.pumpPressureKPA =
              calculations?.pumpDutyKPA ?? 0;
          }
          break;
        case PressureMethod.STATIC_PRESSURE:
          if (result.plant.pressureLoss.staticPressureKPA === null) {
            if (
              result.plant.type === PlantType.PUMP_TANK ||
              result.plant.type === PlantType.RO
            ) {
              result.plant.pressureLoss.staticPressureKPA =
                calculations?.pumpDutyKPA ?? 0;
            } else {
              result.plant.pressureLoss.staticPressureKPA = 0;
            }
          }
          if (result.plant.type === PlantType.FILTER) {
            result.plant.pressureLoss.pressureLossKPA =
              calculations?.pressureDropKPA ?? 0;
          }

          break;
        case PressureMethod.FIXED_PRESSURE_LOSS:
          if (result.plant.pressureLoss.pressureLossKPA === null) {
            result.plant.pressureLoss.pressureLossKPA = 0;
            if (result.plant.type === PlantType.VOLUMISER) {
              result.plant.pressureLoss.pressureLossKPA =
                VOLUMISER_DEFAULT_PRESSURE_DROP_KPA;
            }
            if (result.plant.type === PlantType.MANIFOLD) {
              if (context.featureAccess.fullUnderfloorHeatingLoops) {
                result.plant.pressureLoss.pressureLossKPA =
                  liveCalculations?.pressureDropKPA ?? 0;
              } else {
                result.plant.pressureLoss.pressureLossKPA = 20;
              }
            }
          }
          break;
        case PressureMethod.KV_PRESSURE_LOSS:
          if (result.plant.pressureLoss.kvValue === null) {
            result.plant.pressureLoss.kvValue =
              liveCalculations?.kvValue ?? calculations?.kvValue ?? 0;
          }
          break;
        default:
          assertUnreachable(result.plant.pressureLoss);
      }
    }
  }

  if (!isMultiOutlets(result.plant)) {
    if (result.plant.outletTemperatureC === null) {
      const outSystem = getFlowSystem(drawing, result.plant.outletSystemUid);

      result.plant.outletTemperatureC =
        (outSystem && Number(outSystem.temperatureC)) ||
        Number(drawing.metadata.calculationParams.roomTemperatureC);
    }
  }

  const manufacturer = getDefaultPlantManufacturer(
    result.plant,
    drawing.metadata.catalog,
  );
  let size;

  switch (result.plant.type) {
    case PlantType.RETURN_SYSTEM:
      for (const outlet of result.plant.outlets) {
        const outSystem = getFlowSystem<"pressure" | "mechanical">(
          drawing,
          outlet.outletSystemUid,
        );

        if (outlet.outletTemperatureC === null) {
          outlet.outletTemperatureC =
            (outSystem && Number(outSystem.temperatureC)) ||
            Number(drawing.metadata.calculationParams.roomTemperatureC);
        }

        if (outlet.returnLimitTemperatureC === null) {
          if (isCooling(drawing.metadata.flowSystems[outlet.outletSystemUid])) {
            if (
              isChilled(drawing.metadata.flowSystems[outlet.outletSystemUid])
            ) {
              // We want chilled's defaults to be 5 deg out and 11 deg in.
              outlet.returnLimitTemperatureC = outlet.outletTemperatureC + 6;
            } else {
              outlet.returnLimitTemperatureC = outlet.outletTemperatureC + 5;
            }
          } else {
            outlet.returnLimitTemperatureC = outlet.outletTemperatureC - 5;
          }
        }

        if (outlet.returnVelocityMS === null) {
          outlet.returnVelocityMS = Number(outSystem?.return.maxVelocityMS);
        }
        if (outlet.pressureDropKPA === null) {
          outlet.pressureDropKPA = 0;
        }
        if (outlet.heightAboveFloorM === null) {
          outlet.heightAboveFloorM = chooseByUnitsMetric(
            context.drawing.metadata.units,
            {
              [Units.Meters]: 2,
              [Units.Feet]: 6.5,
            },
            UnitsContext.NONE,
          )[1];
        }
      }

      if (hasSetGasRequirements) {
        result.plant.gasPressureKPA =
          calculations?.gasPressureKPA ?? result.plant.gasPressureKPA;
        result.plant.gasConsumptionMJH =
          calculations?.gasConsumptionMJH ?? result.plant.gasConsumptionMJH;
      }
      if (isHotWaterRheem(drawing, result.plant.returnType)) {
        result.plant.addColdWaterInlet = true;
        result.plant.addGasInlet = true;
        if (result.plant.rheemVariant === null) {
          result.plant.rheemVariant = RheemVariantValues.continuousFlow;
        }
        if (result.plant.rheemPeakHourCapacity === null) {
          result.plant.rheemPeakHourCapacity = 0;
        }
        if (!result.plant.rheemMinimumInitialDelivery) {
          result.plant.rheemMinimumInitialDelivery = 50;
        }
        if (!result.plant.rheemkWRating) {
          result.plant.rheemkWRating = 16;
        }
        if (!result.plant.rheemStorageTankSize) {
          result.plant.rheemStorageTankSize = 325;
        }

        size = catalog.hotWaterPlant.size.rheem![result.plant.rheemVariant]!;

        if (result.inletHeightAboveFloorM === null) {
          result.inletHeightAboveFloorM = 2;
        }
        if (result.plant.gasConsumptionMJH === null) {
          result.plant.gasConsumptionMJH =
            calculations?.gasConsumptionMJH ?? size[1].gas.requirement;
        }
        if (result.plant.gasPressureKPA === null) {
          result.plant.gasPressureKPA =
            calculations?.gasPressureKPA ?? size[1].gas.pressure;
        }
      } else {
        if (result.plant.gasConsumptionMJH === null) {
          result.plant.gasConsumptionMJH = chooseByUnitsMetric(
            context.drawing.metadata.units,
            {
              [Units.MegajoulesPerHour]: 500,
              [Units.BtuPerHour]: 500000,
              [Units.Watts]: 145000,
              [Units.KiloWatts]: 145,
            },
            UnitsContext.GAS_ENERGY_MEASUREMENT,
          )[1];
        }
        if (result.plant.gasPressureKPA === null) {
          result.plant.gasPressureKPA =
            calculations?.gasPressureKPA ??
            chooseByUnitsMetric(
              context.drawing.metadata.units,
              {
                [Units.KiloPascals]: 2.75,
                [Units.GasKiloPascals]: 2.75,
                [Units.Pascals]: 2750,
                [Units.Psi]: 0.4,
                [Units.Bar]: 0.0275,
                [Units.Mbar]: 27.5,
              },
              UnitsContext.NONE,
            )[1];
        }
      }

      let numPctRemain = 100;
      let numSharedPct = 0;
      for (const preheat of result.plant.preheats) {
        if (preheat.heightAboveFloorM === null) {
          if (
            isHeatingPlantSystem(
              context.drawing.metadata.flowSystems[
                result.plant.outlets[0].outletSystemUid
              ],
            )
          ) {
            preheat.heightAboveFloorM = chooseByUnitsMetric(
              context.drawing.metadata.units,
              {
                [Units.Meters]: 0.75,
                [Units.Feet]: 2.5,
              },
              UnitsContext.NONE,
            )[1];
          } else {
            // Cooling has .5m preheat inlet height
            preheat.heightAboveFloorM = chooseByUnitsMetric(
              context.drawing.metadata.units,
              {
                [Units.Meters]: 0.5,
                [Units.Feet]: 1.5,
              },
              UnitsContext.NONE,
            )[1];
          }
        }

        if (preheat.ratingMode === "percentage") {
          if (preheat.ratingPCT == null) {
            numSharedPct += 1;
          } else {
            numPctRemain -= preheat.ratingPCT;
          }
        }
      }

      for (const preheat of result.plant.preheats) {
        if (preheat.ratingMode === "percentage") {
          if (preheat.ratingPCT == null) {
            preheat.ratingPCT = Math.max(0, numPctRemain / numSharedPct);
          }
        }
      }

      if (result.plant.domesticWaterLoadKW === null) {
        if (isRatingDerived(context, result.plant)) {
          let returnHeatingSystemLoopHeatLossKW = 0;
          for (const result of calculations?.returnHeatingSystemLoopHeatLossKW ??
            []) {
            returnHeatingSystemLoopHeatLossKW += result ?? 0;
          }

          result.plant.domesticWaterLoadKW = returnHeatingSystemLoopHeatLossKW;
        }
      }
      if (!plantRatingIsProvided(result.plant)) {
        if (isRatingDerived(context, result.plant)) {
          let returnLoopHeatLossKW = 0;
          for (const result of calculations?.returnLoopHeatLossKW ?? []) {
            returnLoopHeatLossKW += result ?? 0;
          }

          result.plant.rating = {
            type: "energy",
            KW: returnLoopHeatLossKW,
          };
        }
      }
      if (isCIBSEDiversifiedPlant(result)) {
        if (result.depthMM === null) {
          result.depthMM = 600;
        }
        if (result.widthMM === null) {
          result.widthMM = 200;
        }
      }

      if (result.plant.SCOP == null) {
        if (liveCalculations?.SCOP !== undefined) {
          result.plant.SCOP = liveCalculations?.SCOP;
        } else if (calculations?.SCOP !== undefined) {
          result.plant.SCOP = calculations?.SCOP;
        }
      }

      if (result.plant.legionellaPurgeTemperatureC == null) {
        result.plant.legionellaPurgeTemperatureC =
          DEFAULT_LEGIONELLA_PURGE_TEMPERATURE_C;
      }
      if (result.plant.SPF == null) {
        if (liveCalculations?.SPF !== undefined) {
          result.plant.SPF = liveCalculations?.SPF;
        } else if (calculations?.SPF !== undefined) {
          result.plant.SPF = calculations?.SPF;
        }
      }
      if (result.plant.dairyDomesticWaterLoadL == null) {
        result.plant.dairyDomesticWaterLoadL = DEFAULT_DIARY_DOMESTIC_WATER;
      }
      if (result.plant.legionellaPurgeFrequency == null) {
        result.plant.legionellaPurgeFrequency = "Daily";
      }

      if (isDHWCylinderPlant(result.plant)) {
        const dhwCylinderPlant: DHWCylinderPlant = result.plant;
        if (dhwCylinderPlant.capacityL === null) {
          dhwCylinderPlant.capacityL = 0;
        }
      }

      break;
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
      if (result.plant.location === null) {
        result.plant.location = "nsw";
      }
      if (result.plant.position === null) {
        result.plant.position = "belowGround";
      }
      if (result.plant.capacity === null) {
        result.plant.capacity = manufacturer === "generic" ? "1000" : "1000";
      }

      size =
        catalog.greaseInterceptorTrap!.size[manufacturer][
          result.plant.location
        ][result.plant.position][result.plant.capacity];

      if (size) {
        if (result.widthMM === null) {
          result.widthMM = size.widthMM;
        }
        if (result.depthMM === null) {
          result.depthMM = size.heightMM;
        }
        if (result.plant.lengthMM === null) {
          result.plant.lengthMM = size.lengthMM;
        }
        if (result.plant.brand === null) {
          result.plant.brand = size.brand ? size.brand : "";
        }
      }

      break;
    case PlantType.RADIATOR:
      const liveCalcs = context.globalStore.getOrCreateLiveCalculation(result);
      if (result.plant.volumeL === null) {
        result.plant.volumeL = liveCalcs?.volumeL ?? calculations?.volumeL ?? 0;
      }
      if (result.inletHeightAboveFloorM === null) {
        result.inletHeightAboveFloorM = chooseByUnitsMetric(
          context.drawing.metadata.units,
          {
            [Units.Meters]: 0.1,
            [Units.Feet]: 1 / 3,
          },
          UnitsContext.NONE,
        )[1];
      }
      if (result.plant.outletHeightAboveFloorM === null) {
        result.plant.outletHeightAboveFloorM = chooseByUnitsMetric(
          context.drawing.metadata.units,
          {
            [Units.Meters]: 0.1,
            [Units.Feet]: 1 / 3,
          },
          UnitsContext.NONE,
        )[1];
      }

      switch (result.plant.radiatorType) {
        case "fixed":
          if (result.plant.heightMM === null) {
            result.plant.heightMM = 1000;
          }
          break;
        case "specify":
          if (result.plant.rating.type === "energy") {
            if (result.plant.rating.KW === null) {
              result.plant.rating.KW = liveCalcs.heatingRatingKW;
            }
          }
          if (result.plant.depthMM === null) {
            if (result.plant.manufacturer === "generic") {
              switch (result.plant.rangeType) {
                case "11":
                  result.depthMM = 120;
                  break;
                case "21":
                  result.depthMM = 130;
                  break;
                case "22":
                  result.depthMM = 140;
                  break;
                case "33":
                  result.depthMM = 150;
                  break;
                default:
                  // some kind of error here
                  result.depthMM = 120;
              }
            } else if (liveCalcs.depthMM) {
              result.depthMM = liveCalcs.depthMM;
            } else {
              result.depthMM = 120;
            }
          }
          if (
            result.plant.widthMM.type === "upper" ||
            result.plant.heightMM.type === "upper"
          ) {
            result.plant.widthMM.value = liveCalcs.widthMM;
            result.widthMM = liveCalcs.widthMM;
            result.plant.heightMM.value = liveCalcs.heightMM;
          }
          if (result.plant.model === null && calculations?.model) {
            result.plant.model = Array.isArray(calculations.model)
              ? calculations.model[0]
              : calculations.model;
          }
          break;
        default:
          assertUnreachable(result.plant);
      }

      break;
    case PlantType.PUMP:
      if (result.plant.configuration === null) {
        result.plant.configuration = "duty";
      }
      if (result.plant.manufacturer === null) {
        result.plant.manufacturer = manufacturer;
      }
      if (result.plant.model === null && calculations?.model) {
        result.plant.model = Array.isArray(calculations.model)
          ? calculations.model[0]
          : calculations.model;
      }
      if (result.plant.targetPressureKPA === null) {
        result.plant.targetPressureKPA = chooseByUnitsMetric(
          context.drawing.metadata.units,
          {
            [Units.KiloPascals]: 20,
            [Units.GasKiloPascals]: 20,
            [Units.Pascals]: 20000,
            [Units.Psi]: 3,
            [Units.Bar]: 0.2,
            [Units.Mbar]: 200,
          },
          UnitsContext.NONE,
        )[1];
      }
      break;
    case PlantType.PUMP_TANK: {
      if (result.plant.manufacturer === null) {
        result.plant.manufacturer = manufacturer;
      }
      if (result.plant.model === null && calculations?.model) {
        result.plant.model = Array.isArray(calculations.model)
          ? calculations.model[0]
          : calculations.model;
      }

      if (result.plant.targetPressureKPA === null) {
        result.plant.targetPressureKPA = chooseByUnitsMetric(
          context.drawing.metadata.units,
          {
            [Units.KiloPascals]: 20,
            [Units.GasKiloPascals]: 20,
            [Units.Pascals]: 20000,
            [Units.Psi]: 3,
            [Units.Bar]: 0.2,
            [Units.Mbar]: 200,
          },
          UnitsContext.NONE,
        )[1];
      }

      if (result.plant.capacityL === null) {
        result.plant.capacityL = calculations?.capacityL || 0;
      }

      const size = getTankPumpModelSize(
        catalog,
        result.plant.manufacturer,
        result.plant.model,
        result.plant.capacityL,
      );
      // Yes the following are correct (WHL is from different perspective)
      if (result.depthMM === null || size?.widthMM) {
        result.depthMM =
          size?.widthMM ||
          chooseByUnitsMetric(
            context.drawing.metadata.units,
            {
              [Units.Millimeters]: 800,
              [Units.Inches]: 32,
            },
            UnitsContext.NONE,
          )[1];
      }
      if (result.widthMM === null) {
        result.widthMM = size?.depthMM || null;
      }
      if (result.plant.depthMM === null) {
        result.plant.depthMM =
          size?.heightMM ||
          chooseByUnitsMetric(
            context.drawing.metadata.units,
            {
              [Units.Millimeters]: 600,
              [Units.Inches]: 24,
            },
            UnitsContext.NONE,
          )[1];
      }

      if (result.plant.peakFlowRateStorageMinutes === null) {
        result.plant.peakFlowRateStorageMinutes =
          DEFAULT_PEAK_FLOW_RATE_STORAGE_MINUTES;
      }

      break;
    }
    case PlantType.TANK:
      if (result.plant.depthMM === null) {
        result.plant.depthMM = chooseByUnitsMetric(
          context.drawing.metadata.units,
          {
            [Units.Millimeters]: 800,
            [Units.Inches]: 32,
          },
          UnitsContext.NONE,
        )[1];
      }
      if (result.plant.peakFlowRateStorageMinutes === null) {
        result.plant.peakFlowRateStorageMinutes =
          DEFAULT_PEAK_FLOW_RATE_STORAGE_MINUTES;
      }
      if (result.plant.capacityL === null) {
        result.plant.capacityL = calculations?.capacityL || 0;
      }

      break;
    case PlantType.VOLUMISER:
      if (result.plant.volumeL === null) {
        result.plant.volumeL = VOLUMISER_DEFAULT_VOLUME_L;
      }
      break;
    case PlantType.AHU_VENT:
      if (result.plant.supplyPressureDropPA === null) {
        result.plant.supplyPressureDropPA = AHU_DEFAULT_SUPPLY_PRESSURE_DROP_PA;
      }
      if (result.plant.extractPressureDropPA === null) {
        result.plant.extractPressureDropPA =
          AHU_DEFAULT_EXTRACT_PRESSURE_DROP_PA;
      }
      if (result.plant.supplyHeightAboveFloorM === null) {
        result.plant.supplyHeightAboveFloorM =
          getAHUSupplyExtractHeightAboveFloorMM(context.locale) / 1000;
      }
      if (result.plant.extractHeightAboveFloorM === null) {
        result.plant.extractHeightAboveFloorM =
          getAHUSupplyExtractHeightAboveFloorMM(context.locale) / 1000;
      }
      if (result.plant.intakeHeightAboveFloorM === null) {
        result.plant.intakeHeightAboveFloorM =
          getAHUSupplyExtractHeightAboveFloorMM(context.locale) / 1000;
      }
      if (result.plant.exhaustHeightAboveFloorM === null) {
        result.plant.exhaustHeightAboveFloorM =
          getAHUSupplyExtractHeightAboveFloorMM(context.locale) / 1000;
      }
    case PlantType.AHU:
    case PlantType.FCU:
      if (result.plant.heightMM === null) {
        result.plant.heightMM = 1000;
      }
      if (result.plant.heatingPressureLoss.pressureLossKPA === null) {
        result.plant.heatingPressureLoss.pressureLossKPA = 10;
      }
      if (result.plant.chilledPressureLoss.pressureLossKPA === null) {
        result.plant.chilledPressureLoss.pressureLossKPA = 10;
      }

      if (result.plant.heatingHeightAboveFloorM === null) {
        result.plant.heatingHeightAboveFloorM =
          getAHUHeatingChilledHeightAboveFloorMM(context.locale) / 1000;
      }
      if (result.plant.chilledHeightAboveFloorM === null) {
        result.plant.chilledHeightAboveFloorM =
          getAHUHeatingChilledHeightAboveFloorMM(context.locale) / 1000;
      }
      if (isPlantMVHR(result.plant)) {
        if (result.plant.heatRecoveryEfficiencyPct === null) {
          result.plant.heatRecoveryEfficiencyPct = 90;
        }
        if (result.plant.airTemperatureMVHR === null) {
          result.plant.airTemperatureMVHR = 50;
        }
        if (result.plant.increaseFlowRateBasedOnHeatLoad === null) {
          result.plant.increaseFlowRateBasedOnHeatLoad = true;
        }
      }

      if (result.plant.heatingRating.type === "energy") {
        if (result.plant.heatingRating.KW === null) {
          result.plant.heatingRating.KW =
            liveCalculations?.heatingRatingKW ?? 0;
        }
        if (result.plant.chilledRating.type === "energy") {
          if (result.plant.chilledRating.KW === null) {
            result.plant.chilledRating.KW =
              liveCalculations?.chilledRatingKW ?? 0;
          }
        }
      }
      break;
    case PlantType.FILTER:
      if (result.plant.manufacturer === null) {
        result.plant.manufacturer = manufacturer;
      }
      if (result.plant.configuration === null) {
        result.plant.configuration = "duty";
      }
      if (result.plant.model === null && calculations) {
        result.plant.model = Array.isArray(calculations.model)
          ? calculations.model[0]
          : calculations.model;
      }
      if (result.plant.model) {
        const filterData = getDatasheetByModel(
          context.catalog,
          result.plant,
          result.plant.model,
        );

        if (filterData) {
          if (result.inletHeightAboveFloorM === null) {
            result.inletHeightAboveFloorM =
              filterData.connectionHeightMM / 1000;
          }
          if (result.plant.outletHeightAboveFloorM === null) {
            result.plant.outletHeightAboveFloorM =
              filterData.connectionHeightMM / 1000;
          }
        }
      }
      break;
    case PlantType.RO:
      if (result.plant.targetPressureKPA === null) {
        result.plant.targetPressureKPA = chooseByUnitsMetric(
          context.drawing.metadata.units,
          {
            [Units.KiloPascals]: 20,
            [Units.GasKiloPascals]: 20,
            [Units.Pascals]: 20000,
            [Units.Psi]: 3,
            [Units.Bar]: 0.2,
            [Units.Mbar]: 200,
          },
          UnitsContext.NONE,
        )[1];
      }
      if (result.plant.model === null && calculations?.model) {
        result.plant.model = Array.isArray(calculations.model)
          ? calculations.model[0]
          : calculations.model;
      }
      if (result.plant.model) {
        const roData = getDatasheetByModel(
          context.catalog,
          result.plant,
          result.plant.model,
        );

        if (roData) {
          if (result.inletHeightAboveFloorM === null) {
            result.inletHeightAboveFloorM = roData.connectionHeightMM / 1000;
          }
          if (result.plant.outletHeightAboveFloorM === null) {
            result.plant.outletHeightAboveFloorM =
              roData.connectionHeightMM / 1000;
          }
        }
      }
      break;
    case PlantType.MANIFOLD: {
      const liveCalcs = context.globalStore.getOrCreateLiveCalculation(result);
      if (result.plant.manifoldManufacturer === null) {
        result.plant.manifoldManufacturer = getDefaultPlantManufacturer(
          result.plant,
          drawing.metadata.catalog,
          "manifold",
        );
      }
      if (result.plant.manifoldRange === null) {
        const ranges = getManifoldRangeOptions(
          context,
          result.plant.manifoldManufacturer,
        );
        result.plant.manifoldRange = ranges[0]?.key;
      }
      if (result.plant.manifoldModel === null) {
        result.plant.manifoldModel =
          liveCalcs.manifoldManufacturers.manifoldModel ?? "";
      }
      if (result.plant.ballValveManufacturer === null) {
        result.plant.ballValveManufacturer = getDefaultPlantManufacturer(
          result.plant,
          drawing.metadata.catalog,
          "ballValve",
        );
      }
      if (result.plant.ballValveModel === null) {
        result.plant.ballValveModel =
          liveCalcs.manifoldManufacturers.ballValveModel ?? "";
      }
      if (result.plant.actuatorManufacturer === null) {
        result.plant.actuatorManufacturer = getDefaultPlantManufacturer(
          result.plant,
          drawing.metadata.catalog,
          "actuator",
        );
      }
      if (result.plant.actuatorModel === null) {
        result.plant.actuatorModel =
          liveCalcs.manifoldManufacturers.actuatorModel ?? "";
      }
      if (result.plant.pumpPackManufacturer === null) {
        result.plant.pumpPackManufacturer = getDefaultPlantManufacturer(
          result.plant,
          drawing.metadata.catalog,
          "pumpPack",
        );
      }
      if (result.plant.pumpPackModel === null) {
        result.plant.pumpPackModel =
          liveCalcs.manifoldManufacturers.pumpPackModel ?? "";
      }

      if (result.plant.pumpManufacturer === null) {
        result.plant.pumpManufacturer = getDefaultPlantManufacturer(
          result.plant,
          drawing.metadata.catalog,
          "pump",
        );
      }
      if (result.plant.pumpModel === null) {
        result.plant.pumpModel =
          liveCalcs.manifoldManufacturers.pumpId?.[1] ?? "";
      }
      if (result.plant.blendingValveManufacturer === null) {
        result.plant.blendingValveManufacturer = getDefaultPlantManufacturer(
          result.plant,
          drawing.metadata.catalog,
          "blendingValve",
        );
      }
      if (result.plant.blendingValveModel === null) {
        result.plant.blendingValveModel =
          liveCalcs.manifoldManufacturers.blendingValveId?.[1] ?? "";
      }

      if (result.plant.rating.type === "energy") {
        result.plant.rating.KW = liveCalculations?.heatingRatingKW ?? 0;
      }
      if (result.widthMM === null) {
        result.widthMM = liveCalcs.widthMM ?? 1000;
      }
      if (result.plant.heightMM === null) {
        result.plant.heightMM = liveCalcs.heightMM ?? 1000;
      }
      if (result.plant.widthMM === null) {
        result.plant.widthMM = liveCalcs.widthMM ?? 1000;
      }
      if (result.depthMM === null) {
        result.depthMM = liveCalcs.depthMM ?? 100;
      }
      if (result.plant.depthMM === null) {
        result.plant.depthMM = liveCalcs.depthMM ?? 100;
      }
      if (result.plant.volumeL === null) {
        result.plant.volumeL = liveCalcs.volumeL ?? calculations?.volumeL ?? 0;
      }
      if (result.plant.internalVolumeL === null) {
        result.plant.internalVolumeL =
          liveCalcs?.internalVolumeL ?? calculations?.internalVolumeL ?? 0;
      }
      if (!plantRatingIsProvided(result.plant)) {
        if (result.plant.rating.type === "flow-rate") {
          result.plant.rating.LS = ReturnCalculations.KW2LS(
            context,
            result.inletSystemUid,
            liveCalculations?.heatingRatingKW ?? 0,
            liveCalculations?.returnDeltaC ?? 0,
            liveCalculations?.returnAverageC ?? 0,
          );
        }
      }
      if (result.plant.ufhFlowTempC === null) {
        result.plant.ufhFlowTempC =
          (liveCalculations?.returnAverageC ??
            calculations?.returnAverageC ??
            DEFAULT_AVERAGE_RETURN_C) +
          (liveCalculations?.returnDeltaC ??
            calculations?.returnDeltaC ??
            DEFAULT_HEATING_RETURN_DELTA_C) /
            2;
      }
      if (result.plant.ufhReturnTempC === null) {
        result.plant.ufhReturnTempC =
          (liveCalculations?.returnAverageC ??
            calculations?.returnAverageC ??
            DEFAULT_AVERAGE_RETURN_C) -
          (liveCalculations?.returnDeltaC ??
            calculations?.returnDeltaC ??
            DEFAULT_HEATING_RETURN_DELTA_C) /
            2;
      }
      break;
    }
    case PlantType.UFH:
      if (result.plant.heightMM === null) {
        result.plant.heightMM = 1000;
      }
      if (result.plant.volumeL === null) {
        result.plant.volumeL = calculations?.volumeL ?? 0;
      }
    case PlantType.CUSTOM:
    case PlantType.DRAINAGE_PIT:
    case PlantType.DUCT_MANIFOLD:
      break;
    default:
      assertUnreachable(result.plant);
  }

  switch (result.plant.type) {
    case PlantType.PUMP: {
      if (result.widthMM === null) {
        result.widthMM =
          calculations?.widthMM ||
          DEFAULT_PUMP_CONFIGURATION_SIZES[result.plant.configuration!].widthMM;
      }
      if (result.depthMM === null) {
        result.depthMM =
          calculations?.depthMM ||
          DEFAULT_PUMP_CONFIGURATION_SIZES[result.plant.configuration!]
            .heightMM;
      }
      break;
    }
    case PlantType.MANIFOLD:
      if (result.inletHeightAboveFloorM === null) {
        result.inletHeightAboveFloorM = UFH_DEFAULT_INLET_HEIGHT_MM / 1000;
      }
      if (result.plant.outletHeightAboveFloorM === null) {
        result.plant.outletHeightAboveFloorM =
          UFH_DEFAULT_OUTLET_HEIGHT_MM / 1000;
      }
      break;
    case PlantType.RADIATOR:
    case PlantType.RETURN_SYSTEM:
    case PlantType.TANK:
    case PlantType.PUMP_TANK:
    case PlantType.DRAINAGE_PIT:
    case PlantType.CUSTOM:
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
    case PlantType.AHU:
    case PlantType.AHU_VENT:
    case PlantType.UFH:
    case PlantType.FILTER:
    case PlantType.RO:
      if (result.widthMM === null) {
        result.widthMM =
          calculations?.widthMM ||
          chooseByUnitsMetric(
            context.drawing.metadata.units,
            {
              [Units.Millimeters]: 500,
              [Units.Inches]: 20,
            },
            UnitsContext.NONE,
          )[1];
      }
      if (result.depthMM === null) {
        result.depthMM =
          calculations?.depthMM ||
          chooseByUnitsMetric(
            context.drawing.metadata.units,
            {
              [Units.Millimeters]: PLANT_HEIGHT_MM,
              [Units.Inches]: PLANT_HEIGHT_IN,
            },
            UnitsContext.NONE,
          )[1];
      }
      break;
    case PlantType.FCU:
      if (result.widthMM === null) {
        result.widthMM = chooseByUnitsMetric(
          context.drawing.metadata.units,
          {
            [Units.Millimeters]: 1000,
            [Units.Inches]: 40,
          },
          UnitsContext.NONE,
        )[1];
      }
      if (result.depthMM === null) {
        result.depthMM = chooseByUnitsMetric(
          context.drawing.metadata.units,
          {
            [Units.Millimeters]: 600,
            [Units.Inches]: 24,
          },
          UnitsContext.NONE,
        )[1];
      }
      break;
    case PlantType.DUCT_MANIFOLD:
      if (result.widthMM === null) {
        result.widthMM = chooseByUnitsMetric(
          context.drawing.metadata.units,
          {
            [Units.Millimeters]: 200,
            [Units.Inches]: 8,
          },
          UnitsContext.NONE,
        )[1];
      }
      if (result.depthMM === null) {
        const outletNumber = result.plant.outlets.length;
        result.depthMM = chooseByUnitsMetric(
          context.drawing.metadata.units,
          {
            [Units.Millimeters]: (outletNumber + 1) * 150,
            [Units.Inches]: (outletNumber + 1) * 6,
          },
          UnitsContext.NONE,
        )[1];
      }
      for (const outlet of result.plant.outlets) {
        if (outlet.pressureLoss.pressureLossKPA === null) {
          outlet.pressureLoss.pressureLossKPA = 0;
        }
      }
      break;
    case PlantType.VOLUMISER:
      if (result.plant.diameterMM === null) {
        result.plant.diameterMM = 500;
      }
      result.widthMM = result.plant.diameterMM;
      result.depthMM = result.plant.diameterMM;
      break;
    default:
      assertUnreachable(result.plant);
  }
  if (!result.plant.shape || result.plant.shape === null) {
    result.plant.shape = PlantShapes.RECTANGULAR;
  }
  if (!result.plant.diameterMM || result.plant.diameterMM === null) {
    result.plant.diameterMM = result.widthMM;
  }
  if (result.inletHeightAboveFloorM === null) {
    result.inletHeightAboveFloorM = chooseByUnitsMetric(
      context.drawing.metadata.units,
      {
        [Units.Meters]: 0.75,
        [Units.Feet]: 2.5,
      },
      UnitsContext.NONE,
    )[1];
  }
  if (
    !isMultiOutlets(result.plant) &&
    result.plant.outletHeightAboveFloorM === null
  ) {
    result.plant.outletHeightAboveFloorM = chooseByUnitsMetric(
      context.drawing.metadata.units,
      {
        [Units.Meters]: 0.75,
        [Units.Feet]: 2.5,
      },
      UnitsContext.NONE,
    )[1];
  }

  if (hasGas(manufacturer, result)) {
    const returnSystem = result.plant as ReturnSystemPlant;
    if (returnSystem.diversity === null) {
      returnSystem.diversity = 100;
    }
  }

  if (result.name === null) {
    const manRecord = getManufacturerRecord(result, catalog);
    result.name = plantDefaultName(context, result);
    if (manRecord && manRecord.uid !== "generic") {
      if (result.widthMM! > result.depthMM! * 2) {
        result.name = `${manRecord.name} ${result.name}`;
      } else {
        result.name = `${manRecord.name}\n${result.name}`;
      }
    }
  }

  const adjustedHeight = adjustHeightForInletsOutlets(result);
  if (entity.depthMM === null) {
    result.depthMM = adjustedHeight;
  }
  if (entity.plant.diameterMM === null) {
    result.plant.diameterMM = Math.max(
      result.plant.diameterMM!,
      adjustedHeight,
    );
  }

  if (
    result.plant.type === PlantType.RETURN_SYSTEM &&
    isHeatPumpPlant(result.plant)
  ) {
    if (result.plant.noiseReportSetting === null) {
      result.plant.noiseReportSetting = DEFAULT_HEAT_PUMP_NOISE_REPORT_SETTING;
    } else {
      if (result.plant.noiseReportSetting.soundPowerLevelDB === null) {
        result.plant.noiseReportSetting.soundPowerLevelDB =
          DEFAULT_HEAT_PUMP_NOISE_REPORT_SETTING.soundPowerLevelDB;
      }
      if (result.plant.noiseReportSetting.directivity === null) {
        result.plant.noiseReportSetting.directivity =
          DEFAULT_HEAT_PUMP_NOISE_REPORT_SETTING.directivity;
      }
      if (result.plant.noiseReportSetting.distanceMM === null) {
        result.plant.noiseReportSetting.distanceMM =
          DEFAULT_HEAT_PUMP_NOISE_REPORT_SETTING.distanceMM;
      }
      if (result.plant.noiseReportSetting.distanceMM === null) {
        result.plant.noiseReportSetting.distanceMM =
          DEFAULT_HEAT_PUMP_NOISE_REPORT_SETTING.distanceMM;
      }
      if (result.plant.noiseReportSetting.barrier === null) {
        result.plant.noiseReportSetting.barrier =
          DEFAULT_HEAT_PUMP_NOISE_REPORT_SETTING.barrier;
      }
    }
  }

  fillDefaultCustomManufFields(result.plant);

  return result;
}

function fillDefaultCustomManufFields(result: PlantConcrete) {
  const fields = getCustomManufFields(result);
  for (const field of fields) {
    if ("customManufFields" in result && result.customManufFields) {
      if (result.customManufFields[field.uid] === null) {
        result.customManufFields[field.uid] = field.defaultValue;
      }
    }
  }
}

function adjustHeightForInletsOutlets(result: PlantEntity): number {
  // This is to not override Rheem or default manufacturer's height
  if (result.depthMM !== PLANT_HEIGHT_MM) {
    return result.depthMM!;
  }
  if (result.plant.type === PlantType.RETURN_SYSTEM) {
    // TODO this should use plant.getInletsOutlets() in the future
    // inlets
    let inletsCount = 0;
    if (result.plant.addColdWaterInlet) inletsCount++;
    if (result.plant.addGasInlet) inletsCount++;
    inletsCount += result.plant.preheats.length * 2;

    // outlets
    let outletsCount = 0;
    for (const outlet of result.plant.outlets) {
      if (outlet.outletUid) outletsCount++;
      if (outlet.outletReturnUid) outletsCount++;
    }

    const inletsOutletsCount = Math.max(inletsCount, outletsCount);

    if (inletsOutletsCount > 2) {
      const newHeightUnits = inletsOutletsCount + 1;
      const oldHeightUnits = 2 + 1;
      return (result.depthMM! * newHeightUnits) / oldHeightUnits;
    }
  }
  return result.depthMM!;
}

function hasGas(manufacturer: PlantManufacturers, entity: PlantEntity) {
  if (
    entity.plant.type === PlantType.RETURN_SYSTEM &&
    (manufacturer === "generic" ||
      (manufacturer === "rheem" &&
        ![RheemVariantValues.electric, RheemVariantValues.heatPump].includes(
          entity.plant.rheemVariant!,
        )))
  ) {
    return true;
  }

  return false;
}
