import { Color } from "../../../lib/color";
import { Units } from "../../../lib/measurements";
import { assertUnreachable, cloneSimple } from "../../../lib/utils";
import { CoreContext } from "../../calculations/types";
import {
  FieldType,
  PropertyField,
  withPropertyTracking,
} from "./property-field";
import { BoxedEntity, DrawableEntity, NamedEntity } from "./simple-entities";
import { EntityType } from "./types";
import { getAvailableHeatLoadComponentMaterial } from "./utils";

export enum ArchitectType {
  VELUX = "VELUX",
}
export function isArcType(s: string): s is ArchitectType {
  return Object.values(ArchitectType).includes(s as ArchitectType);
}
export interface ArchitectureElementBase
  extends DrawableEntity,
    NamedEntity,
    BoxedEntity {
  type: EntityType.ARCHITECTURE_ELEMENT;
  arcType: ArchitectType;
  architecture: ArchitectureConcrete;

  color: Color | null;
}

export interface VeluxEntity extends ArchitectureElementBase {
  arcType: ArchitectType.VELUX;
  architecture: VeluxArchitecture;
}

export interface CommonArchitecture {}
export interface VeluxArchitecture extends CommonArchitecture {
  materialUid: string | null;
}
export type ArchitectureConcrete = VeluxArchitecture;

export type ArchitectureElementEntity = VeluxEntity;

export function fillDefaultArcFields<T extends ArchitectureElementEntity>(
  context: CoreContext,
  entity: T,
): T {
  let result = cloneSimple(entity);
  switch (result.arcType) {
    case ArchitectType.VELUX: {
      if (result.color === null) {
        result.color = {
          hex: "#64aa4c",
        };
      }

      let veluxSpec = context.drawing.metadata.heatLoss.windowSpec.Velux;
      if (result.widthM === null) {
        result.widthM = veluxSpec.widthM;
      }
      if (result.lengthM === null) {
        result.lengthM = veluxSpec.lengthM;
      }

      if (result.architecture.materialUid === null) {
        result.architecture.materialUid =
          context.drawing.metadata.heatLoss.defaultMaterial["Window"];
      }
      break;
    }
    default:
      assertUnreachable(result.arcType);
  }
  return result;
}

export function makeArchitectureFields(
  context: CoreContext,
  entity: ArchitectureElementEntity,
): PropertyField[] {
  let properties: PropertyField[] = [
    {
      property: "color",
      title: "Color",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Color,
      params: null,
      multiFieldId: "color",
    },
  ];

  switch (entity.arcType) {
    case ArchitectType.VELUX: {
      properties.push({
        property: "architecture.materialUid",
        title: "Window Material",
        hasDefault: true,
        isCalculated: false,
        type: FieldType.Choice,
        params: {
          choices: getAvailableHeatLoadComponentMaterial(context, "Window"),
        },
        multiFieldId: "architecture-materialUid",
      });
    }
  }
  properties.push({
    property: "lengthM",
    title: "Length",
    hasDefault: true,
    isCalculated: false,
    type: FieldType.Number,
    units: Units.Meters,
    params: {
      min: 0,
      max: null,
    },
    multiFieldId: "lengthM",
  });
  properties.push({
    property: "widthM",
    title: "Width",
    hasDefault: true,
    isCalculated: false,
    type: FieldType.Number,
    units: Units.Meters,
    params: {
      min: 0,
      max: null,
    },
    multiFieldId: "widthM",
  });

  return properties.map(withPropertyTracking(entity));
}

export function getArchitectureName(type: ArchitectType): string {
  switch (type) {
    case ArchitectType.VELUX:
      return "Velux";
    default:
      assertUnreachable(type);
  }
  return "";
}
