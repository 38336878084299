import { assertUnreachable } from "../../../lib/utils";
import {
  CalculatableEntityConcrete,
  DrawableEntityConcrete,
} from "../entities/concrete-entity";
import { EntityType } from "../entities/types";

export function isCalculated(
  e: DrawableEntityConcrete,
): e is CalculatableEntityConcrete {
  switch (e.type) {
    case EntityType.RISER:
    case EntityType.DIRECTED_VALVE:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.CONDUIT:
    case EntityType.FITTING:
    case EntityType.SYSTEM_NODE:
    case EntityType.BIG_VALVE:
    case EntityType.FLOW_SOURCE:
    case EntityType.FIXTURE:
    case EntityType.LOAD_NODE:
    case EntityType.PLANT:
    case EntityType.GAS_APPLIANCE:
    case EntityType.COMPOUND:
    case EntityType.EDGE:
    case EntityType.VERTEX:
    case EntityType.ROOM:
    case EntityType.WALL:
    case EntityType.FENESTRATION:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return true;
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
      return false;
  }
  assertUnreachable(e);
}
