
import {
  ResetPasswordOutput,
  confirmResetPassword,
  resetPassword,
} from "aws-amplify/auth";
import { defineComponent } from "vue";
import { assertUnreachable } from "../../../common/src/lib/utils";
import { emailValidation } from "../../../common/src/models/User.validation";
import { CLIENT_METADATA } from "../auth/amplify.config";
import HeroButton from "../components/molecules/HeroButton.vue";

export default defineComponent({
  components: { HeroButton },
  data() {
    const state = "enterUsername" as "enterUsername" | "enterCode" | "success";
    const isLoading: boolean = false;

    return {
      isLoading,
      state,
      username: "",
      code: "",
      password: "",
      sentEmail: "",
    };
  },
  mounted() {
    const username = this.$route.query.username;
    if (username) {
      this.state = "enterCode";
      this.username = String(username);
      this.reset();
    }
  },
  methods: {
    getValidUsername() {
      const { success: isValidEmail } = emailValidation(this.username);
      return isValidEmail ? this.username.toLowerCase() : this.username;
    },
    async reset() {
      try {
        this.isLoading = true;
        const username = this.getValidUsername();
        const res = await resetPassword({
          username,
          options: {
            clientMetadata: CLIENT_METADATA,
          },
        });
        this.handleResetPassRes(res);
      } catch (error: any) {
        console.error(error);
        this.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async confirmReset() {
      try {
        this.isLoading = true;
        const username = this.getValidUsername();
        await confirmResetPassword({
          username: username,
          confirmationCode: this.code,
          newPassword: this.password,
          options: {
            clientMetadata: CLIENT_METADATA,
          },
        });
        this.state = "success";
      } catch (error: any) {
        console.error(error);
        this.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    handleResetPassRes(res: ResetPasswordOutput) {
      const { nextStep } = res;
      switch (nextStep.resetPasswordStep) {
        case "CONFIRM_RESET_PASSWORD_WITH_CODE":
          const deliveryDetails = nextStep.codeDeliveryDetails;
          this.sentEmail = deliveryDetails.destination ?? "";
          this.state = "enterCode";
          break;
        case "DONE":
          return this.$router.push({ name: "login" });
        default:
          assertUnreachable(nextStep.resetPasswordStep);
      }
    },
    handleError(e: Error) {
      this.$bvToast.toast(e.message, {
        variant: "warning",
        toaster: " b-toaster-top-center",
        title: "Something is not right",
        noAutoHide: true,
      });
    },
    goToLogin() {
      return this.$router.push({ name: "login" });
    },
  },
});
