import CoreDirectedValve from "../../../../common/src/api/coreObjects/coreDirectedValve";
import { decomposeMatrix } from "../../../../common/src/api/coreObjects/lib/utils";
import { VALVE_HEIGHT_MM } from "../../../../common/src/api/coreObjects/utils";
import { DuctCalculation } from "../../../../common/src/api/document/calculations-objects/conduit-calculations";
import DirectedValveEntity, {
  fillDirectedValveFields,
} from "../../../../common/src/api/document/entities/directed-valves/directed-valve-entity";
import {
  IsolationValve,
  ValveType,
} from "../../../../common/src/api/document/entities/directed-valves/valve-types";
import { DrawableEntity } from "../../../../common/src/api/document/entities/simple-entities";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { lighten } from "../../../../common/src/lib/color";
import { Coord } from "../../../../common/src/lib/coord";
import { canonizeAngleRad } from "../../../../common/src/lib/mathUtils/mathutils";
import { assertUnreachable } from "../../../../common/src/lib/utils";
import { DEFAULT_FONT_NAME } from "../../config";
import { getDragPriority } from "../../store/document";
import { getGlobalContext } from "../../store/globalCoreContext";
import { VALVE_INACTIVE_COLOR } from "../lib/colors";
import { EntityDrawingArgs } from "../lib/drawable-object";
import { EntityPopupContent } from "../lib/entity-popups/types";
import { AttachableObject } from "../lib/object-traits/attachable-object";
import { CalculatedObject } from "../lib/object-traits/calculated-object";
import { CenteredObject } from "../lib/object-traits/centered-object";
import { ConnectableObject } from "../lib/object-traits/connectable";
import CoolDraggableObject from "../lib/object-traits/cool-draggable-object";
import { Core2Drawable } from "../lib/object-traits/core2drawable";
import {
  HoverSiblingResult,
  HoverableObject,
} from "../lib/object-traits/hoverable-object";
import { SelectableObject } from "../lib/object-traits/selectable";
import { SnappableObject } from "../lib/object-traits/snappable-object";
import { SVG_PATH, SvgPathLoader } from "../lib/svg-path-loader";
import { DrawingContext, ObjectConstructArgs } from "../lib/types";
import {
  VALVE_LINE_WIDTH_MM,
  VALVE_SIZE_MM,
  drawRpzdDouble,
} from "../lib/utils";
import { DrawingMode } from "../types";
import DrawableConduit from "./drawableConduit";
import { applyHoverEffects } from "./utils";

const Base = CalculatedObject(
  SelectableObject(
    CoolDraggableObject(
      AttachableObject(
        HoverableObject(
          ConnectableObject(
            CenteredObject(SnappableObject(Core2Drawable(CoreDirectedValve))),
          ),
        ),
      ),
    ),
  ),
);

export default class DrawableDirectedValve extends Base {
  dragPriority = getDragPriority(EntityType.DIRECTED_VALVE);
  maximumConnections = 2;
  minimumConnections = 0;

  // We could not add this to the base drawable class because
  // of some typescript thing so they have to be added at the concrete class.
  constructor(args: ObjectConstructArgs<DirectedValveEntity>) {
    super(args.context, args.obj);
    this.onSelect = args.onSelect;
    this.onInteractionComplete = args.onInteractionComplete;
    this.document = args.document;
  }

  get rotationRad(): number {
    // We must get the rotation outside of position() because these
    // rotation calculations depend on position(). So apply this rotation
    // to the context manually afterwards when drawing.
    const connections = this.globalStore.getConnections(this.entity.uid);
    if (connections.length === 0) {
      return 0;
    } else if (connections.length === 1) {
      const oc = this.toObjectCoord(this.getRadials()[0][0]);
      if (connections[0] === this.entity.sourceUid) {
        return canonizeAngleRad(Math.atan2(oc.y, oc.x) + Math.PI);
      } else {
        return canonizeAngleRad(Math.atan2(oc.y, oc.x));
      }
    } else if (connections.length === 2) {
      // const s = this.globalStore.get(this.entity.sourceUid) as Pipe;
      // const soc = this.toObjectCoord(s.worldEndpoints(this.uid)[0]);
      const other = this.otherUid!;
      const o = this.globalStore.get(other) as DrawableConduit;
      const ooc = this.toObjectCoord(o.worldEndpoints(this.uid)[0]);
      // const sa = Math.atan2(soc.y, soc.x);
      const oa = Math.atan2(ooc.y, ooc.x);
      return canonizeAngleRad(oa);
      // const diff = canonizeAngleRad(oa - (sa + Math.PI));
      // return canonizeAngleRad(sa + Math.PI + diff / 2);
    } else {
      throw new Error("invalid configuration for directed entity");
    }
  }

  get valveWidthMM(): number {
    return (this.valveHeightMM * VALVE_SIZE_MM) / VALVE_HEIGHT_MM;
  }

  get valveHeightMM(): number {
    return this.entity.valveSizeMM || VALVE_HEIGHT_MM;
  }

  drawConnectable(
    context: DrawingContext,
    { selected }: EntityDrawingArgs,
  ): void {
    const existingTransform = context.ctx.getTransform();
    applyHoverEffects(context, this);
    const s = context.vp.currToSurfaceScale(context.ctx);
    context.ctx.rotate(this.rotationRad);

    const e = fillDirectedValveFields(getGlobalContext(), this.entity);
    if (selected) {
      context.ctx.fillStyle = lighten(e.color!.hex, 50, 0.8);
      context.ctx.fillRect(
        -this.valveWidthMM * 1.2,
        -this.valveWidthMM * 1.2,
        this.valveWidthMM * 2.4,
        this.valveWidthMM * 2.4,
      );
    }
    let color = this.baseDrawnColor(context);
    if (!this.isActive()) {
      color = VALVE_INACTIVE_COLOR;
    }

    const baseWidth = Math.max(
      2.0 / s,
      VALVE_LINE_WIDTH_MM / this.toWorldLength(1),
    );

    context.ctx.fillStyle = color.hex;
    context.ctx.strokeStyle = color.hex;
    context.ctx.lineWidth = baseWidth;
    let maxOffsetY = this.valveWidthMM;
    switch (this.entity.valve.type) {
      case ValveType.CHECK_VALVE:
        this.drawCheckValve(context);
        break;
      case ValveType.ISOLATION_VALVE:
        this.drawIsolationValve(context);
        break;
      case ValveType.RV:
        this.drawRv(context);
        break;
      case ValveType.PRV_SINGLE:
        this.drawPrvSingle(context);
        break;

      case ValveType.PRV_DOUBLE:
        this.drawPrvDouble(context);
        maxOffsetY = this.valveHeightMM * (2 + 0.3);
        break;

      case ValveType.PRV_TRIPLE:
        this.drawPrvTriple(context);
        maxOffsetY = this.valveHeightMM * (3 + 0.3);
        break;
      case ValveType.RPZD_SINGLE:
        this.drawRpzdSingle(context);
        break;

      case ValveType.RPZD_DOUBLE_ISOLATED:
      case ValveType.RPZD_DOUBLE_SHARED:
        drawRpzdDouble(
          context,
          [context.ctx.fillStyle, context.ctx.fillStyle],
          undefined,
          this,
        );
        maxOffsetY = this.valveHeightMM * (2 + 0.3);
        break;
      case ValveType.WATER_METER:
        this.drawWaterMeter(context);
        break;
      case ValveType.CSV:
        this.drawCSValve(context);
        break;
      case ValveType.STRAINER:
        this.drawStrainer(context);
        break;
      case ValveType.TRV:
        this.drawTRV(context);
        break;
      case ValveType.BALANCING:
      case ValveType.LSV:
        this.drawBalancingValve(context);
        break;
      case ValveType.PICV:
        this.drawPICV(context);
        break;
      case ValveType.GAS_REGULATOR:
        this.drawGasRegulator(context);
        break;
      case ValveType.FILTER:
        this.drawFilter(context);
        break;
      case ValveType.FLOOR_WASTE:
        this.drawFloorWaste(context);
        break;
      case ValveType.INSPECTION_OPENING:
        this.drawInspectionOpening(context);
        break;
      case ValveType.REFLUX_VALVE:
        this.drawRefluxValve(context);
        break;
      case ValveType.SMOKE_DAMPER:
        this.drawSmokeDamper(context);
        break;
      case ValveType.FIRE_DAMPER:
        this.drawFireDamper(context);
        break;
      case ValveType.VOLUME_CONTROL_DAMPER:
        this.drawVolumeControlDamper(context);
        break;
      case ValveType.ATTENUATOR:
        this.drawAttenuator(context);
        break;
      case ValveType.CUSTOM_VALVE:
        this.drawCustomValve(context);
        break;
      case ValveType.FAN:
        this.drawFan(context);
        break;
      default:
        assertUnreachable(this.entity.valve);
    }

    // Display Entity Name
    if (this.entity.entityName) {
      const name = this.entity.entityName;
      context.ctx.font = 70 + "pt " + DEFAULT_FONT_NAME;
      const nameWidth = context.ctx.measureText(name).width;
      const offsetx = -nameWidth / 2;
      context.ctx.fillStyle = "rgba(0, 255, 20, 0.13)";
      // the 70 represents the height of the font
      const textHight = 70;
      const offsetY = maxOffsetY + textHight / 2;
      context.ctx.fillRect(offsetx, offsetY, nameWidth, 70);
      context.ctx.fillStyle = color.hex;
      context.ctx.fillTextStable(name, offsetx, offsetY - 4, undefined, "top");
    }

    context.ctx.setTransform(existingTransform);
  }

  drawFloorWaste(context: DrawingContext) {
    this.withWorldAngle(context, { x: 0, y: 0 }, () => {
      const ctx = context.ctx;
      ctx.fillStyle = "#ffffff";
      ctx.beginPath();
      ctx.arc(0, 0, this.valveWidthMM, 0, Math.PI * 2);
      ctx.fill();
      ctx.stroke();

      ctx.beginPath();
      const lineX = this.valveWidthMM / 2;
      const lineY = (this.valveWidthMM ** 2 - lineX ** 2) ** 0.5;

      ctx.moveTo(0, this.valveWidthMM);
      ctx.lineTo(0, -this.valveWidthMM);
      ctx.moveTo(lineX, lineY);
      ctx.lineTo(lineX, -lineY);
      ctx.moveTo(-lineX, lineY);
      ctx.lineTo(-lineX, -lineY);

      ctx.stroke();

      ctx.fillStyle = "#000000";
      ctx.font = "65px " + DEFAULT_FONT_NAME;

      let name = "FW";
      if (
        this.entity.valve.type === ValveType.FLOOR_WASTE &&
        this.entity.valve.variant === "bucketTrap"
      ) {
        name = "BT";
      }

      ctx.fillTextStable(name, -50, 25);
    });
  }

  drawInspectionOpening(context: DrawingContext) {
    this.withWorldAngle(context, { x: 0, y: 0 }, () => {
      const ctx = context.ctx;
      ctx.fillStyle = "#ffffff";
      ctx.beginPath();
      ctx.arc(0, 0, this.valveWidthMM, 0, Math.PI * 2);
      ctx.fill();
      ctx.stroke();

      ctx.fillStyle = "#000000";
      ctx.font = "65px " + DEFAULT_FONT_NAME;
      ctx.fillTextStable("I.O.", -50, 25);
    });
  }

  drawRefluxValve(context: DrawingContext) {
    const ctx = context.ctx;
    ctx.beginPath();
    ctx.moveTo(-this.valveWidthMM, this.valveHeightMM);
    ctx.lineTo(-this.valveWidthMM, -this.valveHeightMM);
    ctx.lineTo(this.valveWidthMM, this.valveHeightMM);
    ctx.lineTo(this.valveWidthMM, -this.valveHeightMM);
    ctx.stroke();
  }

  get ductWidthMM() {
    const conduit = this.getConnectedSidePipe("")[0];
    if (conduit) {
      const conduitCalc = this.globalStore.getOrCreateCalculation(
        conduit.entity,
      ) as DuctCalculation;
      if (conduitCalc.widthMM) {
        return conduitCalc.widthMM;
      }
      if (conduitCalc.diameterMM) {
        return conduitCalc.diameterMM;
      }
    }
    return this.valveWidthMM;
  }

  get showPhysicalDuctView() {
    return (
      this.document.uiState.ductView === "physical" &&
      this.document.uiState.drawingMode === DrawingMode.Calculations
    );
  }

  get physicalDuctScale() {
    return this.showPhysicalDuctView
      ? this.ductWidthMM / (VALVE_HEIGHT_MM * 2)
      : this.valveHeightMM / VALVE_HEIGHT_MM;
  }

  drawSmokeDamper(context: DrawingContext) {
    const ctx = context.ctx;
    ctx.lineWidth = ctx.lineWidth / this.physicalDuctScale;
    const translateScale = 150;
    const scaleAdjust = 0.92;
    const scale = this.physicalDuctScale * scaleAdjust;

    ctx.beginPath();
    ctx.translate(-scale * translateScale, -scale * translateScale);
    ctx.scale(scale, scale);
    ctx.stroke(SvgPathLoader.get(SVG_PATH.SMOKE_DAMPER)!);
  }

  drawFireDamper(context: DrawingContext) {
    const ctx = context.ctx;
    ctx.lineWidth = ctx.lineWidth / this.physicalDuctScale;
    const translateScale = 150;
    const scaleAdjust = 1;
    const scale = this.physicalDuctScale * scaleAdjust;

    ctx.beginPath();
    ctx.translate(-scale * translateScale, -scale * translateScale);
    ctx.scale(scale, scale);
    ctx.stroke(SvgPathLoader.get(SVG_PATH.FIRE_DAMPER)!);
  }

  drawVolumeControlDamper(context: DrawingContext) {
    const ctx = context.ctx;
    ctx.lineWidth = ctx.lineWidth / this.physicalDuctScale;
    const translateScale = 150;
    const scaleAdjust = 1.2;
    const scale = this.physicalDuctScale * scaleAdjust;

    ctx.beginPath();
    ctx.translate(-scale * translateScale, -scale * translateScale);
    ctx.scale(scale, scale);
    ctx.stroke(SvgPathLoader.get(SVG_PATH.VOLUME_CONTROL_DAMPER)!);
  }

  drawAttenuator(context: DrawingContext) {
    const ctx = context.ctx;
    ctx.lineWidth = ctx.lineWidth / this.physicalDuctScale;
    const translateScale = 150;
    const scaleAdjust = 0.7;
    const scale = this.physicalDuctScale * scaleAdjust;

    ctx.beginPath();
    ctx.translate(-scale * translateScale, -scale * translateScale);
    ctx.scale(scale, scale);
    ctx.stroke(SvgPathLoader.get(SVG_PATH.ATTENUATOR)!);
  }

  drawCustomValve(context: DrawingContext) {
    const ctx = context.ctx;
    const insideWidth = this.valveWidthMM * 0.7;
    const insideHeight = this.valveHeightMM * 0.7;

    ctx.beginPath();
    ctx.moveTo(-insideWidth, insideHeight);
    ctx.lineTo(insideWidth, -insideHeight);
    ctx.lineTo(insideWidth, insideHeight);
    ctx.lineTo(-insideWidth, -insideHeight);
    ctx.lineTo(-insideWidth, insideHeight);
    ctx.stroke();

    ctx.strokeRect(
      -this.valveWidthMM,
      -this.valveHeightMM,
      this.valveWidthMM * 2,
      this.valveHeightMM * 2,
    );
  }

  drawFan(context: DrawingContext) {
    const ctx = context.ctx;
    const w = this.valveWidthMM;
    const bg = 14;
    const xs = 3;
    ctx.scale(this.physicalDuctScale, this.physicalDuctScale);

    // draw the rectangle
    ctx.beginPath();
    ctx.rect(-w, -w, w * 2, w * 2);
    ctx.stroke();

    ctx.lineWidth = 5;

    // top
    ctx.beginPath();
    ctx.arc(-w / bg, -w / xs, w / 5, Math.PI / 2, -Math.PI / 2);
    ctx.closePath();
    ctx.stroke();

    // bottom
    ctx.beginPath();
    ctx.arc(w / bg, w / xs, w / 5, -Math.PI / 2, Math.PI / 2);
    ctx.closePath();
    ctx.stroke();

    // left
    ctx.beginPath();
    ctx.arc(-w / xs, w / bg, w / 5, 0, Math.PI);
    ctx.closePath();
    ctx.stroke();

    // right
    ctx.beginPath();
    ctx.arc(w / xs, -w / bg, w / 5, Math.PI, 0);
    ctx.closePath();
    ctx.stroke();

    ctx.font = "40px " + DEFAULT_FONT_NAME;
    ctx.fillTextStable("→", w / 3, -w / 3, undefined, "bottom");
  }

  drawCheckValve(context: DrawingContext) {
    const ctx = context.ctx;
    ctx.beginPath();
    ctx.moveTo(-this.valveHeightMM, -this.valveHeightMM);
    ctx.lineTo(-this.valveHeightMM, this.valveHeightMM);
    ctx.lineTo(this.valveHeightMM, 0);
    ctx.closePath();
    ctx.fill();

    ctx.beginPath();
    ctx.moveTo(this.valveHeightMM, this.valveHeightMM);
    ctx.lineTo(this.valveHeightMM, -this.valveHeightMM);
    ctx.stroke();
  }

  drawIsolationValve(context: DrawingContext) {
    const ctx = context.ctx;
    switch (this.entity.valve.catalogId) {
      case "gateValve":
        const angle = (27 * Math.PI) / 180;

        const x1 = -this.valveWidthMM / 2;
        const y1 = -this.valveHeightMM / 2;
        const x2 = -this.valveWidthMM / 4;
        const y2 = this.valveHeightMM / 4;
        const x3 = this.valveWidthMM / 4;
        const y3 = -this.valveHeightMM / 4;
        const x4 = this.valveWidthMM / 2;
        const y4 = this.valveHeightMM / 2;

        const rotateX = (x: number, y: number) =>
          x * Math.cos(angle) - y * Math.sin(angle);
        const rotateY = (x: number, y: number) =>
          x * Math.sin(angle) + y * Math.cos(angle);

        ctx.beginPath();
        ctx.moveTo(rotateX(x1, y1), rotateY(x1, y1));
        ctx.quadraticCurveTo(
          rotateX(x2, y2),
          rotateY(x2, y2),
          rotateX(0, 0),
          rotateY(0, 0),
        );
        ctx.quadraticCurveTo(
          rotateX(x3, y3),
          rotateY(x3, y3),
          rotateX(x4, y4),
          rotateY(x4, y4),
        );
        ctx.stroke();
        break;
      case "ballValve":
        break;
      case "butterflyValve":
        const ellipseCenterX = 0;
        const ellipseCenterY = 0;
        const ellipseRadiusX = this.valveWidthMM / 4;
        const ellipseRadiusY = this.valveHeightMM / 2;

        ctx.beginPath();
        ctx.ellipse(
          ellipseCenterX,
          ellipseCenterY,
          ellipseRadiusX,
          ellipseRadiusY,
          0,
          0,
          2 * Math.PI,
        );
        ctx.fillStyle = this.baseDrawnColor(context).hex;
        ctx.fill();
        break;
    }

    ctx.beginPath();
    ctx.moveTo(-this.valveWidthMM, this.valveHeightMM);
    ctx.lineTo(this.valveWidthMM, -this.valveHeightMM);
    ctx.lineTo(this.valveWidthMM, this.valveHeightMM);
    ctx.lineTo(-this.valveWidthMM, -this.valveHeightMM);

    if ((this.entity.valve as IsolationValve).isClosed) {
      ctx.closePath();
      ctx.stroke();
      ctx.fill();
    } else {
      ctx.lineTo(-this.valveWidthMM, this.valveHeightMM);
      ctx.stroke();
    }
  }

  drawBalancingValve(context: DrawingContext) {
    const ctx = context.ctx;
    ctx.beginPath();
    ctx.moveTo(-this.valveWidthMM, this.valveHeightMM);
    ctx.lineTo(this.valveWidthMM, -this.valveHeightMM);
    ctx.lineTo(this.valveWidthMM, this.valveHeightMM);
    ctx.lineTo(-this.valveWidthMM, -this.valveHeightMM);

    ctx.lineTo(-this.valveWidthMM, this.valveHeightMM);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(-this.valveWidthMM / 2, -this.valveHeightMM / 2);
    ctx.lineTo(-this.valveWidthMM / 2, (-this.valveHeightMM * 5) / 4);
    ctx.lineTo(-this.valveWidthMM / 4, (-this.valveHeightMM * 5) / 4);

    ctx.moveTo(this.valveWidthMM / 2, -this.valveHeightMM / 2);
    ctx.lineTo(this.valveWidthMM / 2, (-this.valveHeightMM * 5) / 4);
    ctx.lineTo((this.valveWidthMM * 3) / 4, (-this.valveHeightMM * 5) / 4);

    ctx.stroke();
  }

  drawPICV(context: DrawingContext) {
    const ctx = context.ctx;
    // the bow tie
    ctx.beginPath();
    ctx.moveTo(-this.valveWidthMM / 1.5, this.valveHeightMM / 2);
    ctx.lineTo(this.valveWidthMM / 1.5, -this.valveHeightMM / 2);
    ctx.lineTo(this.valveWidthMM / 1.5, this.valveHeightMM / 2);
    ctx.lineTo(-this.valveWidthMM / 1.5, -this.valveHeightMM / 2);
    ctx.lineTo(-this.valveWidthMM / 1.5, this.valveHeightMM / 2);
    ctx.stroke();

    // the rectangle
    ctx.strokeRect(
      -this.valveWidthMM / 2,
      -this.valveHeightMM * 1.3,
      this.valveWidthMM,
      this.valveHeightMM / 1.6,
    );

    // line down
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(0, -this.valveHeightMM / 1.6);
    ctx.stroke();

    // zig zag line down
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(-this.valveWidthMM / 4, this.valveHeightMM / 2);
    ctx.lineTo(this.valveWidthMM / 4, this.valveHeightMM / 1.5);
    ctx.lineTo(-this.valveWidthMM / 4, this.valveHeightMM);
    ctx.lineTo(this.valveWidthMM / 4, this.valveHeightMM * 1.2);
    ctx.stroke();
  }

  drawTRV(context: DrawingContext) {
    const ctx = context.ctx;
    ctx.beginPath();
    const TRIANGE_WIDTH = Math.sin(Math.PI / 4) * this.valveWidthMM;

    ctx.moveTo(-this.valveWidthMM, TRIANGE_WIDTH);
    ctx.lineTo(this.valveWidthMM, -TRIANGE_WIDTH);
    ctx.lineTo(this.valveWidthMM, TRIANGE_WIDTH);
    ctx.lineTo(-this.valveWidthMM, -TRIANGE_WIDTH);

    ctx.lineTo(-this.valveWidthMM, TRIANGE_WIDTH);
    ctx.stroke();

    ctx.beginPath();

    ctx.moveTo(0, 0);
    ctx.lineTo(-TRIANGE_WIDTH, this.valveWidthMM);
    ctx.lineTo(TRIANGE_WIDTH, this.valveWidthMM);
    ctx.lineTo(0, 0);

    ctx.stroke();
  }

  drawGasRegulator(context: DrawingContext) {
    const ctx = context.ctx;

    ctx.beginPath();
    ctx.moveTo(-this.valveWidthMM, this.valveHeightMM * 0.7);
    ctx.lineTo(this.valveWidthMM, -this.valveHeightMM * 0.7);
    ctx.lineTo(this.valveWidthMM, this.valveHeightMM * 0.7);
    ctx.lineTo(-this.valveWidthMM, -this.valveHeightMM * 0.7);
    ctx.lineTo(-this.valveWidthMM, this.valveHeightMM * 0.7);
    ctx.stroke();

    // Umbrella
    const currAngle = canonizeAngleRad(
      decomposeMatrix(context.vp.currToSurfaceTransform(ctx)).a,
    );
    const upsideDown = currAngle > Math.PI / 2 || currAngle < -Math.PI / 2;

    if (upsideDown) {
      ctx.moveTo(0, 0);
      ctx.lineTo(0, this.valveHeightMM);
      ctx.lineTo(-this.valveWidthMM * 0.7, this.valveHeightMM);
      ctx.bezierCurveTo(
        -this.valveWidthMM * 0.7,
        this.valveHeightMM * 1.5,
        this.valveWidthMM * 0.7,
        this.valveHeightMM * 1.5,
        this.valveWidthMM * 0.7,
        this.valveHeightMM,
      );
      ctx.lineTo(0, this.valveHeightMM);
      ctx.stroke();
    } else {
      ctx.moveTo(0, 0);
      ctx.lineTo(0, -this.valveHeightMM);
      ctx.lineTo(-this.valveWidthMM * 0.7, -this.valveHeightMM);
      ctx.bezierCurveTo(
        -this.valveWidthMM * 0.7,
        -this.valveHeightMM * 1.5,
        this.valveWidthMM * 0.7,
        -this.valveHeightMM * 1.5,
        this.valveWidthMM * 0.7,
        -this.valveHeightMM,
      );
      ctx.lineTo(0, -this.valveHeightMM);
      ctx.stroke();
    }

    // Arrow right
    ctx.strokeStyle = "rgba(50, 50, 200, 0.5)";
    ctx.fillStyle = "rgba(50, 50, 200, 0.2)";
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.moveTo(-this.valveWidthMM * 0.5, -this.valveHeightMM * 0.7);
    ctx.lineTo(this.valveWidthMM * 0.7, 0);
    ctx.lineTo(-this.valveWidthMM * 0.5, this.valveHeightMM * 0.7);
    ctx.fill();
  }

  drawReturnPump(context: DrawingContext) {
    const ctx = context.ctx;
    ctx.beginPath();
    ctx.moveTo(
      this.valveHeightMM * Math.cos((Math.PI * 3) / 4),
      this.valveHeightMM * Math.sin((Math.PI * 3) / 4),
    );
    ctx.lineTo(
      this.valveHeightMM * Math.cos((-Math.PI * 3) / 4),
      this.valveHeightMM * Math.sin((-Math.PI * 3) / 4),
    );
    ctx.lineTo(this.valveHeightMM, 0);
    ctx.closePath();
    ctx.fill();
    ctx.beginPath();
    ctx.arc(0, 0, this.valveHeightMM, 0, Math.PI * 2);
    ctx.stroke();
  }

  drawFilter(context: DrawingContext) {
    const ctx = context.ctx;
    ctx.beginPath();
    const VALVE_HEIGHT_MM = (this.valveWidthMM * 6) / 7;

    // outer box
    ctx.moveTo(this.valveWidthMM, -VALVE_HEIGHT_MM);
    ctx.lineTo(this.valveWidthMM, VALVE_HEIGHT_MM);
    ctx.lineTo(-this.valveWidthMM, VALVE_HEIGHT_MM);
    ctx.lineTo(-this.valveWidthMM, -VALVE_HEIGHT_MM);
    ctx.closePath();
    ctx.stroke();

    // mesh
    ctx.beginPath();
    ctx.moveTo((-this.valveWidthMM * 3) / 7, -VALVE_HEIGHT_MM);
    ctx.lineTo(-this.valveWidthMM, -VALVE_HEIGHT_MM / 3);
    ctx.moveTo((this.valveWidthMM * 1) / 7, -VALVE_HEIGHT_MM);
    ctx.lineTo(-this.valveWidthMM, VALVE_HEIGHT_MM / 3);
    ctx.moveTo((this.valveWidthMM * 5) / 7, -VALVE_HEIGHT_MM);
    ctx.lineTo((-this.valveWidthMM * 7) / 7, VALVE_HEIGHT_MM);

    ctx.moveTo(this.valveWidthMM, (-VALVE_HEIGHT_MM * 2) / 3);
    ctx.lineTo((-this.valveWidthMM * 3) / 7, VALVE_HEIGHT_MM);
    ctx.moveTo(this.valveWidthMM, 0);
    ctx.lineTo((this.valveWidthMM * 1) / 7, VALVE_HEIGHT_MM);
    ctx.moveTo(this.valveWidthMM, (VALVE_HEIGHT_MM * 2) / 3);
    ctx.lineTo((this.valveWidthMM * 5) / 7, VALVE_HEIGHT_MM);
    ctx.stroke();
  }

  drawPrvN(context: DrawingContext, n: number) {
    const ctx = context.ctx;
    const oldfs = ctx.fillStyle;
    ctx.fillStyle = "#ffffff";

    ctx.fillRect(
      -this.valveHeightMM * 1.3,
      -this.valveHeightMM * ((2 * n) / 2 + 0.3),
      this.valveHeightMM * 2.6,
      this.valveHeightMM * (2 * n + 0.6),
    );

    ctx.beginPath();
    ctx.rect(
      -this.valveHeightMM * 1.3,
      -this.valveHeightMM * ((2 * n) / 2 + 0.3),
      this.valveHeightMM * 2.6,
      this.valveHeightMM * (2 * n + 0.6),
    );
    ctx.stroke();

    ctx.fillStyle = oldfs;
    for (let i = 0; i < n; i++) {
      const yOffset = (-n / 2 + i + 0.5) * (this.valveWidthMM * 1.3);
      ctx.beginPath();
      ctx.moveTo(-this.valveHeightMM, -this.valveWidthMM / 2 + yOffset);
      ctx.lineTo(-this.valveHeightMM, this.valveWidthMM / 2 + yOffset);
      ctx.lineTo(this.valveHeightMM, 0 + yOffset);
      ctx.closePath();
      ctx.fill();
    }
  }

  drawRv(context: DrawingContext) {
    const ctx = context.ctx;
    ctx.beginPath();
    ctx.moveTo(-this.valveWidthMM, this.valveHeightMM);
    ctx.lineTo(this.valveWidthMM, -this.valveHeightMM);
    ctx.lineTo(this.valveWidthMM, this.valveHeightMM);
    ctx.lineTo(-this.valveWidthMM, -this.valveHeightMM);

    ctx.lineTo(-this.valveWidthMM, this.valveHeightMM);
    ctx.stroke();

    // zig zag line down
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(-this.valveWidthMM / 4, this.valveHeightMM / 2);
    ctx.lineTo(this.valveWidthMM / 4, this.valveHeightMM / 1.5);
    ctx.lineTo(-this.valveWidthMM / 4, this.valveHeightMM);
    ctx.lineTo(this.valveWidthMM / 4, this.valveHeightMM * 1.2);
    ctx.stroke();
  }

  drawPrvSingle(context: DrawingContext) {
    this.drawPrvN(context, 1);
  }

  drawPrvDouble(context: DrawingContext) {
    this.drawPrvN(context, 2);
  }

  drawPrvTriple(context: DrawingContext) {
    this.drawPrvN(context, 3);
  }

  drawRpzdSingle(context: DrawingContext) {
    const ctx = context.ctx;
    const oldfs = ctx.fillStyle;
    ctx.fillStyle = "#ffffff";

    ctx.fillRect(
      -this.valveHeightMM * 1.3,
      -this.valveHeightMM * 1.3,
      this.valveHeightMM * 2.6,
      this.valveHeightMM * 2.6,
    );

    ctx.beginPath();
    ctx.rect(
      -this.valveHeightMM * 1.3,
      -this.valveHeightMM * 1.3,
      this.valveHeightMM * 2.6,
      this.valveHeightMM * 2.6,
    );
    ctx.stroke();

    ctx.fillStyle = oldfs;
    ctx.beginPath();
    ctx.moveTo(-this.valveHeightMM, -this.valveWidthMM / 2);
    ctx.lineTo(-this.valveHeightMM, this.valveWidthMM / 2);
    ctx.lineTo(this.valveHeightMM, 0);
    ctx.closePath();
    ctx.fill();
  }

  drawWaterMeter(context: DrawingContext) {
    const ctx = context.ctx;
    ctx.beginPath();
    ctx.moveTo(-this.valveWidthMM, -this.valveHeightMM);
    ctx.lineTo(this.valveWidthMM, this.valveHeightMM);
    ctx.lineTo(-this.valveWidthMM, this.valveHeightMM);
    ctx.closePath();
    ctx.fill();

    ctx.beginPath();
    ctx.moveTo(-this.valveWidthMM, -this.valveHeightMM);
    ctx.lineTo(this.valveWidthMM, -this.valveHeightMM);
    ctx.lineTo(this.valveWidthMM, this.valveHeightMM);
    ctx.lineTo(-this.valveWidthMM, this.valveHeightMM);
    ctx.lineTo(-this.valveWidthMM, -this.valveHeightMM);
    ctx.stroke();
  }

  drawCSValve(context: DrawingContext) {
    const ctx = context.ctx;
    ctx.beginPath();
    ctx.moveTo(-this.valveWidthMM, -this.valveHeightMM);
    ctx.lineTo(-this.valveWidthMM, this.valveHeightMM);
    ctx.lineTo(this.valveWidthMM, 0);
    ctx.closePath();
    ctx.stroke();
  }

  drawStrainer(context: DrawingContext) {
    const ctx = context.ctx;
    ctx.beginPath();
    const offset = this.valveHeightMM / 2;
    ctx.moveTo(-this.valveWidthMM, -this.valveHeightMM + offset);
    ctx.lineTo(-this.valveWidthMM, 0 + offset);
    ctx.moveTo(-this.valveWidthMM, -this.valveHeightMM / 2 + offset);
    ctx.lineTo(this.valveWidthMM, -this.valveHeightMM / 2 + offset);
    ctx.moveTo(this.valveWidthMM, -this.valveHeightMM + offset);
    ctx.lineTo(this.valveWidthMM, 0 + offset);

    ctx.moveTo(-this.valveWidthMM / 2, -this.valveHeightMM / 2 + offset);
    ctx.lineTo(this.valveWidthMM / 2, this.valveHeightMM / 2 + offset);
    ctx.moveTo(this.valveWidthMM * 0.8, this.valveHeightMM * 0.2 + offset);
    ctx.lineTo(this.valveWidthMM * 0.2, this.valveHeightMM * 0.8 + offset);
    ctx.stroke();
  }

  flip() {
    const entity = this.entity;
    const connections = this.globalStore.getConnections(entity.uid);

    if (connections.length === 0) {
      // This can happen during insertion where the valve is placed on
      // nothing but 'flip' mode is enabled.
      return;
    } else if (connections.length === 1) {
      if (entity.sourceUid === connections[0]) {
        entity.sourceUid = "N/A";
      } else {
        entity.sourceUid = connections[0];
      }
    } else if (connections.length === 2) {
      if (entity.sourceUid === connections[0]) {
        entity.sourceUid = connections[1];
      } else {
        entity.sourceUid = connections[0];
      }
    } else {
      throw new Error("Directed valve is connected to too many things");
    }
  }

  inBounds(objectCoord: Coord, objectRadius?: number): boolean {
    if (!this.isActive()) {
      return false;
    }
    const dist = Math.sqrt(objectCoord.x ** 2 + objectCoord.y ** 2);
    return dist < this.valveWidthMM + (objectRadius ? objectRadius : 0);
  }

  sourceHeirs: string[] = [];
  onConnect(uid: string) {
    if (!this.globalStore.suppressSideEffects) {
      if (this.globalStore.getConnections(this.entity.uid).length === 0) {
        // this.entity.sourceUid = uid;
      } else if (
        this.globalStore.getConnections(this.entity.uid).length === 1
      ) {
        if (
          this.entity.sourceUid !==
          this.globalStore.getConnections(this.entity.uid)[0]
        ) {
          this.entity.sourceUid = uid;
        }
      } else {
        // The following error is disabled to allow intermittent connecting / disconnecting.
        // throw new Error('shouldn\'t be extending here. connections: ' +
        // JSON.stringify(this.objectStore.getConnections(this.entity.uid)) + ' connecting ' + uid);
        this.sourceHeirs.push(uid);
      }
    } else {
      this.sourceHeirs.splice(0);
    }
  }

  onDisconnect(uid: string) {
    this.sourceHeirs = this.sourceHeirs.filter((tuid) => tuid !== uid);
    if (!this.globalStore.suppressSideEffects) {
      const conns = this.globalStore.getConnections(this.entity.uid);
      if (conns.length > 2) {
        if (uid === this.entity.sourceUid) {
          if (this.sourceHeirs.length === 0) {
            this.entity.sourceUid = conns[0];
          } else {
            this.entity.sourceUid = this.sourceHeirs.splice(0, 1)[0];
          }
        }
      }
    } else {
      this.sourceHeirs.splice(0);
    }
  }

  protected refreshObjectInternal(
    _obj: DrawableEntity,
    _old?: DrawableEntity,
  ): void {
    //
  }

  getPopupContent(): EntityPopupContent[] {
    return [];
  }

  getHoverSiblings(): HoverSiblingResult[] {
    return [];
  }

  getAttachCoords(): [Coord, Coord, Coord, Coord] {
    return [
      // left
      {
        x: -this.valveWidthMM * 1.2 - this.attachmentOffset,
        y: 0,
      },
      // right
      {
        x: this.valveWidthMM * 1.2 + this.attachmentOffset,
        y: 0,
      },
      // top
      {
        x: 0,
        y: -this.valveWidthMM - this.attachmentOffset,
      },
      // bottom
      {
        x: 0,
        y: this.valveWidthMM + this.attachmentOffset,
      },
    ];
  }
}
