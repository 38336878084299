import { PipePhysicalMaterial } from "../../../config";
import { PipeSpec, UnderfloorHeatingData } from "../../types";
import {
  ActuatorData,
  BallValve,
  BlendingValveData,
  EdgeExpansionFoam,
  ManifoldData,
  PumpPack,
  UFHPumpData,
} from "../../underfloor-heating/ufh-types";

export type UFHPipeCatalog = Record<
  PipePhysicalMaterial,
  Record<number, PipeSpec>
>;

// Honestly seems really suss but ok
export const GenericBendKValues: { [key: number]: number } = {
  100: 1.2,
  150: 1.0,
  200: 0.8,
  250: 0.5,
  300: 0.3,
};

export const GenericUFH: UnderfloorHeatingData = {
  "Type A - 0.1 TOG - plastic sheet, ceramic tiles, stone tiles": {
    pexSdr74: {
      16: {
        15: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 98, floorTempC: 23.8 },
            { pipeSpacingMM: 150, outputWperM2: 84.3, floorTempC: 22.7 },
            { pipeSpacingMM: 200, outputWperM2: 72.7, floorTempC: 21.7 },
            { pipeSpacingMM: 250, outputWperM2: 63, floorTempC: 20.9 },
            { pipeSpacingMM: 300, outputWperM2: 54.4, floorTempC: 20.2 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 131.2, floorTempC: 26.5 },
            { pipeSpacingMM: 150, outputWperM2: 112.8, floorTempC: 25 },
            { pipeSpacingMM: 200, outputWperM2: 97.3, floorTempC: 23.8 },
            { pipeSpacingMM: 250, outputWperM2: 84.4, floorTempC: 22 },
            { pipeSpacingMM: 300, outputWperM2: 72.9, floorTempC: 21.8 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 164.3, floorTempC: 29.1 },
            { pipeSpacingMM: 150, outputWperM2: 141.3, floorTempC: 27.3 },
            { pipeSpacingMM: 200, outputWperM2: 121.9, floorTempC: 25.8 },
            { pipeSpacingMM: 250, outputWperM2: 105.7, floorTempC: 24.5 },
            { pipeSpacingMM: 300, outputWperM2: 91.2, floorTempC: 23.3 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 197.4, floorTempC: 31.7 },
            { pipeSpacingMM: 150, outputWperM2: 169.7, floorTempC: 29.6 },
            { pipeSpacingMM: 200, outputWperM2: 146.4, floorTempC: 27.7 },
            { pipeSpacingMM: 250, outputWperM2: 126.9, floorTempC: 26.2 },
            { pipeSpacingMM: 300, outputWperM2: 109.6, floorTempC: 24.8 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 230.5, floorTempC: 34.2 },
            { pipeSpacingMM: 150, outputWperM2: 198.1, floorTempC: 31.8 },
            { pipeSpacingMM: 200, outputWperM2: 170.9, floorTempC: 29.6 },
            { pipeSpacingMM: 250, outputWperM2: 148.2, floorTempC: 27.9 },
            { pipeSpacingMM: 300, outputWperM2: 128, floorTempC: 26.3 },
          ],
        },
        18: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 78, floorTempC: 25.2 },
            { pipeSpacingMM: 150, outputWperM2: 67, floorTempC: 24.3 },
            { pipeSpacingMM: 200, outputWperM2: 57.8, floorTempC: 23.5 },
            { pipeSpacingMM: 250, outputWperM2: 50.1, floorTempC: 22.8 },
            { pipeSpacingMM: 300, outputWperM2: 43.3, floorTempC: 22.2 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 111.3, floorTempC: 27.9 },
            { pipeSpacingMM: 150, outputWperM2: 95.7, floorTempC: 26.6 },
            { pipeSpacingMM: 200, outputWperM2: 82.6, floorTempC: 25.6 },
            { pipeSpacingMM: 250, outputWperM2: 71.6, floorTempC: 24.6 },
            { pipeSpacingMM: 300, outputWperM2: 61.8, floorTempC: 23.8 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 144.5, floorTempC: 30.6 },
            { pipeSpacingMM: 150, outputWperM2: 124.2, floorTempC: 29 },
            { pipeSpacingMM: 200, outputWperM2: 107.2, floorTempC: 27.6 },
            { pipeSpacingMM: 250, outputWperM2: 92.9, floorTempC: 26.4 },
            { pipeSpacingMM: 300, outputWperM2: 80.2, floorTempC: 25.4 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 177.6, floorTempC: 33.2 },
            { pipeSpacingMM: 150, outputWperM2: 152.7, floorTempC: 31.2 },
            { pipeSpacingMM: 200, outputWperM2: 131.7, floorTempC: 29.6 },
            { pipeSpacingMM: 250, outputWperM2: 114.2, floorTempC: 28.2 },
            { pipeSpacingMM: 300, outputWperM2: 98.6, floorTempC: 26.9 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 210.6, floorTempC: 35.7 },
            { pipeSpacingMM: 150, outputWperM2: 181.1, floorTempC: 33.4 },
            { pipeSpacingMM: 200, outputWperM2: 156.2, floorTempC: 31.5 },
            { pipeSpacingMM: 250, outputWperM2: 135.4, floorTempC: 29.9 },
            { pipeSpacingMM: 300, outputWperM2: 116.9, floorTempC: 28.4 },
          ],
        },
        20: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 64.6, floorTempC: 26 },
            { pipeSpacingMM: 150, outputWperM2: 55.5, floorTempC: 25.3 },
            { pipeSpacingMM: 200, outputWperM2: 47.9, floorTempC: 24.6 },
            { pipeSpacingMM: 250, outputWperM2: 41.5, floorTempC: 24 },
            { pipeSpacingMM: 300, outputWperM2: 35.8, floorTempC: 23.5 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 98, floorTempC: 28.8 },
            { pipeSpacingMM: 150, outputWperM2: 84.3, floorTempC: 27.7 },
            { pipeSpacingMM: 200, outputWperM2: 72.7, floorTempC: 26.7 },
            { pipeSpacingMM: 250, outputWperM2: 63, floorTempC: 25.9 },
            { pipeSpacingMM: 300, outputWperM2: 54.4, floorTempC: 25.2 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 131.2, floorTempC: 31.5 },
            { pipeSpacingMM: 150, outputWperM2: 112.8, floorTempC: 30 },
            { pipeSpacingMM: 200, outputWperM2: 97.3, floorTempC: 28.8 },
            { pipeSpacingMM: 250, outputWperM2: 84.4, floorTempC: 27.7 },
            { pipeSpacingMM: 300, outputWperM2: 72.9, floorTempC: 26.8 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 164.3, floorTempC: 34.1 },
            { pipeSpacingMM: 150, outputWperM2: 141.3, floorTempC: 32.3 },
            { pipeSpacingMM: 200, outputWperM2: 121.9, floorTempC: 30.8 },
            { pipeSpacingMM: 250, outputWperM2: 105.7, floorTempC: 29.5 },
            { pipeSpacingMM: 300, outputWperM2: 91.2, floorTempC: 28.3 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 197.4, floorTempC: 36.7 },
            { pipeSpacingMM: 150, outputWperM2: 169.7, floorTempC: 34.6 },
            { pipeSpacingMM: 200, outputWperM2: 146.4, floorTempC: 32.7 },
            { pipeSpacingMM: 250, outputWperM2: 126.9, floorTempC: 31.2 },
            { pipeSpacingMM: 300, outputWperM2: 109.6, floorTempC: 29.8 },
          ],
        },
        22: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 51, floorTempC: 26.9 },
            { pipeSpacingMM: 150, outputWperM2: 43.8, floorTempC: 26.2 },
            { pipeSpacingMM: 200, outputWperM2: 37.8, floorTempC: 25.7 },
            { pipeSpacingMM: 250, outputWperM2: 32.8, floorTempC: 25.3 },
            { pipeSpacingMM: 300, outputWperM2: 28.3, floorTempC: 24.9 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 84.7, floorTempC: 29.7 },
            { pipeSpacingMM: 150, outputWperM2: 72.8, floorTempC: 28.7 },
            { pipeSpacingMM: 200, outputWperM2: 62.8, floorTempC: 27.9 },
            { pipeSpacingMM: 250, outputWperM2: 54.4, floorTempC: 27.2 },
            { pipeSpacingMM: 300, outputWperM2: 47, floorTempC: 26.5 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 118, floorTempC: 32.5 },
            { pipeSpacingMM: 150, outputWperM2: 101.4, floorTempC: 31.1 },
            { pipeSpacingMM: 200, outputWperM2: 87.5, floorTempC: 30 },
            { pipeSpacingMM: 250, outputWperM2: 75.8, floorTempC: 29 },
            { pipeSpacingMM: 300, outputWperM2: 65.5, floorTempC: 28.1 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 151.1, floorTempC: 35.1 },
            { pipeSpacingMM: 150, outputWperM2: 129.9, floorTempC: 33.4 },
            { pipeSpacingMM: 200, outputWperM2: 112.1, floorTempC: 32 },
            { pipeSpacingMM: 250, outputWperM2: 97.2, floorTempC: 30.8 },
            { pipeSpacingMM: 300, outputWperM2: 83.9, floorTempC: 29.7 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 184.2, floorTempC: 37.7 },
            { pipeSpacingMM: 150, outputWperM2: 158.3, floorTempC: 35.7 },
            { pipeSpacingMM: 200, outputWperM2: 136.6, floorTempC: 33 },
            { pipeSpacingMM: 250, outputWperM2: 118.4, floorTempC: 32.5 },
            { pipeSpacingMM: 300, outputWperM2: 102.3, floorTempC: 31.2 },
          ],
        },
        24: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 37.2, floorTempC: 27.7 },
            { pipeSpacingMM: 150, outputWperM2: 32, floorTempC: 27.2 },
            { pipeSpacingMM: 200, outputWperM2: 27.6, floorTempC: 26.8 },
            { pipeSpacingMM: 250, outputWperM2: 23.9, floorTempC: 26.4 },
            { pipeSpacingMM: 300, outputWperM2: 20.6, floorTempC: 26.1 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 71.3, floorTempC: 30.6 },
            { pipeSpacingMM: 150, outputWperM2: 61.3, floorTempC: 29.8 },
            { pipeSpacingMM: 200, outputWperM2: 52.9, floorTempC: 29 },
            { pipeSpacingMM: 250, outputWperM2: 45.8, floorTempC: 28.4 },
            { pipeSpacingMM: 300, outputWperM2: 39.6, floorTempC: 27.9 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 104.7, floorTempC: 33.4 },
            { pipeSpacingMM: 150, outputWperM2: 90, floorTempC: 32.2 },
            { pipeSpacingMM: 200, outputWperM2: 77.6, floorTempC: 31.1 },
            { pipeSpacingMM: 250, outputWperM2: 67.3, floorTempC: 30.3 },
            { pipeSpacingMM: 300, outputWperM2: 58.1, floorTempC: 29.5 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 137.9, floorTempC: 36.1 },
            { pipeSpacingMM: 150, outputWperM2: 118.5, floorTempC: 34.5 },
            { pipeSpacingMM: 200, outputWperM2: 102.2, floorTempC: 33.2 },
            { pipeSpacingMM: 250, outputWperM2: 88.6, floorTempC: 32.1 },
            { pipeSpacingMM: 300, outputWperM2: 76.5, floorTempC: 31.1 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 171, floorTempC: 38.7 },
            { pipeSpacingMM: 150, outputWperM2: 147, floorTempC: 36.8 },
            { pipeSpacingMM: 200, outputWperM2: 126.8, floorTempC: 35.2 },
            { pipeSpacingMM: 250, outputWperM2: 109.9, floorTempC: 33.8 },
            { pipeSpacingMM: 300, outputWperM2: 94.9, floorTempC: 32.6 },
          ],
        },
      },
    },
    pertAl: {
      16: {
        15: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 98, floorTempC: 23.8 },
            { pipeSpacingMM: 150, outputWperM2: 84.3, floorTempC: 22.7 },
            { pipeSpacingMM: 200, outputWperM2: 72.7, floorTempC: 21.7 },
            { pipeSpacingMM: 250, outputWperM2: 63, floorTempC: 20.9 },
            { pipeSpacingMM: 300, outputWperM2: 54.4, floorTempC: 20.2 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 131.2, floorTempC: 26.5 },
            { pipeSpacingMM: 150, outputWperM2: 112.8, floorTempC: 25 },
            { pipeSpacingMM: 200, outputWperM2: 97.3, floorTempC: 23.8 },
            { pipeSpacingMM: 250, outputWperM2: 84.4, floorTempC: 22 },
            { pipeSpacingMM: 300, outputWperM2: 72.9, floorTempC: 21.8 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 164.3, floorTempC: 29.1 },
            { pipeSpacingMM: 150, outputWperM2: 141.3, floorTempC: 27.3 },
            { pipeSpacingMM: 200, outputWperM2: 121.9, floorTempC: 25.8 },
            { pipeSpacingMM: 250, outputWperM2: 105.7, floorTempC: 24.5 },
            { pipeSpacingMM: 300, outputWperM2: 91.2, floorTempC: 23.3 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 197.4, floorTempC: 31.7 },
            { pipeSpacingMM: 150, outputWperM2: 169.7, floorTempC: 29.6 },
            { pipeSpacingMM: 200, outputWperM2: 146.4, floorTempC: 27.7 },
            { pipeSpacingMM: 250, outputWperM2: 126.9, floorTempC: 26.2 },
            { pipeSpacingMM: 300, outputWperM2: 109.6, floorTempC: 24.8 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 230.5, floorTempC: 34.2 },
            { pipeSpacingMM: 150, outputWperM2: 198.1, floorTempC: 31.8 },
            { pipeSpacingMM: 200, outputWperM2: 170.9, floorTempC: 29.6 },
            { pipeSpacingMM: 250, outputWperM2: 148.2, floorTempC: 27.9 },
            { pipeSpacingMM: 300, outputWperM2: 128, floorTempC: 26.3 },
          ],
        },
        18: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 78, floorTempC: 25.2 },
            { pipeSpacingMM: 150, outputWperM2: 67, floorTempC: 24.3 },
            { pipeSpacingMM: 200, outputWperM2: 57.8, floorTempC: 23.5 },
            { pipeSpacingMM: 250, outputWperM2: 50.1, floorTempC: 22.8 },
            { pipeSpacingMM: 300, outputWperM2: 43.3, floorTempC: 22.2 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 111.3, floorTempC: 27.9 },
            { pipeSpacingMM: 150, outputWperM2: 95.7, floorTempC: 26.6 },
            { pipeSpacingMM: 200, outputWperM2: 82.6, floorTempC: 25.6 },
            { pipeSpacingMM: 250, outputWperM2: 71.6, floorTempC: 24.6 },
            { pipeSpacingMM: 300, outputWperM2: 61.8, floorTempC: 23.8 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 144.5, floorTempC: 30.6 },
            { pipeSpacingMM: 150, outputWperM2: 124.2, floorTempC: 29 },
            { pipeSpacingMM: 200, outputWperM2: 107.2, floorTempC: 27.6 },
            { pipeSpacingMM: 250, outputWperM2: 92.9, floorTempC: 26.4 },
            { pipeSpacingMM: 300, outputWperM2: 80.2, floorTempC: 25.4 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 177.6, floorTempC: 33.2 },
            { pipeSpacingMM: 150, outputWperM2: 152.7, floorTempC: 31.2 },
            { pipeSpacingMM: 200, outputWperM2: 131.7, floorTempC: 29.6 },
            { pipeSpacingMM: 250, outputWperM2: 114.2, floorTempC: 28.2 },
            { pipeSpacingMM: 300, outputWperM2: 98.6, floorTempC: 26.9 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 210.6, floorTempC: 35.7 },
            { pipeSpacingMM: 150, outputWperM2: 181.1, floorTempC: 33.4 },
            { pipeSpacingMM: 200, outputWperM2: 156.2, floorTempC: 31.5 },
            { pipeSpacingMM: 250, outputWperM2: 135.4, floorTempC: 29.9 },
            { pipeSpacingMM: 300, outputWperM2: 116.9, floorTempC: 28.4 },
          ],
        },
        20: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 64.6, floorTempC: 26 },
            { pipeSpacingMM: 150, outputWperM2: 55.5, floorTempC: 25.3 },
            { pipeSpacingMM: 200, outputWperM2: 47.9, floorTempC: 24.6 },
            { pipeSpacingMM: 250, outputWperM2: 41.5, floorTempC: 24 },
            { pipeSpacingMM: 300, outputWperM2: 35.8, floorTempC: 23.5 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 98, floorTempC: 28.8 },
            { pipeSpacingMM: 150, outputWperM2: 84.3, floorTempC: 27.7 },
            { pipeSpacingMM: 200, outputWperM2: 72.7, floorTempC: 26.7 },
            { pipeSpacingMM: 250, outputWperM2: 63, floorTempC: 25.9 },
            { pipeSpacingMM: 300, outputWperM2: 54.4, floorTempC: 25.2 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 131.2, floorTempC: 31.5 },
            { pipeSpacingMM: 150, outputWperM2: 112.8, floorTempC: 30 },
            { pipeSpacingMM: 200, outputWperM2: 97.3, floorTempC: 28.8 },
            { pipeSpacingMM: 250, outputWperM2: 84.4, floorTempC: 27.7 },
            { pipeSpacingMM: 300, outputWperM2: 72.9, floorTempC: 26.8 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 164.3, floorTempC: 34.1 },
            { pipeSpacingMM: 150, outputWperM2: 141.3, floorTempC: 32.3 },
            { pipeSpacingMM: 200, outputWperM2: 121.9, floorTempC: 30.8 },
            { pipeSpacingMM: 250, outputWperM2: 105.7, floorTempC: 29.5 },
            { pipeSpacingMM: 300, outputWperM2: 91.2, floorTempC: 28.3 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 197.4, floorTempC: 36.7 },
            { pipeSpacingMM: 150, outputWperM2: 169.7, floorTempC: 34.6 },
            { pipeSpacingMM: 200, outputWperM2: 146.4, floorTempC: 32.7 },
            { pipeSpacingMM: 250, outputWperM2: 126.9, floorTempC: 31.2 },
            { pipeSpacingMM: 300, outputWperM2: 109.6, floorTempC: 29.8 },
          ],
        },
        22: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 51, floorTempC: 26.9 },
            { pipeSpacingMM: 150, outputWperM2: 43.8, floorTempC: 26.2 },
            { pipeSpacingMM: 200, outputWperM2: 37.8, floorTempC: 25.7 },
            { pipeSpacingMM: 250, outputWperM2: 32.8, floorTempC: 25.3 },
            { pipeSpacingMM: 300, outputWperM2: 28.3, floorTempC: 24.9 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 84.7, floorTempC: 29.7 },
            { pipeSpacingMM: 150, outputWperM2: 72.8, floorTempC: 28.7 },
            { pipeSpacingMM: 200, outputWperM2: 62.8, floorTempC: 27.9 },
            { pipeSpacingMM: 250, outputWperM2: 54.4, floorTempC: 27.2 },
            { pipeSpacingMM: 300, outputWperM2: 47, floorTempC: 26.5 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 118, floorTempC: 32.5 },
            { pipeSpacingMM: 150, outputWperM2: 101.4, floorTempC: 31.1 },
            { pipeSpacingMM: 200, outputWperM2: 87.5, floorTempC: 30 },
            { pipeSpacingMM: 250, outputWperM2: 75.8, floorTempC: 29 },
            { pipeSpacingMM: 300, outputWperM2: 65.5, floorTempC: 28.1 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 151.1, floorTempC: 35.1 },
            { pipeSpacingMM: 150, outputWperM2: 129.9, floorTempC: 33.4 },
            { pipeSpacingMM: 200, outputWperM2: 112.1, floorTempC: 32 },
            { pipeSpacingMM: 250, outputWperM2: 97.2, floorTempC: 30.8 },
            { pipeSpacingMM: 300, outputWperM2: 83.9, floorTempC: 29.7 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 184.2, floorTempC: 37.7 },
            { pipeSpacingMM: 150, outputWperM2: 158.3, floorTempC: 35.7 },
            { pipeSpacingMM: 200, outputWperM2: 136.6, floorTempC: 33 },
            { pipeSpacingMM: 250, outputWperM2: 118.4, floorTempC: 32.5 },
            { pipeSpacingMM: 300, outputWperM2: 102.3, floorTempC: 31.2 },
          ],
        },
        24: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 37.2, floorTempC: 27.7 },
            { pipeSpacingMM: 150, outputWperM2: 32, floorTempC: 27.2 },
            { pipeSpacingMM: 200, outputWperM2: 27.6, floorTempC: 26.8 },
            { pipeSpacingMM: 250, outputWperM2: 23.9, floorTempC: 26.4 },
            { pipeSpacingMM: 300, outputWperM2: 20.6, floorTempC: 26.1 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 71.3, floorTempC: 30.6 },
            { pipeSpacingMM: 150, outputWperM2: 61.3, floorTempC: 29.8 },
            { pipeSpacingMM: 200, outputWperM2: 52.9, floorTempC: 29 },
            { pipeSpacingMM: 250, outputWperM2: 45.8, floorTempC: 28.4 },
            { pipeSpacingMM: 300, outputWperM2: 39.6, floorTempC: 27.9 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 104.7, floorTempC: 33.4 },
            { pipeSpacingMM: 150, outputWperM2: 90, floorTempC: 32.2 },
            { pipeSpacingMM: 200, outputWperM2: 77.6, floorTempC: 31.1 },
            { pipeSpacingMM: 250, outputWperM2: 67.3, floorTempC: 30.3 },
            { pipeSpacingMM: 300, outputWperM2: 58.1, floorTempC: 29.5 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 137.9, floorTempC: 36.1 },
            { pipeSpacingMM: 150, outputWperM2: 118.5, floorTempC: 34.5 },
            { pipeSpacingMM: 200, outputWperM2: 102.2, floorTempC: 33.2 },
            { pipeSpacingMM: 250, outputWperM2: 88.6, floorTempC: 32.1 },
            { pipeSpacingMM: 300, outputWperM2: 76.5, floorTempC: 31.1 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 171, floorTempC: 38.7 },
            { pipeSpacingMM: 150, outputWperM2: 147, floorTempC: 36.8 },
            { pipeSpacingMM: 200, outputWperM2: 126.8, floorTempC: 35.2 },
            { pipeSpacingMM: 250, outputWperM2: 109.9, floorTempC: 33.8 },
            { pipeSpacingMM: 300, outputWperM2: 94.9, floorTempC: 32.6 },
          ],
        },
      },
    },
    pertEvoh: {
      16: {
        15: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 98, floorTempC: 23.8 },
            { pipeSpacingMM: 150, outputWperM2: 84.3, floorTempC: 22.7 },
            { pipeSpacingMM: 200, outputWperM2: 72.7, floorTempC: 21.7 },
            { pipeSpacingMM: 250, outputWperM2: 63, floorTempC: 20.9 },
            { pipeSpacingMM: 300, outputWperM2: 54.4, floorTempC: 20.2 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 131.2, floorTempC: 26.5 },
            { pipeSpacingMM: 150, outputWperM2: 112.8, floorTempC: 25 },
            { pipeSpacingMM: 200, outputWperM2: 97.3, floorTempC: 23.8 },
            { pipeSpacingMM: 250, outputWperM2: 84.4, floorTempC: 22 },
            { pipeSpacingMM: 300, outputWperM2: 72.9, floorTempC: 21.8 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 164.3, floorTempC: 29.1 },
            { pipeSpacingMM: 150, outputWperM2: 141.3, floorTempC: 27.3 },
            { pipeSpacingMM: 200, outputWperM2: 121.9, floorTempC: 25.8 },
            { pipeSpacingMM: 250, outputWperM2: 105.7, floorTempC: 24.5 },
            { pipeSpacingMM: 300, outputWperM2: 91.2, floorTempC: 23.3 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 197.4, floorTempC: 31.7 },
            { pipeSpacingMM: 150, outputWperM2: 169.7, floorTempC: 29.6 },
            { pipeSpacingMM: 200, outputWperM2: 146.4, floorTempC: 27.7 },
            { pipeSpacingMM: 250, outputWperM2: 126.9, floorTempC: 26.2 },
            { pipeSpacingMM: 300, outputWperM2: 109.6, floorTempC: 24.8 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 230.5, floorTempC: 34.2 },
            { pipeSpacingMM: 150, outputWperM2: 198.1, floorTempC: 31.8 },
            { pipeSpacingMM: 200, outputWperM2: 170.9, floorTempC: 29.6 },
            { pipeSpacingMM: 250, outputWperM2: 148.2, floorTempC: 27.9 },
            { pipeSpacingMM: 300, outputWperM2: 128, floorTempC: 26.3 },
          ],
        },
        18: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 78, floorTempC: 25.2 },
            { pipeSpacingMM: 150, outputWperM2: 67, floorTempC: 24.3 },
            { pipeSpacingMM: 200, outputWperM2: 57.8, floorTempC: 23.5 },
            { pipeSpacingMM: 250, outputWperM2: 50.1, floorTempC: 22.8 },
            { pipeSpacingMM: 300, outputWperM2: 43.3, floorTempC: 22.2 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 111.3, floorTempC: 27.9 },
            { pipeSpacingMM: 150, outputWperM2: 95.7, floorTempC: 26.6 },
            { pipeSpacingMM: 200, outputWperM2: 82.6, floorTempC: 25.6 },
            { pipeSpacingMM: 250, outputWperM2: 71.6, floorTempC: 24.6 },
            { pipeSpacingMM: 300, outputWperM2: 61.8, floorTempC: 23.8 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 144.5, floorTempC: 30.6 },
            { pipeSpacingMM: 150, outputWperM2: 124.2, floorTempC: 29 },
            { pipeSpacingMM: 200, outputWperM2: 107.2, floorTempC: 27.6 },
            { pipeSpacingMM: 250, outputWperM2: 92.9, floorTempC: 26.4 },
            { pipeSpacingMM: 300, outputWperM2: 80.2, floorTempC: 25.4 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 177.6, floorTempC: 33.2 },
            { pipeSpacingMM: 150, outputWperM2: 152.7, floorTempC: 31.2 },
            { pipeSpacingMM: 200, outputWperM2: 131.7, floorTempC: 29.6 },
            { pipeSpacingMM: 250, outputWperM2: 114.2, floorTempC: 28.2 },
            { pipeSpacingMM: 300, outputWperM2: 98.6, floorTempC: 26.9 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 210.6, floorTempC: 35.7 },
            { pipeSpacingMM: 150, outputWperM2: 181.1, floorTempC: 33.4 },
            { pipeSpacingMM: 200, outputWperM2: 156.2, floorTempC: 31.5 },
            { pipeSpacingMM: 250, outputWperM2: 135.4, floorTempC: 29.9 },
            { pipeSpacingMM: 300, outputWperM2: 116.9, floorTempC: 28.4 },
          ],
        },
        20: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 64.6, floorTempC: 26 },
            { pipeSpacingMM: 150, outputWperM2: 55.5, floorTempC: 25.3 },
            { pipeSpacingMM: 200, outputWperM2: 47.9, floorTempC: 24.6 },
            { pipeSpacingMM: 250, outputWperM2: 41.5, floorTempC: 24 },
            { pipeSpacingMM: 300, outputWperM2: 35.8, floorTempC: 23.5 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 98, floorTempC: 28.8 },
            { pipeSpacingMM: 150, outputWperM2: 84.3, floorTempC: 27.7 },
            { pipeSpacingMM: 200, outputWperM2: 72.7, floorTempC: 26.7 },
            { pipeSpacingMM: 250, outputWperM2: 63, floorTempC: 25.9 },
            { pipeSpacingMM: 300, outputWperM2: 54.4, floorTempC: 25.2 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 131.2, floorTempC: 31.5 },
            { pipeSpacingMM: 150, outputWperM2: 112.8, floorTempC: 30 },
            { pipeSpacingMM: 200, outputWperM2: 97.3, floorTempC: 28.8 },
            { pipeSpacingMM: 250, outputWperM2: 84.4, floorTempC: 27.7 },
            { pipeSpacingMM: 300, outputWperM2: 72.9, floorTempC: 26.8 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 164.3, floorTempC: 34.1 },
            { pipeSpacingMM: 150, outputWperM2: 141.3, floorTempC: 32.3 },
            { pipeSpacingMM: 200, outputWperM2: 121.9, floorTempC: 30.8 },
            { pipeSpacingMM: 250, outputWperM2: 105.7, floorTempC: 29.5 },
            { pipeSpacingMM: 300, outputWperM2: 91.2, floorTempC: 28.3 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 197.4, floorTempC: 36.7 },
            { pipeSpacingMM: 150, outputWperM2: 169.7, floorTempC: 34.6 },
            { pipeSpacingMM: 200, outputWperM2: 146.4, floorTempC: 32.7 },
            { pipeSpacingMM: 250, outputWperM2: 126.9, floorTempC: 31.2 },
            { pipeSpacingMM: 300, outputWperM2: 109.6, floorTempC: 29.8 },
          ],
        },
        22: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 51, floorTempC: 26.9 },
            { pipeSpacingMM: 150, outputWperM2: 43.8, floorTempC: 26.2 },
            { pipeSpacingMM: 200, outputWperM2: 37.8, floorTempC: 25.7 },
            { pipeSpacingMM: 250, outputWperM2: 32.8, floorTempC: 25.3 },
            { pipeSpacingMM: 300, outputWperM2: 28.3, floorTempC: 24.9 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 84.7, floorTempC: 29.7 },
            { pipeSpacingMM: 150, outputWperM2: 72.8, floorTempC: 28.7 },
            { pipeSpacingMM: 200, outputWperM2: 62.8, floorTempC: 27.9 },
            { pipeSpacingMM: 250, outputWperM2: 54.4, floorTempC: 27.2 },
            { pipeSpacingMM: 300, outputWperM2: 47, floorTempC: 26.5 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 118, floorTempC: 32.5 },
            { pipeSpacingMM: 150, outputWperM2: 101.4, floorTempC: 31.1 },
            { pipeSpacingMM: 200, outputWperM2: 87.5, floorTempC: 30 },
            { pipeSpacingMM: 250, outputWperM2: 75.8, floorTempC: 29 },
            { pipeSpacingMM: 300, outputWperM2: 65.5, floorTempC: 28.1 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 151.1, floorTempC: 35.1 },
            { pipeSpacingMM: 150, outputWperM2: 129.9, floorTempC: 33.4 },
            { pipeSpacingMM: 200, outputWperM2: 112.1, floorTempC: 32 },
            { pipeSpacingMM: 250, outputWperM2: 97.2, floorTempC: 30.8 },
            { pipeSpacingMM: 300, outputWperM2: 83.9, floorTempC: 29.7 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 184.2, floorTempC: 37.7 },
            { pipeSpacingMM: 150, outputWperM2: 158.3, floorTempC: 35.7 },
            { pipeSpacingMM: 200, outputWperM2: 136.6, floorTempC: 33 },
            { pipeSpacingMM: 250, outputWperM2: 118.4, floorTempC: 32.5 },
            { pipeSpacingMM: 300, outputWperM2: 102.3, floorTempC: 31.2 },
          ],
        },
        24: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 37.2, floorTempC: 27.7 },
            { pipeSpacingMM: 150, outputWperM2: 32, floorTempC: 27.2 },
            { pipeSpacingMM: 200, outputWperM2: 27.6, floorTempC: 26.8 },
            { pipeSpacingMM: 250, outputWperM2: 23.9, floorTempC: 26.4 },
            { pipeSpacingMM: 300, outputWperM2: 20.6, floorTempC: 26.1 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 71.3, floorTempC: 30.6 },
            { pipeSpacingMM: 150, outputWperM2: 61.3, floorTempC: 29.8 },
            { pipeSpacingMM: 200, outputWperM2: 52.9, floorTempC: 29 },
            { pipeSpacingMM: 250, outputWperM2: 45.8, floorTempC: 28.4 },
            { pipeSpacingMM: 300, outputWperM2: 39.6, floorTempC: 27.9 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 104.7, floorTempC: 33.4 },
            { pipeSpacingMM: 150, outputWperM2: 90, floorTempC: 32.2 },
            { pipeSpacingMM: 200, outputWperM2: 77.6, floorTempC: 31.1 },
            { pipeSpacingMM: 250, outputWperM2: 67.3, floorTempC: 30.3 },
            { pipeSpacingMM: 300, outputWperM2: 58.1, floorTempC: 29.5 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 137.9, floorTempC: 36.1 },
            { pipeSpacingMM: 150, outputWperM2: 118.5, floorTempC: 34.5 },
            { pipeSpacingMM: 200, outputWperM2: 102.2, floorTempC: 33.2 },
            { pipeSpacingMM: 250, outputWperM2: 88.6, floorTempC: 32.1 },
            { pipeSpacingMM: 300, outputWperM2: 76.5, floorTempC: 31.1 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 171, floorTempC: 38.7 },
            { pipeSpacingMM: 150, outputWperM2: 147, floorTempC: 36.8 },
            { pipeSpacingMM: 200, outputWperM2: 126.8, floorTempC: 35.2 },
            { pipeSpacingMM: 250, outputWperM2: 109.9, floorTempC: 33.8 },
            { pipeSpacingMM: 300, outputWperM2: 94.9, floorTempC: 32.6 },
          ],
        },
      },
    },
  },
  "Type A - 0.5 TOG - parquet, cushioned linoleum, 75mm stone flooring": {
    pexSdr74: {
      16: {
        15: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 70.3, floorTempC: 21.5 },
            { pipeSpacingMM: 150, outputWperM2: 62.5, floorTempC: 20.9 },
            { pipeSpacingMM: 200, outputWperM2: 55.1, floorTempC: 20.2 },
            { pipeSpacingMM: 250, outputWperM2: 49.8, floorTempC: 19.8 },
            { pipeSpacingMM: 300, outputWperM2: 44, floorTempC: 19.3 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 94.1, floorTempC: 23.5 },
            { pipeSpacingMM: 150, outputWperM2: 83.7, floorTempC: 22.7 },
            { pipeSpacingMM: 200, outputWperM2: 73.8, floorTempC: 21.8 },
            { pipeSpacingMM: 250, outputWperM2: 66.6, floorTempC: 21.2 },
            { pipeSpacingMM: 300, outputWperM2: 58.9, floorTempC: 20.6 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 117, floorTempC: 25.5 },
            { pipeSpacingMM: 150, outputWperM2: 104.8, floorTempC: 24.4 },
            { pipeSpacingMM: 200, outputWperM2: 92.5, floorTempC: 23.4 },
            { pipeSpacingMM: 250, outputWperM2: 83.4, floorTempC: 22.6 },
            { pipeSpacingMM: 300, outputWperM2: 73.8, floorTempC: 21.8 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 141, floorTempC: 27.3 },
            { pipeSpacingMM: 150, outputWperM2: 125.9, floorTempC: 26.1 },
            { pipeSpacingMM: 200, outputWperM2: 111.1, floorTempC: 24.9 },
            { pipeSpacingMM: 250, outputWperM2: 100.2, floorTempC: 24 },
            { pipeSpacingMM: 300, outputWperM2: 88.6, floorTempC: 23.1 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 165, floorTempC: 29.2 },
            { pipeSpacingMM: 150, outputWperM2: 147, floorTempC: 27.8 },
            { pipeSpacingMM: 200, outputWperM2: 129.7, floorTempC: 26.4 },
            { pipeSpacingMM: 250, outputWperM2: 117, floorTempC: 25.4 },
            { pipeSpacingMM: 300, outputWperM2: 103.6, floorTempC: 24.3 },
          ],
        },
        18: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 55.9, floorTempC: 23.3 },
            { pipeSpacingMM: 150, outputWperM2: 49.8, floorTempC: 22.8 },
            { pipeSpacingMM: 200, outputWperM2: 43.9, floorTempC: 22.3 },
            { pipeSpacingMM: 250, outputWperM2: 39.6, floorTempC: 21.9 },
            { pipeSpacingMM: 300, outputWperM2: 35, floorTempC: 21.5 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 79.8, floorTempC: 25.3 },
            { pipeSpacingMM: 150, outputWperM2: 71, floorTempC: 24.6 },
            { pipeSpacingMM: 200, outputWperM2: 62.6, floorTempC: 23.9 },
            { pipeSpacingMM: 250, outputWperM2: 56.5, floorTempC: 23.4 },
            { pipeSpacingMM: 300, outputWperM2: 50, floorTempC: 22.8 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 103.6, floorTempC: 27.3 },
            { pipeSpacingMM: 150, outputWperM2: 92.2, floorTempC: 26.4 },
            { pipeSpacingMM: 200, outputWperM2: 81.3, floorTempC: 25.5 },
            { pipeSpacingMM: 250, outputWperM2: 73.4, floorTempC: 24.8 },
            { pipeSpacingMM: 300, outputWperM2: 64.9, floorTempC: 24.1 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 127.4, floorTempC: 29.2 },
            { pipeSpacingMM: 150, outputWperM2: 113.3, floorTempC: 28.1 },
            { pipeSpacingMM: 200, outputWperM2: 99, floorTempC: 27 },
            { pipeSpacingMM: 250, outputWperM2: 90.2, floorTempC: 26.2 },
            { pipeSpacingMM: 300, outputWperM2: 79.7, floorTempC: 25.3 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 151.1, floorTempC: 31.1 },
            { pipeSpacingMM: 150, outputWperM2: 134.4, floorTempC: 29.8 },
            { pipeSpacingMM: 200, outputWperM2: 118.5, floorTempC: 28.5 },
            { pipeSpacingMM: 250, outputWperM2: 106.9, floorTempC: 27.6 },
            { pipeSpacingMM: 300, outputWperM2: 94.6, floorTempC: 26.6 },
          ],
        },
        20: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 46.3, floorTempC: 24.5 },
            { pipeSpacingMM: 150, outputWperM2: 41.2, floorTempC: 24 },
            { pipeSpacingMM: 200, outputWperM2: 36.3, floorTempC: 23.6 },
            { pipeSpacingMM: 250, outputWperM2: 32.8, floorTempC: 23.3 },
            { pipeSpacingMM: 300, outputWperM2: 29, floorTempC: 22.9 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 70.3, floorTempC: 26.5 },
            { pipeSpacingMM: 150, outputWperM2: 62.5, floorTempC: 25.9 },
            { pipeSpacingMM: 200, outputWperM2: 55.1, floorTempC: 25.2 },
            { pipeSpacingMM: 250, outputWperM2: 49.8, floorTempC: 24.8 },
            { pipeSpacingMM: 300, outputWperM2: 44, floorTempC: 24.3 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 94.1, floorTempC: 28.5 },
            { pipeSpacingMM: 150, outputWperM2: 83.7, floorTempC: 27.7 },
            { pipeSpacingMM: 200, outputWperM2: 73.8, floorTempC: 26.8 },
            { pipeSpacingMM: 250, outputWperM2: 66.6, floorTempC: 26.2 },
            { pipeSpacingMM: 300, outputWperM2: 58.9, floorTempC: 25.6 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 117.9, floorTempC: 30.5 },
            { pipeSpacingMM: 150, outputWperM2: 104.8, floorTempC: 29.4 },
            { pipeSpacingMM: 200, outputWperM2: 92.5, floorTempC: 28.4 },
            { pipeSpacingMM: 250, outputWperM2: 83.4, floorTempC: 27.6 },
            { pipeSpacingMM: 300, outputWperM2: 73.8, floorTempC: 26.8 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 141.6, floorTempC: 32.3 },
            { pipeSpacingMM: 150, outputWperM2: 125.9, floorTempC: 31.1 },
            { pipeSpacingMM: 200, outputWperM2: 111.1, floorTempC: 29.9 },
            { pipeSpacingMM: 250, outputWperM2: 100.2, floorTempC: 29 },
            { pipeSpacingMM: 300, outputWperM2: 88.6, floorTempC: 28.1 },
          ],
        },
        22: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 36.6, floorTempC: 25.6 },
            { pipeSpacingMM: 150, outputWperM2: 32.5, floorTempC: 25.2 },
            { pipeSpacingMM: 200, outputWperM2: 28.7, floorTempC: 24.9 },
            { pipeSpacingMM: 250, outputWperM2: 25.9, floorTempC: 24.6 },
            { pipeSpacingMM: 300, outputWperM2: 22.9, floorTempC: 24.4 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 60.7, floorTempC: 27.7 },
            { pipeSpacingMM: 150, outputWperM2: 54, floorTempC: 27.1 },
            { pipeSpacingMM: 200, outputWperM2: 47.6, floorTempC: 26.6 },
            { pipeSpacingMM: 250, outputWperM2: 43, floorTempC: 26.2 },
            { pipeSpacingMM: 300, outputWperM2: 38, floorTempC: 25.7 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 84.6, floorTempC: 29.7 },
            { pipeSpacingMM: 150, outputWperM2: 75.2, floorTempC: 28.9 },
            { pipeSpacingMM: 200, outputWperM2: 66.4, floorTempC: 28.2 },
            { pipeSpacingMM: 250, outputWperM2: 59.9, floorTempC: 27.6 },
            { pipeSpacingMM: 300, outputWperM2: 53, floorTempC: 27.1 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 108.4, floorTempC: 31.7 },
            { pipeSpacingMM: 150, outputWperM2: 96.4, floorTempC: 30.7 },
            { pipeSpacingMM: 200, outputWperM2: 85, floorTempC: 29.8 },
            { pipeSpacingMM: 250, outputWperM2: 76.7, floorTempC: 29.1 },
            { pipeSpacingMM: 300, outputWperM2: 67.8, floorTempC: 28.3 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 132.1, floorTempC: 33.6 },
            { pipeSpacingMM: 150, outputWperM2: 117.5, floorTempC: 32.4 },
            { pipeSpacingMM: 200, outputWperM2: 103.6, floorTempC: 31.3 },
            { pipeSpacingMM: 250, outputWperM2: 93.6, floorTempC: 30.5 },
            { pipeSpacingMM: 300, outputWperM2: 82.7, floorTempC: 29.6 },
          ],
        },
        24: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 26.7, floorTempC: 26.7 },
            { pipeSpacingMM: 150, outputWperM2: 23.7, floorTempC: 26.4 },
            { pipeSpacingMM: 200, outputWperM2: 20.9, floorTempC: 26.2 },
            { pipeSpacingMM: 250, outputWperM2: 18.9, floorTempC: 26 },
            { pipeSpacingMM: 300, outputWperM2: 16.7, floorTempC: 25.8 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 51.1, floorTempC: 28.9 },
            { pipeSpacingMM: 150, outputWperM2: 45.5, floorTempC: 28.4 },
            { pipeSpacingMM: 200, outputWperM2: 40.1, floorTempC: 27.9 },
            { pipeSpacingMM: 250, outputWperM2: 36.2, floorTempC: 27.6 },
            { pipeSpacingMM: 300, outputWperM2: 32, floorTempC: 27.2 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 75.1, floorTempC: 30.9 },
            { pipeSpacingMM: 150, outputWperM2: 66.8, floorTempC: 30.2 },
            { pipeSpacingMM: 200, outputWperM2: 58.9, floorTempC: 29.6 },
            { pipeSpacingMM: 250, outputWperM2: 53.1, floorTempC: 29.1 },
            { pipeSpacingMM: 300, outputWperM2: 47, floorTempC: 28.5 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 98.9, floorTempC: 32.9 },
            { pipeSpacingMM: 150, outputWperM2: 87.9, floorTempC: 32 },
            { pipeSpacingMM: 200, outputWperM2: 77.6, floorTempC: 31.1 },
            { pipeSpacingMM: 250, outputWperM2: 70, floorTempC: 30.5 },
            { pipeSpacingMM: 300, outputWperM2: 61.9, floorTempC: 29.8 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 122.6, floorTempC: 34.8 },
            { pipeSpacingMM: 150, outputWperM2: 109.1, floorTempC: 33.7 },
            { pipeSpacingMM: 200, outputWperM2: 96.3, floorTempC: 32.7 },
            { pipeSpacingMM: 250, outputWperM2: 86.8, floorTempC: 31.9 },
            { pipeSpacingMM: 300, outputWperM2: 76.7, floorTempC: 31.1 },
          ],
        },
      },
    },
    pertAl: {
      16: {
        15: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 70.3, floorTempC: 21.5 },
            { pipeSpacingMM: 150, outputWperM2: 62.5, floorTempC: 20.9 },
            { pipeSpacingMM: 200, outputWperM2: 55.1, floorTempC: 20.2 },
            { pipeSpacingMM: 250, outputWperM2: 49.8, floorTempC: 19.8 },
            { pipeSpacingMM: 300, outputWperM2: 44, floorTempC: 19.3 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 94.1, floorTempC: 23.5 },
            { pipeSpacingMM: 150, outputWperM2: 83.7, floorTempC: 22.7 },
            { pipeSpacingMM: 200, outputWperM2: 73.8, floorTempC: 21.8 },
            { pipeSpacingMM: 250, outputWperM2: 66.6, floorTempC: 21.2 },
            { pipeSpacingMM: 300, outputWperM2: 58.9, floorTempC: 20.6 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 117, floorTempC: 25.5 },
            { pipeSpacingMM: 150, outputWperM2: 104.8, floorTempC: 24.4 },
            { pipeSpacingMM: 200, outputWperM2: 92.5, floorTempC: 23.4 },
            { pipeSpacingMM: 250, outputWperM2: 83.4, floorTempC: 22.6 },
            { pipeSpacingMM: 300, outputWperM2: 73.8, floorTempC: 21.8 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 141, floorTempC: 27.3 },
            { pipeSpacingMM: 150, outputWperM2: 125.9, floorTempC: 26.1 },
            { pipeSpacingMM: 200, outputWperM2: 111.1, floorTempC: 24.9 },
            { pipeSpacingMM: 250, outputWperM2: 100.2, floorTempC: 24 },
            { pipeSpacingMM: 300, outputWperM2: 88.6, floorTempC: 23.1 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 165, floorTempC: 29.2 },
            { pipeSpacingMM: 150, outputWperM2: 147, floorTempC: 27.8 },
            { pipeSpacingMM: 200, outputWperM2: 129.7, floorTempC: 26.4 },
            { pipeSpacingMM: 250, outputWperM2: 117, floorTempC: 25.4 },
            { pipeSpacingMM: 300, outputWperM2: 103.6, floorTempC: 24.3 },
          ],
        },
        18: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 55.9, floorTempC: 23.3 },
            { pipeSpacingMM: 150, outputWperM2: 49.8, floorTempC: 22.8 },
            { pipeSpacingMM: 200, outputWperM2: 43.9, floorTempC: 22.3 },
            { pipeSpacingMM: 250, outputWperM2: 39.6, floorTempC: 21.9 },
            { pipeSpacingMM: 300, outputWperM2: 35, floorTempC: 21.5 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 79.8, floorTempC: 25.3 },
            { pipeSpacingMM: 150, outputWperM2: 71, floorTempC: 24.6 },
            { pipeSpacingMM: 200, outputWperM2: 62.6, floorTempC: 23.9 },
            { pipeSpacingMM: 250, outputWperM2: 56.5, floorTempC: 23.4 },
            { pipeSpacingMM: 300, outputWperM2: 50, floorTempC: 22.8 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 103.6, floorTempC: 27.3 },
            { pipeSpacingMM: 150, outputWperM2: 92.2, floorTempC: 26.4 },
            { pipeSpacingMM: 200, outputWperM2: 81.3, floorTempC: 25.5 },
            { pipeSpacingMM: 250, outputWperM2: 73.4, floorTempC: 24.8 },
            { pipeSpacingMM: 300, outputWperM2: 64.9, floorTempC: 24.1 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 127.4, floorTempC: 29.2 },
            { pipeSpacingMM: 150, outputWperM2: 113.3, floorTempC: 28.1 },
            { pipeSpacingMM: 200, outputWperM2: 99, floorTempC: 27 },
            { pipeSpacingMM: 250, outputWperM2: 90.2, floorTempC: 26.2 },
            { pipeSpacingMM: 300, outputWperM2: 79.7, floorTempC: 25.3 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 151.1, floorTempC: 31.1 },
            { pipeSpacingMM: 150, outputWperM2: 134.4, floorTempC: 29.8 },
            { pipeSpacingMM: 200, outputWperM2: 118.5, floorTempC: 28.5 },
            { pipeSpacingMM: 250, outputWperM2: 106.9, floorTempC: 27.6 },
            { pipeSpacingMM: 300, outputWperM2: 94.6, floorTempC: 26.6 },
          ],
        },
        20: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 46.3, floorTempC: 24.5 },
            { pipeSpacingMM: 150, outputWperM2: 41.2, floorTempC: 24 },
            { pipeSpacingMM: 200, outputWperM2: 36.3, floorTempC: 23.6 },
            { pipeSpacingMM: 250, outputWperM2: 32.8, floorTempC: 23.3 },
            { pipeSpacingMM: 300, outputWperM2: 29, floorTempC: 22.9 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 70.3, floorTempC: 26.5 },
            { pipeSpacingMM: 150, outputWperM2: 62.5, floorTempC: 25.9 },
            { pipeSpacingMM: 200, outputWperM2: 55.1, floorTempC: 25.2 },
            { pipeSpacingMM: 250, outputWperM2: 49.8, floorTempC: 24.8 },
            { pipeSpacingMM: 300, outputWperM2: 44, floorTempC: 24.3 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 94.1, floorTempC: 28.5 },
            { pipeSpacingMM: 150, outputWperM2: 83.7, floorTempC: 27.7 },
            { pipeSpacingMM: 200, outputWperM2: 73.8, floorTempC: 26.8 },
            { pipeSpacingMM: 250, outputWperM2: 66.6, floorTempC: 26.2 },
            { pipeSpacingMM: 300, outputWperM2: 58.9, floorTempC: 25.6 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 117.9, floorTempC: 30.5 },
            { pipeSpacingMM: 150, outputWperM2: 104.8, floorTempC: 29.4 },
            { pipeSpacingMM: 200, outputWperM2: 92.5, floorTempC: 28.4 },
            { pipeSpacingMM: 250, outputWperM2: 83.4, floorTempC: 27.6 },
            { pipeSpacingMM: 300, outputWperM2: 73.8, floorTempC: 26.8 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 141.6, floorTempC: 32.3 },
            { pipeSpacingMM: 150, outputWperM2: 125.9, floorTempC: 31.1 },
            { pipeSpacingMM: 200, outputWperM2: 111.1, floorTempC: 29.9 },
            { pipeSpacingMM: 250, outputWperM2: 100.2, floorTempC: 29 },
            { pipeSpacingMM: 300, outputWperM2: 88.6, floorTempC: 28.1 },
          ],
        },
        22: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 36.6, floorTempC: 25.6 },
            { pipeSpacingMM: 150, outputWperM2: 32.5, floorTempC: 25.2 },
            { pipeSpacingMM: 200, outputWperM2: 28.7, floorTempC: 24.9 },
            { pipeSpacingMM: 250, outputWperM2: 25.9, floorTempC: 24.6 },
            { pipeSpacingMM: 300, outputWperM2: 22.9, floorTempC: 24.4 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 60.7, floorTempC: 27.7 },
            { pipeSpacingMM: 150, outputWperM2: 54, floorTempC: 27.1 },
            { pipeSpacingMM: 200, outputWperM2: 47.6, floorTempC: 26.6 },
            { pipeSpacingMM: 250, outputWperM2: 43, floorTempC: 26.2 },
            { pipeSpacingMM: 300, outputWperM2: 38, floorTempC: 25.7 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 84.6, floorTempC: 29.7 },
            { pipeSpacingMM: 150, outputWperM2: 75.2, floorTempC: 28.9 },
            { pipeSpacingMM: 200, outputWperM2: 66.4, floorTempC: 28.2 },
            { pipeSpacingMM: 250, outputWperM2: 59.9, floorTempC: 27.6 },
            { pipeSpacingMM: 300, outputWperM2: 53, floorTempC: 27.1 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 108.4, floorTempC: 31.7 },
            { pipeSpacingMM: 150, outputWperM2: 96.4, floorTempC: 30.7 },
            { pipeSpacingMM: 200, outputWperM2: 85, floorTempC: 29.8 },
            { pipeSpacingMM: 250, outputWperM2: 76.7, floorTempC: 29.1 },
            { pipeSpacingMM: 300, outputWperM2: 67.8, floorTempC: 28.3 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 132.1, floorTempC: 33.6 },
            { pipeSpacingMM: 150, outputWperM2: 117.5, floorTempC: 32.4 },
            { pipeSpacingMM: 200, outputWperM2: 103.6, floorTempC: 31.3 },
            { pipeSpacingMM: 250, outputWperM2: 93.6, floorTempC: 30.5 },
            { pipeSpacingMM: 300, outputWperM2: 82.7, floorTempC: 29.6 },
          ],
        },
        24: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 26.7, floorTempC: 26.7 },
            { pipeSpacingMM: 150, outputWperM2: 23.7, floorTempC: 26.4 },
            { pipeSpacingMM: 200, outputWperM2: 20.9, floorTempC: 26.2 },
            { pipeSpacingMM: 250, outputWperM2: 18.9, floorTempC: 26 },
            { pipeSpacingMM: 300, outputWperM2: 16.7, floorTempC: 25.8 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 51.1, floorTempC: 28.9 },
            { pipeSpacingMM: 150, outputWperM2: 45.5, floorTempC: 28.4 },
            { pipeSpacingMM: 200, outputWperM2: 40.1, floorTempC: 27.9 },
            { pipeSpacingMM: 250, outputWperM2: 36.2, floorTempC: 27.6 },
            { pipeSpacingMM: 300, outputWperM2: 32, floorTempC: 27.2 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 75.1, floorTempC: 30.9 },
            { pipeSpacingMM: 150, outputWperM2: 66.8, floorTempC: 30.2 },
            { pipeSpacingMM: 200, outputWperM2: 58.9, floorTempC: 29.6 },
            { pipeSpacingMM: 250, outputWperM2: 53.1, floorTempC: 29.1 },
            { pipeSpacingMM: 300, outputWperM2: 47, floorTempC: 28.5 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 98.9, floorTempC: 32.9 },
            { pipeSpacingMM: 150, outputWperM2: 87.9, floorTempC: 32 },
            { pipeSpacingMM: 200, outputWperM2: 77.6, floorTempC: 31.1 },
            { pipeSpacingMM: 250, outputWperM2: 70, floorTempC: 30.5 },
            { pipeSpacingMM: 300, outputWperM2: 61.9, floorTempC: 29.8 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 122.6, floorTempC: 34.8 },
            { pipeSpacingMM: 150, outputWperM2: 109.1, floorTempC: 33.7 },
            { pipeSpacingMM: 200, outputWperM2: 96.3, floorTempC: 32.7 },
            { pipeSpacingMM: 250, outputWperM2: 86.8, floorTempC: 31.9 },
            { pipeSpacingMM: 300, outputWperM2: 76.7, floorTempC: 31.1 },
          ],
        },
      },
    },
    pertEvoh: {
      16: {
        15: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 70.3, floorTempC: 21.5 },
            { pipeSpacingMM: 150, outputWperM2: 62.5, floorTempC: 20.9 },
            { pipeSpacingMM: 200, outputWperM2: 55.1, floorTempC: 20.2 },
            { pipeSpacingMM: 250, outputWperM2: 49.8, floorTempC: 19.8 },
            { pipeSpacingMM: 300, outputWperM2: 44, floorTempC: 19.3 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 94.1, floorTempC: 23.5 },
            { pipeSpacingMM: 150, outputWperM2: 83.7, floorTempC: 22.7 },
            { pipeSpacingMM: 200, outputWperM2: 73.8, floorTempC: 21.8 },
            { pipeSpacingMM: 250, outputWperM2: 66.6, floorTempC: 21.2 },
            { pipeSpacingMM: 300, outputWperM2: 58.9, floorTempC: 20.6 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 117, floorTempC: 25.5 },
            { pipeSpacingMM: 150, outputWperM2: 104.8, floorTempC: 24.4 },
            { pipeSpacingMM: 200, outputWperM2: 92.5, floorTempC: 23.4 },
            { pipeSpacingMM: 250, outputWperM2: 83.4, floorTempC: 22.6 },
            { pipeSpacingMM: 300, outputWperM2: 73.8, floorTempC: 21.8 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 141, floorTempC: 27.3 },
            { pipeSpacingMM: 150, outputWperM2: 125.9, floorTempC: 26.1 },
            { pipeSpacingMM: 200, outputWperM2: 111.1, floorTempC: 24.9 },
            { pipeSpacingMM: 250, outputWperM2: 100.2, floorTempC: 24 },
            { pipeSpacingMM: 300, outputWperM2: 88.6, floorTempC: 23.1 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 165, floorTempC: 29.2 },
            { pipeSpacingMM: 150, outputWperM2: 147, floorTempC: 27.8 },
            { pipeSpacingMM: 200, outputWperM2: 129.7, floorTempC: 26.4 },
            { pipeSpacingMM: 250, outputWperM2: 117, floorTempC: 25.4 },
            { pipeSpacingMM: 300, outputWperM2: 103.6, floorTempC: 24.3 },
          ],
        },
        18: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 55.9, floorTempC: 23.3 },
            { pipeSpacingMM: 150, outputWperM2: 49.8, floorTempC: 22.8 },
            { pipeSpacingMM: 200, outputWperM2: 43.9, floorTempC: 22.3 },
            { pipeSpacingMM: 250, outputWperM2: 39.6, floorTempC: 21.9 },
            { pipeSpacingMM: 300, outputWperM2: 35, floorTempC: 21.5 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 79.8, floorTempC: 25.3 },
            { pipeSpacingMM: 150, outputWperM2: 71, floorTempC: 24.6 },
            { pipeSpacingMM: 200, outputWperM2: 62.6, floorTempC: 23.9 },
            { pipeSpacingMM: 250, outputWperM2: 56.5, floorTempC: 23.4 },
            { pipeSpacingMM: 300, outputWperM2: 50, floorTempC: 22.8 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 103.6, floorTempC: 27.3 },
            { pipeSpacingMM: 150, outputWperM2: 92.2, floorTempC: 26.4 },
            { pipeSpacingMM: 200, outputWperM2: 81.3, floorTempC: 25.5 },
            { pipeSpacingMM: 250, outputWperM2: 73.4, floorTempC: 24.8 },
            { pipeSpacingMM: 300, outputWperM2: 64.9, floorTempC: 24.1 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 127.4, floorTempC: 29.2 },
            { pipeSpacingMM: 150, outputWperM2: 113.3, floorTempC: 28.1 },
            { pipeSpacingMM: 200, outputWperM2: 99, floorTempC: 27 },
            { pipeSpacingMM: 250, outputWperM2: 90.2, floorTempC: 26.2 },
            { pipeSpacingMM: 300, outputWperM2: 79.7, floorTempC: 25.3 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 151.1, floorTempC: 31.1 },
            { pipeSpacingMM: 150, outputWperM2: 134.4, floorTempC: 29.8 },
            { pipeSpacingMM: 200, outputWperM2: 118.5, floorTempC: 28.5 },
            { pipeSpacingMM: 250, outputWperM2: 106.9, floorTempC: 27.6 },
            { pipeSpacingMM: 300, outputWperM2: 94.6, floorTempC: 26.6 },
          ],
        },
        20: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 46.3, floorTempC: 24.5 },
            { pipeSpacingMM: 150, outputWperM2: 41.2, floorTempC: 24 },
            { pipeSpacingMM: 200, outputWperM2: 36.3, floorTempC: 23.6 },
            { pipeSpacingMM: 250, outputWperM2: 32.8, floorTempC: 23.3 },
            { pipeSpacingMM: 300, outputWperM2: 29, floorTempC: 22.9 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 70.3, floorTempC: 26.5 },
            { pipeSpacingMM: 150, outputWperM2: 62.5, floorTempC: 25.9 },
            { pipeSpacingMM: 200, outputWperM2: 55.1, floorTempC: 25.2 },
            { pipeSpacingMM: 250, outputWperM2: 49.8, floorTempC: 24.8 },
            { pipeSpacingMM: 300, outputWperM2: 44, floorTempC: 24.3 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 94.1, floorTempC: 28.5 },
            { pipeSpacingMM: 150, outputWperM2: 83.7, floorTempC: 27.7 },
            { pipeSpacingMM: 200, outputWperM2: 73.8, floorTempC: 26.8 },
            { pipeSpacingMM: 250, outputWperM2: 66.6, floorTempC: 26.2 },
            { pipeSpacingMM: 300, outputWperM2: 58.9, floorTempC: 25.6 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 117.9, floorTempC: 30.5 },
            { pipeSpacingMM: 150, outputWperM2: 104.8, floorTempC: 29.4 },
            { pipeSpacingMM: 200, outputWperM2: 92.5, floorTempC: 28.4 },
            { pipeSpacingMM: 250, outputWperM2: 83.4, floorTempC: 27.6 },
            { pipeSpacingMM: 300, outputWperM2: 73.8, floorTempC: 26.8 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 141.6, floorTempC: 32.3 },
            { pipeSpacingMM: 150, outputWperM2: 125.9, floorTempC: 31.1 },
            { pipeSpacingMM: 200, outputWperM2: 111.1, floorTempC: 29.9 },
            { pipeSpacingMM: 250, outputWperM2: 100.2, floorTempC: 29 },
            { pipeSpacingMM: 300, outputWperM2: 88.6, floorTempC: 28.1 },
          ],
        },
        22: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 36.6, floorTempC: 25.6 },
            { pipeSpacingMM: 150, outputWperM2: 32.5, floorTempC: 25.2 },
            { pipeSpacingMM: 200, outputWperM2: 28.7, floorTempC: 24.9 },
            { pipeSpacingMM: 250, outputWperM2: 25.9, floorTempC: 24.6 },
            { pipeSpacingMM: 300, outputWperM2: 22.9, floorTempC: 24.4 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 60.7, floorTempC: 27.7 },
            { pipeSpacingMM: 150, outputWperM2: 54, floorTempC: 27.1 },
            { pipeSpacingMM: 200, outputWperM2: 47.6, floorTempC: 26.6 },
            { pipeSpacingMM: 250, outputWperM2: 43, floorTempC: 26.2 },
            { pipeSpacingMM: 300, outputWperM2: 38, floorTempC: 25.7 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 84.6, floorTempC: 29.7 },
            { pipeSpacingMM: 150, outputWperM2: 75.2, floorTempC: 28.9 },
            { pipeSpacingMM: 200, outputWperM2: 66.4, floorTempC: 28.2 },
            { pipeSpacingMM: 250, outputWperM2: 59.9, floorTempC: 27.6 },
            { pipeSpacingMM: 300, outputWperM2: 53, floorTempC: 27.1 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 108.4, floorTempC: 31.7 },
            { pipeSpacingMM: 150, outputWperM2: 96.4, floorTempC: 30.7 },
            { pipeSpacingMM: 200, outputWperM2: 85, floorTempC: 29.8 },
            { pipeSpacingMM: 250, outputWperM2: 76.7, floorTempC: 29.1 },
            { pipeSpacingMM: 300, outputWperM2: 67.8, floorTempC: 28.3 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 132.1, floorTempC: 33.6 },
            { pipeSpacingMM: 150, outputWperM2: 117.5, floorTempC: 32.4 },
            { pipeSpacingMM: 200, outputWperM2: 103.6, floorTempC: 31.3 },
            { pipeSpacingMM: 250, outputWperM2: 93.6, floorTempC: 30.5 },
            { pipeSpacingMM: 300, outputWperM2: 82.7, floorTempC: 29.6 },
          ],
        },
        24: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 26.7, floorTempC: 26.7 },
            { pipeSpacingMM: 150, outputWperM2: 23.7, floorTempC: 26.4 },
            { pipeSpacingMM: 200, outputWperM2: 20.9, floorTempC: 26.2 },
            { pipeSpacingMM: 250, outputWperM2: 18.9, floorTempC: 26 },
            { pipeSpacingMM: 300, outputWperM2: 16.7, floorTempC: 25.8 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 51.1, floorTempC: 28.9 },
            { pipeSpacingMM: 150, outputWperM2: 45.5, floorTempC: 28.4 },
            { pipeSpacingMM: 200, outputWperM2: 40.1, floorTempC: 27.9 },
            { pipeSpacingMM: 250, outputWperM2: 36.2, floorTempC: 27.6 },
            { pipeSpacingMM: 300, outputWperM2: 32, floorTempC: 27.2 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 75.1, floorTempC: 30.9 },
            { pipeSpacingMM: 150, outputWperM2: 66.8, floorTempC: 30.2 },
            { pipeSpacingMM: 200, outputWperM2: 58.9, floorTempC: 29.6 },
            { pipeSpacingMM: 250, outputWperM2: 53.1, floorTempC: 29.1 },
            { pipeSpacingMM: 300, outputWperM2: 47, floorTempC: 28.5 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 98.9, floorTempC: 32.9 },
            { pipeSpacingMM: 150, outputWperM2: 87.9, floorTempC: 32 },
            { pipeSpacingMM: 200, outputWperM2: 77.6, floorTempC: 31.1 },
            { pipeSpacingMM: 250, outputWperM2: 70, floorTempC: 30.5 },
            { pipeSpacingMM: 300, outputWperM2: 61.9, floorTempC: 29.8 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 122.6, floorTempC: 34.8 },
            { pipeSpacingMM: 150, outputWperM2: 109.1, floorTempC: 33.7 },
            { pipeSpacingMM: 200, outputWperM2: 96.3, floorTempC: 32.7 },
            { pipeSpacingMM: 250, outputWperM2: 86.8, floorTempC: 31.9 },
            { pipeSpacingMM: 300, outputWperM2: 76.7, floorTempC: 31.1 },
          ],
        },
      },
    },
  },
  "Type A - 1 TOG - carpet tiles, hardwood flooring": {
    pexSdr74: {
      16: {
        15: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 55.6, floorTempC: 20.3 },
            { pipeSpacingMM: 150, outputWperM2: 50.1, floorTempC: 19.8 },
            { pipeSpacingMM: 200, outputWperM2: 45.5, floorTempC: 19.4 },
            { pipeSpacingMM: 250, outputWperM2: 42.1, floorTempC: 19.1 },
            { pipeSpacingMM: 300, outputWperM2: 37.1, floorTempC: 18.7 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 74.5, floorTempC: 21.9 },
            { pipeSpacingMM: 150, outputWperM2: 67.1, floorTempC: 21.3 },
            { pipeSpacingMM: 200, outputWperM2: 61, floorTempC: 20.7 },
            { pipeSpacingMM: 250, outputWperM2: 56.3, floorTempC: 20.3 },
            { pipeSpacingMM: 300, outputWperM2: 49.7, floorTempC: 19.8 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 93.3, floorTempC: 23.4 },
            { pipeSpacingMM: 150, outputWperM2: 84.1, floorTempC: 22.7 },
            { pipeSpacingMM: 200, outputWperM2: 76.4, floorTempC: 22 },
            { pipeSpacingMM: 250, outputWperM2: 70.5, floorTempC: 21.5 },
            { pipeSpacingMM: 300, outputWperM2: 62.3, floorTempC: 20.9 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 112, floorTempC: 25 },
            { pipeSpacingMM: 150, outputWperM2: 101, floorTempC: 24.1 },
            { pipeSpacingMM: 200, outputWperM2: 91.7, floorTempC: 23.3 },
            { pipeSpacingMM: 250, outputWperM2: 84.7, floorTempC: 22.7 },
            { pipeSpacingMM: 300, outputWperM2: 74.8, floorTempC: 21.9 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 130.8, floorTempC: 26.5 },
            { pipeSpacingMM: 150, outputWperM2: 117.9, floorTempC: 25.5 },
            { pipeSpacingMM: 200, outputWperM2: 107.1, floorTempC: 24.6 },
            { pipeSpacingMM: 250, outputWperM2: 98.9, floorTempC: 23.9 },
            { pipeSpacingMM: 300, outputWperM2: 87.3, floorTempC: 23 },
          ],
        },
        18: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 44.3, floorTempC: 22.3 },
            { pipeSpacingMM: 150, outputWperM2: 39.9, floorTempC: 21.9 },
            { pipeSpacingMM: 200, outputWperM2: 36.2, floorTempC: 21.6 },
            { pipeSpacingMM: 250, outputWperM2: 33.5, floorTempC: 21.3 },
            { pipeSpacingMM: 300, outputWperM2: 29.6, floorTempC: 21 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 63.2, floorTempC: 23.9 },
            { pipeSpacingMM: 150, outputWperM2: 56.9, floorTempC: 23.4 },
            { pipeSpacingMM: 200, outputWperM2: 51.7, floorTempC: 22.9 },
            { pipeSpacingMM: 250, outputWperM2: 47.8, floorTempC: 22.6 },
            { pipeSpacingMM: 300, outputWperM2: 42.2, floorTempC: 22.1 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 82, floorTempC: 25.5 },
            { pipeSpacingMM: 150, outputWperM2: 73.9, floorTempC: 24.8 },
            { pipeSpacingMM: 200, outputWperM2: 67.1, floorTempC: 24.3 },
            { pipeSpacingMM: 250, outputWperM2: 62, floorTempC: 23.8 },
            { pipeSpacingMM: 300, outputWperM2: 54.7, floorTempC: 23.2 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 100.8, floorTempC: 27.1 },
            { pipeSpacingMM: 150, outputWperM2: 90.8, floorTempC: 26.2 },
            { pipeSpacingMM: 200, outputWperM2: 82.5, floorTempC: 25.6 },
            { pipeSpacingMM: 250, outputWperM2: 76.2, floorTempC: 25 },
            { pipeSpacingMM: 300, outputWperM2: 67.3, floorTempC: 24.3 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 119.5, floorTempC: 28.6 },
            { pipeSpacingMM: 150, outputWperM2: 107.8, floorTempC: 27.6 },
            { pipeSpacingMM: 200, outputWperM2: 97.9, floorTempC: 26.8 },
            { pipeSpacingMM: 250, outputWperM2: 90.4, floorTempC: 26.2 },
            { pipeSpacingMM: 300, outputWperM2: 79.8, floorTempC: 25.3 },
          ],
        },
        20: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 36.6, floorTempC: 23.6 },
            { pipeSpacingMM: 150, outputWperM2: 33, floorTempC: 23.3 },
            { pipeSpacingMM: 200, outputWperM2: 30, floorTempC: 23 },
            { pipeSpacingMM: 250, outputWperM2: 27.7, floorTempC: 22.8 },
            { pipeSpacingMM: 300, outputWperM2: 24.5, floorTempC: 22.5 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 55.6, floorTempC: 25.3 },
            { pipeSpacingMM: 150, outputWperM2: 50.1, floorTempC: 24.8 },
            { pipeSpacingMM: 200, outputWperM2: 45.5, floorTempC: 24.4 },
            { pipeSpacingMM: 250, outputWperM2: 42.1, floorTempC: 24.1 },
            { pipeSpacingMM: 300, outputWperM2: 37.1, floorTempC: 23.7 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 74.5, floorTempC: 26.9 },
            { pipeSpacingMM: 150, outputWperM2: 67.1, floorTempC: 26.3 },
            { pipeSpacingMM: 200, outputWperM2: 61, floorTempC: 25.7 },
            { pipeSpacingMM: 250, outputWperM2: 56.3, floorTempC: 25.3 },
            { pipeSpacingMM: 300, outputWperM2: 49.7, floorTempC: 24.8 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 93.3, floorTempC: 28.4 },
            { pipeSpacingMM: 150, outputWperM2: 84.1, floorTempC: 27.7 },
            { pipeSpacingMM: 200, outputWperM2: 76.4, floorTempC: 27 },
            { pipeSpacingMM: 250, outputWperM2: 70.5, floorTempC: 26.5 },
            { pipeSpacingMM: 300, outputWperM2: 62.3, floorTempC: 25.9 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 112, floorTempC: 30 },
            { pipeSpacingMM: 150, outputWperM2: 101, floorTempC: 29.1 },
            { pipeSpacingMM: 200, outputWperM2: 91.7, floorTempC: 28.3 },
            { pipeSpacingMM: 250, outputWperM2: 84.7, floorTempC: 27.7 },
            { pipeSpacingMM: 300, outputWperM2: 74.8, floorTempC: 26.9 },
          ],
        },
        22: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 28.9, floorTempC: 24.9 },
            { pipeSpacingMM: 150, outputWperM2: 26.1, floorTempC: 24.7 },
            { pipeSpacingMM: 200, outputWperM2: 23.7, floorTempC: 24.4 },
            { pipeSpacingMM: 250, outputWperM2: 21.9, floorTempC: 24.3 },
            { pipeSpacingMM: 300, outputWperM2: 19.3, floorTempC: 24 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 48.1, floorTempC: 26.6 },
            { pipeSpacingMM: 150, outputWperM2: 43.3, floorTempC: 26.2 },
            { pipeSpacingMM: 200, outputWperM2: 39.4, floorTempC: 27.3 },
            { pipeSpacingMM: 250, outputWperM2: 36.3, floorTempC: 25.6 },
            { pipeSpacingMM: 300, outputWperM2: 32.1, floorTempC: 25.2 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 66.9, floorTempC: 28.2 },
            { pipeSpacingMM: 150, outputWperM2: 60.3, floorTempC: 27.7 },
            { pipeSpacingMM: 200, outputWperM2: 54.8, floorTempC: 27.2 },
            { pipeSpacingMM: 250, outputWperM2: 50.6, floorTempC: 26.8 },
            { pipeSpacingMM: 300, outputWperM2: 44.7, floorTempC: 26.3 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 85.8, floorTempC: 29.8 },
            { pipeSpacingMM: 150, outputWperM2: 77.3, floorTempC: 29.1 },
            { pipeSpacingMM: 200, outputWperM2: 70.2, floorTempC: 28.5 },
            { pipeSpacingMM: 250, outputWperM2: 64.9, floorTempC: 28.1 },
            { pipeSpacingMM: 300, outputWperM2: 57.3, floorTempC: 27.4 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 104.5, floorTempC: 31.4 },
            { pipeSpacingMM: 150, outputWperM2: 94.2, floorTempC: 30.5 },
            { pipeSpacingMM: 200, outputWperM2: 85.6, floorTempC: 29.8 },
            { pipeSpacingMM: 250, outputWperM2: 79.1, floorTempC: 29.3 },
            { pipeSpacingMM: 300, outputWperM2: 69.8, floorTempC: 28.5 },
          ],
        },
        24: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 21.1, floorTempC: 26.2 },
            { pipeSpacingMM: 150, outputWperM2: 19, floorTempC: 26 },
            { pipeSpacingMM: 200, outputWperM2: 17.3, floorTempC: 25.8 },
            { pipeSpacingMM: 250, outputWperM2: 16, floorTempC: 25.7 },
            { pipeSpacingMM: 300, outputWperM2: 14.1, floorTempC: 25.5 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 40.5, floorTempC: 28 },
            { pipeSpacingMM: 150, outputWperM2: 36.5, floorTempC: 27.6 },
            { pipeSpacingMM: 200, outputWperM2: 33.1, floorTempC: 27.3 },
            { pipeSpacingMM: 250, outputWperM2: 30.6, floorTempC: 27.1 },
            { pipeSpacingMM: 300, outputWperM2: 27, floorTempC: 26.7 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 59.4, floorTempC: 29.6 },
            { pipeSpacingMM: 150, outputWperM2: 53.5, floorTempC: 29.1 },
            { pipeSpacingMM: 200, outputWperM2: 48.6, floorTempC: 28.7 },
            { pipeSpacingMM: 250, outputWperM2: 44.9, floorTempC: 28.3 },
            { pipeSpacingMM: 300, outputWperM2: 39.7, floorTempC: 27.9 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 78.2, floorTempC: 31.2 },
            { pipeSpacingMM: 150, outputWperM2: 70.5, floorTempC: 30.5 },
            { pipeSpacingMM: 200, outputWperM2: 64.1, floorTempC: 30 },
            { pipeSpacingMM: 250, outputWperM2: 59.2, floorTempC: 29.6 },
            { pipeSpacingMM: 300, outputWperM2: 52.2, floorTempC: 29 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 97, floorTempC: 32.8 },
            { pipeSpacingMM: 150, outputWperM2: 87.5, floorTempC: 32 },
            { pipeSpacingMM: 200, outputWperM2: 79.4, floorTempC: 31.3 },
            { pipeSpacingMM: 250, outputWperM2: 73.4, floorTempC: 30.8 },
            { pipeSpacingMM: 300, outputWperM2: 64.8, floorTempC: 30.1 },
          ],
        },
      },
    },
    pertAl: {
      16: {
        15: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 55.6, floorTempC: 20.3 },
            { pipeSpacingMM: 150, outputWperM2: 50.1, floorTempC: 19.8 },
            { pipeSpacingMM: 200, outputWperM2: 45.5, floorTempC: 19.4 },
            { pipeSpacingMM: 250, outputWperM2: 42.1, floorTempC: 19.1 },
            { pipeSpacingMM: 300, outputWperM2: 37.1, floorTempC: 18.7 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 74.5, floorTempC: 21.9 },
            { pipeSpacingMM: 150, outputWperM2: 67.1, floorTempC: 21.3 },
            { pipeSpacingMM: 200, outputWperM2: 61, floorTempC: 20.7 },
            { pipeSpacingMM: 250, outputWperM2: 56.3, floorTempC: 20.3 },
            { pipeSpacingMM: 300, outputWperM2: 49.7, floorTempC: 19.8 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 93.3, floorTempC: 23.4 },
            { pipeSpacingMM: 150, outputWperM2: 84.1, floorTempC: 22.7 },
            { pipeSpacingMM: 200, outputWperM2: 76.4, floorTempC: 22 },
            { pipeSpacingMM: 250, outputWperM2: 70.5, floorTempC: 21.5 },
            { pipeSpacingMM: 300, outputWperM2: 62.3, floorTempC: 20.9 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 112, floorTempC: 25 },
            { pipeSpacingMM: 150, outputWperM2: 101, floorTempC: 24.1 },
            { pipeSpacingMM: 200, outputWperM2: 91.7, floorTempC: 23.3 },
            { pipeSpacingMM: 250, outputWperM2: 84.7, floorTempC: 22.7 },
            { pipeSpacingMM: 300, outputWperM2: 74.8, floorTempC: 21.9 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 130.8, floorTempC: 26.5 },
            { pipeSpacingMM: 150, outputWperM2: 117.9, floorTempC: 25.5 },
            { pipeSpacingMM: 200, outputWperM2: 107.1, floorTempC: 24.6 },
            { pipeSpacingMM: 250, outputWperM2: 98.9, floorTempC: 23.9 },
            { pipeSpacingMM: 300, outputWperM2: 87.3, floorTempC: 23 },
          ],
        },
        18: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 44.3, floorTempC: 22.3 },
            { pipeSpacingMM: 150, outputWperM2: 39.9, floorTempC: 21.9 },
            { pipeSpacingMM: 200, outputWperM2: 36.2, floorTempC: 21.6 },
            { pipeSpacingMM: 250, outputWperM2: 33.5, floorTempC: 21.3 },
            { pipeSpacingMM: 300, outputWperM2: 29.6, floorTempC: 21 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 63.2, floorTempC: 23.9 },
            { pipeSpacingMM: 150, outputWperM2: 56.9, floorTempC: 23.4 },
            { pipeSpacingMM: 200, outputWperM2: 51.7, floorTempC: 22.9 },
            { pipeSpacingMM: 250, outputWperM2: 47.8, floorTempC: 22.6 },
            { pipeSpacingMM: 300, outputWperM2: 42.2, floorTempC: 22.1 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 82, floorTempC: 25.5 },
            { pipeSpacingMM: 150, outputWperM2: 73.9, floorTempC: 24.8 },
            { pipeSpacingMM: 200, outputWperM2: 67.1, floorTempC: 24.3 },
            { pipeSpacingMM: 250, outputWperM2: 62, floorTempC: 23.8 },
            { pipeSpacingMM: 300, outputWperM2: 54.7, floorTempC: 23.2 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 100.8, floorTempC: 27.1 },
            { pipeSpacingMM: 150, outputWperM2: 90.8, floorTempC: 26.2 },
            { pipeSpacingMM: 200, outputWperM2: 82.5, floorTempC: 25.6 },
            { pipeSpacingMM: 250, outputWperM2: 76.2, floorTempC: 25 },
            { pipeSpacingMM: 300, outputWperM2: 67.3, floorTempC: 24.3 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 119.5, floorTempC: 28.6 },
            { pipeSpacingMM: 150, outputWperM2: 107.8, floorTempC: 27.6 },
            { pipeSpacingMM: 200, outputWperM2: 97.9, floorTempC: 26.8 },
            { pipeSpacingMM: 250, outputWperM2: 90.4, floorTempC: 26.2 },
            { pipeSpacingMM: 300, outputWperM2: 79.8, floorTempC: 25.3 },
          ],
        },
        20: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 36.6, floorTempC: 23.6 },
            { pipeSpacingMM: 150, outputWperM2: 33, floorTempC: 23.3 },
            { pipeSpacingMM: 200, outputWperM2: 30, floorTempC: 23 },
            { pipeSpacingMM: 250, outputWperM2: 27.7, floorTempC: 22.8 },
            { pipeSpacingMM: 300, outputWperM2: 24.5, floorTempC: 22.5 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 55.6, floorTempC: 25.3 },
            { pipeSpacingMM: 150, outputWperM2: 50.1, floorTempC: 24.8 },
            { pipeSpacingMM: 200, outputWperM2: 45.5, floorTempC: 24.4 },
            { pipeSpacingMM: 250, outputWperM2: 42.1, floorTempC: 24.1 },
            { pipeSpacingMM: 300, outputWperM2: 37.1, floorTempC: 23.7 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 74.5, floorTempC: 26.9 },
            { pipeSpacingMM: 150, outputWperM2: 67.1, floorTempC: 26.3 },
            { pipeSpacingMM: 200, outputWperM2: 61, floorTempC: 25.7 },
            { pipeSpacingMM: 250, outputWperM2: 56.3, floorTempC: 25.3 },
            { pipeSpacingMM: 300, outputWperM2: 49.7, floorTempC: 24.8 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 93.3, floorTempC: 28.4 },
            { pipeSpacingMM: 150, outputWperM2: 84.1, floorTempC: 27.7 },
            { pipeSpacingMM: 200, outputWperM2: 76.4, floorTempC: 27 },
            { pipeSpacingMM: 250, outputWperM2: 70.5, floorTempC: 26.5 },
            { pipeSpacingMM: 300, outputWperM2: 62.3, floorTempC: 25.9 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 112, floorTempC: 30 },
            { pipeSpacingMM: 150, outputWperM2: 101, floorTempC: 29.1 },
            { pipeSpacingMM: 200, outputWperM2: 91.7, floorTempC: 28.3 },
            { pipeSpacingMM: 250, outputWperM2: 84.7, floorTempC: 27.7 },
            { pipeSpacingMM: 300, outputWperM2: 74.8, floorTempC: 26.9 },
          ],
        },
        22: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 28.9, floorTempC: 24.9 },
            { pipeSpacingMM: 150, outputWperM2: 26.1, floorTempC: 24.7 },
            { pipeSpacingMM: 200, outputWperM2: 23.7, floorTempC: 24.4 },
            { pipeSpacingMM: 250, outputWperM2: 21.9, floorTempC: 24.3 },
            { pipeSpacingMM: 300, outputWperM2: 19.3, floorTempC: 24 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 48.1, floorTempC: 26.6 },
            { pipeSpacingMM: 150, outputWperM2: 43.3, floorTempC: 26.2 },
            { pipeSpacingMM: 200, outputWperM2: 39.4, floorTempC: 27.3 },
            { pipeSpacingMM: 250, outputWperM2: 36.3, floorTempC: 25.6 },
            { pipeSpacingMM: 300, outputWperM2: 32.1, floorTempC: 25.2 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 66.9, floorTempC: 28.2 },
            { pipeSpacingMM: 150, outputWperM2: 60.3, floorTempC: 27.7 },
            { pipeSpacingMM: 200, outputWperM2: 54.8, floorTempC: 27.2 },
            { pipeSpacingMM: 250, outputWperM2: 50.6, floorTempC: 26.8 },
            { pipeSpacingMM: 300, outputWperM2: 44.7, floorTempC: 26.3 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 85.8, floorTempC: 29.8 },
            { pipeSpacingMM: 150, outputWperM2: 77.3, floorTempC: 29.1 },
            { pipeSpacingMM: 200, outputWperM2: 70.2, floorTempC: 28.5 },
            { pipeSpacingMM: 250, outputWperM2: 64.9, floorTempC: 28.1 },
            { pipeSpacingMM: 300, outputWperM2: 57.3, floorTempC: 27.4 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 104.5, floorTempC: 31.4 },
            { pipeSpacingMM: 150, outputWperM2: 94.2, floorTempC: 30.5 },
            { pipeSpacingMM: 200, outputWperM2: 85.6, floorTempC: 29.8 },
            { pipeSpacingMM: 250, outputWperM2: 79.1, floorTempC: 29.3 },
            { pipeSpacingMM: 300, outputWperM2: 69.8, floorTempC: 28.5 },
          ],
        },
        24: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 21.1, floorTempC: 26.2 },
            { pipeSpacingMM: 150, outputWperM2: 19, floorTempC: 26 },
            { pipeSpacingMM: 200, outputWperM2: 17.3, floorTempC: 25.8 },
            { pipeSpacingMM: 250, outputWperM2: 16, floorTempC: 25.7 },
            { pipeSpacingMM: 300, outputWperM2: 14.1, floorTempC: 25.5 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 40.5, floorTempC: 28 },
            { pipeSpacingMM: 150, outputWperM2: 36.5, floorTempC: 27.6 },
            { pipeSpacingMM: 200, outputWperM2: 33.1, floorTempC: 27.3 },
            { pipeSpacingMM: 250, outputWperM2: 30.6, floorTempC: 27.1 },
            { pipeSpacingMM: 300, outputWperM2: 27, floorTempC: 26.7 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 59.4, floorTempC: 29.6 },
            { pipeSpacingMM: 150, outputWperM2: 53.5, floorTempC: 29.1 },
            { pipeSpacingMM: 200, outputWperM2: 48.6, floorTempC: 28.7 },
            { pipeSpacingMM: 250, outputWperM2: 44.9, floorTempC: 28.3 },
            { pipeSpacingMM: 300, outputWperM2: 39.7, floorTempC: 27.9 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 78.2, floorTempC: 31.2 },
            { pipeSpacingMM: 150, outputWperM2: 70.5, floorTempC: 30.5 },
            { pipeSpacingMM: 200, outputWperM2: 64.1, floorTempC: 30 },
            { pipeSpacingMM: 250, outputWperM2: 59.2, floorTempC: 29.6 },
            { pipeSpacingMM: 300, outputWperM2: 52.2, floorTempC: 29 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 97, floorTempC: 32.8 },
            { pipeSpacingMM: 150, outputWperM2: 87.5, floorTempC: 32 },
            { pipeSpacingMM: 200, outputWperM2: 79.4, floorTempC: 31.3 },
            { pipeSpacingMM: 250, outputWperM2: 73.4, floorTempC: 30.8 },
            { pipeSpacingMM: 300, outputWperM2: 64.8, floorTempC: 30.1 },
          ],
        },
      },
    },
    pertEvoh: {
      16: {
        15: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 55.6, floorTempC: 20.3 },
            { pipeSpacingMM: 150, outputWperM2: 50.1, floorTempC: 19.8 },
            { pipeSpacingMM: 200, outputWperM2: 45.5, floorTempC: 19.4 },
            { pipeSpacingMM: 250, outputWperM2: 42.1, floorTempC: 19.1 },
            { pipeSpacingMM: 300, outputWperM2: 37.1, floorTempC: 18.7 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 74.5, floorTempC: 21.9 },
            { pipeSpacingMM: 150, outputWperM2: 67.1, floorTempC: 21.3 },
            { pipeSpacingMM: 200, outputWperM2: 61, floorTempC: 20.7 },
            { pipeSpacingMM: 250, outputWperM2: 56.3, floorTempC: 20.3 },
            { pipeSpacingMM: 300, outputWperM2: 49.7, floorTempC: 19.8 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 93.3, floorTempC: 23.4 },
            { pipeSpacingMM: 150, outputWperM2: 84.1, floorTempC: 22.7 },
            { pipeSpacingMM: 200, outputWperM2: 76.4, floorTempC: 22 },
            { pipeSpacingMM: 250, outputWperM2: 70.5, floorTempC: 21.5 },
            { pipeSpacingMM: 300, outputWperM2: 62.3, floorTempC: 20.9 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 112, floorTempC: 25 },
            { pipeSpacingMM: 150, outputWperM2: 101, floorTempC: 24.1 },
            { pipeSpacingMM: 200, outputWperM2: 91.7, floorTempC: 23.3 },
            { pipeSpacingMM: 250, outputWperM2: 84.7, floorTempC: 22.7 },
            { pipeSpacingMM: 300, outputWperM2: 74.8, floorTempC: 21.9 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 130.8, floorTempC: 26.5 },
            { pipeSpacingMM: 150, outputWperM2: 117.9, floorTempC: 25.5 },
            { pipeSpacingMM: 200, outputWperM2: 107.1, floorTempC: 24.6 },
            { pipeSpacingMM: 250, outputWperM2: 98.9, floorTempC: 23.9 },
            { pipeSpacingMM: 300, outputWperM2: 87.3, floorTempC: 23 },
          ],
        },
        18: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 44.3, floorTempC: 22.3 },
            { pipeSpacingMM: 150, outputWperM2: 39.9, floorTempC: 21.9 },
            { pipeSpacingMM: 200, outputWperM2: 36.2, floorTempC: 21.6 },
            { pipeSpacingMM: 250, outputWperM2: 33.5, floorTempC: 21.3 },
            { pipeSpacingMM: 300, outputWperM2: 29.6, floorTempC: 21 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 63.2, floorTempC: 23.9 },
            { pipeSpacingMM: 150, outputWperM2: 56.9, floorTempC: 23.4 },
            { pipeSpacingMM: 200, outputWperM2: 51.7, floorTempC: 22.9 },
            { pipeSpacingMM: 250, outputWperM2: 47.8, floorTempC: 22.6 },
            { pipeSpacingMM: 300, outputWperM2: 42.2, floorTempC: 22.1 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 82, floorTempC: 25.5 },
            { pipeSpacingMM: 150, outputWperM2: 73.9, floorTempC: 24.8 },
            { pipeSpacingMM: 200, outputWperM2: 67.1, floorTempC: 24.3 },
            { pipeSpacingMM: 250, outputWperM2: 62, floorTempC: 23.8 },
            { pipeSpacingMM: 300, outputWperM2: 54.7, floorTempC: 23.2 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 100.8, floorTempC: 27.1 },
            { pipeSpacingMM: 150, outputWperM2: 90.8, floorTempC: 26.2 },
            { pipeSpacingMM: 200, outputWperM2: 82.5, floorTempC: 25.6 },
            { pipeSpacingMM: 250, outputWperM2: 76.2, floorTempC: 25 },
            { pipeSpacingMM: 300, outputWperM2: 67.3, floorTempC: 24.3 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 119.5, floorTempC: 28.6 },
            { pipeSpacingMM: 150, outputWperM2: 107.8, floorTempC: 27.6 },
            { pipeSpacingMM: 200, outputWperM2: 97.9, floorTempC: 26.8 },
            { pipeSpacingMM: 250, outputWperM2: 90.4, floorTempC: 26.2 },
            { pipeSpacingMM: 300, outputWperM2: 79.8, floorTempC: 25.3 },
          ],
        },
        20: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 36.6, floorTempC: 23.6 },
            { pipeSpacingMM: 150, outputWperM2: 33, floorTempC: 23.3 },
            { pipeSpacingMM: 200, outputWperM2: 30, floorTempC: 23 },
            { pipeSpacingMM: 250, outputWperM2: 27.7, floorTempC: 22.8 },
            { pipeSpacingMM: 300, outputWperM2: 24.5, floorTempC: 22.5 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 55.6, floorTempC: 25.3 },
            { pipeSpacingMM: 150, outputWperM2: 50.1, floorTempC: 24.8 },
            { pipeSpacingMM: 200, outputWperM2: 45.5, floorTempC: 24.4 },
            { pipeSpacingMM: 250, outputWperM2: 42.1, floorTempC: 24.1 },
            { pipeSpacingMM: 300, outputWperM2: 37.1, floorTempC: 23.7 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 74.5, floorTempC: 26.9 },
            { pipeSpacingMM: 150, outputWperM2: 67.1, floorTempC: 26.3 },
            { pipeSpacingMM: 200, outputWperM2: 61, floorTempC: 25.7 },
            { pipeSpacingMM: 250, outputWperM2: 56.3, floorTempC: 25.3 },
            { pipeSpacingMM: 300, outputWperM2: 49.7, floorTempC: 24.8 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 93.3, floorTempC: 28.4 },
            { pipeSpacingMM: 150, outputWperM2: 84.1, floorTempC: 27.7 },
            { pipeSpacingMM: 200, outputWperM2: 76.4, floorTempC: 27 },
            { pipeSpacingMM: 250, outputWperM2: 70.5, floorTempC: 26.5 },
            { pipeSpacingMM: 300, outputWperM2: 62.3, floorTempC: 25.9 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 112, floorTempC: 30 },
            { pipeSpacingMM: 150, outputWperM2: 101, floorTempC: 29.1 },
            { pipeSpacingMM: 200, outputWperM2: 91.7, floorTempC: 28.3 },
            { pipeSpacingMM: 250, outputWperM2: 84.7, floorTempC: 27.7 },
            { pipeSpacingMM: 300, outputWperM2: 74.8, floorTempC: 26.9 },
          ],
        },
        22: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 28.9, floorTempC: 24.9 },
            { pipeSpacingMM: 150, outputWperM2: 26.1, floorTempC: 24.7 },
            { pipeSpacingMM: 200, outputWperM2: 23.7, floorTempC: 24.4 },
            { pipeSpacingMM: 250, outputWperM2: 21.9, floorTempC: 24.3 },
            { pipeSpacingMM: 300, outputWperM2: 19.3, floorTempC: 24 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 48.1, floorTempC: 26.6 },
            { pipeSpacingMM: 150, outputWperM2: 43.3, floorTempC: 26.2 },
            { pipeSpacingMM: 200, outputWperM2: 39.4, floorTempC: 27.3 },
            { pipeSpacingMM: 250, outputWperM2: 36.3, floorTempC: 25.6 },
            { pipeSpacingMM: 300, outputWperM2: 32.1, floorTempC: 25.2 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 66.9, floorTempC: 28.2 },
            { pipeSpacingMM: 150, outputWperM2: 60.3, floorTempC: 27.7 },
            { pipeSpacingMM: 200, outputWperM2: 54.8, floorTempC: 27.2 },
            { pipeSpacingMM: 250, outputWperM2: 50.6, floorTempC: 26.8 },
            { pipeSpacingMM: 300, outputWperM2: 44.7, floorTempC: 26.3 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 85.8, floorTempC: 29.8 },
            { pipeSpacingMM: 150, outputWperM2: 77.3, floorTempC: 29.1 },
            { pipeSpacingMM: 200, outputWperM2: 70.2, floorTempC: 28.5 },
            { pipeSpacingMM: 250, outputWperM2: 64.9, floorTempC: 28.1 },
            { pipeSpacingMM: 300, outputWperM2: 57.3, floorTempC: 27.4 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 104.5, floorTempC: 31.4 },
            { pipeSpacingMM: 150, outputWperM2: 94.2, floorTempC: 30.5 },
            { pipeSpacingMM: 200, outputWperM2: 85.6, floorTempC: 29.8 },
            { pipeSpacingMM: 250, outputWperM2: 79.1, floorTempC: 29.3 },
            { pipeSpacingMM: 300, outputWperM2: 69.8, floorTempC: 28.5 },
          ],
        },
        24: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 21.1, floorTempC: 26.2 },
            { pipeSpacingMM: 150, outputWperM2: 19, floorTempC: 26 },
            { pipeSpacingMM: 200, outputWperM2: 17.3, floorTempC: 25.8 },
            { pipeSpacingMM: 250, outputWperM2: 16, floorTempC: 25.7 },
            { pipeSpacingMM: 300, outputWperM2: 14.1, floorTempC: 25.5 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 40.5, floorTempC: 28 },
            { pipeSpacingMM: 150, outputWperM2: 36.5, floorTempC: 27.6 },
            { pipeSpacingMM: 200, outputWperM2: 33.1, floorTempC: 27.3 },
            { pipeSpacingMM: 250, outputWperM2: 30.6, floorTempC: 27.1 },
            { pipeSpacingMM: 300, outputWperM2: 27, floorTempC: 26.7 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 59.4, floorTempC: 29.6 },
            { pipeSpacingMM: 150, outputWperM2: 53.5, floorTempC: 29.1 },
            { pipeSpacingMM: 200, outputWperM2: 48.6, floorTempC: 28.7 },
            { pipeSpacingMM: 250, outputWperM2: 44.9, floorTempC: 28.3 },
            { pipeSpacingMM: 300, outputWperM2: 39.7, floorTempC: 27.9 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 78.2, floorTempC: 31.2 },
            { pipeSpacingMM: 150, outputWperM2: 70.5, floorTempC: 30.5 },
            { pipeSpacingMM: 200, outputWperM2: 64.1, floorTempC: 30 },
            { pipeSpacingMM: 250, outputWperM2: 59.2, floorTempC: 29.6 },
            { pipeSpacingMM: 300, outputWperM2: 52.2, floorTempC: 29 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 97, floorTempC: 32.8 },
            { pipeSpacingMM: 150, outputWperM2: 87.5, floorTempC: 32 },
            { pipeSpacingMM: 200, outputWperM2: 79.4, floorTempC: 31.3 },
            { pipeSpacingMM: 250, outputWperM2: 73.4, floorTempC: 30.8 },
            { pipeSpacingMM: 300, outputWperM2: 64.8, floorTempC: 30.1 },
          ],
        },
      },
    },
  },
  "Type A - 1.5 TOG - carpet, 22mm timber laminate or wood block": {
    pexSdr74: {
      16: {
        15: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 46.1, floorTempC: 19.5 },
            { pipeSpacingMM: 150, outputWperM2: 42.2, floorTempC: 19.1 },
            { pipeSpacingMM: 200, outputWperM2: 39.4, floorTempC: 18.9 },
            { pipeSpacingMM: 250, outputWperM2: 35.9, floorTempC: 18.5 },
            { pipeSpacingMM: 300, outputWperM2: 32.5, floorTempC: 18.2 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 61.7, floorTempC: 20.8 },
            { pipeSpacingMM: 150, outputWperM2: 56.5, floorTempC: 20.4 },
            { pipeSpacingMM: 200, outputWperM2: 52.8, floorTempC: 20 },
            { pipeSpacingMM: 250, outputWperM2: 48.1, floorTempC: 19.6 },
            { pipeSpacingMM: 300, outputWperM2: 43.5, floorTempC: 19.2 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 77.3, floorTempC: 22.1 },
            { pipeSpacingMM: 150, outputWperM2: 70.8, floorTempC: 21.6 },
            { pipeSpacingMM: 200, outputWperM2: 66.1, floorTempC: 21.2 },
            { pipeSpacingMM: 250, outputWperM2: 60.2, floorTempC: 20.7 },
            { pipeSpacingMM: 300, outputWperM2: 54.4, floorTempC: 20.2 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 92.8, floorTempC: 23.4 },
            { pipeSpacingMM: 150, outputWperM2: 85, floorTempC: 22.8 },
            { pipeSpacingMM: 200, outputWperM2: 79.4, floorTempC: 22.3 },
            { pipeSpacingMM: 250, outputWperM2: 72.3, floorTempC: 21.7 },
            { pipeSpacingMM: 300, outputWperM2: 65.4, floorTempC: 21.1 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 108.3, floorTempC: 24.7 },
            { pipeSpacingMM: 150, outputWperM2: 99.3, floorTempC: 23.9 },
            { pipeSpacingMM: 200, outputWperM2: 92.7, floorTempC: 23.4 },
            { pipeSpacingMM: 250, outputWperM2: 84.4, floorTempC: 22.7 },
            { pipeSpacingMM: 300, outputWperM2: 76.3, floorTempC: 22 },
          ],
        },
        18: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 36.7, floorTempC: 21.6 },
            { pipeSpacingMM: 150, outputWperM2: 33.6, floorTempC: 21.3 },
            { pipeSpacingMM: 200, outputWperM2: 31.4, floorTempC: 21.1 },
            { pipeSpacingMM: 250, outputWperM2: 28.6, floorTempC: 20.9 },
            { pipeSpacingMM: 300, outputWperM2: 25.8, floorTempC: 20.6 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 52.3, floorTempC: 23 },
            { pipeSpacingMM: 150, outputWperM2: 47.9, floorTempC: 22.6 },
            { pipeSpacingMM: 200, outputWperM2: 44.8, floorTempC: 22.3 },
            { pipeSpacingMM: 250, outputWperM2: 40.8, floorTempC: 22 },
            { pipeSpacingMM: 300, outputWperM2: 36.9, floorTempC: 21.6 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 67.9, floorTempC: 24.3 },
            { pipeSpacingMM: 150, outputWperM2: 62.2, floorTempC: 23.8 },
            { pipeSpacingMM: 200, outputWperM2: 58.1, floorTempC: 23.5 },
            { pipeSpacingMM: 250, outputWperM2: 52.9, floorTempC: 23 },
            { pipeSpacingMM: 300, outputWperM2: 47.9, floorTempC: 22.6 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 83.5, floorTempC: 25.6 },
            { pipeSpacingMM: 150, outputWperM2: 76.5, floorTempC: 25.1 },
            { pipeSpacingMM: 200, outputWperM2: 71.4, floorTempC: 24.6 },
            { pipeSpacingMM: 250, outputWperM2: 65.1, floorTempC: 24.1 },
            { pipeSpacingMM: 300, outputWperM2: 58.8, floorTempC: 23.6 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 99, floorTempC: 26.9 },
            { pipeSpacingMM: 150, outputWperM2: 90.7, floorTempC: 26.2 },
            { pipeSpacingMM: 200, outputWperM2: 84.7, floorTempC: 25.7 },
            { pipeSpacingMM: 250, outputWperM2: 77.2, floorTempC: 25.1 },
            { pipeSpacingMM: 300, outputWperM2: 69.8, floorTempC: 24.5 },
          ],
        },
        20: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 30.3, floorTempC: 23 },
            { pipeSpacingMM: 150, outputWperM2: 27.8, floorTempC: 22.8 },
            { pipeSpacingMM: 200, outputWperM2: 26, floorTempC: 22.6 },
            { pipeSpacingMM: 250, outputWperM2: 23.7, floorTempC: 22.4 },
            { pipeSpacingMM: 300, outputWperM2: 21.4, floorTempC: 22.2 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 46.1, floorTempC: 24.5 },
            { pipeSpacingMM: 150, outputWperM2: 42.2, floorTempC: 24.1 },
            { pipeSpacingMM: 200, outputWperM2: 39.4, floorTempC: 23.9 },
            { pipeSpacingMM: 250, outputWperM2: 35.9, floorTempC: 23.5 },
            { pipeSpacingMM: 300, outputWperM2: 32.5, floorTempC: 23.2 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 61.7, floorTempC: 25.8 },
            { pipeSpacingMM: 150, outputWperM2: 56.5, floorTempC: 25.4 },
            { pipeSpacingMM: 200, outputWperM2: 52.8, floorTempC: 25 },
            { pipeSpacingMM: 250, outputWperM2: 48.1, floorTempC: 24.6 },
            { pipeSpacingMM: 300, outputWperM2: 43.5, floorTempC: 24.2 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 77.3, floorTempC: 27.1 },
            { pipeSpacingMM: 150, outputWperM2: 70.8, floorTempC: 26.6 },
            { pipeSpacingMM: 200, outputWperM2: 66.1, floorTempC: 26.2 },
            { pipeSpacingMM: 250, outputWperM2: 60.2, floorTempC: 25.7 },
            { pipeSpacingMM: 300, outputWperM2: 54.4, floorTempC: 25.2 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 92.8, floorTempC: 28.4 },
            { pipeSpacingMM: 150, outputWperM2: 85, floorTempC: 27.8 },
            { pipeSpacingMM: 200, outputWperM2: 79.4, floorTempC: 27.3 },
            { pipeSpacingMM: 250, outputWperM2: 72.3, floorTempC: 26.7 },
            { pipeSpacingMM: 300, outputWperM2: 65.4, floorTempC: 26.1 },
          ],
        },
        22: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 24, floorTempC: 24.5 },
            { pipeSpacingMM: 150, outputWperM2: 22, floorTempC: 24.3 },
            { pipeSpacingMM: 200, outputWperM2: 20.5, floorTempC: 24.1 },
            { pipeSpacingMM: 250, outputWperM2: 18.7, floorTempC: 24 },
            { pipeSpacingMM: 300, outputWperM2: 16.9, floorTempC: 23.8 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 39.8, floorTempC: 25.9 },
            { pipeSpacingMM: 150, outputWperM2: 36.5, floorTempC: 25.6 },
            { pipeSpacingMM: 200, outputWperM2: 34.1, floorTempC: 25.4 },
            { pipeSpacingMM: 250, outputWperM2: 31, floorTempC: 25.1 },
            { pipeSpacingMM: 300, outputWperM2: 28, floorTempC: 24.8 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 55.5, floorTempC: 27.3 },
            { pipeSpacingMM: 150, outputWperM2: 50.8, floorTempC: 26.9 },
            { pipeSpacingMM: 200, outputWperM2: 47.5, floorTempC: 26.6 },
            { pipeSpacingMM: 250, outputWperM2: 43.2, floorTempC: 26.2 },
            { pipeSpacingMM: 300, outputWperM2: 39.1, floorTempC: 25.8 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 71, floorTempC: 28.6 },
            { pipeSpacingMM: 150, outputWperM2: 65.1, floorTempC: 28.1 },
            { pipeSpacingMM: 200, outputWperM2: 60.8, floorTempC: 27.7 },
            { pipeSpacingMM: 250, outputWperM2: 55.4, floorTempC: 27.3 },
            { pipeSpacingMM: 300, outputWperM2: 50.1, floorTempC: 26.8 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 86.6, floorTempC: 29.9 },
            { pipeSpacingMM: 150, outputWperM2: 79.3, floorTempC: 29.3 },
            { pipeSpacingMM: 200, outputWperM2: 74.1, floorTempC: 28.9 },
            { pipeSpacingMM: 250, outputWperM2: 67.5, floorTempC: 28.3 },
            { pipeSpacingMM: 300, outputWperM2: 61, floorTempC: 27.7 },
          ],
        },
        24: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 17.5, floorTempC: 25.8 },
            { pipeSpacingMM: 150, outputWperM2: 16, floorTempC: 25.7 },
            { pipeSpacingMM: 200, outputWperM2: 15, floorTempC: 25.6 },
            { pipeSpacingMM: 250, outputWperM2: 13.6, floorTempC: 25.5 },
            { pipeSpacingMM: 300, outputWperM2: 12.3, floorTempC: 25.3 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 33.5, floorTempC: 27.3 },
            { pipeSpacingMM: 150, outputWperM2: 30.7, floorTempC: 27.1 },
            { pipeSpacingMM: 200, outputWperM2: 28.7, floorTempC: 26.9 },
            { pipeSpacingMM: 250, outputWperM2: 26.1, floorTempC: 26.7 },
            { pipeSpacingMM: 300, outputWperM2: 23.6, floorTempC: 26.4 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 49.2, floorTempC: 28.7 },
            { pipeSpacingMM: 150, outputWperM2: 45.1, floorTempC: 28.4 },
            { pipeSpacingMM: 200, outputWperM2: 42.1, floorTempC: 28.1 },
            { pipeSpacingMM: 250, outputWperM2: 38.4, floorTempC: 27.8 },
            { pipeSpacingMM: 300, outputWperM2: 34.7, floorTempC: 27.4 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 64.8, floorTempC: 30.1 },
            { pipeSpacingMM: 150, outputWperM2: 59.4, floorTempC: 29.6 },
            { pipeSpacingMM: 200, outputWperM2: 55.5, floorTempC: 29.3 },
            { pipeSpacingMM: 250, outputWperM2: 50.5, floorTempC: 28.8 },
            { pipeSpacingMM: 300, outputWperM2: 45.7, floorTempC: 28.4 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 80.4, floorTempC: 31.4 },
            { pipeSpacingMM: 150, outputWperM2: 73.6, floorTempC: 30.8 },
            { pipeSpacingMM: 200, outputWperM2: 68.8, floorTempC: 30.4 },
            { pipeSpacingMM: 250, outputWperM2: 62.6, floorTempC: 29.9 },
            { pipeSpacingMM: 300, outputWperM2: 56.6, floorTempC: 29.4 },
          ],
        },
      },
    },
    pertAl: {
      16: {
        15: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 46.1, floorTempC: 19.5 },
            { pipeSpacingMM: 150, outputWperM2: 42.2, floorTempC: 19.1 },
            { pipeSpacingMM: 200, outputWperM2: 39.4, floorTempC: 18.9 },
            { pipeSpacingMM: 250, outputWperM2: 35.9, floorTempC: 18.5 },
            { pipeSpacingMM: 300, outputWperM2: 32.5, floorTempC: 18.2 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 61.7, floorTempC: 20.8 },
            { pipeSpacingMM: 150, outputWperM2: 56.5, floorTempC: 20.4 },
            { pipeSpacingMM: 200, outputWperM2: 52.8, floorTempC: 20 },
            { pipeSpacingMM: 250, outputWperM2: 48.1, floorTempC: 19.6 },
            { pipeSpacingMM: 300, outputWperM2: 43.5, floorTempC: 19.2 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 77.3, floorTempC: 22.1 },
            { pipeSpacingMM: 150, outputWperM2: 70.8, floorTempC: 21.6 },
            { pipeSpacingMM: 200, outputWperM2: 66.1, floorTempC: 21.2 },
            { pipeSpacingMM: 250, outputWperM2: 60.2, floorTempC: 20.7 },
            { pipeSpacingMM: 300, outputWperM2: 54.4, floorTempC: 20.2 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 92.8, floorTempC: 23.4 },
            { pipeSpacingMM: 150, outputWperM2: 85, floorTempC: 22.8 },
            { pipeSpacingMM: 200, outputWperM2: 79.4, floorTempC: 22.3 },
            { pipeSpacingMM: 250, outputWperM2: 72.3, floorTempC: 21.7 },
            { pipeSpacingMM: 300, outputWperM2: 65.4, floorTempC: 21.1 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 108.3, floorTempC: 24.7 },
            { pipeSpacingMM: 150, outputWperM2: 99.3, floorTempC: 23.9 },
            { pipeSpacingMM: 200, outputWperM2: 92.7, floorTempC: 23.4 },
            { pipeSpacingMM: 250, outputWperM2: 84.4, floorTempC: 22.7 },
            { pipeSpacingMM: 300, outputWperM2: 76.3, floorTempC: 22 },
          ],
        },
        18: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 36.7, floorTempC: 21.6 },
            { pipeSpacingMM: 150, outputWperM2: 33.6, floorTempC: 21.3 },
            { pipeSpacingMM: 200, outputWperM2: 31.4, floorTempC: 21.1 },
            { pipeSpacingMM: 250, outputWperM2: 28.6, floorTempC: 20.9 },
            { pipeSpacingMM: 300, outputWperM2: 25.8, floorTempC: 20.6 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 52.3, floorTempC: 23 },
            { pipeSpacingMM: 150, outputWperM2: 47.9, floorTempC: 22.6 },
            { pipeSpacingMM: 200, outputWperM2: 44.8, floorTempC: 22.3 },
            { pipeSpacingMM: 250, outputWperM2: 40.8, floorTempC: 22 },
            { pipeSpacingMM: 300, outputWperM2: 36.9, floorTempC: 21.6 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 67.9, floorTempC: 24.3 },
            { pipeSpacingMM: 150, outputWperM2: 62.2, floorTempC: 23.8 },
            { pipeSpacingMM: 200, outputWperM2: 58.1, floorTempC: 23.5 },
            { pipeSpacingMM: 250, outputWperM2: 52.9, floorTempC: 23 },
            { pipeSpacingMM: 300, outputWperM2: 47.9, floorTempC: 22.6 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 83.5, floorTempC: 25.6 },
            { pipeSpacingMM: 150, outputWperM2: 76.5, floorTempC: 25.1 },
            { pipeSpacingMM: 200, outputWperM2: 71.4, floorTempC: 24.6 },
            { pipeSpacingMM: 250, outputWperM2: 65.1, floorTempC: 24.1 },
            { pipeSpacingMM: 300, outputWperM2: 58.8, floorTempC: 23.6 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 99, floorTempC: 26.9 },
            { pipeSpacingMM: 150, outputWperM2: 90.7, floorTempC: 26.2 },
            { pipeSpacingMM: 200, outputWperM2: 84.7, floorTempC: 25.7 },
            { pipeSpacingMM: 250, outputWperM2: 77.2, floorTempC: 25.1 },
            { pipeSpacingMM: 300, outputWperM2: 69.8, floorTempC: 24.5 },
          ],
        },
        20: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 30.3, floorTempC: 23 },
            { pipeSpacingMM: 150, outputWperM2: 27.8, floorTempC: 22.8 },
            { pipeSpacingMM: 200, outputWperM2: 26, floorTempC: 22.6 },
            { pipeSpacingMM: 250, outputWperM2: 23.7, floorTempC: 22.4 },
            { pipeSpacingMM: 300, outputWperM2: 21.4, floorTempC: 22.2 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 46.1, floorTempC: 24.5 },
            { pipeSpacingMM: 150, outputWperM2: 42.2, floorTempC: 24.1 },
            { pipeSpacingMM: 200, outputWperM2: 39.4, floorTempC: 23.9 },
            { pipeSpacingMM: 250, outputWperM2: 35.9, floorTempC: 23.5 },
            { pipeSpacingMM: 300, outputWperM2: 32.5, floorTempC: 23.2 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 61.7, floorTempC: 25.8 },
            { pipeSpacingMM: 150, outputWperM2: 56.5, floorTempC: 25.4 },
            { pipeSpacingMM: 200, outputWperM2: 52.8, floorTempC: 25 },
            { pipeSpacingMM: 250, outputWperM2: 48.1, floorTempC: 24.6 },
            { pipeSpacingMM: 300, outputWperM2: 43.5, floorTempC: 24.2 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 77.3, floorTempC: 27.1 },
            { pipeSpacingMM: 150, outputWperM2: 70.8, floorTempC: 26.6 },
            { pipeSpacingMM: 200, outputWperM2: 66.1, floorTempC: 26.2 },
            { pipeSpacingMM: 250, outputWperM2: 60.2, floorTempC: 25.7 },
            { pipeSpacingMM: 300, outputWperM2: 54.4, floorTempC: 25.2 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 92.8, floorTempC: 28.4 },
            { pipeSpacingMM: 150, outputWperM2: 85, floorTempC: 27.8 },
            { pipeSpacingMM: 200, outputWperM2: 79.4, floorTempC: 27.3 },
            { pipeSpacingMM: 250, outputWperM2: 72.3, floorTempC: 26.7 },
            { pipeSpacingMM: 300, outputWperM2: 65.4, floorTempC: 26.1 },
          ],
        },
        22: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 24, floorTempC: 24.5 },
            { pipeSpacingMM: 150, outputWperM2: 22, floorTempC: 24.3 },
            { pipeSpacingMM: 200, outputWperM2: 20.5, floorTempC: 24.1 },
            { pipeSpacingMM: 250, outputWperM2: 18.7, floorTempC: 24 },
            { pipeSpacingMM: 300, outputWperM2: 16.9, floorTempC: 23.8 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 39.8, floorTempC: 25.9 },
            { pipeSpacingMM: 150, outputWperM2: 36.5, floorTempC: 25.6 },
            { pipeSpacingMM: 200, outputWperM2: 34.1, floorTempC: 25.4 },
            { pipeSpacingMM: 250, outputWperM2: 31, floorTempC: 25.1 },
            { pipeSpacingMM: 300, outputWperM2: 28, floorTempC: 24.8 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 55.5, floorTempC: 27.3 },
            { pipeSpacingMM: 150, outputWperM2: 50.8, floorTempC: 26.9 },
            { pipeSpacingMM: 200, outputWperM2: 47.5, floorTempC: 26.6 },
            { pipeSpacingMM: 250, outputWperM2: 43.2, floorTempC: 26.2 },
            { pipeSpacingMM: 300, outputWperM2: 39.1, floorTempC: 25.8 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 71, floorTempC: 28.6 },
            { pipeSpacingMM: 150, outputWperM2: 65.1, floorTempC: 28.1 },
            { pipeSpacingMM: 200, outputWperM2: 60.8, floorTempC: 27.7 },
            { pipeSpacingMM: 250, outputWperM2: 55.4, floorTempC: 27.3 },
            { pipeSpacingMM: 300, outputWperM2: 50.1, floorTempC: 26.8 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 86.6, floorTempC: 29.9 },
            { pipeSpacingMM: 150, outputWperM2: 79.3, floorTempC: 29.3 },
            { pipeSpacingMM: 200, outputWperM2: 74.1, floorTempC: 28.9 },
            { pipeSpacingMM: 250, outputWperM2: 67.5, floorTempC: 28.3 },
            { pipeSpacingMM: 300, outputWperM2: 61, floorTempC: 27.7 },
          ],
        },
        24: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 17.5, floorTempC: 25.8 },
            { pipeSpacingMM: 150, outputWperM2: 16, floorTempC: 25.7 },
            { pipeSpacingMM: 200, outputWperM2: 15, floorTempC: 25.6 },
            { pipeSpacingMM: 250, outputWperM2: 13.6, floorTempC: 25.5 },
            { pipeSpacingMM: 300, outputWperM2: 12.3, floorTempC: 25.3 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 33.5, floorTempC: 27.3 },
            { pipeSpacingMM: 150, outputWperM2: 30.7, floorTempC: 27.1 },
            { pipeSpacingMM: 200, outputWperM2: 28.7, floorTempC: 26.9 },
            { pipeSpacingMM: 250, outputWperM2: 26.1, floorTempC: 26.7 },
            { pipeSpacingMM: 300, outputWperM2: 23.6, floorTempC: 26.4 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 49.2, floorTempC: 28.7 },
            { pipeSpacingMM: 150, outputWperM2: 45.1, floorTempC: 28.4 },
            { pipeSpacingMM: 200, outputWperM2: 42.1, floorTempC: 28.1 },
            { pipeSpacingMM: 250, outputWperM2: 38.4, floorTempC: 27.8 },
            { pipeSpacingMM: 300, outputWperM2: 34.7, floorTempC: 27.4 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 64.8, floorTempC: 30.1 },
            { pipeSpacingMM: 150, outputWperM2: 59.4, floorTempC: 29.6 },
            { pipeSpacingMM: 200, outputWperM2: 55.5, floorTempC: 29.3 },
            { pipeSpacingMM: 250, outputWperM2: 50.5, floorTempC: 28.8 },
            { pipeSpacingMM: 300, outputWperM2: 45.7, floorTempC: 28.4 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 80.4, floorTempC: 31.4 },
            { pipeSpacingMM: 150, outputWperM2: 73.6, floorTempC: 30.8 },
            { pipeSpacingMM: 200, outputWperM2: 68.8, floorTempC: 30.4 },
            { pipeSpacingMM: 250, outputWperM2: 62.6, floorTempC: 29.9 },
            { pipeSpacingMM: 300, outputWperM2: 56.6, floorTempC: 29.4 },
          ],
        },
      },
    },
    pertEvoh: {
      16: {
        15: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 46.1, floorTempC: 19.5 },
            { pipeSpacingMM: 150, outputWperM2: 42.2, floorTempC: 19.1 },
            { pipeSpacingMM: 200, outputWperM2: 39.4, floorTempC: 18.9 },
            { pipeSpacingMM: 250, outputWperM2: 35.9, floorTempC: 18.5 },
            { pipeSpacingMM: 300, outputWperM2: 32.5, floorTempC: 18.2 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 61.7, floorTempC: 20.8 },
            { pipeSpacingMM: 150, outputWperM2: 56.5, floorTempC: 20.4 },
            { pipeSpacingMM: 200, outputWperM2: 52.8, floorTempC: 20 },
            { pipeSpacingMM: 250, outputWperM2: 48.1, floorTempC: 19.6 },
            { pipeSpacingMM: 300, outputWperM2: 43.5, floorTempC: 19.2 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 77.3, floorTempC: 22.1 },
            { pipeSpacingMM: 150, outputWperM2: 70.8, floorTempC: 21.6 },
            { pipeSpacingMM: 200, outputWperM2: 66.1, floorTempC: 21.2 },
            { pipeSpacingMM: 250, outputWperM2: 60.2, floorTempC: 20.7 },
            { pipeSpacingMM: 300, outputWperM2: 54.4, floorTempC: 20.2 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 92.8, floorTempC: 23.4 },
            { pipeSpacingMM: 150, outputWperM2: 85, floorTempC: 22.8 },
            { pipeSpacingMM: 200, outputWperM2: 79.4, floorTempC: 22.3 },
            { pipeSpacingMM: 250, outputWperM2: 72.3, floorTempC: 21.7 },
            { pipeSpacingMM: 300, outputWperM2: 65.4, floorTempC: 21.1 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 108.3, floorTempC: 24.7 },
            { pipeSpacingMM: 150, outputWperM2: 99.3, floorTempC: 23.9 },
            { pipeSpacingMM: 200, outputWperM2: 92.7, floorTempC: 23.4 },
            { pipeSpacingMM: 250, outputWperM2: 84.4, floorTempC: 22.7 },
            { pipeSpacingMM: 300, outputWperM2: 76.3, floorTempC: 22 },
          ],
        },
        18: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 36.7, floorTempC: 21.6 },
            { pipeSpacingMM: 150, outputWperM2: 33.6, floorTempC: 21.3 },
            { pipeSpacingMM: 200, outputWperM2: 31.4, floorTempC: 21.1 },
            { pipeSpacingMM: 250, outputWperM2: 28.6, floorTempC: 20.9 },
            { pipeSpacingMM: 300, outputWperM2: 25.8, floorTempC: 20.6 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 52.3, floorTempC: 23 },
            { pipeSpacingMM: 150, outputWperM2: 47.9, floorTempC: 22.6 },
            { pipeSpacingMM: 200, outputWperM2: 44.8, floorTempC: 22.3 },
            { pipeSpacingMM: 250, outputWperM2: 40.8, floorTempC: 22 },
            { pipeSpacingMM: 300, outputWperM2: 36.9, floorTempC: 21.6 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 67.9, floorTempC: 24.3 },
            { pipeSpacingMM: 150, outputWperM2: 62.2, floorTempC: 23.8 },
            { pipeSpacingMM: 200, outputWperM2: 58.1, floorTempC: 23.5 },
            { pipeSpacingMM: 250, outputWperM2: 52.9, floorTempC: 23 },
            { pipeSpacingMM: 300, outputWperM2: 47.9, floorTempC: 22.6 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 83.5, floorTempC: 25.6 },
            { pipeSpacingMM: 150, outputWperM2: 76.5, floorTempC: 25.1 },
            { pipeSpacingMM: 200, outputWperM2: 71.4, floorTempC: 24.6 },
            { pipeSpacingMM: 250, outputWperM2: 65.1, floorTempC: 24.1 },
            { pipeSpacingMM: 300, outputWperM2: 58.8, floorTempC: 23.6 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 99, floorTempC: 26.9 },
            { pipeSpacingMM: 150, outputWperM2: 90.7, floorTempC: 26.2 },
            { pipeSpacingMM: 200, outputWperM2: 84.7, floorTempC: 25.7 },
            { pipeSpacingMM: 250, outputWperM2: 77.2, floorTempC: 25.1 },
            { pipeSpacingMM: 300, outputWperM2: 69.8, floorTempC: 24.5 },
          ],
        },
        20: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 30.3, floorTempC: 23 },
            { pipeSpacingMM: 150, outputWperM2: 27.8, floorTempC: 22.8 },
            { pipeSpacingMM: 200, outputWperM2: 26, floorTempC: 22.6 },
            { pipeSpacingMM: 250, outputWperM2: 23.7, floorTempC: 22.4 },
            { pipeSpacingMM: 300, outputWperM2: 21.4, floorTempC: 22.2 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 46.1, floorTempC: 24.5 },
            { pipeSpacingMM: 150, outputWperM2: 42.2, floorTempC: 24.1 },
            { pipeSpacingMM: 200, outputWperM2: 39.4, floorTempC: 23.9 },
            { pipeSpacingMM: 250, outputWperM2: 35.9, floorTempC: 23.5 },
            { pipeSpacingMM: 300, outputWperM2: 32.5, floorTempC: 23.2 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 61.7, floorTempC: 25.8 },
            { pipeSpacingMM: 150, outputWperM2: 56.5, floorTempC: 25.4 },
            { pipeSpacingMM: 200, outputWperM2: 52.8, floorTempC: 25 },
            { pipeSpacingMM: 250, outputWperM2: 48.1, floorTempC: 24.6 },
            { pipeSpacingMM: 300, outputWperM2: 43.5, floorTempC: 24.2 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 77.3, floorTempC: 27.1 },
            { pipeSpacingMM: 150, outputWperM2: 70.8, floorTempC: 26.6 },
            { pipeSpacingMM: 200, outputWperM2: 66.1, floorTempC: 26.2 },
            { pipeSpacingMM: 250, outputWperM2: 60.2, floorTempC: 25.7 },
            { pipeSpacingMM: 300, outputWperM2: 54.4, floorTempC: 25.2 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 92.8, floorTempC: 28.4 },
            { pipeSpacingMM: 150, outputWperM2: 85, floorTempC: 27.8 },
            { pipeSpacingMM: 200, outputWperM2: 79.4, floorTempC: 27.3 },
            { pipeSpacingMM: 250, outputWperM2: 72.3, floorTempC: 26.7 },
            { pipeSpacingMM: 300, outputWperM2: 65.4, floorTempC: 26.1 },
          ],
        },
        22: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 24, floorTempC: 24.5 },
            { pipeSpacingMM: 150, outputWperM2: 22, floorTempC: 24.3 },
            { pipeSpacingMM: 200, outputWperM2: 20.5, floorTempC: 24.1 },
            { pipeSpacingMM: 250, outputWperM2: 18.7, floorTempC: 24 },
            { pipeSpacingMM: 300, outputWperM2: 16.9, floorTempC: 23.8 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 39.8, floorTempC: 25.9 },
            { pipeSpacingMM: 150, outputWperM2: 36.5, floorTempC: 25.6 },
            { pipeSpacingMM: 200, outputWperM2: 34.1, floorTempC: 25.4 },
            { pipeSpacingMM: 250, outputWperM2: 31, floorTempC: 25.1 },
            { pipeSpacingMM: 300, outputWperM2: 28, floorTempC: 24.8 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 55.5, floorTempC: 27.3 },
            { pipeSpacingMM: 150, outputWperM2: 50.8, floorTempC: 26.9 },
            { pipeSpacingMM: 200, outputWperM2: 47.5, floorTempC: 26.6 },
            { pipeSpacingMM: 250, outputWperM2: 43.2, floorTempC: 26.2 },
            { pipeSpacingMM: 300, outputWperM2: 39.1, floorTempC: 25.8 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 71, floorTempC: 28.6 },
            { pipeSpacingMM: 150, outputWperM2: 65.1, floorTempC: 28.1 },
            { pipeSpacingMM: 200, outputWperM2: 60.8, floorTempC: 27.7 },
            { pipeSpacingMM: 250, outputWperM2: 55.4, floorTempC: 27.3 },
            { pipeSpacingMM: 300, outputWperM2: 50.1, floorTempC: 26.8 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 86.6, floorTempC: 29.9 },
            { pipeSpacingMM: 150, outputWperM2: 79.3, floorTempC: 29.3 },
            { pipeSpacingMM: 200, outputWperM2: 74.1, floorTempC: 28.9 },
            { pipeSpacingMM: 250, outputWperM2: 67.5, floorTempC: 28.3 },
            { pipeSpacingMM: 300, outputWperM2: 61, floorTempC: 27.7 },
          ],
        },
        24: {
          30: [
            { pipeSpacingMM: 100, outputWperM2: 17.5, floorTempC: 25.8 },
            { pipeSpacingMM: 150, outputWperM2: 16, floorTempC: 25.7 },
            { pipeSpacingMM: 200, outputWperM2: 15, floorTempC: 25.6 },
            { pipeSpacingMM: 250, outputWperM2: 13.6, floorTempC: 25.5 },
            { pipeSpacingMM: 300, outputWperM2: 12.3, floorTempC: 25.3 },
          ],
          35: [
            { pipeSpacingMM: 100, outputWperM2: 33.5, floorTempC: 27.3 },
            { pipeSpacingMM: 150, outputWperM2: 30.7, floorTempC: 27.1 },
            { pipeSpacingMM: 200, outputWperM2: 28.7, floorTempC: 26.9 },
            { pipeSpacingMM: 250, outputWperM2: 26.1, floorTempC: 26.7 },
            { pipeSpacingMM: 300, outputWperM2: 23.6, floorTempC: 26.4 },
          ],
          40: [
            { pipeSpacingMM: 100, outputWperM2: 49.2, floorTempC: 28.7 },
            { pipeSpacingMM: 150, outputWperM2: 45.1, floorTempC: 28.4 },
            { pipeSpacingMM: 200, outputWperM2: 42.1, floorTempC: 28.1 },
            { pipeSpacingMM: 250, outputWperM2: 38.4, floorTempC: 27.8 },
            { pipeSpacingMM: 300, outputWperM2: 34.7, floorTempC: 27.4 },
          ],
          45: [
            { pipeSpacingMM: 100, outputWperM2: 64.8, floorTempC: 30.1 },
            { pipeSpacingMM: 150, outputWperM2: 59.4, floorTempC: 29.6 },
            { pipeSpacingMM: 200, outputWperM2: 55.5, floorTempC: 29.3 },
            { pipeSpacingMM: 250, outputWperM2: 50.5, floorTempC: 28.8 },
            { pipeSpacingMM: 300, outputWperM2: 45.7, floorTempC: 28.4 },
          ],
          50: [
            { pipeSpacingMM: 100, outputWperM2: 80.4, floorTempC: 31.4 },
            { pipeSpacingMM: 150, outputWperM2: 73.6, floorTempC: 30.8 },
            { pipeSpacingMM: 200, outputWperM2: 68.8, floorTempC: 30.4 },
            { pipeSpacingMM: 250, outputWperM2: 62.6, floorTempC: 29.9 },
            { pipeSpacingMM: 300, outputWperM2: 56.6, floorTempC: 29.4 },
          ],
        },
      },
    },
  },
  "Type B or Type B1 - 0 TOG - UFH Metal Conductor System Heat Output from Timber covered floors containing Aluminium (0.5mm) Heat Conductors":
    {
      pexSdr74: {
        16: {
          15: {
            35: [{ pipeSpacingMM: 200, outputWperM2: 61, floorTempC: 20.7 }],
            40: [{ pipeSpacingMM: 200, outputWperM2: 76, floorTempC: 22 }],
            45: [{ pipeSpacingMM: 200, outputWperM2: 91, floorTempC: 23.3 }],
            50: [{ pipeSpacingMM: 200, outputWperM2: 106, floorTempC: 24.5 }],
            55: [{ pipeSpacingMM: 200, outputWperM2: 121, floorTempC: 25.7 }],
          },
          18: {
            35: [{ pipeSpacingMM: 200, outputWperM2: 52, floorTempC: 22.9 }],
            40: [{ pipeSpacingMM: 200, outputWperM2: 67, floorTempC: 24.2 }],
            45: [{ pipeSpacingMM: 200, outputWperM2: 82, floorTempC: 25.5 }],
            50: [{ pipeSpacingMM: 200, outputWperM2: 97, floorTempC: 26.8 }],
            55: [{ pipeSpacingMM: 200, outputWperM2: 112, floorTempC: 28.1 }],
          },
          20: {
            35: [{ pipeSpacingMM: 200, outputWperM2: 45, floorTempC: 24.4 }],
            40: [{ pipeSpacingMM: 200, outputWperM2: 61, floorTempC: 25.7 }],
            45: [{ pipeSpacingMM: 200, outputWperM2: 76, floorTempC: 27 }],
            50: [{ pipeSpacingMM: 200, outputWperM2: 91, floorTempC: 28.3 }],
            55: [{ pipeSpacingMM: 200, outputWperM2: 106, floorTempC: 29.5 }],
          },
          22: {
            35: [{ pipeSpacingMM: 200, outputWperM2: 39, floorTempC: 25.9 }],
            40: [{ pipeSpacingMM: 200, outputWperM2: 55, floorTempC: 27.2 }],
            45: [{ pipeSpacingMM: 200, outputWperM2: 70, floorTempC: 28.5 }],
            50: [{ pipeSpacingMM: 200, outputWperM2: 85, floorTempC: 29.8 }],
            55: [{ pipeSpacingMM: 200, outputWperM2: 100, floorTempC: 31 }],
          },
          24: {
            35: [{ pipeSpacingMM: 200, outputWperM2: 33, floorTempC: 27.3 }],
            40: [{ pipeSpacingMM: 200, outputWperM2: 48, floorTempC: 28.7 }],
            45: [{ pipeSpacingMM: 200, outputWperM2: 64, floorTempC: 30 }],
            50: [{ pipeSpacingMM: 200, outputWperM2: 79, floorTempC: 31.2 }],
            55: [{ pipeSpacingMM: 200, outputWperM2: 94, floorTempC: 32.5 }],
          },
        },
      },
      pertAl: {
        16: {
          15: {
            35: [{ pipeSpacingMM: 200, outputWperM2: 61, floorTempC: 20.7 }],
            40: [{ pipeSpacingMM: 200, outputWperM2: 76, floorTempC: 22 }],
            45: [{ pipeSpacingMM: 200, outputWperM2: 91, floorTempC: 23.3 }],
            50: [{ pipeSpacingMM: 200, outputWperM2: 106, floorTempC: 24.5 }],
            55: [{ pipeSpacingMM: 200, outputWperM2: 121, floorTempC: 25.7 }],
          },
          18: {
            35: [{ pipeSpacingMM: 200, outputWperM2: 52, floorTempC: 22.9 }],
            40: [{ pipeSpacingMM: 200, outputWperM2: 67, floorTempC: 24.2 }],
            45: [{ pipeSpacingMM: 200, outputWperM2: 82, floorTempC: 25.5 }],
            50: [{ pipeSpacingMM: 200, outputWperM2: 97, floorTempC: 26.8 }],
            55: [{ pipeSpacingMM: 200, outputWperM2: 112, floorTempC: 28.1 }],
          },
          20: {
            35: [{ pipeSpacingMM: 200, outputWperM2: 45, floorTempC: 24.4 }],
            40: [{ pipeSpacingMM: 200, outputWperM2: 61, floorTempC: 25.7 }],
            45: [{ pipeSpacingMM: 200, outputWperM2: 76, floorTempC: 27 }],
            50: [{ pipeSpacingMM: 200, outputWperM2: 91, floorTempC: 28.3 }],
            55: [{ pipeSpacingMM: 200, outputWperM2: 106, floorTempC: 29.5 }],
          },
          22: {
            35: [{ pipeSpacingMM: 200, outputWperM2: 39, floorTempC: 25.9 }],
            40: [{ pipeSpacingMM: 200, outputWperM2: 55, floorTempC: 27.2 }],
            45: [{ pipeSpacingMM: 200, outputWperM2: 70, floorTempC: 28.5 }],
            50: [{ pipeSpacingMM: 200, outputWperM2: 85, floorTempC: 29.8 }],
            55: [{ pipeSpacingMM: 200, outputWperM2: 100, floorTempC: 31 }],
          },
          24: {
            35: [{ pipeSpacingMM: 200, outputWperM2: 33, floorTempC: 27.3 }],
            40: [{ pipeSpacingMM: 200, outputWperM2: 48, floorTempC: 28.7 }],
            45: [{ pipeSpacingMM: 200, outputWperM2: 64, floorTempC: 30 }],
            50: [{ pipeSpacingMM: 200, outputWperM2: 79, floorTempC: 31.2 }],
            55: [{ pipeSpacingMM: 200, outputWperM2: 94, floorTempC: 32.5 }],
          },
        },
      },
      pertEvoh: {
        16: {
          15: {
            35: [{ pipeSpacingMM: 200, outputWperM2: 61, floorTempC: 20.7 }],
            40: [{ pipeSpacingMM: 200, outputWperM2: 76, floorTempC: 22 }],
            45: [{ pipeSpacingMM: 200, outputWperM2: 91, floorTempC: 23.3 }],
            50: [{ pipeSpacingMM: 200, outputWperM2: 106, floorTempC: 24.5 }],
            55: [{ pipeSpacingMM: 200, outputWperM2: 121, floorTempC: 25.7 }],
          },
          18: {
            35: [{ pipeSpacingMM: 200, outputWperM2: 52, floorTempC: 22.9 }],
            40: [{ pipeSpacingMM: 200, outputWperM2: 67, floorTempC: 24.2 }],
            45: [{ pipeSpacingMM: 200, outputWperM2: 82, floorTempC: 25.5 }],
            50: [{ pipeSpacingMM: 200, outputWperM2: 97, floorTempC: 26.8 }],
            55: [{ pipeSpacingMM: 200, outputWperM2: 112, floorTempC: 28.1 }],
          },
          20: {
            35: [{ pipeSpacingMM: 200, outputWperM2: 45, floorTempC: 24.4 }],
            40: [{ pipeSpacingMM: 200, outputWperM2: 61, floorTempC: 25.7 }],
            45: [{ pipeSpacingMM: 200, outputWperM2: 76, floorTempC: 27 }],
            50: [{ pipeSpacingMM: 200, outputWperM2: 91, floorTempC: 28.3 }],
            55: [{ pipeSpacingMM: 200, outputWperM2: 106, floorTempC: 29.5 }],
          },
          22: {
            35: [{ pipeSpacingMM: 200, outputWperM2: 39, floorTempC: 25.9 }],
            40: [{ pipeSpacingMM: 200, outputWperM2: 55, floorTempC: 27.2 }],
            45: [{ pipeSpacingMM: 200, outputWperM2: 70, floorTempC: 28.5 }],
            50: [{ pipeSpacingMM: 200, outputWperM2: 85, floorTempC: 29.8 }],
            55: [{ pipeSpacingMM: 200, outputWperM2: 100, floorTempC: 31 }],
          },
          24: {
            35: [{ pipeSpacingMM: 200, outputWperM2: 33, floorTempC: 27.3 }],
            40: [{ pipeSpacingMM: 200, outputWperM2: 48, floorTempC: 28.7 }],
            45: [{ pipeSpacingMM: 200, outputWperM2: 64, floorTempC: 30 }],
            50: [{ pipeSpacingMM: 200, outputWperM2: 79, floorTempC: 31.2 }],
            55: [{ pipeSpacingMM: 200, outputWperM2: 94, floorTempC: 32.5 }],
          },
        },
      },
    },
  "Type B or Type B1 - 1 TOG - UFH Metal Conductor System Heat Output from Timber covered floors containing Aluminium (0.5mm) Heat Conductors":
    {
      pexSdr74: {
        16: {
          15: {
            35: [{ pipeSpacingMM: 150, outputWperM2: 47, floorTempC: 19.5 }],
            40: [{ pipeSpacingMM: 150, outputWperM2: 58, floorTempC: 20.5 }],
            45: [{ pipeSpacingMM: 150, outputWperM2: 70, floorTempC: 21.5 }],
            50: [{ pipeSpacingMM: 150, outputWperM2: 81, floorTempC: 22.5 }],
            55: [{ pipeSpacingMM: 150, outputWperM2: 93, floorTempC: 23.4 }],
          },
          18: {
            35: [{ pipeSpacingMM: 150, outputWperM2: 40, floorTempC: 21.9 }],
            40: [{ pipeSpacingMM: 150, outputWperM2: 51, floorTempC: 22.9 }],
            45: [{ pipeSpacingMM: 150, outputWperM2: 63, floorTempC: 23.9 }],
            50: [{ pipeSpacingMM: 150, outputWperM2: 74, floorTempC: 24.9 }],
            55: [{ pipeSpacingMM: 150, outputWperM2: 86, floorTempC: 25.9 }],
          },
          20: {
            35: [{ pipeSpacingMM: 150, outputWperM2: 35, floorTempC: 23.5 }],
            40: [{ pipeSpacingMM: 150, outputWperM2: 47, floorTempC: 24.5 }],
            45: [{ pipeSpacingMM: 150, outputWperM2: 58, floorTempC: 25.5 }],
            50: [{ pipeSpacingMM: 150, outputWperM2: 70, floorTempC: 26.5 }],
            55: [{ pipeSpacingMM: 150, outputWperM2: 81, floorTempC: 27.5 }],
          },
          22: {
            35: [{ pipeSpacingMM: 150, outputWperM2: 30, floorTempC: 25 }],
            40: [{ pipeSpacingMM: 150, outputWperM2: 42, floorTempC: 26.1 }],
            45: [{ pipeSpacingMM: 150, outputWperM2: 53, floorTempC: 27.1 }],
            50: [{ pipeSpacingMM: 150, outputWperM2: 65, floorTempC: 28.1 }],
            55: [{ pipeSpacingMM: 150, outputWperM2: 77, floorTempC: 29.1 }],
          },
          24: {
            35: [{ pipeSpacingMM: 150, outputWperM2: 26, floorTempC: 26.6 }],
            40: [{ pipeSpacingMM: 150, outputWperM2: 37, floorTempC: 27.7 }],
            45: [{ pipeSpacingMM: 150, outputWperM2: 49, floorTempC: 28.7 }],
            50: [{ pipeSpacingMM: 150, outputWperM2: 60, floorTempC: 29.7 }],
            55: [{ pipeSpacingMM: 150, outputWperM2: 72, floorTempC: 30.7 }],
          },
        },
      },
      pertAl: {
        16: {
          15: {
            35: [{ pipeSpacingMM: 150, outputWperM2: 47, floorTempC: 19.5 }],
            40: [{ pipeSpacingMM: 150, outputWperM2: 58, floorTempC: 20.5 }],
            45: [{ pipeSpacingMM: 150, outputWperM2: 70, floorTempC: 21.5 }],
            50: [{ pipeSpacingMM: 150, outputWperM2: 81, floorTempC: 22.5 }],
            55: [{ pipeSpacingMM: 150, outputWperM2: 93, floorTempC: 23.4 }],
          },
          18: {
            35: [{ pipeSpacingMM: 150, outputWperM2: 40, floorTempC: 21.9 }],
            40: [{ pipeSpacingMM: 150, outputWperM2: 51, floorTempC: 22.9 }],
            45: [{ pipeSpacingMM: 150, outputWperM2: 63, floorTempC: 23.9 }],
            50: [{ pipeSpacingMM: 150, outputWperM2: 74, floorTempC: 24.9 }],
            55: [{ pipeSpacingMM: 150, outputWperM2: 86, floorTempC: 25.9 }],
          },
          20: {
            35: [{ pipeSpacingMM: 150, outputWperM2: 35, floorTempC: 23.5 }],
            40: [{ pipeSpacingMM: 150, outputWperM2: 47, floorTempC: 24.5 }],
            45: [{ pipeSpacingMM: 150, outputWperM2: 58, floorTempC: 25.5 }],
            50: [{ pipeSpacingMM: 150, outputWperM2: 70, floorTempC: 26.5 }],
            55: [{ pipeSpacingMM: 150, outputWperM2: 81, floorTempC: 27.5 }],
          },
          22: {
            35: [{ pipeSpacingMM: 150, outputWperM2: 30, floorTempC: 25 }],
            40: [{ pipeSpacingMM: 150, outputWperM2: 42, floorTempC: 26.1 }],
            45: [{ pipeSpacingMM: 150, outputWperM2: 53, floorTempC: 27.1 }],
            50: [{ pipeSpacingMM: 150, outputWperM2: 65, floorTempC: 28.1 }],
            55: [{ pipeSpacingMM: 150, outputWperM2: 77, floorTempC: 29.1 }],
          },
          24: {
            35: [{ pipeSpacingMM: 150, outputWperM2: 26, floorTempC: 26.6 }],
            40: [{ pipeSpacingMM: 150, outputWperM2: 37, floorTempC: 27.7 }],
            45: [{ pipeSpacingMM: 150, outputWperM2: 49, floorTempC: 28.7 }],
            50: [{ pipeSpacingMM: 150, outputWperM2: 60, floorTempC: 29.7 }],
            55: [{ pipeSpacingMM: 150, outputWperM2: 72, floorTempC: 30.7 }],
          },
        },
      },
      pertEvoh: {
        16: {
          15: {
            35: [{ pipeSpacingMM: 150, outputWperM2: 47, floorTempC: 19.5 }],
            40: [{ pipeSpacingMM: 150, outputWperM2: 58, floorTempC: 20.5 }],
            45: [{ pipeSpacingMM: 150, outputWperM2: 70, floorTempC: 21.5 }],
            50: [{ pipeSpacingMM: 150, outputWperM2: 81, floorTempC: 22.5 }],
            55: [{ pipeSpacingMM: 150, outputWperM2: 93, floorTempC: 23.4 }],
          },
          18: {
            35: [{ pipeSpacingMM: 150, outputWperM2: 40, floorTempC: 21.9 }],
            40: [{ pipeSpacingMM: 150, outputWperM2: 51, floorTempC: 22.9 }],
            45: [{ pipeSpacingMM: 150, outputWperM2: 63, floorTempC: 23.9 }],
            50: [{ pipeSpacingMM: 150, outputWperM2: 74, floorTempC: 24.9 }],
            55: [{ pipeSpacingMM: 150, outputWperM2: 86, floorTempC: 25.9 }],
          },
          20: {
            35: [{ pipeSpacingMM: 150, outputWperM2: 35, floorTempC: 23.5 }],
            40: [{ pipeSpacingMM: 150, outputWperM2: 47, floorTempC: 24.5 }],
            45: [{ pipeSpacingMM: 150, outputWperM2: 58, floorTempC: 25.5 }],
            50: [{ pipeSpacingMM: 150, outputWperM2: 70, floorTempC: 26.5 }],
            55: [{ pipeSpacingMM: 150, outputWperM2: 81, floorTempC: 27.5 }],
          },
          22: {
            35: [{ pipeSpacingMM: 150, outputWperM2: 30, floorTempC: 25 }],
            40: [{ pipeSpacingMM: 150, outputWperM2: 42, floorTempC: 26.1 }],
            45: [{ pipeSpacingMM: 150, outputWperM2: 53, floorTempC: 27.1 }],
            50: [{ pipeSpacingMM: 150, outputWperM2: 65, floorTempC: 28.1 }],
            55: [{ pipeSpacingMM: 150, outputWperM2: 77, floorTempC: 29.1 }],
          },
          24: {
            35: [{ pipeSpacingMM: 150, outputWperM2: 26, floorTempC: 26.6 }],
            40: [{ pipeSpacingMM: 150, outputWperM2: 37, floorTempC: 27.7 }],
            45: [{ pipeSpacingMM: 150, outputWperM2: 49, floorTempC: 28.7 }],
            50: [{ pipeSpacingMM: 150, outputWperM2: 60, floorTempC: 29.7 }],
            55: [{ pipeSpacingMM: 150, outputWperM2: 72, floorTempC: 30.7 }],
          },
        },
      },
    },
  "Type B or Type B1 - 2 TOG - UFH Metal Conductor System Heat Output from Timber covered floors containing Aluminium (0.5mm) Heat Conductors":
    {
      pexSdr74: {
        16: {
          15: {
            35: [{ pipeSpacingMM: 100, outputWperM2: 38, floorTempC: 18.7 }],
            40: [{ pipeSpacingMM: 100, outputWperM2: 47, floorTempC: 19.5 }],
            45: [{ pipeSpacingMM: 100, outputWperM2: 57, floorTempC: 20.4 }],
            50: [{ pipeSpacingMM: 100, outputWperM2: 66, floorTempC: 21.2 }],
            55: [{ pipeSpacingMM: 100, outputWperM2: 75, floorTempC: 22 }],
          },
          18: {
            35: [{ pipeSpacingMM: 100, outputWperM2: 32, floorTempC: 21.2 }],
            40: [{ pipeSpacingMM: 100, outputWperM2: 42, floorTempC: 22 }],
            45: [{ pipeSpacingMM: 100, outputWperM2: 51, floorTempC: 22.9 }],
            50: [{ pipeSpacingMM: 100, outputWperM2: 60, floorTempC: 23.7 }],
            55: [{ pipeSpacingMM: 100, outputWperM2: 70, floorTempC: 24.5 }],
          },
          20: {
            35: [{ pipeSpacingMM: 100, outputWperM2: 28, floorTempC: 22.9 }],
            40: [{ pipeSpacingMM: 100, outputWperM2: 38, floorTempC: 23.7 }],
            45: [{ pipeSpacingMM: 100, outputWperM2: 47, floorTempC: 24.5 }],
            50: [{ pipeSpacingMM: 100, outputWperM2: 57, floorTempC: 25.4 }],
            55: [{ pipeSpacingMM: 100, outputWperM2: 66, floorTempC: 26.2 }],
          },
          22: {
            35: [{ pipeSpacingMM: 100, outputWperM2: 25, floorTempC: 24.5 }],
            40: [{ pipeSpacingMM: 100, outputWperM2: 34, floorTempC: 25.4 }],
            45: [{ pipeSpacingMM: 100, outputWperM2: 43, floorTempC: 26.2 }],
            50: [{ pipeSpacingMM: 100, outputWperM2: 53, floorTempC: 27 }],
            55: [{ pipeSpacingMM: 100, outputWperM2: 62, floorTempC: 27.9 }],
          },
          24: {
            35: [{ pipeSpacingMM: 100, outputWperM2: 21, floorTempC: 26.2 }],
            40: [{ pipeSpacingMM: 100, outputWperM2: 30, floorTempC: 27 }],
            45: [{ pipeSpacingMM: 100, outputWperM2: 40, floorTempC: 27.9 }],
            50: [{ pipeSpacingMM: 100, outputWperM2: 49, floorTempC: 28.7 }],
            55: [{ pipeSpacingMM: 100, outputWperM2: 58, floorTempC: 29.5 }],
          },
        },
      },
      pertAl: {
        16: {
          15: {
            35: [{ pipeSpacingMM: 100, outputWperM2: 38, floorTempC: 18.7 }],
            40: [{ pipeSpacingMM: 100, outputWperM2: 47, floorTempC: 19.5 }],
            45: [{ pipeSpacingMM: 100, outputWperM2: 57, floorTempC: 20.4 }],
            50: [{ pipeSpacingMM: 100, outputWperM2: 66, floorTempC: 21.2 }],
            55: [{ pipeSpacingMM: 100, outputWperM2: 75, floorTempC: 22 }],
          },
          18: {
            35: [{ pipeSpacingMM: 100, outputWperM2: 32, floorTempC: 21.2 }],
            40: [{ pipeSpacingMM: 100, outputWperM2: 42, floorTempC: 22 }],
            45: [{ pipeSpacingMM: 100, outputWperM2: 51, floorTempC: 22.9 }],
            50: [{ pipeSpacingMM: 100, outputWperM2: 60, floorTempC: 23.7 }],
            55: [{ pipeSpacingMM: 100, outputWperM2: 70, floorTempC: 24.5 }],
          },
          20: {
            35: [{ pipeSpacingMM: 100, outputWperM2: 28, floorTempC: 22.9 }],
            40: [{ pipeSpacingMM: 100, outputWperM2: 38, floorTempC: 23.7 }],
            45: [{ pipeSpacingMM: 100, outputWperM2: 47, floorTempC: 24.5 }],
            50: [{ pipeSpacingMM: 100, outputWperM2: 57, floorTempC: 25.4 }],
            55: [{ pipeSpacingMM: 100, outputWperM2: 66, floorTempC: 26.2 }],
          },
          22: {
            35: [{ pipeSpacingMM: 100, outputWperM2: 25, floorTempC: 24.5 }],
            40: [{ pipeSpacingMM: 100, outputWperM2: 34, floorTempC: 25.4 }],
            45: [{ pipeSpacingMM: 100, outputWperM2: 43, floorTempC: 26.2 }],
            50: [{ pipeSpacingMM: 100, outputWperM2: 53, floorTempC: 27 }],
            55: [{ pipeSpacingMM: 100, outputWperM2: 62, floorTempC: 27.9 }],
          },
          24: {
            35: [{ pipeSpacingMM: 100, outputWperM2: 21, floorTempC: 26.2 }],
            40: [{ pipeSpacingMM: 100, outputWperM2: 30, floorTempC: 27 }],
            45: [{ pipeSpacingMM: 100, outputWperM2: 40, floorTempC: 27.9 }],
            50: [{ pipeSpacingMM: 100, outputWperM2: 49, floorTempC: 28.7 }],
            55: [{ pipeSpacingMM: 100, outputWperM2: 58, floorTempC: 29.5 }],
          },
        },
      },
      pertEvoh: {
        16: {
          15: {
            35: [{ pipeSpacingMM: 100, outputWperM2: 38, floorTempC: 18.7 }],
            40: [{ pipeSpacingMM: 100, outputWperM2: 47, floorTempC: 19.5 }],
            45: [{ pipeSpacingMM: 100, outputWperM2: 57, floorTempC: 20.4 }],
            50: [{ pipeSpacingMM: 100, outputWperM2: 66, floorTempC: 21.2 }],
            55: [{ pipeSpacingMM: 100, outputWperM2: 75, floorTempC: 22 }],
          },
          18: {
            35: [{ pipeSpacingMM: 100, outputWperM2: 32, floorTempC: 21.2 }],
            40: [{ pipeSpacingMM: 100, outputWperM2: 42, floorTempC: 22 }],
            45: [{ pipeSpacingMM: 100, outputWperM2: 51, floorTempC: 22.9 }],
            50: [{ pipeSpacingMM: 100, outputWperM2: 60, floorTempC: 23.7 }],
            55: [{ pipeSpacingMM: 100, outputWperM2: 70, floorTempC: 24.5 }],
          },
          20: {
            35: [{ pipeSpacingMM: 100, outputWperM2: 28, floorTempC: 22.9 }],
            40: [{ pipeSpacingMM: 100, outputWperM2: 38, floorTempC: 23.7 }],
            45: [{ pipeSpacingMM: 100, outputWperM2: 47, floorTempC: 24.5 }],
            50: [{ pipeSpacingMM: 100, outputWperM2: 57, floorTempC: 25.4 }],
            55: [{ pipeSpacingMM: 100, outputWperM2: 66, floorTempC: 26.2 }],
          },
          22: {
            35: [{ pipeSpacingMM: 100, outputWperM2: 25, floorTempC: 24.5 }],
            40: [{ pipeSpacingMM: 100, outputWperM2: 34, floorTempC: 25.4 }],
            45: [{ pipeSpacingMM: 100, outputWperM2: 43, floorTempC: 26.2 }],
            50: [{ pipeSpacingMM: 100, outputWperM2: 53, floorTempC: 27 }],
            55: [{ pipeSpacingMM: 100, outputWperM2: 62, floorTempC: 27.9 }],
          },
          24: {
            35: [{ pipeSpacingMM: 100, outputWperM2: 21, floorTempC: 26.2 }],
            40: [{ pipeSpacingMM: 100, outputWperM2: 30, floorTempC: 27 }],
            45: [{ pipeSpacingMM: 100, outputWperM2: 40, floorTempC: 27.9 }],
            50: [{ pipeSpacingMM: 100, outputWperM2: 49, floorTempC: 28.7 }],
            55: [{ pipeSpacingMM: 100, outputWperM2: 58, floorTempC: 29.5 }],
          },
        },
      },
    },
  "Type B2 - 0 TOG - Reflective Aluminium Foil Lined Voids on Timber Floors": {
    pexSdr74: {
      16: {
        15: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 34.3, floorTempC: 18.5 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 45.2, floorTempC: 19.6 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 56.8, floorTempC: 20.8 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 68.8, floorTempC: 22 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 81.2, floorTempC: 23.3 }],
        },
        18: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 28, floorTempC: 20.9 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 38.6, floorTempC: 21.9 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 49.8, floorTempC: 23.1 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 61.5, floorTempC: 24.3 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 73.7, floorTempC: 25.5 }],
        },
        20: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 24, floorTempC: 22.4 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 34.3, floorTempC: 23.5 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 45.2, floorTempC: 24.6 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 56.8, floorTempC: 25.8 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 68.8, floorTempC: 27 }],
        },
        22: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 20, floorTempC: 24 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 30.1, floorTempC: 25.1 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 40.8, floorTempC: 26.2 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 52.1, floorTempC: 27.3 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 63.9, floorTempC: 28.5 }],
        },
        24: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 16.3, floorTempC: 25.7 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 26, floorTempC: 26.6 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 36.4, floorTempC: 27.7 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 47.5, floorTempC: 28.8 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 59.1, floorTempC: 30 }],
        },
      },
    },
    pertAl: {
      16: {
        15: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 34.3, floorTempC: 18.5 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 45.2, floorTempC: 19.6 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 56.8, floorTempC: 20.8 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 68.8, floorTempC: 22 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 81.2, floorTempC: 23.3 }],
        },
        18: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 28, floorTempC: 20.9 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 38.6, floorTempC: 21.9 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 49.8, floorTempC: 23.1 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 61.5, floorTempC: 24.3 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 73.7, floorTempC: 25.5 }],
        },
        20: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 24, floorTempC: 22.4 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 34.3, floorTempC: 23.5 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 45.2, floorTempC: 24.6 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 56.8, floorTempC: 25.8 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 68.8, floorTempC: 27 }],
        },
        22: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 20, floorTempC: 24 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 30.1, floorTempC: 25.1 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 40.8, floorTempC: 26.2 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 52.1, floorTempC: 27.3 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 63.9, floorTempC: 28.5 }],
        },
        24: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 16.3, floorTempC: 25.7 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 26, floorTempC: 26.6 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 36.4, floorTempC: 27.7 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 47.5, floorTempC: 28.8 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 59.1, floorTempC: 30 }],
        },
      },
    },
    pertEvoh: {
      16: {
        15: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 34.3, floorTempC: 18.5 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 45.2, floorTempC: 19.6 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 56.8, floorTempC: 20.8 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 68.8, floorTempC: 22 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 81.2, floorTempC: 23.3 }],
        },
        18: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 28, floorTempC: 20.9 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 38.6, floorTempC: 21.9 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 49.8, floorTempC: 23.1 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 61.5, floorTempC: 24.3 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 73.7, floorTempC: 25.5 }],
        },
        20: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 24, floorTempC: 22.4 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 34.3, floorTempC: 23.5 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 45.2, floorTempC: 24.6 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 56.8, floorTempC: 25.8 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 68.8, floorTempC: 27 }],
        },
        22: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 20, floorTempC: 24 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 30.1, floorTempC: 25.1 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 40.8, floorTempC: 26.2 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 52.1, floorTempC: 27.3 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 63.9, floorTempC: 28.5 }],
        },
        24: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 16.3, floorTempC: 25.7 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 26, floorTempC: 26.6 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 36.4, floorTempC: 27.7 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 47.5, floorTempC: 28.8 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 59.1, floorTempC: 30 }],
        },
      },
    },
  },
  "Type B2 - 1 TOG - Reflective Aluminium Foil Lined Voids on Timber Floors": {
    pexSdr74: {
      16: {
        15: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 29.5, floorTempC: 18 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 39.5, floorTempC: 19 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 50.1, floorTempC: 20.1 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 61.3, floorTempC: 21.3 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 73, floorTempC: 22.4 }],
        },
        18: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 23.9, floorTempC: 20.4 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 33.4, floorTempC: 21.4 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 43.7, floorTempC: 22.5 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 54.6, floorTempC: 23.2 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 66, floorTempC: 24.2 }],
        },
        20: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 20.3, floorTempC: 22.1 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 29.5, floorTempC: 23 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 39.5, floorTempC: 24 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 50.1, floorTempC: 24.8 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 61.3, floorTempC: 25.8 }],
        },
        22: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 16.8, floorTempC: 23.7 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 25.7, floorTempC: 24.6 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 35.4, floorTempC: 25.6 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 45.8, floorTempC: 26.4 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 56.8, floorTempC: 27.4 }],
        },
        24: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 13.5, floorTempC: 25.4 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 22, floorTempC: 26.2 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 31.5, floorTempC: 27.2 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 41.6, floorTempC: 28.1 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 52.3, floorTempC: 29 }],
        },
      },
    },
    pertAl: {
      16: {
        15: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 29.5, floorTempC: 18 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 39.5, floorTempC: 19 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 50.1, floorTempC: 20.1 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 61.3, floorTempC: 21.3 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 73, floorTempC: 22.4 }],
        },
        18: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 23.9, floorTempC: 20.4 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 33.4, floorTempC: 21.4 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 43.7, floorTempC: 22.5 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 54.6, floorTempC: 23.2 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 66, floorTempC: 24.2 }],
        },
        20: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 20.3, floorTempC: 22.1 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 29.5, floorTempC: 23 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 39.5, floorTempC: 24 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 50.1, floorTempC: 24.8 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 61.3, floorTempC: 25.8 }],
        },
        22: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 16.8, floorTempC: 23.7 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 25.7, floorTempC: 24.6 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 35.4, floorTempC: 25.6 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 45.8, floorTempC: 26.4 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 56.8, floorTempC: 27.4 }],
        },
        24: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 13.5, floorTempC: 25.4 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 22, floorTempC: 26.2 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 31.5, floorTempC: 27.2 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 41.6, floorTempC: 28.1 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 52.3, floorTempC: 29 }],
        },
      },
    },
    pertEvoh: {
      16: {
        15: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 29.5, floorTempC: 18 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 39.5, floorTempC: 19 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 50.1, floorTempC: 20.1 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 61.3, floorTempC: 21.3 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 73, floorTempC: 22.4 }],
        },
        18: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 23.9, floorTempC: 20.4 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 33.4, floorTempC: 21.4 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 43.7, floorTempC: 22.5 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 54.6, floorTempC: 23.2 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 66, floorTempC: 24.2 }],
        },
        20: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 20.3, floorTempC: 22.1 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 29.5, floorTempC: 23 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 39.5, floorTempC: 24 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 50.1, floorTempC: 24.8 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 61.3, floorTempC: 25.8 }],
        },
        22: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 16.8, floorTempC: 23.7 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 25.7, floorTempC: 24.6 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 35.4, floorTempC: 25.6 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 45.8, floorTempC: 26.4 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 56.8, floorTempC: 27.4 }],
        },
        24: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 13.5, floorTempC: 25.4 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 22, floorTempC: 26.2 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 31.5, floorTempC: 27.2 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 41.6, floorTempC: 28.1 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 52.3, floorTempC: 29 }],
        },
      },
    },
  },
  "Type B2 - 2 TOG - Reflective Aluminium Foil Lined Voids on Timber Floors": {
    pexSdr74: {
      16: {
        15: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 26.5, floorTempC: 17.7 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 35.7, floorTempC: 18.6 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 45.6, floorTempC: 19.6 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 56, floorTempC: 20.7 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 67, floorTempC: 21.8 }],
        },
        18: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 21.3, floorTempC: 20.2 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 30.1, floorTempC: 21.1 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 39.6, floorTempC: 22 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 49.7, floorTempC: 23.1 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 60.4, floorTempC: 24.2 }],
        },
        20: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 18, floorTempC: 21.8 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 26.5, floorTempC: 22.7 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 35.7, floorTempC: 23.6 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 45.6, floorTempC: 24.6 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 56, floorTempC: 25.7 }],
        },
        22: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 14.9, floorTempC: 23.5 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 23, floorTempC: 24.3 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 31.9, floorTempC: 25.3 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 41.6, floorTempC: 26.2 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 51.8, floorTempC: 27.3 }],
        },
        24: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 11.9, floorTempC: 25.2 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 19.6, floorTempC: 26 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 28.3, floorTempC: 26.9 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 37.6, floorTempC: 27.8 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 47.6, floorTempC: 28.9 }],
        },
      },
    },
    pertAl: {
      16: {
        15: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 26.5, floorTempC: 17.7 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 35.7, floorTempC: 18.6 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 45.6, floorTempC: 19.6 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 56, floorTempC: 20.7 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 67, floorTempC: 21.8 }],
        },
        18: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 21.3, floorTempC: 20.2 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 30.1, floorTempC: 21.1 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 39.6, floorTempC: 22 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 49.7, floorTempC: 23.1 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 60.4, floorTempC: 24.2 }],
        },
        20: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 18, floorTempC: 21.8 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 26.5, floorTempC: 22.7 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 35.7, floorTempC: 23.6 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 45.6, floorTempC: 24.6 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 56, floorTempC: 25.7 }],
        },
        22: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 14.9, floorTempC: 23.5 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 23, floorTempC: 24.3 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 31.9, floorTempC: 25.3 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 41.6, floorTempC: 26.2 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 51.8, floorTempC: 27.3 }],
        },
        24: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 11.9, floorTempC: 25.2 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 19.6, floorTempC: 26 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 28.3, floorTempC: 26.9 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 37.6, floorTempC: 27.8 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 47.6, floorTempC: 28.9 }],
        },
      },
    },
    pertEvoh: {
      16: {
        15: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 26.5, floorTempC: 17.7 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 35.7, floorTempC: 18.6 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 45.6, floorTempC: 19.6 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 56, floorTempC: 20.7 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 67, floorTempC: 21.8 }],
        },
        18: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 21.3, floorTempC: 20.2 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 30.1, floorTempC: 21.1 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 39.6, floorTempC: 22 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 49.7, floorTempC: 23.1 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 60.4, floorTempC: 24.2 }],
        },
        20: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 18, floorTempC: 21.8 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 26.5, floorTempC: 22.7 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 35.7, floorTempC: 23.6 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 45.6, floorTempC: 24.6 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 56, floorTempC: 25.7 }],
        },
        22: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 14.9, floorTempC: 23.5 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 23, floorTempC: 24.3 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 31.9, floorTempC: 25.3 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 41.6, floorTempC: 26.2 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 51.8, floorTempC: 27.3 }],
        },
        24: {
          35: [{ pipeSpacingMM: 200, outputWperM2: 11.9, floorTempC: 25.2 }],
          40: [{ pipeSpacingMM: 200, outputWperM2: 19.6, floorTempC: 26 }],
          45: [{ pipeSpacingMM: 200, outputWperM2: 28.3, floorTempC: 26.9 }],
          50: [{ pipeSpacingMM: 200, outputWperM2: 37.6, floorTempC: 27.8 }],
          55: [{ pipeSpacingMM: 200, outputWperM2: 47.6, floorTempC: 28.9 }],
        },
      },
    },
  },
  "Type B3 - 0 TOG - Heat output with 15mm thick alu-foil faced insulation boards For installation over existing structural floors":
    {
      pexSdr74: {
        12: {
          15: {
            35: [{ outputWperM2: 81.1, floorTempC: 22.1, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 101.3, floorTempC: 23.7, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 121.6, floorTempC: 25.3, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 141.9, floorTempC: 26.8, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 162.1, floorTempC: 28.4, pipeSpacingMM: 150 }],
          },
          18: {
            35: [{ outputWperM2: 68.9, floorTempC: 24.1, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 89.1, floorTempC: 25.8, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 109.4, floorTempC: 27.3, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 129.7, floorTempC: 28.9, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 149.9, floorTempC: 30.4, pipeSpacingMM: 150 }],
          },
          20: {
            35: [{ outputWperM2: 60.8, floorTempC: 25.5, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 81.1, floorTempC: 27.1, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 101.3, floorTempC: 28.7, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 121.6, floorTempC: 30.3, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 141.9, floorTempC: 31.8, pipeSpacingMM: 150 }],
          },
          22: {
            35: [{ outputWperM2: 52.7, floorTempC: 26.8, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 73.0, floorTempC: 28.5, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 93.2, floorTempC: 30.1, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 113.5, floorTempC: 31.7, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 133.8, floorTempC: 33.2, pipeSpacingMM: 150 }],
          },
          24: {
            35: [{ outputWperM2: 44.6, floorTempC: 28.1, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 64.9, floorTempC: 29.8, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 85.2, floorTempC: 31.4, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 105.4, floorTempC: 33.0, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 125.7, floorTempC: 34.6, pipeSpacingMM: 150 }],
          },
        },
      },
      pertAl: {
        12: {
          15: {
            35: [{ outputWperM2: 81.1, floorTempC: 22.1, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 101.3, floorTempC: 23.7, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 121.6, floorTempC: 25.3, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 141.9, floorTempC: 26.8, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 162.1, floorTempC: 28.4, pipeSpacingMM: 150 }],
          },
          18: {
            35: [{ outputWperM2: 68.9, floorTempC: 24.1, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 89.1, floorTempC: 25.8, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 109.4, floorTempC: 27.3, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 129.7, floorTempC: 28.9, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 149.9, floorTempC: 30.4, pipeSpacingMM: 150 }],
          },
          20: {
            35: [{ outputWperM2: 60.8, floorTempC: 25.5, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 81.1, floorTempC: 27.1, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 101.3, floorTempC: 28.7, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 121.6, floorTempC: 30.3, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 141.9, floorTempC: 31.8, pipeSpacingMM: 150 }],
          },
          22: {
            35: [{ outputWperM2: 52.7, floorTempC: 26.8, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 73.0, floorTempC: 28.5, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 93.2, floorTempC: 30.1, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 113.5, floorTempC: 31.7, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 133.8, floorTempC: 33.2, pipeSpacingMM: 150 }],
          },
          24: {
            35: [{ outputWperM2: 44.6, floorTempC: 28.1, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 64.9, floorTempC: 29.8, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 85.2, floorTempC: 31.4, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 105.4, floorTempC: 33.0, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 125.7, floorTempC: 34.6, pipeSpacingMM: 150 }],
          },
        },
      },
      pertEvoh: {
        12: {
          15: {
            35: [{ outputWperM2: 81.1, floorTempC: 22.1, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 101.3, floorTempC: 23.7, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 121.6, floorTempC: 25.3, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 141.9, floorTempC: 26.8, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 162.1, floorTempC: 28.4, pipeSpacingMM: 150 }],
          },
          18: {
            35: [{ outputWperM2: 68.9, floorTempC: 24.1, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 89.1, floorTempC: 25.8, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 109.4, floorTempC: 27.3, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 129.7, floorTempC: 28.9, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 149.9, floorTempC: 30.4, pipeSpacingMM: 150 }],
          },
          20: {
            35: [{ outputWperM2: 60.8, floorTempC: 25.5, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 81.1, floorTempC: 27.1, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 101.3, floorTempC: 28.7, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 121.6, floorTempC: 30.3, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 141.9, floorTempC: 31.8, pipeSpacingMM: 150 }],
          },
          22: {
            35: [{ outputWperM2: 52.7, floorTempC: 26.8, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 73.0, floorTempC: 28.5, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 93.2, floorTempC: 30.1, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 113.5, floorTempC: 31.7, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 133.8, floorTempC: 33.2, pipeSpacingMM: 150 }],
          },
          24: {
            35: [{ outputWperM2: 44.6, floorTempC: 28.1, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 64.9, floorTempC: 29.8, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 85.2, floorTempC: 31.4, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 105.4, floorTempC: 33.0, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 125.7, floorTempC: 34.6, pipeSpacingMM: 150 }],
          },
        },
      },
      polybutylene: {
        12: {
          15: {
            35: [{ outputWperM2: 77.2, floorTempC: 22.1, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 96.5, floorTempC: 23.7, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 115.8, floorTempC: 25.3, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 135.1, floorTempC: 26.8, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 154.4, floorTempC: 28.4, pipeSpacingMM: 150 }],
          },
          18: {
            35: [{ outputWperM2: 65.6, floorTempC: 24.1, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 84.9, floorTempC: 25.8, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 104.2, floorTempC: 27.3, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 123.5, floorTempC: 28.9, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 142.8, floorTempC: 30.4, pipeSpacingMM: 150 }],
          },
          20: {
            35: [{ outputWperM2: 57.9, floorTempC: 25.5, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 77.2, floorTempC: 27.1, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 96.5, floorTempC: 28.7, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 115.8, floorTempC: 30.3, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 135.1, floorTempC: 31.8, pipeSpacingMM: 150 }],
          },
          22: {
            35: [{ outputWperM2: 50.2, floorTempC: 26.8, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 69.5, floorTempC: 28.5, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 88.8, floorTempC: 30.1, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 108.1, floorTempC: 31.7, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 127.4, floorTempC: 33.2, pipeSpacingMM: 150 }],
          },
          24: {
            35: [{ outputWperM2: 42.5, floorTempC: 28.1, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 61.8, floorTempC: 29.8, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 81.1, floorTempC: 31.4, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 100.4, floorTempC: 33.0, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 119.7, floorTempC: 34.6, pipeSpacingMM: 150 }],
          },
        },
      },
    },
  "Type B3 - 1 TOG - Heat output with 15mm thick alu-foil faced insulation boards For installation over existing structural floors":
    {
      pexSdr74: {
        12: {
          15: {
            35: [{ outputWperM2: 54.8, floorTempC: 20.0, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 68.6, floorTempC: 21.1, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 82.2, floorTempC: 22.2, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 96.0, floorTempC: 23.3, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 109.6, floorTempC: 24.4, pipeSpacingMM: 150 }],
          },
          18: {
            35: [{ outputWperM2: 46.6, floorTempC: 22.3, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 60.3, floorTempC: 23.4, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 74.0, floorTempC: 24.5, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 87.7, floorTempC: 25.6, pipeSpacingMM: 150 }],
            55: [
              { outputWperM2: 101.4, floorTempC: 267.0, pipeSpacingMM: 150 },
            ],
          },
          20: {
            35: [{ outputWperM2: 41.2, floorTempC: 23.8, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 54.8, floorTempC: 25.0, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 68.6, floorTempC: 26.1, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 82.2, floorTempC: 27.2, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 96.0, floorTempC: 28.3, pipeSpacingMM: 150 }],
          },
          22: {
            35: [{ outputWperM2: 35.6, floorTempC: 25.4, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 49.4, floorTempC: 26.5, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 63.0, floorTempC: 27.7, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 76.8, floorTempC: 28.8, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 90.4, floorTempC: 29.9, pipeSpacingMM: 150 }],
          },
          24: {
            35: [{ outputWperM2: 30.1, floorTempC: 26.9, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 43.9, floorTempC: 28.1, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 57.5, floorTempC: 29.2, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 71.3, floorTempC: 30.3, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 84.9, floorTempC: 31.4, pipeSpacingMM: 150 }],
          },
        },
      },
      pertAl: {
        12: {
          15: {
            35: [{ outputWperM2: 54.8, floorTempC: 20.0, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 68.6, floorTempC: 21.1, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 82.2, floorTempC: 22.2, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 96.0, floorTempC: 23.3, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 109.6, floorTempC: 24.4, pipeSpacingMM: 150 }],
          },
          18: {
            35: [{ outputWperM2: 46.6, floorTempC: 22.3, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 60.3, floorTempC: 23.4, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 74.0, floorTempC: 24.5, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 87.7, floorTempC: 25.6, pipeSpacingMM: 150 }],
            55: [
              { outputWperM2: 101.4, floorTempC: 267.0, pipeSpacingMM: 150 },
            ],
          },
          20: {
            35: [{ outputWperM2: 41.2, floorTempC: 23.8, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 54.8, floorTempC: 25.0, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 68.6, floorTempC: 26.1, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 82.2, floorTempC: 27.2, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 96.0, floorTempC: 28.3, pipeSpacingMM: 150 }],
          },
          22: {
            35: [{ outputWperM2: 35.6, floorTempC: 25.4, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 49.4, floorTempC: 26.5, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 63.0, floorTempC: 27.7, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 76.8, floorTempC: 28.8, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 90.4, floorTempC: 29.9, pipeSpacingMM: 150 }],
          },
          24: {
            35: [{ outputWperM2: 30.1, floorTempC: 26.9, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 43.9, floorTempC: 28.1, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 57.5, floorTempC: 29.2, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 71.3, floorTempC: 30.3, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 84.9, floorTempC: 31.4, pipeSpacingMM: 150 }],
          },
        },
      },
      pertEvoh: {
        12: {
          15: {
            35: [{ outputWperM2: 54.8, floorTempC: 20.0, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 68.6, floorTempC: 21.1, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 82.2, floorTempC: 22.2, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 96.0, floorTempC: 23.3, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 109.6, floorTempC: 24.4, pipeSpacingMM: 150 }],
          },
          18: {
            35: [{ outputWperM2: 46.6, floorTempC: 22.3, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 60.3, floorTempC: 23.4, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 74.0, floorTempC: 24.5, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 87.7, floorTempC: 25.6, pipeSpacingMM: 150 }],
            55: [
              { outputWperM2: 101.4, floorTempC: 267.0, pipeSpacingMM: 150 },
            ],
          },
          20: {
            35: [{ outputWperM2: 41.2, floorTempC: 23.8, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 54.8, floorTempC: 25.0, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 68.6, floorTempC: 26.1, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 82.2, floorTempC: 27.2, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 96.0, floorTempC: 28.3, pipeSpacingMM: 150 }],
          },
          22: {
            35: [{ outputWperM2: 35.6, floorTempC: 25.4, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 49.4, floorTempC: 26.5, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 63.0, floorTempC: 27.7, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 76.8, floorTempC: 28.8, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 90.4, floorTempC: 29.9, pipeSpacingMM: 150 }],
          },
          24: {
            35: [{ outputWperM2: 30.1, floorTempC: 26.9, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 43.9, floorTempC: 28.1, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 57.5, floorTempC: 29.2, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 71.3, floorTempC: 30.3, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 84.9, floorTempC: 31.4, pipeSpacingMM: 150 }],
          },
        },
      },
      polybutylene: {
        12: {
          15: {
            35: [{ outputWperM2: 52.2, floorTempC: 20.0, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 65.3, floorTempC: 21.1, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 78.3, floorTempC: 22.2, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 91.4, floorTempC: 23.3, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 104.4, floorTempC: 24.4, pipeSpacingMM: 150 }],
          },
          18: {
            35: [{ outputWperM2: 44.4, floorTempC: 22.3, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 57.4, floorTempC: 23.4, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 70.5, floorTempC: 24.5, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 83.5, floorTempC: 25.6, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 96.6, floorTempC: 26.7, pipeSpacingMM: 150 }],
          },
          20: {
            35: [{ outputWperM2: 39.2, floorTempC: 23.8, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 52.2, floorTempC: 25.0, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 65.3, floorTempC: 26.1, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 78.3, floorTempC: 27.2, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 91.4, floorTempC: 28.3, pipeSpacingMM: 150 }],
          },
          22: {
            35: [{ outputWperM2: 33.9, floorTempC: 25.4, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 47.0, floorTempC: 26.5, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 60.0, floorTempC: 27.7, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 73.1, floorTempC: 28.8, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 86.1, floorTempC: 29.9, pipeSpacingMM: 150 }],
          },
          24: {
            35: [{ outputWperM2: 28.7, floorTempC: 26.9, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 41.8, floorTempC: 28.1, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 54.8, floorTempC: 29.2, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 67.9, floorTempC: 30.3, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 80.9, floorTempC: 31.4, pipeSpacingMM: 150 }],
          },
          25: {
            35: [{ outputWperM2: 22.7, floorTempC: 26.3, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 33.0, floorTempC: 28.8, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 43.3, floorTempC: 28.2, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 53.6, floorTempC: 29.1, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 63.9, floorTempC: 30.0, pipeSpacingMM: 150 }],
          },
        },
      },
    },
  "Type B3 - 2 TOG - Heat output with 15mm thick alu-foil faced insulation boards For installation over existing structural floors":
    {
      pexSdr74: {
        12: {
          15: {
            35: [{ outputWperM2: 43.3, floorTempC: 19.0, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 54.1, floorTempC: 19.4, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 64.9, floorTempC: 20.8, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 75.7, floorTempC: 21.7, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 86.5, floorTempC: 22.5, pipeSpacingMM: 150 }],
          },
          18: {
            35: [{ outputWperM2: 37.9, floorTempC: 21.0, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 49.5, floorTempC: 22.0, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 61.1, floorTempC: 23.0, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 72.7, floorTempC: 24.0, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 84.3, floorTempC: 25.0, pipeSpacingMM: 150 }],
          },
          20: {
            35: [{ outputWperM2: 32.4, floorTempC: 24.5, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 43.1, floorTempC: 25.5, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 53.9, floorTempC: 26.5, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 64.6, floorTempC: 27.5, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 75.4, floorTempC: 28.5, pipeSpacingMM: 150 }],
          },
          22: {
            35: [{ outputWperM2: 27.0, floorTempC: 25.0, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 37.8, floorTempC: 26.0, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 48.5, floorTempC: 27.0, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 59.3, floorTempC: 28.0, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 70.1, floorTempC: 29.0, pipeSpacingMM: 150 }],
          },
          24: {
            35: [{ outputWperM2: 22.0, floorTempC: 26.0, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 32.7, floorTempC: 27.0, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 43.4, floorTempC: 28.0, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 54.1, floorTempC: 29.0, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 64.8, floorTempC: 30.0, pipeSpacingMM: 150 }],
          },
        },
      },
      pertAl: {
        12: {
          15: {
            35: [{ outputWperM2: 43.3, floorTempC: 19.0, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 54.1, floorTempC: 19.4, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 64.9, floorTempC: 20.8, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 75.7, floorTempC: 21.7, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 86.5, floorTempC: 22.5, pipeSpacingMM: 150 }],
          },
          18: {
            35: [{ outputWperM2: 37.9, floorTempC: 21.0, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 49.5, floorTempC: 22.0, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 61.1, floorTempC: 23.0, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 72.7, floorTempC: 24.0, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 84.3, floorTempC: 25.0, pipeSpacingMM: 150 }],
          },
          20: {
            35: [{ outputWperM2: 32.4, floorTempC: 24.5, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 43.1, floorTempC: 25.5, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 53.9, floorTempC: 26.5, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 64.6, floorTempC: 27.5, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 75.4, floorTempC: 28.5, pipeSpacingMM: 150 }],
          },
          22: {
            35: [{ outputWperM2: 27.0, floorTempC: 25.0, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 37.8, floorTempC: 26.0, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 48.5, floorTempC: 27.0, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 59.3, floorTempC: 28.0, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 70.1, floorTempC: 29.0, pipeSpacingMM: 150 }],
          },
          24: {
            35: [{ outputWperM2: 22.0, floorTempC: 26.0, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 32.7, floorTempC: 27.0, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 43.4, floorTempC: 28.0, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 54.1, floorTempC: 29.0, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 64.8, floorTempC: 30.0, pipeSpacingMM: 150 }],
          },
        },
      },
      pertEvoh: {
        12: {
          15: {
            35: [{ outputWperM2: 43.3, floorTempC: 19.0, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 54.1, floorTempC: 19.4, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 64.9, floorTempC: 20.8, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 75.7, floorTempC: 21.7, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 86.5, floorTempC: 22.5, pipeSpacingMM: 150 }],
          },
          18: {
            35: [{ outputWperM2: 37.9, floorTempC: 21.0, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 49.5, floorTempC: 22.0, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 61.1, floorTempC: 23.0, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 72.7, floorTempC: 24.0, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 84.3, floorTempC: 25.0, pipeSpacingMM: 150 }],
          },
          20: {
            35: [{ outputWperM2: 32.4, floorTempC: 24.5, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 43.1, floorTempC: 25.5, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 53.9, floorTempC: 26.5, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 64.6, floorTempC: 27.5, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 75.4, floorTempC: 28.5, pipeSpacingMM: 150 }],
          },
          22: {
            35: [{ outputWperM2: 27.0, floorTempC: 25.0, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 37.8, floorTempC: 26.0, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 48.5, floorTempC: 27.0, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 59.3, floorTempC: 28.0, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 70.1, floorTempC: 29.0, pipeSpacingMM: 150 }],
          },
          24: {
            35: [{ outputWperM2: 22.0, floorTempC: 26.0, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 32.7, floorTempC: 27.0, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 43.4, floorTempC: 28.0, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 54.1, floorTempC: 29.0, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 64.8, floorTempC: 30.0, pipeSpacingMM: 150 }],
          },
        },
      },
      polybutylene: {
        12: {
          15: {
            35: [{ outputWperM2: 41.2, floorTempC: 19.0, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 51.5, floorTempC: 19.4, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 61.8, floorTempC: 20.8, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 72.1, floorTempC: 21.7, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 82.4, floorTempC: 22.5, pipeSpacingMM: 150 }],
          },
          18: {
            35: [{ outputWperM2: 35.0, floorTempC: 20.0, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 45.3, floorTempC: 21.0, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 55.6, floorTempC: 22.0, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 65.9, floorTempC: 23.0, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 76.2, floorTempC: 24.0, pipeSpacingMM: 150 }],
          },
          20: {
            35: [{ outputWperM2: 30.9, floorTempC: 23.1, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 41.2, floorTempC: 24.1, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 51.5, floorTempC: 25.1, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 61.8, floorTempC: 26.1, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 72.1, floorTempC: 27.1, pipeSpacingMM: 150 }],
          },
          22: {
            35: [{ outputWperM2: 26.8, floorTempC: 24.7, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 37.1, floorTempC: 25.3, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 47.4, floorTempC: 26.6, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 57.7, floorTempC: 27.5, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 68.0, floorTempC: 28.3, pipeSpacingMM: 150 }],
          },
          24: {
            35: [{ outputWperM2: 22.7, floorTempC: 25.5, pipeSpacingMM: 150 }],
            40: [{ outputWperM2: 33.0, floorTempC: 26.1, pipeSpacingMM: 150 }],
            45: [{ outputWperM2: 43.3, floorTempC: 27.4, pipeSpacingMM: 150 }],
            50: [{ outputWperM2: 53.6, floorTempC: 28.3, pipeSpacingMM: 150 }],
            55: [{ outputWperM2: 63.9, floorTempC: 29.1, pipeSpacingMM: 150 }],
          },
        },
      },
    },
};

// not used in calcs other than testing
export const GenericManifold: ManifoldData = {
  "manifold-range-1": {
    "manifold-model-1": {
      range: "manifold-range-1",
      model: "manifold-model-1",
      productKey: "manifold-productKey-1",
      description: "10 Port Port UFH Distribution Manifold",
      outletsCount: 10,
      widthMM: 500,
      heightMM: 200,
      depthMM: 100,
      kvM3PerHr: 0.1,
      ioCentre2CentreMM: 100,
      allowsActuators: true,
    },
    "manifold-model-2": {
      range: "manifold-range-1",
      model: "manifold-model-2",
      productKey: "manifold-productKey-2",
      description: "4 Port Port UFH Distribution Manifold",
      outletsCount: 4,
      widthMM: 500,
      heightMM: 200,
      depthMM: 100,
      kvM3PerHr: 0.1,
      ioCentre2CentreMM: 100,
      allowsActuators: true,
    },
  },
};

export const GenericActuator: ActuatorData = {
  "actuator-model-1": {
    model: "actuator-model-1",
    productKey: "actuator-productKey-1",
    description: "UFH Actuator",
    kValue: 0.1,
    compatibleManifolds: [["manifold-manufacturer-1", "manifold-model-1"]],
  },
};

export const GenericBlendingValve: BlendingValveData = {
  "blending-valve-model-1": {
    model: "blending-valve-model-1",
    productKey: "blending-valve-productKey-1",
    description: "UFH Blending Valve",
    maxHeatOutputKW: 100,
    minFlowTempC: 0,
    maxFlowTempC: 100,
    pressureDropKPAByFlowRateLS: {
      ranges: [
        {
          min: 0,
          max: Infinity,
          function: {
            type: "polynomial",
            coefficients: [0.1, 0.2, 0.3],
            variableName: "flowRateLS",
          },
        },
      ],
      variableName: "flowRateLS",
    },
  },
};

export const GenericUFHPump: UFHPumpData = {
  "pump-model-1": {
    model: "pump-model-1",
    productKey: "pump-productKey-1",
    description: "UFH Pump",
    maxHeadKPA: 0.1,
    maxFlowRateLS: 100,
    pressureDropKPAByFlowRateLS: {
      ranges: [
        {
          min: 0,
          max: Infinity,
          function: {
            type: "polynomial",
            coefficients: [0.1, 0.2, 0.3],
            variableName: "flowRateLS",
          },
        },
      ],
      variableName: "flowRateLS",
    },
    minPowerKW: 10,
    maxPowerKW: 20,
  },
};

export const GenericPumpPack: PumpPack = {
  "pump-pack-model-1": {
    model: "pump-pack-model-1",
    productKey: "pump-pack-productKey-1",
    description: "UFH Pump Pack",
    pumps: [["generic", "pump-model-1"]],
    blendingValves: [
      ["blending-valve-manufacturer-1", "blending-valve-model-1"],
    ],
    compatibleManifolds: [["manifold-manufacturer-1", "manifold-model-1"]],
  },
};

export const GenericBallValve: BallValve = {
  "Generic Pump Pack Ball Valve": {
    model: "Generic Pump Pack Ball Valve",
    productKey: "generic-ball-valve",
    description: "UFH Pump Pack Ball Valve",
    sizesMM: [25.4],
    kValue: 0.1,
    needsPumpPack: false,
    compatibleManifolds: [["generic", "manifold-model-1"]],
  },
  "Generic Ball Valve": {
    model: "ball-valve-model-1",
    productKey: "generic-ball-valve",
    description: "UFH Ball Valve",
    sizesMM: [25.4],
    kValue: 0.1,
    needsPumpPack: true,
    compatibleManifolds: [["generic", "manifold-model-1"]],
  },
};

export const GenericEdgeExpansionFoam: EdgeExpansionFoam = {
  "Generic Edge Expansion Foam": {
    model: "Generic Edge Expansion Foam",
    productKey: "gGeneric Edge Expansion Foam",
    description: "Edge Expansion Foam",
    rollLengthM: null,
  },
};
