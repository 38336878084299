import { ExecutionStep } from "./flight-data-recorder";

export class GlobalFDR {
  static fdrLog: ExecutionStep[] = [];
  static errorMsg: string | undefined;
  static breakAt: string | null;
  static shouldBreak: ((stepName: string, ...args: any[]) => boolean) | null;
  static pushLog(executionStep: ExecutionStep) {
    this.fdrLog.push(executionStep);
  }
  static popLog(): ExecutionStep {
    return this.fdrLog.pop()!;
  }
  static clearLog() {
    this.fdrLog = [];
    this.focusData([]);
  }

  // Call this as many times to change the "stack" of what is focused.
  // No need to clear, the
  static focusData(entities: string[]) {
    if (this.fdrLog.length === 0) {
      // Shouldn't really happen
    } else {
      this.fdrLog[this.fdrLog.length - 1].focusedData = entities;
    }
  }
}
