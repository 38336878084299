
import Vue from "vue";
import Component from "vue-class-component";
import { AccessEvents } from "../../../common/src/models/AccessEvents";
import {
  customOrgsLabel,
  Organization,
} from "../../../common/src/models/Organization";
import {
  AccessLevel,
  canBlockUser,
  canRemoveUserBlock,
  User as IUser,
} from "../../../common/src/models/User";
import { UserBlock } from "../../../common/src/models/UserBlocks";
import { getAccessEvents } from "../api/access-events";
import { getOrganizations } from "../api/organizations";
import {
  adminResetUserPassword,
  blockUser,
  getUser,
  getUserBlocks,
  unblockUser,
  updateUser,
} from "../api/users";
import MagicplanTokenModal from "../components/magic-plan/MagicplanTokenModal.vue";
import MainNavBar from "../components/MainNavBar.vue";
import MFA from "../components/MFA.vue";
import store from "../store/store";

@Component({
  components: { MainNavBar, MFA, MagicplanTokenModal },
  watch: {
    $route(to) {
      (this as User).updateUsername(to.params.id);
    },
  },
})
export default class User extends Vue {
  user: (IUser & { mfaStatus?: boolean }) | null = null;
  page = 0;
  accessItems: AccessEvents[] = [];

  organization: Organization | null = null;

  deactivateReason = "";

  organizations: Organization[] = [];
  blocks: UserBlock[] = [];

  updateUsername(username: string) {
    this.user = null;
    this.accessItems = [];
    this.page = 0;
    getUser(username).then((res) => {
      if (res.success) {
        this.user = res.data;
        this.organization = null;
        if (res.data.organization) {
          this.organization = res.data.organization;
        }
        if (canBlockUser(this.user, this.profile)) {
          getUserBlocks(this.user.username).then((res) => {
            if (res.success) {
              this.blocks = res.data;
            } else {
              this.$bvToast.toast(res.message, {
                title: "Error retrieving user blocks",
                variant: "danger",
              });
            }
          });
        }
      } else {
        this.$bvToast.toast(res.message, {
          title: "Error retrieving user data",
          variant: "danger",
        });
      }
      this.changePage(0);
    });
  }

  updateUserMFA(enabled: boolean) {
    if (this.user) {
      this.user.mfaStatus = enabled;
    }
  }

  mounted() {
    this.updateUsername(this.$route.params.id);
    if (this.profile && this.profile.accessLevel < AccessLevel.MANAGER) {
      getOrganizations().then((res) => {
        if (res.success) {
          this.organizations.splice(0, this.organizations.length, ...res.data);
        } else {
          this.$bvToast.toast(res.message, {
            variant: "danger",
            title: "Error retrieving org list",
          });
        }
      });
    }
  }

  async changePage(page: number) {
    if (!this.profile || this.profile.accessLevel >= AccessLevel.MANAGER) {
      return;
    }
    this.page = page;
    if (this.user) {
      const result = await getAccessEvents(
        this.user.username,
        this.page * 50,
        50,
      );
      if (result.success) {
        this.accessItems = result.data;
      } else {
        this.$bvToast.toast(result.message, {
          variant: "danger",
          title: "Error Retrieving Access Events",
        });
      }
    }
  }

  save() {
    if (this.user) {
      updateUser(
        this.user.username,
        this.user.name,
        this.user?.email?.toLocaleLowerCase(),
        this.user.subscribed,
        this.user.accessLevel,
        this.user.activated,
        this.organization?.id || undefined,
      ).then((res) => {
        if (res.success) {
          this.$router.push(this.backlink);

          if (this.user!.username === this.profile.username) {
            this.$store.dispatch("profile/setProfile", res.data);
          }
        } else {
          this.$bvToast.toast(res.message, {
            title: "Error saving organization",
            variant: "danger",
          });
        }
      });
    }
  }

  get customOrgsSelectLabel() {
    return customOrgsLabel;
  }

  get userEmailVerified() {
    return this.profile?.email_verified_at != null;
  }

  get profile(): IUser {
    return this.$store.getters["profile/profile"];
  }

  get editingMyself(): boolean {
    return (
      this.profile != null &&
      this.user != null &&
      this.profile.username === this.user.username
    );
  }

  get levels() {
    if (this.profile) {
      if (this.profile.accessLevel <= AccessLevel.ADMIN) {
        return [
          {
            name: "Super User",
            level: AccessLevel.SUPERUSER,
            disabled:
              this.profile.accessLevel > AccessLevel.SUPERUSER ||
              this.editingMyself,
          },
          {
            name: "Admin",
            level: AccessLevel.ADMIN,
            disabled:
              this.profile.accessLevel >= AccessLevel.ADMIN ||
              this.editingMyself,
          },
          {
            name: "User",
            level: AccessLevel.USER,
            disabled:
              this.profile.accessLevel > AccessLevel.ADMIN ||
              this.editingMyself,
          },
        ];
      } else {
        return [
          {
            name: "User",
            level: AccessLevel.USER,
            disabled:
              this.profile.accessLevel > AccessLevel.MANAGER ||
              this.editingMyself,
          },
        ];
      }
    } else {
      return [];
    }
  }

  get AccessLevel() {
    return AccessLevel;
  }

  get backlink() {
    if (!this.profile || this.profile.accessLevel >= AccessLevel.USER) {
      return "/";
    } else {
      return "/users";
    }
  }

  get canBlockUser() {
    return canBlockUser(this.user!, this.profile);
  }

  email() {
    throw new Error("Method not implemented.");
  }
  username() {
    throw new Error("Method not implemented.");
  }

  canRemoveBlock(block: UserBlock) {
    console.log("Checking if can remove block", this.user, block, this.profile);
    return canRemoveUserBlock(this.user!, block, this.profile);
  }

  handleBlockClick() {
    if (this.user) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to block " +
            this.user.username +
            "? Reason: " +
            this.deactivateReason,
          {
            title: "Deactivate User",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Deactivate",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(async (value) => {
          if (value) {
            const result = await blockUser(
              this.user!.username,
              this.deactivateReason,
            );
            if (result.success) {
              Object.assign(this.user!, result.data.user);
              this.blocks.push(result.data.block);
              this.$bvToast.toast("User deactivated", {
                title: "Success",
                variant: "success",
              });
            } else {
              this.$bvToast.toast(result.message, {
                title: "Error blocking user",
                variant: "danger",
              });
            }
          }
        });
    }
  }

  handleUnblockClick(block: UserBlock) {
    if (this.user) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to remove this block for " +
            this.user.username +
            "?" +
            (this.user.numBlocks === 1
              ? " This will reactivate the user."
              : ""),
          {
            title: "Remove Block",
            size: "sm",
            buttonSize: "sm",
            okVariant: "success",
            okTitle: "Remove block",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(async (value) => {
          if (value) {
            const result = await unblockUser(
              this.user!.username,
              block.blockReason,
            );

            console.log("Unblock result", result);

            if (result.success) {
              console.log("here");
              Object.assign(this.user!, result.data);
              console.log("here2");
              this.blocks.splice(this.blocks.indexOf(block), 1);
              console.log("here3");
              this.$bvToast.toast("User block removed", {
                title: "Success",
                variant: "success",
              });
            } else {
              this.$bvToast.toast(result.message, {
                title: "Error unblocking user",
                variant: "danger",
              });
            }
          }
        });
    }
  }

  adminResetUserPassword() {
    if (this.user) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to reset the password for " +
            this.user.username +
            "? The user will no longer be able to login with their old password",
          {
            title: "Reset Password",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Reset Password",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(async (value) => {
          if (value) {
            const result = await adminResetUserPassword(this.user!.username);
            console.log("result", result);
            if (result.success) {
              await this.$bvModal.msgBoxOk(
                "The user's password has been reset to: " + result.data,
                {
                  title: "Reset password successfully!",
                  size: "sm",
                  buttonSize: "sm",
                  okVariant: "success",
                  okTitle: "OK",
                  footerClass: "p-2",
                  hideHeaderClose: false,
                  centered: true,
                  noCloseOnBackdrop: true,
                  noCloseOnEsc: true,
                },
              );
            } else {
              this.$bvToast.toast(result.message, {
                title: "Error resetting password",
                variant: "danger",
              });
            }
          }
        });
    }
  }

  clearMagicplanTokens() {
    this.$bvModal
      .msgBoxConfirm("Are you sure you want to clear your Magicplan tokens?", {
        okTitle: "Yes",
      })
      .then(async (res) => {
        if (res) {
          await store.dispatch("profile/setProperties", {
            magicplanCustomerId: "",
            magicplanSecretKey: "",
          });
          this.$bvToast.toast("Magicplan credentials cleared", {
            title: "Success",
            variant: "success",
          });
        }
      });
  }
}
