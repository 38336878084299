import axios from "axios";
import { APIResult } from "../../../common/src/api/document/types";
import { getFailureFromAxiosRequestErrorEvent } from "../lib/axios-utils";

export async function getVisitorToken(): Promise<APIResult<string | null>> {
  try {
    return (await axios.get("/api/hubspot/visitorToken")).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}
