
import { SignUpOutput, signUp } from "aws-amplify/auth";
import { defineComponent } from "vue";
import { assertType, assertUnreachable } from "../../../common/src/lib/utils";
import { CLIENT_METADATA } from "../auth/amplify.config";
import HeroButton from "../components/molecules/HeroButton.vue";

export default defineComponent({
  components: { HeroButton },
  data() {
    const state: "signUp" = "signUp";
    const isLoading: boolean = false;

    return {
      isLoading,
      state,
    };
  },
  computed: {
    signUpFields() {
      return {
        username: {
          type: "text",
          label: "Username",
          placeholder: "Username",
          value: "",
          dataCy: "username-input",
        },
        email: {
          type: "email",
          label: "Email",
          placeholder: "Email",
          value: "",
          dataCy: "email-input",
        },
        given_name: {
          type: "text",
          label: "Given Name",
          placeholder: "First Name",
          value: "",
          dataCy: "given-name-input",
        },
        family_name: {
          type: "text",
          label: "Family Name",
          placeholder: "Last Name",
          value: "",
          dataCy: "family-name-input",
        },
        password: {
          type: "password",
          label: "Password",
          placeholder: "Password",
          value: "",
          dataCy: "signup-password-input",
        },
      };
    },
  },
  methods: {
    trimSignUpFields() {
      for (const key in this.signUpFields) {
        assertType<keyof typeof this.signUpFields>(key);
        this.signUpFields[key].value = this.signUpFields[key].value.trim();
      }
    },
    async signUp() {
      this.trimSignUpFields();
      try {
        this.isLoading = true;
        const res = await signUp({
          username: this.signUpFields.username.value,
          password: this.signUpFields.password.value,
          options: {
            userAttributes: {
              email: this.signUpFields.email.value.toLowerCase(),
              given_name: this.signUpFields.given_name.value,
              family_name: this.signUpFields.family_name.value,
              "custom:domain": CLIENT_METADATA.domain,
            },
            clientMetadata: CLIENT_METADATA,
            autoSignIn: {
              clientMetadata: CLIENT_METADATA,
            },
          },
        });

        this.handleSignUpResponse(res);
      } catch (e: any) {
        this.$bvToast.toast(e.message, {
          variant: "warning",
          toaster: " b-toaster-top-center",
          title: "Something is not right",
          noAutoHide: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
    handleSignUpResponse(res: SignUpOutput) {
      console.log("sign up response", res);
      const { nextStep } = res;

      switch (nextStep.signUpStep) {
        case "COMPLETE_AUTO_SIGN_IN":
          this.navigateToLoginInNewTab();
        case "CONFIRM_SIGN_UP":
        case "DONE":
          break;
        default:
          assertUnreachable(nextStep);
      }
    },
    /**
     * In order to allow opening in top...
     *
     * The following must be added to the iframe
     *
     * <iframe sandbox="allow-top-navigation">
     *
     * There are other useful sandbox items that should be added:
     * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#attr-sandbox
     *
     * - allow-popups
     * - allow-popups-to-escape-sandbox
     */
    isInAccessibleTopIframe(): boolean | undefined {
      try {
        return window.self !== window.top;
      } catch (e) {
        return undefined;
      }
    },
    isInAccessibleParentIframe(): boolean | undefined {
      try {
        return window.self !== window.parent;
      } catch (e) {
        return undefined;
      }
    },
    navigateToLoginInNewTab() {
      const routeData = this.$router.resolve({ name: "/login" });
      const isInIframeWithTopAccessible = this.isInAccessibleTopIframe();
      const isInIframeWithParentAccessible = this.isInAccessibleParentIframe();
      if (isInIframeWithTopAccessible) {
        window.open(routeData.href, "_top");
        return;
      }

      if (isInIframeWithParentAccessible) {
        window.open(routeData.href, "_parent");
        return;
      }

      // Fall back to opening in the same tab, this likely means either the iframe
      // has hardened permissions or there is no iframe present.
      window.open(routeData.href, "_self");
    },
  },
});
