import { Coord, Coord3D } from "../../../lib/coord";
import { UFH_BELOW_FLOOR_HEIGHT_MM } from "./consts";

interface CoilParams {
  turnRadiusMM: number;
  counterflowCenterDist?: number;

  suggestedMinRadiusMM?: number;
  maxRadiusMM?: number;
}

export interface CoilCoord3D extends Coord3D, CoilParams {}

export interface CoilCoord extends Coord, CoilParams {}

export function underTheFloor(run: CoilCoord[]): CoilCoord3D[] {
  return run.map((coord) => ({
    ...coord,
    z: UFH_BELOW_FLOOR_HEIGHT_MM,
  }));
}

export function toXPlane(coil: CoilCoord3D): CoilCoord {
  return {
    x: coil.x,
    y: coil.z,
    turnRadiusMM: coil.turnRadiusMM,
    counterflowCenterDist: coil.counterflowCenterDist,
    suggestedMinRadiusMM: coil.suggestedMinRadiusMM,
    maxRadiusMM: coil.maxRadiusMM,
  };
}
