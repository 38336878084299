import Flatten from "@flatten-js/core";
import * as TM from "transformation-matrix";
import CorePlant, {
  RECIRCULATION_SYSTEM_NODE_SIZE,
} from "../../../../common/src/api/coreObjects/corePlant";
import CoreSystemNode from "../../../../common/src/api/coreObjects/coreSystemNode";
import { flowSystemsCompatible } from "../../../../common/src/api/coreObjects/utils";
import { CalculationData } from "../../../../common/src/api/document/calculations-objects/calculation-field";
import { FlowConfiguration } from "../../../../common/src/api/document/entities/big-valve/big-valve-entity";
import {
  DrawableEntityConcrete,
  isCenteredEntity,
} from "../../../../common/src/api/document/entities/concrete-entity";
import { isPipeEntity } from "../../../../common/src/api/document/entities/conduit-entity";
import { SystemNodeEntity } from "../../../../common/src/api/document/entities/system-node-entity";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import {
  SystemNodeRole,
  findSystemNodeRole,
} from "../../../../common/src/api/document/entities/utils";
import {
  getFlowSystem,
  getFlowSystemColor,
} from "../../../../common/src/api/document/utils";
import { lighten } from "../../../../common/src/lib/color";
import { Coord, coordDist } from "../../../../common/src/lib/coord";
import { getDragPriority } from "../../store/document";
import { DocumentState } from "../../store/document/types";
import { drawCircle, drawEquilateralTriangle } from "../helpers/draw-helper";
import { MoveIntent } from "../lib/black-magic/cool-drag";
import { EntityDrawingArgs } from "../lib/drawable-object";
import { Interaction, InteractionType } from "../lib/interaction";
import { CalculatedObject } from "../lib/object-traits/calculated-object";
import { CenteredObjectNoParent } from "../lib/object-traits/centered-object";
import { ConnectableObject } from "../lib/object-traits/connectable";
import { Core2Drawable } from "../lib/object-traits/core2drawable";
import { DrawingContext, ObjectConstructArgs } from "../lib/types";
import { DrawableObjectConcrete } from "./concrete-object";
import DrawableConduit from "./drawableConduit";
import { isFlowSystemActive } from "./utils";

const Base = CalculatedObject(
  ConnectableObject(CenteredObjectNoParent(Core2Drawable(CoreSystemNode))),
);

export default class DrawableSystemNode extends Base {
  minimumConnections = 0;
  maximumConnections = 1;
  dragPriority = getDragPriority(EntityType.SYSTEM_NODE);
  customCopyObjects = true;

  // We could not add this to the base drawable class because
  // of some typescript thing so they have to be added at the concrete class.
  constructor(args: ObjectConstructArgs<SystemNodeEntity>) {
    super(args.context, args.obj);
    this.onSelect = args.onSelect;
    this.onInteractionComplete = args.onInteractionComplete;
    this.document = args.document;
  }

  offerInteraction(interaction: Interaction): DrawableEntityConcrete[] | null {
    switch (interaction.type) {
      case InteractionType.STARTING_CONDUIT:
      case InteractionType.CONTINUING_CONDUIT:
        if (this.globalStore.getConnections(this.entity.uid).length > 0) {
          return null;
        }
        if (
          !isFlowSystemActive(
            this.context,
            this.document.uiState,
            this.entity.systemUid,
          )
        ) {
          return null;
        }
        if (
          interaction.system &&
          !flowSystemsCompatible(
            interaction.system.uid,
            this.entity.systemUid,
            this.document.drawing,
          ) &&
          !this.entity.allowAllSystems
        ) {
          return null;
        }
        break;
      case InteractionType.SNAP_ONTO_RECEIVE:
        if (this.globalStore.getConnections(this.entity.uid).length > 0) {
          return null;
        }
        break;
      case InteractionType.SNAP_ONTO_SEND:
        if (interaction.dest.type === EntityType.SYSTEM_NODE) {
          return null;
        } else {
          break;
        }
      case InteractionType.EXTEND_NETWORK:
        if (
          this.entity.configuration === FlowConfiguration.BOTH ||
          interaction.configuration === FlowConfiguration.BOTH
        ) {
          break;
        } else if (this.entity.configuration !== interaction.configuration) {
          return null;
        }
      case InteractionType.LINK_ENTITY:
        return null;
    }
    return super.offerInteraction(interaction);
  }

  locateCalculationBoxWorld(
    context: DrawingContext,
    data: CalculationData[],
    scale: number,
  ): TM.Matrix[] {
    // Choose to show away from the parent
    const po = this.globalStore.get(this.entity.parentUid!)!;
    const wc = this.toWorldCoord();
    if (isCenteredEntity(po.entity)) {
      const center = po.toWorldCoord();
      const v = Flatten.vector([wc.x - center.x, wc.y - center.y]);
      const vo = Flatten.vector([0, -1]);
      let angle: number;
      if (v.length > 0) {
        angle = vo.angleTo(v);
      } else {
        angle = (po.toWorldAngleDeg(0) / 180) * Math.PI;
      }

      return [
        angle,
        angle - Math.PI / 4,
        angle + Math.PI / 4,
        angle - Math.PI / 2,
        angle + Math.PI / 2,
        angle - (Math.PI * 3) / 4,
        angle + (Math.PI * 3) / 4,
      ].map((dir) => {
        return TM.transform(
          TM.identity(),
          TM.translate(wc.x, wc.y),
          TM.rotate(dir),
          TM.scale(scale),
          TM.translate(0, -80),
          TM.rotate(-dir),
        );
      });
    } else {
      // Can be loop entry/exits when the parent is a room.
      // Just try around it.

      const angle = 0;
      return [
        angle,
        angle - Math.PI / 4,
        angle + Math.PI / 4,
        angle - Math.PI / 2,
        angle + Math.PI / 2,
        angle - (Math.PI * 3) / 4,
        angle + (Math.PI * 3) / 4,
      ].map((dir) => {
        return TM.transform(
          TM.identity(),
          TM.translate(wc.x, wc.y),
          TM.rotate(dir),
          TM.scale(scale),
          TM.translate(0, -80),
          TM.rotate(-dir),
        );
      });
    }
  }

  system(doc: DocumentState) {
    const system = getFlowSystem(doc.drawing, this.entity.systemUid);
    if (system) {
      return system;
    } else {
      throw new Error("System does't exist");
    }
  }

  baseDrawnColor(context: DrawingContext) {
    for (const rUid of context.globalStore.getConnections(this.entity.uid)) {
      const rEntity = context.globalStore.get(rUid).entity;
      if (!isPipeEntity(rEntity)) {
        throw new Error(
          "Non pipe conduits connected to system nodes are not implemented",
        );
      }
      const calculation = context.globalStore.getCalculation(rEntity);
      return getFlowSystemColor(
        getFlowSystem(context.doc.drawing, this.entity.systemUid)!,
        rEntity.conduit.network,
        (calculation && calculation.configuration) || undefined,
      );
    }
    return this.system(context.doc).color;
  }

  drawConnectable(
    context: DrawingContext,
    { selected }: EntityDrawingArgs,
  ): void {
    if (selected) {
      context.ctx.fillStyle = lighten(
        this.baseDrawnColor(context).hex,
        50,
        0.7,
      );
      context.ctx.beginPath();
      context.ctx.arc(0, 0, 50, 0, Math.PI * 2);
      context.ctx.fill();
    }
  }

  inBounds(objectCoord: Coord, objectRadius: number): boolean {
    if (objectRadius) {
      if (Flatten.vector(objectCoord.x, objectCoord.y).length <= objectRadius) {
        return true;
      }
      const stubWC = this.getStubWC();
      if (stubWC) {
        const stubObjCoord = this.toObjectCoord(stubWC);
        if (coordDist(objectCoord, stubObjCoord) <= objectRadius) {
          return true;
        }
      }
    }
    return false;
  }

  getCustomCopiedObjects(): DrawableObjectConcrete[] {
    if (this.parent) {
      return [this, this.parent as DrawableObjectConcrete];
    } else {
      return [this];
    }
  }

  getConnetectedSidePipe(pipeUid: string): DrawableConduit[] {
    const connectionUids = this.globalStore.getConnections(this.uid)!;
    const sidePipeUids = connectionUids.filter((uid) => uid !== pipeUid);
    return sidePipeUids
      .map((uid) => this.globalStore.get(uid)! as DrawableConduit)
      .filter((o) => o.type === EntityType.CONDUIT);
  }

  getCoolDragCorrelations(
    myMove: MoveIntent,
    _from?: DrawableObjectConcrete | undefined,
  ): { object: DrawableObjectConcrete; move: MoveIntent }[] {
    if (!this.entity.parentUid) {
      throw new Error("Parent of the system node is missing");
    }
    if (myMove.type === "literal") {
      // this could have only come from the parent. So we don't need to re-pass it on.
      return [];
    }
    return [
      {
        object: this.globalStore.get(this.entity.parentUid)!,
        move: myMove,
      },
    ];
  }

  onUpdate() {
    // console.log("system node updated", this.uid, this.entity.parentUid);
  }

  drawEntity(context: DrawingContext, _args: EntityDrawingArgs): void {
    if (
      findSystemNodeRole(this.entity, context) ===
        SystemNodeRole.RECIRCULATION_PUMP &&
      this.entity.parentUid
    ) {
      const corePlant = context.globalStore.get<CorePlant>(
        this.entity.parentUid,
      );
      const xTran = corePlant.entity.rightToLeft ? -1 : 1;

      const centerOfRectangle = {
        x: RECIRCULATION_SYSTEM_NODE_SIZE / 2,
        y: (-2 * length) / 5,
      };

      // Save the original state
      context.ctx.save();

      // Apply the transformation
      context.ctx.scale(xTran, 1);

      const radiusOfCircle = RECIRCULATION_SYSTEM_NODE_SIZE / 3;
      drawCircle(context.ctx, centerOfRectangle, radiusOfCircle, 5);
      const pumpTran =
        this.entity.configuration === FlowConfiguration.OUTPUT ? -1 : 1;
      // draw the equilateral triangle in the middle of the circle
      centerOfRectangle.y - 5;
      const sizeOfTriangle = pumpTran * 45;

      drawEquilateralTriangle(
        context.ctx,
        centerOfRectangle,
        sizeOfTriangle,
        5,
      );

      context.ctx.restore();
    }
  }
}
