import FlowSourceEntity from "../../../../../common/src/api/document/entities/flow-source-entity";
import {
  Units,
  UnitsContext,
} from "../../../../../common/src/lib/measurements";
import { CoreContext } from "../../calculations/types";
import { isVentilation } from "../../config";
import { CalculationField, FieldCategory } from "./calculation-field";
import {
  CalcFieldSelector,
  Calculation,
  CalculationType,
  LiveCalculation,
  NameCalculation,
  PressureCalculation,
  addPressureCalculationFields,
} from "./types";
import { getFlowSystemLayouts } from "./utils";
export default interface FlowSourceCalculation
  extends Calculation,
    PressureCalculation,
    NameCalculation {
  type: CalculationType.FlowSourceCalculation;
  flowRateLS: number | null;
}

export interface FlowSourceLiveCalculation extends LiveCalculation {}

export const FastLiveFlowSourceCalculationFields: CalcFieldSelector<FlowSourceLiveCalculation> =
  {
    connected: true,
  };

export function makeFlowSourceCalculationFields(
  context: CoreContext,
  entity: FlowSourceEntity,
): CalculationField[] {
  const { drawing } = context;

  const { layouts, layoutsExcept, onlyLayouts } = getFlowSystemLayouts(
    drawing.metadata.flowSystems[entity.systemUid],
  );

  const result: CalculationField[] = [];
  const iAmVentilation = isVentilation(
    context.drawing.metadata.flowSystems[entity.systemUid],
  );

  result.push({
    property: "reference",
    title: "Reference",
    short: "",
    shortTitle: "",
    units: Units.None,
    category: FieldCategory.EntityName,
    systemUid: entity.systemUid,
    layouts: layouts,
    defaultEnabled: false,
  });

  addPressureCalculationFields(
    context,
    result,
    entity.systemUid,
    layouts,
    "",
    { defaultEnabled: false },
    { defaultEnabled: false },
  );

  result.push({
    property: "flowRateLS",
    title: "Peak Flow Rate",
    short: "",
    shortTitle: "",
    units: Units.LitersPerSecond,
    unitContext: iAmVentilation ? UnitsContext.VENTILATION : undefined,
    systemUid: entity.systemUid,
    category: FieldCategory.FlowRate,
    layouts,
  });

  return result;
}

export function emptyFlowSourceCalculation(): FlowSourceCalculation {
  return {
    type: CalculationType.FlowSourceCalculation,
    cost: null,
    costBreakdown: null,
    expandedEntities: null,

    flowRateLS: null,
    pressureKPA: null,
    staticPressureKPA: null,
    warnings: null,

    entityName: null,
  };
}

export function emptyFlowSourceLiveCalculation(): FlowSourceLiveCalculation {
  return {
    connected: null,
    warnings: [],
  };
}
