import { Units } from "../../../lib/measurements";
import { FieldCategory, NestedCalculationField } from "./calculation-field";
import {
  CalcFieldSelector,
  Calculation,
  CalculationType,
  LiveCalculation,
} from "./types";
import {
  LoopSiteCalculation,
  getLoopStatFields,
} from "./underfloor-heating-loop-calculation";

export default interface AreaSegmentCalculation
  extends Calculation,
    LoopSiteCalculation {
  type: CalculationType.AreaSegmentCalculation;
  areaM2: number | null;

  // For heated area to room associations.
  roomUid: string | null;

  loopSpacingMM: number | null;
  loopDirectionDEG: number | null;
}

export interface AreaSegmentLiveCalculation
  extends LiveCalculation,
    LoopSiteCalculation {
  roomUid: string | null;
  areaM2: number | null;
  loopSpacingMM: number | null;
  loopDirectionDEG: number | null;
}

export const FastLiveAreaSegmentCalculationFields: CalcFieldSelector<AreaSegmentLiveCalculation> =
  {
    roomUid: true,
    areaM2: true,
  };

export function makeAreaSegmentCalculationFields(): NestedCalculationField<AreaSegmentCalculation>[] {
  return [
    {
      property: "reference",
      title: "Reference",
      short: "",
      shortTitle: "",
      units: Units.None,
      category: FieldCategory.EntityName,
      layouts: ["mechanical"],
      defaultEnabled: false,
    },
    {
      property: "areaM2",
      title: "Area",
      short: "",
      shortTitle: "Area",
      defaultEnabled: false,
      units: Units.SquareMeters,
      category: FieldCategory.Size,
      layouts: ["mechanical"],
    },
    {
      property: "loopSpacingMM",
      title: "Loop Spacing",
      short: "",
      shortTitle: "Loop Spacing",
      defaultEnabled: false,
      units: Units.Millimeters,
      category: FieldCategory.Pressure,
      layouts: ["mechanical"],
    },
    ...getLoopStatFields(),
  ];
}

export function emptyAreaSegmentCalculation(): AreaSegmentCalculation {
  return {
    type: CalculationType.AreaSegmentCalculation,
    costBreakdown: null,
    cost: null,
    expandedEntities: null,
    warnings: [],
    areaM2: null,
    loopsStats: [],
    loopDirectionDEG: null,
    loopSpacingMM: null,
    roomUid: null,
  };
}

export function emptyAreaSegmentLiveCalculation(): AreaSegmentLiveCalculation {
  return {
    connected: null,
    warnings: [],
    loopsStats: [],
    areaM2: null,
    loopDirectionDEG: null,
    loopSpacingMM: null,
    roomUid: null,
  };
}
