import { AccessLevel, User } from "../../../../common/src/models/User";
import { HubSpotLiveChat } from "./HubSpotLiveChat";

export class CommandAi {
  static init(profile: User) {
    if (!window.CommandBar) {
      console.error("CommandBar not found");
      return;
    }
    // @ts-ignore
    window.CommandBar.boot(profile.username, {
      accessLevel: profile?.accessLevel ?? AccessLevel.USER,
    });
    window.CommandBar.addCallback("openHubSpotChat", () =>
      HubSpotLiveChat.load(),
    );
    HubSpotLiveChat.init(profile?.email ?? "");
  }
}
