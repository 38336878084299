import Flatten from "@flatten-js/core";
import { CoreConnectableObjectConcrete } from ".";
import { Coord3D } from "../../lib/coord";
import {
  assertType,
  assertUnreachable,
  cloneSimple,
  interpolateTable,
  lowerBoundTable,
  lowerCase,
  parseCatalogNumberExact,
} from "../../lib/utils";
import { Vector3 } from "../../lib/vector3";
import { GetPressureLossOptions } from "../calculations/entity-pressure-drops";
import {
  PressureDropCalculations,
  head2kpa,
} from "../calculations/pressure-drops";
import {
  CoreContext,
  CostBreakdown,
  FlowNode,
  PipeConfiguration,
  PressureLossResult,
  PressurePushMode,
} from "../calculations/types";
import { PipeMaterial, PipeSpec } from "../catalog/types";
import {
  DuctHeightMM,
  DuctManufacturerSpec,
  DuctWidthMM,
} from "../catalog/ventilation/ducts";
import {
  ComponentPressureLossMethod,
  DuctPhysicalMaterial,
  StandardFlowSystemUids,
  isDrainage,
  isGas,
} from "../config";
import ConduitCalculation, {
  ConduitLiveCalculation,
  NoFlowAvailableReason,
  PipeCalculation,
  PipeLiveCalculation,
  isPipeReturn,
} from "../document/calculations-objects/conduit-calculations";
import DamperCalculation from "../document/calculations-objects/damper-calculation";
import {
  default as ConduitEntity,
  DuctConduitEntity,
  MutableConduit,
  PipeConduitEntity,
  fillDefaultConduitFields,
  isDuctEntity,
  isPipeEntity,
} from "../document/entities/conduit-entity";
import { EntityType } from "../document/entities/types";
import {
  filterPipesBySizeEntries,
  getEntityNetwork,
  getEntitySystem,
} from "../document/entities/utils";
import { FlowSystem, PipeFlowSystemTypes } from "../document/flow-systems";
import { getFlowSystem } from "../document/utils";
import { CoreBaseEdge } from "./core-traits/coreBaseEdge";
import Cached from "./lib/cached";
import CoreBaseBackedObject from "./lib/coreBaseBackedObject";

export const RING_MAIN_HEAD_LOSS_CONSTANT = 1.28;
const Base = CoreBaseEdge(CoreBaseBackedObject<ConduitEntity>);
let cnt = 0;
export default class CoreConduit extends Base {
  type: EntityType.CONDUIT = EntityType.CONDUIT;

  getHash(): string {
    const ao = this.globalStore.get<CoreConnectableObjectConcrete>(
      this.entity.endpointUid[0],
    ) as CoreConnectableObjectConcrete;
    const bo = this.globalStore.get<CoreConnectableObjectConcrete>(
      this.entity.endpointUid[1],
    ) as CoreConnectableObjectConcrete;
    if (!ao || !bo) {
      throw new Error(
        "One of pipe's endpoints are missing. Pipe is: " +
          JSON.stringify(this.entity),
      );
    }
    const key = ao.getHash() + " " + bo.getHash();
    // const hash = createHash("sha256");
    // hash.update(key.toString());
    // return hash.digest("hex");
    return key;
  }

  get computedLengthM(): number {
    const [wa, wb] = this.physicalWorldEndpoints();
    return (
      Math.sqrt((wa.x - wb.x) ** 2 + (wa.y - wb.y) ** 2 + (wa.z - wb.z) ** 2) /
      1000
    );
  }

  // Returns the world coordinates of the two endpoints
  @Cached(
    (kek) => {
      return new Set(
        [
          kek,
          ...kek
            .getCoreNeighbours()
            .map((o) => o?.getParentChain())
            .flat(),
        ].map((o) => o?.uid),
      );
    },
    (excludeUid) => excludeUid,
  )
  worldEndpoints(excludeUid: string | null = null): Coord3D[] {
    const ao = this.globalStore.get(
      this.entity.endpointUid[0],
    ) as CoreConnectableObjectConcrete;
    const bo = this.globalStore.get(
      this.entity.endpointUid[1],
    ) as CoreConnectableObjectConcrete;
    if (!ao || !bo) {
      throw new Error(
        "One of pipe's endpoints are missing. Pipe is: " +
          JSON.stringify(this.entity),
      );
    }
    if (ao && bo) {
      const res: Coord3D[] = [];
      if (
        (ao.entity.calculationHeightM === null) !==
        (bo.entity.calculationHeightM === null)
      ) {
        throw new Error(
          "We are working with a 3d object and a 2d object - not allowed \n" +
            JSON.stringify(ao.entity) +
            "\n" +
            JSON.stringify(bo.entity),
        );
      }
      if (ao.uid !== excludeUid) {
        const a = ao.toWorldCoord({ x: 0, y: 0 });
        res.push({
          x: a.x,
          y: a.y,
          z: (ao.entity.calculationHeightM || 0) * 1000,
        });
      }
      if (bo.uid !== excludeUid) {
        const b = bo.toWorldCoord({ x: 0, y: 0 });
        res.push({
          x: b.x,
          y: b.y,
          z: (bo.entity.calculationHeightM || 0) * 1000,
        });
      }

      return res;
    } else {
      throw new Error(
        "One of pipe's endpoints are missing. Pipe is: " +
          JSON.stringify(this.entity),
      );
    }
  }

  physicalWorldEndpoints() {
    const ao = this.globalStore.get(
      this.entity.endpointUid[0],
    ) as CoreConnectableObjectConcrete;
    const bo = this.globalStore.get(
      this.entity.endpointUid[1],
    ) as CoreConnectableObjectConcrete;
    if (!ao || !bo) {
      throw new Error(
        "One of pipe's endpoints are missing. Pipe is: " +
          JSON.stringify(this.entity),
      );
    }

    const res: Coord3D[] = [];
    res.push(ao.getConnectionCoord(this.uid));
    res.push(bo.getConnectionCoord(this.uid));
    return res;
  }

  get physicalVector(): Vector3 {
    const [wa, wb] = this.physicalWorldEndpoints();
    return new Vector3(wb.x - wa.x, wb.y - wa.y, wb.z - wa.z);
  }

  table: PipeSpec[] = [];
  init(context: CoreContext) {
    let page = CoreConduit.getPipeManufacturerCatalogPage(
      context,
      this.entity,
    )!;
    const specs = Object.values(page);
    if (specs.length === 0) {
      return;
    }
    let size = specs.reduce((a, b) =>
      parseCatalogNumberExact(a.diameterNominalMM)! >
      parseCatalogNumberExact(b.diameterNominalMM)!
        ? a
        : b,
    ).diameterNominalMM;
    for (let i = 0; i <= parseCatalogNumberExact(size)!; ++i) {
      this.table.push(lowerBoundTable(page, i)!);
    }
  }

  // @Cached(
  //   (kek) =>
  //     new Set(
  //       [kek]
  //         .map((o) => [o, o.getCoreNeighbours(), o.getParentChain()])
  //         .flat(2)
  //         .map((o) => o.getParentChain())
  //         .flat()
  //         .map((o) => o.uid)
  //     ),
  //   ({
  //     context,
  //     flowLS,
  //     from,
  //     to,
  //     signed,
  //     pressurePushMode,
  //     ignoreCalculatedDefaults,
  //     ignoreHeightDifference,
  //   }) =>
  //     "" +
  //     flowLS +
  //     from.connectable +
  //     to.connectable +
  //     signed +
  //     pressurePushMode +
  //     ignoreCalculatedDefaults +
  //     ignoreHeightDifference
  // )
  getFrictionPressureLossKPA({
    context,
    flowLS,
    from,
    to,
    signed,
    pressurePushMode,
    ignoreHeightDifference,
  }: GetPressureLossOptions & {
    ignoreHeightDifference?: boolean;
  }): PressureLossResult {
    /**
     * Utility Functionality
     */
    const flipFlowDirection = (
      flowLS: number,
      from: FlowNode,
      to: FlowNode,
      signed: boolean,
    ): {
      flowLS: number;
      from: FlowNode;
      to: FlowNode;
      flip: boolean;
      sign: number;
    } => {
      let sign = 1;
      let flip = false;
      if (flowLS < 0) {
        [from, to] = [to, from];
        flowLS = -flowLS;
        flip = true;
        if (signed) {
          sign = -1;
        }
      }
      return { flowLS, from, to, flip, sign };
    };
    const findPage = (calculation: PipeCalculation): PipeSpec | undefined => {
      if (!calculation.realNominalPipeDiameterMM || this.table.length === 0) {
        return undefined;
      }

      // TODO: This piece of code is pretty sus to me
      return calculation.realNominalPipeDiameterMM < 0
        ? this.table[0]
        : Math.ceil(calculation.realNominalPipeDiameterMM) >= this.table.length
          ? this.table.at(-1)
          : this.table[Math.ceil(calculation.realNominalPipeDiameterMM)];
    };
    const validateEndPoint = (
      fromObject: CoreConnectableObjectConcrete,
      toObject: CoreConnectableObjectConcrete,
    ) => {
      if (
        !this.entity.endpointUid.includes(from.connectable) ||
        !this.entity.endpointUid.includes(to.connectable)
      ) {
        throw new Error("asking for flow from endpoints that don't exist");
      }
    };
    const computeVolLM = (entity: PipeConduitEntity): number => {
      return (
        (parseCatalogNumberExact(
          context.globalStore.getCalculation(entity)!.realInternalDiameterMM,
        )! **
          2 *
          Math.PI) /
        4 /
        1000
      );
    };
    const computePipeLengthM = (): number => {
      let pipeLengthM =
        this.entity.lengthM == null
          ? this.computedLengthM
          : this.entity.lengthM;

      switch (
        context.drawing.metadata.calculationParams.componentPressureLossMethod
      ) {
        case ComponentPressureLossMethod.INDIVIDUALLY:
          // Find pressure loss from components
          break;
        case ComponentPressureLossMethod.PERCENT_ON_TOP_OF_PIPE:
          pipeLengthM *=
            1 +
            0.01 *
              context.drawing.metadata.calculationParams
                .pipePressureLossAddOnPCT;
          break;
        default:
          assertUnreachable(
            context.drawing.metadata.calculationParams
              .componentPressureLossMethod,
          );
      }
      return pipeLengthM;
    };
    const computeHeadLossM = (
      fromObject: CoreConnectableObjectConcrete,
      toObject: CoreConnectableObjectConcrete,
      flip: boolean,
    ) => {
      if (fromObject.entity.calculationHeightM !== null) {
        if (toObject.entity.calculationHeightM === null) {
          throw new Error("inconsistent 2d/3d paradigm");
        }
        const headLoss =
          toObject.entity.calculationHeightM -
          fromObject.entity.calculationHeightM;

        return flip ? -headLoss : headLoss;
      } else if (toObject.entity.calculationHeightM !== null) {
        throw new Error("inconsistent 2d/3d paradigm");
      } else {
        throw new Error("pipe " + this.uid + " with no 3d");
      }
    };
    const computeTerminiPressureDropsKPA = () => {
      let pressureDropKPA = 0;
      const termini = context.globalStore.getTerminiByEdge(this.uid);
      for (const term of termini) {
        const termCalc = context.globalStore.calculationStore.get(
          term,
        ) as DamperCalculation;
        if (termCalc) {
          pressureDropKPA += termCalc.pressureDropKPA || 0;
        }
      }

      return pressureDropKPA;
    };

    const { drawing, catalog, globalStore } = context;

    const conduitLengthM = computePipeLengthM();

    switch (this.entity.conduitType) {
      case "duct": {
        let filledEntity = fillDefaultConduitFields(context, this.entity);
        const terminiPressureLossKPA = computeTerminiPressureDropsKPA();

        const ductResult = CoreConduit.getDuctPressureLossKPA(
          context,
          filledEntity,
          flowLS,
          conduitLengthM,
          {},
        );

        return {
          pressureLossKPA: ductResult.pressureLossKPA + terminiPressureLossKPA,
          terminiPressureLossKPA,
        };
      }
      case "pipe": {
        const terminiPressureLossKPA = computeTerminiPressureDropsKPA();

        if (
          isDrainage(
            context.drawing.metadata.flowSystems[this.entity.systemUid],
          )
        ) {
          return { pressureLossKPA: 0, terminiPressureLossKPA };
        }
        if (this.table.length === 0) {
          this.init(context);
        }
        const ga =
          context.drawing.metadata.calculationParams.gravitationalAcceleration;

        const calculation = context.globalStore.getCalculation(this.entity);
        const material = CoreConduit.getPipeCatalogPage(context, this.entity);

        if (!calculation || !material) {
          return { pressureLossKPA: null };
        }

        const pipeSpecPage = findPage(calculation);
        if (!pipeSpecPage) {
          return { pressureLossKPA: null };
        }

        let sign = 1;
        let flip = false;
        ({ flowLS, from, to, sign, flip } = flipFlowDirection(
          flowLS,
          from,
          to,
          signed,
        ));

        const pipeIsGas = isGas(
          context.drawing.metadata.flowSystems[this.entity.systemUid!],
        );
        const system = getFlowSystem(drawing, this.entity.systemUid)!;
        const fluid = catalog.fluids[system.fluid];

        const volLM = computeVolLM(this.entity);
        const velocityMS = flowLS / volLM;
        const dynamicViscosity = CoreConduit.computeDynamicViscosity(
          context,
          system,
        );

        let retval = 0;
        if (pipeIsGas) {
          // TODO: Calculate gas.
        } else {
          retval =
            sign *
            head2kpa(
              PressureDropCalculations.getDarcyWeisbachFlatMH(
                parseCatalogNumberExact(pipeSpecPage.diameterInternalMM)!,
                parseCatalogNumberExact(
                  pipeSpecPage.colebrookWhiteCoefficient,
                )!,
                parseCatalogNumberExact(fluid.densityKGM3)!,
                dynamicViscosity!,
                conduitLengthM,
                velocityMS,
                ga,
              ),
              parseCatalogNumberExact(fluid.densityKGM3)!,
              ga,
            );
        }
        if (calculation.configuration === PipeConfiguration.RING_MAIN) {
          retval *= RING_MAIN_HEAD_LOSS_CONSTANT;
        }

        const fromObject = context.globalStore.get(
          from.connectable,
        ) as CoreConnectableObjectConcrete;
        const toObject = context.globalStore.get(
          to.connectable,
        ) as CoreConnectableObjectConcrete;

        validateEndPoint(fromObject, toObject);
        let heightHeadLoss = computeHeadLossM(fromObject, toObject, flip);

        switch (pressurePushMode) {
          case PressurePushMode.PSD:
          case PressurePushMode.Static:
            break;
          case PressurePushMode.CirculationFlowOnly:
            heightHeadLoss = 0;
            break;
          default:
            assertUnreachable(pressurePushMode);
        }

        if (ignoreHeightDifference) {
          heightHeadLoss = 0;
        }

        return {
          pressureLossKPA:
            retval +
            head2kpa(
              heightHeadLoss,
              parseCatalogNumberExact(fluid.densityKGM3)!,
              ga,
            ) +
            terminiPressureLossKPA,
          terminiPressureLossKPA,
        };
      }
      case "cable": {
        throw new Error("Not implemented");
      }
      default: {
        assertUnreachable(this.entity);
      }
    }
    throw new Error("Unknown conduit type");
  }

  static computeDynamicViscosity(
    context: CoreContext,
    system: FlowSystem,
  ): number {
    const fluid = context.catalog.fluids[system.fluid];
    return parseCatalogNumberExact(
      // TODO: get temperature of the pipe.
      interpolateTable(
        fluid.dynamicViscosityByTemperature,
        system.temperatureC,
      ),
    )!;
  }

  // Without terminus
  static getDuctPressureLossKPA(
    context: CoreContext,
    filled: DuctConduitEntity,
    flowLS: number,
    conduitLengthM: number,
    options?: {
      shape?: "circular" | "rectangular";
      diameterMM?: number;
      heightMM?: number;
      widthMM?: number;
    },
  ) {
    if (flowLS === 0 || flowLS === null) {
      return { pressureLossKPA: 0 };
    }

    const { drawing, catalog } = context;
    const system = getFlowSystem(drawing, filled.systemUid);

    if (!system) {
      console.error("No flow system found");
      return { pressureLossKPA: 0 };
    }

    const fluid = catalog.fluids[system.fluid];
    const dynamicViscosity = CoreConduit.computeDynamicViscosity(
      context,
      system,
    );

    const materialPage = CoreConduit.getDuctCatalogPage(context, filled);
    if (!materialPage) {
      throw new Error(
        'Duct material "' + filled.conduit.material + '" not found in catalog',
      );
    }
    const roughnessMM = materialPage.data.generic.colebrookWhiteCoefficient; // colebrookWhiteCoefficient catalog
    const fluidDensity = Number(fluid.densityKGM3); // fluid

    const effectiveShape = options?.shape ?? filled.conduit.shape;
    switch (effectiveShape) {
      case "circular": {
        const internalPipeDiameterMM =
          options?.diameterMM ?? filled.conduit.diameterMM ?? 0;
        const areaMM2 = (internalPipeDiameterMM / 2) ** 2 * Math.PI;
        const velocityMS = flowLS / 1000 / (areaMM2 / 1000000);

        if (velocityMS === 0) {
          return { pressureLossKPA: 0 };
        }

        const reynoldNumber = PressureDropCalculations.getReynoldsNumber(
          fluidDensity,
          velocityMS,
          internalPipeDiameterMM,
          dynamicViscosity,
        );

        const frictionFactor = PressureDropCalculations.getDuctFrictionFactor(
          internalPipeDiameterMM,
          roughnessMM,
          reynoldNumber,
        );

        const paPerM =
          (1000 * frictionFactor * velocityMS ** 2 * 1.204) /
          (internalPipeDiameterMM * 2);
        const pressureLossKPA = (paPerM * conduitLengthM) / 1000;

        return { pressureLossKPA };
      }
      case "rectangular": {
        const widthMM = options?.widthMM ?? filled.conduit.widthMM ?? 0;
        const heightMM = options?.heightMM ?? filled.conduit.heightMM ?? 0;
        const equivalentDiameterMM =
          (1.3 * (widthMM * heightMM) ** 0.625) / (widthMM + heightMM) ** 0.25;
        const hydraulicDiameterMM =
          widthMM === 0 || heightMM === 0
            ? 0
            : (4 * (widthMM * heightMM)) / (2 * (widthMM + heightMM));

        const areaMM = widthMM * heightMM;
        const velocityMS = (flowLS * 1000) / areaMM;
        const reynoldNumber = PressureDropCalculations.getReynoldsNumber(
          fluidDensity,
          velocityMS,
          hydraulicDiameterMM,
          dynamicViscosity,
        );

        const frictionFactor = PressureDropCalculations.getDuctFrictionFactor(
          equivalentDiameterMM,
          roughnessMM,
          reynoldNumber,
        );

        const paPerM =
          (1000 * frictionFactor * velocityMS ** 2 * 1.204) /
          (hydraulicDiameterMM * 2);
        const pressureLossKPA = (paPerM * conduitLengthM) / 1000;

        return { pressureLossKPA };
      }
      case null: {
        throw new Error("This should handled in fillDefaultConduitFields");
      }
      default: {
        assertUnreachable(effectiveShape);
      }
    }

    throw new Error("Unknown shape");
  }

  getCalculationEntities(context: CoreContext): [ConduitEntity] {
    const pe = cloneSimple(this.entity);
    pe.parentUid;
    pe.uid = this.getCalculationUid(context);
    (pe as MutableConduit).endpointUid = [
      (
        this.globalStore.get(pe.endpointUid[0]) as CoreConnectableObjectConcrete
      ).getCalculationNode(context, this.uid).uid,

      (
        this.globalStore.get(pe.endpointUid[1]) as CoreConnectableObjectConcrete
      ).getCalculationNode(context, this.uid).uid,
    ];
    return [pe];
  }

  collectCalculations(context: CoreContext): ConduitCalculation {
    return context.globalStore.getOrCreateCalculation(
      this.getCalculationEntities(context)[0],
    );
  }

  collectLiveCalculations(context: CoreContext): ConduitLiveCalculation {
    const standardCalculations = this.collectCalculations(context);
    const firstRun = this.getCalculationEntities(context)[0];
    if (isPipeEntity(firstRun)) {
      assertType<PipeLiveCalculation>(standardCalculations);
      const result = context.globalStore.getOrCreateLiveCalculation(firstRun);
      result.flowFrom = result.flowFrom || standardCalculations.flowFrom;
      result.configuration =
        result.configuration || standardCalculations.configuration;
      if (
        standardCalculations.warnings?.some(
          (w) => w.type === "MISSING_BALANCING_VALVE_FOR_RETURN",
        )
      ) {
        result.unbalanced = true;
      }
      return result;
    } else if (isDuctEntity(firstRun)) {
      assertType<PipeLiveCalculation>(standardCalculations);
      const result = context.globalStore.getOrCreateLiveCalculation(firstRun);
      result.flowFrom = result.flowFrom || standardCalculations.flowFrom;

      return result;
    } else {
      return context.globalStore.getOrCreateLiveCalculation(firstRun);
    }
  }

  costBreakdown(context: CoreContext): CostBreakdown | null {
    const filled = fillDefaultConduitFields(context, this.entity);
    if (isPipeEntity(filled)) {
      const catalogEntry = CoreConduit.getPipeCatalogPage(context, filled);
      if (!catalogEntry) {
        return null;
      }

      const manufacturer =
        context.drawing.metadata.catalog.pipes.find(
          (pipeObj) => pipeObj.uid === filled.conduit.material,
        )?.manufacturer || "generic";

      const priceTableName = catalogEntry.manufacturer.find(
        (m) => m.uid === manufacturer,
      )?.priceTableName;
      if (!priceTableName) {
        return null;
      }
      let manufactoryCatalog = context.catalog.pipes[
        filled.conduit.material!
      ].manufacturer.find((m) => m.uid === manufacturer);

      const manufacturerWithoutMaterial =
        manufactoryCatalog && manufactoryCatalog.overridBrandName
          ? manufactoryCatalog.overridBrandName
          : manufacturer.split(/(?=[A-Z])/)[0].toUpperCase(); // Use pipe's uid until first capital case letter

      const size =
        context.globalStore.getOrCreateCalculation(
          filled,
        ).realNominalPipeDiameterMM!;
      if (priceTableName in context.priceTable.Pipes) {
        const availableSizes = Object.keys(
          context.catalog.pipes[filled.conduit.material!].pipesBySize[
            manufacturer
          ],
        )
          .map((a) => Number(a))
          .sort((a, b) => a - b);
        const bestSize = availableSizes.find((s) => s >= size);

        if (
          bestSize !== undefined &&
          bestSize in context.priceTable.Pipes[priceTableName]
        ) {
          const res: CostBreakdown = {
            cost:
              context.priceTable.Pipes[priceTableName][bestSize] *
              filled.lengthM!,
            breakdown: [
              {
                qty: filled.lengthM!,
                path: `Pipes.${priceTableName}.${bestSize}`,
                manufacturer:
                  manufacturerWithoutMaterial +
                  ` ${
                    manufactoryCatalog
                      ? lowerCase(manufactoryCatalog?.priceTableName)
                      : "generic"
                  }`,
              },
            ],
          };
          if (
            filled.systemUid === StandardFlowSystemUids.HotWater &&
            isPipeReturn(context.globalStore.getOrCreateCalculation(filled))
          ) {
            // needs insulation
            const availableInsulationSizes = Object.keys(
              context.priceTable.Insulation,
            )
              .map((a) => Number(a))
              .sort((a, b) => a - b);
            const bestInsulationSize = availableInsulationSizes.find(
              (s) => s >= bestSize,
            );

            if (bestInsulationSize) {
              res.breakdown.push({
                qty: filled.lengthM!,
                path: `Insulation.${bestInsulationSize}`,
              });
              res.cost +=
                context.priceTable.Insulation[bestInsulationSize] *
                filled.lengthM!;
            }
          }
          return res;
        }
      }
    }
    if (isDuctEntity(filled)) {
      const catalogEntry = CoreConduit.getDuctCatalogPage(context, filled);
      if (!catalogEntry) {
        return null;
      }

      const manufacturer =
        context.drawing.metadata.catalog.ducts.find(
          (ductObj) => ductObj.uid === filled.conduit.material,
        )?.manufacturer || "generic";

      const priceTableName = catalogEntry.manufacturers.find(
        (m) => m.uid === manufacturer,
      )?.priceTableName;
      if (!priceTableName) {
        return null;
      }

      let manufactoryCatalog = context.catalog.ducts[
        filled.conduit.material!
      ]?.manufacturers.find((m) => m.uid === manufacturer);

      const manufacturerWithoutMaterial =
        manufactoryCatalog && manufactoryCatalog.overridBrandName
          ? manufactoryCatalog.overridBrandName
          : manufacturer.split(/(?=[A-Z])/)[0].toUpperCase(); // Use pipe's uid until first capital case letter

      if (filled.conduit.shape == "circular") {
        const size =
          context.globalStore.getOrCreateCalculation(filled).diameterMM!;
        if (priceTableName in context.priceTable.Ducts) {
          const availableSizes = Object.keys(
            context.catalog.ducts[filled.conduit.material!]?.data[manufacturer]
              .circularByDiameter ?? {},
          )
            .map((a) => Number(a))
            .sort((a, b) => a - b);
          const bestSize = availableSizes.find((s) => s >= size);
          if (
            bestSize !== undefined &&
            bestSize in
              context.priceTable.Ducts[priceTableName].circularByDiameter
          ) {
            const res: CostBreakdown = {
              cost:
                context.priceTable.Ducts[priceTableName].circularByDiameter[
                  bestSize
                ] * filled.lengthM!,
              breakdown: [
                {
                  qty: filled.lengthM!,
                  path: `Ducts.${priceTableName}.circularByDiameter.${bestSize}`,
                  manufacturer:
                    manufacturerWithoutMaterial +
                    ` ${
                      manufactoryCatalog
                        ? lowerCase(manufactoryCatalog?.priceTableName)
                        : "generic"
                    }`,
                },
              ],
            };
            return res;
          }
        }
      } else if (filled.conduit.shape == "rectangular") {
        const heightMM =
          context.globalStore.getOrCreateCalculation(filled).heightMM;
        const widthMM =
          context.globalStore.getOrCreateCalculation(filled).widthMM;

        let bestHeight: DuctHeightMM | undefined = undefined;
        let bestWidth: DuctWidthMM | undefined = undefined;

        if (priceTableName in context.priceTable.Ducts) {
          for (const [availableHeights, value] of Object.entries(
            context.catalog.ducts[filled.conduit.material!]?.data[manufacturer]
              .rectangularByHeightWidth ?? {},
          )) {
            for (const availableWidths of Object.keys(value)) {
              if (
                heightMM === null ||
                widthMM === null ||
                (Number(availableHeights) >= heightMM &&
                  Number(availableWidths) >= widthMM)
              ) {
                if (
                  availableHeights in
                    context.priceTable.Ducts[priceTableName]
                      .rectangularByHeightWidth &&
                  availableWidths in
                    context.priceTable.Ducts[priceTableName]
                      .rectangularByHeightWidth[Number(availableHeights)]
                ) {
                  if (
                    bestHeight === undefined ||
                    bestWidth === undefined ||
                    context.priceTable.Ducts[priceTableName]
                      .rectangularByHeightWidth[Number(availableHeights)][
                      Number(availableWidths)
                    ] >=
                      context.priceTable.Ducts[priceTableName]
                        .rectangularByHeightWidth[bestHeight][bestWidth]
                  ) {
                    bestHeight = Number(availableHeights);
                    bestWidth = Number(availableWidths);
                  }
                }
              }
            }
          }
          if (
            bestHeight !== undefined &&
            bestWidth !== undefined &&
            bestHeight in
              context.priceTable.Ducts[priceTableName]
                .rectangularByHeightWidth &&
            bestWidth in
              context.priceTable.Ducts[priceTableName].rectangularByHeightWidth[
                bestHeight
              ]
          ) {
            const res: CostBreakdown = {
              cost:
                context.priceTable.Ducts[priceTableName]
                  .rectangularByHeightWidth[bestHeight][bestWidth] *
                filled.lengthM!,
              breakdown: [
                {
                  qty: filled.lengthM!,
                  path: `Ducts.${priceTableName}.rectangularByHeightWidth.${bestHeight}.${bestWidth}`,
                  manufacturer:
                    manufacturerWithoutMaterial +
                    ` ${
                      manufactoryCatalog
                        ? lowerCase(manufactoryCatalog?.priceTableName)
                        : "generic"
                    }`,
                },
              ],
            };
            return res;
          }
        }
      }
    }
    return null;
  }

  getConnetectedSidePipe(pipeUid: string): CoreConduit[] {
    const connections: CoreConduit[] = [];
    for (const endpointUid of this.entity.endpointUid) {
      const endpoint =
        this.globalStore.get<CoreConnectableObjectConcrete>(endpointUid);
      if (endpoint.getConnectedSidePipe(pipeUid).length) {
        connections.push(endpoint.getConnectedSidePipe(pipeUid)[0]);
      }
    }
    return connections;
  }

  // TODO: make this applicable to things other than pipes.
  validateConnectionPoints(seenUids?: string[]): boolean {
    const connectedPipes = this.getConnetectedSidePipe(this.entity.uid)!;
    let result = false;
    for (const pipe of connectedPipes) {
      if (seenUids?.includes(pipe.uid)) {
        continue;
      }
      if (isPipeEntity(pipe.entity)) {
        const calc = this.globalStore.getCalculation(pipe.entity)!;
        if (calc.flowFrom) {
          if (calc.noFlowAvailableReason !== NoFlowAvailableReason.NO_SOURCE) {
            return true;
          }
        } else {
          result = pipe.validateConnectionPoints([
            ...(seenUids || []),
            this.entity.uid,
          ]);
        }
      }
    }
    return result;
  }

  get physicallyInversed() {
    return this.vector3.angleTo(this.physicalVector) > Math.PI / 2;
  }

  static getPipeManufacturerCatalogPage(
    context: CoreContext,
    entity: ConduitEntity,
    opts?: { ensureNonEmpty?: boolean },
  ): { [key: string]: PipeSpec } | null {
    const { drawing, catalog } = context;
    if (!isPipeEntity(entity)) {
      return null;
    }
    const computed = CoreConduit.getFilledNetworkEntity(context, entity);
    if (!computed.conduit.material) {
      return null;
    }
    return Object.fromEntries(
      filterPipesBySizeEntries({
        material: computed.conduit.material,
        metadataCatalog: drawing.metadata.catalog,
        catalog,
        flowSystem: getFlowSystem(drawing, computed.systemUid),
        entity: computed,
        ensureNonEmpty: !!opts?.ensureNonEmpty,
      }),
    );
  }

  static getCatalogBySizePage(
    context: CoreContext,
    entity: ConduitEntity,
  ): PipeSpec | null {
    // TODO: support other conduits
    if (!isPipeEntity(entity)) {
      return null;
    }

    const calculation = context.globalStore.getCalculation(entity);

    if (!calculation || !calculation.realNominalPipeDiameterMM) {
      return null;
    }
    const material = CoreConduit.getPipeCatalogPage(context, entity);
    if (!material) {
      return null;
    }

    const tableVal = lowerBoundTable(
      CoreConduit.getPipeManufacturerCatalogPage(context, entity)!,
      calculation.realNominalPipeDiameterMM,
    );

    return tableVal;
  }

  static getPipeCatalogPage(
    context: CoreContext,
    entity: PipeConduitEntity,
  ): PipeMaterial | null {
    const { drawing, catalog, globalStore } = context;

    const computed = CoreConduit.getFilledNetworkEntity(context, entity);
    if (!computed.conduit.material) {
      return null;
    }

    return catalog.pipes[computed.conduit.material];
  }

  static getDuctCatalogPage(context: CoreContext, entity: DuctConduitEntity) {
    const { drawing, catalog, globalStore } = context;

    // TODO: Can't you just use filled?
    const computed = fillDefaultConduitFields(context, entity);
    if (!computed.conduit.material) return null;
    return catalog.ducts[computed.conduit.material];
  }

  static getDuctManufacturerCatalogPage(
    context: CoreContext,
    entity: DuctConduitEntity,
  ): DuctManufacturerSpec | null {
    const { drawing, catalog } = context;
    if (isDuctEntity(entity)) {
      const computed = CoreConduit.getFilledNetworkEntity(context, entity);
      if (!computed.conduit.material) {
        return null;
      }
      const page =
        catalog.ducts[computed.conduit.material as DuctPhysicalMaterial];
      if (!page) {
        return null;
      }
      const manufacturer =
        drawing.metadata.catalog.ducts.find(
          (m) => m.uid === computed.conduit.material,
        )?.manufacturer || "generic";

      return page.data[manufacturer] || null;
    }
    return null;
  }

  static getFilledNetworkEntity(
    context: CoreContext,
    entity: PipeConduitEntity | DuctConduitEntity,
  ) {
    const drawing = context.drawing;
    const result = cloneSimple(entity);
    const system = getEntitySystem(drawing, result);
    if (system) {
      const network = getEntityNetwork<PipeFlowSystemTypes>(drawing, result);
      if (network) {
        if (result.conduit.maximumVelocityMS == null) {
          result.conduit.maximumVelocityMS =
            "velocityMS" in network ? Number(network.velocityMS) : 1e10;
        }
        if (result.conduit.material == null) {
          result.conduit.material = network.material;
        }
        if (result.conduit.maximumPressureDropRateKPAM == null) {
          result.conduit.maximumPressureDropRateKPAM =
            "pressureDropKPAM" in network
              ? Number(network.pressureDropKPAM)
              : 1e10;
        }
      }
    }
    return result;
  }

  getSlopeWrt(endpointIdx: 0 | 1) {
    const fromUid = this.entity.endpointUid[endpointIdx];
    const toUid = this.entity.endpointUid[1 - endpointIdx];

    const from = this.globalStore.get<CoreConnectableObjectConcrete>(fromUid);
    const to = this.globalStore.get<CoreConnectableObjectConcrete>(toUid);

    const fromPoint = from.shape.center;
    const toPoint = to.shape.center;

    return new Flatten.Segment(fromPoint, toPoint).slope;
  }
}
