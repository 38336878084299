import {
  assertType,
  assertUnreachable,
  cloneSimple,
  lowerBoundNumberTable,
  lowerBoundTable,
  parseCatalogNumberExact,
} from "../../lib/utils";
import { GetPressureLossOptions } from "../calculations/entity-pressure-drops";
import {
  fittingFrictionLossMH,
  getFluidDensityOfSystem,
  head2kpa,
} from "../calculations/pressure-drops";
import {
  CoreContext,
  PressureLossError,
  PressureLossResult,
  PressurePushMode,
} from "../calculations/types";
import { Catalog } from "../catalog/types";
import { ComponentPressureLossMethod, isGas } from "../config";
import DirectedValveCalculation, {
  DirectedValveLiveCalculation,
  emptyDirectedValveCalculation,
  emptyDirectedValveLiveCalculation,
} from "../document/calculations-objects/directed-valve-calculation";
import ConduitEntity, {
  MutableConduit,
} from "../document/entities/conduit-entity";
import DirectedValveEntity, {
  fillDirectedValveFields,
} from "../document/entities/directed-valves/directed-valve-entity";
import {
  Fan,
  ValveType,
  isVentsValve,
} from "../document/entities/directed-valves/valve-types";
import { FittingEntity } from "../document/entities/fitting-entity";
import { EntityType } from "../document/entities/types";
import { FlowSystem } from "../document/flow-systems";
import { getFlowSystem } from "../document/utils";
import { CoreConnectable } from "./core-traits/coreConnectable";
import CoreConduit from "./coreConduit";
import CoreBaseBackedObject from "./lib/coreBaseBackedObject";
import {
  determineConnectableSystemUid,
  getIdentityCalculationEntityUid,
  getRpzdManufacturer,
  getRpzdPressureLossKPA,
  isDirectedValveFlowSystemValid,
} from "./utils";

export default class CoreDirectedValve extends CoreConnectable(
  CoreBaseBackedObject<DirectedValveEntity>,
) {
  type: EntityType.DIRECTED_VALVE = EntityType.DIRECTED_VALVE;
  getComponentPressureLossKPA(
    options: GetPressureLossOptions,
  ): PressureLossResult {
    let { context, flowLS, from, to, signed } = options;
    const ga =
      context.drawing.metadata.calculationParams.gravitationalAcceleration;

    let sign = 1;
    if (flowLS < 0) {
      const oldFrom = from;
      from = to;
      to = oldFrom;
      flowLS = -flowLS;
      if (signed) {
        sign = -1;
      }
    }

    if (this.globalStore.getConnections(this.entity.uid).length !== 2) {
      return { pressureLossKPA: null };
    }

    // 1. Directional control
    switch (this.entity.valve.type) {
      case ValveType.CHECK_VALVE:
        if (from.connection !== this.entity.sourceUid) {
          return { pressureLossKPA: sign * (1e10 + flowLS) };
        }
        break;
      case ValveType.ISOLATION_VALVE:
        if (this.entity.valve.isClosed) {
          return { pressureLossKPA: sign * (1e10 + flowLS) };
        }
        break;
      case ValveType.RPZD_SINGLE:
      case ValveType.RPZD_DOUBLE_SHARED:
      case ValveType.RPZD_DOUBLE_ISOLATED:
        if (from.connection !== this.entity.sourceUid) {
          return { pressureLossKPA: sign * (1e10 + flowLS) };
        }
        break;
      case ValveType.GAS_REGULATOR:
      case ValveType.FAN:
      case ValveType.FILTER:
      case ValveType.CSV:
      case ValveType.RV:
      case ValveType.PRV_SINGLE:
      case ValveType.PRV_DOUBLE:
      case ValveType.PRV_TRIPLE:
      case ValveType.BALANCING:
      case ValveType.LSV:
      case ValveType.PICV:
      case ValveType.TRV:
      case ValveType.FLOOR_WASTE:
      case ValveType.INSPECTION_OPENING:
      case ValveType.REFLUX_VALVE:
      case ValveType.WATER_METER:
      case ValveType.STRAINER:
      case ValveType.CUSTOM_VALVE:
      case ValveType.SMOKE_DAMPER:
      case ValveType.FIRE_DAMPER:
      case ValveType.VOLUME_CONTROL_DAMPER:
      case ValveType.ATTENUATOR:
        break;
      default:
        assertUnreachable(this.entity.valve);
    }

    // 2. Pressure Loss Method
    switch (
      context.drawing.metadata.calculationParams.componentPressureLossMethod
    ) {
      case ComponentPressureLossMethod.INDIVIDUALLY:
        // Find pressure loss from components
        break;
      case ComponentPressureLossMethod.PERCENT_ON_TOP_OF_PIPE:
        return { pressureLossKPA: 0 };
      default:
        assertUnreachable(
          context.drawing.metadata.calculationParams
            .componentPressureLossMethod,
        );
    }

    // 3. Component pressure loss
    let kValue: number | null = 0;
    switch (this.entity.valve.type) {
      case ValveType.CHECK_VALVE:
      case ValveType.ISOLATION_VALVE:
      case ValveType.TRV:
      case ValveType.RV:
      case ValveType.STRAINER: {
        const table = context.catalog.valves[this.entity.valve.catalogId];
        const size = this.largestPipeSizeNominalMM(context);
        if (size === null) {
          return { pressureLossKPA: null };
        }
        const entry = lowerBoundTable(table.valvesBySize, size);
        if (entry !== null) {
          kValue = parseCatalogNumberExact(entry.kValue);
        } else {
          return {
            pressureLossKPA: null,
            error: PressureLossError.PipeExceedsValveDiameter,
          };
        }

        break;
      }

      case ValveType.RPZD_SINGLE:
      case ValveType.RPZD_DOUBLE_SHARED:
      case ValveType.RPZD_DOUBLE_ISOLATED: {
        const calcs = context.globalStore.getOrCreateCalculation(this.entity);
        const size = calcs.sizeMM;
        if (size === null) {
          return { pressureLossKPA: null };
        }

        const systemUid = determineConnectableSystemUid(
          context.globalStore,
          this.entity,
        );
        if (systemUid === undefined) {
          return { pressureLossKPA: null };
        }

        return getRpzdPressureLossKPA(
          context,
          this.entity.valve.catalogId,
          calcs.sizeMM!,
          flowLS,
          systemUid,
          this.entity.valve.type,
          this.entity.valve.type === ValveType.RPZD_DOUBLE_ISOLATED
            ? this.entity.valve.isolateOneWhenCalculatingHeadLoss
            : false,
        );
      }

      case ValveType.PRV_SINGLE:
      case ValveType.PRV_DOUBLE:
      case ValveType.PRV_TRIPLE:
        if (from.connection === this.entity.sourceUid) {
          // ok.
          return {
            pressureLossKPA: 0,
            maxPressureKPA: this.entity.valve.targetPressureKPA!,
          };
        } else {
          return { pressureLossKPA: null };
        }
      case ValveType.BALANCING:
      case ValveType.LSV:
      case ValveType.PICV:
        // Pressure for balancing valve is to be set beforehand.
        const sysUid = determineConnectableSystemUid(
          context.globalStore,
          this.entity,
        );
        if (!sysUid) {
          return { pressureLossKPA: null };
        }
        const vCalc = context.globalStore.getOrCreateCalculation(this.entity);
        if (vCalc.pressureDropKPA === null) {
          return { pressureLossKPA: null };
        }
        return {
          pressureLossKPA: vCalc.pressureDropKPA,
        };
      case ValveType.GAS_REGULATOR: {
        if (from.connection === this.entity.sourceUid) {
          return {
            pressureLossKPA: 0,
            maxPressureKPA: this.entity.valve.outletPressureKPA!,
          };
        } else {
          return { pressureLossKPA: null };
        }
      }
      case ValveType.WATER_METER:
      case ValveType.CSV:
      case ValveType.FILTER: {
        const systemUid = determineConnectableSystemUid(
          context.globalStore,
          this.entity,
        );
        if (systemUid === undefined) {
          return { pressureLossKPA: null };
        }
        if (options.pressurePushMode === PressurePushMode.Static) {
          return { pressureLossKPA: 0 };
        }

        return {
          pressureLossKPA: this.entity.valve.pressureDropKPA!,
        };
      }

      case ValveType.FAN: {
        if (options.pressurePushMode === PressurePushMode.Static) {
          return { pressureLossKPA: 0 };
        }

        const filled = fillDirectedValveFields(this.context, this.entity);
        assertType<Fan>(filled.valve);
        return {
          pressureLossKPA: filled.valve.pressureDropKPA!,
        };
      }
      case ValveType.FLOOR_WASTE:
      case ValveType.INSPECTION_OPENING:
      case ValveType.REFLUX_VALVE:
        break;
      case ValveType.SMOKE_DAMPER:
      case ValveType.FIRE_DAMPER:
      case ValveType.VOLUME_CONTROL_DAMPER:
      case ValveType.ATTENUATOR:
        return this.getVentValvePressureLossKPA();
      case ValveType.CUSTOM_VALVE:
        switch (this.entity.valve.pressureDropType) {
          case "fixed":
            return { pressureLossKPA: this.entity.valve.pressureDropKPA || 0 };
          case "kvalue":
            kValue = this.entity.valve.kValue;
            const vCalc = context.globalStore.getOrCreateCalculation(
              this.entity,
            );
            vCalc.kvValue = kValue;
            break;
          default:
            assertUnreachable(this.entity.valve.pressureDropType);
        }
        break;
      default:
        assertUnreachable(this.entity.valve);
    }

    const volLM =
      (this.largestPipeSizeInternal(context)! ** 2 * Math.PI) / 4 / 1000;
    const velocityMS = flowLS / volLM;

    if (kValue) {
      return {
        pressureLossKPA:
          sign *
          head2kpa(
            fittingFrictionLossMH(velocityMS, kValue, ga),
            getFluidDensityOfSystem(
              context,
              determineConnectableSystemUid(context.globalStore, this.entity)!,
            )!,
            ga,
          ),
      };
    } else {
      return {
        pressureLossKPA: 0,
      };
    }
  }
  getCalculationEntities(
    context: CoreContext,
  ): Array<DirectedValveEntity | ConduitEntity | FittingEntity> {
    const tower = this.getCalculationTower(context);
    if (tower.length === 0) {
      return [];
    } else if (tower.length === 1) {
      // replace the connectable
      const e = cloneSimple(this.entity);
      e.uid = tower[0][0].uid;
      e.calculationHeightM = tower[0][0].calculationHeightM;
      if (this.globalStore.has(e.sourceUid)) {
        e.sourceUid = this.globalStore
          .get(e.sourceUid)!
          .getCalculationEntities(context)[0].uid;
      }
      return [e];
    } else if (tower.length === 2) {
      // Plop us on the pipe in the middle.
      const pipe = tower[1][1]!;
      const p1 = cloneSimple(pipe);
      const p2 = cloneSimple(pipe);
      p2.uid = p1.uid + ".segment.2";
      (p1 as MutableConduit).endpointUid = [
        p1.endpointUid[0],
        this.uid + ".calculation",
      ];
      (p2 as MutableConduit).endpointUid = [
        this.uid + ".calculation",
        p2.endpointUid[1],
      ];
      let lower: string;
      let higher: string;
      if (p1.endpointUid[0] === tower[0][0].uid) {
        lower = p1.uid;
        higher = p2.uid;
      } else {
        lower = p2.uid;
        higher = p1.uid;
      }

      if (lower === undefined || higher === undefined) {
        throw new Error("Invalid tower configuration");
      }

      const e = cloneSimple(this.entity);
      e.uid = this.getCalculationUid(context);
      e.calculationHeightM =
        (tower[0][0].calculationHeightM! + tower[1][0].calculationHeightM!) / 2;
      e.parentUid = getIdentityCalculationEntityUid(context, e.parentUid);

      // https://h2xengineering.atlassian.net/browse/SEED-154
      // Unfixed bug: sometimes, valves have empty sourceUID despite having a couple
      // of connections. Still not sure how this happens. But we can at least prevent
      // a crash here.
      if (!this.globalStore.get(this.entity.sourceUid)) {
        e.sourceUid = lower;

        // we have to figure out whether source is the lower one or higher one.
      } else if (
        (this.globalStore.get(this.entity.sourceUid) as CoreConduit).entity
          .heightAboveFloorM <
        (this.globalStore.get(this.otherUid!) as CoreConduit).entity
          .heightAboveFloorM
      ) {
        // source is #0
        e.sourceUid = lower;
      } else {
        e.sourceUid = higher;
      }

      return [e, tower[0][0], p1, p2, tower[1][0]];
    } else {
      throw new Error("Invalid tower configuration on directed valve");
    }
  }

  getVentValvePressureLossKPA(): PressureLossResult {
    const velocityPressureKPA = this.getVentVelocityPressureKPA();

    if (velocityPressureKPA !== null) {
      const filled = fillDirectedValveFields(this.context, this.entity);

      if (isVentsValve(filled.valve) && filled.valve.type !== ValveType.FAN) {
        return {
          pressureLossKPA: velocityPressureKPA * filled.valve.zeta!,
        };
      }
    }

    return { pressureLossKPA: null };
  }

  get otherUid(): string | undefined {
    return this.globalStore
      .getConnections(this.uid)
      .find((uid) => uid !== this.entity.sourceUid);
  }

  collectCalculations(context: CoreContext): DirectedValveCalculation {
    // find the only directed valve in there and take its calc.
    const me = this.getCalculationEntities(context).find(
      (e) => e.type === EntityType.DIRECTED_VALVE,
    ) as DirectedValveEntity;

    if (!me) {
      if (this.getCalculationEntities(context).length === 0) {
        return emptyDirectedValveCalculation();
      } else {
        throw new Error(
          "Can't find self in calculations " +
            this.uid +
            " " +
            this.entity.valve.type,
        );
      }
    } else {
      return context.globalStore.getOrCreateCalculation(me);
    }
  }

  collectLiveCalculations(context: CoreContext): DirectedValveLiveCalculation {
    // find the only directed valve in there and take its calc.
    const me = this.getCalculationEntities(context).find(
      (e) => e.type === EntityType.DIRECTED_VALVE,
    ) as DirectedValveEntity;

    if (!me) {
      if (this.getCalculationEntities(context).length === 0) {
        return emptyDirectedValveLiveCalculation();
      } else {
        throw new Error(
          "Can't find self in calculations " +
            this.uid +
            " " +
            this.entity.valve.type,
        );
      }
    } else {
      return context.globalStore.getOrCreateLiveCalculation(me);
    }
  }

  costBreakdown(context: CoreContext) {
    let pipeSize = null;
    if (!isVentsValve(this.entity.valve)) {
      pipeSize = this.largestPipeSizeNominalMM(context);
    }
    let size = pipeSize;

    const calc = context.globalStore.getOrCreateCalculation(this.entity);
    let ownSize = calc.sizeMM;
    switch (this.entity.valve.type) {
      case ValveType.CHECK_VALVE:
        size = lowerBoundNumberTable(
          context.priceTable.Valves["Check Valve"],
          size,
        );
        if (size) {
          return {
            cost: context.priceTable.Valves["Check Valve"][size],
            breakdown: [
              {
                type: "valve",
                qty: 1,
                path: `Valves.Check Valve.${size}`,
                pipeSize: pipeSize,
              },
            ],
          };
        }
        break;
      case ValveType.ISOLATION_VALVE:
        switch (this.entity.valve.catalogId) {
          case "gateValve":
            size = lowerBoundNumberTable(
              context.priceTable.Valves["Brass Gate Valve"],
              size,
            );
            if (size) {
              return {
                cost: context.priceTable.Valves["Brass Gate Valve"][size],
                breakdown: [
                  {
                    type: "valve",
                    qty: 1,
                    path: `Valves.Brass Gate Valve.${size}`,
                    pipeSize: pipeSize,
                  },
                ],
              };
            }
            break;
          case "ballValve":
            size = lowerBoundNumberTable(
              context.priceTable.Valves["Brass Ball Valve"],
              size,
            );
            if (size) {
              return {
                cost: context.priceTable.Valves["Brass Ball Valve"][size],
                breakdown: [
                  {
                    type: "valve",
                    qty: 1,
                    path: `Valves.Brass Ball Valve.${size}`,
                    pipeSize: pipeSize,
                  },
                ],
              };
            }
            break;
          case "butterflyValve":
            size = lowerBoundNumberTable(
              context.priceTable.Valves["Butterfly Valve"],
              size,
            );
            if (size) {
              return {
                cost: context.priceTable.Valves["Butterfly Valve"][size],
                breakdown: [
                  {
                    type: "valve",
                    qty: 1,
                    path: `Valves.Butterfly Valve.${size}`,
                    pipeSize: pipeSize,
                  },
                ],
              };
            }
            break;
        }
        break;
      case ValveType.WATER_METER: {
        const system = determineConnectableSystemUid(
          context.globalStore,
          this.entity,
        );
        if (isGas(context.drawing.metadata.flowSystems[system!])) {
          return {
            cost: context.priceTable.Equipment["Gas Meter"],
            breakdown: [
              {
                type: "valve",
                qty: 1,
                path: `Equipment.Gas Meter`,
                pipeSize: pipeSize,
              },
            ],
          };
        } else {
          size = lowerBoundNumberTable(
            context.priceTable.Valves["Water Meter"],
            size,
          );
          if (size) {
            return {
              cost: context.priceTable.Valves["Water Meter"][size],
              breakdown: [
                {
                  type: "valve",
                  qty: 1,
                  path: `Valves.Water Meter.${size}`,
                  pipeSize: pipeSize,
                },
              ],
            };
          }
        }

        break;
      }
      case ValveType.STRAINER:
        size = lowerBoundNumberTable(context.priceTable.Valves.Strainer, size);
        if (size) {
          return {
            cost: context.priceTable.Valves["Strainer"][size],
            breakdown: [
              {
                type: "valve",
                qty: 1,
                path: `Valves.Strainer.${size}`,
                pipeSize: pipeSize,
              },
            ],
          };
        }
        break;
      case ValveType.RV:
        size = lowerBoundNumberTable(
          context.priceTable.Valves["Pressure Relief Valve"],
          size,
        );
        if (size) {
          return {
            cost: context.priceTable.Valves["Pressure Relief Valve"][size],
            breakdown: [
              {
                type: "valve",
                qty: 1,
                path: `Valves.Pressure Relief Valve.${size}`,
                pipeSize: pipeSize,
              },
            ],
          };
        }
        break;
      case ValveType.RPZD_SINGLE:
        ownSize = lowerBoundNumberTable(
          context.priceTable.Equipment.RPZD,
          ownSize,
        );
        if (ownSize) {
          return {
            cost: context.priceTable.Equipment.RPZD[ownSize],
            breakdown: [
              {
                type: "valve",
                qty: 1,
                path: `Equipment.RPZD.${ownSize}`,
                pipeSize: pipeSize,
                manufacturer: getRpzdManufacturer(context),
              },
            ],
          };
        }
        break;
      case ValveType.RPZD_DOUBLE_SHARED:
        ownSize = lowerBoundNumberTable(
          context.priceTable.Equipment.RPZD,
          ownSize,
        );
        if (ownSize) {
          return {
            cost: context.priceTable.Equipment.RPZD[ownSize] * 2,
            breakdown: [
              {
                type: "valve",
                qty: 2,
                path: `Equipment.RPZD.${ownSize}`,
                pipeSize: pipeSize,
                manufacturer: getRpzdManufacturer(context),
              },
            ],
          };
        }
        break;
      case ValveType.RPZD_DOUBLE_ISOLATED:
        ownSize = lowerBoundNumberTable(
          context.priceTable.Equipment.RPZD,
          ownSize,
        );
        if (ownSize) {
          return {
            cost: context.priceTable.Equipment.RPZD[ownSize] * 2,
            breakdown: [
              {
                type: "valve",
                qty: 2,
                path: `Equipment.RPZD.${ownSize}`,
                pipeSize: pipeSize,
                manufacturer: getRpzdManufacturer(context),
              },
            ],
          };
        }
        break;
      case ValveType.PRV_SINGLE:
        ownSize = lowerBoundNumberTable(
          context.priceTable.Equipment.PRV,
          ownSize,
        );
        if (ownSize) {
          return {
            cost: context.priceTable.Equipment.PRV[ownSize],
            breakdown: [
              {
                type: "valve",
                qty: 1,
                path: `Equipment.PRV.${ownSize}`,
                pipeSize: pipeSize,
                manufacturer:
                  context.drawing.metadata.catalog.prv.find(
                    (i) => i.uid === "prv",
                  )?.manufacturer || "generic",
              },
            ],
          };
        }
        break;
      case ValveType.PRV_DOUBLE:
        ownSize = lowerBoundNumberTable(
          context.priceTable.Equipment.PRV,
          ownSize,
        );
        if (ownSize) {
          return {
            cost: context.priceTable.Equipment.PRV[ownSize] * 2,
            breakdown: [
              {
                type: "valve",
                qty: 2,
                path: `Equipment.PRV.${ownSize}`,
                pipeSize: pipeSize,
                manufacturer:
                  context.drawing.metadata.catalog.prv.find(
                    (i) => i.uid === "prv",
                  )?.manufacturer || "generic",
              },
            ],
          };
        }
        break;
      case ValveType.PRV_TRIPLE:
        ownSize = lowerBoundNumberTable(
          context.priceTable.Equipment.PRV,
          ownSize,
        );
        if (ownSize) {
          return {
            cost: context.priceTable.Equipment.PRV[ownSize] * 2,
            breakdown: [
              {
                type: "valve",
                qty: 2,
                path: `Equipment.PRV.${ownSize}`,
                pipeSize: pipeSize,
                manufacturer:
                  context.drawing.metadata.catalog.prv.find(
                    (i) => i.uid === "prv",
                  )?.manufacturer || "generic",
              },
            ],
          };
        }
        break;
      case ValveType.CSV:
        size = lowerBoundNumberTable(context.priceTable.Equipment["CSV"], size);
        if (size) {
          return {
            cost: context.priceTable.Equipment["CSV"][size],
            breakdown: [
              {
                type: "valve",
                qty: 1,
                path: `Equipment.CSV.${size}`,
                pipeSize: pipeSize,
              },
            ],
          };
        }
        break;
      case ValveType.BALANCING:
        size = lowerBoundNumberTable(
          context.priceTable.Equipment["Balancing Valve"],
          size,
        );
        if (size) {
          return {
            cost: context.priceTable.Equipment["Balancing Valve"][size],
            breakdown: [
              {
                type: "valve",
                qty: 1,
                path: `Equipment.Balancing Valve.${size}`,
                pipeSize: pipeSize,
                manufacturer:
                  context.drawing.metadata.catalog.balancingValves.find(
                    (i) => i.uid === "balancingValves",
                  )?.manufacturer || "generic",
              },
            ],
          };
        }
        break;
      case ValveType.LSV:
        size = lowerBoundNumberTable(
          context.priceTable.Equipment["Lockshield Valve"],
          size,
        );
        if (size) {
          return {
            cost: context.priceTable.Equipment["Lockshield Valve"][size],
            breakdown: [
              {
                type: "valve",
                qty: 1,
                path: `Equipment.Lockshield Valve.${size}`,
                pipeSize: pipeSize,
              },
            ],
          };
        }
      case ValveType.PICV:
        size = lowerBoundNumberTable(
          context.priceTable.Equipment["PICV"],
          size,
        );
        if (size) {
          return {
            cost: context.priceTable.Equipment["PICV"][size],
            breakdown: [
              {
                type: "valve",
                qty: 1,
                path: `Equipment.PICV.${size}`,
                pipeSize: pipeSize,
              },
            ],
          };
        }
      case ValveType.TRV:
        size = lowerBoundNumberTable(
          context.priceTable.Equipment["Thermostatic Control Valve"],
          size,
        );
        if (size) {
          return {
            cost: context.priceTable.Equipment["Thermostatic Control Valve"][
              size
            ],
            breakdown: [
              {
                type: "valve",
                qty: 1,
                path: `Equipment.Thermostatic Control Valve.${size}`,
                pipeSize: pipeSize,
              },
            ],
          };
        }
      case ValveType.FILTER:
        return {
          cost: context.priceTable.Equipment["Gas Filter"],
          breakdown: [
            {
              type: "valve",
              qty: 1,
              path: `Equipment.Gas Filter`,
              pipeSize: pipeSize,
            },
          ],
        };
      case ValveType.GAS_REGULATOR:
        return {
          cost: context.priceTable.Equipment["Gas Regulator"],
          breakdown: [
            {
              type: "valve",
              qty: 1,
              path: `Equipment.Gas Regulator`,
              pipeSize: pipeSize,
            },
          ],
        };
      case ValveType.FLOOR_WASTE:
        return {
          cost: context.priceTable.Equipment["Floor Waste"],
          breakdown: [
            {
              type: "valve",
              qty: 1,
              path: `Equipment.Floor Waste`,
              pipeSize: pipeSize,
              manufacturer:
                context.drawing.metadata.catalog.floorWaste.find(
                  (i) => i.uid === "floorWaste",
                )?.manufacturer || "generic",
            },
          ],
        };
      case ValveType.INSPECTION_OPENING:
        return {
          cost: context.priceTable.Equipment["Inspection Opening"],
          breakdown: [
            {
              type: "valve",
              qty: 1,
              path: `Equipment.Inspection Opening`,
              pipeSize: pipeSize,
              manufacturer:
                context.drawing.metadata.catalog.inspectionOpening.find(
                  (i) => i.uid === "inspectionOpening",
                )?.manufacturer || "generic",
            },
          ],
        };
      case ValveType.REFLUX_VALVE:
        size = lowerBoundNumberTable(
          context.priceTable.Equipment["Reflux Valve"],
          size,
        );
        if (size) {
          return {
            cost: context.priceTable.Equipment["Reflux Valve"][size],
            breakdown: [
              {
                type: "valve",
                qty: 1,
                path: `Equipment.Reflux Valve.${size}`,
                pipeSize: pipeSize,
              },
            ],
          };
        }
        break;
      case ValveType.FAN:
        return {
          cost: context.priceTable.Equipment["Fan"],
          breakdown: [
            {
              type: "valve",
              qty: 1,
              path: `Equipment.Fan`,
              pipeSize: null,
            },
          ],
        };
      case ValveType.CUSTOM_VALVE:
        return {
          cost: context.priceTable.Valves["Custom Valve"], // there is only one price
          breakdown: [
            {
              type: "valve",
              qty: 1,
              path: `Valves.Custom Valve`, // there is only one size
              pipeSize: pipeSize,
            },
          ],
        };
      case ValveType.SMOKE_DAMPER:
        return {
          cost: context.priceTable.Equipment["Smoke Damper"],
          breakdown: [
            {
              type: "valve",
              qty: 1,
              path: `Equipment.Smoke Damper`,
              pipeSize: pipeSize,
            },
          ],
        };
      case ValveType.FIRE_DAMPER:
        return {
          cost: context.priceTable.Equipment["Fire Damper"],
          breakdown: [
            {
              type: "valve",
              qty: 1,
              path: `Equipment.Fire Damper`,
              pipeSize: pipeSize,
            },
          ],
        };
      case ValveType.VOLUME_CONTROL_DAMPER:
        return {
          cost: context.priceTable.Equipment["Volume Control Damper"],
          breakdown: [
            {
              type: "valve",
              qty: 1,
              path: `Equipment.Volume Control Damper`,
              pipeSize: pipeSize,
            },
          ],
        };
      case ValveType.ATTENUATOR:
        return {
          cost: context.priceTable.Equipment["Attenuator"],
          breakdown: [
            {
              type: "valve",
              qty: 1,
              path: `Equipment.Attenuator`,
              pipeSize: pipeSize,
            },
          ],
        };
      default:
        assertUnreachable(this.entity.valve);
    }
    return null;
  }
  getCalculationUid(context: CoreContext): string {
    return this.entity.uid + ".calculation";
  }
  preCalculationValidation(context: CoreContext) {
    return null;
  }

  friendlyTypeName(catalog: Catalog): string {
    switch (this.entity.valve.type) {
      case ValveType.ISOLATION_VALVE:
        const systemUid = determineConnectableSystemUid(
          this.globalStore,
          this.entity,
        );
        if (isGas(this.drawing.metadata.flowSystems[systemUid!])) {
          return "Isolation Valve";
        }
      // noinspection FallThroughInSwitchStatementJS
      case ValveType.CHECK_VALVE:
      case ValveType.WATER_METER:
      case ValveType.STRAINER:
        return catalog.valves[this.entity.valve.catalogId].name;
      case ValveType.CSV:
        return "Commissioning Set Valve";
      case ValveType.RV:
        return "Pressure Relief Valve";
      case ValveType.RPZD_DOUBLE_ISOLATED:
        return "RPZD Double Isolated - 100% Load";
      case ValveType.RPZD_SINGLE:
        return "RPZD";
      case ValveType.RPZD_DOUBLE_SHARED:
        return "RPZD Double - 50/50 Load";
      case ValveType.PRV_SINGLE:
        return "PRV Single";
      case ValveType.PRV_DOUBLE:
        return "PRV Double (50% Load Each)";
      case ValveType.PRV_TRIPLE:
        return "PRV Triple (33.3% Load Each)";
      case ValveType.BALANCING:
        return "Balancing Valve";
      case ValveType.LSV:
        return "Lockshield Valve";
      case ValveType.PICV:
        return "PICV";
      case ValveType.TRV:
        return "Thermostatic Control Valve";
      case ValveType.GAS_REGULATOR:
        return "Gas Regulator";
      case ValveType.FILTER:
        return "Filter";
      case ValveType.FLOOR_WASTE:
        return "Floor Waste";
      case ValveType.INSPECTION_OPENING:
        return "Inspection Opening";
      case ValveType.REFLUX_VALVE:
        return "Reflux Valve";
      case ValveType.SMOKE_DAMPER:
        return "Smoke Damper";
      case ValveType.FIRE_DAMPER:
        return "Fire Damper";
      case ValveType.VOLUME_CONTROL_DAMPER:
        return "Volume Control Damper";
      case ValveType.ATTENUATOR:
        return "Attenuator";
      case ValveType.CUSTOM_VALVE:
        return "Custom Valve";
      case ValveType.FAN:
        return "Fan";
    }
    assertUnreachable(this.entity.valve);
  }

  isFlowsystemValid(fs?: FlowSystem) {
    if (fs) {
      return isDirectedValveFlowSystemValid(this.entity, fs);
    }

    const fsUid = determineConnectableSystemUid(this.globalStore, this.entity);
    const fss = getFlowSystem(this.drawing, fsUid);
    if (!fss) {
      return false;
    }
    return isDirectedValveFlowSystemValid(this.entity, fss);
  }
}
