import { DrawingLayout } from "../../calculations/types";
import {
  isDrainage,
  isMechanical,
  isPressure,
  isVentilation,
} from "../../config";
import { FlowSystem } from "../flow-systems";
import {
  CalculationConcrete,
  LiveCalculationConcrete,
} from "./calculation-concrete";
import { CalcFieldSelector } from "./types";

export function getFlowSystemLayouts(system: FlowSystem | undefined): {
  layouts: DrawingLayout[];
  layoutsExcept: (...exclude: DrawingLayout[]) => DrawingLayout[];
  onlyLayouts: (...include: DrawingLayout[]) => DrawingLayout[];
} {
  const layoutStrict: DrawingLayout[] = [];
  if (!system) {
    return {
      layouts: layoutStrict,
      layoutsExcept: (...exclude: DrawingLayout[]) => layoutStrict,
      onlyLayouts: (...include: DrawingLayout[]) => layoutStrict,
    };
  }

  if (isDrainage(system)) {
    layoutStrict.push("drainage");
  }
  if (isMechanical(system)) {
    layoutStrict.push("mechanical");
  }
  if (isPressure(system)) {
    layoutStrict.push("pressure");
  }
  if (isVentilation(system)) {
    layoutStrict.push("ventilation");
  }
  const layoutsExcept = (...exclude: DrawingLayout[]) => {
    return layoutStrict.filter((l) => !exclude.includes(l));
  };
  const onlyLayouts = (...include: DrawingLayout[]) => {
    return layoutStrict.filter((l) => include.includes(l));
  };
  return { layouts: layoutStrict, layoutsExcept, onlyLayouts };
}

export function applyCalcFieldSelection<T extends LiveCalculationConcrete>(
  target: Partial<T>,
  source: T,
  sticky: CalcFieldSelector<T>,
): T;
export function applyCalcFieldSelection<T extends CalculationConcrete>(
  target: Partial<T>,
  source: T,
  sticky: CalcFieldSelector<T>,
): T;
// Side effect: If array element fields are specified,
// Array elements will be completely replaced, and sibling fields that
// are not sticky will be removed.
// This doesn't work with 2d arrays either.
// Target ought to be an empty initial calculation.
export function applyCalcFieldSelection<
  T extends CalculationConcrete | LiveCalculationConcrete,
>(target: Partial<T>, source: T, sticky: CalcFieldSelector<T>): T {
  return applyCalcFieldSelectionHelper(target, source, sticky, withNullProps);
}

export function extractCalcFieldSelection<T extends CalculationConcrete>(
  source: T,
  sticky: CalcFieldSelector<T>,
): Partial<T> {
  return applyCalcFieldSelectionHelper({}, source, sticky, emptyObject);
}

function withNullProps(obj: any) {
  const result: any = {};
  for (const key in obj) {
    result[key] = null;
  }
  return result;
}

function emptyObject() {
  return {};
}

function applyCalcFieldSelectionHelper(
  target: any,
  source: any,
  sticky: any,
  initNestedObject: (obj: any) => any,
) {
  for (const key in sticky) {
    if (key === "__all__") {
      if (typeof source !== "object") {
        throw new Error("Cannot apply __all__ sticky fields to non-object");
      }
      for (const k in source[key]) {
        const v = initNestedObject(source[key][k]);
        target[key][k] = applyCalcFieldSelection(
          v,
          source[key][k],
          sticky[key],
        );
      }
    } else if (sticky[key] && source[key] != null) {
      if (Array.isArray(source[key])) {
        target[key] = [];
        for (const v of source[key]) {
          if (typeof v === "object") {
            const o = {};
            applyCalcFieldSelectionHelper(o, v, sticky[key], initNestedObject);
            target[key].push(o);
          } else {
            target[key].push(v);
          }
        }
      } else if (typeof source[key] === "object") {
        target[key] = applyCalcFieldSelectionHelper(
          target[key] || initNestedObject(source[key]),
          source[key],
          sticky[key],
          initNestedObject,
        );
      } else {
        if (Array.isArray(sticky[key])) {
          if (sticky[key].includes(source[key])) {
            target[key] = source[key];
          }
        } else {
          target[key] = source[key];
        }
      }
    } else if (sticky[key] && source[key] == null) {
      target[key] = null;
    }
  }
  return target;
}
