import { GetPressureLossOptions } from "../../../../common/src/api/calculations/entity-pressure-drops";
import { PressureLossResult } from "../../../../common/src/api/calculations/types";
import CoreWall from "../../../../common/src/api/coreObjects/coreWall";
import { DrawableEntityConcrete } from "../../../../common/src/api/document/entities/concrete-entity";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import {
  WallEntity,
  fillDefaultWallFields,
} from "../../../../common/src/api/document/entities/wall-entity";
import { lighten } from "../../../../common/src/lib/color";
import { Coord, coordDist2 } from "../../../../common/src/lib/coord";
import { angleDiffCWRad } from "../../../../common/src/lib/mathUtils/mathutils";
import { EPS } from "../../../../common/src/lib/utils";
import { cssColor2rgba, lerpColor } from "../../lib/utils";
import CanvasContext from "../lib/canvas-context";
import { DrawingArgs, EntityDrawingArgs } from "../lib/drawable-object";
import { EntityPopupContent } from "../lib/entity-popups/types";
import { Interaction } from "../lib/interaction";
import { CalculatedObject } from "../lib/object-traits/calculated-object";
import { Core2Drawable } from "../lib/object-traits/core2drawable";
import { DraggableObject } from "../lib/object-traits/draggable-object";
import {
  HoverSiblingResult,
  HoverableObject,
} from "../lib/object-traits/hoverable-object";
import { SelectableObject } from "../lib/object-traits/selectable";
import { SnappableObject } from "../lib/object-traits/snappable-object";
import { VirtualEdgeObject } from "../lib/object-traits/virtual-edge-object";
import { DrawingContext, ObjectConstructArgs } from "../lib/types";
import { DrawingMode } from "../types";
import { DrawableObjectConcrete } from "./concrete-object";
import { MIN_PIPE_PIXEL_WIDTH } from "./drawableConduit";
import DrawableEdge from "./drawableEdge";
import DrawableRoom from "./drawableRoom";
import DrawableVertex from "./drawableVertex";
import { WALL_PATTERNS } from "./wall-texture-util";

const Base = CalculatedObject(
  SelectableObject(
    DraggableObject(
      HoverableObject(
        SnappableObject(VirtualEdgeObject(Core2Drawable(CoreWall))),
      ),
    ),
  ),
);

function generateWallPatternSVG(
  patternName: string,
  baseColor: string,
): string | null {
  const { r, g, b, a } = cssColor2rgba(baseColor);
  const pattern = WALL_PATTERNS[patternName];
  if (pattern === undefined) {
    return null;
  }
  return pattern.pattern(baseColor, { r, g, b, a });
}

export default class DrawableWall extends Base {
  type: EntityType.WALL = EntityType.WALL;
  private static patternMap = new Map<string, CanvasPattern>();

  // We could not add this to the base drawable class because
  // of some typescript thing so they have to be added at the concrete class.
  constructor(args: ObjectConstructArgs<WallEntity>) {
    super(args.context, args.obj);
    this.onSelect = args.onSelect;
    this.onInteractionComplete = args.onInteractionComplete;
    this.document = args.document;
  }

  getFrictionPressureLossKPA(
    _options: GetPressureLossOptions,
  ): PressureLossResult {
    throw new Error("Method not implemented.");
  }

  getHoverSiblings(): HoverSiblingResult[] {
    if (this.document.uiState.drawingMode !== DrawingMode.FloorPlan) {
      return [];
    }
    const polygons = this.globalStore.getPolygonsByEdge(this.entity.uid);
    const result: HoverSiblingResult[] = [];
    for (const polygon of polygons) {
      const object = this.globalStore.get<DrawableRoom>(polygon);
      if (object) {
        result.concat(object.getHoverSiblings());
      } else {
        throw new Error("Unexpected type");
      }
    }
    return result;
  }

  getPopupContent(): EntityPopupContent[] | null {
    return null;
  }

  // TODO: seet all tese patterns in the first place
  async loadPattern(
    ctx: CanvasRenderingContext2D,
    data: string,
  ): Promise<CanvasPattern | null> {
    return new Promise<CanvasPattern>((resolve, reject) => {
      const img = new Image();

      img.onerror = reject;
      img.src = data;

      img.onload = () => {
        const pattern = ctx.createPattern(img, "repeat");

        if (pattern) {
          resolve(pattern);
        } else {
          reject(new Error("Failed to create pattern"));
        }
      };
    });
  }

  loadTexture(
    context: DrawingContext,
    ctx: CanvasRenderingContext2D,
    baseColor: string,
    forExport: boolean,
  ) {
    if (forExport) {
      console.warn("Wall texture not supported for export");
      return;
    }
    const filledRoom = fillDefaultWallFields(context, this.entity);
    const patternId = filledRoom.wallMaterialUid ?? "";

    const pattern = DrawableWall.patternMap.get(patternId + baseColor);
    if (pattern) {
      ctx.fillStyle = pattern;
    } else {
      ctx.fillStyle = baseColor;
      // Assuming 'patterns' object is defined somewhere
      const patternSvg = generateWallPatternSVG(patternId, baseColor);
      if (!patternSvg) {
        return;
      }

      const patternData = "data:image/svg+xml;base64," + btoa(patternSvg);
      this.loadPattern(ctx, patternData)
        .then((pattern) => {
          // No cache if it's for export, CanvasToSvg don't like it
          if (pattern) {
            DrawableWall.patternMap.set(patternId + baseColor, pattern);

            ctx.fillStyle = pattern;
          } else {
            throw new Error("Failed");
          }
        })
        .catch((e) => {
          console.log("Error loading pattern", e);
        });
    }
  }

  loadTextureLowRes(
    context: DrawingContext,
    ctx: CanvasRenderingContext2D,
    baseColor: string,
    backgroundColor: string,
  ) {
    const filledRoom = fillDefaultWallFields(context, this.entity);
    const patternId = filledRoom.wallMaterialUid ?? "";

    if (WALL_PATTERNS[patternId]) {
      ctx.fillStyle = lerpColor(
        backgroundColor,
        baseColor,
        WALL_PATTERNS[patternId].density,
      );
    } else {
      ctx.fillStyle = baseColor;
    }
  }

  drawWallWithPattern(
    context: DrawingContext,
    args: EntityDrawingArgs,
    ctx: CanvasRenderingContext2D,
    coreWall: CoreWall,
    baseWidth: number,
    baseColor: string,
    forExport: boolean,
  ) {
    const { wallSpec } = context.drawing.metadata.heatLoss;
    const segs = this.getWorldSegments();

    if (!segs || segs.length === 0) {
      console.error("No world segments found for wall", this.uid);
      return;
    }

    const ownEdge = context.globalStore.get<DrawableEdge>(
      this.entity.polygonEdgeUid[0],
    );

    const checkFlipped = coreWall.normalizedCCWIfExternalWall(segs[0]);
    const flipped = coordDist2(checkFlipped[0], segs[0][0]) > EPS;

    const ownVector = flipped ? ownEdge.vector.multiply(-1) : ownEdge.vector;
    const leftUid = flipped
      ? ownEdge.entity.endpointUid[1]
      : ownEdge.entity.endpointUid[0];
    const rightUid = flipped
      ? ownEdge.entity.endpointUid[0]
      : ownEdge.entity.endpointUid[1];

    const leftVertex = context.globalStore.get<DrawableVertex>(leftUid);
    const rightVertex = context.globalStore.get<DrawableVertex>(rightUid);

    const leftEdgeUid = context.globalStore
      .getConnections(leftUid)
      .filter((u) => u !== ownEdge.uid)[0];
    const rightEdgeUid = context.globalStore
      .getConnections(rightUid)
      .filter((u) => u !== ownEdge.uid)[0];

    const leftSegmentPoint = flipped ? segs[0][1] : segs[0][0];
    const rightSegmentPoint = flipped
      ? segs[segs.length - 1][0]
      : segs[segs.length - 1][1];

    const leftIsEndpoint =
      coordDist2(leftSegmentPoint, leftVertex.toWorldCoord()) < EPS;
    const leftIsInternal =
      !leftIsEndpoint ||
      context.globalStore
        .getWallsByRoomEdge(leftEdgeUid)
        .map(
          (w) => context.globalStore.get<DrawableWall>(w).entity.polygonEdgeUid,
        )
        .flat()
        .filter((puid) => puid !== leftEdgeUid)
        .some((puid) => {
          const pEdge = context.globalStore.get<DrawableEdge>(puid);

          return (
            pEdge.shape.distanceTo(
              context.globalStore.get<DrawableVertex>(leftUid).shape,
            )[0] < 200
          );
        });

    const rightIsEndpoint =
      coordDist2(rightSegmentPoint, rightVertex.toWorldCoord()) < EPS;
    const rightIsInternal =
      !rightIsEndpoint ||
      context.globalStore
        .getWallsByRoomEdge(rightEdgeUid)
        .map(
          (w) => context.globalStore.get<DrawableWall>(w).entity.polygonEdgeUid,
        )
        .flat()
        .filter((puid) => puid !== rightEdgeUid)
        .some((puid) => {
          const pEdge = context.globalStore.get<DrawableEdge>(puid);
          return (
            pEdge.shape.distanceTo(
              context.globalStore.get<DrawableVertex>(rightUid).shape,
            )[0] < 200
          );
        });

    for (let i = 0; i < segs.length; i++) {
      const origDrawCoords = segs[i];
      const drawCoords = flipped
        ? origDrawCoords.slice().reverse()
        : origDrawCoords;

      const angle = Math.atan2(
        drawCoords[1].y - drawCoords[0].y,
        drawCoords[1].x - drawCoords[0].x,
      );

      const length = Math.sqrt(
        Math.pow(drawCoords[1].x - drawCoords[0].x, 2) +
          Math.pow(drawCoords[1].y - drawCoords[0].y, 2),
      );

      const oldTransform = context.ctx.getTransform();
      ctx.translate(drawCoords[0].x, drawCoords[0].y);
      ctx.rotate(angle);

      ctx.beginPath();

      if (!coreWall.isAutoInternalWall()) {
        const leftEdge = context.globalStore.get<DrawableEdge>(leftEdgeUid);
        const rightEdge = context.globalStore.get<DrawableEdge>(rightEdgeUid);

        const leftVector = leftEdge.vectorFrom(leftUid);
        const rightVector = rightEdge.vectorFrom(rightUid);

        const leftAngle = Math.atan2(leftVector.y, leftVector.x);
        const rightAngle = Math.atan2(rightVector.y, rightVector.x);

        const myAngle = Math.atan2(ownVector.y, ownVector.x);
        let leftAngleDiff = angleDiffCWRad(myAngle, leftAngle);
        let rightAngleDiff = angleDiffCWRad(myAngle, rightAngle);
        const deg5 = Math.PI / 36;
        if (leftAngleDiff < deg5) leftAngleDiff = deg5;
        if (2 * Math.PI - leftAngleDiff < deg5)
          leftAngleDiff = 2 * Math.PI - deg5;
        if (Math.abs(rightAngleDiff - Math.PI) < deg5) {
          if (rightAngleDiff > Math.PI) rightAngleDiff = Math.PI + deg5;
          else rightAngleDiff = Math.PI - deg5;
        }
        let leftAdj = 0;
        let rightAdj = 0;

        let leftBaseAdj = 0;
        let rightBaseAdj = 0;

        if (i === 0) {
          if (leftIsInternal) {
            if (leftIsEndpoint) {
              leftAdj = wallSpec.internalWidthMM / 2;
              leftBaseAdj = wallSpec.internalWidthMM / 2;
            }
          } else {
            leftAdj = baseWidth * Math.tan((Math.PI - leftAngleDiff) / 2);
            if (leftAngleDiff < Math.PI) {
              leftBaseAdj = baseWidth / 2;
            } else {
              leftAdj += baseWidth / 2;
            }
          }
        }
        if (i === segs.length - 1) {
          if (rightIsInternal) {
            if (rightIsEndpoint) {
              rightAdj = wallSpec.internalWidthMM / 2;
              rightBaseAdj = wallSpec.internalWidthMM / 2;
            }
          } else {
            rightAdj = baseWidth * Math.tan(rightAngleDiff / 2);
            if (rightAngleDiff < Math.PI) {
              rightBaseAdj = baseWidth / 2;
            } else {
              rightAdj += baseWidth / 2;
            }
          }
        }
        ctx.moveTo(-leftAdj, -baseWidth);
        ctx.lineTo(length + rightAdj, -baseWidth);
        ctx.lineTo(length + rightBaseAdj, 0);
        ctx.lineTo(-leftBaseAdj, 0);
      } else {
        ctx.rect(0, -baseWidth / 2, length, baseWidth);
      }

      ctx.closePath();

      const screenScale = context.vp.currToScreenScale(ctx);
      const patternBackgroundColor = "#FFFFFF";

      if (screenScale < 0.03 || forExport) {
        // when zoomed out, draw solid instead of the blurry pattern
        this.loadTextureLowRes(context, ctx, baseColor, patternBackgroundColor);
        ctx.fill();
      } else {
        ctx.fillStyle = patternBackgroundColor;
        ctx.fill();
        this.loadTexture(context, ctx, baseColor, forExport);
        ctx.fill();
      }

      ctx.setTransform(oldTransform);
    }
  }

  drawInternal(context: DrawingContext, args: DrawingArgs) {
    if (this.shouldRenderInternal(context)) {
      super.drawInternal(context, args);
    }
    return;
  }

  drawEntity(context: DrawingContext, args: EntityDrawingArgs): void {
    if (!this.isManifested) return;

    // easier when pipes are same as world coord.
    const { graphics, ctx } = context;
    const wallEntity = this.entity;
    const coreWall = context.globalStore.get<CoreWall>(wallEntity.uid);
    const filledWallEntity = fillDefaultWallFields(context, wallEntity);

    const s = graphics.worldToSurfaceScale;
    const targetWidth = filledWallEntity.widthMM ?? 0;
    const baseWidth = Math.max(
      MIN_PIPE_PIXEL_WIDTH / s,
      targetWidth / graphics.unitWorldLength,
      (MIN_PIPE_PIXEL_WIDTH / s) * (5 + Math.log(s)),
    );
    this.lastDrawnWidthInternal = baseWidth;

    let baseColor: string = fillDefaultWallFields(context, this.entity).color!
      .hex;

    if (this.isHovering) {
      baseColor = lerpColor(baseColor, "#FFFFFF", 0.5);
    } else if (args.selected) {
      baseColor = lerpColor(baseColor, "#FFFFFF", 0.3);
    }

    const neighbourVertex = this.entity.polygonEdgeUid
      .map((uid) => context.globalStore.get<DrawableEdge>(uid))
      .map((edge) => edge.entity.endpointUid)
      .flat()
      .map((uid) => context.globalStore.get<DrawableVertex>(uid));

    const hasBackground = neighbourVertex.some(
      (vertex) => !!vertex.entity.parentUid,
    );

    if (
      hasBackground &&
      this.document.uiState.drawingMode !== DrawingMode.FloorPlan
    ) {
      baseColor = lighten(baseColor, 0, 0.5);
    }

    ctx.fillStyle = baseColor;
    this.drawWallWithPattern(
      context,
      args,
      ctx,
      coreWall,
      baseWidth,
      baseColor,
      args.forExport,
    );
  }

  isActive() {
    return this.isManifested;
  }

  prepareDelete(
    _context: CanvasContext,
    _calleeEntityUid?: string,
  ): DrawableObjectConcrete[] {
    return [];
  }

  onDragStart(
    event: MouseEvent,
    objectCoord: Coord,
    context: CanvasContext,
    isMultiDrag: boolean,
  ): any {
    this.entity.polygonEdgeUid.map((uid) => {
      const obj = this.globalStore.get<DrawableEdge>(uid);
      return obj.onDragStart(event, objectCoord, context, isMultiDrag);
    });
  }
  onDrag(
    event: MouseEvent,
    grabbedObjectCoord: Coord,
    eventObjectCoord: Coord,
    grabState: any,
    context: CanvasContext,
    isMultiDrag: boolean,
  ): void {
    this.entity.polygonEdgeUid.map((uid) => {
      const obj = this.globalStore.get<DrawableEdge>(uid);
      return obj.onDrag(
        event,
        grabbedObjectCoord,
        eventObjectCoord,
        grabState,
        context,
        isMultiDrag,
      );
    });
  }
  onDragFinish(
    event: MouseEvent,
    context: CanvasContext,
    isMultiDrag: boolean,
  ): void {
    this.entity.polygonEdgeUid.map((uid) => {
      const obj = this.globalStore.get<DrawableEdge>(uid);
      return obj.onDragFinish(event, context, isMultiDrag);
    });
    this.onInteractionComplete(event);
  }

  offerInteraction(_interaction: Interaction): DrawableEntityConcrete[] | null {
    return null;
    //TODO: implement
  }

  getCopiedObjects(): DrawableObjectConcrete[] {
    return this.entity.polygonEdgeUid.map(
      (uid) => this.globalStore.get<DrawableObjectConcrete>(uid)!,
    );
  }

  shouldRenderInternal(context: DrawingContext): boolean {
    if (
      context.doc.uiState.toolHandlerName === "insert-room" ||
      context.doc.uiState.toolHandlerName === "insert-roof" ||
      context.doc.uiState.toolHandlerName === "insert-wall" ||
      context.doc.uiState.toolHandlerName === "insert-fenestration" ||
      context.doc.uiState.toolHandlerName === "insert-vertex" ||
      this.document.uiState.draggingEntities.length > 0
    ) {
      return false;
    }
    return true;
  }
}
