import { FilterSettingViewKeyValues, UIState } from "./types";

export const isViewOnly = (state: UIState): boolean => {
  return (
    state.isCalculating ||
    state.hasErrorConnecting ||
    state.isSuperUser ||
    (state.isShareLink && !state.isSandbox) ||
    state.isGlobalSample
  );
};

export const getShortTitle = (title: string): string => {
  if (title.length > 50) {
    title =
      title.slice(0, 40) + "..." + title.slice(title.length - 7, title.length);
  }
  return title;
};

export const shouldSyncWithServer = (state: UIState): boolean => {
  return !isViewOnly(state) && !state.isSandbox;
};

export const isFilterSettingViewKey = (
  key: string,
): key is FilterSettingViewKeyValues => {
  switch (key) {
    case "all":
    case "custom":
    case "reference":
    case "pipe-sizing":
    case "pressure":
    case "heat-loss":
    case "grade-fall":
    case "heat-load":
      return true;
    default:
      return false;
  }
};
