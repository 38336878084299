import { assertUnreachable } from "../../../../lib/utils";
import { MultiwayValveType } from "../multiway-valves/multiway-valve-types";
import { EntityType } from "../types";

export type DirectedValveConcrete =
  | SmokeDamper
  | FireDamper
  | VolumeControlDamper
  | Attenuator
  | CheckValve
  | IsolationValve
  | WaterMeter
  | CSV
  | Strainer
  | RPZDSingle
  | RPZDDoubleShared
  | RPZDDoubleIsolated
  | PressureReliefValve
  | PressureReducingValveSingle
  | PressureReducingValveDouble
  | PressureReducingValveTriple
  | Balancing
  | LSV
  | PICV
  | TRV
  | GasRegulator
  | Filter
  | FloorWaste
  | InspectionOpening
  | RefluxValve
  | CustomValve
  | Fan;

export interface DirectedValve {
  type: ValveType;
  catalogId: string;
}

export interface Fan extends DirectedValve {
  type: ValveType.FAN;
  catalogId: "fan";
  pressureDropKPA: number | null;
  heightMM: number | null;
  widthMM: number | null;
}

export interface CheckValve extends DirectedValve {
  type: ValveType.CHECK_VALVE;
  catalogId: "checkValve";
}

export interface SmokeDamper extends DirectedValve {
  type: ValveType.SMOKE_DAMPER;
  zeta: number | null;
  catalogId: "smokeDamper";
}

export interface FireDamper extends DirectedValve {
  type: ValveType.FIRE_DAMPER;
  zeta: number | null;
  catalogId: "fireDamper";
}

export interface VolumeControlDamper extends DirectedValve {
  type: ValveType.VOLUME_CONTROL_DAMPER;
  zeta: number | null;
  catalogId: "volumeControlDamper";
}

export interface Attenuator extends DirectedValve {
  type: ValveType.ATTENUATOR;
  zeta: number | null;
  catalogId: "attenuator";
}

export interface IsolationValve extends DirectedValve {
  type: ValveType.ISOLATION_VALVE;
  catalogId: "gateValve" | "ballValve" | "butterflyValve";
  isClosed: boolean;
  makeIsolationCaseOnRingMains: boolean;
}

export interface WaterMeter extends DirectedValve {
  type: ValveType.WATER_METER;
  pressureDropKPA: number | null;
  catalogId: "waterMeter";
}

export interface CSV extends DirectedValve {
  type: ValveType.CSV;
  pressureDropKPA: number | null;
  catalogId: "csv";
}

export interface Strainer extends DirectedValve {
  type: ValveType.STRAINER;
  catalogId: "strainer";
}

export interface RPZDSingle extends DirectedValve {
  type: ValveType.RPZD_SINGLE;
  catalogId: "RPZD";
  sizeMM: number | null;
}

export interface RPZDDoubleShared extends DirectedValve {
  type: ValveType.RPZD_DOUBLE_SHARED;
  catalogId: "RPZD";
  sizeMM: number | null;
}

export interface RPZDDoubleIsolated extends DirectedValve {
  type: ValveType.RPZD_DOUBLE_ISOLATED;
  catalogId: "RPZD";
  sizeMM: number | null;
  isolateOneWhenCalculatingHeadLoss: boolean;
}

export interface PressureReliefValve extends DirectedValve {
  type: ValveType.RV;
  catalogId: "rv";
}

export interface PressureReducingValveSingle extends DirectedValve {
  type: ValveType.PRV_SINGLE;
  targetPressureKPA: number | null;
  catalogId: "prv";
  sizeMM: number | null;
}

export interface PressureReducingValveDouble extends DirectedValve {
  type: ValveType.PRV_DOUBLE;
  targetPressureKPA: number | null;
  catalogId: "prv";
  sizeMM: number | null;
  isolateOneWhenCalculatingHeadLoss: boolean;
}

export interface PressureReducingValveTriple extends DirectedValve {
  type: ValveType.PRV_TRIPLE;
  targetPressureKPA: number | null;
  catalogId: "prv";
  sizeMM: number | null;
  isolateOneWhenCalculatingHeadLoss: boolean;
}

export interface Balancing extends DirectedValve {
  type: ValveType.BALANCING;
  catalogId: "balancing";
}

export interface LSV extends DirectedValve {
  type: ValveType.LSV;
  catalogId: "lsv";
}

export interface PICV extends DirectedValve {
  type: ValveType.PICV;
  catalogId: "picv";
}

export interface TRV extends DirectedValve {
  type: ValveType.TRV;
  catalogId: "trv";
}

export interface GasRegulator extends DirectedValve {
  type: ValveType.GAS_REGULATOR;
  outletPressureKPA: number | null;
  downStreamPressureKPA: number | null;
}

export interface Filter extends DirectedValve {
  type: ValveType.FILTER;
  pressureDropKPA: number | null;
}

export interface FloorWaste extends DirectedValve {
  type: ValveType.FLOOR_WASTE;
  variant: "normal" | "bucketTrap" | null;
  bucketTrapSize: "regular" | "large" | null;
}

export interface InspectionOpening extends DirectedValve {
  type: ValveType.INSPECTION_OPENING;
}

export interface RefluxValve extends DirectedValve {
  type: ValveType.REFLUX_VALVE;
}

export interface CustomValve extends DirectedValve {
  type: ValveType.CUSTOM_VALVE;
  catalogId: "customValve";
  pressureDropType: "fixed" | "kvalue";
  pressureDropKPA: number | null;
  kValue: number | null;
}

export enum ValveType {
  CHECK_VALVE = "CHECK_VALVE",
  ISOLATION_VALVE = "ISOLATION_VALVE",
  RPZD_SINGLE = "RPZD",
  RPZD_DOUBLE_SHARED = "RPZD_DOUBLE_SHARED",
  RPZD_DOUBLE_ISOLATED = "RPZD_DOUBLE_ISOLATED",
  WATER_METER = "WATER_METER",
  CSV = "CSV",
  STRAINER = "STRAINER",

  RV = "RV",
  PRV_SINGLE = "PRV_SINGLE",
  PRV_DOUBLE = "PRV_DOUBLE",
  PRV_TRIPLE = "PRV_TRIPLE",

  BALANCING = "BALANCING",
  LSV = "LSV",
  PICV = "PICV",
  TRV = "TRV",
  GAS_REGULATOR = "GAS_REGULATOR",
  FILTER = "FILTER",

  FLOOR_WASTE = "FLOOR_WASTE",
  INSPECTION_OPENING = "INSPECTION_OPENING",
  REFLUX_VALVE = "REFLUX_VALVE",
  SMOKE_DAMPER = "SMOKE_DAMPER",
  FIRE_DAMPER = "FIRE_DAMPER",
  VOLUME_CONTROL_DAMPER = "VOLUME_CONTROL_DAMPER",
  ATTENUATOR = "ATTENUATOR",

  CUSTOM_VALVE = "CUSTOM_VALVE",
  FAN = "FAN",
}

export function isReturnBalancingValve(
  valve: DirectedValveConcrete,
): valve is Balancing | LSV | PICV {
  switch (valve.type) {
    case ValveType.CHECK_VALVE:
    case ValveType.ISOLATION_VALVE:
    case ValveType.WATER_METER:
    case ValveType.CSV:
    case ValveType.STRAINER:
    case ValveType.RV:
    case ValveType.RPZD_SINGLE:
    case ValveType.RPZD_DOUBLE_SHARED:
    case ValveType.RPZD_DOUBLE_ISOLATED:
    case ValveType.PRV_SINGLE:
    case ValveType.PRV_DOUBLE:
    case ValveType.PRV_TRIPLE:
    case ValveType.FILTER:
    case ValveType.FLOOR_WASTE:
    case ValveType.INSPECTION_OPENING:
    case ValveType.REFLUX_VALVE:
    case ValveType.GAS_REGULATOR:
    case ValveType.TRV:
    case ValveType.CUSTOM_VALVE:
    case ValveType.SMOKE_DAMPER:
    case ValveType.FIRE_DAMPER:
    case ValveType.VOLUME_CONTROL_DAMPER:
    case ValveType.ATTENUATOR:
    case ValveType.FAN:
      return false;
    case ValveType.BALANCING:
    case ValveType.LSV:
    case ValveType.PICV:
      return true;
  }
  assertUnreachable(valve);
}

export function hasFixedPressureDrop(
  valve: DirectedValveConcrete,
): valve is WaterMeter | Filter | CSV | Fan {
  switch (valve.type) {
    case ValveType.CHECK_VALVE:
    case ValveType.ISOLATION_VALVE:
    case ValveType.STRAINER:
    case ValveType.RPZD_SINGLE:
    case ValveType.RPZD_DOUBLE_SHARED:
    case ValveType.RPZD_DOUBLE_ISOLATED:
    case ValveType.PRV_SINGLE:
    case ValveType.PRV_DOUBLE:
    case ValveType.PRV_TRIPLE:
    case ValveType.FLOOR_WASTE:
    case ValveType.INSPECTION_OPENING:
    case ValveType.REFLUX_VALVE:
    case ValveType.GAS_REGULATOR:
    case ValveType.TRV:
    case ValveType.BALANCING:
    case ValveType.LSV:
    case ValveType.PICV:
    case ValveType.RV:
    case ValveType.SMOKE_DAMPER:
    case ValveType.FIRE_DAMPER:
    case ValveType.VOLUME_CONTROL_DAMPER:
    case ValveType.ATTENUATOR:
      return false;
    case ValveType.WATER_METER:
    case ValveType.FILTER:
    case ValveType.CSV:
    case ValveType.FAN:
      return true;
    case ValveType.CUSTOM_VALVE:
      switch (valve.pressureDropType) {
        case "fixed":
          return true;
        case "kvalue":
          return false;
      }
  }
  assertUnreachable(valve);
}

export function isVentsValve(
  valve: DirectedValveConcrete,
): valve is SmokeDamper | FireDamper | VolumeControlDamper | Attenuator | Fan {
  switch (valve.type) {
    case ValveType.SMOKE_DAMPER:
    case ValveType.FIRE_DAMPER:
    case ValveType.VOLUME_CONTROL_DAMPER:
    case ValveType.ATTENUATOR:
    case ValveType.FAN:
      return true;
    case ValveType.CHECK_VALVE:
    case ValveType.ISOLATION_VALVE:
    case ValveType.STRAINER:
    case ValveType.RPZD_SINGLE:
    case ValveType.RPZD_DOUBLE_SHARED:
    case ValveType.RPZD_DOUBLE_ISOLATED:
    case ValveType.PRV_SINGLE:
    case ValveType.PRV_DOUBLE:
    case ValveType.PRV_TRIPLE:
    case ValveType.FLOOR_WASTE:
    case ValveType.INSPECTION_OPENING:
    case ValveType.REFLUX_VALVE:
    case ValveType.GAS_REGULATOR:
    case ValveType.TRV:
    case ValveType.BALANCING:
    case ValveType.LSV:
    case ValveType.PICV:
    case ValveType.RV:
    case ValveType.WATER_METER:
    case ValveType.FILTER:
    case ValveType.CSV:
    case ValveType.CUSTOM_VALVE:
      return false;
  }
  assertUnreachable(valve);
}

export interface InsertDirectedValveSpec {
  entityType: EntityType.DIRECTED_VALVE;
  valveType: ValveType;
  catalogId: string;
  valveSizeMM?: number;
  systemUid?: string;
}

export interface InsertMultiwayValveSpec {
  entityType: EntityType.MULTIWAY_VALVE;
  valveType: MultiwayValveType;
  valveSizeMM?: number;
  systemUid?: string;
}

export type InsertValveSpec = InsertDirectedValveSpec | InsertMultiwayValveSpec;
