import axios from "axios";
import { APIResult } from "../../../common/src/api/document/types";
import { ReportS3Links } from "./calculationReportManager";

export async function updateCalculationReport(
  id: number,
  nextOrderIndex: number,
  results: any,
): Promise<APIResult<void>> {
  try {
    return (
      await axios.put(
        "/api/reports/document/" + id + "/calculation/" + nextOrderIndex,
        results,
      )
    ).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function refreshDocumentReport(
  id: number,
): Promise<APIResult<void>> {
  try {
    return (await axios.post("/api/reports/document/" + id)).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function downloadDocumentReport(
  id: number,
): Promise<APIResult<string>> {
  try {
    return (await axios.get("/api/reports/document/" + id)).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function triggerSystemManufacturerReport(): Promise<
  APIResult<void>
> {
  try {
    return await axios.post("/api/reports/");
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function getReportLinks(
  docId: number,
  lastOperationId: number, // acts as a fencing token
): Promise<APIResult<ReportS3Links>> {
  try {
    return (
      await axios.get("/api/reports/document/" + docId + "/links", {
        params: { lastOperationId },
      })
    ).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}
