// Stores auxillary data and objects for the entire document across all levels.

import { CalculatableEntityConcrete } from "../../api/document/entities/concrete-entity";
import { EntityType } from "../../api/document/entities/types";
// tslint:disable-next-line:max-line-length
// tslint:disable-next-line:max-line-length
import {
  makeEmptyCalculation,
  makeEmptyLiveCalculation,
} from "../../api/calculations/utils";
import {
  CoreEdgeObjectConcrete,
  CoreObjectConcrete,
  isCoreEdgeObject,
} from "../../api/coreObjects";

import {
  CalculationConcrete,
  CalculationForEntity,
  LiveCalculationConcrete,
  LiveCalculationForEntity,
} from "../../api/document/calculations-objects/calculation-concrete";

import RBush from "rbush";
import {
  WarningInstance,
  WIID,
} from "../../api/document/calculations-objects/warnings";
import { DrawingState } from "../../api/document/drawing";
import { SpatialIndex } from "../../api/types";
import { ObjectStore } from "./object-store";
import { updateSpatialIndex } from "./utils";

export class GlobalStore extends ObjectStore {
  entitiesInLevel = new Map<string | null, Set<string>>();
  levelOfEntity = new Map<string, string | null>();
  spatialIndex = new Map<string, RBush<SpatialIndex>>();
  spatialIndexObjects = new Map<string, SpatialIndex>();
  spatialIndexUpdateQueue = new Set<string>();

  calculationStore = new Map<string, CalculationConcrete>();
  liveCalculationStore = new Map<string, LiveCalculationConcrete>();
  liveCalculationWarnings = new Map<WIID, WarningInstance>();
  calculationWarnings = new Map<WIID, WarningInstance>();

  // Live calculation caches.
  objectsInCycle = new Map<number, Set<string>>();

  drawingDependencies = new Map<string, Set<string>>();
  drawingDependents = new Map<string, Set<string>>();

  nextWarningInstanceId = 0;

  clear() {
    super.clear();
    this.entitiesInLevel.clear();
    this.levelOfEntity.clear();
    this.calculationStore.clear();
    this.liveCalculationStore.clear();
    this.liveCalculationWarnings.clear();
    this.spatialIndex.clear();
    this.spatialIndexObjects.clear();
    this.spatialIndexUpdateQueue.clear();
  }

  set(key: string, value: CoreObjectConcrete, levelUid?: string | null): this {
    if (levelUid === undefined) {
      throw new Error("Need a level to set in global store.");
    }
    if (!this.entitiesInLevel.has(levelUid)) {
      this.entitiesInLevel.set(levelUid, new Set());
    }
    this.entitiesInLevel.get(levelUid)!.add(value.entity.uid);
    this.levelOfEntity.set(value.entity.uid, levelUid);

    const deps = value.getVisualDeps();
    this.drawingDependencies.set(value.entity.uid, new Set(deps));
    for (const dep of deps) {
      if (!this.drawingDependents.has(dep)) {
        this.drawingDependents.set(dep, new Set());
      }
      this.drawingDependents.get(dep)!.add(value.entity.uid);
    }
    this.spatialIndexUpdateQueue.add(key);

    return super.set(key, value);
  }

  onDocumentLoaded() {
    super.onDocumentLoaded();

    this.spatialIndexUpdateQueue = new Set([...super.keys()]);
    updateSpatialIndex(this);
  }

  delete(key: string): boolean {
    if (this.has(key)) {
      const lvl = this.levelOfEntity.get(key)!;
      this.levelOfEntity.delete(key);
      this.entitiesInLevel.get(lvl)!.delete(key);

      const deps = this.drawingDependencies.get(key)!;
      for (const dep of deps) {
        this.drawingDependents.get(dep)!.delete(key);
      }
      this.drawingDependencies.delete(key);

      // remove parentUid from all children
      // todo create a cache for this
      const entitiesOnLvl = this.entitiesInLevel.get(lvl)!;
      for (const uid of entitiesOnLvl) {
        const o = this.get(uid);
        if (o.entity.parentUid === key) {
          o.entity.parentUid = null;
        }
      }

      this.spatialIndexUpdateQueue.add(key);
      return super.delete(key);
    }

    return false;
  }

  onEntityChange(uid: string): void {
    super.onEntityChange(uid);

    this.updateVisualDependencies(uid);
  }

  updateVisualDependencies(uid: string) {
    const oldDependencies = this.drawingDependencies.get(uid);
    const newDependencies = this.get(uid)!.getVisualDeps();

    // Remove old dependencies.
    if (oldDependencies) {
      for (const dep of oldDependencies) {
        this.drawingDependents.get(dep)!.delete(uid);
      }
    }
    // Add new dependencies.
    this.drawingDependencies.set(uid, new Set(newDependencies));
    for (const dep of newDependencies) {
      if (!this.drawingDependents.has(dep)) {
        this.drawingDependents.set(dep, new Set());
      }
      this.drawingDependents.get(dep)!.add(uid);
    }

    // Update visual dependencies of all dependents.
    const visited = new Set<string>();
    const queue = [uid];
    while (queue.length) {
      const uid = queue.shift()!;
      if (visited.has(uid)) {
        continue;
      }
      visited.add(uid);
      if (this.drawingDependents.has(uid)) {
        const dependents = this.drawingDependents.get(uid);
        if (dependents) {
          for (const dep of dependents) {
            queue.push(dep);
          }
        }
      }
    }

    // visited is the list of entities we must update positioning of.
    // practically, for drawing, we must update the dependencies of each
    // visited too - ie parent of a system node for the system node caps,
    // and connections of a pipe for the fitting connector graphics.
    const visuallyUpdated = new Set<string>();
    for (const uid of visited) {
      this.onGeometryUpdate(uid);
      if (!visuallyUpdated.has(uid)) {
        this.onVisualUpdate(uid);
        visuallyUpdated.add(uid);
      }
      for (const dep of this.drawingDependencies.get(uid) || []) {
        if (!visuallyUpdated.has(dep)) {
          this.onVisualUpdate(dep);
          visuallyUpdated.add(dep);
        }
      }
    }
  }

  onGeometryUpdate(uid: string) {
    this.spatialIndexUpdateQueue.add(uid);
  }

  onVisualUpdate(uid: string) {
    // console.log("Visual update", uid);
    // Update intermediate layer for graphics here.
    this.get(uid)?.onRedrawNeeded();
  }

  forEach<T = CoreObjectConcrete>(
    callbackfn: (value: T, key: string, map: Map<string, T>) => void,
    thisArg?: any,
  ): void;
  forEach(
    callbackfn: (
      value: CoreObjectConcrete,
      key: string,
      map: Map<string, CoreObjectConcrete>,
    ) => void,
    thisArg?: any,
  ): void {
    super.forEach(callbackfn, thisArg);
  }

  values<T = CoreObjectConcrete>(): IterableIterator<T>;
  values(): IterableIterator<CoreObjectConcrete> {
    return super.values();
  }

  getOrCreateCalculation<T extends CalculatableEntityConcrete>(
    entity: T,
  ): CalculationForEntity<T> {
    if (!this.calculationStore.has(entity.uid)) {
      this.calculationStore.set(entity.uid, makeEmptyCalculation(entity));
    }

    return this.calculationStore.get(entity.uid) as CalculationForEntity<T>;
  }

  getCalculation<T extends CalculatableEntityConcrete>(
    entity: T,
  ): CalculationForEntity<T> | undefined {
    return this.calculationStore.get(entity.uid) as CalculationForEntity<T>;
  }

  getLiveCalculation<T extends CalculatableEntityConcrete>(
    entity: T,
  ): LiveCalculationForEntity<T> | undefined {
    return this.liveCalculationStore.get(
      entity.uid,
    ) as LiveCalculationForEntity<T>;
  }

  getOrCreateLiveCalculation<T extends CalculatableEntityConcrete>(
    entity: T,
  ): LiveCalculationForEntity<T> {
    if (!this.liveCalculationStore.has(entity.uid)) {
      this.liveCalculationStore.set(
        entity.uid,
        makeEmptyLiveCalculation(entity),
      );
    }

    return this.liveCalculationStore.get(
      entity.uid,
    )! as LiveCalculationForEntity<T>;
  }

  getCalculations() {
    return this.calculationStore;
  }

  setCalculation(uid: string, calculation: CalculationConcrete) {
    this.calculationStore.set(uid, calculation);
  }

  setLiveCalculation(uid: string, calculation: LiveCalculationConcrete) {
    this.liveCalculationStore.set(uid, calculation);
  }

  rebuildLiveCalculationCache(currLevel: string | null) {
    this.objectsInCycle.clear();

    for (const euid of this.entitiesInLevel.get(currLevel)!) {
      const object = this.get(euid);
      if (object.type === EntityType.CONDUIT) {
        const lCalc = this.getOrCreateLiveCalculation(object.entity);
        if (lCalc.cycle) {
          if (!this.objectsInCycle.has(lCalc.cycle)) {
            this.objectsInCycle.set(lCalc.cycle, new Set());
          }
          this.objectsInCycle.get(lCalc.cycle)!.add(object.entity.uid);
        }
      }
    }
  }

  clearCalculations() {
    this.calculationStore.clear();
    this.liveCalculationStore.clear();
  }

  onLevelDelete(levelUid: string) {
    if (this.entitiesInLevel.get(levelUid)) {
      this.entitiesInLevel.get(levelUid)!.forEach((euid) => {
        this.delete(euid);
      });
    }
    this.entitiesInLevel.delete(levelUid);
  }

  sanityCheck(drawing: DrawingState) {
    // test that there is an exact bijection from document to things.

    // 1. Everything in doc must be in us.
    Object.values(drawing.levels).forEach((l) => {
      Object.values(l.entities).forEach((e) => {
        if (!this.has(e.uid)) {
          throw new Error(
            "entity in document " + JSON.stringify(e) + " not found here",
          );
        }

        if (this.get(e.uid)!.entity !== e) {
          throw new Error(
            "entity in document " +
              JSON.stringify(e) +
              " not in sync with us " +
              JSON.stringify(this.get(e.uid)!.entity),
          );
        }
      });
    });

    Object.values(drawing.shared).forEach((e) => {
      if (!this.has(e.uid)) {
        throw new Error(
          "entity in document " + JSON.stringify(e) + " not found here",
        );
      }

      if (this.get(e.uid)!.entity !== e) {
        throw new Error(
          "entity in document " +
            JSON.stringify(e) +
            " not in sync with us " +
            JSON.stringify(this.get(e.uid)!.entity),
        );
      }
    });

    // 2. Everything in us must be in doc.
    this.forEach((o, k) => {
      if (o.entity === undefined) {
        throw new Error(
          "object " + k + " is deleted in document but still here",
        );
      }
      const lvlUid = this.levelOfEntity.get(o.entity.uid)!;

      if (lvlUid === null) {
        if (!(o.entity.uid in drawing.shared)) {
          throw new Error(
            "Entity " + JSON.stringify(o.entity) + " not in document",
          );
        }
      } else {
        if (!(lvlUid in drawing.levels)) {
          throw new Error(
            "Level we have " + lvlUid + " doesn't exist on document",
          );
        }

        if (!(o.entity.uid in drawing.levels[lvlUid].entities)) {
          throw new Error(
            "Entity " + JSON.stringify(o.entity) + " not in document",
          );
        }
      }
    });

    this.entitiesInLevel.forEach((es, lvlUid) => {
      if (lvlUid === null) {
        return;
      }

      if (!(lvlUid in drawing.levels)) {
        throw new Error(
          "Level we have " + lvlUid + " doesn't exist on document",
        );
      }

      es.forEach((e) => {
        if (!(e in drawing.levels[lvlUid].entities)) {
          throw new Error("Entity " + e + " not in document");
        }
      });
    });

    // 3. Connections must be accurate
    this.connections.forEach((cons, euid) => {
      cons.forEach((c) => {
        const p = this.get(c) as CoreEdgeObjectConcrete;
        if (!p.entity.endpointUid.includes(euid)) {
          const co = this.get(euid);
          throw new Error(
            "connection inconsistency in connectable " +
              JSON.stringify(co ? co.entity : undefined) +
              " to pipe " +
              JSON.stringify(p.entity),
          );
        }
      });
    });

    this.forEach((o) => {
      if (isCoreEdgeObject(o)) {
        o.entity.endpointUid.forEach((euid) => {
          if (!this.connections.get(euid)) {
            throw new Error(
              "connection " +
                euid +
                " on pipe " +
                JSON.stringify(o.entity) +
                " is not found",
            );
          }
          if (!this.connections.get(euid)!.includes(o.entity.uid)) {
            throw new Error(
              "connection inconsistency in pipe " +
                JSON.stringify(o.entity) +
                " to connectable " +
                euid +
                " with connections " +
                JSON.stringify(this.connections.get(euid)),
            );
          }
        });
      }
    });
  }
}
