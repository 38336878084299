import Flatten from "@flatten-js/core";
import RBush from "rbush";
import { CoreObjectConcrete } from "../../api/coreObjects";
import CoreRoom from "../../api/coreObjects/coreRoom";
import CoreBaseBackedObject from "../../api/coreObjects/lib/coreBaseBackedObject";
import {
  DrawingState,
  findEntitiesOfTypeOnLevel,
} from "../../api/document/drawing";
import { DrawableEntityConcrete } from "../../api/document/entities/concrete-entity";
import {
  RoomRoomEntity,
  RoomType,
  isRoomRoomEntity,
} from "../../api/document/entities/rooms/room-entity";
import { CenteredEntity } from "../../api/document/entities/simple-entities";
import { EntityType } from "../../api/document/entities/types";
import { SpatialIndex } from "../../api/types";
import { Coord } from "../coord";
import { pointInsidePolygon } from "../mathUtils/mathutils";
import { assertUnreachable } from "../utils";
import { GlobalStore } from "./global-store";
import Box = Flatten.Box;

export function deleteSpatialIndexForLevel(
  globalStore: GlobalStore,
  levelUid: string,
) {
  globalStore.spatialIndex.delete(levelUid);
}

export function queueRiserSpatialIndexUpdates(
  globalStore: GlobalStore,
  drawing: DrawingState,
) {
  const risers = Object.keys(drawing.shared);
  for (const riser of risers) {
    globalStore.spatialIndexUpdateQueue.add(riser);
  }
}

export function findWithinBounds<T extends CoreObjectConcrete>(
  globalStore: GlobalStore,
  levelUid: string,
  box: Box,
  f?: (o: CoreObjectConcrete) => o is T,
): T[] {
  const tree = globalStore.spatialIndex.get(levelUid);
  if (!tree) {
    return [] as T[];
  }

  const results = tree
    .search({
      minX: box.xmin,
      minY: box.ymin,
      maxX: box.xmax,
      maxY: box.ymax,
    })
    .map((index) => globalStore.get<T>(index.uid));

  if (f) {
    return results.filter((x) => x && f(x));
  } else {
    return results;
  }
}

export function findRoomUnderEntity(
  drawing: DrawingState,
  globalStore: GlobalStore,
  entity: CenteredEntity,
): RoomRoomEntity | null {
  const level = globalStore.levelOfEntity.get(entity.uid) ?? null;
  return findRoomUnderPoint(drawing, globalStore, level, entity.center);
}

export function findRoomUnderPoint(
  drawing: DrawingState,
  globalStore: GlobalStore,
  level: string | null,
  coord: Coord,
): RoomRoomEntity | null {
  if (!level) {
    return null;
  }

  return (
    findEntitiesOfTypeOnLevel(drawing, level, isRoomRoomEntity).find((x) => {
      const coreRoom = globalStore.get<CoreRoom>(x.uid);
      const polygonCw = coreRoom
        .collectVerticesInOrder()
        .map((v) => v.toWorldCoord());
      return pointInsidePolygon(coord, polygonCw);
    }) ?? null
  );
}

export function getRoomsByLevel(globalStore: GlobalStore) {
  const res = new Map<string, CoreRoom[]>();

  for (const [uid, o] of globalStore) {
    if (
      o.type === EntityType.ROOM &&
      o.entity.room.roomType === RoomType.ROOM
    ) {
      const room = o as CoreRoom;
      const lvl = globalStore.levelOfEntity.get(uid);

      if (!lvl) {
        continue;
      }

      if (!res.has(lvl)) {
        res.set(lvl, []);
      }

      res.get(lvl)!.push(room);
    }
  }

  return res;
}

export function getEntityRoom(entityWorldCoord: Coord, roomsOnLvl: CoreRoom[]) {
  const { x, y } = entityWorldCoord;
  for (const room of roomsOnLvl) {
    if (room.shape.contains(Flatten.point(x, y))) {
      return room;
    }
  }
}

export function updateSpatialIndex(globalStore: GlobalStore) {
  const queue = Array.from(globalStore.spatialIndexUpdateQueue);
  const treeBulkInsert = new Map<string, SpatialIndex[]>();

  for (const q of queue) {
    const o = globalStore.get(q);
    if (o) {
      const levels = o.context.drawing.levels;
      for (const levelUid of Object.keys(levels)) {
        treeBulkInsert.set(levelUid, []);
      }
      break;
    }
  }

  while (queue.length > 0) {
    const oUid = queue.pop()!;
    const levelUid = globalStore.levelOfEntity.get(oUid)!;
    const o = globalStore.get(oUid);

    // object has been deleted
    if (!o) {
      const oldIndex = globalStore.spatialIndexObjects.get(oUid);
      if (oldIndex) {
        // everything but riser
        if (oldIndex.levelUid) {
          const tree = globalStore.spatialIndex.get(oldIndex.levelUid);
          tree?.remove(oldIndex);
        } else {
          for (const [_, tree] of globalStore.spatialIndex.entries()) {
            tree.remove(oldIndex, (a, b) => a.uid === b.uid);
          }
        }
        globalStore.spatialIndexObjects.delete(oUid);
      }
      continue;
    }

    switch (o.type) {
      case EntityType.RISER:
        // Remove all the old entries of the riser if we are updating it
        if (globalStore.spatialIndexObjects.has(oUid)) {
          const oldTreeEntry = globalStore.spatialIndexObjects.get(oUid)!;
          // We don't know what levels it is on, so we delete from all levels
          for (const [_, tree] of globalStore.spatialIndex.entries()) {
            tree.remove(oldTreeEntry, (a, b) => a.uid === b.uid);
          }
          globalStore.spatialIndexObjects.delete(oUid);
        }

        const riserBox = o.shape.box;
        if (isNaN(riserBox.xmin)) {
          console.warn("Riser bounding box is NaN", o.type, o.uid);
          continue;
        }
        const riserEntry = {
          minX: riserBox.xmin,
          minY: riserBox.ymin,
          maxX: riserBox.xmax,
          maxY: riserBox.ymax,
          uid: oUid,
          levelUid: null,
        };

        // Add the new riser entry to required levels
        const riserLevels = o.getRiserLevels();
        for (const level of riserLevels) {
          const tree =
            globalStore.spatialIndex.get(level.uid) ||
            new RBush<SpatialIndex>();
          treeBulkInsert.get(level.uid)?.push(riserEntry);
          globalStore.spatialIndex.set(level.uid, tree);
        }

        globalStore.spatialIndexObjects.set(oUid, riserEntry);

        break;
      case EntityType.SYSTEM_NODE:
      case EntityType.BACKGROUND_IMAGE:
      case EntityType.BIG_VALVE:
      case EntityType.COMPOUND:
      case EntityType.DIRECTED_VALVE:
      case EntityType.FITTING:
      case EntityType.FIXTURE:
      case EntityType.FLOW_SOURCE:
      case EntityType.GAS_APPLIANCE:
      case EntityType.LOAD_NODE:
      case EntityType.MULTIWAY_VALVE:
      case EntityType.CONDUIT:
      case EntityType.PLANT:
      case EntityType.VERTEX:
      case EntityType.EDGE:
      case EntityType.ROOM:
      case EntityType.WALL:
      case EntityType.FENESTRATION:
      case EntityType.LINE:
      case EntityType.ANNOTATION:
      case EntityType.ARCHITECTURE_ELEMENT:
      case EntityType.DAMPER:
      case EntityType.AREA_SEGMENT:
        const box = (() => {
          if (o.type === EntityType.SYSTEM_NODE) {
            const shape = o.getStubShape() ?? o.shape;
            return shape?.box;
          }
          return o.shape?.box;
        })();

        if (!box) {
          continue;
        }
        if (isNaN(box.xmin)) {
          console.warn("Entity bounding box is NaN", o.type, o.uid);
          continue;
        }
        const treeEntry = {
          minX: box.xmin,
          minY: box.ymin,
          maxX: box.xmax,
          maxY: box.ymax,
          uid: oUid,
          levelUid,
        };

        let tree: RBush<SpatialIndex>;

        // Create the tree for the level if it doesn't exist already
        if (!globalStore.spatialIndex.has(levelUid)) {
          tree = new RBush<SpatialIndex>();
          globalStore.spatialIndex.set(levelUid, tree);
        }

        tree = globalStore.spatialIndex.get(levelUid)!;

        // Delete the old values if they exist
        const oldIndex = globalStore.spatialIndexObjects.get(oUid);
        if (oldIndex) {
          tree?.remove(oldIndex);
          globalStore.spatialIndexObjects.delete(oUid);
        }

        // Insert the values into the tree
        globalStore.spatialIndexObjects.set(oUid, treeEntry);
        treeBulkInsert.get(levelUid)?.push(treeEntry);
        break;

      default:
        assertUnreachable(o);
    }
  }

  // bulk insert the items into trees
  for (const [levelUid, tree] of globalStore.spatialIndex.entries()) {
    tree.load(treeBulkInsert.get(levelUid) || []);
  }

  globalStore.spatialIndexUpdateQueue.clear();
}

/**
 * Designed to get entities of a specific type
 * eg. getFilteredEntities(context.globalStore, isRoomEntity)
 */
export function getFilteredEntities<A extends DrawableEntityConcrete>(
  globalStore: GlobalStore,
  filter: (entity: DrawableEntityConcrete) => boolean,
): CoreBaseBackedObject<A>[] {
  const entities: CoreBaseBackedObject<A>[] = [];
  for (const entity of globalStore.values()) {
    if (filter(entity.entity)) {
      entities.push(entity as unknown as CoreBaseBackedObject<A>);
    }
  }
  return entities;
}

export function getEntitiesByLevel<T extends CoreObjectConcrete>(
  globalStore: GlobalStore,
  entities: T[],
): Map<string, T[]> {
  const entitiesByLevel = new Map<string, T[]>();
  for (const entity of entities) {
    const level = globalStore.levelOfEntity.get(entity.uid)!;
    if (!entitiesByLevel.has(level)) {
      entitiesByLevel.set(level, []);
    }
    entitiesByLevel.get(level)!.push(entity);
  }
  return entitiesByLevel;
}
