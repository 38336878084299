import {
  GenericActuator,
  GenericBallValve,
  GenericBlendingValve,
  GenericEdgeExpansionFoam,
  GenericManifold,
  GenericPumpPack,
  GenericUFHPump,
} from "../manufacturers/generic/generic-ufh";
import { UnderfloorHeatingSpec } from "./ufh-types";

export const UFH_CATALOG_AU: UnderfloorHeatingSpec = {
  manifold: {
    type: "ufh-manifold",
    name: "Underfloor Heating Manifold",
    uid: "ufh-manifold",
    manufacturer: [
      {
        name: "Generic",
        abbreviation: "generic",
        priceTableName: "UFH Manifold",
        uid: "generic",
      },
    ],
    datasheet: {
      generic: GenericManifold,
    },
  },
  actuator: {
    type: "ufh-actuator",
    name: "Underfloor Heating Actuator",
    uid: "ufh-actuator",
    manufacturer: [
      {
        name: "Generic",
        abbreviation: "generic",
        priceTableName: "UFH Actuator",
        uid: "generic",
      },
    ],
    datasheet: {
      generic: GenericActuator,
    },
  },
  blendingValve: {
    type: "ufh-blending-valve",
    name: "Underfloor Heating Blending Valve",
    uid: "ufh-blending-valve",
    manufacturer: [
      {
        name: "Generic",
        abbreviation: "generic",
        priceTableName: "UFH Blending Valve",
        uid: "generic",
      },
    ],
    datasheet: {
      generic: GenericBlendingValve,
    },
  },
  pump: {
    type: "ufh-pump",
    name: "Underfloor Heating Pump",
    uid: "ufh-pump",
    manufacturer: [
      {
        name: "Generic",
        abbreviation: "generic",
        priceTableName: "UFH Pump",
        uid: "generic",
      },
    ],
    datasheet: {
      generic: GenericUFHPump,
    },
  },
  pumpPack: {
    type: "ufh-pump-pack",
    name: "Underfloor Heating Pump Pack",
    uid: "ufh-pump-pack",
    manufacturer: [
      {
        name: "Generic",
        abbreviation: "generic",
        priceTableName: "UFH Pump Pack",
        uid: "generic",
      },
    ],
    datasheet: {
      generic: GenericPumpPack,
    },
  },
  ballValve: {
    type: "ufh-ball-valve",
    name: "Underfloor Heating Ball Valve",
    uid: "ufh-ball-valve",
    manufacturer: [
      {
        name: "Generic",
        abbreviation: "generic",
        priceTableName: "UFH Ball Valve",
        uid: "generic",
      },
    ],
    datasheet: {
      generic: GenericBallValve,
    },
  },
  edgeExpansionFoam: {
    type: "ufh-edge-expansion-foam",
    name: "Underfloor Heating Foam",
    uid: "ufh-edge-expansion-foam",
    manufacturer: [
      {
        name: "Generic",
        abbreviation: "generic",
        priceTableName: "UFH Edge Expansion Foam",
        uid: "generic",
      },
    ],
    datasheet: {
      generic: GenericEdgeExpansionFoam,
    },
  },
};

export const UFH_CATALOG_US: UnderfloorHeatingSpec = {
  manifold: {
    type: "ufh-manifold",
    name: "Underfloor Heating Manifold",
    uid: "ufh-manifold",
    manufacturer: [
      {
        name: "Generic",
        abbreviation: "generic",
        priceTableName: "UFH Manifold",
        uid: "generic",
      },
    ],
    datasheet: {
      generic: GenericManifold,
    },
  },
  actuator: {
    type: "ufh-actuator",
    name: "Underfloor Heating Actuator",
    uid: "ufh-actuator",
    manufacturer: [
      {
        name: "Generic",
        abbreviation: "generic",
        priceTableName: "UFH Actuator",
        uid: "generic",
      },
    ],
    datasheet: {
      generic: GenericActuator,
    },
  },
  blendingValve: {
    type: "ufh-blending-valve",
    name: "Underfloor Heating Blending Valve",
    uid: "ufh-blending-valve",
    manufacturer: [
      {
        name: "Generic",
        abbreviation: "generic",
        priceTableName: "UFH Blending Valve",
        uid: "generic",
      },
    ],
    datasheet: {
      generic: GenericBlendingValve,
    },
  },
  pump: {
    type: "ufh-pump",
    name: "Underfloor Heating Pump",
    uid: "ufh-pump",
    manufacturer: [
      {
        name: "Generic",
        abbreviation: "generic",
        priceTableName: "UFH Pump",
        uid: "generic",
      },
    ],
    datasheet: {
      generic: GenericUFHPump,
    },
  },
  pumpPack: {
    type: "ufh-pump-pack",
    name: "Underfloor Heating Pump Pack",
    uid: "ufh-pump-pack",
    manufacturer: [
      {
        name: "Generic",
        abbreviation: "generic",
        priceTableName: "UFH Pump Pack",
        uid: "generic",
      },
    ],
    datasheet: {
      generic: GenericPumpPack,
    },
  },
  ballValve: {
    type: "ufh-ball-valve",
    name: "Underfloor Heating Ball Valve",
    uid: "ufh-ball-valve",
    manufacturer: [
      {
        name: "Generic",
        abbreviation: "generic",
        priceTableName: "UFH Ball Valve",
        uid: "generic",
      },
    ],
    datasheet: {
      generic: GenericBallValve,
    },
  },
  edgeExpansionFoam: {
    type: "ufh-edge-expansion-foam",
    name: "Underfloor Heating Foam",
    uid: "ufh-edge-expansion-foam",
    manufacturer: [
      {
        name: "Generic",
        abbreviation: "generic",
        priceTableName: "UFH Edge Expansion Foam",
        uid: "generic",
      },
    ],
    datasheet: {
      generic: GenericEdgeExpansionFoam,
    },
  },
};

export const UFH_CATALOG_UK: UnderfloorHeatingSpec = {
  manifold: {
    type: "ufh-manifold",
    name: "Underfloor Heating Manifold",
    uid: "ufh-manifold",
    manufacturer: [
      {
        name: "Generic",
        abbreviation: "generic",
        priceTableName: "UFH Manifold",
        uid: "generic",
      },
      {
        name: "Instinct",
        abbreviation: "instinct",
        priceTableName: "UFH Manifold",
        uid: "instinct",
      },
    ],
    datasheet: {
      generic: GenericManifold,
      instinct: {
        "IN-1166": {
          "IN-116602-AB-BV": {
            range: "IN-1166",
            model: "IN-116602-AB-BV",
            productKey: "IN-116602-AB-BV",
            outletsCount: 2,
            widthMM: 203.5,
            heightMM: 334,
            depthMM: 94,
            kvM3PerHr: 1.65,
            ioCentre2CentreMM: 210,
            allowsActuators: true,
            description: "2 Port UFH Distribution Manifold",
          },
          "IN-116603-AB-BV": {
            range: "IN-1166",
            model: "IN-116603-AB-BV",
            productKey: "IN-116603-AB-BV",
            outletsCount: 3,
            widthMM: 253.5,
            heightMM: 334,
            depthMM: 94,
            kvM3PerHr: 2.23,
            ioCentre2CentreMM: 210,
            allowsActuators: true,
            description: "3 Port UFH Distribution Manifold",
          },
          "IN-116604-AB-BV": {
            range: "IN-1166",
            model: "IN-116604-AB-BV",
            productKey: "IN-116604-AB-BV",
            outletsCount: 4,
            widthMM: 303.5,
            heightMM: 334,
            depthMM: 94,
            kvM3PerHr: 2.55,
            ioCentre2CentreMM: 210,
            allowsActuators: true,
            description: "4 Port UFH Distribution Manifold",
          },
          "IN-116605-AB-BV": {
            range: "IN-1166",
            model: "IN-116605-AB-BV",
            productKey: "IN-116605-AB-BV",
            outletsCount: 5,
            widthMM: 353.5,
            heightMM: 334,
            depthMM: 94,
            kvM3PerHr: 2.81,
            ioCentre2CentreMM: 210,
            allowsActuators: true,
            description: "5 Port UFH Distribution Manifold",
          },
          "IN-116606-AB-BV": {
            range: "IN-1166",
            model: "IN-116606-AB-BV",
            productKey: "IN-116606-AB-BV",
            outletsCount: 6,
            widthMM: 403.5,
            heightMM: 334,
            depthMM: 94,
            kvM3PerHr: 2.97,
            ioCentre2CentreMM: 210,
            allowsActuators: true,
            description: "6 Port UFH Distribution Manifold",
          },
          "IN-116607-AB-BV": {
            range: "IN-1166",
            model: "IN-116607-AB-BV",
            productKey: "IN-116607-AB-BV",
            outletsCount: 7,
            widthMM: 453.5,
            heightMM: 334,
            depthMM: 94,
            kvM3PerHr: 3.13,
            ioCentre2CentreMM: 210,
            allowsActuators: true,
            description: "7 Port UFH Distribution Manifold",
          },
          "IN-116608-AB-BV": {
            range: "IN-1166",
            model: "IN-116608-AB-BV",
            productKey: "IN-116608-AB-BV",
            outletsCount: 8,
            widthMM: 503.5,
            heightMM: 334,
            depthMM: 94,
            kvM3PerHr: 3.26,
            ioCentre2CentreMM: 210,
            allowsActuators: true,
            description: "8 Port UFH Distribution Manifold",
          },
          "IN-116609-AB-BV": {
            range: "IN-1166",
            model: "IN-116609-AB-BV",
            productKey: "IN-116609-AB-BV",
            outletsCount: 9,
            widthMM: 553.5,
            heightMM: 334,
            depthMM: 94,
            kvM3PerHr: 3.34,
            ioCentre2CentreMM: 210,
            allowsActuators: true,
            description: "9 Port UFH Distribution Manifold",
          },
          "IN-116610-AB-BV": {
            range: "IN-1166",
            model: "IN-116610-AB-BV",
            productKey: "IN-116610-AB-BV",
            outletsCount: 10,
            widthMM: 603.5,
            heightMM: 334,
            depthMM: 94,
            kvM3PerHr: 3.38,
            ioCentre2CentreMM: 210,
            allowsActuators: true,
            description: "10 Port UFH Distribution Manifold",
          },
          "IN-116611-AB-BV": {
            range: "IN-1166",
            model: "IN-116611-AB-BV",
            productKey: "IN-116611-AB-BV",
            outletsCount: 11,
            widthMM: 653.5,
            heightMM: 334,
            depthMM: 94,
            kvM3PerHr: 3.42,
            ioCentre2CentreMM: 210,
            allowsActuators: true,
            description: "11 Port UFH Distribution Manifold",
          },
          "IN-116612-AB-BV": {
            range: "IN-1166",
            model: "IN-116612-AB-BV",
            productKey: "IN-116612-AB-BV",
            outletsCount: 12,
            widthMM: 703.5,
            heightMM: 334,
            depthMM: 94,
            kvM3PerHr: 3.5,
            ioCentre2CentreMM: 210,
            allowsActuators: true,
            description: "12 Port UFH Distribution Manifold",
          },
        },
      },
    },
  },
  actuator: {
    type: "ufh-actuator",
    name: "Underfloor Heating Actuator",
    uid: "ufh-actuator",
    manufacturer: [
      {
        name: "Generic",
        abbreviation: "generic",
        priceTableName: "UFH Actuator",
        uid: "generic",
      },
      {
        name: "Instinct",
        abbreviation: "instinct",
        priceTableName: "UFH Actuator",
        uid: "instinct",
      },
    ],
    datasheet: {
      generic: GenericActuator,
      instinct: {
        "IN-2033-230V": {
          model: "IN-2033-230V",
          productKey: "IN-2033-230V",
          description: "230V UFH ACTUATOR",
          kValue: 0.1, // TODO: not included in the document
          compatibleManifolds: [
            ["instinct", "IN-116602-AB-BV"],
            ["instinct", "IN-116603-AB-BV"],
            ["instinct", "IN-116604-AB-BV"],
            ["instinct", "IN-116605-AB-BV"],
            ["instinct", "IN-116606-AB-BV"],
            ["instinct", "IN-116607-AB-BV"],
            ["instinct", "IN-116608-AB-BV"],
            ["instinct", "IN-116609-AB-BV"],
            ["instinct", "IN-116610-AB-BV"],
            ["instinct", "IN-116611-AB-BV"],
            ["instinct", "IN-116612-AB-BV"],
          ],
        },
      },
    },
  },
  blendingValve: {
    type: "ufh-blending-valve",
    name: "Underfloor Heating Blending Valve",
    uid: "ufh-blending-valve",
    manufacturer: [
      {
        name: "Generic",
        abbreviation: "generic",
        priceTableName: "UFH Blending Valve",
        uid: "generic",
      },
      {
        name: "Instinct",
        abbreviation: "instinct",
        priceTableName: "UFH Blending Valve",
        uid: "instinct",
      },
    ],
    datasheet: {
      generic: GenericBlendingValve,
      instinct: {
        "IN-2536J": {
          model: "IN-2536J",
          productKey: "IN-2536J",
          description: "UFH Blending Valve",
          minFlowTempC: 35,
          maxFlowTempC: 85,
          pressureDropKPAByFlowRateLS: {
            ranges: [
              {
                min: 0,
                max: null,
                function: {
                  type: "polynomial",
                  coefficients: [
                    0.009246108064417058, -0.1274164348942577,
                    366.3283158970298, -73.03410848067142,
                  ],
                  variableName: "flowRateLS",
                },
              },
            ],
            variableName: "flowRateLS",
          },
        },
        /* Removed because the IN-2532 document provided is wrong. They sent the wrong file.
        "IN-2532": {
          model: "IN-2532",
          productKey: "IN-2532",
          maxHeatOutputKW: 3,
          minFlowTempC: 35,
          maxFlowTempC: 60,
          pressureDropKPAByFlowRateLS: {
            ranges: [],
            variableName: "flowRateLS",
          },
        },*/
      },
    },
  },
  pump: {
    type: "ufh-pump",
    name: "Underfloor Heating Pump",
    uid: "ufh-pump",
    manufacturer: [
      {
        name: "Generic",
        abbreviation: "generic",
        priceTableName: "UFH Pump",
        uid: "generic",
      },
      {
        name: "Bastion Industries",
        abbreviation: "bastion",
        priceTableName: "UFH Pump",
        uid: "bastion",
      },
      {
        name: "Wilo",
        abbreviation: "wilo",
        priceTableName: "UFH Pump",
        uid: "wilo",
      },
    ],
    datasheet: {
      generic: GenericUFHPump,
      bastion: {
        "ZL-3002-25/6-130/SCU": {
          model: "ZL-3002-25/6-130/SCU",
          productKey: "ZL-3002-25/6-130/SCU",
          description: "UFH Pump",
          maxHeadKPA: 9.80665 * 6, // converted m head to kPA. * 9.80665
          maxFlowRateLS: 3.0 / 3.6, // convert m3/hr to L/s
          pressureDropKPAByFlowRateLS: {
            ranges: [
              {
                min: 0,
                max: 0.5 / 3.6,
                function: {
                  type: "polynomial",
                  coefficients: [
                    58.8399, 28.243152000008195, -203.35069439996732,
                  ],
                  variableName: "flowRateLS",
                },
              },
              {
                min: 0.5 / 3.6,
                max: null,
                function: {
                  type: "polynomial",
                  coefficients: [67.27361899999998, -60.722776800000005],
                  variableName: "flowRateLS",
                },
              },
            ],
            variableName: "flowRateLS",
          },
          minPowerKW: 5,
          maxPowerKW: 39,
        },
        "ZL-3002-25/6-180/SCU": {
          model: "ZL-3002-25/6-180/SCU",
          productKey: "ZL-3002-25/6-180/SCU",
          description: "UFH Pump",
          maxHeadKPA: 9.80665 * 6, // converted m head to kPA. * 9.80665
          maxFlowRateLS: 3.0 / 3.6, // convert m3/hr to L/s
          pressureDropKPAByFlowRateLS: {
            ranges: [
              {
                min: 0,
                max: 0.5 / 3.6,
                function: {
                  type: "polynomial",
                  coefficients: [
                    58.8399, 28.243152000008195, -203.35069439996732,
                  ],
                  variableName: "flowRateLS",
                },
              },
              {
                min: 0.5 / 3.6,
                max: null,
                function: {
                  type: "polynomial",
                  coefficients: [67.27361899999998, -60.722776800000005],
                  variableName: "flowRateLS",
                },
              },
            ],
            variableName: "flowRateLS",
          },
          minPowerKW: 5,
          maxPowerKW: 39,
        },
      },
      wilo: {
        "Ku15-130/6-43/SC": {
          model: "Ku15-130/6-43/SC",
          productKey: "Ku15-130/6-43/SC",
          description: "UFH Pump",
          maxHeadKPA: 9.80665 * 6.3, // converted m head to kPA. * 9.80665
          maxFlowRateLS: 2.6 / 3.6, // convert m3/hr to L/s
          pressureDropKPAByFlowRateLS: {
            ranges: [
              {
                min: 0,
                max: 0.7 / 3.6,
                function: {
                  type: "polynomial",
                  coefficients: [63.743224],
                  variableName: "flowRateLS",
                },
              },
              {
                min: 0.7 / 3.6,
                max: null,
                function: {
                  type: "polynomial",
                  coefficients: [83.97589236842103, -104.05371789473685],
                  variableName: "flowRateLS",
                },
              },
            ],
            variableName: "flowRateLS",
          },
          minPowerKW: 3,
          maxPowerKW: 43,
        },
        "Ku20-130/6-43/SC": {
          model: "Ku20-130/6-43/SC",
          productKey: "Ku20-130/6-43/SC",
          description: "UFH Pump",
          maxHeadKPA: 9.80665 * 6.3, // converted m head to kPA. * 9.80665
          maxFlowRateLS: 2.6 / 3.6, // convert m3/hr to L/s
          pressureDropKPAByFlowRateLS: {
            ranges: [
              {
                min: 0,
                max: 0.7 / 3.6,
                function: {
                  type: "polynomial",
                  coefficients: [63.743224],
                  variableName: "flowRateLS",
                },
              },
              {
                min: 0.7 / 3.6,
                max: null,
                function: {
                  type: "polynomial",
                  coefficients: [83.97589236842103, -104.05371789473685],
                  variableName: "flowRateLS",
                },
              },
            ],
            variableName: "flowRateLS",
          },
          minPowerKW: 3,
          maxPowerKW: 43,
        },
        "Ku25-130/6-43/SC": {
          model: "Ku25-130/6-43/SC",
          productKey: "Ku25-130/6-43/SC",
          description: "UFH Pump",
          maxHeadKPA: 9.80665 * 6.3, // converted m head to kPA. * 9.80665
          maxFlowRateLS: 2.6 / 3.6, // convert m3/hr to L/s
          pressureDropKPAByFlowRateLS: {
            ranges: [
              {
                min: 0,
                max: 0.7 / 3.6,
                function: {
                  type: "polynomial",
                  coefficients: [63.743224],
                  variableName: "flowRateLS",
                },
              },
              {
                min: 0.7 / 3.6,
                max: null,
                function: {
                  type: "polynomial",
                  coefficients: [83.97589236842103, -104.05371789473685],
                  variableName: "flowRateLS",
                },
              },
            ],
            variableName: "flowRateLS",
          },
          minPowerKW: 3,
          maxPowerKW: 43,
        },
      },
    },
  },
  pumpPack: {
    type: "ufh-pump-pack",
    name: "Underfloor Heating Pump Pack",
    uid: "ufh-pump-pack",
    manufacturer: [
      {
        name: "Generic",
        abbreviation: "generic",
        priceTableName: "UFH Pump Pack",
        uid: "generic",
      },
      {
        name: "Instinct",
        abbreviation: "Instinct",
        priceTableName: "UFH Pump Pack",
        uid: "instinct",
      },
    ],
    datasheet: {
      generic: GenericPumpPack,
      instinct: {
        "IN-2532J+BAS-PUMP": {
          model: "IN-2532J+BAS-PUMP",
          productKey: "IN-2532J+BAS-PUMP",
          description: "UFH Single Room Control Pump Pack (Bastion Pump)",
          pumps: [["bastion", "ZL-3002-25/6-130/SCU"]],
          blendingValves: [["instinct", "IN-2536J"]],
          compatibleManifolds: [
            ["instinct", "IN-116602-AB-BV"],
            ["instinct", "IN-116603-AB-BV"],
            ["instinct", "IN-116604-AB-BV"],
            ["instinct", "IN-116605-AB-BV"],
            ["instinct", "IN-116606-AB-BV"],
            ["instinct", "IN-116607-AB-BV"],
            ["instinct", "IN-116608-AB-BV"],
            ["instinct", "IN-116609-AB-BV"],
            ["instinct", "IN-116610-AB-BV"],
            ["instinct", "IN-116611-AB-BV"],
            ["instinct", "IN-116612-AB-BV"],
          ],
        },
        "IN-2532J+WILO-PUMP": {
          model: "IN-2532J+WILO-PUMP",
          productKey: "IN-2532J+WILO-PUMP",
          description: "UFH Single Room Control Pump Pack (Wilo Pump)",
          pumps: [["wilo", "Ku15-130/6-43/SC"]],
          blendingValves: [["instinct", "IN-2536J"]],
          compatibleManifolds: [
            ["instinct", "IN-116602-AB-BV"],
            ["instinct", "IN-116603-AB-BV"],
            ["instinct", "IN-116604-AB-BV"],
            ["instinct", "IN-116605-AB-BV"],
            ["instinct", "IN-116606-AB-BV"],
            ["instinct", "IN-116607-AB-BV"],
            ["instinct", "IN-116608-AB-BV"],
            ["instinct", "IN-116609-AB-BV"],
            ["instinct", "IN-116610-AB-BV"],
            ["instinct", "IN-116611-AB-BV"],
            ["instinct", "IN-116612-AB-BV"],
          ],
        },
        "IN-2536J+BAS-PUMP": {
          model: "IN-2536J+BAS-PUMP",
          productKey: "IN-2536J+BAS-PUMP",
          description: "UFH Blending Valve & Pump Pack (Bastion Pump)",
          pumps: [["bastion", "ZL-3002-25/6-130/SCU"]],
          blendingValves: [["instinct", "IN-2536J"]],
          compatibleManifolds: [
            ["instinct", "IN-116602-AB-BV"],
            ["instinct", "IN-116603-AB-BV"],
            ["instinct", "IN-116604-AB-BV"],
            ["instinct", "IN-116605-AB-BV"],
            ["instinct", "IN-116606-AB-BV"],
            ["instinct", "IN-116607-AB-BV"],
            ["instinct", "IN-116608-AB-BV"],
            ["instinct", "IN-116609-AB-BV"],
            ["instinct", "IN-116610-AB-BV"],
            ["instinct", "IN-116611-AB-BV"],
            ["instinct", "IN-116612-AB-BV"],
          ],
        },
        "IN-2536J+WILO-PUMP": {
          model: "IN-2536J+WILO-PUMP",
          productKey: "IN-2536J+WILO-PUMP",
          description: "UFH Blending Valve & Pump Pack (Wilo Pump)",
          pumps: [["bastion", "Ku15-130/6-43/SC"]],
          blendingValves: [["instinct", "IN-2536J"]],
          compatibleManifolds: [
            ["instinct", "IN-116602-AB-BV"],
            ["instinct", "IN-116603-AB-BV"],
            ["instinct", "IN-116604-AB-BV"],
            ["instinct", "IN-116605-AB-BV"],
            ["instinct", "IN-116606-AB-BV"],
            ["instinct", "IN-116607-AB-BV"],
            ["instinct", "IN-116608-AB-BV"],
            ["instinct", "IN-116609-AB-BV"],
            ["instinct", "IN-116610-AB-BV"],
            ["instinct", "IN-116611-AB-BV"],
            ["instinct", "IN-116612-AB-BV"],
          ],
        },
      },
    },
  },
  ballValve: {
    type: "ufh-ball-valve",
    name: "Underfloor Heating Ball Valve",
    uid: "ufh-ball-valve",
    manufacturer: [
      {
        name: "Generic",
        abbreviation: "generic",
        priceTableName: "UFH Ball Valve",
        uid: "generic",
      },
      {
        name: "Instinct",
        abbreviation: "instinct",
        priceTableName: "UFH Ball Valve",
        uid: "instinct",
      },
    ],
    datasheet: {
      generic: GenericBallValve,
      instinct: {
        "ZL-4012": {
          model: "ZL-4012",
          productKey: "ZL-4012",
          description: '1" Ball valve With Temp Gauge set Red&Blue(Pair)',
          sizesMM: [25.4],
          kValue: 0.08,
          needsPumpPack: false,
          compatibleManifolds: [
            ["instinct", "IN-116602-AB-BV"],
            ["instinct", "IN-116603-AB-BV"],
            ["instinct", "IN-116604-AB-BV"],
            ["instinct", "IN-116605-AB-BV"],
            ["instinct", "IN-116606-AB-BV"],
            ["instinct", "IN-116607-AB-BV"],
            ["instinct", "IN-116608-AB-BV"],
            ["instinct", "IN-116609-AB-BV"],
            ["instinct", "IN-116610-AB-BV"],
            ["instinct", "IN-116611-AB-BV"],
            ["instinct", "IN-116612-AB-BV"],
          ],
        },
        "ZL-4130B": {
          model: "ZL-4130B",
          productKey: "ZL-4130B",
          description: '3/4" Ball valve set for Blending Valve Red&Blue (pair)',
          sizesMM: [25.4, 25.4 * 0.75],
          kValue: 0.08,
          needsPumpPack: true,
          compatibleManifolds: [
            ["instinct", "IN-116602-AB-BV"],
            ["instinct", "IN-116603-AB-BV"],
            ["instinct", "IN-116604-AB-BV"],
            ["instinct", "IN-116605-AB-BV"],
            ["instinct", "IN-116606-AB-BV"],
            ["instinct", "IN-116607-AB-BV"],
            ["instinct", "IN-116608-AB-BV"],
            ["instinct", "IN-116609-AB-BV"],
            ["instinct", "IN-116610-AB-BV"],
            ["instinct", "IN-116611-AB-BV"],
            ["instinct", "IN-116612-AB-BV"],
          ],
        },
      },
    },
  },
  edgeExpansionFoam: {
    type: "ufh-edge-expansion-foam",
    name: "Underfloor Heating Foam",
    uid: "ufh-edge-expansion-foam",
    manufacturer: [
      {
        name: "Generic",
        abbreviation: "generic",
        priceTableName: "UFH Edge Expansion Foam",
        uid: "generic",
      },
      {
        name: "Resonate",
        abbreviation: "resonate",
        priceTableName: "UFH Edge Expansion Foam",
        uid: "resonate",
      },
    ],
    datasheet: {
      generic: GenericEdgeExpansionFoam,
      resonate: {
        INDGET0815025ADH: {
          model: "INDGET0815025ADH",
          productKey: "INDGET0815025ADH",
          description:
            "Edge Expansion Foam Roll 8mm x 150mm x 25m & bond PVC apron",
          rollLengthM: 25,
        },
      },
    },
  },
};
