import uuid from "uuid";
import {
  Choice,
  assertType,
  assertUnreachable,
  betterObjectKeys,
  betterObjectValues,
} from "../../../../lib/utils";
import {
  DualSystemMechanicalNodeProps,
  MechanicalNodeProps,
  SingleMechanicalNodeProps,
} from "../../../../models/CustomEntity";
import { CoreContext } from "../../../calculations/types";
import { toTitleCase } from "../../../calculations/utils";
import {
  Catalog,
  FilterData,
  ManufacturerRadiatorData,
  PumpData,
  PumpTableData,
  RadiatorData,
  RoPlantData,
} from "../../../catalog/types";
import {
  ActuatorModel,
  BallValveModel,
  ComponentId,
  ManifoldModel,
  PumpPackModel,
} from "../../../catalog/underfloor-heating/ufh-types";
import { StandardFlowSystemUids, isHeating } from "../../../config";
import CoreSystemNode from "../../../coreObjects/coreSystemNode";
import { DrawingState, MetadataCatalog } from "../../drawing";
import { FlowSystem } from "../../flow-systems";
import { getFlowSystem } from "../../utils";
import { DrawableEntityConcrete } from "../concrete-entity";
import { EntityType } from "../types";
import { getCustomManufFields } from "./customManufFields";
import PlantEntity, {
  FilterPlantEntity,
  ManifoldPlantEntity,
  PumpPlantEntity,
  PumpTankPlantEntity,
  ROPlantEntity,
  RadiatorPlantEntity,
  ReturnSystemPlantEntity,
  TankPlantEntity,
} from "./plant-entity";
import {
  AirHandlingUnitPlant,
  AirHandlingUnitVentPlant,
  DHWCylinderPlant,
  DualSystemMechanicalNodeBase,
  DualSystemPlant,
  DuctManifoldPlant,
  FanCoilUnitPlant,
  FilterConfiguration,
  FilterPlant,
  GreaseInterceptorTrapManufacturers,
  HeatPumpPlant,
  HotWaterOutlet,
  ManifoldPlant,
  PlantConcrete,
  PlantShapes,
  PlantType,
  PressureMethod,
  PumpConfiguration,
  PumpPlant,
  ROPlant,
  RadiatorManufacturer,
  RadiatorPlant,
  RadiatorRangeTypeOption,
  ReturnSystemPlant,
  ReturnSystemType,
  SingleSystemMechanicalNodeBase,
  SpecifyRadiatorPlant,
} from "./plant-types";

export function getPumpConfigurationCount(
  configuration: PumpConfiguration | null,
): number {
  switch (configuration) {
    case "duty-assist-assist-assist":
    case "duty-assist-assist-standby":
      return 4;
    case "duty-assist-standby":
    case "duty-assist-assist":
      return 3;
    case "duty-assist":
    case "duty-standby":
      return 2;
    case "duty":
    case null:
      return 1;
  }
  assertUnreachable(configuration);
}

export function getFilterConfigurationCount(
  configuration: FilterConfiguration | null,
): number {
  switch (configuration) {
    case "duty-duty":
    case "duty-standby":
      return 2;
    case "duty":
    case null:
      return 1;
  }
  assertUnreachable(configuration);
}

export function getPumpConfigurationName(
  configuration: PumpConfiguration | null,
  length: "short" | "long" = "long",
): string {
  switch (configuration) {
    case null:
      return "";
    case "duty":
      return "Duty";
    case "duty-standby":
      return length === "long" ? "Duty Standby" : "D/S";
    case "duty-assist":
      return length === "long" ? "Duty Assist" : "D/A";
    case "duty-assist-standby":
      return length === "long" ? "Duty Assist Standby" : "D/A/S";
    case "duty-assist-assist":
      return length === "long" ? "Duty Assist Assist" : "D/A/A";
    case "duty-assist-assist-assist":
      return length === "long" ? "Duty Assist Assist Assist" : "D/A/A/A";
    case "duty-assist-assist-standby":
      return length === "long" ? "Duty Assist Assist Standby" : "D/A/A/S";
  }
  assertUnreachable(configuration);
}

export function getTankPumpModelSize(
  catalog: Catalog,
  manufacturer: string | null,
  model: string | null,
  capacityL: number | null,
): { widthMM: number; heightMM: number; depthMM?: number } | null {
  if (!manufacturer) {
    return null;
  }
  const datasheet = catalog.pumpTank.datasheet[manufacturer];
  switch (datasheet.type) {
    case "model-capacity-dependent":
      if (model == null) {
        return null;
      }
      return datasheet.sizes[model] || null;
    case "model-capacity-independent": {
      if (model == null || capacityL == null) {
        return null;
      }
      const capacities = Object.keys(datasheet.sizes)
        .map((key) => parseInt(key, 10))
        .sort((a, b) => a - b);
      const capacityMatch = capacities.find(
        (capacity) => capacity >= capacityL,
      );
      if (capacityMatch !== undefined) {
        return datasheet.sizes[capacityMatch] || null;
      }
      return null;
    }
    case "none":
      return null;
  }
  assertUnreachable(datasheet);
}

export interface AvailableRadiatorSizes {
  widthMM: { value: number; available: boolean }[];
  heightMM: { value: number; available: boolean }[];
}

export function getRadiatorAvailableSizes(
  radiator: SpecifyRadiatorPlant,
  catalog: Catalog,
): AvailableRadiatorSizes {
  const table = getPlantDatasheet(radiator, catalog, true, {
    rangeType: radiator.rangeType,
  });
  assertType<ManufacturerRadiatorData[]>(table);

  const chosenHeight = radiator.heightMM.value;
  const chosenWidth = radiator.widthMM.value;

  const allHeights = new Set<number>();
  const availableHeights = new Set<number>();
  for (const item of table) {
    allHeights.add(item.heightMM);
    if (chosenWidth && item.widthMM !== chosenWidth) {
      continue;
    }
    availableHeights.add(item.heightMM);
  }

  const allWidths = new Set<number>();
  const availableWidths = new Set<number>();
  for (const item of table) {
    allWidths.add(item.widthMM);
    if (chosenHeight && item.heightMM !== chosenHeight) {
      continue;
    }
    availableWidths.add(item.widthMM);
  }

  return {
    widthMM: Array.from(allWidths)
      .sort((a, b) => a - b)
      .map((width) => ({
        value: width,
        available: availableWidths.has(width),
      })),
    heightMM: Array.from(allHeights)
      .sort((a, b) => a - b)
      .map((height) => ({
        value: height,
        available: availableHeights.has(height),
      })),
  };
}

export function getPlantDatasheet(
  plant: FilterPlant,
  catalog: Catalog,
  filtered?: boolean,
): FilterData[];
export function getPlantDatasheet(
  plant: ROPlant,
  catalog: Catalog,
  filtered?: boolean,
): RoPlantData[];
export function getPlantDatasheet(
  plant: PumpPlant,
  catalog: Catalog,
  filtered?: boolean,
): PumpData[];
export function getPlantDatasheet(
  plant: SpecifyRadiatorPlant,
  catalog: Catalog,
  filtered?: boolean,
  extraFilters?: Partial<RadiatorData>,
): RadiatorData[];
export function getPlantDatasheet(
  plant: FilterPlant | ROPlant | PumpPlant | SpecifyRadiatorPlant,
  catalog: Catalog,
  filtered?: boolean,
  extraFilters?: Partial<RadiatorData>,
): FilterData[] | RoPlantData[] | PumpData[] | RadiatorData[] {
  if (plant.manufacturer === null) {
    return [];
  }
  switch (plant.type) {
    case PlantType.FILTER:
      let filterData: FilterData[] = [];
      switch (plant.filterType) {
        case "softener":
          filterData = catalog.filters.softener.datasheet[plant.manufacturer];
          break;
        case "backwash":
          filterData = catalog.filters.backwash.datasheet[plant.manufacturer];
          break;
        case "cartridge":
          filterData = catalog.filters.cartridge.datasheet[plant.manufacturer];
          break;
        case "uv":
          filterData = catalog.filters.uv.datasheet[plant.manufacturer];
          break;
        case "backwash-rainwater":
          filterData =
            catalog.filters.backwashRainwater.datasheet[plant.manufacturer];
          break;
        default:
          assertUnreachable(plant);
      }

      if (filtered) {
        if (plant.configuration === null) {
          return [];
        }

        let filtered = filterData.filter(
          (d) => d.configuration === plant.configuration,
        );

        return filterByCustomManufFields(plant, filtered);
      }

      return filterData;

    case PlantType.RO:
      const roData = catalog.roPlant.datasheet[plant.manufacturer];

      if (filtered) {
        return filterByCustomManufFields(plant, roData);
      }

      return roData;

    case PlantType.PUMP:
      const pumpData = catalog.pump.datasheet[plant.manufacturer];
      if (filtered && plant.customManufFields) {
        return filterByCustomManufFields(plant, pumpData);
      }
      return pumpData;

    case PlantType.RADIATOR:
      let radiatorData = catalog.heatEmitters.radiators.datasheet[
        plant.manufacturer
      ] as RadiatorData[];

      if (!radiatorData) {
        console.error(
          "No radiator data found for manufacturer",
          plant.manufacturer,
        );
        return [];
      }

      if (filtered) {
        radiatorData = filterByCustomManufFields(plant, radiatorData);
      }
      if (extraFilters) {
        radiatorData = radiatorData.filter((item: RadiatorData) => {
          for (const key in extraFilters) {
            if (extraFilters.hasOwnProperty(key)) {
              if (
                item[key as keyof RadiatorData] !==
                extraFilters[key as keyof RadiatorData]
              ) {
                return false;
              }
            }
          }
          return true;
        });
      }
      return radiatorData;
    default:
      assertUnreachable(plant);
  }

  assertUnreachable(plant);
  return [];
}

export function filterByCustomManufFields(
  filterPlant: FilterPlant | ROPlant | PumpPlant | SpecifyRadiatorPlant,
  data: any[],
) {
  const manufFields = getCustomManufFields(filterPlant);
  for (const field of manufFields) {
    if (
      !filterPlant.customManufFields ||
      !filterPlant.customManufFields[field.uid]
    ) {
      throw new Error("Custom manuf field not defined" + field.uid);
    }

    data = data.filter((d) => {
      if (!d.customManufFields || !d.customManufFields[field.uid]) {
        throw new Error(
          "Datasheet is missing the required custom manuf field" +
            d +
            " field: " +
            field.uid,
        );
      }

      return field.comparisonFn(
        d.customManufFields[field.uid],
        String(filterPlant.customManufFields![field.uid]),
      );
    });
  }

  return data;
}

export function getPlantConfigurations(
  plant: PlantConcrete,
  catalog: Catalog,
): (PumpConfiguration | FilterConfiguration)[] {
  switch (plant.type) {
    case PlantType.PUMP:
      if (!plant.manufacturer) {
        return [
          "duty",
          "duty-assist",
          "duty-assist-assist",
          "duty-assist-standby",
          "duty-standby",
        ];
      }

      const datasheet = getPlantDatasheet(plant, catalog, true)[0];
      switch (datasheet.type) {
        case "dense-table":
        case "sparse-table":
          return Object.keys(datasheet.table) as PumpConfiguration[];
        case "none":
          return [
            "duty",
            "duty-assist",
            "duty-assist-assist",
            "duty-assist-standby",
            "duty-standby",
          ];
      }
      assertUnreachable(datasheet);
      return [];

    case PlantType.PUMP_TANK:
      if (!plant.manufacturer) {
        return [
          "duty",
          "duty-assist",
          "duty-assist-assist",
          "duty-assist-standby",
          "duty-standby",
        ];
      }

      const datasheet1 = catalog.pumpTank.datasheet[plant.manufacturer];
      switch (datasheet1.type) {
        case "model-capacity-dependent":
        case "model-capacity-independent":
          return Object.keys(datasheet1.configuration) as PumpConfiguration[];
        case "none":
          return [
            "duty",
            "duty-assist",
            "duty-assist-assist",
            "duty-assist-standby",
            "duty-standby",
          ];
      }
      assertUnreachable(datasheet1);
      return [];

    case PlantType.FILTER:
      const datasheet2 = getPlantDatasheet(plant, catalog);
      return [...new Set(datasheet2.map((item) => item.configuration))];

    case PlantType.CUSTOM:
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
    case PlantType.DRAINAGE_PIT:
    case PlantType.RADIATOR:
    case PlantType.RETURN_SYSTEM:
    case PlantType.TANK:
    case PlantType.VOLUMISER:
    case PlantType.AHU:
    case PlantType.AHU_VENT:
    case PlantType.FCU:
    case PlantType.MANIFOLD:
    case PlantType.UFH:
    case PlantType.RO:
    case PlantType.DUCT_MANIFOLD:
      return [];
  }
  assertUnreachable(plant);
}

export function getPlantModelChoices(
  plant: PlantConcrete,
  catalog: Catalog,
  extraFilters?: Partial<RadiatorData>,
): string[] {
  const models = new Set<string>();
  switch (plant.type) {
    case PlantType.PUMP:
      if (!plant.manufacturer || !plant.configuration) {
        return [];
      }
      const datasheet = getPlantDatasheet(plant, catalog, true)[0];
      if (!datasheet) {
        return [];
      }
      switch (datasheet.type) {
        case "dense-table":
        case "sparse-table":
          const table = datasheet.table[plant.configuration];
          if (!table) {
            return [];
          }
          for (const row of Object.values(table)) {
            for (const model of Object.values(row)) {
              models.add(model);
            }
          }
          break;
        case "none":
          break;
        default:
          assertUnreachable(datasheet);
      }
      break;

    case PlantType.PUMP_TANK:
      if (!plant.manufacturer || !plant.configuration) {
        return [];
      }

      const datasheet1 = catalog.pumpTank.datasheet[plant.manufacturer];
      if (!datasheet1) {
        return [];
      }
      switch (datasheet1.type) {
        case "model-capacity-dependent":
          const capacityLookup =
            datasheet1.configuration[plant.configuration]?.capacityLookup;
          if (!capacityLookup) {
            return [];
          }
          for (const row of Object.values(capacityLookup)) {
            for (const model of Object.values(row)) {
              models.add(model);
            }
          }
          break;
        case "model-capacity-independent":
          const table = datasheet1.configuration[plant.configuration];
          if (!table) {
            return [];
          }
          for (const row of Object.values(table)) {
            for (const model of Object.values(row)) {
              models.add(model);
            }
          }
          break;
        case "none":
          return [""];
      }
      break;

    case PlantType.FILTER:
      if (!plant.manufacturer || !plant.configuration) {
        return [];
      }

      const datasheet2 = getPlantDatasheet(plant, catalog, true);
      for (const item of datasheet2) {
        if (item.model) {
          models.add(item.model);
        }
      }
      break;

    case PlantType.RO:
      const datasheet3 = getPlantDatasheet(plant, catalog, true);
      for (const item of datasheet3) {
        if (item.model) {
          models.add(item.model);
        }
      }
      break;
    case PlantType.RADIATOR:
      if (plant.radiatorType === "fixed" || plant.manufacturer === "generic") {
        // fixed and generic specify radiators
        return [];
      }
      const datasheet4 = getPlantDatasheet(plant, catalog, true, extraFilters);

      for (const item of datasheet4) {
        if (item.model) {
          models.add(item.model);
        }
      }
      break;

    case PlantType.CUSTOM:
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
    case PlantType.DRAINAGE_PIT:
    case PlantType.RETURN_SYSTEM:
    case PlantType.TANK:
    case PlantType.VOLUMISER:
    case PlantType.AHU:
    case PlantType.AHU_VENT:
    case PlantType.FCU:
    case PlantType.MANIFOLD:
    case PlantType.UFH:
    case PlantType.DUCT_MANIFOLD:
      return [];
    default:
      assertUnreachable(plant);
  }

  return Array.from(models).sort();
}

export function isPumpTableData(data: PumpData): data is PumpTableData {
  return data.type !== "none";
}

export function getFilterTypeToCatalogUid(
  filterType: FilterPlant["filterType"],
): keyof Catalog["filters"] {
  switch (filterType) {
    case "softener":
      return "softener";
    case "backwash":
      return "backwash";
    case "uv":
      return "uv";
    case "cartridge":
      return "cartridge";
    case "backwash-rainwater":
      return "backwashRainwater";
  }
  assertUnreachable(filterType);
}

export function getDefaultPlantManufacturer(
  plant: PlantConcrete,
  catalog: MetadataCatalog,
  subCatalogUid?: string,
): string {
  const defaultManufacturer = "generic";

  switch (plant.type) {
    case PlantType.PUMP:
      if (catalog.pump.length > 0) {
        return catalog.pump[0].manufacturer;
      }
      break;
    case PlantType.PUMP_TANK:
      if (catalog.pumpTank.length > 0) {
        return catalog.pumpTank[0].manufacturer;
      }
      break;
    case PlantType.FILTER:
      return (
        catalog.filters.find(
          (i) => i.uid === getFilterTypeToCatalogUid(plant.filterType),
        )?.manufacturer || defaultManufacturer
      );
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
      if (catalog.greaseInterceptorTrap) {
        return catalog.greaseInterceptorTrap[0].manufacturer;
      }
      break;
    case PlantType.RETURN_SYSTEM:
      return (
        catalog.hotWaterPlant.find((i) => i.uid === "hotWaterPlant")
          ?.manufacturer || defaultManufacturer
      );
      break;
    case PlantType.RO:
      if (catalog.roPlant.length > 0) {
        return catalog.roPlant[0].manufacturer;
      }
      break;
    case PlantType.MANIFOLD:
      const uid = subCatalogUid ?? "manifold";
      return (
        catalog.underfloorHeating?.find((i) => i.uid === uid)?.manufacturer ||
        defaultManufacturer
      );
    case PlantType.TANK:
    case PlantType.DRAINAGE_PIT:
    case PlantType.RADIATOR:
    case PlantType.CUSTOM:
    case PlantType.VOLUMISER:
    case PlantType.AHU:
    case PlantType.AHU_VENT:
    case PlantType.FCU:
    case PlantType.UFH:
    case PlantType.DUCT_MANIFOLD:
      break;
    default:
      assertUnreachable(plant);
  }

  return defaultManufacturer;
}

export function isPumpPressureVariablyCalculated(
  entity: PlantEntity,
): entity is PumpPlantEntity | PumpTankPlantEntity | ROPlantEntity {
  switch (entity.plant.type) {
    case PlantType.PUMP:
      return entity.plant.pressureLoss.pumpPressureKPA === null;
    case PlantType.PUMP_TANK:
    case PlantType.RO:
      return entity.plant.pressureLoss.staticPressureKPA === null;
    case PlantType.CUSTOM:
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
    case PlantType.DRAINAGE_PIT:
    case PlantType.RADIATOR:
    case PlantType.RETURN_SYSTEM:
    case PlantType.TANK:
    case PlantType.VOLUMISER:
    case PlantType.AHU:
    case PlantType.AHU_VENT:
    case PlantType.FCU:
    case PlantType.MANIFOLD:
    case PlantType.UFH:
    case PlantType.FILTER:
    case PlantType.DUCT_MANIFOLD:
      return false;
  }
  assertUnreachable(entity.plant);
}

export function hasPlantPressureCalculation(
  entity: PlantEntity,
): entity is PumpPlantEntity | PumpTankPlantEntity | FilterPlantEntity {
  switch (entity.plant.type) {
    case PlantType.PUMP:
    case PlantType.PUMP_TANK:
    case PlantType.FILTER:
      return true;
    case PlantType.CUSTOM:
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
    case PlantType.DRAINAGE_PIT:
    case PlantType.RADIATOR:
    case PlantType.RETURN_SYSTEM:
    case PlantType.TANK:
    case PlantType.VOLUMISER:
    case PlantType.AHU:
    case PlantType.AHU_VENT:
    case PlantType.FCU:
    case PlantType.MANIFOLD:
    case PlantType.UFH:
    case PlantType.RO:
    case PlantType.DUCT_MANIFOLD:
      return false;
  }
  assertUnreachable(entity.plant);
}

export function isPlantPump(
  entity: PlantEntity,
): entity is PumpPlantEntity | PumpTankPlantEntity | ROPlantEntity {
  switch (entity.plant.type) {
    case PlantType.PUMP:
    case PlantType.PUMP_TANK:
    case PlantType.RO:
      return true;
    case PlantType.CUSTOM:
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
    case PlantType.DRAINAGE_PIT:
    case PlantType.RADIATOR:
    case PlantType.RETURN_SYSTEM:
    case PlantType.TANK:
    case PlantType.VOLUMISER:
    case PlantType.AHU:
    case PlantType.AHU_VENT:
    case PlantType.FCU:
    case PlantType.MANIFOLD:
    case PlantType.UFH:
    case PlantType.FILTER:
    case PlantType.DUCT_MANIFOLD:
      return false;
  }
  assertUnreachable(entity.plant);
}

export function isPlantTank(
  entity: PlantEntity,
): entity is PumpTankPlantEntity | TankPlantEntity | ROPlantEntity {
  switch (entity.plant.type) {
    case PlantType.PUMP_TANK:
    case PlantType.TANK:
    case PlantType.RO:
      return true;
    case PlantType.PUMP:
    case PlantType.CUSTOM:
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
    case PlantType.DRAINAGE_PIT:
    case PlantType.RADIATOR:
    case PlantType.RETURN_SYSTEM:
    case PlantType.VOLUMISER:
    case PlantType.AHU:
    case PlantType.AHU_VENT:
    case PlantType.FCU:
    case PlantType.MANIFOLD:
    case PlantType.UFH:
    case PlantType.FILTER:
    case PlantType.DUCT_MANIFOLD:
      return false;
  }
  assertUnreachable(entity.plant);
}
export function isVentilationPlant(
  plant: PlantConcrete,
  flowSystems: Record<string, FlowSystem>,
): plant is AirHandlingUnitVentPlant {
  return plant.type === PlantType.AHU_VENT;
}
export function isMultiOutlets(
  plant: PlantConcrete,
): plant is
  | ReturnSystemPlant
  | FanCoilUnitPlant
  | AirHandlingUnitPlant
  | AirHandlingUnitVentPlant
  | DuctManifoldPlant {
  switch (plant.type) {
    case PlantType.RETURN_SYSTEM:
    case PlantType.FCU:
    case PlantType.AHU:
    case PlantType.AHU_VENT:
    case PlantType.DUCT_MANIFOLD:
      return true;
    case PlantType.PUMP_TANK:
    case PlantType.TANK:
    case PlantType.PUMP:
    case PlantType.CUSTOM:
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
    case PlantType.DRAINAGE_PIT:
    case PlantType.RADIATOR:
    case PlantType.VOLUMISER:
    case PlantType.MANIFOLD:
    case PlantType.UFH:
    case PlantType.FILTER:
    case PlantType.RO:
      return false;
  }
  assertUnreachable(plant);
}

export function isPlantReturnSystem(
  plant: PlantEntity,
): plant is ReturnSystemPlantEntity {
  switch (plant.plant.type) {
    case PlantType.RETURN_SYSTEM:
      return true;
    case PlantType.PUMP_TANK:
    case PlantType.TANK:
    case PlantType.PUMP:
    case PlantType.CUSTOM:
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
    case PlantType.DRAINAGE_PIT:
    case PlantType.RADIATOR:
    case PlantType.VOLUMISER:
    case PlantType.AHU:
    case PlantType.AHU_VENT:
    case PlantType.FCU:
    case PlantType.MANIFOLD:
    case PlantType.UFH:
    case PlantType.FILTER:
    case PlantType.RO:
    case PlantType.DUCT_MANIFOLD:
      return false;
  }
  assertUnreachable(plant.plant);
}

export function isCIBSEDiversifiedPlant(plant: PlantEntity) {
  return (
    plant.plant.type === PlantType.RETURN_SYSTEM &&
    isReturnPlantCIBSEDiversified(plant.plant)
  );
}

export function isReturnPlantCIBSEDiversified(plant: ReturnSystemPlant) {
  return plant.isCibseDiversified;
}

export function createRadiatorFromNodeSpec(
  context: CoreContext,
  manufacturer: RadiatorManufacturer,
  node: SingleMechanicalNodeProps,
): RadiatorPlantEntity {
  switch (manufacturer) {
    case "generic":
      const radiatorPlantEntity: RadiatorPlantEntity = {
        type: EntityType.PLANT,
        uid: uuid(),
        center: { x: 0, y: 0 },
        parentUid: null,
        inletUid: null,
        rotation: 0,
        inletSystemUid: StandardFlowSystemUids.Heating,
        name: node.name,
        widthMM: node.widthMM,
        depthMM: node.depthMM,
        inletHeightAboveFloorM: node.inletHeightAboveFloorMM / 1000,
        rightToLeft: false,
        plant: {
          type: PlantType.RADIATOR,
          radiatorType: "fixed",
          shape: PlantShapes.RECTANGULAR,
          diameterMM: null,
          outletUid: uuid(),
          outletHeightAboveFloorM: node.outletHeightAboveFloorMM / 1000,
          outletTemperatureC: null,
          outletSystemUid: StandardFlowSystemUids.Heating,
          rating: {
            type: "energy",
            KW: node.ratingKW,
          },
          widthMM: node.widthMM,
          heightMM: node.heightMM,
          depthMM: node.depthMM,
          capacityRateLKW: node.capacityRateLKW,
          volumeL: null,
          pressureLoss: {
            pressureMethod: PressureMethod.FIXED_PRESSURE_LOSS,
            pressureLossKPA: node.pressureDropKPA,
          },
          heatSourceOutletUid: null,
        },
      };
      return radiatorPlantEntity;
    default:
      const stelradPlantEntity: RadiatorPlantEntity = {
        type: EntityType.PLANT,
        uid: uuid(),
        center: { x: 0, y: 0 },
        parentUid: null,
        inletUid: null,
        rotation: 0,
        inletSystemUid: StandardFlowSystemUids.Heating,
        name: "Radiator",
        widthMM: null,
        depthMM: null,
        inletHeightAboveFloorM: null,
        rightToLeft: false,
        plant: {
          type: PlantType.RADIATOR,
          radiatorType: "specify",
          shape: PlantShapes.RECTANGULAR,
          diameterMM: null,
          outletUid: uuid(),
          outletHeightAboveFloorM: null,
          outletTemperatureC: null,
          outletSystemUid: StandardFlowSystemUids.Heating,
          rating: {
            type: "energy",
            KW: null,
          },
          widthMM: { type: "upper", value: 1000 },
          heightMM: { type: "upper", value: 500 },
          depthMM: null,
          capacityRateLKW: 0,
          volumeL: null,
          pressureLoss: {
            pressureMethod: PressureMethod.KV_PRESSURE_LOSS,
            kvValue: 0.3,
          },
          rangeType: getRadiatorRangeTypeOptions(context, manufacturer)[0].key,
          manufacturer: manufacturer,
          model: null,
          customManufFields: {},
          heatSourceOutletUid: null,
        },
      };
      return stelradPlantEntity;
  }
  throw new Error("Unexpected error in createRadiatorFromNodeSpec");
}

export function isHeatPumpPlant(plant: PlantConcrete): plant is HeatPumpPlant {
  switch (plant.type) {
    case PlantType.RETURN_SYSTEM:
      return (
        plant.returnSystemType === ReturnSystemType.AIR_SOURCE_HEAT_PUMP ||
        plant.returnSystemType === ReturnSystemType.GROUND_SOURCE_HEAT_PUMP
      );
    case PlantType.PUMP:
    case PlantType.TANK:
    case PlantType.PUMP_TANK:
    case PlantType.DRAINAGE_PIT:
    case PlantType.CUSTOM:
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
    case PlantType.VOLUMISER:
    case PlantType.RADIATOR:
    case PlantType.AHU:
    case PlantType.AHU_VENT:
    case PlantType.FCU:
    case PlantType.MANIFOLD:
    case PlantType.UFH:
    case PlantType.FILTER:
    case PlantType.RO:
    case PlantType.DUCT_MANIFOLD:
      return false;
    default:
      assertUnreachable(plant);
  }
  return false;
}

export function isDHWCylinderPlant(
  plant: PlantConcrete,
): plant is DHWCylinderPlant {
  switch (plant.type) {
    case PlantType.RETURN_SYSTEM:
      return (
        plant.returnSystemType === ReturnSystemType.DHW_CYLINDER ||
        plant.returnSystemType === ReturnSystemType.DHW_CYLINDER_W_STORAGE
      );
    case PlantType.PUMP:
    case PlantType.TANK:
    case PlantType.PUMP_TANK:
    case PlantType.DRAINAGE_PIT:
    case PlantType.CUSTOM:
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
    case PlantType.VOLUMISER:
    case PlantType.RADIATOR:
    case PlantType.AHU:
    case PlantType.AHU_VENT:
    case PlantType.FCU:
    case PlantType.MANIFOLD:
    case PlantType.UFH:
    case PlantType.FILTER:
    case PlantType.RO:
    case PlantType.DUCT_MANIFOLD:
      return false;
    default:
      assertUnreachable(plant);
  }
  return false;
}

export function isRadiatorPlant(plant: PlantConcrete): plant is RadiatorPlant {
  switch (plant.type) {
    case PlantType.RADIATOR:
      return true;
    case PlantType.RETURN_SYSTEM:
    case PlantType.PUMP:
    case PlantType.TANK:
    case PlantType.PUMP_TANK:
    case PlantType.DRAINAGE_PIT:
    case PlantType.CUSTOM:
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
    case PlantType.VOLUMISER:
    case PlantType.AHU:
    case PlantType.AHU_VENT:
    case PlantType.FCU:
    case PlantType.MANIFOLD:
    case PlantType.UFH:
    case PlantType.FILTER:
    case PlantType.RO:
    case PlantType.DUCT_MANIFOLD:
      return false;
    default:
      assertUnreachable(plant);
  }
  return false;
}

export function isRadiatorPlantEntity(
  entity: DrawableEntityConcrete,
): entity is RadiatorPlantEntity {
  return entity.type === EntityType.PLANT && isRadiatorPlant(entity.plant);
}

export function isManifoldPlant(plant: PlantConcrete): plant is ManifoldPlant {
  return plant.type === PlantType.MANIFOLD;
}

export function isManifoldPlantEntity(
  entity: DrawableEntityConcrete,
): entity is ManifoldPlantEntity {
  return entity.type === EntityType.PLANT && isManifoldPlant(entity.plant);
}

export function plantTypeFromNodeType(
  nodeType: SingleMechanicalNodeProps["type"],
): SingleSystemMechanicalNodeBase["type"];
export function plantTypeFromNodeType(
  nodeType: DualSystemMechanicalNodeProps["type"],
): DualSystemMechanicalNodeBase["type"];
export function plantTypeFromNodeType(
  nodeType:
    | SingleMechanicalNodeProps["type"]
    | DualSystemMechanicalNodeProps["type"],
):
  | SingleSystemMechanicalNodeBase["type"]
  | DualSystemMechanicalNodeBase["type"] {
  switch (nodeType) {
    case "radiator":
      return PlantType.RADIATOR;
    case "air-handling-vent":
      return PlantType.AHU_VENT;
    default:
      assertUnreachable(nodeType);
      throw new Error(`Unknown node type ${nodeType}`);
  }
}

export function isRoomAssociatedPlant(
  plant: PlantConcrete,
): plant is ManifoldPlant | DualSystemPlant {
  return plant.type === PlantType.MANIFOLD || isDualSystemNodePlant(plant);
}

export function isDualSystemNodePlant(
  plant: PlantConcrete,
): plant is DualSystemPlant {
  return (
    plant.type === PlantType.FCU ||
    plant.type === PlantType.AHU ||
    plant.type === PlantType.AHU_VENT
  );
}

export function isDualSystemNode(
  node: MechanicalNodeProps,
): node is DualSystemMechanicalNodeProps {
  return node.type === "air-handling-vent";
}

export function isPlantMVHR(
  plant: PlantConcrete,
): plant is AirHandlingUnitVentPlant {
  return (
    plant.type === PlantType.AHU_VENT &&
    plant.supplyUid !== null &&
    plant.extractUid !== null
  );
}

export function getFilterPlantName(plant: FilterPlant) {
  let config: string = "";
  let type: string = "";

  switch (plant.configuration) {
    case "duty":
      config = "Single Duty";
      break;
    case "duty-standby":
      config = "Duty Standby";
      break;
    case "duty-duty":
      config = "Duty Duty";
      break;
    case null:
      return "Duty";
    default:
      assertUnreachable(plant.configuration);
  }

  switch (plant.filterType) {
    case "softener":
      type = "Water Softener";
      break;
    case "backwash":
      type = "Backwash Filter";
      break;
    case "uv":
      type = "UV Filter";
      break;
    case "cartridge":
      type = "Cartridge Filter";
      break;
    case "backwash-rainwater":
      type = "Backwash Rainwater Reuse Filter";
      break;
    default:
      assertUnreachable(plant);
  }

  return `${type} ${config}`;
}

export function getDatasheetByModel(
  catalog: Catalog,
  plant: FilterPlant,
  model: string,
): FilterData | null;
export function getDatasheetByModel(
  catalog: Catalog,
  plant: ROPlant,
  model: string,
): RoPlantData | null;
export function getDatasheetByModel(
  catalog: Catalog,
  plant: FilterPlant | ROPlant,
  model: string,
): FilterData | RoPlantData | null {
  let data: FilterData[] | RoPlantData[] = [];
  switch (plant.type) {
    case PlantType.FILTER:
      data = getPlantDatasheet(plant, catalog);
      break;
    case PlantType.RO:
      data = getPlantDatasheet(plant, catalog);
      break;
    default:
      assertUnreachable(plant);
  }

  const filtered = data.filter((d) => d.model === model);
  if (filtered.length === 0) {
    return null;
  }

  return filtered[0];
}

export function getBallValveModelOptions(
  context: CoreContext,
  manufacturer: string,
  filter?: (modelObj: BallValveModel) => boolean,
) {
  const data =
    context.catalog.underfloorHeating.ballValve.datasheet[manufacturer];
  if (!data) return [];
  return betterObjectKeys(data).map((model) => ({
    name: String(model),
    key: String(model),
    softDisabled: filter ? !filter(data[model]) : false,
  }));
}

export function getActuatorModelOptions(
  context: CoreContext,
  manufacturer: string,
  filter?: (modelObj: ActuatorModel) => boolean,
) {
  const data =
    context.catalog.underfloorHeating.actuator.datasheet[manufacturer];
  if (!data) return [];
  return betterObjectKeys(data).map((model) => ({
    name: String(model),
    key: String(model),
    softDisabled: filter ? !filter(data[model]) : false,
  }));
}

export function getPumpPackModelOptions(
  context: CoreContext,
  manufacturer: string,
  filter?: (modelObj: PumpPackModel) => boolean,
) {
  const data =
    context.catalog.underfloorHeating.pumpPack.datasheet[manufacturer];
  if (!data) return [];
  return betterObjectKeys(data).map((model) => ({
    name: String(model),
    key: String(model),
    softDisabled: filter ? !filter(data[model]) : false,
  }));
}

export function getUfhPumpModelOptions(
  context: CoreContext,
  pumpPackManufacturer: string,
  pumpPackModel: string,
  filter?: (pump: ComponentId) => boolean,
) {
  const pumpPack =
    context.catalog.underfloorHeating.pumpPack.datasheet[
      pumpPackManufacturer
    ]?.[pumpPackModel];
  if (!pumpPack) return [];
  return pumpPack.pumps.map((pump) => ({
    name: toTitleCase(pump[0]) + " " + pump[1],
    // As long as pump models are unique within a pump pack, we can use the model as the key.
    key: String(pump[1]),
    softDisabled: filter ? !filter(pump) : false,
    // manufacturer field needed for UI to populate the manufacturer prop after
    // the model is selected
    manufacturer: String(pump[0]),
  }));
}

export function getBlendingValveModelOptions(
  context: CoreContext,
  pumpPackManufacturer: string,
  pumpPackModel: string,
  filter?: (modelObj: ComponentId) => boolean,
) {
  const pumpPack =
    context.catalog.underfloorHeating.pumpPack.datasheet[
      pumpPackManufacturer
    ]?.[pumpPackModel];
  if (!pumpPack) return [];
  return pumpPack.blendingValves.map((valve) => ({
    name: toTitleCase(valve[0]) + " " + valve[1],
    // As long as valve models are unique within a pump pack, we can use the model as the key.
    key: String(valve[1]),
    softDisabled: filter ? !filter(valve) : false,
    // manufacturer field needed for UI to populate the manufacturer prop after
    // the model is selected
    manufacturer: String(valve[0]),
  }));
}

export function getManifoldModelOptions(
  context: CoreContext,
  manufacturer: string,
  range: string,
  filter?: (modelObj: ManifoldModel) => boolean,
): Choice<string>[] {
  const data =
    context.catalog.underfloorHeating.manifold.datasheet[manufacturer][range];

  if (!data) return [];

  return betterObjectKeys(data).map((model) => ({
    name: String(model),
    key: String(model),
    softDisabled: filter ? !filter(data[model]) : false,
  }));
}

export function getManifoldRangeOptions(
  context: CoreContext,
  manufacturer: string,
  filter?: (modelObj: ManifoldModel) => boolean,
): Choice<string>[] {
  const data =
    context.catalog.underfloorHeating.manifold.datasheet[manufacturer];

  if (!data) return [];

  return betterObjectKeys(data).map((range) => {
    const models = data[range];
    const include = filter
      ? models && betterObjectValues(models).some(filter)
      : true;

    return {
      name: String(range),
      key: String(range),
      softDisabled: !include,
    };
  });
}

export function getRadiatorRangeTypeOptions(
  context: CoreContext,
  manufacturer: RadiatorManufacturer,
): RadiatorRangeTypeOption[] {
  const catalog = context.catalog;
  const datasheet = catalog.heatEmitters.radiators.datasheet[manufacturer];
  return getRadiatorRangeTypes(datasheet);
}

export function getRadiatorRangeTypes(radiatorData: RadiatorData[]) {
  const rangeTypes = new Set<string>();

  for (const model of radiatorData) {
    rangeTypes.add(model.rangeType);
  }

  return Array.from(rangeTypes).map((rangeType) => ({
    key: rangeType,
    label: rangeType,
  }));
}

export function getHotWaterOutletInfo(
  context: CoreContext,
  coreSystemNode: CoreSystemNode,
): [HotWaterOutlet | null, number] {
  if (coreSystemNode.parent === null) {
    return [null, -1];
  }

  const parent = context.globalStore.get(coreSystemNode.parent.uid);
  if (
    parent?.type !== EntityType.PLANT ||
    parent.entity.plant.type !== PlantType.RETURN_SYSTEM
  ) {
    return [null, -1];
  }

  let index = parent.entity.plant.outlets.findIndex(
    (outlet) => outlet.outletUid === coreSystemNode.uid,
  );
  const outlet = index === -1 ? null : parent.entity.plant.outlets[index];

  if (parent.entity.plant.outlets.length > 1) index++;
  return [outlet, index];
}

export function isHotWaterOutlet(
  context: CoreContext,
  obj: CoreSystemNode,
): boolean {
  const systemNode = context.globalStore.get<CoreSystemNode>(obj.uid);
  const flowSystem = getFlowSystem(
    context.drawing,
    systemNode.entity.systemUid,
  );
  const hotWaterInfo = getHotWaterOutletInfo(context, systemNode);
  return isHeating(flowSystem) && hotWaterInfo[0] !== null;
}

export function getHeatSourceOutletDrawableObjectUidFromNetworkObjectUid(
  context: CoreContext,
  networkObjectUid: string | null,
): string | null {
  if (!networkObjectUid) return null;
  for (const o of context.globalStore.values()) {
    if (o.entity.type === EntityType.SYSTEM_NODE) {
      const systemNode = context.globalStore.get<CoreSystemNode>(o.uid);
      if (isHotWaterOutlet(context, systemNode)) {
        if (o.uid === networkObjectUid) return o.uid;
        if (networkObjectUid === o.getCalculationUid(context)) {
          return o.uid;
        }
      }
    }
  }
  return null;
}

export function isReadonly(drawing: DrawingState, entity: PlantEntity) {
  const catalog = drawing.metadata.catalog;

  switch (entity.plant.type) {
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
      return (
        (catalog.greaseInterceptorTrap![0]
          .manufacturer as GreaseInterceptorTrapManufacturers) !== "generic"
      );
    case PlantType.RETURN_SYSTEM:
    case PlantType.TANK:
    case PlantType.CUSTOM:
    case PlantType.PUMP:
    case PlantType.DRAINAGE_PIT:
    case PlantType.RADIATOR:
    case PlantType.PUMP_TANK:
    case PlantType.VOLUMISER:
    case PlantType.AHU:
    case PlantType.AHU_VENT:
    case PlantType.FCU:
    case PlantType.MANIFOLD:
    case PlantType.UFH:
    case PlantType.FILTER:
    case PlantType.RO:
    case PlantType.DUCT_MANIFOLD:
      return false;
    default:
      assertUnreachable(entity.plant);
  }
}

export function isHotWaterRheem(
  drawing: DrawingState,
  returnType: ReturnSystemPlant["returnType"],
): boolean {
  // rheem is not related to heatSource returnTypes
  if (returnType === "heatSource") {
    return false;
  }
  return (
    drawing.metadata.catalog.hotWaterPlant.find(
      (i) => i.uid === "hotWaterPlant",
    )?.manufacturer === "rheem"
  );
}

export function hasFixedGasRequirements(
  drawing: DrawingState,
  plant: PlantConcrete,
): boolean {
  if (plant.type !== PlantType.RETURN_SYSTEM) {
    return false;
  }
  return isHotWaterRheem(drawing, plant.returnType);
}
