import { findHeatingAreasForRoom } from "../../../../common/src/api/document/drawing";
import { Coord } from "../../../../common/src/lib/coord";
import { findRoomUnderPoint } from "../../../../common/src/lib/globalstore/utils";
import { MainEventBus } from "../../store/main-event-bus";
import CanvasContext from "../lib/canvas-context";
import { ToolHandler } from "../lib/tool";
import PointTool, { PointToolArgs } from "./point-tool";

const SELECT_ROOM_TOOL_NAME = "select-room";

export function isSelectRoomForManifoldTool(
  tool: ToolHandler | null,
): tool is SelectRoomForManifoldTool {
  if (!tool) {
    return false;
  }

  return tool.config.name === SELECT_ROOM_TOOL_NAME;
}

export interface SelectRoomForManifoldToolArgs extends PointToolArgs {
  context: CanvasContext;
  manifoldUid: string;
}

export class SelectRoomForManifoldTool extends PointTool {
  context: CanvasContext;
  manifoldUid: string;

  constructor(args: SelectRoomForManifoldToolArgs) {
    super({ ...args });
    this.context = args.context;
    this.manifoldUid = args.manifoldUid;

    this.onPointChosen = (worldCoord: Coord, _event: MouseEvent) => {
      const room = findRoomUnderPoint(
        this.context.drawing,
        this.context.globalStore,
        this.context.document.uiState.levelUid,
        worldCoord,
      );

      if (!room) {
        return;
      }

      if (room.room.underfloorHeating.manifoldUid === this.manifoldUid) {
        room.room.underfloorHeating.manifoldUid = null;
      } else {
        room.room.underfloorHeating.manifoldUid = this.manifoldUid;
      }

      const heatedAreas = findHeatingAreasForRoom(
        this.context.drawing,
        this.context.document.uiState.levelUid,
        room,
      );

      heatedAreas.forEach((area) => {
        area.underfloorHeating.manifoldUid =
          room.room.underfloorHeating.manifoldUid;
      });

      this.context.$store.dispatch("document/validateAndCommit");
    };
  }
}

export default function selectRoomForManifold(
  context: CanvasContext,
  manifoldUid: string,
) {
  MainEventBus.$emit(
    "set-tool-handler",
    new SelectRoomForManifoldTool({
      context,
      manifoldUid,
      name: SELECT_ROOM_TOOL_NAME,
      getTitleCallback: () => {
        return "Select Rooms for Manifold";
      },
      clickActionName: "Select Room",
      onFinish: (interrupted: boolean, displaced: boolean) => {
        if (interrupted) {
          context.$store.dispatch("document/revert");
          if (!displaced) {
            MainEventBus.$emit("set-tool-handler", null);
          }
        } else {
          selectRoomForManifold(context, manifoldUid);
        }
      },
      onPointChosen: (_wc: Coord, _event: MouseEvent) => {
        // noop, handled by the class
      },
    }),
  );
}
