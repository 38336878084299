import axios from "axios";
import { APIResult, LoginResult } from "../../../common/src/api/document/types";
import { User } from "../../../common/src/models/User";

export async function changePasswords(
  currentPassword: string,
  newPassword: string,
): Promise<APIResult<string>> {
  try {
    return (
      await axios.post("/api/login/password", {
        currentPassword,
        newPassword,
      })
    ).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function logout(): Promise<APIResult<null>> {
  try {
    return (await axios.get("/api/logout")).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function login(
  username: string,
  password: string,
): Promise<APIResult<LoginResult>> {
  try {
    return (await axios.post("/api/login", { username, password })).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function getSession(): Promise<APIResult<User>> {
  try {
    return (await axios.post("/api/session")).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function acceptEula(version: number): Promise<APIResult<null>> {
  try {
    return (await axios.post("/api/acceptEula", { version })).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function declineEula(): Promise<APIResult<null>> {
  try {
    return (await axios.post("/api/declineEula")).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function getLoginStrategy(
  email: string,
): Promise<APIResult<string>> {
  try {
    return (await axios.post("/api/cognito/login-strategy", { email })).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}
