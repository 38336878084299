import { MutationTree } from "vuex";
import FeatureFlagState, { FrontendFeatureFlag } from "./types";

export const mutations: MutationTree<FeatureFlagState> = {
  setClient(state: FeatureFlagState, client: any | null) {
    state.launchDarklyClient = client;
  },

  setFeatureFlag(
    state: FeatureFlagState,
    { flag, value }: { flag: FrontendFeatureFlag; value: any },
  ) {
    state.featureFlags[flag] = value;
  },
};
