import Flatten from "@flatten-js/core";
import { CoreConnectableObjectConcrete, CoreObjectConcrete } from ".";
import { cloneSimple } from "../../lib/utils";
import { GetPressureLossOptions } from "../calculations/entity-pressure-drops";
import {
  CoreContext,
  CostBreakdown,
  PressureLossResult,
} from "../calculations/types";
import { CalculationConcrete } from "../document/calculations-objects/calculation-concrete";
import { NoFlowAvailableReason } from "../document/calculations-objects/conduit-calculations";
import { GasApplianceLiveCalculation } from "../document/calculations-objects/gas-appliance-calculation";
import { CalculatableEntityConcrete } from "../document/entities/concrete-entity";
import { isPipeEntity } from "../document/entities/conduit-entity";
import GasApplianceEntity from "../document/entities/gas-appliance";
import { EntityType } from "../document/entities/types";
import CoreCentered from "./core-traits/coreCentered";
import CoreSystemNode from "./coreSystemNode";
import CoreBaseBackedObject from "./lib/coreBaseBackedObject";
import { getIdentityCalculationEntityUid } from "./utils";

export default class CoreGasAppliance extends CoreCentered(
  CoreBaseBackedObject<GasApplianceEntity>,
) {
  type: EntityType.GAS_APPLIANCE = EntityType.GAS_APPLIANCE;
  getFrictionPressureLossKPA(
    options: GetPressureLossOptions,
  ): PressureLossResult {
    throw new Error("Not applicable to gas appliances");
  }
  getCalculationEntities(context: CoreContext): CalculatableEntityConcrete[] {
    const e: GasApplianceEntity = cloneSimple(this.entity);
    e.uid = this.getCalculationUid(context);
    e.parentUid = getIdentityCalculationEntityUid(context, e.parentUid);
    e.inletUid = this.globalStore
      .get<CoreConnectableObjectConcrete>(e.inletUid)!
      .getCalculationNode(context, this.uid).uid;
    return [e];
  }
  collectCalculations(context: CoreContext): CalculationConcrete {
    return context.globalStore.getOrCreateCalculation(
      this.getCalculationEntities(context)[0],
    );
  }

  collectLiveCalculations(context: CoreContext): GasApplianceLiveCalculation {
    return context.globalStore.getOrCreateLiveCalculation(
      this.getCalculationEntities(context)[0],
    );
  }

  costBreakdown(context: CoreContext): CostBreakdown {
    return { cost: 0, breakdown: [] };
  }
  getCalculationUid(context: CoreContext): string {
    return this.entity.uid + ".calculation";
  }
  preCalculationValidation(context: CoreContext) {
    return null;
  }

  getCoreNeighbours(): CoreObjectConcrete[] {
    const res: CoreObjectConcrete[] = [];
    res.push(
      ...this.globalStore
        .get<CoreSystemNode>(this.entity.inletUid)!
        .getCoreNeighbours(),
    );
    return res;
  }

  validateConnectionPoints(): boolean {
    const connections = this.getCoreNeighbours();
    if (!connections.length) {
      return false;
    }
    for (const conn of connections) {
      const o = this.globalStore.get(conn.uid)!;
      if (o.entity.type === EntityType.CONDUIT) {
        if (isPipeEntity(o.entity)) {
          const calc = this.globalStore.getCalculation(o.entity)!;
          if (calc?.noFlowAvailableReason === NoFlowAvailableReason.NO_SOURCE) {
            return false;
          }
        }
      }
    }
    return true;
  }

  get shape() {
    const p = new Flatten.Polygon();
    // tslint:disable-next-line:one-variable-per-declaration
    let l, t, r, b;
    l = (-this.entity.widthMM * 5) / 8;
    r = (this.entity.widthMM * 5) / 8;
    t = (-this.entity.heightMM * 1) / 8;
    b = (this.entity.heightMM * 8) / 8;

    const tl = this.toWorldCoord({ x: l, y: t });
    const tr = this.toWorldCoord({ x: r, y: t });
    const bl = this.toWorldCoord({ x: l, y: b });
    const br = this.toWorldCoord({ x: r, y: b });
    const tlp = Flatten.point(tl.x, tl.y);
    const trp = Flatten.point(tr.x, tr.y);
    const blp = Flatten.point(bl.x, bl.y);
    const brp = Flatten.point(br.x, br.y);

    p.addFace([
      Flatten.segment(tlp, trp),
      Flatten.segment(trp, brp),
      Flatten.segment(brp, blp),
      Flatten.segment(blp, tlp),
    ]);

    return p;
  }
}
