interface TrieNode {
  children: { [key: string]: TrieNode };
  count: number;
}

export default class Trie {
  root: TrieNode;

  constructor() {
    this.clear();
  }

  add(word: string) {
    let node = this.root;
    for (let i = 0; i < word.length; i++) {
      const char = word[i];
      if (!node.children[char]) {
        node.children[char] = { children: {}, count: 0 };
      }
      node = node.children[char];
    }
    node.count++;
  }

  remove(word: string) {
    let node = this.root;
    for (let i = 0; i < word.length; i++) {
      const char = word[i];
      if (!node.children[char]) {
        return;
      }
      node = node.children[char];
    }
    node.count--;
  }

  // trie.add("abc")
  // trie.add("bcd")
  // trie.add("abz")
  // trie.search("ab") => ["abc", "abz"]
  search(prefix: string) {
    let node = this.root;
    for (let i = 0; i < prefix.length; i++) {
      const char = prefix[i];
      if (!node.children[char]) {
        return [];
      }
      node = node.children[char];
    }
    return this._search(node, prefix);
  }

  _search(node: TrieNode, prefix: string): string[] {
    const words = [];
    for (let i = 0; i < node.count; i++) {
      words.push(prefix);
    }
    for (const char in node.children) {
      words.push(...this._search(node.children[char], prefix + char));
    }
    return words;
  }

  clear() {
    this.root = { children: {}, count: 0 };
  }
}
