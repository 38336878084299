import { PipeConfiguration } from "../../../../common/src/api/calculations/types";
import { FlowConfiguration } from "../../../../common/src/api/document/entities/big-valve/big-valve-entity";
import { DrawableEntityConcrete } from "../../../../common/src/api/document/entities/concrete-entity";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { FlowSystem } from "../../../../common/src/api/document/flow-systems";
import { Coord } from "../../../../common/src/lib/coord";

export interface PipeInteraction extends BaseInteraction {
  type: InteractionType.CONTINUING_CONDUIT | InteractionType.STARTING_CONDUIT;
  system?: FlowSystem;
  configuration?: PipeConfiguration;
}
export interface DuctInteraction extends BaseInteraction {
  type: InteractionType.CONTINUING_CONDUIT | InteractionType.STARTING_CONDUIT;

  //TODO: add duct system parameters
  system?: FlowSystem;
  configuration?: null;
}
export interface InteractionResponseBase {
  success: boolean;
  target: DrawableEntityConcrete;
  associates: DrawableEntityConcrete[];
}

export interface PipeInteractionResponse extends InteractionResponseBase {
  success: true;
}

export interface InsertInteraction extends BaseInteraction {
  type: InteractionType.INSERT;
  entityType: EntityType;
  systemUid: string | null;
}

export interface InsertInteractionResponse extends InteractionResponseBase {}

export interface MovePointToDest extends BaseInteraction {
  type: InteractionType.SNAP_ONTO_RECEIVE;
  src: DrawableEntityConcrete;
}

export interface MovePointToSrc extends BaseInteraction {
  type: InteractionType.SNAP_ONTO_SEND;
  dest: DrawableEntityConcrete;
}

export interface LinkEntity extends BaseInteraction {
  type: InteractionType.LINK_ENTITY;
}

export interface BaseInteraction {
  type: InteractionType;
  worldCoord: Coord;
  worldRadius: number;
  mustConsiderUids?: string[];
}

export interface ExtendNetworkInteraction extends BaseInteraction {
  type: InteractionType.EXTEND_NETWORK;
  systemUid: string | null; // null means any
  configuration: FlowConfiguration;
}

export type Interaction =
  | PipeInteraction
  | InsertInteraction
  | MovePointToDest
  | MovePointToSrc
  | ExtendNetworkInteraction
  | LinkEntity
  | DuctInteraction;

export enum InteractionType {
  STARTING_CONDUIT,
  CONTINUING_CONDUIT,
  INSERT,
  EXTEND_NETWORK,
  SNAP_ONTO_RECEIVE,
  SNAP_ONTO_SEND,
  LINK_ENTITY,
}
