
import {
  SignInOutput,
  autoSignIn,
  confirmSignIn,
  fetchAuthSession,
  signIn,
  signInWithRedirect,
} from "aws-amplify/auth";
import { BFormInput } from "bootstrap-vue";
import { PropType, defineComponent } from "vue";
import { assertUnreachable } from "../../../common/src/lib/utils";
import { emailValidation } from "../../../common/src/models/User.validation";
import { getLoginStrategy } from "../../src/api/logins";
import { CLIENT_METADATA } from "../auth/amplify.config";
import HeroButton from "../components/molecules/HeroButton.vue";

export default defineComponent({
  components: { HeroButton },
  props: {
    next: {
      required: false,
      default: undefined,
      type: Object as PropType<string | undefined>,
    },
  },
  data() {
    const challengeFieldType = "password" as "password" | "text";
    const verificationCode: string = "";
    const challengeInfo: string = "";
    const challengeRes: string = "";
    const strategy: string = "";
    const state = "singIn" as "singIn" | "challenge";
    const isLoading: boolean = false;
    const password: string = "";
    const emailRaw: string = "";

    return {
      emailRaw,
      password,
      isLoading,
      state,
      strategy,
      challengeRes,
      challengeInfo,
      verificationCode,
      challengeFieldName: "",
      challengeFieldType,
    };
  },
  computed: {
    email() {
      return this.emailRaw.trim();
    },
  },
  watch: {
    strategy: [
      {
        handler: "onStrategyChange",
      },
    ],
  },
  async mounted() {
    setTimeout(() => {
      document.getElementById("email-input")?.focus();
    }, 500);
    await this.checkExistingAuth();
    try {
      const res = await autoSignIn();
      this.handleSignInResponse(res);
    } catch (e) {
      // don't really care if it fails (runs after successful sing up)
    }
  },
  methods: {
    async checkExistingAuth() {
      try {
        const a = await fetchAuthSession();
        if (a.tokens?.idToken) {
          this.$router.push("/");
        }
      } catch (e) {
        //noop
      }
    },
    async loginStrategy() {
      const res = await getLoginStrategy(this.email);
      if (res.success) {
        return res.data;
      }
      return "local";
    },
    async handleConfirmSignIn() {
      try {
        this.isLoading = true;
        const res = await confirmSignIn({
          challengeResponse: this.challengeRes,
        });
        this.handleSignInResponse(res);
      } catch (e: any) {
        this.handleError(e);
      } finally {
        this.isLoading = false;
      }
    },
    handleSignInResponse(res: SignInOutput) {
      const { isSignedIn, nextStep } = res;

      if (isSignedIn) {
        return this.$router.push(this.next ?? "/");
      }

      switch (nextStep.signInStep) {
        case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED":
          this.state = "challenge";
          this.challengeInfo = "You are required to create a new password.";
          this.challengeFieldName = "New Password";
          this.challengeFieldType = "password";
          break;
        case "RESET_PASSWORD":
          this.$router.push({
            name: "resetPassword",
            query: { username: this.email },
          });
          break;
        case "CONFIRM_SIGN_IN_WITH_SMS_CODE":
          this.state = "challenge";
          const dest = nextStep.codeDeliveryDetails?.destination;
          this.challengeInfo = `A verification code has been sent to ${dest}`;
          this.challengeFieldName = "Verification Code";
          this.challengeFieldType = "text";
          break;
        case "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE":
        // N/A
        case "CONFIRM_SIGN_IN_WITH_TOTP_CODE":
        // N/A
        case "CONTINUE_SIGN_IN_WITH_TOTP_SETUP":
        // N/A
        case "CONTINUE_SIGN_IN_WITH_MFA_SELECTION":
        // N/A

        case "CONFIRM_SIGN_UP":
        // N/A
        case "DONE":
          // Done!
          break;
        default:
          assertUnreachable(nextStep);
      }
    },
    handleError(e: Error) {
      this.$bvToast.toast(e.message, {
        variant: "warning",
        toaster: " b-toaster-top-center",
        title: "Something is not right",
        noAutoHide: true,
      });
    },
    getValidUsername() {
      const { success: isValidEmail } = emailValidation(this.email);
      return isValidEmail ? this.email.toLowerCase() : this.email;
    },
    async localLogin() {
      try {
        this.isLoading = true;
        const username = this.getValidUsername();
        const res = await signIn({
          username,
          password: this.password,
          options: {
            clientMetadata: CLIENT_METADATA,
          },
        });
        this.handleSignInResponse(res);
      } catch (e: any) {
        this.handleError(e);
      } finally {
        this.isLoading = false;
      }
    },
    async login() {
      if (this.strategy === "local") {
        return this.localLogin();
      }

      this.isLoading = true;
      this.strategy = await this.loginStrategy();

      if (this.strategy !== "local") {
        signInWithRedirect({
          // use diff syntax for social AuthProviders
          provider: { custom: this.strategy },
        });
      }

      this.isLoading = false;
    },
    goToSignUp() {
      this.$router.push({ name: "signUp" });
    },
    goToResetPass() {
      this.$router.push({ name: "resetPassword" });
    },
    onStrategyChange() {
      if (this.strategy === "local") {
        this.$nextTick(() => {
          if (this.$refs.passwordInput instanceof BFormInput) {
            this.$refs.passwordInput.focus();
          }
        });
      }
    },
  },
});
