import {
  assertUnreachable,
  betterObjectKeys,
  betterObjectValues,
} from "../../../../lib/utils";
import {
  Catalog,
  FloorFinish,
  Manufacturer,
  ManufacturerUid,
  UnderfloorHeatingData,
} from "../../../catalog/types";
import {
  ComponentId,
  EdgeExpansionFoamModel,
} from "../../../catalog/underfloor-heating/ufh-types";
import { PipePhysicalMaterial } from "../../../config";
import {
  RoomEntity,
  RoomEntityConcrete,
  isRoomRoomEntity,
} from "./room-entity";

export function getUnderfloorDatasheet(
  catalog: Catalog,
  manufacturer: ManufacturerUid | null,
): UnderfloorHeatingData {
  const manuf = manufacturer ?? "generic";
  return catalog.heatEmitters.ufh.datasheet[manuf];
}

export function getUnderfloorHeatingEdgeExpansionFoams(catalog: Catalog): {
  manufacturer: Manufacturer<"UFH Edge Expansion Foam">;
  model: EdgeExpansionFoamModel;
}[] {
  const record = catalog.underfloorHeating.edgeExpansionFoam;
  return record.manufacturer.flatMap((manuf) => {
    let datasheetElement = record.datasheet[manuf.uid];
    return betterObjectValues(datasheetElement).map((model) => ({
      manufacturer: manuf,
      model,
    }));
  });
}

export function getUnderfloorHeatingEdgeExpansionFoam(
  catalog: Catalog,
  [manufacturer, id]: ComponentId,
): {
  manufacturer: Manufacturer<"UFH Edge Expansion Foam">;
  model: EdgeExpansionFoamModel;
} {
  const record = catalog.underfloorHeating.edgeExpansionFoam;
  const datasheet = record.datasheet[manufacturer];
  return {
    manufacturer: record.manufacturer.find((x) => x.uid === manufacturer)!,
    model: betterObjectValues(datasheet).find((x) => x.model === id)!,
  };
}

export function getUnderfloorHeatingFloorFinish(
  catalog: Catalog,
  manufacturer: ManufacturerUid | null,
): FloorFinish[] {
  const data = getUnderfloorDatasheet(catalog, manufacturer);
  return betterObjectKeys<FloorFinish>(data);
}

export function getUnderFloorPipeMaterials(
  catalog: Catalog,
  manufacturer: ManufacturerUid | null,
  floorFinish: FloorFinish,
): PipePhysicalMaterial[] {
  const data = getUnderfloorDatasheet(catalog, manufacturer);
  const keys = Object.keys(data[floorFinish] ?? {});

  if (keys.length === 0) {
    return [];
  }
  return keys as PipePhysicalMaterial[];
}

export function getUnderFloorPipeDiameters(
  catalog: Catalog,
  manufacturer: ManufacturerUid | null,
  floorFinish: FloorFinish,
  pipeMaterial: PipePhysicalMaterial,
): number[] {
  const data = getUnderfloorDatasheet(catalog, manufacturer);
  const keys = Object.keys(data[floorFinish]?.[pipeMaterial] ?? {});
  if (keys.length === 0) {
    return [];
  }
  return keys.map(Number);
}

export function getUnderFloorLoopSpacing(
  catalog: Catalog,
  manufacturer: ManufacturerUid | null,
): number[] {
  const data = getUnderfloorDatasheet(catalog, manufacturer);

  const ret: Set<number> = new Set();
  for (const a of Object.values(data)) {
    for (const b of Object.values(a)) {
      for (const c of Object.values(b)) {
        for (const d of Object.values(c)) {
          // @ts-ignore fix this later
          for (const e of Object.values(d)) {
            // @ts-ignore fix this later
            for (const f of e) {
              ret.add(f.pipeSpacingMM);
            }
          }
        }
      }
    }
  }

  return Array.from(ret);
}

export function floorTypeToMaterialType(
  floorType: RoomEntityConcrete["floorType"],
): "Bottom Floor" | "Suspended Floor" | "Party Floor" {
  switch (floorType) {
    case "bottom":
    case null:
      return "Bottom Floor";
    case "suspended":
      return "Suspended Floor";
    case "party":
      return "Party Floor";
  }
  assertUnreachable(floorType);
}

export function getRoomFloorMaterialType(room: RoomEntity) {
  if (isRoomRoomEntity(room)) {
    return floorTypeToMaterialType(room.room.floorType);
  }
  return "Bottom Floor";
}
