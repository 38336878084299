import passwordValidator from "password-validator";
import {
  defaultPasswordRequirements,
  PasswordRequirements,
} from "./Organization";

export interface Success {
  success: true;
}

export interface Failure {
  success: false;
  message: string;
}

export type UserValidationResult = Success | Failure;
export type PasswordRequirementValidationResult = Success | Failure;

export interface AggregatedSuccess {
  success: true;
}

export interface AggregatedFailure {
  success: false;
  message: string[];
}

export type AggregatedUserValidationResults =
  | AggregatedSuccess
  | AggregatedFailure;

function usernameValidation(username: string): UserValidationResult {
  const lengthRegExp = /.{3,50}/;
  const noSpecialCharRegExp = /^[A-Za-z0-9]{3,50}$/;

  if (username.length == 0) {
    return {
      success: false,
      message: "Username can't be empty",
    };
  } else if (!lengthRegExp.test(username)) {
    return {
      success: false,
      message:
        "The length of username must be greater than 3 and less than 50 characters",
    };
  } else if (!noSpecialCharRegExp.test(username)) {
    return {
      success: false,
      message: "Username can't contain space or special characters",
    };
  } else {
    return { success: true };
  }
}

export function validatePasswordRequirement(
  requirements: PasswordRequirements,
): PasswordRequirementValidationResult {
  const { minLength, minLowercase, minUppercase, minSpecialChars, minNumbers } =
    defaultPasswordRequirements;

  if (Number(requirements.minLength) < minLength) {
    return {
      success: false,
      message: `Minimum password length cannot be less than ${minLength}`,
    };
  }
  if (Number(requirements.minLowercase) < minLowercase) {
    return {
      success: false,
      message: `Minimum lowercase letters cannot be less than ${minLowercase}`,
    };
  }
  if (Number(requirements.minUppercase) < minUppercase) {
    return {
      success: false,
      message: `Minimum uppercase letters cannot be less than ${minUppercase}`,
    };
  }
  if (Number(requirements.minSpecialChars) < minSpecialChars) {
    return {
      success: false,
      message: `Minimum special characters cannot be less than ${minSpecialChars}`,
    };
  }
  if (Number(requirements.minNumbers) < minNumbers) {
    return {
      success: false,
      message: `Minimum numbers cannot be less than ${minNumbers}`,
    };
  }
  return { success: true };
}

function passwordValidation(
  password: string,
  requirements: PasswordRequirements,
): UserValidationResult {
  const { minLength, minNumbers, minSpecialChars, minLowercase, minUppercase } =
    requirements;
  var validator = new passwordValidator();
  const specialCharRegExp = new RegExp(
    `(?:.*?[-._!"\`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\\\]\^\|].*?){${Number(
      minSpecialChars,
    )},}`,
  );

  validator
    .is()
    .min(
      Number(minLength),
      `The length of the password must be greater than ${minLength}`,
    )
    .is()
    .max(50, "The length of the password must be less than 50")
    .has()
    .digits(
      Number(minNumbers),
      `Password must contain at least ${minNumbers} number`,
    )
    .has()
    .uppercase(
      Number(minUppercase),
      `Password must contain at least ${minUppercase} uppercase letter`,
    )
    .has()
    .lowercase(
      Number(minLowercase),
      `Password must contain at least ${minLowercase} lowercase letter`,
    )
    .has(
      specialCharRegExp,
      `Password must contain at least ${minSpecialChars} special character`,
    );

  const result = validator.validate(password, { details: true });

  if (result && Array.isArray(result) && result.length > 0) {
    return {
      success: false,
      message: result[0].message,
    };
  }

  return { success: true };
}

function emailValidation(email: string): UserValidationResult {
  const EMAIL_REGEX =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+[^<>()\.,;:\s@\"]{2,})$/;
  if (EMAIL_REGEX.test(email)) {
    return { success: true };
  } else {
    return { success: false, message: "Email is invalid" };
  }
}

export function validateUser(
  username: string,
  password: string,
  email: string,
  passwordRequirements: PasswordRequirements,
  noInitialPassword?: boolean,
): AggregatedUserValidationResults {
  let aggregatedResult: AggregatedUserValidationResults;
  let passwordValidationResult: UserValidationResult;

  if (noInitialPassword) {
    //noInitialPassword parameter is only put in when create user.
    passwordValidationResult = { success: true };
  } else {
    passwordValidationResult = passwordValidation(
      password,
      passwordRequirements,
    );
  }

  let res = [
    usernameValidation(username),
    passwordValidationResult,
    emailValidation(email),
  ];

  let resSuccess = res.reduce(
    (previousValue, currentValue) => previousValue && currentValue.success,
    true,
  );
  if (resSuccess === true) {
    aggregatedResult = {
      success: resSuccess,
    };
  } else {
    aggregatedResult = {
      success: resSuccess,
      message: new Array(),
    };
  }

  if (aggregatedResult.success === false) {
    for (var ele of res) {
      if (ele.success === false) {
        aggregatedResult.message.push(ele.message);
      }
    }
  }
  return aggregatedResult;
}

export { emailValidation, passwordValidation, usernameValidation };
