import { bar2kpa } from "../../../../lib/measurements";
import { PumpData, PumpManufacturer } from "../../types";

export const FlowtechBoosterManufacturer: PumpManufacturer = {
  name: "Flowtech",
  abbreviation: "Flowtech",
  uid: "flowtech",
  priceTableName: "Pump",
  componentName: "Flowtech Flowboost",
  displayOnWizard: true,
};

export const FlowtechBoosterPumps: PumpData = {
  type: "dense-table",
  table: {
    duty: {
      [bar2kpa(2)]: {
        "0.5": "1V.E.K.H.3005",
        "0.75": "1V.E.K.H.3005",
        "1": "1V.E.K.H.3005",
        "1.25": "1V.E.K.H.3005",
        "1.5": "1V.E.K.H.5004",
        "1.75": "1V.E.K.H.5004",
        "2": "1V.E.K.H.5004",
        "2.25": "1V.E.K.H.9004",
        "2.5": "1V.E.K.H.9004",
        "2.75": "1V.E.K.H.9004",
        "3": "1V.E.K.H.9004",
      },
      [bar2kpa(2.25)]: {
        "0.5": "1V.E.K.H.3005",
        "0.75": "1V.E.K.H.3005",
        "1": "1V.E.K.H.3005",
        "1.25": "1V.E.K.H.3007",
        "1.5": "1V.E.K.H.5004",
        "1.75": "1V.E.K.H.5004",
        "2": "1V.E.K.H.5005",
        "2.25": "1V.E.K.H.9004",
        "2.5": "1V.E.K.H.9004",
        "2.75": "1V.E.K.H.9004",
        "3": "1V.E.K.H.9004",
      },
      [bar2kpa(2.5)]: {
        "0.5": "1V.E.K.H.3005",
        "0.75": "1V.E.K.H.3005",
        "1": "1V.E.K.H.3005",
        "1.25": "1V.E.K.H.3007",
        "1.5": "1V.E.K.H.5004",
        "1.75": "1V.E.K.H.5004",
        "2": "1V.E.K.H.5005",
        "2.25": "1V.E.K.H.9004",
        "2.5": "1V.E.K.H.9004",
        "2.75": "1V.E.K.H.9004",
        "3": "1V.E.K.H.9004",
      },
      [bar2kpa(2.75)]: {
        "0.5": "1V.E.K.H.3005",
        "0.75": "1V.E.K.H.3005",
        "1": "1V.E.K.H.3005",
        "1.25": "1V.E.K.H.3007",
        "1.5": "1V.E.K.H.5004",
        "1.75": "1V.E.K.H.5004",
        "2": "1V.E.K.H.5006",
        "2.25": "1V.E.K.H.9004",
        "2.5": "1V.E.K.H.9004",
        "2.75": "1V.E.K.H.9004",
        "3": "1V.E.K.H.9004",
      },
      [bar2kpa(3)]: {
        "0.5": "1V.E.K.H.3005",
        "0.75": "1V.E.K.H.3005",
        "1": "1V.E.K.H.3005",
        "1.25": "1V.E.K.H.3007",
        "1.5": "1V.E.K.H.5004",
        "1.75": "1V.E.K.H.5005",
        "2": "1V.E.K.H.5006",
        "2.25": "1V.E.K.H.9004",
        "2.5": "1V.E.K.H.9004",
        "2.75": "1V.E.K.H.9004",
        "3": "1V.E.K.H.9004",
      },
      [bar2kpa(3.25)]: {
        "0.5": "1V.E.K.H.3005",
        "0.75": "1V.E.K.H.3005",
        "1": "1V.E.K.H.3007",
        "1.25": "1V.E.K.H.5005",
        "1.5": "1V.E.K.H.5005",
        "1.75": "1V.E.K.H.5005",
        "2": "1V.E.K.H.5007",
        "2.25": "1V.E.K.H.9004",
        "2.5": "1V.E.K.H.9004",
        "2.75": "1V.E.K.H.9004",
        "3": "1V.E.K.H.9005",
      },
      [bar2kpa(3.5)]: {
        "0.5": "1V.E.K.H.3005",
        "0.75": "1V.E.K.H.3005",
        "1": "1V.E.K.H.3007",
        "1.25": "1V.E.K.H.5005",
        "1.5": "1V.E.K.H.5005",
        "1.75": "1V.E.K.H.5006",
        "2": "1V.E.K.H.5007",
        "2.25": "1V.E.K.H.9004",
        "2.5": "1V.E.K.H.9004",
        "2.75": "1V.E.K.H.9005",
        "3": "1V.E.K.H.9005",
      },
      [bar2kpa(3.75)]: {
        "0.5": "1V.E.K.H.3005",
        "0.75": "1V.E.K.H.3005",
        "1": "1V.E.K.H.3007",
        "1.25": "1V.E.K.H.5005",
        "1.5": "1V.E.K.H.5005",
        "1.75": "1V.E.K.H.5006",
        "2": "1V.E.K.H.9005",
        "2.25": "1V.E.K.H.9004",
        "2.5": "1V.E.K.H.9005",
        "2.75": "1V.E.K.H.9005",
        "3": "1V.E.K.H.9006",
      },
      [bar2kpa(4)]: {
        "0.5": "1V.E.K.H.3005",
        "0.75": "1V.E.K.H.3005",
        "1": "1V.E.K.H.3007",
        "1.25": "1V.E.K.H.5005",
        "1.5": "1V.E.K.H.5006",
        "1.75": "1V.E.K.H.5006",
        "2": "1V.E.K.H.9005",
        "2.25": "1V.E.K.H.9005",
        "2.5": "1V.E.K.H.9005",
        "2.75": "1V.E.K.H.9005",
        "3": "1V.E.K.H.9006",
      },
      [bar2kpa(4.25)]: {
        "0.5": "1V.E.K.H.3005",
        "0.75": "1V.E.K.H.3007",
        "1": "1V.E.K.H.3007",
        "1.25": "1V.E.K.H.5005",
        "1.5": "1V.E.K.H.5006",
        "1.75": "1V.E.K.H.5007",
        "2": "1V.E.K.H.9005",
        "2.25": "1V.E.K.H.9005",
        "2.5": "1V.E.K.H.9005",
        "2.75": "1V.E.K.H.9006",
        "3": "1V.E.K.H.9006",
      },
      [bar2kpa(4.5)]: {
        "0.5": "1V.E.K.H.3005",
        "0.75": "1V.E.K.H.3007",
        "1": "1V.E.K.H.3007",
        "1.25": "1V.E.K.H.5006",
        "1.5": "1V.E.K.H.5006",
        "1.75": "1V.E.K.H.5007",
        "2": "1V.E.K.H.9005",
        "2.25": "1V.E.K.H.9005",
        "2.5": "1V.E.K.H.9006",
        "2.75": "1V.E.K.H.9006",
      },
      [bar2kpa(4.75)]: {
        "0.5": "1V.E.K.H.3007",
        "0.75": "1V.E.K.H.3007",
        "1": "1V.E.K.H.5006",
        "1.25": "1V.E.K.H.5006",
        "1.5": "1V.E.K.H.5006",
        "1.75": "1V.E.K.H.9006",
        "2": "1V.E.K.H.9006",
        "2.25": "1V.E.K.H.9006",
        "2.5": "1V.E.K.H.9006",
      },
      [bar2kpa(5)]: {
        "0.5": "1V.E.K.H.3007",
        "0.75": "1V.E.K.H.3007",
        "1": "1V.E.K.H.5006",
        "1.25": "1V.E.K.H.5006",
        "1.5": "1V.E.K.H.5007",
        "1.75": "1V.E.K.H.9006",
        "2": "1V.E.K.H.9006",
        "2.25": "1V.E.K.H.9006",
        "2.5": "1V.E.K.H.9006",
      },
      [bar2kpa(5.25)]: {
        "0.5": "1V.E.K.H.3007",
        "0.75": "1V.E.K.H.3007",
        "1": "1V.E.K.H.5006",
        "1.25": "1V.E.K.H.5006",
        "1.5": "1V.E.K.H.5007",
        "1.75": "1V.E.K.H.9006",
        "2": "1V.E.K.H.9006",
        "2.25": "1V.E.K.H.9006",
      },
      [bar2kpa(5.5)]: {
        "0.5": "1V.E.K.H.3007",
        "0.75": "1V.E.K.H.3007",
        "1": "1V.E.K.H.5006",
        "1.25": "1V.E.K.H.5007",
        "1.5": "1V.E.K.H.5007",
        "1.75": "1V.E.K.H.9006",
        "2": "1V.E.K.H.9006",
      },
      [bar2kpa(5.75)]: {
        "0.5": "1V.E.K.H.3007",
        "0.75": "1V.E.K.H.3007",
        "1": "1V.E.K.H.5006",
        "1.25": "1V.E.K.H.5007",
      },
      [bar2kpa(6)]: {
        "0.5": "1V.E.K.H.3007",
        "0.75": "1V.E.K.H.5006",
        "1": "1V.E.K.H.5007",
        "1.25": "1V.E.K.H.5007",
      },
      [bar2kpa(6.25)]: {
        "0.5": "1V.E.K.H.3007",
        "0.75": "1V.E.K.H.5007",
        "1": "1V.E.K.H.5007",
      },
      [bar2kpa(6.5)]: {
        "0.5": "1V.E.K.H.3007",
        "0.75": "1V.E.K.H.5007",
        "1": "1V.E.K.H.5007",
      },
      [bar2kpa(6.75)]: {
        "0.5": "1V.E.K.H.3007",
        "0.75": "1V.E.K.H.5007",
      },
    },
    "duty-standby": {
      [bar2kpa(2)]: {
        "0.5": "2V.E.K.H.3005",
        "0.75": "2V.E.K.H.3005",
        "1": "2V.E.K.H.3005",
        "1.25": "2V.E.K.H.3005",
        "1.5": "2V.E.K.H.5004",
        "1.75": "2V.E.K.H.5004",
        "2": "2V.E.K.H.5004",
        "2.25": "2V.E.K.H.9004",
        "2.5": "2V.E.K.H.9004",
        "2.75": "2V.E.K.H.9004",
        "3": "2V.E.K.H.9004",
        "3.5": "2V.E.K.H.9004",
      },
      [bar2kpa(2.25)]: {
        "0.5": "2V.E.K.H.3005",
        "0.75": "2V.E.K.H.3005",
        "1": "2V.E.K.H.3005",
        "1.25": "2V.E.K.H.3007",
        "1.5": "2V.E.K.H.5004",
        "1.75": "2V.E.K.H.5004",
        "2": "2V.E.K.H.5005",
        "2.25": "2V.E.K.H.9004",
        "2.5": "2V.E.K.H.9004",
        "2.75": "2V.E.K.H.9004",
        "3": "2V.E.K.H.9004",
        "3.5": "2V.E.K.H.9005",
      },
      [bar2kpa(2.5)]: {
        "0.5": "2V.E.K.H.3005",
        "0.75": "2V.E.K.H.3005",
        "1": "2V.E.K.H.3005",
        "1.25": "2V.E.K.H.3007",
        "1.5": "2V.E.K.H.5004",
        "1.75": "2V.E.K.H.5004",
        "2": "2V.E.K.H.5005",
        "2.25": "2V.E.K.H.9004",
        "2.5": "2V.E.K.H.9004",
        "2.75": "2V.E.K.H.9004",
        "3": "2V.E.K.H.9004",
        "3.5": "2V.E.K.H.9005",
      },
      [bar2kpa(2.75)]: {
        "0.5": "2V.E.K.H.3005",
        "0.75": "2V.E.K.H.3005",
        "1": "2V.E.K.H.3005",
        "1.25": "2V.E.K.H.3007",
        "1.5": "2V.E.K.H.5004",
        "1.75": "2V.E.K.H.5004",
        "2": "2V.E.K.H.5006",
        "2.25": "2V.E.K.H.9004",
        "2.5": "2V.E.K.H.9004",
        "2.75": "2V.E.K.H.9004",
        "3": "2V.E.K.H.9004",
        "3.5": "2V.E.K.H.9006",
      },
      [bar2kpa(3)]: {
        "0.5": "2V.E.K.H.3005",
        "0.75": "2V.E.K.H.3005",
        "1": "2V.E.K.H.3005",
        "1.25": "2V.E.K.H.3007",
        "1.5": "2V.E.K.H.5004",
        "1.75": "2V.E.K.H.5005",
        "2": "2V.E.K.H.5006",
        "2.25": "2V.E.K.H.9004",
        "2.5": "2V.E.K.H.9004",
        "2.75": "2V.E.K.H.9004",
        "3": "2V.E.K.H.9004",
        "3.5": "2V.E.K.H.9006",
      },
      [bar2kpa(3.25)]: {
        "0.5": "2V.E.K.H.3005",
        "0.75": "2V.E.K.H.3005",
        "1": "2V.E.K.H.3007",
        "1.25": "2V.E.K.H.3009",
        "1.5": "2V.E.K.H.5005",
        "1.75": "2V.E.K.H.5005",
        "2": "2V.E.K.H.5007",
        "2.25": "2V.E.K.H.9004",
        "2.5": "2V.E.K.H.9004",
        "2.75": "2V.E.K.H.9004",
        "3": "2V.E.K.H.9005",
        "3.5": "2V.E.K.H.9008",
      },
      [bar2kpa(3.5)]: {
        "0.5": "2V.E.K.H.3005",
        "0.75": "2V.E.K.H.3005",
        "1": "2V.E.K.H.3007",
        "1.25": "2V.E.K.H.3009",
        "1.5": "2V.E.K.H.5005",
        "1.75": "2V.E.K.H.5006",
        "2": "2V.E.K.H.5007",
        "2.25": "2V.E.K.H.9004",
        "2.5": "2V.E.K.H.9004",
        "2.75": "2V.E.K.H.9005",
        "3": "2V.E.K.H.9005",
        "3.5": "2V.E.K.H.9008",
      },
      [bar2kpa(3.75)]: {
        "0.5": "2V.E.K.H.3005",
        "0.75": "2V.E.K.H.3005",
        "1": "2V.E.K.H.3007",
        "1.25": "2V.E.K.H.3009",
        "1.5": "2V.E.K.H.5005",
        "1.75": "2V.E.K.H.5006",
        "2": "2V.E.K.H.9005",
        "2.25": "2V.E.K.H.9004",
        "2.5": "2V.E.K.H.9005",
        "2.75": "2V.E.K.H.9005",
        "3": "2V.E.K.H.9006",
        "3.5": "2V.E.K.H.9008",
      },
      [bar2kpa(4)]: {
        "0.5": "2V.E.K.H.3005",
        "0.75": "2V.E.K.H.3005",
        "1": "2V.E.K.H.3007",
        "1.25": "2V.E.K.H.3009",
        "1.5": "2V.E.K.H.5006",
        "1.75": "2V.E.K.H.5006",
        "2": "2V.E.K.H.9005",
        "2.25": "2V.E.K.H.9005",
        "2.5": "2V.E.K.H.9005",
        "2.75": "2V.E.K.H.9005",
        "3": "2V.E.K.H.9006",
        "3.5": "2V.E.K.H.9008",
      },
      [bar2kpa(4.25)]: {
        "0.5": "2V.E.K.H.3005",
        "0.75": "2V.E.K.H.3007",
        "1": "2V.E.K.H.3007",
        "1.25": "2V.E.K.H.5005",
        "1.5": "2V.E.K.H.5006",
        "1.75": "2V.E.K.H.5007",
        "2": "2V.E.K.H.9005",
        "2.25": "2V.E.K.H.9005",
        "2.5": "2V.E.K.H.9005",
        "2.75": "2V.E.K.H.9006",
        "3": "2V.E.K.H.9006",
        "3.5": "2V.E.K.H.9008",
      },
      [bar2kpa(4.5)]: {
        "0.5": "2V.E.K.H.3005",
        "0.75": "2V.E.K.H.3007",
        "1": "2V.E.K.H.3007",
        "1.25": "2V.E.K.H.5006",
        "1.5": "2V.E.K.H.5006",
        "1.75": "2V.E.K.H.5007",
        "2": "2V.E.K.H.9005",
        "2.25": "2V.E.K.H.9005",
        "2.5": "2V.E.K.H.9006",
        "2.75": "2V.E.K.H.9006",
        "3": "2V.E.K.H.9008",
        "3.5": "2V.E.K.H.9008",
      },
      [bar2kpa(4.75)]: {
        "0.5": "2V.E.K.H.3007",
        "0.75": "2V.E.K.H.3007",
        "1": "2V.E.K.H.3009",
        "1.25": "2V.E.K.H.5006",
        "1.5": "2V.E.K.H.5006",
        "1.75": "2V.E.K.H.5009",
        "2": "2V.E.K.H.9006",
        "2.25": "2V.E.K.H.9006",
        "2.5": "2V.E.K.H.9006",
        "2.75": "2V.E.K.H.9008",
        "3": "2V.E.K.H.9008",
      },
      [bar2kpa(5)]: {
        "0.5": "2V.E.K.H.3007",
        "0.75": "2V.E.K.H.3007",
        "1": "2V.E.K.H.3009",
        "1.25": "2V.E.K.H.5006",
        "1.5": "2V.E.K.H.5007",
        "1.75": "2V.E.K.H.5009",
        "2": "2V.E.K.H.9006",
        "2.25": "2V.E.K.H.9006",
        "2.5": "2V.E.K.H.9006",
        "2.75": "2V.E.K.H.9008",
        "3": "2V.E.K.H.9008",
      },
      [bar2kpa(5.25)]: {
        "0.5": "2V.E.K.H.3007",
        "0.75": "2V.E.K.H.3007",
        "1": "2V.E.K.H.3009",
        "1.25": "2V.E.K.H.5006",
        "1.5": "2V.E.K.H.5007",
        "1.75": "2V.E.K.H.5009",
        "2": "2V.E.K.H.9006",
        "2.25": "2V.E.K.H.9006",
        "2.5": "2V.E.K.H.9008",
        "2.75": "2V.E.K.H.9008",
        "3": "2V.E.K.H.9008",
      },
      [bar2kpa(5.5)]: {
        "0.5": "2V.E.K.H.3007",
        "0.75": "2V.E.K.H.3007",
        "1": "2V.E.K.H.3009",
        "1.25": "2V.E.K.H.5007",
        "1.5": "2V.E.K.H.5007",
        "1.75": "2V.E.K.H.5009",
        "2": "2V.E.K.H.9006",
        "2.25": "2V.E.K.H.9008",
        "2.5": "2V.E.K.H.9008",
        "2.75": "2V.E.K.H.9008",
        "3": "2V.E.K.H.9008",
      },
      [bar2kpa(5.75)]: {
        "0.5": "2V.E.K.H.3007",
        "0.75": "2V.E.K.H.3007",
        "1": "2V.E.K.H.3009",
        "1.25": "2V.E.K.H.5007",
        "1.5": "2V.E.K.H.5009",
        "1.75": "2V.E.K.H.5009",
        "2": "2V.E.K.H.9008",
        "2.25": "2V.E.K.H.9008",
        "2.5": "2V.E.K.H.9008",
        "2.75": "2V.E.K.H.9008",
        "3": "2V.E.K.H.9008",
      },
      [bar2kpa(6)]: {
        "0.5": "2V.E.K.H.3007",
        "0.75": "2V.E.K.H.3009",
        "1": "2V.E.K.H.3009",
        "1.25": "2V.E.K.H.5007",
        "1.5": "2V.E.K.H.5009",
        "1.75": "2V.E.K.H.5009",
        "2": "2V.E.K.H.9008",
        "2.25": "2V.E.K.H.9008",
        "2.5": "2V.E.K.H.9008",
        "2.75": "2V.E.K.H.9008",
        "3": "2V.E.K.H.9008",
      },
      [bar2kpa(6.25)]: {
        "0.5": "2V.E.K.H.3007",
        "0.75": "2V.E.K.H.3009",
        "1": "2V.E.K.H.5007",
        "1.25": "2V.E.K.H.5009",
        "1.5": "2V.E.K.H.5009",
        "1.75": "2V.E.K.H.9008",
        "2": "2V.E.K.H.9008",
        "2.25": "2V.E.K.H.9008",
        "2.5": "2V.E.K.H.9008",
        "2.75": "2V.E.K.H.9008",
      },
      [bar2kpa(6.5)]: {
        "0.5": "2V.E.K.H.3007",
        "0.75": "2V.E.K.H.3009",
        "1": "2V.E.K.H.5007",
        "1.25": "2V.E.K.H.5009",
        "1.5": "2V.E.K.H.5009",
        "1.75": "2V.E.K.H.9008",
        "2": "2V.E.K.H.9008",
        "2.25": "2V.E.K.H.9008",
        "2.5": "2V.E.K.H.9008",
        "2.75": "2V.E.K.H.9008",
      },
      [bar2kpa(6.75)]: {
        "0.5": "2V.E.K.H.3007",
        "0.75": "2V.E.K.H.3009",
        "1": "2V.E.K.H.5009",
        "1.25": "2V.E.K.H.5009",
        "1.5": "2V.E.K.H.5009",
        "1.75": "2V.E.K.H.9008",
        "2": "2V.E.K.H.9008",
        "2.25": "2V.E.K.H.9008",
        "2.5": "2V.E.K.H.9008",
      },
      [bar2kpa(7)]: {
        "0.5": "2V.E.K.H.3009",
        "0.75": "2V.E.K.H.3009",
        "1": "2V.E.K.H.5009",
        "1.25": "2V.E.K.H.5009",
        "1.5": "2V.E.K.H.5009",
        "1.75": "2V.E.K.H.9008",
        "2": "2V.E.K.H.9008",
        "2.25": "2V.E.K.H.9008",
        "2.5": "2V.E.K.H.9008",
      },
      [bar2kpa(7.25)]: {
        "0.5": "2V.E.K.H.3009",
        "0.75": "2V.E.K.H.3009",
        "1": "2V.E.K.H.5009",
        "1.25": "2V.E.K.H.5009",
        "1.5": "2V.E.K.H.9008",
        "1.75": "2V.E.K.H.9008",
        "2": "2V.E.K.H.9008",
        "2.25": "2V.E.K.H.9008",
      },
      [bar2kpa(7.5)]: {
        "0.5": "2V.E.K.H.3009",
        "0.75": "2V.E.K.H.3009",
        "1": "2V.E.K.H.5009",
        "1.25": "2V.E.K.H.5009",
        "1.5": "2V.E.K.H.9008",
        "1.75": "2V.E.K.H.9008",
        "2": "2V.E.K.H.9008",
        "2.25": "2V.E.K.H.9008",
      },
      [bar2kpa(7.75)]: {
        "0.5": "2V.E.K.H.3009",
        "0.75": "2V.E.K.H.5009",
        "1": "2V.E.K.H.5009",
        "1.25": "2V.E.K.H.5009",
        "1.5": "2V.E.K.H.9008",
        "1.75": "2V.E.K.H.9008",
        "2": "2V.E.K.H.9008",
      },
      [bar2kpa(8)]: {
        "0.5": "2V.E.K.H.3009",
        "0.75": "2V.E.K.H.5009",
        "1": "2V.E.K.H.5009",
        "1.25": "2V.E.K.H.5009",
        "1.5": "2V.E.K.H.9008",
        "1.75": "2V.E.K.H.9008",
      },
    },
    "duty-assist": {
      [bar2kpa(2)]: {
        "0.5": "2V.E.K.H.3005",
        "0.75": "2V.E.K.H.3005",
        "1": "2V.E.K.H.3005",
        "1.25": "2V.E.K.H.3005",
        "1.5": "2V.E.K.H.3005",
        "1.75": "2V.E.K.H.3005",
        "2": "2V.E.K.H.3005",
        "2.25": "2V.E.K.H.3005",
        "2.5": "2V.E.K.H.3005",
        "2.75": "2V.E.K.H.5004",
        "3": "2V.E.K.H.5004",
        "3.5": "2V.E.K.H.5004",
        "4": "2V.E.K.H.5004",
        "4.5": "2V.E.K.H.9004",
        "5": "2V.E.K.H.9004",
        "5.5": "2V.E.K.H.9004",
        "6": "2V.E.K.H.9004",
        "6.5": "2V.E.K.H.9004",
        "7": "2V.E.K.H.9004",
        "7.5": "2V.E.K.H.9005",
      },
      [bar2kpa(2.25)]: {
        "0.5": "2V.E.K.H.3005",
        "0.75": "2V.E.K.H.3005",
        "1": "2V.E.K.H.3005",
        "1.25": "2V.E.K.H.3005",
        "1.5": "2V.E.K.H.3005",
        "1.75": "2V.E.K.H.3005",
        "2": "2V.E.K.H.3005",
        "2.25": "2V.E.K.H.3005",
        "2.5": "2V.E.K.H.3007",
        "2.75": "2V.E.K.H.5004",
        "3": "2V.E.K.H.5004",
        "3.5": "2V.E.K.H.5004",
        "4": "2V.E.K.H.5005",
        "4.5": "2V.E.K.H.9004",
        "5": "2V.E.K.H.9004",
        "5.5": "2V.E.K.H.9004",
        "6": "2V.E.K.H.9004",
        "6.5": "2V.E.K.H.9004",
        "7": "2V.E.K.H.9005",
        "7.5": "2V.E.K.H.9005",
      },
      [bar2kpa(2.5)]: {
        "0.5": "2V.E.K.H.3005",
        "0.75": "2V.E.K.H.3005",
        "1": "2V.E.K.H.3005",
        "1.25": "2V.E.K.H.3005",
        "1.5": "2V.E.K.H.3005",
        "1.75": "2V.E.K.H.3005",
        "2": "2V.E.K.H.3005",
        "2.25": "2V.E.K.H.3005",
        "2.5": "2V.E.K.H.3007",
        "2.75": "2V.E.K.H.5004",
        "3": "2V.E.K.H.5004",
        "3.5": "2V.E.K.H.5004",
        "4": "2V.E.K.H.5005",
        "4.5": "2V.E.K.H.9004",
        "5": "2V.E.K.H.9004",
        "5.5": "2V.E.K.H.9004",
        "6": "2V.E.K.H.9004",
        "6.5": "2V.E.K.H.9004",
        "7": "2V.E.K.H.9005",
        "7.5": "2V.E.K.H.9008",
      },
      [bar2kpa(2.75)]: {
        "0.5": "2V.E.K.H.3005",
        "0.75": "2V.E.K.H.3005",
        "1": "2V.E.K.H.3005",
        "1.25": "2V.E.K.H.3005",
        "1.5": "2V.E.K.H.3005",
        "1.75": "2V.E.K.H.3005",
        "2": "2V.E.K.H.3005",
        "2.25": "2V.E.K.H.3007",
        "2.5": "2V.E.K.H.3007",
        "2.75": "2V.E.K.H.5004",
        "3": "2V.E.K.H.5004",
        "3.5": "2V.E.K.H.5004",
        "4": "2V.E.K.H.5006",
        "4.5": "2V.E.K.H.9004",
        "5": "2V.E.K.H.9004",
        "5.5": "2V.E.K.H.9004",
        "6": "2V.E.K.H.9004",
        "6.5": "2V.E.K.H.9005",
        "7": "2V.E.K.H.9006",
        "7.5": "2V.E.K.H.9008",
      },
      [bar2kpa(3)]: {
        "0.5": "2V.E.K.H.3005",
        "0.75": "2V.E.K.H.3005",
        "1": "2V.E.K.H.3005",
        "1.25": "2V.E.K.H.3005",
        "1.5": "2V.E.K.H.3005",
        "1.75": "2V.E.K.H.3005",
        "2": "2V.E.K.H.3005",
        "2.25": "2V.E.K.H.3007",
        "2.5": "2V.E.K.H.3007",
        "2.75": "2V.E.K.H.5004",
        "3": "2V.E.K.H.5004",
        "3.5": "2V.E.K.H.5005",
        "4": "2V.E.K.H.5006",
        "4.5": "2V.E.K.H.9004",
        "5": "2V.E.K.H.9004",
        "5.5": "2V.E.K.H.9004",
        "6": "2V.E.K.H.9004",
        "6.5": "2V.E.K.H.9005",
        "7": "2V.E.K.H.9006",
        "7.5": "2V.E.K.H.9008",
      },
      [bar2kpa(3.25)]: {
        "0.5": "2V.E.K.H.3005",
        "0.75": "2V.E.K.H.3005",
        "1": "2V.E.K.H.3005",
        "1.25": "2V.E.K.H.3005",
        "1.5": "2V.E.K.H.3005",
        "1.75": "2V.E.K.H.3005",
        "2": "2V.E.K.H.3007",
        "2.25": "2V.E.K.H.3007",
        "2.5": "2V.E.K.H.3009",
        "2.75": "2V.E.K.H.5004",
        "3": "2V.E.K.H.5005",
        "3.5": "2V.E.K.H.5005",
        "4": "2V.E.K.H.5007",
        "4.5": "2V.E.K.H.9004",
        "5": "2V.E.K.H.9004",
        "5.5": "2V.E.K.H.9004",
        "6": "2V.E.K.H.9005",
        "6.5": "2V.E.K.H.9006",
        "7": "2V.E.K.H.9008",
        "7.5": "2V.E.K.H.9008",
      },
      [bar2kpa(3.5)]: {
        "0.5": "2V.E.K.H.3005",
        "0.75": "2V.E.K.H.3005",
        "1": "2V.E.K.H.3005",
        "1.25": "2V.E.K.H.3005",
        "1.5": "2V.E.K.H.3005",
        "1.75": "2V.E.K.H.3005",
        "2": "2V.E.K.H.3007",
        "2.25": "2V.E.K.H.3007",
        "2.5": "2V.E.K.H.3009",
        "2.75": "2V.E.K.H.5005",
        "3": "2V.E.K.H.5005",
        "3.5": "2V.E.K.H.5006",
        "4": "2V.E.K.H.5007",
        "4.5": "2V.E.K.H.9004",
        "5": "2V.E.K.H.9004",
        "5.5": "2V.E.K.H.9005",
        "6": "2V.E.K.H.9005",
        "6.5": "2V.E.K.H.9006",
        "7": "2V.E.K.H.9008",
        "7.5": "2V.E.K.H.9008",
      },
      [bar2kpa(3.75)]: {
        "0.5": "2V.E.K.H.3005",
        "0.75": "2V.E.K.H.3005",
        "1": "2V.E.K.H.3005",
        "1.25": "2V.E.K.H.3005",
        "1.5": "2V.E.K.H.3005",
        "1.75": "2V.E.K.H.3007",
        "2": "2V.E.K.H.3007",
        "2.25": "2V.E.K.H.3007",
        "2.5": "2V.E.K.H.3009",
        "2.75": "2V.E.K.H.5005",
        "3": "2V.E.K.H.5005",
        "3.5": "2V.E.K.H.5006",
        "4": "2V.E.K.H.9005",
        "4.5": "2V.E.K.H.9004",
        "5": "2V.E.K.H.9005",
        "5.5": "2V.E.K.H.9005",
        "6": "2V.E.K.H.9006",
        "6.5": "2V.E.K.H.9008",
        "7": "2V.E.K.H.9008",
        "7.5": "2V.E.K.H.9008",
      },
      [bar2kpa(4)]: {
        "0.5": "2V.E.K.H.3005",
        "0.75": "2V.E.K.H.3005",
        "1": "2V.E.K.H.3005",
        "1.25": "2V.E.K.H.3005",
        "1.5": "2V.E.K.H.3005",
        "1.75": "2V.E.K.H.3007",
        "2": "2V.E.K.H.3007",
        "2.25": "2V.E.K.H.3009",
        "2.5": "2V.E.K.H.3009",
        "2.75": "2V.E.K.H.5005",
        "3": "2V.E.K.H.5006",
        "3.5": "2V.E.K.H.5006",
        "4": "2V.E.K.H.9005",
        "4.5": "2V.E.K.H.9005",
        "5": "2V.E.K.H.9005",
        "5.5": "2V.E.K.H.9005",
        "6": "2V.E.K.H.9006",
        "6.5": "2V.E.K.H.9008",
        "7": "2V.E.K.H.9008",
      },
      [bar2kpa(4.25)]: {
        "0.5": "2V.E.K.H.3005",
        "0.75": "2V.E.K.H.3005",
        "1": "2V.E.K.H.3005",
        "1.25": "2V.E.K.H.3005",
        "1.5": "2V.E.K.H.3007",
        "1.75": "2V.E.K.H.3007",
        "2": "2V.E.K.H.3007",
        "2.25": "2V.E.K.H.3009",
        "2.5": "2V.E.K.H.5005",
        "2.75": "2V.E.K.H.5006",
        "3": "2V.E.K.H.5006",
        "3.5": "2V.E.K.H.5007",
        "4": "2V.E.K.H.9005",
        "4.5": "2V.E.K.H.9005",
        "5": "2V.E.K.H.9005",
        "5.5": "2V.E.K.H.9006",
        "6": "2V.E.K.H.9006",
        "6.5": "2V.E.K.H.9008",
        "7": "2V.E.K.H.9008",
      },
      [bar2kpa(4.5)]: {
        "0.5": "2V.E.K.H.3005",
        "0.75": "2V.E.K.H.3005",
        "1": "2V.E.K.H.3005",
        "1.25": "2V.E.K.H.3007",
        "1.5": "2V.E.K.H.3007",
        "1.75": "2V.E.K.H.3007",
        "2": "2V.E.K.H.3007",
        "2.25": "2V.E.K.H.3009",
        "2.5": "2V.E.K.H.5006",
        "2.75": "2V.E.K.H.5006",
        "3": "2V.E.K.H.5006",
        "3.5": "2V.E.K.H.5007",
        "4": "2V.E.K.H.9005",
        "4.5": "2V.E.K.H.9005",
        "5": "2V.E.K.H.9006",
        "5.5": "2V.E.K.H.9006",
        "6": "2V.E.K.H.9008",
        "6.5": "2V.E.K.H.9008",
        "7": "2V.E.K.H.9008",
      },
      [bar2kpa(4.75)]: {
        "0.5": "2V.E.K.H.3007",
        "0.75": "2V.E.K.H.3007",
        "1": "2V.E.K.H.3007",
        "1.25": "2V.E.K.H.3007",
        "1.5": "2V.E.K.H.3007",
        "1.75": "2V.E.K.H.3007",
        "2": "2V.E.K.H.3009",
        "2.25": "2V.E.K.H.3009",
        "2.5": "2V.E.K.H.5006",
        "2.75": "2V.E.K.H.5006",
        "3": "2V.E.K.H.5006",
        "3.5": "2V.E.K.H.5009",
        "4": "2V.E.K.H.9006",
        "4.5": "2V.E.K.H.9006",
        "5": "2V.E.K.H.9006",
        "5.5": "2V.E.K.H.9008",
        "6": "2V.E.K.H.9008",
        "6.5": "2V.E.K.H.9008",
      },
      [bar2kpa(5)]: {
        "0.5": "2V.E.K.H.3007",
        "0.75": "2V.E.K.H.3007",
        "1": "2V.E.K.H.3007",
        "1.25": "2V.E.K.H.3007",
        "1.5": "2V.E.K.H.3007",
        "1.75": "2V.E.K.H.3007",
        "2": "2V.E.K.H.3009",
        "2.25": "2V.E.K.H.3009",
        "2.5": "2V.E.K.H.5006",
        "2.75": "2V.E.K.H.5006",
        "3": "2V.E.K.H.5007",
        "3.5": "2V.E.K.H.5009",
        "4": "2V.E.K.H.9006",
        "4.5": "2V.E.K.H.9006",
        "5": "2V.E.K.H.9006",
        "5.5": "2V.E.K.H.9008",
        "6": "2V.E.K.H.9008",
        "6.5": "2V.E.K.H.9008",
      },
      [bar2kpa(5.25)]: {
        "0.5": "2V.E.K.H.3007",
        "0.75": "2V.E.K.H.3007",
        "1": "2V.E.K.H.3007",
        "1.25": "2V.E.K.H.3007",
        "1.5": "2V.E.K.H.3007",
        "1.75": "2V.E.K.H.3009",
        "2": "2V.E.K.H.3009",
        "2.25": "2V.E.K.H.5006",
        "2.5": "2V.E.K.H.5006",
        "2.75": "2V.E.K.H.5007",
        "3": "2V.E.K.H.5007",
        "3.5": "2V.E.K.H.5009",
        "4": "2V.E.K.H.9006",
        "4.5": "2V.E.K.H.9006",
        "5": "2V.E.K.H.9008",
        "5.5": "2V.E.K.H.9008",
        "6": "2V.E.K.H.9008",
        "6.5": "2V.E.K.H.9008",
      },
      [bar2kpa(5.5)]: {
        "0.5": "2V.E.K.H.3007",
        "0.75": "2V.E.K.H.3007",
        "1": "2V.E.K.H.3007",
        "1.25": "2V.E.K.H.3007",
        "1.5": "2V.E.K.H.3007",
        "1.75": "2V.E.K.H.3009",
        "2": "2V.E.K.H.3009",
        "2.25": "2V.E.K.H.5006",
        "2.5": "2V.E.K.H.5007",
        "2.75": "2V.E.K.H.5007",
        "3": "2V.E.K.H.5007",
        "3.5": "2V.E.K.H.5009",
        "4": "2V.E.K.H.9006",
        "4.5": "2V.E.K.H.9008",
        "5": "2V.E.K.H.9008",
        "5.5": "2V.E.K.H.9008",
        "6": "2V.E.K.H.9008",
      },
      [bar2kpa(5.75)]: {
        "0.5": "2V.E.K.H.3007",
        "0.75": "2V.E.K.H.3007",
        "1": "2V.E.K.H.3007",
        "1.25": "2V.E.K.H.3007",
        "1.5": "2V.E.K.H.3007",
        "1.75": "2V.E.K.H.3009",
        "2": "2V.E.K.H.3009",
        "2.25": "2V.E.K.H.5007",
        "2.5": "2V.E.K.H.5007",
        "2.75": "2V.E.K.H.5009",
        "3": "2V.E.K.H.5009",
        "3.5": "2V.E.K.H.5009",
        "4": "2V.E.K.H.9008",
        "4.5": "2V.E.K.H.9008",
        "5": "2V.E.K.H.9008",
        "5.5": "2V.E.K.H.9008",
        "6": "2V.E.K.H.9008",
      },
      [bar2kpa(6)]: {
        "0.5": "2V.E.K.H.3007",
        "0.75": "2V.E.K.H.3007",
        "1": "2V.E.K.H.3007",
        "1.25": "2V.E.K.H.3007",
        "1.5": "2V.E.K.H.3009",
        "1.75": "2V.E.K.H.3009",
        "2": "2V.E.K.H.3009",
        "2.25": "2V.E.K.H.5007",
        "2.5": "2V.E.K.H.5007",
        "2.75": "2V.E.K.H.5009",
        "3": "2V.E.K.H.5009",
        "3.5": "2V.E.K.H.5009",
        "4": "2V.E.K.H.9008",
        "4.5": "2V.E.K.H.9008",
        "5": "2V.E.K.H.9008",
        "5.5": "2V.E.K.H.9008",
        "6": "2V.E.K.H.9008",
      },
      [bar2kpa(6.25)]: {
        "0.5": "2V.E.K.H.3007",
        "0.75": "2V.E.K.H.3007",
        "1": "2V.E.K.H.3007",
        "1.25": "2V.E.K.H.3007",
        "1.5": "2V.E.K.H.3009",
        "1.75": "2V.E.K.H.3009",
        "2": "2V.E.K.H.5007",
        "2.25": "2V.E.K.H.5007",
        "2.5": "2V.E.K.H.5009",
        "2.75": "2V.E.K.H.5009",
        "3": "2V.E.K.H.5009",
        "3.5": "2V.E.K.H.9008",
        "4": "2V.E.K.H.9008",
        "4.5": "2V.E.K.H.9008",
        "5": "2V.E.K.H.9008",
        "5.5": "2V.E.K.H.9008",
      },
      [bar2kpa(6.5)]: {
        "0.5": "2V.E.K.H.3007",
        "0.75": "2V.E.K.H.3007",
        "1": "2V.E.K.H.3007",
        "1.25": "2V.E.K.H.3009",
        "1.5": "2V.E.K.H.3009",
        "1.75": "2V.E.K.H.3009",
        "2": "2V.E.K.H.5007",
        "2.25": "2V.E.K.H.5009",
        "2.5": "2V.E.K.H.5009",
        "2.75": "2V.E.K.H.5009",
        "3": "2V.E.K.H.5009",
        "3.5": "2V.E.K.H.9008",
        "4": "2V.E.K.H.9008",
        "4.5": "2V.E.K.H.9008",
        "5": "2V.E.K.H.9008",
        "5.5": "2V.E.K.H.9008",
      },
      [bar2kpa(6.75)]: {
        "0.5": "2V.E.K.H.3007",
        "0.75": "2V.E.K.H.3007",
        "1": "2V.E.K.H.3007",
        "1.25": "2V.E.K.H.3009",
        "1.5": "2V.E.K.H.3009",
        "1.75": "2V.E.K.H.3009",
        "2": "2V.E.K.H.5009",
        "2.25": "2V.E.K.H.5009",
        "2.5": "2V.E.K.H.5009",
        "2.75": "2V.E.K.H.5009",
        "3": "2V.E.K.H.5009",
        "3.5": "2V.E.K.H.9008",
        "4": "2V.E.K.H.9008",
        "4.5": "2V.E.K.H.9008",
        "5": "2V.E.K.H.9008",
      },
      [bar2kpa(7)]: {
        "0.5": "2V.E.K.H.3009",
        "0.75": "2V.E.K.H.3009",
        "1": "2V.E.K.H.3009",
        "1.25": "2V.E.K.H.3009",
        "1.5": "2V.E.K.H.3009",
        "1.75": "2V.E.K.H.5009",
        "2": "2V.E.K.H.5009",
        "2.25": "2V.E.K.H.5009",
        "2.5": "2V.E.K.H.5009",
        "2.75": "2V.E.K.H.5009",
        "3": "2V.E.K.H.5009",
        "3.5": "2V.E.K.H.9008",
        "4": "2V.E.K.H.9008",
        "4.5": "2V.E.K.H.9008",
        "5": "2V.E.K.H.9008",
      },
      [bar2kpa(7.25)]: {
        "0.5": "2V.E.K.H.3009",
        "0.75": "2V.E.K.H.3009",
        "1": "2V.E.K.H.3009",
        "1.25": "2V.E.K.H.3009",
        "1.5": "2V.E.K.H.3009",
        "1.75": "2V.E.K.H.5009",
        "2": "2V.E.K.H.5009",
        "2.25": "2V.E.K.H.5009",
        "2.5": "2V.E.K.H.5009",
        "2.75": "2V.E.K.H.5009",
        "3": "2V.E.K.H.9008",
        "3.5": "2V.E.K.H.9008",
        "4": "2V.E.K.H.9008",
        "4.5": "2V.E.K.H.9008",
      },
      [bar2kpa(7.5)]: {
        "0.5": "2V.E.K.H.3009",
        "0.75": "2V.E.K.H.3009",
        "1": "2V.E.K.H.3009",
        "1.25": "2V.E.K.H.3009",
        "1.5": "2V.E.K.H.3009",
        "1.75": "2V.E.K.H.5009",
        "2": "2V.E.K.H.5009",
        "2.25": "2V.E.K.H.5009",
        "2.5": "2V.E.K.H.5009",
        "2.75": "2V.E.K.H.9008",
        "3": "2V.E.K.H.9008",
        "3.5": "2V.E.K.H.9008",
        "4": "2V.E.K.H.9008",
        "4.5": "2V.E.K.H.9008",
      },
      [bar2kpa(7.75)]: {
        "0.5": "2V.E.K.H.3009",
        "0.75": "2V.E.K.H.3009",
        "1": "2V.E.K.H.3009",
        "1.25": "2V.E.K.H.3009",
        "1.5": "2V.E.K.H.5009",
        "1.75": "2V.E.K.H.5009",
        "2": "2V.E.K.H.5009",
        "2.25": "2V.E.K.H.5009",
        "2.5": "2V.E.K.H.5009",
        "2.75": "2V.E.K.H.9008",
        "3": "2V.E.K.H.9008",
        "3.5": "2V.E.K.H.9008",
        "4": "2V.E.K.H.9008",
      },
      [bar2kpa(8)]: {
        "0.5": "2V.E.K.H.3009",
        "0.75": "2V.E.K.H.3009",
        "1": "2V.E.K.H.3009",
        "1.25": "2V.E.K.H.3009",
        "1.5": "2V.E.K.H.5009",
        "1.75": "2V.E.K.H.5009",
        "2": "2V.E.K.H.5009",
        "2.25": "2V.E.K.H.5009",
        "2.5": "2V.E.K.H.5009",
        "2.75": "2V.E.K.H.9008",
        "3": "2V.E.K.H.9008",
        "3.5": "2V.E.K.H.9008",
      },
    },
    "duty-assist-standby": {
      [bar2kpa(8)]: {
        "0.5": "3V.E.K.H.3009",
        "0.75": "3V.E.K.H.3009",
        "1": "3V.E.K.H.3009",
        "1.25": "3V.E.K.H.3009",
        "1.5": "3V.E.K.H.5009",
        "1.75": "3V.E.K.H.5009",
        "2": "3V.E.K.H.5009",
        "2.25": "3V.E.K.H.5009",
        "2.5": "3V.E.K.H.5009",
        "2.75": "3V.E.K.H.9008",
        "3": "3V.E.K.H.9008",
        "3.5": "3V.E.K.H.9008",
        "4": "3V.E.K.V.1009",
        "4.5": "3V.E.K.V.1011",
        "5": "3V.E.K.V.1011",
        "5.5": "3V.E.K.V.1011",
        "6": "3V.E.K.V.1011",
        "6.5": "3V.E.K.V.1013",
        "7": "3V.E.K.V.1507",
        "7.5": "3V.E.K.V.1507",
        "8": "3V.E.K.V.1507",
        "8.5": "3V.E.K.V.1509",
        "9": "3V.E.K.V.1509",
        "9.5": "3V.E.K.V.1509",
        "10": "3V.E.K.V.1509",
        "11": "3V.E.K.V.1510",
        "12": "3V.E.K.V.1510",
      },
      [bar2kpa(7.75)]: {
        "0.5": "3V.E.K.H.3009",
        "0.75": "3V.E.K.H.3009",
        "1": "3V.E.K.H.3009",
        "1.25": "3V.E.K.H.3009",
        "1.5": "3V.E.K.H.5009",
        "1.75": "3V.E.K.H.5009",
        "2": "3V.E.K.H.5009",
        "2.25": "3V.E.K.H.5009",
        "2.5": "3V.E.K.H.5009",
        "2.75": "3V.E.K.H.9008",
        "3": "3V.E.K.H.9008",
        "3.5": "3V.E.K.H.9008",
        "4": "3V.E.K.H.9008",
        "4.5": "3V.E.K.V.1009",
        "5": "3V.E.K.V.1011",
        "5.5": "3V.E.K.V.1011",
        "6": "3V.E.K.V.1011",
        "6.5": "3V.E.K.V.1013",
        "7": "3V.E.K.V.1507",
        "7.5": "3V.E.K.V.1507",
        "8": "3V.E.K.V.1507",
        "8.5": "3V.E.K.V.1507",
        "9": "3V.E.K.V.1509",
        "9.5": "3V.E.K.V.1509",
        "10": "3V.E.K.V.1509",
        "11": "3V.E.K.V.1509",
        "12": "3V.E.K.V.1510",
      },
      [bar2kpa(7.5)]: {
        "0.5": "3V.E.K.H.3009",
        "0.75": "3V.E.K.H.3009",
        "1": "3V.E.K.H.3009",
        "1.25": "3V.E.K.H.3009",
        "1.5": "3V.E.K.H.3009",
        "1.75": "3V.E.K.H.5009",
        "2": "3V.E.K.H.5009",
        "2.25": "3V.E.K.H.5009",
        "2.5": "3V.E.K.H.5009",
        "2.75": "3V.E.K.H.9008",
        "3": "3V.E.K.H.9008",
        "3.5": "3V.E.K.H.9008",
        "4": "3V.E.K.H.9008",
        "4.5": "3V.E.K.H.9008",
        "5": "3V.E.K.V.1011",
        "5.5": "3V.E.K.V.1011",
        "6": "3V.E.K.V.1011",
        "6.5": "3V.E.K.V.1011",
        "7": "3V.E.K.V.1013",
        "7.5": "3V.E.K.V.1507",
        "8": "3V.E.K.V.1507",
        "8.5": "3V.E.K.V.1507",
        "9": "3V.E.K.V.1507",
        "9.5": "3V.E.K.V.1509",
        "10": "3V.E.K.V.1509",
        "11": "3V.E.K.V.1509",
        "12": "3V.E.K.V.1510",
      },
      [bar2kpa(7.25)]: {
        "0.5": "3V.E.K.H.3009",
        "0.75": "3V.E.K.H.3009",
        "1": "3V.E.K.H.3009",
        "1.25": "3V.E.K.H.3009",
        "1.5": "3V.E.K.H.3009",
        "1.75": "3V.E.K.H.5009",
        "2": "3V.E.K.H.5009",
        "2.25": "3V.E.K.H.5009",
        "2.5": "3V.E.K.H.5009",
        "2.75": "3V.E.K.H.9008",
        "3": "3V.E.K.H.9008",
        "3.5": "3V.E.K.H.9008",
        "4": "3V.E.K.H.9008",
        "4.5": "3V.E.K.H.9008",
        "5": "3V.E.K.H.9008",
        "5.5": "3V.E.K.V.1011",
        "6": "3V.E.K.V.1011",
        "6.5": "3V.E.K.V.1011",
        "7": "3V.E.K.V.1013",
        "7.5": "3V.E.K.V.1507",
        "8": "3V.E.K.V.1507",
        "8.5": "3V.E.K.V.1507",
        "9": "3V.E.K.V.1507",
        "9.5": "3V.E.K.V.1509",
        "10": "3V.E.K.V.1509",
        "11": "3V.E.K.V.1509",
        "12": "3V.E.K.V.1510",
      },
      [bar2kpa(7)]: {
        "0.5": "3V.E.K.H.3009",
        "0.75": "3V.E.K.H.3009",
        "1": "3V.E.K.H.3009",
        "1.25": "3V.E.K.H.3009",
        "1.5": "3V.E.K.H.3009",
        "1.75": "3V.E.K.H.5009",
        "2": "3V.E.K.H.5009",
        "2.25": "3V.E.K.H.5009",
        "2.5": "3V.E.K.H.5009",
        "2.75": "3V.E.K.H.5009",
        "3": "3V.E.K.H.5009",
        "3.5": "3V.E.K.H.9008",
        "4": "3V.E.K.H.9008",
        "4.5": "3V.E.K.H.9008",
        "5": "3V.E.K.H.9008",
        "5.5": "3V.E.K.V.1009",
        "6": "3V.E.K.V.1011",
        "6.5": "3V.E.K.V.1011",
        "7": "3V.E.K.V.1013",
        "7.5": "3V.E.K.V.1507",
        "8": "3V.E.K.V.1507",
        "8.5": "3V.E.K.V.1507",
        "9": "3V.E.K.V.1507",
        "9.5": "3V.E.K.V.1507",
        "10": "3V.E.K.V.1509",
        "11": "3V.E.K.V.1509",
        "12": "3V.E.K.V.1510",
      },
      [bar2kpa(6.25)]: {
        "0.5": "3V.E.K.H.3007",
        "0.75": "3V.E.K.H.3007",
        "1": "3V.E.K.H.3007",
        "1.25": "3V.E.K.H.3007",
        "1.5": "3V.E.K.H.3009",
        "1.75": "3V.E.K.H.3009",
        "2": "3V.E.K.H.5007",
        "2.25": "3V.E.K.H.5007",
        "2.5": "3V.E.K.H.5009",
        "2.75": "3V.E.K.H.5009",
        "3": "3V.E.K.H.5009",
        "3.5": "3V.E.K.H.9008",
        "4": "3V.E.K.H.9008",
        "4.5": "3V.E.K.H.9008",
        "5": "3V.E.K.H.9008",
        "5.5": "3V.E.K.H.9008",
        "6": "3V.E.K.V.1009",
        "6.5": "3V.E.K.V.1011",
        "7": "3V.E.K.V.1011",
        "7.5": "3V.E.K.V.1013",
        "8": "3V.E.K.V.1507",
        "8.5": "3V.E.K.V.1507",
        "9": "3V.E.K.V.1507",
        "9.5": "3V.E.K.V.1507",
        "10": "3V.E.K.V.1507",
        "11": "3V.E.K.V.1509",
        "12": "3V.E.K.V.1509",
      },
      [bar2kpa(6.5)]: {
        "0.5": "3V.E.K.H.3007",
        "0.75": "3V.E.K.H.3007",
        "1": "3V.E.K.H.3007",
        "1.25": "3V.E.K.H.3009",
        "1.5": "3V.E.K.H.3009",
        "1.75": "3V.E.K.H.3009",
        "2": "3V.E.K.H.5007",
        "2.25": "3V.E.K.H.5009",
        "2.5": "3V.E.K.H.5009",
        "2.75": "3V.E.K.H.5009",
        "3": "3V.E.K.H.5009",
        "3.5": "3V.E.K.H.9008",
        "4": "3V.E.K.H.9008",
        "4.5": "3V.E.K.H.9008",
        "5": "3V.E.K.H.9008",
        "5.5": "3V.E.K.H.9008",
        "6": "3V.E.K.V.1009",
        "6.5": "3V.E.K.V.1011",
        "7": "3V.E.K.V.1011",
        "7.5": "3V.E.K.V.1013",
        "8": "3V.E.K.V.1507",
        "8.5": "3V.E.K.V.1507",
        "9": "3V.E.K.V.1507",
        "9.5": "3V.E.K.V.1507",
        "10": "3V.E.K.V.1507",
        "11": "3V.E.K.V.1509",
        "12": "3V.E.K.V.1509",
      },
      [bar2kpa(6.75)]: {
        "0.5": "3V.E.K.H.3007",
        "0.75": "3V.E.K.H.3007",
        "1": "3V.E.K.H.3007",
        "1.25": "3V.E.K.H.3009",
        "1.5": "3V.E.K.H.3009",
        "1.75": "3V.E.K.H.3009",
        "2": "3V.E.K.H.5009",
        "2.25": "3V.E.K.H.5009",
        "2.5": "3V.E.K.H.5009",
        "2.75": "3V.E.K.H.5009",
        "3": "3V.E.K.H.5009",
        "3.5": "3V.E.K.H.9008",
        "4": "3V.E.K.H.9008",
        "4.5": "3V.E.K.H.9008",
        "5": "3V.E.K.H.9008",
        "5.5": "3V.E.K.H.9008",
        "6": "3V.E.K.V.1011",
        "6.5": "3V.E.K.V.1011",
        "7": "3V.E.K.V.1013",
        "7.5": "3V.E.K.V.1507",
        "8": "3V.E.K.V.1507",
        "8.5": "3V.E.K.V.1507",
        "9": "3V.E.K.V.1507",
        "9.5": "3V.E.K.V.1507",
        "10": "3V.E.K.V.1507",
        "11": "3V.E.K.V.1509",
        "12": "3V.E.K.V.1509",
      },
      [bar2kpa(2)]: {
        "0.5": "3V.E.K.H.3005",
        "0.75": "3V.E.K.H.3005",
        "1": "3V.E.K.H.3005",
        "1.25": "3V.E.K.H.3005",
        "1.5": "3V.E.K.H.3005",
        "1.75": "3V.E.K.H.3005",
        "2": "3V.E.K.H.3005",
        "2.25": "3V.E.K.H.3005",
        "2.5": "3V.E.K.H.3005",
        "2.75": "3V.E.K.H.5004",
        "3": "3V.E.K.H.5004",
        "3.5": "3V.E.K.H.5004",
        "4": "3V.E.K.H.5004",
        "4.5": "3V.E.K.H.9004",
        "5": "3V.E.K.H.9004",
        "5.5": "3V.E.K.H.9004",
        "6": "3V.E.K.H.9004",
        "6.5": "3V.E.K.H.9004",
        "7": "3V.E.K.H.9004",
        "7.5": "3V.E.K.H.9005",
        "8": "3V.E.K.V.1505",
        "8.5": "3V.E.K.V.1505",
        "9": "3V.E.K.V.1505",
        "9.5": "3V.E.K.V.1505",
        "10": "3V.E.K.V.1505",
        "11": "3V.E.K.V.1505",
        "12": "3V.E.K.V.1505",
      },
      [bar2kpa(2.25)]: {
        "0.5": "3V.E.K.H.3005",
        "0.75": "3V.E.K.H.3005",
        "1": "3V.E.K.H.3005",
        "1.25": "3V.E.K.H.3005",
        "1.5": "3V.E.K.H.3005",
        "1.75": "3V.E.K.H.3005",
        "2": "3V.E.K.H.3005",
        "2.25": "3V.E.K.H.3005",
        "2.5": "3V.E.K.H.3007",
        "2.75": "3V.E.K.H.5004",
        "3": "3V.E.K.H.5004",
        "3.5": "3V.E.K.H.5004",
        "4": "3V.E.K.H.5005",
        "4.5": "3V.E.K.H.9004",
        "5": "3V.E.K.H.9004",
        "5.5": "3V.E.K.H.9004",
        "6": "3V.E.K.H.9004",
        "6.5": "3V.E.K.H.9004",
        "7": "3V.E.K.H.9005",
        "7.5": "3V.E.K.H.9006",
        "8": "3V.E.K.V.1505",
        "8.5": "3V.E.K.V.1505",
        "9": "3V.E.K.V.1505",
        "9.5": "3V.E.K.V.1505",
        "10": "3V.E.K.V.1505",
        "11": "3V.E.K.V.1505",
        "12": "3V.E.K.V.1505",
      },
      [bar2kpa(2.5)]: {
        "0.5": "3V.E.K.H.3005",
        "0.75": "3V.E.K.H.3005",
        "1": "3V.E.K.H.3005",
        "1.25": "3V.E.K.H.3005",
        "1.5": "3V.E.K.H.3005",
        "1.75": "3V.E.K.H.3005",
        "2": "3V.E.K.H.3005",
        "2.25": "3V.E.K.H.3005",
        "2.5": "3V.E.K.H.3007",
        "2.75": "3V.E.K.H.5004",
        "3": "3V.E.K.H.5004",
        "3.5": "3V.E.K.H.5004",
        "4": "3V.E.K.H.5005",
        "4.5": "3V.E.K.H.9004",
        "5": "3V.E.K.H.9004",
        "5.5": "3V.E.K.H.9004",
        "6": "3V.E.K.H.9004",
        "6.5": "3V.E.K.H.9004",
        "7": "3V.E.K.H.9005",
        "7.5": "3V.E.K.H.9008",
        "8": "3V.E.K.V.1505",
        "8.5": "3V.E.K.V.1505",
        "9": "3V.E.K.V.1505",
        "9.5": "3V.E.K.V.1505",
        "10": "3V.E.K.V.1505",
        "11": "3V.E.K.V.1505",
        "12": "3V.E.K.V.1505",
      },
      [bar2kpa(2.75)]: {
        "0.5": "3V.E.K.H.3005",
        "0.75": "3V.E.K.H.3005",
        "1": "3V.E.K.H.3005",
        "1.25": "3V.E.K.H.3005",
        "1.5": "3V.E.K.H.3005",
        "1.75": "3V.E.K.H.3005",
        "2": "3V.E.K.H.3005",
        "2.25": "3V.E.K.H.3007",
        "2.5": "3V.E.K.H.3007",
        "2.75": "3V.E.K.H.5004",
        "3": "3V.E.K.H.5004",
        "3.5": "3V.E.K.H.5004",
        "4": "3V.E.K.H.5006",
        "4.5": "3V.E.K.H.9004",
        "5": "3V.E.K.H.9004",
        "5.5": "3V.E.K.H.9004",
        "6": "3V.E.K.H.9004",
        "6.5": "3V.E.K.H.9005",
        "7": "3V.E.K.H.9006",
        "7.5": "3V.E.K.H.9008",
        "8": "3V.E.K.V.1505",
        "8.5": "3V.E.K.V.1505",
        "9": "3V.E.K.V.1505",
        "9.5": "3V.E.K.V.1505",
        "10": "3V.E.K.V.1505",
        "11": "3V.E.K.V.1505",
        "12": "3V.E.K.V.1505",
      },
      [bar2kpa(3)]: {
        "0.5": "3V.E.K.H.3005",
        "0.75": "3V.E.K.H.3005",
        "1": "3V.E.K.H.3005",
        "1.25": "3V.E.K.H.3005",
        "1.5": "3V.E.K.H.3005",
        "1.75": "3V.E.K.H.3005",
        "2": "3V.E.K.H.3005",
        "2.25": "3V.E.K.H.3007",
        "2.5": "3V.E.K.H.3007",
        "2.75": "3V.E.K.H.5004",
        "3": "3V.E.K.H.5004",
        "3.5": "3V.E.K.H.5005",
        "4": "3V.E.K.H.5006",
        "4.5": "3V.E.K.H.9004",
        "5": "3V.E.K.H.9004",
        "5.5": "3V.E.K.H.9004",
        "6": "3V.E.K.H.9004",
        "6.5": "3V.E.K.H.9005",
        "7": "3V.E.K.H.9006",
        "7.5": "3V.E.K.H.9008",
        "8": "3V.E.K.V.1505",
        "8.5": "3V.E.K.V.1505",
        "9": "3V.E.K.V.1505",
        "9.5": "3V.E.K.V.1505",
        "10": "3V.E.K.V.1505",
        "11": "3V.E.K.V.1505",
        "12": "3V.E.K.V.1505",
      },
      [bar2kpa(3.25)]: {
        "0.5": "3V.E.K.H.3005",
        "0.75": "3V.E.K.H.3005",
        "1": "3V.E.K.H.3005",
        "1.25": "3V.E.K.H.3005",
        "1.5": "3V.E.K.H.3005",
        "1.75": "3V.E.K.H.3005",
        "2": "3V.E.K.H.3007",
        "2.25": "3V.E.K.H.3007",
        "2.5": "3V.E.K.H.3009",
        "2.75": "3V.E.K.H.5004",
        "3": "3V.E.K.H.5005",
        "3.5": "3V.E.K.H.5005",
        "4": "3V.E.K.H.5007",
        "4.5": "3V.E.K.H.9004",
        "5": "3V.E.K.H.9004",
        "5.5": "3V.E.K.H.9004",
        "6": "3V.E.K.H.9005",
        "6.5": "3V.E.K.H.9006",
        "7": "3V.E.K.H.9008",
        "7.5": "3V.E.K.H.9008",
        "8": "3V.E.K.V.1505",
        "8.5": "3V.E.K.V.1505",
        "9": "3V.E.K.V.1505",
        "9.5": "3V.E.K.V.1505",
        "10": "3V.E.K.V.1505",
        "11": "3V.E.K.V.1505",
        "12": "3V.E.K.V.1505",
      },
      [bar2kpa(3.5)]: {
        "0.5": "3V.E.K.H.3005",
        "0.75": "3V.E.K.H.3005",
        "1": "3V.E.K.H.3005",
        "1.25": "3V.E.K.H.3005",
        "1.5": "3V.E.K.H.3005",
        "1.75": "3V.E.K.H.3005",
        "2": "3V.E.K.H.3007",
        "2.25": "3V.E.K.H.3007",
        "2.5": "3V.E.K.H.3009",
        "2.75": "3V.E.K.H.5005",
        "3": "3V.E.K.H.5005",
        "3.5": "3V.E.K.H.5006",
        "4": "3V.E.K.H.5007",
        "4.5": "3V.E.K.H.9004",
        "5": "3V.E.K.H.9004",
        "5.5": "3V.E.K.H.9005",
        "6": "3V.E.K.H.9005",
        "6.5": "3V.E.K.H.9006",
        "7": "3V.E.K.H.9008",
        "7.5": "3V.E.K.H.9008",
        "8": "3V.E.K.V.1505",
        "8.5": "3V.E.K.V.1505",
        "9": "3V.E.K.V.1505",
        "9.5": "3V.E.K.V.1505",
        "10": "3V.E.K.V.1505",
        "11": "3V.E.K.V.1505",
        "12": "3V.E.K.V.1505",
      },
      [bar2kpa(3.75)]: {
        "0.5": "3V.E.K.H.3005",
        "0.75": "3V.E.K.H.3005",
        "1": "3V.E.K.H.3005",
        "1.25": "3V.E.K.H.3005",
        "1.5": "3V.E.K.H.3005",
        "1.75": "3V.E.K.H.3007",
        "2": "3V.E.K.H.3007",
        "2.25": "3V.E.K.H.3007",
        "2.5": "3V.E.K.H.3009",
        "2.75": "3V.E.K.H.5005",
        "3": "3V.E.K.H.5005",
        "3.5": "3V.E.K.H.5006",
        "4": "3V.E.K.H.5009",
        "4.5": "3V.E.K.H.9004",
        "5": "3V.E.K.H.9005",
        "5.5": "3V.E.K.H.9005",
        "6": "3V.E.K.H.9006",
        "6.5": "3V.E.K.H.9008",
        "7": "3V.E.K.H.9008",
        "7.5": "3V.E.K.H.9008",
        "8": "3V.E.K.V.1505",
        "8.5": "3V.E.K.V.1505",
        "9": "3V.E.K.V.1505",
        "9.5": "3V.E.K.V.1505",
        "10": "3V.E.K.V.1505",
        "11": "3V.E.K.V.1505",
        "12": "3V.E.K.V.1507",
      },
      [bar2kpa(4)]: {
        "0.5": "3V.E.K.H.3005",
        "0.75": "3V.E.K.H.3005",
        "1": "3V.E.K.H.3005",
        "1.25": "3V.E.K.H.3005",
        "1.5": "3V.E.K.H.3005",
        "1.75": "3V.E.K.H.3007",
        "2": "3V.E.K.H.3007",
        "2.25": "3V.E.K.H.3009",
        "2.5": "3V.E.K.H.3009",
        "2.75": "3V.E.K.H.5005",
        "3": "3V.E.K.H.5006",
        "3.5": "3V.E.K.H.5006",
        "4": "3V.E.K.H.5009",
        "4.5": "3V.E.K.H.9005",
        "5": "3V.E.K.H.9005",
        "5.5": "3V.E.K.H.9005",
        "6": "3V.E.K.H.9006",
        "6.5": "3V.E.K.H.9008",
        "7": "3V.E.K.H.9008",
        "7.5": "3V.E.K.V.1009",
        "8": "3V.E.K.V.1505",
        "8.5": "3V.E.K.V.1505",
        "9": "3V.E.K.V.1505",
        "9.5": "3V.E.K.V.1505",
        "10": "3V.E.K.V.1505",
        "11": "3V.E.K.V.1505",
        "12": "3V.E.K.V.1507",
      },
      [bar2kpa(4.25)]: {
        "0.5": "3V.E.K.H.3005",
        "0.75": "3V.E.K.H.3005",
        "1": "3V.E.K.H.3005",
        "1.25": "3V.E.K.H.3005",
        "1.5": "3V.E.K.H.3007",
        "1.75": "3V.E.K.H.3007",
        "2": "3V.E.K.H.3007",
        "2.25": "3V.E.K.H.3009",
        "2.5": "3V.E.K.H.5005",
        "2.75": "3V.E.K.H.5006",
        "3": "3V.E.K.H.5006",
        "3.5": "3V.E.K.H.5007",
        "4": "3V.E.K.H.5009",
        "4.5": "3V.E.K.H.9005",
        "5": "3V.E.K.H.9005",
        "5.5": "3V.E.K.H.9006",
        "6": "3V.E.K.H.9006",
        "6.5": "3V.E.K.H.9008",
        "7": "3V.E.K.H.9008",
        "7.5": "3V.E.K.V.1009",
        "8": "3V.E.K.V.1505",
        "8.5": "3V.E.K.V.1505",
        "9": "3V.E.K.V.1505",
        "9.5": "3V.E.K.V.1505",
        "10": "3V.E.K.V.1505",
        "11": "3V.E.K.V.1505",
        "12": "3V.E.K.V.1507",
      },
      [bar2kpa(4.5)]: {
        "0.5": "3V.E.K.H.3005",
        "0.75": "3V.E.K.H.3005",
        "1": "3V.E.K.H.3005",
        "1.25": "3V.E.K.H.3007",
        "1.5": "3V.E.K.H.3007",
        "1.75": "3V.E.K.H.3007",
        "2": "3V.E.K.H.3007",
        "2.25": "3V.E.K.H.3009",
        "2.5": "3V.E.K.H.5006",
        "2.75": "3V.E.K.H.5006",
        "3": "3V.E.K.H.5006",
        "3.5": "3V.E.K.H.5007",
        "4": "3V.E.K.H.5009",
        "4.5": "3V.E.K.H.9005",
        "5": "3V.E.K.H.9006",
        "5.5": "3V.E.K.H.9006",
        "6": "3V.E.K.H.9008",
        "6.5": "3V.E.K.H.9008",
        "7": "3V.E.K.H.9008",
        "7.5": "3V.E.K.V.1009",
        "8": "3V.E.K.V.1505",
        "8.5": "3V.E.K.V.1505",
        "9": "3V.E.K.V.1505",
        "9.5": "3V.E.K.V.1505",
        "10": "3V.E.K.V.1505",
        "11": "3V.E.K.V.1507",
        "12": "3V.E.K.V.1507",
      },
      [bar2kpa(4.75)]: {
        "0.5": "3V.E.K.H.3007",
        "0.75": "3V.E.K.H.3007",
        "1": "3V.E.K.H.3007",
        "1.25": "3V.E.K.H.3007",
        "1.5": "3V.E.K.H.3007",
        "1.75": "3V.E.K.H.3007",
        "2": "3V.E.K.H.3009",
        "2.25": "3V.E.K.H.3009",
        "2.5": "3V.E.K.H.5006",
        "2.75": "3V.E.K.H.5006",
        "3": "3V.E.K.H.5006",
        "3.5": "3V.E.K.H.5009",
        "4": "3V.E.K.H.9005",
        "4.5": "3V.E.K.H.9006",
        "5": "3V.E.K.H.9006",
        "5.5": "3V.E.K.H.9008",
        "6": "3V.E.K.H.9008",
        "6.5": "3V.E.K.H.9008",
        "7": "3V.E.K.V.1009",
        "7.5": "3V.E.K.V.1011",
        "8": "3V.E.K.V.1505",
        "8.5": "3V.E.K.V.1505",
        "9": "3V.E.K.V.1505",
        "9.5": "3V.E.K.V.1505",
        "10": "3V.E.K.V.1507",
        "11": "3V.E.K.V.1507",
        "12": "3V.E.K.V.1507",
      },
      [bar2kpa(5)]: {
        "0.5": "3V.E.K.H.3007",
        "0.75": "3V.E.K.H.3007",
        "1": "3V.E.K.H.3007",
        "1.25": "3V.E.K.H.3007",
        "1.5": "3V.E.K.H.3007",
        "1.75": "3V.E.K.H.3007",
        "2": "3V.E.K.H.3009",
        "2.25": "3V.E.K.H.3009",
        "2.5": "3V.E.K.H.5006",
        "2.75": "3V.E.K.H.5006",
        "3": "3V.E.K.H.5007",
        "3.5": "3V.E.K.H.5009",
        "4": "3V.E.K.H.9006",
        "4.5": "3V.E.K.H.9006",
        "5": "3V.E.K.H.9006",
        "5.5": "3V.E.K.H.9008",
        "6": "3V.E.K.H.9008",
        "6.5": "3V.E.K.H.9008",
        "7": "3V.E.K.V.1009",
        "7.5": "3V.E.K.V.1011",
        "8": "3V.E.K.V.1505",
        "8.5": "3V.E.K.V.1505",
        "9": "3V.E.K.V.1505",
        "9.5": "3V.E.K.V.1507",
        "10": "3V.E.K.V.1507",
        "11": "3V.E.K.V.1507",
        "12": "3V.E.K.V.1507",
      },
      [bar2kpa(5.25)]: {
        "0.5": "3V.E.K.H.3007",
        "0.75": "3V.E.K.H.3007",
        "1": "3V.E.K.H.3007",
        "1.25": "3V.E.K.H.3007",
        "1.5": "3V.E.K.H.3007",
        "1.75": "3V.E.K.H.3009",
        "2": "3V.E.K.H.3009",
        "2.25": "3V.E.K.H.5006",
        "2.5": "3V.E.K.H.5006",
        "2.75": "3V.E.K.H.5007",
        "3": "3V.E.K.H.5007",
        "3.5": "3V.E.K.H.5009",
        "4": "3V.E.K.H.9006",
        "4.5": "3V.E.K.H.9006",
        "5": "3V.E.K.H.9008",
        "5.5": "3V.E.K.H.9008",
        "6": "3V.E.K.H.9008",
        "6.5": "3V.E.K.H.9008",
        "7": "3V.E.K.V.1011",
        "7.5": "3V.E.K.V.1011",
        "8": "3V.E.K.V.1505",
        "8.5": "3V.E.K.V.1505",
        "9": "3V.E.K.V.1507",
        "9.5": "3V.E.K.V.1507",
        "10": "3V.E.K.V.1507",
        "11": "3V.E.K.V.1507",
        "12": "3V.E.K.V.1507",
      },
      [bar2kpa(5.5)]: {
        "0.5": "3V.E.K.H.3007",
        "0.75": "3V.E.K.H.3007",
        "1": "3V.E.K.H.3007",
        "1.25": "3V.E.K.H.3007",
        "1.5": "3V.E.K.H.3007",
        "1.75": "3V.E.K.H.3009",
        "2": "3V.E.K.H.3009",
        "2.25": "3V.E.K.H.5006",
        "2.5": "3V.E.K.H.5007",
        "2.75": "3V.E.K.H.5007",
        "3": "3V.E.K.H.5007",
        "3.5": "3V.E.K.H.5009",
        "4": "3V.E.K.H.9006",
        "4.5": "3V.E.K.H.9008",
        "5": "3V.E.K.H.9008",
        "5.5": "3V.E.K.H.9008",
        "6": "3V.E.K.H.9008",
        "6.5": "3V.E.K.V.1009",
        "7": "3V.E.K.V.1011",
        "7.5": "3V.E.K.V.1011",
        "8": "3V.E.K.V.1505",
        "8.5": "3V.E.K.V.1507",
        "9": "3V.E.K.V.1507",
        "9.5": "3V.E.K.V.1507",
        "10": "3V.E.K.V.1507",
        "11": "3V.E.K.V.1507",
        "12": "3V.E.K.V.1509",
      },
      [bar2kpa(5.75)]: {
        "0.5": "3V.E.K.H.3007",
        "0.75": "3V.E.K.H.3007",
        "1": "3V.E.K.H.3007",
        "1.25": "3V.E.K.H.3007",
        "1.5": "3V.E.K.H.3007",
        "1.75": "3V.E.K.H.3009",
        "2": "3V.E.K.H.3009",
        "2.25": "3V.E.K.H.5007",
        "2.5": "3V.E.K.H.5007",
        "2.75": "3V.E.K.H.5009",
        "3": "3V.E.K.H.5009",
        "3.5": "3V.E.K.H.5009",
        "4": "3V.E.K.H.9008",
        "4.5": "3V.E.K.H.9008",
        "5": "3V.E.K.H.9008",
        "5.5": "3V.E.K.H.9008",
        "6": "3V.E.K.H.9008",
        "6.5": "3V.E.K.V.1009",
        "7": "3V.E.K.V.1011",
        "7.5": "3V.E.K.V.1011",
        "8": "3V.E.K.V.1507",
        "8.5": "3V.E.K.V.1507",
        "9": "3V.E.K.V.1507",
        "9.5": "3V.E.K.V.1507",
        "10": "3V.E.K.V.1507",
        "11": "3V.E.K.V.1507",
        "12": "3V.E.K.V.1509",
      },
      [bar2kpa(6)]: {
        "0.5": "3V.E.K.H.3007",
        "0.75": "3V.E.K.H.3007",
        "1": "3V.E.K.H.3007",
        "1.25": "3V.E.K.H.3007",
        "1.5": "3V.E.K.H.3009",
        "1.75": "3V.E.K.H.3009",
        "2": "3V.E.K.H.3009",
        "2.25": "3V.E.K.H.5007",
        "2.5": "3V.E.K.H.5007",
        "2.75": "3V.E.K.H.5009",
        "3": "3V.E.K.H.5009",
        "3.5": "3V.E.K.H.5009",
        "4": "3V.E.K.H.9008",
        "4.5": "3V.E.K.H.9008",
        "5": "3V.E.K.H.9008",
        "5.5": "3V.E.K.H.9008",
        "6": "3V.E.K.H.9008",
        "6.5": "3V.E.K.V.1011",
        "7": "3V.E.K.V.1011",
        "7.5": "3V.E.K.V.1013",
        "8": "3V.E.K.V.1507",
        "8.5": "3V.E.K.V.1507",
        "9": "3V.E.K.V.1507",
        "9.5": "3V.E.K.V.1507",
        "10": "3V.E.K.V.1507",
        "11": "3V.E.K.V.1507",
        "12": "3V.E.K.V.1509",
      },
    },
    "duty-assist-assist": {
      [bar2kpa(2)]: {
        "0.5": "3V.E.K.H.3005",
        "0.75": "3V.E.K.H.3005",
        "1": "3V.E.K.H.3005",
        "1.25": "3V.E.K.H.3005",
        "1.5": "3V.E.K.H.3005",
        "1.75": "3V.E.K.H.3005",
        "2": "3V.E.K.H.3005",
        "2.25": "3V.E.K.H.3005",
        "2.5": "3V.E.K.H.3005",
        "2.75": "3V.E.K.H.3005",
        "3": "3V.E.K.H.3005",
        "3.5": "3V.E.K.H.3005",
        "4": "3V.E.K.H.5004",
        "4.5": "3V.E.K.H.5004",
        "5": "3V.E.K.H.5004",
        "5.5": "3V.E.K.H.5004",
        "6": "3V.E.K.H.5004",
        "6.5": "3V.E.K.H.9004",
        "7": "3V.E.K.H.9004",
        "7.5": "3V.E.K.H.9004",
        "8": "3V.E.K.H.9004",
        "8.5": "3V.E.K.H.9004",
        "9": "3V.E.K.H.9004",
        "9.5": "3V.E.K.H.9004",
        "10": "3V.E.K.H.9004",
        "11": "3V.E.K.H.9005",
        "12": "3V.E.K.V.1505",
      },
      [bar2kpa(2.25)]: {
        "0.5": "3V.E.K.H.3005",
        "0.75": "3V.E.K.H.3005",
        "1": "3V.E.K.H.3005",
        "1.25": "3V.E.K.H.3005",
        "1.5": "3V.E.K.H.3005",
        "1.75": "3V.E.K.H.3005",
        "2": "3V.E.K.H.3005",
        "2.25": "3V.E.K.H.3005",
        "2.5": "3V.E.K.H.3005",
        "2.75": "3V.E.K.H.3005",
        "3": "3V.E.K.H.3005",
        "3.5": "3V.E.K.H.3005",
        "4": "3V.E.K.H.5004",
        "4.5": "3V.E.K.H.5004",
        "5": "3V.E.K.H.5004",
        "5.5": "3V.E.K.H.5004",
        "6": "3V.E.K.H.5005",
        "6.5": "3V.E.K.H.9004",
        "7": "3V.E.K.H.9004",
        "7.5": "3V.E.K.H.9004",
        "8": "3V.E.K.H.9004",
        "8.5": "3V.E.K.H.9004",
        "9": "3V.E.K.H.9004",
        "9.5": "3V.E.K.H.9004",
        "10": "3V.E.K.H.9004",
        "11": "3V.E.K.H.9005",
        "12": "3V.E.K.V.1505",
      },
      [bar2kpa(2.5)]: {
        "0.5": "3V.E.K.H.3005",
        "0.75": "3V.E.K.H.3005",
        "1": "3V.E.K.H.3005",
        "1.25": "3V.E.K.H.3005",
        "1.5": "3V.E.K.H.3005",
        "1.75": "3V.E.K.H.3005",
        "2": "3V.E.K.H.3005",
        "2.25": "3V.E.K.H.3005",
        "2.5": "3V.E.K.H.3005",
        "2.75": "3V.E.K.H.3005",
        "3": "3V.E.K.H.3005",
        "3.5": "3V.E.K.H.3007",
        "4": "3V.E.K.H.5004",
        "4.5": "3V.E.K.H.5004",
        "5": "3V.E.K.H.5004",
        "5.5": "3V.E.K.H.5004",
        "6": "3V.E.K.H.5005",
        "6.5": "3V.E.K.H.9004",
        "7": "3V.E.K.H.9004",
        "7.5": "3V.E.K.H.9004",
        "8": "3V.E.K.H.9004",
        "8.5": "3V.E.K.H.9004",
        "9": "3V.E.K.H.9004",
        "9.5": "3V.E.K.H.9004",
        "10": "3V.E.K.H.9004",
        "11": "3V.E.K.H.9006",
        "12": "3V.E.K.V.1505",
      },
      [bar2kpa(2.75)]: {
        "0.5": "3V.E.K.H.3005",
        "0.75": "3V.E.K.H.3005",
        "1": "3V.E.K.H.3005",
        "1.25": "3V.E.K.H.3005",
        "1.5": "3V.E.K.H.3005",
        "1.75": "3V.E.K.H.3005",
        "2": "3V.E.K.H.3005",
        "2.25": "3V.E.K.H.3005",
        "2.5": "3V.E.K.H.3005",
        "2.75": "3V.E.K.H.3005",
        "3": "3V.E.K.H.3005",
        "3.5": "3V.E.K.H.3007",
        "4": "3V.E.K.H.5004",
        "4.5": "3V.E.K.H.5004",
        "5": "3V.E.K.H.5005",
        "5.5": "3V.E.K.H.5005",
        "6": "3V.E.K.H.5006",
        "6.5": "3V.E.K.H.9004",
        "7": "3V.E.K.H.9004",
        "7.5": "3V.E.K.H.9004",
        "8": "3V.E.K.H.9004",
        "8.5": "3V.E.K.H.9004",
        "9": "3V.E.K.H.9004",
        "9.5": "3V.E.K.H.9004",
        "10": "3V.E.K.H.9005",
        "11": "3V.E.K.H.9008",
        "12": "3V.E.K.V.1505",
      },
      [bar2kpa(3)]: {
        "0.5": "3V.E.K.H.3005",
        "0.75": "3V.E.K.H.3005",
        "1": "3V.E.K.H.3005",
        "1.25": "3V.E.K.H.3005",
        "1.5": "3V.E.K.H.3005",
        "1.75": "3V.E.K.H.3005",
        "2": "3V.E.K.H.3005",
        "2.25": "3V.E.K.H.3005",
        "2.5": "3V.E.K.H.3005",
        "2.75": "3V.E.K.H.3005",
        "3": "3V.E.K.H.3005",
        "3.5": "3V.E.K.H.3007",
        "4": "3V.E.K.H.5004",
        "4.5": "3V.E.K.H.5004",
        "5": "3V.E.K.H.5005",
        "5.5": "3V.E.K.H.5005",
        "6": "3V.E.K.H.5006",
        "6.5": "3V.E.K.H.9004",
        "7": "3V.E.K.H.9004",
        "7.5": "3V.E.K.H.9004",
        "8": "3V.E.K.H.9004",
        "8.5": "3V.E.K.H.9004",
        "9": "3V.E.K.H.9004",
        "9.5": "3V.E.K.H.9005",
        "10": "3V.E.K.H.9005",
        "11": "3V.E.K.H.9008",
        "12": "3V.E.K.V.1505",
      },
      [bar2kpa(3.25)]: {
        "0.5": "3V.E.K.H.3005",
        "0.75": "3V.E.K.H.3005",
        "1": "3V.E.K.H.3005",
        "1.25": "3V.E.K.H.3005",
        "1.5": "3V.E.K.H.3005",
        "1.75": "3V.E.K.H.3005",
        "2": "3V.E.K.H.3005",
        "2.25": "3V.E.K.H.3005",
        "2.5": "3V.E.K.H.3005",
        "2.75": "3V.E.K.H.3005",
        "3": "3V.E.K.H.3007",
        "3.5": "3V.E.K.H.3007",
        "4": "3V.E.K.H.5004",
        "4.5": "3V.E.K.H.5005",
        "5": "3V.E.K.H.5005",
        "5.5": "3V.E.K.H.5006",
        "6": "3V.E.K.H.5007",
        "6.5": "3V.E.K.H.9004",
        "7": "3V.E.K.H.9004",
        "7.5": "3V.E.K.H.9004",
        "8": "3V.E.K.H.9004",
        "8.5": "3V.E.K.H.9005",
        "9": "3V.E.K.H.9005",
        "9.5": "3V.E.K.H.9005",
        "10": "3V.E.K.H.9006",
        "11": "3V.E.K.H.9008",
        "12": "3V.E.K.V.1505",
      },
      [bar2kpa(3.5)]: {
        "0.5": "3V.E.K.H.3005",
        "0.75": "3V.E.K.H.3005",
        "1": "3V.E.K.H.3005",
        "1.25": "3V.E.K.H.3005",
        "1.5": "3V.E.K.H.3005",
        "1.75": "3V.E.K.H.3005",
        "2": "3V.E.K.H.3005",
        "2.25": "3V.E.K.H.3005",
        "2.5": "3V.E.K.H.3005",
        "2.75": "3V.E.K.H.3007",
        "3": "3V.E.K.H.3007",
        "3.5": "3V.E.K.H.3007",
        "4": "3V.E.K.H.5005",
        "4.5": "3V.E.K.H.5005",
        "5": "3V.E.K.H.5006",
        "5.5": "3V.E.K.H.5006",
        "6": "3V.E.K.H.5007",
        "6.5": "3V.E.K.H.9004",
        "7": "3V.E.K.H.9004",
        "7.5": "3V.E.K.H.9004",
        "8": "3V.E.K.H.9005",
        "8.5": "3V.E.K.H.9005",
        "9": "3V.E.K.H.9005",
        "9.5": "3V.E.K.H.9006",
        "10": "3V.E.K.H.9006",
        "11": "3V.E.K.H.9008",
        "12": "3V.E.K.V.1505",
      },
      [bar2kpa(3.75)]: {
        "0.5": "3V.E.K.H.3005",
        "0.75": "3V.E.K.H.3005",
        "1": "3V.E.K.H.3005",
        "1.25": "3V.E.K.H.3005",
        "1.5": "3V.E.K.H.3005",
        "1.75": "3V.E.K.H.3005",
        "2": "3V.E.K.H.3005",
        "2.25": "3V.E.K.H.3005",
        "2.5": "3V.E.K.H.3007",
        "2.75": "3V.E.K.H.3007",
        "3": "3V.E.K.H.3007",
        "3.5": "3V.E.K.H.3009",
        "4": "3V.E.K.H.5005",
        "4.5": "3V.E.K.H.5005",
        "5": "3V.E.K.H.5006",
        "5.5": "3V.E.K.H.5007",
        "6": "3V.E.K.H.9005",
        "6.5": "3V.E.K.H.9005",
        "7": "3V.E.K.H.9005",
        "7.5": "3V.E.K.H.9005",
        "8": "3V.E.K.H.9005",
        "8.5": "3V.E.K.H.9005",
        "9": "3V.E.K.H.9006",
        "9.5": "3V.E.K.H.9006",
        "10": "3V.E.K.H.9008",
        "11": "3V.E.K.H.9008",
        "12": "3V.E.K.V.1505",
      },
      [bar2kpa(4)]: {
        "0.5": "3V.E.K.H.3005",
        "0.75": "3V.E.K.H.3005",
        "1": "3V.E.K.H.3005",
        "1.25": "3V.E.K.H.3005",
        "1.5": "3V.E.K.H.3005",
        "1.75": "3V.E.K.H.3005",
        "2": "3V.E.K.H.3005",
        "2.25": "3V.E.K.H.3005",
        "2.5": "3V.E.K.H.3007",
        "2.75": "3V.E.K.H.3007",
        "3": "3V.E.K.H.3007",
        "3.5": "3V.E.K.H.3009",
        "4": "3V.E.K.H.5005",
        "4.5": "3V.E.K.H.5006",
        "5": "3V.E.K.H.5006",
        "5.5": "3V.E.K.H.5007",
        "6": "3V.E.K.H.9005",
        "6.5": "3V.E.K.H.9005",
        "7": "3V.E.K.H.9005",
        "7.5": "3V.E.K.H.9005",
        "8": "3V.E.K.H.9005",
        "8.5": "3V.E.K.H.9006",
        "9": "3V.E.K.H.9006",
        "9.5": "3V.E.K.H.9008",
        "10": "3V.E.K.H.9008",
        "11": "3V.E.K.H.9008",
        "12": "3V.E.K.V.1505",
      },
      [bar2kpa(4.25)]: {
        "0.5": "3V.E.K.H.3005",
        "0.75": "3V.E.K.H.3005",
        "1": "3V.E.K.H.3005",
        "1.25": "3V.E.K.H.3005",
        "1.5": "3V.E.K.H.3005",
        "1.75": "3V.E.K.H.3005",
        "2": "3V.E.K.H.3007",
        "2.25": "3V.E.K.H.3007",
        "2.5": "3V.E.K.H.3007",
        "2.75": "3V.E.K.H.3007",
        "3": "3V.E.K.H.3007",
        "3.5": "3V.E.K.H.3009",
        "4": "3V.E.K.H.5006",
        "4.5": "3V.E.K.H.5006",
        "5": "3V.E.K.H.5007",
        "5.5": "3V.E.K.H.5009",
        "6": "3V.E.K.H.9005",
        "6.5": "3V.E.K.H.9005",
        "7": "3V.E.K.H.9005",
        "7.5": "3V.E.K.H.9005",
        "8": "3V.E.K.H.9006",
        "8.5": "3V.E.K.H.9006",
        "9": "3V.E.K.H.9006",
        "9.5": "3V.E.K.H.9008",
        "10": "3V.E.K.H.9008",
        "11": "3V.E.K.V.1009",
        "12": "3V.E.K.V.1505",
      },
      [bar2kpa(4.5)]: {
        "0.5": "3V.E.K.H.3005",
        "0.75": "3V.E.K.H.3005",
        "1": "3V.E.K.H.3005",
        "1.25": "3V.E.K.H.3005",
        "1.5": "3V.E.K.H.3005",
        "1.75": "3V.E.K.H.3007",
        "2": "3V.E.K.H.3007",
        "2.25": "3V.E.K.H.3007",
        "2.5": "3V.E.K.H.3007",
        "2.75": "3V.E.K.H.3007",
        "3": "3V.E.K.H.3007",
        "3.5": "3V.E.K.H.3009",
        "4": "3V.E.K.H.5006",
        "4.5": "3V.E.K.H.5006",
        "5": "3V.E.K.H.5007",
        "5.5": "3V.E.K.H.5009",
        "6": "3V.E.K.H.9005",
        "6.5": "3V.E.K.H.9005",
        "7": "3V.E.K.H.9006",
        "7.5": "3V.E.K.H.9006",
        "8": "3V.E.K.H.9006",
        "8.5": "3V.E.K.H.9008",
        "9": "3V.E.K.H.9008",
        "9.5": "3V.E.K.H.9008",
        "10": "3V.E.K.H.9008",
        "11": "3V.E.K.V.1009",
        "12": "3V.E.K.V.1505",
      },
      [bar2kpa(4.75)]: {
        "0.5": "3V.E.K.H.3007",
        "0.75": "3V.E.K.H.3007",
        "1": "3V.E.K.H.3007",
        "1.25": "3V.E.K.H.3007",
        "1.5": "3V.E.K.H.3007",
        "1.75": "3V.E.K.H.3007",
        "2": "3V.E.K.H.3007",
        "2.25": "3V.E.K.H.3007",
        "2.5": "3V.E.K.H.3007",
        "2.75": "3V.E.K.H.3007",
        "3": "3V.E.K.H.3009",
        "3.5": "3V.E.K.H.5006",
        "4": "3V.E.K.H.5006",
        "4.5": "3V.E.K.H.5006",
        "5": "3V.E.K.H.5007",
        "5.5": "3V.E.K.H.5009",
        "6": "3V.E.K.H.9006",
        "6.5": "3V.E.K.H.9006",
        "7": "3V.E.K.H.9006",
        "7.5": "3V.E.K.H.9006",
        "8": "3V.E.K.H.9006",
        "8.5": "3V.E.K.H.9008",
        "9": "3V.E.K.H.9008",
        "9.5": "3V.E.K.H.9008",
        "10": "3V.E.K.H.9008",
        "11": "3V.E.K.V.1011",
        "12": "3V.E.K.V.1505",
      },
      [bar2kpa(5)]: {
        "0.5": "3V.E.K.H.3007",
        "0.75": "3V.E.K.H.3007",
        "1": "3V.E.K.H.3007",
        "1.25": "3V.E.K.H.3007",
        "1.5": "3V.E.K.H.3007",
        "1.75": "3V.E.K.H.3007",
        "2": "3V.E.K.H.3007",
        "2.25": "3V.E.K.H.3007",
        "2.5": "3V.E.K.H.3007",
        "2.75": "3V.E.K.H.3009",
        "3": "3V.E.K.H.3009",
        "3.5": "3V.E.K.H.5006",
        "4": "3V.E.K.H.5006",
        "4.5": "3V.E.K.H.5007",
        "5": "3V.E.K.H.5009",
        "5.5": "3V.E.K.H.5009",
        "6": "3V.E.K.H.9006",
        "6.5": "3V.E.K.H.9006",
        "7": "3V.E.K.H.9006",
        "7.5": "3V.E.K.H.9006",
        "8": "3V.E.K.H.9008",
        "8.5": "3V.E.K.H.9008",
        "9": "3V.E.K.H.9008",
        "9.5": "3V.E.K.H.9008",
        "10": "3V.E.K.H.9008",
        "11": "3V.E.K.V.1011",
        "12": "3V.E.K.V.1505",
      },
      [bar2kpa(5.25)]: {
        "0.5": "3V.E.K.H.3007",
        "0.75": "3V.E.K.H.3007",
        "1": "3V.E.K.H.3007",
        "1.25": "3V.E.K.H.3007",
        "1.5": "3V.E.K.H.3007",
        "1.75": "3V.E.K.H.3007",
        "2": "3V.E.K.H.3007",
        "2.25": "3V.E.K.H.3007",
        "2.5": "3V.E.K.H.3007",
        "2.75": "3V.E.K.H.3009",
        "3": "3V.E.K.H.3009",
        "3.5": "3V.E.K.H.5006",
        "4": "3V.E.K.H.5007",
        "4.5": "3V.E.K.H.5007",
        "5": "3V.E.K.H.5009",
        "5.5": "3V.E.K.H.5009",
        "6": "3V.E.K.H.9006",
        "6.5": "3V.E.K.H.9006",
        "7": "3V.E.K.H.9008",
        "7.5": "3V.E.K.H.9008",
        "8": "3V.E.K.H.9008",
        "8.5": "3V.E.K.H.9008",
        "9": "3V.E.K.H.9008",
        "9.5": "3V.E.K.H.9008",
        "10": "3V.E.K.V.1009",
        "11": "3V.E.K.V.1011",
        "12": "3V.E.K.V.1505",
      },
      [bar2kpa(5.5)]: {
        "0.5": "3V.E.K.H.3007",
        "0.75": "3V.E.K.H.3007",
        "1": "3V.E.K.H.3007",
        "1.25": "3V.E.K.H.3007",
        "1.5": "3V.E.K.H.3007",
        "1.75": "3V.E.K.H.3007",
        "2": "3V.E.K.H.3007",
        "2.25": "3V.E.K.H.3007",
        "2.5": "3V.E.K.H.3009",
        "2.75": "3V.E.K.H.3009",
        "3": "3V.E.K.H.3009",
        "3.5": "3V.E.K.H.5006",
        "4": "3V.E.K.H.5007",
        "4.5": "3V.E.K.H.5007",
        "5": "3V.E.K.H.5009",
        "5.5": "3V.E.K.H.5009",
        "6": "3V.E.K.H.9006",
        "6.5": "3V.E.K.H.9008",
        "7": "3V.E.K.H.9008",
        "7.5": "3V.E.K.H.9008",
        "8": "3V.E.K.H.9008",
        "8.5": "3V.E.K.H.9008",
        "9": "3V.E.K.H.9008",
        "9.5": "3V.E.K.H.9008",
        "10": "3V.E.K.V.1009",
        "11": "3V.E.K.V.1011",
        "12": "3V.E.K.V.1505",
      },
      [bar2kpa(5.75)]: {
        "0.5": "3V.E.K.H.3007",
        "0.75": "3V.E.K.H.3007",
        "1": "3V.E.K.H.3007",
        "1.25": "3V.E.K.H.3007",
        "1.5": "3V.E.K.H.3007",
        "1.75": "3V.E.K.H.3007",
        "2": "3V.E.K.H.3007",
        "2.25": "3V.E.K.H.3007",
        "2.5": "3V.E.K.H.3009",
        "2.75": "3V.E.K.H.3009",
        "3": "3V.E.K.H.3009",
        "3.5": "3V.E.K.H.5007",
        "4": "3V.E.K.H.5007",
        "4.5": "3V.E.K.H.5009",
        "5": "3V.E.K.H.5009",
        "5.5": "3V.E.K.H.9006",
        "6": "3V.E.K.H.9008",
        "6.5": "3V.E.K.H.9008",
        "7": "3V.E.K.H.9008",
        "7.5": "3V.E.K.H.9008",
        "8": "3V.E.K.H.9008",
        "8.5": "3V.E.K.H.9008",
        "9": "3V.E.K.H.9008",
        "9.5": "3V.E.K.V.1009",
        "10": "3V.E.K.V.1011",
        "11": "3V.E.K.V.1011",
        "12": "3V.E.K.V.1507",
      },
      [bar2kpa(6)]: {
        "0.5": "3V.E.K.H.3007",
        "0.75": "3V.E.K.H.3007",
        "1": "3V.E.K.H.3007",
        "1.25": "3V.E.K.H.3007",
        "1.5": "3V.E.K.H.3007",
        "1.75": "3V.E.K.H.3007",
        "2": "3V.E.K.H.3007",
        "2.25": "3V.E.K.H.3009",
        "2.5": "3V.E.K.H.3009",
        "2.75": "3V.E.K.H.3009",
        "3": "3V.E.K.H.3009",
        "3.5": "3V.E.K.H.5007",
        "4": "3V.E.K.H.5009",
        "4.5": "3V.E.K.H.5009",
        "5": "3V.E.K.H.5009",
        "5.5": "3V.E.K.H.9008",
        "6": "3V.E.K.H.9008",
        "6.5": "3V.E.K.H.9008",
        "7": "3V.E.K.H.9008",
        "7.5": "3V.E.K.H.9008",
        "8": "3V.E.K.H.9008",
        "8.5": "3V.E.K.H.9008",
        "9": "3V.E.K.H.9008",
        "9.5": "3V.E.K.V.1009",
        "10": "3V.E.K.V.1011",
        "11": "3V.E.K.V.1011",
        "12": "3V.E.K.V.1507",
      },
      [bar2kpa(6.25)]: {
        "0.5": "3V.E.K.H.3007",
        "0.75": "3V.E.K.H.3007",
        "1": "3V.E.K.H.3007",
        "1.25": "3V.E.K.H.3007",
        "1.5": "3V.E.K.H.3007",
        "1.75": "3V.E.K.H.3007",
        "2": "3V.E.K.H.3009",
        "2.25": "3V.E.K.H.3009",
        "2.5": "3V.E.K.H.3009",
        "2.75": "3V.E.K.H.3009",
        "3": "3V.E.K.H.5007",
        "3.5": "3V.E.K.H.5007",
        "4": "3V.E.K.H.5009",
        "4.5": "3V.E.K.H.5009",
        "5": "3V.E.K.H.5009",
        "5.5": "3V.E.K.H.9008",
        "6": "3V.E.K.H.9008",
        "6.5": "3V.E.K.H.9008",
        "7": "3V.E.K.H.9008",
        "7.5": "3V.E.K.H.9008",
        "8": "3V.E.K.H.9008",
        "8.5": "3V.E.K.H.9008",
        "9": "3V.E.K.V.1009",
        "9.5": "3V.E.K.V.1011",
        "10": "3V.E.K.V.1011",
        "11": "3V.E.K.V.1013",
        "12": "3V.E.K.V.1507",
      },
      [bar2kpa(6.5)]: {
        "0.5": "3V.E.K.H.3007",
        "0.75": "3V.E.K.H.3007",
        "1": "3V.E.K.H.3007",
        "1.25": "3V.E.K.H.3007",
        "1.5": "3V.E.K.H.3007",
        "1.75": "3V.E.K.H.3009",
        "2": "3V.E.K.H.3009",
        "2.25": "3V.E.K.H.3009",
        "2.5": "3V.E.K.H.3009",
        "2.75": "3V.E.K.H.3009",
        "3": "3V.E.K.H.5007",
        "3.5": "3V.E.K.H.5009",
        "4": "3V.E.K.H.5009",
        "4.5": "3V.E.K.H.5009",
        "5": "3V.E.K.H.9008",
        "5.5": "3V.E.K.H.9008",
        "6": "3V.E.K.H.9008",
        "6.5": "3V.E.K.H.9008",
        "7": "3V.E.K.H.9008",
        "7.5": "3V.E.K.H.9008",
        "8": "3V.E.K.H.9008",
        "8.5": "3V.E.K.H.9008",
        "9": "3V.E.K.V.1009",
        "9.5": "3V.E.K.V.1011",
        "10": "3V.E.K.V.1011",
        "11": "3V.E.K.V.1013",
        "12": "3V.E.K.V.1507",
      },
      [bar2kpa(6.75)]: {
        "0.5": "3V.E.K.H.3007",
        "0.75": "3V.E.K.H.3007",
        "1": "3V.E.K.H.3007",
        "1.25": "3V.E.K.H.3007",
        "1.5": "3V.E.K.H.3007",
        "1.75": "3V.E.K.H.3009",
        "2": "3V.E.K.H.3009",
        "2.25": "3V.E.K.H.3009",
        "2.5": "3V.E.K.H.3009",
        "2.75": "3V.E.K.H.5007",
        "3": "3V.E.K.H.5009",
        "3.5": "3V.E.K.H.5009",
        "4": "3V.E.K.H.5009",
        "4.5": "3V.E.K.H.5009",
        "5": "3V.E.K.H.9008",
        "5.5": "3V.E.K.H.9008",
        "6": "3V.E.K.H.9008",
        "6.5": "3V.E.K.H.9008",
        "7": "3V.E.K.H.9008",
        "7.5": "3V.E.K.H.9008",
        "8": "3V.E.K.H.9008",
        "8.5": "3V.E.K.V.1009",
        "9": "3V.E.K.V.1011",
        "9.5": "3V.E.K.V.1011",
        "10": "3V.E.K.V.1011",
        "11": "3V.E.K.V.1013",
        "12": "3V.E.K.V.1507",
      },
      [bar2kpa(7)]: {
        "0.5": "3V.E.K.H.3009",
        "0.75": "3V.E.K.H.3009",
        "1": "3V.E.K.H.3009",
        "1.25": "3V.E.K.H.3009",
        "1.5": "3V.E.K.H.3009",
        "1.75": "3V.E.K.H.3009",
        "2": "3V.E.K.H.3009",
        "2.25": "3V.E.K.H.3009",
        "2.5": "3V.E.K.H.3009",
        "2.75": "3V.E.K.H.5009",
        "3": "3V.E.K.H.5009",
        "3.5": "3V.E.K.H.5009",
        "4": "3V.E.K.H.5009",
        "4.5": "3V.E.K.H.5009",
        "5": "3V.E.K.H.9008",
        "5.5": "3V.E.K.H.9008",
        "6": "3V.E.K.H.9008",
        "6.5": "3V.E.K.H.9008",
        "7": "3V.E.K.H.9008",
        "7.5": "3V.E.K.H.9008",
        "8": "3V.E.K.V.1009",
        "8.5": "3V.E.K.V.1011",
        "9": "3V.E.K.V.1011",
        "9.5": "3V.E.K.V.1011",
        "10": "3V.E.K.V.1011",
        "11": "3V.E.K.V.1013",
        "12": "3V.E.K.V.1507",
      },
      [bar2kpa(7.25)]: {
        "0.5": "3V.E.K.H.3009",
        "0.75": "3V.E.K.H.3009",
        "1": "3V.E.K.H.3009",
        "1.25": "3V.E.K.H.3009",
        "1.5": "3V.E.K.H.3009",
        "1.75": "3V.E.K.H.3009",
        "2": "3V.E.K.H.3009",
        "2.25": "3V.E.K.H.3009",
        "2.5": "3V.E.K.H.5009",
        "2.75": "3V.E.K.H.5009",
        "3": "3V.E.K.H.5009",
        "3.5": "3V.E.K.H.5009",
        "4": "3V.E.K.H.5009",
        "4.5": "3V.E.K.H.9008",
        "5": "3V.E.K.H.9008",
        "5.5": "3V.E.K.H.9008",
        "6": "3V.E.K.H.9008",
        "6.5": "3V.E.K.H.9008",
        "7": "3V.E.K.H.9008",
        "7.5": "3V.E.K.V.1009",
        "8": "3V.E.K.V.1011",
        "8.5": "3V.E.K.V.1011",
        "9": "3V.E.K.V.1011",
        "9.5": "3V.E.K.V.1011",
        "10": "3V.E.K.V.1013",
        "11": "3V.E.K.V.1507",
        "12": "3V.E.K.V.1507",
      },
      [bar2kpa(7.5)]: {
        "0.5": "3V.E.K.H.3009",
        "0.75": "3V.E.K.H.3009",
        "1": "3V.E.K.H.3009",
        "1.25": "3V.E.K.H.3009",
        "1.5": "3V.E.K.H.3009",
        "1.75": "3V.E.K.H.3009",
        "2": "3V.E.K.H.3009",
        "2.25": "3V.E.K.H.3009",
        "2.5": "3V.E.K.H.5009",
        "2.75": "3V.E.K.H.5009",
        "3": "3V.E.K.H.5009",
        "3.5": "3V.E.K.H.5009",
        "4": "3V.E.K.H.5009",
        "4.5": "3V.E.K.H.9008",
        "5": "3V.E.K.H.9008",
        "5.5": "3V.E.K.H.9008",
        "6": "3V.E.K.H.9008",
        "6.5": "3V.E.K.H.9008",
        "7": "3V.E.K.V.1009",
        "7.5": "3V.E.K.V.1011",
        "8": "3V.E.K.V.1011",
        "8.5": "3V.E.K.V.1011",
        "9": "3V.E.K.V.1011",
        "9.5": "3V.E.K.V.1011",
        "10": "3V.E.K.V.1013",
        "11": "3V.E.K.V.1507",
        "12": "3V.E.K.V.1507",
      },
      [bar2kpa(7.75)]: {
        "0.5": "3V.E.K.H.3009",
        "0.75": "3V.E.K.H.3009",
        "1": "3V.E.K.H.3009",
        "1.25": "3V.E.K.H.3009",
        "1.5": "3V.E.K.H.3009",
        "1.75": "3V.E.K.H.3009",
        "2": "3V.E.K.H.3009",
        "2.25": "3V.E.K.H.5009",
        "2.5": "3V.E.K.H.5009",
        "2.75": "3V.E.K.H.5009",
        "3": "3V.E.K.H.5009",
        "3.5": "3V.E.K.H.5009",
        "4": "3V.E.K.H.9008",
        "4.5": "3V.E.K.H.9008",
        "5": "3V.E.K.H.9008",
        "5.5": "3V.E.K.H.9008",
        "6": "3V.E.K.H.9008",
        "6.5": "3V.E.K.V.1009",
        "7": "3V.E.K.V.1011",
        "7.5": "3V.E.K.V.1011",
        "8": "3V.E.K.V.1011",
        "8.5": "3V.E.K.V.1011",
        "9": "3V.E.K.V.1011",
        "9.5": "3V.E.K.V.1013",
        "10": "3V.E.K.V.1013",
        "11": "3V.E.K.V.1507",
        "12": "3V.E.K.V.1507",
      },
      [bar2kpa(8)]: {
        "0.5": "3V.E.K.H.3009",
        "0.75": "3V.E.K.H.3009",
        "1": "3V.E.K.H.3009",
        "1.25": "3V.E.K.H.3009",
        "1.5": "3V.E.K.H.3009",
        "1.75": "3V.E.K.H.3009",
        "2": "3V.E.K.H.3009",
        "2.25": "3V.E.K.H.5009",
        "2.5": "3V.E.K.H.5009",
        "2.75": "3V.E.K.H.5009",
        "3": "3V.E.K.H.5009",
        "3.5": "3V.E.K.H.5009",
        "4": "3V.E.K.H.9008",
        "4.5": "3V.E.K.H.9008",
        "5": "3V.E.K.H.9008",
        "5.5": "3V.E.K.H.9008",
        "6": "3V.E.K.V.1009",
        "6.5": "3V.E.K.V.1011",
        "7": "3V.E.K.V.1011",
        "7.5": "3V.E.K.V.1011",
        "8": "3V.E.K.V.1011",
        "8.5": "3V.E.K.V.1011",
        "9": "3V.E.K.V.1011",
        "9.5": "3V.E.K.V.1013",
        "10": "3V.E.K.V.1013",
        "11": "3V.E.K.V.1507",
        "12": "3V.E.K.V.1507",
      },
    },
  },
};
