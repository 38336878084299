
import { Component, Vue } from "vue-property-decorator";
import { User } from "../../../common/src/models/User";
import MainNavBar from "../../src/components/MainNavBar.vue";

@Component({
  components: {
    MainNavBar,
  },
})
export default class EnforceMFA extends Vue {
  get profile(): User {
    return this.$store.getters["profile/profile"];
  }

  goToProfile() {
    this.$router.push({ name: "user", params: { id: this.profile.username } });
  }
}
