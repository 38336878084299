import { CoreContext } from "../../../calculations/types";
import { CoreObjectConcrete } from "../../../coreObjects";
import CoreAnnotation from "../../../coreObjects/coreAnnotation";
import CoreLine from "../../../coreObjects/coreLine";
import CoreVertex from "../../../coreObjects/coreVertex";
import { EntityType } from "../types";

/*                       -------------------
                        |                   |
                        |                   |
        B - - - - - - A |        Box        |
      /                 |                   |
    /                   |                   |
  ↙                      -------------------
  C
*/

export type AnnotationGroup =
  | AnnotationGroupWithArrow
  | AnnotationGroupWithoutArrow;

export interface AnnotationGroupWithoutArrow {
  type: "withoutArrow";
  annotationBox: CoreAnnotation;
}

export interface AnnotationGroupWithArrow {
  type: "withArrow";
  annotationBox: CoreAnnotation;
  vertexA: CoreVertex;
  vertexB: CoreVertex;
  vertexC: CoreVertex;
  lineAB: CoreLine;
  lineBC: CoreLine;
}

export function findAnnotationGroup(
  context: CoreContext,
  o: CoreObjectConcrete,
): AnnotationGroup {
  switch (o.type) {
    case EntityType.ANNOTATION:
      const noArrowAnnotation: AnnotationGroupWithoutArrow = {
        type: "withoutArrow",
        annotationBox: o,
      };

      const vertexA = findVertexAFromBox(context, o as CoreAnnotation);
      if (!vertexA) {
        return noArrowAnnotation;
      }

      const lineAB = findLineABFromVertexA(context, vertexA);
      if (!lineAB) {
        return noArrowAnnotation;
      }

      const vertexB = findVertexBFromLineAB(context, lineAB);
      if (!vertexB) {
        return noArrowAnnotation;
      }

      const lineBC = findLineBCFromVertexB(context, vertexB);
      if (!lineBC) {
        return noArrowAnnotation;
      }

      const vertexC = findVertexCFromLineBC(context, lineBC);
      if (!vertexC) {
        return noArrowAnnotation;
      }

      return {
        type: "withArrow",
        annotationBox: o,
        vertexA,
        vertexB,
        vertexC,
        lineAB,
        lineBC,
      };

    case EntityType.VERTEX:
      throw new Error("Entity type vertex has not been implemented");

    case EntityType.LINE:
      throw new Error("Entity type line has not been implemented");

    default:
      throw new Error("Entity type does not belong to Annotation Groups");
  }
}

export function findVertexAFromBox(
  context: CoreContext,
  box: CoreAnnotation,
): CoreVertex | null {
  for (let [_, o] of context.globalStore) {
    if (o.entity.parentUid === box.uid) {
      return o as CoreVertex;
    }
  }

  return null;
}

export function findLineABFromVertexA(
  context: CoreContext,
  vertexA: CoreVertex,
): CoreLine | null {
  const conns = context.globalStore.getConnections(vertexA.uid);

  for (let conn of conns) {
    const o = context.globalStore.get(conn);
    if (o && o.type === EntityType.LINE) {
      return o as CoreLine;
    }
  }

  return null;
}

export function findLineABFromVertexB(
  context: CoreContext,
  vertexA: CoreVertex,
): CoreLine | null {
  const conns = context.globalStore.getConnections(vertexA.uid);

  for (let conn of conns) {
    const o = context.globalStore.get(conn);
    if (o && o.type === EntityType.LINE) {
      for (const endpoint of o.entity.endpointUid) {
        const vertex = context.globalStore.get(endpoint);
        if (vertex && vertex.type === EntityType.VERTEX) {
          if (isVertexA(context, vertex)) {
            return o as CoreLine;
          }
        }
      }
    }
  }

  return null;
}

export function findLineBCFromVertexB(
  context: CoreContext,
  vertexA: CoreVertex,
): CoreLine | null {
  const conns = context.globalStore.getConnections(vertexA.uid);

  for (let conn of conns) {
    const o = context.globalStore.get(conn);
    if (o && o.type === EntityType.LINE) {
      for (const endpoint of o.entity.endpointUid) {
        if (endpoint === vertexA.uid) {
          continue;
        }

        const vertex = context.globalStore.get(endpoint);
        if (vertex && vertex.type === EntityType.VERTEX) {
          if (isVertexA(context, vertex)) {
            continue;
          } else {
            return o as CoreLine;
          }
        }
      }
    }
  }

  return null;
}

export function findVertexBFromLineAB(
  context: CoreContext,
  lineAB: CoreLine,
): CoreVertex | null {
  const conns = lineAB.entity.endpointUid;

  for (let conn of conns) {
    const o = context.globalStore.get(conn);
    if (o && o.type === EntityType.VERTEX) {
      if (o.entity.vertexType === "fixed") {
        return o as CoreVertex;
      }
    }
  }

  return null;
}

export function findVertexAFromLineAB(
  context: CoreContext,
  lineAB: CoreLine,
): CoreVertex | null {
  const conns = lineAB.entity.endpointUid;

  for (let conn of conns) {
    const o = context.globalStore.get(conn);
    if (o && o.type === EntityType.VERTEX) {
      if (isVertexA(context, o)) {
        return o;
      }
    }
  }

  return null;
}

export function isVertexA(context: CoreContext, o: CoreVertex): boolean {
  if (o.entity.parentUid) {
    const parent = context.globalStore.get(o.entity.parentUid);
    // a valid parent should exist but sometimes doesn't due to other issues (needs investigating) SEED-1810
    if (parent && parent.type === EntityType.ANNOTATION) {
      return true;
    }
  }

  return false;
}

export function findVertexBFromBox(
  context: CoreContext,
  box: CoreAnnotation,
): CoreVertex | null {
  const vertexA = findVertexAFromBox(context, box);
  if (!vertexA) {
    return null;
  }

  const lineAB = findLineABFromVertexA(context, vertexA);
  if (!lineAB) {
    return null;
  }

  return findVertexBFromLineAB(context, lineAB);
}

export function isLineAB(context: CoreContext, o: CoreLine): boolean {
  const vertices = o.entity.endpointUid.map(
    (x) => context.globalStore.get(x) as CoreVertex,
  );

  for (const vertex of vertices) {
    if (isVertexA(context, vertex)) {
      return true;
    }
  }

  return false;
}

export function findBoxFromLineAB(
  context: CoreContext,
  o: CoreLine,
): CoreAnnotation | null {
  const vertices = o.entity.endpointUid.map(
    (x) => context.globalStore.get(x) as CoreVertex,
  );

  for (const vertex of vertices) {
    if (vertex.entity.parentUid) {
      const parent = context.globalStore.get(vertex.entity.parentUid);
      if (parent.type === EntityType.ANNOTATION) {
        return parent as CoreAnnotation;
      }
    }
  }

  return null;
}

export function findVertexBFromLineBC(
  context: CoreContext,
  lineBC: CoreLine,
): CoreVertex | null {
  const conns = lineBC.entity.endpointUid;

  for (let conn of conns) {
    const o = context.globalStore.get(conn);
    if (o && o.type === EntityType.VERTEX) {
      if (o.entity.vertexType === "fixed") {
        const cc = context.globalStore.getConnections(o.uid);
        if (cc.length === 2) {
          return o as CoreVertex;
        }
      }
    }
  }

  return null;
}

export function findVertexCFromLineBC(
  context: CoreContext,
  lineBC: CoreLine,
): CoreVertex | null {
  const conns = lineBC.entity.endpointUid;

  for (let conn of conns) {
    const o = context.globalStore.get(conn);
    if (o && o.type === EntityType.VERTEX) {
      const cc = context.globalStore.getConnections(o.uid);
      if (cc.length === 1) {
        return o as CoreVertex;
      }
    }
  }

  return null;
}

export function findBoxFromLineBC(
  context: CoreContext,
  lineBC: CoreLine,
): CoreAnnotation | null {
  const vertexB = findVertexBFromLineBC(context, lineBC);
  if (!vertexB) {
    return null;
  }

  const lineAB = findLineABFromVertexB(context, vertexB);
  if (!lineAB) {
    return null;
  }

  return findBoxFromLineAB(context, lineAB);
}

export function findBoxFromLine(
  context: CoreContext,
  o: CoreLine,
): CoreAnnotation | null {
  if (isLineAB(context, o)) {
    return findBoxFromLineAB(context, o);
  }

  return findBoxFromLineBC(context, o);
}
