import Flatten from "@flatten-js/core";
import { Coord } from "../../../../lib/coord";
import { RoofRectangleAssumption } from "./roof-types";

/**
 * Generates a line segment, centered and specified length between two points.
 */
export function createCenteredSegment(
  p1: Coord,
  p2: Coord,
  lengthMM: number,
): { start: Coord; end: Coord } {
  const dx = p2.x - p1.x;
  const dy = p2.y - p1.y;

  const mag = Math.sqrt(dx * dx + dy * dy);
  const ux = dx / mag;
  const uy = dy / mag;

  const mx = (p1.x + p2.x) / 2;
  const my = (p1.y + p2.y) / 2;
  const start = {
    x: mx - (ux * lengthMM) / 2,
    y: my - (uy * lengthMM) / 2,
  };
  const end = {
    x: mx + (ux * lengthMM) / 2,
    y: my + (uy * lengthMM) / 2,
  };

  return { start, end };
}

/**
 * Return current Position adjusted with adjustment in horizontal direction, set to nearest X-axis boundary point of rectangle if exceed limits.
 */
export function adjustHoriPos(
  currPos: Coord,
  rectangle: RoofRectangleAssumption,
  adjustmentMM: number,
): Coord {
  let x = currPos.x + adjustmentMM;
  if (x < rectangle.leftTop.coord.x) {
    x = rectangle.leftTop.coord.x;
  } else if (x > rectangle.rightTop.coord.x) {
    x = rectangle.rightTop.coord.x;
  }

  return { x, y: currPos.y };
}

export function rotatePointRelativeToCenter(
  point: Coord,
  angle: number,
  pivot: Coord,
): Coord {
  const rad = (Math.PI / 180) * angle;
  const cos = Math.cos(rad);
  const sin = Math.sin(rad);

  // Translate point to origin
  const translatedX = point.x - pivot.x;
  const translatedY = point.y - pivot.y;

  // Rotate point
  const rotatedX = translatedX * cos - translatedY * sin;
  const rotatedY = translatedX * sin + translatedY * cos;
  return { x: rotatedX + pivot.x, y: rotatedY + pivot.y };
}

export function rotatePolygonCW(
  coordinates: Coord[],
  centroid: Flatten.Point,
  angle: number,
): Coord[] {
  return coordinates.map((coord) =>
    rotatePointRelativeToCenter(coord, angle, centroid),
  );
}

function centroid(vertices: Coord[]): Coord {
  const centroid: Coord = { x: 0, y: 0 };
  let signedArea = 0;

  // Iterate over each segment of the polygon
  for (let i = 0; i < vertices.length; i++) {
    const x0 = vertices[i].x;
    const y0 = vertices[i].y;
    const x1 = vertices[(i + 1) % vertices.length].x; // Next vertex, or first if at the end
    const y1 = vertices[(i + 1) % vertices.length].y; // Next vertex, or first if at the end
    const a = x0 * y1 - x1 * y0;
    signedArea += a;
    centroid.x += (x0 + x1) * a;
    centroid.y += (y0 + y1) * a;
  }

  signedArea *= 0.5;
  centroid.x /= 6 * signedArea;
  centroid.y /= 6 * signedArea;

  return centroid;
}

export function calculateCentroidFlatten(
  coordinates: Flatten.Point[],
): Flatten.Point {
  const centroidCoord = centroid(
    coordinates.map((coord) => {
      return {
        x: coord.x,
        y: coord.y,
      };
    }),
  );
  return new Flatten.Point(centroidCoord.x, centroidCoord.y);
}

export function calculateCentroidCoord(coordinates: Coord[]): Flatten.Point {
  return calculateCentroidFlatten(
    coordinates.map((coord) => new Flatten.Point(coord.x, coord.y)),
  );
}
