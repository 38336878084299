import { Coord, Coord3D, coord, coord3DTranslate } from "../coord";
import { EPS } from "../utils";
import { Vector3 } from "../vector3";
import { angleDiffCWRad } from "./mathutils";

interface Arc2D {
  center: Coord;
  startAngleRAD: number;
  endAngleRAD: number;
  isCw: boolean;
  radiusMM: number;
  thisEp: Coord3D;
  nextEp: Coord3D;
}

interface Arc3D {
  center: Coord3D;
  totalAngleRAD: number;
  isCw: boolean;
  radiusMM: number;
  thisEp: Coord3D;
  nextEp: Coord3D;
  rotationAxis: Vector3;
  pivot: Coord3D;
}

export function angleStart2D(arc: Arc3D): number {
  return -Vector3.between(arc.thisEp, arc.pivot)
    .rotateCWDEG(-90, Vector3.UNIT_Z)
    .mul(arc.isCw ? -1 : 1)
    .angleToCCW(Vector3.UNIT_X);
}

export function angleEnd2D(arc: Arc3D): number {
  return -Vector3.between(arc.pivot, arc.nextEp)
    .rotateCWDEG(-90, Vector3.UNIT_Z)
    .mul(arc.isCw ? -1 : 1)
    .angleToCCW(Vector3.UNIT_X);
}

/**
 * Basic translation from 3d to 2d arcs, useful for drawing.
 *
 * Essentially acts as a top down projection onto the XY plane.
 *
 * TODO (when needed): Allow projecting arbitrary arcs.
 */
export function arc2d(
  coords: [Coord3D, Coord3D, Coord3D],
  radiusMM: number,
): Arc2D | undefined {
  if (!coords.every((c) => Math.abs(c.z - coords[0].z) < EPS)) {
    return undefined;
  }

  const arc = arc3d(coords, radiusMM);

  if (!arc) {
    return undefined;
  }

  return {
    center: coord(arc.center.x, arc.center.y),
    startAngleRAD: angleStart2D(arc),
    endAngleRAD: angleEnd2D(arc),
    isCw: arc.isCw,
    radiusMM: arc.radiusMM,
    thisEp: arc.thisEp,
    nextEp: arc.nextEp,
  };
}

export function arc3d(
  [c1, c2, c3]: [Coord3D, Coord3D, Coord3D],
  radiusMM: number,
): Arc3D | undefined {
  const thisVec = Vector3.between(c1, c2);
  const nextVec = Vector3.between(c2, c3);

  if (thisVec.length < EPS || nextVec.length < EPS) {
    // console.error("Arc3D: Zero length vector", {
    //   thisVec,
    //   nextVec,
    //   c1,
    //   c2,
    //   c3,
    // });
    return undefined;
  }

  const angle = thisVec.angleToCCW(nextVec);
  const offset = Math.abs(radiusMM * Math.tan(Math.PI - angle / 2));

  const thisEp = coord3DTranslate(c2, thisVec.normalize().mul(-offset));
  const nextEp = coord3DTranslate(c2, nextVec.normalize().mul(offset));

  const cross = thisVec.cross(nextVec).z;

  const isCw = cross > 0;

  const rotationAxis = new Vector3(0, 0, 1);

  const center = coord3DTranslate(
    thisEp,
    thisVec
      .rotateCWDEG(90)
      .normalize()
      .mul(radiusMM * (isCw ? -1 : 1)),
  );

  const totalAngleRAD = thisVec.mul(-1).angleTo(nextVec);

  return {
    center,
    totalAngleRAD,
    isCw,
    radiusMM,
    rotationAxis,
    thisEp,
    nextEp,
    pivot: c2,
  };
}

export function totalBendAngleRAD(
  startAngleDEG: number,
  endAngleDEG: number,
): number {
  const angleCW = angleDiffCWRad(startAngleDEG, endAngleDEG);
  const angleCCW = angleDiffCWRad(endAngleDEG, startAngleDEG);
  return Math.min(angleCW, angleCCW);
}

export function crossSectionalAreaM2(diameterMM: number): number {
  return Math.PI * (diameterMM / 2000) ** 2;
}
