import Flatten from "@flatten-js/core";
import { CoreCenteredObjectConcrete } from ".";
import {
  convertMeasurementSystem,
  Precision,
  Units,
} from "../../lib/measurements";
import { cloneSimple } from "../../lib/utils";
import { GetPressureLossOptions } from "../calculations/entity-pressure-drops";
import {
  CoreContext,
  CostBreakdown,
  isHeightDiffPipeBreakdown,
  PressureLossResult,
} from "../calculations/types";
import { FLOW_SOURCE_EDGE } from "../calculations/utils";
import { CalculationConcrete } from "../document/calculations-objects/calculation-concrete";
import {
  emptySystemNodeCalculation,
  emptySystemNodeLiveCalculation,
  SystemNodeLiveCalculation,
} from "../document/calculations-objects/system-node-calculation";
import {
  CalculatableEntityConcrete,
  ConnectableEntityConcrete,
} from "../document/entities/concrete-entity";
import PipeEntity from "../document/entities/conduit-entity";
import { SystemNodeEntity } from "../document/entities/system-node-entity";
import { EntityType } from "../document/entities/types";
import { CoreConnectable } from "./core-traits/coreConnectable";
import CoreConduit from "./coreConduit";
import CoreBaseBackedObject from "./lib/coreBaseBackedObject";
import {
  getHeightDiffPipeCostBreakdown,
  getIdentityCalculationEntityUid,
} from "./utils";

export default class CoreSystemNode extends CoreConnectable(
  CoreBaseBackedObject<SystemNodeEntity>,
) {
  type: EntityType.SYSTEM_NODE = EntityType.SYSTEM_NODE;

  getComponentPressureLossKPA(
    options: GetPressureLossOptions,
  ): PressureLossResult {
    const { from, to } = options;
    const otherEnds = [this.entity.parentUid, FLOW_SOURCE_EDGE];
    if (
      otherEnds.includes(from.connection) ||
      otherEnds.includes(to.connection)
    ) {
      return { pressureLossKPA: 0 };
    } else {
      throw new Error(
        "system node shouldn't have any extra joints. " +
          "from: " +
          JSON.stringify(from) +
          " to " +
          JSON.stringify(to) +
          " parent: " +
          this.entity.parentUid,
      );
    }
  }

  override getHash(): string {
    if (!this.entity.parentUid) {
      throw new Error("Parent of the system node is missing");
    }
    const key =
      this.globalStore
        .get<CoreCenteredObjectConcrete>(this.entity.parentUid)
        .getHash() +
      " " +
      this.effectiveCenter.x +
      " " +
      this.effectiveCenter.y;
    // const hash = createHash("sha256");
    // hash.update(key.toString());
    // return hash.digest("hex");
    return key;
  }

  getCalculationEntities(context: CoreContext): CalculatableEntityConcrete[] {
    if (!this.entity.parentUid) {
      throw new Error("Parent of the system node is missing");
    }
    const tower: Array<
      [ConnectableEntityConcrete, PipeEntity] | [ConnectableEntityConcrete]
    > = this.getCalculationTower(context);

    // find the fixture that our parent is connected to, and turn it back into a system node.
    const connected = this.getCalculationNode(context, this.entity.parentUid);
    for (const level of tower) {
      if (level[0].uid === connected.uid) {
        // replace it with a system node (me).
        const se: SystemNodeEntity = cloneSimple(this.entity);
        se.uid = level[0].uid;
        se.calculationHeightM = level[0].calculationHeightM;
        se.parentUid = getIdentityCalculationEntityUid(
          context,
          this.entity.parentUid,
        );
        level[0] = se;
      }
    }

    return tower.flat();
  }

  getImplicitCalculationConnections(): string[] {
    if (!this.entity.parentUid) {
      throw new Error("Parent of the system node is missing");
    }
    return [this.entity.parentUid];
  }

  collectCalculations(context: CoreContext): CalculationConcrete {
    const ce = this.getCalculationEntities(context);

    const ghost = ce.filter((e) => e.type === this.type)[0] as SystemNodeEntity;
    const calc = context.globalStore.getOrCreateCalculation(ghost);

    const res = emptySystemNodeCalculation();
    res.psdUnits = calc.psdUnits;
    res.flowRateLS = calc.flowRateLS;
    res.pressureKPA = calc.pressureKPA;
    res.warnings = calc.warnings;
    res.staticPressureKPA = calc.staticPressureKPA;
    res.returnLoopHeatLossKW = calc.returnLoopHeatLossKW;
    res.returnHeatingSystemLoopHeatLossKW =
      calc.returnHeatingSystemLoopHeatLossKW;
    res.circulationPressureLoss = calc.circulationPressureLoss;
    res.circulationFlowRateLS = calc.circulationFlowRateLS;
    res.circulatingPumpModel = calc.circulatingPumpModel;

    if (calc.circulationFlowRateLS && calc.circulationPressureLoss) {
      const [flowUnits, flowRate] = convertMeasurementSystem(
        context.drawing.metadata.units,
        Units.LitersPerSecond,
        calc.circulationFlowRateLS,
        Precision.DISPLAY,
      );

      const [pressureUnits, duty] = convertMeasurementSystem(
        context.drawing.metadata.units,
        Units.KiloPascals,
        calc.circulationPressureLoss,
        Precision.DISPLAY,
      );

      res.circulationPumpDutyString = `${
        calc.circulatingPumpModel ? calc.circulatingPumpModel + " - " : ""
      }${flowRate} ${flowUnits} @ ${duty} ${pressureUnits}`;
    }

    const tower = this.getCalculationTower(context);

    if (this.getCalculationConnectionGroups(context).flat().length <= 2) {
      // that's fine
    } else {
      throw new Error(
        "Too many connections coming out of the system node " +
          JSON.stringify(this.getCalculationConnections()),
      );
    }

    tower.forEach(([v, p]) => {
      res.warnings = [
        ...(res.warnings || []),
        ...(context.globalStore.getOrCreateCalculation(v).warnings || []),
      ];
    });

    return res;
  }

  collectLiveCalculations(context: CoreContext): SystemNodeLiveCalculation {
    const ce = this.getCalculationEntities(context);
    const ghost = ce.filter((e) => e.type === this.type)[0] as SystemNodeEntity;
    const calc = context.globalStore.getOrCreateLiveCalculation(ghost);

    const res = emptySystemNodeLiveCalculation();
    res.connected = calc.connected;

    if (this.getCalculationConnectionGroups(context).flat().length <= 2) {
      // that's fine
    } else {
      throw new Error(
        "Too many connections coming out of the system node " +
          JSON.stringify(this.getCalculationConnections()),
      );
    }

    return res;
  }

  costBreakdown(context: CoreContext): CostBreakdown | null {
    const heightDiffPipeBreakdown = this.getHeightDiffPipesBreakdown();
    if (heightDiffPipeBreakdown) {
      return {
        cost: heightDiffPipeBreakdown.cost,
        breakdown: heightDiffPipeBreakdown.breakdown,
      };
    }
    return { cost: 0, breakdown: [] };
  }

  getCalculationUid(context: CoreContext): string {
    return this.getCalculationEntities(context)[0].uid;
  }

  preCalculationValidation(context: CoreContext) {
    return null;
  }

  getConnectedPipe(
    connectionUid: string,
    flowSystemUid: string | null,
  ): CoreConduit | undefined {
    for (const itemId of this.globalStore.getConnections(connectionUid)) {
      const item = this.globalStore.get(itemId);
      if (
        item &&
        item.type === EntityType.CONDUIT &&
        (!flowSystemUid || item.entity.systemUid === flowSystemUid)
      ) {
        return item;
      }
    }
  }

  getHeightDiffPipesBreakdown(): CostBreakdown {
    let costBreakdowns: CostBreakdown[] = [];
    let result: CostBreakdown = { cost: 0, breakdown: [] };
    const nodeHeight = this.entity.calculationHeightM;
    const pipe = this.getConnectedPipe(this.entity.uid, this.entity.systemUid);

    if (pipe && nodeHeight !== null) {
      const heightDiffPipeCostBreakdown = getHeightDiffPipeCostBreakdown(
        this.context,
        pipe,
        nodeHeight,
      );
      if (heightDiffPipeCostBreakdown) {
        heightDiffPipeCostBreakdown.breakdown.forEach((item) => {
          item.type = "heightDiffPipe";
          if (isHeightDiffPipeBreakdown(item)) {
            item.systemUid = this.entity.systemUid;
          }
        });
        costBreakdowns.push(heightDiffPipeCostBreakdown);
      }
    }

    result.cost = costBreakdowns.reduce((a, b) => a + b.cost, 0);
    costBreakdowns.forEach((element) => {
      result.breakdown.push(...element.breakdown);
    });
    return result;
  }

  /** Returns the world coord of the stub if there is one */
  getStubWC() {
    const parent = this.parent;
    if (parent !== null && parent.type === EntityType.PLANT) {
      const ioSpec = parent
        .getInletsOutletSpecs()
        .find((spec) => spec.uid === this.uid);
      if (ioSpec) {
        return parent.getSystemNodePositionWorld(this.uid, {
          overshootMM: ioSpec.length,
        });
      }
    }
    return null;
  }

  /** Returns the shape of the stub if there is one */
  getStubShape() {
    const stubWC = this.getStubWC();
    if (stubWC) {
      return Flatten.circle(Flatten.point(stubWC.x, stubWC.y), this.radius);
    }
    return null;
  }
}
