import { ACRUnits, AirChangeRateSpec, SpaceType } from "./types";

export const AIR_CHANGE_RATE_DATA: AirChangeRateSpec = {
  MCSPre2000: {
    data: {
      [SpaceType.Lounge]: [{ unit: ACRUnits.ACH, coeff: 1.5 }],
      [SpaceType.Living]: [{ unit: ACRUnits.ACH, coeff: 1.5 }],
      [SpaceType.Breakfast]: [{ unit: ACRUnits.ACH, coeff: 1.5 }],
      [SpaceType.Dining]: [{ unit: ACRUnits.ACH, coeff: 1.5 }],
      [SpaceType.Kitchen]: [{ unit: ACRUnits.ACH, coeff: 2 }],
      [SpaceType.Family]: [{ unit: ACRUnits.ACH, coeff: 2 }],
      [SpaceType.Hall]: [{ unit: ACRUnits.ACH, coeff: 2 }],
      [SpaceType.CloaksWC]: [{ unit: ACRUnits.ACH, coeff: 2 }],
      [SpaceType.Toilet]: [{ unit: ACRUnits.ACH, coeff: 3 }],
      [SpaceType.Utility]: [{ unit: ACRUnits.ACH, coeff: 3 }],
      [SpaceType.Study]: [{ unit: ACRUnits.ACH, coeff: 1.5 }],
      [SpaceType.Games]: [{ unit: ACRUnits.ACH, coeff: 1.5 }],
      [SpaceType.Bedroom]: [{ unit: ACRUnits.ACH, coeff: 1 }],
      [SpaceType.Bedsitting]: [{ unit: ACRUnits.ACH, coeff: 1.5 }],
      [SpaceType.BedEnsuite]: [{ unit: ACRUnits.ACH, coeff: 2 }],
      [SpaceType.Internal]: [{ unit: ACRUnits.ACH, coeff: 0 }],
      [SpaceType.BedStudy]: [{ unit: ACRUnits.ACH, coeff: 1.5 }],
      [SpaceType.Landing]: [{ unit: ACRUnits.ACH, coeff: 2 }],
      [SpaceType.Bath]: [{ unit: ACRUnits.ACH, coeff: 3 }],
      [SpaceType.Shower]: [{ unit: ACRUnits.ACH, coeff: 3 }],
      [SpaceType.Dressing]: [{ unit: ACRUnits.ACH, coeff: 1.5 }],
      [SpaceType.Store]: [{ unit: ACRUnits.ACH, coeff: 1 }],
    },
  },
  MCSPost2000: {
    data: {
      [SpaceType.Lounge]: [{ unit: ACRUnits.ACH, coeff: 1 }],
      [SpaceType.Living]: [{ unit: ACRUnits.ACH, coeff: 1 }],
      [SpaceType.Breakfast]: [{ unit: ACRUnits.ACH, coeff: 1 }],
      [SpaceType.Dining]: [{ unit: ACRUnits.ACH, coeff: 1 }],
      [SpaceType.Kitchen]: [{ unit: ACRUnits.ACH, coeff: 1.5 }],
      [SpaceType.Family]: [{ unit: ACRUnits.ACH, coeff: 1.5 }],
      [SpaceType.Hall]: [{ unit: ACRUnits.ACH, coeff: 1 }],
      [SpaceType.CloaksWC]: [{ unit: ACRUnits.ACH, coeff: 1.5 }],
      [SpaceType.Toilet]: [{ unit: ACRUnits.ACH, coeff: 1.5 }],
      [SpaceType.Utility]: [{ unit: ACRUnits.ACH, coeff: 2 }],
      [SpaceType.Study]: [{ unit: ACRUnits.ACH, coeff: 1.5 }],
      [SpaceType.Games]: [{ unit: ACRUnits.ACH, coeff: 1 }],
      [SpaceType.Bedroom]: [{ unit: ACRUnits.ACH, coeff: 1 }],
      [SpaceType.Bedsitting]: [{ unit: ACRUnits.ACH, coeff: 1 }],
      [SpaceType.BedEnsuite]: [{ unit: ACRUnits.ACH, coeff: 1.5 }],
      [SpaceType.Internal]: [{ unit: ACRUnits.ACH, coeff: 0 }],
      [SpaceType.BedStudy]: [{ unit: ACRUnits.ACH, coeff: 1.5 }],
      [SpaceType.Landing]: [{ unit: ACRUnits.ACH, coeff: 1 }],
      [SpaceType.Bath]: [{ unit: ACRUnits.ACH, coeff: 1.5 }],
      [SpaceType.Shower]: [{ unit: ACRUnits.ACH, coeff: 1.5 }],
      [SpaceType.Dressing]: [{ unit: ACRUnits.ACH, coeff: 1 }],
      [SpaceType.Store]: [{ unit: ACRUnits.ACH, coeff: 0.5 }],
    },
  },
  MCSPost2006: {
    data: {
      [SpaceType.Lounge]: [{ unit: ACRUnits.ACH, coeff: 0.5 }],
      [SpaceType.Living]: [{ unit: ACRUnits.ACH, coeff: 0.5 }],
      [SpaceType.Breakfast]: [{ unit: ACRUnits.ACH, coeff: 0.5 }],
      [SpaceType.Dining]: [{ unit: ACRUnits.ACH, coeff: 0.5 }],
      [SpaceType.Kitchen]: [{ unit: ACRUnits.ACH, coeff: 1.5 }],
      [SpaceType.Family]: [{ unit: ACRUnits.ACH, coeff: 1.5 }],
      [SpaceType.Hall]: [{ unit: ACRUnits.ACH, coeff: 0.5 }],
      [SpaceType.CloaksWC]: [{ unit: ACRUnits.ACH, coeff: 1.5 }],
      [SpaceType.Toilet]: [{ unit: ACRUnits.ACH, coeff: 1.5 }],
      [SpaceType.Utility]: [{ unit: ACRUnits.ACH, coeff: 0.5 }],
      [SpaceType.Study]: [{ unit: ACRUnits.ACH, coeff: 0.5 }],
      [SpaceType.Games]: [{ unit: ACRUnits.ACH, coeff: 0.5 }],
      [SpaceType.Bedroom]: [{ unit: ACRUnits.ACH, coeff: 0.5 }],
      [SpaceType.Bedsitting]: [{ unit: ACRUnits.ACH, coeff: 0.5 }],
      [SpaceType.BedEnsuite]: [{ unit: ACRUnits.ACH, coeff: 1 }],
      [SpaceType.Internal]: [{ unit: ACRUnits.ACH, coeff: 0 }],
      [SpaceType.BedStudy]: [{ unit: ACRUnits.ACH, coeff: 0.5 }],
      [SpaceType.Landing]: [{ unit: ACRUnits.ACH, coeff: 0.5 }],
      [SpaceType.Bath]: [{ unit: ACRUnits.ACH, coeff: 1.5 }],
      [SpaceType.Shower]: [{ unit: ACRUnits.ACH, coeff: 1.5 }],
      [SpaceType.Dressing]: [{ unit: ACRUnits.ACH, coeff: 0.5 }],
      [SpaceType.Store]: [{ unit: ACRUnits.ACH, coeff: 0.5 }],
    },
  },
  UKBuildingRegs2021Dwellings: {
    data: {
      [SpaceType.Lounge]: [{ unit: ACRUnits.Ls, coeff: 0 }],
      [SpaceType.Living]: [{ unit: ACRUnits.Ls, coeff: 0 }],
      [SpaceType.Breakfast]: [{ unit: ACRUnits.Ls, coeff: 0 }],
      [SpaceType.Dining]: [{ unit: ACRUnits.Ls, coeff: 0 }],
      [SpaceType.Kitchen]: [{ unit: ACRUnits.Ls, coeff: 13 }],
      [SpaceType.Family]: [{ unit: ACRUnits.Ls, coeff: 0 }],
      [SpaceType.Hall]: [{ unit: ACRUnits.Ls, coeff: 0 }],
      [SpaceType.CloaksWC]: [{ unit: ACRUnits.Ls, coeff: 6 }],
      [SpaceType.Toilet]: [{ unit: ACRUnits.Ls, coeff: 6 }],
      [SpaceType.Utility]: [{ unit: ACRUnits.Ls, coeff: 8 }],
      [SpaceType.Study]: [{ unit: ACRUnits.Ls, coeff: 0 }],
      [SpaceType.Games]: [{ unit: ACRUnits.Ls, coeff: 0 }],
      [SpaceType.Bedroom]: [{ unit: ACRUnits.Ls, coeff: 0 }],
      [SpaceType.Bedsitting]: [{ unit: ACRUnits.Ls, coeff: 0 }],
      [SpaceType.BedEnsuite]: [{ unit: ACRUnits.Ls, coeff: 8 }],
      [SpaceType.Internal]: [{ unit: ACRUnits.Ls, coeff: 0 }],
      [SpaceType.BedStudy]: [{ unit: ACRUnits.Ls, coeff: 0 }],
      [SpaceType.Landing]: [{ unit: ACRUnits.Ls, coeff: 0 }],
      [SpaceType.Bath]: [{ unit: ACRUnits.Ls, coeff: 8 }],
      [SpaceType.Shower]: [{ unit: ACRUnits.Ls, coeff: 8 }],
      [SpaceType.Dressing]: [{ unit: ACRUnits.Ls, coeff: 0 }],
      [SpaceType.Store]: [{ unit: ACRUnits.Ls, coeff: 0 }],
    },
  },
  UKBuildingRegs2021NonDwellings: {
    data: {
      [SpaceType.Office]: [{ unit: ACRUnits.LsPerM2, coeff: 1 }],
      [SpaceType.OfficeCommonSpace]: [{ unit: ACRUnits.LsPerM2, coeff: 0.5 }],
      [SpaceType.Bathroom]: [
        { unit: ACRUnits.LsPerShower, coeff: 15 },
        { unit: ACRUnits.LsPerBath, coeff: 15 },
        { unit: ACRUnits.LsPerWC, coeff: 6 },
        { unit: ACRUnits.LsPerUrinal, coeff: 6 },
      ],
      [SpaceType.Kitchen]: [{ unit: ACRUnits.Ls, coeff: 30 }],
      [SpaceType.Kitchenette]: [{ unit: ACRUnits.Ls, coeff: 15 }],
      [SpaceType.PrintRoom]: [{ unit: ACRUnits.LsPerMachine, coeff: 20 }],
      [SpaceType.CarPark]: [{ unit: ACRUnits.ACH, coeff: 3 }],
      [SpaceType.BasementCarPark]: [{ unit: ACRUnits.ACH, coeff: 6 }],
      [SpaceType.CarParkQueueArea]: [{ unit: ACRUnits.ACH, coeff: 10 }],
      [SpaceType.General]: [{ unit: ACRUnits.LsPerPerson, coeff: 8 }],
      [SpaceType.OperatingTheatre]: [{ unit: ACRUnits.M3s, coeff: 1 }],
      [SpaceType.ChangingRooms]: [{ unit: ACRUnits.ACH, coeff: 10 }],
      [SpaceType.SwimmingPoolHall]: [{ unit: ACRUnits.LsPerM2, coeff: 15 }],
      [SpaceType.SquashCourt]: [{ unit: ACRUnits.ACH, coeff: 4 }],
      [SpaceType.IceRink]: [{ unit: ACRUnits.ACH, coeff: 3 }],
      [SpaceType.AssemblyHalls]: [{ unit: ACRUnits.ACH, coeff: 6 }],
      [SpaceType.Dwelling]: [{ unit: ACRUnits.ACH, coeff: 0.5 }],
      [SpaceType.HotelBathroom]: [{ unit: ACRUnits.LsPerPerson, coeff: 12 }],
      [SpaceType.HospitalRecoveryRoom]: [{ unit: ACRUnits.ACH, coeff: 15 }],
      [SpaceType.HospitalTreatmentRoom]: [{ unit: ACRUnits.ACH, coeff: 6 }],
      [SpaceType.BackOfHouse]: [{ unit: ACRUnits.ACH, coeff: 15 }],
      [SpaceType.Laboratory]: [{ unit: ACRUnits.ACH, coeff: 10 }],
      [SpaceType.Darkroom]: [{ unit: ACRUnits.ACH, coeff: 7 }],
      [SpaceType.Horticulture]: [{ unit: ACRUnits.ACH, coeff: 55 }],
    },
  },
  CIBSE2016: {
    data: {
      [SpaceType.General]: [{ unit: ACRUnits.LsPerPerson, coeff: 8 }],
      [SpaceType.Toilet]: [{ unit: ACRUnits.ACH, coeff: 5 }],
      [SpaceType.Bathroom]: [{ unit: ACRUnits.Ls, coeff: 15 }],
      [SpaceType.HotelBathroom]: [{ unit: ACRUnits.LsPerPerson, coeff: 12 }],
      [SpaceType.ChangingRooms]: [{ unit: ACRUnits.ACH, coeff: 10 }],
      [SpaceType.Kitchen]: [{ unit: ACRUnits.Ls, coeff: 60 }],
      [SpaceType.Bedroom]: [{ unit: ACRUnits.ACH, coeff: 0.7 }],
      [SpaceType.LivingRoom]: [{ unit: ACRUnits.ACH, coeff: 0.7 }],
      [SpaceType.AssemblyHalls]: [{ unit: ACRUnits.ACH, coeff: 6 }],
      [SpaceType.Dwelling]: [{ unit: ACRUnits.ACH, coeff: 0.5 }],
      [SpaceType.BackOfHouse]: [{ unit: ACRUnits.ACH, coeff: 15 }],
      [SpaceType.Laboratory]: [{ unit: ACRUnits.ACH, coeff: 10 }],
      [SpaceType.OperatingTheatre]: [{ unit: ACRUnits.M3s, coeff: 1 }],
      [SpaceType.HospitalRecoveryRoom]: [{ unit: ACRUnits.ACH, coeff: 15 }],
      [SpaceType.HospitalTreatmentRoom]: [{ unit: ACRUnits.ACH, coeff: 6 }],
      [SpaceType.SwimmingPoolHall]: [{ unit: ACRUnits.LsPerM2, coeff: 15 }],
      [SpaceType.Darkroom]: [{ unit: ACRUnits.ACH, coeff: 7 }],
      [SpaceType.Horticulture]: [{ unit: ACRUnits.ACH, coeff: 55 }],
      [SpaceType.SquashCourt]: [{ unit: ACRUnits.ACH, coeff: 4 }],
      [SpaceType.IceRink]: [{ unit: ACRUnits.ACH, coeff: 3 }],
    },
  },
  "ASHRAE62.1": {
    data: {
      [SpaceType.AnimalFacility]: [{ unit: ACRUnits.CfmPerF2, coeff: 2.45 }],
      [SpaceType.CorrectionalFacility]: [
        { unit: ACRUnits.CfmPerF2, coeff: 0.135 },
      ],
      [SpaceType.ArtRoom]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.9 }],
      [SpaceType.Classroom]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.37 }],
      [SpaceType.Library]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.17 }],
      [SpaceType.LectureHall]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.11 }],
      [SpaceType.Laboratory]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.43 }],
      [SpaceType.Kitchen]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.27 }],
      [SpaceType.CommercialKitchen]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.7 }],
      [SpaceType.Bar]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.93 }],
      [SpaceType.Dining]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.93 }],
      [SpaceType.Office]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.16 }],
      [SpaceType.MeetingRoom]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.31 }],
      [SpaceType.Bedroom]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.11 }],
      [SpaceType.LivingRoom]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.11 }],
      [SpaceType.CommercialLaundry]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.17 }],
      [SpaceType.Laundry]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.17 }],
      [SpaceType.Corridor]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.06 }],
      [SpaceType.ComputerRoom]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.08 }],
      [SpaceType.Manufacturing]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.25 }],
      [SpaceType.Pharmacy]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.13 }],
      [SpaceType.PhotoStudio]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.17 }],
      [SpaceType.Warehouse]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.36 }],
      [SpaceType.Auditorium]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.81 }],
      [SpaceType.Museum]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.36 }],
      [SpaceType.ReligiousWorship]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.66 }],
      [SpaceType.Barbershop]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.69 }],
      [SpaceType.Salon]: [{ unit: ACRUnits.CfmPerF2, coeff: 1.1 }],
      [SpaceType.Mall]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.36 }],
      [SpaceType.Supermarket]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.12 }],
      [SpaceType.Retail]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.23 }],
      [SpaceType.Gym]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.2 }],
      [SpaceType.BowlingAlley]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.52 }],
      [SpaceType.DanceFloor]: [{ unit: ACRUnits.CfmPerF2, coeff: 2.06 }],
      [SpaceType.Casino]: [{ unit: ACRUnits.CfmPerF2, coeff: 1.18 }],
      [SpaceType.Arcade]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.33 }],
      [SpaceType.SportsArena]: [{ unit: ACRUnits.CfmPerF2, coeff: 0.32 }],
      [SpaceType.Toilet]: [{ unit: ACRUnits.Cfm, coeff: 50 }],
      [SpaceType.Trash]: [{ unit: ACRUnits.CfmPerF2, coeff: 1 }],
    },
  },
  "AS1668.2-2012": {
    data: {
      [SpaceType.Bar]: [{ unit: ACRUnits.LsPerM2, coeff: 10 }],
      [SpaceType.Bathroom]: [{ unit: ACRUnits.LsPerM2, coeff: 10 }],
      [SpaceType.Bedroom]: [{ unit: ACRUnits.LsPerM2, coeff: 1 }],
      [SpaceType.Cafe]: [{ unit: ACRUnits.LsPerM2, coeff: 10 }],
      [SpaceType.ClassroomAbove16]: [{ unit: ACRUnits.LsPerM2, coeff: 5 }],
      [SpaceType.ClassroomBelow16]: [{ unit: ACRUnits.LsPerM2, coeff: 6 }],
      [SpaceType.ConferenceRoomLarge]: [{ unit: ACRUnits.LsPerM2, coeff: 10 }],
      [SpaceType.ConferenceRoomSmall]: [{ unit: ACRUnits.LsPerM2, coeff: 5 }],
      [SpaceType.DiningRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 6.7 }],
      [SpaceType.Dormitory]: [{ unit: ACRUnits.LsPerM2, coeff: 10 }],
      [SpaceType.Kitchen]: [{ unit: ACRUnits.LsPerM2, coeff: 5 }],
      [SpaceType.Library]: [{ unit: ACRUnits.LsPerM2, coeff: 2 }],
      [SpaceType.LivingRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 1 }],
      [SpaceType.Lobby]: [{ unit: ACRUnits.LsPerM2, coeff: 1 }],
      [SpaceType.Lounge]: [{ unit: ACRUnits.LsPerM2, coeff: 6.7 }],
      [SpaceType.MeetingRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 10 }],
      [SpaceType.Office]: [{ unit: ACRUnits.LsPerM2, coeff: 1 }],
      [SpaceType.Restroom]: [{ unit: ACRUnits.LsPerM2, coeff: 10 }],
      [SpaceType.StoreRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 1 }],
      [SpaceType.Supermarket]: [{ unit: ACRUnits.LsPerM2, coeff: 10 }],
      [SpaceType.Toilet]: [{ unit: ACRUnits.LsPerRoom, coeff: 25 }],
      [SpaceType.WaitingArea]: [{ unit: ACRUnits.LsPerM2, coeff: 6.7 }],
      [SpaceType.Arcade]: [{ unit: ACRUnits.LsPerM2, coeff: 2 }],
      [SpaceType.Barbershop]: [{ unit: ACRUnits.LsPerM2, coeff: 2.5 }],
      [SpaceType.Salon]: [{ unit: ACRUnits.LsPerM2, coeff: 2.5 }],
      [SpaceType.Casino]: [{ unit: ACRUnits.LsPerM2, coeff: 6.7 }],
      [SpaceType.Chapel]: [{ unit: ACRUnits.LsPerM2, coeff: 16.7 }],
      [SpaceType.ConsultationRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 2.9 }],
      [SpaceType.Corridor]: [{ unit: ACRUnits.LsPerM2, coeff: 1 }],
      [SpaceType.ExerciseRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 2 }],
      [SpaceType.FastFoodOutlet]: [{ unit: ACRUnits.LsPerM2, coeff: 10 }],
      [SpaceType.FittingRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 10 }],
      [SpaceType.Florist]: [{ unit: ACRUnits.LsPerM2, coeff: 1 }],
      [SpaceType.Foyer]: [{ unit: ACRUnits.LsPerM2, coeff: 1 }],
      [SpaceType.GamesRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 10 }],
      [SpaceType.HealthSpa]: [{ unit: ACRUnits.LsPerM2, coeff: 2 }],
      [SpaceType.HighActivityArea]: [{ unit: ACRUnits.LsPerM2, coeff: 1.5 }],
      [SpaceType.Laboratory]: [{ unit: ACRUnits.LsPerM2, coeff: 2.9 }],
      [SpaceType.LowActivityArea]: [{ unit: ACRUnits.LsPerM2, coeff: 1 }],
      [SpaceType.Mall]: [{ unit: ACRUnits.LsPerM2, coeff: 2 }],
      [SpaceType.MediumActivityArea]: [{ unit: ACRUnits.LsPerM2, coeff: 1.5 }],
      [SpaceType.Museum]: [{ unit: ACRUnits.LsPerM2, coeff: 1 }],
      [SpaceType.MusicRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 5 }],
      [SpaceType.NightClub]: [{ unit: ACRUnits.LsPerM2, coeff: 15 }],
      [SpaceType.PatientRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 1 }],
      [SpaceType.Pharmacy]: [{ unit: ACRUnits.LsPerM2, coeff: 2 }],
      [SpaceType.PhysicalTherapyRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 2 }],
      [SpaceType.SalesFloorGround]: [{ unit: ACRUnits.LsPerM2, coeff: 2.9 }],
      [SpaceType.SalesFloorUpper]: [{ unit: ACRUnits.LsPerM2, coeff: 2 }],
      [SpaceType.Studio]: [{ unit: ACRUnits.LsPerM2, coeff: 6.7 }],
      [SpaceType.TrainingShop]: [{ unit: ACRUnits.LsPerM2, coeff: 2.9 }],
      [SpaceType.AirportGates]: [{ unit: ACRUnits.LsPerM2, coeff: 6.7 }],
      [SpaceType.AirportHangars]: [{ unit: ACRUnits.LsPerM2, coeff: 0.2 }],
      [SpaceType.Amphitheatres]: [{ unit: ACRUnits.LsPerM2, coeff: 16.7 }],
      [SpaceType.AnimalRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 5 }],
      [SpaceType.AutopsyRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 5 }],
      [SpaceType.Ballroom]: [{ unit: ACRUnits.LsPerM2, coeff: 10 }],
      [SpaceType.Bank]: [{ unit: ACRUnits.LsPerM2, coeff: 1 }],
      [SpaceType.BowlingAlley]: [{ unit: ACRUnits.LsPerM2, coeff: 10 }],
      [SpaceType.Brothel]: [{ unit: ACRUnits.LsPerM2, coeff: 2 }],
      [SpaceType.Cabarets]: [{ unit: ACRUnits.LsPerM2, coeff: 6.7 }],
      [SpaceType.CameraRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 1 }],
      [SpaceType.ChangeRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 5 }],
      [SpaceType.ConcertHall]: [{ unit: ACRUnits.LsPerM2, coeff: 16.7 }],
      [SpaceType.Concourses]: [{ unit: ACRUnits.LsPerM2, coeff: 16.7 }],
      [SpaceType.DeliveryRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 4 }],
      [SpaceType.DressingRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 5 }],
      [SpaceType.EatingHall]: [{ unit: ACRUnits.LsPerM2, coeff: 6.7 }],
      [SpaceType.ElectricalRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 4 }],
      [SpaceType.FoodPrep]: [{ unit: ACRUnits.LsPerM2, coeff: 2.9 }],
      [SpaceType.GarbageRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 5 }],
      [SpaceType.Greenhouse]: [{ unit: ACRUnits.LsPerM2, coeff: 0.1 }],
      [SpaceType.Hairdresser]: [{ unit: ACRUnits.LsPerM2, coeff: 3.8 }],
      [SpaceType.IntensiveCareRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 2 }],
      [SpaceType.Kiosk]: [{ unit: ACRUnits.LsPerM2, coeff: 10 }],
      [SpaceType.LaundryCoinOperated]: [{ unit: ACRUnits.LsPerM2, coeff: 2 }],
      [SpaceType.LaundryStorage]: [{ unit: ACRUnits.LsPerM2, coeff: 2.9 }],
      [SpaceType.MeatProcessing]: [{ unit: ACRUnits.LsPerM2, coeff: 0.5 }],
      [SpaceType.OperatingRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 4 }],
      [SpaceType.PlantRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 5 }],
      [SpaceType.PrintingRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 2.9 }],
      [SpaceType.PrisonCell]: [{ unit: ACRUnits.LsPerM2, coeff: 2 }],
      [SpaceType.PrisonGuardStation]: [{ unit: ACRUnits.LsPerM2, coeff: 4 }],
      [SpaceType.ProcedureArea]: [{ unit: ACRUnits.LsPerM2, coeff: 1 }],
      [SpaceType.RecoveryRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 2 }],
      [SpaceType.SpectatorArea]: [{ unit: ACRUnits.LsPerM2, coeff: 16.7 }],
      [SpaceType.SportsPlayingFloor]: [{ unit: ACRUnits.LsPerM2, coeff: 2.9 }],
      [SpaceType.Stairs]: [{ unit: ACRUnits.LsPerM2, coeff: 1 }],
      [SpaceType.Suite]: [{ unit: ACRUnits.LsPerM2, coeff: 2 }],
      [SpaceType.SwimmingPoolArea]: [{ unit: ACRUnits.LsPerM2, coeff: 10 }],
      [SpaceType.TicketAreas]: [{ unit: ACRUnits.LsPerM2, coeff: 6.7 }],
      [SpaceType.UtilityRoom]: [{ unit: ACRUnits.LsPerM2, coeff: 1 }],
      [SpaceType.Warehouse]: [{ unit: ACRUnits.LsPerM2, coeff: 0.5 }],
    },
  },
};
