import { MixingValveManufacturer } from "../../../../../common/src/api/catalog/types";
import { StandardFlowSystemUids } from "../../../../../common/src/api/config";
import { SelectedMaterialManufacturer } from "../../../../../common/src/api/document/drawing";
import BigValveEntity, {
  BigValveType,
} from "../../../../../common/src/api/document/entities/big-valve/big-valve-entity";
import { getFlowSystem } from "../../../../../common/src/api/document/utils";
import { Units } from "../../../../../common/src/lib/measurements";
import { assertUnreachable } from "../../../../../common/src/lib/utils";
import { CoreContext } from "../../calculations/types";
import { PsdCountEntry } from "../../calculations/utils";
import { CalculationField, FieldCategory } from "./calculation-field";
import {
  CalcFieldSelector,
  Calculation,
  CalculationType,
  LiveCalculation,
  NameCalculation,
} from "./types";

export interface BigValveLiveCalculation extends LiveCalculation {}

export const FastBigValveCalculationFields: CalcFieldSelector<BigValveLiveCalculation> =
  {
    connected: true,
  };

export default interface BigValveCalculation
  extends Calculation,
    NameCalculation {
  type: CalculationType.BigValveCalculation;
  coldTemperatureC: number | null;
  coldPressureKPA: number | null;
  coldRawPeakFlowRate: number | null;
  coldPeakFlowRate: number | null;
  coldPsdUs: PsdCountEntry | null;

  hotTemperatureC: number | null;
  hotPressureKPA: number | null;
  hotRawPeakFlowRate: number | null;
  hotPeakFlowRate: number | null;
  hotPsdUs: PsdCountEntry | null;
  hotReturnFlowRateLS: number | null;
  hotTotalFlowRateLS: number | null;

  outputs: {
    [key: string]: {
      temperatureC: number | null;
      pressureDropKPA: number | null;
    };
  };

  rpzdSizeMM: {
    [key: string]: number | null;
  } | null;

  mixingValveSizeMM: number | null;
}

export function makeBigValveCalculationFields(
  context: CoreContext,
  entity: BigValveEntity,
): CalculationField[] {
  const { catalog, drawing } = context;
  const result: CalculationField[] = [];

  result.push({
    property: "reference",
    title: "Reference",
    short: "",
    shortTitle: "",
    units: Units.None,
    category: FieldCategory.EntityName,
    defaultEnabled: false,
    layouts: ["pressure"],
  });

  const suids: string[] = [];
  const attachments: string[] = [];
  switch (entity.valve.type) {
    case BigValveType.TMV:
      suids.push(
        StandardFlowSystemUids.WarmWater,
        StandardFlowSystemUids.ColdWater,
      );
      attachments.push(entity.valve.warmOutputUid, entity.valve.coldOutputUid);
      break;
    case BigValveType.TEMPERING:
      suids.push(StandardFlowSystemUids.WarmWater);
      attachments.push(entity.valve.warmOutputUid);
      break;
    case BigValveType.RPZD_HOT_COLD:
      suids.push(
        StandardFlowSystemUids.HotWater,
        StandardFlowSystemUids.ColdWater,
      );
      attachments.push(entity.valve.hotOutputUid, entity.valve.coldOutputUid);

      for (let i = 0; i < 2; i++) {
        const system = getFlowSystem(drawing, suids[i])!;
        result.push({
          property: "rpzdSizeMM." + suids[i],
          title: system.name + " RPZD Size",
          short: "",
          systemUid: suids[i],
          units: Units.Millimeters,
          category: FieldCategory.Size,
          layouts: ["pressure"],
        });
      }

      break;
    default:
      assertUnreachable(entity.valve);
  }

  for (let i = 0; i < suids.length; i++) {
    const system = getFlowSystem(drawing, suids[i])!;
    result.push({
      property: "outputs." + suids[i] + ".pressureDropKPA",
      title: system.name + " Pressure Drop",
      short: "",
      attachUid: attachments[i] as string,
      systemUid: suids[i],
      units: Units.KiloPascals,
      category: FieldCategory.Pressure,
      layouts: ["pressure"],
    });
  }

  if (entity.valve.type !== BigValveType.RPZD_HOT_COLD) {
    const manufacturer =
      drawing.metadata.catalog.mixingValves.find(
        (material: SelectedMaterialManufacturer) =>
          material.uid === entity.valve.catalogId,
      )?.manufacturer || "generic";
    const abbreviation =
      (manufacturer !== "generic" &&
        catalog?.mixingValves[entity.valve.catalogId].manufacturer.find(
          (manufacturerObj: MixingValveManufacturer) =>
            manufacturerObj.uid === manufacturer,
        )?.abbreviation) ||
      "";

    result.push({
      property: "mixingValveSizeMM",
      title: "Size",
      attachUid: entity.uid,
      short: abbreviation,
      units: (manufacturer !== "enware" && Units.Millimeters) || Units.None,
      category: FieldCategory.Size,
      format: (v) => {
        return Number(v).toString();
      },
      layouts: ["pressure"],
    });
  }

  return result;
}

export function emptyBigValveCalculations(
  entity: BigValveEntity,
): BigValveCalculation {
  const result: BigValveCalculation = {
    type: CalculationType.BigValveCalculation,
    cost: null,
    costBreakdown: null,
    expandedEntities: null,

    coldPressureKPA: null,
    coldTemperatureC: null,
    coldRawPeakFlowRate: null,
    coldPeakFlowRate: null,
    coldPsdUs: null,

    hotPressureKPA: null,
    hotTemperatureC: null,
    hotRawPeakFlowRate: null,
    hotPeakFlowRate: null,
    hotPsdUs: null,
    hotTotalFlowRateLS: null,
    hotReturnFlowRateLS: null,

    outputs: {},
    rpzdSizeMM: {},
    mixingValveSizeMM: null,

    warnings: null,

    entityName: null,
  };

  const suids: string[] = [];
  switch (entity.valve.type) {
    case BigValveType.TMV:
      suids.push(
        StandardFlowSystemUids.WarmWater,
        StandardFlowSystemUids.ColdWater,
      );
      break;
    case BigValveType.TEMPERING:
      suids.push(StandardFlowSystemUids.WarmWater);
      break;
    case BigValveType.RPZD_HOT_COLD:
      suids.push(
        StandardFlowSystemUids.HotWater,
        StandardFlowSystemUids.ColdWater,
      );

      result.rpzdSizeMM = {};
      for (const suid of suids) {
        result.rpzdSizeMM[suid] = null;
      }
      break;
    default:
      assertUnreachable(entity.valve);
  }

  for (const suid of suids) {
    result.outputs[suid] = {
      temperatureC: null,
      pressureDropKPA: null,
    };
  }

  return result;
}

export function emptyBigValveLiveCalculation(): BigValveLiveCalculation {
  return {
    connected: null,
    warnings: [],
  };
}
