import { User } from "./User";

export enum ErrorStatus {
  NEW,
  DOING,
  RESOLVED,
  HIDDEN,
}

export interface ErrorReport {
  id: number;
  user: User | null;
  ip: string;
  name: string;
  url: string;
  message: string;
  trace: string;
  threwOn: Date;
  appVersion: string;
  status: ErrorStatus;
}
