import { assertUnreachable } from "../../lib/utils";
import { CoreContext } from "../calculations/types";
import { CoreObjectConcrete } from "../coreObjects";
import CoreAnnotation from "../coreObjects/coreAnnotation";
import CoreArchitectureElement from "../coreObjects/coreArchitectureElement";
import CoreAreaSegment from "../coreObjects/coreAreaSegment";
import CoreBackgroundImage from "../coreObjects/coreBackgroundImage";
import CoreBigValve from "../coreObjects/coreBigValve";
import CoreConduit from "../coreObjects/coreConduit";
import CoreDamper from "../coreObjects/coreDamper";
import CoreDirectedValve from "../coreObjects/coreDirectedValve";
import CoreEdge from "../coreObjects/coreEdge";
import CoreFen from "../coreObjects/coreFenestration";
import CoreFitting from "../coreObjects/coreFitting";
import CoreFixture from "../coreObjects/coreFixture";
import CoreFlowSource from "../coreObjects/coreFlowSource";
import CoreGasAppliance from "../coreObjects/coreGasAppliance";
import CoreLine from "../coreObjects/coreLine";
import CoreLoadNode from "../coreObjects/coreLoadNode";
import CoreMultiwayValve from "../coreObjects/coreMultiwayValve";
import CorePlant from "../coreObjects/corePlant";
import CoreRiser from "../coreObjects/coreRiser";
import CoreRoom from "../coreObjects/coreRoom";
import CoreSystemNode from "../coreObjects/coreSystemNode";
import CoreVertex from "../coreObjects/coreVertex";
import CoreWall from "../coreObjects/coreWall";
import { AnnotationEntity } from "./entities/annotations/annotation-entity";
import { ArchitectureElementEntity } from "./entities/architectureElement-entity";
import AreaSegmentEntity from "./entities/area-segment-entity";
import { BackgroundEntity } from "./entities/background-entity";
import BigValveEntity from "./entities/big-valve/big-valve-entity";
import { DrawableEntityConcrete } from "./entities/concrete-entity";
import ConduitEntity from "./entities/conduit-entity";
import DamperEntity from "./entities/damper-entity";
import DirectedValveEntity from "./entities/directed-valves/directed-valve-entity";
import { EdgeEntity } from "./entities/edge-entity";
import { FenEntity } from "./entities/fenestration-entity";
import { FittingEntity } from "./entities/fitting-entity";
import FixtureEntity from "./entities/fixtures/fixture-entity";
import FlowSourceEntity from "./entities/flow-source-entity";
import GasApplianceEntity from "./entities/gas-appliance";
import { LineEntity } from "./entities/lines/line-entity";
import LoadNodeEntity from "./entities/load-node-entity";
import { MultiwayValveEntity } from "./entities/multiway-valves/multiway-valve-entity";
import PlantEntity from "./entities/plants/plant-entity";
import RiserEntity from "./entities/riser-entity";
import { RoomEntity } from "./entities/rooms/room-entity";
import { SystemNodeEntity } from "./entities/system-node-entity";
import { EntityType } from "./entities/types";
import { VertexEntity } from "./entities/vertices/vertex-entity";
import { WallEntity } from "./entities/wall-entity";

type CoreCtorArgs<T> = [context: CoreContext, obj: T];

export default class CoreObjectFactory {
  static build(
    entity: DrawableEntityConcrete,
    context: CoreContext,
    levelUid: string | null,
  ) {
    const args: CoreCtorArgs<DrawableEntityConcrete> = [context, entity];

    const object = ((): CoreObjectConcrete => {
      switch (args[1].type) {
        case EntityType.BACKGROUND_IMAGE:
          return new CoreBackgroundImage(
            ...(args as CoreCtorArgs<BackgroundEntity>),
          );
        case EntityType.BIG_VALVE:
          return new CoreBigValve(...(args as CoreCtorArgs<BigValveEntity>));
        case EntityType.CONDUIT:
          return new CoreConduit(...(args as CoreCtorArgs<ConduitEntity>));
        case EntityType.RISER:
          return new CoreRiser(...(args as CoreCtorArgs<RiserEntity>));
        case EntityType.FITTING:
          return new CoreFitting(...(args as CoreCtorArgs<FittingEntity>));
        case EntityType.SYSTEM_NODE:
          return new CoreSystemNode(
            ...(args as CoreCtorArgs<SystemNodeEntity>),
          );
        case EntityType.FIXTURE:
          return new CoreFixture(...(args as CoreCtorArgs<FixtureEntity>));
        case EntityType.LOAD_NODE:
          return new CoreLoadNode(...(args as CoreCtorArgs<LoadNodeEntity>));
        case EntityType.DIRECTED_VALVE:
          return new CoreDirectedValve(
            ...(args as CoreCtorArgs<DirectedValveEntity>),
          );
        case EntityType.MULTIWAY_VALVE:
          return new CoreMultiwayValve(
            ...(args as CoreCtorArgs<MultiwayValveEntity>),
          );
        case EntityType.FLOW_SOURCE:
          return new CoreFlowSource(
            ...(args as CoreCtorArgs<FlowSourceEntity>),
          );
        case EntityType.GAS_APPLIANCE:
          return new CoreGasAppliance(
            ...(args as CoreCtorArgs<GasApplianceEntity>),
          );
        case EntityType.PLANT:
          return new CorePlant(...(args as CoreCtorArgs<PlantEntity>));
        case EntityType.EDGE:
          return new CoreEdge(...(args as CoreCtorArgs<EdgeEntity>));
        case EntityType.VERTEX:
          return new CoreVertex(...(args as CoreCtorArgs<VertexEntity>));
        case EntityType.ROOM:
          return new CoreRoom(...(args as CoreCtorArgs<RoomEntity>));
        case EntityType.COMPOUND:
          throw new Error("Compound objects are not supported");
        case EntityType.WALL:
          return new CoreWall(...(args as CoreCtorArgs<WallEntity>));
        case EntityType.FENESTRATION:
          return new CoreFen(...(args as CoreCtorArgs<FenEntity>));
        case EntityType.LINE:
          return new CoreLine(...(args as CoreCtorArgs<LineEntity>));
        case EntityType.ANNOTATION:
          return new CoreAnnotation(
            ...(args as CoreCtorArgs<AnnotationEntity>),
          );
        case EntityType.ARCHITECTURE_ELEMENT:
          return new CoreArchitectureElement(
            ...(args as CoreCtorArgs<ArchitectureElementEntity>),
          );
        case EntityType.AREA_SEGMENT:
          return new CoreAreaSegment(
            ...(args as CoreCtorArgs<AreaSegmentEntity>),
          );
        case EntityType.DAMPER:
          return new CoreDamper(...(args as CoreCtorArgs<DamperEntity>));
      }
      assertUnreachable(args[1]);
    })();

    context.globalStore.set(entity.uid, object, levelUid);

    return object;
  }
}
