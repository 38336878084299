import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";
import ReconnectingWebSocket, { CloseEvent } from "reconnecting-websocket";
import { CURRENT_VERSION } from "../../../common/src/api/config";
import {
  DrawingState,
  GeneralInfo,
} from "../../../common/src/api/document/drawing";
import * as OT from "../../../common/src/api/document/operation-transforms";
import {
  APIResult,
  DocumentClientMessage,
  DocumentWSMessageType,
} from "../../../common/src/api/document/types";
import { SupportedLocales } from "../../../common/src/api/locale";
import { assertUnreachable } from "../../../common/src/lib/utils";
import { Document } from "../../../common/src/models/Document";
import { Operation } from "../../../common/src/models/Operation";
import { RemoteClonePayload } from "../../../common/src/models/RemoteClonePayload";
import { getAutoRefreshedAccessToken } from "../auth/auth-utils";
import { getFailureFromAxiosRequestErrorEvent } from "../lib/axios-utils";

const ongoingWebsockets = new Map<number | string, ReconnectingWebSocket>();

// For debugging
(window as any).CURRENT_VERSION = CURRENT_VERSION;

export async function openDocument(
  id: number,
  sharedToken: string | null,
  operationNextIdFunc: () => number,
  onOperation: (ot: OT.OperationTransformConcrete) => void,
  onDeleted: () => void,
  onLoaded: () => void,
  onError: (msg: string) => void,
) {
  const url = sharedToken
    ? "/api/documents/share/" + sharedToken
    : "/api/documents/" + id;

  let accessToken: string = "";
  if (!sharedToken) {
    try {
      accessToken =
        (await fetchAuthSession()).tokens?.idToken?.toString() ?? "";
    } catch (e) {
      console.error(e);
      onError("Error getting access token");
    }
  }

  function websocketUrl() {
    const lastOpId = operationNextIdFunc() - 1;
    const urlParams = new URLSearchParams();

    urlParams.append(
      "drawingVersion",
      (window as any).CURRENT_VERSION.toString(),
    );

    // TODO this should be sent in the headers but the library doesn't support this yet
    // https://github.com/joewalnes/reconnecting-websocket/issues/103
    urlParams.append("accessToken", accessToken);

    if (lastOpId > 0) {
      urlParams.append("lastOpId", lastOpId.toString());
    }

    const baseWebsocketUrl = new URL(url, HOST);
    baseWebsocketUrl.pathname += "/websocket";
    baseWebsocketUrl.search = urlParams.toString();

    return baseWebsocketUrl.toString();
  }

  let connectionAttempt = 0;
  if (ongoingWebsockets.has(id)) {
    throw new Error("warning: Document is already open");
  }
  const HOST = location.origin.replace(/^http(s?)/, "ws$1");
  const ws = new ReconnectingWebSocket(websocketUrl, undefined, {
    maxRetries: 5,
  });
  ongoingWebsockets.set(id, ws);

  ws.onmessage = (wsmsg: MessageEvent) => {
    if (wsmsg.type === "message") {
      const data: DocumentClientMessage = JSON.parse(wsmsg.data as string);
      data.forEach((msg) => {
        switch (msg.type) {
          case DocumentWSMessageType.OPERATION:
            onOperation(msg.operation);
            break;
          case DocumentWSMessageType.DOCUMENT_DELETED:
            onDeleted();
            break;
          case DocumentWSMessageType.DOCUMENT_LOADED:
            onLoaded();
            break;
          case DocumentWSMessageType.DOCUMENT_ERROR:
          case DocumentWSMessageType.DOCUMENT_UPDATE:
            onError(msg.message);
            break;
          default:
            assertUnreachable(msg);
        }
      });
    } else {
      throw new Error(
        "unknown websocket message type " +
          JSON.stringify(wsmsg.type) +
          " " +
          JSON.stringify(wsmsg),
      );
    }
  };

  queues.set(id, []);

  ws.onclose = (ev: CloseEvent) => {
    if (ev.code !== 1000) {
      connectionAttempt++;
      if (connectionAttempt > 4) {
        onError(ev.code + " " + ev.reason);
      }
    }
  };
}

export async function updateDocument(
  id: number,
  payload: {
    organization?: string;
    metadata?: GeneralInfo;
    tags?: string;
    isConfidential?: boolean;
  },
): Promise<APIResult<Document>> {
  try {
    return (await axios.put("/api/documents/" + id, payload)).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function closeDocument(id: number | string) {
  if (ongoingWebsockets.has(id)) {
    const ws = ongoingWebsockets.get(id)!;
    queues.delete(id);
    ongoingWebsockets.delete(id);
    await ws.close();
  } else {
    throw new Error("Document already closed: " + id);
  }
}

export async function sendOperations(
  id: number,
  ops: OT.OperationTransformConcrete[],
) {
  if (ongoingWebsockets.has(id)) {
    const p = ongoingWebsockets.get(id)!.send(JSON.stringify(ops));
    const timedOut = await Promise.race([
      p,
      new Promise((res) => {
        setTimeout(() => {
          res(true);
        }, 1000);
      }),
    ]);

    if (timedOut) {
      await p;
    }
  } else {
    throw new Error("Document already closed");
  }
}

const queues = new Map<number | string, OT.OperationTransformConcrete[][]>();
const submitLoopRunning = new Set<number>();

async function submitLoop(id: number) {
  submitLoopRunning.add(id);
  if (ongoingWebsockets.has(id)) {
    const queue = queues.get(id)!;
    await sendOperations(id, queue[0]);
    queue.splice(0, 1);
    if (queue.length) {
      await submitLoop(id);
    } else {
      submitLoopRunning.delete(id);
    }
  }
}

export async function submitOperation(
  id: number,
  commit: any,
  ops: OT.OperationTransformConcrete[],
) {
  // yay it's javascript! There's no atomic concurrency issues!
  const queue = queues.get(id)!;

  queue.push(ops);
  if (!submitLoopRunning.has(id)) {
    return await submitLoop(id);
  }
}

export const DEFAULT_DOCUMENT_LIMIT = 200;

export async function getDocumentsCount(
  queryString?: string,
  globalSamples: "OnlyGlobalSamples" | "ExcludeGlobalSamples" | null = null,
): Promise<APIResult<number>> {
  try {
    return (
      await axios.get("/api/documents/", {
        params: {
          search: queryString,
          countOnly: true,
          globalSamples,
        },
      })
    ).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function getDocumentsWithSearch(
  queryString: string | undefined,
  showDelete: boolean,
  userId: string | undefined,
  orgId: string | undefined,
  /** comma delimited eg. "tag1,tag2" */
  tags: string | undefined,
  limit: number = DEFAULT_DOCUMENT_LIMIT,
  globalSamples: "OnlyGlobalSamples" | "ExcludeGlobalSamples" | null = null,
): Promise<APIResult<Document[]>> {
  try {
    return (
      await axios.get("/api/documents/", {
        params: {
          search: queryString,
          limit: limit,
          showDelete: showDelete,
          userId: userId,
          tags: tags ? tags : undefined,
          orgId: orgId,
          globalSamples,
        },
      })
    ).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function getDocument(id: number): Promise<APIResult<Document>> {
  try {
    return (await axios.get("/api/documents/" + id)).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

/** will auto-upgrade drawing state to latest version */
export async function exportDocument(
  id: number,
  deep: boolean,
): Promise<APIResult<RemoteClonePayload>> {
  try {
    const authToken = await getAutoRefreshedAccessToken();
    if (!authToken) {
      return { success: false, message: "No access token?" };
    }
    const remoteExportResult = await axios.get(`/api/documents/${id}/export`, {
      params: {
        deep,
      },
      // cookie session-id needs to be set
      headers: {
        Cookie: "session-id=" + authToken.toString(),
        "drawing-version": CURRENT_VERSION,
        "Access-Token": authToken.toString(),
      },
    });
    return remoteExportResult.data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function getSharedDocument(
  sharedId: string,
): Promise<APIResult<Document>> {
  try {
    return (await axios.get("/api/documents/shared/" + sharedId)).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function getDocumentsTags(
  userId: string | undefined,
  orgId: string | undefined,
  showDelete: boolean,
  locale: SupportedLocales | undefined,
  globalSamples: "OnlyGlobalSamples" | "ExcludeGlobalSamples" | null = null,
) {
  try {
    return (
      await axios.get("/api/documents/tags", {
        params: {
          showDelete: showDelete,
          userId: userId,
          orgId: orgId,
          locale: locale,
          globalSamples,
        },
      })
    ).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function createDocument(
  locale: SupportedLocales,
  diff?: OT.DiffOperation,
  version?: number,
  templateId: number | null = null,
): Promise<APIResult<Document>> {
  try {
    const params = {
      ...(templateId != null && { templateId: templateId }),
      ...(version != null && { version: version }),
    };
    console.log("Creating document with params", params);
    return (
      await axios.post(
        "/api/documents/",
        {
          locale: locale,
          diff,
        },
        { params: params },
      )
    ).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function deleteDocument(id: number): Promise<APIResult<void>> {
  try {
    return (await axios.delete("/api/documents/" + id)).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function restoreDocument(id: number): Promise<APIResult<void>> {
  try {
    return (await axios.post("/api/documents/" + id + "/restore")).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function cloneDocument(
  id: number,
  deepClone: boolean,
  lastOrderIndex?: number,
): Promise<APIResult<Document>> {
  try {
    return (
      await axios.post("/api/documents/" + id + "/clone", {
        deepClone,
        lastOrderIndex,
      })
    ).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function getDocumentHistory(
  id: number,
  { from, to }: { from?: number; to?: number } = {},
  entityData: { entityUids: string[]; entityLevel: string | null },
): Promise<APIResult<Operation[]>> {
  try {
    const url = `/api/documents/${id}/history`;
    const uids = entityData.entityUids;
    const level = entityData.entityLevel;
    return (
      await axios.get(url, {
        params: { from, to, uids, level },
      })
    ).data;
  } catch (e: any) {
    const message =
      e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : e.message;
    return { success: false, message };
  }
}

export async function getDocumentHistorySnapshot(
  id: number,
  lastOpId: number,
  uids?: string[],
): Promise<
  APIResult<{
    startVersion: number;
    closestSnapshot: DrawingState;
    followingOperations: Operation[];
  }>
> {
  const url = `/api/documents/${id}/snapshot/${lastOpId}`;

  try {
    const params = { uids };
    const response = await axios.get(url, { params });
    return response.data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function postCloneRemoteProject(
  remoteProjectUrl: string,
  remoteUsername: string,
  remotePassword: string,
  syncFullHistory: boolean,
  forceSyncShareToken: boolean,
): Promise<
  APIResult<{
    docId: number;
    numSnapshots: number;
    nextOperationIndex: number;
  }>
> {
  try {
    return (
      await axios.post("/api/documents/cloneremote", {
        remoteProjectUrl,
        remoteUsername,
        remotePassword,
        syncFullHistory,
        forceSyncShareToken,
      })
    ).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      console.error(e.response.data.message);
      return { success: false, message: e.response.data.message };
    } else {
      console.error(e.message);
      return { success: false, message: e.message };
    }
  }
}

export async function getDocumentLastOperationIndex(
  id: number,
): Promise<APIResult<number>> {
  try {
    return (await axios.get("/api/documents/" + id + "/lastOperationIndex"))
      .data;
  } catch (e: any) {
    console.error(e);
    return { success: false, message: e.message };
  }
}

export async function getDocumentDrawingIds(
  id: number,
): Promise<APIResult<number[]>> {
  try {
    return (await axios.get("/api/documents/" + id + "/drawingIds")).data;
  } catch (e: any) {
    console.error(e);
    return { success: false, message: e.message };
  }
}

/** returns created docId */
export async function uploadDocument(
  locale: SupportedLocales,
  drawing: DrawingState,
): Promise<APIResult<Document>> {
  try {
    const response = await axios.post(
      "/api/documents/upload",
      {
        version: CURRENT_VERSION,
        locale: locale,
        drawingstate: drawing,
      },
      {
        headers: {
          "drawing-version": CURRENT_VERSION,
        },
      },
    );
    return response.data;
  } catch (e: any) {
    console.error(e);
    return { success: false, message: e.message };
  }
}

/** string return type is just an acknowledgement message. can be ignored. */
export async function setIsGlobalSample(
  id: number,
  isGlobalSample: boolean,
): Promise<APIResult<string>> {
  try {
    return (
      await axios.post(`/api/documents/${id}/isGlobalSample`, {
        isGlobalSample: isGlobalSample ? "true" : "false",
      })
    ).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}
