import CoreMultiwayValve from "../../../../common/src/api/coreObjects/coreMultiwayValve";
import { VALVE_HEIGHT_MM } from "../../../../common/src/api/coreObjects/utils";
import { CalculationData } from "../../../../common/src/api/document/calculations-objects/calculation-field";
import { MultiwayValveEntity } from "../../../../common/src/api/document/entities/multiway-valves/multiway-valve-entity";
import { MultiwayValveType } from "../../../../common/src/api/document/entities/multiway-valves/multiway-valve-types";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { lighten } from "../../../../common/src/lib/color";
import { Coord } from "../../../../common/src/lib/coord";
import { angleDiffRad } from "../../../../common/src/lib/mathUtils/mathutils";
import { assertUnreachable } from "../../../../common/src/lib/utils";
import { DEFAULT_FONT_NAME } from "../../config";
import { getDragPriority } from "../../store/document";
import { VALVE_INACTIVE_COLOR } from "../lib/colors";
import { EntityDrawingArgs } from "../lib/drawable-object";
import { EntityPopupContent } from "../lib/entity-popups/types";
import { AttachableObject } from "../lib/object-traits/attachable-object";
import { CalculatedObject } from "../lib/object-traits/calculated-object";
import { CenteredObject } from "../lib/object-traits/centered-object";
import { ConnectableObject } from "../lib/object-traits/connectable";
import CoolDraggableObject from "../lib/object-traits/cool-draggable-object";
import { Core2Drawable } from "../lib/object-traits/core2drawable";
import {
  HoverSiblingResult,
  HoverableObject,
} from "../lib/object-traits/hoverable-object";
import { SelectableObject } from "../lib/object-traits/selectable";
import { SnappableObject } from "../lib/object-traits/snappable-object";
import { DrawingContext, ObjectConstructArgs } from "../lib/types";
import { VALVE_LINE_WIDTH_MM, VALVE_SIZE_MM } from "../lib/utils";
import { applyHoverEffects } from "./utils";

const Base = CalculatedObject(
  SelectableObject(
    AttachableObject(
      CoolDraggableObject(
        HoverableObject(
          ConnectableObject(
            CenteredObject(SnappableObject(Core2Drawable(CoreMultiwayValve))),
          ),
        ),
      ),
    ),
  ),
);

export default class DrawableMultiwayValve extends Base {
  minimumConnections = 0;

  // We could not add this to the base drawable class because
  // of some typescript thing so they have to be added at the concrete class.
  constructor(args: ObjectConstructArgs<MultiwayValveEntity>) {
    super(args.context, args.obj);
    this.onSelect = args.onSelect;
    this.onInteractionComplete = args.onInteractionComplete;
    this.document = args.document;
  }

  get maximumConnections() {
    return DrawableMultiwayValve.getMaximumConnections(this.entity.valve.type);
  }

  static getMaximumConnections(type: MultiwayValveType) {
    switch (type) {
      case "diverter-valve":
        return 3;
    }
    assertUnreachable(type);
  }

  dragPriority = getDragPriority(EntityType.MULTIWAY_VALVE);
  locateCalculationBoxWorld(
    _context: DrawingContext,
    _data: CalculationData[],
    _scale: number,
  ): { a: number; b: number; c: number; d: number; e: number; f: number }[] {
    return [];
  }

  drawConnectable(
    context: DrawingContext,
    { selected }: EntityDrawingArgs,
  ): void {
    applyHoverEffects(context, this);
    const s = context.vp.currToSurfaceScale(context.ctx);
    // draw a M
    const { ctx } = context;

    if (selected) {
      context.ctx.fillStyle = lighten(
        this.baseDrawnColor(context).hex,
        50,
        0.8,
      );
      context.ctx.fillRect(
        -this.valveWidthMM * 1.2,
        -this.valveWidthMM * 1.2,
        this.valveWidthMM * 2.4,
        this.valveWidthMM * 2.4,
      );
    }

    let color = this.baseDrawnColor(context);
    if (!this.isActive()) {
      color = VALVE_INACTIVE_COLOR;
    }
    const baseWidth = Math.max(
      2.0 / s,
      VALVE_LINE_WIDTH_MM / this.toWorldLength(1),
    );

    const angles = this.getSortedAnglesRAD();

    const maxOffsetY = this.valveWidthMM;
    switch (this.entity.valve.type) {
      case "diverter-valve":
        const drawnAngles = [];
        // we need 3 angles.
        if (angles.angles.length === 0) {
          drawnAngles.push(0, Math.PI / 2, Math.PI);
        } else if (angles.angles.length === 1) {
          drawnAngles.push(
            angles.angles[0],
            angles.angles[0] + Math.PI / 2,
            angles.angles[0] - Math.PI / 2,
          );
        } else if (angles.angles.length === 2) {
          // get other angle in between
          const diff1 = angleDiffRad(angles.angles[1], angles.angles[0]);
          const diff2 = -(Math.PI * 2 - diff1);

          const maxDiff = Math.min(diff1, diff2);

          drawnAngles.push(
            angles.angles[0],
            angles.angles[1],
            angles.angles[0] + maxDiff / 2,
          );
        } else {
          drawnAngles.push(...angles.angles);
        }

        context.ctx.fillStyle = color.hex;
        context.ctx.strokeStyle = color.hex;
        context.ctx.lineWidth = baseWidth;

        for (const a of drawnAngles) {
          const a1 = a - Math.PI / 6;
          const a2 = a + Math.PI / 6;
          const x1 = Math.cos(a1) * this.valveWidthMM;
          const y1 = Math.sin(a1) * this.valveWidthMM;
          const x2 = Math.cos(a2) * this.valveWidthMM;
          const y2 = Math.sin(a2) * this.valveWidthMM;

          ctx.beginPath();
          ctx.moveTo(0, 0);
          ctx.lineTo(x1, y1);
          ctx.lineTo(x2, y2);
          ctx.lineTo(0, 0);
          ctx.stroke();
        }
      default:
        break;
    }

    // Display Entity Name
    if (this.entity.entityName) {
      const name = this.entity.entityName;
      context.ctx.font = 70 + "pt " + DEFAULT_FONT_NAME;
      const nameWidth = context.ctx.measureText(name).width;
      const offsetx = -nameWidth / 2;
      context.ctx.fillStyle = "rgba(0, 255, 20, 0.13)";
      // the 70 represents the height of the font
      const textHight = 70;
      const offsetY = maxOffsetY + textHight / 2;
      context.ctx.fillRect(offsetx, offsetY, nameWidth, 70);
      context.ctx.fillStyle = color.hex;
      context.ctx.fillTextStable(name, offsetx, offsetY - 4, undefined, "top");
    }
  }

  inBounds(objectCoord: Coord, objectRadius?: number | undefined): boolean {
    if (!this.isActive()) {
      return false;
    }
    const dist = Math.sqrt(objectCoord.x ** 2 + objectCoord.y ** 2);
    return dist < this.valveWidthMM + (objectRadius ? objectRadius : 0);
  }

  friendlyTypeName(): string {
    switch (this.entity.valve.type) {
      case "diverter-valve":
        return "Diverter Valve";
    }
    assertUnreachable(this.entity.valve.type);
  }

  get valveWidthMM(): number {
    return (this.valveHeightMM * VALVE_SIZE_MM) / VALVE_HEIGHT_MM;
  }

  get valveHeightMM(): number {
    return this.entity.valveSizeMM || VALVE_HEIGHT_MM;
  }

  getPopupContent(): EntityPopupContent[] {
    return [];
  }

  getHoverSiblings(): HoverSiblingResult[] {
    return [];
  }

  getAttachCoords(): [Coord, Coord, Coord, Coord] {
    return [
      // left
      {
        x: -this.valveWidthMM * 1.2 - this.attachmentOffset,
        y: 0,
      },
      // right
      {
        x: this.valveWidthMM * 1.2 + this.attachmentOffset,
        y: 0,
      },
      // top
      {
        x: 0,
        y: -this.valveWidthMM - this.attachmentOffset,
      },
      // bottom
      {
        x: 0,
        y: this.valveWidthMM + this.attachmentOffset,
      },
    ];
  }
}
