import { ResourcesConfig } from "aws-amplify";
import { useLocalCognito } from "../config";

const hostnameWithProtocol = `${window.location.protocol}//${
  window.location.hostname
}${
  window.location.port &&
  window.location.port !== "80" &&
  window.location.port !== "443"
    ? ":" + window.location.port
    : ""
}`;

// Replace this with your tunnel URL (without the protocol) to enable sign ups
// e.g. "def6-2403-4800-3563-d401-3c6b-f3f0-1caa-4da0.ngrok-free.app"
const override: null | string = useLocalCognito ? null : null;

export const CLIENT_METADATA = { domain: override ?? window.location.hostname };

export const AMPLIFY_CONFIG: ResourcesConfig = {
  Auth: {
    Cognito: {
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: useLocalCognito
        ? "us-west-1_4lH8YVwK9"
        : "us-west-1_oerL2yMND",

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: useLocalCognito
        ? "2e300tjtd4ms67fm9mn1h1qcek"
        : "4k8f8vtfooe5mebgkerfc1hj6b",

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      // mandatorySignIn: false,

      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      signUpVerificationMethod: "code", // 'code' | 'link'

      // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
      // clientMetadata: { domain: window.location.hostname },

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      // authenticationFlowType: "USER_PASSWORD_AUTH",

      // OPTIONAL - Hosted UI configuration
      loginWith: {
        oauth: {
          domain: useLocalCognito
            ? "h2x-non-prod.auth.us-west-1.amazoncognito.com"
            : "h2x.auth.us-west-1.amazoncognito.com",
          scopes: ["email", "profile", "openid"],
          redirectSignIn: [hostnameWithProtocol],
          redirectSignOut: [hostnameWithProtocol],
          responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
      },

      // OPTIONAL Password requirements
      // passwordFormat: {
      //     minLength?: number;
      //     requireLowercase?: boolean;
      //     requireUppercase?: boolean;
      //     requireNumbers?: boolean;
      //     requireSpecialCharacters?: boolean;
      // };
    },
  },
};
