import Flatten from "@flatten-js/core";
import { sum } from "lodash";
import { EPS } from "./utils";
import { Vector3 } from "./vector3";

export interface Coord {
  // x,y represent mm
  x: number;
  y: number;
}

// Project a Coord3D onto the XY plane
export function asCoord({ x, y }: Coord3D) {
  return { x, y };
}

export function coord(x: number, y: number) {
  return { x, y };
}

export function coordDist2(a: Coord, b: Coord) {
  return (a.x - b.x) * (a.x - b.x) + (a.y - b.y) * (a.y - b.y);
}

export function coordDist(a: Coord, b: Coord) {
  return Math.sqrt(coordDist2(a, b));
}

export function coordAdd(a: Coord, b: Coord) {
  return { x: a.x + b.x, y: a.y + b.y };
}
export function coordSub(a: Coord, b: Coord) {
  return { x: a.x - b.x, y: a.y - b.y };
}
export function coordMidpoint(...coords: Coord[]) {
  return {
    x: sum(coords.map((c) => c.x)) / coords.length,
    y: sum(coords.map((c) => c.y)) / coords.length,
  };
}

export interface Coord3D extends Coord {
  z: number;
}

export function coord3d(x: number, y: number, z: number) {
  return { x, y, z };
}

export function coordList3d(...coords: [number, number, number][]): Coord3D[] {
  return coords.map((coord) => coord3d(coord[0], coord[1], coord[2]));
}

export function coord3DDist2(a: Coord3D, b: Coord3D) {
  return coordDist2(a, b) + (a.z - b.z) * (a.z - b.z);
}

export function coord3DDist(a: Coord3D, b: Coord3D) {
  return Math.sqrt(coord3DDist2(a, b));
}

export function coord3DAdd(a: Coord3D, b: Coord3D) {
  return { x: a.x + b.x, y: a.y + b.y, z: a.z + b.z };
}

export function coord3DTranslate(coord: Coord3D, vector3: Vector3) {
  return {
    x: coord.x + vector3.x,
    y: coord.y + vector3.y,
    z: coord.z + vector3.z,
  };
}

export function coord3DSub(a: Coord3D, b: Coord3D) {
  return { x: a.x - b.x, y: a.y - b.y, z: a.z - b.z };
}

export function coord3DMul(a: Coord3D, b: number) {
  return { x: a.x * b, y: a.y * b, z: a.z * b };
}

export function coordMagnitude(c: Coord | Coord3D) {
  return Math.sqrt(c.x * c.x + c.y * c.y + ("z" in c ? c.z * c.z : 0));
}

export function coordNormalize(c: Coord | Coord3D) {
  const magnitude = coordMagnitude(c);
  const result = { ...c };
  result.x /= magnitude;
  result.y /= magnitude;
  if ("z" in result) {
    result.z /= magnitude;
  }
  return result;
}

export function coordDot(a: Coord | Coord3D, b: Coord | Coord3D) {
  return a.x * b.x + a.y * b.y + ("z" in a && "z" in b ? a.z * b.z : 0);
}
export function coorEquals(
  a: Coord | Coord3D,
  b: Coord | Coord3D,
  eps: number = 0,
) {
  return (
    Math.abs(a.x - b.x) <= eps &&
    Math.abs(a.y - b.y) <= eps &&
    ("z" in a && "z" in b ? Math.abs(a.z - b.z) <= eps : true)
  );
}

export function coord3DMidpoint(...coords: Coord3D[]) {
  return {
    x: sum(coords.map((c) => c.x)) / coords.length,
    y: sum(coords.map((c) => c.y)) / coords.length,
    z: sum(coords.map((c) => c.z)) / coords.length,
  };
}

export function coord2Point(c: Coord) {
  return new Flatten.Point(c.x, c.y);
}

export function flattenPoint2Coord(c: Coord) {
  return { x: c.x, y: c.y };
}

export function areCoordsInLine(coords: Coord[]): boolean {
  if (coords.length < 2) {
    // not enough coords
    return false;
  }
  if (coords.length === 2) {
    // always in line
    return true;
  }

  // create a line through the first two points
  const line = new Flatten.Line(coord2Point(coords[0]), coord2Point(coords[1]));

  // check if each of the remaining points lies on the line
  for (let i = 2; i < coords.length; i++) {
    const distance = line.distanceTo(
      new Flatten.Point(coords[i].x, coords[i].y),
    );

    // if the distance from the point to the line is greater than EPS, it's not on the line
    if (distance[0] > EPS) {
      return false;
    }
  }

  return true;
}
