import { assertUnreachable } from "../../../lib/utils";
import { CoreContext } from "../../calculations/types";
import { isDrainage } from "../../config";
import { DrawingState } from "../drawing";
import { flowSystemHasVent } from "../flow-systems";
import { AnnotationEntity } from "./annotations/annotation-entity";
import { ArchitectureElementEntity } from "./architectureElement-entity";
import AreaSegmentEntity from "./area-segment-entity";
import { BackgroundEntity } from "./background-entity";
import BigValveEntity, { BigValveType } from "./big-valve/big-valve-entity";
import { CompoundEntity } from "./compound-entities/compound-entity";
import { DrawableEntityConcrete, isNamedEntity } from "./concrete-entity";
import ConduitEntity, { getConduitTypeName } from "./conduit-entity";
import DamperEntity from "./damper-entity";
import DirectedValveEntity from "./directed-valves/directed-valve-entity";
import { ValveType } from "./directed-valves/valve-types";
import { EdgeEntity } from "./edge-entity";
import { FenEntity, FenType } from "./fenestration-entity";
import { FittingEntity } from "./fitting-entity";
import FixtureEntity from "./fixtures/fixture-entity";
import FlowSourceEntity from "./flow-source-entity";
import GasApplianceEntity from "./gas-appliance";
import { LineEntity } from "./lines/line-entity";
import LoadNodeEntity, { NodeType } from "./load-node-entity";
import { MultiwayValveEntity } from "./multiway-valves/multiway-valve-entity";
import PlantEntity from "./plants/plant-entity";
import { PlantType } from "./plants/plant-types";
import RiserEntity from "./riser-entity";
import { RoomEntity, RoomType } from "./rooms/room-entity";
import { SystemNodeEntity } from "./system-node-entity";
import { SystemNodeRole, findSystemNodeRole } from "./utils";
import { VertexEntity } from "./vertices/vertex-entity";
import { WallEntity } from "./wall-entity";

export enum EntityTypeV1 {
  BACKGROUND_IMAGE = "BACKGROUND_IMAGE",
  RISER = "RISER",
  RETURN = "FLOW_RETURN",
  PIPE = "PIPE",
  FITTING = "FITTING",

  BIG_VALVE = "BIG_VALVE",
  SYSTEM_NODE = "SYSTEM_NODE",

  FIXTURE = "FIXTURE",

  DIRECTED_VALVE = "DIRECTED_VALVE",
  MULTIWAY_VALVE = "MULTIWAY_VALVE",
  LOAD_NODE = "LOAD_NODE",
  PLANT = "PLANT",

  FLOW_SOURCE = "FLOW_SOURCE",
  GAS_APPLIANCE = "GAS_APPLIANCE",

  COMPOUND = "COMPOUND",
}

export enum EntityType {
  BACKGROUND_IMAGE = "BACKGROUND_IMAGE",
  RISER = "RISER",
  RETURN = "FLOW_RETURN",
  CONDUIT = "CONDUIT",
  FITTING = "FITTING",

  LINE = "LINE",
  ANNOTATION = "ANNOTATION",

  EDGE = "EDGE",
  WALL = "WALL",
  VERTEX = "VERTEX",
  FENESTRATION = "FENESTRATION",

  ROOM = "ROOM",

  BIG_VALVE = "BIG_VALVE",
  SYSTEM_NODE = "SYSTEM_NODE",

  FIXTURE = "FIXTURE",

  DIRECTED_VALVE = "DIRECTED_VALVE",
  MULTIWAY_VALVE = "MULTIWAY_VALVE",
  LOAD_NODE = "LOAD_NODE",
  PLANT = "PLANT",

  FLOW_SOURCE = "FLOW_SOURCE",
  GAS_APPLIANCE = "GAS_APPLIANCE",

  COMPOUND = "COMPOUND",

  ARCHITECTURE_ELEMENT = "ARCHITECTURE_ELEMENT",
  DAMPER = "DAMPER",

  AREA_SEGMENT = "AREA_SEGMENT",
}

export type EntityTypeMap = {
  [EntityType.BACKGROUND_IMAGE]: BackgroundEntity;
  [EntityType.BIG_VALVE]: BigValveEntity;
  [EntityType.CONDUIT]: ConduitEntity;
  [EntityType.RISER]: RiserEntity;
  [EntityType.FITTING]: FittingEntity;
  [EntityType.SYSTEM_NODE]: SystemNodeEntity;
  [EntityType.FIXTURE]: FixtureEntity;
  [EntityType.DIRECTED_VALVE]: DirectedValveEntity;
  [EntityType.MULTIWAY_VALVE]: MultiwayValveEntity;
  [EntityType.LOAD_NODE]: LoadNodeEntity;
  [EntityType.PLANT]: PlantEntity;
  [EntityType.FLOW_SOURCE]: FlowSourceEntity;
  [EntityType.GAS_APPLIANCE]: GasApplianceEntity;
  [EntityType.COMPOUND]: CompoundEntity;
  [EntityType.EDGE]: EdgeEntity;
  [EntityType.LINE]: LineEntity;
  [EntityType.VERTEX]: VertexEntity;
  [EntityType.ROOM]: RoomEntity;
  [EntityType.WALL]: WallEntity;
  [EntityType.FENESTRATION]: FenEntity;
  [EntityType.ANNOTATION]: AnnotationEntity;
  [EntityType.ARCHITECTURE_ELEMENT]: ArchitectureElementEntity;
  [EntityType.DAMPER]: DamperEntity;
  [EntityType.AREA_SEGMENT]: AreaSegmentEntity;
};

export const ENTITY_TYPE_ABBREV: { [key in EntityType]: string } = {
  [EntityType.BACKGROUND_IMAGE]: "BG",
  [EntityType.BIG_VALVE]: "BV",
  [EntityType.CONDUIT]: "C",
  [EntityType.RISER]: "R",
  [EntityType.FITTING]: "F",
  [EntityType.SYSTEM_NODE]: "SN",
  [EntityType.FIXTURE]: "FX",
  [EntityType.DIRECTED_VALVE]: "DV",
  [EntityType.MULTIWAY_VALVE]: "MV",
  [EntityType.LOAD_NODE]: "LN",
  [EntityType.PLANT]: "P",
  [EntityType.FLOW_SOURCE]: "FS",
  [EntityType.GAS_APPLIANCE]: "GA",
  [EntityType.COMPOUND]: "CP",
  [EntityType.WALL]: "W",
  [EntityType.VERTEX]: "V",
  [EntityType.ROOM]: "RM",
  [EntityType.RETURN]: "RT",
  [EntityType.EDGE]: "RE",
  [EntityType.FENESTRATION]: "FN",
  [EntityType.LINE]: "L",
  [EntityType.ANNOTATION]: "AN",
  [EntityType.ARCHITECTURE_ELEMENT]: "FU",
  [EntityType.DAMPER]: "DP",
  [EntityType.AREA_SEGMENT]: "S",
};

export const ENTITY_TYPE_X_MODE_COLOR: { [key in EntityType]: string } = {
  [EntityType.BACKGROUND_IMAGE]: "#FFFFFF",
  [EntityType.BIG_VALVE]: "#FFFFFF",
  [EntityType.CONDUIT]: "#0000CC",
  [EntityType.RISER]: "#FF0000",
  [EntityType.FITTING]: "#00FFFF",
  [EntityType.SYSTEM_NODE]: "#FF00FF",
  [EntityType.FIXTURE]: "#00FF00",
  [EntityType.DIRECTED_VALVE]: "#88FFFF",
  [EntityType.MULTIWAY_VALVE]: "#88FFFF",
  [EntityType.LOAD_NODE]: "#FFFF00",
  [EntityType.PLANT]: "#FFFFFF",
  [EntityType.FLOW_SOURCE]: "#FFFFFF",
  [EntityType.GAS_APPLIANCE]: "#00FF00",
  [EntityType.COMPOUND]: "#FFFFFF",
  [EntityType.WALL]: "#0000CC",
  [EntityType.VERTEX]: "#00FFFF",
  [EntityType.ROOM]: "#FFFFFF",
  [EntityType.RETURN]: "#0000CC",
  [EntityType.EDGE]: "#FFFFFF",
  [EntityType.LINE]: "#FFFFFF",
  [EntityType.FENESTRATION]: "#FFFFFF",
  [EntityType.ANNOTATION]: "#FFFFFF",
  [EntityType.ARCHITECTURE_ELEMENT]: "#FFFFFF",
  [EntityType.DAMPER]: "#FFFFFF",
  [EntityType.AREA_SEGMENT]: "#FFFFFF",
};

export const ENTITY_TYPE_MIN_X_MODE_SCALE_DRAW: {
  [key in EntityType]: number;
} = {
  [EntityType.BACKGROUND_IMAGE]: 0,
  [EntityType.BIG_VALVE]: 0,
  [EntityType.CONDUIT]: 0.02,
  [EntityType.RISER]: 0,
  [EntityType.FITTING]: 0.08,
  [EntityType.SYSTEM_NODE]: 0.1,
  [EntityType.FIXTURE]: 0,
  [EntityType.DIRECTED_VALVE]: 0.06,
  [EntityType.MULTIWAY_VALVE]: 0.06,
  [EntityType.LOAD_NODE]: 0,
  [EntityType.PLANT]: 0,
  [EntityType.FLOW_SOURCE]: 0,
  [EntityType.GAS_APPLIANCE]: 0,
  [EntityType.COMPOUND]: 0,
  [EntityType.WALL]: 0.02,
  [EntityType.VERTEX]: 0.02,
  [EntityType.ROOM]: 0,
  [EntityType.RETURN]: 0,
  [EntityType.EDGE]: 0,
  [EntityType.LINE]: 0,
  [EntityType.FENESTRATION]: 0,
  [EntityType.ANNOTATION]: 0,
  [EntityType.ARCHITECTURE_ELEMENT]: 0,
  [EntityType.DAMPER]: 0,
  [EntityType.AREA_SEGMENT]: 0,
};

export function getEntityIndividualName(
  filled: DrawableEntityConcrete,
): string | null {
  if (isNamedEntity(filled)) {
    return filled.entityName;
  }
  switch (filled.type) {
    case EntityType.BACKGROUND_IMAGE:
      return filled.filename;
    case EntityType.ANNOTATION:
      return filled.anno.text;
    case EntityType.GAS_APPLIANCE:
    case EntityType.PLANT:
    case EntityType.LOAD_NODE:
      return filled.name ?? null;
    case EntityType.LINE:
    case EntityType.SYSTEM_NODE:
    case EntityType.VERTEX:
      return null;
  }
  assertUnreachable(filled);
}

export function getEntityEntryName(
  context: CoreContext,
  filled: DrawableEntityConcrete,
) {
  const typeName = getEntityName(filled, context.drawing, context);

  const individualName = getEntityIndividualName(filled);

  const levelUid = context.globalStore.levelOfEntity.get(filled.uid);
  const level = context.drawing.levels[levelUid!];

  return `${level ? level.abbreviation + " - " : ""}${typeName}${
    individualName ? ` [${individualName}]` : ""
  }`;
}

export function getEntityName(
  entity: DrawableEntityConcrete,
  drawing: DrawingState,
  context?: CoreContext,
): string {
  switch (entity.type) {
    case EntityType.BACKGROUND_IMAGE:
      return "Background";
    case EntityType.RISER:
      if (flowSystemHasVent(drawing.metadata.flowSystems[entity.systemUid])) {
        if (entity.riserType === "pipe" && entity.riser.isVent) {
          return "Vertical vent";
        } else {
          return "Stack";
        }
      } else {
        return "Riser";
      }
    case EntityType.CONDUIT:
      return getConduitTypeName(entity.conduitType);
    case EntityType.FITTING:
      return "Fitting";
    case EntityType.BIG_VALVE:
      return "Large Valves";
    case EntityType.SYSTEM_NODE:
      return "Inlet/Outlet";
    case EntityType.PLANT:
      switch (entity.plant.type) {
        case PlantType.RETURN_SYSTEM:
        case PlantType.TANK:
        case PlantType.CUSTOM:
          return "Plant";
        case PlantType.DRAINAGE_PIT:
          return "Drainage pit";
        case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
          return "Grease Interceptor Trap";
        case PlantType.RADIATOR:
          return "Radiator";
        case PlantType.AHU:
          return "Air Handling Unit";
        case PlantType.AHU_VENT:
          return "Air Handling Unit Vent";
        case PlantType.FCU:
          return "Fan Coil Unit";
        case PlantType.MANIFOLD:
          if (context) {
            const liveCalcs =
              context.globalStore.getOrCreateLiveCalculation(entity);
            return `Manifold ${liveCalcs.manifoldId}`;
          }
          return "Manifold";
        case PlantType.UFH:
          return "Underfloor Heating";
        case PlantType.PUMP:
        case PlantType.PUMP_TANK:
          return "Pump/Tank";
        case PlantType.VOLUMISER:
          return "Volumiser";
        case PlantType.FILTER:
          return "Filter";
        case PlantType.RO:
          return "Reverse Osmosis";
        case PlantType.DUCT_MANIFOLD:
          return "Duct Manifold";
        default:
          assertUnreachable(entity.plant);
      }
      return "Plant";
    case EntityType.FIXTURE:
      return "Fixture";
    case EntityType.DIRECTED_VALVE:
      if (entity.valve.type === ValveType.FLOOR_WASTE) {
        return "Floor Waste";
      }

      if (entity.valve.type === ValveType.INSPECTION_OPENING) {
        return "Inspection Opening";
      }

      return "Small Valves";
    case EntityType.MULTIWAY_VALVE:
      return "Multiway Valves";
    case EntityType.LOAD_NODE:
      switch (entity.node.type) {
        case NodeType.DWELLING:
        case NodeType.LOAD_NODE:
          return "Load Node";
        case NodeType.FIRE:
          return "Fire Node";
        case NodeType.VENTILATION:
          return "Grilles";
      }
    case EntityType.FLOW_SOURCE:
      if (isDrainage(drawing.metadata.flowSystems[entity.systemUid])) {
        return "Sewer Connection";
      } else {
        return "Flow Source";
      }
    case EntityType.GAS_APPLIANCE:
      return "Gas Appliance";
    case EntityType.COMPOUND:
      switch (entity.compound.type) {
        case "multi-pump":
          return "Multi Pump";
        case "custom":
          return "Custom Combo Entity";
        default:
          assertUnreachable(entity.compound);
      }
      return "Compound Entity";
    case EntityType.VERTEX:
      return "Vertex";
    case EntityType.EDGE:
      return "Room Edge";
    case EntityType.ROOM:
      switch (entity.room.roomType) {
        case RoomType.ROOM:
          return "Room";
        case RoomType.ROOF:
          return "Roof";
        default:
          assertUnreachable(entity.room);
      }
    case EntityType.WALL:
      return "Wall";
    case EntityType.FENESTRATION:
      switch (entity.fenType) {
        case FenType.DOOR:
          return "Door";
        case FenType.WINDOW:
          return "Window";
        case FenType.LOOP_ENTRY:
          return "Loop Entry";
        default:
          assertUnreachable(entity);
      }
      return "Fenestration";
    case EntityType.LINE:
      return "Line";
    case EntityType.ANNOTATION:
      return "Annotation";
    case EntityType.ARCHITECTURE_ELEMENT:
      return "Velux";
    case EntityType.DAMPER:
      return "Damper";
    case EntityType.AREA_SEGMENT:
      switch (entity.areaType) {
        case "unheated-area":
          return "Unheated Area";
        case "heated-area":
          return "Heated Area";
        default:
          assertUnreachable(entity);
      }
      return "Area Segment";
  }
  assertUnreachable(entity);
}

export function getEntityResultFieldName(
  entity: DrawableEntityConcrete,
  context: CoreContext,
): string {
  switch (entity.type) {
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.RISER:
    case EntityType.CONDUIT:
    case EntityType.FITTING:
    case EntityType.BIG_VALVE:
    case EntityType.PLANT:
    case EntityType.FIXTURE:
    case EntityType.DIRECTED_VALVE:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.LOAD_NODE:
    case EntityType.FLOW_SOURCE:
    case EntityType.GAS_APPLIANCE:
    case EntityType.COMPOUND:
    case EntityType.VERTEX:
    case EntityType.WALL:
    case EntityType.ROOM:
    case EntityType.EDGE:
    case EntityType.FENESTRATION:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return getEntityName(entity, context.drawing, context);
    case EntityType.SYSTEM_NODE:
      switch (findSystemNodeRole(entity, context)) {
        case SystemNodeRole.FIXTURE:
        case SystemNodeRole.PLANT:
        case SystemNodeRole.OTHER:
          return "Inlet/Outlet";
        case SystemNodeRole.RECIRCULATION_PUMP:
          return "Pump/Tank";
      }
    case EntityType.ARCHITECTURE_ELEMENT:
      return "Velux";
  }
  assertUnreachable(entity);
}

// for copy paste to replace
export function getReferences(entity: DrawableEntityConcrete): string[] {
  const refs: string[] = [];
  if (entity.parentUid) {
    refs.push(entity.parentUid);
  }

  switch (entity.type) {
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.RISER:
    case EntityType.FITTING:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.FLOW_SOURCE:
    case EntityType.ANNOTATION:
      break;
    case EntityType.LOAD_NODE:
      if (entity.linkedToUid) {
        refs.push(entity.linkedToUid);
      }
      break;
    case EntityType.SYSTEM_NODE:
      refs.push(entity.parentUid!);
      break;
    case EntityType.CONDUIT:
    case EntityType.EDGE:
    case EntityType.LINE:
      refs.push(entity.endpointUid[0], entity.endpointUid[1]);
      break;
    case EntityType.BIG_VALVE:
      refs.push(entity.coldRoughInUid, entity.hotRoughInUid);
      switch (entity.valve.type) {
        case BigValveType.TMV:
          refs.push(entity.valve.coldOutputUid);
          refs.push(entity.valve.warmOutputUid);
          break;
        case BigValveType.TEMPERING:
          refs.push(entity.valve.warmOutputUid);
          break;
        case BigValveType.RPZD_HOT_COLD:
          refs.push(entity.valve.hotOutputUid);
          refs.push(entity.valve.coldOutputUid);
          break;
      }
      break;
    case EntityType.FIXTURE:
      refs.push(...entity.roughInsInOrder.map((r) => entity.roughIns[r].uid));
      break;
    case EntityType.GAS_APPLIANCE:
      refs.push(entity.inletUid);
      break;
    case EntityType.DIRECTED_VALVE:
      refs.push(entity.sourceUid);
      break;
    case EntityType.PLANT:
      if (entity.inletUid) {
        refs.push(entity.inletUid);
      }

      switch (entity.plant.type) {
        case PlantType.RETURN_SYSTEM:
          for (const o of entity.plant.outlets) {
            refs.push(o.outletUid!);
          }

          if (entity.plant.gasNodeUid) {
            refs.push(entity.plant.gasNodeUid);
          }
          for (const preheat of entity.plant.preheats) {
            refs.push(preheat.inletUid);
            refs.push(preheat.returnUid);
          }
          entity.plant.outlets.forEach((outlet) => {
            refs.push(outlet.outletUid!);
            refs.push(outlet.outletReturnUid!);
          });
          break;
        case PlantType.AHU_VENT:
          if (entity.plant.supplyUid) {
            refs.push(entity.plant.supplyUid);
          }
          if (entity.plant.extractUid) {
            refs.push(entity.plant.extractUid);
          }
          if (entity.plant.intakeUid) {
            refs.push(entity.plant.intakeUid);
          }
          if (entity.plant.exhaustUid) {
            refs.push(entity.plant.exhaustUid);
          }
        case PlantType.AHU:
        case PlantType.FCU:
          if (entity.plant.heatingInletUid) {
            refs.push(entity.plant.heatingInletUid);
          }
          if (entity.plant.heatingOutletUid) {
            refs.push(entity.plant.heatingOutletUid);
          }
          if (entity.plant.chilledInletUid) {
            refs.push(entity.plant.chilledInletUid);
          }
          if (entity.plant.chilledOutletUid) {
            refs.push(entity.plant.chilledOutletUid);
          }
          break;

        case PlantType.DUCT_MANIFOLD:
          for (const outlet of entity.plant.outlets) {
            refs.push(outlet.uid);
          }
          break;
        case PlantType.TANK:
        case PlantType.CUSTOM:
        case PlantType.PUMP:
        case PlantType.DRAINAGE_PIT:
        case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
        case PlantType.RADIATOR:
        case PlantType.PUMP_TANK:
        case PlantType.VOLUMISER:
        case PlantType.MANIFOLD:
        case PlantType.UFH:
        case PlantType.FILTER:
        case PlantType.RO:
          refs.push(entity.plant.outletUid);
          break;
        default:
          assertUnreachable(entity.plant);
      }
      break;
    case EntityType.COMPOUND:
      switch (entity.compound.type) {
        case "multi-pump":
          refs.push(
            ...(entity.compound.pumps
              .map((p) => p.uid)
              .filter(Boolean) as string[]),
          );
          break;
        case "custom":
          break;
        default:
          assertUnreachable(entity.compound);
      }
      break;
    case EntityType.ROOM:
      refs.push(...entity.edgeUid);
      break;
    case EntityType.AREA_SEGMENT:
      refs.push(...entity.edgeUid);
    case EntityType.VERTEX:
      break;
    case EntityType.WALL:
    case EntityType.FENESTRATION:
      refs.push(...entity.polygonEdgeUid!);
      break;
    case EntityType.ARCHITECTURE_ELEMENT:
      break;
    case EntityType.DAMPER:
      refs.push(...entity.edgeUid);
      break;
    default:
      assertUnreachable(entity);
  }

  return refs;
}
