import { Module } from "vuex";
import { RootState } from "../types";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { getInitProjectSetupState, ProjectSetupState } from "./state";

const state = getInitProjectSetupState();

const namespaced: boolean = true;

export const projectSetup: Module<ProjectSetupState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
};
