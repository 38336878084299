export const WALL_PATTERNS: {
  [key: string]: {
    pattern: (
      baseColor: string,

      rgba: { r: number; g: number; b: number; a: number },
    ) => string;
    density: number;
  };
} = {
  "Solid Brick": {
    pattern: (
      baseColor: string,
    ) => `<svg width="827" height="200" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1919.3 464.8" style="enable-background:new 0 0 1919.3 464.8;" xml:space="preserve">
<style type="text/css">
	.st0{display:none;}
	.st1{display:inline;fill:none;stroke:${baseColor};stroke-width:30;stroke-miterlimit:10;}
	.st2{fill:none;stroke:${baseColor};stroke-width:9;stroke-miterlimit:10;}
	.st3{fill-rule:evenodd;clip-rule:evenodd;fill:#ECECEC;}
	.st4{display:inline;}
	.st5{fill-rule:evenodd;clip-rule:evenodd;stroke:${baseColor};stroke-width:20;stroke-miterlimit:10;}
	.st6{display:inline;fill:none;stroke:${baseColor};stroke-width:20;stroke-miterlimit:10;}
	.st7{display:inline;fill-rule:evenodd;clip-rule:evenodd;stroke:${baseColor};stroke-width:20;stroke-miterlimit:10;}
	.st8{display:inline;fill-rule:evenodd;clip-rule:evenodd;stroke:${baseColor};stroke-width:27;stroke-miterlimit:10;}
	.st9{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-miterlimit:10;}
	.st10{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-width:8;stroke-miterlimit:10;}
	.st11{display:inline;fill:none;stroke:${baseColor};stroke-width:8;stroke-miterlimit:10;}
	.st12{fill:none;stroke:${baseColor};stroke-width:45;stroke-miterlimit:10;}
	.st13{display:inline;fill:none;stroke:${baseColor};stroke-width:28;stroke-miterlimit:10;}
	.st14{display:inline;fill:none;stroke:${baseColor};stroke-width:14;stroke-miterlimit:10;}
	.st15{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-width:9;stroke-miterlimit:10;}
	.st16{fill:none;stroke:${baseColor};stroke-width:15;stroke-miterlimit:10;}
</style>
<g id="Concrete" class="st0">
	<line class="st1" x1="0" y1="155.5" x2="1919.3" y2="155.5"/>
	<line class="st2" x1="328.3" y1="0" x2="328.3" y2="151.6"/>
	<line class="st1" x1="0" y1="301.4" x2="1919.3" y2="301.4"/>
	<line class="st2" x1="328.3" y1="297.3" x2="328.3" y2="464.8"/>
	<line class="st2" x1="1005.9" y1="0" x2="1005.9" y2="151.6"/>
	<line class="st2" x1="1655.3" y1="0" x2="1655.3" y2="151.6"/>
	<line class="st2" x1="1005.9" y1="300.7" x2="1005.9" y2="464.8"/>
	<line class="st2" x1="1655.3" y1="297.3" x2="1655.3" y2="464.8"/>
	<rect y="145.6" class="st3" width="1919.3" height="163.8"/>
</g>
<g id="Layer_2_00000041294061573236642060000008068305606222413205_" class="st0">
	<g id="Layer_1-2" class="st4">
		<path class="st5" d="M586,158.8l-25.5,106c-0.4,1.7-1.8,2.8-3.5,2.8c0,0-395.3,1.2-414.9,1.2l0,0c-1.3,0-3.1-1-3.8-2.4
			c0,0,0,0,0-0.1c-0.3-0.7-0.4-1.5-0.4-2.3c-0.1-0.2-0.5-0.7-1-1.1c-1.2-1-3.2-2-6.3-3c-13.3-4.3-44.6-7.7-117-3.1L10,257l-0.5-7.4
			l3.6-0.2c74-4.7,106-1,119.6,3.5c8,2.6,10.9,5.9,11.9,8.5c37.8-0.1,374.8-1.1,409.3-1.2l28.3-117.4c0-0.1,0.1-0.2,0.1-0.3
			c2.3-6.6,3.4-12.4,3.5-17.3v-2.6c-0.2-6.2-1.9-11.1-4.4-15c-6.3-9.7-17.1-12.5-19-12.9L13.5,87.2H9.8v-0.1l0,0l0.1-3.7v-3.7H10
			v-0.1l3.6,0.1h659c7-5.6,10.9-15.9,13.3-26.3c3.8-16.3,3.6-32.9,3.5-34.6l-0.4-3.5h0.3V15l3.5-0.1l3.5-0.4v0.3h0.3l0.1,3.5
			c0.2,1.7,2.3,19.1,8.8,31.9c3,6,6.9,11.1,12.4,12.3c5.7,1.2,12.6-1.8,21.5-10.8l0,0c2.8-2.9,6.5-4.4,10.6-4.4c16,0,66.7,0,86.3,0
			c5.7,0,11,2.9,14.1,7.8c4.9,7.6,17.6,18.5,46.4,10.5c1.6-1,3.3-2,5-2.8c8.1-3.7,16.1-4.7,16.1-4.7c0.2,0,0.3,0,0.5,0
			c0,0,62.5,0.8,152.6,2h158.4V14.9h7.3v47.3c211.6,2.7,455.5,5.9,483.4,6.2l0,0c2.3,0,4.4,0.5,6.6,1.4c0,0,0,0,0.1,0
			c18.6,7.8,31.4,17.4,39.9,27.8c0.7-4.7,4.7-8.3,9.5-8.3h152.7v7.4h-152.7c-1.3,0-2.3,1.1-2.3,2.4v9.3c1.4,2.7,2.6,5.5,3.6,8.2
			c5.9,16.6,4.2,34.2-0.9,50.4c-0.8,2.6-1.8,5.2-2.7,7.8v78.6c0,1.3,1,2.3,2.3,2.3l0,0c28.4,0,149.1,0.2,149.1,0.2h3.6v7.4h-3.6
			c0,0-120.7-0.2-149.1-0.2c-5.3,0-9.6-4.4-9.6-9.7v-62.7c-6.8,13-14.5,23.5-19,29.2l0,0c-3.1,3.8-7.7,6.1-12.6,6.2l0,0
			c-62.7,1.7-598.1,16.2-648.2,17.5c-3.1,0.1-6.1-0.7-8.9-2.2c-22-11-72.4-16.7-89.3-18.4c-4.6-0.4-8.8-2.8-11.8-6.5l0,0
			c-11.2-14.5-46.2-59.5-64-82.4v134.8c0,0.9-0.1,1.8-0.3,2.6H1929v7.4H775.2c-0.4,18.1-8.3,94.3-111.4,148.3h104.3
			c-1.1-3.7-23.1-78,47-115.6c0,0,0,0,0.1,0c2.1-1.1,4.3-1.6,6.6-1.6c12.5,0,67.9,0,82.7,0v-22.5c0-2,1.6-3.7,3.6-3.7
			c0,0,911.8-3.2,1017.2-0.6l3.6,0.1l-0.2,7.4l-3.6-0.1c-101.5-2.5-950.9,0.3-1013.4,0.5v22.5c0,2-1.6,3.7-3.6,3.7
			c0,0-71.8,0-86.3,0c-1.2,0-2.2,0.3-3.3,0.8C752,354,775,425.3,775,425.3l0.7,2.2h174.9v-0.4l3.4,0.4h974.8v7.4h-956
			c4.4,3.4,8,7.6,10.9,12.1c8.8,13.7,10.8,29.8,10.8,29.8l0.5,3.7l-7.2,0.9l-0.5-3.7c0,0-1.8-14.4-9.7-26.7
			c-5-7.8-12.5-14.7-23.8-16.1h-305c-2.6,1.2-5.5,1.9-8.3,2c-26.7,1.2-189,9.1-294.7,24.3c-27,3.9-50.3,8.2-66.7,13
			c-10.2,3-17.6,6.1-21.1,9.5l-2.6,2.6l-5.1-5.3l2.6-2.6c3.1-3,8.6-6,16.3-8.7c16.5-5.9,43.4-11.2,75.5-15.8
			c60.1-8.6,138.4-14.9,199.8-18.9H9.8v-7.4h637.3c111.9-52.3,120.3-131,120.8-148.3H9.8v-7.4h576.5c-0.2-0.8-0.3-1.7-0.3-2.6V158.8
			L586,158.8z M563.5,87.2L563.5,87.2c0.1,0,13.7,2.6,22.4,13.8v-1.2c0-5.5,4.4-10,9.9-10h288.8c-0.8-5.8,0.3-10.6,2.4-14.5v-0.1
			c-26.1,3.7-38.1-8.2-43.1-16.1l0,0c-1.7-2.7-4.7-4.4-8-4.4c-19.6,0-70.3,0-86.3,0l0,0c-2.1,0-4,0.8-5.5,2.3
			c-9,9.1-16.7,12.8-23.1,13.2c-10.6,0.6-18.3-7.6-23.3-18.4c-1.2-2.5-2.2-5.1-3.1-7.7c-0.5,3.6-1.2,7.3-2,11.1
			c-2.9,12.7-8.2,25-17.1,31.3c-0.6,0.4-1.3,0.7-2.1,0.7H563.5L563.5,87.2z M1766.5,110.3c-7.3-12.8-20.6-24.4-42.6-33.7
			c-1.3-0.5-2.6-0.8-4-0.8c-35.8-0.5-426.6-5.5-649.1-8.3c-74.9,0-149.2,0-157.3,0c-5,2.1-9.7,3.7-14,5c-2.4,1.7-4.6,3.8-6.1,6.4
			c-1.6,2.9-2.3,6.5-1.5,10.9H903c5.4,0,9.8,4.5,9.8,10v22.5c14.4,18.6,57,73.4,69.7,89.7c1.7,2.1,4.1,3.5,6.8,3.7l0,0
			c17.4,1.7,69.3,7.8,91.9,19.2h0.1c1.7,0.9,3.5,1.4,5.3,1.3l0,0c50.1-1.3,585.5-15.8,648.2-17.5c2.8-0.1,5.4-1.4,7.1-3.5
			c5.9-7.4,17.2-23.1,24.7-41.9L1766.5,110.3L1766.5,110.3z M1773.8,142.6c0.1-2.3,0.1-4.7,0-7V142.6z M903.3,271.7
			c1.3-0.2,2.3-1.3,2.3-2.6V124.9c-1.8-2.3-2.9-3.7-3.2-4.1c-8.2-9.1-13.1-16.9-15.6-23.6H595.9c-1.5,0-2.7,1.2-2.7,2.7v21.5
			c0.1,1.5,0.1,3.1,0,4.7v143c0,1.4,1,2.5,2.3,2.6h175.8l3.5-0.2v0.2H903.3L903.3,271.7z M905.5,113.3V99.8c0-1.5-1.2-2.7-2.6-2.7
			h-8.4C896.7,101.9,900.3,107.2,905.5,113.3z M1423.8,41.7v-8.5c0-2.8,2.1-5.1,5.1-5.1h411.2V14.9h7.3v16.8c0,2-1.6,3.7-3.6,3.7
			h-412.6V44c0,2.8-2.4,5.1-5.1,5.1h-138.7c-28.2,0-35-29.6-35-29.6l-0.8-3.6l7.1-1.7l0.8,3.6c0,0,5.3,23.9,27.9,23.9L1423.8,41.7
			L1423.8,41.7z M1030.6,477.6l-0.3,3.7l-7.2-0.7l0.3-3.7c0,0,0.7-10.1,11.5-20.2c9.9-9.2,28.9-18.7,65.3-18.7h0.1l828.6,16
			l-0.1,7.4l-3.6-0.1l-825-16C1034.3,445.4,1030.6,477.5,1030.6,477.6L1030.6,477.6z"/>
	</g>
	<line class="st6" x1="1769" y1="65.2" x2="1938.8" y2="65.2"/>
	<line class="st6" x1="1770.2" y1="236.2" x2="1940" y2="236.2"/>
	<path class="st7" d="M0.1,277.2c1.1,0,2.1,0,3.2,0h3c109.3-1.6,152.2-4.4,166.4-7.1c2-0.4,13.2-2.6,28.6-4.2
		c10.1-1,18.5-1.3,24.1-1.5"/>
	<path class="st7" d="M0,83c1.1,0,2.1,0,3.2,0h3c109.3-1.6,152.2-4.4,166.4-7.1c2-0.4,13.2-2.6,28.6-4.2c10.1-1,18.5-1.3,24.1-1.5"
		/>
	<path class="st8" d="M0,444c1.1,0,2.1,0,3.2,0h3c65.6,1.3,102.5-3.9,124.4-9.3c6.6-1.6,32.7-8.3,68.9-13.9
		c9.4-1.4,17.2-2.5,22.2-3.1"/>
</g>
<g id="Flower" class="st0">
	<path class="st9" d="M1919.3,293.2H0v-135c5.7,0,11.5,0,17.2,0c634,0,1268.1,0,1902.1,0C1919.3,203.2,1919.3,248.2,1919.3,293.2z"
		/>
	<path class="st10" d="M6.2,170.9"/>
	<path class="st11" d="M29.5,167.8c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st11" d="M132.8,168.9c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st11" d="M173.8,169.5c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st11" d="M277.1,170.6c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st11" d="M324.6,170c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st11" d="M427.9,171.1c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st11" d="M467.7,171.7c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st11" d="M571,172.8c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st11" d="M611.9,173.4c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st11" d="M715.3,174.5c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st11" d="M762.7,174c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st11" d="M866.1,175.1c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st11" d="M905.4,173.6c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st11" d="M1008.7,174.7c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st11" d="M1049.6,175.3c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,6.9,5.7,5.5,3.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-3.2,5.8-5,8.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st11" d="M1155,174.4c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st11" d="M1202.4,173.9c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st11" d="M1305.8,174c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,8,4.5,10.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,1.4-4.5,10.9"/>
	<path class="st11" d="M1347.5,174.5c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,1.1,7.9,1.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,6.3-7.3,10.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st11" d="M1450.9,173.6c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st11" d="M1492.8,174.2c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st11" d="M1596.1,175.3c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st11" d="M1643.6,173.8c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,8-4.5,10.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,1.4,4.5,10.9"/>
	<path class="st11" d="M1746.9,174.9c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st11" d="M1788.2,174.7c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st11" d="M1891.6,175.8c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,3.9,7.9,5.2c2.4,1.6,2.8,2.6,8.5,6.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
</g>
<g id="Brick">
	<line class="st12" x1="0" y1="97.2" x2="1919.3" y2="97.2"/>
	<line class="st12" x1="0" y1="361.1" x2="1919.3" y2="361.1"/>
	<line class="st12" x1="67.4" y1="7.6" x2="67.4" y2="97.2"/>
	<line class="st12" x1="67.4" y1="367.6" x2="67.4" y2="457.2"/>
	<line class="st12" x1="726.9" y1="367.6" x2="726.9" y2="457.2"/>
	<line class="st12" x1="726.9" y1="7.6" x2="726.9" y2="97.2"/>
	<line class="st12" x1="397.2" y1="103.7" x2="397.2" y2="356"/>
	<line class="st12" x1="1104.7" y1="102.9" x2="1104.7" y2="355.3"/>
	<line class="st12" x1="1441.9" y1="7.6" x2="1441.9" y2="97.2"/>
	<line class="st12" x1="1441.9" y1="366.9" x2="1441.9" y2="456.4"/>
	<line class="st12" x1="1733.3" y1="100.4" x2="1733.3" y2="352.7"/>
	<g id="Layer_6">
	</g>
</g>
<g id="Layer_7" class="st0">
	<line class="st13" x1="0" y1="201.4" x2="1919.3" y2="201.4"/>
	<line class="st13" x1="0" y1="394.5" x2="1919.3" y2="394.5"/>
	<line class="st13" x1="67.4" y1="135.9" x2="67.4" y2="201.4"/>
	<line class="st13" x1="67.4" y1="399.3" x2="67.4" y2="464.8"/>
	<line class="st13" x1="726.9" y1="399.3" x2="726.9" y2="464.8"/>
	<line class="st13" x1="726.9" y1="135.9" x2="726.9" y2="201.4"/>
	<line class="st13" x1="397.2" y1="206.2" x2="397.2" y2="390.8"/>
	<line class="st13" x1="1104.7" y1="205.7" x2="1104.7" y2="390.2"/>
	<line class="st13" x1="1441.9" y1="135.9" x2="1441.9" y2="201.4"/>
	<line class="st13" x1="1441.9" y1="398.7" x2="1441.9" y2="464.2"/>
	<line class="st13" x1="1733.3" y1="203.8" x2="1733.3" y2="388.4"/>
</g>
<g id="Brick_x27_s_bottom" class="st0">
	<line class="st14" x1="0" y1="285.6" x2="1919.3" y2="285.6"/>
	<line class="st14" x1="0" y1="164.4" x2="1919.3" y2="164.4"/>
	<path class="st15" d="M25,171.3"/>
</g>
<line class="st16" x1="0" y1="457.2" x2="1919.3" y2="457.2"/>
<line class="st16" x1="0" y1="7.6" x2="1919.3" y2="7.6"/>
</svg>
`,
    density: 0.5,
  },
  "Solid Brick Insulated": {
    pattern: (
      baseColor: string,
    ) => `<svg width="827" height="200" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1919.3 464.8" style="enable-background:new 0 0 1919.3 464.8;" xml:space="preserve">
<style type="text/css">
	.st0{display:none;}
	.st1{display:inline;fill:none;stroke:${baseColor};stroke-width:30;stroke-miterlimit:10;}
	.st2{fill:none;stroke:${baseColor};stroke-width:9;stroke-miterlimit:10;}
	.st3{fill-rule:evenodd;clip-rule:evenodd;fill:#ECECEC;}
	.st4{display:inline;}
	.st5{fill-rule:evenodd;clip-rule:evenodd;stroke:${baseColor};stroke-width:20;stroke-miterlimit:10;}
	.st6{display:inline;fill:none;stroke:${baseColor};stroke-width:20;stroke-miterlimit:10;}
	.st7{display:inline;fill-rule:evenodd;clip-rule:evenodd;stroke:${baseColor};stroke-width:20;stroke-miterlimit:10;}
	.st8{display:inline;fill-rule:evenodd;clip-rule:evenodd;stroke:${baseColor};stroke-width:27;stroke-miterlimit:10;}
	.st9{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-miterlimit:10;}
	.st10{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-width:8;stroke-miterlimit:10;}
	.st11{fill:none;stroke:${baseColor};stroke-width:8;stroke-miterlimit:10;}
	.st12{fill:none;stroke:${baseColor};stroke-width:45;stroke-miterlimit:10;}
	.st13{display:inline;fill:none;stroke:${baseColor};stroke-width:28;stroke-miterlimit:10;}
	.st14{display:inline;fill:none;stroke:${baseColor};stroke-width:14;stroke-miterlimit:10;}
	.st15{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-width:9;stroke-miterlimit:10;}
	.st16{fill:none;stroke:${baseColor};stroke-width:15;stroke-miterlimit:10;}
	.st17{display:none;fill:none;stroke:${baseColor};stroke-width:15;stroke-miterlimit:10;}
</style>
<g id="Concrete" class="st0">
	<line class="st1" x1="0" y1="155.5" x2="1919.3" y2="155.5"/>
	<line class="st2" x1="328.3" y1="0" x2="328.3" y2="151.6"/>
	<line class="st1" x1="0" y1="301.4" x2="1919.3" y2="301.4"/>
	<line class="st2" x1="328.3" y1="297.3" x2="328.3" y2="464.8"/>
	<line class="st2" x1="1005.9" y1="0" x2="1005.9" y2="151.6"/>
	<line class="st2" x1="1655.3" y1="0" x2="1655.3" y2="151.6"/>
	<line class="st2" x1="1005.9" y1="300.7" x2="1005.9" y2="464.8"/>
	<line class="st2" x1="1655.3" y1="297.3" x2="1655.3" y2="464.8"/>
	<rect y="145.6" class="st3" width="1919.3" height="163.8"/>
</g>
<g id="Layer_2_00000041294061573236642060000008068305606222413205_" class="st0">
	<g id="Layer_1-2" class="st4">
		<path class="st5" d="M586,158.8l-25.5,106c-0.4,1.7-1.8,2.8-3.5,2.8c0,0-395.3,1.2-414.9,1.2l0,0c-1.3,0-3.1-1-3.8-2.4
			c0,0,0,0,0-0.1c-0.3-0.7-0.4-1.5-0.4-2.3c-0.1-0.2-0.5-0.7-1-1.1c-1.2-1-3.2-2-6.3-3c-13.3-4.3-44.6-7.7-117-3.1L10,257l-0.5-7.4
			l3.6-0.2c74-4.7,106-1,119.6,3.5c8,2.6,10.9,5.9,11.9,8.5c37.8-0.1,374.8-1.1,409.3-1.2l28.3-117.4c0-0.1,0.1-0.2,0.1-0.3
			c2.3-6.6,3.4-12.4,3.5-17.3v-2.6c-0.2-6.2-1.9-11.1-4.4-15c-6.3-9.7-17.1-12.5-19-12.9L13.5,87.2H9.8v-0.1l0,0l0.1-3.7v-3.7H10
			v-0.1l3.6,0.1h659c7-5.6,10.9-15.9,13.3-26.3c3.8-16.3,3.6-32.9,3.5-34.6l-0.4-3.5h0.3V15l3.5-0.1l3.5-0.4v0.3h0.3l0.1,3.5
			c0.2,1.7,2.3,19.1,8.8,31.9c3,6,6.9,11.1,12.4,12.3c5.7,1.2,12.6-1.8,21.5-10.8l0,0c2.8-2.9,6.5-4.4,10.6-4.4c16,0,66.7,0,86.3,0
			c5.7,0,11,2.9,14.1,7.8c4.9,7.6,17.6,18.5,46.4,10.5c1.6-1,3.3-2,5-2.8c8.1-3.7,16.1-4.7,16.1-4.7c0.2,0,0.3,0,0.5,0
			c0,0,62.5,0.8,152.6,2h158.4V14.9h7.3v47.3c211.6,2.7,455.5,5.9,483.4,6.2l0,0c2.3,0,4.4,0.5,6.6,1.4c0,0,0,0,0.1,0
			c18.6,7.8,31.4,17.4,39.9,27.8c0.7-4.7,4.7-8.3,9.5-8.3h152.7v7.4h-152.7c-1.3,0-2.3,1.1-2.3,2.4v9.3c1.4,2.7,2.6,5.5,3.6,8.2
			c5.9,16.6,4.2,34.2-0.9,50.4c-0.8,2.6-1.8,5.2-2.7,7.8v78.6c0,1.3,1,2.3,2.3,2.3l0,0c28.4,0,149.1,0.2,149.1,0.2h3.6v7.4h-3.6
			c0,0-120.7-0.2-149.1-0.2c-5.3,0-9.6-4.4-9.6-9.7v-62.7c-6.8,13-14.5,23.5-19,29.2l0,0c-3.1,3.8-7.7,6.1-12.6,6.2l0,0
			c-62.7,1.7-598.1,16.2-648.2,17.5c-3.1,0.1-6.1-0.7-8.9-2.2c-22-11-72.4-16.7-89.3-18.4c-4.6-0.4-8.8-2.8-11.8-6.5l0,0
			c-11.2-14.5-46.2-59.5-64-82.4v134.8c0,0.9-0.1,1.8-0.3,2.6H1929v7.4H775.2c-0.4,18.1-8.3,94.3-111.4,148.3h104.3
			c-1.1-3.7-23.1-78,47-115.6c0,0,0,0,0.1,0c2.1-1.1,4.3-1.6,6.6-1.6c12.5,0,67.9,0,82.7,0v-22.5c0-2,1.6-3.7,3.6-3.7
			c0,0,911.8-3.2,1017.2-0.6l3.6,0.1l-0.2,7.4l-3.6-0.1c-101.5-2.5-950.9,0.3-1013.4,0.5v22.5c0,2-1.6,3.7-3.6,3.7
			c0,0-71.8,0-86.3,0c-1.2,0-2.2,0.3-3.3,0.8C752,354,775,425.3,775,425.3l0.7,2.2h174.9v-0.4l3.4,0.4h974.8v7.4h-956
			c4.4,3.4,8,7.6,10.9,12.1c8.8,13.7,10.8,29.8,10.8,29.8l0.5,3.7l-7.2,0.9l-0.5-3.7c0,0-1.8-14.4-9.7-26.7
			c-5-7.8-12.5-14.7-23.8-16.1h-305c-2.6,1.2-5.5,1.9-8.3,2c-26.7,1.2-189,9.1-294.7,24.3c-27,3.9-50.3,8.2-66.7,13
			c-10.2,3-17.6,6.1-21.1,9.5l-2.6,2.6l-5.1-5.3l2.6-2.6c3.1-3,8.6-6,16.3-8.7c16.5-5.9,43.4-11.2,75.5-15.8
			c60.1-8.6,138.4-14.9,199.8-18.9H9.8v-7.4h637.3c111.9-52.3,120.3-131,120.8-148.3H9.8v-7.4h576.5c-0.2-0.8-0.3-1.7-0.3-2.6V158.8
			L586,158.8z M563.5,87.2L563.5,87.2c0.1,0,13.7,2.6,22.4,13.8v-1.2c0-5.5,4.4-10,9.9-10h288.8c-0.8-5.8,0.3-10.6,2.4-14.5v-0.1
			c-26.1,3.7-38.1-8.2-43.1-16.1l0,0c-1.7-2.7-4.7-4.4-8-4.4c-19.6,0-70.3,0-86.3,0l0,0c-2.1,0-4,0.8-5.5,2.3
			c-9,9.1-16.7,12.8-23.1,13.2c-10.6,0.6-18.3-7.6-23.3-18.4c-1.2-2.5-2.2-5.1-3.1-7.7c-0.5,3.6-1.2,7.3-2,11.1
			c-2.9,12.7-8.2,25-17.1,31.3c-0.6,0.4-1.3,0.7-2.1,0.7H563.5L563.5,87.2z M1766.5,110.3c-7.3-12.8-20.6-24.4-42.6-33.7
			c-1.3-0.5-2.6-0.8-4-0.8c-35.8-0.5-426.6-5.5-649.1-8.3c-74.9,0-149.2,0-157.3,0c-5,2.1-9.7,3.7-14,5c-2.4,1.7-4.6,3.8-6.1,6.4
			c-1.6,2.9-2.3,6.5-1.5,10.9H903c5.4,0,9.8,4.5,9.8,10v22.5c14.4,18.6,57,73.4,69.7,89.7c1.7,2.1,4.1,3.5,6.8,3.7l0,0
			c17.4,1.7,69.3,7.8,91.9,19.2h0.1c1.7,0.9,3.5,1.4,5.3,1.3l0,0c50.1-1.3,585.5-15.8,648.2-17.5c2.8-0.1,5.4-1.4,7.1-3.5
			c5.9-7.4,17.2-23.1,24.7-41.9L1766.5,110.3L1766.5,110.3z M1773.8,142.6c0.1-2.3,0.1-4.7,0-7V142.6z M903.3,271.7
			c1.3-0.2,2.3-1.3,2.3-2.6V124.9c-1.8-2.3-2.9-3.7-3.2-4.1c-8.2-9.1-13.1-16.9-15.6-23.6H595.9c-1.5,0-2.7,1.2-2.7,2.7v21.5
			c0.1,1.5,0.1,3.1,0,4.7v143c0,1.4,1,2.5,2.3,2.6h175.8l3.5-0.2v0.2H903.3L903.3,271.7z M905.5,113.3V99.8c0-1.5-1.2-2.7-2.6-2.7
			h-8.4C896.7,101.9,900.3,107.2,905.5,113.3z M1423.8,41.7v-8.5c0-2.8,2.1-5.1,5.1-5.1h411.2V14.9h7.3v16.8c0,2-1.6,3.7-3.6,3.7
			h-412.6V44c0,2.8-2.4,5.1-5.1,5.1h-138.7c-28.2,0-35-29.6-35-29.6l-0.8-3.6l7.1-1.7l0.8,3.6c0,0,5.3,23.9,27.9,23.9L1423.8,41.7
			L1423.8,41.7z M1030.6,477.6l-0.3,3.7l-7.2-0.7l0.3-3.7c0,0,0.7-10.1,11.5-20.2c9.9-9.2,28.9-18.7,65.3-18.7h0.1l828.6,16
			l-0.1,7.4l-3.6-0.1l-825-16C1034.3,445.4,1030.6,477.5,1030.6,477.6L1030.6,477.6z"/>
	</g>
	<line class="st6" x1="1769" y1="65.2" x2="1938.8" y2="65.2"/>
	<line class="st6" x1="1770.2" y1="236.2" x2="1940" y2="236.2"/>
	<path class="st7" d="M0.1,277.2c1.1,0,2.1,0,3.2,0h3c109.3-1.6,152.2-4.4,166.4-7.1c2-0.4,13.2-2.6,28.6-4.2
		c10.1-1,18.5-1.3,24.1-1.5"/>
	<path class="st7" d="M0,83c1.1,0,2.1,0,3.2,0h3c109.3-1.6,152.2-4.4,166.4-7.1c2-0.4,13.2-2.6,28.6-4.2c10.1-1,18.5-1.3,24.1-1.5"
		/>
	<path class="st8" d="M0,444c1.1,0,2.1,0,3.2,0h3c65.6,1.3,102.5-3.9,124.4-9.3c6.6-1.6,32.7-8.3,68.9-13.9
		c9.4-1.4,17.2-2.5,22.2-3.1"/>
</g>
<g id="Flower">
	<path class="st9" d="M1919.3,138.2H0V3.2c5.7,0,11.5,0,17.2,0c634,0,1268.1,0,1902.1,0C1919.3,48.2,1919.3,93.2,1919.3,138.2z"/>
	<path class="st10" d="M6.2,15.9"/>
	<path class="st11" d="M29.5,10.3c3.4,0.1,10.1,2.1,13.6,2.9c1.6,0.4,4.9,1.1,9,2.4c5.7,1.6,7.8,2.4,9,2.9c3.3,1.4,6.8,3.2,7.9,4
		c2.2,1.6,5.9,8,4.5,5.4c1.9,3.8,1.5,6.9,1.1,8.9c0,0-0.1,0.7-1.7,5.8c-0.9,1.8-2.2,4-4,6.5c-0.6,0.9-3.1,4.5-7.3,8.9
		c-2.1,2.2-4.2,4-8.5,7.6c-3.9,3.3-5.9,5.1-7.9,6.5c-2.4,1.7-2.8,1.7-8.5,5.3c-3.4,2.2-5.1,3.2-6.2,4.2c-1.9,1.5-4.7,3.8-7.3,7.6
		c-2.6,3.9-3.7,7.3-4.5,10c-0.5,1.7-1.3,4.3-1.7,7.6c-0.8,7.2,0.7,12.9,0.6,12.9c-0.2,0-1.4-5.2-1.1-5.3c0.2-0.1,1.3,2.5,4.5,12.4"
		/>
	<path class="st11" d="M132.8,11.4c-3.4,0.1-10.1,2.1-13.6,2.9c-1.6,0.4-4.9,1.1-9,2.4c-5.7,1.6-7.8,2.4-9,2.9
		c-3.3,1.4-6.8,3.2-7.9,4c-2.2,1.6-5.9,8-4.5,5.4c-1.9,3.8-1.5,6.9-1.1,8.9c0,0,0.1,0.7,1.7,5.8c0.9,1.8,2.2,4,4,6.5
		c0.6,0.9,3.1,4.5,7.3,8.9c2.1,2.2,4.2,4,8.5,7.6c3.9,3.3,5.9,5.1,7.9,6.5c2.4,1.7,2.8,1.7,8.5,5.3c3.4,2.2,5.1,3.2,6.2,4.2
		c1.9,1.5,4.7,3.8,7.3,7.6c2.6,3.9,3.7,7.3,4.5,10c0.5,1.7,1.3,4.3,1.7,7.6c0.8,7.2-0.7,12.9-0.6,12.9c0.2,0,1.4-5.2,1.1-5.3
		c-0.2-0.1-1.3,2.5-4.5,12.4"/>
	<path class="st11" d="M173.8,14.5c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st11" d="M277.1,15.6c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st11" d="M324.6,15c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st11" d="M427.9,16.1c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st11" d="M467.7,16.7c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st11" d="M571,17.8c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8c-3.3,1.3-6.8,3.1-7.9,3.8
		c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2c0.6,0.9,3.1,4.3,7.3,8.5
		c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4c1.9,1.4,4.7,3.6,7.3,7.3
		s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st11" d="M611.9,18.4c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st11" d="M715.3,19.5c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st11" d="M762.7,19c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st11" d="M866.1,20.1c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st11" d="M905.4,18.6c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st11" d="M1008.7,19.7c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st11" d="M1049.6,20.3c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,6.9,5.7,5.5,3.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-3.2,5.8-5,8.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st11" d="M1155,19.4c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st11" d="M1202.4,18.9c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st11" d="M1305.8,19c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,8,4.5,10.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,1.4-4.5,10.9"/>
	<path class="st11" d="M1347.5,19.5c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,1.1,7.9,1.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,6.3-7.3,10.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st11" d="M1496.7,14.4c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st11" d="M1600,15.5c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st11" d="M1455.6,16c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st11" d="M1639.2,16c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st11" d="M1752,14.7c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st11" d="M1790.2,15.1c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st11" d="M1896.3,13.4c-3.4,0.1-10.1,2.1-13.6,2.9c-1.6,0.4-4.9,1.1-9,2.4c-5.7,1.6-7.8,2.4-9,2.9
		c-3.3,1.4-6.8,3.2-7.9,4c-2.2,1.6-5.9,8-4.5,5.4c-1.9,3.8-1.5,6.9-1.1,8.9c0,0,0.1,0.7,1.7,5.8c0.9,1.8,2.2,4,4,6.5
		c0.6,0.9,3.1,4.5,7.3,8.9c2.1,2.2,4.2,4,8.5,7.6c3.9,3.3,5.9,5.1,7.9,6.5c2.4,1.7,2.8,1.7,8.5,5.3c3.4,2.2,5.1,3.2,6.2,4.2
		c1.9,1.5,4.7,3.8,7.3,7.6c2.6,3.9,3.7,7.3,4.5,10c0.5,1.7,1.3,4.3,1.7,7.6c0.8,7.2-0.7,12.9-0.6,12.9c0.2,0,1.4-5.2,1.1-5.3
		c-0.2-0.1-1.3,2.5-4.5,12.4"/>
</g>
<g id="Brick">
	<line class="st12" x1="0" y1="201.7" x2="1919.3" y2="201.7"/>
	<line class="st12" x1="0" y1="389" x2="1919.3" y2="389"/>
	<line class="st12" x1="67.4" y1="138.2" x2="67.4" y2="201.7"/>
	<line class="st12" x1="67.4" y1="393.7" x2="67.4" y2="457.2"/>
	<line class="st12" x1="726.9" y1="393.7" x2="726.9" y2="457.2"/>
	<line class="st12" x1="726.9" y1="138.2" x2="726.9" y2="201.7"/>
	<line class="st12" x1="397.2" y1="206.4" x2="397.2" y2="385.4"/>
	<line class="st12" x1="1104.7" y1="205.8" x2="1104.7" y2="384.9"/>
	<line class="st12" x1="1441.9" y1="138.2" x2="1441.9" y2="201.7"/>
	<line class="st12" x1="1441.9" y1="393.1" x2="1441.9" y2="456.6"/>
	<line class="st12" x1="1733.3" y1="204.1" x2="1733.3" y2="383.1"/>
	<g id="Layer_6">
	</g>
</g>
<g id="Layer_7" class="st0">
	<line class="st13" x1="0" y1="201.4" x2="1919.3" y2="201.4"/>
	<line class="st13" x1="0" y1="394.5" x2="1919.3" y2="394.5"/>
	<line class="st13" x1="67.4" y1="135.9" x2="67.4" y2="201.4"/>
	<line class="st13" x1="67.4" y1="399.3" x2="67.4" y2="464.8"/>
	<line class="st13" x1="726.9" y1="399.3" x2="726.9" y2="464.8"/>
	<line class="st13" x1="726.9" y1="135.9" x2="726.9" y2="201.4"/>
	<line class="st13" x1="397.2" y1="206.2" x2="397.2" y2="390.8"/>
	<line class="st13" x1="1104.7" y1="205.7" x2="1104.7" y2="390.2"/>
	<line class="st13" x1="1441.9" y1="135.9" x2="1441.9" y2="201.4"/>
	<line class="st13" x1="1441.9" y1="398.7" x2="1441.9" y2="464.2"/>
	<line class="st13" x1="1733.3" y1="203.8" x2="1733.3" y2="388.4"/>
</g>
<g id="Brick_x27_s_bottom" class="st0">
	<line class="st14" x1="0" y1="285.6" x2="1919.3" y2="285.6"/>
	<line class="st14" x1="0" y1="164.4" x2="1919.3" y2="164.4"/>
	<path class="st15" d="M25,171.3"/>
</g>
<line class="st16" x1="0" y1="457.2" x2="1919.3" y2="457.2"/>
<line class="st17" x1="0" y1="7.6" x2="1919.3" y2="7.6"/>
<line class="st16" x1="-4" y1="135.9" x2="1915.3" y2="135.9"/>
<line class="st16" x1="0" y1="3.2" x2="1919.3" y2="3.2"/>
</svg>`,
    density: 0.5,
  },
  "Solid Stone": {
    pattern: (baseColor: string) => `
      <svg width="827" height="200" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1919.3 464.8" style="enable-background:new 0 0 1919.3 464.8;" xml:space="preserve">
<style type="text/css">
	.st0{display:none;}
	.st1{fill:none;stroke:${baseColor};stroke-width:30;stroke-miterlimit:10;}
	.st2{fill:none;stroke:${baseColor};stroke-width:9;stroke-miterlimit:10;}
	.st3{fill-rule:evenodd;clip-rule:evenodd;fill:#ECECEC;}
	.st4{fill-rule:evenodd;clip-rule:evenodd;stroke:${baseColor};stroke-width:20;stroke-miterlimit:10;}
	.st5{fill:none;stroke:${baseColor};stroke-width:20;stroke-miterlimit:10;}
	.st6{fill-rule:evenodd;clip-rule:evenodd;stroke:${baseColor};stroke-width:27;stroke-miterlimit:10;}
	.st7{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-miterlimit:10;}
	.st8{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-width:8;stroke-miterlimit:10;}
	.st9{display:inline;fill:none;stroke:${baseColor};stroke-width:8;stroke-miterlimit:10;}
	.st10{display:inline;fill:none;stroke:${baseColor};stroke-width:45;stroke-miterlimit:10;}
	.st11{display:inline;}
	.st12{display:inline;fill:none;stroke:${baseColor};stroke-width:28;stroke-miterlimit:10;}
	.st13{display:inline;fill:none;stroke:${baseColor};stroke-width:14;stroke-miterlimit:10;}
	.st14{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-width:9;stroke-miterlimit:10;}
	.st15{fill:none;stroke:${baseColor};stroke-width:15;stroke-miterlimit:10;}
	.st16{display:none;fill:none;stroke:${baseColor};stroke-width:15;stroke-miterlimit:10;}
</style>
<g id="Concrete" class="st0">
	<line class="st1" x1="0" y1="155.5" x2="1919.3" y2="155.5"/>
	<line class="st2" x1="328.3" y1="0" x2="328.3" y2="151.6"/>
	<line class="st1" x1="0" y1="301.4" x2="1919.3" y2="301.4"/>
	<line class="st2" x1="328.3" y1="297.3" x2="328.3" y2="464.8"/>
	<line class="st2" x1="1005.9" y1="0" x2="1005.9" y2="151.6"/>
	<line class="st2" x1="1655.3" y1="0" x2="1655.3" y2="151.6"/>
	<line class="st2" x1="1005.9" y1="300.7" x2="1005.9" y2="464.8"/>
	<line class="st2" x1="1655.3" y1="297.3" x2="1655.3" y2="464.8"/>
	<rect y="145.6" class="st3" width="1919.3" height="163.8"/>
</g>
<g id="Layer_2_00000041294061573236642060000008068305606222413205_">
	<g id="Layer_1-2">
		<path class="st4" d="M581.6,148l-25.3,98.1c-0.4,1.6-1.8,2.6-3.5,2.6c0,0-392.3,1.1-411.8,1.1l0,0c-1.3,0-3.1-0.9-3.8-2.2
			c0,0,0,0,0-0.1c-0.3-0.6-0.4-1.4-0.4-2.1c-0.1-0.2-0.5-0.6-1-1c-1.2-0.9-3.2-1.9-6.3-2.8c-13.2-4-44.3-7.1-116.1-2.9l-3.6,0.2
			L9.4,232l3.6-0.2c73.4-4.3,105.2-0.9,118.7,3.2c7.9,2.4,10.8,5.5,11.8,7.9c37.5-0.1,372-1,406.2-1.1l28.1-108.6
			c0-0.1,0.1-0.2,0.1-0.3c2.3-6.1,3.4-11.5,3.5-16v-2.4c-0.2-5.7-1.9-10.3-4.4-13.9c-6.3-9-17-11.6-18.9-11.9L13.4,81.7H9.7v-0.1
			l0,0l0.1-3.4v-3.4h0.1v-0.1l3.6,0.1h654c6.9-5.2,10.8-14.7,13.2-24.3c3.8-15.1,3.6-30.4,3.5-32l-0.4-3.2h0.3v-0.3l3.5-0.1l3.5-0.4
			v0.3h0.3l0.1,3.2c0.2,1.6,2.3,17.7,8.7,29.5c3,5.6,6.8,10.3,12.3,11.4c5.7,1.1,12.5-1.7,21.3-10l0,0c2.8-2.7,6.5-4.1,10.5-4.1
			c15.9,0,66.2,0,85.6,0c5.7,0,10.9,2.7,14,7.2c4.9,7,17.5,17.1,46,9.7c1.6-0.9,3.3-1.9,5-2.6c8-3.4,16-4.3,16-4.3
			c0.2,0,0.3,0,0.5,0c0,0,62,0.7,151.4,1.9h157.2V14.8h7.2v43.8c210,2.5,452,5.5,479.7,5.7l0,0c2.3,0,4.4,0.5,6.5,1.3c0,0,0,0,0.1,0
			c18.5,7.2,31.2,16.1,39.6,25.7c0.7-4.3,4.7-7.7,9.4-7.7h151.5v6.8h-151.5c-1.3,0-2.3,1-2.3,2.2v8.6c1.4,2.5,2.6,5.1,3.6,7.6
			c5.9,15.4,4.2,31.6-0.9,46.6c-0.8,2.4-1.8,4.8-2.7,7.2v72.7c0,1.2,1,2.1,2.3,2.1l0,0c28.2,0,148,0.2,148,0.2h3.6v6.8h-3.6
			c0,0-119.8-0.2-148-0.2c-5.3,0-9.5-4.1-9.5-9v-58c-6.7,12-14.4,21.7-18.9,27l0,0c-3.1,3.5-7.6,5.6-12.5,5.7l0,0
			c-62.2,1.6-593.6,15-643.3,16.2c-3.1,0.1-6.1-0.6-8.8-2c-21.8-10.2-71.9-15.5-88.6-17c-4.6-0.4-8.7-2.6-11.7-6l0,0
			c-11.1-13.4-45.8-55.1-63.5-76.2v124.7c0,0.8-0.1,1.7-0.3,2.4h1008.8v6.8H769.3c-0.4,16.7-8.2,87.2-110.6,137.2h103.5
			c-1.1-3.4-22.9-72.2,46.6-107c0,0,0,0,0.1,0c2.1-1,4.3-1.5,6.5-1.5c12.4,0,67.4,0,82.1,0v-20.8c0-1.9,1.6-3.4,3.6-3.4
			c0,0,904.9-3,1009.5-0.6l3.6,0.1l-0.2,6.8l-3.6-0.1c-100.7-2.3-943.7,0.3-1005.7,0.5v20.8c0,1.9-1.6,3.4-3.6,3.4
			c0,0-71.3,0-85.6,0c-1.2,0-2.2,0.3-3.3,0.7c-66,33.2-43.2,99.2-43.2,99.2l0.7,2h173.6v-0.4l3.4,0.4h967.4v6.8H965.4
			c4.4,3.1,7.9,7,10.8,11.2c8.7,12.7,10.7,27.6,10.7,27.6l0.5,3.4l-7.1,0.8l-0.5-3.4c0,0-1.8-13.3-9.6-24.7
			c-5-7.2-12.4-13.6-23.6-14.9H643.9c-2.6,1.1-5.5,1.8-8.2,1.9c-26.5,1.1-187.6,8.4-292.5,22.5c-26.8,3.6-49.9,7.6-66.2,12
			c-10.1,2.8-17.5,5.6-20.9,8.8l-2.6,2.4l-5.1-4.9l2.6-2.4c3.1-2.8,8.5-5.6,16.2-8c16.4-5.5,43.1-10.4,74.9-14.6
			c59.6-8,137.4-13.8,198.3-17.5H9.7v-6.8h632.5c111.1-48.4,119.4-121.2,119.9-137.2H9.7v-6.8h572.1c-0.2-0.7-0.3-1.6-0.3-2.4V148
			L581.6,148z M559.2,81.7L559.2,81.7c0.1,0,13.6,2.4,22.2,12.8v-1.1c0-5.1,4.4-9.3,9.8-9.3h286.6c-0.8-5.4,0.3-9.8,2.4-13.4v-0.1
			c-25.9,3.4-37.8-7.6-42.8-14.9l0,0c-1.7-2.5-4.7-4.1-7.9-4.1c-19.5,0-69.8,0-85.6,0l0,0c-2.1,0-4,0.7-5.5,2.1
			c-8.9,8.4-16.6,11.8-22.9,12.2c-10.5,0.6-18.2-7-23.1-17c-1.2-2.3-2.2-4.7-3.1-7.1c-0.5,3.3-1.2,6.8-2,10.3
			c-2.9,11.8-8.1,23.1-17,29c-0.6,0.4-1.3,0.6-2.1,0.6H559.2L559.2,81.7z M1753.1,103.1c-7.2-11.8-20.4-22.6-42.3-31.2
			c-1.3-0.5-2.6-0.7-4-0.7c-35.5-0.5-423.4-5.1-644.2-7.7c-74.3,0-148.1,0-156.1,0c-5,1.9-9.6,3.4-13.9,4.6
			c-2.4,1.6-4.6,3.5-6.1,5.9c-1.6,2.7-2.3,6-1.5,10.1h11c5.4,0,9.7,4.2,9.7,9.3v20.8c14.3,17.2,56.6,67.9,69.2,83
			c1.7,1.9,4.1,3.2,6.7,3.4l0,0c17.3,1.6,68.8,7.2,91.2,17.8h0.1c1.7,0.8,3.5,1.3,5.3,1.2l0,0c49.7-1.2,581.1-14.6,643.3-16.2
			c2.8-0.1,5.4-1.3,7-3.2c5.9-6.8,17.1-21.4,24.5-38.8L1753.1,103.1L1753.1,103.1z M1760.4,133c0.1-2.1,0.1-4.3,0-6.5V133z
			 M896.5,252.4c1.3-0.2,2.3-1.2,2.3-2.4V116.6c-1.8-2.1-2.9-3.4-3.2-3.8c-8.1-8.4-13-15.6-15.5-21.8H591.4c-1.5,0-2.7,1.1-2.7,2.5
			v19.9c0.1,1.4,0.1,2.9,0,4.3V250c0,1.3,1,2.3,2.3,2.4h174.5l3.5-0.2v0.2H896.5L896.5,252.4z M898.6,105.9V93.4
			c0-1.4-1.2-2.5-2.6-2.5h-8.3C889.9,95.3,893.5,100.2,898.6,105.9z M1413,39.6v-7.9c0-2.6,2.1-4.7,5.1-4.7h408.1V14.8h7.2v15.5
			c0,1.9-1.6,3.4-3.6,3.4h-409.5v8c0,2.6-2.4,4.7-5.1,4.7h-137.6c-28,0-34.7-27.4-34.7-27.4l-0.8-3.3l7-1.6l0.8,3.3
			c0,0,5.3,22.1,27.7,22.1L1413,39.6L1413,39.6z M1022.8,443l-0.3,3.4l-7.1-0.6l0.3-3.4c0,0,0.7-9.3,11.4-18.7
			c9.8-8.5,28.7-17.3,64.8-17.3h0.1l822.3,14.8l-0.1,6.8l-3.6-0.1l-818.7-14.8C1026.5,413.2,1022.8,442.9,1022.8,443L1022.8,443z"/>
	</g>
	<line class="st5" x1="1755.6" y1="61.4" x2="1924.1" y2="61.4"/>
	<line class="st5" x1="1756.8" y1="219.6" x2="1925.3" y2="219.6"/>
	<path class="st4" d="M0.1,257.5c1.1,0,2.1,0,3.2,0h3c108.5-1.5,151-4.1,165.1-6.6c2-0.4,13.1-2.4,28.4-3.9
		c10-0.9,18.4-1.2,23.9-1.4"/>
	<path class="st4" d="M0,77.9c1.1,0,2.1,0,3.2,0h3c108.5-1.5,151-4.1,165.1-6.6c2-0.4,13.1-2.4,28.4-3.9c10-0.9,18.4-1.2,23.9-1.4"
		/>
	<path class="st6" d="M0,411.9c1.1,0,2.1,0,3.2,0h3c65.1,1.2,101.7-3.6,123.5-8.6c6.6-1.5,32.5-7.7,68.4-12.9
		c9.3-1.3,17.1-2.3,22-2.9"/>
</g>
<g id="Flower" class="st0">
	<path class="st7" d="M1919.3,138.2H0V3.2c5.7,0,11.5,0,17.2,0c634,0,1268.1,0,1902.1,0C1919.3,48.2,1919.3,93.2,1919.3,138.2z"/>
	<path class="st8" d="M6.2,15.9"/>
	<path class="st9" d="M29.5,10.3c3.4,0.1,10.1,2.1,13.6,2.9c1.6,0.4,4.9,1.1,9,2.4c5.7,1.6,7.8,2.4,9,2.9c3.3,1.4,6.8,3.2,7.9,4
		c2.2,1.6,5.9,8,4.5,5.4c1.9,3.8,1.5,6.9,1.1,8.9c0,0-0.1,0.7-1.7,5.8c-0.9,1.8-2.2,4-4,6.5c-0.6,0.9-3.1,4.5-7.3,8.9
		c-2.1,2.2-4.2,4-8.5,7.6c-3.9,3.3-5.9,5.1-7.9,6.5c-2.4,1.7-2.8,1.7-8.5,5.3c-3.4,2.2-5.1,3.2-6.2,4.2c-1.9,1.5-4.7,3.8-7.3,7.6
		c-2.6,3.9-3.7,7.3-4.5,10c-0.5,1.7-1.3,4.3-1.7,7.6c-0.8,7.2,0.7,12.9,0.6,12.9c-0.2,0-1.4-5.2-1.1-5.3c0.2-0.1,1.3,2.5,4.5,12.4"
		/>
	<path class="st9" d="M132.8,11.4c-3.4,0.1-10.1,2.1-13.6,2.9c-1.6,0.4-4.9,1.1-9,2.4c-5.7,1.6-7.8,2.4-9,2.9
		c-3.3,1.4-6.8,3.2-7.9,4c-2.2,1.6-5.9,8-4.5,5.4c-1.9,3.8-1.5,6.9-1.1,8.9c0,0,0.1,0.7,1.7,5.8c0.9,1.8,2.2,4,4,6.5
		c0.6,0.9,3.1,4.5,7.3,8.9c2.1,2.2,4.2,4,8.5,7.6c3.9,3.3,5.9,5.1,7.9,6.5c2.4,1.7,2.8,1.7,8.5,5.3c3.4,2.2,5.1,3.2,6.2,4.2
		c1.9,1.5,4.7,3.8,7.3,7.6c2.6,3.9,3.7,7.3,4.5,10c0.5,1.7,1.3,4.3,1.7,7.6c0.8,7.2-0.7,12.9-0.6,12.9c0.2,0,1.4-5.2,1.1-5.3
		c-0.2-0.1-1.3,2.5-4.5,12.4"/>
	<path class="st9" d="M173.8,14.5c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st9" d="M277.1,15.6c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st9" d="M324.6,15c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st9" d="M427.9,16.1c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st9" d="M467.7,16.7c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st9" d="M571,17.8c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8c-3.3,1.3-6.8,3.1-7.9,3.8
		c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2c0.6,0.9,3.1,4.3,7.3,8.5
		c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4c1.9,1.4,4.7,3.6,7.3,7.3
		s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st9" d="M611.9,18.4c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st9" d="M715.3,19.5c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st9" d="M762.7,19c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st9" d="M866.1,20.1c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st9" d="M905.4,18.6c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st9" d="M1008.7,19.7c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st9" d="M1049.6,20.3c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,6.9,5.7,5.5,3.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-3.2,5.8-5,8.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st9" d="M1155,19.4c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8c-3.3,1.3-6.8,3.1-7.9,3.8
		c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2c0.6,0.9,3.1,4.3,7.3,8.5
		c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4c1.9,1.4,4.7,3.6,7.3,7.3
		c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st9" d="M1202.4,18.9c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st9" d="M1305.8,19c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8c-3.3,1.3-6.8,3.1-7.9,3.8
		c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2c0.6,0.9,3.1,4.3,7.3,8.5
		c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4c1.9,1.4,4.7,3.6,7.3,7.3
		c2.6,3.7,3.7,8,4.5,10.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1c-0.2-0.1-1.3,1.4-4.5,10.9"/>
	<path class="st9" d="M1347.5,19.5c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,1.1,7.9,1.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,6.3-7.3,10.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st9" d="M1496.7,14.4c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st9" d="M1600,15.5c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8c-3.3,1.3-6.8,3.1-7.9,3.8
		c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2c0.6,0.9,3.1,4.3,7.3,8.5
		c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4c1.9,1.4,4.7,3.6,7.3,7.3
		c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st9" d="M1455.6,16c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8c-3.3,1.3-6.8,3.1-7.9,3.8
		c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2c0.6,0.9,3.1,4.3,7.3,8.5
		c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4c1.9,1.4,4.7,3.6,7.3,7.3
		s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st9" d="M1639.2,16c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st9" d="M1752,14.7c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8c-3.3,1.3-6.8,3.1-7.9,3.8
		c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2c0.6,0.9,3.1,4.3,7.3,8.5
		c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4c1.9,1.4,4.7,3.6,7.3,7.3
		c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st9" d="M1790.2,15.1c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st9" d="M1896.3,13.4c-3.4,0.1-10.1,2.1-13.6,2.9c-1.6,0.4-4.9,1.1-9,2.4c-5.7,1.6-7.8,2.4-9,2.9
		c-3.3,1.4-6.8,3.2-7.9,4c-2.2,1.6-5.9,8-4.5,5.4c-1.9,3.8-1.5,6.9-1.1,8.9c0,0,0.1,0.7,1.7,5.8c0.9,1.8,2.2,4,4,6.5
		c0.6,0.9,3.1,4.5,7.3,8.9c2.1,2.2,4.2,4,8.5,7.6c3.9,3.3,5.9,5.1,7.9,6.5c2.4,1.7,2.8,1.7,8.5,5.3c3.4,2.2,5.1,3.2,6.2,4.2
		c1.9,1.5,4.7,3.8,7.3,7.6c2.6,3.9,3.7,7.3,4.5,10c0.5,1.7,1.3,4.3,1.7,7.6c0.8,7.2-0.7,12.9-0.6,12.9c0.2,0,1.4-5.2,1.1-5.3
		c-0.2-0.1-1.3,2.5-4.5,12.4"/>
</g>
<g id="Brick" class="st0">
	<line class="st10" x1="0" y1="201.7" x2="1919.3" y2="201.7"/>
	<line class="st10" x1="0" y1="389" x2="1919.3" y2="389"/>
	<line class="st10" x1="67.4" y1="138.2" x2="67.4" y2="201.7"/>
	<line class="st10" x1="67.4" y1="393.7" x2="67.4" y2="457.2"/>
	<line class="st10" x1="726.9" y1="393.7" x2="726.9" y2="457.2"/>
	<line class="st10" x1="726.9" y1="138.2" x2="726.9" y2="201.7"/>
	<line class="st10" x1="397.2" y1="206.4" x2="397.2" y2="385.4"/>
	<line class="st10" x1="1104.7" y1="205.8" x2="1104.7" y2="384.9"/>
	<line class="st10" x1="1441.9" y1="138.2" x2="1441.9" y2="201.7"/>
	<line class="st10" x1="1441.9" y1="393.1" x2="1441.9" y2="456.6"/>
	<line class="st10" x1="1733.3" y1="204.1" x2="1733.3" y2="383.1"/>
	<g id="Layer_6" class="st11">
	</g>
</g>
<g id="Layer_7" class="st0">
	<line class="st12" x1="0" y1="201.4" x2="1919.3" y2="201.4"/>
	<line class="st12" x1="0" y1="394.5" x2="1919.3" y2="394.5"/>
	<line class="st12" x1="67.4" y1="135.9" x2="67.4" y2="201.4"/>
	<line class="st12" x1="67.4" y1="399.3" x2="67.4" y2="464.8"/>
	<line class="st12" x1="726.9" y1="399.3" x2="726.9" y2="464.8"/>
	<line class="st12" x1="726.9" y1="135.9" x2="726.9" y2="201.4"/>
	<line class="st12" x1="397.2" y1="206.2" x2="397.2" y2="390.8"/>
	<line class="st12" x1="1104.7" y1="205.7" x2="1104.7" y2="390.2"/>
	<line class="st12" x1="1441.9" y1="135.9" x2="1441.9" y2="201.4"/>
	<line class="st12" x1="1441.9" y1="398.7" x2="1441.9" y2="464.2"/>
	<line class="st12" x1="1733.3" y1="203.8" x2="1733.3" y2="388.4"/>
</g>
<g id="Brick_x27_s_bottom" class="st0">
	<line class="st13" x1="0" y1="285.6" x2="1919.3" y2="285.6"/>
	<line class="st13" x1="0" y1="164.4" x2="1919.3" y2="164.4"/>
	<path class="st14" d="M25,171.3"/>
</g>
<line class="st15" x1="0" y1="457.2" x2="1919.3" y2="457.2"/>
<line class="st16" x1="0" y1="7.6" x2="1919.3" y2="7.6"/>
<line class="st16" x1="-4" y1="135.9" x2="1915.3" y2="135.9"/>
<line class="st15" x1="0" y1="3.2" x2="1919.3" y2="3.2"/>
</svg>`,
    density: 0.5,
  },
  "Solid Stone Insulated": {
    pattern: (
      baseColor: string,
    ) => `<svg width="827" height="200" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1919.3 464.8" style="enable-background:new 0 0 1919.3 464.8;" xml:space="preserve">
<style type="text/css">
	.st0{display:none;}
	.st1{fill:none;stroke:${baseColor};stroke-width:30;stroke-miterlimit:10;}
	.st2{fill:none;stroke:${baseColor};stroke-width:9;stroke-miterlimit:10;}
	.st3{fill-rule:evenodd;clip-rule:evenodd;fill:#ECECEC;}
	.st4{fill-rule:evenodd;clip-rule:evenodd;stroke:${baseColor};stroke-width:21;stroke-miterlimit:10;}
	.st5{fill:none;stroke:${baseColor};stroke-width:21;stroke-miterlimit:10;}
	.st6{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-miterlimit:10;}
	.st7{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-width:8;stroke-miterlimit:10;}
	.st8{fill:none;stroke:${baseColor};stroke-width:8;stroke-miterlimit:10;}
	.st9{display:inline;fill:none;stroke:${baseColor};stroke-width:45;stroke-miterlimit:10;}
	.st10{display:inline;}
	.st11{display:inline;fill:none;stroke:${baseColor};stroke-width:28;stroke-miterlimit:10;}
	.st12{display:inline;fill:none;stroke:${baseColor};stroke-width:14;stroke-miterlimit:10;}
	.st13{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-width:9;stroke-miterlimit:10;}
	.st14{fill:none;stroke:${baseColor};stroke-width:15;stroke-miterlimit:10;}
	.st15{display:none;fill:none;stroke:${baseColor};stroke-width:15;stroke-miterlimit:10;}
</style>
<g id="Concrete" class="st0">
	<line class="st1" x1="0" y1="155.5" x2="1919.3" y2="155.5"/>
	<line class="st2" x1="328.3" y1="0" x2="328.3" y2="151.6"/>
	<line class="st1" x1="0" y1="301.4" x2="1919.3" y2="301.4"/>
	<line class="st2" x1="328.3" y1="297.3" x2="328.3" y2="464.8"/>
	<line class="st2" x1="1005.9" y1="0" x2="1005.9" y2="151.6"/>
	<line class="st2" x1="1655.3" y1="0" x2="1655.3" y2="151.6"/>
	<line class="st2" x1="1005.9" y1="300.7" x2="1005.9" y2="464.8"/>
	<line class="st2" x1="1655.3" y1="297.3" x2="1655.3" y2="464.8"/>
	<rect y="145.6" class="st3" width="1919.3" height="163.8"/>
</g>
<g id="Layer_2_00000041294061573236642060000008068305606222413205_">
	<g id="Layer_1-2">
		<path class="st4" d="M581.6,148l-25.3,98.1c-0.4,1.6-1.8,2.6-3.5,2.6c0,0-392.3,1.1-411.8,1.1l0,0c-1.3,0-3.1-0.9-3.8-2.2
			c0,0,0,0,0-0.1c-0.3-0.6-0.4-1.4-0.4-2.1c-0.1-0.2-0.5-0.6-1-1c-1.2-0.9-3.2-1.9-6.3-2.8c-13.2-4-44.3-7.1-116.1-2.9l-3.6,0.2
			L9.4,232l3.6-0.2c73.4-4.3,105.2-0.9,118.7,3.2c7.9,2.4,10.8,5.5,11.8,7.9c37.5-0.1,372-1,406.2-1.1l28.1-108.6
			c0-0.1,0.1-0.2,0.1-0.3c2.3-6.1,3.4-11.5,3.5-16v-2.4c-0.2-5.7-1.9-10.3-4.4-13.9c-6.3-9-17-11.6-18.9-11.9L13.4,81.7H9.7v-0.1
			l0,0l0.1-3.4v-3.4h0.1v-0.1l3.6,0.1h654c6.9-5.2,10.8-14.7,13.2-24.3c3.8-15.1,3.6-30.4,3.5-32l-0.4-3.2h0.3v-0.3l3.5-0.1l3.5-0.4
			v0.3h0.3l0.1,3.2c0.2,1.6,2.3,17.7,8.7,29.5c3,5.6,6.8,10.3,12.3,11.4c5.7,1.1,12.5-1.7,21.3-10l0,0c2.8-2.7,6.5-4.1,10.5-4.1
			c15.9,0,66.2,0,85.6,0c5.7,0,10.9,2.7,14,7.2c4.9,7,17.5,17.1,46,9.7c1.6-0.9,3.3-1.9,5-2.6c8-3.4,16-4.3,16-4.3
			c0.2,0,0.3,0,0.5,0c0,0,62,0.7,151.4,1.9h157.2V14.8h7.2v43.8c210,2.5,452,5.5,479.7,5.7l0,0c2.3,0,4.4,0.5,6.5,1.3c0,0,0,0,0.1,0
			c18.5,7.2,31.2,16.1,39.6,25.7c0.7-4.3,4.7-7.7,9.4-7.7h151.5v6.8h-151.5c-1.3,0-2.3,1-2.3,2.2v8.6c1.4,2.5,2.6,5.1,3.6,7.6
			c5.9,15.4,4.2,31.6-0.9,46.6c-0.8,2.4-1.8,4.8-2.7,7.2v72.7c0,1.2,1,2.1,2.3,2.1l0,0c28.2,0,148,0.2,148,0.2h3.6v6.8h-3.6
			c0,0-119.8-0.2-148-0.2c-5.3,0-9.5-4.1-9.5-9v-58c-6.7,12-14.4,21.7-18.9,27l0,0c-3.1,3.5-7.6,5.6-12.5,5.7l0,0
			c-62.2,1.6-593.6,15-643.3,16.2c-3.1,0.1-6.1-0.6-8.8-2c-21.8-10.2-71.9-15.5-88.6-17c-4.6-0.4-8.7-2.6-11.7-6l0,0
			c-11.1-13.4-45.8-55.1-63.5-76.2v124.7c0,0.8-0.1,1.7-0.3,2.4h1008.8v6.8H769.3c-0.4,16.7-8.2,87.2-110.6,137.2h103.5
			c-1.1-3.4-22.9-72.2,46.6-107c0,0,0,0,0.1,0c2.1-1,4.3-1.5,6.5-1.5c12.4,0,67.4,0,82.1,0v-20.8c0-1.9,1.6-3.4,3.6-3.4
			c0,0,904.9-3,1009.5-0.6l3.6,0.1l-0.2,6.8l-3.6-0.1c-100.7-2.3-943.7,0.3-1005.7,0.5v20.8c0,1.9-1.6,3.4-3.6,3.4
			c0,0-71.3,0-85.6,0c-1.2,0-2.2,0.3-3.3,0.7c-66,33.2-43.2,99.2-43.2,99.2l0.7,2h173.6v-0.4l3.4,0.4h967.4v6.8H965.4
			c4.4,3.1,7.9,7,10.8,11.2c8.7,12.7,10.7,27.6,10.7,27.6l0.5,3.4l-7.1,0.8l-0.5-3.4c0,0-1.8-13.3-9.6-24.7
			c-5-7.2-12.4-13.6-23.6-14.9H643.9c-2.6,1.1-5.5,1.8-8.2,1.9c-26.5,1.1-187.6,8.4-292.5,22.5c-26.8,3.6-49.9,7.6-66.2,12
			c-10.1,2.8-17.5,5.6-20.9,8.8l-2.6,2.4l-5.1-4.9l2.6-2.4c3.1-2.8,8.5-5.6,16.2-8c16.4-5.5,43.1-10.4,74.9-14.6
			c59.6-8,137.4-13.8,198.3-17.5H9.7v-6.8h632.5c111.1-48.4,119.4-121.2,119.9-137.2H9.7v-6.8h572.1c-0.2-0.7-0.3-1.6-0.3-2.4V148
			L581.6,148z M559.2,81.7L559.2,81.7c0.1,0,13.6,2.4,22.2,12.8v-1.1c0-5.1,4.4-9.3,9.8-9.3h286.6c-0.8-5.4,0.3-9.8,2.4-13.4v-0.1
			c-25.9,3.4-37.8-7.6-42.8-14.9l0,0c-1.7-2.5-4.7-4.1-7.9-4.1c-19.5,0-69.8,0-85.6,0l0,0c-2.1,0-4,0.7-5.5,2.1
			c-8.9,8.4-16.6,11.8-22.9,12.2c-10.5,0.6-18.2-7-23.1-17c-1.2-2.3-2.2-4.7-3.1-7.1c-0.5,3.3-1.2,6.8-2,10.3
			c-2.9,11.8-8.1,23.1-17,29c-0.6,0.4-1.3,0.6-2.1,0.6H559.2L559.2,81.7z M1753.1,103.1c-7.2-11.8-20.4-22.6-42.3-31.2
			c-1.3-0.5-2.6-0.7-4-0.7c-35.5-0.5-423.4-5.1-644.2-7.7c-74.3,0-148.1,0-156.1,0c-5,1.9-9.6,3.4-13.9,4.6
			c-2.4,1.6-4.6,3.5-6.1,5.9c-1.6,2.7-2.3,6-1.5,10.1h11c5.4,0,9.7,4.2,9.7,9.3v20.8c14.3,17.2,56.6,67.9,69.2,83
			c1.7,1.9,4.1,3.2,6.7,3.4l0,0c17.3,1.6,68.8,7.2,91.2,17.8h0.1c1.7,0.8,3.5,1.3,5.3,1.2l0,0c49.7-1.2,581.1-14.6,643.3-16.2
			c2.8-0.1,5.4-1.3,7-3.2c5.9-6.8,17.1-21.4,24.5-38.8L1753.1,103.1L1753.1,103.1z M1760.4,133c0.1-2.1,0.1-4.3,0-6.5V133z
			 M896.5,252.4c1.3-0.2,2.3-1.2,2.3-2.4V116.6c-1.8-2.1-2.9-3.4-3.2-3.8c-8.1-8.4-13-15.6-15.5-21.8H591.4c-1.5,0-2.7,1.1-2.7,2.5
			v19.9c0.1,1.4,0.1,2.9,0,4.3V250c0,1.3,1,2.3,2.3,2.4h174.5l3.5-0.2v0.2H896.5L896.5,252.4z M898.6,105.9V93.4
			c0-1.4-1.2-2.5-2.6-2.5h-8.3C889.9,95.3,893.5,100.2,898.6,105.9z M1413,39.6v-7.9c0-2.6,2.1-4.7,5.1-4.7h408.1V14.8h7.2v15.5
			c0,1.9-1.6,3.4-3.6,3.4h-409.5v8c0,2.6-2.4,4.7-5.1,4.7h-137.6c-28,0-34.7-27.4-34.7-27.4l-0.8-3.3l7-1.6l0.8,3.3
			c0,0,5.3,22.1,27.7,22.1L1413,39.6L1413,39.6z M1022.8,443l-0.3,3.4l-7.1-0.6l0.3-3.4c0,0,0.7-9.3,11.4-18.7
			c9.8-8.5,28.7-17.3,64.8-17.3h0.1l822.3,14.8l-0.1,6.8l-3.6-0.1l-818.7-14.8C1026.5,413.2,1022.8,442.9,1022.8,443L1022.8,443z"/>
	</g>
	<line class="st5" x1="1755.6" y1="61.4" x2="1924.1" y2="61.4"/>
	<line class="st5" x1="1756.8" y1="219.6" x2="1925.3" y2="219.6"/>
	<path class="st4" d="M0.1,257.5c1.1,0,2.1,0,3.2,0h3c108.5-1.5,151-4.1,165.1-6.6c2-0.4,13.1-2.4,28.4-3.9
		c10-0.9,18.4-1.2,23.9-1.4"/>
	<path class="st4" d="M0,77.9c1.1,0,2.1,0,3.2,0h3c108.5-1.5,151-4.1,165.1-6.6c2-0.4,13.1-2.4,28.4-3.9c10-0.9,18.4-1.2,23.9-1.4"
		/>
	<path class="st4" d="M0,411.9c1.1,0,2.1,0,3.2,0h3c65.1,1.2,101.7-3.6,123.5-8.6c6.6-1.5,32.5-7.7,68.4-12.9
		c9.3-1.3,17.1-2.3,22-2.9"/>
</g>
<g id="Flower">
	<path class="st6" d="M1919.3,138.2H0V3.2c5.7,0,11.5,0,17.2,0c634,0,1268.1,0,1902.1,0C1919.3,48.2,1919.3,93.2,1919.3,138.2z"/>
	<path class="st7" d="M6.2,15.9"/>
	<path class="st8" d="M29.5,10.3c3.4,0.1,10.1,2.1,13.6,2.9c1.6,0.4,4.9,1.1,9,2.4c5.7,1.6,7.8,2.4,9,2.9c3.3,1.4,6.8,3.2,7.9,4
		c2.2,1.6,5.9,8,4.5,5.4c1.9,3.8,1.5,6.9,1.1,8.9c0,0-0.1,0.7-1.7,5.8c-0.9,1.8-2.2,4-4,6.5c-0.6,0.9-3.1,4.5-7.3,8.9
		c-2.1,2.2-4.2,4-8.5,7.6c-3.9,3.3-5.9,5.1-7.9,6.5c-2.4,1.7-2.8,1.7-8.5,5.3c-3.4,2.2-5.1,3.2-6.2,4.2c-1.9,1.5-4.7,3.8-7.3,7.6
		c-2.6,3.9-3.7,7.3-4.5,10c-0.5,1.7-1.3,4.3-1.7,7.6c-0.8,7.2,0.7,12.9,0.6,12.9c-0.2,0-1.4-5.2-1.1-5.3c0.2-0.1,1.3,2.5,4.5,12.4"
		/>
	<path class="st8" d="M132.8,11.4c-3.4,0.1-10.1,2.1-13.6,2.9c-1.6,0.4-4.9,1.1-9,2.4c-5.7,1.6-7.8,2.4-9,2.9
		c-3.3,1.4-6.8,3.2-7.9,4c-2.2,1.6-5.9,8-4.5,5.4c-1.9,3.8-1.5,6.9-1.1,8.9c0,0,0.1,0.7,1.7,5.8c0.9,1.8,2.2,4,4,6.5
		c0.6,0.9,3.1,4.5,7.3,8.9c2.1,2.2,4.2,4,8.5,7.6c3.9,3.3,5.9,5.1,7.9,6.5c2.4,1.7,2.8,1.7,8.5,5.3c3.4,2.2,5.1,3.2,6.2,4.2
		c1.9,1.5,4.7,3.8,7.3,7.6c2.6,3.9,3.7,7.3,4.5,10c0.5,1.7,1.3,4.3,1.7,7.6c0.8,7.2-0.7,12.9-0.6,12.9c0.2,0,1.4-5.2,1.1-5.3
		c-0.2-0.1-1.3,2.5-4.5,12.4"/>
	<path class="st8" d="M173.8,14.5c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st8" d="M277.1,15.6c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st8" d="M324.6,15c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st8" d="M427.9,16.1c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st8" d="M467.7,16.7c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st8" d="M571,17.8c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8c-3.3,1.3-6.8,3.1-7.9,3.8
		c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2c0.6,0.9,3.1,4.3,7.3,8.5
		c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4c1.9,1.4,4.7,3.6,7.3,7.3
		s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st8" d="M611.9,18.4c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st8" d="M715.3,19.5c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st8" d="M762.7,19c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st8" d="M866.1,20.1c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st8" d="M905.4,18.6c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st8" d="M1008.7,19.7c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st8" d="M1049.6,20.3c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,6.9,5.7,5.5,3.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-3.2,5.8-5,8.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st8" d="M1155,19.4c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8c-3.3,1.3-6.8,3.1-7.9,3.8
		c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2c0.6,0.9,3.1,4.3,7.3,8.5
		c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4c1.9,1.4,4.7,3.6,7.3,7.3
		c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st8" d="M1202.4,18.9c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st8" d="M1305.8,19c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8c-3.3,1.3-6.8,3.1-7.9,3.8
		c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2c0.6,0.9,3.1,4.3,7.3,8.5
		c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4c1.9,1.4,4.7,3.6,7.3,7.3
		c2.6,3.7,3.7,8,4.5,10.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1c-0.2-0.1-1.3,1.4-4.5,10.9"/>
	<path class="st8" d="M1347.5,19.5c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,1.1,7.9,1.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,6.3-7.3,10.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st8" d="M1496.7,14.4c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st8" d="M1600,15.5c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8c-3.3,1.3-6.8,3.1-7.9,3.8
		c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2c0.6,0.9,3.1,4.3,7.3,8.5
		c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4c1.9,1.4,4.7,3.6,7.3,7.3
		c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st8" d="M1455.6,16c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8c-3.3,1.3-6.8,3.1-7.9,3.8
		c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2c0.6,0.9,3.1,4.3,7.3,8.5
		c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4c1.9,1.4,4.7,3.6,7.3,7.3
		s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st8" d="M1639.2,16c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st8" d="M1752,14.7c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8c-3.3,1.3-6.8,3.1-7.9,3.8
		c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2c0.6,0.9,3.1,4.3,7.3,8.5
		c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4c1.9,1.4,4.7,3.6,7.3,7.3
		c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st8" d="M1790.2,15.1c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st8" d="M1896.3,13.4c-3.4,0.1-10.1,2.1-13.6,2.9c-1.6,0.4-4.9,1.1-9,2.4c-5.7,1.6-7.8,2.4-9,2.9
		c-3.3,1.4-6.8,3.2-7.9,4c-2.2,1.6-5.9,8-4.5,5.4c-1.9,3.8-1.5,6.9-1.1,8.9c0,0,0.1,0.7,1.7,5.8c0.9,1.8,2.2,4,4,6.5
		c0.6,0.9,3.1,4.5,7.3,8.9c2.1,2.2,4.2,4,8.5,7.6c3.9,3.3,5.9,5.1,7.9,6.5c2.4,1.7,2.8,1.7,8.5,5.3c3.4,2.2,5.1,3.2,6.2,4.2
		c1.9,1.5,4.7,3.8,7.3,7.6c2.6,3.9,3.7,7.3,4.5,10c0.5,1.7,1.3,4.3,1.7,7.6c0.8,7.2-0.7,12.9-0.6,12.9c0.2,0,1.4-5.2,1.1-5.3
		c-0.2-0.1-1.3,2.5-4.5,12.4"/>
</g>
<g id="Brick" class="st0">
	<line class="st9" x1="0" y1="201.7" x2="1919.3" y2="201.7"/>
	<line class="st9" x1="0" y1="389" x2="1919.3" y2="389"/>
	<line class="st9" x1="67.4" y1="138.2" x2="67.4" y2="201.7"/>
	<line class="st9" x1="67.4" y1="393.7" x2="67.4" y2="457.2"/>
	<line class="st9" x1="726.9" y1="393.7" x2="726.9" y2="457.2"/>
	<line class="st9" x1="726.9" y1="138.2" x2="726.9" y2="201.7"/>
	<line class="st9" x1="397.2" y1="206.4" x2="397.2" y2="385.4"/>
	<line class="st9" x1="1104.7" y1="205.8" x2="1104.7" y2="384.9"/>
	<line class="st9" x1="1441.9" y1="138.2" x2="1441.9" y2="201.7"/>
	<line class="st9" x1="1441.9" y1="393.1" x2="1441.9" y2="456.6"/>
	<line class="st9" x1="1733.3" y1="204.1" x2="1733.3" y2="383.1"/>
	<g id="Layer_6" class="st10">
	</g>
</g>
<g id="Layer_7" class="st0">
	<line class="st11" x1="0" y1="201.4" x2="1919.3" y2="201.4"/>
	<line class="st11" x1="0" y1="394.5" x2="1919.3" y2="394.5"/>
	<line class="st11" x1="67.4" y1="135.9" x2="67.4" y2="201.4"/>
	<line class="st11" x1="67.4" y1="399.3" x2="67.4" y2="464.8"/>
	<line class="st11" x1="726.9" y1="399.3" x2="726.9" y2="464.8"/>
	<line class="st11" x1="726.9" y1="135.9" x2="726.9" y2="201.4"/>
	<line class="st11" x1="397.2" y1="206.2" x2="397.2" y2="390.8"/>
	<line class="st11" x1="1104.7" y1="205.7" x2="1104.7" y2="390.2"/>
	<line class="st11" x1="1441.9" y1="135.9" x2="1441.9" y2="201.4"/>
	<line class="st11" x1="1441.9" y1="398.7" x2="1441.9" y2="464.2"/>
	<line class="st11" x1="1733.3" y1="203.8" x2="1733.3" y2="388.4"/>
</g>
<g id="Brick_x27_s_bottom" class="st0">
	<line class="st12" x1="0" y1="285.6" x2="1919.3" y2="285.6"/>
	<line class="st12" x1="0" y1="164.4" x2="1919.3" y2="164.4"/>
	<path class="st13" d="M25,171.3"/>
</g>
<line class="st14" x1="0" y1="457.2" x2="1919.3" y2="457.2"/>
<line class="st15" x1="0" y1="7.6" x2="1919.3" y2="7.6"/>
<line class="st14" x1="-4" y1="135.9" x2="1915.3" y2="135.9"/>
<line class="st14" x1="0" y1="3.2" x2="1919.3" y2="3.2"/>
</svg>

`,
    density: 0.5,
  },
  "Solid Concrete": {
    pattern: (
      baseColor: string,
      { r, g, b }: { r: number; g: number; b: number },
    ) => `<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
            <rect x="0" y="0" width="50" height="50" fill="${baseColor}" />
            <rect x="50" y="0" width="50" height="50" fill="rgba(${r}, ${g}, ${b}, 0.5)" />
          </svg>`,
    density: 0.5,
  },
  "Solid Concrete Insulated": {
    pattern: (
      baseColor: string,
    ) => `<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill="${baseColor}" />
          </svg>`,
    density: 0.5,
  },
  "Cavity Wall Uninsulated": {
    pattern: (
      baseColor: string,
    ) => `<svg width="827" height="200" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1919.3 464.8" style="enable-background:new 0 0 1919.3 464.8;" xml:space="preserve">
<style type="text/css">
	.st0{display:none;}
	.st1{fill:none;stroke:${baseColor};stroke-width:30;stroke-miterlimit:10;}
	.st2{fill:none;stroke:${baseColor};stroke-width:9;stroke-miterlimit:10;}
	.st3{fill-rule:evenodd;clip-rule:evenodd;fill:#ECECEC;}
	.st4{display:inline;}
	.st5{fill-rule:evenodd;clip-rule:evenodd;stroke:${baseColor};stroke-width:21;stroke-miterlimit:10;}
	.st6{display:inline;fill:none;stroke:${baseColor};stroke-width:21;stroke-miterlimit:10;}
	.st7{display:inline;fill-rule:evenodd;clip-rule:evenodd;stroke:${baseColor};stroke-width:21;stroke-miterlimit:10;}
	.st8{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-miterlimit:10;}
	.st9{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-width:8;stroke-miterlimit:10;}
	.st10{display:inline;fill:none;stroke:${baseColor};stroke-width:8;stroke-miterlimit:10;}
	.st11{display:inline;fill:none;stroke:${baseColor};stroke-width:45;stroke-miterlimit:10;}
	.st12{fill:none;stroke:${baseColor};stroke-width:28;stroke-miterlimit:10;}
	.st13{display:none;fill:none;stroke:${baseColor};stroke-width:28;stroke-miterlimit:10;}
	.st14{display:inline;fill:none;stroke:${baseColor};stroke-width:14;stroke-miterlimit:10;}
	.st15{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-width:9;stroke-miterlimit:10;}
	.st16{fill:none;stroke:${baseColor};stroke-width:15;stroke-miterlimit:10;}
	.st17{display:none;fill:none;stroke:${baseColor};stroke-width:15;stroke-miterlimit:10;}
	.st18{fill:#DDDDDD;}
</style>
<g id="Concrete" class="st0">
	<line class="st1" x1="0" y1="155.5" x2="1919.3" y2="155.5"/>
	<line class="st2" x1="328.3" y1="0" x2="328.3" y2="151.6"/>
	<line class="st1" x1="0" y1="301.4" x2="1919.3" y2="301.4"/>
	<line class="st2" x1="328.3" y1="297.3" x2="328.3" y2="464.8"/>
	<line class="st2" x1="1005.9" y1="0" x2="1005.9" y2="151.6"/>
	<line class="st2" x1="1655.3" y1="0" x2="1655.3" y2="151.6"/>
	<line class="st2" x1="1005.9" y1="300.7" x2="1005.9" y2="464.8"/>
	<line class="st2" x1="1655.3" y1="297.3" x2="1655.3" y2="464.8"/>
	<rect y="145.6" class="st3" width="1919.3" height="163.8"/>
</g>
<g id="Layer_2_00000041294061573236642060000008068305606222413205_" class="st0">
	<g id="Layer_1-2" class="st4">
		<path class="st5" d="M581.6,148l-25.3,98.1c-0.4,1.6-1.8,2.6-3.5,2.6c0,0-392.3,1.1-411.8,1.1l0,0c-1.3,0-3.1-0.9-3.8-2.2
			c0,0,0,0,0-0.1c-0.3-0.6-0.4-1.4-0.4-2.1c-0.1-0.2-0.5-0.6-1-1c-1.2-0.9-3.2-1.9-6.3-2.8c-13.2-4-44.3-7.1-116.1-2.9l-3.6,0.2
			L9.4,232l3.6-0.2c73.4-4.3,105.2-0.9,118.7,3.2c7.9,2.4,10.8,5.5,11.8,7.9c37.5-0.1,372-1,406.2-1.1l28.1-108.6
			c0-0.1,0.1-0.2,0.1-0.3c2.3-6.1,3.4-11.5,3.5-16v-2.4c-0.2-5.7-1.9-10.3-4.4-13.9c-6.3-9-17-11.6-18.9-11.9L13.4,81.7H9.7v-0.1
			l0,0l0.1-3.4v-3.4h0.1v-0.1l3.6,0.1h654c6.9-5.2,10.8-14.7,13.2-24.3c3.8-15.1,3.6-30.4,3.5-32l-0.4-3.2h0.3v-0.3l3.5-0.1l3.5-0.4
			v0.3h0.3l0.1,3.2c0.2,1.6,2.3,17.7,8.7,29.5c3,5.6,6.8,10.3,12.3,11.4c5.7,1.1,12.5-1.7,21.3-10l0,0c2.8-2.7,6.5-4.1,10.5-4.1
			c15.9,0,66.2,0,85.6,0c5.7,0,10.9,2.7,14,7.2c4.9,7,17.5,17.1,46,9.7c1.6-0.9,3.3-1.9,5-2.6c8-3.4,16-4.3,16-4.3
			c0.2,0,0.3,0,0.5,0c0,0,62,0.7,151.4,1.9h157.2V14.8h7.2v43.8c210,2.5,452,5.5,479.7,5.7l0,0c2.3,0,4.4,0.5,6.5,1.3c0,0,0,0,0.1,0
			c18.5,7.2,31.2,16.1,39.6,25.7c0.7-4.3,4.7-7.7,9.4-7.7h151.5v6.8h-151.5c-1.3,0-2.3,1-2.3,2.2v8.6c1.4,2.5,2.6,5.1,3.6,7.6
			c5.9,15.4,4.2,31.6-0.9,46.6c-0.8,2.4-1.8,4.8-2.7,7.2v72.7c0,1.2,1,2.1,2.3,2.1l0,0c28.2,0,148,0.2,148,0.2h3.6v6.8h-3.6
			c0,0-119.8-0.2-148-0.2c-5.3,0-9.5-4.1-9.5-9v-58c-6.7,12-14.4,21.7-18.9,27l0,0c-3.1,3.5-7.6,5.6-12.5,5.7l0,0
			c-62.2,1.6-593.6,15-643.3,16.2c-3.1,0.1-6.1-0.6-8.8-2c-21.8-10.2-71.9-15.5-88.6-17c-4.6-0.4-8.7-2.6-11.7-6l0,0
			c-11.1-13.4-45.8-55.1-63.5-76.2v124.7c0,0.8-0.1,1.7-0.3,2.4h1008.8v6.8H769.3c-0.4,16.7-8.2,87.2-110.6,137.2h103.5
			c-1.1-3.4-22.9-72.2,46.6-107c0,0,0,0,0.1,0c2.1-1,4.3-1.5,6.5-1.5c12.4,0,67.4,0,82.1,0v-20.8c0-1.9,1.6-3.4,3.6-3.4
			c0,0,904.9-3,1009.5-0.6l3.6,0.1l-0.2,6.8l-3.6-0.1c-100.7-2.3-943.7,0.3-1005.7,0.5v20.8c0,1.9-1.6,3.4-3.6,3.4
			c0,0-71.3,0-85.6,0c-1.2,0-2.2,0.3-3.3,0.7c-66,33.2-43.2,99.2-43.2,99.2l0.7,2h173.6v-0.4l3.4,0.4h967.4v6.8H965.4
			c4.4,3.1,7.9,7,10.8,11.2c8.7,12.7,10.7,27.6,10.7,27.6l0.5,3.4l-7.1,0.8l-0.5-3.4c0,0-1.8-13.3-9.6-24.7
			c-5-7.2-12.4-13.6-23.6-14.9H643.9c-2.6,1.1-5.5,1.8-8.2,1.9c-26.5,1.1-187.6,8.4-292.5,22.5c-26.8,3.6-49.9,7.6-66.2,12
			c-10.1,2.8-17.5,5.6-20.9,8.8l-2.6,2.4l-5.1-4.9l2.6-2.4c3.1-2.8,8.5-5.6,16.2-8c16.4-5.5,43.1-10.4,74.9-14.6
			c59.6-8,137.4-13.8,198.3-17.5H9.7v-6.8h632.5c111.1-48.4,119.4-121.2,119.9-137.2H9.7v-6.8h572.1c-0.2-0.7-0.3-1.6-0.3-2.4V148
			L581.6,148z M559.2,81.7L559.2,81.7c0.1,0,13.6,2.4,22.2,12.8v-1.1c0-5.1,4.4-9.3,9.8-9.3h286.6c-0.8-5.4,0.3-9.8,2.4-13.4v-0.1
			c-25.9,3.4-37.8-7.6-42.8-14.9l0,0c-1.7-2.5-4.7-4.1-7.9-4.1c-19.5,0-69.8,0-85.6,0l0,0c-2.1,0-4,0.7-5.5,2.1
			c-8.9,8.4-16.6,11.8-22.9,12.2c-10.5,0.6-18.2-7-23.1-17c-1.2-2.3-2.2-4.7-3.1-7.1c-0.5,3.3-1.2,6.8-2,10.3
			c-2.9,11.8-8.1,23.1-17,29c-0.6,0.4-1.3,0.6-2.1,0.6H559.2L559.2,81.7z M1753.1,103.1c-7.2-11.8-20.4-22.6-42.3-31.2
			c-1.3-0.5-2.6-0.7-4-0.7c-35.5-0.5-423.4-5.1-644.2-7.7c-74.3,0-148.1,0-156.1,0c-5,1.9-9.6,3.4-13.9,4.6
			c-2.4,1.6-4.6,3.5-6.1,5.9c-1.6,2.7-2.3,6-1.5,10.1h11c5.4,0,9.7,4.2,9.7,9.3v20.8c14.3,17.2,56.6,67.9,69.2,83
			c1.7,1.9,4.1,3.2,6.7,3.4l0,0c17.3,1.6,68.8,7.2,91.2,17.8h0.1c1.7,0.8,3.5,1.3,5.3,1.2l0,0c49.7-1.2,581.1-14.6,643.3-16.2
			c2.8-0.1,5.4-1.3,7-3.2c5.9-6.8,17.1-21.4,24.5-38.8L1753.1,103.1L1753.1,103.1z M1760.4,133c0.1-2.1,0.1-4.3,0-6.5V133z
			 M896.5,252.4c1.3-0.2,2.3-1.2,2.3-2.4V116.6c-1.8-2.1-2.9-3.4-3.2-3.8c-8.1-8.4-13-15.6-15.5-21.8H591.4c-1.5,0-2.7,1.1-2.7,2.5
			v19.9c0.1,1.4,0.1,2.9,0,4.3V250c0,1.3,1,2.3,2.3,2.4h174.5l3.5-0.2v0.2H896.5L896.5,252.4z M898.6,105.9V93.4
			c0-1.4-1.2-2.5-2.6-2.5h-8.3C889.9,95.3,893.5,100.2,898.6,105.9z M1413,39.6v-7.9c0-2.6,2.1-4.7,5.1-4.7h408.1V14.8h7.2v15.5
			c0,1.9-1.6,3.4-3.6,3.4h-409.5v8c0,2.6-2.4,4.7-5.1,4.7h-137.6c-28,0-34.7-27.4-34.7-27.4l-0.8-3.3l7-1.6l0.8,3.3
			c0,0,5.3,22.1,27.7,22.1L1413,39.6L1413,39.6z M1022.8,443l-0.3,3.4l-7.1-0.6l0.3-3.4c0,0,0.7-9.3,11.4-18.7
			c9.8-8.5,28.7-17.3,64.8-17.3h0.1l822.3,14.8l-0.1,6.8l-3.6-0.1l-818.7-14.8C1026.5,413.2,1022.8,442.9,1022.8,443L1022.8,443z"/>
	</g>
	<line class="st6" x1="1755.6" y1="61.4" x2="1924.1" y2="61.4"/>
	<line class="st6" x1="1756.8" y1="219.6" x2="1925.3" y2="219.6"/>
	<path class="st7" d="M0.1,257.5c1.1,0,2.1,0,3.2,0h3c108.5-1.5,151-4.1,165.1-6.6c2-0.4,13.1-2.4,28.4-3.9
		c10-0.9,18.4-1.2,23.9-1.4"/>
	<path class="st7" d="M0,77.9c1.1,0,2.1,0,3.2,0h3c108.5-1.5,151-4.1,165.1-6.6c2-0.4,13.1-2.4,28.4-3.9c10-0.9,18.4-1.2,23.9-1.4"
		/>
	<path class="st7" d="M0,411.9c1.1,0,2.1,0,3.2,0h3c65.1,1.2,101.7-3.6,123.5-8.6c6.6-1.5,32.5-7.7,68.4-12.9
		c9.3-1.3,17.1-2.3,22-2.9"/>
</g>
<g id="Flower" class="st0">
	<path class="st8" d="M1919.3,138.2H0V3.2c5.7,0,11.5,0,17.2,0c634,0,1268.1,0,1902.1,0C1919.3,48.2,1919.3,93.2,1919.3,138.2z"/>
	<path class="st9" d="M6.2,15.9"/>
	<path class="st10" d="M29.5,10.3c3.4,0.1,10.1,2.1,13.6,2.9c1.6,0.4,4.9,1.1,9,2.4c5.7,1.6,7.8,2.4,9,2.9c3.3,1.4,6.8,3.2,7.9,4
		c2.2,1.6,5.9,8,4.5,5.4c1.9,3.8,1.5,6.9,1.1,8.9c0,0-0.1,0.7-1.7,5.8c-0.9,1.8-2.2,4-4,6.5c-0.6,0.9-3.1,4.5-7.3,8.9
		c-2.1,2.2-4.2,4-8.5,7.6c-3.9,3.3-5.9,5.1-7.9,6.5c-2.4,1.7-2.8,1.7-8.5,5.3c-3.4,2.2-5.1,3.2-6.2,4.2c-1.9,1.5-4.7,3.8-7.3,7.6
		c-2.6,3.9-3.7,7.3-4.5,10c-0.5,1.7-1.3,4.3-1.7,7.6c-0.8,7.2,0.7,12.9,0.6,12.9c-0.2,0-1.4-5.2-1.1-5.3c0.2-0.1,1.3,2.5,4.5,12.4"
		/>
	<path class="st10" d="M132.8,11.4c-3.4,0.1-10.1,2.1-13.6,2.9c-1.6,0.4-4.9,1.1-9,2.4c-5.7,1.6-7.8,2.4-9,2.9
		c-3.3,1.4-6.8,3.2-7.9,4c-2.2,1.6-5.9,8-4.5,5.4c-1.9,3.8-1.5,6.9-1.1,8.9c0,0,0.1,0.7,1.7,5.8c0.9,1.8,2.2,4,4,6.5
		c0.6,0.9,3.1,4.5,7.3,8.9c2.1,2.2,4.2,4,8.5,7.6c3.9,3.3,5.9,5.1,7.9,6.5c2.4,1.7,2.8,1.7,8.5,5.3c3.4,2.2,5.1,3.2,6.2,4.2
		c1.9,1.5,4.7,3.8,7.3,7.6c2.6,3.9,3.7,7.3,4.5,10c0.5,1.7,1.3,4.3,1.7,7.6c0.8,7.2-0.7,12.9-0.6,12.9c0.2,0,1.4-5.2,1.1-5.3
		c-0.2-0.1-1.3,2.5-4.5,12.4"/>
	<path class="st10" d="M173.8,14.5c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st10" d="M277.1,15.6c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st10" d="M324.6,15c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st10" d="M427.9,16.1c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st10" d="M467.7,16.7c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st10" d="M571,17.8c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8c-3.3,1.3-6.8,3.1-7.9,3.8
		c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2c0.6,0.9,3.1,4.3,7.3,8.5
		c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4c1.9,1.4,4.7,3.6,7.3,7.3
		s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st10" d="M611.9,18.4c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st10" d="M715.3,19.5c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st10" d="M762.7,19c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st10" d="M866.1,20.1c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st10" d="M905.4,18.6c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st10" d="M1008.7,19.7c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st10" d="M1049.6,20.3c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,6.9,5.7,5.5,3.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-3.2,5.8-5,8.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st10" d="M1155,19.4c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st10" d="M1202.4,18.9c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st10" d="M1305.8,19c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,8,4.5,10.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,1.4-4.5,10.9"/>
	<path class="st10" d="M1347.5,19.5c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,1.1,7.9,1.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,6.3-7.3,10.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st10" d="M1496.7,14.4c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st10" d="M1600,15.5c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st10" d="M1455.6,16c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st10" d="M1639.2,16c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st10" d="M1752,14.7c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
		c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
		c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
		c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
		c-0.2-0.1-1.3,2.4-4.5,11.9"/>
	<path class="st10" d="M1790.2,15.1c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
		c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
		c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
		s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
	<path class="st10" d="M1896.3,13.4c-3.4,0.1-10.1,2.1-13.6,2.9c-1.6,0.4-4.9,1.1-9,2.4c-5.7,1.6-7.8,2.4-9,2.9
		c-3.3,1.4-6.8,3.2-7.9,4c-2.2,1.6-5.9,8-4.5,5.4c-1.9,3.8-1.5,6.9-1.1,8.9c0,0,0.1,0.7,1.7,5.8c0.9,1.8,2.2,4,4,6.5
		c0.6,0.9,3.1,4.5,7.3,8.9c2.1,2.2,4.2,4,8.5,7.6c3.9,3.3,5.9,5.1,7.9,6.5c2.4,1.7,2.8,1.7,8.5,5.3c3.4,2.2,5.1,3.2,6.2,4.2
		c1.9,1.5,4.7,3.8,7.3,7.6c2.6,3.9,3.7,7.3,4.5,10c0.5,1.7,1.3,4.3,1.7,7.6c0.8,7.2-0.7,12.9-0.6,12.9c0.2,0,1.4-5.2,1.1-5.3
		c-0.2-0.1-1.3,2.5-4.5,12.4"/>
</g>
<g id="Brick" class="st0">
	<line class="st11" x1="0" y1="201.7" x2="1919.3" y2="201.7"/>
	<line class="st11" x1="0" y1="389" x2="1919.3" y2="389"/>
	<line class="st11" x1="67.4" y1="138.2" x2="67.4" y2="201.7"/>
	<line class="st11" x1="67.4" y1="393.7" x2="67.4" y2="457.2"/>
	<line class="st11" x1="726.9" y1="393.7" x2="726.9" y2="457.2"/>
	<line class="st11" x1="726.9" y1="138.2" x2="726.9" y2="201.7"/>
	<line class="st11" x1="397.2" y1="206.4" x2="397.2" y2="385.4"/>
	<line class="st11" x1="1104.7" y1="205.8" x2="1104.7" y2="384.9"/>
	<line class="st11" x1="1441.9" y1="138.2" x2="1441.9" y2="201.7"/>
	<line class="st11" x1="1441.9" y1="393.1" x2="1441.9" y2="456.6"/>
	<line class="st11" x1="1733.3" y1="204.1" x2="1733.3" y2="383.1"/>
	<g id="Layer_6" class="st4">
	</g>
</g>
<g id="Layer_7">
	<line class="st12" x1="0" y1="93.6" x2="1919.3" y2="93.6"/>
	<line class="st12" x1="0" y1="360.2" x2="1919.3" y2="360.2"/>
	<line class="st12" x1="123.8" y1="3.2" x2="123.8" y2="93.6"/>
	<line class="st12" x1="123.8" y1="366.8" x2="123.8" y2="457.2"/>
	<line class="st12" x1="800.4" y1="360.2" x2="800.4" y2="450.6"/>
	<line class="st12" x1="800.4" y1="3.2" x2="800.4" y2="93.6"/>
	<line class="st13" x1="397.2" y1="100.2" x2="397.2" y2="355.1"/>
	<line class="st13" x1="1104.7" y1="99.5" x2="1104.7" y2="354.2"/>
	<line class="st12" x1="1441.9" y1="3.2" x2="1441.9" y2="93.6"/>
	<line class="st12" x1="1441.9" y1="366" x2="1441.9" y2="456.4"/>
	<line class="st13" x1="1733.3" y1="96.9" x2="1733.3" y2="351.8"/>
</g>
<g id="Brick_x27_s_bottom" class="st0">
	<line class="st14" x1="0" y1="285.6" x2="1919.3" y2="285.6"/>
	<line class="st14" x1="0" y1="164.4" x2="1919.3" y2="164.4"/>
	<path class="st15" d="M25,171.3"/>
</g>
<line class="st16" x1="0" y1="457.2" x2="1919.3" y2="457.2"/>
<line class="st17" x1="0" y1="7.6" x2="1919.3" y2="7.6"/>
<line class="st17" x1="-4" y1="135.9" x2="1915.3" y2="135.9"/>
<line class="st16" x1="0" y1="3.2" x2="1919.3" y2="3.2"/>
<polygon class="st18" points="0,111.8 0,345.5 1919.3,345.5 1919.3,108.4 "/>
<path class="st18" d="M2146.6,257.5"/>
<path class="st18" d="M2383.8,2453"/>
</svg>
`,
    density: 0.5,
  },
  "Cavity Wall Insulated": {
    pattern: (
      baseColor: string,
    ) => `<svg width="827" height="200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1919.3 464.8" style="enable-background:new 0 0 1919.3 464.8;" xml:space="preserve">
<style type="text/css">
	.st0{display:none;}
	.st1{fill:none;stroke:${baseColor};stroke-width:30;stroke-miterlimit:10;}
	.st2{fill:none;stroke:${baseColor};stroke-width:9;stroke-miterlimit:10;}
	.st3{fill-rule:evenodd;clip-rule:evenodd;fill:#ECECEC;}
	.st4{display:inline;}
	.st5{fill-rule:evenodd;clip-rule:evenodd;stroke:${baseColor};stroke-width:21;stroke-miterlimit:10;}
	.st6{display:inline;fill:none;stroke:${baseColor};stroke-width:21;stroke-miterlimit:10;}
	.st7{display:inline;fill-rule:evenodd;clip-rule:evenodd;stroke:${baseColor};stroke-width:21;stroke-miterlimit:10;}
	.st8{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-miterlimit:10;}
	.st9{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-width:8;stroke-miterlimit:10;}
	.st10{display:inline;fill:none;stroke:${baseColor};stroke-width:8;stroke-miterlimit:10;}
	.st11{display:inline;fill:none;stroke:${baseColor};stroke-width:45;stroke-miterlimit:10;}
	.st12{fill:none;stroke:${baseColor};stroke-width:44;stroke-miterlimit:10;}
	.st13{fill:none;stroke:${baseColor};stroke-width:40;stroke-miterlimit:10;}
	.st14{fill:none;stroke:${baseColor};stroke-width:28;stroke-miterlimit:10;}
	.st15{display:none;fill:none;stroke:${baseColor};stroke-width:28;stroke-miterlimit:10;}
	.st16{display:inline;fill:none;stroke:${baseColor};stroke-width:14;stroke-miterlimit:10;}
	.st17{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-width:9;stroke-miterlimit:10;}
	.st18{fill:none;stroke:${baseColor};stroke-width:15;stroke-miterlimit:10;}
	.st19{display:none;fill:none;stroke:${baseColor};stroke-width:15;stroke-miterlimit:10;}
	.st20{display:none;fill:#DDDDDD;}
	.st21{fill:#DDDDDD;}
	.st22{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-miterlimit:10;}
	.st23{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-width:8;stroke-miterlimit:10;}
	.st24{fill:none;stroke:${baseColor};stroke-width:8;stroke-miterlimit:10;}
</style>
<g id="Layer_1">
	<g id="Concrete" class="st0">
		<line class="st1" x1="0" y1="155.5" x2="1919.3" y2="155.5"/>
		<line class="st2" x1="328.3" y1="0" x2="328.3" y2="151.6"/>
		<line class="st1" x1="0" y1="301.4" x2="1919.3" y2="301.4"/>
		<line class="st2" x1="328.3" y1="297.3" x2="328.3" y2="464.8"/>
		<line class="st2" x1="1005.9" y1="0" x2="1005.9" y2="151.6"/>
		<line class="st2" x1="1655.3" y1="0" x2="1655.3" y2="151.6"/>
		<line class="st2" x1="1005.9" y1="300.7" x2="1005.9" y2="464.8"/>
		<line class="st2" x1="1655.3" y1="297.3" x2="1655.3" y2="464.8"/>
		<rect y="145.6" class="st3" width="1919.3" height="163.8"/>
	</g>
	<g id="Layer_2_00000041294061573236642060000008068305606222413205_" class="st0">
		<g id="Layer_1-2" class="st4">
			<path class="st5" d="M581.6,148l-25.3,98.1c-0.4,1.6-1.8,2.6-3.5,2.6c0,0-392.3,1.1-411.8,1.1l0,0c-1.3,0-3.1-0.9-3.8-2.2
				c0,0,0,0,0-0.1c-0.3-0.6-0.4-1.4-0.4-2.1c-0.1-0.2-0.5-0.6-1-1c-1.2-0.9-3.2-1.9-6.3-2.8c-13.2-4-44.3-7.1-116.1-2.9l-3.6,0.2
				L9.4,232l3.6-0.2c73.4-4.3,105.2-0.9,118.7,3.2c7.9,2.4,10.8,5.5,11.8,7.9c37.5-0.1,372-1,406.2-1.1l28.1-108.6
				c0-0.1,0.1-0.2,0.1-0.3c2.3-6.1,3.4-11.5,3.5-16v-2.4c-0.2-5.7-1.9-10.3-4.4-13.9c-6.3-9-17-11.6-18.9-11.9L13.4,81.7H9.7v-0.1
				l0,0l0.1-3.4v-3.4h0.1v-0.1l3.6,0.1h654c6.9-5.2,10.8-14.7,13.2-24.3c3.8-15.1,3.6-30.4,3.5-32l-0.4-3.2h0.3v-0.3l3.5-0.1
				l3.5-0.4v0.3h0.3l0.1,3.2c0.2,1.6,2.3,17.7,8.7,29.5c3,5.6,6.8,10.3,12.3,11.4c5.7,1.1,12.5-1.7,21.3-10l0,0
				c2.8-2.7,6.5-4.1,10.5-4.1c15.9,0,66.2,0,85.6,0c5.7,0,10.9,2.7,14,7.2c4.9,7,17.5,17.1,46,9.7c1.6-0.9,3.3-1.9,5-2.6
				c8-3.4,16-4.3,16-4.3c0.2,0,0.3,0,0.5,0c0,0,62,0.7,151.4,1.9h157.2V14.8h7.2v43.8c210,2.5,452,5.5,479.7,5.7l0,0
				c2.3,0,4.4,0.5,6.5,1.3c0,0,0,0,0.1,0c18.5,7.2,31.2,16.1,39.6,25.7c0.7-4.3,4.7-7.7,9.4-7.7h151.5v6.8h-151.5
				c-1.3,0-2.3,1-2.3,2.2v8.6c1.4,2.5,2.6,5.1,3.6,7.6c5.9,15.4,4.2,31.6-0.9,46.6c-0.8,2.4-1.8,4.8-2.7,7.2v72.7
				c0,1.2,1,2.1,2.3,2.1l0,0c28.2,0,148,0.2,148,0.2h3.6v6.8h-3.6c0,0-119.8-0.2-148-0.2c-5.3,0-9.5-4.1-9.5-9v-58
				c-6.7,12-14.4,21.7-18.9,27l0,0c-3.1,3.5-7.6,5.6-12.5,5.7l0,0c-62.2,1.6-593.6,15-643.3,16.2c-3.1,0.1-6.1-0.6-8.8-2
				c-21.8-10.2-71.9-15.5-88.6-17c-4.6-0.4-8.7-2.6-11.7-6l0,0c-11.1-13.4-45.8-55.1-63.5-76.2v124.7c0,0.8-0.1,1.7-0.3,2.4h1008.8
				v6.8H769.3c-0.4,16.7-8.2,87.2-110.6,137.2h103.5c-1.1-3.4-22.9-72.2,46.6-107c0,0,0,0,0.1,0c2.1-1,4.3-1.5,6.5-1.5
				c12.4,0,67.4,0,82.1,0v-20.8c0-1.9,1.6-3.4,3.6-3.4c0,0,904.9-3,1009.5-0.6l3.6,0.1l-0.2,6.8l-3.6-0.1
				c-100.7-2.3-943.7,0.3-1005.7,0.5v20.8c0,1.9-1.6,3.4-3.6,3.4c0,0-71.3,0-85.6,0c-1.2,0-2.2,0.3-3.3,0.7
				c-66,33.2-43.2,99.2-43.2,99.2l0.7,2h173.6v-0.4l3.4,0.4h967.4v6.8H965.4c4.4,3.1,7.9,7,10.8,11.2c8.7,12.7,10.7,27.6,10.7,27.6
				l0.5,3.4l-7.1,0.8l-0.5-3.4c0,0-1.8-13.3-9.6-24.7c-5-7.2-12.4-13.6-23.6-14.9H643.9c-2.6,1.1-5.5,1.8-8.2,1.9
				c-26.5,1.1-187.6,8.4-292.5,22.5c-26.8,3.6-49.9,7.6-66.2,12c-10.1,2.8-17.5,5.6-20.9,8.8l-2.6,2.4l-5.1-4.9l2.6-2.4
				c3.1-2.8,8.5-5.6,16.2-8c16.4-5.5,43.1-10.4,74.9-14.6c59.6-8,137.4-13.8,198.3-17.5H9.7v-6.8h632.5
				c111.1-48.4,119.4-121.2,119.9-137.2H9.7v-6.8h572.1c-0.2-0.7-0.3-1.6-0.3-2.4V148L581.6,148z M559.2,81.7L559.2,81.7
				c0.1,0,13.6,2.4,22.2,12.8v-1.1c0-5.1,4.4-9.3,9.8-9.3h286.6c-0.8-5.4,0.3-9.8,2.4-13.4v-0.1c-25.9,3.4-37.8-7.6-42.8-14.9l0,0
				c-1.7-2.5-4.7-4.1-7.9-4.1c-19.5,0-69.8,0-85.6,0l0,0c-2.1,0-4,0.7-5.5,2.1c-8.9,8.4-16.6,11.8-22.9,12.2
				c-10.5,0.6-18.2-7-23.1-17c-1.2-2.3-2.2-4.7-3.1-7.1c-0.5,3.3-1.2,6.8-2,10.3c-2.9,11.8-8.1,23.1-17,29c-0.6,0.4-1.3,0.6-2.1,0.6
				H559.2L559.2,81.7z M1753.1,103.1c-7.2-11.8-20.4-22.6-42.3-31.2c-1.3-0.5-2.6-0.7-4-0.7c-35.5-0.5-423.4-5.1-644.2-7.7
				c-74.3,0-148.1,0-156.1,0c-5,1.9-9.6,3.4-13.9,4.6c-2.4,1.6-4.6,3.5-6.1,5.9c-1.6,2.7-2.3,6-1.5,10.1h11c5.4,0,9.7,4.2,9.7,9.3
				v20.8c14.3,17.2,56.6,67.9,69.2,83c1.7,1.9,4.1,3.2,6.7,3.4l0,0c17.3,1.6,68.8,7.2,91.2,17.8h0.1c1.7,0.8,3.5,1.3,5.3,1.2l0,0
				c49.7-1.2,581.1-14.6,643.3-16.2c2.8-0.1,5.4-1.3,7-3.2c5.9-6.8,17.1-21.4,24.5-38.8L1753.1,103.1L1753.1,103.1z M1760.4,133
				c0.1-2.1,0.1-4.3,0-6.5V133z M896.5,252.4c1.3-0.2,2.3-1.2,2.3-2.4V116.6c-1.8-2.1-2.9-3.4-3.2-3.8c-8.1-8.4-13-15.6-15.5-21.8
				H591.4c-1.5,0-2.7,1.1-2.7,2.5v19.9c0.1,1.4,0.1,2.9,0,4.3V250c0,1.3,1,2.3,2.3,2.4h174.5l3.5-0.2v0.2H896.5L896.5,252.4z
				 M898.6,105.9V93.4c0-1.4-1.2-2.5-2.6-2.5h-8.3C889.9,95.3,893.5,100.2,898.6,105.9z M1413,39.6v-7.9c0-2.6,2.1-4.7,5.1-4.7
				h408.1V14.8h7.2v15.5c0,1.9-1.6,3.4-3.6,3.4h-409.5v8c0,2.6-2.4,4.7-5.1,4.7h-137.6c-28,0-34.7-27.4-34.7-27.4l-0.8-3.3l7-1.6
				l0.8,3.3c0,0,5.3,22.1,27.7,22.1L1413,39.6L1413,39.6z M1022.8,443l-0.3,3.4l-7.1-0.6l0.3-3.4c0,0,0.7-9.3,11.4-18.7
				c9.8-8.5,28.7-17.3,64.8-17.3h0.1l822.3,14.8l-0.1,6.8l-3.6-0.1l-818.7-14.8C1026.5,413.2,1022.8,442.9,1022.8,443L1022.8,443z"
				/>
		</g>
		<line class="st6" x1="1755.6" y1="61.4" x2="1924.1" y2="61.4"/>
		<line class="st6" x1="1756.8" y1="219.6" x2="1925.3" y2="219.6"/>
		<path class="st7" d="M0.1,257.5c1.1,0,2.1,0,3.2,0h3c108.5-1.5,151-4.1,165.1-6.6c2-0.4,13.1-2.4,28.4-3.9
			c10-0.9,18.4-1.2,23.9-1.4"/>
		<path class="st7" d="M0,77.9c1.1,0,2.1,0,3.2,0h3c108.5-1.5,151-4.1,165.1-6.6c2-0.4,13.1-2.4,28.4-3.9c10-0.9,18.4-1.2,23.9-1.4"
			/>
		<path class="st7" d="M0,411.9c1.1,0,2.1,0,3.2,0h3c65.1,1.2,101.7-3.6,123.5-8.6c6.6-1.5,32.5-7.7,68.4-12.9
			c9.3-1.3,17.1-2.3,22-2.9"/>
	</g>
	<g id="Flower" class="st0">
		<path class="st8" d="M1919.3,138.2H0V3.2c5.7,0,11.5,0,17.2,0c634,0,1268.1,0,1902.1,0C1919.3,48.2,1919.3,93.2,1919.3,138.2z"/>
		<path class="st9" d="M6.2,15.9"/>
		<path class="st10" d="M29.5,10.3c3.4,0.1,10.1,2.1,13.6,2.9c1.6,0.4,4.9,1.1,9,2.4c5.7,1.6,7.8,2.4,9,2.9c3.3,1.4,6.8,3.2,7.9,4
			c2.2,1.6,5.9,8,4.5,5.4c1.9,3.8,1.5,6.9,1.1,8.9c0,0-0.1,0.7-1.7,5.8c-0.9,1.8-2.2,4-4,6.5c-0.6,0.9-3.1,4.5-7.3,8.9
			c-2.1,2.2-4.2,4-8.5,7.6c-3.9,3.3-5.9,5.1-7.9,6.5c-2.4,1.7-2.8,1.7-8.5,5.3c-3.4,2.2-5.1,3.2-6.2,4.2c-1.9,1.5-4.7,3.8-7.3,7.6
			c-2.6,3.9-3.7,7.3-4.5,10c-0.5,1.7-1.3,4.3-1.7,7.6c-0.8,7.2,0.7,12.9,0.6,12.9c-0.2,0-1.4-5.2-1.1-5.3c0.2-0.1,1.3,2.5,4.5,12.4"
			/>
		<path class="st10" d="M132.8,11.4c-3.4,0.1-10.1,2.1-13.6,2.9c-1.6,0.4-4.9,1.1-9,2.4c-5.7,1.6-7.8,2.4-9,2.9
			c-3.3,1.4-6.8,3.2-7.9,4c-2.2,1.6-5.9,8-4.5,5.4c-1.9,3.8-1.5,6.9-1.1,8.9c0,0,0.1,0.7,1.7,5.8c0.9,1.8,2.2,4,4,6.5
			c0.6,0.9,3.1,4.5,7.3,8.9c2.1,2.2,4.2,4,8.5,7.6c3.9,3.3,5.9,5.1,7.9,6.5c2.4,1.7,2.8,1.7,8.5,5.3c3.4,2.2,5.1,3.2,6.2,4.2
			c1.9,1.5,4.7,3.8,7.3,7.6c2.6,3.9,3.7,7.3,4.5,10c0.5,1.7,1.3,4.3,1.7,7.6c0.8,7.2-0.7,12.9-0.6,12.9c0.2,0,1.4-5.2,1.1-5.3
			c-0.2-0.1-1.3,2.5-4.5,12.4"/>
		<path class="st10" d="M173.8,14.5c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M277.1,15.6c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M324.6,15c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M427.9,16.1c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M467.7,16.7c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M571,17.8c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M611.9,18.4c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M715.3,19.5c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M762.7,19c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M866.1,20.1c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M905.4,18.6c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M1008.7,19.7c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M1049.6,20.3c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,6.9,5.7,5.5,3.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-3.2,5.8-5,8.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M1155,19.4c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M1202.4,18.9c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M1305.8,19c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,8,4.5,10.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,1.4-4.5,10.9"/>
		<path class="st10" d="M1347.5,19.5c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,1.1,7.9,1.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,6.3-7.3,10.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M1496.7,14.4c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M1600,15.5c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M1455.6,16c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M1639.2,16c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M1752,14.7c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M1790.2,15.1c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M1896.3,13.4c-3.4,0.1-10.1,2.1-13.6,2.9c-1.6,0.4-4.9,1.1-9,2.4c-5.7,1.6-7.8,2.4-9,2.9
			c-3.3,1.4-6.8,3.2-7.9,4c-2.2,1.6-5.9,8-4.5,5.4c-1.9,3.8-1.5,6.9-1.1,8.9c0,0,0.1,0.7,1.7,5.8c0.9,1.8,2.2,4,4,6.5
			c0.6,0.9,3.1,4.5,7.3,8.9c2.1,2.2,4.2,4,8.5,7.6c3.9,3.3,5.9,5.1,7.9,6.5c2.4,1.7,2.8,1.7,8.5,5.3c3.4,2.2,5.1,3.2,6.2,4.2
			c1.9,1.5,4.7,3.8,7.3,7.6c2.6,3.9,3.7,7.3,4.5,10c0.5,1.7,1.3,4.3,1.7,7.6c0.8,7.2-0.7,12.9-0.6,12.9c0.2,0,1.4-5.2,1.1-5.3
			c-0.2-0.1-1.3,2.5-4.5,12.4"/>
	</g>
	<g id="Brick" class="st0">
		<line class="st11" x1="0" y1="201.7" x2="1919.3" y2="201.7"/>
		<line class="st11" x1="0" y1="389" x2="1919.3" y2="389"/>
		<line class="st11" x1="67.4" y1="138.2" x2="67.4" y2="201.7"/>
		<line class="st11" x1="67.4" y1="393.7" x2="67.4" y2="457.2"/>
		<line class="st11" x1="726.9" y1="393.7" x2="726.9" y2="457.2"/>
		<line class="st11" x1="726.9" y1="138.2" x2="726.9" y2="201.7"/>
		<line class="st11" x1="397.2" y1="206.4" x2="397.2" y2="385.4"/>
		<line class="st11" x1="1104.7" y1="205.8" x2="1104.7" y2="384.9"/>
		<line class="st11" x1="1441.9" y1="138.2" x2="1441.9" y2="201.7"/>
		<line class="st11" x1="1441.9" y1="393.1" x2="1441.9" y2="456.6"/>
		<line class="st11" x1="1733.3" y1="204.1" x2="1733.3" y2="383.1"/>
		<g id="Layer_6" class="st4">
		</g>
	</g>
	<g id="Layer_7">
		<line class="st12" x1="0" y1="135.5" x2="1919.3" y2="135.5"/>
		<line class="st13" x1="5" y1="325.2" x2="1924.3" y2="325.2"/>
		<line class="st14" x1="123.8" y1="3.2" x2="123.8" y2="113.5"/>
		<line class="st14" x1="123.8" y1="345.5" x2="123.8" y2="457.2"/>
		<line class="st14" x1="800.4" y1="345.5" x2="800.4" y2="450.6"/>
		<line class="st14" x1="800.4" y1="3.2" x2="800.4" y2="113.5"/>
		<line class="st15" x1="397.2" y1="100.2" x2="397.2" y2="355.1"/>
		<line class="st15" x1="1104.7" y1="99.5" x2="1104.7" y2="354.2"/>
		<line class="st14" x1="1441.9" y1="3.2" x2="1441.9" y2="113.5"/>
		<line class="st14" x1="1441.9" y1="345.5" x2="1441.9" y2="456.4"/>
		<line class="st15" x1="1733.3" y1="96.9" x2="1733.3" y2="351.8"/>
	</g>
	<g id="Brick_x27_s_bottom" class="st0">
		<line class="st16" x1="0" y1="285.6" x2="1919.3" y2="285.6"/>
		<line class="st16" x1="0" y1="164.4" x2="1919.3" y2="164.4"/>
		<path class="st17" d="M25,171.3"/>
	</g>
	<line class="st18" x1="0" y1="457.2" x2="1919.3" y2="457.2"/>
	<line class="st19" x1="0" y1="7.6" x2="1919.3" y2="7.6"/>
	<line class="st19" x1="-4" y1="135.9" x2="1915.3" y2="135.9"/>
	<line class="st18" x1="0" y1="3.2" x2="1919.3" y2="3.2"/>
	<polygon class="st20" points="0,131.6 0,333.7 1919.3,333.7 1919.3,128.7 	"/>
	<path class="st21" d="M2146.6,257.5"/>
	<path class="st21" d="M2383.8,2453"/>
</g>
<g id="Layer_2">
	<g id="Flower_00000046301666801641120990000001772011929299514508_">
		<path class="st22" d="M1919.3,312.7H0v-165c5.7,0,11.5,0,17.2,0c634,0,1268.1,0,1902.1,0C1919.3,202.7,1919.3,257.7,1919.3,312.7z
			"/>
		<path class="st23" d="M6.2,163.2"/>
		<path class="st24" d="M29.5,156.4c3.4,0.1,10.1,2.6,13.6,3.6c1.6,0.5,4.9,1.4,9,2.9c5.7,1.9,7.8,2.9,9,3.6c3.3,1.7,6.8,4,7.9,4.8
			c2.2,1.9,5.9,9.8,4.5,6.6c1.9,4.6,1.5,8.4,1.1,10.8c0,0-0.1,0.9-1.7,7.1c-0.9,2.2-2.2,4.8-4,7.9c-0.6,1.1-3.1,5.5-7.3,10.8
			c-2.1,2.7-4.2,4.8-8.5,9.3c-3.9,4.1-5.9,6.2-7.9,7.9c-2.4,2-2.8,2-8.5,6.5c-3.4,2.7-5.1,4-6.2,5.1c-1.9,1.8-4.7,4.6-7.3,9.3
			s-3.7,8.9-4.5,12.2c-0.5,2-1.3,5.2-1.7,9.3c-0.8,8.8,0.7,15.8,0.6,15.8c-0.2,0-1.4-6.4-1.1-6.5c0.2-0.1,1.3,3.1,4.5,15.2"/>
		<path class="st24" d="M132.8,157.8c-3.4,0.1-10.1,2.6-13.6,3.6c-1.6,0.5-4.9,1.4-9,2.9c-5.7,1.9-7.8,2.9-9,3.6
			c-3.3,1.7-6.8,4-7.9,4.8c-2.2,1.9-5.9,9.8-4.5,6.6c-1.9,4.6-1.5,8.4-1.1,10.8c0,0,0.1,0.9,1.7,7.1c0.9,2.2,2.2,4.8,4,7.9
			c0.6,1.1,3.1,5.5,7.3,10.8c2.1,2.7,4.2,4.8,8.5,9.3c3.9,4.1,5.9,6.2,7.9,7.9c2.4,2,2.8,2,8.5,6.5c3.4,2.7,5.1,4,6.2,5.1
			c1.9,1.8,4.7,4.6,7.3,9.3s3.7,8.9,4.5,12.2c0.5,2,1.3,5.2,1.7,9.3c0.8,8.8-0.7,15.8-0.6,15.8c0.2,0,1.4-6.4,1.1-6.5
			c-0.2-0.1-1.3,3.1-4.5,15.2"/>
		<path class="st24" d="M173.8,161.5c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st24" d="M277.1,162.9c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st24" d="M324.6,162.1c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st24" d="M427.9,163.5c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st24" d="M467.7,164.2c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st24" d="M571,165.5c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9s3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st24" d="M611.9,166.3c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st24" d="M715.3,167.6c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st24" d="M762.7,167c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4c3.3,1.6,6.8,3.8,7.9,4.6
			c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6c-0.6,1.1-3.1,5.3-7.3,10.4
			c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9c-1.9,1.7-4.7,4.4-7.3,8.9
			c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st24" d="M866.1,168.4c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9s3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st24" d="M905.4,166.5c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9s-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st24" d="M1008.7,167.9c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st24" d="M1049.6,168.6c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,6.9,7,5.5,3.9c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-3.2,7.1-5,10
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st24" d="M1155,167.5c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st24" d="M1202.4,166.9c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st24" d="M1305.8,167c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,9.8,4.5,13c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,1.7-4.5,13.3"/>
		<path class="st24" d="M1347.5,167.6c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,1.3,7.9,2.2c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,7.7-7.3,12.8c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st24" d="M1496.7,161.4c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9s-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st24" d="M1600,162.8c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st24" d="M1455.6,163.3c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9s3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st24" d="M1639.2,163.3c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st24" d="M1752,161.8c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st24" d="M1790.2,162.3c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st24" d="M1896.3,160.2c-3.4,0.1-10.1,2.6-13.6,3.6c-1.6,0.5-4.9,1.4-9,2.9c-5.7,1.9-7.8,2.9-9,3.6
			c-3.3,1.7-6.8,4-7.9,4.8c-2.2,1.9-5.9,9.8-4.5,6.6c-1.9,4.6-1.5,8.4-1.1,10.8c0,0,0.1,0.9,1.7,7.1c0.9,2.2,2.2,4.8,4,7.9
			c0.6,1.1,3.1,5.5,7.3,10.8c2.1,2.7,4.2,4.8,8.5,9.3c3.9,4.1,5.9,6.2,7.9,7.9c2.4,2,2.8,2,8.5,6.5c3.4,2.7,5.1,4,6.2,5.1
			c1.9,1.8,4.7,4.6,7.3,9.3c2.6,4.7,3.7,8.9,4.5,12.2c0.5,2,1.3,5.2,1.7,9.3c0.8,8.8-0.7,15.8-0.6,15.8c0.2,0,1.4-6.4,1.1-6.5
			c-0.2-0.1-1.3,3.1-4.5,15.2"/>
	</g>
</g>
</svg>
`,
    density: 0.5,
  },
  Softwood: {
    pattern: (
      baseColor: string,
    ) => `<svg width="1697" height="200"  version="1.1" id="Layer_2_00000005253280282176354390000001221378662314550172_"
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1918.7 226.2"
	 style="enable-background:new 0 0 1918.7 226.2;" xml:space="preserve">
<style type="text/css">
	.st0{fill-rule:evenodd;clip-rule:evenodd;stroke:${baseColor};stroke-width:12;stroke-miterlimit:10;}
</style>
<g id="Layer_1-2">
	<path class="st0" d="M956.9,40.8C851.9,2.2,715,18.8,671.4,25.4c-8.7,1.4-17.1,3.7-25.2,6.7c-195,71.4-328.6,44-348.7,39.1
		c0,0,0,0,0,0c-1.2-0.3-2.5-0.6-3.8-0.8C138.5,42.7,2.9,76,2.9,76l-1.9,0.5l-1-3.3l1.9-0.5c0,0,136.4-33.6,292.5-5.7l0,0
		c1.4,0.2,2.8,0.5,4.1,0.9c20.1,4.9,152.6,31.9,346.2-39c8.4-3.1,17.1-5.4,26-6.9c0,0,0,0,0,0c44-6.7,182.7-23.4,288.7,16l0.3,0.1
		c0,0,135.5,33.3,290.7,5.6c1.3-0.2,2.6-0.5,3.8-0.8c0,0,0,0,0.1,0C1274.4,38,1408,10.6,1603,82c8.1,3,16.6,5.2,25.2,6.7
		c43.8,6.7,181.8,23.4,287.1-16l1.8-0.7l1.5,3.2l-1.8,0.7c-106.1,39.6-245.2,22.9-289.3,16.2c0,0,0,0,0,0c-8.9-1.5-17.7-3.8-26-6.9
		c-193.6-70.9-326.1-43.9-346.2-39c-1.3,0.4-2.8,0.6-4.2,0.9l0,0c-146,26.1-274.7-1.6-290.8-5.3l-0.1,0.3l-1.8-0.7
		c-0.1,0-0.2-0.1-0.3-0.1l-1.3-0.3L956.9,40.8L956.9,40.8z M956.9,79.9C851.9,41.3,715,57.9,671.4,64.5c-8.7,1.4-17.1,3.7-25.2,6.7
		c-195,71.4-328.6,44-348.7,39.1c0,0,0,0,0,0c-1.2-0.3-2.5-0.6-3.8-0.8C138.5,81.8,2.9,115.1,2.9,115.1l-1.9,0.5l-1-3.3l1.9-0.5
		c0,0,136.4-33.6,292.5-5.7l0,0c1.4,0.2,2.8,0.5,4.1,0.9c20.1,4.9,152.6,31.9,346.2-39c8.4-3.1,17.1-5.4,26-6.9c0,0,0,0,0,0
		c44-6.7,182.7-23.4,288.7,16l0.3,0.1c0,0,135.5,33.3,290.7,5.6c1.3-0.2,2.6-0.5,3.8-0.8c0,0,0,0,0.1,0
		c20.1-4.9,153.7-32.3,348.7,39.1c8.1,3,16.6,5.2,25.2,6.7c43.8,6.7,181.8,23.4,287.1-16l1.8-0.7l1.5,3.2l-1.8,0.7
		c-106.1,39.6-245.2,22.9-289.3,16.2c0,0,0,0,0,0c-8.9-1.5-17.7-3.8-26-6.9c-193.6-70.9-326.1-43.9-346.2-39
		c-1.3,0.4-2.8,0.6-4.2,0.9l0,0c-146,26.1-274.7-1.6-290.8-5.3l-0.1,0.3l-1.8-0.7c-0.1,0-0.2-0.1-0.3-0.1l-1.3-0.3L956.9,79.9
		L956.9,79.9z M956.9,113.8C851.9,75.2,715,91.8,671.4,98.4c-8.7,1.4-17.1,3.7-25.2,6.7c-195,71.4-328.6,44-348.7,39.1c0,0,0,0,0,0
		c-1.2-0.3-2.5-0.6-3.8-0.8C138.5,115.7,2.9,149,2.9,149l-1.9,0.5l-1-3.3l1.9-0.5c0,0,136.4-33.6,292.5-5.7l0,0
		c1.4,0.2,2.8,0.5,4.1,0.9c20.1,4.9,152.6,31.9,346.2-39c8.4-3.1,17.1-5.4,26-6.9c0,0,0,0,0,0c44-6.7,182.7-23.4,288.7,16l0.3,0.1
		c0,0,135.5,33.3,290.7,5.6c1.3-0.2,2.6-0.5,3.8-0.8c0,0,0,0,0.1,0C1274.4,111,1408,83.6,1603,155c8.1,3,16.6,5.2,25.2,6.7
		c43.8,6.7,181.8,23.4,287.1-16l1.8-0.7l1.5,3.2l-1.8,0.7c-106.1,39.6-245.2,22.9-289.3,16.2c0,0,0,0,0,0c-8.9-1.5-17.7-3.8-26-6.9
		c-193.6-70.9-326.1-43.9-346.2-39c-1.3,0.4-2.8,0.6-4.2,0.9l0,0c-146,26.1-274.7-1.6-290.8-5.3l-0.1,0.3l-1.8-0.7
		c-0.1,0-0.2-0.1-0.3-0.1l-1.3-0.3L956.9,113.8L956.9,113.8z M956.9,152.9c-105-38.6-241.9-22.1-285.5-15.4
		c-8.7,1.4-17.1,3.7-25.2,6.7c-195,71.4-328.6,44-348.7,39.1c0,0,0,0,0,0c-1.2-0.3-2.5-0.6-3.8-0.8c-155.1-27.7-290.7,5.6-290.7,5.6
		l-1.9,0.5l-1-3.3l1.9-0.5c0,0,136.4-33.6,292.5-5.7l0,0c1.4,0.2,2.8,0.5,4.1,0.9c20.1,4.9,152.6,31.9,346.2-39
		c8.4-3.1,17.1-5.4,26-6.9c0,0,0,0,0,0c44-6.7,182.7-23.4,288.7,16l0.3,0.1c0,0,135.5,33.3,290.7,5.6c1.3-0.2,2.6-0.5,3.8-0.8
		c0,0,0,0,0.1,0c20.1-4.9,153.7-32.3,348.7,39.1c8.1,3,16.6,5.2,25.2,6.7c43.8,6.7,181.8,23.4,287.1-16l1.8-0.7l1.5,3.2l-1.8,0.7
		c-106.1,39.6-245.2,22.9-289.3,16.2c0,0,0,0,0,0c-8.9-1.5-17.7-3.8-26-6.9c-193.6-70.9-326.1-43.9-346.2-39
		c-1.3,0.4-2.8,0.6-4.2,0.9l0,0c-146,26.1-274.7-1.6-290.8-5.3l-0.1,0.3l-1.8-0.7c-0.1,0-0.2-0.1-0.3-0.1l-1.3-0.3L956.9,152.9
		L956.9,152.9z"/>
	<line class="st0" x1="0" y1="0" x2="1918.6" y2="0"/>
	<line class="st0" x1="0" y1="220.6" x2="1918.6" y2="220.6"/>
</g>
</svg>`,
    density: 0.5,
  },
  Hardwood: {
    pattern: (
      baseColor: string,
    ) => `<svg width="827" height="200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1919.3 464.8" style="enable-background:new 0 0 1919.3 464.8;" xml:space="preserve">
<style type="text/css">
	.st0{display:none;}
	.st1{fill:none;stroke:${baseColor};stroke-width:30;stroke-miterlimit:10;}
	.st2{fill:none;stroke:${baseColor};stroke-width:9;stroke-miterlimit:10;}
	.st3{fill-rule:evenodd;clip-rule:evenodd;fill:#ECECEC;}
	.st4{display:inline;}
	.st5{fill-rule:evenodd;clip-rule:evenodd;stroke:${baseColor};stroke-width:21;stroke-miterlimit:10;}
	.st6{display:inline;fill:none;stroke:${baseColor};stroke-width:21;stroke-miterlimit:10;}
	.st7{display:inline;fill-rule:evenodd;clip-rule:evenodd;stroke:${baseColor};stroke-width:21;stroke-miterlimit:10;}
	.st8{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-miterlimit:10;}
	.st9{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-width:8;stroke-miterlimit:10;}
	.st10{display:inline;fill:none;stroke:${baseColor};stroke-width:8;stroke-miterlimit:10;}
	.st11{display:inline;fill:none;stroke:${baseColor};stroke-width:45;stroke-miterlimit:10;}
	.st12{display:inline;fill:none;stroke:${baseColor};stroke-width:44;stroke-miterlimit:10;}
	.st13{display:inline;fill:none;stroke:${baseColor};stroke-width:40;stroke-miterlimit:10;}
	.st14{display:inline;fill:none;stroke:${baseColor};stroke-width:28;stroke-miterlimit:10;}
	.st15{fill:none;stroke:${baseColor};stroke-width:28;stroke-miterlimit:10;}
	.st16{display:inline;fill:none;stroke:${baseColor};stroke-width:14;stroke-miterlimit:10;}
	.st17{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-width:9;stroke-miterlimit:10;}
	.st18{fill:none;stroke:${baseColor};stroke-width:15;stroke-miterlimit:10;}
	.st19{display:none;fill:none;stroke:${baseColor};stroke-width:15;stroke-miterlimit:10;}
	.st20{display:none;fill:#DDDDDD;}
	.st21{fill-rule:evenodd;clip-rule:evenodd;stroke:${baseColor};stroke-width:10;stroke-miterlimit:10;}
</style>
<g id="Layer_1">
	<g id="Concrete" class="st0">
		<line class="st1" x1="0" y1="155.5" x2="1919.3" y2="155.5"/>
		<line class="st2" x1="328.3" y1="0" x2="328.3" y2="151.6"/>
		<line class="st1" x1="0" y1="301.4" x2="1919.3" y2="301.4"/>
		<line class="st2" x1="328.3" y1="297.3" x2="328.3" y2="464.8"/>
		<line class="st2" x1="1005.9" y1="0" x2="1005.9" y2="151.6"/>
		<line class="st2" x1="1655.3" y1="0" x2="1655.3" y2="151.6"/>
		<line class="st2" x1="1005.9" y1="300.7" x2="1005.9" y2="464.8"/>
		<line class="st2" x1="1655.3" y1="297.3" x2="1655.3" y2="464.8"/>
		<rect y="145.6" class="st3" width="1919.3" height="163.8"/>
	</g>
	<g id="Layer_2_00000041294061573236642060000008068305606222413205_" class="st0">
		<g id="Layer_1-2" class="st4">
			<path class="st5" d="M581.6,148l-25.3,98.1c-0.4,1.6-1.8,2.6-3.5,2.6c0,0-392.3,1.1-411.8,1.1l0,0c-1.3,0-3.1-0.9-3.8-2.2
				c0,0,0,0,0-0.1c-0.3-0.6-0.4-1.4-0.4-2.1c-0.1-0.2-0.5-0.6-1-1c-1.2-0.9-3.2-1.9-6.3-2.8c-13.2-4-44.3-7.1-116.1-2.9l-3.6,0.2
				L9.4,232l3.6-0.2c73.4-4.3,105.2-0.9,118.7,3.2c7.9,2.4,10.8,5.5,11.8,7.9c37.5-0.1,372-1,406.2-1.1l28.1-108.6
				c0-0.1,0.1-0.2,0.1-0.3c2.3-6.1,3.4-11.5,3.5-16v-2.4c-0.2-5.7-1.9-10.3-4.4-13.9c-6.3-9-17-11.6-18.9-11.9L13.4,81.7H9.7v-0.1
				l0,0l0.1-3.4v-3.4h0.1v-0.1l3.6,0.1h654c6.9-5.2,10.8-14.7,13.2-24.3c3.8-15.1,3.6-30.4,3.5-32l-0.4-3.2h0.3v-0.3l3.5-0.1
				l3.5-0.4v0.3h0.3l0.1,3.2c0.2,1.6,2.3,17.7,8.7,29.5c3,5.6,6.8,10.3,12.3,11.4c5.7,1.1,12.5-1.7,21.3-10l0,0
				c2.8-2.7,6.5-4.1,10.5-4.1c15.9,0,66.2,0,85.6,0c5.7,0,10.9,2.7,14,7.2c4.9,7,17.5,17.1,46,9.7c1.6-0.9,3.3-1.9,5-2.6
				c8-3.4,16-4.3,16-4.3c0.2,0,0.3,0,0.5,0c0,0,62,0.7,151.4,1.9h157.2V14.8h7.2v43.8c210,2.5,452,5.5,479.7,5.7l0,0
				c2.3,0,4.4,0.5,6.5,1.3c0,0,0,0,0.1,0c18.5,7.2,31.2,16.1,39.6,25.7c0.7-4.3,4.7-7.7,9.4-7.7h151.5v6.8h-151.5
				c-1.3,0-2.3,1-2.3,2.2v8.6c1.4,2.5,2.6,5.1,3.6,7.6c5.9,15.4,4.2,31.6-0.9,46.6c-0.8,2.4-1.8,4.8-2.7,7.2v72.7
				c0,1.2,1,2.1,2.3,2.1l0,0c28.2,0,148,0.2,148,0.2h3.6v6.8h-3.6c0,0-119.8-0.2-148-0.2c-5.3,0-9.5-4.1-9.5-9v-58
				c-6.7,12-14.4,21.7-18.9,27l0,0c-3.1,3.5-7.6,5.6-12.5,5.7l0,0c-62.2,1.6-593.6,15-643.3,16.2c-3.1,0.1-6.1-0.6-8.8-2
				c-21.8-10.2-71.9-15.5-88.6-17c-4.6-0.4-8.7-2.6-11.7-6l0,0c-11.1-13.4-45.8-55.1-63.5-76.2v124.7c0,0.8-0.1,1.7-0.3,2.4h1008.8
				v6.8H769.3c-0.4,16.7-8.2,87.2-110.6,137.2h103.5c-1.1-3.4-22.9-72.2,46.6-107c0,0,0,0,0.1,0c2.1-1,4.3-1.5,6.5-1.5
				c12.4,0,67.4,0,82.1,0v-20.8c0-1.9,1.6-3.4,3.6-3.4c0,0,904.9-3,1009.5-0.6l3.6,0.1l-0.2,6.8l-3.6-0.1
				c-100.7-2.3-943.7,0.3-1005.7,0.5v20.8c0,1.9-1.6,3.4-3.6,3.4c0,0-71.3,0-85.6,0c-1.2,0-2.2,0.3-3.3,0.7
				c-66,33.2-43.2,99.2-43.2,99.2l0.7,2h173.6v-0.4l3.4,0.4h967.4v6.8H965.4c4.4,3.1,7.9,7,10.8,11.2c8.7,12.7,10.7,27.6,10.7,27.6
				l0.5,3.4l-7.1,0.8l-0.5-3.4c0,0-1.8-13.3-9.6-24.7c-5-7.2-12.4-13.6-23.6-14.9H643.9c-2.6,1.1-5.5,1.8-8.2,1.9
				c-26.5,1.1-187.6,8.4-292.5,22.5c-26.8,3.6-49.9,7.6-66.2,12c-10.1,2.8-17.5,5.6-20.9,8.8l-2.6,2.4l-5.1-4.9l2.6-2.4
				c3.1-2.8,8.5-5.6,16.2-8c16.4-5.5,43.1-10.4,74.9-14.6c59.6-8,137.4-13.8,198.3-17.5H9.7v-6.8h632.5
				c111.1-48.4,119.4-121.2,119.9-137.2H9.7v-6.8h572.1c-0.2-0.7-0.3-1.6-0.3-2.4V148L581.6,148z M559.2,81.7L559.2,81.7
				c0.1,0,13.6,2.4,22.2,12.8v-1.1c0-5.1,4.4-9.3,9.8-9.3h286.6c-0.8-5.4,0.3-9.8,2.4-13.4v-0.1c-25.9,3.4-37.8-7.6-42.8-14.9l0,0
				c-1.7-2.5-4.7-4.1-7.9-4.1c-19.5,0-69.8,0-85.6,0l0,0c-2.1,0-4,0.7-5.5,2.1c-8.9,8.4-16.6,11.8-22.9,12.2
				c-10.5,0.6-18.2-7-23.1-17c-1.2-2.3-2.2-4.7-3.1-7.1c-0.5,3.3-1.2,6.8-2,10.3c-2.9,11.8-8.1,23.1-17,29c-0.6,0.4-1.3,0.6-2.1,0.6
				H559.2L559.2,81.7z M1753.1,103.1c-7.2-11.8-20.4-22.6-42.3-31.2c-1.3-0.5-2.6-0.7-4-0.7c-35.5-0.5-423.4-5.1-644.2-7.7
				c-74.3,0-148.1,0-156.1,0c-5,1.9-9.6,3.4-13.9,4.6c-2.4,1.6-4.6,3.5-6.1,5.9c-1.6,2.7-2.3,6-1.5,10.1h11c5.4,0,9.7,4.2,9.7,9.3
				v20.8c14.3,17.2,56.6,67.9,69.2,83c1.7,1.9,4.1,3.2,6.7,3.4l0,0c17.3,1.6,68.8,7.2,91.2,17.8h0.1c1.7,0.8,3.5,1.3,5.3,1.2l0,0
				c49.7-1.2,581.1-14.6,643.3-16.2c2.8-0.1,5.4-1.3,7-3.2c5.9-6.8,17.1-21.4,24.5-38.8L1753.1,103.1L1753.1,103.1z M1760.4,133
				c0.1-2.1,0.1-4.3,0-6.5V133z M896.5,252.4c1.3-0.2,2.3-1.2,2.3-2.4V116.6c-1.8-2.1-2.9-3.4-3.2-3.8c-8.1-8.4-13-15.6-15.5-21.8
				H591.4c-1.5,0-2.7,1.1-2.7,2.5v19.9c0.1,1.4,0.1,2.9,0,4.3V250c0,1.3,1,2.3,2.3,2.4h174.5l3.5-0.2v0.2H896.5L896.5,252.4z
				 M898.6,105.9V93.4c0-1.4-1.2-2.5-2.6-2.5h-8.3C889.9,95.3,893.5,100.2,898.6,105.9z M1413,39.6v-7.9c0-2.6,2.1-4.7,5.1-4.7
				h408.1V14.8h7.2v15.5c0,1.9-1.6,3.4-3.6,3.4h-409.5v8c0,2.6-2.4,4.7-5.1,4.7h-137.6c-28,0-34.7-27.4-34.7-27.4l-0.8-3.3l7-1.6
				l0.8,3.3c0,0,5.3,22.1,27.7,22.1L1413,39.6L1413,39.6z M1022.8,443l-0.3,3.4l-7.1-0.6l0.3-3.4c0,0,0.7-9.3,11.4-18.7
				c9.8-8.5,28.7-17.3,64.8-17.3h0.1l822.3,14.8l-0.1,6.8l-3.6-0.1l-818.7-14.8C1026.5,413.2,1022.8,442.9,1022.8,443L1022.8,443z"
				/>
		</g>
		<line class="st6" x1="1755.6" y1="61.4" x2="1924.1" y2="61.4"/>
		<line class="st6" x1="1756.8" y1="219.6" x2="1925.3" y2="219.6"/>
		<path class="st7" d="M0.1,257.5c1.1,0,2.1,0,3.2,0h3c108.5-1.5,151-4.1,165.1-6.6c2-0.4,13.1-2.4,28.4-3.9
			c10-0.9,18.4-1.2,23.9-1.4"/>
		<path class="st7" d="M0,77.9c1.1,0,2.1,0,3.2,0h3c108.5-1.5,151-4.1,165.1-6.6c2-0.4,13.1-2.4,28.4-3.9c10-0.9,18.4-1.2,23.9-1.4"
			/>
		<path class="st7" d="M0,411.9c1.1,0,2.1,0,3.2,0h3c65.1,1.2,101.7-3.6,123.5-8.6c6.6-1.5,32.5-7.7,68.4-12.9
			c9.3-1.3,17.1-2.3,22-2.9"/>
	</g>
	<g id="Flower" class="st0">
		<path class="st8" d="M1919.3,138.2H0V3.2c5.7,0,11.5,0,17.2,0c634,0,1268.1,0,1902.1,0C1919.3,48.2,1919.3,93.2,1919.3,138.2z"/>
		<path class="st9" d="M6.2,15.9"/>
		<path class="st10" d="M29.5,10.3c3.4,0.1,10.1,2.1,13.6,2.9c1.6,0.4,4.9,1.1,9,2.4c5.7,1.6,7.8,2.4,9,2.9c3.3,1.4,6.8,3.2,7.9,4
			c2.2,1.6,5.9,8,4.5,5.4c1.9,3.8,1.5,6.9,1.1,8.9c0,0-0.1,0.7-1.7,5.8c-0.9,1.8-2.2,4-4,6.5c-0.6,0.9-3.1,4.5-7.3,8.9
			c-2.1,2.2-4.2,4-8.5,7.6c-3.9,3.3-5.9,5.1-7.9,6.5c-2.4,1.7-2.8,1.7-8.5,5.3c-3.4,2.2-5.1,3.2-6.2,4.2c-1.9,1.5-4.7,3.8-7.3,7.6
			c-2.6,3.9-3.7,7.3-4.5,10c-0.5,1.7-1.3,4.3-1.7,7.6c-0.8,7.2,0.7,12.9,0.6,12.9c-0.2,0-1.4-5.2-1.1-5.3c0.2-0.1,1.3,2.5,4.5,12.4"
			/>
		<path class="st10" d="M132.8,11.4c-3.4,0.1-10.1,2.1-13.6,2.9c-1.6,0.4-4.9,1.1-9,2.4c-5.7,1.6-7.8,2.4-9,2.9
			c-3.3,1.4-6.8,3.2-7.9,4c-2.2,1.6-5.9,8-4.5,5.4c-1.9,3.8-1.5,6.9-1.1,8.9c0,0,0.1,0.7,1.7,5.8c0.9,1.8,2.2,4,4,6.5
			c0.6,0.9,3.1,4.5,7.3,8.9c2.1,2.2,4.2,4,8.5,7.6c3.9,3.3,5.9,5.1,7.9,6.5c2.4,1.7,2.8,1.7,8.5,5.3c3.4,2.2,5.1,3.2,6.2,4.2
			c1.9,1.5,4.7,3.8,7.3,7.6c2.6,3.9,3.7,7.3,4.5,10c0.5,1.7,1.3,4.3,1.7,7.6c0.8,7.2-0.7,12.9-0.6,12.9c0.2,0,1.4-5.2,1.1-5.3
			c-0.2-0.1-1.3,2.5-4.5,12.4"/>
		<path class="st10" d="M173.8,14.5c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M277.1,15.6c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M324.6,15c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M427.9,16.1c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M467.7,16.7c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M571,17.8c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M611.9,18.4c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M715.3,19.5c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M762.7,19c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M866.1,20.1c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M905.4,18.6c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M1008.7,19.7c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M1049.6,20.3c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,6.9,5.7,5.5,3.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-3.2,5.8-5,8.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M1155,19.4c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M1202.4,18.9c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M1305.8,19c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,8,4.5,10.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,1.4-4.5,10.9"/>
		<path class="st10" d="M1347.5,19.5c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,1.1,7.9,1.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,6.3-7.3,10.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M1496.7,14.4c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M1600,15.5c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M1455.6,16c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M1639.2,16c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M1752,14.7c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M1790.2,15.1c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M1896.3,13.4c-3.4,0.1-10.1,2.1-13.6,2.9c-1.6,0.4-4.9,1.1-9,2.4c-5.7,1.6-7.8,2.4-9,2.9
			c-3.3,1.4-6.8,3.2-7.9,4c-2.2,1.6-5.9,8-4.5,5.4c-1.9,3.8-1.5,6.9-1.1,8.9c0,0,0.1,0.7,1.7,5.8c0.9,1.8,2.2,4,4,6.5
			c0.6,0.9,3.1,4.5,7.3,8.9c2.1,2.2,4.2,4,8.5,7.6c3.9,3.3,5.9,5.1,7.9,6.5c2.4,1.7,2.8,1.7,8.5,5.3c3.4,2.2,5.1,3.2,6.2,4.2
			c1.9,1.5,4.7,3.8,7.3,7.6c2.6,3.9,3.7,7.3,4.5,10c0.5,1.7,1.3,4.3,1.7,7.6c0.8,7.2-0.7,12.9-0.6,12.9c0.2,0,1.4-5.2,1.1-5.3
			c-0.2-0.1-1.3,2.5-4.5,12.4"/>
	</g>
	<g id="Brick" class="st0">
		<line class="st11" x1="0" y1="201.7" x2="1919.3" y2="201.7"/>
		<line class="st11" x1="0" y1="389" x2="1919.3" y2="389"/>
		<line class="st11" x1="67.4" y1="138.2" x2="67.4" y2="201.7"/>
		<line class="st11" x1="67.4" y1="393.7" x2="67.4" y2="457.2"/>
		<line class="st11" x1="726.9" y1="393.7" x2="726.9" y2="457.2"/>
		<line class="st11" x1="726.9" y1="138.2" x2="726.9" y2="201.7"/>
		<line class="st11" x1="397.2" y1="206.4" x2="397.2" y2="385.4"/>
		<line class="st11" x1="1104.7" y1="205.8" x2="1104.7" y2="384.9"/>
		<line class="st11" x1="1441.9" y1="138.2" x2="1441.9" y2="201.7"/>
		<line class="st11" x1="1441.9" y1="393.1" x2="1441.9" y2="456.6"/>
		<line class="st11" x1="1733.3" y1="204.1" x2="1733.3" y2="383.1"/>
		<g id="Layer_6" class="st4">
		</g>
	</g>
	<g id="Layer_7" class="st0">
		<line class="st12" x1="0" y1="135.5" x2="1919.3" y2="135.5"/>
		<line class="st13" x1="5" y1="325.2" x2="1924.3" y2="325.2"/>
		<line class="st14" x1="123.8" y1="3.2" x2="123.8" y2="113.5"/>
		<line class="st14" x1="123.8" y1="345.5" x2="123.8" y2="457.2"/>
		<line class="st14" x1="800.4" y1="345.5" x2="800.4" y2="450.6"/>
		<line class="st14" x1="800.4" y1="3.2" x2="800.4" y2="113.5"/>
		<line class="st15" x1="397.2" y1="100.2" x2="397.2" y2="355.1"/>
		<line class="st15" x1="1104.7" y1="99.5" x2="1104.7" y2="354.2"/>
		<line class="st14" x1="1441.9" y1="3.2" x2="1441.9" y2="113.5"/>
		<line class="st14" x1="1441.9" y1="345.5" x2="1441.9" y2="456.4"/>
		<line class="st15" x1="1733.3" y1="96.9" x2="1733.3" y2="351.8"/>
	</g>
	<g id="Brick_x27_s_bottom" class="st0">
		<line class="st16" x1="0" y1="285.6" x2="1919.3" y2="285.6"/>
		<line class="st16" x1="0" y1="164.4" x2="1919.3" y2="164.4"/>
		<path class="st17" d="M25,171.3"/>
	</g>
	<line class="st18" x1="0" y1="457.2" x2="1919.3" y2="457.2"/>
	<line class="st19" x1="0" y1="7.6" x2="1919.3" y2="7.6"/>
	<line class="st19" x1="-4" y1="135.9" x2="1915.3" y2="135.9"/>
	<line class="st18" x1="0" y1="3.2" x2="1919.3" y2="3.2"/>
	<polygon class="st20" points="0,131.6 0,333.7 1919.3,333.7 1919.3,128.7 	"/>
	<path class="st20" d="M2146.6,257.5"/>
	<path class="st20" d="M2383.8,2453"/>
</g>
<g id="Layer_3">
	<g id="Layer_1-2_00000173855234758883175860000014032130267821187976_">
		<path class="st21" d="M265.4,873.2c-7.9,0.6-101.9,9.1-153.4,32.1c-11.3,5.1-20.7,10.9-26.3,17.3c-5.5,6.3-7.6,13.3-5,21.2l0,0
			c0.3,0.6,0.4,1.3,0.5,1.9c1.7,6.4,13,44.4,44.2,59.5c1.1,0.5,2.1,1.3,3,2.1c7.4,5.4,38.8,22,159.1,42.3c1.3,0.2,2.5,0.6,3.8,1.1
			l0,0c3.2,1.6,9.5,5,13.4,9.5c2.9,3.2,4.3,7.1,2.9,11.2c-0.9,2.6-3,5.3-7,8c-5.5,3.8-15,7.9-29.8,11.7l0,0
			c-0.7,0.2-1.4,0.3-2.1,0.3c-11.7,1.3-118.6,13-168.4,37c-8.4,4-15.1,8.5-19.5,13.3c-3,3.4-4.9,7.1-5.1,10.7
			c-0.1,3.8,1.3,7.7,4.6,12l0,0c2,2.6,3.4,5.6,4.1,9c1.2,5.1,2.9,12.8,4.1,18.4c0.9,4,2.9,7.7,5.7,10.1
			c17.5,15.2,85.1,67.2,192.1,68.1c2.5,0,5,0.6,7.2,1.9l0,0c3.2,1.8,7.4,4.6,10,8.2c2,2.6,2.9,5.6,2.2,8.8c-0.4,2.1-1.6,4.2-3.8,6.4
			c-2.9,2.7-7.6,5.6-14.9,8.5c7.2,2.9,12,5.8,14.9,8.5c2.2,2.1,3.4,4.3,3.8,6.4c0.7,3.2-0.4,6.1-2.2,8.8c-2.6,3.5-6.8,6.4-10,8.2
			l0,0c-2.2,1.3-4.7,1.9-7.2,1.9c-107,1-174.6,52.9-192.1,68.1c-2.8,2.6-4.7,6.1-5.7,10.1c-1.2,5.6-2.9,13.3-4.1,18.4
			c-0.8,3.4-2.1,6.4-4.1,9l0,0c-3.3,4.2-4.7,8.2-4.6,12s2,7.4,5.1,10.7c4.3,4.8,11.1,9.3,19.5,13.3c49.6,24,156.7,35.7,168.4,37
			c0.8,0,1.4,0.2,2.1,0.3l0,0c14.7,3.8,24.2,7.9,29.8,11.7c4,2.7,6.1,5.4,7,8c1.4,4-0.1,7.9-2.9,11.2c-3.8,4.5-10.1,7.9-13.4,9.5
			l0,0c-1.2,0.5-2.5,1-3.8,1.1c-120.2,20.4-151.7,36.7-159.1,42.2c-0.9,1-1.8,1.6-3,2.1c-31.2,15.1-42.5,53-44.2,59.5
			c-0.1,0.6-0.4,1.3-0.5,1.9l0,0c-2.6,7.9-0.5,14.7,5,21.2c5.8,6.6,15,12.3,26.5,17.3c53.7,23.9,153.9,32.1,153.9,32.1l0.9,0.2
			l-0.1,2.4l-0.9-0.2c0,0-100.5-8.2-154.4-32.2c-11.7-5.1-21.2-11.1-27.1-18c-6.2-7.1-8.4-14.9-5.5-23.7c0.1-0.5,0.4-1.1,0.5-1.8
			l0,0c1.7-6.6,13.3-45.5,45.4-60.9l0,0c0.9-0.5,1.7-1.1,2.5-1.8c0,0,0.1,0,0.1-0.2c7.4-5.4,38.7-22.1,159.8-42.6l0,0
			c1.2-0.2,2.2-0.5,3.3-1c3-1.4,9.1-4.6,12.8-9c2.2-2.6,3.6-5.4,2.5-8.5c-0.8-2.2-2.6-4.5-6.1-6.9c-5.5-3.8-14.6-7.7-29.2-11.4
			c-0.7-0.2-1.3-0.3-2-0.3c-11.7-1.3-119-13-168.9-37.2c-8.7-4.2-15.7-8.8-20-13.8c-3.6-3.8-5.5-8-5.7-12.3c-0.3-4.3,1.3-9,5.1-13.8
			c1.7-2.2,3-5,3.7-8l0,0c1.2-5.1,2.9-12.8,4.1-18.4l0,0c1.1-4.5,3.3-8.7,6.3-11.4l0,0c17.6-15.2,85.5-67.5,193.2-68.4
			c2.2,0,4.3-0.6,6.5-1.8c2.9-1.6,7-4.3,9.3-7.7c1.4-1.9,2.4-4.2,1.8-6.6c-0.4-1.6-1.3-3.4-3.2-5.1c-3-2.9-8-5.8-15.9-8.8
			c-0.4-0.2-0.7-0.6-0.7-1.1v-1l0.9,1.1l-0.3-2.4c7.9-2.9,13-5.9,15.9-8.8c1.8-1.8,2.8-3.5,3.2-5.1c0.5-2.4-0.4-4.6-1.8-6.6
			c-2.5-3.4-6.5-5.9-9.3-7.7c-2-1.1-4.2-1.8-6.5-1.8c-107.7-1-175.6-53.2-193.2-68.4l0,0c-3-2.9-5.4-6.9-6.3-11.4l0,0
			c-1.2-5.6-2.9-13.3-4.1-18.4l0,0c-0.7-3-2-5.8-3.7-8c-3.8-4.8-5.3-9.5-5.1-13.8c0.3-4.3,2.2-8.5,5.7-12.3c4.5-5,11.5-9.6,20-13.8
			c49.9-24.2,157.2-35.9,168.9-37.2c0.7,0,1.3-0.2,2-0.3c14.5-3.7,23.7-7.7,29.2-11.4c3.4-2.4,5.4-4.6,6.1-6.9
			c1.1-3.2-0.3-6.1-2.5-8.5c-3.7-4.2-9.7-7.4-12.8-9c-1.1-0.5-2.2-0.8-3.3-1l0,0c-121.1-20.5-152.5-37.2-159.8-42.6
			c0,0-0.1,0-0.1-0.2c-0.8-0.8-1.6-1.4-2.5-1.8l0,0c-32-15.4-43.6-54.3-45.4-60.9l0,0c-0.1-0.6-0.3-1.1-0.5-1.8
			c-2.9-8.8-0.7-16.7,5.5-23.7c5.9-6.7,15.4-12.7,27.1-18c42.5-18.9,113.9-28,142.3-31.1c-28.3-3-99.8-12.2-142.3-31.1
			c-11.7-5.1-21.2-11.2-27.1-17.8c-6.2-7.1-8.4-14.9-5.5-23.7c0.1-0.5,0.4-1.1,0.5-1.8l0,0c1.7-6.6,13.3-45.5,45.4-60.9l0,0
			c0.9-0.5,1.7-1.1,2.5-1.8c0,0,0.1,0,0.1-0.2c7.4-5.4,38.7-22.1,159.8-42.6l0,0c1.2-0.2,2.2-0.5,3.3-1c3-1.4,9.1-4.6,12.8-9
			c2.2-2.6,3.6-5.4,2.5-8.5c-0.8-2.2-2.6-4.5-6.1-6.9c-5.5-3.8-14.6-7.7-29.2-11.4c-0.7-0.2-1.3-0.3-2-0.3
			c-11.7-1.3-119-13-168.9-37.2c-8.7-4.2-15.7-8.8-20-13.8c-3.6-3.8-5.5-8-5.7-12.3c-0.3-4.3,1.3-9,5.1-13.8c1.7-2.2,3-5,3.7-8l0,0
			c1.2-5.1,2.9-12.8,4.1-18.4l0,0c1.1-4.6,3.3-8.7,6.3-11.4l0,0c17.6-15.2,85.5-67.5,193.2-68.4c2.2,0,4.3-0.6,6.5-1.8
			c2.9-1.6,7-4.5,9.3-7.9c1.4-2.1,2.4-4.3,1.8-6.9c-0.4-1.8-1.3-3.5-3.2-5.3c-3-2.9-8-5.9-15.9-8.8c-0.4-0.2-0.7-0.6-0.7-1.1v-0.2
			l0.9,1.1l-0.3-2.4c7.9-2.9,13-5.9,15.9-8.8c1.8-1.8,2.8-3.4,3.2-5.1c0.5-2.4-0.4-4.6-1.8-6.6c-2.5-3.4-6.5-5.9-9.3-7.7
			c-2-1.1-4.2-1.8-6.5-1.8c-107.7-1-175.6-53.2-193.2-68.4l0,0c-3-2.9-5.4-6.9-6.3-11.4l0,0c-1.2-5.6-2.9-13.3-4.1-18.4l0,0
			c-0.7-3-2-5.8-3.7-8c-3.8-4.8-5.3-9.5-5.1-13.8c0.3-4.3,2.2-8.5,5.7-12.3c4.5-5,11.5-9.6,20-13.8c49.9-24.2,157.2-35.9,168.9-37.2
			c0.7,0,1.3-0.2,2-0.3c14.5-3.8,23.7-7.7,29.2-11.4c3.4-2.4,5.4-4.6,6.1-6.9c1.1-3.2-0.3-6.1-2.5-8.5c-3.7-4.2-9.7-7.4-12.8-9
			c-1.1-0.5-2.2-0.8-3.3-1l0,0c-121.1-20.5-152.5-37.2-159.8-42.6c0,0-0.1,0-0.1-0.2c-0.8-0.8-1.6-1.4-2.5-1.8l0,0
			C92.8,128,81.2,89,79.4,82.5l0,0c-0.1-0.6-0.3-1.1-0.5-1.8C76,71.9,78.2,64,84.4,57c5.9-6.7,15.4-12.7,27.1-17.8
			C165.4,15.3,265.9,7,265.9,7l0.9-0.2l0.1,2.4l-0.9,0.2c0,0-100.2,8.2-153.9,32.1c-11.3,5.1-20.7,10.9-26.3,17.3
			c-5.5,6.3-7.6,13.3-5,21.2l0,0c0.3,0.6,0.4,1.3,0.5,1.9c1.7,6.4,13,44.4,44.2,59.5c1.1,0.5,2.1,1.3,3,2.1
			c7.4,5.4,38.8,22,159.1,42.3c1.3,0.2,2.5,0.6,3.8,1.1l0,0c3.2,1.6,9.5,5,13.4,9.5c2.9,3.2,4.3,7.1,2.9,11.2c-0.9,2.6-3,5.3-7,8
			c-5.5,3.8-15,7.9-29.8,11.7l0,0c-0.7,0.2-1.4,0.3-2.1,0.3c-11.7,1.3-118.6,13-168.4,37c-8.4,4-15.1,8.5-19.5,13.3
			c-3,3.4-4.9,7.1-5.1,10.7c-0.1,3.8,1.3,7.9,4.6,12l0,0c2,2.6,3.4,5.6,4.1,9c1.2,5.1,2.9,12.8,4.1,18.4c0.9,4,2.9,7.7,5.7,10.1
			c17.5,15.2,85.1,67.2,192.1,68.1c2.5,0,5,0.6,7.2,1.9l0,0c3.2,1.8,7.4,4.6,10,8.2c2,2.6,2.9,5.6,2.2,8.8c-0.4,2.1-1.6,4.2-3.8,6.4
			c-2.8,2.7-7.1,5.4-13.8,8.2c6.7,2.7,11.1,5.6,13.8,8.2c2.2,2.2,3.4,4.5,3.8,6.6c0.7,3.2-0.4,6.3-2.2,9c-2.6,3.7-6.8,6.7-10,8.5
			l0,0c-2.2,1.3-4.7,1.9-7.2,1.9c-107,1-174.6,52.9-192.1,68.1c-2.8,2.6-4.7,6.1-5.7,10.1c-1.2,5.6-2.9,13.3-4.1,18.4
			c-0.8,3.4-2.1,6.4-4.1,9l0,0c-3.3,4.2-4.7,8.2-4.6,12s2,7.4,5.1,10.7c4.3,4.8,11.1,9.3,19.5,13.3c49.6,24,156.7,35.7,168.4,37
			c0.8,0,1.4,0.2,2.1,0.3l0,0c14.7,3.8,24.2,7.9,29.8,11.7c4,2.7,6.1,5.4,7,8c1.4,4-0.1,7.9-2.9,11.2c-3.8,4.5-10.1,7.9-13.4,9.5
			l0,0c-1.2,0.5-2.5,1-3.8,1.1c-120.2,20.4-151.7,36.7-159.1,42.3c-0.9,0.8-1.8,1.6-3,2.1c-31.2,15.1-42.5,53-44.2,59.5
			c-0.1,0.6-0.4,1.3-0.5,1.9l0,0c-2.6,7.9-0.5,14.7,5,21.2c5.8,6.6,15,12.3,26.5,17.3c53.7,23.9,153.9,32.1,153.9,32.1l-0.1,2.4l0,0
			c0,0-0.1,0-0.5,0C265.3,873.2,265.3,873.2,265.4,873.2L265.4,873.2z M265.8,873.2c0,0,0.9-2.9,0.9-1.1
			C266.9,872.8,266.5,873.2,265.8,873.2C265.9,873.2,265.9,873.2,265.8,873.2L265.8,873.2L265.8,873.2z M265.8,873.2
			c0,0-0.3,0.2-0.4,0C265.7,873.2,265.8,873.2,265.8,873.2z M399.3,873.2c0,0,0.9-2.9,0.9-1.1c0,0.5-0.3,1-0.7,1.1
			c0,0-137.5,44.2-188.4,77.1c-7.8,5-13.4,9.6-16.3,13.9c-1.7,2.4-2.4,4.8-1.6,6.7c0.5,1.4,1.6,2.7,3.4,3.8c2.9,1.9,7.5,3.7,14,5
			c1.1,0.2,2.1,0.5,3,1c7.4,2.9,40,14.9,102.4,22.6c0.3,0,0.5,0,0.9,0c4.7,0.3,42.5,3,66.6,17.1c10.5,6.3,18.6,14.7,20.1,26l0,0
			c0,0.3,0.1,0.5,0.1,0.8c0.7,3.5,6.1,39.6-20.9,55.6l0,0c-1.1,0.6-2.1,1.1-3.2,1.4c-10.4,3.2-80.6,25.8-131,49
			c-21.5,9.9-39.4,19.9-47.4,28.7c-1.6,1.8-2.8,3.4-3.6,5c-0.7,1.4-0.9,2.7-0.7,4c0.3,1.3,0.9,2.6,2.1,3.7c1.3,1.3,3.3,2.6,5.8,3.7
			l0,0c1.2,0.5,2.4,0.8,3.6,1c14.1,1.9,120.7,17.6,169.1,40.9c8.4,4,15,8.3,19.2,12.8c3.3,3.5,5,7.4,5,11.2s-1.8,7.7-5.9,11.7
			c-2.4,2.4-4.1,5.4-5,8.8l0,0c-1.1,4.2-1.8,9.3,0,13c1.2,2.4,3.6,4,7.6,4.3c0.4,0,0.7,0.3,0.8,0.6c0,0,0.1,0.2,0.1,0.5v1
			c0,0.6-0.4,1.1-0.9,1.1c-4.1,0.3-6.5,1.9-7.6,4.3c-1.8,3.7-1.1,9,0,13l0,0c0.9,3.5,2.6,6.6,5,8.8c4.1,4,5.9,8,5.9,11.7
			c0,3.8-1.7,7.5-5,11.2c-4.1,4.5-10.8,8.8-19.2,12.8c-48.3,23.2-155.1,38.8-169.1,40.9c-1.2,0.2-2.4,0.5-3.6,1l0,0
			c-2.5,1.1-4.5,2.2-5.8,3.7c-1.2,1.1-1.8,2.4-2.1,3.7c-0.3,1.3,0,2.7,0.7,4c0.8,1.6,2,3.2,3.6,5c8,8.8,25.9,18.8,47.4,28.7
			c50.4,23.2,120.6,45.7,131,49c1.2,0.3,2.2,0.8,3.2,1.4l0,0c27,15.9,21.6,52.1,20.9,55.6c0,0.3-0.1,0.5-0.1,0.8l0,0
			c-1.6,11.2-9.6,19.7-20.1,26c-24.1,14.1-62,16.8-66.6,17.1c-0.3,0-0.7,0-0.9,0c-62.4,7.7-95.1,19.7-102.4,22.6c-1.1,0.5-2,0.8-3,1
			c-6.5,1.3-11.1,3-14,5c-1.7,1.1-2.9,2.4-3.4,3.8c-0.8,2.1,0,4.3,1.6,6.7c2.9,4.3,8.6,9,16.3,13.9c51.1,33,188.4,77.1,188.4,77.1
			l0.9,0.3l-0.5,2.2l-0.9-0.3c0,0-137.7-44.4-188.8-77.4c-8-5.1-14-10.1-17-14.6c-2.2-3.4-2.9-6.4-2-9.3c0.7-1.8,2-3.5,4.2-5
			c3-2.1,7.8-3.8,14.6-5.3l0,0c0.9-0.2,1.8-0.5,2.8-0.8l0,0c7.4-2.9,40.2-15.1,102.8-22.8l0,0c0.3,0,0.7,0,0.9,0l0,0
			c4-0.3,32-2.2,55-11.5c7.6-3,14.6-7.1,20-11.9c5.3-4.8,9-10.6,9.9-17.6c0-0.3,0.1-0.5,0.1-0.8l0,0c0.5-3.2,5.9-37.8-19.9-53
			c-0.9-0.5-1.8-1-2.9-1.3c-10.4-3.4-80.7-25.8-131.1-49c-21.7-10.1-39.9-20.2-48.1-29.2c-3.7-4-5.4-7.9-4.7-11.4
			c0.5-3.4,3.4-6.6,9.1-9.1c1.3-0.6,2.6-1,4.1-1.1l0,0c14-1.9,120.5-17.5,168.7-40.7c8.2-3.8,14.6-8,18.6-12.3
			c2.8-3,4.3-6.1,4.3-9.3s-1.7-6.6-5.3-9.9l0,0c-2.6-2.6-4.5-6.1-5.5-9.9c-1.3-4.6-2-10.7,0.1-15.1c1.1-2.1,2.8-3.8,5.5-4.6
			c-2.8-1-4.5-2.7-5.5-4.6c-2.1-4.3-1.4-10.3-0.1-15.1c1.1-3.8,2.9-7.4,5.5-9.9l0,0c3.6-3.4,5.3-6.7,5.3-9.9s-1.6-6.3-4.3-9.3
			c-4-4.3-10.5-8.5-18.6-12.3c-48.2-23.2-154.6-38.6-168.7-40.7l0,0c-1.3-0.2-2.8-0.6-4.1-1.1c-5.8-2.6-8.6-5.8-9.1-9.1
			c-0.5-3.4,1.1-7.2,4.7-11.4c8.2-8.8,26.2-19.1,48.1-29.2c50.6-23.2,120.9-45.8,131.1-49c1.1-0.3,2-0.8,2.9-1.3
			c25.8-15.2,20.4-49.7,19.9-53l0,0c0-0.3-0.1-0.5-0.1-0.8c-1.1-7.1-4.7-12.8-9.9-17.6c-5.4-5-12.4-8.8-20-11.9
			c-23-9.3-51.1-11.4-55-11.5l0,0c-0.3,0-0.7,0-0.9,0l0,0c-62.7-7.7-95.5-19.7-102.8-22.8l0,0c-0.9-0.3-1.8-0.6-2.8-0.8l0,0
			c-6.7-1.4-11.6-3.2-14.6-5.3c-2.2-1.4-3.6-3.2-4.2-5c-0.9-2.7-0.3-5.9,2-9.3c3-4.5,9-9.3,17-14.6c46.3-30,163.5-69.1,185.4-76.1
			c-21.7-7.2-139-46.3-185.4-76.1c-8-5.1-14-10.1-17-14.6c-2.2-3.4-2.9-6.4-2-9.3c0.7-1.8,2-3.5,4.2-5c3-2.1,7.8-3.8,14.6-5.3l0,0
			c0.9-0.2,1.8-0.5,2.8-0.8l0,0c7.4-2.9,40.2-15.1,102.8-22.8l0,0c0.3,0,0.7,0,0.9,0l0,0c4-0.3,32-2.2,55-11.5
			c7.6-3,14.6-7.1,20-11.9c5.3-4.8,9-10.6,9.9-17.6c0-0.3,0.1-0.5,0.1-0.8l0,0c0.5-3.2,5.9-37.8-19.9-53c-0.9-0.5-1.8-1-2.9-1.3
			c-10.4-3.4-80.7-25.8-131.1-49c-21.7-10.1-39.9-20.2-48.1-29c-3.7-4-5.4-7.9-4.7-11.4c0.5-3.4,3.4-6.6,9.1-9.1
			c1.3-0.6,2.6-1,4.1-1.1l0,0c14-1.9,120.5-17.5,168.7-40.7c8.2-4,14.6-8,18.6-12.3c2.8-3,4.3-6.1,4.3-9.3s-1.7-6.6-5.3-9.9l0,0
			c-2.6-2.6-4.5-6.1-5.5-9.9c-1.3-4.6-2-10.7,0.1-15.1c1.1-2.1,2.8-3.8,5.5-4.6c-2.8-1-4.5-2.6-5.5-4.6c-2.1-4.2-1.4-10.3-0.1-15.1
			c1.1-3.8,2.9-7.4,5.5-9.9l0,0c3.6-3.4,5.3-6.7,5.3-9.9c0-3.2-1.6-6.3-4.3-9.3c-4-4.3-10.5-8.5-18.6-12.3
			c-48.2-22.9-154.6-38.3-168.7-40.4l0,0c-1.3-0.2-2.8-0.6-4.1-1.1c-5.8-2.6-8.6-5.8-9.1-9.1c-0.5-3.4,1.1-7.2,4.7-11.4
			c8.2-8.8,26.2-19.1,48.1-29c50.6-23.2,120.9-45.8,131.1-49c1.1-0.3,2-0.8,2.9-1.3c25.8-15.2,20.4-49.7,19.9-53l0,0
			c0-0.3-0.1-0.5-0.1-0.8c-1.1-7.1-4.7-12.8-9.9-17.5c-5.4-5-12.4-8.8-20-11.9c-23-9.5-51.1-11.4-55-11.5l0,0c-0.3,0-0.7,0-0.9,0
			l0,0C253,135,220.3,123,212.9,120l0,0c-0.9-0.3-1.8-0.6-2.8-0.8l0,0c-6.7-1.4-11.6-3.2-14.6-5.3c-2.2-1.4-3.6-3.2-4.2-5
			c-0.9-2.7-0.3-5.9,2-9.3c3-4.5,9-9.3,17-14.6C261.3,51.8,399.1,7.4,399.1,7.4l0.9-0.3l0.5,2.2l-0.9,0.3c0,0-137.5,44.2-188.4,77.1
			c-7.8,5-13.4,9.8-16.3,13.9c-1.7,2.4-2.4,4.6-1.6,6.7c0.5,1.4,1.6,2.7,3.4,3.8c2.9,1.9,7.5,3.7,14,5c1.1,0.2,2.1,0.5,3,1
			c7.4,3,40,15.1,102.4,22.6c0.3,0,0.5,0,0.9,0c4.7,0.3,42.5,3,66.6,17.1c10.5,6.3,18.6,14.6,20.1,26l0,0c0,0.3,0.1,0.5,0.1,0.8
			c0.7,3.5,6.1,39.6-20.9,55.6l0,0c-1.1,0.6-2.1,1.1-3.2,1.4c-10.4,3.4-80.6,25.8-131,49c-21.5,9.9-39.4,19.9-47.4,28.7
			c-1.6,1.8-2.8,3.4-3.6,5c-0.7,1.4-0.9,2.7-0.7,4.2c0.3,1.3,0.9,2.6,2.1,3.7c1.3,1.3,3.3,2.6,5.8,3.7l0,0c1.2,0.5,2.4,0.8,3.6,1
			c14.1,1.9,120.7,17.6,169.1,40.9c8.4,4,15,8.3,19.2,12.8c3.3,3.5,5,7.4,5,11.2c0,3.8-1.8,7.7-5.9,11.7c-2.4,2.2-4.1,5.4-5,8.8l0,0
			c-1.1,4.2-1.8,9.3,0,13c1.2,2.4,3.6,4,7.6,4.3c0.4,0,0.7,0.3,0.8,0.6c0,0,0.1,0.2,0.1,0.5v1c0,0.6-0.4,1.1-0.9,1.1
			c-4.1,0.3-6.5,1.9-7.6,4.3c-1.8,3.7-1.1,9,0,13l0,0c0.9,3.5,2.6,6.6,5,8.8c4.1,4,5.9,8,5.9,11.7c0,3.8-1.7,7.5-5,11.2
			c-4.1,4.5-10.8,8.8-19.2,12.8c-48.3,23.2-155.1,38.8-169.1,40.9c-1.2,0.2-2.4,0.5-3.6,1l0,0c-2.5,1.1-4.5,2.2-5.8,3.7
			c-1.2,1.1-1.8,2.4-2.1,3.7c-0.3,1.3,0,2.7,0.7,4c0.8,1.6,2,3.2,3.6,5c8,8.8,25.9,18.8,47.4,28.7c50.4,23.2,120.6,45.7,131,49
			c1.2,0.3,2.2,0.8,3.2,1.4l0,0c27,16,21.6,52.1,20.9,55.6c0,0.3-0.1,0.5-0.1,0.8l0,0c-1.6,11.2-9.6,19.7-20.1,26
			c-24.1,14.1-62,16.8-66.6,17.1c-0.3,0-0.7,0-0.9,0c-62.4,7.7-95.1,19.7-102.4,22.6c-1.1,0.5-2,0.8-3,1c-6.5,1.3-11.1,3-14,5
			c-1.7,1.1-2.9,2.4-3.4,3.8c-0.8,2.1,0,4.3,1.6,6.7c2.9,4.3,8.6,9,16.3,13.9c51.1,32.9,188.4,77.1,188.4,77.1L399.3,873.2h-0.3
			c0,0-0.1,0-0.5-0.2C398.9,873.6,399.3,873.2,399.3,873.2L399.3,873.2z M835.9,441.1c0.3,0.6,0.8,0.3,0.8,0.3l-0.9-1.4
			c4.6-22.8-10.9-36.2-31.3-44.2c-31.2-12.3-74.1-12.2-77.9-12.2H726c-40.3-0.6-66.6-7.1-83.6-16c-17.2-9-25-20.5-28.2-31.3
			c-4.2-14.3,0-27.1,1.2-30.3l0,0c0.3-0.6,0.5-1.3,0.7-2.1l0,0c1.3-4.2,4.7-8.3,10-12.5c7.6-5.9,19.1-11.7,32.7-17
			c52.5-21,136.9-37.2,154.6-40.5l0,0c2.4-0.5,4.6-1.4,6.5-3l0,0c7.6-6.3,11.3-12.5,12.4-18.6c1.1-5.4-0.1-10.7-2.2-15.4
			c-3.3-7.2-9-13.1-12.9-16.7c-2.6-2.2-5.7-3.5-8.8-3.5C696,174.9,632.6,144,620,137.1l0,0c-2-1.1-3.8-2.7-5.3-4.6l0,0
			c-5.3-6.9-7.8-13.5-8.2-19.9c-0.4-7.5,2.4-14.7,7.5-21.6c5-6.7,12.2-13,21.2-19.1c59-39.7,189.6-63.9,189.6-63.9l0.9-0.2l0.3,2.4
			l-0.9,0.2c0,0-130.2,24.2-188.9,63.8c-8.7,5.9-15.8,12-20.7,18.6c-4.7,6.3-7.2,13-7,19.9c0.3,5.9,2.6,12,7.6,18.4
			c1.3,1.8,2.9,3.2,4.7,4.2c12.6,6.9,75.7,37.7,187.5,39.4l0,0c3.6,0.2,7.1,1.4,10,4l0,0c5.9,5.3,15.4,15.7,16.2,28
			c0.5,8.3-2.6,17.5-13.6,26.4c-2.2,1.8-4.7,3-7.2,3.5c-17.6,3.4-101.9,19.6-154.3,40.4c-13.4,5.3-24.8,11.1-32.3,16.8
			c-4.9,3.7-8.2,7.5-9.2,11.4c-0.3,0.8-0.5,1.4-0.8,2.2c-1.2,3-5.1,15.1-1.2,28.5c3,10.3,10.5,21.2,27.1,29.8
			c16.9,8.8,42.9,15.1,83,15.9h0.4c3.8,0,47.1-0.3,78.6,12.2c21.3,8.5,37.3,22.9,32.7,46.8c4.6,23.9-11.2,38.3-32.7,46.8
			c-31.5,12.5-74.8,12.3-78.6,12.2h-0.4c-40,0.6-66.1,7.1-83,15.9c-16.6,8.7-24.1,19.6-27.1,29.8c-4,13.5,0,25.5,1.2,28.5
			c0.3,0.8,0.5,1.4,0.8,2.2c1.2,3.8,4.5,7.5,9.2,11.2c7.5,5.8,18.8,11.4,32.3,16.8c52.4,20.8,136.7,37.2,154.3,40.4
			c2.6,0.5,5.1,1.6,7.2,3.5c10.9,9,14.1,18.3,13.6,26.4c-0.8,12.3-10.3,22.8-16.2,28l0,0c-2.9,2.6-6.3,4-10,4l0,0
			c-111.8,1.8-174.7,32.5-187.5,39.4c-1.7,1-3.4,2.4-4.7,4.2c-4.9,6.4-7.4,12.5-7.6,18.4c-0.3,6.9,2.2,13.5,7,19.9
			c4.9,6.6,12,12.7,20.7,18.6c58.7,39.6,188.9,63.8,188.9,63.8c0.3,0,0.7,0.3,0.7,0.6c0,0,0.1,0.2,0.1,0.5v1c0,0.6-0.4,1.1-0.8,1.1
			c0,0-130.2,24.2-188.9,63.8c-8.7,5.9-15.8,12-20.7,18.6c-4.7,6.4-7.2,13-7,19.9c0.3,5.9,2.6,12,7.6,18.4c1.3,1.8,2.9,3.2,4.7,4.2
			c12.6,6.9,75.7,37.7,187.5,39.4l0,0c3.6,0,7.1,1.4,10,4l0,0c5.9,5.3,15.4,15.7,16.2,28c0.5,8.3-2.6,17.5-13.6,26.4
			c-2.2,1.8-4.7,3-7.2,3.5c-17.6,3.4-101.9,19.6-154.3,40.4c-13.4,5.3-24.8,10.9-32.3,16.8c-4.9,3.7-8.2,7.5-9.2,11.2
			c-0.3,0.8-0.5,1.4-0.8,2.2c-1.2,3-5.1,15.1-1.2,28.5c3,10.3,10.5,21.2,27.1,29.8c16.9,8.8,42.9,15.1,83,15.7h0.4
			c3.8,0,47.1-0.3,78.6,12.2c21.3,8.5,37.3,22.9,32.7,46.8c4.6,23.9-11.2,38.3-32.7,46.8c-31.5,12.5-74.8,12.3-78.6,12.2h-0.4
			c-40,0.6-66.1,7.1-83,15.7c-16.6,8.7-24.1,19.6-27.1,29.8c-4,13.5,0,25.5,1.2,28.5c0.3,0.6,0.5,1.4,0.8,2.2
			c1.2,3.8,4.5,7.5,9.2,11.2c7.5,5.8,18.8,11.5,32.3,16.8c52.4,20.8,136.7,37.2,154.3,40.4c2.6,0.5,5.1,1.6,7.2,3.5
			c10.9,9,14.1,18.3,13.6,26.4c-0.8,12.3-10.3,22.8-16.2,28l0,0c-2.9,2.6-6.3,4-10,4l0,0c-111.8,1.8-174.7,32.5-187.5,39.4
			c-1.7,1-3.4,2.4-4.7,4.2c-4.9,6.4-7.4,12.5-7.6,18.4c-0.3,6.9,2.2,13.5,7,19.9c4.9,6.6,12,12.7,20.7,18.6
			c58.7,39.6,188.9,63.8,188.9,63.8l0.9,0.2l-0.3,2.4l-0.9-0.2c0,0-130.6-24.4-189.6-63.9c-9-6.1-16.2-12.3-21.2-19.1
			c-5.1-6.9-7.8-14.1-7.5-21.6c0.3-6.4,2.9-13.1,8.2-19.9l0,0c1.4-1.9,3.3-3.5,5.3-4.6l0,0c12.6-6.9,76-37.8,188.2-39.6
			c3.2,0,6.3-1.3,8.8-3.5c4-3.5,9.6-9.5,12.9-16.7c2.1-4.6,3.3-9.9,2.2-15.4c-1.2-5.9-4.9-12.3-12.4-18.6l0,0c-2-1.6-4.2-2.7-6.5-3
			l0,0c-17.6-3.4-102-19.6-154.6-40.5c-13.6-5.4-25-11.2-32.7-17c-5.3-4-8.8-8.3-10-12.5l0,0c-0.1-0.6-0.4-1.3-0.7-2.1l0,0
			c-1.2-3.2-5.4-16-1.2-30.3c3.2-10.6,10.8-22.1,28.2-31.3c17-8.8,43.3-15.2,83.6-16h0.5c3.7,0,46.7,0.3,77.9-12.2
			c20.1-8,35.6-21.3,31.3-43.8c0.3,0.6,0.8,0.3,0.8,0.3l-0.9-1.4c4.6-22.8-10.9-36.2-31.3-44.2c-31.2-12.3-74.1-12.2-77.9-12.2h-0.5
			c-40.3-0.6-66.6-7.1-83.6-16c-17.2-9-25-20.5-28.2-31.3c-4.2-14.3,0-27.1,1.2-30.3l0,0c0.3-0.6,0.5-1.3,0.7-2.1l0,0
			c1.3-4.2,4.7-8.3,10-12.5c7.6-5.9,19.1-11.7,32.7-17c52.5-21,136.9-37.2,154.6-40.5l0,0c2.4-0.5,4.6-1.4,6.5-3l0,0
			c7.6-6.3,11.3-12.5,12.4-18.6c1.1-5.4-0.1-10.7-2.2-15.4c-3.3-7.2-9-13.1-12.9-16.7c-2.6-2.2-5.7-3.5-8.8-3.5
			c-112.2-1.8-175.5-32.7-188.2-39.6l0,0c-2-1.1-3.8-2.7-5.3-4.6l0,0c-5.3-6.9-7.8-13.5-8.2-19.9c-0.4-7.5,2.4-14.7,7.5-21.6
			c5-6.7,12.2-13.1,21.2-19.1c53.1-35.7,164.5-59,185.9-63.3c-21.5-4.3-132.9-27.6-185.9-63.3c-9-6.1-16.2-12.3-21.2-19.1
			c-5.1-6.9-7.8-14.1-7.5-21.6c0.3-6.4,2.9-13.1,8.2-19.9l0,0c1.4-1.9,3.3-3.5,5.3-4.6l0,0c12.6-6.9,76-37.8,188.2-39.6
			c3.2,0,6.3-1.3,8.8-3.5c4-3.5,9.6-9.5,12.9-16.7c2.1-4.6,3.3-9.9,2.2-15.4c-1.2-6.1-4.9-12.3-12.4-18.6l0,0c-2-1.6-4.2-2.7-6.5-3
			l0,0c-17.6-3.4-102-19.6-154.6-40.5c-13.6-5.4-25-11.2-32.7-17c-5.3-4-8.8-8.3-10-12.5l0,0c-0.1-0.6-0.4-1.4-0.7-2.1l0,0
			c-1.2-3.2-5.4-16-1.2-30.3c3.2-10.6,10.8-22.3,28.2-31.3c17-8.8,43.3-15.2,83.6-16h0.5c3.7,0,46.7,0.3,77.9-12.2
			C824.7,476.9,840.2,463.7,835.9,441.1L835.9,441.1z M835.9,441.1c-0.1-0.2-0.1-0.5-0.1-0.8c0,0.2,0,0.2,0,0.3
			C835.8,440.7,835.9,441,835.9,441.1L835.9,441.1z M835.9,1304.7c-0.1-0.2-0.1-0.5-0.1-0.8c0,0.2,0,0.2,0,0.3
			S835.9,1304.5,835.9,1304.7L835.9,1304.7z M877.1,1305.2c-3.8,0.3-6.1,1.9-7.2,4.3c-1.8,3.7-1.1,9,0,13l0,0c0.9,3.5,2.6,6.6,5,8.8
			c4.1,4,5.9,8,5.9,11.7c0,3.8-1.7,7.5-5,11.2c-4.1,4.5-10.8,8.8-19.2,12.8c-48.3,23.2-155.1,38.8-169.1,40.9
			c-1.2,0.2-2.4,0.5-3.6,1l0,0c-2.5,1.1-4.5,2.2-5.8,3.7c-1.2,1.1-1.8,2.4-2.1,3.7c-0.3,1.3,0,2.7,0.7,4c0.7,1.6,2,3.2,3.6,5
			c8,8.8,25.9,18.8,47.4,28.7c50.4,23.2,120.6,45.7,131,49c1.1,0.3,2.2,0.8,3.2,1.4l0,0c27,16,21.5,52.1,20.9,55.6
			c0,0.3-0.1,0.5-0.1,0.8l0,0c-1.6,11.2-9.6,19.7-20.1,26c-24.1,14.1-61.9,16.8-66.6,17.1c-0.3,0-0.7,0-0.9,0
			c-62.4,7.7-95.1,19.7-102.4,22.6c-1.1,0.5-2,0.8-3,1c-6.5,1.3-11.1,3-14,5c-1.7,1.1-2.9,2.4-3.4,3.8c-0.7,2.1,0,4.3,1.6,6.7
			c2.9,4.3,8.6,9,16.3,13.9c51.1,33,188.4,77.1,188.4,77.1l0.9,0.3l-0.5,2.2l-0.9-0.3c0,0-137.7-44.4-188.8-77.4
			c-8-5.1-14-10.1-17-14.6c-2.2-3.4-2.9-6.4-2-9.3c0.7-1.8,2-3.5,4.2-5c3-2.1,7.9-3.8,14.6-5.3l0,0c0.9-0.2,1.8-0.5,2.8-0.8l0,0
			c7.4-2.9,40.2-15.1,102.8-22.8l0,0c0.3,0,0.7,0,0.9,0l0,0c4-0.3,32-2.2,55-11.5c7.6-3,14.6-7.1,20-11.9c5.3-4.8,9-10.6,9.9-17.6
			c0-0.3,0.1-0.5,0.1-0.8l0,0c0.5-3.2,5.9-37.8-19.9-53c-0.9-0.5-1.8-1-2.9-1.3c-10.4-3.4-80.7-25.8-131.1-49
			c-21.7-10.1-39.9-20.2-48.1-29.2c-3.7-4-5.4-7.9-4.9-11.4c0.5-3.4,3.4-6.6,9.2-9.1c1.3-0.6,2.6-1,4-1.1l0,0
			c14-1.9,120.5-17.5,168.5-40.7c8.2-4,14.6-8,18.6-12.3c2.8-3,4.3-6.1,4.3-9.3s-1.7-6.6-5.3-9.9l0,0c-2.6-2.6-4.5-6.1-5.5-9.9
			c-1.3-4.6-2-10.7,0.1-15.1c0.9-1.8,2.2-3.2,4.3-4.3c-2.1-1-3.4-2.4-4.3-4.3c-2.1-4.3-1.4-10.3-0.1-15.1c1.1-3.8,2.9-7.4,5.5-9.9
			l0,0c3.6-3.4,5.3-6.7,5.3-9.9c0-3.2-1.6-6.3-4.3-9.3c-4-4.3-10.5-8.5-18.6-12.3c-48.2-23.2-154.6-38.6-168.5-40.7l0,0
			c-1.3-0.2-2.8-0.6-4-1.1c-5.8-2.6-8.6-5.8-9.2-9.1c-0.5-3.4,1.1-7.2,4.9-11.4c8.2-8.8,26.2-19.1,48.1-29.2
			c50.6-23.2,120.9-45.8,131.1-49c1.1-0.3,2-0.8,2.9-1.3c25.8-15.2,20.4-49.7,19.9-53l0,0c0-0.3-0.1-0.5-0.1-0.8
			c-1.1-7.1-4.7-12.8-9.9-17.6c-5.4-5-12.4-8.8-20-11.9c-23-9.3-51.1-11.4-55-11.5l0,0c-0.3,0-0.7,0-0.9,0l0,0
			c-62.7-7.7-95.5-19.7-102.8-22.8l0,0c-0.9-0.3-1.7-0.6-2.8-0.8l0,0c-6.7-1.4-11.6-3.2-14.6-5.3c-2.2-1.4-3.6-3.2-4.2-5
			c-0.9-2.7-0.3-5.9,2-9.3c3-4.5,9-9.3,17-14.6c47.4-30.6,169.3-71,186.8-76.6c-17.4-5.6-139.3-46-186.8-76.6
			c-8-5.1-14-10.1-17-14.6c-2.2-3.4-2.9-6.4-2-9.3c0.7-1.8,2-3.5,4.2-5c3-2.1,7.9-3.8,14.6-5.3l0,0c0.9-0.2,1.8-0.5,2.8-0.8l0,0
			c7.4-3,40.2-15.1,102.8-22.8l0,0c0.3,0,0.7,0,0.9,0l0,0c4-0.2,32-2.2,55-11.5c7.6-3,14.6-7.1,20-11.9c5.3-4.8,9-10.6,9.9-17.6
			c0-0.3,0.1-0.5,0.1-0.8l0,0c0.5-3.2,5.9-37.8-19.9-53c-0.9-0.5-1.8-1-2.9-1.3c-10.4-3.4-80.7-25.8-131.1-49
			c-21.7-10.1-39.9-20.2-48.1-29c-3.7-4-5.4-7.9-4.9-11.4c0.5-3.4,3.4-6.6,9.2-9.1c1.3-0.6,2.6-1,4-1.1l0,0
			c14-1.9,120.5-17.5,168.5-40.7c8.2-4,14.6-8,18.6-12.3c2.8-3,4.3-6.1,4.3-9.3c0-3.2-1.7-6.6-5.3-9.9l0,0c-2.6-2.6-4.5-6.1-5.5-9.9
			c-1.3-4.6-2-10.7,0.1-15.1c0.9-1.8,2.2-3.2,4.3-4.2c-2.1-1-3.4-2.4-4.3-4.2c-2.1-4.3-1.4-10.3-0.1-15.1c1.1-3.8,2.9-7.4,5.5-9.9
			l0,0c3.6-3.4,5.3-6.7,5.3-9.9c0-3.2-1.6-6.3-4.3-9.3c-4-4.3-10.5-8.5-18.6-12.3c-48.2-23.2-154.6-38.6-168.5-40.7l0,0
			c-1.3-0.2-2.8-0.6-4-1.1c-5.8-2.6-8.6-5.8-9.2-9.1c-0.5-3.4,1.1-7.2,4.9-11.4c8.2-8.8,26.2-19.1,48.1-29
			c50.6-23.2,120.9-45.8,131.1-49c1.1-0.3,2-0.8,2.9-1.3c25.8-15.2,20.4-49.7,19.9-53l0,0c0-0.3-0.1-0.5-0.1-0.8
			c-1.1-7.1-4.7-12.8-9.9-17.5c-5.4-5-12.4-8.8-20-11.9c-23-9.5-51.1-11.4-55-11.5l0,0c-0.3,0-0.7,0-0.9,0l0,0
			c-62.7-7.7-95.5-19.7-102.8-22.8l0,0c-0.9-0.3-1.7-0.6-2.8-0.8l0,0c-6.7-1.4-11.6-3.2-14.6-5.3c-2.2-1.4-3.6-3.2-4.2-5
			c-0.9-2.7-0.3-5.9,2-9.3c3-4.5,9-9.3,17-14.6C740.2,51.8,877.9,7.6,877.9,7.6l0.9-0.3l0.5,2.2l-0.9,0.3c0,0-137.5,44.2-188.4,77.1
			c-7.8,5-13.4,9.8-16.3,13.9c-1.7,2.4-2.4,4.6-1.6,6.7c0.5,1.4,1.6,2.7,3.4,3.8c2.9,1.9,7.5,3.7,14,5c1.1,0.2,2.1,0.5,3,1
			c7.4,2.9,40,14.9,102.4,22.6c0.3,0,0.5,0,0.9,0c4.7,0.3,42.5,3,66.6,17.1c10.5,6.3,18.6,14.6,20.1,26l0,0c0,0.3,0.1,0.5,0.1,0.8
			c0.7,3.5,6.1,39.6-20.9,55.6l0,0c-1.1,0.6-2.1,1.1-3.2,1.4c-10.4,3.4-80.6,25.8-131,49c-21.5,9.9-39.4,19.9-47.4,28.7
			c-1.6,1.8-2.8,3.4-3.6,5c-0.7,1.4-0.9,2.7-0.7,4.2c0.3,1.3,0.9,2.6,2.1,3.7c1.3,1.3,3.3,2.6,5.8,3.7l0,0c1.2,0.5,2.4,0.8,3.6,1
			c14.1,1.9,120.7,17.6,169.1,40.9c8.4,4,15,8.3,19.2,12.8c3.3,3.5,5,7.4,5,11.2c0,3.8-1.8,7.7-5.9,11.7c-2.4,2.2-4.1,5.4-5,8.8l0,0
			c-1.1,4.2-1.8,9.3,0,13c1.2,2.4,3.6,4,7.6,4.3v2.4l0,0c-0.3,0-0.4,0-0.7,0c0.1,0,0.1,0.2,0.3,0.2c-3.8,0.3-6.1,1.9-7.2,4.3
			c-1.8,3.7-1.1,9,0,13l0,0c0.9,3.5,2.6,6.6,5,8.8c4.1,4,5.9,8,5.9,11.7c0,3.8-1.7,7.5-5,11.2c-4.1,4.5-10.8,8.8-19.2,12.8
			c-48.3,23.2-155.1,38.8-169.1,40.9c-1.2,0.2-2.4,0.5-3.6,1l0,0c-2.5,1.1-4.5,2.2-5.8,3.7c-1.2,1.1-1.8,2.4-2.1,3.7
			c-0.3,1.3,0,2.7,0.7,4c0.7,1.6,2,3.2,3.6,5c8,8.8,25.9,18.8,47.4,28.7c50.4,23.2,120.6,45.7,131,49c1.1,0.3,2.2,0.8,3.2,1.4l0,0
			c27,16,21.5,52.1,20.9,55.6c0,0.3-0.1,0.5-0.1,0.8l0,0c-1.6,11.2-9.6,19.7-20.1,26c-24.1,14.1-61.9,16.8-66.6,17.1
			c-0.3,0-0.7,0-0.9,0c-62.4,7.7-95.1,19.7-102.4,22.6c-1.1,0.5-2,0.8-3,1c-6.5,1.3-11.1,3-14,5c-1.7,1.1-2.9,2.4-3.4,3.8
			c-0.7,2.1,0,4.3,1.6,6.7c2.9,4.3,8.6,9,16.3,13.9c51.1,32.9,188.4,77.1,188.4,77.1c0.3,0.2,0.5,0.3,0.7,0.6c0,0,0.1,0.2,0.1,0.5v1
			c0,0.5-0.3,1-0.7,1.1c0,0-137.5,44.2-188.4,77.1c-7.8,5-13.4,9.6-16.3,13.9c-1.7,2.4-2.4,4.8-1.6,6.7c0.5,1.4,1.6,2.7,3.4,3.8
			c2.9,1.9,7.5,3.7,14,5c1.1,0.2,2.1,0.5,3,1c7.4,2.9,40,14.9,102.4,22.6c0.3,0,0.5,0,0.9,0c4.7,0.3,42.5,3,66.6,17.1
			c10.5,6.3,18.6,14.7,20.1,26l0,0c0,0.3,0.1,0.5,0.1,0.8c0.7,3.5,6.1,39.6-20.9,55.6l0,0c-1.1,0.6-2.1,1.1-3.2,1.4
			c-10.4,3.2-80.6,25.8-131,49c-21.5,9.9-39.4,19.9-47.4,28.7c-1.6,1.8-2.8,3.4-3.6,5c-0.7,1.4-0.9,2.7-0.7,4
			c0.3,1.3,0.9,2.6,2.1,3.7c1.3,1.3,3.3,2.6,5.8,3.7l0,0c1.2,0.5,2.4,0.8,3.6,1c14.1,1.9,120.7,17.6,169.1,40.9
			c8.4,4,15,8.3,19.2,12.8c3.3,3.5,5,7.4,5,11.2s-1.8,7.7-5.9,11.7c-2.4,2.4-4.1,5.4-5,8.8l0,0c-1.1,4.2-1.8,9.3,0,13
			c1.2,2.4,3.6,4,7.6,4.3v2.4l0,0c-0.3,0-0.4,0-0.7,0C877,1305.2,877,1305.2,877.1,1305.2L877.1,1305.2z M877.5,441.5
			c0,0,0.9-2.9,0.9-1.1C878.6,441,878.1,441.5,877.5,441.5L877.5,441.5L877.5,441.5z M877.5,441.5c-0.1,0-0.3,0-0.4,0
			C877.3,441.5,877.4,441.5,877.5,441.5z M877.5,1305c0,0,0.9-2.9,0.9-1.1C878.6,1304.5,878.1,1305,877.5,1305
			C877.5,1305.2,877.5,1305.2,877.5,1305L877.5,1305L877.5,1305z M877.5,1305.2c-0.1,0-0.3,0-0.4,0
			C877.3,1305.2,877.4,1305.2,877.5,1305.2z M960.1,405.1L960.1,405.1l-0.9-0.2c-67.4-7.5-134.8-63-134.8-63.1l-0.1-0.2
			c-0.1,0-0.1-0.2-0.3-0.2l0,0c-9-7.1-11.1-18.6-11.2-27.4l0,0c0-5.9-2.4-11.5-6.3-15.1c-2.9-2.6-4.2-5.3-4.3-8
			c0-1.6,0.4-3.4,1.3-5.1c1.2-2.2,3.3-4.6,6.2-7.1c29.9-24.5,143.4-50,149.3-51.3h0.1c21.7-6.6,35.6-12,43.7-16.7
			c5.1-2.9,8-5.4,9-7.7c0.7-1.4,0.1-2.9-1.1-4c-1.6-1.4-4.1-2.7-7.2-3.8c-13.7-4.8-38.2-6.3-43.2-6.4v0.2l-0.9-0.2
			c-0.3,0-0.7,0-0.9-0.2c-74.8-8.2-126.8-58.8-133-65.1l0,0c-0.5-0.5-0.9-1-1.3-1.6l0,0c-11.3-14.7-15.4-27.6-14.5-38.6
			c1.4-16.5,14.4-29.3,32.3-39.1C885.7,20.4,959.3,15,959.3,15h0.9l0.1,2.4h-0.9c0,0-73.1,5.4-116.8,29
			c-8.7,4.6-16.1,10.1-21.6,16.3c-5.4,6.1-8.8,13-9.5,20.8c-0.9,10.6,3.2,22.6,14,36.7c0.4,0.5,0.8,1,1.2,1.4
			c6.2,6.3,57.7,56.4,131.8,64.6h0.9c0,0,29.2,1.1,44.6,6.6c4.2,1.4,7.4,3.4,9,5.4c1.2,1.8,1.6,3.7,0.7,5.8
			c-1.1,2.6-4.1,5.4-9.9,8.7c-8.2,4.6-21.9,10.1-43.2,16.5l0,0l-0.9,0.2c0,0,0,0-0.1,0l-0.7,0.2l0,0C948,232,840,256.7,811.1,280.4
			c-2.2,1.8-4,3.5-5.1,5.4c-1.1,1.6-1.6,3.2-1.6,4.8c0,2.1,1.2,4.2,3.6,6.1l0,0c4.5,4,7.1,10.3,7.1,16.8c0.1,8.2,2,19.1,10.3,25.5
			c0,0,0,0,0.1,0.2c0.1,0.2,0.3,0.2,0.3,0.3l-0.1-0.2c0.3,0.3,66.6,54.8,133.2,62.7l0,0l0.9,0.2c0,0,31.6,3.5,45.8,12.2
			c4.1,2.6,6.8,5.6,7.2,8.8c0.3,2.1-0.3,4.5-2.1,6.9c-2.2,3-6.3,6.4-13,10.1c6.7,3.7,10.8,7.1,13,10.1c1.7,2.4,2.4,4.8,2.1,6.9
			c-0.4,3.4-3.2,6.3-7.2,8.8c-14.2,8.5-45.8,11.7-45.8,11.7l-0.9,0.2l0,0c-66.6,7.9-132.9,62.5-133.2,62.7l0.1-0.2
			c-0.1,0.2-0.3,0.2-0.3,0.3c0,0,0,0-0.1,0C817,547.1,815,558,815,566.2c0,6.7-2.6,13-7.1,16.8l0,0c-2.4,1.9-3.4,4-3.6,6.1
			c0,1.6,0.5,3.2,1.6,4.8c1.2,1.8,2.9,3.5,5.1,5.4c29,23.7,136.9,48.4,147.9,50.8l0,0l0.7,0.2c0.1,0,0.1,0,0.1,0l0.9,0.2l0,0
			c21.3,6.4,35,11.9,43.2,16.5c5.8,3.2,8.8,6.3,9.9,8.7c0.9,2.1,0.7,4-0.7,5.8c-1.6,2.1-4.7,4-9,5.4c-15.4,5.4-44.6,6.6-44.6,6.6
			h-0.1c-74.7,8.3-126.5,61.9-132.6,68.4c-0.4,0.5-0.8,1-1.2,1.4l0,0c-10.8,14.9-14.9,27.7-14,38.9c1.4,16.8,14,29.5,31.1,39.4
			c41.9,24,111,30.3,116.4,30.8c0.1,0,0.3,0,0.3,0h-0.1c0.1,0,0.3,0,0.3,0l-1.1,1.3l1.1,1.3c0,0,1.1-2.9,1.1-1.3l0,0
			c0,0.3-0.1,0.6-0.3,1c-0.3,0.2-0.5,0.3-0.8,0.3c0,0-0.1,0-0.3,0c-5.4,0.5-74.5,6.6-116.4,30.1c-17.1,9.6-29.8,22.1-31.1,38.5
			c-0.9,10.9,3.2,23.6,14,38c0.4,0.5,0.8,1,1.2,1.4c6.2,6.4,57.9,58.8,132.6,67h0.3c0,0,29.2,1.1,44.6,6.6c4.2,1.4,7.4,3.4,9,5.4
			c1.2,1.8,1.6,3.7,0.7,5.8c-1.1,2.6-4.1,5.4-9.9,8.7c-8.2,4.6-21.9,10.1-43.2,16.5l0,0l-0.9,0.2c0,0,0,0-0.1,0l-0.7,0.2l0,0
			c-10.9,2.4-118.9,27.1-147.9,50.8c-2.2,1.8-4,3.5-5.1,5.3c-1.1,1.6-1.6,3.2-1.6,4.8c0,2.1,1.2,4.2,3.6,6.1l0,0
			c4.5,4,7.1,10.3,7.1,16.8c0.1,8.2,2,19.1,10.3,25.5c0,0,0,0,0.1,0c0.1,0.2,0.3,0.2,0.3,0.3l-0.1-0.2c0.3,0.3,66.6,54.8,133.2,62.7
			l0,0l0.9,0.2c0,0,31.6,3.5,45.8,12.2c4.1,2.6,6.8,5.6,7.2,8.8c0.3,2.1-0.3,4.5-2.1,6.9c-2.1,2.9-6.1,6.3-12.4,9.8
			c6.3,3.5,10.3,6.9,12.4,9.8c1.7,2.4,2.4,4.8,2.1,6.9c-0.4,3.4-3.2,6.4-7.2,8.8c-14.2,8.7-45.8,12.2-45.8,12.2l-0.9,0.2l0,0
			c-66.6,7.9-132.9,62.5-133.2,62.7l0.1-0.2c-0.1,0.2-0.3,0.2-0.3,0.3c0,0,0,0-0.1,0c-8.3,6.4-10.3,17.3-10.3,25.5
			c0,6.7-2.6,13-7.1,16.8l0,0c-2.4,1.9-3.4,4-3.6,6.1c0,1.6,0.5,3.2,1.6,4.8c1.2,1.8,2.9,3.5,5.1,5.3c29,23.7,136.9,48.4,147.9,50.8
			l0,0l0.7,0.2c0.1,0,0.1,0,0.1,0l0.9,0.2l0,0c21.3,6.4,35,11.9,43.2,16.5c5.8,3.2,8.8,6.3,9.9,8.7c0.9,2.1,0.7,4-0.7,5.8
			c-1.6,2.1-4.7,4-9,5.4c-15.4,5.4-44.6,6.6-44.6,6.6h-0.9c-74.1,8-125.6,58.3-131.8,64.6c-0.4,0.5-0.8,1-1.2,1.4
			c-10.8,14.1-14.9,26.1-14,36.7c0.7,7.9,4.2,14.7,9.5,20.8c5.5,6.3,12.9,11.7,21.6,16.3c43.6,23.7,116.8,29.2,116.8,29.2h0.9
			l-0.1,2.4h-0.9c0,0-73.6-5.4-117.4-29.3c-17.9-9.8-30.8-22.6-32.3-39.1c-1.1-11.1,3.2-23.9,14.5-38.6l0,0c0.4-0.5,0.9-1.1,1.3-1.6
			l0,0c6.2-6.3,58.2-57.1,133-65.1c0.3,0,0.7,0,0.9-0.2l0.9-0.2v0.2c5-0.3,29.6-1.6,43.2-6.4c3.2-1.1,5.7-2.4,7.2-3.8
			c1.2-1.1,1.7-2.4,1.1-4c-0.9-2.2-3.8-4.6-9-7.7c-8.2-4.6-22-10.1-43.7-16.5h-0.1c-5.9-1.3-119.4-26.9-149.3-51.3
			c-2.9-2.4-5-4.8-6.2-7.1c-0.9-1.8-1.3-3.4-1.3-5.1c0-2.7,1.3-5.4,4.3-8c4-3.5,6.3-9.1,6.3-15.1l0,0c0.1-8.8,2.2-20.5,11.2-27.4
			c0.1,0,0.1-0.2,0.3-0.2l0.1-0.2c0,0,67.4-55.6,134.8-63l0.9-0.2l0,0c5.4-0.6,31.6-4.2,44.2-11.9c1.8-1.1,3.3-2.2,4.3-3.5
			c0.9-1.1,1.6-2.2,1.8-3.5c0.3-1.6-0.3-3.2-1.6-5c-2.2-3-6.6-6.4-13.7-10.3c-0.4-0.2-0.7-0.6-0.7-1.1c0-0.5,0.3-1,0.7-1.1
			c7.1-3.7,11.5-7.2,13.7-10.3c1.3-1.8,1.8-3.4,1.6-5c-0.1-1.3-0.8-2.4-1.8-3.5c-1.1-1.3-2.6-2.4-4.3-3.5
			c-12.5-7.7-38.8-11.2-44.2-11.9l0,0l-0.9-0.2c-67.4-7.5-134.8-63-134.8-63s-0.1,0-0.1-0.2c-0.1,0-0.1-0.2-0.3-0.2l0,0
			c-9-6.9-11.1-18.6-11.2-27.4l0,0c0-5.9-2.4-11.5-6.3-15.1c-2.9-2.6-4.2-5.3-4.3-8c0-1.6,0.4-3.4,1.3-5.1c1.2-2.4,3.3-4.6,6.2-7.1
			c29.9-24.5,143.4-50,149.3-51.3h0.1c21.7-6.4,35.6-12,43.7-16.5c5.1-2.9,8-5.4,9-7.7c0.7-1.6,0.1-2.9-1.1-4
			c-1.6-1.4-4.1-2.7-7.2-3.8c-13.7-4.8-38.1-6.3-43.2-6.4v0.2l-1.1-0.2c-75.4-8.2-127.7-61.1-133.9-67.6l0,0
			c-0.4-0.5-0.9-1.1-1.3-1.6l0,0c-11.3-15.2-15.4-28.5-14.5-39.9c1.4-17.1,14.4-30.5,32.3-40.5c33-18.6,83.1-26.4,105.6-29.2
			c-22.6-2.7-72.5-10.7-105.7-29.8c-17.9-10.3-30.8-23.9-32.3-41.4c-0.9-11.7,3.2-25.2,14.5-40.7c0.4-0.6,0.9-1.1,1.3-1.6l0,0
			c6.2-6.6,58.6-60.7,133.9-69.1l1.1-0.2v0.2c5.1-0.2,29.6-1.6,43.2-6.4c3.2-1.1,5.7-2.4,7.2-3.8c1.2-1.1,1.7-2.4,1.1-4
			c-0.9-2.2-3.8-4.6-9-7.7c-8.2-4.6-22-10.1-43.7-16.5H959c-5.9-1.3-119.4-26.9-149.3-51.3c-2.9-2.4-5-4.8-6.2-7.1
			c-0.9-1.8-1.3-3.4-1.3-5.1c0-2.7,1.3-5.4,4.3-8c4-3.5,6.3-9.1,6.3-15.1l0,0c0.1-8.8,2.2-20.5,11.2-27.4c0.1,0,0.1-0.2,0.3-0.2
			l0.1-0.2c0,0,67.4-55.6,134.8-63.1l0.9-0.2l0,0c5.4-0.6,31.7-4,44.2-11.4c1.8-1.1,3.4-2.2,4.5-3.7c0.9-1.1,1.6-2.4,1.7-3.7
			c0.1-1.9-0.8-3.8-2.9-6.1c-2.5-2.7-6.5-5.6-12.5-8.7c-0.4-0.2-0.7-0.6-0.7-1.1v-0.6l0.9,1.1l-0.4-2.2c7.1-3.8,11.5-7.2,13.7-10.3
			c1.3-1.8,1.8-3.4,1.6-5c-0.1-1.3-0.8-2.4-1.8-3.5c-1.1-1.3-2.6-2.4-4.3-3.5C991.7,409.1,965.4,405.6,960.1,405.1L960.1,405.1z
			 M958.3,165.6c-33.6-3-68.2-22.6-73.2-25.6c-0.5-0.3-1.1-0.6-1.4-1l0,0c-16.3-12.3-23-24-23.3-34.5c-0.3-10.6,5.8-20.2,15.3-28.7
			c26.2-23.4,78.3-37.7,83.2-38.9L1064,7.1l0.4,2.2L960.5,38.7l0,0l-0.9,0.3c0,0-55.6,14.3-83,38.6c-8.8,7.9-14.7,16.8-14.5,26.8
			s6.8,20.8,22.4,32.5c0.4,0.3,0.9,0.6,1.3,1c5.1,3,39.9,22.8,73.5,25.5h0.3c4.6,0.2,68.1,3.4,91.5,14.7c3.2,1.6,5.7,3.2,7.1,5.1
			c1.7,2.1,2.4,4.5,1.7,6.9c-1.4,5.8-0.7,11.9,2.2,16.7l0,0c1.1,1.8,1.7,3.8,1.4,6.1c-0.3,3-2.1,6.7-6.8,10.6
			c-10.9,9-38.1,20.2-97.3,32.4l0,0c0,0-51.2,19.4-78.3,44.7c-5.7,5.3-10.4,10.9-13.2,16.7c-2.8,5.4-3.7,11.2-2.2,16.8
			c1.7,6.6,6.8,13.1,16.2,19.6c14.5,9.9,39,19.2,77.3,27.6l0,0c0,0,71.9,5.4,92.8,17.8c2.4,1.4,4.1,2.9,5.1,4.5
			c0.8,1.3,1.2,2.6,1.1,3.8c0,1.3-0.5,2.7-1.6,4.2c-3,4-4.2,9.8-2.9,15.1l0,0c1.3,5.3,4.7,10.7,9.1,12.2c0.3,0,0.5,0.3,0.7,0.6
			c0.1,0.2,0.1,0.5,0.1,0.6v0.8c0,0.2,0,0.5-0.1,0.6c-0.1,0.3-0.4,0.5-0.7,0.6c-4.3,1.4-7.9,7.2-9.1,12.7l0,0
			c-1.3,5.3-0.3,11.1,2.9,15.1c1.1,1.4,1.6,2.9,1.6,4.2c0,1.3-0.3,2.6-1.1,3.8c-1.1,1.6-2.8,3-5.1,4.5
			c-21.1,12.3-92.8,17.6-92.8,17.6l0,0c-38.3,8.3-62.8,17.6-77.3,27.6c-9.3,6.4-14.5,13-16.2,19.6c-1.4,5.6-0.5,11.2,2.2,16.8
			c2.8,5.8,7.5,11.4,13.2,16.7c27.1,25.3,78.3,44.7,78.3,44.7l0,0c59.3,12.2,86.4,23.4,97.3,32.4c4.7,3.8,6.6,7.5,6.8,10.6
			c0.1,2.2-0.4,4.2-1.4,6.1l0,0c-2.9,4.8-3.7,11.1-2.2,16.7c0.7,2.4,0,4.8-1.7,6.9c-1.6,1.8-3.9,3.5-7.1,5.1
			c-23.4,11.2-86.9,14.4-91.5,14.6h-0.3c-33.6,2.7-68.3,22.4-73.5,25.5c-0.4,0.3-0.9,0.6-1.3,1c-15.5,11.7-22.1,22.6-22.4,32.5
			c-0.3,9.9,5.5,18.9,14.5,26.8c27.3,24.4,83,38.6,83,38.6l0.9,0.3l0,0l102.8,29.2l-0.3,2.4c-0.1,0-0.1,0-0.3,0l-0.5-0.2
			c0.3,0.5,0.8,0.2,0.8,0.2s0.9-2.9,0.9-1.1c0,0.5-0.3,1-0.8,1.1l-103,29.2l0,0l-0.9,0.3c0,0-55.6,14.3-83,38.6
			c-8.8,7.9-14.7,16.8-14.5,26.8c0.3,9.9,6.8,20.8,22.4,32.5c0.4,0.3,0.9,0.6,1.3,1c5.1,3,39.9,22.8,73.5,25.5h0.3
			c4.6,0.2,68.1,3.4,91.5,14.7c3.2,1.6,5.7,3.2,7.1,5.1c1.7,2.1,2.4,4.5,1.7,6.9c-1.4,5.6-0.7,11.9,2.2,16.7l0,0
			c1.1,1.8,1.7,3.8,1.4,6.1c-0.3,3-2.1,6.7-6.8,10.6c-10.9,9-38.1,20.2-97.3,32.4l0,0c0,0-51.2,19.6-78.3,44.7
			c-5.7,5.3-10.4,10.9-13.2,16.7c-2.8,5.4-3.7,11.2-2.2,16.8c1.7,6.6,6.8,13.1,16.2,19.6c14.5,9.9,39,19.2,77.3,27.6l0,0
			c0,0,71.9,5.4,92.8,17.8c2.4,1.4,4.1,2.9,5.1,4.5c0.8,1.3,1.2,2.4,1.1,3.8c0,1.3-0.5,2.7-1.6,4.2c-3,4-4.2,9.8-2.9,15.1l0,0
			c0.5,2.1,2.1,4.5,3.8,6.6c2.6,3.2,5.7,5.9,5.7,5.9c0.3,0.2,0.4,0.6,0.4,1c0,0.2,0,1,0,1c0,0.3,0,0.5-0.1,0.6
			c-0.1,0.3-0.4,0.5-0.7,0.6c-4.3,1.4-7.9,7.2-9.1,12.5l0,0c-1.3,5.3-0.3,11.1,2.9,15.1c1.1,1.4,1.6,2.9,1.6,4.2
			c0,1.3-0.3,2.6-1.1,3.8c-1.1,1.6-2.8,3-5.1,4.5c-21.1,12.3-92.8,17.6-92.8,17.6l0,0c-38.3,8.3-62.8,17.6-77.3,27.6
			c-9.3,6.4-14.5,13-16.2,19.6c-1.4,5.6-0.5,11.2,2.2,16.8c2.8,5.8,7.5,11.4,13.2,16.7c27.1,25.3,78.3,44.7,78.3,44.7l0,0
			c59.3,12.2,86.4,23.4,97.3,32.4c4.7,3.8,6.6,7.5,6.8,10.6c0.1,2.2-0.4,4.3-1.4,6.1l0,0c-2.9,4.8-3.7,11.1-2.2,16.7
			c0.7,2.6,0,4.8-1.7,6.9c-1.6,1.8-3.9,3.5-7.1,5.1c-23.4,11.4-86.9,14.4-91.5,14.6H959c-33.6,2.7-68.3,22.4-73.5,25.5
			c-0.4,0.3-0.9,0.6-1.3,1c-15.5,11.7-22.1,22.6-22.4,32.5c-0.3,9.9,5.5,18.9,14.5,26.8c27.3,24.4,83,38.6,83,38.6l0.9,0.3l0,0
			l103.9,29.3l-0.4,2.2l-105.1-29.8c-5-1.3-57-15.5-83.2-38.9c-9.5-8.5-15.5-18.1-15.3-28.7c0.3-10.6,7-22.1,23.3-34.5l0,0
			c0.5-0.3,1.1-0.6,1.4-1c5.1-3,39.6-22.6,73.2-25.5l0,0h0.9l0,0h0.9l0,0c7.1-0.3,52.7-2.9,79.4-10.6c7.2-2.1,13.2-4.5,16.3-7.5
			c1.7-1.6,2.6-3.4,2.1-5.4c-1.6-6.4-0.7-13.3,2.6-18.8c0.8-1.3,1.3-2.9,1.2-4.5c-0.3-2.7-2-5.6-5.9-8.8
			c-10.8-9-37.5-19.7-95.9-31.9v0.2l-0.8-0.3l-1.1-0.2v-0.2c-6.6-2.6-52.8-21.2-78.1-44.9c-5.9-5.6-10.8-11.4-13.7-17.3
			c-3-6.1-4.1-12.5-2.4-18.8c1.8-7.1,7.1-14.1,17.1-21c14.5-9.9,39-19.4,77.2-27.7v-1.4h0.8l1.1-0.2v0.2c8.2-0.6,71.8-5.9,91.2-17.5
			c1.7-1,3-2.1,4-3.2c0.8-1,1.2-1.8,1.2-2.9c0-0.8-0.4-1.8-1.2-2.7l0,0c-3.6-4.6-4.7-11.2-3.3-17.3c1.3-5.8,5-11.7,9.5-13.8
			c-1.1-1-3.3-3-5.3-5.6c-2-2.4-3.7-5.1-4.2-7.5c-1.4-6.1-0.3-12.7,3.3-17.3l0,0c0.7-1,1.1-1.8,1.2-2.7c0-1-0.4-1.9-1.2-2.9
			c-0.9-1.1-2.2-2.1-4-3.2c-19.5-11.4-83.1-16.8-91.2-17.5v0.2l-1.1-0.2h-0.8v-0.2c-38.2-8.3-62.7-17.8-77.2-27.7
			c-10-6.7-15.3-13.9-17.1-21c-1.7-6.3-0.7-12.7,2.4-18.8c2.9-5.9,7.8-11.7,13.7-17.3c25.3-23.6,71.5-42.2,78.1-44.9v-0.2
			c0,0,0.9-0.2,1.1-0.2l0.8-0.3v0.2c58.3-12,85.1-22.9,95.9-31.9c4-3.4,5.8-6.3,5.9-8.8c0.1-1.6-0.4-3-1.2-4.5
			c-3.3-5.4-4.2-12.3-2.6-18.8c0.5-2.1-0.4-3.8-2.1-5.4c-3.2-2.9-9.1-5.4-16.3-7.5c-26.7-7.7-72.3-10.1-79.4-10.6l0,0H959l0,0h-0.9
			l0,0c-33.6-3-68.2-22.6-73.2-25.6c-0.5-0.3-1.1-0.6-1.4-1l0,0c-16.3-12.3-23-24-23.3-34.5c-0.3-10.6,5.8-20.4,15.3-28.7
			c26.2-23.4,78.3-37.7,83.2-38.9l100.1-28.4l-100.1-28.4c-5-1.3-57-15.5-83.2-38.9c-9.5-8.5-15.5-18.1-15.3-28.7
			c0.3-10.6,7-22.1,23.3-34.5l0,0c0.5-0.3,1.1-0.6,1.4-1c5.1-3,39.6-22.6,73.2-25.6l0,0h0.9l0,0h0.9l0,0c7.1-0.3,52.7-2.9,79.4-10.6
			c7.2-2.1,13.2-4.6,16.3-7.5c1.7-1.6,2.6-3.4,2.1-5.4c-1.6-6.4-0.7-13.3,2.6-18.8c0.8-1.3,1.3-2.9,1.2-4.5c-0.3-2.7-2-5.6-5.9-8.8
			c-10.8-9-37.5-19.7-95.9-31.7v0.2l-0.8-0.3l-1.1-0.2v-0.2c-6.6-2.6-52.8-21.2-78.1-44.9c-5.9-5.6-10.8-11.4-13.7-17.3
			c-3-6.1-4.1-12.5-2.4-18.8c1.8-7.1,7.1-14.1,17.1-21c14.5-9.9,39-19.4,77.2-27.7v-0.2h0.8l1.1-0.2v0.2c8.2-0.6,71.8-5.9,91.2-17.5
			c1.7-1,3-2.1,4-3.2c0.8-1,1.2-1.8,1.2-2.9c0-0.8-0.4-1.8-1.2-2.7l0,0c-3.6-4.6-4.7-11.2-3.3-17.3c1.3-5.4,4.7-11.4,9.1-13.6
			c-4.3-2.2-7.8-7.7-9.1-13.1c-1.4-6.1-0.3-12.7,3.3-17.3l0,0c0.7-1,1.1-1.8,1.2-2.7c0-1-0.4-1.9-1.2-2.9c-0.9-1.1-2.2-2.1-4-3.2
			c-19.5-11.4-83.1-16.8-91.2-17.5v0.2l-1.1-0.2h-0.8V383c-38.2-8.3-62.7-17.8-77.2-27.7c-10-6.7-15.3-13.9-17.1-21
			c-1.7-6.3-0.7-12.7,2.4-18.8c2.9-5.9,7.8-11.9,13.7-17.3c25.3-23.6,71.5-42.2,78.1-44.9v-0.2c0,0,0.9-0.2,1.1-0.2l0.8-0.3v0.2
			c58.3-12,85.1-22.9,95.9-31.9c4-3.4,5.8-6.3,5.9-8.8c0.1-1.6-0.4-3-1.2-4.5c-3.3-5.4-4.2-12.3-2.6-18.8c0.5-2.1-0.4-3.8-2.1-5.4
			c-3.2-2.9-9.1-5.4-16.3-7.5c-26.7-7.7-72.3-10.1-79.4-10.6l0,0H959l0,0L958.3,165.6v-0.2V165.6z M103.6,873.2c0,0,0.9-2.9,0.9-1.1
			c0,0.5-0.3,1-0.8,1.1L-0.4,902.7l-0.4-2.2l100.1-28.4L-0.8,843.7l0.4-2.2L103.7,871L103.6,873.2c-0.1,0-0.1,0-0.3,0l-0.5-0.2
			C103.1,873.6,103.6,873.2,103.6,873.2L103.6,873.2z M146.3,873.2c0,0,0.9-2.9,0.9-1.1c0,0.5-0.3,1-0.7,1.1L-0.3,932.5l-0.7-2.2
			l144.2-58.2L-0.9,813.8l0.7-2.2L146.7,871L146.3,873.2H146l-0.5-0.2C145.7,873.6,146.3,873.2,146.3,873.2L146.3,873.2z
			 M1224.9,873.4c-7.9,0.6-101.9,9.1-153.4,32.1c-11.5,5.1-20.7,10.9-26.5,17.3c-5.5,6.3-7.6,13.3-5,21.2l0,0
			c0.3,0.6,0.4,1.3,0.5,1.9c1.7,6.4,13,44.4,44.2,59.5c1.1,0.5,2.1,1.3,3,2.1c7.4,5.4,38.8,22,159.1,42.3c1.3,0.2,2.5,0.6,3.8,1.1
			l0,0c3.2,1.6,9.5,5,13.4,9.5c2.9,3.2,4.3,7.1,2.9,11.2c-0.9,2.6-3,5.3-7,8c-5.5,3.8-15,7.9-29.8,11.7l0,0
			c-0.7,0.2-1.4,0.3-2.2,0.3c-11.7,1.3-118.6,13-168.4,37c-8.4,4-15.1,8.5-19.5,13.3c-3,3.4-4.9,7.1-5.1,10.7
			c-0.1,3.8,1.3,7.7,4.6,12l0,0c2,2.6,3.3,5.6,4.1,9c1.2,5.1,2.9,12.8,4.1,18.4c0.9,4,2.9,7.7,5.7,10.1
			c17.5,15.2,85.1,67.2,192.1,68.1c2.5,0,5,0.6,7.2,1.9l0,0c3.2,1.8,7.4,4.6,10,8.2c2,2.6,2.9,5.6,2.2,8.8c-0.4,2.1-1.6,4.2-3.8,6.4
			c-2.9,2.7-7.6,5.6-14.9,8.5c7.2,2.9,12,5.8,14.9,8.5c2.2,2.1,3.4,4.3,3.8,6.4c0.7,3.2-0.4,6.1-2.2,8.8c-2.6,3.5-6.8,6.4-10,8.2
			l0,0c-2.2,1.3-4.7,1.9-7.2,1.9c-107,1-174.6,52.9-192.1,68.1c-2.8,2.6-4.7,6.1-5.7,10.1c-1.2,5.6-2.9,13.3-4.1,18.4
			c-0.8,3.4-2.1,6.4-4.1,9l0,0c-3.3,4.2-4.7,8.2-4.6,12c0.1,3.8,2,7.4,5.1,10.7c4.3,4.8,11.1,9.3,19.5,13.3
			c49.6,24,156.7,35.7,168.4,37c0.8,0,1.4,0.2,2.2,0.3l0,0c14.7,3.8,24.2,7.9,29.8,11.7c3.9,2.7,6.1,5.4,7,8c1.4,4-0.1,7.9-2.9,11.2
			c-3.8,4.5-10.1,7.9-13.4,9.5l0,0c-1.2,0.5-2.5,1-3.8,1.1c-120.2,20.4-151.7,36.7-159.1,42.2c-0.9,1-2,1.6-3,2.1
			c-31.2,15.1-42.5,53-44.2,59.5c-0.1,0.6-0.4,1.3-0.5,1.9l0,0c-2.6,7.9-0.4,14.7,5,21.2c5.8,6.6,15,12.3,26.5,17.3
			c53.7,23.9,153.9,32.1,153.9,32.1l0.9,0.2l-0.1,2.4l-0.9-0.2c0,0-100.5-8.2-154.4-32.2c-11.7-5.1-21.2-11.1-27.1-18
			c-6.2-7.1-8.4-14.9-5.5-23.7c0.1-0.5,0.4-1.1,0.5-1.8l0,0c1.7-6.6,13.3-45.5,45.4-60.9l0,0c0.9-0.5,1.7-1.1,2.5-1.8
			c0,0,0,0,0.1-0.2c7.4-5.4,38.7-22.1,159.8-42.6l0,0c1.2-0.2,2.2-0.5,3.3-1c3-1.4,9.1-4.6,12.8-9c2.2-2.6,3.6-5.4,2.5-8.5
			c-0.8-2.2-2.6-4.5-6.1-6.9c-5.5-3.8-14.6-7.7-29.2-11.4c-0.7-0.2-1.3-0.3-2-0.3c-11.7-1.3-119-13-168.9-37.2
			c-8.7-4.2-15.5-8.8-20-13.8c-3.6-3.8-5.5-8-5.7-12.3c-0.3-4.3,1.3-9,5.1-13.8c1.7-2.2,3-5,3.7-8l0,0c1.2-5.1,2.9-12.8,4.1-18.4
			l0,0c1.1-4.5,3.3-8.7,6.3-11.4l0,0c17.6-15.2,85.5-67.5,193.2-68.4c2.2,0,4.3-0.6,6.5-1.8c2.9-1.6,7-4.3,9.3-7.7
			c1.4-1.9,2.4-4.2,1.8-6.6c-0.4-1.6-1.3-3.4-3.2-5.1c-3-2.9-8-5.8-15.9-8.8c-0.4-0.2-0.7-0.6-0.7-1.1v-1l0.9,1.1l-0.3-2.4
			c7.9-2.9,12.9-5.9,15.9-8.8c1.8-1.8,2.9-3.5,3.2-5.1c0.5-2.4-0.4-4.6-1.8-6.6c-2.4-3.4-6.5-5.9-9.3-7.7c-2-1.1-4.2-1.8-6.5-1.8
			c-107.7-1-175.6-53.2-193.2-68.4l0,0c-3-2.9-5.4-6.9-6.3-11.4l0,0c-1.2-5.6-2.9-13.3-4.1-18.4l0,0c-0.7-3-2-5.8-3.7-8
			c-3.8-4.8-5.3-9.5-5.1-13.8c0.3-4.3,2.2-8.5,5.7-12.3c4.5-5,11.5-9.6,20-13.8c49.9-24.2,157.2-35.9,168.9-37.2
			c0.7,0,1.3-0.2,2-0.3c14.5-3.7,23.7-7.7,29.2-11.4c3.4-2.4,5.4-4.6,6.1-6.9c1.1-3.2-0.3-6.1-2.5-8.5c-3.7-4.2-9.7-7.4-12.8-9
			c-1.1-0.5-2.2-0.8-3.3-1l0,0c-121.1-20.5-152.5-37.2-159.8-42.6c0,0-0.1,0-0.1-0.2c-0.8-0.8-1.6-1.4-2.5-1.8l0,0
			c-32-15.4-43.6-54.3-45.4-60.9l0,0c-0.1-0.6-0.3-1.1-0.5-1.8c-2.9-8.8-0.7-16.7,5.5-23.7c5.9-6.7,15.4-12.7,27.1-18
			c42.5-18.9,113.9-28,142.3-31.1c-28.3-3-99.8-12.2-142.3-31.1c-11.7-5.1-21.2-11.2-27.1-17.8c-6.2-7.1-8.4-14.9-5.5-23.7
			c0.1-0.5,0.4-1.1,0.5-1.8l0,0c1.7-6.6,13.3-45.5,45.4-60.9l0,0c0.9-0.5,1.7-1.1,2.5-1.8c0,0,0,0,0.1-0.2
			c7.4-5.4,38.7-22.1,159.8-42.6l0,0c1.2-0.2,2.2-0.5,3.3-1c3-1.4,9.1-4.6,12.8-9c2.2-2.6,3.6-5.4,2.5-8.5c-0.8-2.2-2.6-4.5-6.1-6.9
			c-5.5-3.8-14.6-7.7-29.2-11.4c-0.7-0.2-1.3-0.3-2-0.3c-11.7-1.3-119-13-168.9-37.2c-8.7-4.2-15.5-8.8-20-13.8
			c-3.6-3.8-5.5-8-5.7-12.3c-0.3-4.3,1.3-9,5.1-13.8c1.7-2.2,3-5,3.7-8l0,0c1.2-5.1,2.9-12.8,4.1-18.4l0,0c1.1-4.6,3.3-8.7,6.3-11.4
			l0,0c17.6-15.2,85.5-67.5,193.2-68.4c2.2,0,4.3-0.6,6.5-1.8c2.9-1.6,7-4.3,9.3-7.9c1.4-2.1,2.4-4.3,1.8-6.7
			c-0.4-1.6-1.3-3.4-3.2-5.1c-3-2.9-8-5.9-15.9-8.8c-0.4-0.2-0.7-0.6-0.7-1.1c0-0.5,0.3-1,0.7-1.1c7.9-2.9,12.9-5.9,15.9-8.8
			c1.8-1.8,2.9-3.5,3.2-5.1c0.5-2.4-0.4-4.6-1.8-6.7c-2.4-3.4-6.5-6.1-9.3-7.9c-2-1.1-4.2-1.8-6.5-1.8
			c-107.7-1-175.6-53.2-193.2-68.4l0,0c-3-2.9-5.4-6.9-6.3-11.4l0,0c-1.2-5.4-2.9-13.3-4.1-18.4l0,0c-0.7-3-2-5.8-3.7-8
			c-3.8-4.8-5.3-9.5-5.1-13.8c0.3-4.3,2.2-8.5,5.7-12.3c4.5-5,11.5-9.5,20-13.8c49.9-24.2,157.2-35.9,168.9-37.2
			c0.7,0,1.3-0.2,2-0.3c14.5-3.8,23.7-7.7,29.2-11.4c3.4-2.4,5.4-4.6,6.1-6.9c1.1-3.2-0.3-6.1-2.5-8.5c-3.7-4.2-9.7-7.4-12.8-9
			c-1.1-0.5-2.2-0.8-3.3-1l0,0c-121.1-20.5-152.5-37.2-159.8-42.6c0,0-0.1,0-0.1-0.2c-0.8-0.8-1.6-1.4-2.5-1.8l0,0
			c-32-15.4-43.6-54.3-45.4-60.9l0,0c-0.1-0.6-0.3-1.1-0.5-1.8c-2.9-8.8-0.7-16.7,5.5-23.7c5.9-6.7,15.4-12.7,27.1-17.8
			c53.9-24,154.4-32.2,154.4-32.2l0.9-0.2l0.1,2.4l-0.9,0.2c0,0-100.2,8.2-153.9,32.1c-11.5,5.1-20.7,10.9-26.5,17.3
			c-5.5,6.3-7.6,13.3-5,21.2l0,0c0.3,0.6,0.4,1.3,0.5,1.9c1.7,6.4,13,44.4,44.2,59.5c1.1,0.5,2.1,1.3,3,2.1
			c7.4,5.4,38.8,22,159.1,42.3c1.3,0.2,2.5,0.6,3.8,1.1l0,0c3.2,1.6,9.5,5,13.4,9.5c2.9,3.2,4.3,7.1,2.9,11.2c-0.9,2.6-3,5.3-7,8
			c-5.5,3.8-15,7.9-29.8,11.7l0,0c-0.7,0.2-1.4,0.3-2.2,0.3c-11.7,1.3-118.6,13-168.4,37c-8.4,4-15.1,8.5-19.5,13.3
			c-3,3.4-4.9,7.1-5.1,10.7c-0.1,3.8,1.3,7.9,4.6,12l0,0c2,2.6,3.3,5.6,4.1,9c1.2,5.1,2.9,12.8,4.1,18.4c0.9,4,2.9,7.7,5.7,10.1
			c17.5,15.2,85.1,67.2,192.1,68.1c2.5,0,5,0.6,7.2,1.9l0,0c3.2,1.8,7.4,4.8,10,8.3c2,2.7,2.9,5.8,2.2,9c-0.4,2.1-1.6,4.2-3.8,6.4
			c-2.8,2.7-7.1,5.4-13.7,8.2c6.6,2.7,10.9,5.4,13.7,8.2c2.2,2.2,3.4,4.3,3.8,6.4c0.7,3.2-0.4,6.3-2.2,8.8c-2.6,3.7-6.8,6.6-10,8.3
			l0,0c-2.2,1.3-4.7,1.9-7.2,1.9c-107,1-174.6,52.9-192.1,68.1c-2.8,2.6-4.7,6.1-5.7,10.1c-1.2,5.6-2.9,13.3-4.1,18.4
			c-0.8,3.4-2.1,6.4-4.1,9l0,0c-3.3,4.2-4.7,8.2-4.6,12c0.1,3.8,2,7.4,5.1,10.7c4.3,4.8,11.1,9.3,19.5,13.3
			c49.6,24,156.7,35.7,168.4,37c0.8,0,1.4,0.2,2.2,0.3l0,0c14.7,3.8,24.2,7.9,29.8,11.7c3.9,2.7,6.1,5.4,7,8c1.4,4-0.1,7.9-2.9,11.2
			c-3.8,4.5-10.1,7.9-13.4,9.5l0,0c-1.2,0.5-2.5,1-3.8,1.1c-120.2,20.4-151.7,36.7-159.1,42.2c-0.9,0.8-2,1.6-3,2.1
			c-31.2,15.1-42.5,53-44.2,59.5c-0.1,0.6-0.4,1.3-0.5,1.9l0,0c-2.6,7.9-0.4,14.7,5,21.2c5.8,6.6,15,12.3,26.5,17.3
			c53.7,23.9,153.9,32.1,153.9,32.1v2.4l0,0c0,0-0.3,0-0.5,0C1224.8,873.2,1224.9,873.2,1224.9,873.4L1224.9,873.4z M1225.3,873.2
			c0,0,0.9-2.9,0.9-1.1C1226.3,872.8,1225.9,873.2,1225.3,873.2C1225.4,873.2,1225.4,873.2,1225.3,873.2L1225.3,873.2z
			 M1225.3,873.2c0,0-0.3,0.2-0.4,0C1225.1,873.2,1225.3,873.2,1225.3,873.2z M1358.8,873.2c0,0,0.9-2.9,0.9-1.1
			c0,0.5-0.3,1-0.8,1.1c0,0-137.5,44.2-188.4,77.1c-7.8,5-13.4,9.6-16.3,13.9c-1.7,2.4-2.4,4.8-1.6,6.7c0.5,1.4,1.6,2.7,3.4,3.8
			c2.9,1.9,7.5,3.7,14,5c1.1,0.2,2.1,0.5,3,1c7.4,2.9,40,14.9,102.4,22.6c0.3,0,0.5,0,0.9,0c4.7,0.3,42.5,3,66.6,17.1
			c10.7,6.3,18.6,14.7,20.1,26l0,0c0,0.3,0.1,0.5,0.1,0.8c0.5,3.5,6.1,39.6-20.9,55.6l0,0c-1.1,0.6-2.1,1.1-3.2,1.4
			c-10.4,3.2-80.6,25.8-131,49c-21.5,9.9-39.4,19.9-47.4,28.7c-1.6,1.8-2.8,3.4-3.6,5c-0.7,1.4-0.9,2.7-0.7,4
			c0.3,1.3,0.9,2.6,2.1,3.7c1.3,1.3,3.3,2.6,5.8,3.7l0,0c1.2,0.5,2.4,0.8,3.6,1c14.1,1.9,120.7,17.6,169.1,40.9
			c8.4,4,15,8.3,19.2,12.8c3.3,3.5,5,7.4,5,11.2s-1.8,7.7-5.9,11.7c-2.4,2.4-4.1,5.4-5,8.8l0,0c-1.1,4.2-1.8,9.3,0,13
			c1.2,2.4,3.6,4,7.6,4.3c0.4,0,0.7,0.3,0.8,0.6c0.1,0,0.1,0.2,0.1,0.5v1c0,0.6-0.4,1.1-0.9,1.1c-4.1,0.3-6.5,1.9-7.6,4.3
			c-1.8,3.7-1.1,9,0,13l0,0c0.9,3.5,2.6,6.6,5,8.8c4.1,4,5.9,8,5.9,11.7c0,3.8-1.7,7.5-5,11.2c-4.1,4.5-10.8,8.8-19.2,12.8
			c-48.3,23.2-155.1,38.8-169.1,40.9c-1.2,0.2-2.4,0.5-3.6,1l0,0c-2.5,1.1-4.5,2.2-5.8,3.7c-1.2,1.1-1.8,2.4-2.1,3.7
			c-0.3,1.3,0,2.7,0.7,4c0.7,1.6,2,3.2,3.6,5c8,8.8,25.9,18.8,47.4,28.7c50.4,23.2,120.6,45.7,131,49c1.1,0.3,2.2,0.8,3.2,1.4l0,0
			c27,16,21.5,52.1,20.9,55.6c0,0.3-0.1,0.5-0.1,0.8l0,0c-1.6,11.2-9.6,19.7-20.1,26c-24.1,14.1-61.9,16.8-66.6,17.1
			c-0.3,0-0.5,0-0.9,0c-62.4,7.7-95.1,19.7-102.4,22.6c-1.1,0.5-2,0.8-3,1c-6.5,1.3-11.1,3-14,5c-1.7,1.1-2.9,2.4-3.4,3.8
			c-0.7,2.1,0,4.3,1.6,6.7c2.9,4.3,8.6,9,16.3,13.9c51.1,33,188.4,77.1,188.4,77.1l0.9,0.3l-0.5,2.2l-0.9-0.3
			c0,0-137.7-44.4-188.8-77.4c-8-5.1-14-10.1-17-14.6c-2.2-3.4-2.9-6.4-2-9.3c0.7-1.8,2-3.5,4.2-5c3-2.1,7.9-3.8,14.6-5.3l0,0
			c0.9-0.2,1.8-0.5,2.8-0.8l0,0c7.4-2.9,40.2-15.1,102.8-22.8l0,0c0.3,0,0.7,0,0.9,0l0,0c3.9-0.3,32-2.2,55-11.5
			c7.6-3,14.6-7.1,20-11.9c5.3-4.8,9-10.6,10-17.6c0-0.3,0.1-0.5,0.1-0.8l0,0c0.5-3.2,5.9-37.8-19.9-53c-0.9-0.5-1.8-1-2.9-1.3
			c-10.4-3.4-80.7-25.8-131.1-49c-21.7-10.1-39.9-20.2-48.1-29.2c-3.7-4-5.4-7.9-4.9-11.4c0.5-3.4,3.4-6.6,9.2-9.1
			c1.3-0.6,2.6-1,3.9-1.1l0,0c14-1.9,120.5-17.5,168.5-40.7c8.2-3.8,14.6-8,18.6-12.3c2.8-3,4.3-6.1,4.3-9.3s-1.7-6.6-5.3-9.9l0,0
			c-2.6-2.6-4.5-6.1-5.5-9.9c-1.3-4.6-2-10.7,0.1-15.1c1.1-2.1,2.8-3.8,5.5-4.6c-2.8-1-4.5-2.7-5.5-4.6c-2.1-4.3-1.4-10.3-0.1-15.1
			c1.1-3.8,2.9-7.4,5.5-9.9l0,0c3.6-3.4,5.1-6.7,5.3-9.9c0-3.2-1.6-6.3-4.3-9.3c-4.1-4.3-10.5-8.5-18.6-12.3
			c-48.2-23.2-154.6-38.6-168.5-40.7l0,0c-1.3-0.2-2.8-0.6-4-1.1c-5.8-2.6-8.6-5.8-9.2-9.1c-0.5-3.4,1.1-7.2,4.9-11.4
			c8.2-8.8,26.2-19.1,48.1-29.2c50.6-23.2,120.9-45.8,131.1-49c1.1-0.3,2-0.8,2.9-1.3c25.8-15.2,20.4-49.7,19.9-53l0,0
			c0-0.3-0.1-0.5-0.1-0.8c-1.1-7.1-4.7-12.8-10-17.6c-5.4-5-12.4-8.8-20-11.9c-22.9-9.3-51.1-11.4-55-11.5l0,0c-0.3,0-0.7,0-0.9,0
			l0,0c-62.7-7.7-95.5-19.7-102.8-22.8l0,0c-0.9-0.3-1.7-0.6-2.8-0.8l0,0c-6.7-1.4-11.6-3.2-14.6-5.3c-2.2-1.4-3.6-3.2-4.2-5
			c-0.9-2.7-0.3-5.9,2-9.3c3-4.5,9-9.3,17-14.6c46.3-30,163.5-69.1,185.4-76.1c-21.7-7.2-139-46.3-185.4-76.1
			c-8-5.1-14-10.1-17-14.6c-2.2-3.4-2.9-6.4-2-9.3c0.7-1.8,2-3.5,4.2-5c3-2.1,7.9-3.8,14.6-5.3l0,0c0.9-0.2,1.8-0.5,2.8-0.8l0,0
			c7.4-2.9,40.2-15.1,102.8-22.8l0,0c0.3,0,0.7,0,0.9,0l0,0c3.9-0.3,32-2.2,55-11.5c7.6-3,14.6-7.1,20-11.9c5.3-4.8,9-10.6,10-17.6
			c0-0.3,0.1-0.5,0.1-0.8l0,0c0.5-3.2,5.9-37.8-19.9-53c-0.9-0.5-1.8-1-2.9-1.3c-10.4-3.4-80.7-25.8-131.1-49
			c-21.7-10.1-39.9-20.2-48.1-29c-3.7-4-5.4-7.9-4.9-11.4c0.5-3.4,3.4-6.6,9.2-9.1c1.3-0.6,2.6-1,3.9-1.1l0,0
			c14-1.9,120.5-17.5,168.5-40.7c8.2-4,14.6-8,18.6-12.3c2.8-3,4.3-6.1,4.3-9.3s-1.7-6.6-5.3-9.9l0,0c-2.6-2.6-4.5-6.1-5.5-9.9
			c-1.3-4.6-2-10.7,0.1-15.1c1.1-2.1,2.8-3.8,5.5-4.6c-2.8-1-4.5-2.6-5.5-4.6c-2.1-4.2-1.4-10.3-0.1-15.1c1.1-3.8,2.9-7.4,5.5-9.9
			l0,0c3.6-3.4,5.1-6.7,5.3-9.9c0-3.2-1.6-6.3-4.3-9.3c-4.1-4.3-10.5-8.5-18.6-12.3c-48.2-23.2-154.6-38.6-168.5-40.7l0,0
			c-1.3-0.2-2.8-0.6-4-1.1c-5.8-2.6-8.6-5.8-9.2-9.1c-0.5-3.4,1.1-7.2,4.9-11.4c8.2-8.8,26.2-19.1,48.1-29
			c50.6-23.2,120.9-45.8,131.1-49c1.1-0.3,2-0.8,2.9-1.3c25.8-15.2,20.4-49.7,19.9-53l0,0c0-0.3-0.1-0.5-0.1-0.8
			c-1.1-7.1-4.7-12.8-10-17.5c-5.4-5-12.4-8.8-20-11.9c-22.9-9.5-51.1-11.4-55-11.5l0,0c-0.3,0-0.7,0-0.9,0l0,0
			c-62.7-7.7-95.5-19.7-102.8-22.8l0,0c-0.9-0.3-1.7-0.6-2.8-0.8l0,0c-6.7-1.4-11.6-3.2-14.6-5.3c-2.2-1.4-3.6-3.2-4.2-5
			c-0.9-2.7-0.3-5.9,2-9.3c3-4.5,9-9.3,17-14.6c51.1-33.2,188.8-77.6,188.8-77.6l0.9-0.3l0.5,2.2l-0.9,0.3
			c0,0-137.5,44.2-188.4,77.1c-7.8,5-13.4,9.8-16.3,13.9c-1.7,2.4-2.4,4.6-1.6,6.7c0.5,1.4,1.6,2.7,3.4,3.8c2.9,1.9,7.5,3.7,14,5
			c1.1,0.2,2.1,0.5,3,1c7.4,3,40,15.1,102.4,22.6c0.3,0,0.5,0,0.9,0c4.7,0.3,42.5,3,66.6,17.1c10.7,6.3,18.6,14.6,20.1,26l0,0
			c0,0.3,0.1,0.5,0.1,0.8c0.5,3.5,6.1,39.6-20.9,55.6l0,0c-1.1,0.6-2.1,1.1-3.2,1.4c-10.4,3.4-80.6,25.8-131,49
			c-21.5,9.9-39.4,19.9-47.4,28.7c-1.6,1.8-2.8,3.4-3.6,5c-0.7,1.4-0.9,2.7-0.7,4.2c0.3,1.3,0.9,2.6,2.1,3.7
			c1.3,1.3,3.3,2.6,5.8,3.7l0,0c1.2,0.5,2.4,0.8,3.6,1c14.1,1.9,120.7,17.6,169.1,40.9c8.4,4,15,8.3,19.2,12.8c3.3,3.5,5,7.4,5,11.2
			c0,3.8-1.8,7.7-5.9,11.7c-2.4,2.2-4.1,5.4-5,8.8l0,0c-1.1,4.2-1.8,9.3,0,13c1.2,2.4,3.6,4,7.6,4.3c0.4,0,0.7,0.3,0.8,0.6
			c0.1,0,0.1,0.2,0.1,0.5v1c0,0.6-0.4,1.1-0.9,1.1c-4.1,0.3-6.5,1.9-7.6,4.3c-1.8,3.7-1.1,9,0,13l0,0c0.9,3.5,2.6,6.6,5,8.8
			c4.1,4,5.9,8,5.9,11.7c0,3.8-1.7,7.5-5,11.2c-4.1,4.5-10.8,8.8-19.2,12.8c-48.3,23.2-155.1,38.8-169.1,40.9
			c-1.2,0.2-2.4,0.5-3.6,1l0,0c-2.5,1.1-4.5,2.2-5.8,3.7c-1.2,1.1-1.8,2.4-2.1,3.7s0,2.7,0.7,4c0.7,1.6,2,3.2,3.6,5
			c8,8.8,25.9,18.8,47.4,28.7c50.4,23.2,120.6,45.7,131,49c1.1,0.3,2.2,0.8,3.2,1.4l0,0c27,16,21.5,52.1,20.9,55.6
			c0,0.3-0.1,0.5-0.1,0.8l0,0c-1.6,11.2-9.6,19.7-20.1,26c-24.1,14.1-61.9,16.8-66.6,17.1c-0.3,0-0.5,0-0.9,0
			c-62.4,7.7-95.1,19.7-102.4,22.6c-1.1,0.5-2,0.8-3,1c-6.5,1.3-11.1,3-14,5c-1.7,1.1-2.9,2.4-3.4,3.8c-0.7,2.1,0,4.3,1.6,6.7
			c2.9,4.3,8.6,9,16.3,13.9c51.1,32.9,188.4,77.1,188.4,77.1L1358.8,873.2h-0.3c0,0-0.1,0-0.5-0.2
			C1358.4,873.6,1358.8,873.2,1358.8,873.2L1358.8,873.2z M1795.3,440.3c0-0.2,0-0.2,0-0.3c4.6-22.8-10.9-36.2-31.3-44.2
			c-31.2-12.3-74.1-12.2-77.9-12.2h-0.5c-40.4-0.6-66.6-7.1-83.6-16c-17.2-9-25-20.5-28.2-31.3c-4.2-14.3,0-27.1,1.2-30.3l0,0
			c0.3-0.6,0.5-1.3,0.7-2.1l0,0c1.2-4.2,4.7-8.3,10-12.5c7.6-5.9,19.1-11.7,32.7-17c52.5-21,136.9-37.2,154.6-40.5l0,0
			c2.4-0.5,4.5-1.4,6.5-3l0,0c7.6-6.3,11.3-12.5,12.4-18.6c1.1-5.4-0.1-10.7-2.2-15.4c-3.3-7.2-9-13.1-12.9-16.7
			c-2.6-2.2-5.7-3.5-8.8-3.5c-112.2-1.8-175.5-32.7-188.2-39.6l0,0c-2-1.1-3.8-2.7-5.3-4.6l0,0c-5.3-6.9-7.8-13.5-8.2-19.9
			c-0.4-7.5,2.4-14.7,7.5-21.6c5-6.7,12.2-13,21.2-19.1c59-39.7,189.6-63.9,189.6-63.9l0.9-0.2l0.3,2.4l-0.9,0.2
			c0,0-130.2,24.2-188.9,63.8c-8.7,5.9-15.8,12-20.7,18.6c-4.7,6.3-7.2,13-7,19.9c0.3,5.9,2.8,12,7.6,18.4c1.3,1.8,2.9,3.2,4.7,4.2
			c12.6,6.9,75.7,37.7,187.4,39.4l0,0c3.6,0.2,7.1,1.4,10,4l0,0c5.9,5.4,15.4,15.9,16.2,28c0.5,8.3-2.6,17.5-13.6,26.4
			c-2.2,1.8-4.7,3-7.2,3.5c-17.6,3.4-101.9,19.6-154.3,40.4c-13.4,5.3-24.8,11.1-32.3,16.8c-4.9,3.7-8.2,7.5-9.2,11.4
			c-0.3,0.8-0.5,1.4-0.8,2.2c-1.2,3-5.1,15.1-1.2,28.5c3,10.3,10.5,21.2,27.1,29.8c16.9,8.8,42.9,15.1,83,15.9h0.4
			c3.8,0,47-0.3,78.6,12.2c21.3,8.5,37.3,22.9,32.7,46.8c4.6,23.9-11.2,38.3-32.7,46.8c-31.5,12.5-74.8,12.3-78.6,12.2h-0.4
			c-40,0.6-66.1,7.1-83,15.9c-16.6,8.7-24.1,19.6-27.1,29.8c-4,13.5,0,25.5,1.2,28.5c0.3,0.8,0.5,1.4,0.8,2.2
			c1.2,3.8,4.5,7.5,9.2,11.2c7.5,5.8,18.8,11.4,32.3,16.8c52.4,20.8,136.7,37.2,154.3,40.4c2.6,0.5,5.1,1.6,7.4,3.5
			c10.9,9,14.1,18.3,13.6,26.4c-0.8,12.3-10.3,22.8-16.2,28l0,0c-2.9,2.6-6.3,4-10,4l0,0c-111.8,1.8-174.7,32.5-187.4,39.4
			c-1.7,1-3.4,2.4-4.7,4.2c-4.9,6.4-7.4,12.5-7.6,18.4c-0.4,6.9,2.2,13.5,7,19.9c4.9,6.6,12,12.7,20.7,18.6
			c58.7,39.6,188.9,63.8,188.9,63.8c0.4,0,0.7,0.3,0.8,0.6c0.1,0,0.1,0.2,0.1,0.5v1c0,0.6-0.4,1.1-0.8,1.1
			c0,0-130.2,24.2-188.9,63.8c-8.7,5.9-15.8,12-20.7,18.6c-4.7,6.4-7.2,13-7,19.9c0.3,5.9,2.8,12,7.6,18.4c1.3,1.8,2.9,3.2,4.7,4.2
			c12.6,6.9,75.7,37.7,187.4,39.4l0,0c3.6,0,7.1,1.4,10,4l0,0c5.9,5.4,15.4,15.9,16.2,28c0.5,8.3-2.6,17.5-13.6,26.4
			c-2.2,1.8-4.7,3-7.2,3.5c-17.6,3.4-101.9,19.6-154.3,40.4c-13.4,5.3-24.8,10.9-32.3,16.8c-4.9,3.7-8.2,7.5-9.2,11.2
			c-0.3,0.8-0.5,1.4-0.8,2.2c-1.2,3-5.1,15.1-1.2,28.5c3,10.3,10.5,21.2,27.1,29.8c16.9,8.8,42.9,15.1,83,15.7h0.4
			c3.8,0,47-0.3,78.6,12.2c21.3,8.5,37.3,22.9,32.7,46.8c4.6,23.9-11.2,38.3-32.7,46.8c-31.5,12.5-74.8,12.3-78.6,12.2h-0.4
			c-40,0.6-66.1,7.1-83,15.7c-16.6,8.7-24.1,19.6-27.1,29.8c-4,13.5,0,25.5,1.2,28.5c0.3,0.6,0.5,1.4,0.8,2.2
			c1.2,3.8,4.5,7.5,9.2,11.2c7.5,5.8,18.8,11.5,32.3,16.8c52.4,20.8,136.7,37.2,154.3,40.4c2.6,0.5,5.1,1.6,7.4,3.5
			c10.9,9,14.1,18.3,13.6,26.4c-0.8,12.3-10.3,22.8-16.2,28l0,0c-2.9,2.6-6.3,4-10,4l0,0c-111.8,1.8-174.7,32.5-187.4,39.4
			c-1.7,1-3.4,2.4-4.7,4.2c-4.9,6.4-7.4,12.5-7.6,18.4c-0.4,6.9,2.2,13.5,7,19.9c4.9,6.6,12,12.7,20.7,18.6
			c58.7,39.6,188.9,63.8,188.9,63.8l0.9,0.2l-0.3,2.4l-0.9-0.2c0,0-130.6-24.4-189.6-63.9c-9-6.1-16.2-12.3-21.2-19.1
			c-5.1-6.9-7.8-14.1-7.5-21.6c0.3-6.4,2.9-13.1,8.2-19.9l0,0c1.6-1.9,3.3-3.5,5.3-4.6l0,0c12.6-6.9,76-37.8,188.2-39.6
			c3.2,0,6.3-1.3,8.8-3.5c4-3.5,9.6-9.5,12.9-16.7c2.1-4.6,3.3-9.9,2.2-15.4c-1.2-5.9-4.9-12.3-12.4-18.6l0,0c-2-1.6-4.2-2.7-6.5-3
			l0,0c-17.6-3.4-102-19.6-154.6-40.5c-13.6-5.4-25-11.2-32.7-17c-5.3-4-8.8-8.3-10-12.5l0,0c-0.3-0.6-0.4-1.4-0.7-2.1l0,0
			c-1.2-3.2-5.4-16-1.2-30.3c3.2-10.6,10.9-22.1,28.2-31.3c17-8.8,43.3-15.2,83.6-16h0.5c3.7,0,46.6,0.3,77.9-12.2
			c20.1-8,35.6-21.3,31.3-43.8c0.3,0.6,0.8,0.3,0.8,0.3c-0.3,0-0.5-0.2-0.8-0.5c-0.1-0.2-0.3-0.5-0.3-0.6c0-0.2,0-0.2,0-0.3
			c4.6-22.8-10.9-36.2-31.3-44.2c-31.2-12.3-74.1-12.2-77.9-12.2h-0.5c-40.4-0.6-66.6-7.1-83.6-16c-17.2-9-25-20.5-28.2-31.3
			c-4.2-14.3,0-27.1,1.2-30.3l0,0c0.3-0.6,0.5-1.3,0.7-2.1l0,0c1.2-4.2,4.7-8.3,10-12.5c7.6-5.9,19.1-11.7,32.7-17
			c52.5-21,136.9-37.2,154.6-40.5l0,0c2.4-0.5,4.5-1.4,6.5-3l0,0c7.6-6.3,11.3-12.5,12.4-18.6c1.1-5.4-0.1-10.7-2.2-15.4
			c-3.3-7.2-9-13.1-12.9-16.7c-2.6-2.2-5.7-3.5-8.8-3.5c-112.2-1.8-175.5-32.7-188.2-39.6l0,0c-2-1.1-3.8-2.7-5.3-4.6l0,0
			c-5.3-6.9-7.8-13.5-8.2-19.9c-0.4-7.5,2.4-14.7,7.5-21.6c5-6.7,12.2-13.1,21.2-19.1c53.1-35.7,164.5-59,185.9-63.3
			c-21.5-4.3-132.9-27.6-185.9-63.3c-9-6.1-16.2-12.3-21.2-19.1c-5.1-6.9-7.8-14.1-7.5-21.6c0.3-6.4,2.9-13.1,8.2-19.9l0,0
			c1.6-1.9,3.3-3.5,5.3-4.6l0,0c12.6-6.9,76-37.8,188.2-39.6c3.2,0,6.3-1.3,8.8-3.5c4-3.5,9.6-9.5,12.9-16.7
			c2.1-4.6,3.3-9.9,2.2-15.4c-1.2-6.1-4.9-12.3-12.4-18.6l0,0c-2-1.6-4.2-2.7-6.5-3l0,0c-17.6-3.4-102-19.6-154.6-40.5
			c-13.6-5.4-25-11.2-32.7-17c-5.3-4-8.8-8.3-10-12.5l0,0c-0.3-0.6-0.4-1.4-0.7-2.1l0,0c-1.2-3.2-5.4-16-1.2-30.3
			c3.2-10.6,10.9-22.3,28.2-31.3c17-8.8,43.3-15.2,83.6-16h0.5c3.7,0,46.6,0.3,77.9-12.2c20.1-8,35.6-21.3,31.3-43.8
			c0.3,0.6,0.8,0.3,0.8,0.3c-0.3,0-0.5-0.2-0.8-0.5C1795.4,440.8,1795.3,440.7,1795.3,440.3L1795.3,440.3z M1795.4,1304.7
			c-0.1-0.2-0.1-0.5-0.1-0.8c0,0.2,0,0.2,0,0.3C1795.4,1304.4,1795.4,1304.5,1795.4,1304.7L1795.4,1304.7z M1795.4,441.1
			c-0.1-0.2-0.1-0.5-0.1-0.8c0,0.2,0,0.2,0,0.3C1795.4,440.8,1795.4,441,1795.4,441.1L1795.4,441.1z M1836.6,1305.2
			c-3.8,0.3-6.1,1.9-7.2,4.3c-1.8,3.7-1.1,9,0,13l0,0c0.9,3.5,2.6,6.6,5,8.8c4.1,4,5.9,8,5.9,11.7c0.1,3.8-1.7,7.5-5,11.2
			c-4.1,4.5-10.8,8.8-19.2,12.8c-48.3,23.2-155.1,38.8-169.1,40.9c-1.2,0.2-2.4,0.5-3.6,1l0,0c-2.5,1.1-4.5,2.2-5.8,3.7
			c-1.2,1.1-1.8,2.4-2.1,3.7c-0.3,1.3,0.1,2.7,0.7,4c0.8,1.6,2,3.2,3.6,5c8,8.8,25.9,18.8,47.4,28.7c50.4,23.2,120.6,45.7,131,49
			c1.1,0.3,2.2,0.8,3.2,1.4l0,0c27,16,21.6,52.1,20.9,55.6c0,0.3-0.1,0.5-0.1,0.8l0,0c-1.6,11.2-9.6,19.7-20.1,26
			c-24.1,14.1-62,16.8-66.6,17.1c-0.3,0-0.5,0-0.9,0c-62.4,7.7-95.1,19.7-102.4,22.6c-0.9,0.5-2,0.8-3,1c-6.5,1.3-11.1,3-14,5
			c-1.7,1.1-2.9,2.4-3.4,3.8c-0.8,2.1,0,4.3,1.6,6.7c2.9,4.3,8.7,9,16.3,13.9c51.1,33,188.4,77.1,188.4,77.1l0.9,0.3l-0.5,2.2
			l-0.9-0.3c0,0-137.7-44.4-188.8-77.4c-8-5.1-14-10.1-17-14.6c-2.2-3.4-2.9-6.4-2-9.3c0.7-1.8,2-3.5,4.2-5c3-2.1,7.8-3.8,14.6-5.3
			l0,0c0.9-0.2,1.8-0.5,2.8-0.8l0,0c7.4-2.9,40.2-15.1,102.8-22.8l0,0c0.3,0,0.7,0,0.9,0l0,0c4-0.3,32-2.2,55-11.5
			c7.6-3,14.6-7.1,20-11.9c5.3-4.8,9-10.6,10-17.6c0-0.3,0.1-0.5,0.1-0.8l0,0c0.5-3.2,5.9-37.8-19.9-53c-0.9-0.5-1.8-1-2.9-1.3
			c-10.4-3.4-80.7-25.8-131.1-49c-21.7-10.1-39.9-20.2-48.1-29.2c-3.7-4-5.4-7.9-4.9-11.4c0.5-3.4,3.4-6.6,9.2-9.1
			c1.3-0.6,2.6-1,4-1.1l0,0c14-1.9,120.5-17.5,168.7-40.7c8.2-4,14.6-8,18.6-12.3c2.8-3,4.3-6.1,4.3-9.3s-1.7-6.6-5.3-9.9l0,0
			c-2.6-2.6-4.5-6.1-5.5-9.9c-1.2-4.6-2-10.7,0.1-15.1c0.9-1.8,2.2-3.2,4.3-4.3c-2.1-1-3.4-2.4-4.3-4.3c-2.1-4.3-1.4-10.3-0.1-15.1
			c1.1-3.8,3-7.4,5.5-9.9l0,0c3.6-3.4,5.3-6.7,5.3-9.9c0-3.2-1.6-6.3-4.3-9.3c-4.1-4.3-10.4-8.5-18.6-12.3
			c-48.2-23.2-154.6-38.6-168.7-40.7l0,0c-1.3-0.2-2.8-0.6-4-1.1c-5.8-2.6-8.6-5.8-9.2-9.1c-0.5-3.4,1.1-7.2,4.9-11.4
			c8.2-8.8,26.2-19.1,48.1-29.2c50.6-23.2,120.9-45.8,131.1-49c1.1-0.3,2-0.8,2.9-1.3c25.8-15.2,20.4-49.7,19.9-53l0,0
			c0-0.3-0.1-0.5-0.1-0.8c-1.1-7.1-4.7-12.8-10-17.6c-5.4-5-12.4-8.8-20-11.9c-22.9-9.3-51.1-11.4-55-11.5l0,0c-0.3,0-0.7,0-0.9,0
			l0,0c-62.7-7.7-95.5-19.7-102.8-22.8l0,0c-0.9-0.3-1.7-0.6-2.8-0.8l0,0c-6.7-1.4-11.6-3.2-14.6-5.3c-2.2-1.4-3.6-3.2-4.2-5
			c-0.9-2.7-0.3-5.9,2-9.3c3-4.5,9-9.3,17-14.6c47.4-30.6,169.3-71,186.8-76.6c-17.4-5.6-139.3-46-186.8-76.6
			c-8-5.1-14-10.1-17-14.6c-2.2-3.4-2.9-6.4-2-9.3c0.7-1.8,2-3.5,4.2-5c3-2.1,7.8-3.8,14.6-5.3l0,0c0.9-0.2,1.8-0.5,2.8-0.8l0,0
			c7.4-3,40.2-15.1,102.8-22.8l0,0c0.3,0,0.7,0,0.9,0l0,0c4-0.2,32-2.2,55-11.5c7.6-3,14.6-7.1,20-11.9c5.3-4.8,9-10.6,10-17.6
			c0-0.3,0.1-0.5,0.1-0.8l0,0c0.5-3.2,5.9-37.8-19.9-53c-0.9-0.5-1.8-1-2.9-1.3c-10.4-3.4-80.7-25.8-131.1-49
			c-21.7-10.1-39.9-20.2-48.1-29c-3.7-4-5.4-7.9-4.9-11.4c0.5-3.4,3.4-6.6,9.2-9.1c1.3-0.6,2.6-1,4-1.1l0,0
			c14-1.9,120.5-17.5,168.7-40.7c8.2-4,14.6-8,18.6-12.3c2.8-3,4.3-6.1,4.3-9.3c0-3.2-1.7-6.6-5.3-9.9l0,0c-2.6-2.6-4.5-6.1-5.5-9.9
			c-1.2-4.6-2-10.7,0.1-15.1c0.9-1.8,2.2-3.2,4.3-4.2c-2.1-1-3.4-2.4-4.3-4.2c-2.1-4.3-1.4-10.3-0.1-15.1c1.1-3.8,3-7.4,5.5-9.9l0,0
			c3.6-3.4,5.3-6.7,5.3-9.9c0-3.2-1.6-6.3-4.3-9.3c-4.1-4.3-10.4-8.5-18.6-12.3c-48.2-23.2-154.6-38.6-168.7-40.7l0,0
			c-1.3-0.2-2.8-0.6-4-1.1c-5.8-2.6-8.6-5.8-9.2-9.1c-0.5-3.4,1.1-7.2,4.9-11.4c8.2-8.8,26.2-19.1,48.1-29
			c50.6-23.2,120.9-45.8,131.1-49c1.1-0.3,2-0.8,2.9-1.3c25.8-15.2,20.4-49.7,19.9-53l0,0c0-0.3-0.1-0.5-0.1-0.8
			c-1.1-7.1-4.7-12.8-10-17.5c-5.4-5-12.4-8.8-20-11.9c-22.9-9.5-51.1-11.4-55-11.5l0,0c-0.3,0-0.7,0-0.9,0l0,0
			c-62.7-7.7-95.5-19.7-102.8-22.8l0,0c-0.9-0.3-1.7-0.6-2.8-0.8c-0.7-0.2-0.7-0.2,0,0c-6.7-1.4-11.6-3.2-14.6-5.3
			c-2.2-1.4-3.6-3.2-4.2-5c-0.9-2.7-0.3-5.9,2-9.3c3-4.5,9-9.3,17-14.6c51.1-33.3,188.8-77.6,188.8-77.6l0.9-0.3l0.5,2.2l-0.9,0.3
			c0,0-137.5,44.2-188.4,77.1c-7.8,5-13.4,9.8-16.3,13.9c-1.7,2.4-2.4,4.6-1.6,6.7c0.5,1.4,1.6,2.7,3.4,3.8c2.9,1.9,7.5,3.7,14,5
			c1.1,0.2,2.1,0.5,3,1c7.4,2.9,40,14.9,102.4,22.6c0.3,0,0.5,0,0.9,0c4.7,0.3,42.5,3,66.6,17.1c10.5,6.3,18.6,14.6,20.1,26l0,0
			c0,0.3,0.1,0.5,0.1,0.8c0.5,3.5,6.1,39.6-20.9,55.6l0,0c-1.1,0.6-2.1,1.1-3.2,1.4c-10.4,3.4-80.6,25.8-131,49
			c-21.5,9.9-39.4,19.9-47.4,28.7c-1.6,1.8-2.8,3.4-3.6,5c-0.7,1.4-0.9,2.7-0.7,4.2c0.3,1.3,0.9,2.6,2.1,3.7
			c1.3,1.3,3.3,2.6,5.8,3.7l0,0c1.2,0.5,2.4,0.8,3.6,1c14.1,1.9,120.9,17.6,169.1,40.9c8.4,4,15,8.3,19.2,12.8c3.3,3.5,5,7.4,5,11.2
			c0,3.8-1.8,7.7-5.9,11.7c-2.4,2.2-4.1,5.4-5,8.8l0,0c-1.1,4.2-1.8,9.3,0,13c1.2,2.4,3.6,4,7.6,4.3v2.4h-0.1c-0.1,0-0.4,0-0.5,0
			c0.1,0,0.1,0.2,0.3,0.2c-3.8,0.3-6.1,1.9-7.2,4.3c-1.8,3.7-1.1,9,0,13l0,0c0.9,3.5,2.6,6.6,5,8.8c4.1,4,5.9,8,5.9,11.7
			c0.1,3.8-1.7,7.5-5,11.2c-4.1,4.5-10.8,8.8-19.2,12.8c-48.3,23.2-155.1,38.8-169.1,40.9c-1.2,0.2-2.4,0.5-3.6,1l0,0
			c-2.5,1.1-4.5,2.2-5.8,3.7c-1.2,1.1-1.8,2.4-2.1,3.7c-0.3,1.3,0.1,2.7,0.7,4c0.8,1.6,2,3.2,3.6,5c8,8.8,25.9,18.8,47.4,28.7
			c50.4,23.2,120.6,45.7,131,49c1.1,0.3,2.2,0.8,3.2,1.4l0,0c27,16,21.6,52.1,20.9,55.6c0,0.3-0.1,0.5-0.1,0.8l0,0
			c-1.6,11.2-9.6,19.7-20.1,26c-24.1,14.1-62,16.8-66.6,17.1c-0.3,0-0.5,0-0.9,0c-62.4,7.7-95.1,19.7-102.4,22.6c-0.9,0.5-2,0.8-3,1
			c-6.5,1.3-11.1,3-14,5c-1.7,1.1-2.9,2.4-3.4,3.8c-0.8,2.1,0,4.3,1.6,6.7c2.9,4.3,8.7,9,16.3,13.9c51.1,32.9,188.4,77.1,188.4,77.1
			c0.3,0.2,0.5,0.3,0.7,0.6c0.1,0,0.1,0.2,0.1,0.5v1c0,0.5-0.3,1-0.8,1.1c0,0-137.5,44.2-188.4,77.1c-7.8,5-13.4,9.6-16.3,13.9
			c-1.7,2.4-2.4,4.8-1.6,6.7c0.5,1.4,1.6,2.7,3.4,3.8c2.9,1.9,7.5,3.7,14,5c1.1,0.2,2.1,0.5,3,1c7.4,2.9,40,14.9,102.4,22.6
			c0.3,0,0.5,0,0.9,0c4.7,0.3,42.5,3,66.6,17.1c10.5,6.3,18.6,14.7,20.1,26l0,0c0,0.3,0.1,0.5,0.1,0.8c0.5,3.5,6.1,39.6-20.9,55.6
			l0,0c-1.1,0.6-2.1,1.1-3.2,1.4c-10.4,3.2-80.6,25.8-131,49c-21.5,9.9-39.4,19.9-47.4,28.7c-1.6,1.8-2.8,3.4-3.6,5
			c-0.7,1.4-0.9,2.7-0.7,4c0.3,1.3,0.9,2.6,2.1,3.7c1.3,1.3,3.3,2.6,5.8,3.7l0,0c1.2,0.5,2.4,0.8,3.6,1
			c14.1,1.9,120.9,17.6,169.1,40.9c8.4,4,15,8.3,19.2,12.8c3.3,3.5,5,7.4,5,11.2s-1.8,7.7-5.9,11.7c-2.4,2.4-4.1,5.4-5,8.8l0,0
			c-1.1,4.2-1.8,9.3,0,13c1.2,2.4,3.6,4,7.6,4.3v2.4h-0.1c-0.1,0-0.4,0-0.5,0C1836.5,1305.2,1836.5,1305.2,1836.6,1305.2
			L1836.6,1305.2z M1837,441.5c0,0,0.9-2.9,0.9-1.1C1838.1,441,1837.7,441.5,1837,441.5L1837,441.5L1837,441.5L1837,441.5z
			 M1837,441.5c-0.1,0-0.3,0-0.4,0C1836.7,441.5,1836.9,441.5,1837,441.5z M1837,1305.2c0,0,0.9-2.9,0.9-1.1
			C1838.1,1304.5,1837.7,1305,1837,1305.2L1837,1305.2C1837,1305.2,1837,1305,1837,1305.2L1837,1305.2z M1837,1305.2
			c-0.1,0-0.3,0-0.4,0C1836.7,1305.2,1836.9,1305.2,1837,1305.2z M477.8,399c-67.2-7.7-132.6-57.7-132.7-57.7l0.4,0.2l-0.5-0.2
			c-9.2-6.9-11.5-18.8-11.6-27.6l0,0c0-6.1-2.5-11.5-6.5-15.1c-2.9-2.6-4.2-5.1-4.2-7.7c0-1.6,0.4-3.2,1.3-4.8
			c1.2-2.2,3.3-4.3,6.2-6.6c28.6-21.6,134.6-42.3,147.2-44.7l0,0l1.1-0.5c21.6-9.1,35.4-16.8,43.6-23.2c5.1-4.2,8-7.7,9-10.7
			c0.7-2.4,0.3-4.5-1.1-6.3c-1.6-2.1-4.1-3.8-7.1-5.4c-15.3-7.5-44-9.1-44-9.1h-1.2v-0.3c-51.2,2.9-118.8-46.3-130.4-55.1
			c-1.3-1-2.5-2.2-3.6-3.7c-10.8-14.6-14.6-27.2-13.4-38.1c1.8-16.3,14.9-29,32.8-38.6c44-23.6,116.8-29,116.8-29h0.9l0.1,2.4H480
			c0,0-72.4,5.4-116.1,28.7c-8.7,4.6-16.2,9.9-21.7,16.2c-5.4,6.1-9.1,12.8-9.9,20.7c-1.2,10.4,2.6,22.3,12.9,36.2
			c0.9,1.3,2,2.2,3.2,3.2c11.6,8.8,78.6,57.7,129.4,54.7v-0.3h1.2c0,0,0.3,0,0.7,0h0.8v0.2c6.2,0.5,29.9,2.6,43.3,9.1
			c3.4,1.8,6.3,3.8,8,6.1c2,2.7,2.6,5.8,1.6,9.1c-1.1,3.5-4.1,7.7-9.9,12.2c-8.3,6.6-22.3,14.4-44.1,23.6l-1.2,0.5l-0.1-0.5
			c-10.8,2.1-118,22.6-146.8,44.4c-2.1,1.6-4,3.4-5,5c-1.1,1.4-1.6,2.9-1.6,4.3c0,1.9,1.2,3.8,3.4,5.8l0,0c4.5,4,7.2,10.3,7.2,17
			c0.1,8.2,2.1,19.2,10.7,25.6c0,0,0,0,0.1,0c3.4,2.6,66.9,49.8,131.8,57.4l0,0l1.1,0.2c0,0,31.7,3.7,46,13.5
			c4.1,2.9,6.8,6.3,7.2,10.1c0.3,2.4-0.3,5-2,7.9c-2.2,3.5-6.3,7.5-13,11.9c6.7,4.3,10.9,8.3,13,11.9c1.7,2.9,2.2,5.4,2,7.9
			c-0.4,3.8-3.2,7.4-7.2,10.3c-14.2,9.9-46,13.8-46,13.8l-1.1,0.2l0,0c-65.7,7.5-129.7,55.8-131.9,57.4l0,0
			c-8.6,6.4-10.5,17.3-10.7,25.6c-0.1,6.7-2.8,13-7.2,17l0,0c-2.2,1.9-3.4,3.8-3.4,5.8c0,1.4,0.5,2.9,1.6,4.3c1.2,1.6,2.9,3.2,5,5
			c28.7,21.8,136,42.3,146.8,44.4l0.1-0.5l1.2,0.5c21.9,9.3,35.8,17,44.1,23.6c5.8,4.6,8.8,8.7,9.9,12.2c1.1,3.5,0.4,6.6-1.6,9.1
			c-1.7,2.4-4.5,4.5-8,6.1c-13.4,6.6-37,8.8-43.3,9.1v0.2h-1.1c-0.1,0-0.1,0-0.3,0c-0.1,0-0.3,0-0.3,0h-1.2v0.5
			c-50.8-3.2-117.8,48.7-129.4,57.9c-1.2,1-2.1,2.1-3.2,3.4c-10.4,14.7-14.1,27.4-13,38.5c1.7,16.7,14.5,29.2,31.6,38.9
			c42,23.9,110.6,30,115.9,30.5c0.1,0,0.1,0,0.1,0l0,0c0.1,0,0.1,0,0.1,0l-0.1,0.2v2.2c0,0,1.1-2.9,1.1-1.1c0,0.3-0.1,0.6-0.3,1
			c-0.1,0.3-0.5,0.3-0.8,0.3c0,0,0,0-0.1,0c-5.3,0.5-73.9,6.4-115.9,29.8c-17.1,9.5-29.9,21.8-31.6,38c-1.2,10.7,2.6,23.2,12.9,37.5
			c0.9,1.3,2,2.4,3.2,3.2c11.6,9.1,78.6,59.8,129.4,56.6v-0.2h1.2c0,0,0.1,0,0.4,0c0,0,0,0,0.1,0h1.1v0.2c6.2,0.5,29.9,2.6,43.3,9.1
			c3.4,1.8,6.3,3.8,8,6.1c2,2.7,2.6,5.6,1.6,9.1s-4.1,7.7-9.9,12.2c-8.3,6.6-22.3,14.4-44.1,23.6l-1.2,0.5l-0.1-0.5
			c-10.8,2.1-118,22.6-146.8,44.4c-2.1,1.6-4,3.2-5,5c-1.1,1.4-1.6,2.9-1.6,4.3c0,1.9,1.2,3.8,3.4,5.8l0,0c4.5,4,7.2,10.3,7.2,17
			c0.1,8.3,2.1,19.2,10.7,25.6c0,0,0,0-0.1,0c0,0,0,0,0.1,0.2c3.4,2.6,66.9,49.8,131.8,57.4l0,0l1.1,0.2c0,0,31.7,3.7,46,13.3
			c4.1,2.9,6.8,6.3,7.2,10.1c0.3,2.4-0.3,5-2,7.9c-2.1,3.5-6.2,7.4-12.6,11.5c6.5,4.3,10.5,8.2,12.6,11.7c1.7,2.9,2.2,5.4,2,7.9
			c-0.4,3.8-3.2,7.4-7.2,10.3c-14.2,10.1-46,14.3-46,14.3l-1.1,0.2l0,0c-65.7,7.5-129.7,55.8-131.9,57.4l0,0
			c-8.6,6.4-10.5,17.3-10.7,25.6c-0.1,6.7-2.8,13-7.2,17l0,0c-2.2,1.9-3.4,3.8-3.4,5.8c0,1.4,0.5,2.9,1.6,4.3c1.2,1.6,2.9,3.2,5,5
			c28.7,21.8,136,42.3,146.8,44.4l0.1-0.5l1.2,0.5c21.9,9.3,35.8,17,44.1,23.6c5.8,4.6,8.8,8.7,9.9,12.2c1.1,3.5,0.4,6.6-1.6,9.1
			c-1.7,2.4-4.5,4.5-8,6.1c-13.4,6.7-37.1,8.8-43.3,9.1v0.2h-0.8c-0.5,0-0.7,0-0.7,0h-1.2v-0.3c-50.8-3-117.8,46-129.4,54.7
			c-1.2,1-2.2,1.9-3.2,3.2c-10.4,13.8-14.1,25.8-12.9,36.2c0.9,7.7,4.5,14.6,9.9,20.5c5.5,6.1,13.2,11.5,21.7,16.2
			c43.7,23.4,116.1,28.7,116.1,28.7h0.9l-0.1,2.4h-0.9c0,0-72.9-5.4-116.8-29c-17.9-9.6-30.9-22.3-32.8-38.6
			c-1.2-10.9,2.5-23.6,13.4-38.1c1.1-1.4,2.2-2.6,3.6-3.7c11.7-8.8,79.1-58,130.4-55.1v-0.3h1.2c0,0,28.7-1.6,44-9.1
			c3.2-1.6,5.7-3.4,7.1-5.4c1.3-1.8,1.8-3.8,1.1-6.3c-0.9-3.2-3.8-6.7-9-10.7c-8.2-6.6-22-14.1-43.6-23.2l-1.2-0.5l0,0
			c-12.6-2.4-118.5-23.1-147.1-44.6c-2.9-2.1-5-4.3-6.2-6.6c-0.9-1.6-1.3-3.2-1.3-4.8c0-2.6,1.3-5.1,4.2-7.7c4-3.5,6.5-9.1,6.5-15.1
			l0,0c0.1-9,2.4-20.7,11.6-27.6c-0.4,0.3-0.5,0.8-0.4,1.3c0.1,0.5,0.5,0.8,0.9,0.8l-0.4-2.2c0,0,65.6-50,132.7-57.7v-0.2l1.1-0.2
			c0,0,31.1-4,45.2-13.9c3.4-2.4,5.8-5.1,6.2-8.5c0.3-2.4-0.7-5-2.9-7.9c-2.5-3.2-6.5-6.6-12.5-10.3l0.4,0.2
			c-0.1-0.2-1.7-2.2-0.3-2.6c5.9-3.7,9.9-7.1,12.2-10.1c2.2-2.9,3.2-5.3,2.9-7.7c-0.4-3.2-2.8-5.8-6.2-8.2
			c-14-9.6-45.2-13.1-45.2-13.1l-1.1-0.2v-0.2c-67.2-7.7-132.6-57.7-132.7-57.7c0,0,0,0-0.1,0c-9.2-6.9-11.5-18.8-11.6-27.6l0,0
			c0-6.1-2.5-11.5-6.5-15.1c-2.9-2.6-4.2-5.1-4.2-7.7c0-1.6,0.4-3.2,1.3-4.8c1.2-2.2,3.3-4.3,6.2-6.6
			c28.6-21.6,134.6-42.3,147.2-44.6l0,0l1.1-0.5c21.6-9.1,35.4-16.8,43.6-23.2c5.1-4.2,8-7.7,9-10.7c0.7-2.4,0.3-4.5-1.1-6.3
			c-1.6-2.1-4.1-3.8-7.1-5.4c-15.3-7.5-44-9.1-44-9.1h-1.2v-0.3c-51.2,3-118.8-47.9-130.5-57.1l0,0c-1.3-1.1-2.5-2.2-3.6-3.7
			c-10.9-15.1-14.6-28.2-13.4-39.4c1.8-16.8,14.9-30,32.8-39.9c33-18.4,82.7-26.1,105.1-28.8c-22.4-2.7-72-10.6-105.1-29.5
			c-17.9-10.1-30.9-23.6-32.8-40.9c-1.2-11.5,2.5-25,13.4-40.4l0,0c1.1-1.4,2.2-2.7,3.4-3.8l0,0c11.6-9.3,79.1-61.4,130.5-58.3v-0.3
			h1.2c0,0,28.7-1.6,44-9.1c3.2-1.6,5.7-3.4,7.1-5.4c1.3-1.8,1.8-3.8,1.1-6.3c-0.9-3.2-3.8-6.7-9-10.7c-8.2-6.4-22-14.1-43.6-23.2
			l-1.2-0.5l0,0c-12.6-2.4-118.5-23.1-147.1-44.6c-2.9-2.2-5-4.3-6.2-6.6c-0.9-1.6-1.3-3.2-1.3-4.8c0-2.6,1.3-5.1,4.2-7.7
			c4-3.5,6.5-9.1,6.5-15.1l0,0c0.1-9,2.4-20.7,11.6-27.6l0.5,2.2l-0.4-2.2c0,0,65.6-50,132.7-57.7v-0.2l1.1-0.2
			c0,0,31.1-3.7,45.2-13.5c3.4-2.4,5.8-5.1,6.2-8.3c0.3-2.4-0.7-4.8-2.9-7.7c-2.5-3.2-6.5-6.6-12.5-10.3c-0.4-0.2-0.5-0.6-0.5-1.1
			v-0.6l1.1,1.3l-0.5-2.4c5.9-3.7,10-7.1,12.5-10.3c2.2-2.9,3.2-5.3,2.9-7.7c-0.4-3.2-2.8-5.8-6.2-8.2c-14-9.6-45.2-13.1-45.2-13.1
			L477.8,399L477.8,399L477.8,399z M479.6,900.5l87.2-24.7c-4,0-8.4-0.3-13.4-0.8c-36.3-3.4-97.4-18.4-136.8-42.2
			c-13.6-8.2-24.6-17.5-31.2-27.6c-4.5-6.9-6.8-14.3-6.7-22c0.3-12.7,7.6-26.4,24.9-41c0.9-0.8,1.8-1.3,2.8-1.8l0,0
			c6.7-3.2,39.4-17.8,71.4-20.8l0,0h1.1c0,0,0.1,0,0.5,0c0.1,0,0.4,0,0.5,0h1.1l0,0c10-0.5,53.3-3.4,78.7-11.5
			c7.2-2.4,13-5,16.1-8.3c1.7-1.9,2.5-3.8,1.8-6.1c-2-6.7-1.1-14.1,2.4-19.9c0.9-1.4,1.4-3,1.4-4.8c-0.1-2.9-1.7-6.1-5.5-9.8
			c-10.5-9.8-37.3-21.8-96.2-35.1v0.2l-0.7-0.3l-1.1-0.2v-0.2c-6.5-2.4-52.8-20.2-78.2-43.1c-5.9-5.3-10.8-11.1-13.7-16.7
			c-3-5.9-4.1-12.2-2.4-18.3c1.8-6.9,7.1-13.8,17.1-20.5c14.5-9.6,39-19.1,77.3-27.4v-0.2h0.7l1.1-0.2v0.2
			c7.6-0.6,71.9-5.9,91.4-17.6c1.7-1,3-2.1,4-3.2c0.8-1,1.2-1.8,1.2-2.9c0-0.8-0.4-1.8-1.2-2.7c-3.6-4.6-4.9-11.4-3.3-17.5
			c1.3-5.4,4.6-10.9,8.8-13.1c-4.2-2.2-7.6-7.5-8.8-13c-1.4-6.1-0.3-12.8,3.3-17.5c0.7-1,1.2-1.8,1.2-2.7s-0.4-1.9-1.2-2.9
			c-0.9-1.1-2.2-2.2-4-3.2c-19.5-11.5-83.7-17-91.4-17.6v0.2l-1.1-0.2h-0.7v-0.5c-38.3-8.3-62.8-17.6-77.3-27.4
			c-10-6.7-15.3-13.6-17.1-20.5c-1.7-6.1-0.7-12.3,2.4-18.3c2.9-5.8,7.8-11.4,13.7-16.7c25.4-22.8,71.8-40.5,78.2-43.1V257l1.1-0.2
			l0.7-0.3v0.2c59.1-13.3,85.8-25.3,96.2-35.1c4-3.7,5.5-6.9,5.5-9.8c0-1.8-0.5-3.5-1.4-5c-3.4-5.8-4.3-13.1-2.4-19.9
			c0.7-2.2-0.1-4.3-1.8-6.1c-3-3.4-8.8-6.1-16.1-8.3c-25.4-8.2-68.9-11.1-78.7-11.5l0,0h-0.1c-1.2,0-1.8-0.2-1.8-0.2h-1.1v-0.2
			c-32-3-64.6-17.8-71.4-21l0,0c-0.9-0.5-1.8-1.1-2.8-1.8c-16.1-12.2-22.5-23.7-22.8-34.1c-0.1-10.6,5.9-20,15.4-28.5
			c27.7-24.4,83.5-38.8,83.5-38.8l0.9-0.2l0,0L583.4,7.1l0.4,2.2L478.6,39.2l0,0c-9,2.4-56.5,16.2-81.2,38
			c-8.8,7.9-14.7,16.7-14.6,26.4c0.1,9.9,6.6,20.7,21.9,32.2l0,0c0.8,0.6,1.6,1.1,2.5,1.6c6.7,3.2,39,17.6,70.7,20.7l0,0h1.1
			c0,0,52.3,2.4,81.2,11.9c7.6,2.4,13.7,5.4,16.9,8.8c2.5,2.6,3.3,5.6,2.4,8.8l0,0c-1.7,5.9-1.1,12.5,2.1,17.5l0,0
			c1.4,2.4,2.2,5.1,1.6,8.3c-0.5,2.9-2.4,6.3-6.2,9.8c-10.7,9.9-37.8,22.3-98,35.7l-0.7,0.2c-6.1,2.2-52.4,20-77.7,42.6
			c-5.8,5.1-10.4,10.6-13.2,16c-2.8,5.3-3.7,10.7-2.2,16.2c1.7,6.4,6.8,12.8,16.2,19.1c14.5,9.6,39,18.9,77.2,27.2h0.3
			c0,0,71.9,5.4,92.8,18c2.4,1.4,4.1,2.9,5.1,4.5c0.8,1.3,1.2,2.6,1.1,3.8c0,1.3-0.5,2.7-1.7,4.2c-3.2,4.2-4.2,9.9-2.9,15.2l0,0
			c1.3,5.3,4.7,10.6,9.2,12c0.3,0,0.5,0.3,0.7,0.6c0.1,0.2,0.1,0.5,0.1,0.8v0.5c0,0.3,0,0.5-0.1,0.8c-0.1,0.3-0.4,0.5-0.7,0.6
			c-4.5,1.4-7.9,6.9-9.2,12.3l0,0c-1.3,5.3-0.1,11.1,2.9,15.2c1.1,1.4,1.6,2.9,1.7,4.2c0,1.3-0.3,2.6-1.1,3.8
			c-1.1,1.6-2.8,3-5.1,4.5c-20.9,12.5-92.8,18-92.8,18h-0.3c-38.2,8.3-62.7,17.6-77.2,27.2c-9.3,6.3-14.5,12.7-16.2,19.1
			c-1.4,5.4-0.5,10.9,2.2,16.2c2.8,5.6,7.5,10.9,13.2,16c25.3,22.8,71.6,40.4,77.7,42.6l0.7,0.2c60.2,13.5,87.3,25.8,98,35.7
			c3.8,3.5,5.5,6.9,6.2,9.8c0.7,3-0.1,5.9-1.6,8.3l0,0c-3,5.1-3.8,11.7-2.1,17.5l0,0c0.9,3.2,0,6.3-2.4,8.8
			c-3.2,3.5-9.2,6.4-16.9,8.8c-29.1,9.3-81.2,11.9-81.2,11.9h-0.5c-31.9,2.9-64.5,17.6-71.2,20.8c-0.8,0.5-1.7,0.8-2.4,1.4
			c-16.5,13.9-23.7,26.9-24,39.1c-0.3,9,3.4,17.5,9.7,25.2c6.6,8,15.8,15.4,27,22.1c39.1,23.6,99.9,38.5,136,41.8
			c9.2,0.8,16.9,1,22,0.3c3.2-0.5,5.4-1.1,6.5-2.2c-0.3,0.2-0.3,0.5-0.3,0.8l0.8-0.2l-0.4,0.6l0.7,0.8c0,0,1.1-2.9,1.1-1.3v1
			c0,0.5-0.3,1.1-0.8,1.1l-104.1,29.5v-0.2c-9.1,2.4-56.5,16.2-81.2,38c-8.8,7.9-14.7,16.7-14.6,26.4c0.1,9.9,6.6,20.7,21.9,32.2
			l0,0c0.8,0.6,1.6,1.1,2.5,1.6c6.7,3.2,39,17.6,70.7,20.8l0,0h1.1c0,0,52.3,2.4,81.2,11.9c7.6,2.4,13.7,5.4,16.9,8.8
			c2.5,2.7,3.3,5.6,2.4,8.8l0,0c-1.7,5.9-1.1,12.5,2.1,17.5l0,0c1.4,2.4,2.2,5.1,1.6,8.3c-0.5,2.9-2.4,6.3-6.2,9.8
			c-10.7,9.9-37.8,22.3-98,35.7l-0.7,0.2c-6.1,2.2-52.4,20-77.7,42.8c-5.8,5.1-10.4,10.6-13.2,16c-2.8,5.3-3.7,10.7-2.2,16.2
			c1.7,6.4,6.8,12.8,16.2,19.1c14.5,9.6,39,18.9,77.2,27.2h0.3c0,0,71.9,5.4,92.8,18c2.4,1.4,4.1,2.9,5.1,4.5
			c0.8,1.3,1.2,2.6,1.1,3.8c0,1.3-0.5,2.7-1.7,4.2c-3.2,4-4.2,9.9-2.9,15.2l0,0c1.3,5.3,4.7,10.9,9.2,12.3c0.3,0,0.5,0.3,0.7,0.6
			c0.1,0.2,0.1,0.5,0.1,0.8s0,0.5-0.1,0.8s-0.4,0.5-0.7,0.6c-4.5,1.4-7.9,7.2-9.2,12.7l0,0c-1.3,5.3-0.1,11.1,2.9,15.2
			c1.1,1.4,1.6,2.9,1.7,4.2c0,1.3-0.3,2.6-1.1,3.8c-1.1,1.6-2.8,3-5.1,4.5c-20.9,12.5-92.8,18-92.8,18h-0.3
			c-38.2,8.3-62.7,17.6-77.2,27.2c-9.3,6.3-14.5,12.7-16.2,19.1c-1.4,5.4-0.5,10.9,2.2,16.2c2.8,5.6,7.5,10.9,13.2,16
			c25.3,22.8,71.6,40.4,77.7,42.8l0.7,0.2c60.2,13.5,87.3,25.8,98,35.7c3.8,3.5,5.5,6.9,6.2,9.8c0.7,3-0.1,5.9-1.6,8.3l0,0
			c-3,5.1-3.8,11.7-2.1,17.5l0,0c0.9,3.2,0,6.3-2.4,8.8c-3.2,3.5-9.2,6.4-16.9,8.8c-29.1,9.3-81.2,11.9-81.2,11.9h-1.1l0,0
			c-31.7,3-64,17.6-70.7,20.7c-0.8,0.5-1.7,1-2.5,1.6l0,0c-15.3,11.5-21.6,22.3-21.9,32.2c-0.1,9.8,5.8,18.6,14.6,26.4
			c24.8,21.8,72.3,35.6,81.2,38l0,0l105.2,29.8l-0.4,2.2l-102.7-29l0,0l-0.9-0.3c0,0-55.8-14.3-83.5-38.8
			c-9.5-8.3-15.7-18-15.4-28.5c0.1-10.4,6.6-22,22.8-34.1c0.9-0.6,1.8-1.3,2.8-1.8l0,0c6.7-3.2,39.4-17.8,71.4-21v-0.2h1.1
			c0,0,0.7,0,1.8-0.2h0.1l0,0c9.9-0.5,53.3-3.4,78.7-11.5c7.2-2.2,13-5.1,16.1-8.3c1.7-1.9,2.5-3.8,1.8-6.1
			c-2-6.7-1.2-14.1,2.4-19.9c0.9-1.4,1.4-3,1.4-4.8c-0.1-2.9-1.7-6.1-5.5-9.8c-10.5-9.8-37.3-21.8-96.2-35.1v0.2l-0.7-0.3l-1.1-0.2
			v-0.2c-6.5-2.4-52.8-20.2-78.2-43.1c-5.9-5.3-10.8-10.9-13.7-16.8c-3-5.9-4.1-12.2-2.4-18.3c1.8-6.9,7.1-13.8,17.1-20.5
			c14.5-9.6,39-19.1,77.3-27.4v0.6h0.7l1.1-0.2v0.2c7.6-0.6,71.9-5.9,91.4-17.6c1.7-1,3-2.1,4-3.2c0.8-1,1.2-1.9,1.2-2.9
			c0-0.8-0.4-1.8-1.2-2.7c-3.6-4.6-4.9-11.4-3.3-17.5c1.2-5.3,4.3-10.7,8.4-13.3c-4.1-2.4-7.2-7.7-8.4-13
			c-1.4-6.1-0.3-12.8,3.3-17.5c0.7-1,1.1-1.9,1.2-2.7c0-1-0.4-1.9-1.2-2.9c-0.9-1.1-2.2-2.1-4-3.2c-19.6-11.5-83.7-17-91.4-17.6v0.2
			l-1.1-0.2h-0.7v-0.2c-38.3-8.3-62.8-17.6-77.3-27.4c-10-6.7-15.3-13.6-17.1-20.5c-1.7-6.1-0.7-12.3,2.4-18.3
			c2.9-5.8,7.8-11.4,13.7-16.8c25.4-22.8,71.8-40.5,78.2-43.1v-0.2l1.1-0.2l0.7-0.3v0.2c59.1-13.3,85.8-25.3,96.2-35.1
			c4-3.7,5.5-6.9,5.5-9.8c0-1.8-0.5-3.5-1.4-4.8c-3.4-5.8-4.3-13.1-2.4-19.9c0.7-2.2-0.1-4.3-1.8-6.1c-3-3.2-8.8-6.1-16.1-8.3
			c-25.4-8.2-68.9-11.1-78.7-11.5l0,0H481c-1.2,0-1.8-0.2-1.8-0.2h-1.1v-0.2c-32-3-64.6-17.8-71.4-20.8l0,0
			c-0.9-0.5-1.8-1.1-2.8-1.8c-16.1-12.2-22.5-23.7-22.8-34.1c-0.1-10.4,5.9-20,15.4-28.5C423,915.5,475.7,901.4,479.6,900.5
			L479.6,900.5z M958.3,133.9c-16.5-4.3-27-9.1-33.2-14.1c-4.5-3.5-6.8-7.4-7.6-11.1c-1.4-6.6,1.7-13.1,7.1-19.1
			c11.9-13,34.4-23.1,34.4-23.1l1.1-0.5v0.2L1106.4,7l0.7,2.2L960.6,68.4l0,0l-1.1,0.6c0,0-22,9.8-33.7,22.6
			c-4.7,5.1-7.8,10.7-6.5,16.3c0.7,3.2,2.9,6.4,6.8,9.6c6.2,5,16.9,9.8,33.3,13.9l1.1,0.3l0,0c10.8,0.8,91.6,7.4,130.1,25
			c7.5,3.5,13.4,7.4,17,11.7c2.5,3,3.8,6.4,3.7,9.9c-0.1,3.7-1.7,7.7-5.4,12c-4.7,5.6-6.1,14.3-3.2,21.6c1.4,3.5,2.1,7.7,0.9,11.9
			c-1.1,4.2-3.8,8.3-9.3,12.5c-0.8,0.6-1.7,1.1-2.5,1.6l0,0c-8.4,4.2-76.9,36.5-131.9,44.6h-0.1l0,0c0,0-21.3,11.5-32.7,25.6
			c-4.6,5.8-7.6,12-6.3,18c0.7,3.4,2.8,6.7,6.6,9.8c5.9,5,16.1,9.5,31.9,13h0.1c0,0,102.6,14.4,141.5,33.7c5.1,2.6,9.2,5.1,11.7,7.9
			c1.8,1.9,3,4,3.4,5.9c0.4,2.1,0,4.3-1.4,6.4c-1.8,2.9-5.7,5.8-11.9,8.7c-4.2,2.1-7.1,6.7-7.5,12.2s1.7,12.7,5.5,15.7
			c0.3,0.2,0.4,0.6,0.4,1v0.6c0,0.6-0.3,1-0.5,1.1h0.1c-3.8,2.9-5.9,9.5-5.5,15.1c0.4,5.4,3.3,10.1,7.5,12.2
			c6.2,2.9,10,5.8,11.9,8.7c1.4,2.2,1.8,4.3,1.4,6.4c-0.4,1.9-1.6,4-3.4,5.9c-2.6,2.7-6.6,5.3-11.7,7.9
			c-39,19.4-141.5,33.7-141.5,33.7h-0.1c-15.8,3.5-25.9,8-31.9,13c-3.8,3.2-5.9,6.4-6.6,9.8c-1.3,5.9,1.7,12.2,6.3,18
			c11.3,14.1,32.7,25.6,32.7,25.6l0,0h0.1c55,7.9,123.5,40.4,131.9,44.6l0,0c0.9,0.5,1.7,1,2.5,1.6c5.5,4,8.3,8.3,9.3,12.5
			c1.1,4.2,0.5,8.2-0.9,11.9c-2.9,7.2-1.6,16,3.2,21.6c3.6,4.3,5.3,8.3,5.4,12c0.1,3.5-1.2,6.9-3.7,9.9c-3.6,4.3-9.5,8.3-17,11.7
			c-38.3,17.6-119.3,24.2-130.1,25l0,0l-1.1,0.3c-16.5,4.2-27,9-33.3,13.9c-4,3.2-6.1,6.4-6.8,9.6c-1.3,5.6,1.8,11.2,6.5,16.3
			c11.7,12.8,33.7,22.6,33.7,22.6l1.1,0.5l0,0l145.5,58.8l-0.3,2.2h-0.3l-0.5-0.2c0.3,0.5,0.8,0.3,0.8,0.3s0.9-2.9,0.9-1.1
			c0,0.5-0.3,1-0.7,1.1l-145.5,58.8l0,0l-1.1,0.5c0,0-22,9.8-33.7,22.6c-4.7,5.1-7.8,10.7-6.5,16.3c0.7,3.2,2.9,6.4,6.8,9.6
			c6.2,5,16.9,9.8,33.3,13.9l1.1,0.3l0,0c10.8,0.8,91.6,7.4,130.1,25c7.5,3.5,13.4,7.4,17,11.7c2.5,3,3.8,6.4,3.7,9.9
			c-0.1,3.7-1.7,7.7-5.4,12c-4.7,5.6-6.1,14.3-3.2,21.6c1.4,3.5,2.1,7.7,0.9,11.9c-1.1,4-3.8,8.3-9.3,12.5c-0.8,0.6-1.7,1.1-2.5,1.6
			l0,0c-8.4,4.2-76.9,36.5-131.9,44.6h-0.1l0,0c0,0-21.3,11.5-32.7,25.6c-4.6,5.8-7.6,12-6.3,18c0.7,3.4,2.8,6.7,6.6,9.8
			c5.9,5,16.1,9.3,31.9,13h0.1c0,0,102.6,14.4,141.5,33.7c5.1,2.6,9.2,5.1,11.7,7.9c1.8,1.9,3,4,3.4,5.9c0.4,2.1,0,4.3-1.4,6.4
			c-1.8,2.9-5.7,5.8-11.9,8.7c-4.2,2.1-7.1,6.7-7.5,12.2c-0.4,5.4,1.7,12.7,5.5,15.7c0.3,0.2,0.4,0.6,0.4,1s-0.1,0.8-0.4,1
			c-3.8,2.9-5.9,10.1-5.5,15.7c0.4,5.4,3.3,10.1,7.5,12.2c6.2,2.9,10,5.9,11.9,8.7c1.4,2.1,1.8,4.3,1.4,6.4c-0.4,1.9-1.6,4-3.4,5.9
			c-2.6,2.7-6.6,5.3-11.7,7.9c-39,19.4-141.5,33.7-141.5,33.7h-0.1c-15.8,3.5-25.9,8-31.9,13c-3.8,3.2-5.9,6.4-6.6,9.8
			c-1.3,6.1,1.7,12.2,6.3,18c11.3,14.1,32.7,25.6,32.7,25.6l0,0h0.1c55,7.9,123.5,40.4,131.9,44.6l0,0c0.9,0.5,1.7,1,2.5,1.6
			c5.5,4,8.3,8.3,9.3,12.5c1.1,4.2,0.5,8.3-0.9,11.9c-2.9,7.4-1.6,16,3.2,21.6c3.6,4.3,5.3,8.2,5.4,12c0.1,3.5-1.2,6.9-3.7,9.9
			c-3.6,4.3-9.5,8.3-17,11.7c-38.3,17.6-119.3,24.2-130.1,25l0,0l-1.1,0.3c-16.5,4.2-27,9-33.3,13.9c-4,3.2-6.1,6.4-6.8,9.6
			c-1.3,5.6,1.8,11.2,6.5,16.3c11.7,12.8,33.7,22.6,33.7,22.6l1.1,0.5v0.2l146.4,59.1l-0.7,2.2l-146.5-59.3v0.2l-1.1-0.5
			c0,0-22.5-10.1-34.4-23.1c-5.4-5.9-8.6-12.5-7.1-19.1c0.8-3.7,3.2-7.5,7.6-11.1c6.3-5.1,16.9-9.9,33.2-14.1v-0.2h0.9l0.9-0.3v0.2
			c9.5-0.6,91.2-7.2,129.8-24.8c7.2-3.4,12.9-7.1,16.2-11.2c2.1-2.6,3.2-5.3,3.2-8.2c-0.1-3.2-1.6-6.6-4.7-10.3l0,0
			c-5.4-6.4-6.8-16.2-3.6-24.4l0,0c1.2-3,1.8-6.4,0.9-10.1c-0.9-3.7-3.6-7.5-8.6-11.1l0,0c-0.7-0.5-1.4-1-2.2-1.3
			c-8.4-4.2-76.5-36.4-131.1-44.2l-0.1,0.2l-0.5-0.3h-0.5v-0.2c-2.2-1.3-22.1-12.5-33-26.1c-5.1-6.4-8.3-13.5-6.8-20.2
			c0.8-3.8,3-7.7,7.4-11.2c6.1-5,16.2-9.6,32-13.1l0,0l0.7-0.2h0.1l0.9-0.2v0.2c10.1-1.4,103.5-15.2,140.1-33.3
			c4.9-2.4,8.7-5,11.2-7.5c1.4-1.6,2.5-3,2.8-4.6c0.4-1.9-0.3-3.7-2-5.6c-2-2.2-5.3-4.5-10.1-6.7l0,0c-4.9-2.4-8.2-7.9-8.7-14.1l0,0
			c-0.4-5.8,1.6-13.1,5.3-16.8c-3.7-3.8-5.8-11.1-5.3-16.8l0,0c0.5-6.4,3.8-11.9,8.7-14.1l0,0c4.7-2.2,8-4.5,10.1-6.7
			c1.7-1.9,2.4-3.8,2-5.6c-0.3-1.6-1.3-3.2-2.8-4.6c-2.5-2.6-6.3-5.1-11.2-7.5c-36.6-18.1-130-31.9-140.1-33.3v0.2l-0.9-0.2h-0.1
			l-0.7-0.2l0,0c-15.8-3.7-25.9-8.2-32-13.1c-4.3-3.5-6.6-7.4-7.4-11.2c-1.4-6.7,1.7-13.8,6.8-20.2c10.9-13.6,30.8-24.8,33-26.1
			v-0.2h0.5l0.5-0.3l0.1,0.2c54.8-7.9,122.8-40.2,131.1-44.4c0.8-0.3,1.6-0.8,2.2-1.3l0,0c5-3.7,7.5-7.5,8.6-11.2
			c0.9-3.5,0.4-7.1-0.9-10.1l0,0c-3.2-8.2-1.7-18,3.6-24.4l0,0c3.2-3.7,4.6-7.1,4.7-10.4c0.1-2.9-1.1-5.6-3.2-8.2
			c-3.3-4.2-9.1-7.9-16.2-11.2c-38.6-17.6-120.2-24.2-129.8-24.8v0.2l-0.9-0.3h-0.9v-0.2c-16.5-4.3-27-9.1-33.2-14.1
			c-4.5-3.5-6.8-7.4-7.6-11.1c-1.4-6.6,1.7-13.1,7.1-19.1c11.9-13,34.4-23.1,34.4-23.1l1.1-0.5v0.2l142.9-57.7l-142.9-57.7v0.2
			l-1.1-0.5c0,0-22.5-10.1-34.4-23.1c-5.4-5.9-8.6-12.5-7.1-19.1c0.8-3.7,3.2-7.5,7.6-11.1c6.3-5,16.9-9.9,33.2-14.1v-0.2h0.9
			l0.9-0.2v0.2c9.5-0.6,91.2-7.2,129.8-24.8c7.2-3.4,12.9-7.1,16.2-11.2c2.1-2.6,3.2-5.3,3.2-8.2c-0.1-3.2-1.6-6.6-4.7-10.4l0,0
			c-5.4-6.4-6.8-16.2-3.6-24.4l0,0c1.2-3,1.8-6.4,0.9-10.1c-0.9-3.7-3.6-7.5-8.6-11.2l0,0c-0.7-0.5-1.4-1-2.2-1.4
			c-8.4-4-76.5-36.4-131.1-44.2l-0.1,0.2l-0.5-0.3h-0.5v-0.2c-2.2-1.3-22.1-12.5-33-26.1c-5.1-6.4-8.3-13.5-6.8-20.2
			c0.8-3.8,3-7.7,7.4-11.2c6.1-5,16.2-9.6,32-13.1l0,0l0.7-0.2h0.1l0.9-0.2v0.2c10.1-1.4,103.5-15.2,140.1-33.5
			c4.9-2.4,8.7-5,11.2-7.5c1.4-1.6,2.5-3,2.8-4.6c0.4-1.9-0.3-3.7-2-5.6c-2-2.2-5.3-4.5-10.1-6.7l0,0c-4.9-2.4-8.2-7.9-8.7-14.1l0,0
			c-0.4-5.9,1.7-13,5.7-16.5c-4-3.7-6.1-11.2-5.7-17.1l0,0c0.5-6.4,3.8-11.9,8.7-14.1l0,0c4.7-2.2,8-4.5,10.1-6.7
			c1.7-1.9,2.4-3.8,2-5.8c-0.3-1.6-1.3-3.2-2.8-4.6c-2.5-2.6-6.3-5-11.2-7.5c-36.6-18.3-130-31.9-140.1-33.5v0.2l-0.9-0.2h-0.1
			l-0.7-0.2l0,0c-15.8-3.7-25.9-8.2-32-13.1c-4.3-3.5-6.6-7.4-7.4-11.2c-1.4-6.7,1.7-13.8,6.8-20.2c10.9-13.6,30.8-24.8,33-26.1
			v-0.2h0.5l0.5-0.3l0.1,0.2c54.8-7.9,122.8-40.2,131.1-44.2c0.8-0.3,1.6-0.8,2.2-1.4l0,0c5-3.7,7.5-7.4,8.6-11.2
			c0.9-3.5,0.4-7.1-0.9-10.1l0,0c-3.2-8.2-1.7-18,3.6-24.4l0,0c3.2-3.7,4.6-7.1,4.7-10.3c0.1-2.9-1.1-5.6-3.2-8.2
			c-3.3-4.2-9.1-7.9-16.2-11.2c-38.6-17.6-120.2-24.2-129.8-24.8v0.2l-0.9-0.2h-0.9L958.3,133.9L958.3,133.9z M1437.3,399
			c-67.2-7.7-132.6-57.7-132.7-57.7l0.4,0.2l-0.5-0.2c-9.2-6.9-11.5-18.8-11.6-27.6l0,0c0-6.1-2.4-11.5-6.5-15.1
			c-2.9-2.6-4.2-5.1-4.3-7.7c0-1.6,0.4-3.2,1.3-4.8c1.2-2.2,3.3-4.3,6.2-6.6c28.6-21.6,134.6-42.3,147.2-44.7l0,0l1.1-0.5
			c21.6-9.1,35.4-16.8,43.6-23.2c5.1-4.2,8-7.7,9-10.7c0.8-2.4,0.3-4.5-1.1-6.3c-1.6-2.1-4.1-3.8-7.1-5.4c-15.3-7.5-44-9.1-44-9.1
			h-1.2v-0.3c-51.2,2.9-118.8-46.3-130.4-55.1c-1.3-1-2.5-2.2-3.6-3.7c-10.8-14.6-14.6-27.2-13.4-38.1c1.8-16.3,14.9-29,32.8-38.6
			c44-23.6,116.8-29,116.8-29h0.9l0.1,2.4h-0.9c0,0-72.4,5.4-116.1,28.7c-8.7,4.6-16.2,9.9-21.7,16.2c-5.5,6.1-9.1,12.8-9.9,20.7
			c-1.2,10.4,2.5,22.3,12.9,36.2c0.9,1.3,2,2.2,3.2,3.2c11.6,8.8,78.6,57.7,129.4,54.7v-0.3h1.2c0,0,0.3,0,0.8,0h0.8v0.2
			c6.2,0.5,29.9,2.6,43.3,9.1c3.6,1.8,6.3,3.8,8,6.1c2,2.7,2.6,5.8,1.6,9.1c-1.1,3.5-4.1,7.7-9.9,12.2c-8.3,6.6-22.3,14.4-44.1,23.6
			l-1.1,0.5l-0.1-0.5c-10.8,2.1-118,22.6-146.8,44.4c-2.2,1.6-3.8,3.4-5,5c-1.1,1.4-1.6,2.9-1.6,4.3c0,1.9,1.2,3.8,3.4,5.8l0,0
			c4.5,4,7.2,10.3,7.2,17c0.1,8.2,2.1,19.2,10.7,25.6c0,0,0,0,0.1,0c3.6,2.6,66.9,49.8,131.8,57.4l0,0l1.1,0.2c0,0,31.7,3.7,46,13.5
			c4.1,2.9,6.8,6.3,7.2,10.1c0.3,2.4-0.3,5-2,7.9c-2.2,3.5-6.3,7.5-13,11.9c6.7,4.3,10.8,8.3,13,11.9c1.7,2.9,2.2,5.4,2,7.9
			c-0.4,3.8-3.2,7.4-7.2,10.3c-14.2,9.9-46,13.8-46,13.8l-1.1,0.2l0,0c-65.7,7.5-129.7,55.8-131.9,57.4l0,0
			c-8.6,6.4-10.5,17.3-10.7,25.6c-0.1,6.7-2.8,13-7.2,17l0,0c-2.2,1.9-3.4,3.8-3.4,5.8c0,1.4,0.5,2.9,1.6,4.3c1.2,1.6,2.9,3.2,5,5
			c28.7,21.8,136,42.3,146.8,44.4l0.1-0.5l1.1,0.5c21.9,9.3,35.8,17,44.1,23.6c5.8,4.6,8.8,8.7,9.9,12.2c1.1,3.5,0.4,6.6-1.6,9.1
			c-1.7,2.4-4.5,4.5-8,6.1c-13.4,6.6-37.1,8.8-43.3,9.1v0.2h-1.1c-0.1,0-0.1,0-0.3,0c-0.1,0-0.3,0-0.3,0h-1.2v0.5
			c-50.8-3.2-118,48.7-129.4,57.9c-1.2,1-2.2,2.1-3,3.4c-10.4,14.7-14.1,27.4-12.9,38.5c1.7,16.5,14.5,29.2,31.6,38.9
			c42,23.9,110.6,30,115.9,30.5c0.1,0,0.1,0,0.1,0l0,0c0.1,0,0.1,0,0.1,0l-0.1,0.2v2.2c0,0,1.1-2.9,1.1-1.1c0,0.3-0.1,0.6-0.3,1
			c-0.1,0.3-0.5,0.3-0.8,0.3h-0.1c-0.9,0-3.7,0.3-7.9,0.8c-22.3,2.4-84.4,11.2-118,35.3c-10.9,7.9-18.8,17.3-21.2,28.7
			c-2.2,11.5,1.1,24.8,12.5,40.5c0.9,1.3,2,2.4,3.2,3.2c11.6,9.1,78.6,59.8,129.4,56.6v-0.2h1.2c0,0,0.1,0,0.4,0h0.1h1.1v0.2
			c6.2,0.5,29.9,2.6,43.3,9.1c3.6,1.8,6.3,3.8,8,6.1c2,2.7,2.6,5.6,1.6,9.1c-1.1,3.5-4.1,7.7-9.9,12.2c-8.3,6.6-22.3,14.4-44.1,23.6
			l-1.1,0.5l-0.1-0.5c-10.8,2.1-118,22.6-146.8,44.4c-2.2,1.6-3.8,3.2-5,5c-1.1,1.4-1.6,2.9-1.6,4.3c0,1.9,1.2,3.8,3.4,5.8l0,0
			c4.5,4,7.2,10.3,7.2,17c0.1,8.3,2.1,19.2,10.7,25.6c0,0,0,0-0.1,0c0,0,0,0,0.1,0.2c3.6,2.6,66.9,49.8,131.8,57.4l0,0l1.1,0.2
			c0,0,31.7,4,46,14.3c4.1,2.9,6.8,6.4,7.2,10.3c0.3,2.4-0.3,5.1-2,7.9c-2.1,3.5-6.2,7.4-12.6,11.7c6.5,4.2,10.5,8,12.6,11.5
			c1.7,2.7,2.2,5.4,2,7.9c-0.4,3.8-3.2,7.2-7.2,10.1c-14.2,9.8-46,13.5-46,13.5l-1.1,0.2l0,0c-65.7,7.5-129.7,55.8-131.9,57.4l0,0
			c-8.6,6.4-10.5,17.3-10.7,25.6c-0.1,6.7-2.8,13-7.2,17l0,0c-2.2,1.9-3.4,3.8-3.4,5.8c0,1.4,0.5,2.9,1.6,4.3c1.2,1.6,2.9,3.2,5,5
			c28.7,21.8,136,42.3,146.8,44.4l0.1-0.5l1.1,0.5c21.9,9.3,35.8,17,44.1,23.6c5.8,4.6,8.8,8.7,9.9,12.2c1.1,3.5,0.4,6.6-1.6,9.1
			c-1.7,2.4-4.5,4.5-8,6.1c-13.4,6.7-37.1,8.8-43.3,9.1v0.2h-0.8c-0.5,0-0.8,0-0.8,0h-1.2v-0.3c-50.8-3-117.8,46-129.4,54.7
			c-1.2,1-2.2,1.9-3.2,3.2c-10.4,13.8-14.1,25.8-12.9,36.2c0.9,7.7,4.5,14.6,9.9,20.5c5.5,6.1,13,11.5,21.7,16.2
			c43.7,23.4,116.1,28.7,116.1,28.7h0.9l-0.1,2.4h-0.9c0,0-72.8-5.4-116.8-29c-17.9-9.6-30.9-22.3-32.8-38.6
			c-1.2-10.9,2.5-23.6,13.4-38.1c1.1-1.4,2.2-2.6,3.6-3.7c11.7-8.8,79.1-58,130.4-55.1v-0.3h1.2c0,0,28.7-1.6,44-9.1
			c3-1.6,5.7-3.4,7.1-5.4c1.3-1.8,1.8-3.8,1.1-6.3c-0.9-3.2-3.8-6.7-9-10.7c-8.2-6.6-22-14.1-43.6-23.2l-1.1-0.5l0,0
			c-12.6-2.4-118.5-23.1-147.1-44.6c-2.9-2.1-5-4.3-6.2-6.6c-0.9-1.6-1.3-3.2-1.3-4.8c0-2.6,1.3-5.1,4.3-7.7c4-3.5,6.3-9.1,6.5-15.1
			l0,0c0.1-9,2.4-20.7,11.6-27.6c-0.4,0.3-0.5,0.8-0.4,1.3c0.1,0.5,0.5,0.8,0.9,0.8l-0.4-2.2c0,0,65.6-50,132.7-57.7v-0.2l1.1-0.2
			c0,0,31.1-3.5,45.2-13.1c3.4-2.4,5.8-5,6.2-8.2c0.3-2.4-0.7-4.8-2.9-7.7c-2.5-3.2-6.5-6.6-12.5-10.3c-0.4-0.2-0.5-0.6-0.5-1.1
			s0.3-1,0.5-1.1c5.9-3.7,10-7.1,12.5-10.3c2.2-2.9,3.2-5.4,2.9-7.9c-0.4-3.2-2.8-5.9-6.2-8.3c-14-9.9-45.2-13.9-45.2-13.9l-1.1-0.2
			v-0.2c-67.2-7.7-132.6-57.7-132.7-57.7c0,0,0,0-0.1,0c-9.2-6.9-11.5-18.8-11.6-27.6l0,0c0-6.1-2.4-11.5-6.5-15.1
			c-2.9-2.6-4.2-5.1-4.3-7.7c0-1.6,0.4-3.2,1.3-4.8c1.2-2.2,3.3-4.3,6.2-6.6c28.6-21.6,134.6-42.3,147.2-44.6l0,0l1.1-0.5
			c21.6-9.1,35.4-16.8,43.6-23.2c5.1-4.2,8-7.7,9-10.7c0.8-2.4,0.3-4.5-1.1-6.3c-1.6-2.1-4.1-3.8-7.1-5.4c-15.3-7.5-44-9.1-44-9.1
			h-1.2v-0.3c-51.2,3-118.8-47.9-130.5-57.1l0,0c-1.3-1.1-2.5-2.2-3.6-3.7c-12-16.5-15.3-30.8-12.9-42.8c2.4-12,10.5-22.1,22.1-30.3
			c32.3-22.9,90.3-32.2,115.2-35.3c-22.5-2.7-72-10.6-105.1-29.5c-17.9-10.1-30.9-23.6-32.8-40.9c-1.2-11.5,2.5-25,13.4-40.4l0,0
			c1.1-1.4,2.2-2.7,3.6-3.8l0,0c11.6-9.3,79.3-61.4,130.5-58.3V700h1.2c0,0,28.7-1.6,44-9.1c3-1.6,5.7-3.4,7.1-5.4
			c1.3-1.8,1.8-3.8,1.1-6.3c-0.9-3.2-3.8-6.7-9-10.7c-8.2-6.4-22-14.1-43.6-23.2l-1.1-0.5l0,0c-12.6-2.4-118.5-23.1-147.1-44.6
			c-2.9-2.2-5-4.3-6.2-6.6c-0.9-1.6-1.3-3.2-1.3-4.8c0-2.6,1.3-5.1,4.3-7.7c4-3.5,6.3-9.1,6.5-15.1l0,0c0.1-9,2.4-20.7,11.6-27.6
			l0.5,2.2l-0.4-2.2c0,0,65.6-50,132.7-57.7v-0.2l1.1-0.2c0,0,31.1-3.7,45-13.5c3.4-2.4,5.8-5.1,6.2-8.3c0.3-2.4-0.7-4.8-2.9-7.7
			c-2.5-3.2-6.5-6.6-12.5-10.3c-0.4-0.2-0.7-0.8-0.5-1.3v-0.5l1.1,1.4l-0.5-2.4c5.9-3.7,10-7.1,12.5-10.3c2.2-2.9,3.2-5.3,2.9-7.7
			c-0.4-3.2-2.8-5.8-6.2-8.2c-14-9.6-45.2-13.1-45.2-13.1L1437.3,399L1437.3,399L1437.3,399z M1437.4,160.8
			c-32-3-64.6-17.8-71.4-20.8l0,0c-0.9-0.5-1.8-1.1-2.8-1.8c-16.1-12.2-22.5-23.7-22.8-34.1c-0.1-10.4,5.9-20,15.4-28.5
			c27.7-24.4,83.5-38.8,83.5-38.8l0.9-0.2l0,0l102.7-29l0.4,2.2l-105.1,29.8l0,0c-9,2.4-56.5,16.2-81.2,38
			c-8.8,7.9-14.7,16.7-14.6,26.4c0.1,9.9,6.5,20.7,21.9,32.2l0,0c0.8,0.6,1.6,1.1,2.5,1.6c6.7,3.2,39,17.6,70.7,20.7l0,0h1.1
			c0,0,52.3,2.4,81.2,11.9c7.6,2.4,13.7,5.4,16.9,8.8c2.4,2.6,3.3,5.6,2.4,8.8l0,0c-1.7,5.9-1.1,12.5,2.1,17.5l0,0
			c1.4,2.4,2.2,5.1,1.6,8.3c-0.5,2.9-2.4,6.3-6.2,9.8c-10.7,9.9-37.8,22.3-98,35.7l-0.7,0.2c-6.1,2.2-52.4,20-77.7,42.6
			c-5.7,5.1-10.4,10.6-13.2,16c-2.8,5.3-3.7,10.7-2.2,16.2c1.7,6.4,6.8,12.8,16.2,19.1c14.5,9.6,39,18.9,77.2,27.2h0.3
			c0,0,71.9,5.4,92.8,18c2.4,1.4,4.1,2.9,5.1,4.5c0.8,1.3,1.2,2.6,1.1,3.8c0,1.3-0.5,2.7-1.7,4.2c-3,4.2-4.2,9.9-2.9,15.2l0,0
			c1.3,5.4,4.7,11.2,9.2,12.7c0.4,0.2,0.8,0.6,0.8,1.1c0,0.5-0.3,1-0.8,1.1c-4.5,1.4-7.9,7.2-9.2,12.7l0,0
			c-1.3,5.3-0.1,11.1,2.9,15.2c1.1,1.4,1.6,2.9,1.7,4.2c0,1.3-0.3,2.6-1.1,3.8c-1.1,1.6-2.8,3-5.1,4.5c-20.9,12.5-92.8,18-92.8,18
			h-0.3c-38.3,8.3-62.8,17.6-77.2,27.2c-9.3,6.3-14.5,12.7-16.2,19.1c-1.4,5.4-0.5,10.9,2.2,16.2c2.8,5.6,7.5,10.9,13.2,16
			c25.3,22.8,71.6,40.4,77.7,42.6l0.7,0.2c60.2,13.5,87.3,25.8,98,35.7c3.8,3.5,5.5,6.9,6.2,9.8c0.5,3-0.1,5.9-1.6,8.3l0,0
			c-3,5.1-3.8,11.7-2.1,17.5l0,0c0.9,3.2,0.1,6.3-2.4,8.8c-3.2,3.5-9.2,6.4-16.9,8.8c-29.1,9.3-81.2,11.9-81.2,11.9h-1.1l0,0
			c-31.7,3-64,17.6-70.7,20.7c-0.9,0.5-1.7,1-2.5,1.6l0,0c-15.3,11.5-21.6,22.3-21.9,32.2c-0.1,9.8,5.8,18.6,14.6,26.4
			c24.8,21.8,72.3,35.4,81.2,38l0,0l104.1,29.5c0.3,0,0.5,0.3,0.7,0.6c0,0,0.1,0.2,0.1,0.5v1c0,0.5-0.3,1-0.8,1.1l-104.1,29.5l0,0
			c-9,2.4-56.5,16-81.2,38c-8.8,7.9-14.7,16.7-14.6,26.4c0.1,9.9,6.5,20.7,21.9,32.2l0,0c0.8,0.6,1.6,1.1,2.5,1.6
			c6.7,3.2,39,17.6,70.7,20.8l0,0h1.1c0,0,52.3,2.4,81.2,11.9c7.6,2.4,13.7,5.4,16.9,8.8c2.4,2.7,3.3,5.6,2.4,8.8l0,0
			c-1.7,5.9-1.1,12.5,2.1,17.5l0,0c1.4,2.4,2.2,5.1,1.6,8.3c-0.5,2.9-2.4,6.3-6.2,9.8c-10.7,9.9-37.8,22.3-98,35.7l-0.7,0.2
			c-6.1,2.2-52.4,20-77.7,42.8c-5.7,5.1-10.4,10.6-13.2,16c-2.8,5.3-3.7,10.7-2.2,16.2c1.7,6.4,6.8,12.8,16.2,19.1
			c14.5,9.6,39,18.9,77.2,27.2h0.3c0,0,71.9,5.4,92.8,18c2.4,1.4,4.1,2.9,5.1,4.5c0.8,1.3,1.2,2.6,1.1,3.8c0,1.3-0.5,2.7-1.7,4.2
			c-3,4-4.2,9.9-2.9,15.2l0,0c1.3,5.3,4.7,10.7,9.2,12.2c0.3,0,0.5,0.3,0.7,0.6c0.1,0.3,0.1,0.5,0.1,0.8v0.2c0,0.3,0,0.5-0.1,0.8
			c-0.1,0.3-0.4,0.5-0.7,0.6c-4.5,1.4-7.9,7.2-9.2,12.7l0,0c-1.3,5.3-0.1,11.1,2.9,15.2c1.1,1.4,1.6,2.9,1.7,4.2
			c0,1.3-0.3,2.6-1.1,3.8c-1.1,1.6-2.8,3-5.1,4.5c-20.9,12.5-92.8,18-92.8,18h-0.3c-38.3,8.3-62.8,17.6-77.2,27.2
			c-9.3,6.3-14.5,12.7-16.2,19.1c-1.4,5.4-0.5,10.9,2.2,16.2c2.8,5.6,7.5,10.9,13.2,16c25.3,22.8,71.6,40.4,77.7,42.8l0.7,0.2
			c60.2,13.5,87.3,25.8,98,35.7c3.8,3.5,5.5,6.9,6.2,9.8c0.5,3-0.1,5.9-1.6,8.3l0,0c-3,5.1-3.8,11.7-2.1,17.5l0,0
			c0.9,3.2,0.1,6.3-2.4,8.8c-3.2,3.5-9.2,6.4-16.9,8.8c-29.1,9.3-81.2,11.9-81.2,11.9h-1.1l0,0c-31.7,3-64,17.6-70.7,20.7
			c-0.9,0.5-1.7,1-2.5,1.6l0,0c-15.3,11.5-21.6,22.3-21.9,32.2c-0.1,9.8,5.8,18.6,14.6,26.4c24.8,21.8,72.3,35.6,81.2,38l0,0
			l105.2,29.8l-0.4,2.2l-102.7-29l0,0l-0.9-0.3c0,0-55.8-14.3-83.5-38.8c-9.5-8.3-15.7-18-15.4-28.5c0.1-10.4,6.6-22,22.8-34.1
			c0.9-0.6,1.8-1.3,2.8-1.8l0,0c6.7-3.2,39.4-17.8,71.4-21v-0.2h1.1c0,0,0.7,0,1.8-0.2h0.3l0,0c9.9-0.5,53.3-3.4,78.7-11.5
			c7.2-2.2,13-5.1,16.1-8.3c1.7-1.9,2.5-3.8,1.8-6.1c-2-6.7-1.1-14.1,2.4-19.9c0.9-1.4,1.4-3,1.4-4.8c-0.1-2.9-1.7-6.1-5.5-9.8
			c-10.5-9.8-37.3-21.8-96.4-35.1v0.2l-0.7-0.3l-1.1-0.2v-0.2c-6.5-2.4-52.8-20.2-78.2-43.1c-5.9-5.3-10.8-10.9-13.7-16.8
			c-3-5.9-4.1-12.2-2.4-18.3c1.8-6.9,7.1-13.8,17.1-20.5c14.5-9.6,39-19.1,77.3-27.4v0h0.7l1.1-0.2v0.2c7.6-0.6,71.9-5.9,91.4-17.6
			c1.7-1,3-2.1,4-3.2c0.8-1,1.2-1.9,1.2-2.9c0-0.8-0.4-1.8-1.2-2.7c-3.6-4.6-4.9-11.4-3.4-17.5c1.3-5.3,4.5-10.9,8.6-13.3
			c-4.1-2.4-7.4-7.5-8.6-12.8c-1.4-6.1-0.3-12.8,3.4-17.5c0.8-1,1.1-1.9,1.2-2.7c0-1-0.4-1.9-1.2-2.9c-0.9-1.1-2.2-2.1-4-3.2
			c-19.5-11.5-83.7-17-91.4-17.6v0.2l-1.1-0.2h-0.7v-0.2c-38.3-8.3-62.8-17.6-77.3-27.4c-10-6.7-15.3-13.6-17.1-20.5
			c-1.7-6.1-0.7-12.3,2.4-18.3c2.9-5.8,7.8-11.4,13.7-16.8c25.4-22.8,71.6-40.5,78.2-43.1v-0.2l1.1-0.2l0.7-0.3v0.2
			c59.1-13.3,85.8-25.3,96.4-35.1c3.9-3.7,5.5-6.9,5.5-9.8c0-1.8-0.5-3.5-1.4-4.8c-3.4-5.8-4.3-13.1-2.4-19.9
			c0.7-2.2-0.1-4.3-1.8-6.1c-3-3.2-8.8-6.1-16.1-8.3c-25.4-8.2-68.9-11.1-78.7-11.5l0,0h-0.3c-1.2,0-1.8-0.2-1.8-0.2h-1.1v-0.2
			c-32-3-64.6-17.8-71.4-20.8l0,0c-0.9-0.5-1.8-1.1-2.8-1.8c-16.1-12.2-22.5-23.7-22.8-34.1c-0.1-10.4,5.9-20,15.4-28.5
			c27.7-24.4,83.5-38.8,83.5-38.8l0.9-0.3l0,0l99.3-28l-99.3-28l0,0l-0.9-0.3c0,0-55.8-14.4-83.5-38.8c-9.5-8.3-15.7-18-15.4-28.5
			c0.1-10.4,6.6-22,22.8-34.1c0.9-0.6,1.8-1.3,2.8-1.8l0,0c6.7-3.2,39.4-17.8,71.4-20.8v-0.2h1.1c0,0,0.7,0,1.8-0.2h0.3l0,0
			c9.9-0.5,53.3-3.4,78.7-11.5c7.2-2.4,13-5,16.1-8.3c1.7-1.9,2.5-3.8,1.8-6.1c-2-6.7-1.1-14.1,2.4-19.9c0.9-1.4,1.4-3,1.4-4.8
			c-0.1-2.9-1.7-6.1-5.5-9.8c-10.5-9.8-37.3-21.8-96.4-35.1v0.2l-0.7-0.3l-1.1-0.2v-0.2c-6.5-2.4-52.8-20.2-78.2-43.1
			c-5.9-5.3-10.8-11.1-13.7-16.7c-3-5.9-4.1-12.2-2.4-18.3c1.8-6.9,7.1-13.8,17.1-20.5c14.5-9.6,39-19.1,77.3-27.4v-0.2h0.7l1.1-0.2
			v0.2c7.6-0.6,71.9-5.9,91.4-17.6c1.7-1,3-2.1,4-3.2c0.8-1,1.2-1.8,1.2-2.9c0-0.8-0.4-1.8-1.2-2.7c-3.6-4.6-4.9-11.4-3.4-17.5
			c1.2-5.1,4.2-10.6,8.2-13.1c-4-2.6-7-8-8.2-13.1c-1.4-6.1-0.3-12.8,3.4-17.5c0.8-1,1.1-1.8,1.2-2.7c0-1-0.4-1.9-1.2-2.9
			c-0.9-1.1-2.2-2.2-4-3.2c-19.5-11.5-83.7-17-91.4-17.6v0.2l-1.1-0.2h-0.7v0.3c-38.3-8.3-62.8-17.6-77.3-27.4
			c-10-6.7-15.3-13.6-17.1-20.5c-1.7-6.1-0.7-12.3,2.4-18.3c2.9-5.8,7.8-11.4,13.7-16.7c25.4-22.8,71.6-40.5,78.2-43.1V257l1.1-0.2
			l0.7-0.3v0.2c59.1-13.3,85.8-25.3,96.4-35.1c3.9-3.7,5.5-6.9,5.5-9.8c0-1.8-0.5-3.5-1.4-5c-3.4-5.8-4.3-13.1-2.4-19.9
			c0.7-2.2-0.1-4.3-1.8-6.1c-3-3.4-8.8-6.1-16.1-8.3c-25.4-8.2-68.9-11.1-78.7-11.5l0,0h-0.3c-1.2,0-1.8-0.2-1.8-0.2H1437.4
			L1437.4,160.8L1437.4,160.8z M477.8,127.2c-15.7-3.5-25.8-7.4-31.9-11.5c-4.5-3-6.8-6.3-7.6-9.3c-0.8-2.9-0.3-5.8,1.1-8.5
			c1.3-2.6,3.4-5.1,6.1-7.5c9.7-8.8,26.5-16,32.3-18.4l0,0L636.9,7.6l0.7,2.2L481.4,73v0.2l-0.9,0.3c0,0-22.1,8.2-33.7,18.9
			c-2.4,2.2-4.3,4.5-5.5,6.9c-1.1,2.2-1.4,4.3-0.9,6.6c0.7,2.7,2.9,5.3,6.7,8c6.1,4,16.1,7.9,31.5,11.4h0.7c0,0,91.9,7.2,132.5,29.5
			c6.3,3.5,11.6,7.4,14.9,11.5c2.4,3,3.8,6.1,4.3,9.5c0.9,5.4-0.9,11.5-6.3,18.3c-4.6,5.8-5.7,14.4-2.6,21.6l0,0
			c1.7,4.2,2.8,9,1.8,14.1c-0.9,5-3.7,10.3-9.5,15.4c-0.8,0.8-1.7,1.4-2.6,1.9c-2.9,1.6-12.4,6.7-25.8,13.3
			c52.1-22.1,150.9-33,162.1-34.1c0.7,0,1.3-0.2,2-0.3c14.5-3.8,23.7-7.7,29.2-11.4c3.4-2.4,5.3-4.6,6.1-6.9
			c1.1-3.2-0.3-6.1-2.5-8.5c-3.7-4.2-9.7-7.4-12.8-9c-1.1-0.5-2.2-0.8-3.3-1l0,0c-121.1-20.5-152.5-37.2-159.8-42.6c0,0,0,0-0.1-0.2
			c-0.8-0.8-1.6-1.4-2.5-1.8l0,0c-32-15.4-43.6-54.3-45.4-60.9l0,0c-0.1-0.6-0.3-1.1-0.5-1.8c-2.9-8.8-0.7-16.7,5.5-23.7
			c5.9-6.7,15.4-12.7,27.1-17.8c53.9-24,154.4-32.2,154.4-32.2l0.9-0.2l0.1,2.4h-0.9c0,0-100.2,8.2-153.9,32.1
			C580,47.7,570.8,53.4,565,59.9c-5.5,6.3-7.6,13.3-5,21.2l0,0c0.1,0.6,0.4,1.3,0.5,1.9c1.7,6.4,13,44.4,44.2,59.5
			c1.1,0.5,2.1,1.3,3,2.1c7.4,5.4,38.8,22,159.1,42.3c1.3,0.2,2.5,0.6,3.8,1.1l0,0c3.2,1.6,9.5,5,13.4,9.5c2.9,3.2,4.3,7.1,2.9,11.2
			c-0.9,2.6-3,5.3-7,8c-5.5,3.8-14.9,7.9-29.8,11.7l0,0c-0.7,0.2-1.4,0.3-2.2,0.3c-11.7,1.3-118.6,13-168.4,37
			c-8.4,4-15.1,8.5-19.5,13.3c-3,3.4-4.9,7.1-5.1,10.7c-0.1,3.8,1.3,7.9,4.6,12l0,0c2,2.6,3.3,5.6,4.1,9c1.2,5.1,2.9,12.8,4.1,18.4
			c0.9,4,2.9,7.7,5.7,10.1c17.5,15.2,85.1,67.2,192.1,68.1c2.5,0,5,0.6,7.2,1.9l0,0c3.2,1.8,7.4,4.6,10,8.2c2,2.6,2.9,5.6,2.2,8.8
			c-0.4,2.1-1.6,4.2-3.8,6.4c-2.8,2.6-7.1,5.4-13.7,8c6.6,2.7,10.9,5.4,13.7,8c2.2,2.2,3.4,4.3,3.8,6.4c0.7,3.2-0.4,6.1-2.2,8.8
			c-2.6,3.5-6.8,6.4-10,8.2l0,0c-2.2,1.3-4.7,1.9-7.2,1.9c-107,1-174.6,52.9-192.1,68.1c-2.8,2.6-4.7,6.1-5.7,10.1
			c-1.2,5.6-2.9,13.3-4.1,18.4c-0.8,3.4-2.1,6.4-4.1,9l0,0c-3.3,4.2-4.7,8.2-4.6,12c0.1,3.8,2,7.4,5.1,10.7
			c4.3,4.8,11.1,9.3,19.5,13.3c49.6,24,156.7,35.7,168.4,37c0.8,0,1.4,0.2,2.2,0.3l0,0c14.7,3.8,24.2,7.9,29.8,11.7
			c4,2.7,6.1,5.4,7,8c1.4,4-0.1,7.9-2.9,11.2c-3.8,4.5-10.1,7.9-13.4,9.5l0,0c-1.2,0.5-2.5,1-3.8,1.1
			c-120.2,20.4-151.7,36.7-159.1,42.3c-0.9,0.8-2,1.6-3,2.1c-31.2,15.1-42.5,53-44.2,59.5c-0.1,0.6-0.4,1.3-0.5,1.9l0,0
			c-2.6,7.9-0.4,14.7,5,21.2c5.8,6.6,15,12.3,26.5,17.3c53.7,23.9,153.9,32.1,153.9,32.1c0.4,0,0.7,0.3,0.8,0.6c0,0,0.1,0.2,0.1,0.5
			v1c0,0.6-0.4,1.1-0.9,1.1c0,0-100.2,8.2-153.9,32.1c-11.5,5.1-20.7,10.9-26.5,17.3c-5.5,6.3-7.6,13.3-5,21.2l0,0
			c0.1,0.6,0.4,1.3,0.5,1.9c1.7,6.4,13,44.4,44.2,59.5c1.1,0.5,2.1,1.3,3,2.1c7.4,5.4,38.8,22,159.1,42.3c1.3,0.2,2.5,0.6,3.8,1.1
			l0,0c3.2,1.6,9.5,5,13.4,9.5c2.9,3.2,4.3,7.1,2.9,11.2c-0.9,2.6-3,5.3-7,8c-5.5,3.8-14.9,7.9-29.8,11.7l0,0
			c-0.7,0.2-1.4,0.3-2.2,0.3c-11.7,1.3-118.6,13-168.4,37c-8.4,4-15.1,8.5-19.5,13.3c-3,3.4-4.9,7.1-5.1,10.7
			c-0.1,3.8,1.3,7.9,4.6,12l0,0c2,2.6,3.3,5.6,4.1,9c1.2,5.1,2.9,12.8,4.1,18.4c0.9,4,2.9,7.7,5.7,10.1
			c17.5,15.2,85.1,67.2,192.1,68.1c2.5,0,5,0.6,7.2,1.9l0,0c3.2,1.8,7.4,4.6,10,8.2c2,2.6,2.9,5.6,2.2,8.8c-0.4,2.1-1.6,4.2-3.8,6.4
			c-2.8,2.7-7.1,5.3-13.7,8c6.6,2.7,10.9,5.4,13.7,8c2.2,2.1,3.4,4.3,3.8,6.4c0.7,3.2-0.4,6.1-2.2,8.8c-2.6,3.5-6.8,6.4-10,8.2l0,0
			c-2.2,1.3-4.7,1.9-7.2,1.9c-107,1-174.6,52.9-192.1,68.1c-2.8,2.6-4.7,6.1-5.7,10.1c-1.2,5.6-2.9,13.3-4.1,18.4
			c-0.8,3.4-2.1,6.4-4.1,9l0,0c-3.3,4.2-4.7,8.2-4.6,12s2,7.4,5.1,10.7c4.3,4.8,11.1,9.3,19.5,13.3c49.6,24,156.7,35.7,168.4,37
			c0.8,0,1.4,0.2,2.2,0.3l0,0c14.7,3.8,24.2,7.9,29.8,11.7c4,2.7,6.1,5.4,7,8c1.4,4-0.1,7.9-2.9,11.2c-3.8,4.5-10.1,7.9-13.4,9.5
			l0,0c-1.2,0.5-2.5,1-3.8,1.1c-120.2,20.4-151.7,36.7-159.1,42.2c-0.9,1-2,1.6-3,2.1c-31.2,15.1-42.5,53-44.2,59.5
			c-0.1,0.6-0.4,1.3-0.5,1.9l0,0c-2.6,7.9-0.4,14.7,5,21.2c5.8,6.6,15,12.3,26.5,17.3c53.7,23.9,153.9,32.1,153.9,32.1l0.9,0.2
			l-0.1,2.4l-0.9-0.2c0,0-100.5-8.2-154.4-32.2c-11.7-5.1-21.2-11.1-27.1-18c-6.2-7.1-8.4-14.9-5.5-23.7c0.1-0.5,0.4-1.1,0.5-1.8
			l0,0c1.7-6.6,13.3-45.5,45.4-60.9l0,0c0.9-0.5,1.7-1.1,2.5-1.8c0,0,0,0,0.1-0.2c7.4-5.4,38.7-22.1,159.8-42.6l0,0
			c1.2-0.2,2.2-0.5,3.3-1c3-1.4,9.1-4.6,12.8-9c2.2-2.6,3.6-5.4,2.5-8.5c-0.8-2.2-2.6-4.5-6.1-6.9c-5.5-3.8-14.6-7.7-29.2-11.4
			c-0.7-0.2-1.3-0.3-2-0.3c-11.2-1.1-109.9-12-162.1-34.1c13.4,6.6,22.9,11.7,25.8,13.3c0.9,0.5,1.8,1.1,2.6,1.9
			c5.9,5,8.7,10.3,9.5,15.4c0.9,5.1-0.1,9.9-1.8,14.1l0,0c-3,7.2-2,15.9,2.6,21.6c5.4,6.6,7.1,12.7,6.3,18.3c-0.5,3.4-2,6.6-4.3,9.5
			c-3.4,4.3-8.6,8.2-14.9,11.5c-40.7,22.1-132.5,29.5-132.5,29.5h-0.7c-15.5,3.5-25.5,7.2-31.5,11.4c-4,2.6-6.1,5.3-6.7,8
			c-0.7,2.2-0.1,4.5,0.9,6.6c1.2,2.4,3.2,4.6,5.5,6.9c11.7,10.7,33.7,18.9,33.7,18.9l0.9,0.3v0.2l156.2,63.1l-0.7,2.2l-159.2-64.7
			v-0.2c-5.8-2.4-22.5-9.6-32.3-18.4c-2.6-2.4-4.7-5-6.1-7.5c-1.4-2.9-1.8-5.6-1.1-8.5c0.8-3,3.2-6.3,7.6-9.3
			c6.1-4.2,16.2-8,31.9-11.5v-0.2l1.1-0.2c0,0,0.1,0,0.5,0c0.1,0,0.3,0,0.5-0.2l0.9-0.2v0.2c13.6-1.3,93-9,129.8-29
			c5.1-2.7,9.3-5.8,12.5-9.1c3-3,5-6.4,5.5-10.1c0.8-4.8-1.1-10.1-5.8-15.9l0,0c-5.3-6.6-6.5-16.5-3-24.5c1.6-3.7,2.5-8,1.7-12.5
			c-0.8-4.5-3.3-9.3-8.7-13.8c-0.8-0.6-1.6-1.3-2.4-1.6c-8.7-4.8-76.5-41.7-131-50.8l0,0l-0.3-0.2l-1.1-0.2v-0.2
			c-2.8-1-22.3-7.4-32.9-16c-2.6-2.1-4.6-4.3-5.9-6.6c-1.4-2.6-1.8-5.1-1.1-7.7c0.8-2.7,3-5.6,7.4-8.5c6.1-3.8,16.2-7.7,32-11.4
			v-0.3l1.1-0.2c0,0,102-15.2,140.9-35.6c5.8-3,10.3-6.3,12.5-9.5c1.3-1.9,2-3.8,1.6-5.8c-0.3-1.3-1.1-2.7-2.2-4
			c-2-2.2-5.1-4.5-9.7-6.9l0,0c-5-2.6-8.6-8.3-9.1-15.1l0,0c-0.5-6.1,1.6-13.3,5.5-17.3c-3.8-4-5.9-11.4-5.5-17.3l0,0
			c0.5-6.7,4-12.5,9.1-15.1l0,0c4.6-2.2,7.8-4.6,9.7-6.9c1.2-1.4,2-2.7,2.2-4c0.4-1.9-0.3-3.8-1.6-5.8c-2.4-3.2-6.7-6.4-12.5-9.5
			c-38.8-20.5-140.9-35.6-140.9-35.6l-1.1-0.2v-0.3c-15.8-3.5-25.9-7.5-32-11.4c-4.3-2.9-6.6-5.8-7.4-8.5c-0.8-2.6-0.3-5.3,1.1-7.7
			c1.3-2.2,3.3-4.5,5.9-6.6c10.7-8.7,30.2-15.2,32.9-16v-0.3l1.1-0.2h0.3l0,0c54.6-9.1,122.5-46,131-50.8c0.8-0.5,1.6-1,2.4-1.6
			c5.4-4.5,7.9-9.3,8.7-13.8c0.8-4.5-0.1-8.8-1.7-12.5c-3.4-8.2-2.2-18,3-24.5l0,0c4.7-5.8,6.5-11.1,5.8-16
			c-0.5-3.7-2.5-7.1-5.5-10.1c-3.2-3.2-7.4-6.3-12.5-9.1c-36.9-20-116.1-27.9-129.8-29v0.2l-0.9-0.2c-0.1,0-0.3,0-0.5-0.2
			c-0.4,0-0.5,0-0.5,0h-1.1v-0.2c-15.7-3.5-25.8-7.4-31.9-11.5c-4.5-3-6.8-6.3-7.6-9.3c-0.8-2.9-0.3-5.8,1.1-8.5
			c1.3-2.6,3.4-5.1,6.1-7.5c9.7-8.8,26.5-16,32.3-18.4l0,0L634.2,872l-156.6-63.3l0,0c-5.8-2.4-22.5-9.6-32.3-18.4
			c-2.6-2.4-4.7-5-6.1-7.5c-1.4-2.9-1.8-5.6-1.1-8.5c0.8-3,3.2-6.3,7.6-9.3c6.1-4.2,16.2-8,31.9-11.5v-0.2h1.1c0,0,0.1,0,0.5,0
			c0.1,0,0.3,0,0.5-0.2l0.9-0.2v0.2c13.6-1.3,93-9,129.8-29c5.1-2.7,9.3-5.8,12.5-9.1c3-3.2,5-6.4,5.5-10.1
			c0.8-4.8-1.1-10.1-5.7-15.9l0,0c-5.3-6.6-6.5-16.5-3-24.5c1.6-3.7,2.5-8,1.7-12.5c-0.8-4.5-3.3-9.3-8.7-13.8
			c-0.8-0.6-1.6-1.3-2.4-1.6c-8.7-4.8-76.5-41.7-131-50.8l0,0h-0.3l-1.1-0.2v-0.2c-2.8-1-22.3-7.4-32.9-16c-2.6-2.1-4.6-4.3-5.9-6.6
			c-1.4-2.6-1.8-5.1-1.1-7.7c0.8-2.7,3-5.6,7.4-8.5c6.1-3.8,16.2-7.7,32-11.4v-0.2l1.1-0.2c0,0,102-15.2,140.9-35.6
			c5.8-3,10.3-6.3,12.5-9.5c1.3-1.9,2-3.8,1.6-5.8c-0.3-1.3-1.1-2.7-2.2-4c-2-2.2-5.1-4.5-9.7-6.9l0,0c-5-2.6-8.6-8.3-9.1-15.1l0,0
			c-0.5-6.3,1.8-13.6,5.9-17.5c-4.1-3.8-6.5-11.1-5.9-17.3l0,0c0.5-6.6,4-12.5,9.1-15.1l0,0c4.6-2.2,7.8-4.6,9.7-6.9
			c1.2-1.4,2-2.7,2.2-4c0.4-1.9-0.3-3.8-1.6-5.8c-2.4-3.2-6.7-6.4-12.5-9.5c-38.8-20.5-140.9-35.6-140.9-35.6l-1.1-0.2v-0.2
			c-15.8-3.5-25.9-7.5-32-11.4c-4.3-2.9-6.6-5.8-7.4-8.5c-0.8-2.6-0.3-5.3,1.1-7.7c1.3-2.2,3.3-4.5,5.9-6.6
			c10.7-8.7,30.2-15.2,32.9-16v-0.2l1.1-0.2h0.3l0,0c54.6-9,122.5-46,131-50.8c0.8-0.5,1.6-1,2.4-1.6c5.4-4.5,7.9-9.3,8.7-13.8
			c0.8-4.5-0.1-8.8-1.7-12.5c-3.4-8.2-2.1-18,3-24.5l0,0c4.7-5.8,6.5-11.1,5.7-16c-0.5-3.7-2.5-7.1-5.5-10.1
			c-3.2-3.2-7.4-6.3-12.5-9.1c-36.9-20.2-116.1-27.9-129.8-29v0.2l-0.9-0.2c-0.1,0-0.3,0-0.5-0.2c-0.4,0-0.5,0-0.5,0L477.8,127.2
			v-0.3V127.2z M574.3,265.3c-26.6,12.3-62.9,27.1-94.8,32.2l-1.1,0.2l0,0c-3.4,1.1-21.9,7.4-32.1,15.7c-2.4,1.9-4.2,3.8-5.4,5.9
			c-1.1,1.8-1.4,3.7-0.8,5.6c0.7,2.4,2.8,4.8,6.6,7.2c6.1,3.8,16.3,7.7,32.3,11.4l0.9,0.2l0,0c10,1.6,103.6,16.2,140.5,35.7
			c6.2,3.2,10.8,6.7,13.3,10.1c1.8,2.6,2.5,5.3,2,8c-0.4,1.8-1.2,3.5-2.8,5.3c-2.1,2.4-5.5,5-10.3,7.4c-4.3,2.2-7.4,7.2-7.8,13
			c-0.4,5.8,1.7,12.5,5.7,15.7c0.3,0.2,0.4,0.6,0.4,1v0.8c0,0.6-0.3,1-0.5,1.1h0.1c-4,3.2-6.2,9.9-5.7,15.7c0.4,5.8,3.4,10.7,7.8,13
			c4.9,2.4,8.2,5,10.3,7.4c1.6,1.8,2.4,3.5,2.8,5.3c0.5,2.7-0.1,5.3-2,8c-2.5,3.5-7.1,6.9-13.3,10.1
			c-36.9,19.4-130.5,34.1-140.5,35.7l0,0l-0.9,0c-15.9,3.5-26.2,7.4-32.3,11.2c-3.8,2.4-5.8,4.8-6.6,7.2c-0.5,1.9-0.1,3.8,0.8,5.6
			c1.2,2.1,3,4,5.4,5.9c10.3,8.3,28.7,14.6,32.1,15.7l0,0l1.1,0.2c31.9,5.3,68.2,19.9,94.8,32.2c-6.6-3.5-11.9-7.4-15.5-11.4
			c-3.6-3.8-5.5-8-5.7-12.3c-0.3-4.3,1.3-9,5.1-13.8c1.7-2.2,3-5,3.7-8l0,0c1.2-5.1,2.9-12.8,4.1-18.4l0,0c1.1-4.6,3.3-8.7,6.3-11.4
			l0,0c17.6-15.2,85.5-67.5,193.2-68.4c2.2,0,4.3-0.6,6.5-1.8c2.9-1.6,7-4.3,9.3-7.7c1.4-1.9,2.4-4.2,1.8-6.6
			c-0.4-1.6-1.3-3.4-3.2-5.1c-2.9-2.9-7.9-5.8-15.8-8.7h0.1c-0.5,0-0.9-0.5-0.9-1v-0.2c0-0.5,0.3-1,0.7-1.1
			c7.9-2.9,12.9-5.9,15.9-8.8c1.8-1.8,2.8-3.4,3.2-5.1c0.5-2.4-0.4-4.6-1.8-6.6c-2.4-3.4-6.5-5.9-9.3-7.7c-2-1.1-4.2-1.8-6.5-1.8
			c-107.7-1-175.6-53.2-193.2-68.4l0,0c-3.2-2.9-5.4-6.9-6.3-11.4l0,0c-1.2-5.4-2.9-13.3-4.1-18.4l0,0c-0.7-3-2-5.8-3.7-8
			c-3.8-4.8-5.3-9.5-5.1-13.8c0.3-4.3,2.2-8.5,5.7-12.3C562.5,272.7,567.7,269,574.3,265.3L574.3,265.3z M574.3,1478.7
			c-6.6-3.5-11.9-7.4-15.5-11.4c-3.6-3.8-5.5-8-5.7-12.3c-0.3-4.3,1.3-9,5.1-13.8c1.7-2.2,3-5,3.7-8l0,0c1.2-5.1,2.9-12.8,4.1-18.4
			l0,0c1.1-4.5,3.3-8.7,6.3-11.4l0,0c17.6-15.2,85.5-67.5,193.2-68.4c2.2,0,4.3-0.6,6.5-1.8c2.9-1.6,7-4.3,9.3-7.7
			c1.4-1.9,2.4-4.2,1.8-6.6c-0.4-1.6-1.3-3.4-3.2-5.1c-2.9-2.9-7.9-5.8-15.8-8.7h0.1c-0.5,0-0.9-0.5-0.9-1v-0.2c0-0.5,0.3-1,0.7-1.1
			c7.9-2.9,12.9-5.9,15.9-8.8c1.8-1.8,2.8-3.5,3.2-5.1c0.5-2.4-0.4-4.6-1.8-6.6c-2.4-3.4-6.5-5.9-9.3-7.7c-2-1.1-4.2-1.8-6.5-1.8
			c-107.7-1-175.6-53.2-193.2-68.4l0,0c-3.2-2.9-5.4-6.9-6.3-11.4l0,0c-1.2-5.4-2.9-13.3-4.1-18.4l0,0c-0.7-3-2-5.8-3.7-8
			c-3.8-4.8-5.3-9.5-5.1-13.8c0.3-4.3,2.2-8.5,5.7-12.3c3.7-4,9-7.9,15.5-11.4c-26.6,12.3-62.9,27.1-94.8,32.2l-1.1,0.2l0,0
			c-3.4,1.1-21.9,7.4-32.1,15.7c-2.4,1.9-4.2,3.8-5.4,5.9c-1.1,1.8-1.4,3.7-0.8,5.6c0.7,2.4,2.8,4.8,6.6,7.2
			c6.1,3.8,16.3,7.7,32.3,11.2l0.9,0.2l0,0c10,1.6,103.6,16.3,140.5,35.7c6.2,3.2,10.8,6.7,13.3,10.1c1.8,2.6,2.5,5.3,2,8
			c-0.4,1.8-1.2,3.5-2.8,5.3c-2.1,2.4-5.5,4.8-10.3,7.4c-4.3,2.2-7.4,7.2-7.8,13c-0.4,5.8,1.7,13,5.7,16.2c0.3,0.2,0.4,0.6,0.4,1
			c0,0.3-0.1,0.8-0.4,1c-4,3.2-6.2,10.4-5.7,16.2c0.4,5.8,3.4,10.7,7.8,13c4.9,2.4,8.2,5,10.3,7.4c1.6,1.8,2.4,3.5,2.8,5.3
			c0.5,2.7-0.1,5.3-2,8c-2.5,3.5-7.1,6.9-13.3,10.1c-36.9,19.4-130.5,34.1-140.5,35.7l0,0l-0.9,0.2c-15.9,3.5-26.2,7.4-32.3,11.2
			c-3.8,2.4-5.8,4.8-6.6,7.2c-0.5,1.9-0.1,3.8,0.8,5.6c1.2,2.1,3,4,5.4,5.9c10.3,8.3,28.7,14.6,32.1,15.7l0,0l1.1,0.2
			C511.4,1451.8,547.7,1466.6,574.3,1478.7L574.3,1478.7z M585.6,1123.6c52.1-22.1,150.9-32.9,162.1-34.1c0.7,0,1.3-0.2,2-0.3
			c14.5-3.7,23.7-7.7,29.2-11.4c3.4-2.4,5.3-4.6,6.1-6.9c1.1-3.2-0.3-6.1-2.5-8.5c-3.7-4.2-9.7-7.4-12.8-9c-1.1-0.5-2.2-0.8-3.3-1
			l0,0c-121.1-20.5-152.5-37.2-159.8-42.6c0,0,0,0-0.1-0.2c-0.8-0.8-1.6-1.4-2.5-1.8l0,0c-32-15.4-43.6-54.3-45.4-60.9l0,0
			c-0.1-0.6-0.3-1.1-0.5-1.8c-2.9-8.8-0.7-16.7,5.5-23.7c5.9-6.7,15.4-12.7,27.1-18c45-20,122.5-29,146.8-31.6
			c-24.4-2.4-101.8-11.5-146.8-31.6c-11.7-5.1-21.2-11.2-27.1-17.8c-6.2-7.1-8.4-14.9-5.5-23.7c0.1-0.5,0.4-1.1,0.5-1.8l0,0
			c1.7-6.6,13.4-45.5,45.4-60.9l0,0c0.9-0.5,1.7-1.1,2.5-1.8c0,0,0,0,0.1-0.2c7.4-5.4,38.7-22.1,159.8-42.6l0,0
			c1.2-0.2,2.2-0.5,3.3-1c3-1.4,9.1-4.6,12.8-9c2.2-2.6,3.6-5.4,2.5-8.5c-0.8-2.2-2.6-4.5-6.1-6.9c-5.5-3.8-14.6-7.7-29.2-11.4
			c-0.7-0.2-1.3-0.3-2-0.3c-11.2-1.1-109.9-12-162.1-34.1c13.4,6.6,22.9,11.7,25.8,13.3c0.9,0.5,1.8,1.1,2.6,1.9
			c5.9,5,8.7,10.3,9.5,15.4c0.9,5.1-0.1,9.9-1.8,14.1l0,0c-3,7.2-2,15.9,2.6,21.6c5.4,6.6,7.1,12.7,6.3,18.3c-0.5,3.4-2,6.4-4.3,9.5
			c-3.4,4.3-8.6,8.2-14.9,11.5C570.9,748.2,479,755.6,479,755.6h-0.7c-15.5,3.5-25.5,7.2-31.5,11.4c-4,2.6-6.1,5.3-6.7,8
			c-0.7,2.2-0.1,4.5,0.9,6.6c1.2,2.4,3.2,4.6,5.5,6.9c11.7,10.7,33.7,18.9,33.7,18.9l0.9,0.3v0.2l155.2,62.8
			c0.3,0.2,0.4,0.3,0.5,0.6c0,0,0.1,0.2,0.1,0.5v1c0,0.5-0.3,1-0.7,1.1l-155.2,62.8v0.2l-0.9,0.3c0,0-22.1,8.2-33.7,18.9
			c-2.4,2.2-4.3,4.5-5.5,6.9c-1.1,2.1-1.4,4.3-0.9,6.6c0.7,2.7,2.9,5.3,6.7,8c6.1,4,16.1,7.9,31.5,11.4h0.7c0,0,91.9,7.2,132.5,29.5
			c6.3,3.5,11.6,7.4,14.9,11.5c2.4,2.9,3.8,6.1,4.3,9.5c0.9,5.4-0.9,11.5-6.3,18.3c-4.6,5.8-5.7,14.4-2.6,21.6l0,0
			c1.7,4.2,2.8,9,1.8,14.1c-0.9,5-3.7,10.3-9.5,15.4c-0.8,0.8-1.7,1.3-2.6,1.9C608.5,1111.9,599.1,1117,585.6,1123.6L585.6,1123.6z
			 M764.2,441.5c-0.3,0-0.9,0.2-0.9-1.3c0,0.5,0.3,1,0.7,1.1C764,441.5,764,441.5,764.2,441.5L764.2,441.5z M764.2,1305.2
			c-0.3,0-0.9,0.2-0.9-1.3c0,0.5,0.3,1,0.7,1.1C764,1305,764,1305.2,764.2,1305.2L764.2,1305.2z M1437.3,127.2
			c-15.7-3.5-25.8-7.4-31.9-11.5c-4.5-3-6.8-6.3-7.6-9.3c-0.8-2.9-0.3-5.8,1.1-8.5c1.3-2.6,3.4-5.1,6.1-7.5
			c9.7-8.8,26.5-16,32.3-18.4v-0.2l159.2-64.3l0.7,2.2l-156.2,63.1V73l-0.9,0.3c0,0-22.1,8.2-33.7,18.9c-2.4,2.2-4.3,4.5-5.5,6.9
			c-1.1,2.2-1.4,4.3-0.9,6.6c0.8,2.7,2.9,5.3,6.7,8c6.1,4,16.1,7.9,31.5,11.4h0.7c0,0,91.9,7.2,132.5,29.5
			c6.3,3.5,11.6,7.4,14.9,11.5c2.4,3,3.8,6.1,4.3,9.5c0.9,5.4-0.9,11.5-6.3,18.3c-4.6,5.8-5.7,14.4-2.8,21.6l0,0
			c1.7,4.2,2.8,9,1.8,14.1c-0.9,5-3.7,10.3-9.5,15.4c-0.9,0.8-1.7,1.4-2.6,1.9c-2.9,1.6-12.4,6.7-25.8,13.3
			c52.1-22.1,150.9-33,162.1-34.1c0.7,0,1.3-0.2,2-0.3c14.5-3.8,23.7-7.7,29.2-11.4c3.4-2.4,5.4-4.6,6.1-6.9
			c1.1-3.2-0.3-6.1-2.5-8.5c-3.7-4.2-9.7-7.4-12.8-9c-1.1-0.5-2.2-0.8-3.3-1c-0.8-0.2-0.8-0.2,0,0
			c-121.1-20.5-152.5-37.2-159.8-42.6c0,0-0.1,0-0.1-0.2c-0.8-0.8-1.6-1.4-2.5-1.8l0,0c-32-15.4-43.6-54.3-45.4-60.9l0,0
			c-0.1-0.6-0.3-1.1-0.5-1.8c-2.9-8.8-0.7-16.7,5.5-23.7c5.9-6.7,15.4-12.7,27.1-17.8c53.9-24,154.4-32.2,154.4-32.2l0.9-0.2
			l0.1,2.4l-0.9,0.2c0,0-100.2,8.2-153.9,32.1c-11.3,5.1-20.7,10.9-26.5,17.3c-5.5,6.3-7.6,13.3-5,21.2l0,0c0.3,0.6,0.4,1.3,0.5,1.9
			c1.7,6.4,13,44.4,44.2,59.5c1.1,0.5,2.1,1.3,3,2.1c7.4,5.4,38.8,22,159.1,42.3c1.3,0.2,2.5,0.6,3.8,1.1l0,0
			c3.2,1.6,9.5,5,13.4,9.5c2.9,3.2,4.3,7.1,2.9,11.2c-0.9,2.6-3,5.3-7,8c-5.5,3.8-14.9,7.9-29.8,11.7l0,0c-0.8,0.2-1.4,0.3-2.2,0.3
			c-11.7,1.3-118.6,13-168.4,37c-8.4,4-15.1,8.5-19.5,13.3c-3,3.4-4.9,7.1-5.1,10.7c-0.1,3.8,1.3,7.9,4.6,12l0,0
			c2,2.6,3.4,5.6,4.1,9c1.2,5.1,2.9,12.8,4.1,18.4c0.9,4,2.9,7.7,5.7,10.1c17.5,15.2,85.1,67.2,192.1,68.1c2.5,0,5,0.6,7.2,1.9l0,0
			c3.2,1.8,7.4,4.6,10,8.2c2,2.6,2.9,5.6,2.2,8.8c-0.4,2.1-1.6,4.2-3.8,6.4c-2.8,2.6-7.1,5.4-13.7,8c6.6,2.7,10.9,5.4,13.7,8
			c2.2,2.2,3.4,4.3,3.8,6.4c0.7,3.2-0.3,6.1-2.2,8.8c-2.6,3.5-6.8,6.4-10,8.2l0,0c-2.2,1.3-4.7,1.9-7.2,1.9
			c-107,1-174.6,52.9-192.1,68.1c-2.8,2.6-4.7,6.1-5.7,10.1c-1.2,5.6-2.9,13.3-4.1,18.4c-0.8,3.4-2.1,6.4-4.1,9l0,0
			c-3.3,4.2-4.7,8.2-4.6,12c0.1,3.8,2,7.4,5.1,10.7c4.3,4.8,11.1,9.3,19.5,13.3c49.8,24,156.7,35.7,168.4,37c0.8,0,1.4,0.2,2.2,0.3
			l0,0c14.7,3.8,24.2,7.9,29.8,11.7c4,2.7,6.1,5.4,7,8c1.4,4-0.1,7.9-2.9,11.2c-3.8,4.5-10.3,7.9-13.4,9.5l0,0
			c-1.2,0.6-2.5,1-3.8,1.1c-120.2,20.4-151.7,36.7-159.1,42.2c-0.9,0.8-1.8,1.6-3,2.1c-31.2,15.1-42.5,53-44.2,59.5
			c-0.1,0.6-0.4,1.3-0.5,1.9l0,0c-2.6,7.9-0.4,14.7,5,21.2c5.8,6.6,15,12.3,26.5,17.3c53.7,23.9,153.9,32.1,153.9,32.1
			c0.4,0,0.7,0.3,0.8,0.6c0.1,0,0.1,0.2,0.1,0.5v1c0,0.6-0.4,1.1-0.9,1.1c0,0-100.2,8.2-153.9,32.1c-11.3,5.1-20.7,10.9-26.5,17.3
			c-5.5,6.3-7.6,13.3-5,21.2l0,0c0.3,0.6,0.4,1.3,0.5,1.9c1.7,6.4,13,44.4,44.2,59.5c1.1,0.5,2.1,1.3,3,2.1
			c7.4,5.4,38.8,22,159.1,42.3c1.3,0.2,2.5,0.6,3.8,1.1l0,0c3.2,1.6,9.5,5,13.4,9.5c2.9,3.2,4.3,7.1,2.9,11.2c-0.9,2.6-3,5.3-7,8
			c-5.5,3.8-14.9,7.9-29.8,11.7l0,0c-0.8,0.2-1.4,0.3-2.2,0.3c-11.7,1.3-118.6,13-168.4,37c-8.4,4-15.1,8.5-19.5,13.3
			c-3,3.4-4.9,7.1-5.1,10.7c-0.1,3.8,1.3,7.9,4.6,12l0,0c2,2.6,3.4,5.6,4.1,9c1.2,5.1,2.9,12.8,4.1,18.4c0.9,4,2.9,7.7,5.7,10.1
			c17.5,15.2,85.1,67.2,192.1,68.1c2.5,0,5,0.6,7.2,1.9l0,0c3.2,1.8,7.4,4.6,10,8.2c2,2.6,2.9,5.6,2.2,8.8c-0.4,2.1-1.6,4.2-3.8,6.4
			c-2.8,2.7-7.1,5.3-13.7,8c6.6,2.7,10.9,5.4,13.7,8c2.2,2.1,3.4,4.3,3.8,6.4c0.7,3.2-0.3,6.1-2.2,8.8c-2.6,3.5-6.8,6.4-10,8.2l0,0
			c-2.2,1.3-4.7,1.9-7.2,1.9c-107,1-174.6,52.9-192.1,68.1c-2.8,2.6-4.7,6.1-5.7,10.1c-1.2,5.6-2.9,13.3-4.1,18.4
			c-0.8,3.4-2.1,6.4-4.1,9l0,0c-3.3,4.2-4.7,8.2-4.6,12c0.1,3.8,2,7.4,5.1,10.7c4.3,4.8,11.1,9.3,19.5,13.3
			c49.8,24,156.7,35.7,168.4,37c0.8,0,1.4,0.2,2.2,0.3l0,0c14.7,3.8,24.2,7.9,29.8,11.7c4,2.7,6.1,5.4,7,8c1.4,4-0.1,7.9-2.9,11.2
			c-3.8,4.5-10.3,7.9-13.4,9.5l0,0c-1.2,0.5-2.5,1-3.8,1.1c-120.2,20.4-151.7,36.7-159.1,42.2c-0.9,1-1.8,1.6-3,2.1
			c-31.2,15.1-42.5,53-44.2,59.5c-0.1,0.6-0.4,1.3-0.5,1.9l0,0c-2.6,7.9-0.4,14.7,5,21.2c5.8,6.6,15,12.3,26.5,17.3
			c53.7,23.9,153.9,32.1,153.9,32.1l0.9,0.2l-0.1,2.4l-0.9-0.2c0,0-100.5-8.2-154.4-32.2c-11.7-5.1-21.2-11.1-27.1-18
			c-6.2-7.1-8.4-14.9-5.5-23.7c0.1-0.5,0.4-1.1,0.5-1.8l0,0c1.7-6.6,13.3-45.5,45.4-60.9l0,0c0.9-0.5,1.7-1.1,2.5-1.8
			c0,0,0.1,0,0.1-0.2c7.4-5.4,38.7-22.1,159.8-42.6l0,0c1.2-0.2,2.2-0.5,3.3-1c3-1.4,9.1-4.6,12.8-9c2.2-2.6,3.6-5.4,2.5-8.5
			c-0.8-2.2-2.6-4.5-6.1-6.9c-5.5-3.8-14.6-7.7-29.2-11.4c-0.7-0.2-1.3-0.3-2-0.3c-11.2-1.1-109.9-12-162.1-34.1
			c13.4,6.6,22.9,11.7,25.8,13.3c0.9,0.5,1.8,1.1,2.6,1.9c5.9,5,8.7,10.3,9.5,15.4c0.9,5.1-0.1,9.9-1.8,14.1l0,0
			c-3,7.2-1.8,15.9,2.8,21.6c5.4,6.6,7.1,12.7,6.3,18.3c-0.5,3.4-2,6.6-4.3,9.5c-3.4,4.3-8.6,8.2-14.9,11.5
			c-40.6,22.1-132.5,29.5-132.5,29.5h-0.7c-15.5,3.5-25.5,7.2-31.5,11.4c-4,2.6-6.1,5.3-6.7,8c-0.5,2.2-0.1,4.5,0.9,6.6
			c1.2,2.4,3.2,4.6,5.5,6.9c11.7,10.7,33.7,18.9,33.7,18.9l0.9,0.3v0.2l156.2,63.1l-0.7,2.2l-159.2-64.4v-0.2
			c-5.8-2.4-22.5-9.6-32.3-18.4c-2.6-2.4-4.7-5-6.1-7.5c-1.4-2.9-1.8-5.6-1.1-8.5c0.8-3,3.2-6.3,7.6-9.3c6.1-4.2,16.2-8,31.9-11.5
			v-0.2l1.1-0.2c0,0,0.1,0,0.5,0c0.1,0,0.3,0,0.4-0.2l0.9-0.2v0.2c13.6-1.3,93-9,129.8-29c5.1-2.7,9.3-5.8,12.5-9.1
			c3-3,5-6.4,5.5-10.1c0.8-4.8-1.1-10.1-5.7-15.9l0,0c-5.3-6.6-6.5-16.5-3-24.5c1.6-3.7,2.5-8,1.7-12.5c-0.8-4.5-3.3-9.3-8.7-13.8
			c-0.8-0.6-1.6-1.3-2.4-1.6c-8.7-4.8-76.5-41.7-131-50.8l0,0l-0.3-0.2l-1.1-0.2v-0.2c-2.8-1-22.3-7.4-32.9-16
			c-2.5-2.1-4.6-4.3-5.9-6.6c-1.4-2.6-1.8-5.1-1.1-7.7c0.8-2.7,3-5.6,7.5-8.5c6.1-3.8,16.2-7.7,32-11.4v-0.3l1.1-0.2
			c0,0,102-15.2,140.9-35.6c5.8-3,10.3-6.3,12.5-9.5c1.3-1.9,2-3.8,1.6-5.8c-0.3-1.3-1.1-2.7-2.2-4c-2-2.2-5.1-4.5-9.7-6.9l0,0
			c-5-2.6-8.6-8.3-9.1-15.1l0,0c-0.4-6.1,1.6-13.1,5.5-17.1c-3.8-4-5.9-11.7-5.5-17.6l0,0c0.5-6.7,3.9-12.5,9.1-15.1l0,0
			c4.5-2.2,7.8-4.6,9.7-6.9c1.2-1.4,2-2.7,2.2-4c0.4-1.9-0.3-3.8-1.6-5.8c-2.4-3.2-6.7-6.4-12.5-9.5
			c-38.8-20.5-140.9-35.6-140.9-35.6l-1.1-0.2v-0.3c-15.8-3.5-25.9-7.5-32-11.4c-4.3-2.9-6.6-5.8-7.5-8.5c-0.8-2.6-0.3-5.3,1.1-7.7
			c1.3-2.2,3.3-4.5,5.9-6.6c10.7-8.7,30.2-15.2,32.9-16v-0.3l1.1-0.2h0.3l0,0c54.6-9.1,122.5-46,131-50.8c0.8-0.5,1.6-1,2.4-1.6
			c5.4-4.5,7.9-9.3,8.7-13.8c0.8-4.5-0.1-8.8-1.7-12.5c-3.4-8.2-2.2-18,3-24.5l0,0c4.7-5.8,6.5-11.1,5.7-16
			c-0.5-3.7-2.5-7.1-5.5-10.1c-3.2-3.2-7.4-6.3-12.5-9.1c-36.9-20-116.1-27.9-129.8-29v0.2l-0.9-0.2c-0.1,0-0.3,0-0.4-0.2
			c-0.4,0-0.5,0-0.5,0h-1.1v-0.2c-15.7-3.5-25.8-7.4-31.9-11.5c-4.5-3-6.8-6.3-7.6-9.3c-0.8-2.9-0.3-5.8,1.1-8.5
			c1.3-2.6,3.4-5.1,6.1-7.5c9.7-8.8,26.5-16,32.3-18.4l0,0l156.6-63.3L1437.1,809l0,0c-5.8-2.4-22.5-9.6-32.3-18.4
			c-2.6-2.4-4.7-5-6.1-7.5c-1.4-2.9-1.8-5.6-1.1-8.5c0.8-3,3.2-6.3,7.6-9.3c6.1-4.2,16.2-8,31.9-11.5v-0.2h1.1c0,0,0.1,0,0.5,0
			c0.1,0,0.3,0,0.4-0.2l0.9-0.2v0.2c13.6-1.3,93-9,129.8-29c5.1-2.7,9.3-5.8,12.5-9.1c3-3.2,5-6.4,5.5-10.1
			c0.8-4.8-1.1-10.1-5.7-15.9l0,0c-5.3-6.6-6.5-16.5-3-24.5c1.6-3.7,2.5-8,1.7-12.5c-0.8-4.5-3.3-9.3-8.7-13.8
			c-0.8-0.6-1.6-1.3-2.4-1.6c-8.7-4.8-76.5-41.7-131-50.8l0,0h-0.3l-1.1-0.2v-0.2c-2.8-1-22.3-7.4-32.9-16c-2.5-2.1-4.6-4.3-5.9-6.6
			c-1.4-2.6-1.8-5.1-1.1-7.7c0.8-2.7,3-5.6,7.5-8.5c6.1-3.8,16.2-7.7,32-11.4v-0.2l1.1-0.2c0,0,102-15.2,140.9-35.6
			c5.8-3,10.3-6.3,12.5-9.5c1.3-1.9,2-3.8,1.6-5.8c-0.3-1.3-1.1-2.7-2.2-4c-2-2.2-5.1-4.5-9.7-6.9l0,0c-5-2.6-8.6-8.3-9.1-15.1l0,0
			c-0.5-6.3,1.7-13,5.7-16.8c-4-4-6.2-11.7-5.7-18l0,0c0.5-6.6,3.9-12.5,9.1-15.1l0,0c4.5-2.2,7.8-4.6,9.7-6.9c1.2-1.4,2-2.7,2.2-4
			c0.4-1.9-0.3-3.8-1.6-5.8c-2.4-3.2-6.7-6.4-12.5-9.5c-38.8-20.5-140.9-35.6-140.9-35.6l-1.1-0.2v-0.2c-15.8-3.5-25.9-7.5-32-11.4
			c-4.3-2.9-6.6-5.8-7.5-8.5c-0.8-2.6-0.3-5.3,1.1-7.7c1.3-2.2,3.3-4.5,5.9-6.6c10.7-8.7,30.2-15.2,32.9-16v-0.2l1.1-0.2h0.3l0,0
			c54.6-9,122.5-46,131-50.8c0.8-0.5,1.6-1,2.4-1.6c5.4-4.5,7.9-9.3,8.7-13.8c0.8-4.5-0.1-8.8-1.7-12.5c-3.4-8.2-2.2-18,3-24.5l0,0
			c4.7-5.8,6.5-11.1,5.7-16c-0.5-3.7-2.5-7.1-5.5-10.1c-3.2-3.2-7.4-6.3-12.5-9.1c-36.9-20.2-116.1-27.9-129.8-29v0.2l-0.9-0.2
			c-0.1,0-0.3,0-0.4-0.2c-0.4,0-0.5,0-0.5,0L1437.3,127.2V127V127.2z M1533.8,265.3c-26.6,12.3-62.9,27.1-94.8,32.2l-1.1,0.2l0,0
			c-3.4,1.1-21.9,7.4-32.1,15.7c-2.4,1.9-4.2,3.8-5.4,5.9c-1.1,1.8-1.4,3.7-0.9,5.6c0.7,2.4,2.8,4.8,6.5,7.2
			c6.1,3.8,16.3,7.7,32.3,11.2l0.9,0.2l0,0c10,1.6,103.6,16.2,140.5,35.7c6.2,3.2,10.8,6.7,13.3,10.1c1.8,2.6,2.5,5.3,2,8
			c-0.4,1.8-1.2,3.5-2.8,5.3c-2.1,2.4-5.5,4.8-10.3,7.4c-4.3,2.2-7.4,7.2-7.8,13c-0.4,5.8,1.7,13.3,5.7,16.5c0.3,0.2,0.4,0.6,0.4,1
			v0.5c0,0.6-0.3,1-0.5,1.1h0.1c-4,3.2-6.2,9.6-5.7,15.4c0.4,5.8,3.4,10.7,7.8,13c4.9,2.4,8.2,5,10.3,7.4c1.6,1.8,2.4,3.5,2.8,5.3
			c0.5,2.7-0.1,5.3-2,8c-2.5,3.5-7.1,6.9-13.3,10.1c-36.9,19.4-130.5,34.1-140.5,35.7l0,0l-0.9,0.2c-15.9,3.5-26.2,7.4-32.3,11.2
			c-3.7,2.4-5.8,4.8-6.5,7.2c-0.5,1.9-0.1,3.8,0.9,5.6c1.2,2.1,3,4,5.4,5.9c10.3,8.3,28.7,14.6,32.1,15.7l0,0l1.1,0.2
			c31.9,5.3,68.2,19.9,94.8,32.2c-6.6-3.5-11.9-7.4-15.5-11.4c-3.6-3.8-5.5-8-5.7-12.3c-0.3-4.3,1.3-9,5.1-13.8c1.7-2.2,3-5,3.7-8
			l0,0c1.2-5.1,2.9-12.8,4.1-18.4l0,0c1.1-4.6,3.3-8.7,6.3-11.4l0,0c17.6-15.2,85.5-67.5,193.2-68.4c2.2,0,4.3-0.6,6.5-1.8
			c2.9-1.6,7-4.3,9.3-7.7c1.4-1.9,2.4-4.2,1.8-6.6c-0.4-1.6-1.3-3.4-3.2-5.1c-2.9-2.9-7.9-5.8-15.8-8.7c0.1,0,0.1,0,0.1,0l-0.3-2.4
			c7.9-2.9,13-5.9,15.9-8.8c1.8-1.8,2.8-3.4,3.2-5.1c0.5-2.4-0.4-4.6-1.8-6.6c-2.4-3.4-6.5-5.9-9.3-7.7c-2-1.1-4.2-1.8-6.5-1.8
			c-107.7-1-175.6-53.2-193.2-68.4l0,0c-3-2.9-5.4-6.9-6.3-11.4l0,0c-1.2-5.4-2.9-13.3-4.1-18.4l0,0c-0.7-3-2-5.8-3.7-8
			c-3.8-4.8-5.3-9.5-5.1-13.8c0.3-4.3,2.2-8.5,5.7-12.3C1521.9,272.7,1527.2,269,1533.8,265.3L1533.8,265.3z M1533.8,1478.7
			c-6.6-3.5-11.9-7.4-15.5-11.4c-3.6-3.8-5.5-8-5.7-12.3c-0.3-4.3,1.3-9,5.1-13.8c1.7-2.2,3-5,3.7-8l0,0c1.2-5.1,2.9-12.8,4.1-18.4
			l0,0c1.1-4.5,3.3-8.7,6.3-11.4l0,0c17.6-15.2,85.5-67.5,193.2-68.4c2.2,0,4.3-0.6,6.5-1.8c2.9-1.6,7-4.3,9.3-7.7
			c1.4-1.9,2.4-4.2,1.8-6.6c-0.4-1.6-1.3-3.4-3.2-5.1c-2.9-2.9-7.9-5.8-15.8-8.7c0.1,0,0.1,0,0.1,0l-0.3-2.4
			c7.9-2.9,13-5.9,15.9-8.8c1.8-1.8,2.8-3.5,3.2-5.1c0.5-2.4-0.4-4.6-1.8-6.6c-2.4-3.4-6.5-5.9-9.3-7.7c-2-1.1-4.2-1.8-6.5-1.8
			c-107.7-1-175.6-53.2-193.2-68.4l0,0c-3-2.9-5.4-6.9-6.3-11.4l0,0c-1.2-5.4-2.9-13.3-4.1-18.4l0,0c-0.7-3-2-5.8-3.7-8
			c-3.8-4.8-5.3-9.5-5.1-13.8c0.3-4.3,2.2-8.5,5.7-12.3c3.7-4,9-7.9,15.5-11.4c-26.6,12.3-62.9,27.1-94.8,32.2l-1.1,0.2l0,0
			c-3.4,1.1-21.9,7.4-32.1,15.7c-2.4,1.9-4.2,3.8-5.4,5.9c-1.1,1.8-1.4,3.7-0.9,5.6c0.7,2.4,2.8,4.8,6.5,7.2
			c6.1,3.8,16.3,7.7,32.3,11.2l0.9,0.2l0,0c10,1.6,103.6,16.3,140.5,35.7c6.2,3.2,10.8,6.7,13.3,10.1c1.8,2.6,2.5,5.3,2,8
			c-0.4,1.8-1.2,3.5-2.8,5.3c-2.1,2.4-5.5,4.8-10.3,7.4c-4.3,2.2-7.4,7.2-7.8,13c-0.4,5.8,1.7,13.1,5.7,16.5c0.3,0.2,0.4,0.6,0.4,1
			s-0.1,0.8-0.4,1c-4,3.2-6.2,10.1-5.7,15.9c0.4,5.8,3.4,10.7,7.8,13c4.9,2.4,8.2,5,10.3,7.4c1.6,1.8,2.4,3.5,2.8,5.3
			c0.5,2.7-0.1,5.3-2,8c-2.5,3.5-7.1,6.9-13.3,10.1c-36.9,19.4-130.5,34.1-140.5,35.7l0,0l-0.9,0.2c-15.9,3.5-26.2,7.4-32.3,11.2
			c-3.7,2.4-5.8,4.8-6.5,7.2c-0.5,1.9-0.1,3.8,0.9,5.6c1.2,2.1,3,4,5.4,5.9c10.3,8.3,28.7,14.6,32.1,15.7l0,0l1.1,0.2
			C1470.8,1451.8,1507.2,1466.4,1533.8,1478.7L1533.8,1478.7z M1545.1,1123.6c52.1-22.1,150.9-32.9,162.1-34.1c0.7,0,1.3-0.2,2-0.3
			c14.5-3.7,23.7-7.7,29.2-11.4c3.4-2.4,5.4-4.6,6.1-6.9c1.1-3.2-0.3-6.1-2.5-8.5c-3.7-4.2-9.7-7.4-12.8-9c-1.1-0.5-2.2-0.8-3.3-1
			l0,0c-121.1-20.5-152.5-37.2-159.8-42.6c0,0-0.1,0-0.1-0.2c-0.8-0.8-1.6-1.4-2.5-1.8l0,0c-32-15.4-43.6-54.3-45.4-60.9l0,0
			c-0.1-0.6-0.3-1.1-0.5-1.8c-2.9-8.8-0.7-16.7,5.5-23.7c5.9-6.7,15.4-12.7,27.1-18c45-20,122.5-29,146.8-31.6
			c-24.4-2.4-101.8-11.5-146.8-31.6c-11.7-5.1-21.2-11.2-27.1-17.8c-6.2-7.1-8.4-14.9-5.5-23.7c0.1-0.5,0.4-1.1,0.5-1.8l0,0
			c1.7-6.6,13.3-45.5,45.4-60.9l0,0c0.9-0.5,1.7-1.1,2.5-1.8c0,0,0.1,0,0.1-0.2c7.4-5.4,38.7-22.1,159.8-42.6l0,0
			c1.2-0.2,2.2-0.5,3.3-1c3-1.4,9.1-4.6,12.8-9c2.2-2.6,3.6-5.4,2.5-8.5c-0.8-2.2-2.6-4.5-6.1-6.9c-5.5-3.8-14.6-7.7-29.2-11.4
			c-0.7-0.2-1.3-0.3-2-0.3c-11.2-1.1-109.9-12-162.1-34.1c13.4,6.6,22.9,11.7,25.8,13.3c0.9,0.5,1.8,1.1,2.6,1.9
			c5.9,5,8.7,10.3,9.5,15.4c0.9,5.1-0.1,9.9-1.8,14.1l0,0c-3,7.2-1.8,15.9,2.8,21.6c5.4,6.6,7.1,12.7,6.3,18.3
			c-0.5,3.4-2,6.4-4.3,9.5c-3.4,4.3-8.6,8.2-14.9,11.5c-40.6,22.1-132.5,29.5-132.5,29.5h-0.7c-15.5,3.5-25.5,7.2-31.5,11.4
			c-4,2.6-6.1,5.3-6.7,8c-0.5,2.2-0.1,4.5,0.9,6.6c1.2,2.4,3.2,4.6,5.5,6.9c11.7,10.7,33.7,18.9,33.7,18.9l0.9,0.3v0.2l155.2,62.8
			c0.3,0.2,0.4,0.3,0.5,0.6c0.1,0,0.1,0.2,0.1,0.5v1c0,0.5-0.3,1-0.7,1.1l-155.2,62.8v0.2l-0.9,0.3c0,0-22.1,8.2-33.7,18.9
			c-2.4,2.2-4.3,4.5-5.5,6.9c-1.1,2.1-1.4,4.3-0.9,6.6c0.8,2.7,2.9,5.3,6.7,8c6.1,4,16.1,7.9,31.5,11.4h0.7c0,0,91.9,7.2,132.5,29.5
			c6.3,3.5,11.6,7.4,14.9,11.5c2.4,2.9,3.8,6.1,4.3,9.5c0.9,5.4-0.9,11.5-6.3,18.3c-4.6,5.8-5.7,14.4-2.8,21.6l0,0
			c1.7,4.2,2.8,9,1.8,14.1c-0.9,5-3.7,10.3-9.5,15.4c-0.9,0.8-1.7,1.3-2.6,1.9C1568,1111.9,1558.5,1117,1545.1,1123.6L1545.1,1123.6
			z M1723.6,441.5c-0.3,0-0.9,0.2-0.9-1.3c0,0.5,0.3,1,0.7,1.1C1723.5,441.5,1723.5,441.5,1723.6,441.5z M1723.6,1305.2
			c-0.3,0-0.9,0.2-0.9-1.3c0,0.5,0.3,1,0.7,1.1C1723.5,1305,1723.5,1305.2,1723.6,1305.2z M-0.4,39.2l-0.4-2.2L104.3,7.1l0.4,2.2
			L-0.4,39.2z M-0.3,69l-0.7-2.2L146.9,7l0.7,2.2L-0.3,69z M358.5,439.9c0,0.2,0,0.6,0,0.6c4.7,24-11.2,38.5-32.7,47
			c-31.5,12.5-74.8,12.3-78.6,12.2h-0.5c-40,0.6-66.1,7.1-83,15.9c-16.6,8.7-24.1,19.6-27.1,29.8c-4,13.5,0,25.5,1.2,28.5
			c0.3,0.8,0.5,1.4,0.8,2.2c1.2,3.8,4.5,7.5,9.2,11.2c7.5,5.8,18.8,11.4,32.3,16.8c52.4,20.8,136.7,37.2,154.3,40.4
			c2.6,0.5,5.1,1.6,7.2,3.5c10.9,9,14.1,18.3,13.6,26.4c-0.8,12.3-10.3,22.8-16.2,28l0,0c-2.9,2.6-6.5,4-10,4l0,0
			c-111.8,1.8-174.7,32.5-187.4,39.4c-1.8,1-3.4,2.4-4.7,4.2c-4.9,6.4-7.4,12.5-7.6,18.4c-0.3,6.9,2.2,13.5,7,19.9
			c4.9,6.6,12,12.7,20.7,18.6c58.7,39.6,188.9,63.8,188.9,63.8l-0.1,2.4c0,0,0.9-2.9,0.9-1.1c0,0.6-0.4,1.1-0.8,1.1c0,0-0.1,0-0.3,0
			h-0.1c-7.9,1.4-131.7,25.3-188.5,63.6c-8.7,5.9-15.8,12-20.7,18.6c-4.7,6.4-7.2,13-7,19.9c0.3,5.9,2.8,12,7.6,18.4
			c1.3,1.8,2.9,3.2,4.7,4.2c12.6,6.9,75.7,37.7,187.5,39.4l0,0c3.6,0,7.1,1.4,10,4l0,0c5.9,5.3,15.4,15.7,16.2,28
			c0.5,8.3-2.6,17.5-13.6,26.4c-2.2,1.8-4.7,3-7.2,3.5c-17.5,3.4-101.8,19.6-154.2,40.5c-13.4,5.3-24.8,10.9-32.3,16.8
			c-4.9,3.7-8.2,7.5-9.2,11.2c-0.3,0.8-0.5,1.4-0.8,2.2c-1.2,3-5.1,15.1-1.2,28.5c3,10.3,10.5,21.2,27.1,29.8
			c16.9,8.8,42.9,15.1,83,15.7h0.5c3.8,0,47.1-0.3,78.6,12.2c21.5,8.5,37.3,22.9,32.7,47c0,0.2,0,0.6,0,0.6
			c4.7,24-11.2,38.5-32.7,47c-31.5,12.5-74.8,12.3-78.6,12.2H247c-40,0.6-66.1,7.1-83,15.7c-16.6,8.7-24.1,19.6-27.1,29.8
			c-4,13.5,0,25.5,1.2,28.5c0.3,0.8,0.5,1.4,0.8,2.2c1.2,3.8,4.5,7.5,9.2,11.4c7.5,5.8,18.8,11.5,32.3,16.8
			c52.4,20.8,136.7,37.2,154.3,40.4c2.6,0.5,5.1,1.6,7.2,3.5c10.9,9,14.1,18.3,13.6,26.4c-0.8,12.3-10.3,22.8-16.2,28l0,0
			c-2.9,2.6-6.5,4-10,4l0,0c-111.8,1.8-174.7,32.5-187.4,39.4c-1.8,1-3.4,2.4-4.7,4.2c-4.9,6.3-7.4,12.5-7.6,18.4
			c-0.3,6.9,2.2,13.5,7,19.9c4.9,6.6,12,12.7,20.7,18.6c58.7,39.6,188.9,63.8,188.9,63.8l0.9,0.2l-0.3,2.4l-0.9-0.2
			c0,0-130.6-24.4-189.6-63.9c-9-6.1-16.2-12.3-21.2-19.1c-5.1-6.9-7.8-14.1-7.5-21.6c0.3-6.4,2.9-13.1,8.2-19.9l0,0
			c1.4-1.9,3.3-3.5,5.3-4.6l0,0c12.6-6.9,76-37.8,188.2-39.6c3.2,0,6.3-1.3,8.8-3.5c4-3.5,9.6-9.5,12.9-16.7
			c2.1-4.6,3.3-9.9,2.2-15.4c-1.2-6.1-4.7-12.3-12.4-18.6l0,0c-2-1.6-4.2-2.7-6.5-3l0,0c-17.6-3.4-102-19.6-154.6-40.5
			c-13.6-5.4-25-11.2-32.7-17c-5.3-4-8.8-8.3-10-12.5l0,0c-0.1-0.6-0.4-1.3-0.7-2.1l0,0c-1.2-3.2-5.4-16-1.2-30.3
			c3.2-10.6,10.8-22.1,28.2-31.3c17-8.8,43.3-15.2,83.6-16h0.5c3.8,0,46.7,0.3,77.9-12.2c20.4-8,35.8-21.5,31.3-44.2
			c0-0.2,0-0.2,0-0.3v-1l0.9,1.1l-0.9-1.4c4.6-22.8-10.9-36.2-31.3-44.2c-31.2-12.3-74.1-12.2-77.9-12.2H247
			c-40.3-0.6-66.6-7.1-83.6-16c-17.2-9-25-20.5-28.2-31.3c-4.2-14.3,0-27.1,1.2-30.3l0,0c0.3-0.6,0.5-1.3,0.7-2.1l0,0
			c1.3-4.2,4.7-8.3,10-12.5c7.6-5.9,19.1-11.7,32.7-17c52.5-21,136.9-37.2,154.6-40.5l0,0c2.4-0.5,4.6-1.4,6.5-3l0,0
			c7.6-6.3,11.3-12.5,12.4-18.6c1.1-5.4-0.1-10.7-2.2-15.4c-3.3-7.2-9-13.1-12.9-16.7c-2.6-2.2-5.7-3.5-8.8-3.5
			c-112.2-1.8-175.5-32.7-188.2-39.6l0,0c-2-1.1-3.8-2.7-5.3-4.6l0,0c-5.3-6.9-7.8-13.5-8.2-19.9c-0.4-7.5,2.4-14.7,7.5-21.6
			c5-6.7,12.2-13.1,21.2-19.1c51.5-34.6,157.3-57.5,183.5-62.8c-26.2-5.3-132.2-28.2-183.5-62.8c-9-6.1-16.2-12.3-21.2-19.1
			c-5.1-6.9-7.8-14.1-7.5-21.6c0.3-6.4,2.9-13,8.2-19.9l0,0c1.4-1.9,3.3-3.5,5.3-4.6l0,0c12.6-6.9,76-37.8,188.2-39.6
			c3.2,0,6.3-1.3,8.8-3.5c4-3.5,9.6-9.5,12.9-16.7c2.1-4.6,3.3-9.9,2.2-15.4c-1.2-6.1-4.7-12.3-12.4-18.6l0,0c-2-1.6-4.2-2.7-6.5-3
			l0,0c-17.6-3.4-102-19.6-154.6-40.5c-13.6-5.4-25-11.2-32.7-17c-5.3-4-8.8-8.3-10-12.5l0,0c-0.1-0.6-0.4-1.4-0.7-2.1l0,0
			c-1.2-3.2-5.4-16-1.2-30.3c3.2-10.6,10.8-22.3,28.2-31.3c17-8.8,43.3-15.2,83.6-16h0.5c3.8,0,46.7,0.3,77.9-12.2
			c20.4-8,35.8-21.5,31.3-44.2c0-0.2,0-0.2,0-0.3v-1l0.9,1.1l-0.9-1.4c4.6-22.8-10.9-36.2-31.3-44.2c-31.2-12.3-74.1-12.2-77.9-12.2
			H247c-40.3-0.6-66.6-7.1-83.6-16c-17.2-9-25-20.5-28.2-31.3c-4.2-14.3,0-27.1,1.2-30.3l0,0c0.3-0.6,0.5-1.3,0.7-2.1l0,0
			c1.3-4.2,4.7-8.3,10-12.5c7.6-5.9,19.1-11.7,32.7-17c52.5-21,136.9-37.2,154.6-40.5l0,0c2.4-0.5,4.6-1.4,6.5-3l0,0
			c7.6-6.3,11.3-12.5,12.4-18.6c1.1-5.4-0.1-10.7-2.2-15.4c-3.3-7.2-9-13.1-12.9-16.7c-2.6-2.2-5.7-3.5-8.8-3.5
			C217,174.5,153.6,143.5,141,136.6l0,0c-2-1.1-3.8-2.7-5.3-4.6l0,0c-5.3-6.9-7.8-13.5-8.2-19.9c-0.4-7.5,2.4-14.7,7.5-21.6
			c5-6.7,12.2-13,21.2-19.1C215.1,31.6,345.9,7.3,345.9,7.3l0.9-0.2l0.3,2.4l-0.9,0.2c0,0-130.2,24.2-188.9,63.8
			c-8.7,5.9-15.8,12-20.7,18.6c-4.7,6.3-7.2,13-7,19.9c0.3,5.9,2.8,12,7.6,18.4c1.3,1.8,2.9,3.2,4.7,4.2
			c12.6,6.9,75.7,37.7,187.5,39.4l0,0c3.6,0.2,7.1,1.4,10,4l0,0c5.9,5.4,15.4,15.9,16.2,28c0.5,8.2-2.6,17.5-13.6,26.4
			c-2.2,1.8-4.7,3-7.2,3.5c-17.6,3.4-101.9,19.6-154.3,40.4c-13.4,5.3-24.8,11.1-32.3,16.8c-4.9,3.7-8.2,7.5-9.2,11.2
			c-0.3,0.8-0.5,1.4-0.8,2.2c-1.2,3-5.1,15.1-1.2,28.5c3,10.3,10.5,21.2,27.1,29.8c16.9,8.8,42.9,15.1,83,15.9h0.5
			c3.8,0,47.1-0.3,78.6,12.2C347.3,401.4,363.3,416,358.5,439.9L358.5,439.9z M-0.3,134.1h-0.9l0.1-2.4h0.9c0,0,90.1,6.3,131.3,25.2
			c7.5,3.5,13.4,7.4,17,11.7c2.5,3,3.8,6.4,3.7,9.9c-0.1,3.7-1.7,7.7-5.4,12c-4.7,5.6-6.1,14.3-3.2,21.6c1.4,3.5,2.1,7.7,0.9,11.9
			c-1.1,4.2-3.8,8.3-9.3,12.5c-0.8,0.6-1.7,1.1-2.5,1.6l0,0c-8.4,4.2-76.9,36.5-131.9,44.6l-0.9,0.2l-0.3-2.4l0.9-0.2
			c54.8-7.9,123-40.2,131.4-44.4c0.8-0.3,1.6-0.8,2.2-1.4l0,0c5-3.7,7.5-7.4,8.6-11.2c0.9-3.5,0.4-7.1-0.8-10.1l0,0
			c-3.2-8.2-1.8-18,3.6-24.4l0,0c3.2-3.7,4.7-7.1,4.7-10.3c0.1-2.9-1.1-5.6-3.2-8.2c-3.3-4.2-9.1-7.9-16.2-11.2
			C89.4,140.3-0.3,134.1-0.3,134.1z M-0.3,165.6h-0.9v-2.4h0.9c0,0,67.4,2.9,91.8,14.6c3.2,1.6,5.7,3.2,7.1,5.1
			c1.7,2.1,2.4,4.5,1.7,6.9c-1.4,5.8-0.7,11.9,2.2,16.7l0,0c1.1,1.8,1.7,3.8,1.4,6.1c-0.3,3-2.1,6.7-6.8,10.6
			c-10.9,9-38.1,20.2-97.4,32.4l-0.9,0.2l-0.3-2.4l0.9-0.2c59-12.2,85.8-23.1,96.8-32.1c4.1-3.4,5.8-6.3,5.9-8.8
			c0.1-1.6-0.4-3-1.2-4.5c-3.3-5.4-4.2-12.3-2.6-18.8c0.5-2.1-0.4-3.8-2.1-5.4c-3.2-2.9-9.1-5.4-16.3-7.5
			C51.1,167.9-0.3,165.6-0.3,165.6L-0.3,165.6z M-0.3,189h-0.9v-2.4h0.9c0,0,29.1,1.1,44.6,6.6c4.2,1.4,7.4,3.4,9,5.4
			c1.2,1.8,1.6,3.7,0.5,5.8c-1.1,2.6-4.1,5.4-9.9,8.7c-8.3,4.6-22.3,10.3-44.1,16.7l-0.9,0.3l-0.5-2.2l0.9-0.3
			c21.7-6.4,35.6-12,43.7-16.7c5.1-2.9,8-5.4,9-7.7c0.7-1.6,0.1-2.9-1.1-4c-1.6-1.4-4.1-2.7-7.2-3.8C28.6,190.2-0.3,189-0.3,189
			L-0.3,189z M141.8,440.5v0.5c0,0.6-0.3,1-0.5,1.1h0.1c-3.8,2.9-5.9,9.5-5.5,14.9c0.4,5.4,3.3,10.1,7.5,12.2
			c6.2,2.9,10,5.8,11.9,8.7c1.4,2.2,1.8,4.3,1.4,6.4c-0.4,1.9-1.6,4-3.4,5.9c-2.6,2.7-6.6,5.3-11.7,7.9C102.5,517.4,0,531.7,0,531.7
			l-0.9,0.2l-0.3-2.4l0.9-0.2c0,0,102.2-14.3,141-33.5c4.9-2.4,8.7-5,11.2-7.5c1.4-1.6,2.5-3,2.8-4.6c0.4-1.9-0.3-3.7-2.1-5.6
			c-2-2.2-5.3-4.5-10-6.7l0,0c-4.9-2.4-8.2-7.9-8.7-14.1l0,0c-0.4-5.9,1.7-12.8,5.5-16.3c-3.8-3.7-6.1-11.5-5.5-17.3l0,0
			c0.5-6.4,3.8-11.9,8.7-14.1l0,0c4.7-2.2,8-4.5,10-6.7c1.7-1.9,2.4-3.8,2.1-5.8c-0.3-1.6-1.3-3.2-2.8-4.6c-2.5-2.6-6.3-5-11.2-7.5
			c-38.8-19.2-141-33.7-141-33.7l-0.9-0.2l0.3-2.4l0.9,0.2c0,0,102.6,14.4,141.5,33.7c5.1,2.6,9.1,5.1,11.7,7.9c1.8,1.9,3,4,3.4,5.9
			c0.4,2.1,0,4.3-1.4,6.4c-1.8,2.9-5.7,5.8-11.9,8.7c-4.2,2.1-7.1,6.7-7.5,12.2c-0.4,5.4,1.7,13,5.5,15.9
			C141.5,439.7,141.8,440,141.8,440.5L141.8,440.5z M103.6,439.4L103.6,439.4c0.1,0,0.4,0.2,0.5,0.6c0.1,0.3,0.1,0.6-0.1,1
			c-0.3,0.3-0.4,0.5-0.7,0.6c-4.3,1.4-7.8,7.4-9.1,12.8l0,0c-1.3,5.3-0.1,11.1,2.9,15.1c1.1,1.4,1.6,2.9,1.6,4.2
			c0,1.3-0.3,2.6-1.1,3.8c-1.1,1.6-2.8,3-5.1,4.5c-20.9,12.3-92.8,17.6-92.8,17.6h-0.9l-0.1-2.4h0.9c0,0,71.2-5.3,92.2-17.5
			c1.7-1,3-2.1,4-3.2c0.8-1,1.2-1.8,1.2-2.9c0-0.8-0.4-1.8-1.2-2.7l0,0c-3.6-4.6-4.7-11.2-3.3-17.3c1.3-5.4,4.6-11.2,8.8-13.6
			c-1.2-1.3-2.9-3.2-4.6-5.4c-2-2.6-3.7-5.4-4.2-7.9c-1.4-6.1-0.3-12.7,3.3-17.3l0,0c0.7-1,1.1-1.8,1.2-2.7c0-1-0.4-1.9-1.2-2.9
			c-0.9-1.1-2.2-2.1-4-3.2c-20.8-12.2-92.2-17.5-92.2-17.5h-0.9l0.1-2.4h0.9c0,0,71.9,5.4,92.8,17.6c2.4,1.4,4.1,2.9,5.1,4.5
			c0.8,1.3,1.2,2.4,1.1,3.8c0,1.3-0.5,2.7-1.6,4.2c-3,4-4.2,9.8-2.9,15.1l0,0c0.5,2.1,2.1,4.6,3.8,6.9
			C100.4,436.2,102.9,438.7,103.6,439.4L103.6,439.4z M103.2,441.6c0.1,0,0.3,0,0.3,0c0.1,0,0.3-0.2,0.4-0.3s0.3-0.6,0.3-0.6
			L103.2,441.6z M-0.3,404.9l-0.9-0.2l0.1-2.4l0.9,0.2c0,0,31.6,3.2,45.8,11.5c4.1,2.4,6.8,5.4,7.2,8.7c0.3,2.1-0.3,4.3-2.1,6.7
			c-2.1,2.9-6.1,6.3-12.4,9.8c6.3,3.5,10.3,6.9,12.4,9.8c1.7,2.4,2.4,4.8,2.1,6.9c-0.4,3.4-3,6.4-7.2,9c-14.2,9-45.8,12.8-45.8,12.8
			l-0.9,0.2l-0.3-2.4l0.9-0.2c0,0,31.2-3.7,45.2-12.5c1.8-1.1,3.3-2.4,4.3-3.5c1.1-1.1,1.6-2.4,1.8-3.7c0.1-1.6-0.3-3.2-1.6-5
			c-2.2-3.2-6.6-6.6-13.7-10.3c-0.4-0.2-0.5-0.6-0.5-1.1s0.3-1,0.5-1.1c7.1-3.8,11.5-7.2,13.7-10.3c1.3-1.8,1.8-3.4,1.6-4.8
			c-0.1-1.3-0.8-2.4-1.8-3.5c-1.1-1.3-2.6-2.4-4.3-3.4C30.8,408-0.4,404.9-0.3,404.9L-0.3,404.9z M-0.2,748.9h-0.9l-0.1-2.4h0.9
			c0,0,89.7-6.1,130.6-25c7.2-3.4,12.9-7.1,16.3-11.2c2.1-2.6,3.2-5.3,3.2-8.2c-0.1-3.2-1.6-6.6-4.7-10.4l0,0
			c-5.4-6.4-6.8-16.2-3.6-24.4l0,0c1.2-3,1.8-6.4,0.9-10.1c-0.9-3.7-3.6-7.5-8.6-11.2l0,0c-0.7-0.5-1.4-1-2.2-1.4
			C123.2,640.4,55,608,0.2,600.1l-0.9-0.2l0.3-2.4l0.9,0.2c55,7.9,123.5,40.4,131.9,44.6l0,0c0.9,0.5,1.7,1,2.5,1.6
			c5.5,4,8.3,8.3,9.3,12.5c1.1,4.2,0.5,8.2-0.9,11.9c-2.9,7.2-1.6,16,3.2,21.6c3.6,4.3,5.3,8.3,5.4,12c0.1,3.5-1.2,6.9-3.7,9.9
			c-3.6,4.3-9.5,8.3-17,11.7C89.9,742.6-0.2,748.9-0.2,748.9L-0.2,748.9z M-0.3,717.3h-0.9l-0.1-2.4h0.9c0,0,51.4-2.2,80.3-10.6
			c7.4-2.1,13.2-4.5,16.3-7.5c1.8-1.6,2.6-3.4,2.1-5.4c-1.6-6.4-0.7-13.5,2.6-18.8c0.8-1.3,1.3-2.9,1.2-4.5c-0.3-2.6-2-5.6-5.9-8.8
			c-10.8-9-37.8-19.9-96.8-32.1l-0.9-0.2l0.3-2.4l0.9,0.2c59.4,12.2,86.5,23.4,97.4,32.4c4.7,3.8,6.6,7.5,6.8,10.6
			c0.1,2.2-0.4,4.2-1.4,6.1l0,0c-2.9,4.8-3.8,11.1-2.2,16.7c0.7,2.4,0,4.8-1.7,6.9c-1.6,1.8-4,3.5-7.1,5.1
			C67.3,714.2-0.3,717.3-0.3,717.3z M-0.3,693.9h-0.9l-0.1-2.4h0.9c0,0,28.8-1.1,44.1-6.4c3.2-1.1,5.7-2.4,7.2-3.8
			c1.2-1.1,1.7-2.4,1.1-4c-0.9-2.2-3.8-4.6-9-7.7c-8.2-4.6-22-10.1-43.7-16.5l-0.9-0.3l0.4-2.2l0.9,0.3c21.9,6.6,35.8,12,44.1,16.7
			c5.8,3.2,8.8,6.3,9.9,8.7c0.9,2.1,0.7,4-0.5,5.8c-1.6,2.1-4.7,4-9,5.4C28.9,692.8-0.3,693.9-0.3,693.9L-0.3,693.9z M1318,439.9
			c0,0.2,0,0.6,0,0.6c4.7,24-11.2,38.5-32.7,47c-31.5,12.5-74.8,12.3-78.6,12.2h-0.4c-40,0.6-66.1,7.1-83,15.9
			c-16.6,8.7-24.1,19.6-27.1,29.8c-4,13.5,0,25.5,1.2,28.5c0.3,0.8,0.5,1.4,0.8,2.2c1.2,3.8,4.5,7.5,9.2,11.2
			c7.5,5.8,18.8,11.4,32.3,16.8c52.4,20.8,136.7,37.2,154.3,40.4c2.6,0.5,5.1,1.6,7.2,3.5c10.9,9,14.1,18.3,13.6,26.4
			c-0.8,12.3-10.3,22.8-16.2,28l0,0c-2.9,2.6-6.3,4-10,4l0,0c-111.8,1.8-174.7,32.5-187.4,39.4c-1.7,1-3.4,2.4-4.7,4.2
			c-4.9,6.4-7.4,12.5-7.6,18.4c-0.3,6.9,2.2,13.5,7,19.9c4.9,6.6,12,12.7,20.7,18.6c58.7,39.6,188.9,63.8,188.9,63.8l-0.1,2.4
			c0,0,0.9-2.9,0.9-1.1c0,0.6-0.4,1.1-0.8,1.1c0,0-0.1,0-0.3,0h-0.1c-7.9,1.4-131.7,25.3-188.5,63.6c-8.7,5.9-15.8,12-20.7,18.6
			c-4.7,6.4-7.2,13-7,19.9c0.3,5.9,2.6,12,7.6,18.4c1.3,1.8,2.9,3.2,4.7,4.2c12.6,6.9,75.7,37.7,187.4,39.4l0,0c3.6,0,7.1,1.4,10,4
			l0,0c5.9,5.3,15.4,15.7,16.2,28c0.5,8.3-2.6,17.5-13.6,26.4c-2.2,1.8-4.7,3-7.2,3.5c-17.6,3.4-101.9,19.6-154.3,40.4
			c-13.4,5.3-24.8,10.9-32.3,16.8c-4.9,3.7-8.2,7.5-9.2,11.2c-0.3,0.8-0.5,1.4-0.8,2.2c-1.2,3-5.1,15.1-1.2,28.5
			c3,10.3,10.5,21.2,27.1,29.8c16.9,8.8,42.9,15.1,83,15.7h0.4c3.8,0,47.1-0.3,78.6,12.2c21.5,8.5,37.3,22.9,32.7,47
			c0,0.2,0,0.6,0,0.6c4.7,24-11.2,38.5-32.7,47c-31.5,12.5-74.8,12.3-78.6,12.2h-0.4c-40,0.6-66.1,7.1-83,15.7
			c-16.6,8.7-24.1,19.6-27.1,29.8c-4,13.5,0,25.5,1.2,28.5c0.3,0.8,0.5,1.4,0.8,2.2c1.2,3.8,4.5,7.5,9.2,11.4
			c7.5,5.8,18.8,11.5,32.3,16.8c52.4,20.8,136.7,37.2,154.3,40.4c2.6,0.5,5.1,1.6,7.2,3.5c10.9,9,14.1,18.3,13.6,26.4
			c-0.8,12.3-10.3,22.8-16.2,28l0,0c-2.9,2.6-6.3,4-10,4l0,0c-111.8,1.8-174.7,32.5-187.4,39.4c-1.7,1-3.4,2.4-4.7,4.2
			c-4.9,6.3-7.4,12.5-7.6,18.4c-0.3,6.9,2.2,13.5,7,19.9c4.9,6.6,12,12.7,20.7,18.6c58.7,39.6,188.9,63.8,188.9,63.8l0.9,0.2
			l-0.3,2.4l-0.9-0.2c0,0-130.6-24.4-189.6-63.9c-9-6.1-16.2-12.3-21.2-19.1c-5.1-6.9-7.8-14.1-7.5-21.6c0.3-6.4,2.9-13.1,8.2-19.9
			l0,0c1.4-1.9,3.3-3.5,5.3-4.6l0,0c12.6-6.9,76-37.8,188.2-39.6c3.2,0,6.3-1.3,8.8-3.5c4-3.5,9.6-9.5,12.9-16.7
			c2.1-4.6,3.3-9.9,2.2-15.4c-1.1-6.1-4.9-12.3-12.4-18.6l0,0c-2-1.6-4.2-2.7-6.5-3l0,0c-17.6-3.4-102-19.6-154.6-40.5
			c-13.6-5.4-25-11.2-32.7-17c-5.3-4-8.8-8.3-10-12.5l0,0c-0.3-0.6-0.4-1.3-0.7-2.1l0,0c-1.2-3.2-5.4-16-1.2-30.3
			c3.2-10.6,10.8-22.1,28.2-31.3c17-8.8,43.3-15.2,83.6-16h0.5c3.7,0,46.7,0.3,77.9-12.2c20.4-8,35.8-21.5,31.3-44.2
			c0-0.2,0-0.2,0-0.3v-1l0.9,1.1l-0.9-1.4c4.6-22.8-10.9-36.2-31.3-44.2c-31.2-12.3-74.1-12.2-77.9-12.2h-0.5
			c-40.3-0.6-66.6-7.1-83.6-16c-17.2-9-25-20.5-28.2-31.3c-4.2-14.3,0-27.1,1.2-30.3l0,0c0.3-0.6,0.5-1.3,0.7-2.1l0,0
			c1.3-4.2,4.7-8.3,10-12.5c7.6-5.9,19.1-11.7,32.7-17c52.5-21,136.9-37.2,154.6-40.5l0,0c2.4-0.5,4.5-1.4,6.5-3l0,0
			c7.6-6.3,11.3-12.5,12.4-18.6c1.1-5.4-0.1-10.7-2.2-15.4c-3.3-7.2-9-13.1-12.9-16.7c-2.6-2.2-5.7-3.5-8.8-3.5
			c-112.2-1.8-175.5-32.7-188.2-39.6l0,0c-2-1.1-3.8-2.7-5.3-4.6l0,0c-5.3-6.9-7.8-13.5-8.2-19.9c-0.4-7.5,2.4-14.7,7.5-21.6
			c5-6.7,12.2-13.1,21.2-19.1c51.4-34.6,157.3-57.5,183.5-62.8c-26.2-5.3-132.2-28.2-183.5-62.8c-9-6.1-16.2-12.3-21.2-19.1
			c-5.1-6.9-7.8-14.1-7.5-21.6c0.3-6.4,2.9-13,8.2-19.9l0,0c1.4-1.9,3.3-3.5,5.3-4.6l0,0c12.6-6.9,76-37.8,188.2-39.6
			c3.2,0,6.3-1.3,8.8-3.5c4-3.5,9.6-9.5,12.9-16.7c2.1-4.6,3.3-9.9,2.2-15.4c-1.1-6.1-4.9-12.3-12.4-18.6l0,0c-2-1.6-4.2-2.7-6.5-3
			l0,0c-17.6-3.4-102-19.6-154.6-40.5c-13.6-5.4-25-11.2-32.7-17c-5.3-4-8.8-8.3-10-12.5l0,0c-0.3-0.6-0.4-1.4-0.7-2.1l0,0
			c-1.2-3.2-5.4-16-1.2-30.3c3.2-10.6,10.8-22.3,28.2-31.3c17-8.8,43.3-15.2,83.6-16h0.5c3.7,0,46.7,0.3,77.9-12.2
			c20.4-8,35.8-21.5,31.3-44.2c0-0.2,0-0.2,0-0.3v-1l0.9,1.1l-0.9-1.4c4.6-22.8-10.9-36.2-31.3-44.2c-31.2-12.3-74.1-12.2-77.9-12.2
			h-0.5c-40.3-0.6-66.6-7.1-83.6-16c-17.2-9-25-20.5-28.2-31.3c-4.2-14.3,0-27.1,1.2-30.3l0,0c0.3-0.6,0.5-1.3,0.7-2.1l0,0
			c1.3-4.2,4.7-8.3,10-12.5c7.6-5.9,19.1-11.7,32.7-17c52.5-21,136.9-37.2,154.6-40.5l0,0c2.4-0.5,4.5-1.4,6.5-3l0,0
			c7.6-6.3,11.3-12.5,12.4-18.6c1.1-5.4-0.1-10.7-2.2-15.4c-3.3-7.2-9-13.1-12.9-16.7c-2.6-2.2-5.7-3.5-8.8-3.5
			c-112.2-1.8-175.5-32.7-188.2-39.6l0,0c-2-1.1-3.8-2.7-5.3-4.6l0,0c-5.3-6.9-7.8-13.5-8.2-19.9c-0.4-7.5,2.4-14.7,7.5-21.6
			c5-6.7,12.2-13,21.2-19.1c59-39.7,189.6-64.1,189.6-64.1l0.9-0.2l0.3,2.4l-0.9,0.2c0,0-130.2,24.2-188.9,63.8
			c-8.7,5.9-15.8,12-20.7,18.6c-4.7,6.3-7.2,13-7,19.9c0.3,5.9,2.6,12,7.6,18.4c1.3,1.8,2.9,3.2,4.7,4.2
			c12.6,6.9,75.7,37.7,187.4,39.4l0,0c3.6,0.2,7.1,1.4,10,4l0,0c5.9,5.4,15.4,15.9,16.2,28c0.5,8.2-2.6,17.5-13.6,26.4
			c-2.2,1.8-4.7,3-7.2,3.5c-17.6,3.4-101.9,19.6-154.3,40.4c-13.4,5.3-24.8,11.1-32.3,16.8c-4.9,3.7-8.2,7.5-9.2,11.2
			c-0.3,0.8-0.5,1.4-0.8,2.2c-1.2,3-5.1,15.1-1.2,28.5c3,10.3,10.5,21.2,27.1,29.8c16.9,8.8,42.9,15.1,83,15.9h0.4
			c3.8,0,47.1-0.3,78.6,12.2C1306.8,401.4,1322.7,416,1318,439.9L1318,439.9z M1918.6,15.3h0.9l0.1,2.4h-0.9c0,0-73.2,5.4-116.8,29
			c-8.7,4.6-16.1,10.1-21.6,16.3c-5.4,6.1-8.8,13-9.5,20.8c-0.9,10.6,3.2,22.6,14,36.7c0.4,0.5,0.8,1,1.2,1.4
			c6.2,6.3,58.1,56.9,132.9,64.7l0.9,0.2l-0.1,2.4l-0.9-0.2c-75.3-7.9-127.7-59-133.9-65.2l0,0c-0.4-0.5-0.9-1-1.3-1.6l0,0
			c-11.3-14.7-15.4-27.6-14.4-38.6c1.4-16.5,14.4-29.3,32.3-39.1C1845,20.8,1918.6,15.3,1918.6,15.3L1918.6,15.3z M1918.5,36.6
			l0.9-0.2l0.4,2.2l-0.9,0.3c0,0-55.6,14.3-83,38.6c-8.8,7.9-14.7,16.8-14.5,26.8c0.3,9.9,6.8,20.8,22.4,32.5c0.4,0.3,0.9,0.6,1.3,1
			c5.1,3,39.9,22.8,73.5,25.5l0.9,0.2l-0.1,2.4l-0.9-0.2c-34-2.7-69-22.6-74.3-25.6c-0.5-0.3-1.1-0.6-1.4-1l0,0
			c-16.3-12.3-23-24-23.3-34.5c-0.3-10.6,5.8-20.2,15.3-28.7C1862.4,51.2,1918.5,36.8,1918.5,36.6L1918.5,36.6z M1918.4,66.4
			l1.1-0.5l0.8,2.4l-1.1,0.5c0,0-22,9.8-33.7,22.6c-4.7,5.1-7.8,10.7-6.5,16.3c0.8,3.2,2.9,6.4,6.8,9.6c6.2,5,16.7,9.8,33.3,13.9
			l1.1,0.3l-0.4,2.6l-1.1-0.3c-16.9-4.3-27.7-9.3-34-14.4c-4.5-3.5-6.8-7.4-7.6-11.1c-1.4-6.6,1.7-13.1,7.1-19.1
			C1895.9,76.5,1918.4,66.4,1918.4,66.4L1918.4,66.4z M1784.6,339.7c0.3,0.3,67.3,55.3,134.2,62.8l0.9,0.2l-0.1,2.4l-0.9-0.2
			c-67.4-7.5-134.8-63-134.8-63c-0.1,0-0.1-0.2-0.1-0.2c-0.1,0-0.1-0.2-0.3-0.2l0,0c-9-7.1-11.1-18.6-11.2-27.4l0,0
			c-0.1-5.9-2.4-11.5-6.3-15.1c-2.9-2.6-4.2-5.3-4.3-8c0-1.6,0.4-3.4,1.3-5.1c1.2-2.2,3.3-4.6,6.2-7.1
			c30.7-25.2,149.6-51.4,149.6-51.4l0.9-0.2l0.4,2.4l-0.9,0.2c0,0-118.2,26-148.8,51c-2.2,1.8-4,3.5-5.1,5.4
			c-1.1,1.6-1.6,3.2-1.6,4.8c0,2.1,1.2,4.2,3.6,6.1l0,0c4.5,4,7.1,10.3,7.1,16.8c0.1,8.2,2,19.1,10.3,25.5
			C1784.5,339.5,1784.5,339.5,1784.6,339.7c0,0,0.1,0,0.1,0.2L1784.6,339.7L1784.6,339.7z M1918.4,253.3l0.9-0.3l0.5,2.2l-0.9,0.3
			c0,0-51.2,19.4-78.3,44.7c-5.7,5.3-10.4,10.9-13.2,16.7c-2.8,5.4-3.7,11.2-2.2,16.8c1.7,6.6,6.8,13.1,16.2,19.6
			c14.5,9.9,39.1,19.2,77.4,27.6l0.9,0.2l-0.4,2.4l-0.9-0.2c-38.7-8.3-63.5-18-78.1-27.9c-10-6.7-15.3-13.9-17.1-21
			c-1.7-6.3-0.7-12.7,2.4-18.8c2.9-5.9,7.8-11.9,13.7-17.3C1866.8,273,1918.4,253.3,1918.4,253.3L1918.4,253.3z M1918.2,280.2
			l0.9-0.5l0.8,2.2l-0.9,0.5c0,0-21.3,11.5-32.7,25.6c-4.6,5.8-7.6,11.9-6.3,18c0.7,3.4,2.8,6.7,6.6,9.8c6.1,5,16.2,9.5,32.1,13
			l0.9,0.2l-0.4,2.2l-0.9-0.2c-16.3-3.7-26.7-8.3-32.9-13.3c-4.3-3.5-6.6-7.4-7.4-11.2c-1.4-6.7,1.7-13.8,6.8-20.2
			C1896.5,291.9,1918.2,280.2,1918.2,280.2L1918.2,280.2z M1918.8,862.8h0.9l-0.1,2.4h-0.9c0,0-73.6-5.4-117.4-29.3
			c-17.9-9.8-30.8-22.6-32.3-39.1c-0.9-11.1,3.2-23.9,14.4-38.6l0,0c0.4-0.5,0.9-1.1,1.3-1.6l0,0c6.2-6.3,58.6-57.4,133.9-65.2
			l0.9-0.2l0.1,2.4l-0.9,0.2c-74.7,7.7-126.7,58.5-132.9,64.7c-0.4,0.5-0.8,1-1.2,1.4c-10.8,14.1-14.9,26.1-14,36.7
			c0.7,7.9,4.2,14.7,9.5,20.8c5.5,6.3,13,11.7,21.6,16.3C1845.6,857.4,1918.8,862.8,1918.8,862.8L1918.8,862.8z M1918.9,841.5
			l0.9,0.3l-0.4,2.4l-0.9-0.3c0,0-56.1-14.4-83.6-39.1c-9.5-8.5-15.5-18.1-15.3-28.7c0.3-10.6,7-22.1,23.3-34.5l0,0
			c0.5-0.3,1.1-0.6,1.4-1c5.1-3,40.3-22.9,74.3-25.6h0.9l0.1,2.4h-0.9c-33.6,2.7-68.3,22.4-73.5,25.5c-0.4,0.3-0.9,0.6-1.3,1
			c-15.5,11.7-22.1,22.6-22.4,32.5c-0.3,9.9,5.5,18.9,14.5,26.8C1863.2,827.2,1918.9,841.5,1918.9,841.5L1918.9,841.5z M1919,811.5
			l1.1,0.5l-0.8,2.4l-1.1-0.5c0,0-22.5-10.1-34.4-23.1c-5.4-5.9-8.6-12.5-7.1-19.1c0.8-3.7,3.2-7.5,7.6-11.1
			c6.3-5.1,17.1-10.1,34-14.4l1.1-0.3l0.4,2.6l-1.1,0.3c-16.5,4.2-27.1,9-33.3,13.9c-4,3.2-6.1,6.4-6.8,9.6
			c-1.3,5.6,1.8,11.2,6.5,16.3C1897,801.8,1919,811.5,1919,811.5L1919,811.5z M1784.6,540.8l0.1-0.2c-0.1,0.2-0.3,0.2-0.3,0.3
			c0,0,0,0-0.1,0c-8.3,6.4-10.3,17.3-10.3,25.5c-0.1,6.7-2.8,13-7.1,16.8l0,0c-2.2,1.9-3.4,4-3.6,6.1c0,1.6,0.5,3.2,1.6,4.8
			c1.2,1.8,2.9,3.5,5.1,5.4c30.5,25,148.8,51,148.8,51l0.9,0.2l-0.4,2.4l-0.9-0.2c0,0-118.9-26.3-149.6-51.4c-2.9-2.4-5-4.8-6.2-7.1
			c-0.9-1.8-1.3-3.4-1.3-5.1c0-2.7,1.3-5.4,4.3-8c3.9-3.5,6.3-9.1,6.3-15.1l0,0c0.1-8.8,2.2-20.5,11.2-27.4c0.1,0,0.1-0.2,0.3-0.2
			l0.1-0.2c0,0,67.4-55.6,134.8-63.1l0.9-0.2l0.1,2.4l-0.9,0.2C1851.9,485.4,1784.9,540.5,1784.6,540.8L1784.6,540.8z M1919,625
			l0.9,0.3l-0.5,2.2l-0.9-0.3c0,0-51.6-19.6-78.9-45c-5.9-5.6-10.8-11.4-13.7-17.3c-3-6.1-4.1-12.5-2.4-18.8
			c1.8-7.1,7.1-14.1,17.1-21c14.6-9.9,39.4-19.6,78.1-27.9l0.9-0.2l0.4,2.4l-0.9,0.2c-38.4,8.3-62.9,17.6-77.4,27.6
			c-9.3,6.4-14.5,13-16.2,19.6c-1.4,5.6-0.5,11.2,2.2,16.8c2.8,5.8,7.5,11.4,13.2,16.7C1867.7,605.4,1918.9,625,1919,625L1919,625z
			 M1919,598.2l0.9,0.5l-0.8,2.2l-0.9-0.5c0,0-21.7-11.9-33.2-26.1c-5.1-6.4-8.2-13.5-6.8-20.2c0.8-3.8,3-7.7,7.4-11.2
			c6.2-5.1,16.6-9.6,32.9-13.3l0.9-0.2l0.4,2.4l-0.9,0.2c-15.9,3.5-26.2,8-32.1,13c-3.8,3.2-5.9,6.4-6.6,9.8
			c-1.2,5.9,1.7,12.2,6.3,18C1897.7,586.7,1919,598.2,1919,598.2L1919,598.2z M-0.3,997.8h-0.9l0.1-2.4h0.9c0,0,90.1,6.3,131.3,25.2
			c7.5,3.5,13.4,7.4,17,11.7c2.5,3,3.8,6.4,3.7,9.9c-0.1,3.7-1.7,7.7-5.4,12c-4.7,5.6-6.1,14.3-3.2,21.6c1.4,3.5,2.1,7.7,0.9,11.9
			c-1.1,4-3.8,8.3-9.3,12.5c-0.8,0.6-1.7,1.1-2.5,1.6l0,0c-8.4,4.2-76.9,36.5-131.9,44.6l-0.9,0.2l-0.3-2.4l0.9-0.2
			c54.8-7.9,123-40.2,131.4-44.4c0.8-0.3,1.6-0.8,2.2-1.3l0,0c5-3.7,7.5-7.5,8.6-11.2c0.9-3.5,0.4-7.1-0.8-10.1l0,0
			c-3.2-8.2-1.8-18,3.6-24.4l0,0c3.2-3.7,4.7-7.1,4.7-10.4c0.1-2.9-1.1-5.6-3.2-8.2c-3.3-4.2-9.1-7.9-16.2-11.2
			C89.4,1003.9-0.3,997.8-0.3,997.8L-0.3,997.8z M-0.3,1029.3h-0.9v-2.4h0.9c0,0,67.4,2.9,91.8,14.6c3.2,1.6,5.7,3.2,7.1,5.1
			c1.7,2.1,2.4,4.5,1.7,6.9c-1.4,5.6-0.7,11.9,2.2,16.7l0,0c1.1,1.8,1.7,3.8,1.4,6.1c-0.3,3-2.1,6.7-6.8,10.6
			c-10.9,9-38.1,20.2-97.4,32.4l-0.9,0.2l-0.3-2.4l0.9-0.2c59-12.2,85.8-23.1,96.8-32.1c4.1-3.4,5.8-6.3,5.9-8.8
			c0.1-1.6-0.4-3-1.2-4.5c-3.3-5.4-4.2-12.3-2.6-18.8c0.5-2.1-0.4-3.8-2.1-5.4c-3.2-2.9-9.1-5.4-16.3-7.5
			C51.1,1031.4-0.3,1029.3-0.3,1029.3L-0.3,1029.3z M-0.3,1052.7h-0.9v-2.4h0.9c0,0,29.1,1.1,44.6,6.6c4.2,1.4,7.4,3.4,9,5.4
			c1.2,1.8,1.6,3.7,0.5,5.8c-1.1,2.6-4.1,5.4-9.9,8.7c-8.3,4.6-22.3,10.1-44.1,16.7l-0.9,0.3l-0.5-2.2l0.9-0.3
			c21.7-6.4,35.6-12,43.7-16.5c5.1-2.9,8-5.4,9-7.7c0.7-1.6,0.1-2.9-1.1-4c-1.6-1.4-4.1-2.7-7.2-3.8
			C28.6,1053.7-0.3,1052.7-0.3,1052.7L-0.3,1052.7z M140.7,1303.1h-0.9v-0.5c-4.1-3.4-6.5-9.3-6.1-15.4l0,0
			c0.5-6.4,3.8-11.9,8.7-14.1l0,0c4.7-2.2,8-4.5,10-6.7c1.7-1.9,2.4-3.8,2.1-5.6c-0.3-1.6-1.3-3.2-2.8-4.6
			c-2.5-2.6-6.3-5.1-11.2-7.5c-38.8-19.2-141-33.5-141-33.5l-0.9-0.2l0.3-2.4l0.9,0.2c0,0,102.6,14.4,141.5,33.7
			c5.1,2.6,9.1,5.1,11.7,7.9c1.8,1.9,3,4,3.4,5.9c0.4,2.1,0,4.3-1.4,6.4c-1.8,2.9-5.7,5.8-11.9,8.7c-4.2,2.1-7.1,6.7-7.5,12.2
			s1.7,10.7,5.5,13.6L140.7,1303.1L140.7,1303.1z M103.2,1303.1h-0.9v-0.3c-4.7-1.9-8.3-6.4-9.7-12.2c-1.4-6.1-0.3-12.7,3.3-17.3
			l0,0c0.7-1,1.1-1.8,1.2-2.7c0-1-0.4-1.9-1.2-2.9c-0.9-1.1-2.2-2.1-4-3.2C71.1,1252.3-0.3,1247-0.3,1247h-0.9l0.1-2.4h0.9
			c0,0,71.9,5.4,92.8,17.6c2.4,1.4,4.1,2.9,5.1,4.5c0.8,1.3,1.2,2.4,1.1,3.8c0,1.3-0.5,2.7-1.6,4.2c-3,4-4.2,9.8-2.9,15.1l0,0
			c1.3,5.3,4.7,9.5,9.1,10.9c0.3,0,0.5,0.3,0.7,0.6s0.1,0.6,0.1,1C103.7,1303.4,103.2,1302.9,103.2,1303.1L103.2,1303.1z
			 M-0.3,1268.5h-0.9l0.1-2.4h0.9c0,0,31.6,2.7,45.8,10.9c2.5,1.4,4.5,3,5.8,4.8c0.9,1.3,1.4,2.7,1.6,4.2c0.1,2.4-0.8,5-3.6,7.9
			c-2.5,2.7-6.7,5.8-13,9.1l-0.9,0.5l-0.8-2.2l0.9-0.5c7.1-3.7,11.5-7.1,13.7-10.1c1.3-1.8,1.8-3.2,1.6-4.8
			c-0.1-1.3-0.8-2.4-1.7-3.4c-1.1-1.1-2.6-2.2-4.3-3.2C30.9,1271.2-0.3,1268.6-0.3,1268.5L-0.3,1268.5z M-0.8,1707.3l0.4-2.2
			l105.1,29.8l-0.4,2.2L-0.8,1707.3z M-0.9,1677.5l0.7-2.2l147.9,59.8l-0.7,2.2L-0.9,1677.5z M-0.2,1612.4h-0.9l-0.1-2.4h0.9
			c0,0,89.7-6.1,130.6-25c7.2-3.4,12.9-7.1,16.3-11.2c2.1-2.6,3.2-5.3,3.2-8.2c-0.1-3.2-1.6-6.6-4.7-10.3l0,0
			c-5.4-6.4-6.8-16.2-3.6-24.4l0,0c1.2-3,1.8-6.4,0.9-10.1c-0.9-3.7-3.6-7.5-8.6-11.1l0,0c-0.7-0.5-1.4-1-2.2-1.3
			c-8.4-4.2-76.6-36.5-131.4-44.4l-0.9-0.2l0.3-2.4l0.9,0.2c55,7.9,123.5,40.4,131.9,44.6l0,0c0.9,0.5,1.7,1,2.5,1.6
			c5.5,4,8.3,8.3,9.3,12.5c1.1,4.2,0.5,8.3-0.9,11.9c-2.9,7.2-1.6,16,3.2,21.6c3.6,4.3,5.3,8.2,5.4,12c0.1,3.5-1.2,6.9-3.7,9.9
			c-3.6,4.3-9.5,8.3-17,11.7C89.9,1606.2-0.2,1612.4-0.2,1612.4L-0.2,1612.4z M-0.3,1580.8h-0.9l-0.1-2.4h0.9
			c0,0,51.4-2.2,80.3-10.6c7.4-2.1,13.2-4.5,16.3-7.5c1.8-1.6,2.6-3.4,2.1-5.4c-1.6-6.4-0.7-13.3,2.6-18.8c0.8-1.3,1.3-2.9,1.2-4.5
			c-0.3-2.7-2-5.6-5.9-8.8c-10.8-9-37.8-19.9-96.8-32.1l-0.9-0.2l0.3-2.4l0.9,0.2c59.4,12.2,86.5,23.4,97.4,32.4
			c4.7,3.8,6.6,7.5,6.8,10.6c0.1,2.2-0.4,4.3-1.4,6.1l0,0c-2.9,4.8-3.8,11.1-2.2,16.7c0.7,2.6,0,4.8-1.7,6.9c-1.6,1.8-4,3.5-7.1,5.1
			C67.3,1578-0.3,1580.8-0.3,1580.8L-0.3,1580.8z M-0.3,1557.4h-0.9l-0.1-2.4h0.9c0,0,28.8-1.1,44.1-6.4c3.2-1.1,5.7-2.4,7.2-3.8
			c1.2-1.1,1.7-2.4,1.1-4c-0.9-2.2-3.8-4.6-9-7.7c-8.2-4.6-22-10.1-43.7-16.5l-0.9-0.3l0.4-2.2l0.9,0.3c21.9,6.6,35.8,12,44.1,16.7
			c5.8,3.2,8.8,6.3,9.9,8.7c0.9,2.1,0.7,4-0.5,5.8c-1.6,2.1-4.7,4-9,5.4C28.9,1556.3-0.3,1557.4-0.3,1557.4L-0.3,1557.4z
			 M139.8,1305.3v-0.6l0.1,0.3c0.1,0,0.1-0.2,0.3-0.2l0.5,2.2l0.5-0.2c-3.8,2.9-5.9,8.2-5.5,13.6c0.4,5.4,3.3,10.1,7.5,12.2
			c6.2,2.9,10,5.9,11.9,8.7c1.4,2.1,1.8,4.3,1.4,6.4c-0.4,1.9-1.6,4-3.4,5.9c-2.6,2.7-6.6,5.3-11.7,7.9
			c-39,19.4-141.5,33.7-141.5,33.7l-0.9,0.2l-0.3-2.4l0.9-0.2c0,0,102.2-14.3,141-33.5c4.9-2.4,8.7-5,11.2-7.5
			c1.4-1.6,2.5-3,2.8-4.6c0.4-1.9-0.3-3.7-2.1-5.6c-2-2.2-5.3-4.5-10-6.7l0,0c-4.9-2.4-8.2-7.9-8.7-14.1l0,0
			C133.4,1314.6,135.6,1308.7,139.8,1305.3L139.8,1305.3z M102.2,1305.2v-0.5l0.1,0.3c0.1,0,0.4-0.2,0.7-0.2l-0.4,0.8l0.5,1.4
			c0,0,0.4-3.2,0.9-1.4c0.1,0.3,0.1,0.6-0.1,1c-0.3,0.3-0.4,0.5-0.7,0.6c-4.3,1.4-7.9,5.6-9.1,10.9l0,0c-1.3,5.3-0.1,11.1,2.9,15.1
			c1.1,1.4,1.6,2.9,1.6,4.2c0,1.3-0.3,2.6-1.1,3.8c-1.1,1.6-2.8,3-5.1,4.5c-20.9,12.3-92.8,17.6-92.8,17.6h-0.9l-0.1-2.4h0.9
			c0,0,71.2-5.3,92.2-17.5c1.7-1,3-2.1,4-3.2c0.8-1,1.2-1.8,1.2-2.9c0-0.8-0.4-1.8-1.2-2.7l0,0c-3.6-4.6-4.7-11.2-3.3-17.3
			C93.9,1311.4,97.5,1306.9,102.2,1305.2L102.2,1305.2z M-0.2,1341.5h-0.9l-0.1-2.4h0.9c0,0,31.2-2.7,45.2-10.6
			c1.8-1,3.3-2.1,4.3-3.2c0.9-1.1,1.6-2.1,1.7-3.4c0.1-1.4-0.3-3-1.6-4.8c-2.2-3-6.6-6.4-13.7-10.1l-0.9-0.5l0.8-2.2l0.9,0.5
			c7.5,4,12.1,7.5,14.4,10.7c1.7,2.4,2.4,4.6,2.1,6.7c-0.1,1.4-0.8,2.7-1.7,4c-1.2,1.6-3.2,3.2-5.5,4.5
			C31.4,1338.8-0.2,1341.5-0.2,1341.5L-0.2,1341.5z M1918.6,879h0.9l0.1,2.4h-0.9c0,0-73.2,5.4-116.8,29.2
			c-8.7,4.6-16.1,10.1-21.6,16.3c-5.4,6.1-8.8,13-9.5,20.8c-0.9,10.6,3.2,22.6,14,36.7c0.4,0.5,0.8,1,1.2,1.4
			c6.2,6.3,58.1,56.9,132.9,64.7l0.9,0.2l-0.1,2.4l-0.9-0.2c-75.3-7.9-127.7-59-133.9-65.2l0,0c-0.4-0.5-0.9-1-1.3-1.6l0,0
			c-11.3-14.7-15.4-27.6-14.4-38.6c1.4-16.5,14.4-29.3,32.3-39.1C1845,884.5,1918.6,879,1918.6,879L1918.6,879z M1918.5,900.3
			l0.9-0.3l0.4,2.4l-0.9,0.3c0,0-55.6,14.3-83,38.6c-8.8,7.9-14.7,16.8-14.5,26.8c0.3,9.9,6.8,20.8,22.4,32.5c0.4,0.3,0.9,0.6,1.3,1
			c5.1,3,39.9,22.8,73.5,25.5h0.9l-0.1,2.4h-0.9c-34-2.7-69-22.6-74.3-25.6c-0.5-0.3-1.1-0.6-1.4-1l0,0c-16.3-12.3-23-24-23.3-34.5
			c-0.3-10.6,5.8-20.4,15.3-28.7C1862.4,914.7,1918.5,900.3,1918.5,900.3L1918.5,900.3z M1918.4,930.1l1.1-0.5l0.8,2.4l-1.1,0.5
			c0,0-22,9.8-33.7,22.6c-4.7,5.1-7.8,10.7-6.5,16.3c0.8,3.2,2.9,6.4,6.8,9.6c6.2,5,16.7,9.8,33.3,13.9l1.1,0.3l-0.4,2.6l-1.1-0.3
			c-16.9-4.3-27.7-9.3-34-14.4c-4.5-3.5-6.8-7.4-7.6-11.1c-1.4-6.6,1.7-13.1,7.1-19.1C1895.9,940.1,1918.4,930.1,1918.4,930.1
			L1918.4,930.1z M1784.6,1203.4c0.3,0.3,67.3,55.3,134.2,62.8l0.9,0.2l-0.1,2.4l-0.9-0.2c-67.4-7.5-134.8-63-134.8-63
			c-0.1,0-0.1,0-0.1-0.2c-0.1,0-0.1-0.2-0.3-0.2l0,0c-9-6.9-11.1-18.6-11.2-27.4l0,0c-0.1-5.9-2.4-11.5-6.3-15.1
			c-2.9-2.6-4.2-5.3-4.3-8c0-1.6,0.4-3.4,1.3-5.1c1.2-2.4,3.3-4.6,6.2-7.1c30.7-25.2,149.6-51.4,149.6-51.4l0.9-0.2l0.4,2.4
			l-0.9,0.2c0,0-118.2,26-148.8,51c-2.2,1.8-4,3.5-5.1,5.3c-1.1,1.6-1.6,3.2-1.6,4.8c0,2.1,1.2,4.2,3.6,6.1l0,0
			c4.5,4,7.1,10.3,7.1,16.8c0.1,8.2,2,19.1,10.3,25.5C1784.5,1203.2,1784.5,1203.2,1784.6,1203.4c0,0,0.1,0,0.1,0.2L1784.6,1203.4
			L1784.6,1203.4z M1918.4,1117l0.9-0.3l0.5,2.2l-0.9,0.3c0,0-51.2,19.4-78.3,44.7c-5.7,5.3-10.4,10.9-13.2,16.7
			c-2.8,5.4-3.7,11.2-2.2,16.8c1.7,6.6,6.8,13.1,16.2,19.6c14.5,9.9,39.1,19.2,77.4,27.6l0.9,0.2l-0.4,2.4l-0.9-0.2
			c-38.7-8.3-63.5-18-78.1-27.9c-10-6.7-15.3-13.9-17.1-21c-1.7-6.3-0.7-12.7,2.4-18.8c2.9-5.9,7.8-11.7,13.7-17.3
			C1866.8,1136.6,1918.4,1117,1918.4,1117L1918.4,1117z M1918.2,1143.8l0.9-0.5l0.8,2.1l-0.9,0.5c0,0-21.3,11.5-32.7,25.6
			c-4.6,5.8-7.6,12-6.3,18c0.7,3.4,2.8,6.7,6.6,9.8c6.1,5,16.2,9.5,32.1,13l0.9,0.2l-0.4,2.4l-0.9-0.2c-16.3-3.7-26.7-8.3-32.9-13.3
			c-4.3-3.5-6.6-7.4-7.4-11.2c-1.4-6.7,1.7-13.8,6.8-20.2C1896.5,1155.6,1918.2,1143.8,1918.2,1143.8L1918.2,1143.8z M1918.8,1726.5
			h0.9l-0.1,2.4h-0.9c0,0-73.6-5.4-117.4-29.3c-17.9-9.8-30.8-22.6-32.3-39.1c-0.9-11.1,3.2-23.9,14.4-38.6l0,0
			c0.4-0.5,0.9-1.1,1.3-1.6l0,0c6.2-6.3,58.6-57.4,133.9-65.2l0.9-0.2l0.1,2.4l-0.9,0.2c-74.7,7.7-126.7,58.3-132.9,64.7
			c-0.4,0.5-0.8,1-1.2,1.4c-10.8,14.1-14.9,26.1-14,36.7c0.7,7.9,4.2,14.7,9.5,20.8c5.5,6.3,13,11.7,21.6,16.3
			C1845.6,1721.1,1918.8,1726.5,1918.8,1726.5L1918.8,1726.5z M1918.9,1705.2l0.9,0.3l-0.4,2.2l-0.9-0.3c0,0-56.1-14.4-83.6-39.1
			c-9.5-8.5-15.5-18.1-15.3-28.7c0.3-10.6,7-22.1,23.3-34.5l0,0c0.5-0.3,1.1-0.6,1.4-1c5.1-3,40.3-22.9,74.3-25.6h0.9l0.1,2.4h-0.9
			c-33.6,2.7-68.3,22.4-73.5,25.5c-0.4,0.3-0.9,0.6-1.3,1c-15.5,11.7-22.1,22.6-22.4,32.5c-0.3,9.9,5.5,18.9,14.5,26.8
			C1863.2,1690.8,1918.9,1705.2,1918.9,1705.2L1918.9,1705.2z M1919,1675.2l1.1,0.5l-0.8,2.4l-1.1-0.5c0,0-22.5-10.1-34.4-23.1
			c-5.4-5.9-8.6-12.5-7.1-19.1c0.8-3.7,3.2-7.5,7.6-11.1c6.3-5.1,17.1-10.1,34-14.4l1.1-0.3l0.4,2.6l-1.1,0.3
			c-16.5,4.2-27.1,9-33.3,13.9c-4,3.2-6.1,6.4-6.8,9.6c-1.3,5.6,1.8,11.2,6.5,16.3C1897,1665.5,1919,1675.2,1919,1675.2L1919,1675.2
			z M1784.6,1404.4l0.1-0.2c-0.1,0.2-0.3,0.2-0.3,0.3c0,0,0,0-0.1,0c-8.3,6.4-10.3,17.3-10.3,25.5c-0.1,6.7-2.8,13-7.1,16.8l0,0
			c-2.2,1.9-3.4,4-3.6,6.1c0,1.6,0.5,3.2,1.6,4.8c1.2,1.8,2.9,3.5,5.1,5.3c30.5,25,148.8,51,148.8,51l0.9,0.2l-0.4,2.2l-0.9-0.2
			c0,0-118.9-26.3-149.6-51.4c-2.9-2.4-5-4.8-6.2-7.1c-0.9-1.8-1.3-3.4-1.3-5.1c0-2.7,1.3-5.4,4.3-8c3.9-3.5,6.3-9.1,6.3-15.1l0,0
			c0.1-8.8,2.2-20.5,11.2-27.4c0.1,0,0.1-0.2,0.3-0.2l0.1-0.2c0,0,67.4-55.6,134.8-63l0.9-0.2l0.1,2.4l-0.9,0.2
			C1851.9,1349.1,1784.9,1404.1,1784.6,1404.4L1784.6,1404.4z M1919,1488.5l0.9,0.3l-0.5,2.2l-0.9-0.3c0,0-51.6-19.6-78.9-45.2
			c-5.9-5.6-10.8-11.4-13.7-17.3c-3-6.1-4.1-12.5-2.4-18.8c1.8-7.1,7.1-14.1,17.1-21c14.6-9.9,39.4-19.6,78.1-27.9l0.9-0.2l0.4,2.4
			l-0.9,0.2c-38.4,8.3-62.9,17.6-77.4,27.6c-9.3,6.4-14.5,13-16.2,19.6c-1.4,5.6-0.5,11.2,2.2,16.8c2.8,5.8,7.5,11.4,13.2,16.7
			C1867.7,1469.1,1918.9,1488.5,1919,1488.5L1919,1488.5z M1919,1461.8l0.9,0.5l-0.8,2.1l-0.9-0.5c0,0-21.7-11.7-33.2-26.1
			c-5.1-6.4-8.2-13.5-6.8-20.2c0.8-3.8,3-7.7,7.4-11.2c6.2-5.1,16.6-9.6,32.9-13.3l0.9-0.2l0.4,2.4l-0.9,0.2
			c-15.9,3.5-26.2,8-32.1,13c-3.8,3.2-5.9,6.4-6.6,9.8c-1.2,6.1,1.7,12.2,6.3,18C1897.7,1450.2,1919,1461.8,1919,1461.8L1919,1461.8
			z"/>
	</g>
</g>
<g id="Layer_2">
	<g id="Flower_00000046301666801641120990000001772011929299514508_" class="st0">
		<path class="st8" d="M1919.3,312.7H0v-165c5.7,0,11.5,0,17.2,0c634,0,1268.1,0,1902.1,0C1919.3,202.7,1919.3,257.7,1919.3,312.7z"
			/>
		<path class="st9" d="M6.2,163.2"/>
		<path class="st10" d="M29.5,156.4c3.4,0.1,10.1,2.6,13.6,3.6c1.6,0.5,4.9,1.4,9,2.9c5.7,1.9,7.8,2.9,9,3.6c3.3,1.7,6.8,4,7.9,4.8
			c2.2,1.9,5.9,9.8,4.5,6.6c1.9,4.6,1.5,8.4,1.1,10.8c0,0-0.1,0.9-1.7,7.1c-0.9,2.2-2.2,4.8-4,7.9c-0.6,1.1-3.1,5.5-7.3,10.8
			c-2.1,2.7-4.2,4.8-8.5,9.3c-3.9,4.1-5.9,6.2-7.9,7.9c-2.4,2-2.8,2-8.5,6.5c-3.4,2.7-5.1,4-6.2,5.1c-1.9,1.8-4.7,4.6-7.3,9.3
			s-3.7,8.9-4.5,12.2c-0.5,2-1.3,5.2-1.7,9.3c-0.8,8.8,0.7,15.8,0.6,15.8c-0.2,0-1.4-6.4-1.1-6.5c0.2-0.1,1.3,3.1,4.5,15.2"/>
		<path class="st10" d="M132.8,157.8c-3.4,0.1-10.1,2.6-13.6,3.6c-1.6,0.5-4.9,1.4-9,2.9c-5.7,1.9-7.8,2.9-9,3.6
			c-3.3,1.7-6.8,4-7.9,4.8c-2.2,1.9-5.9,9.8-4.5,6.6c-1.9,4.6-1.5,8.4-1.1,10.8c0,0,0.1,0.9,1.7,7.1c0.9,2.2,2.2,4.8,4,7.9
			c0.6,1.1,3.1,5.5,7.3,10.8c2.1,2.7,4.2,4.8,8.5,9.3c3.9,4.1,5.9,6.2,7.9,7.9c2.4,2,2.8,2,8.5,6.5c3.4,2.7,5.1,4,6.2,5.1
			c1.9,1.8,4.7,4.6,7.3,9.3s3.7,8.9,4.5,12.2c0.5,2,1.3,5.2,1.7,9.3c0.8,8.8-0.7,15.8-0.6,15.8c0.2,0,1.4-6.4,1.1-6.5
			c-0.2-0.1-1.3,3.1-4.5,15.2"/>
		<path class="st10" d="M173.8,161.5c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st10" d="M277.1,162.9c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st10" d="M324.6,162.1c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st10" d="M427.9,163.5c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st10" d="M467.7,164.2c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st10" d="M571,165.5c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9s3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st10" d="M611.9,166.3c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st10" d="M715.3,167.6c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st10" d="M762.7,167c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4c3.3,1.6,6.8,3.8,7.9,4.6
			c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6c-0.6,1.1-3.1,5.3-7.3,10.4
			c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9c-1.9,1.7-4.7,4.4-7.3,8.9
			c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st10" d="M866.1,168.4c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9s3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st10" d="M905.4,166.5c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9s-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st10" d="M1008.7,167.9c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st10" d="M1049.6,168.6c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,6.9,7,5.5,3.9c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-3.2,7.1-5,10
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st10" d="M1155,167.5c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st10" d="M1202.4,166.9c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st10" d="M1305.8,167c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,9.8,4.5,13c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,1.7-4.5,13.3"/>
		<path class="st10" d="M1347.5,167.6c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,1.3,7.9,2.2c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,7.7-7.3,12.8c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st10" d="M1496.7,161.4c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9s-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st10" d="M1600,162.8c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st10" d="M1455.6,163.3c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9s3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st10" d="M1639.2,163.3c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st10" d="M1752,161.8c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st10" d="M1790.2,162.3c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st10" d="M1896.3,160.2c-3.4,0.1-10.1,2.6-13.6,3.6c-1.6,0.5-4.9,1.4-9,2.9c-5.7,1.9-7.8,2.9-9,3.6
			c-3.3,1.7-6.8,4-7.9,4.8c-2.2,1.9-5.9,9.8-4.5,6.6c-1.9,4.6-1.5,8.4-1.1,10.8c0,0,0.1,0.9,1.7,7.1c0.9,2.2,2.2,4.8,4,7.9
			c0.6,1.1,3.1,5.5,7.3,10.8c2.1,2.7,4.2,4.8,8.5,9.3c3.9,4.1,5.9,6.2,7.9,7.9c2.4,2,2.8,2,8.5,6.5c3.4,2.7,5.1,4,6.2,5.1
			c1.9,1.8,4.7,4.6,7.3,9.3c2.6,4.7,3.7,8.9,4.5,12.2c0.5,2,1.3,5.2,1.7,9.3c0.8,8.8-0.7,15.8-0.6,15.8c0.2,0,1.4-6.4,1.1-6.5
			c-0.2-0.1-1.3,3.1-4.5,15.2"/>
	</g>
</g>
</svg>`,
    density: 0.5,
  },
  Plasterboard: {
    pattern: (
      baseColor: string,
    ) => `<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="15" fill="${baseColor}" />
          </svg>`,
    density: 0.5,
  },
  "Partition Uninsulated": {
    pattern: (
      baseColor: string,
    ) => `<svg width="827" height="200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1919.3 464.8" style="enable-background:new 0 0 1919.3 464.8;" xml:space="preserve">
<style type="text/css">
	.st0{display:none;}
	.st1{fill:none;stroke:${baseColor};stroke-width:30;stroke-miterlimit:10;}
	.st2{fill:none;stroke:${baseColor};stroke-width:9;stroke-miterlimit:10;}
	.st3{fill-rule:evenodd;clip-rule:evenodd;fill:#ECECEC;}
	.st4{display:inline;}
	.st5{fill-rule:evenodd;clip-rule:evenodd;stroke:${baseColor};stroke-width:21;stroke-miterlimit:10;}
	.st6{display:inline;fill:none;stroke:${baseColor};stroke-width:21;stroke-miterlimit:10;}
	.st7{display:inline;fill-rule:evenodd;clip-rule:evenodd;stroke:${baseColor};stroke-width:21;stroke-miterlimit:10;}
	.st8{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-miterlimit:10;}
	.st9{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-width:8;stroke-miterlimit:10;}
	.st10{display:inline;fill:none;stroke:${baseColor};stroke-width:8;stroke-miterlimit:10;}
	.st11{fill:none;stroke:${baseColor};stroke-width:50;stroke-miterlimit:10;}
	.st12{display:none;fill:none;stroke:${baseColor};stroke-width:45;stroke-miterlimit:10;}
	.st13{display:inline;fill:none;stroke:${baseColor};stroke-width:44;stroke-miterlimit:10;}
	.st14{display:inline;fill:none;stroke:${baseColor};stroke-width:40;stroke-miterlimit:10;}
	.st15{display:inline;fill:none;stroke:${baseColor};stroke-width:28;stroke-miterlimit:10;}
	.st16{fill:none;stroke:${baseColor};stroke-width:28;stroke-miterlimit:10;}
	.st17{display:inline;fill:none;stroke:${baseColor};stroke-width:14;stroke-miterlimit:10;}
	.st18{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-width:9;stroke-miterlimit:10;}
	.st19{fill:none;stroke:${baseColor};stroke-width:21;stroke-miterlimit:10;}
	.st20{display:none;fill:none;stroke:${baseColor};stroke-width:15;stroke-miterlimit:10;}
	.st21{display:none;fill:#DDDDDD;}
</style>
<g id="Layer_1">
	<g id="Concrete" class="st0">
		<line class="st1" x1="0" y1="155.5" x2="1919.3" y2="155.5"/>
		<line class="st2" x1="328.3" y1="0" x2="328.3" y2="151.6"/>
		<line class="st1" x1="0" y1="301.4" x2="1919.3" y2="301.4"/>
		<line class="st2" x1="328.3" y1="297.3" x2="328.3" y2="464.8"/>
		<line class="st2" x1="1005.9" y1="0" x2="1005.9" y2="151.6"/>
		<line class="st2" x1="1655.3" y1="0" x2="1655.3" y2="151.6"/>
		<line class="st2" x1="1005.9" y1="300.7" x2="1005.9" y2="464.8"/>
		<line class="st2" x1="1655.3" y1="297.3" x2="1655.3" y2="464.8"/>
		<rect y="145.6" class="st3" width="1919.3" height="163.8"/>
	</g>
	<g id="Layer_2_00000041294061573236642060000008068305606222413205_" class="st0">
		<g id="Layer_1-2" class="st4">
			<path class="st5" d="M581.6,148l-25.3,98.1c-0.4,1.6-1.8,2.6-3.5,2.6c0,0-392.3,1.1-411.8,1.1l0,0c-1.3,0-3.1-0.9-3.8-2.2
				c0,0,0,0,0-0.1c-0.3-0.6-0.4-1.4-0.4-2.1c-0.1-0.2-0.5-0.6-1-1c-1.2-0.9-3.2-1.9-6.3-2.8c-13.2-4-44.3-7.1-116.1-2.9l-3.6,0.2
				L9.4,232l3.6-0.2c73.4-4.3,105.2-0.9,118.7,3.2c7.9,2.4,10.8,5.5,11.8,7.9c37.5-0.1,372-1,406.2-1.1l28.1-108.6
				c0-0.1,0.1-0.2,0.1-0.3c2.3-6.1,3.4-11.5,3.5-16v-2.4c-0.2-5.7-1.9-10.3-4.4-13.9c-6.3-9-17-11.6-18.9-11.9L13.4,81.7H9.7v-0.1
				l0,0l0.1-3.4v-3.4h0.1v-0.1l3.6,0.1h654c6.9-5.2,10.8-14.7,13.2-24.3c3.8-15.1,3.6-30.4,3.5-32l-0.4-3.2h0.3v-0.3l3.5-0.1
				l3.5-0.4v0.3h0.3l0.1,3.2c0.2,1.6,2.3,17.7,8.7,29.5c3,5.6,6.8,10.3,12.3,11.4c5.7,1.1,12.5-1.7,21.3-10l0,0
				c2.8-2.7,6.5-4.1,10.5-4.1c15.9,0,66.2,0,85.6,0c5.7,0,10.9,2.7,14,7.2c4.9,7,17.5,17.1,46,9.7c1.6-0.9,3.3-1.9,5-2.6
				c8-3.4,16-4.3,16-4.3c0.2,0,0.3,0,0.5,0c0,0,62,0.7,151.4,1.9h157.2V14.8h7.2v43.8c210,2.5,452,5.5,479.7,5.7l0,0
				c2.3,0,4.4,0.5,6.5,1.3c0,0,0,0,0.1,0c18.5,7.2,31.2,16.1,39.6,25.7c0.7-4.3,4.7-7.7,9.4-7.7h151.5v6.8h-151.5
				c-1.3,0-2.3,1-2.3,2.2v8.6c1.4,2.5,2.6,5.1,3.6,7.6c5.9,15.4,4.2,31.6-0.9,46.6c-0.8,2.4-1.8,4.8-2.7,7.2v72.7
				c0,1.2,1,2.1,2.3,2.1l0,0c28.2,0,148,0.2,148,0.2h3.6v6.8h-3.6c0,0-119.8-0.2-148-0.2c-5.3,0-9.5-4.1-9.5-9v-58
				c-6.7,12-14.4,21.7-18.9,27l0,0c-3.1,3.5-7.6,5.6-12.5,5.7l0,0c-62.2,1.6-593.6,15-643.3,16.2c-3.1,0.1-6.1-0.6-8.8-2
				c-21.8-10.2-71.9-15.5-88.6-17c-4.6-0.4-8.7-2.6-11.7-6l0,0c-11.1-13.4-45.8-55.1-63.5-76.2v124.7c0,0.8-0.1,1.7-0.3,2.4h1008.8
				v6.8H769.3c-0.4,16.7-8.2,87.2-110.6,137.2h103.5c-1.1-3.4-22.9-72.2,46.6-107c0,0,0,0,0.1,0c2.1-1,4.3-1.5,6.5-1.5
				c12.4,0,67.4,0,82.1,0v-20.8c0-1.9,1.6-3.4,3.6-3.4c0,0,904.9-3,1009.5-0.6l3.6,0.1l-0.2,6.8l-3.6-0.1
				c-100.7-2.3-943.7,0.3-1005.7,0.5v20.8c0,1.9-1.6,3.4-3.6,3.4c0,0-71.3,0-85.6,0c-1.2,0-2.2,0.3-3.3,0.7
				c-66,33.2-43.2,99.2-43.2,99.2l0.7,2h173.6v-0.4l3.4,0.4h967.4v6.8H965.4c4.4,3.1,7.9,7,10.8,11.2c8.7,12.7,10.7,27.6,10.7,27.6
				l0.5,3.4l-7.1,0.8l-0.5-3.4c0,0-1.8-13.3-9.6-24.7c-5-7.2-12.4-13.6-23.6-14.9H643.9c-2.6,1.1-5.5,1.8-8.2,1.9
				c-26.5,1.1-187.6,8.4-292.5,22.5c-26.8,3.6-49.9,7.6-66.2,12c-10.1,2.8-17.5,5.6-20.9,8.8l-2.6,2.4l-5.1-4.9l2.6-2.4
				c3.1-2.8,8.5-5.6,16.2-8c16.4-5.5,43.1-10.4,74.9-14.6c59.6-8,137.4-13.8,198.3-17.5H9.7v-6.8h632.5
				c111.1-48.4,119.4-121.2,119.9-137.2H9.7v-6.8h572.1c-0.2-0.7-0.3-1.6-0.3-2.4V148L581.6,148z M559.2,81.7L559.2,81.7
				c0.1,0,13.6,2.4,22.2,12.8v-1.1c0-5.1,4.4-9.3,9.8-9.3h286.6c-0.8-5.4,0.3-9.8,2.4-13.4v-0.1c-25.9,3.4-37.8-7.6-42.8-14.9l0,0
				c-1.7-2.5-4.7-4.1-7.9-4.1c-19.5,0-69.8,0-85.6,0l0,0c-2.1,0-4,0.7-5.5,2.1c-8.9,8.4-16.6,11.8-22.9,12.2
				c-10.5,0.6-18.2-7-23.1-17c-1.2-2.3-2.2-4.7-3.1-7.1c-0.5,3.3-1.2,6.8-2,10.3c-2.9,11.8-8.1,23.1-17,29c-0.6,0.4-1.3,0.6-2.1,0.6
				H559.2L559.2,81.7z M1753.1,103.1c-7.2-11.8-20.4-22.6-42.3-31.2c-1.3-0.5-2.6-0.7-4-0.7c-35.5-0.5-423.4-5.1-644.2-7.7
				c-74.3,0-148.1,0-156.1,0c-5,1.9-9.6,3.4-13.9,4.6c-2.4,1.6-4.6,3.5-6.1,5.9c-1.6,2.7-2.3,6-1.5,10.1h11c5.4,0,9.7,4.2,9.7,9.3
				v20.8c14.3,17.2,56.6,67.9,69.2,83c1.7,1.9,4.1,3.2,6.7,3.4l0,0c17.3,1.6,68.8,7.2,91.2,17.8h0.1c1.7,0.8,3.5,1.3,5.3,1.2l0,0
				c49.7-1.2,581.1-14.6,643.3-16.2c2.8-0.1,5.4-1.3,7-3.2c5.9-6.8,17.1-21.4,24.5-38.8L1753.1,103.1L1753.1,103.1z M1760.4,133
				c0.1-2.1,0.1-4.3,0-6.5V133z M896.5,252.4c1.3-0.2,2.3-1.2,2.3-2.4V116.6c-1.8-2.1-2.9-3.4-3.2-3.8c-8.1-8.4-13-15.6-15.5-21.8
				H591.4c-1.5,0-2.7,1.1-2.7,2.5v19.9c0.1,1.4,0.1,2.9,0,4.3V250c0,1.3,1,2.3,2.3,2.4h174.5l3.5-0.2v0.2H896.5L896.5,252.4z
				 M898.6,105.9V93.4c0-1.4-1.2-2.5-2.6-2.5h-8.3C889.9,95.3,893.5,100.2,898.6,105.9z M1413,39.6v-7.9c0-2.6,2.1-4.7,5.1-4.7
				h408.1V14.8h7.2v15.5c0,1.9-1.6,3.4-3.6,3.4h-409.5v8c0,2.6-2.4,4.7-5.1,4.7h-137.6c-28,0-34.7-27.4-34.7-27.4l-0.8-3.3l7-1.6
				l0.8,3.3c0,0,5.3,22.1,27.7,22.1L1413,39.6L1413,39.6z M1022.8,443l-0.3,3.4l-7.1-0.6l0.3-3.4c0,0,0.7-9.3,11.4-18.7
				c9.8-8.5,28.7-17.3,64.8-17.3h0.1l822.3,14.8l-0.1,6.8l-3.6-0.1l-818.7-14.8C1026.5,413.2,1022.8,442.9,1022.8,443L1022.8,443z"
				/>
		</g>
		<line class="st6" x1="1755.6" y1="61.4" x2="1924.1" y2="61.4"/>
		<line class="st6" x1="1756.8" y1="219.6" x2="1925.3" y2="219.6"/>
		<path class="st7" d="M0.1,257.5c1.1,0,2.1,0,3.2,0h3c108.5-1.5,151-4.1,165.1-6.6c2-0.4,13.1-2.4,28.4-3.9
			c10-0.9,18.4-1.2,23.9-1.4"/>
		<path class="st7" d="M0,77.9c1.1,0,2.1,0,3.2,0h3c108.5-1.5,151-4.1,165.1-6.6c2-0.4,13.1-2.4,28.4-3.9c10-0.9,18.4-1.2,23.9-1.4"
			/>
		<path class="st7" d="M0,411.9c1.1,0,2.1,0,3.2,0h3c65.1,1.2,101.7-3.6,123.5-8.6c6.6-1.5,32.5-7.7,68.4-12.9
			c9.3-1.3,17.1-2.3,22-2.9"/>
	</g>
	<g id="Flower" class="st0">
		<path class="st8" d="M1919.3,138.2H0V3.2c5.7,0,11.5,0,17.2,0c634,0,1268.1,0,1902.1,0C1919.3,48.2,1919.3,93.2,1919.3,138.2z"/>
		<path class="st9" d="M6.2,15.9"/>
		<path class="st10" d="M29.5,10.3c3.4,0.1,10.1,2.1,13.6,2.9c1.6,0.4,4.9,1.1,9,2.4c5.7,1.6,7.8,2.4,9,2.9c3.3,1.4,6.8,3.2,7.9,4
			c2.2,1.6,5.9,8,4.5,5.4c1.9,3.8,1.5,6.9,1.1,8.9c0,0-0.1,0.7-1.7,5.8c-0.9,1.8-2.2,4-4,6.5c-0.6,0.9-3.1,4.5-7.3,8.9
			c-2.1,2.2-4.2,4-8.5,7.6c-3.9,3.3-5.9,5.1-7.9,6.5c-2.4,1.7-2.8,1.7-8.5,5.3c-3.4,2.2-5.1,3.2-6.2,4.2c-1.9,1.5-4.7,3.8-7.3,7.6
			c-2.6,3.9-3.7,7.3-4.5,10c-0.5,1.7-1.3,4.3-1.7,7.6c-0.8,7.2,0.7,12.9,0.6,12.9c-0.2,0-1.4-5.2-1.1-5.3c0.2-0.1,1.3,2.5,4.5,12.4"
			/>
		<path class="st10" d="M132.8,11.4c-3.4,0.1-10.1,2.1-13.6,2.9c-1.6,0.4-4.9,1.1-9,2.4c-5.7,1.6-7.8,2.4-9,2.9
			c-3.3,1.4-6.8,3.2-7.9,4c-2.2,1.6-5.9,8-4.5,5.4c-1.9,3.8-1.5,6.9-1.1,8.9c0,0,0.1,0.7,1.7,5.8c0.9,1.8,2.2,4,4,6.5
			c0.6,0.9,3.1,4.5,7.3,8.9c2.1,2.2,4.2,4,8.5,7.6c3.9,3.3,5.9,5.1,7.9,6.5c2.4,1.7,2.8,1.7,8.5,5.3c3.4,2.2,5.1,3.2,6.2,4.2
			c1.9,1.5,4.7,3.8,7.3,7.6c2.6,3.9,3.7,7.3,4.5,10c0.5,1.7,1.3,4.3,1.7,7.6c0.8,7.2-0.7,12.9-0.6,12.9c0.2,0,1.4-5.2,1.1-5.3
			c-0.2-0.1-1.3,2.5-4.5,12.4"/>
		<path class="st10" d="M173.8,14.5c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M277.1,15.6c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M324.6,15c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M427.9,16.1c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M467.7,16.7c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M571,17.8c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M611.9,18.4c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M715.3,19.5c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M762.7,19c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M866.1,20.1c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M905.4,18.6c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M1008.7,19.7c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M1049.6,20.3c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,6.9,5.7,5.5,3.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-3.2,5.8-5,8.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M1155,19.4c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M1202.4,18.9c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M1305.8,19c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,8,4.5,10.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,1.4-4.5,10.9"/>
		<path class="st10" d="M1347.5,19.5c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,1.1,7.9,1.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,6.3-7.3,10.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M1496.7,14.4c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M1600,15.5c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M1455.6,16c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M1639.2,16c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M1752,14.7c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st10" d="M1790.2,15.1c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st10" d="M1896.3,13.4c-3.4,0.1-10.1,2.1-13.6,2.9c-1.6,0.4-4.9,1.1-9,2.4c-5.7,1.6-7.8,2.4-9,2.9
			c-3.3,1.4-6.8,3.2-7.9,4c-2.2,1.6-5.9,8-4.5,5.4c-1.9,3.8-1.5,6.9-1.1,8.9c0,0,0.1,0.7,1.7,5.8c0.9,1.8,2.2,4,4,6.5
			c0.6,0.9,3.1,4.5,7.3,8.9c2.1,2.2,4.2,4,8.5,7.6c3.9,3.3,5.9,5.1,7.9,6.5c2.4,1.7,2.8,1.7,8.5,5.3c3.4,2.2,5.1,3.2,6.2,4.2
			c1.9,1.5,4.7,3.8,7.3,7.6c2.6,3.9,3.7,7.3,4.5,10c0.5,1.7,1.3,4.3,1.7,7.6c0.8,7.2-0.7,12.9-0.6,12.9c0.2,0,1.4-5.2,1.1-5.3
			c-0.2-0.1-1.3,2.5-4.5,12.4"/>
	</g>
	<g id="Brick">
		<line class="st11" x1="0" y1="114" x2="1919.3" y2="114"/>
		<line class="st11" x1="0" y1="324.1" x2="1919.3" y2="324.1"/>
		<line class="st12" x1="67.4" y1="-10.4" x2="67.4" y2="80.1"/>
		<line class="st12" x1="67.4" y1="353.2" x2="67.4" y2="443.7"/>
		<line class="st12" x1="726.9" y1="353.2" x2="726.9" y2="443.7"/>
		<line class="st12" x1="726.9" y1="-10.4" x2="726.9" y2="80.1"/>
		<line class="st12" x1="397.2" y1="86.7" x2="397.2" y2="341.5"/>
		<line class="st12" x1="1104.7" y1="85.8" x2="1104.7" y2="340.7"/>
		<line class="st12" x1="1441.9" y1="-10.4" x2="1441.9" y2="80.1"/>
		<line class="st12" x1="1441.9" y1="352.4" x2="1441.9" y2="442.8"/>
		<line class="st12" x1="1733.3" y1="83.4" x2="1733.3" y2="338.2"/>
		<g id="Layer_6">
		</g>
	</g>
	<g id="Layer_7" class="st0">
		<line class="st13" x1="0" y1="135.5" x2="1919.3" y2="135.5"/>
		<line class="st14" x1="5" y1="325.2" x2="1924.3" y2="325.2"/>
		<line class="st15" x1="123.8" y1="3.2" x2="123.8" y2="113.5"/>
		<line class="st15" x1="123.8" y1="345.5" x2="123.8" y2="457.2"/>
		<line class="st15" x1="800.4" y1="345.5" x2="800.4" y2="450.6"/>
		<line class="st15" x1="800.4" y1="3.2" x2="800.4" y2="113.5"/>
		<line class="st16" x1="397.2" y1="100.2" x2="397.2" y2="355.1"/>
		<line class="st16" x1="1104.7" y1="99.5" x2="1104.7" y2="354.2"/>
		<line class="st15" x1="1441.9" y1="3.2" x2="1441.9" y2="113.5"/>
		<line class="st15" x1="1441.9" y1="345.5" x2="1441.9" y2="456.4"/>
		<line class="st16" x1="1733.3" y1="96.9" x2="1733.3" y2="351.8"/>
	</g>
	<g id="Brick_x27_s_bottom" class="st0">
		<line class="st17" x1="0" y1="285.6" x2="1919.3" y2="285.6"/>
		<line class="st17" x1="0" y1="164.4" x2="1919.3" y2="164.4"/>
		<path class="st18" d="M25,171.3"/>
	</g>
	<line class="st19" x1="0" y1="457.2" x2="1919.3" y2="457.2"/>
	<line class="st20" x1="0" y1="7.6" x2="1919.3" y2="7.6"/>
	<line class="st20" x1="-4" y1="135.9" x2="1915.3" y2="135.9"/>
	<line class="st19" x1="0" y1="12.4" x2="1919.3" y2="12.4"/>
	<polygon class="st21" points="0,131.6 0,333.7 1919.3,333.7 1919.3,128.7 	"/>
	<path class="st21" d="M2146.6,257.5"/>
	<path class="st21" d="M2383.8,2453"/>
</g>
<g id="Layer_3">
</g>
<g id="Layer_2">
	<g id="Flower_00000046301666801641120990000001772011929299514508_" class="st0">
		<path class="st8" d="M1919.3,312.7H0v-165c5.7,0,11.5,0,17.2,0c634,0,1268.1,0,1902.1,0C1919.3,202.7,1919.3,257.7,1919.3,312.7z"
			/>
		<path class="st9" d="M6.2,163.2"/>
		<path class="st10" d="M29.5,156.4c3.4,0.1,10.1,2.6,13.6,3.6c1.6,0.5,4.9,1.4,9,2.9c5.7,1.9,7.8,2.9,9,3.6c3.3,1.7,6.8,4,7.9,4.8
			c2.2,1.9,5.9,9.8,4.5,6.6c1.9,4.6,1.5,8.4,1.1,10.8c0,0-0.1,0.9-1.7,7.1c-0.9,2.2-2.2,4.8-4,7.9c-0.6,1.1-3.1,5.5-7.3,10.8
			c-2.1,2.7-4.2,4.8-8.5,9.3c-3.9,4.1-5.9,6.2-7.9,7.9c-2.4,2-2.8,2-8.5,6.5c-3.4,2.7-5.1,4-6.2,5.1c-1.9,1.8-4.7,4.6-7.3,9.3
			s-3.7,8.9-4.5,12.2c-0.5,2-1.3,5.2-1.7,9.3c-0.8,8.8,0.7,15.8,0.6,15.8c-0.2,0-1.4-6.4-1.1-6.5c0.2-0.1,1.3,3.1,4.5,15.2"/>
		<path class="st10" d="M132.8,157.8c-3.4,0.1-10.1,2.6-13.6,3.6c-1.6,0.5-4.9,1.4-9,2.9c-5.7,1.9-7.8,2.9-9,3.6
			c-3.3,1.7-6.8,4-7.9,4.8c-2.2,1.9-5.9,9.8-4.5,6.6c-1.9,4.6-1.5,8.4-1.1,10.8c0,0,0.1,0.9,1.7,7.1c0.9,2.2,2.2,4.8,4,7.9
			c0.6,1.1,3.1,5.5,7.3,10.8c2.1,2.7,4.2,4.8,8.5,9.3c3.9,4.1,5.9,6.2,7.9,7.9c2.4,2,2.8,2,8.5,6.5c3.4,2.7,5.1,4,6.2,5.1
			c1.9,1.8,4.7,4.6,7.3,9.3s3.7,8.9,4.5,12.2c0.5,2,1.3,5.2,1.7,9.3c0.8,8.8-0.7,15.8-0.6,15.8c0.2,0,1.4-6.4,1.1-6.5
			c-0.2-0.1-1.3,3.1-4.5,15.2"/>
		<path class="st10" d="M173.8,161.5c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st10" d="M277.1,162.9c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st10" d="M324.6,162.1c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st10" d="M427.9,163.5c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st10" d="M467.7,164.2c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st10" d="M571,165.5c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9s3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st10" d="M611.9,166.3c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st10" d="M715.3,167.6c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st10" d="M762.7,167c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4c3.3,1.6,6.8,3.8,7.9,4.6
			c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6c-0.6,1.1-3.1,5.3-7.3,10.4
			c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9c-1.9,1.7-4.7,4.4-7.3,8.9
			c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st10" d="M866.1,168.4c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9s3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st10" d="M905.4,166.5c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9s-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st10" d="M1008.7,167.9c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st10" d="M1049.6,168.6c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,6.9,7,5.5,3.9c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-3.2,7.1-5,10
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st10" d="M1155,167.5c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st10" d="M1202.4,166.9c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st10" d="M1305.8,167c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,9.8,4.5,13c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,1.7-4.5,13.3"/>
		<path class="st10" d="M1347.5,167.6c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,1.3,7.9,2.2c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,7.7-7.3,12.8c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st10" d="M1496.7,161.4c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9s-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st10" d="M1600,162.8c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st10" d="M1455.6,163.3c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9s3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st10" d="M1639.2,163.3c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st10" d="M1752,161.8c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st10" d="M1790.2,162.3c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st10" d="M1896.3,160.2c-3.4,0.1-10.1,2.6-13.6,3.6c-1.6,0.5-4.9,1.4-9,2.9c-5.7,1.9-7.8,2.9-9,3.6
			c-3.3,1.7-6.8,4-7.9,4.8c-2.2,1.9-5.9,9.8-4.5,6.6c-1.9,4.6-1.5,8.4-1.1,10.8c0,0,0.1,0.9,1.7,7.1c0.9,2.2,2.2,4.8,4,7.9
			c0.6,1.1,3.1,5.5,7.3,10.8c2.1,2.7,4.2,4.8,8.5,9.3c3.9,4.1,5.9,6.2,7.9,7.9c2.4,2,2.8,2,8.5,6.5c3.4,2.7,5.1,4,6.2,5.1
			c1.9,1.8,4.7,4.6,7.3,9.3c2.6,4.7,3.7,8.9,4.5,12.2c0.5,2,1.3,5.2,1.7,9.3c0.8,8.8-0.7,15.8-0.6,15.8c0.2,0,1.4-6.4,1.1-6.5
			c-0.2-0.1-1.3,3.1-4.5,15.2"/>
	</g>
</g>
</svg>
`,
    density: 0.5,
  },
  "Partition Insulated": {
    pattern: (
      baseColor: string,
    ) => `<svg width="827" height="200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1919.3 464.8" style="enable-background:new 0 0 1919.3 464.8;" xml:space="preserve">
<style type="text/css">
	.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-miterlimit:10;}
	.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-width:8;stroke-miterlimit:10;}
	.st2{fill:none;stroke:${baseColor};stroke-width:8;stroke-miterlimit:10;}
	.st3{display:none;}
	.st4{fill:none;stroke:${baseColor};stroke-width:30;stroke-miterlimit:10;}
	.st5{fill:none;stroke:${baseColor};stroke-width:9;stroke-miterlimit:10;}
	.st6{fill-rule:evenodd;clip-rule:evenodd;fill:#ECECEC;}
	.st7{display:inline;}
	.st8{fill-rule:evenodd;clip-rule:evenodd;stroke:${baseColor};stroke-width:21;stroke-miterlimit:10;}
	.st9{display:inline;fill:none;stroke:${baseColor};stroke-width:21;stroke-miterlimit:10;}
	.st10{display:inline;fill-rule:evenodd;clip-rule:evenodd;stroke:${baseColor};stroke-width:21;stroke-miterlimit:10;}
	.st11{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-miterlimit:10;}
	.st12{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-width:8;stroke-miterlimit:10;}
	.st13{display:inline;fill:none;stroke:${baseColor};stroke-width:8;stroke-miterlimit:10;}
	.st14{fill:none;stroke:${baseColor};stroke-width:50;stroke-miterlimit:10;}
	.st15{display:none;fill:none;stroke:${baseColor};stroke-width:45;stroke-miterlimit:10;}
	.st16{display:inline;fill:none;stroke:${baseColor};stroke-width:44;stroke-miterlimit:10;}
	.st17{display:inline;fill:none;stroke:${baseColor};stroke-width:40;stroke-miterlimit:10;}
	.st18{display:inline;fill:none;stroke:${baseColor};stroke-width:28;stroke-miterlimit:10;}
	.st19{fill:none;stroke:${baseColor};stroke-width:28;stroke-miterlimit:10;}
	.st20{display:inline;fill:none;stroke:${baseColor};stroke-width:14;stroke-miterlimit:10;}
	.st21{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:${baseColor};stroke-width:9;stroke-miterlimit:10;}
	.st22{fill:none;stroke:${baseColor};stroke-width:21;stroke-miterlimit:10;}
	.st23{display:none;fill:none;stroke:${baseColor};stroke-width:15;stroke-miterlimit:10;}
	.st24{display:none;fill:#DDDDDD;}
</style>
<g id="Layer_2">
	<g id="Flower_00000046301666801641120990000001772011929299514508_">
		<path class="st0" d="M1919.3,312.7H0v-165c5.7,0,11.5,0,17.2,0c634,0,1268.1,0,1902.1,0C1919.3,202.7,1919.3,257.7,1919.3,312.7z"
			/>
		<path class="st1" d="M6.2,163.2"/>
		<path class="st2" d="M29.5,156.4c3.4,0.1,10.1,2.6,13.6,3.6c1.6,0.5,4.9,1.4,9,2.9c5.7,1.9,7.8,2.9,9,3.6c3.3,1.7,6.8,4,7.9,4.8
			c2.2,1.9,5.9,9.8,4.5,6.6c1.9,4.6,1.5,8.4,1.1,10.8c0,0-0.1,0.9-1.7,7.1c-0.9,2.2-2.2,4.8-4,7.9c-0.6,1.1-3.1,5.5-7.3,10.8
			c-2.1,2.7-4.2,4.8-8.5,9.3c-3.9,4.1-5.9,6.2-7.9,7.9c-2.4,2-2.8,2-8.5,6.5c-3.4,2.7-5.1,4-6.2,5.1c-1.9,1.8-4.7,4.6-7.3,9.3
			s-3.7,8.9-4.5,12.2c-0.5,2-1.3,5.2-1.7,9.3c-0.8,8.8,0.7,15.8,0.6,15.8c-0.2,0-1.4-6.4-1.1-6.5c0.2-0.1,1.3,3.1,4.5,15.2"/>
		<path class="st2" d="M132.8,157.8c-3.4,0.1-10.1,2.6-13.6,3.6c-1.6,0.5-4.9,1.4-9,2.9c-5.7,1.9-7.8,2.9-9,3.6
			c-3.3,1.7-6.8,4-7.9,4.8c-2.2,1.9-5.9,9.8-4.5,6.6c-1.9,4.6-1.5,8.4-1.1,10.8c0,0,0.1,0.9,1.7,7.1c0.9,2.2,2.2,4.8,4,7.9
			c0.6,1.1,3.1,5.5,7.3,10.8c2.1,2.7,4.2,4.8,8.5,9.3c3.9,4.1,5.9,6.2,7.9,7.9c2.4,2,2.8,2,8.5,6.5c3.4,2.7,5.1,4,6.2,5.1
			c1.9,1.8,4.7,4.6,7.3,9.3s3.7,8.9,4.5,12.2c0.5,2,1.3,5.2,1.7,9.3c0.8,8.8-0.7,15.8-0.6,15.8c0.2,0,1.4-6.4,1.1-6.5
			c-0.2-0.1-1.3,3.1-4.5,15.2"/>
		<path class="st2" d="M173.8,161.5c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st2" d="M277.1,162.9c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st2" d="M324.6,162.1c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st2" d="M427.9,163.5c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st2" d="M467.7,164.2c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st2" d="M571,165.5c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9s3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st2" d="M611.9,166.3c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st2" d="M715.3,167.6c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st2" d="M762.7,167c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4c3.3,1.6,6.8,3.8,7.9,4.6
			c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6c-0.6,1.1-3.1,5.3-7.3,10.4
			c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9c-1.9,1.7-4.7,4.4-7.3,8.9
			c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st2" d="M866.1,168.4c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9s3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st2" d="M905.4,166.5c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9s-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st2" d="M1008.7,167.9c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st2" d="M1049.6,168.6c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,6.9,7,5.5,3.9c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-3.2,7.1-5,10
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st2" d="M1155,167.5c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st2" d="M1202.4,166.9c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st2" d="M1305.8,167c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,9.8,4.5,13c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,1.7-4.5,13.3"/>
		<path class="st2" d="M1347.5,167.6c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,1.3,7.9,2.2c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,7.7-7.3,12.8c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st2" d="M1496.7,161.4c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9s-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st2" d="M1600,162.8c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st2" d="M1455.6,163.3c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9s3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st2" d="M1639.2,163.3c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st2" d="M1752,161.8c-3.4,0.1-10.1,2.4-13.6,3.4c-1.6,0.5-4.9,1.3-9,2.8c-5.7,1.8-7.8,2.8-9,3.4
			c-3.3,1.6-6.8,3.8-7.9,4.6c-2.2,1.8-5.9,9.4-4.5,6.4c-1.9,4.4-1.5,8.1-1.1,10.4c0,0,0.1,0.9,1.7,6.8c0.9,2.1,2.2,4.6,4,7.6
			c0.6,1.1,3.1,5.3,7.3,10.4c2.1,2.6,4.2,4.6,8.5,8.9c3.9,3.9,5.9,6,7.9,7.6c2.4,2,2.8,2,8.5,6.2c3.4,2.6,5.1,3.8,6.2,4.9
			c1.9,1.7,4.7,4.4,7.3,8.9c2.6,4.5,3.7,8.6,4.5,11.7c0.5,2,1.3,5,1.7,8.9c0.8,8.4-0.7,15.2-0.6,15.2c0.2,0,1.4-6.1,1.1-6.2
			c-0.2-0.1-1.3,2.9-4.5,14.5"/>
		<path class="st2" d="M1790.2,162.3c3.4,0.1,10.1,2.4,13.6,3.4c1.6,0.5,4.9,1.3,9,2.8c5.7,1.8,7.8,2.8,9,3.4
			c3.3,1.6,6.8,3.8,7.9,4.6c2.2,1.8,5.9,9.4,4.5,6.4c1.9,4.4,1.5,8.1,1.1,10.4c0,0-0.1,0.9-1.7,6.8c-0.9,2.1-2.2,4.6-4,7.6
			c-0.6,1.1-3.1,5.3-7.3,10.4c-2.1,2.6-4.2,4.6-8.5,8.9c-3.9,3.9-5.9,6-7.9,7.6c-2.4,2-2.8,2-8.5,6.2c-3.4,2.6-5.1,3.8-6.2,4.9
			c-1.9,1.7-4.7,4.4-7.3,8.9c-2.6,4.5-3.7,8.6-4.5,11.7c-0.5,2-1.3,5-1.7,8.9c-0.8,8.4,0.7,15.2,0.6,15.2c-0.2,0-1.4-6.1-1.1-6.2
			c0.2-0.1,1.3,2.9,4.5,14.5"/>
		<path class="st2" d="M1896.3,160.2c-3.4,0.1-10.1,2.6-13.6,3.6c-1.6,0.5-4.9,1.4-9,2.9c-5.7,1.9-7.8,2.9-9,3.6
			c-3.3,1.7-6.8,4-7.9,4.8c-2.2,1.9-5.9,9.8-4.5,6.6c-1.9,4.6-1.5,8.4-1.1,10.8c0,0,0.1,0.9,1.7,7.1c0.9,2.2,2.2,4.8,4,7.9
			c0.6,1.1,3.1,5.5,7.3,10.8c2.1,2.7,4.2,4.8,8.5,9.3c3.9,4.1,5.9,6.2,7.9,7.9c2.4,2,2.8,2,8.5,6.5c3.4,2.7,5.1,4,6.2,5.1
			c1.9,1.8,4.7,4.6,7.3,9.3c2.6,4.7,3.7,8.9,4.5,12.2c0.5,2,1.3,5.2,1.7,9.3c0.8,8.8-0.7,15.8-0.6,15.8c0.2,0,1.4-6.4,1.1-6.5
			c-0.2-0.1-1.3,3.1-4.5,15.2"/>
	</g>
</g>
<g id="Layer_1">
	<g id="Concrete" class="st3">
		<line class="st4" x1="0" y1="155.5" x2="1919.3" y2="155.5"/>
		<line class="st5" x1="328.3" y1="0" x2="328.3" y2="151.6"/>
		<line class="st4" x1="0" y1="301.4" x2="1919.3" y2="301.4"/>
		<line class="st5" x1="328.3" y1="297.3" x2="328.3" y2="464.8"/>
		<line class="st5" x1="1005.9" y1="0" x2="1005.9" y2="151.6"/>
		<line class="st5" x1="1655.3" y1="0" x2="1655.3" y2="151.6"/>
		<line class="st5" x1="1005.9" y1="300.7" x2="1005.9" y2="464.8"/>
		<line class="st5" x1="1655.3" y1="297.3" x2="1655.3" y2="464.8"/>
		<rect y="145.6" class="st6" width="1919.3" height="163.8"/>
	</g>
	<g id="Layer_2_00000041294061573236642060000008068305606222413205_" class="st3">
		<g id="Layer_1-2" class="st7">
			<path class="st8" d="M581.6,148l-25.3,98.1c-0.4,1.6-1.8,2.6-3.5,2.6c0,0-392.3,1.1-411.8,1.1l0,0c-1.3,0-3.1-0.9-3.8-2.2
				c0,0,0,0,0-0.1c-0.3-0.6-0.4-1.4-0.4-2.1c-0.1-0.2-0.5-0.6-1-1c-1.2-0.9-3.2-1.9-6.3-2.8c-13.2-4-44.3-7.1-116.1-2.9l-3.6,0.2
				L9.4,232l3.6-0.2c73.4-4.3,105.2-0.9,118.7,3.2c7.9,2.4,10.8,5.5,11.8,7.9c37.5-0.1,372-1,406.2-1.1l28.1-108.6
				c0-0.1,0.1-0.2,0.1-0.3c2.3-6.1,3.4-11.5,3.5-16v-2.4c-0.2-5.7-1.9-10.3-4.4-13.9c-6.3-9-17-11.6-18.9-11.9L13.4,81.7H9.7v-0.1
				l0,0l0.1-3.4v-3.4h0.1v-0.1l3.6,0.1h654c6.9-5.2,10.8-14.7,13.2-24.3c3.8-15.1,3.6-30.4,3.5-32l-0.4-3.2h0.3v-0.3l3.5-0.1
				l3.5-0.4v0.3h0.3l0.1,3.2c0.2,1.6,2.3,17.7,8.7,29.5c3,5.6,6.8,10.3,12.3,11.4c5.7,1.1,12.5-1.7,21.3-10l0,0
				c2.8-2.7,6.5-4.1,10.5-4.1c15.9,0,66.2,0,85.6,0c5.7,0,10.9,2.7,14,7.2c4.9,7,17.5,17.1,46,9.7c1.6-0.9,3.3-1.9,5-2.6
				c8-3.4,16-4.3,16-4.3c0.2,0,0.3,0,0.5,0c0,0,62,0.7,151.4,1.9h157.2V14.8h7.2v43.8c210,2.5,452,5.5,479.7,5.7l0,0
				c2.3,0,4.4,0.5,6.5,1.3c0,0,0,0,0.1,0c18.5,7.2,31.2,16.1,39.6,25.7c0.7-4.3,4.7-7.7,9.4-7.7h151.5v6.8h-151.5
				c-1.3,0-2.3,1-2.3,2.2v8.6c1.4,2.5,2.6,5.1,3.6,7.6c5.9,15.4,4.2,31.6-0.9,46.6c-0.8,2.4-1.8,4.8-2.7,7.2v72.7
				c0,1.2,1,2.1,2.3,2.1l0,0c28.2,0,148,0.2,148,0.2h3.6v6.8h-3.6c0,0-119.8-0.2-148-0.2c-5.3,0-9.5-4.1-9.5-9v-58
				c-6.7,12-14.4,21.7-18.9,27l0,0c-3.1,3.5-7.6,5.6-12.5,5.7l0,0c-62.2,1.6-593.6,15-643.3,16.2c-3.1,0.1-6.1-0.6-8.8-2
				c-21.8-10.2-71.9-15.5-88.6-17c-4.6-0.4-8.7-2.6-11.7-6l0,0c-11.1-13.4-45.8-55.1-63.5-76.2v124.7c0,0.8-0.1,1.7-0.3,2.4h1008.8
				v6.8H769.3c-0.4,16.7-8.2,87.2-110.6,137.2h103.5c-1.1-3.4-22.9-72.2,46.6-107c0,0,0,0,0.1,0c2.1-1,4.3-1.5,6.5-1.5
				c12.4,0,67.4,0,82.1,0v-20.8c0-1.9,1.6-3.4,3.6-3.4c0,0,904.9-3,1009.5-0.6l3.6,0.1l-0.2,6.8l-3.6-0.1
				c-100.7-2.3-943.7,0.3-1005.7,0.5v20.8c0,1.9-1.6,3.4-3.6,3.4c0,0-71.3,0-85.6,0c-1.2,0-2.2,0.3-3.3,0.7
				c-66,33.2-43.2,99.2-43.2,99.2l0.7,2h173.6v-0.4l3.4,0.4h967.4v6.8H965.4c4.4,3.1,7.9,7,10.8,11.2c8.7,12.7,10.7,27.6,10.7,27.6
				l0.5,3.4l-7.1,0.8l-0.5-3.4c0,0-1.8-13.3-9.6-24.7c-5-7.2-12.4-13.6-23.6-14.9H643.9c-2.6,1.1-5.5,1.8-8.2,1.9
				c-26.5,1.1-187.6,8.4-292.5,22.5c-26.8,3.6-49.9,7.6-66.2,12c-10.1,2.8-17.5,5.6-20.9,8.8l-2.6,2.4l-5.1-4.9l2.6-2.4
				c3.1-2.8,8.5-5.6,16.2-8c16.4-5.5,43.1-10.4,74.9-14.6c59.6-8,137.4-13.8,198.3-17.5H9.7v-6.8h632.5
				c111.1-48.4,119.4-121.2,119.9-137.2H9.7v-6.8h572.1c-0.2-0.7-0.3-1.6-0.3-2.4V148L581.6,148z M559.2,81.7L559.2,81.7
				c0.1,0,13.6,2.4,22.2,12.8v-1.1c0-5.1,4.4-9.3,9.8-9.3h286.6c-0.8-5.4,0.3-9.8,2.4-13.4v-0.1c-25.9,3.4-37.8-7.6-42.8-14.9l0,0
				c-1.7-2.5-4.7-4.1-7.9-4.1c-19.5,0-69.8,0-85.6,0l0,0c-2.1,0-4,0.7-5.5,2.1c-8.9,8.4-16.6,11.8-22.9,12.2
				c-10.5,0.6-18.2-7-23.1-17c-1.2-2.3-2.2-4.7-3.1-7.1c-0.5,3.3-1.2,6.8-2,10.3c-2.9,11.8-8.1,23.1-17,29c-0.6,0.4-1.3,0.6-2.1,0.6
				H559.2L559.2,81.7z M1753.1,103.1c-7.2-11.8-20.4-22.6-42.3-31.2c-1.3-0.5-2.6-0.7-4-0.7c-35.5-0.5-423.4-5.1-644.2-7.7
				c-74.3,0-148.1,0-156.1,0c-5,1.9-9.6,3.4-13.9,4.6c-2.4,1.6-4.6,3.5-6.1,5.9c-1.6,2.7-2.3,6-1.5,10.1h11c5.4,0,9.7,4.2,9.7,9.3
				v20.8c14.3,17.2,56.6,67.9,69.2,83c1.7,1.9,4.1,3.2,6.7,3.4l0,0c17.3,1.6,68.8,7.2,91.2,17.8h0.1c1.7,0.8,3.5,1.3,5.3,1.2l0,0
				c49.7-1.2,581.1-14.6,643.3-16.2c2.8-0.1,5.4-1.3,7-3.2c5.9-6.8,17.1-21.4,24.5-38.8L1753.1,103.1L1753.1,103.1z M1760.4,133
				c0.1-2.1,0.1-4.3,0-6.5V133z M896.5,252.4c1.3-0.2,2.3-1.2,2.3-2.4V116.6c-1.8-2.1-2.9-3.4-3.2-3.8c-8.1-8.4-13-15.6-15.5-21.8
				H591.4c-1.5,0-2.7,1.1-2.7,2.5v19.9c0.1,1.4,0.1,2.9,0,4.3V250c0,1.3,1,2.3,2.3,2.4h174.5l3.5-0.2v0.2H896.5L896.5,252.4z
				 M898.6,105.9V93.4c0-1.4-1.2-2.5-2.6-2.5h-8.3C889.9,95.3,893.5,100.2,898.6,105.9z M1413,39.6v-7.9c0-2.6,2.1-4.7,5.1-4.7
				h408.1V14.8h7.2v15.5c0,1.9-1.6,3.4-3.6,3.4h-409.5v8c0,2.6-2.4,4.7-5.1,4.7h-137.6c-28,0-34.7-27.4-34.7-27.4l-0.8-3.3l7-1.6
				l0.8,3.3c0,0,5.3,22.1,27.7,22.1L1413,39.6L1413,39.6z M1022.8,443l-0.3,3.4l-7.1-0.6l0.3-3.4c0,0,0.7-9.3,11.4-18.7
				c9.8-8.5,28.7-17.3,64.8-17.3h0.1l822.3,14.8l-0.1,6.8l-3.6-0.1l-818.7-14.8C1026.5,413.2,1022.8,442.9,1022.8,443L1022.8,443z"
				/>
		</g>
		<line class="st9" x1="1755.6" y1="61.4" x2="1924.1" y2="61.4"/>
		<line class="st9" x1="1756.8" y1="219.6" x2="1925.3" y2="219.6"/>
		<path class="st10" d="M0.1,257.5c1.1,0,2.1,0,3.2,0h3c108.5-1.5,151-4.1,165.1-6.6c2-0.4,13.1-2.4,28.4-3.9
			c10-0.9,18.4-1.2,23.9-1.4"/>
		<path class="st10" d="M0,77.9c1.1,0,2.1,0,3.2,0h3c108.5-1.5,151-4.1,165.1-6.6c2-0.4,13.1-2.4,28.4-3.9c10-0.9,18.4-1.2,23.9-1.4
			"/>
		<path class="st10" d="M0,411.9c1.1,0,2.1,0,3.2,0h3c65.1,1.2,101.7-3.6,123.5-8.6c6.6-1.5,32.5-7.7,68.4-12.9
			c9.3-1.3,17.1-2.3,22-2.9"/>
	</g>
	<g id="Flower" class="st3">
		<path class="st11" d="M1919.3,138.2H0V3.2c5.7,0,11.5,0,17.2,0c634,0,1268.1,0,1902.1,0C1919.3,48.2,1919.3,93.2,1919.3,138.2z"/>
		<path class="st12" d="M6.2,15.9"/>
		<path class="st13" d="M29.5,10.3c3.4,0.1,10.1,2.1,13.6,2.9c1.6,0.4,4.9,1.1,9,2.4c5.7,1.6,7.8,2.4,9,2.9c3.3,1.4,6.8,3.2,7.9,4
			c2.2,1.6,5.9,8,4.5,5.4c1.9,3.8,1.5,6.9,1.1,8.9c0,0-0.1,0.7-1.7,5.8c-0.9,1.8-2.2,4-4,6.5c-0.6,0.9-3.1,4.5-7.3,8.9
			c-2.1,2.2-4.2,4-8.5,7.6c-3.9,3.3-5.9,5.1-7.9,6.5c-2.4,1.7-2.8,1.7-8.5,5.3c-3.4,2.2-5.1,3.2-6.2,4.2c-1.9,1.5-4.7,3.8-7.3,7.6
			c-2.6,3.9-3.7,7.3-4.5,10c-0.5,1.7-1.3,4.3-1.7,7.6c-0.8,7.2,0.7,12.9,0.6,12.9c-0.2,0-1.4-5.2-1.1-5.3c0.2-0.1,1.3,2.5,4.5,12.4"
			/>
		<path class="st13" d="M132.8,11.4c-3.4,0.1-10.1,2.1-13.6,2.9c-1.6,0.4-4.9,1.1-9,2.4c-5.7,1.6-7.8,2.4-9,2.9
			c-3.3,1.4-6.8,3.2-7.9,4c-2.2,1.6-5.9,8-4.5,5.4c-1.9,3.8-1.5,6.9-1.1,8.9c0,0,0.1,0.7,1.7,5.8c0.9,1.8,2.2,4,4,6.5
			c0.6,0.9,3.1,4.5,7.3,8.9c2.1,2.2,4.2,4,8.5,7.6c3.9,3.3,5.9,5.1,7.9,6.5c2.4,1.7,2.8,1.7,8.5,5.3c3.4,2.2,5.1,3.2,6.2,4.2
			c1.9,1.5,4.7,3.8,7.3,7.6c2.6,3.9,3.7,7.3,4.5,10c0.5,1.7,1.3,4.3,1.7,7.6c0.8,7.2-0.7,12.9-0.6,12.9c0.2,0,1.4-5.2,1.1-5.3
			c-0.2-0.1-1.3,2.5-4.5,12.4"/>
		<path class="st13" d="M173.8,14.5c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st13" d="M277.1,15.6c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st13" d="M324.6,15c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st13" d="M427.9,16.1c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st13" d="M467.7,16.7c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st13" d="M571,17.8c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st13" d="M611.9,18.4c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st13" d="M715.3,19.5c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st13" d="M762.7,19c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st13" d="M866.1,20.1c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st13" d="M905.4,18.6c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st13" d="M1008.7,19.7c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st13" d="M1049.6,20.3c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,6.9,5.7,5.5,3.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-3.2,5.8-5,8.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st13" d="M1155,19.4c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st13" d="M1202.4,18.9c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st13" d="M1305.8,19c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,8,4.5,10.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,1.4-4.5,10.9"/>
		<path class="st13" d="M1347.5,19.5c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,1.1,7.9,1.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,6.3-7.3,10.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st13" d="M1496.7,14.4c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st13" d="M1600,15.5c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st13" d="M1455.6,16c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3s3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st13" d="M1639.2,16c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			c-2.6,3.7-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st13" d="M1752,14.7c-3.4,0.1-10.1,2-13.6,2.8c-1.6,0.4-4.9,1.1-9,2.3c-5.7,1.5-7.8,2.3-9,2.8
			c-3.3,1.3-6.8,3.1-7.9,3.8c-2.2,1.5-5.9,7.7-4.5,5.2c-1.9,3.6-1.5,6.6-1.1,8.5c0,0,0.1,0.7,1.7,5.6c0.9,1.7,2.2,3.8,4,6.2
			c0.6,0.9,3.1,4.3,7.3,8.5c2.1,2.1,4.2,3.8,8.5,7.3c3.9,3.2,5.9,4.9,7.9,6.2c2.4,1.6,2.8,1.6,8.5,5.1c3.4,2.1,5.1,3.1,6.2,4
			c1.9,1.4,4.7,3.6,7.3,7.3c2.6,3.7,3.7,7,4.5,9.6c0.5,1.6,1.3,4.1,1.7,7.3c0.8,6.9-0.7,12.4-0.6,12.4c0.2,0,1.4-5,1.1-5.1
			c-0.2-0.1-1.3,2.4-4.5,11.9"/>
		<path class="st13" d="M1790.2,15.1c3.4,0.1,10.1,2,13.6,2.8c1.6,0.4,4.9,1.1,9,2.3c5.7,1.5,7.8,2.3,9,2.8c3.3,1.3,6.8,3.1,7.9,3.8
			c2.2,1.5,5.9,7.7,4.5,5.2c1.9,3.6,1.5,6.6,1.1,8.5c0,0-0.1,0.7-1.7,5.6c-0.9,1.7-2.2,3.8-4,6.2c-0.6,0.9-3.1,4.3-7.3,8.5
			c-2.1,2.1-4.2,3.8-8.5,7.3c-3.9,3.2-5.9,4.9-7.9,6.2c-2.4,1.6-2.8,1.6-8.5,5.1c-3.4,2.1-5.1,3.1-6.2,4c-1.9,1.4-4.7,3.6-7.3,7.3
			s-3.7,7-4.5,9.6c-0.5,1.6-1.3,4.1-1.7,7.3c-0.8,6.9,0.7,12.4,0.6,12.4c-0.2,0-1.4-5-1.1-5.1c0.2-0.1,1.3,2.4,4.5,11.9"/>
		<path class="st13" d="M1896.3,13.4c-3.4,0.1-10.1,2.1-13.6,2.9c-1.6,0.4-4.9,1.1-9,2.4c-5.7,1.6-7.8,2.4-9,2.9
			c-3.3,1.4-6.8,3.2-7.9,4c-2.2,1.6-5.9,8-4.5,5.4c-1.9,3.8-1.5,6.9-1.1,8.9c0,0,0.1,0.7,1.7,5.8c0.9,1.8,2.2,4,4,6.5
			c0.6,0.9,3.1,4.5,7.3,8.9c2.1,2.2,4.2,4,8.5,7.6c3.9,3.3,5.9,5.1,7.9,6.5c2.4,1.7,2.8,1.7,8.5,5.3c3.4,2.2,5.1,3.2,6.2,4.2
			c1.9,1.5,4.7,3.8,7.3,7.6c2.6,3.9,3.7,7.3,4.5,10c0.5,1.7,1.3,4.3,1.7,7.6c0.8,7.2-0.7,12.9-0.6,12.9c0.2,0,1.4-5.2,1.1-5.3
			c-0.2-0.1-1.3,2.5-4.5,12.4"/>
	</g>
	<g id="Brick">
		<line class="st14" x1="0" y1="132.1" x2="1919.3" y2="132.1"/>
		<line class="st14" x1="0" y1="324.1" x2="1919.3" y2="324.1"/>
		<line class="st15" x1="67.4" y1="-10.4" x2="67.4" y2="80.1"/>
		<line class="st15" x1="67.4" y1="353.2" x2="67.4" y2="443.7"/>
		<line class="st15" x1="726.9" y1="353.2" x2="726.9" y2="443.7"/>
		<line class="st15" x1="726.9" y1="-10.4" x2="726.9" y2="80.1"/>
		<line class="st15" x1="397.2" y1="86.7" x2="397.2" y2="341.5"/>
		<line class="st15" x1="1104.7" y1="85.8" x2="1104.7" y2="340.7"/>
		<line class="st15" x1="1441.9" y1="-10.4" x2="1441.9" y2="80.1"/>
		<line class="st15" x1="1441.9" y1="352.4" x2="1441.9" y2="442.8"/>
		<line class="st15" x1="1733.3" y1="83.4" x2="1733.3" y2="338.2"/>
		<g id="Layer_6">
		</g>
	</g>
	<g id="Layer_7" class="st3">
		<line class="st16" x1="0" y1="135.5" x2="1919.3" y2="135.5"/>
		<line class="st17" x1="5" y1="325.2" x2="1924.3" y2="325.2"/>
		<line class="st18" x1="123.8" y1="3.2" x2="123.8" y2="113.5"/>
		<line class="st18" x1="123.8" y1="345.5" x2="123.8" y2="457.2"/>
		<line class="st18" x1="800.4" y1="345.5" x2="800.4" y2="450.6"/>
		<line class="st18" x1="800.4" y1="3.2" x2="800.4" y2="113.5"/>
		<line class="st19" x1="397.2" y1="100.2" x2="397.2" y2="355.1"/>
		<line class="st19" x1="1104.7" y1="99.5" x2="1104.7" y2="354.2"/>
		<line class="st18" x1="1441.9" y1="3.2" x2="1441.9" y2="113.5"/>
		<line class="st18" x1="1441.9" y1="345.5" x2="1441.9" y2="456.4"/>
		<line class="st19" x1="1733.3" y1="96.9" x2="1733.3" y2="351.8"/>
	</g>
	<g id="Brick_x27_s_bottom" class="st3">
		<line class="st20" x1="0" y1="285.6" x2="1919.3" y2="285.6"/>
		<line class="st20" x1="0" y1="164.4" x2="1919.3" y2="164.4"/>
		<path class="st21" d="M25,171.3"/>
	</g>
	<line class="st22" x1="0" y1="457.2" x2="1919.3" y2="457.2"/>
	<line class="st23" x1="0" y1="7.6" x2="1919.3" y2="7.6"/>
	<line class="st23" x1="-4" y1="135.9" x2="1915.3" y2="135.9"/>
	<line class="st22" x1="0" y1="12.4" x2="1919.3" y2="12.4"/>
	<polygon class="st24" points="0,131.6 0,333.7 1919.3,333.7 1919.3,128.7 	"/>
	<path class="st24" d="M2146.6,257.5"/>
	<path class="st24" d="M2383.8,2453"/>
</g>
<g id="Layer_3">
</g>
</svg>
`,
    density: 0.5,
  },
};
