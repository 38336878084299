import RBush from "rbush";
const EPS = -1e10;

/*
  Custom extension of RBush to include some extra search features
*/
export default class ExRBush<T> extends RBush<T> {
  closestManhattan(point: { x: number; y: number }): T | undefined {
    let low = 0.0001;
    let result: T[] = [];
    for (; low < 1e10; low *= 2) {
      result = this.search({
        maxX: point.x + low,
        minX: point.x - low,
        maxY: point.y + low,
        minY: point.y - low,
      });

      if (result.length > 0) {
        break;
      }
    }

    if (result.length === 0) {
      return undefined;
    }

    let high = low;
    low = 0.0001;

    let lastGoodResult: T[] = [];
    for (let i = 0; i < 30; i++) {
      const mid = (high + low) / 2;
      result = this.search({
        maxX: point.x + mid,
        minX: point.x - mid,
        maxY: point.y + mid,
        minY: point.y - mid,
      });

      if (result.length === 1) {
        return result[0];
      }
      if (result.length === 0) {
        low = mid;
      } else {
        lastGoodResult = result;
        high = mid;
      }
    }
    return lastGoodResult[0];
  }
}
