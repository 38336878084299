import { render, staticRenderFns } from "./HeroButton.vue?vue&type=template&id=2e08306a&scoped=true&"
import script from "./HeroButton.vue?vue&type=script&lang=ts&"
export * from "./HeroButton.vue?vue&type=script&lang=ts&"
import style0 from "./HeroButton.vue?vue&type=style&index=0&id=2e08306a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e08306a",
  null
  
)

export default component.exports