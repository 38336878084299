import Flatten from "@flatten-js/core";
import * as _ from "lodash";
import * as TM from "transformation-matrix";
import { CoreContext } from "../../../../../common/src/api/calculations/types";
import { makeCalculationFields } from "../../../../../common/src/api/calculations/utils";
import CoreBaseBackedObject from "../../../../../common/src/api/coreObjects/lib/coreBaseBackedObject";
import { isCalculated } from "../../../../../common/src/api/document/calculations-objects";
import {
  CalculationData,
  CalculationDataType,
  CalculationField,
  CalculationFieldWithValue,
  CalculationMessage,
  FieldCategory,
  toCalculationFieldWithValue,
} from "../../../../../common/src/api/document/calculations-objects/calculation-field";
import {
  PipeCalculation,
  getAmbiguousMessage,
} from "../../../../../common/src/api/document/calculations-objects/conduit-calculations";
import { Calculation } from "../../../../../common/src/api/document/calculations-objects/types";
import { renderWarning } from "../../../../../common/src/api/document/calculations-objects/warning-definitions";
import {
  WarningDetail,
  isWarningVisible,
} from "../../../../../common/src/api/document/calculations-objects/warnings";
import {
  CalculatableEntityConcrete,
  DrawableEntityConcrete,
} from "../../../../../common/src/api/document/entities/concrete-entity";
import {
  PipeConduitEntity,
  isPipeEntity,
} from "../../../../../common/src/api/document/entities/conduit-entity";
import { getEntityResultFieldName } from "../../../../../common/src/api/document/entities/types";
import { getFlowSystem } from "../../../../../common/src/api/document/utils";
import { SupportedLocales } from "../../../../../common/src/api/locale";
import { lighten } from "../../../../../common/src/lib/color";
import {
  Units,
  convertMeasurementSystem,
} from "../../../../../common/src/lib/measurements";
import { DEFAULT_FONT_NAME } from "../../../config";
import { getPropertyByString } from "../../../lib/utils";
import { CalculationFilters } from "../../../store/document/types";
import { drawRoundRectangle, drawWarningIcon } from "../../helpers/draw-helper";
import { TEXT_MAX_SCALE } from "../../objects/drawableConduit";
import { toCapitalize, wrapText } from "../../utils";
import { EntityPopupContent, EntityPopupTable } from "../entity-popups/types";
import { DrawingContext } from "../types";
import { tm2flatten } from "../utils";
import {
  FIELD_FONT_SIZE,
  FIELD_HEIGHT,
  MIN_SCALE,
  RECT_PADDING,
  RECT_ROUND,
  SCALE_GRADIENT_MIN,
  WARNING_HEIGHT,
  WARNING_HINT_WIDTH,
  WARNING_WIDTH,
} from "./calculated-object-const";
import { shouldShowField } from "./calculation-field-visibility";
import { IDrawableObject } from "./core2drawable";

export function CalculatedObject<
  // I extends CalculatableEntityConcrete,
  T extends abstract new (
    ...args: any[]
  ) => // Should be CalculatableEntityConcrete, but it causes state explosion in type analysis for mixins
  // above, so we use DrawableEntityConcrete instead which merges in with a lot of other mixin's type
  // parameters.
  CoreBaseBackedObject<DrawableEntityConcrete> & IDrawableObject,
>(Base: T) {
  abstract class Generated extends Base {
    calculated: true = true;

    abstract locateCalculationBoxWorld(
      context: DrawingContext,
      data: CalculationData[],
      scale: number,
    ): TM.Matrix[];

    makeDatumText(
      context: CoreContext,
      datum: CalculationData,
    ): string | Array<string> {
      if (datum.type === CalculationDataType.VALUE) {
        const titleText =
          datum.shortTitle === ""
            ? ""
            : datum.shortTitle || datum.title
              ? (datum.shortTitle || datum.title) + ": "
              : "";
        if (datum.static) {
          const text = titleText + datum.value;
          return text as any as string[] | string;
        }

        const convFun = datum.convert || convertMeasurementSystem;
        const docUnits = context.drawing.metadata.units;
        let units: Units = datum.units;
        let value: (string | number | null) | (string | number | null)[] =
          datum.value;
        if (Array.isArray(datum.value)) {
          value = datum.value
            .map((v) =>
              convFun(docUnits, datum.units, v, undefined, v.unitContext),
            )
            .flatMap((v) => v[1]);
          units = convFun(docUnits, datum.units, null)[0];

          // if values are the same, coalesce into one
          if (value.length == 2 && value[0] == value[1]) {
            value = value[0];
          } else if (value.length == 2 && (value[0] == 0 || value[1] == 0)) {
            // if one of the values is 0, we skip it
            value = value[0] ? value[0] : value[1];
          }
        } else if (typeof datum.value !== "string") {
          [units, value] = convFun(
            docUnits,
            datum.units,
            datum.value,
            undefined,
            datum.unitContext,
          );
        }

        if (value === undefined) {
          throw new Error(
            "undefined value: " +
              JSON.stringify(datum) +
              " " +
              JSON.stringify(this.globalStore.get(datum.attachUid)!.entity),
          );
        }

        let numberText: string;
        if (datum.format) {
          numberText = datum.format(value);
        } else {
          const fractionDigits =
            datum.significantDigits === undefined ? 2 : datum.significantDigits;

          if (typeof value === "string") {
            numberText = value;
          } else if (Array.isArray(value)) {
            numberText =
              value === null
                ? "??"
                : value
                    .map((v) =>
                      parseFloat(
                        (v as number).toFixed(fractionDigits),
                      ).toString(),
                    )
                    .toString();
          } else {
            numberText =
              value === null
                ? "??"
                : parseFloat(
                    (value as number).toFixed(fractionDigits),
                  ).toString();
          }
        }

        return (
          titleText +
          numberText +
          " " +
          (datum.hideUnits ? "" : units + " ") +
          datum.short
        ).trim();
      } else {
        return datum.message;
      }
    }

    getResultsPopupContent(): EntityPopupContent[] | null {
      if (!isCalculated(this.entity)) {
        return null;
      }

      const oC = this.globalStore.getCalculation(this.entity);

      if (!oC) {
        return null;
      }

      const calcFields = makeCalculationFields(
        this.context,
        this.entity,
        null,
      ).filter((field) => shouldShowField(field, oC) && !field.hideOnHover);

      return [
        {
          title: oC.reference || "",
          description: "",
          table: [
            {
              title: "",
              cells: this.getResultsPopupCells(calcFields, oC),
            },
          ],
        },
      ];
    }

    private getResultsPopupCells<T extends Calculation>(
      calcFields: CalculationField[],
      calculation: T,
    ) {
      return calcFields
        .map((field) => {
          const value = getPropertyByString(calculation, field.property, true);
          const text = this.makeDatumTextFromField(field, value);
          return {
            key: field.title,
            value: `**${text}**`,
          };
        })
        .filter((value) => value !== null) as EntityPopupTable["cells"];
    }

    makeDatumTextFromField(
      field: CalculationField,
      value: number | null,
    ): string | Array<string> | null {
      const datum: CalculationFieldWithValue = {
        ...field,
        type: CalculationDataType.VALUE,
        value,
        attachUid: this.entity.uid,
        color: field.color,
      };
      datum.title = "";
      datum.shortTitle = "";
      datum.short = "";
      if (datum.value == undefined) {
        return null;
      }
      return this.makeDatumText(this.context, datum);
    }

    drawWarningSignOnly(context: DrawingContext, dryRun: boolean): Flatten.Box {
      this.withWorldAngle(context, { x: 0, y: 0 }, () => {
        if (!dryRun) {
          drawWarningIcon(
            context.ctx,
            -WARNING_WIDTH / 2,
            -WARNING_HEIGHT / 2,
            WARNING_WIDTH,
            WARNING_HEIGHT,
          );
        }
      });

      return new Flatten.Box(
        -WARNING_WIDTH / 2,
        -WARNING_HEIGHT / 2,
        WARNING_WIDTH,
        WARNING_HEIGHT,
      );
    }

    drawCalculationBox(
      context: DrawingContext,
      data: CalculationData[],
      dryRun: boolean = false,
      warnSignOnly: boolean = false,
      forExport: boolean = false,
    ): Flatten.Box {
      if (warnSignOnly) {
        return this.drawWarningSignOnly(context, dryRun);
      }

      const isPinned = context.doc.uiState.pinnedCalcEntityUids.includes(
        this.uid,
      );
      const isHovered = context.doc.uiState.hoveredResultsUid === this.uid;

      const { ctx, vp } = context;
      const calculation = context.globalStore.getCalculation(
        this.entity as CalculatableEntityConcrete,
      );
      const hasWarning = context.doc.uiState.exportSettings.isAppendix
        ? false
        : this.hasWarning(context, forExport);
      const warnings = this.visibleWarnings(context, forExport);
      const { hiddenUids, activeEntityUids } =
        context.doc.uiState.warningFilter;

      // ctx.fillText(this.entity.calculation!.realNominalPipeDiameterMM!.toPrecision(2), 0, 0);

      let height = 0;
      let maxWidth = 0;
      let shouldDraw = hasWarning || false;
      for (let i = data.length - 1; i >= 0; i--) {
        const datum = data[i];
        if (
          datum.type !== CalculationDataType.VALUE ||
          datum.value === null ||
          datum.value === undefined
        ) {
          continue;
        }

        ctx.font = `${datum.bold ? "bold " : ""}${FIELD_FONT_SIZE}px ${DEFAULT_FONT_NAME}`;
        height +=
          (datum.fontMultiplier === undefined ? 1 : datum.fontMultiplier) *
          FIELD_FONT_SIZE;

        const text = this.makeDatumText(context, datum);
        if (Array.isArray(text)) {
          for (let j = 0; j < text.length; j++) {
            const str = text[j];
            const textWidth = ctx.measureText(str).width;
            maxWidth = Math.max(maxWidth, textWidth);
          }

          height += FIELD_FONT_SIZE * (text.length - 1);
        } else {
          const textWidth = ctx.measureText(text).width;
          maxWidth = Math.max(maxWidth, textWidth);
        }

        shouldDraw = true;
      }

      if (hasWarning && calculation?.warnings) {
        const rendered = renderWarning(context, calculation.warnings[0]!);
        const warnWidth = ctx.measureText(rendered.title!).width;
        maxWidth = Math.max(maxWidth, Math.min(WARNING_HINT_WIDTH, warnWidth));
      }

      let warnHeight = 0;
      if (hasWarning) {
        ctx.font = `bold ${FIELD_FONT_SIZE}px ${DEFAULT_FONT_NAME}`;
        warnings.forEach((warning) => {
          const rendered = renderWarning(context, warning);
          warnHeight += wrapText(
            ctx,
            toCapitalize(rendered.title!),
            0,
            0,
            maxWidth,
            FIELD_HEIGHT,
            true,
          );
        });
        height += warnHeight;
      }
      let y = height / 2 - RECT_PADDING;

      const box = new Flatten.Box(
        -maxWidth / 2 - RECT_PADDING,
        -height / 2 - RECT_PADDING,
        maxWidth / 2 + RECT_PADDING,
        height / 2 + RECT_PADDING,
      );

      if (hasWarning) {
        box.xmin -= WARNING_WIDTH + RECT_PADDING;
      }

      if (!dryRun) {
        ctx.fillStyle = isPinned
          ? "rgba(255, 255, 0, 1)"
          : "rgba(255, 255, 255, 1)";

        ctx.globalAlpha = 0.8;
        if (isHovered) {
          ctx.globalAlpha = 1;
        }

        ctx.strokeStyle = "#000";
        if (hasWarning) {
          ctx.fillStyle = "rgba(255,215,0, 0.8)";
          // highlight warning in result view
          if (activeEntityUids.includes(this.entity.uid) && !forExport) {
            ctx.fillStyle = "rgba(0,123,255, 0.8)";
          }
        }

        if (shouldDraw) {
          drawRoundRectangle(
            ctx,
            box.xmin,
            box.ymin,
            box.xmax - box.xmin,
            box.ymax - box.ymin,
            RECT_ROUND,
          );
        }

        // draw pin at the top right of result box
        if (isPinned || isHovered) {
          const xx = box.xmax + 5;
          const yy = box.ymin - 5;
          const r = 6;

          // Draw the line from the pin
          ctx.beginPath();
          ctx.moveTo(xx, yy);
          if (isPinned) {
            ctx.lineTo(xx - 12, yy + 12);
          } else {
            ctx.lineTo(xx - 14, yy + 10);
          }
          ctx.lineWidth = 2;
          ctx.strokeStyle = "black";
          ctx.stroke();

          // Draw the pin head
          ctx.beginPath();
          ctx.arc(xx, yy, r, 0, 2 * Math.PI);
          ctx.fillStyle = "rgb(200, 0, 0)";
          ctx.fill();
        }

        ctx.font = FIELD_FONT_SIZE + "px " + DEFAULT_FONT_NAME;
        ctx.fillStyle = "#000";

        if (hasWarning) {
          ctx.font = "bold " + FIELD_FONT_SIZE + "px " + DEFAULT_FONT_NAME;

          warnings.forEach((warning) => {
            const rendered = renderWarning(context, warning);
            ctx.fillStyle = "#000";
            if (hiddenUids.includes(warning.uid) && !forExport) {
              ctx.fillStyle = "rgba(0, 0, 0, 0.25)";
            }

            warnHeight = wrapText(
              ctx,
              toCapitalize(rendered.title!),
              0,
              0,
              maxWidth,
              FIELD_HEIGHT,
              true,
            );
            y -= wrapText(
              ctx,
              toCapitalize(
                warnings.length > 1 ? `• ${rendered.title!}` : rendered.title!,
              ),
              -maxWidth / 2,
              y - warnHeight + FIELD_HEIGHT,
              maxWidth,
              FIELD_HEIGHT,
              false,
              "middle",
            );
          });

          ctx.fillStyle = "#000";
          ctx.strokeStyle = "#000";
          if (
            !_.difference(
              warnings.map((e) => e.uid),
              hiddenUids,
            ).length
          ) {
            ctx.fillStyle = "rgba(0, 0, 0, 0.25)";
            ctx.strokeStyle = "rgba(0, 0, 0, 0.25)";
          }

          drawWarningIcon(
            ctx,
            -maxWidth / 2 - WARNING_WIDTH - RECT_PADDING,
            -WARNING_HEIGHT / 2,
            WARNING_WIDTH,
            WARNING_HEIGHT,
          );
        }

        let datumDrawn: boolean = false;
        for (let i = data.length - 1; i >= 0; i--) {
          const datum = data[i];

          if (
            datum.type === CalculationDataType.VALUE &&
            datum.value !== null &&
            datum.value !== undefined
          ) {
            let multiplier = 1;

            if (datum.type === CalculationDataType.VALUE) {
              multiplier =
                datum.fontMultiplier === undefined ? 1 : datum.fontMultiplier!;
              ctx.font =
                (datum.bold ? "bold " : "") +
                (multiplier * FIELD_FONT_SIZE).toFixed(0) +
                "px " +
                DEFAULT_FONT_NAME;
            } else {
              ctx.font =
                (multiplier * FIELD_FONT_SIZE).toFixed(0) +
                "px " +
                DEFAULT_FONT_NAME;
            }

            ctx.fillStyle = "#000";

            if (datum.systemUid) {
              const col = getFlowSystem(
                context.doc.drawing,
                data[i].systemUid,
              )!.color;
              ctx.fillStyle = lighten(col.hex, -20);
            }
            if (datum.color) {
              ctx.fillStyle = datum.color;
            }

            const text = this.makeDatumText(context, data[i]);
            if (Array.isArray(text)) {
              for (let i2 = 0; i2 < text.length; i2++) {
                ctx.fillTextStable(
                  text[i2],
                  -maxWidth / 2,
                  y,
                  undefined,
                  "middle",
                );
                y -= multiplier * FIELD_HEIGHT;
              }
            } else {
              ctx.fillTextStable(text, -maxWidth / 2, y, undefined, "middle");
              y -= multiplier * FIELD_HEIGHT;
            }

            datumDrawn = true;
          }
        }

        if (datumDrawn) {
          // line too
          const boxShape = new Flatten.Polygon();
          const worldMin = vp.toWorldCoord(
            TM.applyToPoint(context.vp.currToScreenTransform(ctx), {
              x: box.xmin,
              y: box.ymin,
            }),
          );
          const worldMax = vp.toWorldCoord(
            TM.applyToPoint(context.vp.currToScreenTransform(ctx), {
              x: box.xmax,
              y: box.ymax,
            }),
          );
          if (hasWarning) {
            worldMin.x -= WARNING_WIDTH;
          }
          const worldBox = new Flatten.Box(
            Math.min(worldMin.x, worldMax.x) - 1,
            Math.min(worldMin.y, worldMax.y) - 1,
            Math.max(worldMin.x, worldMax.x) + 1,
            Math.max(worldMin.y, worldMax.y + 1),
          );

          boxShape.addFace(worldBox);
          const line = this.shape!.distanceTo(boxShape);

          // Draw the dotted line if the box not inside the entity
          if (!boxShape.contains(line[1].start)) {
            // line is now in world position. Transform line back to current position.
            const world2curr = TM.transform(
              TM.inverse(context.vp.currToScreenTransform(ctx)),
              vp.world2ScreenMatrix,
            );

            const currLine = line[1].transform(tm2flatten(world2curr));
            let start = currLine.start;
            const end = currLine.end;
            if (this.shape) {
              console.log();
              start = new Flatten.Point(
                (this.shape?.box.xmax + this.shape?.box.xmin) / 2,
                (this.shape?.box.ymax + this.shape?.box.ymin) / 2,
              ).transform(tm2flatten(world2curr));
            }

            ctx.strokeStyle = "#aaa";
            ctx.setLineDash([5, 5]);
            ctx.lineWidth = 3;
            ctx.beginPath();
            ctx.moveTo(start.x, start.y);
            ctx.lineTo(end.x, end.y);
            ctx.stroke();
            ctx.setLineDash([]);
          }
        }
      }

      return box;
    }

    measureCalculationBox(
      context: DrawingContext,
      data: CalculationData[],
      forExport: boolean,
    ): Array<[TM.Matrix, Flatten.Polygon]> {
      const s = context.vp.currToSurfaceScale(context.ctx);
      let newScale: number;

      if (s > TEXT_MAX_SCALE) {
        newScale = 1 / TEXT_MAX_SCALE;
      } else if (s > SCALE_GRADIENT_MIN) {
        newScale =
          (1 * (s - SCALE_GRADIENT_MIN) + 0.7 * (TEXT_MAX_SCALE - s)) /
          (TEXT_MAX_SCALE - SCALE_GRADIENT_MIN) /
          s;
      } else {
        newScale = MIN_SCALE / s;
      }

      const locs: TM.Matrix[] = this.locateCalculationBoxWorld(
        context,
        data,
        newScale,
      );
      try {
        this.drawCalculationBox(context, data, true, false, forExport);
      } catch (error) {
        console.log(error);
      }
      const box = this.drawCalculationBox(
        context,
        data,
        true,
        false,
        forExport,
      );

      return locs.map((loc) => {
        let p = new Flatten.Polygon();
        p.addFace(box);
        p = p.transform(tm2flatten(loc));
        return [loc, p];
      });
    }

    getCalculationFields(
      context: DrawingContext,
      filters: CalculationFilters,
    ): CalculationData[] {
      const eName = getEntityResultFieldName(this.entity, context);
      const filter = filters[eName].filters;
      const calculation = context.globalStore.getCalculation(
        this.entity as CalculatableEntityConcrete,
      );

      if (!calculation) {
        return [];
      }

      const res: CalculationData[] = [];

      if (isPipeEntity(this.entity)) {
        const pCalc = calculation as PipeCalculation;
        if (
          pCalc &&
          !pCalc.totalPeakFlowRateLS &&
          pCalc.realNominalPipeDiameterMM === null
        ) {
          const pipeEntityCalculationMessage =
            this.pipeEntityCalculationMessage(
              this.entity,
              pCalc,
              context.doc.locale,
            );
          if (pipeEntityCalculationMessage) {
            res.push(pipeEntityCalculationMessage);
          }

          // There was a problem with the calculation or it is misconfigured and needs adjustment
          // before the calculations are useful
          if (pCalc.totalPeakFlowRateLS === null) {
            return res;
          }
        }
      }

      res.push(
        ...makeCalculationFields(
          context,
          this.entity,
          context.doc.uiState.levelUid,
        )
          .filter(
            (f) =>
              shouldShowField(f, calculation, context.doc.uiState, filter) &&
              f.category !== FieldCategory.HighLightInfo,
          )
          .map((field) =>
            toCalculationFieldWithValue(this.entity.uid, field, calculation),
          ),
      );

      return res;
    }

    private pipeEntityCalculationMessage(
      entity: PipeConduitEntity,
      pCalc: PipeCalculation,
      locale: SupportedLocales,
    ): CalculationMessage | undefined {
      // TODO: remove this from CalculatedObject.
      // TODO: make this available for all conduit types, not just pipes.
      if (
        pCalc &&
        !pCalc.totalPeakFlowRateLS &&
        pCalc.realNominalPipeDiameterMM === null
      ) {
        return {
          message: getAmbiguousMessage(pCalc, locale),
          attachUid: this.uid,
          type: CalculationDataType.MESSAGE,
          systemUid: entity.systemUid,
        } satisfies CalculationMessage;
      }

      return undefined;
    }

    visibleWarnings(
      context: DrawingContext,
      forExport: boolean,
    ): WarningDetail[] {
      if (!this.hasWarning(context, forExport)) {
        return [];
      }
      if (context.doc.uiState.isEmbedded) {
        if (!context.doc.uiState.showWarnings) {
          return [];
        }
      }
      const calculation = context.globalStore.getCalculation(
        this.entity as CalculatableEntityConcrete,
      );
      const { hiddenUids, showHiddenWarnings } =
        context.doc.uiState.warningFilter;
      return calculation!.warnings!.filter(
        (warning) =>
          (showHiddenWarnings || !hiddenUids.includes(warning.uid)) &&
          isWarningVisible({
            warning,
            drawingLayout: context.doc.uiState.drawingLayout,
          }),
      );
    }

    hasWarning(context: DrawingContext, forExport: boolean): boolean {
      if (context.doc.uiState.isEmbedded) {
        if (!context.doc.uiState.showWarnings) {
          return false;
        }
      }
      const calculation = context.globalStore.getCalculation(
        this.entity as CalculatableEntityConcrete,
      );
      if (calculation && calculation.warnings === undefined) {
        throw new Error(
          "undefined calculation: " + JSON.stringify(this.entity),
        );
      }
      if (
        !calculation ||
        !calculation.warnings ||
        !calculation.warnings.length
      ) {
        return false;
      }

      const { hiddenUids, showHiddenWarnings, showWarningsToPDF } =
        context.doc.uiState.warningFilter;
      if (!showWarningsToPDF) {
        return false;
      }
      if (
        (forExport || !showHiddenWarnings) &&
        !_.difference(
          calculation.warnings.map((e) => e.uid),
          hiddenUids,
        ).length
      ) {
        return false;
      }

      return calculation.warnings.some((warning) =>
        isWarningVisible({
          warning,
          drawingLayout: context.doc.uiState.drawingLayout,
        }),
      );
    }
  }
  return Generated;
}
