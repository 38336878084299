import { DrawingState } from "../../../../common/src/api/document/drawing";
import { DeepPartial } from "../../../../common/src/lib/utils";
import { UIState } from "../../store/document/types";

export interface IframeDrawingMessage {
  type: "drawing";
  data: DrawingState;
}

export interface IframeToolMessage {
  type: "toolHandler";
  data: string | null; // null means tool handler got cleared.
}

export interface IframeButtonClickMessage {
  type: "buttonClick";
  data: string | null;
}

export interface IframeCanvasClick {
  type: "canvasClick";
  data: "clickableArea" | string | null; // null means tool handler got cleared.
}

export type IframeMessage =
  | IframeDrawingMessage
  | IframeToolMessage
  | IframeButtonClickMessage
  | IframeCanvasClick;

export interface ParentSetUiStateMessage {
  type: "setUiState";
  data: DeepPartial<UIState>;
}

export interface ParentResetToolHandlerMessage {
  type: "resetToolHandler";
}

export interface ParentGetDrawing {
  type: "getDrawing";
}

export type ParentMessage =
  | ParentSetUiStateMessage
  | ParentResetToolHandlerMessage
  | ParentGetDrawing;

export function dispatchIframeMessage(message: IframeMessage) {
  window.postMessage(JSON.parse(JSON.stringify(message)));
}

export function dispatchParentMessage(
  target: HTMLIFrameElement,
  message: ParentMessage,
) {
  target.contentWindow?.postMessage?.(JSON.parse(JSON.stringify(message)));
}
