import mixpanel from "mixpanel-browser";
import { AnalyticsPayload } from "../../../common/src/api/document/analytics/analytics";
import { ENABLE_MIXPANEL } from "../config";
import store from "../store/store";

export function trackEvent(payload: AnalyticsPayload) {
  if (!ENABLE_MIXPANEL) return;

  const properties = {
    ...getCommonProps(),
    ...("props" in payload ? payload.props : {}),
  };

  try {
    mixpanel.track(payload.type, properties);
  } catch (error) {
    console.error("Tracking Failed:", error);
  }
}

function getCommonProps(): Record<string, any> {
  return {
    documentId: String(store.getters["document/documentId"]),
  };
}
