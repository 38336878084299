import Vue from "vue";
import { CoreContext } from "../../../../common/src/api/calculations/types";
import {
  getEntitySystem,
  makeCalculationFields,
} from "../../../../common/src/api/calculations/utils";
import { isDrainage } from "../../../../common/src/api/config";
import {
  FieldCategory,
  calculationResultOrder,
} from "../../../../common/src/api/document/calculations-objects/calculation-field";
import { CalculatableEntityConcrete } from "../../../../common/src/api/document/entities/concrete-entity";
import { getEntityResultFieldName } from "../../../../common/src/api/document/entities/types";
import { getFlowSystem } from "../../../../common/src/api/document/utils";
import { cloneSimple } from "../../../../common/src/lib/utils";
import CanvasContext from "../../htmlcanvas/lib/canvas-context";
import { shouldShowField } from "../../htmlcanvas/lib/object-traits/calculation-field-visibility";
import { DrawableObjectConcrete } from "../../htmlcanvas/objects/concrete-object";
import {
  CalculationFilter,
  CalculationFilterSettingType,
  CalculationFilterSettings,
  CalculationFilters,
  DocumentState,
  FilterKey,
  FilterSettingKey,
  FilterSettingViewKeyValues,
} from "../../store/document/types";
import { getSavedPreferenceOrDefault, savePreference } from "../localStorage";
import { ALL_KEY_VERSION, CombineFilters } from "../types";

const combineFilters: CombineFilters = {
  Fixture: [
    {
      name: "Dead Leg Volume",
      fields: [
        /Warm Water Dead Leg Volume/,
        /Hot Water Dead Leg Volume/,
        /Cold Water Dead Leg Volume/,
      ],
    },
    {
      name: "Dead Leg Length",
      fields: [
        /Warm Water Dead Leg Length/,
        /Hot Water Dead Leg Length/,
        /Cold Water Dead Leg Length/,
      ],
    },
    {
      name: "Dead Leg Wait Time",
      fields: [
        /Warm Water Dead Leg Wait Time/,
        /Hot Water Dead Leg Wait Time/,
        /Cold Water Dead Leg Wait Time/,
      ],
    },
  ],
  Duct: [
    {
      name: "Duct Sizing",
      fields: [/Internal Diameter/, /Internal Width/, /Internal Height/],
    },
  ],
};

const customFilterViewFields = [
  {
    view: "pressure",
    custom: [
      {
        entityNames: ["Small Valves", "Large Valves"],
        fields: ["Size"],
      },
    ],
  },
  {
    view: "heat-loss",
    custom: [
      {
        entityNames: ["Small Valves"],
        fields: ["Kv Value"],
      },
      {
        entityNames: ["Pipe"],
        fields: ["Pipe Diameter", "Recirculation Flow Rate"],
      },
      {
        entityNames: ["Inlet/Outlet"],
        fields: [
          "Return System Duty Flow Rate",
          "Return System Pressure Loss",
          "Recirculation Pump Model",
        ],
      },
    ],
  },
  {
    view: "pipe-sizing",
    custom: [
      {
        entityNames: [
          "Floor Waste",
          "Inspection Opening",
          "Grease Interceptor Trap",
        ],
        fields: ["Size"],
      },
      {
        entityNames: ["Grease Interceptor Trap"],
        fields: ["Model"],
      },
    ],
  },
];

function customFilterViewByField(
  filterViewSetting: { [key: string]: FilterSettingKey },
  eName: string,
  title: string,
): boolean {
  // Custom filter view for the particular filter & entity
  for (const item of customFilterViewFields) {
    if (filterViewSetting[item.view]?.enabled) {
      for (const op of item.custom) {
        if (op.entityNames.includes(eName) && op.fields.includes(title)) {
          return true;
        }
      }
    }
  }
  return false;
}

export function getEffectiveFilter(
  context: CoreContext,
  document: DocumentState,
  objects: DrawableObjectConcrete[],
  calculationFilterSettings: CalculationFilterSettings,
): CalculationFilters {
  const build: CalculationFilters = {};
  const existing = getSavedFilters(window, document.documentId, {});

  const allowedCategories = new Set<FieldCategory>(); // allowed categories from the view setting
  const filterViewSetting = calculationFilterSettings.view.filters;

  for (const cName in filterViewSetting) {
    if (filterViewSetting[cName as FilterSettingViewKeyValues].enabled) {
      if (filterViewSetting[cName as FilterSettingViewKeyValues].category) {
        Array.from(
          filterViewSetting[cName as FilterSettingViewKeyValues].category!,
        ).forEach((category) => {
          allowedCategories.add(category);
        });
      }
    }
  }

  for (const o of objects) {
    const calc = context.globalStore.getCalculation(
      o.entity as CalculatableEntityConcrete,
    );

    if (!calc) {
      continue;
    }

    const fields = makeCalculationFields(
      context,
      o.entity,
      document.uiState.levelUid!,
    ).filter((f) => shouldShowField(f, calc, document.uiState));

    const eName = getEntityResultFieldName(o.entity, context);
    const isCollapsed = existing[eName] ? existing[eName].collapsed : true;

    if (eName in build) {
      Vue.set(build[eName], "enabled", true);
      Vue.set(build[eName], "collapsed", isCollapsed);
    } else {
      Vue.set(build, eName, {
        name: eName,
        filters: {},
        enabled: true,
        collapsed: isCollapsed,
        order: calculationResultOrder(o.entity as CalculatableEntityConcrete),
      } satisfies CalculationFilter);
    }

    build[eName].enabled = true;
    build[eName].collapsed = isCollapsed;

    fields.forEach((f) => {
      const enabled = (() => {
        const isCustomView = filterViewSetting.custom.enabled;
        const existingFilterKey = existing[eName]?.filters[f.title];

        if (isCustomView) {
          if (existingFilterKey === undefined) {
            return !!f.defaultEnabled;
          } else {
            return existingFilterKey.enabled;
          }
        } else {
          return (
            allowedCategories.has(f.category) ||
            customFilterViewByField(filterViewSetting, eName, f.title) ||
            filterViewSetting["all"]?.enabled
          );
        }
      })();
      Vue.set(build[eName].filters, f.title, {
        name: f.title,
        enabled: enabled,
      });
      build[eName].filters[f.title].enabled = enabled;
    });
  }

  return build;
}

export function setInitFilterSettings(
  objects: DrawableObjectConcrete[],
  calculationFilterSettings: CalculationFilterSettings,
  context: CanvasContext,
) {
  const allFlowSystems = new Set<string>();

  objects.forEach((o) => {
    const entitySystem = getEntitySystem(o.entity, context.globalStore)!;
    if (entitySystem) {
      allFlowSystems.add(entitySystem);
    }
  });

  const filterSystemSetting = cloneSimple(
    calculationFilterSettings.systems.filters,
  );

  for (const prop in filterSystemSetting) {
    if (prop !== "all" && !allFlowSystems.has(prop)) {
      delete filterSystemSetting[prop];
    }
  }

  allFlowSystems.forEach((flowSystemUid) => {
    const systemName = getFlowSystem(
      context.document.drawing,
      flowSystemUid,
    )?.name!;
    if (flowSystemUid in filterSystemSetting) {
      filterSystemSetting[flowSystemUid].name = systemName;
    } else if (
      isDrainage(context.document.drawing.metadata.flowSystems[flowSystemUid])
    ) {
      filterSystemSetting[flowSystemUid] = {
        name: systemName,
        enabled: true,
        drawingLayout: ["drainage"],
      };
    } else {
      filterSystemSetting[flowSystemUid] = {
        name: systemName,
        enabled: true,
        drawingLayout: ["pressure"],
      };
    }
  });
  Vue.set(calculationFilterSettings.systems, "filters", filterSystemSetting);
}

export function getFilterSettings(
  calculationFilterSettings: CalculationFilterSettings,
  document: DocumentState,
): CalculationFilterSettings {
  const existing = cloneSimple(calculationFilterSettings);
  const build = existing;

  for (const eName in existing) {
    let isSelectedAll = true;
    switch (eName) {
      case CalculationFilterSettingType.Systems:
        for (const prop in existing[CalculationFilterSettingType.Systems]
          .filters) {
          if (
            existing[CalculationFilterSettingType.Systems].filters[prop]
              .drawingLayout &&
            !existing[CalculationFilterSettingType.Systems].filters[
              prop
            ].drawingLayout?.includes(document.uiState.drawingLayout)
          ) {
            delete build[CalculationFilterSettingType.Systems].filters[prop];
          } else if (
            !existing[CalculationFilterSettingType.Systems].filters[prop]
              .enabled
          ) {
            isSelectedAll = false;
          }
        }
        build[CalculationFilterSettingType.Systems].filters["all"].enabled =
          isSelectedAll;
        break;
      case CalculationFilterSettingType.View:
        for (const prop in existing[CalculationFilterSettingType.View]
          .filters) {
          if (
            existing[CalculationFilterSettingType.View].filters[
              prop as FilterSettingViewKeyValues
            ].drawingLayout &&
            !existing[CalculationFilterSettingType.View].filters[
              prop as FilterSettingViewKeyValues
            ].drawingLayout?.includes(document.uiState.drawingLayout)
          ) {
            delete build[CalculationFilterSettingType.View].filters[
              prop as FilterSettingViewKeyValues
            ];
          } else if (
            prop !== "custom" &&
            !existing[CalculationFilterSettingType.View].filters[
              prop as FilterSettingViewKeyValues
            ].enabled
          ) {
            isSelectedAll = false;
          }
        }
        build[CalculationFilterSettingType.View].filters["all"].enabled =
          isSelectedAll;
        break;
      default:
        break;
    }
  }

  return build;
}

export function getCombinedFilter(
  calculationFilters: CalculationFilters,
): CalculationFilters {
  const filters = cloneSimple(calculationFilters);

  for (const eName in calculationFilters) {
    if (!combineFilters[eName]?.length) {
      continue;
    }
    const groupFilters: {
      [key: string]: FilterKey;
    } = {};

    for (const group of combineFilters[eName]!) {
      const targets = Object.keys(calculationFilters[eName].filters).filter(
        (f) => group.fields.some((g) => f.match(g)),
      );
      targets.forEach((t) => delete filters[eName].filters[t]);
      if (targets.length !== 0) {
        groupFilters[group.name] = {
          name: group.name,
          enabled: targets.every(
            (t) => calculationFilters[eName].filters[t].enabled,
          ),
          targets,
        };
      }
    }
    filters[eName].collapsed = calculationFilters[eName].collapsed;
    filters[eName].order = calculationFilters[eName].order;

    Object.assign(filters[eName].filters, groupFilters);
  }
  return filters;
}

const FILTERS_KEY = (docId: number) => `filters_v${ALL_KEY_VERSION}:${docId}`;
const FILTERSETTINGS_KEY = (docId: number) =>
  `filters-setting_v${ALL_KEY_VERSION}:${docId}`;

export function getSavedFilters(
  window: WindowLocalStorage,
  documentId: number,
  defaultValue: CalculationFilters,
): CalculationFilters {
  return getSavedPreferenceOrDefault<CalculationFilters>(
    window,
    FILTERS_KEY(documentId),
    defaultValue,
  );
}

export function getSavedFilterSettings(
  window: WindowLocalStorage,
  documentId: number,
  defaultValue: CalculationFilterSettings,
): CalculationFilterSettings {
  return getSavedPreferenceOrDefault<CalculationFilterSettings>(
    window,
    FILTERSETTINGS_KEY(documentId),
    defaultValue,
  );
}

export function saveDocumentFilterData(
  window: WindowLocalStorage,
  documentId: number,
  preference: CalculationFilters,
  perferenceSettings: CalculationFilterSettings,
) {
  savePreference(window, FILTERS_KEY(documentId), preference);
  savePreference(window, FILTERSETTINGS_KEY(documentId), perferenceSettings);
}
