import Flatten from "@flatten-js/core";
import * as TM from "transformation-matrix";
import { DuctSize } from "../../../../../common/src/api/coreObjects/lib/ductFittingPrimitives";
import { Coord } from "../../../../../common/src/lib/coord";
import { DrawingContext } from "../types";

export function paintVerticalDuctShape(
  context: DrawingContext,
  options: {
    size: DuctSize;
    axialRotDEG: number;
  },
) {
  const { ctx } = context;
  const { size, axialRotDEG } = options;
  switch (size.type) {
    case "circ": {
      const diameterMM = Math.max(size.diameterMM, 15);

      ctx.beginPath();
      ctx.arc(0, 0, diameterMM / 2, 0, Math.PI * 2);
      ctx.closePath();
      ctx.stroke();

      ctx.globalAlpha = 0.1;
      ctx.fill();
      ctx.globalAlpha = 1;

      break;
    }
    case "rect": {
      // this is a bit of a hack to make sure the vertical duct fitting covers the whole area
      // where the transitions are drawn until we properly tackle the problem of visualizing
      // vertical duct fittings.
      const maxSize = Math.max(size.widthMM ?? 0, size.heightMM ?? 0);
      const widthMM = Math.max(maxSize, 15);
      const heightMM = Math.max(maxSize, 15);

      // const widthMM = Math.max(size.heightMM ?? 0, 15);
      // const heightMM = Math.max(size.widthMM ?? 0, 15);

      // apply rotation
      let pts: Coord[] = [
        Flatten.point(-widthMM / 2, heightMM / 2),
        Flatten.point(widthMM / 2, heightMM / 2),
        Flatten.point(widthMM / 2, -heightMM / 2),
        Flatten.point(-widthMM / 2, -heightMM / 2),
      ];

      const rotMat = TM.rotateDEG(axialRotDEG ?? 0);
      pts = pts.map((pt) => TM.applyToPoint(rotMat, pt));

      ctx.beginPath();
      ctx.moveTo(pts[0].x, pts[0].y);
      for (const p of pts.slice(1)) {
        ctx.lineTo(p.x, p.y);
      }
      ctx.closePath();
      ctx.stroke();

      ctx.globalAlpha = 0.1;
      ctx.fill();
      ctx.globalAlpha = 1;

      break;
    }
  }
}
