import Flatten from "@flatten-js/core";
import * as TM from "transformation-matrix";
import { Level } from "../../document/drawing";
import RiserEntity from "../../document/entities/riser-entity";

export interface Transformation {
  tx: number;
  ty: number;
  sx: number;
  sy: number;
  a: number;
}

/**
 * Decomposes a transformation matrix to the 2d transformation components.
 * Why doesn't this exist in transformation-matrix?
 * @param mat
 */
export const decomposeMatrix = (mat: TM.Matrix): Transformation => {
  const dp = TM.applyToPoint(mat, { x: 1, y: 0 });
  const cp = TM.applyToPoint(mat, { x: 0, y: 0 });
  const n = Flatten.vector(dp.x - cp.x, dp.y - cp.y).normalize();
  return {
    tx: mat.e,
    ty: mat.f,
    sx: (mat.a >= 0 ? 1 : -1) * Math.sqrt(mat.a * mat.a + mat.c * mat.c),
    sy: (mat.d >= 0 ? 1 : -1) * Math.sqrt(mat.b * mat.b + mat.d * mat.d),
    a: Math.atan2(n.y, n.x),
  };
};

export const matrixScale = (mat: TM.Matrix): number => {
  const sx = (mat.a >= 0 ? 1 : -1) * Math.sqrt(mat.a * mat.a + mat.c * mat.c);
  return Math.abs(sx);
};

export function levelIncludesRiser(
  level: Level,
  riser: RiserEntity,
  sortedLevels: Level[],
): boolean {
  if (level == null) {
    return false; // this shouldn't really be a case.
  }

  // Get the level in reference to the ground floor
  let sortLevelAscending = [...sortedLevels].reverse();
  let groundFloorIdx = sortLevelAscending.findIndex((lv) => {
    return lv.uid === "ground";
  });
  let currLevelRef =
    sortLevelAscending.findIndex((lv) => {
      return lv.uid === level.uid;
    }) - groundFloorIdx;

  if (riser.bottomFloorRef === null && riser.topFloorRef === null) {
    return true;
  } else if (riser.bottomFloorRef === null) {
    return riser.topFloorRef! >= currLevelRef;
  } else if (riser.topFloorRef === null) {
    return riser.bottomFloorRef! <= currLevelRef;
  } else {
    return (
      riser.bottomFloorRef! <= currLevelRef &&
      riser.topFloorRef! >= currLevelRef
    );
  }

  return true;
}
