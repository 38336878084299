import { User } from "../../../../common/src/models/User";

export class Pendo {
  static init(profile: User) {
    pendo.initialize({
      visitor: {
        id: profile.username,
        name: profile.name,
        email: profile.email ?? "",
      },
      account: {
        id: profile.organization?.id ?? "",
        organizationId: profile.organization?.id ?? "",
        organizationName: profile.organization?.name ?? "",
        organizationType: profile.organization?.type ?? "",
        organizationSubscriptionStatus:
          profile.organization?.subscriptionStatus ?? "",
        role: profile.accessLevel,
        accountType:
          profile.organization?.name === "Free Account" ? "free" : "paid",
      },
    });
  }
}
