import {
  CoreContext,
  DrawingLayout,
} from "../../../../../common/src/api/calculations/types";

import CoreConduit from "../../../../../common/src/api/coreObjects/coreConduit";
import { ConduitConnectableCalculationConcrete } from "../../../../../common/src/api/document/calculations-objects/calculation-concrete";
import {
  DuctCalculation,
  PipeCalculation,
} from "../../../../../common/src/api/document/calculations-objects/conduit-calculations";
import FittingCalculation from "../../../../../common/src/api/document/calculations-objects/fitting-calculation";
import FixtureCalculation from "../../../../../common/src/api/document/calculations-objects/fixture-calculation";
import LoadNodeCalculation from "../../../../../common/src/api/document/calculations-objects/load-node-calculation";
import { CalculationType } from "../../../../../common/src/api/document/calculations-objects/types";
import { UnitsParameters } from "../../../../../common/src/api/document/drawing";
import { ConnectableEntityConcrete } from "../../../../../common/src/api/document/entities/concrete-entity";
import ConduitEntity, {
  DuctConduitEntity,
  PipeConduitEntity,
  isPipeEntity,
} from "../../../../../common/src/api/document/entities/conduit-entity";
import { FittingEntity } from "../../../../../common/src/api/document/entities/fitting-entity";
import FixtureEntity from "../../../../../common/src/api/document/entities/fixtures/fixture-entity";
import LoadNodeEntity from "../../../../../common/src/api/document/entities/load-node-entity";
import { EntityType } from "../../../../../common/src/api/document/entities/types";
import { Color } from "../../../../../common/src/lib/color";
import {
  Precision,
  Units,
  UnitsContext,
  chooseByUnitsMetric,
  convertMeasurementSystem,
  getTargetUnits,
} from "../../../../../common/src/lib/measurements";
import {
  assertUnreachable,
  parseCatalogNumberExact,
} from "../../../../../common/src/lib/utils";
import { lerpColor } from "../../../lib/utils";
import { DocumentState, UIState } from "../../../store/document/types";
import { getGlobalContext } from "../../../store/globalCoreContext";
import DrawableConduit from "../../objects/drawableConduit";
import DrawableFitting from "../../objects/drawableFitting";
import DrawableLoadNode from "../../objects/drawableLoadNode";
import DrawableRiser from "../../objects/drawableRiser";
import { DrawingMode } from "../../types";

export enum HeatmapMode {
  "Off" = "Off",
  "ResidualPressure" = "Residual Pressure",
  "StaticPressure" = "Max Static Pressure",
  "PressureDropKPAM" = "Pressure Drop",
  "Velocity" = "Max Velocity",
}

const AllHeatModes: HeatmapMode[] = [
  HeatmapMode.Off,
  HeatmapMode.ResidualPressure,
  HeatmapMode.StaticPressure,
  HeatmapMode.PressureDropKPAM,
  HeatmapMode.Velocity,
];

export const HeatmapModeByDrawingLayout: {
  [key in DrawingLayout]: HeatmapMode[];
} = {
  pressure: AllHeatModes,
  drainage: AllHeatModes,
  ventilation: [
    HeatmapMode.Off,
    HeatmapMode.ResidualPressure,
    HeatmapMode.PressureDropKPAM,
    HeatmapMode.Velocity,
  ],
  mechanical: [
    HeatmapMode.Off,
    HeatmapMode.PressureDropKPAM,
    HeatmapMode.Velocity,
  ],
};

export const heatmapDesc = {
  [HeatmapMode.Off]: "",
  [HeatmapMode.ResidualPressure]:
    "Residual Pressure Mode: Indicates absolute residual pressure. You can change the absolute threshold values and colors in the heatmap settings menu, beside the heatmaps dropdown.",
  [HeatmapMode.StaticPressure]:
    "Max Pressure Mode: Indicates pressure between 0% and 100% relative to the maximum allowed for the entity - ie. safe working pressure for pipes, and max inlet pressure for fixtures.",
  [HeatmapMode.PressureDropKPAM]:
    "Pressure Drop Mode: Indicates pressure drop of pipes from 0% to 100% based on the maximum pressure drop per meter setting. You can change the threshold on each individual pipe, and the global defaults in flow system settings.",
  [HeatmapMode.Velocity]:
    "Max Velocity Mode: Indicates velocity from 0% to 100% based on the maximum velocity in pipes. You can change the threshold on each individual pipe, and the global defaults in the flow system settings.",
};

export function initialResidualPressureHeatmapSetting(
  units: UnitsParameters,
): HeatmapSetting {
  const ret: HeatmapSetting = {
    mechanical: [],
    pressure: [],
    drainage: [],
    ventilation: [],
  };

  for (const layout in ret) {
    switch (layout) {
      case "pressure":
      case "drainage":
        ret[layout].push(
          {
            pressureKPA: chooseByUnitsMetric(
              units,
              {
                [Units.KiloPascals]: 1000,
                [Units.GasKiloPascals]: 1000,
                [Units.Pascals]: 1000000,
                [Units.Psi]: 150,
                [Units.Bar]: 10,
                [Units.Mbar]: 10000,
              },
              UnitsContext.NONE,
            )[1],
            color: Color.ORANGE.hex,
          },
          {
            pressureKPA: chooseByUnitsMetric(
              units,
              {
                [Units.KiloPascals]: 700,
                [Units.GasKiloPascals]: 700,
                [Units.Pascals]: 700000,
                [Units.Psi]: 100,
                [Units.Bar]: 7,
                [Units.Mbar]: 7000,
              },
              UnitsContext.NONE,
            )[1],
            color: Color.YELLOW.hex,
          },
          {
            pressureKPA: chooseByUnitsMetric(
              units,
              {
                [Units.KiloPascals]: 300,
                [Units.GasKiloPascals]: 300,
                [Units.Pascals]: 300000,
                [Units.Psi]: 50,
                [Units.Bar]: 3,
                [Units.Mbar]: 3000,
              },
              UnitsContext.NONE,
            )[1],
            color: Color.BLUE.hex,
          },
          { pressureKPA: 0, color: Color.GREEN.hex },
        );
        break;
      case "ventilation":
        ret[layout].push(
          {
            pressureKPA: chooseByUnitsMetric(
              units,
              {
                [Units.KiloPascals]: -1000,
                [Units.GasKiloPascals]: -1000,
                [Units.Pascals]: -1000000,
                [Units.Psi]: -150,
                [Units.Bar]: -10,
                [Units.Mbar]: -10000,
              },
              UnitsContext.NONE,
            )[1],
            color: Color.ORANGE.hex,
          },
          {
            pressureKPA: chooseByUnitsMetric(
              units,
              {
                [Units.KiloPascals]: -700,
                [Units.GasKiloPascals]: -700,
                [Units.Pascals]: -700000,
                [Units.Psi]: -100,
                [Units.Bar]: -7,
                [Units.Mbar]: -7000,
              },
              UnitsContext.NONE,
            )[1],
            color: Color.YELLOW.hex,
          },
          {
            pressureKPA: chooseByUnitsMetric(
              units,
              {
                [Units.KiloPascals]: -300,
                [Units.GasKiloPascals]: -300,
                [Units.Pascals]: -300000,
                [Units.Psi]: -50,
                [Units.Bar]: -3,
                [Units.Mbar]: -3000,
              },
              UnitsContext.NONE,
            )[1],
            color: Color.BLUE.hex,
          },
          { pressureKPA: 0, color: Color.GREEN.hex },
        );
        break;
      case "mechanical":
        break;
    }
  }

  return ret;
}

const colorDividers = {
  // >100: Color.RED,
  100: Color.ORANGE.hex,
  50: Color.YELLOW.hex,
  0: Color.GREEN.hex,
  // <0: Color.CYAN,
};
export interface ColorDivider {
  [key: number]: string;
}

export type HeatmapSetting = {
  [key in DrawingLayout]: HeatmapSettingEntry[];
};

export interface HeatmapSettingEntry {
  pressureKPA: number;
  color: string;
}

export function drawHeatmapLegend(
  heatmapMode: HeatmapMode,
  doc: DocumentState,
) {
  if (
    heatmapMode !== HeatmapMode.Off &&
    heatmapMode !== HeatmapMode.ResidualPressure
  ) {
    const canvas = document.getElementById(
      "heatmap-legend",
    ) as HTMLCanvasElement;
    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const width = canvas.width * 0.36;
    const height = canvas.height * 0.9;
    ctx.fillStyle = "red";
    ctx.fillRect(0, 0.1 * canvas.height, width, 0.1 * canvas.height);
    ctx.fillStyle = "cyan";
    ctx.fillRect(0, height, width, 0.1 * canvas.height);

    ctx.fillStyle = "black";
    ctx.font = "Bold 12px Serif";

    ctx.fillTextStable(heatmapMode, 0, 20);

    ctx.fillTextStable("100%+", 50, 0.11 * canvas.height);
    ctx.fillTextStable("0%-", 50, 0.99 * canvas.height);

    const gradient = ctx.createLinearGradient(
      0,
      0.2 * canvas.height,
      0,
      height,
    );
    const keys = Object.keys(colorDividers).sort(
      (a, b) => parseInt(b) - parseInt(a),
    );
    for (const key of keys) {
      gradient.addColorStop(
        1 - parseInt(key) / 100,
        (colorDividers as any)[key],
      );
      ctx.fillTextStable(
        key + "%",
        50,
        0.2 * canvas.height +
          (1 - parseInt(key) / 100) * 0.7 * canvas.height +
          5,
      );
    }
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0.2 * canvas.height, width, 0.7 * canvas.height);
  } else if (heatmapMode === HeatmapMode.ResidualPressure) {
    const canvas = document.getElementById(
      "heatmap-legend",
    ) as HTMLCanvasElement;
    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
    const heatmapSetting = getSortedResidualPressure(doc.uiState);
    const localUnits = getTargetUnits(
      doc.drawing.metadata.units,
      Units.KiloPascals,
      UnitsContext.NONE,
    );

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const width = canvas.width * 0.36;
    const height = canvas.height * 0.9;
    ctx.fillStyle = "red";
    ctx.fillRect(0, 0.1 * canvas.height, width, 0.1 * canvas.height);
    ctx.fillStyle = "cyan";
    ctx.fillRect(0, height, width, 0.1 * canvas.height);

    const gradient = ctx.createLinearGradient(
      0,
      0.2 * canvas.height,
      0,
      height,
    );

    const threshold = convertPressureLocal(heatmapSetting[0].pressureKPA, doc);
    ctx.fillStyle = "black";
    ctx.font = "Bold 12px Serif";

    ctx.fillTextStable(heatmapMode + " (" + localUnits + ")", 0, 20);

    const smallThreshold = convertPressureLocal(
      heatmapSetting[heatmapSetting.length - 1].pressureKPA,
      doc,
    );
    const flipSign = smallThreshold > threshold;

    ctx.fillTextStable(
      `${flipSign ? "<" : ">"} ${threshold}`,
      50,
      0.11 * canvas.height,
    );
    ctx.fillTextStable(
      `${flipSign ? ">" : "<"} ${smallThreshold}`,
      50,
      0.99 * canvas.height,
    );

    for (const key of heatmapSetting) {
      const thisPressure = convertPressureLocal(key.pressureKPA, doc);

      gradient.addColorStop(
        1 - Number(thisPressure) / Number(threshold),
        key.color,
      );
      ctx.fillTextStable(
        String(thisPressure),
        50,
        0.2 * canvas.height +
          (1 - Number(thisPressure) / Number(threshold)) * 0.7 * canvas.height +
          5,
      );
    }
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0.2 * canvas.height, width, 0.7 * canvas.height);
  }
}

function convertPressureLocal(pressureKPA: number, doc: DocumentState) {
  return convertMeasurementSystem(
    doc.drawing.metadata.units,
    Units.KiloPascals,
    pressureKPA,
    Precision.DISPLAY,
    UnitsContext.NONE,
  )[1];
}

function getHeatmapColor(
  metricValue: number,
  threshold: number,
  colorDividers: ColorDivider,
) {
  const percentage = (metricValue / threshold) * 100;
  let topPercentage = Number.MIN_VALUE;
  let bottomPercentage = Number.MAX_VALUE;
  for (const keys of Object.keys(colorDividers)) {
    topPercentage = Math.max(topPercentage, parseFloat(keys));
    bottomPercentage = Math.min(bottomPercentage, parseFloat(keys));
  }
  if (percentage > topPercentage) {
    return Color.RED.hex;
  }
  if (percentage < bottomPercentage) {
    return Color.CYAN.hex;
  }
  let bottomColorKey: number | undefined;
  let topColorKey: number | undefined;
  const keys = Object.keys(colorDividers).sort(
    (a, b) => parseFloat(a) - parseFloat(b),
  );
  for (const key of keys) {
    if (percentage >= parseFloat(key)) {
      bottomColorKey = parseFloat(key);
    } else {
      topColorKey = parseFloat(key);
      break;
    }
  }
  if (topColorKey === undefined && bottomColorKey != undefined) {
    return colorDividers[bottomColorKey];
  }
  if (topColorKey != undefined && bottomColorKey != undefined) {
    return lerpColor(
      colorDividers[bottomColorKey],
      colorDividers[topColorKey],
      (percentage - bottomColorKey) / (topColorKey - bottomColorKey),
    );
  }
  return Color.GREY.hex;
}

export function getSortedResidualPressure(
  uiState: UIState,
): HeatmapSettingEntry[] {
  const reverseSort = uiState.drawingLayout === "ventilation";
  return [
    ...uiState.heatmapSettings[HeatmapMode.ResidualPressure][
      uiState.drawingLayout
    ],
  ]!.sort((a, b) => {
    return reverseSort
      ? a.pressureKPA - b.pressureKPA
      : b.pressureKPA - a.pressureKPA;
  });
}

export function generateConduitHeatmap(
  conduit: DrawableConduit,
  coreContext: CoreContext,
  heatmapMode: HeatmapMode,
  calculation: PipeCalculation | DuctCalculation,
  filledEntity: PipeConduitEntity | DuctConduitEntity,
) {
  switch (heatmapMode) {
    case HeatmapMode.Off:
      break;
    case HeatmapMode.ResidualPressure:
      const { residualPressure } = getConduitPressure(conduit.entity);
      const heatmapSetting = getSortedResidualPressure(
        conduit.document.uiState,
      );

      const rpColorDividers: ColorDivider = {};

      if (residualPressure !== undefined) {
        const threshold = heatmapSetting[0].pressureKPA;
        heatmapSetting.forEach((ele) => {
          rpColorDividers[(ele.pressureKPA / threshold) * 100] = ele.color;
        });

        const o = getHeatmapColor(residualPressure, threshold, rpColorDividers);

        console.log(
          "residualPressure",
          residualPressure,
          "threshold",
          threshold,
          "color",
          o,
        );

        return o;
      }
      break;
    case HeatmapMode.PressureDropKPAM:
      if (
        calculation.pressureDropExcludingTerminiKPAPerMeter !== null &&
        filledEntity.conduit.maximumPressureDropRateKPAM != null
      ) {
        return getHeatmapColor(
          calculation.pressureDropExcludingTerminiKPAPerMeter,
          filledEntity.conduit.maximumPressureDropRateKPAM,
          colorDividers,
        );
      }
      break;
    case HeatmapMode.StaticPressure:
      if (!isPipeEntity(conduit.entity)) return;
      const pipeSpec = CoreConduit.getCatalogBySizePage(
        coreContext,
        conduit.entity,
      );
      const endpointUid = (
        coreContext.globalStore.getOrCreateCalculation(conduit.entity)
          .expandedEntities![0] as ConduitEntity
      ).endpointUid;
      let ca: number | null = null;
      const caa = coreContext.globalStore.calculationStore.get(
        endpointUid[0],
      ) as ConduitConnectableCalculationConcrete;
      if (caa.type !== CalculationType.RiserCalculation) {
        ca = caa.staticPressureKPA;
      }

      let cb: number | null = null;
      const cbb = coreContext.globalStore.calculationStore.get(
        endpointUid[1],
      ) as ConduitConnectableCalculationConcrete;
      if (cbb.type !== CalculationType.RiserCalculation) {
        cb = cbb.staticPressureKPA;
      }

      const actualMaxPressureKPA = Math.max(ca || 0, cb || 0);

      if (pipeSpec) {
        const maxWorking = parseCatalogNumberExact(
          pipeSpec.safeWorkingPressureKPA,
        );
        if (maxWorking) {
          return getHeatmapColor(
            actualMaxPressureKPA,
            maxWorking,
            colorDividers,
          );
        }
      }
      break;
    case HeatmapMode.Velocity:
      if (
        calculation &&
        calculation.velocityRealMS != null &&
        filledEntity.conduit.maximumVelocityMS != null
      ) {
        return getHeatmapColor(
          calculation.velocityRealMS,
          filledEntity.conduit.maximumVelocityMS,
          colorDividers,
        );
      }
      break;
  }
}

export function generateRiserHeatmap(
  riser: DrawableRiser,
  heatmapMode: HeatmapMode,
  pipes: PipeConduitEntity[],
  coreContext: CoreContext,
) {
  switch (heatmapMode) {
    case HeatmapMode.Off:
      break;
    case HeatmapMode.ResidualPressure:
      const heatmapSetting = getSortedResidualPressure(riser.document.uiState);
      const threshold = heatmapSetting[0].pressureKPA;
      const rpColorDividers: ColorDivider = {};
      const rpPipe = pipes.reduce((prev, curr) => {
        const prevCalculation = getConduitPressure(prev);
        const currCalculation = getConduitPressure(curr);
        if (prevCalculation && currCalculation) {
          return prevCalculation.residualPressure >
            currCalculation.residualPressure
            ? prev
            : curr;
        } else {
          return curr;
        }
      });
      const rpPressure = getConduitPressure(rpPipe)?.residualPressure;

      if (rpPressure !== undefined) {
        heatmapSetting.forEach((ele) => {
          rpColorDividers[(ele.pressureKPA / threshold) * 100] = ele.color;
        });

        return getHeatmapColor(rpPressure, threshold, rpColorDividers);
      }
      break;
    case HeatmapMode.PressureDropKPAM:
      const pipe = pipes.reduce((prev, curr) => {
        const prevFilledEntity = CoreConduit.getFilledNetworkEntity(
          getGlobalContext(),
          prev,
        );
        const currFilledEntity = CoreConduit.getFilledNetworkEntity(
          getGlobalContext(),
          curr,
        );
        const prevCalculation = coreContext.globalStore.getCalculation(prev);
        const currCalculation = coreContext.globalStore.getCalculation(curr);
        if (
          prevCalculation &&
          currCalculation &&
          prevCalculation.pressureDropExcludingTerminiKPA !== null &&
          currCalculation.pressureDropExcludingTerminiKPA !== null &&
          prevFilledEntity.conduit.maximumPressureDropRateKPAM !== null &&
          currFilledEntity.conduit.maximumPressureDropRateKPAM !== null &&
          prevCalculation.lengthM !== null &&
          currCalculation.lengthM !== null
        ) {
          const prevPressureDropKPAM =
            prevCalculation.pressureDropExcludingTerminiKPA /
            prevCalculation.lengthM;
          const prevMaxPressureDropKPAM =
            prevFilledEntity.conduit.maximumPressureDropRateKPAM;
          const currPressureDropKPAM =
            currCalculation.pressureDropExcludingTerminiKPA /
            currCalculation.lengthM;
          const currMaxPressureDropKPAM =
            currFilledEntity.conduit.maximumPressureDropRateKPAM;
          return prevPressureDropKPAM / prevMaxPressureDropKPAM >
            currPressureDropKPAM / currMaxPressureDropKPAM
            ? prev
            : curr;
        } else {
          return curr;
        }
      });

      const maximumPressureDropRateKPAM = CoreConduit.getFilledNetworkEntity(
        getGlobalContext(),
        pipe,
      ).conduit.maximumPressureDropRateKPAM;
      const calculation = coreContext.globalStore.getCalculation(pipe);

      if (
        calculation &&
        calculation.pressureDropExcludingTerminiKPA &&
        calculation.lengthM &&
        maximumPressureDropRateKPAM
      ) {
        const pressureDropKPAM =
          calculation.pressureDropExcludingTerminiKPA / calculation.lengthM;
        return getHeatmapColor(
          pressureDropKPAM,
          maximumPressureDropRateKPAM,
          colorDividers,
        );
      }
      break;
    case HeatmapMode.StaticPressure:
      const pressurePipe = pipes.reduce((prev, curr) => {
        const prevs = getConduitPressure(prev);
        const currs = getConduitPressure(curr);
        const prevMaxWorking = prevs?.maxWorkingPressure;
        const currMaxWorking = currs?.maxWorkingPressure;
        const prevActualMaxPressureKPA = prevs?.actualStaticPressure;
        const currActualMaxPressureKPA = currs?.actualStaticPressure;

        if (
          prevMaxWorking &&
          currMaxWorking &&
          prevActualMaxPressureKPA !== undefined &&
          currActualMaxPressureKPA !== undefined
        ) {
          return prevActualMaxPressureKPA / prevMaxWorking >
            currActualMaxPressureKPA / currMaxWorking
            ? prev
            : curr;
        } else {
          return curr;
        }
      });
      const pressures = getConduitPressure(pressurePipe);
      if (pressures && pressures.maxWorkingPressure !== null) {
        return getHeatmapColor(
          pressures.actualStaticPressure,
          pressures?.maxWorkingPressure,
          colorDividers,
        );
      }
      break;
    case HeatmapMode.Velocity:
      const velocityPipe = pipes.reduce((prev, curr) => {
        const prevFilledEntity = CoreConduit.getFilledNetworkEntity(
          getGlobalContext(),
          prev,
        );
        const currFilledEntity = CoreConduit.getFilledNetworkEntity(
          getGlobalContext(),
          curr,
        );
        const prevCalculation = coreContext.globalStore.getCalculation(prev);
        const currCalculation = coreContext.globalStore.getCalculation(curr);
        if (
          prevCalculation &&
          currCalculation &&
          prevCalculation.velocityRealMS !== null &&
          currCalculation.velocityRealMS !== null &&
          prevFilledEntity.conduit.maximumVelocityMS !== null &&
          currFilledEntity.conduit.maximumVelocityMS !== null
        ) {
          return prevCalculation.velocityRealMS /
            prevFilledEntity.conduit.maximumVelocityMS >
            currCalculation.velocityRealMS /
              currFilledEntity.conduit.maximumVelocityMS
            ? prev
            : curr;
        } else {
          return curr;
        }
      });
      const velocity =
        coreContext.globalStore.getCalculation(velocityPipe)?.velocityRealMS;
      const maximumVelocityMS = CoreConduit.getFilledNetworkEntity(
        getGlobalContext(),
        velocityPipe,
      ).conduit.maximumVelocityMS;

      if (
        velocity !== null &&
        velocity !== undefined &&
        maximumVelocityMS !== null &&
        maximumVelocityMS !== undefined
      ) {
        return getHeatmapColor(velocity, maximumVelocityMS, colorDividers);
      }
      break;
  }
}

export function generateFixtureHeatmap(
  heatmapMode: HeatmapMode,
  calculation: FixtureCalculation,
  filledEntity: FixtureEntity,
  context: CoreContext,
) {
  switch (heatmapMode) {
    case HeatmapMode.Off:
      break;
    case HeatmapMode.PressureDropKPAM:
      break;
    case HeatmapMode.StaticPressure:
      const flowSystemUids = filledEntity.roughInsInOrder;
      let maxPressure: number | null = null;
      flowSystemUids.forEach((element) => {
        if (calculation.inlets[element].pressureKPA !== null) {
          if (maxPressure === null) {
            maxPressure = calculation.inlets[element].pressureKPA;
          }
          const pressure = calculation.inlets[element].pressureKPA;
          if (maxPressure && pressure) {
            maxPressure = Math.max(maxPressure, pressure);
          }
        }
      });
      const maxInletPressureKPA = parseCatalogNumberExact(
        context.catalog.fixtures[filledEntity.name].maxInletPressureKPA,
      );
      if (calculation && maxPressure != null && maxInletPressureKPA != null) {
        return getHeatmapColor(maxPressure, maxInletPressureKPA, colorDividers);
      }
      break;
    case HeatmapMode.Velocity:
      break;
  }
}

export function generateLoadNodeHeatmap(
  loadNode: DrawableLoadNode,
  heatmapMode: HeatmapMode,
  calculation: LoadNodeCalculation,
  filledEntity: LoadNodeEntity,
) {
  switch (heatmapMode) {
    case HeatmapMode.StaticPressure:
      const staticPressureKPA = calculation.staticPressureKPA;
      const maxStaticPressureKPA = filledEntity.maxPressureKPA;
      if (staticPressureKPA !== null && maxStaticPressureKPA !== null) {
        return getHeatmapColor(
          staticPressureKPA,
          maxStaticPressureKPA,
          colorDividers,
        );
      }
      break;
    case HeatmapMode.ResidualPressure:
      const residualPressure = calculation.pressureKPA;
      const heatmapSetting = getSortedResidualPressure(
        loadNode.document.uiState,
      );
      const rpColorDividers: ColorDivider = {};
      if (residualPressure !== null) {
        const threshold = heatmapSetting[0].pressureKPA;
        heatmapSetting.forEach((ele) => {
          rpColorDividers[(ele.pressureKPA / threshold) * 100] = ele.color;
        });
        return getHeatmapColor(residualPressure, threshold, rpColorDividers);
      }
      break;
    case HeatmapMode.Off:
      break;
    case HeatmapMode.PressureDropKPAM:
      break;
    case HeatmapMode.Velocity:
      break;
  }
}

export function generateFittingHeatmap(
  fitting: DrawableFitting,
  heatmapMode: HeatmapMode,
  calculation: FittingCalculation,
  filledEntity: FittingEntity,
  coreContext: CoreContext,
) {
  switch (heatmapMode) {
    case HeatmapMode.StaticPressure:
      const connectedPipe = coreContext.globalStore.getConnections(
        filledEntity.uid,
      );
      const pipeEntity = coreContext.globalStore.get(connectedPipe[0]);
      const pressure = getConduitPressure(pipeEntity.entity as ConduitEntity);
      if (pressure && pressure.maxWorkingPressure !== null) {
        return getHeatmapColor(
          pressure.actualStaticPressure,
          pressure.maxWorkingPressure,
          colorDividers,
        );
      }
      break;
    case HeatmapMode.ResidualPressure:
      const residualPressure = calculation.pressureKPA;
      const heatmapSetting = getSortedResidualPressure(
        fitting.document.uiState,
      );

      const rpColorDividers: ColorDivider = {};
      if (residualPressure !== null) {
        const threshold = heatmapSetting[0].pressureKPA;
        heatmapSetting.forEach((ele) => {
          rpColorDividers[(ele.pressureKPA / threshold) * 100] = ele.color;
        });
        return getHeatmapColor(residualPressure, threshold, rpColorDividers);
      }
      break;
    case HeatmapMode.Off:
      break;
    case HeatmapMode.PressureDropKPAM:
      break;
    case HeatmapMode.Velocity:
      break;
    default:
      assertUnreachable(heatmapMode);
  }
}

function getConduitPressure(entity: ConduitEntity) {
  const coreContext = getGlobalContext();
  const pipeSpec = CoreConduit.getCatalogBySizePage(getGlobalContext(), entity);

  const endpointUid = entity.endpointUid;
  let ca: number | null = null;
  let rca: number | null = null;
  const caa = coreContext.globalStore.calculationStore.get(
    endpointUid[0],
  ) as ConduitConnectableCalculationConcrete;
  if (caa.type !== CalculationType.RiserCalculation) {
    ca = caa.staticPressureKPA;
    rca = caa.pressureKPA;
  }

  let cb: number | null = null;
  let rcb: number | null = null;
  const cbb = coreContext.globalStore.calculationStore.get(
    endpointUid[1],
  ) as ConduitConnectableCalculationConcrete;
  if (cbb.type !== CalculationType.RiserCalculation) {
    cb = cbb.staticPressureKPA;
    rcb = cbb.pressureKPA;
  }

  const actualMaxPressureKPA = Math.max(ca || 0, cb || 0);

  const residualPressure = Math.max(rca || 0, rcb || 0);

  let maxWorking = null;
  if (pipeSpec) {
    maxWorking = parseCatalogNumberExact(pipeSpec.safeWorkingPressureKPA);
  }

  return {
    residualPressure: residualPressure,
    actualStaticPressure: actualMaxPressureKPA,
    maxWorkingPressure: maxWorking,
  };
}

export function isHeatmapEnabled(document: DocumentState) {
  return (
    document.uiState.heatmapMode !== undefined &&
    document.uiState.heatmapMode !== HeatmapMode.Off &&
    document.uiState.drawingMode === DrawingMode.Calculations
  );
}

export function drawConnectableInHeatmap(
  e: ConnectableEntityConcrete,
  _heatmapMode: HeatmapMode,
) {
  switch (e.type) {
    case EntityType.FITTING:
    case EntityType.RISER:
    case EntityType.LOAD_NODE:
      return true;
    default:
      return false;
  }
}
