import { captureConsoleIntegration } from "@sentry/integrations";
import * as SentryClient from "@sentry/vue";
import Vue from "vue";
import { CURRENT_VERSION } from "../../../../common/src/api/config";
import { FeatureAccess } from "../../../../common/src/models/FeatureAccess";
import { User } from "../../../../common/src/models/User";
import { ENABLE_SENTRY } from "../../config";
import { useAppVersion } from "../../lib/hooks/use-app-version";
import { useDocument } from "../../lib/hooks/use-document";
import { useFeatureFlags } from "../../lib/hooks/use-feature-flags";

export class Sentry {
  static updateUser(profile: User, featureAccess: FeatureAccess) {
    SentryClient.setUser({
      username: profile.username,
      email: profile.email ?? undefined,
      accessLevel: profile.accessLevel ?? undefined,
    });
    SentryClient.setExtra("featureAccess", featureAccess);
  }

  static updateDocument() {
    const document = useDocument();
    // Note: Tags are indexed and searchable, context is not
    SentryClient.setTags({
      documentId: document.documentId,
      documentVersion: CURRENT_VERSION,
    });
  }

  static updateFeatureFlags() {
    const featureFlags = useFeatureFlags();
    SentryClient.setExtra("featureFlags", featureFlags);
  }

  static init() {
    if (ENABLE_SENTRY) {
      SentryClient.init({
        Vue,
        dsn: "https://429531c63738445f907ccef3558e792e@o1351079.ingest.sentry.io/6631360",
        integrations: [
          SentryClient.browserTracingIntegration(),
          captureConsoleIntegration({
            levels: ["error"], // You can also capture other levels: 'log', 'warn', 'info'
          }),
        ],
        environment: "prod",
        release: useAppVersion(),
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        tracePropagationTargets: ["localhost", "app.h2xengineering.com", /^\//],
      });
    }
  }
}
