import { immutableAppend } from "../../../../common/src/lib/utils";
import { CanvasMouseMovementRecordT } from "../../../../flatbuffers/generated/canvas-mouse-movement-record";
import { ActiveReplayState } from "../../../src/store/document/types";

export const renderCanvasMouseMovementRecord = async (
  event: CanvasMouseMovementRecordT,
  replayState: ActiveReplayState,
) => {
  const canvasMouseMovements = immutableAppend(
    replayState.lastCanvasMouseMovements,
    event,
    10,
  );

  const newReplayState: ActiveReplayState = {
    ...replayState,
    lastCanvasMouseMovements: canvasMouseMovements,
  };

  return newReplayState;
};
