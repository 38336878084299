
import { defineComponent } from "vue";
import { asAny } from "../../../../common/src/lib/utils";

export default defineComponent({
  props: {
    type: {
      type: String,
      required: false,
      default: "submit",
    },
  },
  data() {
    return {
      scale: 20,
      asAny,
    };
  },
  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
});
