// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from "../flatbuffers/flatbuffers";

import { CanvasMouseEventRecordT } from "./canvas-mouse-event-record";
import { CanvasMouseMovementRecordT } from "./canvas-mouse-movement-record";
import { DiffRecordT } from "./diff-record";
import { KeyboardEventRecordT } from "./keyboard-event-record";
import { LevelChangeRecordT } from "./level-change-record";
import {
  ReplayEventUnion,
  unionListToReplayEventUnion,
} from "./replay-event-union";
import { ToolEventRecordT } from "./tool-event-record";
import { ViewPortRecordT } from "./view-port-record";

export class ReplayEventCollection
  implements flatbuffers.IUnpackableObject<ReplayEventCollectionT>
{
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): ReplayEventCollection {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsReplayEventCollection(
    bb: flatbuffers.ByteBuffer,
    obj?: ReplayEventCollection,
  ): ReplayEventCollection {
    return (obj || new ReplayEventCollection()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsReplayEventCollection(
    bb: flatbuffers.ByteBuffer,
    obj?: ReplayEventCollection,
  ): ReplayEventCollection {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new ReplayEventCollection()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  eventsType(index: number): ReplayEventUnion | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index)
      : 0;
  }

  eventsTypeLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  eventsTypeArray(): Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? new Uint8Array(
          this.bb!.bytes().buffer,
          this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset),
          this.bb!.__vector_len(this.bb_pos + offset),
        )
      : null;
  }

  events(index: number, obj: any): any | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? this.bb!.__union(
          obj,
          this.bb!.__vector(this.bb_pos + offset) + index * 4,
        )
      : null;
  }

  eventsLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  static startReplayEventCollection(builder: flatbuffers.Builder) {
    builder.startObject(2);
  }

  static addEventsType(
    builder: flatbuffers.Builder,
    eventsTypeOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(0, eventsTypeOffset, 0);
  }

  static createEventsTypeVector(
    builder: flatbuffers.Builder,
    data: ReplayEventUnion[],
  ): flatbuffers.Offset {
    builder.startVector(1, data.length, 1);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addInt8(data[i]!);
    }
    return builder.endVector();
  }

  static startEventsTypeVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(1, numElems, 1);
  }

  static addEvents(
    builder: flatbuffers.Builder,
    eventsOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(1, eventsOffset, 0);
  }

  static createEventsVector(
    builder: flatbuffers.Builder,
    data: flatbuffers.Offset[],
  ): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addOffset(data[i]!);
    }
    return builder.endVector();
  }

  static startEventsVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static endReplayEventCollection(
    builder: flatbuffers.Builder,
  ): flatbuffers.Offset {
    const offset = builder.endObject();
    return offset;
  }

  static createReplayEventCollection(
    builder: flatbuffers.Builder,
    eventsTypeOffset: flatbuffers.Offset,
    eventsOffset: flatbuffers.Offset,
  ): flatbuffers.Offset {
    ReplayEventCollection.startReplayEventCollection(builder);
    ReplayEventCollection.addEventsType(builder, eventsTypeOffset);
    ReplayEventCollection.addEvents(builder, eventsOffset);
    return ReplayEventCollection.endReplayEventCollection(builder);
  }

  unpack(): ReplayEventCollectionT {
    return new ReplayEventCollectionT(
      this.bb!.createScalarList<ReplayEventUnion>(
        this.eventsType.bind(this),
        this.eventsTypeLength(),
      ),
      (() => {
        const ret: (
          | CanvasMouseEventRecordT
          | CanvasMouseMovementRecordT
          | DiffRecordT
          | KeyboardEventRecordT
          | LevelChangeRecordT
          | ToolEventRecordT
          | ViewPortRecordT
        )[] = [];
        for (
          let targetEnumIndex = 0;
          targetEnumIndex < this.eventsTypeLength();
          ++targetEnumIndex
        ) {
          const targetEnum = this.eventsType(targetEnumIndex);
          if (targetEnum === null || ReplayEventUnion[targetEnum!] === "NONE") {
            continue;
          }

          const temp = unionListToReplayEventUnion(
            targetEnum,
            this.events.bind(this),
            targetEnumIndex,
          );
          if (temp === null) {
            continue;
          }
          ret.push(temp.unpack());
        }
        return ret;
      })(),
    );
  }

  unpackTo(_o: ReplayEventCollectionT): void {
    _o.eventsType = this.bb!.createScalarList<ReplayEventUnion>(
      this.eventsType.bind(this),
      this.eventsTypeLength(),
    );
    _o.events = (() => {
      const ret: (
        | CanvasMouseEventRecordT
        | CanvasMouseMovementRecordT
        | DiffRecordT
        | KeyboardEventRecordT
        | LevelChangeRecordT
        | ToolEventRecordT
        | ViewPortRecordT
      )[] = [];
      for (
        let targetEnumIndex = 0;
        targetEnumIndex < this.eventsTypeLength();
        ++targetEnumIndex
      ) {
        const targetEnum = this.eventsType(targetEnumIndex);
        if (targetEnum === null || ReplayEventUnion[targetEnum!] === "NONE") {
          continue;
        }

        const temp = unionListToReplayEventUnion(
          targetEnum,
          this.events.bind(this),
          targetEnumIndex,
        );
        if (temp === null) {
          continue;
        }
        ret.push(temp.unpack());
      }
      return ret;
    })();
  }
}

export class ReplayEventCollectionT implements flatbuffers.IGeneratedObject {
  _type = "ReplayEventCollectionT" as const;
  constructor(
    public eventsType: ReplayEventUnion[] = [],
    public events: (
      | CanvasMouseEventRecordT
      | CanvasMouseMovementRecordT
      | DiffRecordT
      | KeyboardEventRecordT
      | LevelChangeRecordT
      | ToolEventRecordT
      | ViewPortRecordT
    )[] = [],
  ) {}

  pack(builder: flatbuffers.Builder): flatbuffers.Offset {
    const eventsType = ReplayEventCollection.createEventsTypeVector(
      builder,
      this.eventsType,
    );
    const events = ReplayEventCollection.createEventsVector(
      builder,
      builder.createObjectOffsetList(this.events),
    );

    return ReplayEventCollection.createReplayEventCollection(
      builder,
      eventsType,
      events,
    );
  }
}
