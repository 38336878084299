import { CoreContext } from "../../../../common/src/api/calculations/types";
import { AnnotationEntity } from "../../../../common/src/api/document/entities/annotations/annotation-entity";
import { BackgroundEntity } from "../../../../common/src/api/document/entities/background-entity";
import BigValveEntity from "../../../../common/src/api/document/entities/big-valve/big-valve-entity";
import { DrawableEntityConcrete } from "../../../../common/src/api/document/entities/concrete-entity";
import ConduitEntity from "../../../../common/src/api/document/entities/conduit-entity";
import DirectedValveEntity from "../../../../common/src/api/document/entities/directed-valves/directed-valve-entity";
import { FenEntity } from "../../../../common/src/api/document/entities/fenestration-entity";
import FixtureEntity from "../../../../common/src/api/document/entities/fixtures/fixture-entity";
import FlowSourceEntity from "../../../../common/src/api/document/entities/flow-source-entity";
import GasApplianceEntity from "../../../../common/src/api/document/entities/gas-appliance";
import { LineEntity } from "../../../../common/src/api/document/entities/lines/line-entity";
import LoadNodeEntity from "../../../../common/src/api/document/entities/load-node-entity";
import { MultiwayValveEntity } from "../../../../common/src/api/document/entities/multiway-valves/multiway-valve-entity";
import PlantEntity from "../../../../common/src/api/document/entities/plants/plant-entity";
import RiserEntity from "../../../../common/src/api/document/entities/riser-entity";
import { EdgeEntity } from "../../../../common/src/api/document/entities/edge-entity";
import { RoomEntity } from "../../../../common/src/api/document/entities/rooms/room-entity";
import { SystemNodeEntity } from "../../../../common/src/api/document/entities/system-node-entity";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { VertexEntity } from "../../../../common/src/api/document/entities/vertices/vertex-entity";
import { WallEntity } from "../../../../common/src/api/document/entities/wall-entity";
import { assertUnreachable } from "../../../../common/src/lib/utils";
import { DocumentState } from "../../store/document/types";
import { DrawableObjectConcrete } from "../objects/concrete-object";
import DrawableBackgroundImage from "../objects/drawableBackgroundImage";
import DrawableBigValve from "../objects/drawableBigValve";
import DrawableConduit from "../objects/drawableConduit";
import DrawableDirectedValve from "../objects/drawableDirectedValve";
import DrawableFen from "../objects/drawableFenestration";
import DrawableFitting from "../objects/drawableFitting";
import DrawableFixture from "../objects/drawableFixture";
import DrawableFlowSource from "../objects/drawableFlowSource";
import DrawableGasAppliance from "../objects/drawableGasAppliance";
import DrawableLine from "../objects/drawableLine";
import DrawableLoadNode from "../objects/drawableLoadNode";
import DrawableMultiwayValve from "../objects/drawableMultiwayValve";
import DrawablePlant from "../objects/drawablePlant";
import DrawableRiser from "../objects/drawableRiser";
import DrawableRoom from "../objects/drawableRoom";
import DrawableEdge from "../objects/drawableEdge";
import DrawableSystemNode from "../objects/drawableSystemNode";
import DrawableVertex from "../objects/drawableVertex";
import DrawableWall from "../objects/drawableWall";
import { ObjectConstructArgs } from "./types";

// organize-imports-ignore
// this is to avoid cyclical dependency on DrawableConduit and cool-rag caused by selectable
import DrawableAnnotation from "../objects/drawableAnnotation";
import { ArchitectureElementEntity } from "../../../../common/src/api/document/entities/architectureElement-entity";
import DrawableArchitectureElement from "../objects/drawableArchitectureElement";
import { FittingEntity } from "../../../../common/src/api/document/entities/fitting-entity";
import DrawableDamper from "../objects/drawableDamper";
import DamperEntity from "../../../../common/src/api/document/entities/damper-entity";
import DrawableAreaSegment from "../objects/drawableAreaSegment";
import AreaSegmentEntity from "../../../../common/src/api/document/entities/area-segment-entity";

export default class DrawableObjectFactory {
  // Set hadndlers to false to create object without adding it to the state.
  // Use this if you just need an object instance for calculations, for example.
  static build(
    entity: DrawableEntityConcrete,
    context: CoreContext,
    document: DocumentState,
    levelUid: string | null,
    handlers: Handlers,
  ) {
    const args: ObjectConstructArgs<DrawableEntityConcrete> = {
      context,
      document,
      obj: entity,
      onSelect: (e) => handlers.onSelect(e),
      onInteractionComplete: (e) => handlers.onInteractionComplete(e),
    };

    const object = ((): DrawableObjectConcrete => {
      switch (args.obj.type) {
        case EntityType.BACKGROUND_IMAGE:
          return new DrawableBackgroundImage(
            args as ObjectConstructArgs<BackgroundEntity>,
          );
        case EntityType.BIG_VALVE:
          return new DrawableBigValve(
            args as ObjectConstructArgs<BigValveEntity>,
          );
        case EntityType.CONDUIT:
          return new DrawableConduit(
            args as ObjectConstructArgs<ConduitEntity>,
          );
        case EntityType.RISER:
          return new DrawableRiser(args as ObjectConstructArgs<RiserEntity>);
        case EntityType.FITTING:
          return new DrawableFitting(
            args as ObjectConstructArgs<FittingEntity>,
          );
        case EntityType.SYSTEM_NODE:
          return new DrawableSystemNode(
            args as ObjectConstructArgs<SystemNodeEntity>,
          );
        case EntityType.FIXTURE:
          return new DrawableFixture(
            args as ObjectConstructArgs<FixtureEntity>,
          );
        case EntityType.DIRECTED_VALVE:
          return new DrawableDirectedValve(
            args as ObjectConstructArgs<DirectedValveEntity>,
          );
        case EntityType.MULTIWAY_VALVE:
          return new DrawableMultiwayValve(
            args as ObjectConstructArgs<MultiwayValveEntity>,
          );
        case EntityType.LOAD_NODE:
          return new DrawableLoadNode(
            args as ObjectConstructArgs<LoadNodeEntity>,
          );
        case EntityType.PLANT:
          return new DrawablePlant(args as ObjectConstructArgs<PlantEntity>);
        case EntityType.FLOW_SOURCE:
          return new DrawableFlowSource(
            args as ObjectConstructArgs<FlowSourceEntity>,
          );
        case EntityType.GAS_APPLIANCE:
          return new DrawableGasAppliance(
            args as ObjectConstructArgs<GasApplianceEntity>,
          );
        case EntityType.EDGE:
          return new DrawableEdge(args as ObjectConstructArgs<EdgeEntity>);
        case EntityType.WALL:
          return new DrawableWall(args as ObjectConstructArgs<WallEntity>);
        case EntityType.FENESTRATION:
          return new DrawableFen(args as ObjectConstructArgs<FenEntity>);
        case EntityType.VERTEX:
          return new DrawableVertex(args as ObjectConstructArgs<VertexEntity>);
        case EntityType.ROOM:
          return new DrawableRoom(args as ObjectConstructArgs<RoomEntity>);
        case EntityType.LINE:
          return new DrawableLine(args as ObjectConstructArgs<LineEntity>);
        case EntityType.ANNOTATION:
          return new DrawableAnnotation(
            args as ObjectConstructArgs<AnnotationEntity>,
          );
        case EntityType.ARCHITECTURE_ELEMENT:
          return new DrawableArchitectureElement(
            args as ObjectConstructArgs<ArchitectureElementEntity>,
          );
        case EntityType.DAMPER:
          return new DrawableDamper(args as ObjectConstructArgs<DamperEntity>);
        case EntityType.AREA_SEGMENT:
          return new DrawableAreaSegment(
            args as ObjectConstructArgs<AreaSegmentEntity>,
          );
        case EntityType.COMPOUND:
          throw new Error("not implemented");
      }
      assertUnreachable(args.obj);
    })();

    context.globalStore.set(entity.uid, object, levelUid);

    return object;
  }
}

export interface Handlers {
  onSelect: (event: MouseEvent | KeyboardEvent) => void;
  onInteractionComplete: (event: MouseEvent | KeyboardEvent) => void;
}
