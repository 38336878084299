import Flatten from "@flatten-js/core";
import * as TM from "transformation-matrix";
import { Coord } from "../../../lib/coord";
import { decomposeMatrix, matrixScale } from "./utils";

export type BoxableShape =
  | Flatten.Segment
  | Flatten.Point
  | Flatten.Polygon
  | Flatten.Circle;
export default abstract class CoreWorldObject {
  abstract position: TM.Matrix;
  parentInternal: CoreWorldObject | null; // null parents mean root objects

  constructor(parent: CoreWorldObject | null) {
    this.parentInternal = parent;
  }

  get parent(): CoreWorldObject | null {
    return this.parentInternal;
  }

  fromParentToObjectCoord(parent: Coord): Coord {
    return TM.applyToPoint(TM.inverse(this.position), parent);
  }

  toObjectCoord(world: Coord): Coord {
    if (this.parent == null) {
      return this.fromParentToObjectCoord(world);
    }
    return this.fromParentToObjectCoord(this.parent.toObjectCoord(world));
  }

  fromParentToObjectLength(parentLength: number): number {
    return matrixScale(TM.inverse(this.position)) * parentLength;
  }

  toObjectLength(worldLength: number): number {
    if (this.parent == null) {
      return this.fromParentToObjectLength(worldLength);
    }
    return this.fromParentToObjectLength(
      this.parent.toObjectLength(worldLength),
    );
  }

  toParentCoord(object: Coord): Coord {
    return TM.applyToPoint(this.position, object);
  }

  toWorldCoord(object: Coord = { x: 0, y: 0 }): Coord {
    if (this.parent == null) {
      return TM.applyToPoint(this.position, object);
    }
    return this.parent.toWorldCoord(this.toParentCoord(object));
  }

  fromParentToWorldCoord(parent: Coord) {
    if (this.parent == null) {
      return parent;
    }
    return this.parent.toWorldCoord(parent);
  }

  fromParentToWorldLength(parent: number) {
    if (this.parent == null) {
      return parent;
    }
    return this.parent.toWorldLength(parent);
  }

  toParentLength(object: number): number {
    return matrixScale(this.position) * object;
  }

  toWorldLength(object: number): number {
    if (this.parent == null) {
      return this.toParentLength(object);
    }
    return this.parent.toWorldLength(this.toParentLength(object));
  }

  toParentAngleDeg(object: number) {
    return object + (decomposeMatrix(this.position).a / Math.PI) * 180;
  }

  toWorldAngleDeg(object: number): number {
    if (this.parent == null) {
      return this.toParentAngleDeg(object);
    }
    return this.parent.toWorldAngleDeg(this.toParentAngleDeg(object));
  }

  // For figuring out how to fit the view.
  get shape(): BoxableShape | null {
    const point = this.toWorldCoord({ x: 0, y: 0 });
    return Flatten.point(point.x, point.y);
  }
}
