import { Units } from "../../../../lib/measurements";
import { CoreContext } from "../../../calculations/types";
import { getTooltip, getTooltipsEntityName } from "../../../tooltips/tooltips";
import { DEFAULT_SPF } from "../../drawing";
import { FieldType, PropertyField } from "../property-field";
import PlantEntity from "./plant-entity";
import { HotWaterImmersionUse } from "./plant-types";

export function createDHWEnergyTabFields(
  filled: PlantEntity,
  context: CoreContext,
): PropertyField[] {
  const { drawing } = context;
  const tooltipsEntityName = getTooltipsEntityName(filled, drawing);
  return [
    {
      property: "plant.dairyDomesticWaterLoadL",
      title: "Daily Domestic Hot Water Load",
      hint: getTooltip(tooltipsEntityName, "Daily Domestic Hot Water Load"),
      hasDefault: true,
      isCalculated: false,
      requiresInput: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: null,
      units: Units.Liters,
      description: "45L/occupant",
    },
    {
      property: "plant.hotWaterImmersionUse",
      title: "Hot Water Immersion Use",
      hasDefault: false,
      isCalculated: false,
      requiresInput: false,
      type: FieldType.Choice,
      params: {
        choices: [
          { name: "Once Per Day", key: HotWaterImmersionUse.ONCE_PER_DAY },
          { name: "Once Per Week", key: HotWaterImmersionUse.ONCE_PER_WEEK },
          { name: "None", key: HotWaterImmersionUse.NONE },
        ],
      },
      multiFieldId: "plant-hotWaterImmersionUse",
    },
    {
      property: "plant.capacityL",
      title: "Size of Hot Water Cylinder",
      hasDefault: false,
      isCalculated: false,
      requiresInput: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: null,
      units: Units.Liters,
    },
    {
      property: "plant.SPF",
      title: "SPF",
      hint: getTooltip(tooltipsEntityName, "SPF"),
      hasDefault: false,
      isCalculated: true,
      requiresInput: false,
      type: FieldType.Number,
      params: { min: 0, max: null, initialValue: DEFAULT_SPF },
      multiFieldId: null,
      units: Units.None,
    },
    {
      property: "plant.legionellaPurgeTemperatureC",
      title: "Legionella Purge Temperature",
      hint: getTooltip(tooltipsEntityName, "Legionella Purge Temperature"),
      hasDefault: true,
      isCalculated: false,
      requiresInput: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "plant-legionellaPurge",
      units: Units.Celsius,
    },
    {
      property: "plant.legionellaPurgeFrequency",
      title: "Legionella Purge Frequency",
      hint: getTooltip(tooltipsEntityName, "Legionella Purge Frequency"),
      hasDefault: true,
      isCalculated: false,
      requiresInput: false,
      type: FieldType.Choice,
      params: {
        choices: [
          {
            name: "Daily",
            key: "Daily",
          },
          {
            name: "Weekly",
            key: "Weekly",
          },
          {
            name: "Monthly",
            key: "Monthly",
          },
        ],
      },
      multiFieldId: "plant-legionellaPurge-frequency",
    },
  ];
}
