export const FEATURE_ACCESS_OVERRIDE = "FEATURE_ACCESS_OVERRIDE";

export interface FeatureAccess {
  revitSync: boolean;
  marketplaceVerified: boolean;
  autocadExport: boolean;
  metaPdf: boolean;
  fullUnderfloorHeatingLoops: boolean;
}

export const defaultFeatureAccess: FeatureAccess = {
  revitSync: false,
  marketplaceVerified: false,
  autocadExport: false,
  metaPdf: true,
  fullUnderfloorHeatingLoops: true,
};

export const defaultTestingFeatureAccess: FeatureAccess = {
  revitSync: true,
  marketplaceVerified: true,
  autocadExport: true,
  metaPdf: true,
  fullUnderfloorHeatingLoops: true,
};
