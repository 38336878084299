export enum RoofType {
  Flat = "Flat",
  Shed = "Shed",
  Gable = "Gable",
  Hip = "Hip",
  Hex = "Hex",
  Sloped = "Sloped",
  RaisedShed = "Raised Shed",
  RaisedGable = "Raised Gable",
  RaisedHip = "Raised Hip",
  RaisedHex = "Raised Hex",
  RaisedSloped = "Raised Slope",
}

export interface RoomParamBase {
  roofType: RoofType;
}

export interface FlatRoofParams extends RoomParamBase {
  roofType: RoofType.Flat;
}

export interface ShedParams extends RoomParamBase {
  roofType: RoofType.Shed;
  heightM: number;
}

export interface GableParams extends RoomParamBase {
  roofType: RoofType.Gable;
  heightM: number;
}

export interface HipParams extends RoomParamBase {
  roofType: RoofType.Hip;
  lengthM: number;
  heightM: number;
}

export interface HexParams extends RoomParamBase {
  roofType: RoofType.Hex;
  heightM: number;
  widthM: number;
}

export interface SlopedParams extends RoomParamBase {
  roofType: RoofType.Sloped;
  heightM: number;
  widthM: number;
}

export interface RaisedShedParams extends RoomParamBase {
  roofType: RoofType.RaisedShed;
  raiseHeightM: number;
  peakHeightM: number;
}

export interface RasedGable extends RoomParamBase {
  roofType: RoofType.RaisedGable;
  raiseHeightM: number;
  peakHeightM: number;
}

export interface RaisedHip extends RoomParamBase {
  roofType: RoofType.RaisedHip;
  lengthM: number;
  raiseHeightM: number;
  peakHeightM: number;
}

export interface RaisedHex extends RoomParamBase {
  roofType: RoofType.RaisedHex;
  widthM: number;
  raiseHeightM: number;
  peakHeightM: number;
}

export interface RaisedSlope extends RoomParamBase {
  roofType: RoofType.RaisedSloped;
  widthM: number;
  raiseHeightM: number;
  peakHeightM: number;
}

interface RoofParamsMapping {
  [RoofType.Flat]: FlatRoofParams;
  [RoofType.Shed]: ShedParams;
  [RoofType.Gable]: GableParams;
  [RoofType.Hip]: HipParams;
  [RoofType.Hex]: HexParams;
  [RoofType.Sloped]: SlopedParams;
  [RoofType.RaisedShed]: RaisedShedParams;
  [RoofType.RaisedGable]: RasedGable;
  [RoofType.RaisedHip]: RaisedHip;
  [RoofType.RaisedHex]: RaisedHex;
  [RoofType.RaisedSloped]: RaisedSlope;
}

export type RoofParams = RoofParamsMapping[keyof RoofParamsMapping];

export type RoofSpec = {
  [K in RoofType]: RoofParamsMapping[K];
};

export const INITIAL_ROOF_SPEC: RoofSpec = {
  [RoofType.Flat]: {
    roofType: RoofType.Flat,
  },
  [RoofType.Shed]: {
    roofType: RoofType.Shed,
    heightM: 2.5,
  },
  [RoofType.Gable]: {
    roofType: RoofType.Gable,
    heightM: 2.5,
  },
  [RoofType.Hip]: {
    roofType: RoofType.Hip,
    lengthM: 2.5,
    heightM: 2.5,
  },
  [RoofType.Hex]: {
    roofType: RoofType.Hex,
    widthM: 2.5,
    heightM: 2.5,
  },
  [RoofType.Sloped]: {
    roofType: RoofType.Sloped,
    widthM: 2.5,
    heightM: 2.5,
  },
  [RoofType.RaisedShed]: {
    roofType: RoofType.RaisedShed,
    raiseHeightM: 2.5,
    peakHeightM: 4,
  },
  [RoofType.RaisedGable]: {
    roofType: RoofType.RaisedGable,
    raiseHeightM: 2.5,
    peakHeightM: 4,
  },
  [RoofType.RaisedHip]: {
    roofType: RoofType.RaisedHip,
    lengthM: 2.5,
    raiseHeightM: 2.5,
    peakHeightM: 4,
  },
  [RoofType.RaisedHex]: {
    roofType: RoofType.RaisedHex,
    widthM: 2.5,
    raiseHeightM: 2.5,
    peakHeightM: 4,
  },
  [RoofType.RaisedSloped]: {
    roofType: RoofType.RaisedSloped,
    widthM: 2.5,
    raiseHeightM: 2.5,
    peakHeightM: 4,
  },
};
