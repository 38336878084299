import { FloorWaste } from "../document/entities/directed-valves/valve-types";
import {
  FilterConfiguration,
  FilterPlant,
  GreaseInterceptorTrapLocation,
  GreaseInterceptorTrapPosition,
  PumpConfiguration,
  RheemVariantValues,
} from "../document/entities/plants/plant-types";

export interface ConduitCalculationReportEntry {
  type: "CONDUIT";
  nominalSizeMM: number | null;
  lengthM: number | null;
}

export interface RiserCalculationReportEntry {
  type: "RISER";
  expandedEntities: ConduitCalculationReportEntry[];
}

export interface BackflowValveSpecificationData {
  sizeMM: number | null;
  flowSystemUid: string | null;
}

export interface GreaseInterceptorTrapSpecificationData {
  capacity: number | null;
  location: keyof typeof GreaseInterceptorTrapLocation | null;
  position: keyof typeof GreaseInterceptorTrapPosition | null;
  model: string | null;
  flowSystemUid: string | null;
}

export interface PumpSpecificationData {
  model: string | null;
  pressureKPA: number | null;
  dutyKPA: number | null;
  configuration: PumpConfiguration | null;
  flowRateLS: number | null;
  flowSystemUid: string | null;
}

export interface PumpTankSpecificationData {
  model: string | null;
  pressureKPA: number | null;
  dutyKPA: number | null;
  configuration: PumpConfiguration | null;
  capacityL: number | null;
  flowRateLS: number | null;
  flowSystemUid: string | null;
}

export interface BalancingValveSpecificationData {
  pressureDropKPA: number | null;
  kv: number | null;
  returnLoopFlowRateLS: number | null;
  flowRateLS: number | null;
  flowSystemUid: string | null;
}

export interface HotWaterPlantSpecificationData {
  variant: RheemVariantValues | null;
  storageTank: number | null;
  flowRateLS: number | null;
  peakHourFlowRateLS: number | null;
  models: string | string[] | null | undefined;
}

export interface PRVSpecificationData {
  sizeMM: number | null;
  targetPressureKPA: number | null;
  flowRateLS: number | null;
  residualIncomingPressureKPA: number | null;
  staticIncomingPressureKPA: number | null;
  flowSystemUid: string | null;
}

export interface FixtureSpecificationData {
  // None
  // what fixture it is is included
  inletResidualPressureKPA: Record<string, number | null> | null;
}

// Note: riser is the same as pipe.
export interface PipeSpecificationData {
  //material is covered
  nominalSizeMM: number | null;
  lengthM: number | null;
  flowSystemUid: string | null;
}

export function pipeSpecKey(
  material: string,
  manufacturer: string,
  flowSystemUid: string | null,
): string {
  return `${material}-${manufacturer}-${flowSystemUid}`;
}

export interface MixingValveSpecificationData {
  flowRateLS: number | null;
  sizeMM: number | null;
  pressureDropKPA: number | null;
}

export interface FloorWasteSpecificationData {
  variant: FloorWaste["variant"] | null;
  bucketTrapSize: FloorWaste["bucketTrapSize"] | null;
  flowSystemUid: string | null;
}

export interface InspectionOpeningSpecificationData {
  sizeMM: number | null;
}

export interface CirculatingPumpSpecificationData {
  model: string | null;
}

export interface FilterSpecificationData {
  model: string | null;
  filterType: FilterPlant["filterType"] | null;
  configuration: FilterConfiguration | null;
}

export interface RoSpecificationData {
  model: string | null;
}

export interface RadiatorSpecificationData {
  model: string | null;
  rangeType: string | null;
  ratingKw: number | null;
}

export type SpecificationData =
  | BackflowValveSpecificationData
  | GreaseInterceptorTrapSpecificationData
  | PumpSpecificationData
  | PumpTankSpecificationData
  | BalancingValveSpecificationData
  | HotWaterPlantSpecificationData
  | PRVSpecificationData
  | FixtureSpecificationData
  | PipeSpecificationData
  | MixingValveSpecificationData
  | FloorWasteSpecificationData
  | InspectionOpeningSpecificationData
  | CirculatingPumpSpecificationData
  | FilterSpecificationData
  | RoSpecificationData
  | RadiatorSpecificationData;

export default interface AbbreviatedCalculationReport {
  calculations: {
    [key: string]: ConduitCalculationReportEntry | RiserCalculationReportEntry;
  };
  specifications: {
    // (partType, manufacturer)
    byPartType: Record<string, Record<string, SpecificationData[]>>;

    // Special because we would aggregate by diamater, instead of
    // individual pipe segments.
    // (material, manufacturer, diameter)
    pipeSpecifications: Record<
      string,
      {
        material: string;
        manufacturer: string;
        flowSystemUid: string | null;
        segments: number;
        bySize: Record<number, PipeSpecificationData>;
      }
    >;
  };
}

export function anonymizeSpecifications(
  partType: string,
  data: SpecificationData[],
): SpecificationData[] {
  return data.map((spec) => {
    if ("model" in spec) {
      spec.model = null;
    }
    if (partType.startsWith("hotWaterPlant") && "variant" in spec) {
      spec.variant = null;
    }
    return spec;
  });
}
