
import Vue from "vue";
import Component from "vue-class-component";
import {
  ErrorReport,
  ErrorStatus,
} from "../../../common/src/models/ErrorReport";
import { getErrorReport } from "../api/error-report";
import MainNavBar from "../components/MainNavBar.vue";

@Component({
  components: { MainNavBar },
  watch: {
    $route(to) {
      (this as ViewError).updateId(to.params.id);
    },
  },
})
export default class ViewError extends Vue {
  errorReport: ErrorReport | null = null;

  updateId(id: string) {
    getErrorReport(Number(id)).then((res) => {
      if (res.success) {
        this.errorReport = res.data;
      } else {
        this.$bvToast.toast(res.message, {
          title: "Error retrieving data about error (kek)",
          variant: "danger",
        });
      }
    });
  }

  mounted() {
    this.updateId(this.$route.params.id);
  }

  get ErrorStatus() {
    return ErrorStatus;
  }
}
