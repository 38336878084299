export interface Color {
  hex: string;
}

export const Color = {
  RED: { hex: "#FF0000" },
  ORANGE: { hex: "#FFA500" },
  YELLOW: { hex: "#FFFF00" },
  GREEN: { hex: "#00FF00" },
  BLUE: { hex: "#0000FF" },
  CYAN: { hex: "#00FFFF" },
  WHITE: { hex: "#FFFFFF" },
  GREY: { hex: "#808080" },
  BLACK: { hex: "#000000" },
  PURPLE: { hex: "#800080" },
};

export function goldenRatioColor(n: number) {
  const goldenRatio = 0.618033988749895;
  const hue = (n * goldenRatio) % 1;
  return hslToColor(hue, 0.8, 0.7);
}

export function hslToRgb(
  h: number,
  s: number,
  l: number,
): { r: number; g: number; b: number } {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255),
  };
}

export function hslToColor(h: number, s: number, l: number) {
  const rgb = hslToRgb(h, s, l);
  return rgb2color(rgb);
}

export function rgb2color(rgb: { r: number; g: number; b: number }): Color {
  let str = ((rgb.r << 16) | (rgb.g << 8) | (rgb.b << 0)).toString(16);
  return { hex: "#" + str };
}

export function hexToRgba(hex: string, alpha: number) {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function getColorFromPalette(
  colorIdx: number,
  palette: string[],
): string {
  return palette[colorIdx % palette.length];
}

export function lighten(col: string, percent: number, alpha: number = 1.0) {
  const num = parseInt(col.substr(1), 16);

  let b = num & 0xff;
  let g = (num >> 8) & 0xff;
  let r = (num >> 16) & 0xff;

  if (percent < 0) {
    // darken
    b *= (100 + percent) / 100;
    g *= (100 + percent) / 100;
    r *= (100 + percent) / 100;
  } else {
    // lighten
    b += (255 - b) * (percent / 100);
    g += (255 - g) * (percent / 100);
    r += (255 - r) * (percent / 100);
  }

  if (alpha === 1) {
    let str = ((r << 16) | (g << 8) | (b << 0)).toString(16);
    while (str.length < 6) {
      str = "0" + str;
    }
    return "#" + str;
  } else {
    return (
      "rgba(" +
      Math.round(r) +
      ", " +
      Math.round(g) +
      ", " +
      Math.round(b) +
      ", " +
      alpha +
      ")"
    );
  }
}
