// can't believe I can't find a basic 3D vector module

import Flatten from "@flatten-js/core";
import { Coord3D } from "./coord";
import { isParallelRad } from "./mathUtils/mathutils";
import { EPS, floatEq } from "./utils";

export class Vector3 {
  x: number;
  y: number;
  z: number;

  static ZERO = new Vector3(0, 0, 0);
  static UNIT_X = new Vector3(1, 0, 0);
  static UNIT_Y = new Vector3(0, 1, 0);
  static UNIT_Z = new Vector3(0, 0, 1);

  static between(a: Coord3D, b: Coord3D): Vector3 {
    return new Vector3(b.x - a.x, b.y - a.y, b.z - a.z);
  }

  constructor(x: number, y: number, z: number) {
    this.x = x;
    this.y = y;
    this.z = z;
  }

  normalize(): Vector3 {
    const v = Math.sqrt(this.x ** 2 + this.y ** 2 + this.z ** 2);
    if (floatEq(v, 0)) {
      return Vector3.ZERO;
    }

    return this.div(v);
  }

  add(v: Vector3): Vector3 {
    return new Vector3(this.x + v.x, this.y + v.y, this.z + v.z);
  }

  sub(v: Vector3): Vector3 {
    return new Vector3(this.x - v.x, this.y - v.y, this.z - v.z);
  }

  mul(v: number): Vector3 {
    return new Vector3(this.x * v, this.y * v, this.z * v);
  }

  div(v: number): Vector3 {
    if (floatEq(v, 0)) {
      throw new Error("Vector3: Division by zero");
    }
    return new Vector3(this.x / v, this.y / v, this.z / v);
  }

  dot(v: Vector3): number {
    return this.x * v.x + this.y * v.y + this.z * v.z;
  }

  equals(v: Vector3): boolean {
    return floatEq(this.x, v.x) && floatEq(this.y, v.y) && floatEq(this.z, v.z);
  }

  isParallelTo(v: Vector3, tolerance = EPS): boolean {
    const angle = this.angleTo(v);
    if (isParallelRad(0, angle, tolerance)) {
      return true;
    }
    return false;
  }

  isClockwiseRotation(v: Vector3, axis: Vector3 = Vector3.UNIT_Z): boolean {
    return this.mul(-1).cross(v).dot(axis) > 0;
  }

  cross(v: Vector3): Vector3 {
    return new Vector3(
      this.y * v.z - this.z * v.y,
      this.z * v.x - this.x * v.z,
      this.x * v.y - this.y * v.x,
    );
  }

  determinant(v: Vector3): number {
    const cross = this.cross(v);
    return Math.sqrt(cross.dot(cross));
  }

  // Absolute angle, shortest
  angleTo(v: Vector3): number {
    return Math.atan2(this.determinant(v), this.dot(v));
  }

  angleToCCW(v: Vector3, axis: Vector3 = Vector3.UNIT_Z): number {
    const angle = this.angleTo(v);
    if (this.cross(v).dot(axis) > 0) {
      return angle;
    }
    return 2 * Math.PI - angle;
  }

  get length(): number {
    return Math.sqrt(this.dot(this));
  }

  get unit(): Vector3 {
    if (floatEq(this.length, 0)) {
      return Vector3.ZERO;
    }
    return this.div(this.length);
  }

  get angleRAD(): number {
    return Math.atan2(this.y, this.x);
  }

  rotateCWRAD(angle: number, axis: Vector3 = Vector3.UNIT_Z): Vector3 {
    const u = axis.unit;
    const cos = Math.cos(angle);
    const sin = Math.sin(angle);
    const oneMinusCos = 1 - cos;
    const x =
      this.dot(u.mul(oneMinusCos)) * u.x +
      this.x * cos +
      (-this.z * u.y + this.y * u.z) * sin;
    const y =
      this.dot(u.mul(oneMinusCos)) * u.y +
      this.y * cos +
      (this.z * u.x - this.x * u.z) * sin;
    const z =
      this.dot(u.mul(oneMinusCos)) * u.z +
      this.z * cos +
      (-this.y * u.x + this.x * u.y) * sin;
    return new Vector3(x, y, z);
  }

  rotateCWDEG(angle: number, axis: Vector3 = new Vector3(0, 0, 1)): Vector3 {
    return this.rotateCWRAD((angle * Math.PI) / 180, axis);
  }

  toFlatten(): Flatten.Vector {
    return new Flatten.Vector(this.x, this.y);
  }
}
