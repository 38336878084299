import { CoreContext } from "../../calculations/types";
import { ArchitectureElementEntity } from "../entities/architectureElement-entity";
import { CalculationField } from "./calculation-field";
import { Calculation, CalculationType, LiveCalculation } from "./types";

export interface ArchitectureElementCalculation extends Calculation {
  type: CalculationType.ArchitectureElementCalculation;
  areaM2: number;
}

export interface ArchitectureElementLiveCalculation extends LiveCalculation {
  type: CalculationType.ArchitectureElementCalculation;
  areaM2: number;
}

export function makeArchitectureElementCalculationFields(
  context: CoreContext,
  entity: ArchitectureElementEntity,
): CalculationField[] {
  let result: CalculationField[] = [];

  return result;
}

export function emptyArchitectureElementLiveCalculation(): ArchitectureElementLiveCalculation {
  return {
    connected: null,
    warnings: [],
    type: CalculationType.ArchitectureElementCalculation,
    areaM2: 0,
  };
}

export function emptyArchitectureElementCalculation(): ArchitectureElementCalculation {
  return {
    warnings: [],
    costBreakdown: null,
    expandedEntities: null,
    type: CalculationType.ArchitectureElementCalculation,
    areaM2: 0,
    cost: null,
  };
}
