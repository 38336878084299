import Flatten from "@flatten-js/core";
import * as TM from "transformation-matrix";
import { cloneSimple } from "../../../lib/utils";
import { CoreContext } from "../../calculations/types";
import { ArchitectureElementEntity } from "../../document/entities/architectureElement-entity";
import { BoxedEntityConcrete } from "../../document/entities/concrete-entity";
import CoreBaseBackedObject from "../lib/coreBaseBackedObject";
import { GuessEntity, SelectionTarget } from "../lib/types";

export function CoreBoxed<
  T extends abstract new (...args: any[]) => CoreBaseBackedObject<I>,
  I extends BoxedEntityConcrete = GuessEntity<T>,
>(Base: T) {
  abstract class Generated extends Base {
    get minX(): number {
      return this.entity.center.x - (this.entity.widthM * 1000) / 2;
    }
    get maxX(): number {
      return this.entity.center.x + (this.entity.widthM * 1000) / 2;
    }
    get minY(): number {
      return this.entity.center.y - (this.entity.lengthM * 1000) / 2;
    }
    get maxY(): number {
      return this.entity.center.y + (this.entity.lengthM * 1000) / 2;
    }
    getHash(): string {
      let hashStr = "";
      hashStr += this.entity;
      return hashStr;
    }

    get position(): TM.Matrix {
      // We don't draw by object location because the object doesn't really have an own location. Instead, its
      // location is determined by other objects.
      return TM.identity();
    }
    getCalculationEntities(context: CoreContext): ArchitectureElementEntity[] {
      let res = cloneSimple(this.entity);
      res.uid = this.getCalculationUid(context);
      return [this.entity];
    }
    getCalculationUid(context: CoreContext): string {
      return this.uid + ".calculation";
    }

    preCalculationValidation(context: CoreContext): SelectionTarget | null {
      return null;
    }
    get areaM2(): number {
      return 0;
    }

    get shape(): Flatten.Polygon {
      let polygon: Flatten.Polygon = new Flatten.Polygon();
      let o1 = { x: this.minX, y: this.minY };
      let o2 = { x: this.maxX, y: this.maxY };

      polygon.addFace([
        Flatten.point(o1.x, o1.y),
        Flatten.point(o2.x, o1.y),
        Flatten.point(o2.x, o2.y),
        Flatten.point(o1.x, o2.y),
      ]);
      return polygon;
    }
  }
  return Generated;
}
