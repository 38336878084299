import { Coord } from "../../../lib/coord";
import { Units, UnitsContext } from "../../../lib/measurements";
import { assertUnreachable } from "../../../lib/utils";
import {
  GlobalHeatLoadInfo,
  HEATLOAD_COMPONENT_TYPE_SET,
  HeatLoadComponentType,
  RoofHeatLoadCalculation,
  RoomHeatLoadCalculation,
} from "../../calculations/heatloss/heat-loss-result-type";
import { CoreContext } from "../../calculations/types";
import {
  RoomEntity,
  RoomEntityConcrete,
  RoomType,
} from "../entities/rooms/room-entity";
import {
  CalculationField,
  FieldCategory,
  nestCalculationFields,
} from "./calculation-field";
import {
  CalcFieldSelector,
  Calculation,
  CalculationType,
  LiveCalculation,
} from "./types";
import {
  LoopSiteCalculation,
  UnderfloorHeatingLoopCalculation,
  getLoopStatFields,
} from "./underfloor-heating-loop-calculation";

export interface RoomCalculation
  extends Calculation,
    RoomHeatLoadCalculation,
    RoofHeatLoadCalculation {
  type: CalculationType.RoomCalculation;

  isLeaderRoomPerFloor: boolean | null;
  isZoneLeader: boolean | null;
  zoneVentFlowRateLS: number | null;
  zoneSupplyVentFlowRateAddressedLS: number | null;
  zoneExtractVentFlowRateAddressedLS: number | null;

  floorType: RoomEntityConcrete["floorType"] | null;

  underfloorHeating: UnderfloorHeatingCalc;

  debug?: RoomCalcDebug;
}

export type RoomCalcDebug = {
  color: string;
  chain: Coord[];
  text?: string;
  dash?: number[];
  role?: string;
  thickness?: number;
}[];

export interface CoilMapping {
  coilLengthM: number;
  loopsCovered: UnderfloorHeatingLoopCalculation[];
}

export interface UnderfloorHeatingCalc extends LoopSiteCalculation {
  unheatedAreaM2: number;
  excludedAreaM2: number; // covered by other heated areas

  // new calc fields
  loopSpacingMM: number | null;
  pipeSizeMM: number | null;
  loopDirectionDEG: number | null;
  floorTempC: number | null;
  outputWattPerM2: number | null;
  heatOutputW: number | null;
  totalPipeLengthM: number | null;
  numLoops: number | null;
  manifoldUid: string | null; // null indicates no manifold - approximate loop only.
  meanWaterTempC: number | null;
  returnTempC: number | null;
  deltaT: number | null;
  floorFinish: string | null;
  designTemperatureC: number | null;
  roomName: string | null;

  loopMode: "approximate" | "full" | null;

  // this only exists for one room in the drawing to represent the all coils in the project
  coils: CoilMapping[];
}

export interface RoomLiveCalculation extends LiveCalculation {
  totalHeatLossWatt: number | null;
  totalHeatGainWatt: number | null;
  totalHeatLossAddressedWATT: number | null;
  totalHeatGainAddressedWATT: number | null;
  ventAirChangeRatePerHour: number | null;
  heatingAirChangeRatePerHour: number | null;
  ventilationFlowRateLS: number | null;
  heatingFlowRateLS: number | null;
  underfloorHeating: UnderfloorHeatingCalc;

  floorHeadLoadInfo: GlobalHeatLoadInfo | null;
  buildingHeatLoadInfo: GlobalHeatLoadInfo | null;

  isLeaderRoomPerFloor: boolean | null;
  isZoneLeader: boolean | null;
  zoneVentFlowRateLS: number | null;
  zoneSupplyVentFlowRateAddressedLS: number | null;
  zoneExtractVentFlowRateAddressedLS: number | null;

  reference: string | null;
  volumeM3: number | null;
  floorType: RoomEntityConcrete["floorType"] | null;

  debug?: {
    color: string;
    chain: Coord[];
  }[];
}

export const FastLiveRoomCalculationFields: CalcFieldSelector<RoomLiveCalculation> =
  {
    connected: true,
    totalHeatLossWatt: true,
    totalHeatGainWatt: true,
    totalHeatLossAddressedWATT: true,
    totalHeatGainAddressedWATT: true,

    zoneSupplyVentFlowRateAddressedLS: true,
    zoneExtractVentFlowRateAddressedLS: true,
    zoneVentFlowRateLS: true,

    floorHeadLoadInfo: {
      heatLossWatt: true,
      heatGainWatt: true,
      areaM2: true,
    },
    buildingHeatLoadInfo: {
      heatLossWatt: true,
      heatGainWatt: true,
      areaM2: true,
      volumeM3: true,
    },
    volumeM3: true,
    debug: true,
  };

export const StickyRoomCalcualtionFields: CalcFieldSelector<RoomCalculation> =
  {};
export const HEATLOSS_RESULT_HEX = "#db0f27";
export const HEATGAIN_RESULT_HEX = "#0a1172";

function makeRoomHeatLossCalculationFields(): CalculationField<RoomCalculation>[] {
  const result: CalculationField[] = [];
  result.push({
    property: "totalHeatLossWatt",
    title: "Heat Loss",
    short: "",
    units: Units.Watts,
    category: FieldCategory.HeatLoss,
    layouts: ["mechanical"],
    defaultEnabled: false,
    color: HEATLOSS_RESULT_HEX,
    unitContext: UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
  });

  for (const type of HEATLOAD_COMPONENT_TYPE_SET) {
    if (
      type === HeatLoadComponentType.SOLAR_GAIN ||
      type === HeatLoadComponentType.INTERNAL_SOURCES
    )
      continue;
    result.push({
      property: `heatLoadResult.${type}.heatLossThroughComponentWatt`,
      title: `Heat Loss Through ${type}`,
      short: "",
      units: Units.Watts,
      category: FieldCategory.HeatLoss,
      layouts: ["mechanical"],
      defaultEnabled: false,
      color: HEATLOSS_RESULT_HEX,
      unitContext: UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
    });
  }

  return result;
}

function makeRoomUnderfloorHeatingCalculationFields(): CalculationField<RoomCalculation>[] {
  return nestCalculationFields<RoomCalculation, UnderfloorHeatingCalc>(
    "underfloorHeating",
    [
      {
        property: "floorTempC",
        title: "Floor Temperature",
        short: "",
        units: Units.Celsius,
        category: FieldCategory.HeatLoss,
        layouts: ["mechanical"],
        defaultEnabled: false,
      },
      {
        property: "meanWaterTempC",
        title: "Loop Mean Water Temperature",
        short: "",
        units: Units.Celsius,
        category: FieldCategory.HeatLoss,
        layouts: ["mechanical"],
        defaultEnabled: false,
      },
      {
        property: "loopSpacingMM",
        title: "Loop Spacing",
        short: "",
        units: Units.Millimeters,
        category: FieldCategory.HeatLoss,
        layouts: ["mechanical"],
        defaultEnabled: false,
      },
      {
        property: "totalPipeLengthM",
        title: "Total Loop Pipe Length",
        short: "",
        units: Units.Millimeters,
        category: FieldCategory.Length,
        layouts: ["mechanical"],
        defaultEnabled: false,
      },
      {
        property: "heatOutputW",
        title: "Loop Heat Output",
        short: "",
        units: Units.Watts,
        unitContext: UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
        category: FieldCategory.HeatLoss,
        layouts: ["mechanical"],
        defaultEnabled: false,
      },
      ...getLoopStatFields(),
    ],
  );
}

function makeRoomHeatGainCalculationFields(): CalculationField<RoomCalculation>[] {
  const result: CalculationField[] = [];
  result.push({
    property: "totalHeatGainWatt",
    title: "Heat Gain",
    short: "",
    units: Units.Watts,
    category: FieldCategory.HeatLoss,
    layouts: ["mechanical"],
    defaultEnabled: false,
    color: HEATGAIN_RESULT_HEX,
    unitContext: UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
  });

  for (const type of HEATLOAD_COMPONENT_TYPE_SET) {
    result.push({
      property: `heatLoadResult.${type}.heatGainThroughComponentWatt`,
      title: `Heat Gain Through ${type}`,
      short: "",
      units: Units.Watts,
      category: FieldCategory.HeatLoss,
      layouts: ["mechanical"],
      defaultEnabled: false,
      color: HEATGAIN_RESULT_HEX,
      unitContext: UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
    });
  }
  return result;
}

function makeRoomConcreteCalculationFields(): CalculationField<RoomCalculation>[] {
  return [
    {
      property: "areaM2",
      title: "Room Area",
      short: "",
      units: Units.SquareMeters,
      category: FieldCategory.Size,
      layouts: ["mechanical"],
      defaultEnabled: false,
    },
    {
      property: "volumeM3",
      title: "Room Volume",
      short: "",
      units: Units.CubicMeters,
      category: FieldCategory.Volume,
      layouts: ["mechanical"],
      defaultEnabled: false,
    },
    {
      property: "perimeterM",
      title: "Room Perimeter",
      short: "",
      units: Units.Meters,
      category: FieldCategory.Size,
      layouts: ["mechanical"],
      defaultEnabled: false,
    },
    {
      property: "ventAirChangeRatePerHour",
      title: "Ventilation Air change rate of the room per hour",
      short: "",
      units: Units.None,
      category: FieldCategory.HeatLoss,
      layouts: ["mechanical"],
      defaultEnabled: false,
    },
    {
      property: "heatingAirChangeRatePerHour",
      title: "Heating Air change rate of the room per hour",
      short: "",
      units: Units.None,
      category: FieldCategory.HeatLoss,
      layouts: ["mechanical"],
      defaultEnabled: false,
    },
    {
      property: "thermalBridgingCoefficient",
      title: "Thermal Bridging Coefficient",
      short: "",
      units: Units.None,
      category: FieldCategory.HeatLoss,
      layouts: ["mechanical"],
      defaultEnabled: false,
    },
    ...makeRoomHeatLossCalculationFields(),
    ...makeRoomHeatGainCalculationFields(),
    ...makeRoomUnderfloorHeatingCalculationFields(),
  ];
}

function makeRoofConcreteCalculationFields(
  context: CoreContext,
  entity: RoomEntity,
) {
  const result: CalculationField[] = [];

  result.push({
    property: "roofAreaM2",
    title: "Roof Area",
    short: "",
    units: Units.SquareMeters,
    category: FieldCategory.Size,
    layouts: ["mechanical"],
    defaultEnabled: false,
  });
  result.push({
    property: "externalWallAreaM2",
    title: "External Wall Area",
    short: "",
    units: Units.SquareMeters,
    category: FieldCategory.Size,
    layouts: ["mechanical"],
    defaultEnabled: false,
  });
  result.push({
    property: "windowAreaM2",
    title: "Window Area",
    short: "",
    units: Units.SquareMeters,
    category: FieldCategory.Size,
    layouts: ["mechanical"],
    defaultEnabled: false,
  });

  for (const type of HEATLOAD_COMPONENT_TYPE_SET) {
    if (
      type === HeatLoadComponentType.WINDOW ||
      type === HeatLoadComponentType.EXTERNAL_WALL ||
      type === HeatLoadComponentType.ROOF
    ) {
      result.push({
        property: `roofHeatLoad.${type}.heatLossThroughComponentWatt`,
        title: `Heat Loss Through ${type}`,
        short: "",
        units: Units.Watts,
        category: FieldCategory.HeatLoss,
        layouts: ["mechanical"],
        defaultEnabled: false,
        color: HEATLOSS_RESULT_HEX,
        unitContext: UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
      });
    }
    continue;
  }
  for (const type of HEATLOAD_COMPONENT_TYPE_SET) {
    if (
      type === HeatLoadComponentType.WINDOW ||
      type === HeatLoadComponentType.EXTERNAL_WALL ||
      type === HeatLoadComponentType.ROOF
    ) {
      result.push({
        property: `roofHeatLoad.${type}.heatGainThroughComponentWatt`,
        title: `Heat Gain Through ${type}`,
        short: "",
        units: Units.Watts,
        category: FieldCategory.HeatLoss,
        layouts: ["mechanical"],
        defaultEnabled: false,
        color: HEATGAIN_RESULT_HEX,
        unitContext: UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
      });
    }
    continue;
  }

  return result;
}

export function makeRoomCalculationFields(
  context: CoreContext,
  entity: RoomEntity,
): CalculationField[] {
  switch (entity.room.roomType) {
    case RoomType.ROOM:
      return makeRoomConcreteCalculationFields();
    case RoomType.ROOF:
      return makeRoofConcreteCalculationFields(context, entity);
    default:
      assertUnreachable(entity.room);
  }
  return [];
}

export function emptyRoomLiveCalculation(): RoomLiveCalculation {
  return {
    connected: null,
    warnings: [],
    totalHeatGainWatt: null,
    totalHeatLossWatt: null,
    totalHeatLossAddressedWATT: null,
    totalHeatGainAddressedWATT: null,
    ventAirChangeRatePerHour: null,
    heatingAirChangeRatePerHour: null,
    ventilationFlowRateLS: null,
    heatingFlowRateLS: null,
    isLeaderRoomPerFloor: null,
    isZoneLeader: null,
    zoneVentFlowRateLS: null,
    zoneExtractVentFlowRateAddressedLS: null,
    zoneSupplyVentFlowRateAddressedLS: null,
    underfloorHeating: {
      roomName: null,
      designTemperatureC: null,
      returnTempC: null,
      deltaT: null,
      unheatedAreaM2: 0,
      excludedAreaM2: 0,
      floorTempC: null,
      loopSpacingMM: null,
      heatOutputW: null,
      outputWattPerM2: null,
      totalPipeLengthM: null,
      pipeSizeMM: null,
      manifoldUid: null,
      numLoops: null,
      loopMode: null,
      meanWaterTempC: null,
      floorFinish: null,
      loopsStats: [],
      loopDirectionDEG: null,
      coils: [],
    },
    floorHeadLoadInfo: null,
    buildingHeatLoadInfo: null,
    reference: null,
    volumeM3: null,
    floorType: null,
  };
}

export function emptyRoomCalculation(): RoomCalculation {
  return {
    warnings: [],
    costBreakdown: null,
    expandedEntities: null,
    type: CalculationType.RoomCalculation,
    cost: null,
    volumeM3: null,
    areaM2: null,
    perimeterM: null,
    roofAreaM2: null,
    externalWallAreaM2: null,
    windowAreaM2: 0,
    roofComponents: [],
    totalHeatLossWatt: null,
    totalHeatGainWatt: null,
    totalHeatLossAddressedWATT: null,
    totalHeatGainAddressedWATT: null,
    ventAirChangeRatePerHour: null,
    heatingAirChangeRatePerHour: null,
    ventilationFlowRateLS: null,
    heatingFlowRateLS: null,
    thermalBridgingCoefficient: null,
    floorHeadLoadInfo: null,
    buildingHeatLoadInfo: null,
    isLeaderRoomPerFloor: null,
    isZoneLeader: null,
    zoneVentFlowRateLS: null,
    zoneExtractVentFlowRateAddressedLS: null,
    zoneSupplyVentFlowRateAddressedLS: null,
    heatLoadResult: {
      [HeatLoadComponentType.EXTERNAL_DOOR]: {
        headLoadType: HeatLoadComponentType.EXTERNAL_DOOR,
        heatLossThroughComponentWatt: 0,
        heatGainThroughComponentWatt: 0,
        areaM2: 0,
      },
      [HeatLoadComponentType.INTERNAL_DOOR]: {
        headLoadType: HeatLoadComponentType.INTERNAL_DOOR,
        heatLossThroughComponentWatt: 0,
        heatGainThroughComponentWatt: 0,
        areaM2: 0,
      },
      [HeatLoadComponentType.EXTERNAL_WALL]: {
        headLoadType: HeatLoadComponentType.EXTERNAL_WALL,
        heatLossThroughComponentWatt: 0,
        heatGainThroughComponentWatt: 0,
        areaM2: 0,
      },
      [HeatLoadComponentType.INTERNAL_WALL]: {
        headLoadType: HeatLoadComponentType.INTERNAL_WALL,
        heatLossThroughComponentWatt: 0,
        heatGainThroughComponentWatt: 0,
        areaM2: 0,
      },
      [HeatLoadComponentType.PARTY_WALL]: {
        headLoadType: HeatLoadComponentType.PARTY_WALL,
        heatLossThroughComponentWatt: 0,
        heatGainThroughComponentWatt: 0,
        areaM2: 0,
      },
      [HeatLoadComponentType.FLOOR]: {
        headLoadType: HeatLoadComponentType.FLOOR,
        heatLossThroughComponentWatt: 0,
        heatGainThroughComponentWatt: 0,
        areaM2: 0,
      },
      [HeatLoadComponentType.CEILING]: {
        headLoadType: HeatLoadComponentType.CEILING,
        heatLossThroughComponentWatt: 0,
        heatGainThroughComponentWatt: 0,
        areaM2: 0,
      },
      [HeatLoadComponentType.ROOF]: {
        headLoadType: HeatLoadComponentType.ROOF,
        heatLossThroughComponentWatt: 0,
        heatGainThroughComponentWatt: 0,
        areaM2: 0,
      },
      [HeatLoadComponentType.WINDOW]: {
        headLoadType: HeatLoadComponentType.WINDOW,
        heatLossThroughComponentWatt: 0,
        heatGainThroughComponentWatt: 0,
        areaM2: 0,
      },
      [HeatLoadComponentType.VENTILATION]: {
        headLoadType: HeatLoadComponentType.VENTILATION,
        heatLossThroughComponentWatt: 0,
        heatGainThroughComponentWatt: 0,
      },
      [HeatLoadComponentType.THERMAL_BRIDGING]: {
        headLoadType: HeatLoadComponentType.THERMAL_BRIDGING,
        heatLossThroughComponentWatt: 0,
        heatGainThroughComponentWatt: 0,
      },
      [HeatLoadComponentType.INTERNAL_SOURCES]: {
        headLoadType: HeatLoadComponentType.INTERNAL_SOURCES,
        heatLossThroughComponentWatt: 0,
        heatGainThroughComponentWatt: 0,
      },
      [HeatLoadComponentType.SOLAR_GAIN]: {
        headLoadType: HeatLoadComponentType.SOLAR_GAIN,
        heatLossThroughComponentWatt: 0,
        heatGainThroughComponentWatt: 0,
      },
      [HeatLoadComponentType.SPARE_LOSS]: {
        headLoadType: HeatLoadComponentType.SPARE_LOSS,
        heatLossThroughComponentWatt: 0,
        heatGainThroughComponentWatt: 0,
      },
      [HeatLoadComponentType.SPARE_GAIN]: {
        headLoadType: HeatLoadComponentType.SPARE_GAIN,
        heatLossThroughComponentWatt: 0,
        heatGainThroughComponentWatt: 0,
      },
    },
    materials: {
      "External Wall": [],
      "Internal Wall": [],
      "Party Wall": [],
      Window: [],
      "External Door": [],
      "Internal Door": [],
      Roof: [],
      "Bottom Floor": [],
      "Suspended Floor": [],
      "Party Floor": [],
    },
    floorType: null,
    roomsBelow: {},
    roomsAbove: {},
    roomsAdjacent: {},
    underfloorHeating: {
      roomName: null,
      designTemperatureC: null,
      returnTempC: null,
      deltaT: null,
      unheatedAreaM2: 0,
      excludedAreaM2: 0,
      floorTempC: null,
      loopSpacingMM: null,
      heatOutputW: null,
      outputWattPerM2: null,
      totalPipeLengthM: null,
      numLoops: null,
      manifoldUid: null,
      pipeSizeMM: null,
      loopMode: null,
      meanWaterTempC: null,
      floorFinish: null,
      loopsStats: [],
      loopDirectionDEG: null,
      coils: [],
    },
    heatEmittersStats: {},
    roofHeatLoad: {
      [HeatLoadComponentType.EXTERNAL_WALL]: {
        headLoadType: HeatLoadComponentType.EXTERNAL_WALL,
        heatLossThroughComponentWatt: 0,
        heatGainThroughComponentWatt: 0,
        areaM2: 0,
      },
      [HeatLoadComponentType.ROOF]: {
        headLoadType: HeatLoadComponentType.ROOF,
        heatLossThroughComponentWatt: 0,
        heatGainThroughComponentWatt: 0,
        areaM2: 0,
      },
      [HeatLoadComponentType.WINDOW]: {
        headLoadType: HeatLoadComponentType.WINDOW,
        heatLossThroughComponentWatt: 0,
        heatGainThroughComponentWatt: 0,
        areaM2: 0,
      },
    },
  };
}
