// This file principally contains utils relating to splitting text strings in the same way that HTML textareas do.
// This is needed for consistency between canvas representations of text annotations and the editable HTML textareas

export const extractWordAt = (s: string, i: number) => {
  const preIndex = (() => {
    // eslint-disable-next-line no-constant-condition
    for (let j = i - 1; true; j--) {
      if (j <= 0) return 0;
      const char = s[j];
      if (char == " ") return j + 1;
    }
  })();
  const postIndex = (() => {
    // eslint-disable-next-line no-constant-condition
    for (let j = i; true; j++) {
      if (j >= s.length) return s.length;
      const char = s[j];
      if (char == " ") return j;
    }
  })();

  // for the phrase "hello xi" and the index "7" this should yield "xi", "6" and "8" respectively
  return { word: s.slice(preIndex, postIndex), pre: preIndex, post: postIndex };
};

/** are there any spaces in the string before the specified index */
export const anySpacesBefore = (s: string, i: number) => {
  for (let j = i - 1; j >= 0; j--) {
    const char = s[j];
    if (char == " ") return true;
  }
  return false;
};

export const getTextWrappedLines = (
  measureTextWidth: (text: string) => number,
  text: string,
  maxWidth: number,
): string[] => {
  const recurse = (
    /** return accumulator */
    lines: string[],
    /** the current working string. this becomes smaller as we proceed through characters */
    buff: string,
    /** relative to 'buff' the index of the current character we are investigating */
    currentIndex: number,
  ): string[] => {
    // check if we've hit the end of the text
    if (currentIndex === buff.length) {
      if (buff === "" && !lines.length) return [""];
      if (buff === "") return lines;
      return [...lines, buff];
    }

    // check if we've hit newline
    const currentCharacter = buff[currentIndex];
    if (currentCharacter === "\n") {
      const currentChars = buff.slice(0, currentIndex);
      const newLines = [...lines, currentChars];
      const newChars = buff.slice(currentIndex + 1, buff.length);
      return recurse(newLines, newChars, 0);
    }

    const proposed = buff.slice(0, currentIndex + 1);
    const width = measureTextWidth(proposed);

    // check if we're still under width, if so, continue
    if (width <= maxWidth) {
      return recurse(lines, buff, currentIndex + 1);
    }

    // we have exceeded max width, need to take action!
    const haveSplitOnSpace = buff[currentIndex] == " ";
    if (haveSplitOnSpace) {
      const currentChars = buff.slice(0, currentIndex);
      const newLines = [...lines, currentChars];
      const newChars = buff.slice(currentIndex, buff.length).trim();
      return recurse(newLines, newChars, 0);
    }

    // we split in the middle of a word
    const { word, pre } = extractWordAt(buff, currentIndex);
    const wordLength = measureTextWidth(word);

    // special case. if a word is not even possible to fit on one line the logic changes
    if (wordLength > maxWidth) {
      const isNewBigWord = anySpacesBefore(buff, pre); // this big word has started AFTER some other word. move the start of the big word down a line
      if (isNewBigWord) {
        // we start our algo again but from the beginning of the big word
        const currentChars = buff.slice(0, pre).trim();
        const newLines = [...lines, currentChars];
        const newChars = buff.slice(pre, buff.length);
        return recurse(newLines, newChars, 0);
      }
      // we are in the process of splitting the big word. split characters individually
      const currentChars = buff.slice(0, currentIndex || 1).trim();
      const newLines = [...lines, currentChars];
      const newChars = buff.slice(currentIndex || 1, buff.length);
      return recurse(newLines, newChars, 0);
    }

    // default case. trigger a split at the start of the word and move to next line
    const currentChars = buff.slice(0, pre).trim();
    const newLines = [...lines, currentChars];
    const newChars = buff.slice(pre, buff.length);
    return recurse(newLines, newChars, 0);
  };

  return recurse([], text, 0);
};
