import { CoreContext } from "../../calculations/types";
import { EdgeEntity } from "../entities/edge-entity";
import { CalculationField } from "./calculation-field";
import {
  CalcFieldSelector,
  Calculation,
  CalculationType,
  LiveCalculation,
} from "./types";

export default interface EdgeCalculation extends Calculation {
  type: CalculationType.EdgeCalculation;
}

export interface EdgeLiveCalculation extends LiveCalculation {}

export const FastLiveEdgeCalculationFields: CalcFieldSelector<EdgeLiveCalculation> =
  {
    connected: true,
  };

export const StickyEdgeCalculationFields: CalcFieldSelector<EdgeCalculation> =
  {};

export function makeEdgeCalculationFields(
  context: CoreContext,
  entity: EdgeEntity,
): CalculationField[] {
  return [];
}

export function emptyEdgeLiveCalculation(): EdgeLiveCalculation {
  return {
    connected: null,
    warnings: [],
  };
}

export function emptyEdgeCalculation(): EdgeCalculation {
  return {
    warnings: [],
    costBreakdown: null,
    expandedEntities: null,
    type: CalculationType.EdgeCalculation,
    cost: null,
  };
}
