import Flatten from "@flatten-js/core";
import uuid from "uuid";
import { StandardFlowSystemUids } from "../../../../../common/src/api/config";
import { FlowConfiguration } from "../../../../../common/src/api/document/entities/big-valve/big-valve-entity";
import {
  ConduitConnectableEntityConcrete,
  ConnectableEntityConcrete,
  DrawableEntityConcrete,
  isConduitConnectableEntity,
} from "../../../../../common/src/api/document/entities/concrete-entity";
import ConduitEntity from "../../../../../common/src/api/document/entities/conduit-entity";
import { FittingEntity } from "../../../../../common/src/api/document/entities/fitting-entity";
import { DrawableEntity } from "../../../../../common/src/api/document/entities/simple-entities";
import { EntityType } from "../../../../../common/src/api/document/entities/types";
import {
  HORIZONTAL_SYSTEM_NETWORKS_BY_PRIORITY,
  NetworkType,
} from "../../../../../common/src/api/document/flow-systems";
import { Coord } from "../../../../../common/src/lib/coord";
import { addValveAndSplitPipe } from "../../../../src/htmlcanvas/lib/black-magic/split-pipe";
import CanvasContext from "../../../../src/htmlcanvas/lib/canvas-context";
import { InteractionType } from "../../../../src/htmlcanvas/lib/interaction";
import DrawableBigValve from "../../objects/drawableBigValve";
import DrawableConduit from "../../objects/drawableConduit";
import DrawableSystemNode from "../../objects/drawableSystemNode";
import { makeConduitEntity } from "./utils";

export default function connectBigValveToSource(
  context: CanvasContext,
  newBigValve: DrawableBigValve,
  radiusMM: number = 3000,
  heightPipesM?: number,
) {
  const wc = newBigValve.toWorldCoord();
  const selfUids: string[] = newBigValve.getInletsOutlets().map((o) => o.uid);

  const interactive = getClosestJoinable({
    context,
    systemUid: StandardFlowSystemUids.ColdWater,
    wc,
    radius: radiusMM,
    excludeUids: selfUids,
  });

  let coldDrawn = false;

  const coldObj = context.globalStore.get(
    newBigValve.entity.coldRoughInUid,
  ) as DrawableSystemNode;
  const hotObj = context.globalStore.get(
    newBigValve.entity.hotRoughInUid,
  ) as DrawableSystemNode;

  if (
    interactive &&
    context.globalStore.getConnections(coldObj.uid).length === 0
  ) {
    coldDrawn = true;
    const target = interactive[0];
    const targetObj = context.globalStore.get(target.uid)!;
    // rotate our pipe and try again with correct position of cold water

    const closePoint = targetObj.shape!.distanceTo(Flatten.point(wc.x, wc.y))[1]
      .ps;
    const currA = newBigValve.toWorldAngleDeg(0);
    let desiredA =
      (-Flatten.vector(Flatten.point(wc.x, wc.y), closePoint).angleTo(
        Flatten.vector(0, -1),
      ) /
        Math.PI) *
      180;

    // round angle to 45 deg
    desiredA = Math.round(desiredA / 45) * 45;
    newBigValve.entity.rotation = (((desiredA - currA) % 360) + 360) % 360;
    const coldLoc = coldObj.toWorldCoord({ x: 0, y: 0 });

    leadPipe({
      context,
      wc: coldLoc,
      connectTo: coldObj.entity,
      systemUid: StandardFlowSystemUids.ColdWater,
      pipeSpec: target.uid,
      excludeUids: selfUids,
      heightAboveFloorM: heightPipesM,
    });
  }

  // do closest hot pipe
  if (coldDrawn) {
    const interactiveC = getClosestJoinable({
      context,
      systemUid: StandardFlowSystemUids.HotWater,
      wc,
      radius: radiusMM,
      excludeUids: selfUids,
    });
    if (
      interactiveC &&
      interactiveC.length &&
      context.globalStore.getConnections(hotObj.uid).length === 0
    ) {
      const hotWc = hotObj.toWorldCoord({ x: 0, y: 0 });

      leadPipe({
        context,
        wc: hotWc,
        connectTo: hotObj.entity,
        systemUid: StandardFlowSystemUids.HotWater,
        excludeUids: selfUids,
        heightAboveFloorM: heightPipesM,
      });
    }
  }
}

export function leadPipe(props: {
  context: CanvasContext;
  wc: Coord;
  connectTo?: ConnectableEntityConcrete;
  systemUid: string;
  pipeSpec?: string;
  radius?: number;
  heightAboveFloorM?: number;
  excludeUids?: string[];
  network?: NetworkType;
}): { pipe: ConduitEntity; connection: ConnectableEntityConcrete } | null {
  const {
    context,
    wc,
    systemUid,
    pipeSpec,
    radius,
    excludeUids,
    heightAboveFloorM,
  } = props;
  let { connectTo, network } = props;

  let pipe: DrawableConduit;
  let valve: ConduitConnectableEntityConcrete;
  if (pipeSpec) {
    const obj = context.globalStore.get(pipeSpec)!;
    if (obj.entity.type === EntityType.CONDUIT) {
      valve = addValveAndSplitPipe(
        context,
        obj as DrawableConduit,
        wc,
        systemUid,
        30,
      ).focus as FittingEntity;
    } else if (isConduitConnectableEntity(obj.entity)) {
      valve = obj.entity as ConduitConnectableEntityConcrete;
    } else {
      throw new Error("not supported to lead from");
    }
  } else {
    const interactive = getClosestJoinable({
      context,
      systemUid,
      wc,
      radius: radius || Infinity,
      excludeUids,
    });
    if (interactive) {
      if (interactive[0].type === EntityType.CONDUIT) {
        const pipeE = interactive[0];
        pipe = context.globalStore.get(pipeE.uid) as DrawableConduit;
        valve = addValveAndSplitPipe(context, pipe, wc, systemUid, 30)
          .focus as FittingEntity;
      } else if (isConduitConnectableEntity(interactive[0])) {
        valve = interactive[0];
      } else {
        throw new Error("not supported");
      }
    } else {
      return null;
    }
  }

  const system = context.drawing.metadata.flowSystems[systemUid];
  network =
    props.network || HORIZONTAL_SYSTEM_NETWORKS_BY_PRIORITY[system.type].at(-1);

  const newEntities = [];
  let connection: ConnectableEntityConcrete;

  if (!connectTo) {
    const newCapFitting: FittingEntity = {
      uid: uuid.v4(),
      type: EntityType.FITTING,
      center: wc,
      systemUid: systemUid,
      color: null,
      calculationHeightM: null,
      parentUid: null,
      entityName: null,
      fittingType: "pipe",
      fitting: {},
    };
    connectTo = newCapFitting;
    newEntities.push(newCapFitting);
    connection = newCapFitting;
  } else {
    connection = connectTo;
  }

  const newPipe = makeConduitEntity({
    fields: {
      endpointUid: [valve.uid, connectTo.uid],
      heightAboveFloorM: heightAboveFloorM == null ? 1 : heightAboveFloorM,
      systemUid,
      conduitType: "pipe",
      conduit: {
        network,
      },
    },
    objectStore: context.globalStore,
    extendedFrom: valve,
  });
  newEntities.push(newPipe);

  for (const newEntity of newEntities) {
    context.$store.dispatch("document/addEntity", newEntity);
  }
  return { pipe: newPipe, connection };
}

export function getClosestJoinable(props: {
  context: CanvasContext;
  systemUid?: string | null;
  wc: Coord;
  radius: number;
  excludeUids?: string[];
  filter?: (e: DrawableEntityConcrete) => boolean;
}): Array<ConduitEntity | ConnectableEntityConcrete> | null {
  const { context, systemUid, wc, radius, excludeUids, filter } = props;
  return context.offerInteraction(
    {
      type: InteractionType.EXTEND_NETWORK,
      systemUid: systemUid || null,
      worldCoord: wc,
      worldRadius: radius, // 1 M radius
      configuration: FlowConfiguration.OUTPUT,
    },
    (obj) => {
      if (excludeUids) {
        if (excludeUids.includes(obj[0].uid)) {
          return false;
        }
      }
      if (filter) {
        if (!filter(obj[0])) {
          return false;
        }
      }
      return true;
    },
    (object: DrawableEntity[]) => {
      const obj = context.globalStore.get(object[0].uid);
      if (!obj) {
        return -Infinity;
      }
      if (!obj.shape) {
        return -Infinity;
      }

      return -obj!.shape!.distanceTo(Flatten.point(wc.x, wc.y))[0];
    },
  ) as Array<ConduitEntity | ConnectableEntityConcrete>;
}
