import axios from "axios";
import { LoadCatalogAPIResult } from "../../../common/src/api/catalog/types";
import { APIResult } from "../../../common/src/api/document/types";

export async function loadCatalog(
  params: { documentId: number } | { shareToken: string },
): Promise<APIResult<LoadCatalogAPIResult>> {
  try {
    return (await axios.get("/api/catalog/", { params })).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}
