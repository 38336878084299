export const FIELD_HEIGHT = 15;
export const WARNING_HINT_WIDTH = 200;
export const FIELD_FONT_SIZE = 15;
export const SCALE_GRADIENT_MIN = 0.05;

export const WARNING_WIDTH = 24;
export const WARNING_HEIGHT = 21;
export const MIN_SCALE = 0.7;

export const RECT_ROUND = 2;
export const RECT_PADDING = 5;
export const EYE_ICON_SIZE = 16;
