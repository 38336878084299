import stringify from "json-stable-stringify";
import {
  CORE_FEATURE_FLAG_DEFAULTS,
  CoreFeatureFlags,
} from "../../lib/feature-flags";
import { GlobalStore } from "../../lib/globalstore/global-store";
import { ObjectStore } from "../../lib/globalstore/object-store";
import {
  Precision,
  Units,
  convertMeasurementSystem,
} from "../../lib/measurements";
import {
  assertType,
  assertUnreachable,
  cloneSimple,
  getNext,
  getPropertyByString,
  interpolateTable,
  lowerBoundTable,
  numToPercent,
  parseCatalogNumberExact,
  parseCatalogNumberOrMax,
  parseCatalogNumberOrMin,
  upperBoundTable,
} from "../../lib/utils";
import { NodeProps } from "../../models/CustomEntity";
import {
  FeatureAccess,
  defaultFeatureAccess,
} from "../../models/FeatureAccess";
import { getManufacturerRecord } from "../catalog/manufacturers/utils";
import { PriceTable } from "../catalog/price-table";
import {
  Catalog,
  HotWaterPlantFlowRateTemperature,
  HotWaterPlantSizePropsContinuousFlow,
  HotWaterPlantSizePropsElectric,
  HotWaterPlantSizePropsHeatPump,
  HotWaterPlantSizePropsTankPak,
  PipeSpec,
} from "../catalog/types";
import {
  StandardFlowSystemUids,
  SupportedPsdStandards,
  isClosedSystem,
  isDrainage,
  isGas,
  isGermanStandard,
  isMechanical,
  isPressure,
  isSewer,
  isStormwater,
  isVentilation,
  shouldCalculateIsolatedRunDeadleg,
  shouldCalculateStagnantFlowDeadleg,
} from "../config";
import {
  CoreCalculatedObjectConcrete,
  CoreConnectableObjectConcrete,
  CoreEdgeObjectConcrete,
  CoreObjectConcrete,
  isCoreCalculatedObject,
} from "../coreObjects";
import CoreBigValve from "../coreObjects/coreBigValve";
import CoreConduit from "../coreObjects/coreConduit";
import CoreDirectedValve from "../coreObjects/coreDirectedValve";
import CoreFixture from "../coreObjects/coreFixture";
import CoreFlowSource from "../coreObjects/coreFlowSource";
import CoreGasAppliance from "../coreObjects/coreGasAppliance";
import CoreLoadNode from "../coreObjects/coreLoadNode";
import CorePlant from "../coreObjects/corePlant";
import CoreSystemNode from "../coreObjects/coreSystemNode";
import { SelectionTarget } from "../coreObjects/lib/types";
import {
  determineConnectableSystemUid,
  getPlantPressureLoss,
} from "../coreObjects/utils";
import { isCalculated } from "../document/calculations-objects";
import {
  DuctCalculation,
  NoFlowAvailableReason,
  PipeCalculation,
  isPipeReturn,
} from "../document/calculations-objects/conduit-calculations";
import DirectedValveCalculation from "../document/calculations-objects/directed-valve-calculation";
import { NameCalculation } from "../document/calculations-objects/types";
import { getFlowSystemLayouts } from "../document/calculations-objects/utils";
import { addWarning } from "../document/calculations-objects/warnings";
import CoreObjectFactory from "../document/coreObjectFactory";
import {
  DrawingState,
  SelectedMaterialManufacturer,
  isPressureSizingEnabled,
  isVelocitySizingEnabled,
} from "../document/drawing";
import { BigValveType } from "../document/entities/big-valve/big-valve-entity";
import {
  DrawableEntityConcrete,
  isConnectableEntity,
} from "../document/entities/concrete-entity";
import ConduitEntity, {
  PipeConduitEntity,
  fillDefaultConduitFields,
  isDuctEntity,
  isPipeEntity,
} from "../document/entities/conduit-entity";
import DirectedValveEntity from "../document/entities/directed-valves/directed-valve-entity";
import {
  ValveType,
  hasFixedPressureDrop,
  isReturnBalancingValve,
} from "../document/entities/directed-valves/valve-types";
import FixtureEntity, {
  fillFixtureFields,
  getCatalogFixtureDrainageUnits,
  getFixtureCalculationUnits,
  getFixtureContinuousFlow,
} from "../document/entities/fixtures/fixture-entity";
import { fillGasApplianceFields } from "../document/entities/gas-appliance";
import LoadNodeEntity, {
  DwellingNode,
  FireNode,
  LoadNode,
  MAX_DWELLINGS,
  NodeType,
  VentilationNode,
  fillDefaultLoadNodeFields,
  getLoadNodeCalculationUnits,
  getLoadNodeDrainageUnits,
  getLoadNodeGasDiversity,
} from "../document/entities/load-node-entity";
import { fillPlantDefaults } from "../document/entities/plants/plant-defaults";
import PlantEntity, {
  plantHasSingleRating,
} from "../document/entities/plants/plant-entity";
import {
  DrainageGreaseInterceptorTrap,
  PlantType,
  ReturnSystemPlant,
  RheemVariant,
  VolumiserPlant,
} from "../document/entities/plants/plant-types";
import {
  isDualSystemNodePlant,
  isHotWaterRheem,
  isMultiOutlets,
  isPlantPump,
  isPlantTank,
} from "../document/entities/plants/utils";
import {
  ChoiceField,
  FieldType,
  PropertyField,
} from "../document/entities/property-field";
import { NamedEntity } from "../document/entities/simple-entities";
import { SystemNodeEntity } from "../document/entities/system-node-entity";
import { EntityType } from "../document/entities/types";
import { flowSystemNetworkHasSpareCapacity } from "../document/flow-systems";
import { getFlowSystem, makeInertEntityFields } from "../document/utils";
import { SupportedLocales } from "../locale";
import { CalculationHelper } from "./calculation-helper";
import {
  determineConnectivity,
  determineCycles,
  makeConnectivityWarnings,
} from "./cosmetic/connectivity";
import { fillStormwaterPipeCalcResult } from "./cosmetic/stormwater";
import { DrainageCalculations } from "./drainage";
import { EnergyGraph } from "./energy-graph";
import {
  applyPressureLoss,
  getObjectFrictionPressureLossKPA,
} from "./entity-pressure-drops";
import { FilterCalculations } from "./filters/filter-calculations";
import { FireCalculations } from "./fire-calculation";
import {
  ExecutionStep,
  TESTING_BREAKPOINT_ERROR,
  TraceCalculation,
} from "./flight-data-recorder";
import { FlowGraph } from "./flow-graph";
import { GasCalculations } from "./gas";
import { GlobalFDR } from "./global-fdr";
import Graph, { Edge, VISIT_RESULT_WRONG_WAY } from "./graph";
import { HeatLoadCalculations } from "./heatloss/heat-loss";
import {
  PressureDropCalculations,
  getFluidDensityOfSystem,
  head2kpa,
} from "./pressure-drops";
import { PumpCalculations } from "./pump-calculations";
import { addArchitectureReference, addHydraulicReferences } from "./references";
import {
  MINIMUM_BALANCING_VALVE_PRESSURE_DROP_KPA,
  ReturnCalculations,
} from "./returns";
import { RingMainCalculator } from "./ring-main-calculator";
import { TankCalculations } from "./tank";
import {
  CoreContext,
  EdgeType,
  EnergyEdge,
  EnergyEdgeType,
  EnergyNode,
  FlowEdge,
  FlowNode,
  PipeConfiguration,
  PressureLossError,
  PressureLossResult,
  PressurePushMode,
  SkippableCalcs,
  isEdgeTypeConnectable,
} from "./types";
import { UnderfloorHeatingCalcs } from "./underfloor-heating/underfloor-heating";
import {
  CALCULATION_PRESSURE_MAX_KPA,
  ContextualPCE,
  FLOW_SOURCE_EDGE,
  FLOW_SOURCE_ROOT,
  FLOW_SOURCE_ROOT_BRIDGE,
  FLOW_SOURCE_ROOT_NODE,
  FinalPsdCountEntry,
  IndexPath,
  PsdProfile,
  addCosts,
  addPsdCounts,
  compareWaterPsdCounts,
  countPsdProfile,
  findFireSubGroup,
  fireNodeKey,
  flowSourceKPA,
  flowSystemsFlowTogether,
  getDuctMaterialName,
  getHCAAFixtureTypeByName,
  getLevelHeightFloorToVertexM,
  getPipeMaterialName,
  insertPsdProfile,
  isFlowSource,
  isZeroWaterPsdCounts,
  lookupFlowRate,
  makeEmptyCalculationWithStickyFields,
  subtractPsdProfiles,
  trackBackPath,
  zeroContextualPCE,
  zeroCost,
  zeroFinalPsdCounts,
  zeroPsdCounts,
} from "./utils";
import {
  DamperCalculations,
  PRESSURE_DIFF_REQ_FOR_DAMPER,
} from "./ventilation/dampers";
import { DuctCalculations } from "./ventilation/ducts";
import { VentCalculations, VentRecord } from "./ventilation/ventilation";

export interface CalculatePointPressureOptions {
  ignoreCalculatedDefaults?: boolean;
  allowApproximate?: boolean;
  nodePressureKPA?: Map<string, number | null>;
  edge2PressureLoss?: Map<string, PressureLossResult>;
  edgeFlowFrom?: Map<string, FlowNode>;
}

export interface CalculationProgress {
  progress: number;
  max: number;
  message: string;
}

export const FireCalculationMsg = "Solving fire system";

export default class CalculationEngine implements CoreContext {
  globalStore!: GlobalStore;
  networkObjectUids!: string[];
  drawableObjectUids!: string[];

  catalog!: Catalog;
  drawing!: DrawingState;
  ga!: number;
  nodes!: NodeProps[];
  locale!: SupportedLocales;
  skip: SkippableCalcs;

  flowGraph!: Graph<FlowNode, FlowEdge>;
  energyGraph!: Graph<EnergyNode, EnergyEdge>;
  entityMaxPressuresKPA = new Map<string, number | null>();
  nodePressureKPA = new Map<string, number | null>();
  isNodePressureValid = new Map<string, boolean>();
  isEdgePressureLossValid = new Map<string, boolean>();
  entityStaticPressureKPA = new Map<string, number | null>();
  nodeStaticPressureKPA = new Map<string, number | null>();
  allBridges = new Map<string, Edge<FlowNode, FlowEdge>>();
  pumpIndexNodeCache: Map<FlowNode, IndexPath[]> = new Map<
    FlowNode,
    IndexPath[]
  >();
  sourceIndexNodeCache: Map<FlowNode, IndexPath[]> = new Map<
    FlowNode,
    IndexPath[]
  >();
  psdAfterBridgeCache = new Map<string, PsdProfile>();
  parentBridgeOfWetEdge = new Map<
    string,
    Edge<FlowNode | undefined, FlowEdge | undefined>
  >();
  globalReachedPsdUs = new PsdProfile();
  firstWet = new Map<string, FlowNode>();
  secondWet = new Map<string, FlowNode>();

  psdProfileWithinGroup = new Map<string, PsdProfile>();
  childBridges = new Map<string, Array<Edge<FlowNode, FlowEdge>>>();
  ventRecords = new Map<string, VentRecord>();
  ventLoadNodeToVentRecord = new Map<string, string>();

  priceTable: PriceTable;
  onProgress?: (progress: CalculationProgress) => void;
  onValidationErrors?: (errors: SelectionTarget[]) => void;
  nextLiveWarningInstanceId = 0;

  needsRecalculation = false;
  featureAccess: FeatureAccess = defaultFeatureAccess;
  featureFlags: CoreFeatureFlags = CORE_FEATURE_FLAG_DEFAULTS;

  networkObjects(): CoreCalculatedObjectConcrete[] {
    for (const uid of this.networkObjectUids) {
      if (!this.globalStore.has(uid)) {
        console.error(`Missing network object ${uid}`);
      }
    }
    return this.networkObjectUids.map((u) => this.globalStore.get(u)!);
  }

  drawableObjects(): CoreObjectConcrete[] {
    return this.drawableObjectUids.map((u) => this.globalStore.get(u)!);
  }

  isFeatureFlagEnabled(flag: keyof CoreFeatureFlags): boolean {
    return this.featureFlags[flag] ?? false;
  }

  resetBetweenIterations() {
    this.entityMaxPressuresKPA = new Map<string, number | null>();
    this.nodePressureKPA = new Map<string, number | null>();
    this.isNodePressureValid = new Map<string, boolean>();
    this.isEdgePressureLossValid = new Map<string, boolean>();
    this.entityStaticPressureKPA = new Map<string, number | null>();
    this.nodeStaticPressureKPA = new Map<string, number | null>();
    this.allBridges = new Map<string, Edge<FlowNode, FlowEdge>>();
    this.psdAfterBridgeCache = new Map<string, PsdProfile>();
    this.bridgeChildren = new Map();
    this.bridgeSelfProfile = new Map();
    this.bridgesInTopsortOrder = [];
    this.parentBridgeOfWetEdge = new Map<
      string,
      Edge<FlowNode | undefined, FlowEdge | undefined>
    >();
    this.globalReachedPsdUs = new PsdProfile();
    this.firstWet = new Map<string, FlowNode>();
    this.secondWet = new Map<string, FlowNode>();
    this.ventRecords = new Map<string, VentRecord>();
    this.ventLoadNodeToVentRecord = new Map<string, string>();

    this.softClearCalculations();
    // this.clearCalculations();
  }

  async calculate(
    context: CoreContext,
    skip: SkippableCalcs,
    done: (success: boolean, logs?: ExecutionStep[], errorMsg?: string) => void,
    onProgress?: (progress: CalculationProgress) => void,
    onValidationErrors?: (errors: SelectionTarget[]) => void,
  ) {
    try {
      GlobalFDR.clearLog();
      this.globalStore = context.globalStore;
      this.globalStore.nextWarningInstanceId = 0;
      this.priceTable = context.priceTable;
      this.locale = context.locale;
      this.catalog = context.catalog;
      this.drawing = context.drawing;
      this.nodes = context.nodes;
      this.skip = skip;
      this.featureAccess = context.featureAccess;
      this.featureFlags = context.featureFlags;

      this.ga =
        this.drawing.metadata.calculationParams.gravitationalAcceleration;

      this.onProgress = onProgress;
      this.onValidationErrors = onValidationErrors;

      const success = this.preValidate();

      if (!success) {
        done(false);
        return;
      }

      this.clearCalculations();
      this.needsRecalculation = true;
      let iters = 1;
      let exception: any = undefined;
      try {
        while (this.needsRecalculation) {
          this.needsRecalculation = false;

          await this.doRealCalculation(iters++);
          if (iters > 3) {
            console.error("Calculations didn't converge after 3 iterations");
            break;
          }
        }
      } catch (e) {
        if (typeof e === "string") {
          GlobalFDR.errorMsg = e;
        } else if (e instanceof Error) {
          GlobalFDR.errorMsg = e.message;
        } else if (e) {
          GlobalFDR.errorMsg = (e as any).toString();
        } else {
          GlobalFDR.errorMsg = "Unknown error";
        }

        exception = e || "Unknown error";
      } finally {
        if (GlobalFDR.errorMsg !== TESTING_BREAKPOINT_ERROR) {
          // Only clean up if we weren't stopped by a test breakpoint for further inspection
          try {
            this.removeNetworkObjects();
            this.networkObjectUids = undefined!;
            this.drawableObjectUids = undefined!;
          } catch (e) {
            console.error("Error removing network objects", e);
          }
        }
      }

      if (exception) {
        done(false, GlobalFDR.fdrLog);
      } else {
        done(true);
      }
    } catch (e) {
      if (typeof e === "string") {
        GlobalFDR.errorMsg = e;
      } else if (e instanceof Error) {
        GlobalFDR.errorMsg = e.message + "\n" + (e.stack || "");
      } else if (e) {
        GlobalFDR.errorMsg = (e as any).toString();
      } else {
        GlobalFDR.errorMsg = "Unknown error";
      }
      done(false, GlobalFDR.fdrLog, GlobalFDR.errorMsg);
    }
  }

  async calculateLive(context: CoreContext, mode: "fast-live" | "full-live") {
    this.globalStore = context.globalStore;
    this.priceTable = context.priceTable;
    this.locale = context.locale;
    this.catalog = context.catalog;
    this.drawing = context.drawing;
    this.nodes = context.nodes;
    this.featureAccess = context.featureAccess;
    this.featureFlags = context.featureFlags;

    this.globalStore.liveCalculationStore.clear();

    try {
      this.doRealLiveCalculation(mode);
    } finally {
      this.removeNetworkObjects();
    }
  }

  clearCalculations() {
    this.globalStore.clearCalculations();
  }

  @TraceCalculation("Validating fields")
  preValidate(): boolean {
    const errors: SelectionTarget[] = [];
    for (const obj of this.globalStore.values()) {
      GlobalFDR.focusData([obj.uid]);
      const objResult = obj.preCalculationValidation(this);
      if (objResult) {
        errors.push(objResult);
      }

      let fields: PropertyField[] = makeInertEntityFields(
        this,
        obj.entity,
        false,
      )!;

      const fieldResult = this.preValidateFields(fields, obj);
      if (fieldResult) {
        errors.push(fieldResult);
      }
    }

    if (errors.length) {
      if (this.onValidationErrors) {
        this.onValidationErrors(errors);
      }
      return false;
    }

    return true;
  }

  @TraceCalculation("Validating individual entity", (_, obj) => [obj.uid])
  preValidateFields(
    fields: PropertyField[],
    obj: CoreObjectConcrete,
    selectObject?: SelectionTarget,
  ): SelectionTarget | undefined {
    for (const field of fields) {
      if (selectObject) {
        return selectObject;
      } else if (field.type === FieldType.Tabs) {
        for (const tab of field.tabs) {
          const selectedObject = this.preValidateFields(tab.fields, obj);
          if (selectedObject) {
            return selectedObject;
          }
        }
      } else {
        if (field.requiresInput) {
          const val = getPropertyByString(obj.entity, field.property);
          if (val === null || val === "") {
            return {
              uid: obj.uid,
              property: field.property,
              message: `A value that is needed to finalise the calculations is missing. Please enter a value for ${field.title} (${field.property}) to enable the calculations to run.`,
              variant: "danger",
              title: "Missing required value",
              recenter: true,
            };
          }
        }
        if (field.type == FieldType.Choice) {
          const val = getPropertyByString(obj.entity, field.property);
          const choices = (field as ChoiceField).params.choices || [];
          const validChoices = choices.filter((c) => !c.disabled);
          if (
            val &&
            val !== "" &&
            validChoices &&
            validChoices.length > 0 &&
            !validChoices.find((c) => c.key == val)
          ) {
            return {
              uid: obj.uid,
              property: field.property,
              message:
                "Please select a valid option for " +
                field.title +
                " (" +
                field.property +
                ")",
              variant: "danger",
              title: "Valid value required",
              recenter: true,
            };
          }
        } else if (field.type === FieldType.FlowSystemChoice) {
          const val = getPropertyByString(obj.entity, field.property);
          const disabledSystems = field.params.disabledSystems || [];
          if (
            val &&
            val !== "" &&
            disabledSystems &&
            disabledSystems.length > 0 &&
            disabledSystems.find((c) => c == val)
          ) {
            return {
              uid: obj.uid,
              property: field.property,
              message:
                val +
                " is not a valid option for " +
                field.title +
                " (" +
                field.property +
                ")",
              variant: "danger",
              title: "Valid value required",
              recenter: true,
            };
          }
        }
      }
    }
  }

  @TraceCalculation("Resetting global storage cache")
  resetGlobalstoreCache() {
    this.globalStore.forEach((o) => this.globalStore.bustDependencies(o.uid));
    if (this.globalStore.dependedBy.size) {
      throw new Error(
        "couldn't clear cache, dependedBy still " +
          JSON.stringify(this.globalStore.dependedBy, (key, value) => {
            if (value instanceof Map) {
              return [...value];
            }
            return value;
          }),
      );
    }
    if (this.globalStore.dependsOn.size) {
      throw new Error(
        "couldn't clear cache, dependsOn still " +
          JSON.stringify([...this.globalStore.dependsOn], (key, value) => {
            if (value instanceof Map) {
              return [...value];
            }
            return value;
          }),
      );
    }
    this.globalStore.forEach((o) => {
      if (o.cache.size) {
        throw new Error("couldn't clear cache");
      }
    });
  }

  @TraceCalculation("Starting main calculation loop")
  async doRealCalculation(iteration: number) {
    console.time("calculations");

    this.resetGlobalstoreCache();

    const sanityPassed = this.sanityCheck(this.globalStore, this.drawing);
    const numberOfSkips = Object.values(this.skip).filter(Boolean).length;
    const maxProgressSteps = 25 - numberOfSkips;
    let lastProgressStep: string | null = null;
    const reportProgressStep = async (progress: number, message: string) => {
      if (iteration > 1) {
        message = message + " (" + iteration + ")";
      }

      if (lastProgressStep !== message) {
        if (lastProgressStep) {
          console.timeEnd(lastProgressStep);
        }
        lastProgressStep = message;
        console.time(lastProgressStep);
      }
      if (this.onProgress) {
        this.onProgress({
          progress,
          message,
          max: maxProgressSteps,
        });
      }
    };

    if (sanityPassed) {
      let step = 0;

      if (iteration > 1) {
        await reportProgressStep(
          step++,
          "Preparing calculations for iteration " + iteration,
        );
        this.resetBetweenIterations();
      } else {
        this.networkObjectUids = [];
        this.drawableObjectUids = [];

        await reportProgressStep(step++, "Building flow network");
        this.buildNetworkObjects();
      }
      await reportProgressStep(step++, "Adding References");
      addHydraulicReferences(this);

      await reportProgressStep(step++, "Determining tank hydrations");
      TankCalculations.determineTankHydration(this);

      await reportProgressStep(step++, "Configuring LU flow graphs");
      this.configureLUFlowGraph();
      this.configureEnergyGraph();
      this.identifyMultipleFlowSources();

      UnderfloorHeatingCalcs.prefillLoopsStats(this);

      await reportProgressStep(step++, "Extracting return networks");
      const returns = ReturnCalculations.identifyReturns(this);
      console.log(
        "returns",
        returns,
        returns.map((r) => r.type),
      );

      await reportProgressStep(step++, "Calculating Room Physics Sizes");
      const cacheArchitectureToRoom =
        HeatLoadCalculations.createCacheArchitectureToRoom(this);
      const cacheRoomPolygon =
        HeatLoadCalculations.createCacheRoomPolygon(this);
      const roomsConfigure = HeatLoadCalculations.calculateRoomsConfigure(this);
      const roomsRTree = HeatLoadCalculations.createRoomsRTree(this);

      HeatLoadCalculations.calculateRoofAreaRatio(
        this,
        cacheArchitectureToRoom,
      );
      HeatLoadCalculations.calculateRoomVolume(
        this,
        roomsConfigure,
        cacheRoomPolygon,
      );

      await reportProgressStep(step++, "Calculating Ventilation Flow Rates");
      VentCalculations.calculateRoomFlowRates(this);
      this.ventRecords = VentCalculations.identifyVentSystems(this);
      const ventZones = VentCalculations.identifyVentilationZones(this);
      const ventZonesWithMVHR = VentCalculations.addMVHRToZones(
        this,
        ventZones,
        this.ventRecords,
      );
      HeatLoadCalculations.calculateHeatLossCoolingLoad(
        this,
        ventZonesWithMVHR,
        cacheArchitectureToRoom,
        cacheRoomPolygon,
        roomsConfigure,
      );
      const ventZonesWithResults = VentCalculations.addVentFlowRateToZones(
        this,
        ventZonesWithMVHR,
      );

      VentCalculations.calculateGrilleFlowRates(this, ventZonesWithResults);

      await reportProgressStep(step++, "Precomputing bridge components");
      this.precomputeBridges(); // Find all bridges
      this.precomputePsdAfterBridge(
        // Calculate Flowrate
        FLOW_SOURCE_ROOT_BRIDGE,
        FLOW_SOURCE_ROOT_NODE,
      );

      await reportProgressStep(step++, "Calculating pipes with exact PSDs");
      this.simpleMappingResults1();
      this.configureComponentsWithExactPSD(); //Set Flowrate for all bridges

      await reportProgressStep(step++, "Identifying ring mains");
      this.sizeRingMains();

      await reportProgressStep(step++, "Add architectural references");
      // addArchitectureReference needs to happen before UnderfloorHeatingCalcs
      addArchitectureReference(this);

      await reportProgressStep(step++, "Calculating damper pressure drops");
      DamperCalculations.assignDamperPressureDrops(this, this.ventRecords);
      VentCalculations.determinePlantFlowRates(this, this.ventRecords);
      VentCalculations.calculateExteriorVentilation(this, this.ventRecords);
      VentCalculations.setExteriorFlowDirections(this, this.ventRecords);

      if (!this.skip.skipFireCalcs) {
        await reportProgressStep(step++, FireCalculationMsg);
        //Generic fire flow rate calculation
        FireCalculations.fireFlowRates(this);
      }

      await reportProgressStep(step++, "Calculating underfloor heating");
      UnderfloorHeatingCalcs.calculateRoomUnheatedArea(
        this,
        cacheRoomPolygon,
        roomsRTree,
      ); // needs to happen before UnderfloorHeatingCalcs.calculateLoops

      const ufhCalcs = UnderfloorHeatingCalcs.calculateLoops(this);

      UnderfloorHeatingCalcs.calculateUFHCoils(this, ufhCalcs);
      HeatLoadCalculations.calculateHeatLoadElementContributions(
        this,
        roomsRTree,
      );

      UnderfloorHeatingCalcs.assignManifoldRating(this);

      await reportProgressStep(
        step++,
        "Calculating return networks and balancing valves",
      );
      ReturnCalculations.returnFlowRates(this, returns);

      // We need the manifold flow rates to figure this out.
      UnderfloorHeatingCalcs.calculateLoopHydraulics(this, ufhCalcs);
      UnderfloorHeatingCalcs.calculateManifoldComponents(this, ufhCalcs); // depends on calculateLoopHydraulics

      ReturnCalculations.returnBalanceValves(this, returns); // balance valves before calculating point pressures so that balancing valve pressure drops are accounted for.
      ReturnCalculations.checkHeatPumpMaximumRecirculation(this, returns);
      ReturnCalculations.returnDetermineIns(this);
      ReturnCalculations.returnTotalVolume(this, returns);
      ReturnCalculations.detectMisplacedFixturesNodes(this, returns);
      ReturnCalculations.detectMisplacedHeatEmitters(this, returns);

      ReturnCalculations.returnIndexCircuitPath(this, returns);

      UnderfloorHeatingCalcs.calculateHeatEmitterStats(this);

      await reportProgressStep(step++, "Calculating drainage");
      DrainageCalculations.processDrainage(this);

      await reportProgressStep(step++, "Calculating deadlegs");
      this.calculateStagnantFlowDeadlegs();
      this.calculateIsolatedRunDeadlegs();
      this.calculateDeadlegsWaitTime();

      await reportProgressStep(step++, "Determining pump duties");
      this.pumpIndexNodeCache = PumpCalculations.assignPumpDuties(this);
      VentCalculations.determinePlantFanDuties(this, this.ventRecords);

      this.nodePressureKPA.clear();
      this.entityMaxPressuresKPA.clear();
      this.entityStaticPressureKPA.clear();
      this.nodeStaticPressureKPA.clear();
      this.isEdgePressureLossValid.clear();
      this.isNodePressureValid.clear();
      await reportProgressStep(step++, "Calculating pressures w/ pumps");

      this.calculateAllPointPressures();

      this.calculateStaticPressures();

      await reportProgressStep(step++, "Determining manufacturer components");
      this.fillPressureDropFields(this);

      PumpCalculations.determinePumpModels(this);
      FilterCalculations.determineFilterModels(this);
      this.resolveManufacturers();
      this.getIndexNodePath();

      // We can get away with calculating this after residual pressure because
      // gas doesn't have any pressure drop in our calculations (for now).
      // We also need this after resolveManufacturers because we need to know
      // models of plants before we know thier gas consumption.
      await reportProgressStep(step++, "Calculating gas");
      GasCalculations.calculateGas(this);

      this.simpleMappingResults2();

      await reportProgressStep(step++, "Generating duct fitting primitives");
      DuctCalculations.calculateDuctFittingPrimitives(this);

      await reportProgressStep(
        step++,
        "Calculating SCOP (heat pumps) and SPF (dhw cylinders)",
      );
      ReturnCalculations.calculateSCOPandSPF(this);
      ReturnCalculations.linkDHWReferenceToHeatPump(this, returns);

      await reportProgressStep(
        step++,
        "Creating warnings and collecting results",
      );
      this.createWarnings();
      this.collectResults();
      await reportProgressStep(step++, "Done!");
    }

    console.timeEnd("calculations");
  }

  async doRealLiveCalculation(mode: "fast-live" | "full-live") {
    this.resetGlobalstoreCache();

    this.networkObjectUids = [];
    this.drawableObjectUids = [];

    this.buildNetworkObjects();

    this.configureLUFlowGraph();
    this.configureEnergyGraph();
    this.identifyMultipleFlowSources();

    addArchitectureReference(this); // needs to happen before UnderfloorHeatingCalcs

    determineConnectivity(this);
    determineCycles(this);
    makeConnectivityWarnings(this);

    UnderfloorHeatingCalcs.prefillLoopsStats(this);

    DrainageCalculations.processDrainageLive(this);

    const cacheArchitectureToRoom =
      HeatLoadCalculations.createCacheArchitectureToRoom(this);
    const cacheRoomPolygon = HeatLoadCalculations.createCacheRoomPolygon(this);
    const roomsConfigure = HeatLoadCalculations.calculateRoomsConfigure(this);
    const roomsRTree = HeatLoadCalculations.createRoomsRTree(this);

    HeatLoadCalculations.calculateRoofAreaRatio(this, cacheArchitectureToRoom);
    HeatLoadCalculations.calculateRoomVolume(
      this,
      roomsConfigure,
      cacheRoomPolygon,
    );

    VentCalculations.calculateRoomFlowRates(this);
    const ventZones = VentCalculations.identifyVentilationZones(this);
    this.ventRecords = VentCalculations.identifyVentSystems(this);
    const ventZonesWithMVHR = VentCalculations.addMVHRToZones(
      this,
      ventZones,
      this.ventRecords,
    );

    HeatLoadCalculations.calculateHeatLossCoolingLoad(
      this,
      ventZonesWithMVHR,
      cacheArchitectureToRoom,
      cacheRoomPolygon,
      roomsConfigure,
    );

    const ventZonesWithResults = VentCalculations.addVentFlowRateToZones(
      this,
      ventZonesWithMVHR,
    );

    VentCalculations.calculateGrilleFlowRates(this, ventZonesWithResults);
    VentCalculations.setExteriorFlowDirections(this, this.ventRecords);

    UnderfloorHeatingCalcs.calculateRoomUnheatedArea(
      this,
      cacheRoomPolygon,
      roomsRTree,
    ); // needs to happen before UnderfloorHeatingCalcs.calculateLoops

    if (mode === "full-live") {
      const returns = ReturnCalculations.identifyReturns(this);
      this.precomputeBridges();
      this.precomputePsdAfterBridge(
        FLOW_SOURCE_ROOT_BRIDGE,
        FLOW_SOURCE_ROOT_NODE,
      );
      this.configureComponentsWithExactPSD(); // we need this one here to get pipe directions.
      this.sizeRingMains();

      const ufhCalcs = UnderfloorHeatingCalcs.calculateLoops(this);
      UnderfloorHeatingCalcs.assignManifoldRating(this);

      ReturnCalculations.returnFlowRates(this, returns);
      UnderfloorHeatingCalcs.calculateLoopHydraulics(this, ufhCalcs);
      UnderfloorHeatingCalcs.calculateManifoldComponents(this, ufhCalcs); // depends on calculateLoopHydraulics

      ReturnCalculations.returnBalanceValves(this, returns); // balance valves before calculating point pressures so that balancing valve pressure drops are accounted for.
      ReturnCalculations.checkHeatPumpMaximumRecirculation(this, returns);
      ReturnCalculations.returnDetermineIns(this);
      ReturnCalculations.calculateSCOPandSPF(this);
    } else {
      // We still need to run a subset of calulatedLoops to surface the addressed loads.
      const _ = UnderfloorHeatingCalcs.calculateLoops(this, true);
      UnderfloorHeatingCalcs.assignManifoldRating(this);
      UnderfloorHeatingCalcs.calculateRoomUnheatedArea(
        this,
        cacheRoomPolygon,
        roomsRTree,
      );
      ReturnCalculations.identifyReturnLoopsOnly(this);
      ReturnCalculations.calculateSCOPandSPF(this, {
        heatPump: true,
        dhw: false,
      });
    }

    HeatLoadCalculations.calculateHeatLoadElementContributions(
      this,
      roomsRTree,
    );

    this.createWarnings();
    DuctCalculations.calculateDuctFittingPrimitives(this);
    this.collectLiveResults();
  }

  softClearCalculations() {
    for (const e of this.networkObjects()) {
      if (!isCalculated(e.entity)) {
        continue;
      }
      const calcs = this.globalStore.getCalculation(e.entity);

      if (calcs) {
        this.globalStore.setCalculation(
          e.uid,
          makeEmptyCalculationWithStickyFields(e.entity, calcs),
        );
      }
    }
  }

  @TraceCalculation("Calculating stagnant flow deadlegs")
  calculateStagnantFlowDeadlegs() {
    // complete another Dijkstra around town for this one.
    for (const o of this.networkObjects()) {
      GlobalFDR.focusData([o.entity.uid]);
      // Change startNode into an array and loop through
      let startNodes: FlowNode[] = [];
      if (o.entity.type === EntityType.PLANT) {
        if (isMultiOutlets(o.entity.plant)) {
          if (isDualSystemNodePlant(o.entity.plant)) {
            if (
              o.entity.plant.heatingOutletUid &&
              shouldCalculateStagnantFlowDeadleg(
                this.drawing.metadata.flowSystems[
                  o.entity.plant.heatingSystemUid
                ],
              )
            ) {
              startNodes.push({
                connectable: o.entity.plant.heatingOutletUid,
                connection: o.entity.uid,
              });
            }

            if (
              o.entity.plant.chilledOutletUid &&
              shouldCalculateStagnantFlowDeadleg(
                this.drawing.metadata.flowSystems[
                  o.entity.plant.chilledSystemUid
                ],
              )
            ) {
              startNodes.push({
                connectable: o.entity.plant.chilledOutletUid,
                connection: o.entity.uid,
              });
            }
          } else if (o.entity.plant.type === PlantType.DUCT_MANIFOLD) {
            // no op
          } else {
            o.entity.plant.outlets.forEach((outlet) => {
              if (
                shouldCalculateStagnantFlowDeadleg(
                  this.drawing.metadata.flowSystems[outlet.outletSystemUid],
                )
              ) {
                startNodes.push({
                  connectable: outlet.outletUid!,
                  connection: o.entity.uid,
                });
              }
            });
          }
        } else {
          if (
            shouldCalculateStagnantFlowDeadleg(
              this.drawing.metadata.flowSystems[o.entity.plant.outletSystemUid],
            )
          ) {
            startNodes.push({
              connectable: o.entity.plant.outletUid!,
              connection: o.entity.uid,
            });
          }
        }
      } else if (isFlowSource(o.entity, this)) {
        const systemUid = determineConnectableSystemUid(
          this.globalStore,
          o.entity,
        );
        if (
          systemUid &&
          shouldCalculateStagnantFlowDeadleg(
            this.drawing.metadata.flowSystems[systemUid],
          )
        ) {
          startNodes.push({
            connectable: o.entity.uid,
            connection: FLOW_SOURCE_EDGE,
          });
        }
      }

      // loop through the startNode array
      for (const startNode of startNodes) {
        // length
        this.flowGraph.dijkstra({
          curr: startNode,
          getDistance: (e, w) => {
            switch (e.value.type) {
              case EdgeType.CONDUIT:
                const o = this.globalStore.get(e.value.uid) as CoreConduit;
                const pCalc = this.globalStore.getOrCreateCalculation(o.entity);

                if (isPipeReturn(pCalc)) {
                  return 0;
                } else {
                  const filled = fillDefaultConduitFields(this, o.entity);
                  return filled.lengthM!;
                }
              case EdgeType.BIG_VALVE_HOT_HOT:
              case EdgeType.BIG_VALVE_HOT_WARM:
              case EdgeType.FITTING_FLOW:
              case EdgeType.CHECK_THROUGH:
              case EdgeType.ISOLATION_THROUGH:
              case EdgeType.BALANCING_THROUGH:
              case EdgeType.PLANT_PREHEAT:
              case EdgeType.BIG_VALVE_COLD_WARM:
              case EdgeType.BIG_VALVE_COLD_COLD:
                // ok, pass through
                return 0;
              case EdgeType.FLOW_SOURCE_EDGE:
              case EdgeType.RETURN_PUMP:
                // yeah nah
                throw new Error("unexpected edge");
              case EdgeType.PLANT_THROUGH:
                // shouldn't continue or be here really
                return Infinity;
            }
            assertUnreachable(e.value.type);
          },
          visitNode: (dijk) => {
            const o = this.globalStore.get(dijk.node.connectable);
            if (o instanceof CoreSystemNode) {
              const po = this.globalStore.get(o.entity.parentUid!);
              if (po instanceof CoreFixture) {
                const fCalc = this.globalStore.getOrCreateCalculation(
                  po.entity,
                );
                fCalc.inlets[o.entity.systemUid].deadlegLengthM = dijk.weight;
              }
            }
          },
        });

        // volume
        this.flowGraph.dijkstra({
          curr: startNode,
          getDistance: (e, w) => {
            switch (e.value.type) {
              case EdgeType.CONDUIT:
                const o = this.globalStore.get(e.value.uid) as CoreConduit;
                if (isPipeEntity(o.entity)) {
                  const pCalc = this.globalStore.getOrCreateCalculation(
                    o.entity,
                  );

                  if (isPipeReturn(pCalc)) {
                    return 0;
                  } else {
                    return this.calculatePipeVolume(
                      pCalc.realInternalDiameterMM,
                      o.computedLengthM,
                      o.entity,
                    );
                  }
                } else {
                  throw new Error(
                    "unexpected conduit type " + o.entity.type + " " + o.uid,
                  );
                }
              case EdgeType.BIG_VALVE_HOT_HOT:
              case EdgeType.BIG_VALVE_HOT_WARM:
              case EdgeType.FITTING_FLOW:
              case EdgeType.CHECK_THROUGH:
              case EdgeType.ISOLATION_THROUGH:
              case EdgeType.BALANCING_THROUGH:
              case EdgeType.PLANT_PREHEAT:
              case EdgeType.BIG_VALVE_COLD_WARM:
              case EdgeType.BIG_VALVE_COLD_COLD:
                // ok, pass through
                return 0;
              case EdgeType.FLOW_SOURCE_EDGE:
              case EdgeType.RETURN_PUMP:
                // yeah nah
                throw new Error(
                  "unexpected edge type: " + EdgeType[e.value.type],
                );
              case EdgeType.PLANT_THROUGH:
                // shouldn't continue or be here really
                return Infinity;
            }
            assertUnreachable(e.value.type);
          },
          visitNode: (dijk) => {
            const o = this.globalStore.get(dijk.node.connectable);
            if (o instanceof CoreSystemNode) {
              const po = this.globalStore.get(o.entity.parentUid!);
              if (po instanceof CoreFixture) {
                const fCalc = this.globalStore.getOrCreateCalculation(
                  po.entity,
                );
                fCalc.inlets[o.entity.systemUid].deadlegVolumeL = dijk.weight;
              }
            }
          },
        });
      }
    }
  }

  @TraceCalculation("Calculate Pipe Volume", (_, __, e) => [e.uid])
  calculatePipeVolume(
    realInternalDiameterMM: number | null,
    computedLengthM: number,
    pipeEntity: ConduitEntity,
  ) {
    if (realInternalDiameterMM !== null) {
      const filled = fillDefaultConduitFields(this, pipeEntity);
      return (
        filled.lengthM! * 10 * (realInternalDiameterMM / 100 / 2) ** 2 * Math.PI
      );
    } else {
      return Infinity;
    }
  }

  getCalcByPipeId(pipeId: string) {
    const pipe = this.globalStore.get(pipeId);
    if (pipe.type === EntityType.CONDUIT && isPipeEntity(pipe.entity)) {
      const pEntity = pipe?.entity;
      const pCalc = this.globalStore.getOrCreateCalculation(pEntity);
      return { pEntity, pCalc, pipe };
    } else {
      return null;
    }
  }

  @TraceCalculation("Calculate Isolated Run Deadlegs")
  calculateIsolatedRunDeadlegs() {
    for (const o of this.networkObjects()) {
      GlobalFDR.focusData([o.uid]);
      if (o instanceof CoreFixture) {
        for (const inlet of Object.keys(o.entity.roughIns)) {
          if (
            !shouldCalculateIsolatedRunDeadleg(
              this.drawing.metadata.flowSystems[inlet],
            )
          ) {
            continue;
          }
          const roughInId = o.entity.roughIns[inlet].uid;
          let pipeId = this.globalStore.getConnections(roughInId)[0];
          let deadLegLengthM: number | null = 0;
          let deadLegVolumeL: number | null = 0;

          // follow the flow source until we reach a connection to another fixture
          const seen: Set<string> = new Set();
          calcLoop: while (pipeId) {
            if (seen.has(pipeId)) {
              break calcLoop;
            }
            seen.add(pipeId);
            const pipe = this.getCalcByPipeId(pipeId);
            if (pipe) {
              if (
                pipe.pCalc.psdUnits &&
                isZeroWaterPsdCounts(pipe.pCalc.psdUnits)
              )
                break;
              if (pipe.pCalc.lengthM === null) {
                deadLegLengthM = null;
                deadLegVolumeL = null;
                break;
              }

              deadLegVolumeL += this.calculatePipeVolume(
                pipe.pCalc.realInternalDiameterMM,
                pipe.pCalc.lengthM,
                pipe.pEntity,
              );
              deadLegLengthM += pipe.pCalc.lengthM;

              if (pipe.pCalc.flowFrom) {
                const pConnections = this.globalStore.getConnections(
                  pipe.pCalc.flowFrom,
                );
                const filteredConnections = pConnections.filter(
                  (c) => c !== pipeId,
                );
                const connectionsWithFlow = [];

                for (const c of filteredConnections) {
                  const nextPipe = this.getCalcByPipeId(c);
                  if (
                    nextPipe &&
                    nextPipe.pCalc.psdUnits &&
                    !isZeroWaterPsdCounts(nextPipe.pCalc.psdUnits)
                  ) {
                    if (nextPipe.pCalc.flowFrom === pipe.pCalc.flowFrom) {
                      break calcLoop;
                    }
                    connectionsWithFlow.push(c);
                  }
                }

                // we found another fixture -> end of deadleg
                if (connectionsWithFlow.length > 1) break;

                pipeId = connectionsWithFlow[0];
              } else break;
            }
          }

          const fCalc = this.globalStore.getOrCreateCalculation(o.entity);
          fCalc.inlets[inlet].deadlegLengthM = deadLegLengthM;
          fCalc.inlets[inlet].deadlegVolumeL = deadLegVolumeL;
        }
      }
    }
  }

  @TraceCalculation("Calculate Deadlegs Wait Time")
  calculateDeadlegsWaitTime() {
    for (const o of this.networkObjects()) {
      GlobalFDR.focusData([o.uid]);
      if (o instanceof CoreFixture) {
        const fCalc = this.globalStore.getOrCreateCalculation(o.entity);

        for (const systemUid of o.entity.roughInsInOrder) {
          if (isDrainage(this.drawing.metadata.flowSystems[systemUid])) {
            continue;
          }

          const connectedPipe = o.entity.roughIns[systemUid].uid;
          const connections = this.globalStore.getConnections(connectedPipe);

          if (connections.length > 0) {
            const pipeDetails = this.globalStore.get<CoreEdgeObjectConcrete>(
              connections[0],
            ).entity;

            if (isPipeEntity(pipeDetails)) {
              const pCalc =
                this.globalStore.getOrCreateCalculation(pipeDetails);

              const pipeFlowRate = pCalc.totalPeakFlowRateLS;
              const fixtureDeadLegVolume =
                fCalc.inlets[systemUid].deadlegVolumeL;

              fCalc.inlets[systemUid].deadlegWaitTimeS =
                fixtureDeadLegVolume! / pipeFlowRate!;
            }
          }
        }
      }
    }
  }

  sizeRingMains() {
    const rmc = new RingMainCalculator(this);
    rmc.calculateAllRings();
  }

  @TraceCalculation("Precomputing data structures for the pipe network")
  precomputeBridges() {
    const [bridges, components] =
      this.flowGraph.findBridgeSeparatedComponents();

    for (const e of bridges) {
      this.allBridges.set(e.uid, e);
    }

    const bridgeStack: Array<Edge<FlowNode | undefined, FlowEdge | undefined>> =
      [FLOW_SOURCE_ROOT_BRIDGE];

    this.childBridges.set(FLOW_SOURCE_ROOT_BRIDGE.uid, []);
    const seenEdges = new Set<string>();

    this.flowGraph.dfsRecursive(
      FLOW_SOURCE_ROOT_NODE,
      (n) => {
        if (
          !this.psdProfileWithinGroup.has(
            bridgeStack[bridgeStack.length - 1].uid,
          )
        ) {
          this.psdProfileWithinGroup.set(
            bridgeStack[bridgeStack.length - 1].uid,
            new PsdProfile(),
          );
        }
        const units = this.getTerminalPsdU(n);
        for (var i = 0; i < units.length; i++) {
          insertPsdProfile(
            this.psdProfileWithinGroup.get(
              bridgeStack[bridgeStack.length - 1].uid,
            )!,
            units[i],
          );
          insertPsdProfile(this.globalReachedPsdUs, units[i]);
        }
      },
      undefined,
      (e) => {
        const pc = this.globalStore.getOrCreateCalculation(
          (this.globalStore.get(e.value.uid) as CoreConduit).entity,
        );

        // Edge case: Do not push flow, including sewer flow, through vents.
        if (e.value.type === EdgeType.CONDUIT) {
          const pipe = this.globalStore.get(e.value.uid);
          if (
            pipe &&
            isPipeEntity(pipe.entity) &&
            isSewer(this.drawing.metadata.flowSystems[pipe.entity.systemUid])
          ) {
            if (pipe.entity.conduit.network === "vents") {
              return true;
            }
          }
        }

        // Some pipes may have their flow directions fixed in an earlier step (such as return systems)
        if (pc.flowFrom) {
          if (e.from.connectable !== pc.flowFrom) {
            seenEdges.delete(e.uid);
            return true;
          }
        }

        if (this.allBridges.has(e.uid)) {
          this.childBridges
            .get(bridgeStack[bridgeStack.length - 1].uid)!
            .push(e);
          this.childBridges.set(e.uid, []);
          bridgeStack.push(e);
        }
        this.parentBridgeOfWetEdge.set(
          e.uid,
          bridgeStack[bridgeStack.length - 1],
        );
        this.firstWet.set(e.uid, e.from);
        this.secondWet.set(e.uid, e.to);
      },
      (e, wasCancelled) => {
        if (wasCancelled) {
          return;
        }
        if (this.allBridges.has(e.uid)) {
          if (e.uid !== bridgeStack.pop()!.uid) {
            throw new Error("traversal error");
          }
        }
      },
      undefined,
      seenEdges,
      true,
      false,
    );
  }

  // Take the calcs from the invisible network and collect them into the visible results.
  @TraceCalculation("Collecting and finalizing results")
  collectResults() {
    this.drawableObjects().forEach((o) => {
      GlobalFDR.focusData([o.uid]);
      if (isCalculated(o.entity)) {
        const ogCalc = this.globalStore.getOrCreateCalculation(o.entity);
        const calc = o.collectCalculations(this);

        // the name would be displayed in the Results mode
        if ((o.entity as NamedEntity).entityName) {
          (calc as NameCalculation).entityName = (
            o.entity as NamedEntity
          ).entityName;
        }
        calc.cost = zeroCost();
        // we need to re-add expanded entites here since collectCaculations
        // is not responsible for them.
        calc.expandedEntities = ogCalc.expandedEntities;
        if (ogCalc.reference) {
          calc.reference = ogCalc.reference;
        }

        for (const e of ogCalc.expandedEntities!) {
          const thisCost = this.globalStore.get(e.uid)!.costBreakdown(this);
          if (thisCost !== null) {
            calc.cost = addCosts(calc.cost, {
              value: thisCost.cost,
              exact: true,
            });
            if (calc.costBreakdown === null) {
              calc.costBreakdown = [];
            }
            calc.costBreakdown.push(...thisCost.breakdown);
          } else {
            calc.cost = addCosts(calc.cost, null);
          }
        }

        this.globalStore.setCalculation(o.uid, calc);
      }
    });
  }

  @TraceCalculation("Collecting and finalizing live results")
  collectLiveResults() {
    this.drawableObjects().forEach((o) => {
      GlobalFDR.focusData([o.uid]);
      if (isCoreCalculatedObject(o)) {
        this.globalStore.setLiveCalculation(
          o.uid,
          o.collectLiveCalculations(this),
        );
      }
    });
  }

  @TraceCalculation("Generating 3D model objects for calculations")
  buildNetworkObjects(runtime?: boolean) {
    this.drawableObjectUids = Array.from(this.globalStore.keys());
    // We assume we have a fresh globalstore with no pollutants.
    // DO NOT refactor this into a traversal of the this.globalStore.values()
    Array.from(this.globalStore.values()).forEach((o) => {
      GlobalFDR.focusData([o.uid]);
      if (!isCalculated(o.entity)) {
        return;
      }

      const es = o.getCalculationEntities(this);
      const calc = this.globalStore.getOrCreateCalculation(o.entity);

      // Some calculations (like references) need the expanded entities right up front.
      calc.expandedEntities = cloneSimple(es);
      es.forEach((e) => {
        GlobalFDR.focusData([e.uid]);
        if (this.globalStore.has(e.uid)) {
          throw new Error(
            "Projected entity already exists: " + JSON.stringify(e),
          );
        }

        // all z coordinates are thingos.
        if (!runtime && isConnectableEntity(e)) {
          if (e.calculationHeightM === null) {
            throw new Error(
              "entities in the calculation phase must be 3d - " +
                e.uid +
                " " +
                e.type,
            );
          }
        }

        (e as any).__calc__ = true;
        CoreObjectFactory.build(
          e,
          this,
          this.globalStore.levelOfEntity.get(o.uid)!,
        );
        // this.globalStore.set(e.uid, e); this is already done by CoreObjectFactory

        this.networkObjectUids.push(e.uid);
      });
    });
  }

  removeNetworkObjects() {
    this.networkObjectUids.forEach((uid) => {
      this.globalStore.delete(uid);
    });
  }

  @TraceCalculation("Simple calculations part 1")
  simpleMappingResults1() {
    this.networkObjects().forEach((o) => {
      GlobalFDR.focusData([o.uid]);
      if (o.entity.type === EntityType.CONDUIT) {
        const c = this.globalStore.getOrCreateCalculation(o.entity);

        c.lengthM =
          o.entity.lengthM == null
            ? (o as CoreConduit).computedLengthM
            : o.entity.lengthM;
        c.heightM = o.entity.heightAboveFloorM;

        if (isPipeEntity(o.entity)) {
          assertType<PipeCalculation>(c);
          c.materialName = getPipeMaterialName(this, o.entity);
        } else if (isDuctEntity(o.entity)) {
          assertType<DuctCalculation>(c);
          c.materialName = getDuctMaterialName(this, o.entity);
        }
      } else if (o.entity.type === EntityType.GAS_APPLIANCE) {
        const c = this.globalStore.getOrCreateCalculation(o.entity);
        c.demandMJH = o.entity.flowRateMJH;
      } else if (
        o.entity.type === EntityType.DIRECTED_VALVE &&
        o.entity.valve.type === ValveType.FLOOR_WASTE
      ) {
        const manufacturer =
          this.drawing.metadata.catalog.floorWaste[0]?.manufacturer ||
          "generic";

        if (manufacturer === "blucher") {
          const c = this.globalStore.getOrCreateCalculation(o.entity);
          c.sizeMM = o.entity.valve.bucketTrapSize === "large" ? 300 : 200;
        }
      } else if (o.entity.type === EntityType.LOAD_NODE) {
        let node: LoadNode | DwellingNode | FireNode | VentilationNode =
          o.entity.node;
        if (node.type === NodeType.FIRE) {
          const fireNode = findFireSubGroup(
            this.drawing,
            node.customEntityId,
            node.subGroupId,
          );
          const loadNodeCalc = this.globalStore.getOrCreateCalculation(
            o.entity,
          );
          if (fireNode != undefined) {
            loadNodeCalc.maxiumumSimutaneousNode =
              fireNode.maxiumumSimutaneousNode;
          }
        }
      }
    });
  }

  @TraceCalculation("Simple calculations part 2")
  simpleMappingResults2() {
    this.networkObjects().forEach((o) => {
      GlobalFDR.focusData([o.uid]);
      if (o.entity.type === EntityType.PLANT) {
        const filled = fillPlantDefaults(this, o.entity);
        const c = this.globalStore.getOrCreateCalculation(o.entity);

        switch (o.entity.plant.type) {
          case PlantType.CUSTOM:
          case PlantType.DRAINAGE_PIT:
          case PlantType.PUMP:
          case PlantType.PUMP_TANK:
          case PlantType.RADIATOR:
          case PlantType.TANK:
          case PlantType.AHU:
          case PlantType.AHU_VENT:
          case PlantType.FCU:
          case PlantType.MANIFOLD:
          case PlantType.UFH:
          case PlantType.FILTER:
          case PlantType.RO:
          case PlantType.DUCT_MANIFOLD:
            break;
          case PlantType.RETURN_SYSTEM:
            for (const vv of c.returnLoopHeatLossKW) {
              const v = Number(vv);
              if (v > 0) {
                c.totalHeatingLoadKW = Number(c.totalHeatingLoadKW) + v;
              } else {
                c.totalChilledLoadKW =
                  Number(c.totalChilledLoadKW) + Math.abs(v);
              }
            }
            break;
          case PlantType.VOLUMISER:
            const volumiser = filled.plant as VolumiserPlant;
            c.totalPlantVolumeL = volumiser.volumeL;
            c.pressureDropKPA = volumiser.pressureLoss.pressureLossKPA;
            break;
          case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
            const greatTrap = filled.plant as DrainageGreaseInterceptorTrap;
            const manufacturer =
              this.drawing.metadata.catalog.greaseInterceptorTrap![0]
                ?.manufacturer || "generic";
            const manufacturerName =
              (manufacturer !== "generic" &&
                this.catalog.greaseInterceptorTrap!.manufacturer.find(
                  (i) => i.uid === manufacturer,
                )!.name) ||
              "";
            const location = greatTrap.location!;
            const position = greatTrap.position!;
            const size =
              this.catalog.greaseInterceptorTrap!.size[manufacturer][location][
                position
              ][greatTrap.capacity!];
            const capacity =
              manufacturer === "generic" ? greatTrap.capacity : "";
            const product = size?.product || "";

            c.size = `${size.lengthMM}mm (L) x ${size.widthMM}mm (W) x ${size.heightMM}mm (H)`;
            c.model =
              `${manufacturerName.toLocaleUpperCase()} ${product}${capacity}`.trim();
            break;
          default:
            assertUnreachable(o.entity.plant);
        }
      }
    });
  }

  @TraceCalculation("Fixing manufacturers")
  resolveManufacturers() {
    this.networkObjects().forEach((o) => {
      if (
        o.entity.type === EntityType.PLANT &&
        o.entity.plant.type === PlantType.RETURN_SYSTEM
      ) {
        if (isHotWaterRheem(this.drawing, o.entity.plant.returnType)) {
          const filled = fillPlantDefaults(this, o.entity);
          this.resolveRheemCalculation(filled);
        }
      }
    });
  }

  @TraceCalculation("Calculate Rheem Plant Values", (e) => [e.uid])
  resolveRheemCalculation(entity: PlantEntity) {
    const plant = entity.plant as ReturnSystemPlant;
    const calc = this.globalStore.getOrCreateCalculation(entity);
    const coldWaterTemp = getFlowSystem(
      this.drawing,
      StandardFlowSystemUids.ColdWater,
    )!.temperatureC;
    const hotWaterTemp = getFlowSystem(
      this.drawing,
      StandardFlowSystemUids.HotWater,
    )!.temperatureC;

    if (!!coldWaterTemp && !!hotWaterTemp) {
      const hotColdTempDiff = hotWaterTemp - coldWaterTemp;
      const rheemVariant = plant.rheemVariant!;
      const manufacturerSizeValue = this.catalog.hotWaterPlant.size.rheem!;
      const tempRiseList = Object.keys(
        manufacturerSizeValue[rheemVariant]?.[1].flowRate || {},
      ) as unknown as Array<HotWaterPlantFlowRateTemperature>;
      const targetTempRise = getNext(
        hotColdTempDiff,
        tempRiseList,
        true,
      ) as HotWaterPlantFlowRateTemperature;

      let size;
      let sizes = Object.values(manufacturerSizeValue[rheemVariant]!);
      switch (rheemVariant) {
        case "continuousFlow":
        case "eclipse":
          const pipe = this.globalStore.get(
            (entity.plant as ReturnSystemPlant).outlets[0].outletUid!,
          )!.entity as SystemNodeEntity;
          const cpipe = this.globalStore.getOrCreateCalculation(pipe);
          const outletPipeFlowRate = cpipe.flowRateLS || 0;

          // get size by flow rate @ temperature rise/diff and outlet pipe flow rate
          size = getNext(
            outletPipeFlowRate,
            sizes,
            true,
            `flowRate.${targetTempRise}`,
          ) as HotWaterPlantSizePropsContinuousFlow;
          calc.size = `${size.widthMM} (W) x ${size.depthMM} (D)`;
          calc.model =
            (rheemVariant === "continuousFlow"
              ? `${size.heaters} x`
              : `${size.model}`) +
            ` Rheem ${RheemVariant[rheemVariant]} Heaters`;
          calc.widthMM = size.widthMM;
          calc.depthMM = size.depthMM;

          if (
            outletPipeFlowRate > size.flowRate[targetTempRise]! ||
            size.heaters! > 18
          ) {
            addWarning(this, "RHEEM_ADVICE", [entity]);
          }

          break;
        case "tankpak":
          // get size by flow rate @ temperature rise/diff and peak hour capacity
          size = getNext(
            plant.rheemPeakHourCapacity!,
            sizes,
            false,
            `flowRate.${targetTempRise}`,
          ) as HotWaterPlantSizePropsTankPak;
          calc.size = `${size.widthMM} (W) x ${size.depthMM} (D)`;
          calc.model = [
            `${size.tanks} x Rheem ${size.tanksCategoryL}L Storage Tanks`,
            `${size.heaters} x Rheem Continuous Flow Heaters`,
          ];
          calc.widthMM = size.widthMM;
          calc.depthMM = size.depthMM;

          if (plant.rheemPeakHourCapacity! > size.flowRate[targetTempRise]!) {
            addWarning(this, "RHEEM_ADVICE", [entity], {});
          }

          break;
        case "electric":
          // filter sizes by selected minimumInitialDelivery
          const filteredSizes = (
            sizes as HotWaterPlantSizePropsElectric[]
          ).filter(
            (i) =>
              i.minimumInitialDelivery === plant.rheemMinimumInitialDelivery,
          );
          const maxFlowRateLS = Math.max.apply(
            Math,
            filteredSizes.map((i) => i.flowRate[targetTempRise]!),
          );

          // if filtered sizes max flow rate is less than/equal to peak hour capacity
          if (maxFlowRateLS <= plant.rheemPeakHourCapacity!) {
            // get size by flow rate @ temperature rise/diff and peak hour capacity
            size = getNext(
              plant.rheemPeakHourCapacity!,
              sizes,
              false,
              `flowRate.${targetTempRise}`,
            ) as HotWaterPlantSizePropsElectric;
          } else {
            // get size from filtered sizes by flow rate @ temperature rise/diff and peak hour capacity
            size = getNext(
              plant.rheemPeakHourCapacity!,
              filteredSizes,
              false,
              `flowRate.${targetTempRise}`,
            ) as HotWaterPlantSizePropsElectric;
          }

          calc.size = `${size.widthMM} (W) x ${size.depthMM} (D)`;
          calc.model = (size as HotWaterPlantSizePropsElectric).model!;
          calc.widthMM = size.widthMM;
          calc.depthMM = size.depthMM;

          if (plant.rheemPeakHourCapacity! > size.flowRate[targetTempRise]!) {
            addWarning(this, "RHEEM_ADVICE", [entity], {});
          }

          break;
        case "heatPump":
          // calculation#1
          const ambientTemp =
            this.drawing.metadata.calculationParams.roomTemperatureC;
          const storageTank =
            this.catalog.hotWaterPlant.storageTanks[
              plant.rheemStorageTankSize!
            ];
          const computeStorageTank =
            plant.rheemPeakHourCapacity! / plant.rheemStorageTankSize!;
          const roundedComputeStorageTank = Math.round(computeStorageTank);
          const storageTankQuantity =
            roundedComputeStorageTank > computeStorageTank
              ? roundedComputeStorageTank
              : roundedComputeStorageTank + 1;
          const storageTankModel = `${storageTankQuantity} x ${storageTank.model} Storage Tanks`;

          // calculation#2
          // filter sizes by rheemkWRating
          sizes = (sizes as HotWaterPlantSizePropsHeatPump[]).filter(
            (i) => i.kW === plant.rheemkWRating,
          );
          // get size by room temperature
          size = getNext(
            ambientTemp,
            sizes,
            true,
            "roomTemperature",
          ) as HotWaterPlantSizePropsHeatPump;

          const computeHeatPump =
            plant.rheemPeakHourCapacity! / 4 / size.flowRate[targetTempRise]!;
          const roundedComputeHeatPump = Math.round(computeHeatPump);
          const heatPumpQuantity =
            roundedComputeHeatPump > computeHeatPump
              ? roundedComputeHeatPump
              : roundedComputeHeatPump + 1;
          const heatPumpModel = `${heatPumpQuantity} x ${size.model} Heat Pumps`;

          calc.size = `${Math.max(size.widthMM, storageTank.widthMM)} (W) x ${
            size.depthMM * heatPumpQuantity +
            storageTank.depthMM * storageTankQuantity
          } (D)`;
          calc.model = [storageTankModel, heatPumpModel];
          calc.widthMM = Math.max(size.widthMM, storageTank.widthMM);
          calc.depthMM =
            size.depthMM * heatPumpQuantity +
            storageTank.depthMM * storageTankQuantity;

          if (ambientTemp <= 10) {
            addWarning(this, "RHEEM_ADVICE", [entity]);
          }

          break;
      }

      if (!!size) {
        const calculation = this.globalStore.getOrCreateCalculation(entity);
        if (calculation.widthMM !== size.widthMM) {
          console.error("widthMM changed", calculation.widthMM, size.widthMM);
          calculation.widthMM = size.widthMM;
          this.needsRecalculation = true;
        }
        if (calculation.depthMM !== size.depthMM) {
          console.error("depthMM changed", calculation.depthMM, size.depthMM);
          calculation.depthMM = size.depthMM;
          this.needsRecalculation = true;
        }
        if (calculation.gasConsumptionMJH !== size.gas.requirement) {
          console.error(
            "gasConsumptionMJH changed",
            calculation.gasConsumptionMJH,
            size.gas.requirement,
          );
          calculation.gasConsumptionMJH = size.gas.requirement;
          this.needsRecalculation = true;
        }
        if (calculation.gasPressureKPA !== size.gas.pressure) {
          console.error(
            "gasPressureKPA changed",
            calculation.gasPressureKPA,
            size.gas.pressure,
          );
          calculation.gasPressureKPA = size.gas.pressure;
          this.needsRecalculation = true;
        }
      }
    }
  }

  @TraceCalculation("Calculating and setting residual pressures")
  calculateAllPointPressures(options?: CalculatePointPressureOptions) {
    this.precomputePeakKPAPoints(options);

    this.networkObjects().forEach((obj) => {
      GlobalFDR.focusData([obj.entity.uid]);
      const entity = obj.entity;
      switch (entity.type) {
        case EntityType.FIXTURE: {
          const calculation = this.globalStore.getOrCreateCalculation(entity);

          for (const suid of entity.roughInsInOrder) {
            calculation.inlets[suid].pressureKPA =
              this.getAbsolutePressurePoint({
                connectable: entity.roughIns[suid].uid,
                connection: entity.uid,
              });
          }
          break;
        }
        case EntityType.BIG_VALVE: {
          const calculation = this.globalStore.getOrCreateCalculation(entity);
          calculation.coldPressureKPA = this.getAbsolutePressurePoint({
            connectable: entity.coldRoughInUid,
            connection: entity.uid,
          });
          calculation.hotPressureKPA = this.getAbsolutePressurePoint({
            connectable: entity.hotRoughInUid,
            connection: entity.uid,
          });
          break;
        }
        case EntityType.FLOW_SOURCE:
        case EntityType.DIRECTED_VALVE:
        case EntityType.FITTING:
        case EntityType.MULTIWAY_VALVE:
        case EntityType.LOAD_NODE:
        case EntityType.SYSTEM_NODE: {
          const calculation = this.globalStore.getOrCreateCalculation(entity);
          const candidates = cloneSimple(
            this.globalStore.getConnections(entity.uid),
          );
          if (isFlowSource(entity, this)) {
            candidates.push(FLOW_SOURCE_EDGE);
          }
          if (entity.type === EntityType.SYSTEM_NODE) {
            candidates.push(entity.parentUid!);
          }
          let maxPressure: number | null = null;
          let minPressure: number | null = null;
          candidates.forEach((cuid) => {
            const thisPressure = this.getAbsolutePressurePoint({
              connectable: entity.uid,
              connection: cuid,
            });
            if (
              thisPressure != null &&
              (maxPressure === null || thisPressure > maxPressure)
            ) {
              maxPressure = thisPressure;
            }
            if (
              minPressure === null ||
              (thisPressure !== null && thisPressure < minPressure)
            ) {
              minPressure = thisPressure;
            }
            if (
              entity.type === EntityType.FITTING ||
              entity.type === EntityType.MULTIWAY_VALVE
            ) {
              const fCalc = this.globalStore.getOrCreateCalculation(entity);
              fCalc.pressureByEndpointKPA[cuid] = thisPressure;
            }
          });
          // For the entry, we have to get the highest pressure (the entry pressure)
          calculation.pressureKPA = maxPressure;
          break;
        }
        case EntityType.CONDUIT:
          break;
        case EntityType.PLANT: {
          if (isPlantPump(entity) || plantHasSingleRating(entity.plant)) {
            if (
              entity.plant.type === PlantType.RETURN_SYSTEM ||
              (isDualSystemNodePlant(entity.plant) &&
                entity.plant.type !== PlantType.AHU_VENT)
            ) {
              break;
            }
            const calculation = this.globalStore.getOrCreateCalculation(entity);
            calculation.entryPressureKPA = entity.inletUid
              ? this.getAbsolutePressurePoint({
                  connectable: entity.inletUid,
                  connection: entity.uid,
                })
              : null;

            if (
              entity.plant.type !== PlantType.AHU_VENT &&
              entity.plant.type !== PlantType.DUCT_MANIFOLD
            ) {
              calculation.exitPressureKPA = this.getAbsolutePressurePoint({
                connectable: entity.plant.outletUid,
                connection: entity.uid,
              });
            }
          }
        }
        case EntityType.GAS_APPLIANCE:
        case EntityType.RISER:
        case EntityType.COMPOUND:
        case EntityType.ROOM:
        case EntityType.EDGE:
        case EntityType.VERTEX:
        case EntityType.WALL:
        case EntityType.FENESTRATION:
        case EntityType.ARCHITECTURE_ELEMENT:
        case EntityType.DAMPER:
        case EntityType.AREA_SEGMENT:
          break;
        default:
          assertUnreachable(entity);
      }
    });
  }

  @TraceCalculation("Identifying conflicting flow sources")
  identifyMultipleFlowSources() {
    this.networkObjects().forEach((o) => {
      GlobalFDR.focusData([o.entity.uid]);
      if (isFlowSource(o.entity, this)) {
        const n = { connectable: o.uid, connection: FLOW_SOURCE_EDGE };
        let done = false;
        this.flowGraph.dfs(
          n,
          () => done,
          undefined,
          (e) => {
            if (e.to.connection === FLOW_SOURCE_EDGE) {
              addWarning(this, "MULTIPLE_CONNECTED_FLOW_SOURCES", [o.entity]);
              done = true;
            }
            return done;
          },
        );
      }
    });
  }

  @TraceCalculation("Calculating static pressures")
  calculateStaticPressures() {
    this.networkObjects().forEach((o) => {
      GlobalFDR.focusData([o.entity.uid]);
      if (isFlowSource(o.entity, this)) {
        const n = { connectable: o.uid, connection: FLOW_SOURCE_EDGE };
        this.pushPressureThroughNetwork({
          start: n,
          pressureKPA: flowSourceKPA(o.entity, PressurePushMode.Static, this)!,
          entityMaxPressuresKPA: this.entityStaticPressureKPA,
          nodePressureKPA: this.nodeStaticPressureKPA,
          pressurePushMode: PressurePushMode.Static,
        });
      }
    });

    this.networkObjects().forEach((obj) => {
      GlobalFDR.focusData([obj.entity.uid]);
      const entity = obj.entity;
      switch (entity.type) {
        case EntityType.FIXTURE: {
          const calculation = this.globalStore.getOrCreateCalculation(entity);

          for (const suid of entity.roughInsInOrder) {
            calculation.inlets[suid].staticPressureKPA =
              this.getAbsolutePressurePoint(
                {
                  connectable: entity.roughIns[suid].uid,
                  connection: entity.uid,
                },
                this.nodeStaticPressureKPA,
              );
          }
          break;
        }
        case EntityType.BIG_VALVE: {
          break;
        }
        case EntityType.FLOW_SOURCE:
        case EntityType.DIRECTED_VALVE:
        case EntityType.MULTIWAY_VALVE:
        case EntityType.FITTING:
        case EntityType.SYSTEM_NODE:
        case EntityType.LOAD_NODE: {
          const calculation = this.globalStore.getOrCreateCalculation(entity);
          const candidates = cloneSimple(
            this.globalStore.getConnections(entity.uid),
          );
          if (entity.type === EntityType.FLOW_SOURCE) {
            candidates.push(FLOW_SOURCE_EDGE);
          } else if (entity.type === EntityType.SYSTEM_NODE) {
            candidates.push(entity.parentUid!);
          }
          let maxPressure: number | null = null;
          let minPressure: number | null = null;
          candidates.forEach((cuid) => {
            const thisPressure = this.getAbsolutePressurePoint(
              {
                connectable: entity.uid,
                connection: cuid,
              },
              this.nodeStaticPressureKPA,
            );
            if (
              thisPressure != null &&
              (maxPressure === null || thisPressure > maxPressure)
            ) {
              maxPressure = thisPressure;
            }
            if (
              minPressure === null ||
              (thisPressure !== null && thisPressure < minPressure)
            ) {
              minPressure = thisPressure;
            }
          });
          calculation.staticPressureKPA = maxPressure;
          break;
        }
        case EntityType.PLANT:
        case EntityType.CONDUIT:
        case EntityType.GAS_APPLIANCE:
        case EntityType.RISER:
        case EntityType.COMPOUND:
        case EntityType.EDGE:
        case EntityType.ROOM:
        case EntityType.VERTEX:
        case EntityType.WALL:
        case EntityType.FENESTRATION:
        case EntityType.ARCHITECTURE_ELEMENT:
        case EntityType.DAMPER:
        case EntityType.AREA_SEGMENT:
          break;
        default:
          assertUnreachable(entity);
      }
    });
  }

  getAbsolutePressurePoint(
    node: FlowNode,
    nodePressureKPA?: Map<string, number | null>,
  ) {
    const num = (nodePressureKPA || this.nodePressureKPA).get(
      this.serializeNode(node),
    );
    return num === undefined ? null : num;
  }

  @TraceCalculation("Pushing pressures through network")
  pushPressureThroughNetwork(options: {
    start: FlowNode;
    pressureKPA: number;
    entityMaxPressuresKPA: Map<string, number | null>;
    entityPressureFromKPA?: Map<string, Map<string, number | null>>;
    isNodePressureValid?: Map<string, boolean>;
    entityPressureToKPA?: Map<string, Map<string, number | null>>;

    // Edge2pressureloss is the pressure loss calculated for each edge traversed.
    // Note: some edges may be traversed but are actually invalid edges, in the case of
    // connectables with >= 3 connections, where only exploded edges stemming from the entry point
    // of the connectable are valid.
    // In this case, the pressure loss for the invalid edges will be non-existent.
    edge2PressureLoss?: Map<string, PressureLossResult>;
    // Used to tell which side an undirected edge was traversed from.
    edgeFlowFrom?: Map<string, FlowNode>;

    nodePressureKPA: Map<string, number | null>;
    pressurePushMode: PressurePushMode;
    tryBothDirections?: boolean;
    ignoreCalculatedDefaults?: boolean;
    isEdgePressureLossValid?: Map<string, boolean>;

    // Allow some kind of calculation to get through loops, etc.
    allowApproximate?: boolean;
  }) {
    const {
      start,
      pressureKPA,
      entityMaxPressuresKPA,
      nodePressureKPA,
      pressurePushMode,
      tryBothDirections,
      entityPressureFromKPA,
      entityPressureToKPA,
      ignoreCalculatedDefaults,
      allowApproximate,
      isNodePressureValid,
      isEdgePressureLossValid,
    } = options;
    let { edge2PressureLoss, edgeFlowFrom } = options;
    if (edge2PressureLoss === undefined) {
      edge2PressureLoss = new Map();
    }
    if (edgeFlowFrom === undefined) {
      edgeFlowFrom = new Map();
    }

    const firstNodeAtConnectable: Map<string, string> = new Map();

    // Only applicable for PressurePushMode.CirculationOnly
    const incomingConduitsAtConnectable: Map<string, Set<string>> = new Map();

    this.flowGraph.dfs(start, undefined, undefined, (e) => {
      if (e.value.type === EdgeType.CONDUIT) {
        const conduit = this.globalStore.get<CoreConduit>(e.value.uid);
        const calc = this.globalStore.getOrCreateCalculation(conduit.entity);
        if (calc.flowFrom !== null) {
          let to = e.to.connectable;
          const from = e.from.connection;
          if (calc.flowFrom === e.to.connectable) {
            to = e.from.connectable;
          }
          const s = incomingConduitsAtConnectable.get(to) || new Set();
          s.add(from);
          incomingConduitsAtConnectable.set(to, s);
        }
      }
    });

    const excludedNodes = new Set<string>();

    // Dijkstra to all objects, recording the max pressure that's arrived there.
    this.flowGraph.dijkstra({
      curr: start,
      tryBothDirections,
      getDistance: (edge, weight): number => {
        const obj = this.globalStore.get(edge.value.uid)!;
        const flowFrom = edge.from;
        const flowTo = edge.to;

        const edgeUid = tryBothDirections
          ? edge.uid + "." + this.flowGraph.sn(edge.from)
          : edge.uid;
        edgeFlowFrom?.set(edgeUid, edge.from);

        let finalPressureKPA: number | null;
        if (weight > -Infinity) {
          finalPressureKPA = pressureKPA! - weight;
        } else {
          finalPressureKPA = null;
        }

        switch (edge.value.type) {
          case EdgeType.CONDUIT:
            if (isPipeEntity(obj.entity) || isDuctEntity(obj.entity)) {
              const calculation = this.globalStore.getOrCreateCalculation(
                obj.entity,
              );

              // recalculate with height
              if (!calculation) {
                console.error(
                  `No calculation for ${obj.entity.uid}`,
                  obj.entity,
                );
                throw new Error(`No calculation for ${obj.entity.uid}`);
                return -Infinity; // The following values are unknown, because this pressure
                // drop is unknown.
              }

              let flowRate: number;
              switch (pressurePushMode) {
                case PressurePushMode.PSD:
                  if (calculation.totalPeakFlowRateLS === null) {
                    isEdgePressureLossValid?.set(edge.uid, false);
                    edge2PressureLoss?.set(edgeUid, {
                      pressureLossKPA: null,
                    });
                    return allowApproximate ? 0 : -Infinity;
                  }
                  flowRate = calculation.totalPeakFlowRateLS;
                  break;
                case PressurePushMode.CirculationFlowOnly:
                  if (isPipeEntity(obj.entity)) {
                    assertType<PipeCalculation>(calculation);
                    // also take care of direction
                    if (calculation.flowFrom !== flowFrom.connectable) {
                      return (
                        CALCULATION_PRESSURE_MAX_KPA +
                        (calculation.totalPeakFlowRateLS || 0)
                      );
                    }

                    if (
                      calculation.rawReturnFlowRateLS === null ||
                      calculation.returnFlowRateLS === null
                    ) {
                      isEdgePressureLossValid?.set(edge.uid, false);
                      edge2PressureLoss?.set(edgeUid, {
                        pressureLossKPA: null,
                      });
                      return allowApproximate ? 0 : -Infinity;
                    }
                    flowRate = calculation.returnFlowRateLS;
                    break;
                  } else {
                    isEdgePressureLossValid?.set(edge.uid, false);
                    edge2PressureLoss?.set(edgeUid, {
                      pressureLossKPA: null,
                    });
                    return allowApproximate ? 0 : -Infinity;
                    break;
                  }
                case PressurePushMode.Static:
                  flowRate = 0;
                  break;
                default: // typechecker
                  flowRate = 0;
                  assertUnreachable(pressurePushMode);
              }

              const pressureLossResult = getObjectFrictionPressureLossKPA({
                context: this,
                object: obj,
                flowLS: flowRate,
                from: edge.from,
                to: edge.to,
                signed: true,
                pressurePushMode,
                ignoreCalculatedDefaults,
              });

              const { pressureLossKPA, isValid } = applyPressureLoss({
                pressureLossResult,
                initialPressureKPA: finalPressureKPA,
              });

              isEdgePressureLossValid?.set(edge.uid, isValid);

              edge2PressureLoss?.set(edgeUid, pressureLossResult);

              return pressureLossKPA ?? (allowApproximate ? 0 : -Infinity);
            } else {
              throw new Error("misconfigured flow graph");
            }
          case EdgeType.BIG_VALVE_HOT_HOT:
          case EdgeType.BIG_VALVE_HOT_WARM:
          case EdgeType.BIG_VALVE_COLD_WARM:
          case EdgeType.BIG_VALVE_COLD_COLD: {
            if (obj instanceof CoreBigValve) {
              const calculation = this.globalStore.getOrCreateCalculation(
                obj.entity,
              );
              if (!calculation) {
                return Infinity;
              }

              let fr: number | null = null;

              let systemUid: string = "";

              if (
                edge.value.type === EdgeType.BIG_VALVE_COLD_COLD &&
                flowFrom.connectable === obj.entity.coldRoughInUid
              ) {
                fr = calculation.coldPeakFlowRate;
                systemUid = (
                  this.globalStore.get(obj.entity.coldRoughInUid)!
                    .entity as SystemNodeEntity
                ).systemUid;
              } else if (
                (edge.value.type === EdgeType.BIG_VALVE_HOT_WARM ||
                  edge.value.type === EdgeType.BIG_VALVE_HOT_HOT) &&
                flowFrom.connectable === obj.entity.hotRoughInUid
              ) {
                fr = calculation.hotPeakFlowRate;
                systemUid = (
                  this.globalStore.get(obj.entity.hotRoughInUid)!
                    .entity as SystemNodeEntity
                ).systemUid;
              } else {
                throw new Error("Misused TMV");
              }

              switch (pressurePushMode) {
                case PressurePushMode.PSD:
                  break;
                case PressurePushMode.CirculationFlowOnly:
                case PressurePushMode.Static:
                  fr = 0;
                  break;
                default:
                  assertUnreachable(pressurePushMode);
              }

              if (fr === null) {
                isEdgePressureLossValid?.set(edge.uid, false);
                return allowApproximate ? 0 : -Infinity;
              }

              const pressureLossResult = getObjectFrictionPressureLossKPA({
                context: this,
                object: obj,
                flowLS: fr,
                from: flowFrom,
                to: flowTo,
                signed: true,
                pressurePushMode,
              });

              const { pressureLossKPA, isValid } = applyPressureLoss({
                pressureLossResult,
                initialPressureKPA: finalPressureKPA,
              });

              isEdgePressureLossValid?.set(edge.uid, isValid);

              edge2PressureLoss?.set(edgeUid, pressureLossResult);

              return pressureLossKPA ?? (allowApproximate ? 0 : -Infinity);
            } else {
              throw new Error("misconfigured flow graph");
            }
          }
          case EdgeType.FITTING_FLOW:
          case EdgeType.CHECK_THROUGH:
          case EdgeType.RETURN_PUMP:
          case EdgeType.ISOLATION_THROUGH: {
            if (flowTo.connection === "FLOW_SOURCE_EDGE") {
              return 0;
            }
            const sourceConduit = this.globalStore.get(flowFrom.connection);
            const destConduit = this.globalStore.get(flowTo.connection);

            if (!destConduit) {
              return 0;
            }
            let srcDist: number | null = null;
            let destDist: number | null = null;
            let dist: number | null = null;

            if (sourceConduit && isPipeEntity(sourceConduit.entity)) {
              const srcCalc = this.globalStore.getOrCreateCalculation(
                sourceConduit.entity,
              );

              switch (pressurePushMode) {
                case PressurePushMode.PSD:
                  srcDist = srcCalc.totalPeakFlowRateLS || 0;
                  break;
                case PressurePushMode.CirculationFlowOnly:
                  srcDist = srcCalc.returnFlowRateLS || 0;
                  break;
                case PressurePushMode.Static:
                  srcDist = 0;
                  break;
                default:
                  assertUnreachable(pressurePushMode);
              }
            }
            if (sourceConduit && isDuctEntity(sourceConduit.entity)) {
              const srcCalc = this.globalStore.getOrCreateCalculation(
                sourceConduit.entity,
              );

              switch (pressurePushMode) {
                case PressurePushMode.PSD:
                  srcDist = srcCalc.totalPeakFlowRateLS || 0;
                  break;
                case PressurePushMode.CirculationFlowOnly:
                case PressurePushMode.Static:
                  srcDist = 0;
                  break;
                default:
                  assertUnreachable(pressurePushMode);
              }
            }

            if (isPipeEntity(destConduit.entity)) {
              const destCalc = this.globalStore.getOrCreateCalculation(
                destConduit.entity,
              );

              switch (pressurePushMode) {
                case PressurePushMode.PSD:
                  destDist = destCalc.totalPeakFlowRateLS || 0;
                  break;
                case PressurePushMode.CirculationFlowOnly:
                  destDist = destCalc.returnFlowRateLS || 0;
                  break;
                case PressurePushMode.Static:
                  destDist = 0;
                  break;
                default:
                  assertUnreachable(pressurePushMode);
              }
            }
            if (isDuctEntity(destConduit.entity)) {
              const destCalc = this.globalStore.getOrCreateCalculation(
                destConduit.entity,
              );

              switch (pressurePushMode) {
                case PressurePushMode.PSD:
                  destDist = destCalc.totalPeakFlowRateLS || 0;
                  break;
                case PressurePushMode.CirculationFlowOnly:
                case PressurePushMode.Static:
                  destDist = 0;
                  break;
                default:
                  assertUnreachable(pressurePushMode);
              }
            }

            if (srcDist != null && destDist != null) {
              dist = Math.min(srcDist, destDist);
            } else if (srcDist != null) {
              dist = srcDist;
            } else {
              dist = destDist;
            }

            const systemUid = determineConnectableSystemUid(
              this.globalStore,
              obj.entity as DirectedValveEntity,
            )!;

            if (dist === null) {
              // this should only happen between the artificial flow
              // source created from FLOW_SOURCE_NODE --> {tank outlet flow node, tank plant}.
              dist = 0.001;
            }

            const pressureLossResult = getObjectFrictionPressureLossKPA({
              context: this,
              object: obj,
              flowLS: dist,
              from: flowFrom,
              to: flowTo,
              signed: true,
              pressurePushMode,
            });

            const { pressureLossKPA, isValid } = applyPressureLoss({
              pressureLossResult,
              initialPressureKPA: finalPressureKPA,
            });

            isEdgePressureLossValid?.set(edge.uid, isValid);

            edge2PressureLoss?.set(edgeUid, pressureLossResult);

            return pressureLossKPA ?? (allowApproximate ? 0 : -Infinity);
          }
          case EdgeType.PLANT_THROUGH: {
            const plant = obj as CorePlant;

            const conns = plant.entity.inletUid
              ? this.globalStore.getConnections(plant.entity.inletUid)
              : [];
            let flowLS: number | null = 0;
            if (conns.length === 1) {
              const connection = this.globalStore.get<CoreEdgeObjectConcrete>(
                conns[0],
              )!;

              if (isPipeEntity(connection.entity)) {
                const calc = this.globalStore.getOrCreateCalculation(
                  connection.entity,
                );

                switch (pressurePushMode) {
                  case PressurePushMode.PSD:
                    flowLS = calc.totalPeakFlowRateLS || 0;
                    break;
                  case PressurePushMode.CirculationFlowOnly:
                    flowLS = calc.returnFlowRateLS || 0;
                    break;
                  case PressurePushMode.Static:
                    flowLS = 0;
                    break;
                  default:
                    assertUnreachable(pressurePushMode);
                }
              } else if (isDuctEntity(connection.entity)) {
                // No pressure drop via height difference through the ducts.
                const calc = this.globalStore.getOrCreateCalculation(
                  connection.entity,
                );

                switch (pressurePushMode) {
                  case PressurePushMode.PSD:
                    flowLS = calc.totalPeakFlowRateLS || 0;
                    break;
                  case PressurePushMode.CirculationFlowOnly:
                    flowLS = 0;
                    break;
                  case PressurePushMode.Static:
                    flowLS = 0;
                    break;
                  default:
                    assertUnreachable(pressurePushMode);
                }
              }
            }

            const pressureLossResult = getObjectFrictionPressureLossKPA({
              context: this,
              object: obj,
              flowLS: flowLS!,
              from: flowFrom,
              to: flowTo,
              signed: true,
              pressurePushMode,
            });

            const { pressureLossKPA, isValid } = applyPressureLoss({
              pressureLossResult,
              initialPressureKPA: finalPressureKPA,
            });

            isEdgePressureLossValid?.set(edge.uid, isValid);

            edge2PressureLoss?.set(edgeUid, pressureLossResult);

            return pressureLossKPA ?? (allowApproximate ? 0 : -Infinity);
          }
          case EdgeType.BALANCING_THROUGH:
            let pressureDrop = 0;
            let isValid = true;
            switch (pressurePushMode) {
              case PressurePushMode.PSD:
                const afterPipe = this.globalStore.get<CoreConduit>(
                  flowTo.connection,
                );
                if (!isPipeEntity(afterPipe.entity)) {
                  throw new Error(
                    "misconfigured flow graph - non-pipe connected to balancing valve",
                  );
                }
                const afterPCalc = this.globalStore.getOrCreateCalculation(
                  afterPipe.entity,
                );
                if (afterPCalc.flowFrom !== edge.value.uid) {
                  // opposite direction
                  pressureDrop =
                    CALCULATION_PRESSURE_MAX_KPA +
                    (afterPCalc.totalPeakFlowRateLS || 0);
                  isValid = false;
                  break;
                }

                const vCalc = this.globalStore.getOrCreateCalculation(
                  (this.globalStore.get(edge.value.uid) as CoreDirectedValve)
                    .entity,
                );
                if (vCalc.pressureDropKPA === null) {
                  isEdgePressureLossValid?.set(edge.uid, false);
                  return allowApproximate ? 0 : -Infinity;
                } else {
                  pressureDrop = vCalc.pressureDropKPA;
                  break;
                }
              case PressurePushMode.CirculationFlowOnly:
                // direction is always correct - assuming balancing valves were placed correctly.
                pressureDrop = MINIMUM_BALANCING_VALVE_PRESSURE_DROP_KPA;
                break;
              case PressurePushMode.Static:
                pressureDrop = 0;
                break;
              default:
                assertUnreachable(pressurePushMode);
            }

            isEdgePressureLossValid?.set(edge.uid, isValid);
            if (isValid) {
              edge2PressureLoss?.set(edge.uid, {
                pressureLossKPA: pressureDrop,
              });
            }
            return pressureDrop;
          case EdgeType.PLANT_PREHEAT:
            if (obj.type === EntityType.PLANT) {
              if (obj.entity.plant.type === PlantType.RETURN_SYSTEM) {
                const inletUid = edge.from.connectable;
                for (const preheat of obj.entity.plant.preheats) {
                  if (preheat.inletUid === inletUid) {
                    return preheat.pressureDropKPA ?? 0;
                  }
                }
              }
            }
          case EdgeType.FLOW_SOURCE_EDGE:
            throw new Error("oopsies");
        }
        assertUnreachable(edge.value.type);
      },
      visitNode: (dijk) => {
        let finalPressureKPA: number | null;

        let thisNodeValid = true;
        if (
          dijk.parent?.from &&
          isNodePressureValid?.get(this.serializeNode(dijk.parent?.from)) ===
            false
        ) {
          thisNodeValid = false;
        }
        const edgeValid =
          isEdgePressureLossValid?.get(dijk.parent?.uid!) ?? true;

        thisNodeValid = thisNodeValid && edgeValid;

        isNodePressureValid?.set(this.serializeNode(dijk.node), thisNodeValid);

        if (dijk.weight > -Infinity) {
          finalPressureKPA = pressureKPA! - dijk.weight;
        } else {
          finalPressureKPA = null;
        }
        if (entityMaxPressuresKPA.has(dijk.node.connectable)) {
          const existing = entityMaxPressuresKPA.get(dijk.node.connectable)!;
          if (
            existing !== null &&
            (finalPressureKPA === null || existing < finalPressureKPA)
          ) {
            // throw new Error('new size is larger than us ' + existing + ' ' + finalPressureKPA);
          }
        } else {
          entityMaxPressuresKPA.set(dijk.node.connectable, finalPressureKPA);
        }
        nodePressureKPA.set(this.serializeNode(dijk.node), finalPressureKPA);

        // For circulation flow rates, we must achieve the *maximum* pressure drop.
        // The pipes in the circulation must already have their directions determined in circulation
        // mode, and also form a DAG. So we can achieve this by not traversing past nodes until all
        // the node's in-flows have arrived, thereby we get the last and highest pressure drop.

        if (pressurePushMode === PressurePushMode.CirculationFlowOnly) {
          if (dijk.parent) {
            if (dijk.parent.value.type === EdgeType.CONDUIT) {
              const s =
                incomingConduitsAtConnectable.get(dijk.node.connectable) ||
                new Set();
              s.delete(dijk.node.connection);
              if (s.size > 0) {
                // We have processed this node, we just don't want to keep propagating.
                // We need to mark it as seen because dijkstra will skip that when we return
                // true here.
                excludedNodes.add(this.flowGraph.sn(dijk.node));
                return true;
              }
            }
          }
        }
      },
      visitEdge: (edge, weight) => {
        // Make sure we go through connectables once, so we don't get the tee K value bug
        // where flow takes a shortcut through two tee flows and reports a pressure drop too low.
        if (isEdgeTypeConnectable(edge.value.type)) {
          if (firstNodeAtConnectable.has(edge.value.uid)) {
            const expectedNode = firstNodeAtConnectable.get(edge.value.uid)!;
            if (expectedNode !== this.serializeNode(edge.from)) {
              edge2PressureLoss?.delete(edge.uid);
              return true;
            }
          } else {
            firstNodeAtConnectable.set(
              edge.value.uid,
              this.serializeNode(edge.from),
            );
          }
        }

        if (weight >= CALCULATION_PRESSURE_MAX_KPA) {
          // this was a "wrong way" edge.
          return;
        }

        if (entityPressureFromKPA) {
          if (edge.value.uid !== edge.to.connectable) {
            const innerMapFrom =
              entityPressureFromKPA.get(edge.to.connectable) ||
              new Map<string, number | null>();
            innerMapFrom.set(edge.value.uid, pressureKPA - weight);
            entityPressureFromKPA.set(edge.to.connectable, innerMapFrom);
          }
        }

        if (entityPressureToKPA) {
          if (edge.value.uid !== edge.from.connectable) {
            const innerMapTo =
              entityPressureToKPA.get(edge.from.connectable) ||
              new Map<string, number | null>();
            innerMapTo.set(edge.value.uid, pressureKPA - weight);
            entityPressureToKPA.set(edge.from.connectable, innerMapTo);
          }
        }
      },
      excludedNodes,
    });
  }

  /**
   * In a peak flow graph, flow paths don't represent a valid network flow state, and sometimes, don't
   * even have a direction for each pipe.
   * One strategy to get a sane pressure drop to a point is to find the smallest pressure drop from it to
   * any source along the least pressure drop path.
   */
  @TraceCalculation("Calculating highest residual pressure at each point")
  precomputePeakKPAPoints(options?: CalculatePointPressureOptions) {
    console.log("Calculating peak pressure at each point");
    const nodePressureKPA = options?.nodePressureKPA || this.nodePressureKPA;
    this.networkObjects().forEach((o) => {
      GlobalFDR.focusData([o.uid]);
      if (isFlowSource(o.entity, this)) {
        const n = { connectable: o.uid, connection: FLOW_SOURCE_EDGE };
        this.pushPressureThroughNetwork({
          start: n,
          pressureKPA: flowSourceKPA(o.entity, PressurePushMode.PSD, this)!,
          entityMaxPressuresKPA: this.entityMaxPressuresKPA,
          nodePressureKPA,
          isNodePressureValid: this.isNodePressureValid,
          isEdgePressureLossValid: this.isEdgePressureLossValid,
          pressurePushMode: PressurePushMode.PSD,
          ignoreCalculatedDefaults: options?.ignoreCalculatedDefaults,
          allowApproximate: options?.allowApproximate,
          edge2PressureLoss: options?.edge2PressureLoss,
          edgeFlowFrom: options?.edgeFlowFrom,
        });
      }
    });
    console.log("Done calculating peak pressures");
  }

  addDirectedEdge(
    type: EdgeType,
    connection: string,
    fromConnectable: string,
    toConnectable: string,
  ) {
    const ev = { type, uid: connection };
    this.flowGraph.addDirectedEdge(
      { connectable: fromConnectable, connection },
      { connectable: toConnectable, connection },
      ev,
      stringify(ev),
    );
  }

  serializeNode(node: FlowNode) {
    return node.connection + " " + node.connectable;
  }

  @TraceCalculation("Configuring energy graph")
  configureEnergyGraph() {
    this.energyGraph = new EnergyGraph();

    for (const obj of this.networkObjects()) {
      GlobalFDR.focusData([obj.uid]);
      switch (obj.entity.type) {
        case EntityType.PLANT:
          switch (obj.entity.plant.type) {
            case PlantType.RETURN_SYSTEM:
              const filled = fillPlantDefaults(this, obj.entity);
              assertType<ReturnSystemPlant>(filled.plant);
              for (const outlet of filled.plant.outlets) {
                for (const preheat of filled.plant.preheats) {
                  // preheat inlet -> outlet
                  this.energyGraph.addDirectedEdge(
                    {
                      connectable: preheat.inletUid!,
                      connection: filled.uid,
                    },
                    {
                      connectable: outlet.outletUid!,
                      connection: filled.uid,
                    },
                    {
                      type: EnergyEdgeType.PLANT,
                      uid: filled.uid,
                      energyRatio:
                        preheat.ratingPCT! > 0 ? preheat.ratingPCT! / 100 : 1,
                    },
                  );
                  this.energyGraph.addEdge(
                    {
                      connectable: preheat.inletUid!,
                      connection: filled.uid,
                    },
                    {
                      connectable: preheat.returnUid!,
                      connection: filled.uid,
                    },
                    {
                      type: EnergyEdgeType.PLANT,
                      uid: filled.uid,
                      energyRatio:
                        preheat.ratingPCT! > 0
                          ? 1 / (preheat.ratingPCT! / 100)
                          : 1,
                    },
                  );
                }
                // gas inlet -> outlets
                if (filled.plant.gasNodeUid) {
                  this.energyGraph.addEdge(
                    {
                      connectable: filled.plant.gasNodeUid,
                      connection: filled.uid,
                    },
                    {
                      connectable: outlet.outletUid!,
                      connection: filled.uid,
                    },
                    {
                      type: EnergyEdgeType.PLANT,
                      uid: filled.uid,
                    },
                  );
                }
              }
              break;
            case PlantType.AHU:
            case PlantType.FCU:
            case PlantType.AHU_VENT:
              // heating
              if (
                obj.entity.plant.heatingInletUid &&
                obj.entity.plant.heatingOutletUid
              ) {
                this.energyGraph.addEdge(
                  {
                    connectable: obj.entity.plant.heatingInletUid,
                    connection: obj.entity.uid,
                  },
                  {
                    connectable: obj.entity.plant.heatingOutletUid,
                    connection: obj.entity.uid,
                  },
                  {
                    type: EnergyEdgeType.PLANT,
                    uid: obj.entity.uid,
                  },
                );
              }

              // chilled
              if (
                obj.entity.plant.chilledInletUid &&
                obj.entity.plant.chilledOutletUid
              ) {
                this.energyGraph.addEdge(
                  {
                    connectable: obj.entity.plant.chilledInletUid,
                    connection: obj.entity.uid,
                  },
                  {
                    connectable: obj.entity.plant.chilledOutletUid,
                    connection: obj.entity.uid,
                  },
                  {
                    type: EnergyEdgeType.PLANT,
                    uid: obj.entity.uid,
                  },
                );
              }

              // vents
              if (obj.entity.plant.type === PlantType.AHU_VENT) {
                if (obj.entity.plant.supplyUid && obj.entity.plant.extractUid) {
                  this.energyGraph.addEdge(
                    {
                      connectable: obj.entity.plant.supplyUid,
                      connection: obj.entity.uid,
                    },
                    {
                      connectable: obj.entity.plant.extractUid,
                      connection: obj.entity.uid,
                    },
                    {
                      type: EnergyEdgeType.PLANT,
                      uid: obj.entity.uid,
                    },
                  );
                }

                if (obj.entity.plant.exhaustUid && obj.entity.plant.intakeUid) {
                  this.energyGraph.addEdge(
                    {
                      connectable: obj.entity.plant.exhaustUid,
                      connection: obj.entity.uid,
                    },
                    {
                      connectable: obj.entity.plant.intakeUid,
                      connection: obj.entity.uid,
                    },
                    {
                      type: EnergyEdgeType.PLANT,
                      uid: obj.entity.uid,
                    },
                  );
                }
              }
              break;
            case PlantType.DUCT_MANIFOLD:
              if (obj.entity.inletUid) {
                for (const outlet of obj.entity.plant.outlets) {
                  this.energyGraph.addEdge(
                    {
                      connectable: obj.entity.inletUid,
                      connection: obj.entity.uid,
                    },
                    {
                      connectable: outlet.uid!,
                      connection: obj.entity.uid,
                    },
                    {
                      type: EnergyEdgeType.PLANT,
                      uid: obj.entity.uid,
                    },
                  );
                }
              }
              break;
            case PlantType.CUSTOM:
            case PlantType.VOLUMISER:
            case PlantType.MANIFOLD:
            case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
            case PlantType.FILTER:
            case PlantType.DRAINAGE_PIT:
            case PlantType.PUMP:
            case PlantType.PUMP_TANK:
            case PlantType.RADIATOR:
            case PlantType.RO:
            case PlantType.TANK:
            case PlantType.UFH:
              if (obj.entity.inletUid && obj.entity.plant.outletUid) {
                this.energyGraph.addEdge(
                  {
                    connectable: obj.entity.inletUid,
                    connection: obj.entity.uid,
                  },
                  {
                    connectable: obj.entity.plant.outletUid,
                    connection: obj.entity.uid,
                  },
                  {
                    type: EnergyEdgeType.PLANT,
                    uid: obj.entity.uid,
                  },
                );
              }
              break;
          }
          break;
        case EntityType.CONDUIT:
          if (obj.entity.endpointUid[0] && obj.entity.endpointUid[1]) {
            this.energyGraph.addEdge(
              {
                connectable: obj.entity.endpointUid[0],
                connection: obj.entity.uid,
              },
              {
                connectable: obj.entity.endpointUid[1],
                connection: obj.entity.uid,
              },
              {
                type: EnergyEdgeType.DEFAULT,
                uid: obj.entity.uid,
              },
            );
          }
          break;
        case EntityType.SYSTEM_NODE:
          const conns = [...this.globalStore.getConnections(obj.entity.uid)];
          if (conns.length && obj.entity.parentUid) {
            this.energyGraph.addEdge(
              {
                connectable: obj.entity.uid,
                connection: conns[0],
              },
              {
                connectable: obj.entity.uid,
                connection: obj.entity.parentUid,
              },
              {
                type: EnergyEdgeType.DEFAULT,
                uid: obj.entity.uid,
              },
            );
          }
        case EntityType.FITTING:
        case EntityType.MULTIWAY_VALVE:
        case EntityType.DIRECTED_VALVE: {
          const conns = [...this.globalStore.getConnections(obj.entity.uid)];

          for (let i = 0; i < conns.length; i++) {
            for (let j = i + 1; j < conns.length; j++) {
              this.energyGraph.addEdge(
                {
                  connectable: obj.entity.uid,
                  connection: conns[i],
                },
                {
                  connectable: obj.entity.uid,
                  connection: conns[j],
                },
                {
                  type: EnergyEdgeType.DEFAULT,
                  uid: obj.entity.uid,
                },
              );
            }
          }
          break;
        }
        case EntityType.FLOW_SOURCE:
        case EntityType.LOAD_NODE:
        case EntityType.BIG_VALVE:
        case EntityType.COMPOUND:
        case EntityType.FIXTURE:
        case EntityType.GAS_APPLIANCE:
        case EntityType.RISER:
        case EntityType.EDGE:
        case EntityType.VERTEX:
        case EntityType.ROOM:
        case EntityType.WALL:
        case EntityType.FENESTRATION:
        case EntityType.ARCHITECTURE_ELEMENT:
        case EntityType.DAMPER:
        case EntityType.AREA_SEGMENT:
          break;
        default:
          assertUnreachable(obj.entity);
      }
    }
  }

  // Just like a flow graph, but only connects when loading units are transferred.
  // TODO: move logic like this into the object classes themselves.
  @TraceCalculation("Building flow network graph")
  configureLUFlowGraph(options?: {
    // Forces tanks to be separate.
    tanksAlwaysFlowSources?: boolean;
  }) {
    const tanksAlwaysFlowSources = options?.tanksAlwaysFlowSources ?? false;

    this.flowGraph = new FlowGraph();
    this.flowGraph.addNode(FLOW_SOURCE_ROOT_NODE);
    this.networkObjects().forEach((obj) => {
      GlobalFDR.focusData([obj.uid]);
      switch (obj.entity.type) {
        case EntityType.CONDUIT:
          if (
            obj.entity.endpointUid[0] === null ||
            obj.entity.endpointUid[1] === null
          ) {
            throw new Error(
              "null found on " + obj.entity.type + " " + obj.entity.uid,
            );
          }
          const ev = {
            type: EdgeType.CONDUIT,
            uid: obj.entity.uid,
          };
          this.flowGraph.addEdge(
            {
              connectable: obj.entity.endpointUid[0],
              connection: obj.entity.uid,
            },
            {
              connectable: obj.entity.endpointUid[1],
              connection: obj.entity.uid,
            },
            ev,
            stringify(ev),
          );
          break;
        case EntityType.BIG_VALVE:
          const entity = obj.entity;
          switch (entity.valve.type) {
            case BigValveType.TMV:
              this.addDirectedEdge(
                EdgeType.BIG_VALVE_COLD_COLD,
                entity.uid,
                entity.coldRoughInUid,
                entity.valve.coldOutputUid,
              );
              this.addDirectedEdge(
                EdgeType.BIG_VALVE_HOT_WARM,
                entity.uid,
                entity.hotRoughInUid,
                entity.valve.warmOutputUid,
              );
              break;
            case BigValveType.TEMPERING:
              this.addDirectedEdge(
                EdgeType.BIG_VALVE_HOT_WARM,
                entity.uid,
                entity.hotRoughInUid,
                entity.valve.warmOutputUid,
              );
              break;
            case BigValveType.RPZD_HOT_COLD:
              this.addDirectedEdge(
                EdgeType.BIG_VALVE_COLD_COLD,
                entity.uid,
                entity.coldRoughInUid,
                entity.valve.coldOutputUid,
              );
              this.addDirectedEdge(
                EdgeType.BIG_VALVE_HOT_HOT,
                entity.uid,
                entity.hotRoughInUid,
                entity.valve.hotOutputUid,
              );
              break;
            default:
              assertUnreachable(entity.valve);
          }
          break;
        case EntityType.LOAD_NODE:
          // Currently don't need to flow through a load node
          break;
        case EntityType.FLOW_SOURCE:
        case EntityType.SYSTEM_NODE:
        case EntityType.MULTIWAY_VALVE:
        case EntityType.FITTING:
          const toConnect = cloneSimple(
            this.globalStore.getConnections(obj.entity.uid),
          );
          if (isFlowSource(obj.entity, this, { tanksAlwaysFlowSources })) {
            this.flowGraph.addNode({
              connectable: obj.uid,
              connection: FLOW_SOURCE_EDGE,
            });
            toConnect.push(FLOW_SOURCE_EDGE);

            this.flowGraph.addDirectedEdge(
              FLOW_SOURCE_ROOT_NODE,
              { connectable: obj.uid, connection: FLOW_SOURCE_EDGE },
              {
                type: EdgeType.FLOW_SOURCE_EDGE,
                uid: obj.entity.uid,
              },
            );
          }
          if (obj.entity.type === EntityType.SYSTEM_NODE) {
            if (!obj.entity.parentUid) {
              throw new Error("invalid system node");
            }
            this.flowGraph.addNode({
              connectable: obj.uid,
              connection: obj.entity.parentUid,
            });
            toConnect.push(obj.entity.parentUid);
          }

          for (let i = 0; i < toConnect.length; i++) {
            for (let j = i + 1; j < toConnect.length; j++) {
              // For nodes, that might connect things of two different
              const p1 = this.globalStore.get(toConnect[i]);
              const p2 = this.globalStore.get(toConnect[j]);
              if (
                p1 &&
                p2 &&
                p1 instanceof CoreConduit &&
                p2 instanceof CoreConduit
              ) {
                if (
                  !flowSystemsFlowTogether(
                    this,
                    p1.entity.systemUid,
                    p2.entity.systemUid,
                  )
                ) {
                  // Cannot enable this yet. Because can't make this work with
                  // towared calculations yet.
                  continue;
                }
              }

              this.flowGraph.addEdge(
                {
                  connectable: obj.entity.uid,
                  connection: toConnect[i],
                },
                {
                  connectable: obj.entity.uid,
                  connection: toConnect[j],
                },
                {
                  type: EdgeType.FITTING_FLOW,
                  // type: obj.entity.type === EntityType.LOAD_NODE ? EdgeType.LOAD_NODE_FLOW : EdgeType.FITTING_FLOW,
                  uid: obj.entity.uid,
                },
              );
            }
          }
          break;
        case EntityType.DIRECTED_VALVE:
          if (isFlowSource(obj.entity, this, { tanksAlwaysFlowSources })) {
            const toConnect = cloneSimple(
              this.globalStore.getConnections(obj.entity.uid),
            );
            const sourceUid = obj.entity.sourceUid;
            const other = toConnect.find((c) => c !== sourceUid)!;

            this.flowGraph.addNode({
              connectable: obj.uid,
              connection: FLOW_SOURCE_EDGE,
            });

            this.flowGraph.addDirectedEdge(
              FLOW_SOURCE_ROOT_NODE,
              { connectable: obj.uid, connection: FLOW_SOURCE_EDGE },
              {
                type: EdgeType.FLOW_SOURCE_EDGE,
                uid: obj.entity.uid,
              },
            );
            //
            this.flowGraph.addDirectedEdge(
              {
                connectable: obj.uid,
                connection: FLOW_SOURCE_EDGE,
              },
              {
                connectable: obj.uid,
                connection: obj.entity.sourceUid,
              },
              {
                type: EdgeType.FITTING_FLOW,
                uid: obj.entity.uid,
              },
            );
          }
          this.configureDirectedValveLUGraph(obj.entity);
          break;
        case EntityType.PLANT:
          if (!isPlantTank(obj.entity)) {
            if (obj.entity.plant.type === PlantType.DUCT_MANIFOLD) {
              for (const outlet of obj.entity.plant.outlets) {
                this.flowGraph.addDirectedEdge(
                  {
                    connectable: obj.entity.inletUid!,
                    connection: obj.entity.uid,
                  },
                  {
                    connectable: outlet.uid,
                    connection: obj.entity.uid,
                  },
                  {
                    type: EdgeType.PLANT_THROUGH,
                    uid: obj.entity.uid,
                  },
                );
              }
            } else if (obj.entity.inletUid) {
              // sewer pits are reversed
              if (
                isDrainage(
                  this.drawing.metadata.flowSystems[obj.entity.inletSystemUid],
                )
              ) {
                // TODO: have a declarative list of relationships
                // between inlets and outlets for plants so this
                // is no longer hard coded.
                if (!isMultiOutlets(obj.entity.plant)) {
                  this.flowGraph.addDirectedEdge(
                    {
                      connectable: obj.entity.plant.outletUid,
                      connection: obj.entity.uid,
                    },
                    {
                      connectable: obj.entity.inletUid,
                      connection: obj.entity.uid,
                    },
                    {
                      type: EdgeType.PLANT_THROUGH,
                      uid: obj.entity.uid,
                    },
                  );
                }
              } else {
                const outletUids: string[] = [];

                if (isMultiOutlets(obj.entity.plant)) {
                  if (isDualSystemNodePlant(obj.entity.plant)) {
                    // this case is handled below
                  } else {
                    for (const outlet of obj.entity.plant.outlets) {
                      outletUids.push(outlet.outletUid!);
                    }
                  }
                } else {
                  outletUids.push(obj.entity.plant.outletUid!);
                }

                for (const outletUid of outletUids) {
                  this.flowGraph.addDirectedEdge(
                    {
                      connectable: obj.entity.inletUid,
                      connection: obj.entity.uid,
                    },
                    {
                      connectable: outletUid,
                      connection: obj.entity.uid,
                    },
                    {
                      type: EdgeType.PLANT_THROUGH,
                      uid: obj.entity.uid,
                    },
                  );
                }
              }
            }
          } else {
            if (!tanksAlwaysFlowSources) {
              const calc = this.globalStore.getOrCreateCalculation(obj.entity);
              if (calc.isInletHydrated && obj.entity.inletUid) {
                this.flowGraph.addDirectedEdge(
                  {
                    connectable: obj.entity.inletUid,
                    connection: obj.entity.uid,
                  },
                  {
                    connectable: obj.entity.plant.outletUid,
                    connection: obj.entity.uid,
                  },
                  {
                    type: EdgeType.PLANT_THROUGH,
                    uid: obj.entity.uid,
                  },
                );
              }
            }
          }
          if (obj.entity.plant.type === PlantType.RETURN_SYSTEM) {
            for (const preheat of obj.entity.plant.preheats) {
              this.flowGraph.addDirectedEdge(
                {
                  connectable: preheat.inletUid,
                  connection: obj.entity.uid,
                },
                {
                  connectable: preheat.returnUid,
                  connection: obj.entity.uid,
                },
                {
                  type: EdgeType.PLANT_PREHEAT,
                  uid: obj.entity.uid,
                },
              );
            }
          }

          if (isDualSystemNodePlant(obj.entity.plant)) {
            if (
              obj.entity.plant.heatingInletUid &&
              obj.entity.plant.heatingOutletUid
            ) {
              this.flowGraph.addDirectedEdge(
                {
                  connectable: obj.entity.plant.heatingInletUid,
                  connection: obj.entity.uid,
                },
                {
                  connectable: obj.entity.plant.heatingOutletUid,
                  connection: obj.entity.uid,
                },
                {
                  type: EdgeType.PLANT_THROUGH,
                  uid: obj.entity.uid,
                },
              );
            }

            if (
              obj.entity.plant.chilledInletUid &&
              obj.entity.plant.chilledOutletUid
            ) {
              this.flowGraph.addDirectedEdge(
                {
                  connectable: obj.entity.plant.chilledInletUid,
                  connection: obj.entity.uid,
                },
                {
                  connectable: obj.entity.plant.chilledOutletUid,
                  connection: obj.entity.uid,
                },
                {
                  type: EdgeType.PLANT_THROUGH,
                  uid: obj.entity.uid,
                },
              );
            }
          }
          break;
        case EntityType.FIXTURE:
        case EntityType.GAS_APPLIANCE:
        case EntityType.RISER:
        case EntityType.COMPOUND:
        case EntityType.EDGE:
        case EntityType.VERTEX:
        case EntityType.ROOM:
        case EntityType.WALL:
        case EntityType.FENESTRATION:
        case EntityType.ARCHITECTURE_ELEMENT:
        case EntityType.DAMPER:
        case EntityType.AREA_SEGMENT:
          break;
        default:
          assertUnreachable(obj.entity);
      }
    });
  }

  configureDirectedValveLUGraph(entity: DirectedValveEntity) {
    const connections = this.globalStore.getConnections(entity.uid);
    if (connections.length === 2) {
      if (entity.sourceUid === null) {
        throw new Error("directed valve with unknown direction");
      }
      if (!connections.includes(entity.sourceUid)) {
        throw new Error("directed valve with invalid direction");
      }
      const other = connections.find((uid) => uid !== entity.sourceUid)!;

      switch (entity.valve.type) {
        case ValveType.RPZD_SINGLE:
        case ValveType.RPZD_DOUBLE_ISOLATED:
        case ValveType.RPZD_DOUBLE_SHARED:
        case ValveType.PRV_SINGLE:
        case ValveType.PRV_DOUBLE:
        case ValveType.PRV_TRIPLE:
        case ValveType.GAS_REGULATOR:
        case ValveType.CHECK_VALVE:
          // from start to finish only
          this.flowGraph.addDirectedEdge(
            {
              connectable: entity.uid,
              connection: entity.sourceUid,
            },
            {
              connectable: entity.uid,
              connection: other,
            },
            {
              type: EdgeType.CHECK_THROUGH,
              uid: entity.uid,
            },
          );
          break;
        case ValveType.ISOLATION_VALVE:
          // Only if it is open
          if (!entity.valve.isClosed) {
            this.flowGraph.addEdge(
              {
                connectable: entity.uid,
                connection: entity.sourceUid,
              },
              {
                connectable: entity.uid,
                connection: other,
              },
              {
                type: EdgeType.ISOLATION_THROUGH,
                uid: entity.uid,
              },
            );
          }
          break;
        case ValveType.BALANCING:
        case ValveType.LSV:
        case ValveType.PICV:
          this.flowGraph.addEdge(
            {
              connectable: entity.uid,
              connection: entity.sourceUid,
            },
            {
              connectable: entity.uid,
              connection: other,
            },
            {
              type: EdgeType.BALANCING_THROUGH,
              uid: entity.uid,
            },
          );
          break;

        case ValveType.WATER_METER:
        case ValveType.CSV:
        case ValveType.FILTER:
        case ValveType.STRAINER:
        case ValveType.RV:
        case ValveType.FLOOR_WASTE:
        case ValveType.INSPECTION_OPENING:
        case ValveType.REFLUX_VALVE:
        case ValveType.TRV:
        case ValveType.CUSTOM_VALVE:
        case ValveType.SMOKE_DAMPER:
        case ValveType.FIRE_DAMPER:
        case ValveType.VOLUME_CONTROL_DAMPER:
        case ValveType.ATTENUATOR:
          this.flowGraph.addEdge(
            {
              connectable: entity.uid,
              connection: entity.sourceUid,
            },
            {
              connectable: entity.uid,
              connection: other,
            },
            {
              type: EdgeType.CHECK_THROUGH,
              uid: entity.uid,
            },
          );
          break;
        case ValveType.FAN:
          if (!isFlowSource(entity, this)) {
            this.flowGraph.addEdge(
              {
                connectable: entity.uid,
                connection: entity.sourceUid,
              },
              {
                connectable: entity.uid,
                connection: other,
              },
              {
                type: EdgeType.CHECK_THROUGH,
                uid: entity.uid,
              },
            );
          }
          break;
        default:
          assertUnreachable(entity.valve);
      }
    } else if (connections.length > 2) {
      throw new Error("too many pipes coming out of this one");
    }
  }

  // Checks basic validity stuff, like hot water/cold water shouldn't mix, all fixtures have
  // required water sources filled in, etc.
  sanityCheck(objectStore: ObjectStore, drawing: DrawingState): boolean {
    // TODO come up with all sanity checks that are required
    return true;
  }

  // Returns PSD of node and correlation group ID
  @TraceCalculation("Get PSD Units of Flow Node", (n) => [
    n.connection,
    n.connectable,
  ])
  getTerminalPsdU(flowNode: FlowNode): ContextualPCE[] {
    let units: ContextualPCE[] = [];

    if (flowNode.connectable === FLOW_SOURCE_ROOT) {
      return units;
    }

    const node = this.globalStore.get<CoreConnectableObjectConcrete>(
      flowNode.connectable,
    );
    const entity = node.entity as DrawableEntityConcrete;

    if (entity.type === EntityType.SYSTEM_NODE) {
      const systemUid = entity.systemUid as StandardFlowSystemUids;
      const parent = this.globalStore.get(node.entity.parentUid!);

      if (parent === undefined) {
        throw new Error(
          "System node is missing parent. " + JSON.stringify(node),
        );
      }
      if (parent.uid !== flowNode.connection) {
        return [];
      }

      const parentEntity = parent.entity as DrawableEntityConcrete;

      switch (parentEntity.type) {
        case EntityType.FIXTURE: {
          const fixture = parentEntity as FixtureEntity;
          const mainFixture = fillFixtureFields(this, fixture);
          const drainageUnits = mainFixture.drainageFixtureUnits;

          for (const suid of fixture.roughInsInOrder) {
            const iAmDrainage =
              isDrainage(this.drawing.metadata.flowSystems[systemUid]) &&
              isDrainage(this.drawing.metadata.flowSystems[suid]);

            // If we need to merge LUs instead of summing up
            const correlationGroup = this.drawing.metadata.calculationParams
              .combineLUs
              ? fixture.roughIns[suid].uid
              : fixture.uid;

            if (node.uid === fixture.roughIns[suid].uid || iAmDrainage) {
              if (
                isGermanStandard(
                  this.drawing.metadata.calculationParams.psdMethod,
                )
              ) {
                units.push({
                  units: Number(mainFixture.roughIns[suid].designFlowRateLS),
                  continuousFlowLS:
                    mainFixture.roughIns[suid].continuousFlowLS!,
                  dwellings: 0,
                  entityGroup: node.entity.uid,
                  entityRef: node.entity.uid,
                  correlationGroup: {
                    groupKey: correlationGroup,
                    subGroupKey: undefined,
                    groupSize: 1,
                  },
                  hcaaFixtureType: getHCAAFixtureTypeByName(fixture.name),
                  drainageUnits: iAmDrainage ? drainageUnits! : 0,
                  gasMJH: 0,
                  gasUndiversifiedMJH: 0,
                  gasHighestMJH: 0,
                  gasDiversifiedMJH: 0,
                  mixedHotCold: suid === StandardFlowSystemUids.WarmWater,
                });
              } else {
                units.push({
                  units: Number(mainFixture.roughIns[suid].loadingUnits),
                  continuousFlowLS:
                    mainFixture.roughIns[suid].continuousFlowLS!,
                  dwellings: 0,
                  entityGroup: node.entity.uid,
                  entityRef: node.entity.uid,
                  gasMJH: 0,
                  gasUndiversifiedMJH: 0,
                  gasHighestMJH: 0,
                  gasDiversifiedMJH: 0,
                  correlationGroup: {
                    groupKey: correlationGroup,
                    subGroupKey: undefined,
                    groupSize: 1,
                  },
                  hcaaFixtureType: getHCAAFixtureTypeByName(fixture.name),
                  drainageUnits: iAmDrainage ? drainageUnits! : 0,
                  mixedHotCold: suid === StandardFlowSystemUids.WarmWater,
                });
              }
            }
          }

          if (!units.length) {
            units.push(
              zeroContextualPCE(
                node.entity.uid,
                node.entity.uid,
                {
                  groupKey: node.entity.uid,
                  subGroupKey: undefined,
                  groupSize: 1,
                },
                getHCAAFixtureTypeByName(fixture.name),
              ),
            );
          }
          break;
        }
        case EntityType.GAS_APPLIANCE: {
          const filled = fillGasApplianceFields(this, parentEntity);
          units.push({
            units: 0,
            continuousFlowLS: 0,
            dwellings: 0,
            entityGroup: filled.uid,
            entityRef: filled.uid,
            correlationGroup: {
              groupKey: filled.uid,
              subGroupKey: undefined,
              groupSize: 1,
            },
            hcaaFixtureType: null,
            gasMJH: 0,
            gasUndiversifiedMJH: 0,
            gasHighestMJH: filled.flowRateMJH!,
            gasDiversifiedMJH:
              filled.flowRateMJH! * numToPercent(filled.diversity!),
            drainageUnits: 0,
          });
          break;
        }
        case EntityType.PLANT: {
          const filled = fillPlantDefaults(this, parentEntity);
          switch (filled.plant.type) {
            case PlantType.RETURN_SYSTEM:
              if (
                filled.plant.gasConsumptionMJH !== null &&
                node.uid === filled.plant.gasNodeUid
              ) {
                units.push({
                  units: 0,
                  continuousFlowLS: 0,
                  dwellings: 0,
                  entityGroup: node.entity.uid,
                  entityRef: node.entity.uid,
                  correlationGroup: {
                    groupKey: parentEntity.uid,
                    subGroupKey: undefined,
                    groupSize: 1,
                  },
                  hcaaFixtureType: null,
                  gasMJH: 0,
                  gasUndiversifiedMJH: 0,
                  gasHighestMJH: filled.plant.gasConsumptionMJH,
                  gasDiversifiedMJH:
                    filled.plant.gasConsumptionMJH *
                    numToPercent(filled.plant.diversity!),
                  drainageUnits: 0,
                });
              }
              break;
            case PlantType.RADIATOR:
            case PlantType.TANK:
            case PlantType.CUSTOM:
            case PlantType.PUMP:
            case PlantType.DRAINAGE_PIT:
            case PlantType.PUMP_TANK:
            case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
            case PlantType.VOLUMISER:
            case PlantType.AHU:
            case PlantType.AHU_VENT:
            case PlantType.FCU:
            case PlantType.MANIFOLD:
            case PlantType.UFH:
            case PlantType.FILTER:
            case PlantType.RO:
            case PlantType.DUCT_MANIFOLD:
              break;
            default:
              assertUnreachable(filled.plant);
          }

          if (!units.length) {
            units.push(
              zeroContextualPCE(
                node.entity.uid,
                node.entity.uid,
                {
                  groupKey: node.entity.uid,
                  subGroupKey: undefined,
                  groupSize: 1,
                },
                null,
              ),
            );
          }
          break;
        }
        case EntityType.BIG_VALVE:
          if (
            isGermanStandard(
              this.drawing.metadata.calculationParams.psdMethod,
            ) &&
            systemUid === StandardFlowSystemUids.HotWater &&
            (parentEntity.valve.type === BigValveType.TMV ||
              parentEntity.valve.type === BigValveType.TEMPERING)
          ) {
            units.push({
              ...zeroContextualPCE(
                node.entity.uid,
                node.entity.uid,
                {
                  groupKey: node.entity.uid,
                  subGroupKey: undefined,
                  groupSize: 1,
                },
                null,
              ),
              mixedHotCold: true,
              warmTemperature: parentEntity.outputTemperatureC,
            });

            break;
          }
        case EntityType.ROOM:
        case EntityType.ARCHITECTURE_ELEMENT:
        // TODO: ventilation
        case EntityType.LOAD_NODE:
        case EntityType.BACKGROUND_IMAGE:
        case EntityType.RISER:
        case EntityType.FLOW_SOURCE:
        case EntityType.CONDUIT:
        case EntityType.FITTING:
        case EntityType.SYSTEM_NODE:
        case EntityType.COMPOUND:
        case EntityType.DIRECTED_VALVE:
        case EntityType.MULTIWAY_VALVE:
        case EntityType.EDGE:
        case EntityType.VERTEX:
        case EntityType.WALL:
        case EntityType.FENESTRATION:
        case EntityType.LINE:
        case EntityType.ANNOTATION:
        case EntityType.DAMPER:
        case EntityType.AREA_SEGMENT:
          units.push(
            zeroContextualPCE(
              node.entity.uid,
              node.entity.uid,
              {
                groupKey: node.entity.uid,
                subGroupKey: undefined,
                groupSize: 1,
              },
              null,
            ),
          );
          break;
        default:
          assertUnreachable(parentEntity);
      }

      if (units.length) {
        return units;
      }

      // Sadly, typescript type checking for return value was not smart enough to avoid these two lines.
      throw new Error("parent type is not a correct value");
    } else if (entity.type === EntityType.LOAD_NODE) {
      const correlationGroup = this.drawing.metadata.calculationParams
        .combineLUs
        ? entity.uid
        : entity.linkedToUid || entity.uid;
      const filled = fillDefaultLoadNodeFields(this, entity);
      switch (filled.node.type) {
        case NodeType.LOAD_NODE:
        case NodeType.DWELLING:
          const psdStandard = this.drawing.metadata.calculationParams.psdMethod;

          const nodeProp = this.nodes.find(
            (node: NodeProps) =>
              // TODO: here the undefined customNodeIds are getting attached to the first node, causing
              // all fixture nodes to be calculated as the first node (ensuite). We need to fix this
              // but I didn't in this release yet.
              node.id === filled.customNodeId ||
              node.uid === filled.customNodeId ||
              // We need to check dbid because legacy nodes are missing the id/uid attribute.
              node.dbid === filled.customNodeId,
          );

          const drainageUnits = getLoadNodeDrainageUnits(entity);
          const drainageUnitsOverridden = drainageUnits != null;
          const calculationUnitsOverridden =
            getLoadNodeCalculationUnits({ entity, psdStrategy: psdStandard }) !=
            null;
          const continuousFlowOverridden = filled.node.continuousFlowLS != null;
          const nodeIsGas = isGas(
            this.drawing.metadata.flowSystems[filled.systemUidOption!],
          );

          if (
            filled.node.type === NodeType.DWELLING &&
            Boolean(this.drawing.metadata.calculationParams.dwellingMethod)
          ) {
            // perform only dwelling calculations, and fixture drainage calculations.
            units.push({
              units: 0,
              continuousFlowLS: filled.node.continuousFlowLS!,
              dwellings: filled.node.dwellings,
              entityGroup: filled.uid,
              entityRef: filled.uid,
              gasMJH: 0,
              gasUndiversifiedMJH:
                (nodeIsGas &&
                  filled.node.gasFlowRateMJH * filled.node.dwellings) ||
                0,
              gasHighestMJH: 0,
              gasDiversifiedMJH: 0,
              drainageUnits: drainageUnits || 0,
              correlationGroup: {
                groupKey: correlationGroup,
                subGroupKey: undefined,
                groupSize: 1,
              },
              hcaaFixtureType: null,
            });

            // Add individual fixtures.
            if (nodeProp && !drainageUnitsOverridden) {
              for (let rep = 0; rep < filled.node.dwellings; rep++) {
                for (let i = 0; i < nodeProp.fixtures.length; i++) {
                  const drainageUnits =
                    (!drainageUnitsOverridden &&
                      getCatalogFixtureDrainageUnits({
                        fixtureName: nodeProp.fixtures[i],
                        catalog: this.catalog,
                        drawing: this.drawing,
                      })) ||
                    0;

                  units.push({
                    units: 0,
                    continuousFlowLS: 0,
                    dwellings: 0,
                    entityGroup: filled.uid + "-" + rep + "-" + i,
                    entityRef: filled.uid,
                    gasMJH: 0,
                    gasUndiversifiedMJH: 0,
                    gasHighestMJH: 0,
                    gasDiversifiedMJH: 0,
                    drainageUnits,
                    correlationGroup: {
                      groupKey: correlationGroup + "-" + rep + "-" + i,
                      subGroupKey: undefined,
                      groupSize: 1,
                    },
                    hcaaFixtureType: getHCAAFixtureTypeByName(
                      nodeProp.fixtures[i],
                    ),
                  });
                }
              }
            }
          } else if (nodeProp) {
            // Fixture group load node entity.

            let dwellingMultiplier = 1;
            let nDwellings = 0;
            if (filled.node.type === NodeType.DWELLING) {
              dwellingMultiplier = Math.min(
                filled.node.dwellings,
                MAX_DWELLINGS,
              );
              nDwellings = filled.node.dwellings;
            }

            const gasUndiversifiedMJH =
              (nodeIsGas && filled.node.gasFlowRateMJH * nDwellings) || 0;
            const gasHighestMJH =
              (nodeIsGas &&
                filled.node.type === NodeType.LOAD_NODE &&
                filled.node.gasFlowRateMJH) ||
              0;
            const gasDiversifiedMJH =
              (nodeIsGas &&
                filled.node.type === NodeType.LOAD_NODE &&
                filled.node.gasFlowRateMJH *
                  numToPercent(getLoadNodeGasDiversity({ entity: filled })!)) ||
              0;

            // add loads that apply to entire node and not accounted for by the fixtures.
            // careful to use the unfilled entity to use the overridden (if any) values.
            units.push({
              units:
                getLoadNodeCalculationUnits({
                  entity,
                  psdStrategy: psdStandard,
                }) || 0,
              continuousFlowLS: filled.node.continuousFlowLS || 0,
              dwellings: nDwellings,
              entityGroup: filled.uid,
              entityRef: filled.uid,
              gasMJH: 0,
              gasUndiversifiedMJH,
              gasHighestMJH,
              gasDiversifiedMJH,
              drainageUnits: drainageUnits || 0,
              correlationGroup: {
                groupKey: correlationGroup + "-global",
                subGroupKey: undefined,
                groupSize: 1,
              },
              hcaaFixtureType:
                filled.node.type === NodeType.LOAD_NODE
                  ? filled.node.hcaaFixtureType
                  : null,
            });

            // Add individual fixtures.
            for (let rep = 0; rep < dwellingMultiplier; rep++) {
              for (let i = 0; i < nodeProp.fixtures.length; i++) {
                const drainageUnits =
                  (!drainageUnitsOverridden &&
                    getCatalogFixtureDrainageUnits({
                      fixtureName: nodeProp.fixtures[i],
                      catalog: this.catalog,
                      drawing: this.drawing,
                    })) ||
                  0;

                const calculationUnits =
                  (!calculationUnitsOverridden &&
                    getFixtureCalculationUnits({
                      fixtureName: nodeProp.fixtures[i],
                      catalog: this.catalog,
                      drawing: this.drawing,
                      psdStrategy: psdStandard,
                      systemUid: filled.systemUidOption!,
                      fallbackSystemUid:
                        filled.systemUidOption === "hot-water"
                          ? "warm-water"
                          : undefined,
                      loadingUnitVariant: filled.loadingUnitVariant!,
                    })) ||
                  0;
                const continuousFlowLS =
                  (!continuousFlowOverridden &&
                    getFixtureContinuousFlow({
                      fixtureName: nodeProp.fixtures[i],
                      systemUid: filled.systemUidOption!,
                      fallbackSystemUid:
                        filled.systemUidOption === "hot-water"
                          ? "warm-water"
                          : undefined,
                      catalog: this.catalog,
                    })) ||
                  0;

                units.push({
                  units: calculationUnits,
                  continuousFlowLS,
                  dwellings: 0,
                  entityGroup: filled.uid + "-" + rep + "-" + i,
                  entityRef: filled.uid,
                  gasMJH: 0,
                  gasUndiversifiedMJH: 0,
                  gasHighestMJH: 0,
                  gasDiversifiedMJH: 0,
                  drainageUnits,
                  correlationGroup: {
                    groupKey: correlationGroup + "-" + rep + "-" + i,
                    subGroupKey: undefined,
                    groupSize: 1,
                  },
                  hcaaFixtureType: getHCAAFixtureTypeByName(
                    nodeProp.fixtures[i],
                  ),
                });
              }
            }

            return units;
          } else {
            // Explicit load node entity.
            switch (filled.node.type) {
              case NodeType.LOAD_NODE:
                units.push({
                  units:
                    getLoadNodeCalculationUnits({
                      entity: filled,
                      psdStrategy: psdStandard,
                    }) || 0,
                  continuousFlowLS: filled.node.continuousFlowLS!,
                  dwellings: 0,
                  entityGroup: filled.uid,
                  entityRef: filled.uid,
                  gasMJH: 0,
                  gasUndiversifiedMJH: 0,
                  gasHighestMJH: filled.node.gasFlowRateMJH,
                  gasDiversifiedMJH:
                    filled.node.gasFlowRateMJH *
                    numToPercent(filled.node.diversity!),
                  drainageUnits: getLoadNodeDrainageUnits(filled) ?? 0,
                  correlationGroup: {
                    groupKey: correlationGroup,
                    subGroupKey: undefined,
                    groupSize: 1,
                  },
                  hcaaFixtureType: filled.node.hcaaFixtureType,
                });
                break;
              case NodeType.DWELLING:
                units.push({
                  units:
                    getLoadNodeCalculationUnits({
                      entity: filled,
                      psdStrategy: psdStandard,
                    }) || 0,
                  continuousFlowLS: filled.node.continuousFlowLS!,
                  dwellings: filled.node.dwellings,
                  entityGroup: filled.uid,
                  entityRef: filled.uid,
                  gasMJH: 0,
                  gasUndiversifiedMJH:
                    filled.node.gasFlowRateMJH * filled.node.dwellings!,
                  gasHighestMJH: 0,
                  gasDiversifiedMJH: 0,
                  drainageUnits: drainageUnits!,
                  correlationGroup: {
                    groupKey: correlationGroup,
                    subGroupKey: undefined,
                    groupSize: 1,
                  },
                  hcaaFixtureType: null,
                });
                break;
              default:
                assertUnreachable(filled.node);
            }
          }

          if (!units.length) {
            throw new Error("invalid node type");
          }
          break;
        case NodeType.FIRE:
          let fireSubGroup = findFireSubGroup(
            this.drawing,
            filled.node.customEntityId,
            filled.node.subGroupId,
          );
          if (fireSubGroup === undefined) {
            console.warn(
              "Invalid FireNode - Investigate reason: ",
              filled.node,
            );
            throw new Error("invalid FireNode");
          }

          units.push({
            units: 0,
            continuousFlowLS: fireSubGroup.continuousFlowRateLS,
            dwellings: 0,
            entityGroup: filled.uid,
            entityRef: filled.uid,
            gasMJH: 0,
            gasUndiversifiedMJH: 0,
            gasHighestMJH: 0,
            gasDiversifiedMJH: 0,
            drainageUnits: 0,
            correlationGroup: {
              groupKey: fireNodeKey(filled.node),
              subGroupKey: `${filled.node.customEntityId}-${filled.node.subGroupId}`,
              groupSize: fireSubGroup.maxiumumSimutaneousNode,
            },
            hcaaFixtureType: null,
          });
          break;
        case NodeType.VENTILATION:
          const calc = this.globalStore.getOrCreateCalculation(entity);
          units.push({
            units: 0,
            continuousFlowLS: calc.flowRateLS ?? filled.node.continuousFlowLS,
            dwellings: 0,
            entityGroup: filled.uid,
            entityRef: filled.uid,
            gasMJH: 0,
            gasUndiversifiedMJH: 0,
            gasHighestMJH: 0,
            gasDiversifiedMJH: 0,
            drainageUnits: 0,
            correlationGroup: {
              groupKey: filled.uid,
              subGroupKey: undefined,
              groupSize: 1,
            },
            hcaaFixtureType: null,
          });
          break;
        default:
          assertUnreachable(filled.node);
      }
    } else {
      units.push(
        zeroContextualPCE(
          node.entity.uid,
          node.entity.uid,
          {
            groupKey: node.entity.uid,
            subGroupKey: undefined,
            groupSize: 1,
          },
          null,
        ),
      );
    }

    return units;
  }

  @TraceCalculation("Configuring entity for PSD", (e, _1, _2, _3, _4, _5) => [
    e.uid,
  ])
  configureEntityForPSD(
    entity: DrawableEntityConcrete,
    psdU: FinalPsdCountEntry,
    flowEdge: FlowEdge,
    wet: FlowNode | null,
    profile: PsdProfile,
    noFlowReason: NoFlowAvailableReason | null,
  ) {
    switch (entity.type) {
      case EntityType.CONDUIT: {
        switch (entity.conduitType) {
          case "pipe": {
            const calculation = this.globalStore.getOrCreateCalculation(entity);

            calculation.psdUnits = psdU;
            calculation.psdProfile = profile;
            if (!calculation.flowFrom) {
              calculation.flowFrom = wet ? wet.connectable : null;
            }
            calculation.noFlowAvailableReason = noFlowReason;

            if (isGas(this.drawing.metadata.flowSystems[entity.systemUid])) {
              // Gas calculation done elsewhere
            } else if (
              isSewer(this.drawing.metadata.flowSystems[entity.systemUid])
            ) {
              DrainageCalculations.fillSewagePipeCalcResult(entity, this);
            } else if (
              isStormwater(this.drawing.metadata.flowSystems[entity.systemUid])
            ) {
              fillStormwaterPipeCalcResult(entity, this);
            } else {
              const flowRate = lookupFlowRate(
                this,
                psdU,
                entity.systemUid,
                profile,
              );
              if (flowRate === null) {
                // Warn for no PSD
                if (isZeroWaterPsdCounts(psdU)) {
                  this.setPipePSDFlowRate(entity, 0);
                } else {
                  calculation.noFlowAvailableReason =
                    NoFlowAvailableReason.LOADING_UNITS_OUT_OF_BOUNDS;
                  addWarning(
                    this,
                    "CHANGE_THE_PEAK_FLOW_RATE_CALCULATION_METHOD",
                    [entity],
                  );
                }
              } else {
                this.setPipePSDFlowRate(entity, flowRate.flowRateLS);
              }
            }
            break;
          }
          case "duct": {
            const calculation = this.globalStore.getOrCreateCalculation(entity);

            calculation.psdUnits = psdU;
            calculation.psdProfile = profile;
            if (!calculation.flowFrom) {
              calculation.flowFrom = wet ? wet.connectable : null;
            }

            DuctCalculations.setDuctSizeForFlowRate(
              this,
              entity,
              psdU.continuousFlowLS,
            );

            break;
          }
          case "cable":
            // TODO
            throw new Error("Not implemented");
        }

        return;
      }
      case EntityType.BIG_VALVE: {
        const calculation = this.globalStore.getOrCreateCalculation(entity);

        const systemUid =
          flowEdge.type === EdgeType.BIG_VALVE_COLD_COLD
            ? StandardFlowSystemUids.ColdWater
            : StandardFlowSystemUids.HotWater;
        const flowRate = lookupFlowRate(this, psdU, systemUid, profile);

        // size is based on the warm water flow rate on the outlet of the TMV
        if (
          entity.valve.type !== BigValveType.RPZD_HOT_COLD &&
          systemUid === StandardFlowSystemUids.HotWater
        ) {
          const manufacturer =
            this.drawing.metadata.catalog.mixingValves.find(
              (material: SelectedMaterialManufacturer) =>
                material.uid === entity.valve.catalogId,
            )?.manufacturer || "generic";

          calculation.mixingValveSizeMM = this.sizeMixingValveForFlowRate(
            !!flowRate?.flowRateLS ? flowRate.flowRateLS : 0,
            manufacturer,
          );
        }

        if (
          entity.valve.type === BigValveType.RPZD_HOT_COLD &&
          flowRate !== null
        ) {
          if (flowEdge.type === EdgeType.BIG_VALVE_HOT_HOT) {
            calculation.rpzdSizeMM![StandardFlowSystemUids.HotWater] =
              this.sizeRpzdForFlowRate(
                entity.valve.catalogId,
                ValveType.RPZD_SINGLE,
                flowRate.flowRateLS,
              );
          } else if (flowEdge.type === EdgeType.BIG_VALVE_COLD_COLD) {
            calculation.rpzdSizeMM![StandardFlowSystemUids.ColdWater] =
              this.sizeRpzdForFlowRate(
                entity.valve.catalogId,
                ValveType.RPZD_SINGLE,
                flowRate.flowRateLS,
              );
          } else {
            throw new Error("Invalid edge on hot-cold RPZD");
          }
        }

        if (flowEdge.type === EdgeType.BIG_VALVE_COLD_COLD) {
          calculation.coldPsdUs = psdU;
          calculation.coldPeakFlowRate = flowRate ? flowRate.flowRateLS : null;
        } else if (
          flowEdge.type === EdgeType.BIG_VALVE_HOT_WARM ||
          flowEdge.type === EdgeType.BIG_VALVE_HOT_HOT
        ) {
          calculation.hotPsdUs = psdU;
          calculation.hotPeakFlowRate = flowRate ? flowRate.flowRateLS : null;
          calculation.hotTotalFlowRateLS = calculation.hotPeakFlowRate;
        } else {
          throw new Error("invalid edge in TMV");
        }

        return;
      }
      case EntityType.ROOM:
      case EntityType.ARCHITECTURE_ELEMENT:
      case EntityType.LOAD_NODE:
      case EntityType.BACKGROUND_IMAGE:
      case EntityType.RISER:
      case EntityType.FLOW_SOURCE:
      case EntityType.FITTING:
      case EntityType.PLANT:
      case EntityType.DIRECTED_VALVE:
      case EntityType.MULTIWAY_VALVE:
      case EntityType.SYSTEM_NODE:
      case EntityType.FIXTURE:
      case EntityType.COMPOUND:
      case EntityType.GAS_APPLIANCE:
      case EntityType.EDGE:
      case EntityType.VERTEX:
      case EntityType.WALL:
      case EntityType.FENESTRATION:
      case EntityType.LINE:
      case EntityType.ANNOTATION:
      case EntityType.DAMPER:
      case EntityType.AREA_SEGMENT:
        throw new Error("Cannot configure this entity to accept loading units");
      default:
        assertUnreachable(entity);
    }
  }

  // TODO: maybe generic function for all conduits
  @TraceCalculation("Setting pipe PSD flow rate", (p, _) => [p.uid])
  setPipePSDFlowRate(pipe: PipeConduitEntity, flowRateLS: number) {
    const cpipe = fillDefaultConduitFields(this, pipe);

    // apply spare capacity
    const system = getFlowSystem(this.drawing, cpipe.systemUid!);
    const spareCapacityPCT =
      system && flowSystemNetworkHasSpareCapacity(system)
        ? system.networks[pipe.conduit.network]!.spareCapacityPCT
        : 0;

    const calc = this.globalStore.getOrCreateCalculation(pipe);
    flowRateLS = CalculationHelper.fillPipeFlowRateCalcResult(
      calc,
      spareCapacityPCT,
      flowRateLS,
    );

    this.sizePipeForFlowRate(pipe, [
      [flowRateLS, cpipe.conduit.maximumVelocityMS!],
    ]);
  }

  // TODO: maybe make it generic and for ducts as well.
  @TraceCalculation("Sizing pipe for flow rate", (p, _) => [p.uid])
  sizePipeForFlowRate(
    pipe: PipeConduitEntity,
    requirements: Array<[number | null, number]>,
  ) {
    const calculation = this.globalStore.getOrCreateCalculation(pipe);

    let sizeMM = -Infinity;
    for (const [flowRate, maxVel] of requirements) {
      if (flowRate === null) {
        // one of the requirements contains an undefined value. Not possible.
        return;
      }
      const thisSizeMM = this.calculateInnerDiameter(pipe, flowRate, maxVel);
      if (thisSizeMM !== null && thisSizeMM > sizeMM) {
        sizeMM = thisSizeMM;
      }
    }

    calculation.optimalInnerPipeDiameterMM = sizeMM;

    let page: PipeSpec | null = null;
    if (pipe.conduit.diameterMM === null) {
      page = this.getRealPipe(pipe);
    } else {
      calculation.realNominalPipeDiameterMM = pipe.conduit.diameterMM;
      page = this.getPipeByNominal(pipe, pipe.conduit.diameterMM);
    }
    if (!page) {
      calculation.noFlowAvailableReason =
        NoFlowAvailableReason.NO_SUITABLE_PIPE_SIZE;
      page = this.getBiggestPipe(pipe);
      if (page) {
        calculation.realNominalPipeDiameterMM = parseCatalogNumberExact(
          page.diameterNominalMM,
        );
        calculation.realInternalDiameterMM = parseCatalogNumberExact(
          page.diameterInternalMM,
        );
        calculation.realOutsideDiameterMM = parseCatalogNumberExact(
          page.diameterOutsideMM,
        );
      }
      if (calculation.realNominalPipeDiameterMM) {
        calculation.velocityRealMS = this.getVelocityRealMs(pipe);

        const drop = this.getPipePressureDropMH(pipe);
        if (drop !== null) {
          calculation.pressureDropKPA = head2kpa(
            drop,
            getFluidDensityOfSystem(this, pipe.systemUid)!,
            this.ga,
          );
        }
      }

      addWarning(this, "NO_SUITABLE_PIPE_SIZE", [pipe], {
        mode: isMechanical(this.drawing.metadata.flowSystems[pipe.systemUid])
          ? "mechanical"
          : null,
        replaceSameWarnings: true,
      });
      return;
    }
    calculation.realNominalPipeDiameterMM = parseCatalogNumberExact(
      page.diameterNominalMM,
    );
    calculation.realInternalDiameterMM = parseCatalogNumberExact(
      page.diameterInternalMM,
    );
    calculation.realOutsideDiameterMM = parseCatalogNumberExact(
      page.diameterOutsideMM,
    );

    if (calculation.realNominalPipeDiameterMM) {
      calculation.velocityRealMS = this.getVelocityRealMs(pipe);

      const drop = this.getPipePressureDropMH(pipe);
      if (drop !== null) {
        calculation.pressureDropKPA = head2kpa(
          drop,
          getFluidDensityOfSystem(this, pipe.systemUid)!,
          this.ga,
        );
      }
    }
  }

  @TraceCalculation("Calculating pipe inner diameter", (p, _1, _2) => [p.uid])
  calculateInnerDiameter(
    pipe: PipeConduitEntity,
    flowRateLS: number,
    maximumVelocityMS: number,
  ): number | null {
    let result = -Infinity;
    // depends on pipe sizing method
    const flowSystem = this.drawing.metadata.flowSystems[pipe.systemUid];

    if (isVelocitySizingEnabled(this.drawing, flowSystem)) {
      // http://www.1728.org/flowrate.htm
      result = Math.max(
        result,
        Math.sqrt((4000 * flowRateLS!) / (Math.PI * maximumVelocityMS!)),
      );
    }
    if (isPressureSizingEnabled(this.drawing, flowSystem)) {
      const o = this.globalStore.get(pipe.uid) as CoreConduit;
      const filled = fillDefaultConduitFields(this, pipe);

      const allowedPressureDropKPAM =
        filled.conduit.maximumPressureDropRateKPAM!;
      const calc = this.globalStore.getOrCreateCalculation(pipe);
      const oldInternalDiameterCalc = calc.realInternalDiameterMM;
      const oldNominalDiameterCalc = calc.realNominalPipeDiameterMM;

      const page = CoreConduit.getPipeManufacturerCatalogPage(this, o.entity);
      if (!page) {
        // should not be possible after validation
        throw new Error("Pipe material missing for pipe " + pipe.uid);
      }

      if (allowedPressureDropKPAM === undefined) {
        throw new Error("Allowed pressure drop missing for pipe " + pipe.uid);
      }

      const evalSizeKPAM = (
        internalDiameterMM: number,
        nominalDiameterMM: number,
      ) => {
        // set the diameter so the pipe's pressure drop can be calculated
        calc.realInternalDiameterMM = internalDiameterMM;
        calc.realNominalPipeDiameterMM = nominalDiameterMM;

        // The friction head loss calc is cached but doesn't depend on the
        // calcualtions which makes things break.
        this.globalStore.bustDependencies(pipe.uid);
        const pressureLossKPA = o.getFrictionPressureLossKPA({
          context: this,
          flowLS: flowRateLS,
          from: {
            connectable: pipe.endpointUid[0],
            connection: pipe.uid,
          },
          to: {
            connectable: pipe.endpointUid[1],
            connection: pipe.uid,
          },
          pressurePushMode: PressurePushMode.PSD,
          signed: true,
          ignoreHeightDifference: true,
        }).pressureLossKPA;
        this.globalStore.bustDependencies(pipe.uid);

        if (pressureLossKPA === null) {
          // This should not be possible after we choose valid pipe sizes.
          throw new Error(
            "Pipe head loss could not be calculated while sizing",
          );
        }

        calc.realInternalDiameterMM = oldInternalDiameterCalc;
        calc.realNominalPipeDiameterMM = oldNominalDiameterCalc;
        return pressureLossKPA / filled.lengthM!;
      };

      const sizesStrs = Object.keys(page);
      if (sizesStrs.length === 0) {
        return null;
      }
      const sizes = sizesStrs.map(Number).sort((a, b) => a - b);
      let lowIndex = 0;
      let highIndex = sizes.length - 1;
      while (lowIndex < highIndex) {
        const midIndex = Math.floor((lowIndex + highIndex) / 2);
        const midSize = sizes[midIndex];
        const midInternalSize = parseCatalogNumberExact(
          page[midSize].diameterInternalMM,
        );
        const midNominalDiameterSize = parseCatalogNumberExact(
          page[midSize].diameterNominalMM,
        );
        if (!midInternalSize || !midNominalDiameterSize) {
          throw new Error("Invalid pipe size");
        }

        const pressureLossKPAM = evalSizeKPAM(
          midInternalSize,
          midNominalDiameterSize,
        );
        if (pressureLossKPAM > allowedPressureDropKPAM) {
          lowIndex = midIndex + 1;
        } else {
          highIndex = midIndex;
        }
      }

      const innerPipeSizeResult = parseCatalogNumberExact(
        page[sizes[lowIndex]].diameterInternalMM,
      )!;
      if (innerPipeSizeResult > result) {
        result = innerPipeSizeResult;
      }
    }
    return result;
  }

  @TraceCalculation("Getting pipe pressure drop", (p) => [p.uid])
  getPipePressureDropMH(pipe: PipeConduitEntity): number | null {
    const obj = this.globalStore.get(pipe.uid) as CoreConduit;
    const calculation = this.globalStore.getOrCreateCalculation(pipe);
    const realPipe = lowerBoundTable(
      CoreConduit.getPipeManufacturerCatalogPage(this, obj.entity)!,
      calculation.realNominalPipeDiameterMM!,
    );
    if (realPipe === null) {
      return null;
    }

    const roughness = parseCatalogNumberExact(
      realPipe.colebrookWhiteCoefficient,
    );
    const realInternalDiameter = parseCatalogNumberExact(
      realPipe.diameterInternalMM,
    );

    const system = getFlowSystem(this.drawing, pipe.systemUid)!;

    const fluidDensity = parseCatalogNumberExact(
      this.catalog.fluids[system.fluid].densityKGM3,
    );
    const dynamicViscosity = parseCatalogNumberExact(
      interpolateTable(
        this.catalog.fluids[system.fluid].dynamicViscosityByTemperature,
        system.temperatureC, // TOOD: Use pipe's temperature
      ),
    );

    const frictionFactor = PressureDropCalculations.getWaterPipeFrictionFactor(
      realInternalDiameter!,
      roughness!,
      PressureDropCalculations.getReynoldsNumber(
        fluidDensity!,
        calculation.velocityRealMS!,
        realInternalDiameter!,
        dynamicViscosity!,
      ),
    );

    const result = PressureDropCalculations.getDarcyWeisbachMH(
      frictionFactor,
      calculation.lengthM!,
      realInternalDiameter!,
      calculation.velocityRealMS!,
      this.ga,
    );

    return result;
  }

  // TODO: maybe all conduits
  @TraceCalculation("Getting pipe velocity", (p) => [p.uid])
  getVelocityRealMs(pipe: PipeConduitEntity) {
    // http://www.1728.org/flowrate.htm

    const calculation = this.globalStore.getOrCreateCalculation(pipe);
    const res =
      (4000 * calculation.totalPeakFlowRateLS!) /
      (Math.PI *
        parseCatalogNumberExact(calculation.realInternalDiameterMM)! ** 2);
    if (isNaN(res)) {
      throw new Error("velocity is NaN " + JSON.stringify(calculation));
    }
    return res;
  }

  @TraceCalculation("Getting pipe from catalog", (p, _) => [p.uid])
  getPipeByNominal(
    pipe: PipeConduitEntity,
    maxNominalMM: number,
  ): PipeSpec | null {
    const pipeFilled = fillDefaultConduitFields(this, pipe);
    const a = upperBoundTable(
      CoreConduit.getPipeManufacturerCatalogPage(this, pipeFilled)!,
      maxNominalMM,
      (p, isMax) => {
        if (isMax) {
          return parseCatalogNumberOrMax(p.diameterNominalMM)!;
        } else {
          return parseCatalogNumberOrMin(p.diameterNominalMM)!;
        }
      },
    );
    if (!a) {
      // Todo: Warn No pipe big enough
      return null;
    } else {
      return a;
    }
  }

  @TraceCalculation("Getting pipe from catalog", (p) => [p.uid])
  getRealPipe(pipe: PipeConduitEntity): PipeSpec | null {
    const obj = this.globalStore.get(pipe.uid) as CoreConduit;
    const calculation = this.globalStore.getOrCreateCalculation(pipe);
    const pipeFilled = fillDefaultConduitFields(this, pipe);
    const table = this.catalog.pipes[pipeFilled.conduit.material!];

    if (!table) {
      throw new Error(
        "Material doesn't exist anymore " + JSON.stringify(pipeFilled),
      );
    }

    const a = lowerBoundTable(
      CoreConduit.getPipeManufacturerCatalogPage(this, obj.entity)!,
      calculation.optimalInnerPipeDiameterMM!,
      (p) => {
        const v = parseCatalogNumberExact(p.diameterInternalMM);
        if (!v) {
          throw new Error("no nominal diameter");
        }
        return v;
      },
    );

    if (!a) {
      // Todo: Warn No pipe big enough
      return null;
    } else {
      return a;
    }
  }

  @TraceCalculation("Getting biggest pipe for the material", (p) => [p.uid])
  getBiggestPipe(pipe: PipeConduitEntity): PipeSpec | null {
    const pipeFilled = fillDefaultConduitFields(this, pipe);
    const table = this.catalog.pipes[pipeFilled.conduit.material!];

    if (!table) {
      throw new Error(
        "Material doesn't exist anymore " + JSON.stringify(pipeFilled),
      );
    }

    const a = upperBoundTable(
      CoreConduit.getPipeManufacturerCatalogPage(this, pipeFilled)!,
      Infinity,
      (p) => {
        const v = parseCatalogNumberExact(p.diameterInternalMM);
        if (!v) {
          throw new Error("no nominal diameter");
        }
        return v;
      },
    );

    if (!a) {
      // Todo: Warn no pipe is big enough
      return null;
    } else {
      return a;
    }
  }

  calculateDefiniteLoads(roots: FlowNode[]) {
    // N = edges + vertices, S = sources, T = sinks (or fixtures).
    // 1. Create a strictly directed graph from the mixed-directed one, sourced by roots. This creates a DAG.
    // 2. For every edge, record the set of all roots with flow into them. O(N*S)
    // 3. Backtrack the graph, to record the sets of loads that each edge flow into. O(N*T)
    // 4. For each edge, check all its fixtures to see if any of them are supplied by sources that don't supply
    //    that edge. If there are AND by removing them, it decreases the flow rate, then it is ambiguous. O(N*T).
  }

  @TraceCalculation("Calculating all entities with exact PSDs")
  configureComponentsWithExactPSD() {
    const totalReachedPsdU = this.globalReachedPsdUs;

    // Size all pipes first
    this.networkObjects().forEach((object) => {
      GlobalFDR.focusData([object.uid]);
      switch (object.entity.type) {
        case EntityType.BIG_VALVE:
          switch (object.entity.valve.type) {
            case BigValveType.TMV:
              this.configureComponentIfExactPSD(
                object,
                totalReachedPsdU,
                { type: EdgeType.BIG_VALVE_HOT_WARM, uid: object.uid },
                [
                  object.entity.hotRoughInUid,
                  object.entity.valve.warmOutputUid,
                ],
              );
              this.configureComponentIfExactPSD(
                object,
                totalReachedPsdU,
                { type: EdgeType.BIG_VALVE_COLD_COLD, uid: object.uid },
                [
                  object.entity.coldRoughInUid,
                  object.entity.valve.coldOutputUid,
                ],
              );
              break;
            case BigValveType.TEMPERING:
              this.configureComponentIfExactPSD(
                object,
                totalReachedPsdU,
                { type: EdgeType.BIG_VALVE_HOT_WARM, uid: object.uid },
                [
                  object.entity.hotRoughInUid,
                  object.entity.valve.warmOutputUid,
                ],
              );
              break;
            case BigValveType.RPZD_HOT_COLD:
              this.configureComponentIfExactPSD(
                object,
                totalReachedPsdU,
                { type: EdgeType.BIG_VALVE_COLD_COLD, uid: object.uid },
                [
                  object.entity.coldRoughInUid,
                  object.entity.valve.coldOutputUid,
                ],
              );
              this.configureComponentIfExactPSD(
                object,
                totalReachedPsdU,
                { type: EdgeType.BIG_VALVE_HOT_HOT, uid: object.uid },
                [object.entity.hotRoughInUid, object.entity.valve.hotOutputUid],
              );
              break;
            default:
              assertUnreachable(object.entity.valve);
          }
          break;
        case EntityType.CONDUIT:
          if (
            object.entity.endpointUid[0] === null ||
            object.entity.endpointUid[1] === null
          ) {
            throw new Error("pipe has dry endpoint: " + object.entity.uid);
          }
          switch (object.entity.conduitType) {
            case "pipe":
              const c = this.globalStore.getOrCreateCalculation(object.entity);
              if (c.configuration === null) {
                c.configuration = PipeConfiguration.NORMAL;
              }
              this.configureComponentIfExactPSD(
                object,
                totalReachedPsdU,
                { type: EdgeType.CONDUIT, uid: object.uid },
                [object.entity.endpointUid[0], object.entity.endpointUid[1]],
              );
              break;
            case "duct":
              this.configureComponentIfExactPSD(
                object,
                totalReachedPsdU,
                { type: EdgeType.CONDUIT, uid: object.uid },
                [object.entity.endpointUid[0], object.entity.endpointUid[1]],
              );
              break;
            case "cable":
              break;
          }
          break;
        case EntityType.DIRECTED_VALVE:
        case EntityType.MULTIWAY_VALVE:
        case EntityType.LOAD_NODE:
        case EntityType.FLOW_SOURCE:
        case EntityType.FITTING:
        case EntityType.PLANT:
        case EntityType.RISER:
        case EntityType.SYSTEM_NODE:
        case EntityType.COMPOUND:
        case EntityType.FIXTURE:
        case EntityType.GAS_APPLIANCE:
        case EntityType.VERTEX:
        case EntityType.EDGE:
        case EntityType.ROOM:
        case EntityType.WALL:
        case EntityType.FENESTRATION:
        case EntityType.ARCHITECTURE_ELEMENT:
        case EntityType.DAMPER:
        case EntityType.AREA_SEGMENT:
          break;
        default:
          assertUnreachable(object.entity);
      }
    });

    // Now size RPZD's, PRVs or any other directed valves, based on pipe peak flow rates
    this.networkObjects().forEach((obj) => {
      GlobalFDR.focusData([obj.uid]);
      switch (obj.entity.type) {
        case EntityType.DIRECTED_VALVE:
          const conns = this.globalStore.getConnections(obj.entity.uid);
          switch (obj.entity.valve.type) {
            case ValveType.RPZD_SINGLE:
            case ValveType.RPZD_DOUBLE_SHARED:
            case ValveType.RPZD_DOUBLE_ISOLATED:
              if (conns.length === 2) {
                const p = this.globalStore.get<CoreConduit>(conns[0]);
                if (isPipeEntity(p.entity)) {
                  const pCalc = this.globalStore.getOrCreateCalculation(
                    p.entity,
                  );

                  if (pCalc.totalPeakFlowRateLS !== null) {
                    let size: number | null = null;
                    if (obj.entity.valve.sizeMM === null) {
                      size = this.sizeRpzdForFlowRate(
                        obj.entity.valve.catalogId,
                        obj.entity.valve.type,
                        pCalc.totalPeakFlowRateLS,
                      );
                    } else {
                      size = obj.entity.valve.sizeMM;
                    }
                    if (size !== null) {
                      const calc = this.globalStore.getOrCreateCalculation(
                        obj.entity,
                      );
                      calc.sizeMM = size;
                    }
                  }
                }
              }
              break;
            case ValveType.PRV_SINGLE:
            case ValveType.PRV_DOUBLE:
            case ValveType.PRV_TRIPLE:
              if (conns.length === 2) {
                const p = this.globalStore.get<CoreConduit>(conns[0]);
                if (isPipeEntity(p.entity)) {
                  const pCalc = this.globalStore.getOrCreateCalculation(
                    p.entity,
                  );

                  if (pCalc.totalPeakFlowRateLS !== null) {
                    let size = this.sizePrvForFlowRate(
                      obj.entity.valve.type,
                      pCalc.totalPeakFlowRateLS,
                    );

                    if (obj.entity.valve.sizeMM !== null) {
                      size = obj.entity.valve.sizeMM;
                    }

                    if (size !== null) {
                      const calc = this.globalStore.getOrCreateCalculation(
                        obj.entity,
                      );
                      calc.sizeMM = size;
                    }
                  }
                }
              }
              break;
            case ValveType.CHECK_VALVE:
            case ValveType.ISOLATION_VALVE:
            case ValveType.WATER_METER:
            case ValveType.CSV:
            case ValveType.STRAINER:
            case ValveType.RV:
            case ValveType.GAS_REGULATOR:
            case ValveType.FILTER:
            case ValveType.BALANCING:
            case ValveType.LSV:
            case ValveType.PICV:
            case ValveType.TRV:
            case ValveType.FLOOR_WASTE:
            case ValveType.INSPECTION_OPENING:
            case ValveType.REFLUX_VALVE:
            case ValveType.CUSTOM_VALVE:
            case ValveType.SMOKE_DAMPER:
            case ValveType.FIRE_DAMPER:
            case ValveType.VOLUME_CONTROL_DAMPER:
            case ValveType.ATTENUATOR:
            case ValveType.FAN:
              break;
            default:
              assertUnreachable(obj.entity.valve);
          }
          break;
        case EntityType.FITTING:
        case EntityType.CONDUIT:
        case EntityType.RISER:
        case EntityType.SYSTEM_NODE:
        case EntityType.BIG_VALVE:
        case EntityType.FIXTURE:
        case EntityType.LOAD_NODE:
          break;
      }
    });
  }

  @TraceCalculation("Sizing RPZD for flow rate")
  sizeRpzdForFlowRate(
    catalogId: string,
    type:
      | ValveType.RPZD_SINGLE
      | ValveType.RPZD_DOUBLE_ISOLATED
      | ValveType.RPZD_DOUBLE_SHARED,
    fr: number,
  ): number | null {
    if (type === ValveType.RPZD_DOUBLE_SHARED) {
      fr = fr / 2;
    }
    const manufacturer =
      this.drawing.metadata.catalog.backflowValves.find(
        (material: SelectedMaterialManufacturer) => material.uid === catalogId,
      )?.manufacturer || "generic";
    const entry = lowerBoundTable(
      this.catalog.backflowValves[catalogId].valvesBySize[manufacturer],
      fr,
      (t, m) => parseCatalogNumberExact(m ? t.maxFlowRateLS : t.minFlowRateLS)!,
    );

    if (entry) {
      return parseCatalogNumberExact(entry.sizeMM);
    }
    return null;
  }

  @TraceCalculation("Sizing PRV for flow rate")
  sizePrvForFlowRate(
    type: ValveType.PRV_SINGLE | ValveType.PRV_DOUBLE | ValveType.PRV_TRIPLE,
    fr: number,
  ): number | null {
    switch (type) {
      case ValveType.PRV_SINGLE:
        break;
      case ValveType.PRV_DOUBLE:
        fr /= 2;
        break;
      case ValveType.PRV_TRIPLE:
        fr /= 3;
        break;
      default:
        assertUnreachable(type);
    }

    const manufacturer =
      this.drawing.metadata.catalog.prv[0]?.manufacturer || "generic";
    const entry = lowerBoundTable(
      this.catalog.prv.size[manufacturer],
      fr,
      (t, m) => parseCatalogNumberExact(m ? t.maxFlowRateLS : t.minFlowRateLS)!,
    );

    if (entry) {
      return parseCatalogNumberExact(entry.diameterNominalMM);
    }
    return null;
  }

  @TraceCalculation(
    "Configuring component if it has exact PSD",
    (o, _1, _2, _3) => [o.uid],
  )
  configureComponentIfExactPSD(
    object: CoreObjectConcrete,
    totalReachedPsdU: PsdProfile,
    flowEdge: FlowEdge,
    endpointUids: string[],
  ) {
    if (flowEdge.uid !== object.uid) {
      throw new Error("invalid args");
    }

    const exclusiveProfile = this.getExcludedPsdUs(flowEdge);

    if (exclusiveProfile === false) {
      // No flow source.
      this.configureEntityForPSD(
        object.entity,
        zeroFinalPsdCounts(),
        flowEdge,
        null,
        new PsdProfile(),
        NoFlowAvailableReason.NO_SOURCE,
      );
    } else {
      const exclusivePsdU = countPsdProfile(this, exclusiveProfile);

      const wet = this.firstWet.get(stringify(flowEdge))!;
      const dryUids = endpointUids.filter((ep) => ep !== wet.connectable);
      if (dryUids.length !== 1) {
        throw new Error(
          "Both endpoints of the edge are 'wet'. This could be caused by an invalid edge that is connected to the same point on both ends.",
        );
      }
      const dryUid = dryUids[0];

      const residualPsdProfile = this.getDownstreamPsdUs(
        [{ connectable: dryUid, connection: object.uid }],
        [],
        [stringify(flowEdge)],
      );
      const residualPsdU = countPsdProfile(this, residualPsdProfile);

      if (!isZeroWaterPsdCounts(exclusivePsdU)) {
        const cmp = compareWaterPsdCounts(residualPsdU, exclusivePsdU);
        if (cmp === null) {
          throw new Error("Impossible PSD situation");
        }
        if (cmp > 0) {
          // TODO: Info that flow rate is ambiguous, but some flow is exclusive to us
          if (object.entity.type === EntityType.CONDUIT) {
            if (isPipeEntity(object.entity)) {
              const pcalc = this.globalStore.getOrCreateCalculation(
                object.entity,
              );
              pcalc.noFlowAvailableReason =
                NoFlowAvailableReason.UNUSUAL_CONFIGURATION;
            }
          }
        } else {
          // TODO comment out temporarily https://h2xengineering.atlassian.net/browse/DEV-443
          // if (cmp < 0) {
          //     throw new Error("Invalid PSD situation");
          // }
          // we have successfully calculated the pipe's loading units.
          this.configureEntityForPSD(
            object.entity,
            exclusivePsdU,
            flowEdge,
            wet,
            exclusiveProfile,
            null,
          );
        }
      } else {
        if (isZeroWaterPsdCounts(residualPsdU)) {
          this.configureEntityForPSD(
            object.entity,
            exclusivePsdU,
            flowEdge,
            wet,
            new PsdProfile(),
            NoFlowAvailableReason.NO_LOADS_CONNECTED,
          );
        } else {
          // TODO: flow rate is ambiguous, and no flow is exclusive to us.
          if (object.entity.type === EntityType.CONDUIT) {
            if (isPipeEntity(object.entity)) {
              const pcalc = this.globalStore.getOrCreateCalculation(
                object.entity,
              );

              pcalc.noFlowAvailableReason =
                NoFlowAvailableReason.UNUSUAL_CONFIGURATION;
              pcalc.psdUnits = residualPsdU;
            }
          }
        }
      }
    }
  }

  @TraceCalculation("Filling pressure drop fields")
  fillPressureDropFields(context: CoreContext) {
    for (const o of this.networkObjects()) {
      GlobalFDR.focusData([o.uid]);
      switch (o.entity.type) {
        case EntityType.BIG_VALVE: {
          const calculation = this.globalStore.getOrCreateCalculation(o.entity);

          switch (o.entity.valve.type) {
            case BigValveType.TMV: {
              const fr = calculation.coldPeakFlowRate;
              if (fr !== null) {
                const pressureLossResult = getObjectFrictionPressureLossKPA({
                  context: this,
                  object: o,
                  flowLS: fr,
                  from: {
                    connection: o.uid,
                    connectable: o.entity.coldRoughInUid,
                  },
                  to: {
                    connection: o.uid,
                    connectable: o.entity.valve.coldOutputUid,
                  },
                  signed: true,
                  pressurePushMode: PressurePushMode.PSD,
                });
                calculation.outputs[
                  StandardFlowSystemUids.ColdWater
                ].pressureDropKPA = applyPressureLoss({
                  initialPressureKPA: calculation.coldPressureKPA,
                  pressureLossResult,
                }).pressureLossKPA;
              }
            }
            /* fall through */
            case BigValveType.TEMPERING: {
              const frw = calculation.hotPeakFlowRate;
              if (frw !== null) {
                const pressureLossResult = getObjectFrictionPressureLossKPA({
                  context: this,
                  object: o,
                  flowLS: frw,
                  from: {
                    connection: o.uid,
                    connectable: o.entity.hotRoughInUid,
                  },
                  to: {
                    connection: o.uid,
                    connectable: o.entity.valve.warmOutputUid,
                  },
                  signed: true,
                  pressurePushMode: PressurePushMode.PSD,
                });
                calculation.outputs[
                  StandardFlowSystemUids.WarmWater
                ].pressureDropKPA = applyPressureLoss({
                  initialPressureKPA: calculation.hotPressureKPA,
                  pressureLossResult,
                }).pressureLossKPA;
              }
              break;
            }
            case BigValveType.RPZD_HOT_COLD: {
              const fr = calculation.coldPeakFlowRate;
              if (fr !== null) {
                const pressureLossResult = getObjectFrictionPressureLossKPA({
                  context: this,
                  object: o,
                  flowLS: fr,
                  from: {
                    connection: o.uid,
                    connectable: o.entity.coldRoughInUid,
                  },
                  to: {
                    connection: o.uid,
                    connectable: o.entity.valve.coldOutputUid,
                  },
                  signed: true,
                  pressurePushMode: PressurePushMode.PSD,
                });
                calculation.outputs[
                  StandardFlowSystemUids.ColdWater
                ].pressureDropKPA = applyPressureLoss({
                  initialPressureKPA: calculation.coldPressureKPA,
                  pressureLossResult,
                }).pressureLossKPA;
              }

              const frh = calculation.hotPeakFlowRate;
              if (frh !== null) {
                const pressureLossResult = getObjectFrictionPressureLossKPA({
                  context: this,
                  object: o,
                  flowLS: frh,
                  from: {
                    connection: o.uid,
                    connectable: o.entity.hotRoughInUid,
                  },
                  to: {
                    connection: o.uid,
                    connectable: o.entity.valve.hotOutputUid,
                  },
                  signed: true,
                  pressurePushMode: PressurePushMode.PSD,
                });
                calculation.outputs[
                  StandardFlowSystemUids.HotWater
                ].pressureDropKPA = applyPressureLoss({
                  initialPressureKPA: calculation.hotPressureKPA,
                  pressureLossResult,
                }).pressureLossKPA;
              }
              break;
            }
          }

          break;
        }
        case EntityType.CONDUIT: {
          switch (o.entity.conduitType) {
            case "pipe": {
              const calc = this.globalStore.getOrCreateCalculation(o.entity);
              if (calc.totalPeakFlowRateLS !== null) {
                const from = calc.flowFrom
                  ? calc.flowFrom
                  : o.entity.endpointUid[0];
                const to = o.entity.endpointUid.find((uid) => uid !== from)!;
                const { pressureLossKPA, terminiPressureLossKPA } =
                  getObjectFrictionPressureLossKPA({
                    context: this,
                    object: o,
                    flowLS: calc.totalPeakFlowRateLS,
                    from: { connection: o.uid, connectable: from },
                    to: { connection: o.uid, connectable: to },
                    signed: true,
                    pressurePushMode: PressurePushMode.PSD,
                  });

                if (
                  pressureLossKPA !== null &&
                  terminiPressureLossKPA != null
                ) {
                  calc.pressureDropKPA = pressureLossKPA;
                  calc.pressureDropExcludingTerminiKPA =
                    pressureLossKPA - terminiPressureLossKPA;
                }
              }

              if (calc.pressureDropKPA !== null) {
                // Get the meter of pipe
                const pipeEntity = fillDefaultConduitFields(this, o.entity);
                const lengthPipe = pipeEntity.lengthM;
                if (lengthPipe !== null) {
                  calc.pressureDropKPAPerMeter =
                    calc.pressureDropKPA / lengthPipe;
                  calc.pressureDropExcludingTerminiKPAPerMeter =
                    Number(calc.pressureDropExcludingTerminiKPA) / lengthPipe;
                }
              }
              break;
            }
            case "cable":
              break;
            case "duct": {
              const calc = this.globalStore.getOrCreateCalculation(o.entity);

              if (calc.totalPeakFlowRateLS !== null) {
                const from = calc.flowFrom
                  ? calc.flowFrom
                  : o.entity.endpointUid[0];
                const to = o.entity.endpointUid.find((uid) => uid !== from)!;
                const { pressureLossKPA, terminiPressureLossKPA } =
                  getObjectFrictionPressureLossKPA({
                    context: this,
                    object: o,
                    flowLS: calc.totalPeakFlowRateLS,
                    from: { connection: o.uid, connectable: from },
                    to: { connection: o.uid, connectable: to },
                    signed: true,
                    pressurePushMode: PressurePushMode.PSD,
                  });

                calc.pressureDropKPA = pressureLossKPA;
                calc.pressureDropExcludingTerminiKPA =
                  Number(pressureLossKPA) - Number(terminiPressureLossKPA);
              }

              if (calc.pressureDropKPA !== null) {
                // Get the meter of duct
                const ductEntity = fillDefaultConduitFields(this, o.entity);
                const lengthDuct = ductEntity.lengthM;
                if (lengthDuct !== null) {
                  calc.pressureDropKPAPerMeter =
                    calc.pressureDropKPA / lengthDuct;
                  calc.pressureDropExcludingTerminiKPAPerMeter =
                    Number(calc.pressureDropExcludingTerminiKPA) / lengthDuct;
                }
              }
              break;
            }
            default:
              assertUnreachable(o.entity);
          }
          break;
        }
        case EntityType.FITTING:
          const calculation = this.globalStore.getOrCreateCalculation(o.entity);
          const connections = this.globalStore.getConnections(o.entity.uid);
          if (connections.length === 2) {
            const p1 = this.globalStore.get(connections[0])!;
            const p2 = this.globalStore.get(connections[1])!;
            if (
              p1 instanceof CoreConduit &&
              p2 instanceof CoreConduit &&
              isPipeEntity(p1.entity) &&
              isPipeEntity(p2.entity)
            ) {
              const pipeCalc1 = this.globalStore.getOrCreateCalculation(
                p1.entity,
              );
              const pipeCalc2 = this.globalStore.getOrCreateCalculation(
                p2.entity,
              );
              if (
                pipeCalc1!.totalPeakFlowRateLS !== null &&
                pipeCalc2!.totalPeakFlowRateLS !== null
              ) {
                calculation.flowRateLS = Math.min(
                  pipeCalc1!.totalPeakFlowRateLS,
                  pipeCalc2!.totalPeakFlowRateLS,
                );
              }
            }
          }
          break;
        case EntityType.DIRECTED_VALVE: {
          const calculation = this.globalStore.getOrCreateCalculation(
            o.entity,
          ) as DirectedValveCalculation;
          const connections = this.globalStore.getConnections(o.entity.uid);

          if (isReturnBalancingValve(o.entity.valve)) {
            // Pressure loss should already have been done.
            break;
          }

          if (hasFixedPressureDrop(o.entity.valve)) {
            calculation.pressureDropKPA = o.entity.valve.pressureDropKPA;
          }

          if (connections.length === 2) {
            const p1 = this.globalStore.get(connections[0])!;
            const p2 = this.globalStore.get(connections[1])!;
            if (
              (isPipeEntity(p1.entity) && isPipeEntity(p2.entity)) ||
              (isDuctEntity(p1.entity) && isDuctEntity(p2.entity))
            ) {
              const pipeCalc1 = this.globalStore.getOrCreateCalculation(
                p1.entity,
              );
              const pipeCalc2 = this.globalStore.getOrCreateCalculation(
                p2.entity,
              );
              if (
                pipeCalc1!.totalPeakFlowRateLS !== null &&
                pipeCalc2!.totalPeakFlowRateLS !== null
              ) {
                calculation.flowRateLS = Math.min(
                  pipeCalc1!.totalPeakFlowRateLS,
                  pipeCalc2!.totalPeakFlowRateLS,
                );

                if (hasFixedPressureDrop(o.entity.valve)) {
                  break;
                }

                const pressureLoss1 = getObjectFrictionPressureLossKPA({
                  context: this,
                  object: o,
                  flowLS: calculation.flowRateLS,
                  from: { connectable: o.uid, connection: connections[0] },
                  to: { connectable: o.uid, connection: connections[1] },
                  signed: true,
                  pressurePushMode: PressurePushMode.PSD,
                });

                const pressureLoss2 = getObjectFrictionPressureLossKPA({
                  context: this,
                  object: o,
                  flowLS: calculation.flowRateLS,
                  from: { connectable: o.uid, connection: connections[1] },
                  to: { connectable: o.uid, connection: connections[0] },
                  signed: true,
                  pressurePushMode: PressurePushMode.PSD,
                });
                if (
                  isPipeEntity(p1.entity) &&
                  isPipeEntity(p2.entity) &&
                  // we need the 'in' check because the headLossMH check below doesn't work for
                  // strictNullCheck = false in backend.
                  ((pressureLoss1.pressureLossKPA === null &&
                    "error" in pressureLoss1 &&
                    pressureLoss1.error ==
                      PressureLossError.PipeExceedsValveDiameter) ||
                    (pressureLoss2.pressureLossKPA === null &&
                      "error" in pressureLoss2 &&
                      pressureLoss2.error ==
                        PressureLossError.PipeExceedsValveDiameter))
                ) {
                  addWarning(
                    this,
                    "PIPE_DIAMETER_EXCEEDS_VALVE_MAX_DIAMETER",
                    [o.entity],
                    {
                      params: {
                        diameterMM: Math.max(
                          p1.entity.conduit.diameterMM!,
                          p2.entity.conduit.diameterMM!,
                        ),
                        maxMM: o.entity.valveSizeMM!,
                        type: o.entity.valve.type,
                      },
                    },
                  );
                }

                const { pressureLossKPA: pressureLossKPA1 } = applyPressureLoss(
                  {
                    initialPressureKPA: calculation.pressureKPA,
                    pressureLossResult: pressureLoss1,
                  },
                );
                const { pressureLossKPA: pressureLossKPA2 } = applyPressureLoss(
                  {
                    initialPressureKPA: calculation.pressureKPA,
                    pressureLossResult: pressureLoss2,
                  },
                );

                if (pressureLossKPA1 === null || pressureLossKPA2 === null) {
                  calculation.pressureDropKPA = null;
                } else {
                  calculation.pressureDropKPA = Math.min(
                    pressureLossKPA1,
                    pressureLossKPA2,
                  );
                }
              }
            }
          }
          break;
        }
        case EntityType.LOAD_NODE:
        case EntityType.SYSTEM_NODE:
        case EntityType.FLOW_SOURCE: {
          const calculation = this.globalStore.getOrCreateCalculation(o.entity);

          const conns = this.globalStore.getConnections(o.entity.uid);

          calculation.flowRateLS = 0;
          if (o.entity.type !== EntityType.LOAD_NODE) {
            for (const conn of conns) {
              const p = this.globalStore.get<CoreConduit>(conn);

              if (p && isPipeEntity(p.entity)) {
                const pCalc = this.globalStore.getOrCreateCalculation(p.entity);

                if (pCalc.totalPeakFlowRateLS) {
                  calculation.flowRateLS += pCalc.totalPeakFlowRateLS;
                }
              }
            }
          } else {
            for (const conn of conns) {
              const p = this.globalStore.get(conn) as CoreConduit;
              if (p && (isPipeEntity(p.entity) || isDuctEntity(p.entity))) {
                const pCalc = this.globalStore.getOrCreateCalculation(p.entity);
                if (
                  pCalc.totalPeakFlowRateLS &&
                  pCalc.totalPeakFlowRateLS > calculation.flowRateLS
                ) {
                  calculation.flowRateLS = pCalc.totalPeakFlowRateLS;
                }
              }
            }
          }

          if (
            o.entity.type === EntityType.SYSTEM_NODE ||
            o.entity.type === EntityType.LOAD_NODE
          ) {
            const sc = this.globalStore.getOrCreateCalculation(o.entity);
            const units = this.getTerminalPsdU({
              connectable: o.entity.uid,
              connection: o.entity.parentUid!,
            });

            let total = zeroPsdCounts();
            let highestLU = 0;
            units.forEach((contextual) => {
              total = addPsdCounts(this, total, contextual);
              highestLU = Math.max(highestLU, contextual.units);

              const gasMJHDiversified = Math.max(
                total.gasHighestMJH,
                total.gasDiversifiedMJH,
              );
              total.gasMJH = total.gasUndiversifiedMJH + gasMJHDiversified;
              total.gasHighestMJH = gasMJHDiversified;
            });

            let newUnits: FinalPsdCountEntry = {
              units: total.units,
              dwellings: total.dwellings,
              continuousFlowLS: total.continuousFlowLS,
              gasMJH: total.gasMJH,
              gasUndiversifiedMJH: total.gasUndiversifiedMJH,
              gasHighestMJH: total.gasHighestMJH,
              gasDiversifiedMJH: total.gasDiversifiedMJH,
              drainageUnits: total.drainageUnits,
              highestLU,
            };

            if (units.length) {
              sc.psdUnits = newUnits;
            } else {
              sc.psdUnits = null;
            }
          }

          if (o.type === EntityType.LOAD_NODE) {
            const { pressureLossKPA } = o.getComponentPressureLossKPA();

            if (pressureLossKPA !== null) {
              const calculation = this.globalStore.getOrCreateCalculation(
                o.entity,
              );

              calculation.pressureDropKPA = pressureLossKPA;

              if (calculation.pressureKPA) {
                calculation.pressureKPA -= calculation.pressureDropKPA;
              }
            }
          }
          break;
        }
        case EntityType.PLANT: {
          if (o.entity.inletUid) {
            const calc = this.globalStore.getOrCreateCalculation(o.entity);
            const inlet = this.globalStore.get(o.entity.inletUid)!
              .entity as SystemNodeEntity;
            const inletCalc = this.globalStore.getOrCreateCalculation(inlet);
            const pressureLossResult = getPlantPressureLoss(
              this,
              o.entity,
              calc,
              inlet.systemUid,
              {},
            );
            calc.pressureDropKPA = applyPressureLoss({
              initialPressureKPA: inletCalc.pressureKPA,
              pressureLossResult,
            }).pressureLossKPA;
          }
          break;
        }
        case EntityType.FIXTURE:
        case EntityType.GAS_APPLIANCE:
        case EntityType.RISER:
        case EntityType.COMPOUND:
        case EntityType.MULTIWAY_VALVE:
        case EntityType.EDGE:
        case EntityType.VERTEX:
        case EntityType.ROOM:
        case EntityType.WALL:
        case EntityType.FENESTRATION:
        case EntityType.ARCHITECTURE_ELEMENT:
        case EntityType.DAMPER:
        case EntityType.AREA_SEGMENT:
          break;
        default:
          assertUnreachable(o.entity);
      }
    }
  }

  @TraceCalculation("Creating warnings")
  createWarnings() {
    const unbalancedGrills: LoadNodeEntity[] = [];

    for (const o of this.networkObjects()) {
      GlobalFDR.focusData([o.entity.uid]);
      switch (o.type) {
        case EntityType.FITTING:
        case EntityType.MULTIWAY_VALVE:
          break;
        case EntityType.CONDUIT: {
          const thisIsDrainage = isDrainage(
            this.drawing.metadata.flowSystems[o.entity.systemUid],
          );

          if (isPipeEntity(o.entity)) {
            // TODO: warnings for drainage pipes
            const calc = this.globalStore.getOrCreateCalculation(o.entity);

            const ca = this.entityStaticPressureKPA.get(
              o.entity.endpointUid[0],
            );
            const cb = this.entityStaticPressureKPA.get(
              o.entity.endpointUid[1],
            );
            const actualMaxPressureKPA = Math.max(ca || 0, cb || 0);
            const actualMinPressureKPA = Math.min(ca || 0, cb || 0);

            const ra = this.entityMaxPressuresKPA.get(o.entity.endpointUid[0]);
            const rb = this.entityMaxPressuresKPA.get(o.entity.endpointUid[1]);
            const actualResidualMaxPressureKPA = Math.max(ra || 0, rb || 0);
            const actualResidualMinPressureKPA = Math.min(ra || 0, rb || 0);

            if (!thisIsDrainage) {
              const filled = fillDefaultConduitFields(this, o.entity);
              const pipeSpec = CoreConduit.getCatalogBySizePage(this, o.entity);

              if (pipeSpec) {
                const maxWorking = parseCatalogNumberExact(
                  pipeSpec.safeWorkingPressureKPA,
                );
                if (maxWorking !== null) {
                  if (actualMaxPressureKPA > maxWorking) {
                    addWarning(this, "MAX_PRESSURE_EXCEEDED_PIPE", [o.entity], {
                      params: {
                        pressureKPA: maxWorking,
                        actualKPA: actualMaxPressureKPA,
                      },
                    });
                  }
                }
              }

              if (
                isMechanical(
                  this.drawing.metadata.flowSystems[o.entity.systemUid],
                ) &&
                (calc.configuration === PipeConfiguration.RETURN_IN ||
                  calc.configuration === PipeConfiguration.RETURN_OUT)
              ) {
                const flowSystem = getFlowSystem(
                  this.drawing.metadata.flowSystems,
                  o.entity.systemUid,
                )!;
              }
            }
            if (
              !calc ||
              (isGas(this.drawing.metadata.flowSystems[o.entity.systemUid]) &&
                calc.PSDFlowRateLS === null &&
                calc.optimalInnerPipeDiameterMM === null)
            ) {
              addWarning(
                this,
                "PRESSURE_AT_UPSTREAM_NEEDS_HIGHER_THAN_DOWNSTREAM",
                [o.entity],
              );
            }

            if (
              this.drawing.metadata.calculationParams.psdMethod ===
                SupportedPsdStandards.bs806 &&
              calc.psdUnits?.units! > 5000 &&
              calc.psdUnits?.units! < 10001
            ) {
              addWarning(this, "EXTRAPOLATED", [o.entity]);
            }

            if (
              this.drawing.metadata.calculationParams.psdMethod ===
                SupportedPsdStandards.as35002021LoadingUnits &&
              calc.psdUnits?.units! > 60 &&
              calc.psdUnits?.units! < 5001
            ) {
              addWarning(this, "EXTRAPOLATED", [o.entity]);
            }

            if (
              this.drawing.metadata.calculationParams.psdMethod ===
                SupportedPsdStandards.barriesBookLoadingUnits &&
              calc.psdUnits?.units! > 4000 &&
              calc.psdUnits?.units! < 10001
            ) {
              addWarning(this, "EXTRAPOLATED", [o.entity]);
            }

            if (
              isGermanStandard(
                this.drawing.metadata.calculationParams.psdMethod,
              ) &&
              calc.psdUnits?.units! > 500
            ) {
              addWarning(this, "EXTRAPOLATED", [o.entity]);
            }

            if (
              calc.noFlowAvailableReason === NoFlowAvailableReason.NO_SOURCE &&
              !(
                !calc.flowFrom && (o as CoreConduit).validateConnectionPoints()
              ) &&
              !isClosedSystem(
                this.drawing.metadata.flowSystems[o.entity.systemUid],
              ) &&
              !DrainageCalculations.isPipeVent(this, o.entity)
            ) {
              addWarning(
                this,
                "PIPE_NOT_CONNECTED_TO_FLOW_SOURCE",
                [o.entity],
                {
                  mode: thisIsDrainage ? "drainage" : null,
                },
              );
            }

            const thisLevel = this.globalStore.levelOfEntity.get(o.entity.uid);
            if (thisLevel) {
              const heightAboveToVertexM = getLevelHeightFloorToVertexM(
                this.drawing.levels,
                thisLevel,
              );

              if (
                heightAboveToVertexM &&
                heightAboveToVertexM < o.entity.heightAboveFloorM
              ) {
                addWarning(this, "PIPE_HIGHER_THAN_FLOOR_LEVEL", [o.entity], {
                  mode: thisIsDrainage ? "drainage" : null,
                });
              }
            }

            // if (calc.rawReturnFlowRateLS === null && isPipeReturn(calc)) {
            // 	addWarning(o.entity.uid, calc, Warning.REMOVE_PLANT_FROM_FLOW_AND_RETURN_PIPEWORK, thisIsDrainage ? "drainage" : null);
            // }

            if (
              calc.psdUnits === null &&
              calc.optimalInnerPipeDiameterMM === null &&
              calc.configuration === PipeConfiguration.RING_MAIN &&
              calc.fireFlowRateLS === null
            ) {
              addWarning(
                this,
                "ISOLATION_VALVES_REQUIRED_ON_RING_MAIN",
                [o.entity],
                {
                  mode: thisIsDrainage ? "drainage" : null,
                },
              );
            }

            if (
              calc.psdUnits === null &&
              calc.optimalInnerPipeDiameterMM === null &&
              !isPipeReturn(calc) &&
              calc.fireFlowRateLS === null
            ) {
              addWarning(
                this,
                "ARRANGEMENT_OF_PIPEWORK_NOT_SUITABLE_FOR_CALCULATION",
                [o.entity],
                {
                  mode: thisIsDrainage ? "drainage" : null,
                },
              );
            }
          }

          if (o.physicallyInversed) {
            addWarning(this, "BEND_TOO_TIGHT", [o.entity], {
              mode: getFlowSystemLayouts(
                this.drawing.metadata.flowSystems[o.entity.systemUid],
              ).layouts,
              replaceSameWarnings: true,
            });
          }

          break;
        }
        case EntityType.FLOW_SOURCE:
          break;
        case EntityType.SYSTEM_NODE:
          break;
        case EntityType.BIG_VALVE: {
          let calc = this.globalStore.getOrCreateCalculation(o.entity);
          let maxFlowRateLS = null;
          let manufacturer = "generic";
          switch (o.entity.valve.type) {
            case BigValveType.TMV:
              manufacturer =
                this.drawing.metadata.catalog.mixingValves.find(
                  (material: SelectedMaterialManufacturer) =>
                    material.uid === "tmv",
                )?.manufacturer || "generic";
              maxFlowRateLS = parseCatalogNumberExact(
                this.catalog.mixingValves.tmv.maxFlowRateLS[manufacturer],
              );
              break;
            case BigValveType.TEMPERING:
              manufacturer =
                this.drawing.metadata.catalog.mixingValves.find(
                  (material: SelectedMaterialManufacturer) =>
                    material.uid === "temperingValve",
                )?.manufacturer || "generic";
              maxFlowRateLS = parseCatalogNumberExact(
                this.catalog.mixingValves.temperingValve.maxFlowRateLS[
                  manufacturer
                ],
              );
              break;
            case BigValveType.RPZD_HOT_COLD:
              break;
            default:
              assertUnreachable(o.entity.valve);
          }
          if (maxFlowRateLS !== null) {
            if (
              (calc.hotPeakFlowRate || 0) > maxFlowRateLS ||
              (calc.coldPeakFlowRate || 0) > maxFlowRateLS
            ) {
              addWarning(this, "MAX_FLOW_RATE_EXCEEDED", [o.entity], {
                params: { flowRateLS: maxFlowRateLS },
              });
            }
          }
          break;
        }
        case EntityType.FIXTURE: {
          const e = fillFixtureFields(this, o.entity);
          const calculation = this.globalStore.getOrCreateCalculation(o.entity);

          for (const suid of e.roughInsInOrder) {
            if (calculation.inlets[suid].pressureKPA === null) {
              if (isPressure(this.drawing.metadata.flowSystems[suid])) {
                if (!calculation.warnings) {
                  calculation.warnings = [];
                }
              }
            } else if (
              (calculation.inlets[suid].pressureKPA || 0) <
              e.roughIns[suid].minPressureKPA!
            ) {
              const system = getFlowSystem(this.drawing, suid)!;
              addWarning(this, "NOT_ENOUGH_PRESSURE", [o.entity], {
                params: {
                  systemName: system.name,
                  requiredKPA: e.roughIns[suid].minPressureKPA!,
                  entity: "fixture",
                },
              });
            } else if (
              (calculation.inlets[suid].staticPressureKPA || 0) >
              e.roughIns[suid].maxPressureKPA!
            ) {
              const system = getFlowSystem(this.drawing, suid)!;

              addWarning(this, "MAX_PRESSURE_OVERLOAD", [o.entity], {
                params: {
                  systemName: system.name,
                  maxKPA: e.roughIns[suid].maxPressureKPA!,
                },
              });
            }
          }
          const connectionErrors = (
            o as CoreFixture
          ).validateConnectionPoints();
          if (connectionErrors !== true) {
            addWarning(
              this,
              "CONNECT_THE_FIXTURE_TO_A_FLOW_SYSTEM",
              [o.entity],
              {
                mode: connectionErrors,
              },
            );
          }
          if (calculation.warnings && !calculation.warnings.length)
            calculation.warnings = null;
          break;
        }
        case EntityType.DIRECTED_VALVE: {
          const calculation = this.globalStore.getOrCreateCalculation(o.entity);
          switch (o.entity.valve.type) {
            case ValveType.CHECK_VALVE:
            case ValveType.ISOLATION_VALVE:
            case ValveType.WATER_METER:
            case ValveType.CSV:
            case ValveType.STRAINER:
            case ValveType.RV:
            case ValveType.RPZD_SINGLE:
            case ValveType.RPZD_DOUBLE_SHARED:
            case ValveType.RPZD_DOUBLE_ISOLATED:
            case ValveType.BALANCING:
            case ValveType.LSV:
            case ValveType.PICV:
            case ValveType.TRV:
            case ValveType.GAS_REGULATOR:
            case ValveType.FILTER:
            case ValveType.FLOOR_WASTE:
            case ValveType.INSPECTION_OPENING:
            case ValveType.REFLUX_VALVE:
            case ValveType.CUSTOM_VALVE:
            case ValveType.SMOKE_DAMPER:
            case ValveType.FIRE_DAMPER:
            case ValveType.VOLUME_CONTROL_DAMPER:
            case ValveType.ATTENUATOR:
            case ValveType.FAN:
              break;
            case ValveType.PRV_SINGLE:
            case ValveType.PRV_DOUBLE:
            case ValveType.PRV_TRIPLE: {
              const manufacturer =
                this.drawing.metadata.catalog.prv[0]?.manufacturer || "generic";
              const inPressure = calculation.pressureKPA;

              if (inPressure !== null && calculation.sizeMM !== null) {
                const maxInletPressure = parseCatalogNumberExact(
                  lowerBoundTable(
                    this.catalog.prv.size[manufacturer],
                    calculation.sizeMM,
                    (prv) => Number(prv.diameterNominalMM),
                  )!.maxInletPressureKPA,
                );

                if (
                  maxInletPressure !== null &&
                  inPressure > maxInletPressure
                ) {
                  addWarning(this, "MAX_PRESSURE_EXCEEDED_PIPE", [o.entity], {
                    params: {
                      pressureKPA: maxInletPressure,
                      actualKPA: inPressure,
                    },
                  });
                }
              }

              if (
                inPressure !== null &&
                o.entity.valve.targetPressureKPA !== null &&
                calculation.sizeMM !== null
              ) {
                const ratio = parseCatalogNumberExact(
                  lowerBoundTable(
                    this.catalog.prv.size[manufacturer],
                    calculation.sizeMM,
                    (prv) => Number(prv.diameterNominalMM),
                  )!.maxPressureDropRatio,
                );
                if (
                  ratio !== null &&
                  inPressure > o.entity.valve.targetPressureKPA * ratio
                ) {
                  addWarning(
                    this,
                    "TARGET_PRESSURE_IS_LESS_THAN_HALF_OF_THE_PRV_INLET_PRESSURE",
                    [o.entity],
                    {
                      params: {
                        pressureKPA: inPressure,
                        ratio,
                        targetKPA: o.entity.valve.targetPressureKPA!,
                      },
                    },
                  );
                }
              }
              break;
            }
            default:
              assertUnreachable(o.entity.valve);
          }
          break;
        }
        case EntityType.RISER:
          break;
        case EntityType.PLANT:
          const calculation = this.globalStore.getOrCreateCalculation(o.entity);
          if (!(o as CorePlant).showFlowSourceConnectedWarning()) {
            addWarning(this, "FLOW_SYSTEM_NOT_CONNECTED_TO_PLANT", [o.entity]);
          }
          const filled = fillPlantDefaults(this, o.entity) as typeof o.entity;
          if (isPlantPump(filled)) {
            if (
              calculation.capacityL !== null &&
              calculation.maxCapacityAvailableL !== null &&
              calculation.capacityL > calculation.maxCapacityAvailableL
            ) {
              const manRecord = getManufacturerRecord(filled, this.catalog);

              addWarning(this, "CAPACITY_EXCEEDS_MAX_AVAILABLE", [o.entity], {
                params: {
                  currentL: calculation.capacityL!,
                  maxL: calculation.maxCapacityAvailableL!,
                  type:
                    (manRecord &&
                      manRecord.uid !== "generic" &&
                      (calculation.modelReference
                        ? ` reference ${calculation.modelReference} of `
                        : " ") + manRecord.name) ||
                    "",
                },
              });
            }

            if (filled.plant.manufacturer !== "generic") {
              if (
                calculation.pumpDutyKPA !== null &&
                calculation.model === null
              ) {
                const [du, duty] = convertMeasurementSystem(
                  this.drawing.metadata.units,
                  Units.KiloPascals,
                  calculation.pumpDutyKPA,
                  Precision.DISPLAY,
                );

                const [fu, flowRate] = convertMeasurementSystem(
                  this.drawing.metadata.units,
                  Units.LitersPerSecond,
                  calculation.pumpFlowRateLS,
                  Precision.DISPLAY,
                );

                const manufacturerRecord = getManufacturerRecord(
                  o.entity,
                  this.catalog,
                );
              }
            }
          }
          if (filled.plant.type === PlantType.FILTER) {
            // min and max pressure requirements
            if (
              calculation.minPressureKPA !== null ||
              calculation.maxPressureKPA !== null
            ) {
              const inlet = this.globalStore.get(
                filled.inletUid!,
              ) as CoreSystemNode;

              if (!inlet) continue;

              const inletCalc = this.globalStore.getOrCreateCalculation(
                inlet.entity,
              );

              if (inletCalc.pressureKPA === null) continue;

              if (inletCalc.pressureKPA < calculation.minPressureKPA!) {
                const system = getFlowSystem(
                  this.drawing,
                  filled.inletSystemUid!,
                )!;
                addWarning(this, "NOT_ENOUGH_PRESSURE", [o.entity], {
                  params: {
                    systemName: system.name,
                    requiredKPA: calculation.minPressureKPA!,
                    entity: "plant",
                  },
                });
              }

              if (inletCalc.pressureKPA > calculation.maxPressureKPA!) {
                addWarning(this, "MAX_PRESSURE_EXCEEDED", [o.entity], {
                  params: {
                    pressureKPA: inletCalc.pressureKPA,
                    targetKPA: calculation.maxPressureKPA!,
                  },
                });
              }
            }
          }
          if (filled.plant.type === PlantType.RO) {
            if (filled.plant.manufacturer === "southland") {
              addWarning(this, "SOUTHLAND_RO_PLANT", [filled]);
            }
          }
          break;
        case EntityType.LOAD_NODE: {
          const filled = fillDefaultLoadNodeFields(this, o.entity);
          const calc = this.globalStore.getOrCreateCalculation(filled);
          if (!(o as CoreLoadNode).validateConnectionPoints()) {
            addWarning(this, "NOT_CONNECTED_TO_FLOW_SYSTEM", [o.entity]);
          }
          // Drainage system/Ventilation doesn't have pressure warning
          if (
            filled.systemUidOption &&
            (isDrainage(
              this.drawing.metadata.flowSystems[filled.systemUidOption],
            ) ||
              isVentilation(
                this.drawing.metadata.flowSystems[filled.systemUidOption],
              ))
          ) {
            break;
          }

          if (
            calc.pressureKPA !== null &&
            filled.maxPressureKPA !== null &&
            calc.pressureKPA > filled.maxPressureKPA!
          ) {
            addWarning(this, "MAX_PRESSURE_EXCEEDED_NODE", [o.entity], {
              params: {
                pressureKPA: calc.pressureKPA,
                targetKPA: filled.maxPressureKPA,
              },
            });
          } else if (
            calc.pressureKPA !== null &&
            filled.minPressureKPA !== null &&
            calc.pressureKPA < filled.minPressureKPA &&
            !isGas(this.drawing.metadata.flowSystems[filled.systemUidOption!])
          ) {
            const system = getFlowSystem(
              this.drawing,
              filled.systemUidOption!,
            )!;

            addWarning(this, "NOT_ENOUGH_PRESSURE", [o.entity], {
              params: {
                systemName: system.name,
                requiredKPA: filled.minPressureKPA,
                entity: "node",
              },
            });
          } else if ((calc.staticPressureKPA || 0) > filled.maxPressureKPA!) {
            const system = getFlowSystem(this.drawing, filled.systemUidOption)!;

            addWarning(this, "MAX_PRESSURE_OVERLOAD", [o.entity], {
              params: {
                systemName: system.name,
                maxKPA: filled.maxPressureKPA!,
              },
            });
          }

          if (filled.node.type === NodeType.VENTILATION) {
            const recordUid = this.ventLoadNodeToVentRecord.get(filled.uid);
            if (recordUid) {
              const record = this.ventRecords.get(recordUid);
              if (record && record.hasMoreThanOneDamper) {
                let indexPressureKPA: number | null = null;

                switch (record.parentType) {
                  case "ahu":
                    const sCalc = this.globalStore.getOrCreateCalculation(
                      record.parent,
                    );
                    indexPressureKPA =
                      record.role === "vent-supply"
                        ? sCalc.supplyIndexCircuitPressureDropKPA
                        : sCalc.extractIndexCircuitPressureDropKPA;
                    break;
                  case "fan":
                    const dCalc = this.globalStore.getOrCreateCalculation(
                      record.parent,
                    );
                    indexPressureKPA = dCalc.interiorPressureDropKPA;
                    break;
                  default:
                    assertUnreachable(record);
                }

                if (
                  calc.flowRateLS &&
                  calc.flowRateLS > 0 &&
                  Math.abs(
                    Math.abs(calc.pressureKPA || 0) -
                      Math.abs(indexPressureKPA ?? 0),
                  ) > PRESSURE_DIFF_REQ_FOR_DAMPER
                ) {
                  unbalancedGrills.push(o.entity);
                }
              }
            }
          }
          break;
        }
        case EntityType.GAS_APPLIANCE:
          // TODO: Gas applicance warnings - like not enough gas pressure.
          const calc = this.globalStore.getOrCreateCalculation(o.entity);
          if (!(o as CoreGasAppliance).validateConnectionPoints()) {
            addWarning(this, "NOT_CONNECTED_TO_FLOW_SYSTEM", [o.entity]);
          }
          break;
        case EntityType.COMPOUND:
        case EntityType.EDGE:
        case EntityType.VERTEX:
        case EntityType.ROOM:
        case EntityType.WALL:
        case EntityType.FENESTRATION:
        case EntityType.ARCHITECTURE_ELEMENT:
        case EntityType.DAMPER:
        case EntityType.AREA_SEGMENT:
          break;
        default:
          assertUnreachable(o);
      }
    }

    // Add balance grill warning for all unbalanced grills
    if (unbalancedGrills.length > 0) {
      addWarning(this, "UNBALANCED_GRILLS", unbalancedGrills, {
        mode: "ventilation",
        params: {
          grills: unbalancedGrills,
        },
      });
    }
  }

  serializeDirectedEdge(
    edge: Edge<FlowNode | undefined, FlowEdge | undefined>,
    dst: FlowNode,
  ) {
    return dst.connection + " " + dst.connectable + " " + edge.uid;
  }

  bridgesInTopsortOrder: [
    Edge<FlowNode | undefined, FlowEdge | undefined>,
    FlowNode,
  ][] = [];
  bridgeSelfProfile: Map<string, PsdProfile> = new Map();
  bridgeChildren: Map<
    string,
    [Edge<FlowNode | undefined, FlowEdge | undefined>, FlowNode][]
  > = new Map();

  edgeCanCarryFlow(edge: Edge<FlowNode, FlowEdge>) {
    if (edge.value.type === EdgeType.CONDUIT) {
      const pipe = this.globalStore.get(edge.value.uid);
      if (
        pipe &&
        isPipeEntity(pipe.entity) && //
        isSewer(this.drawing.metadata.flowSystems[pipe.entity.systemUid])
      ) {
        if (pipe.entity.conduit.network === "vents") {
          return false;
        }
      }
    }
    return true;
  }

  // While this could have been a top down DP, the recursion is too much for
  // webworker's stack.
  @TraceCalculation(
    "Precomputing PSDs for each 'bridge' in the network",
    (b, d) => [b.value?.uid || "", d.connection, d.connectable],
  )
  precomputePsdAfterBridge(
    bridge: Edge<FlowNode | undefined, FlowEdge | undefined>,
    dst: FlowNode,
  ): void {
    const queue: Array<
      [Edge<FlowNode | undefined, FlowEdge | undefined>, FlowNode]
    > = [[bridge, dst]];

    const visitedEdges = new Set<string>();

    while (queue.length > 0) {
      const [bridge, dst] = queue.pop()!;
      GlobalFDR.focusData([
        bridge.value?.uid || "",
        dst.connection,
        dst.connectable,
      ]);
      this.bridgesInTopsortOrder.push([bridge, dst]);
      const key = this.serializeDirectedEdge(bridge, dst);
      const visited = new Set<string>();

      visitedEdges.add(bridge.uid);
      const childBridges = this.bridgeChildren.get(key) || [];
      this.bridgeChildren.set(key, childBridges);

      const retVal = new PsdProfile();
      this.flowGraph.dfs(
        dst,
        (node) => {
          const res = this.getTerminalPsdU(node);
          for (var i = 0; i < res.length; i++) {
            insertPsdProfile(retVal, res[i]);
          }
        },
        undefined,
        (edge) => {
          // Edge case: Do not push flow, including sewer flow, through vents.
          if (!this.edgeCanCarryFlow(edge)) {
            return true;
          }

          if (this.allBridges.has(edge.uid)) {
            childBridges.push([edge, edge.to]);
            queue.push([edge, edge.to]);
            return true;
          }
        },
        undefined,
        visited,
        visitedEdges,
        true,
        false,
      );

      this.bridgeSelfProfile.set(key, retVal);

      // visitedEdges.delete(bridge.uid);
    }

    for (let i = this.bridgesInTopsortOrder.length - 1; i >= 0; i--) {
      const bridge = this.bridgesInTopsortOrder[i];
      GlobalFDR.focusData([bridge[0].value?.uid || ""]);
      const key = this.serializeDirectedEdge(bridge[0], bridge[1]);
      const childBridges = this.bridgeChildren.get(key) || [];
      const selfProfile = this.bridgeSelfProfile.get(key)!;
      const retVal = new PsdProfile();
      for (const val of selfProfile.values()) {
        insertPsdProfile(retVal, val);
      }
      for (let j = 0; j < childBridges.length; j++) {
        const childKey = this.serializeDirectedEdge(
          childBridges[j][0],
          childBridges[j][1],
        );
        const childProfile = this.psdAfterBridgeCache.get(childKey)!;
        for (const val of childProfile.values()) {
          insertPsdProfile(retVal, val);
        }
      }
      this.psdAfterBridgeCache.set(key, retVal);
    }
  }

  // While this could have been a top down DP, the recursion is too much for
  // webworker's stack.
  getPsdAfterBridge(
    bridge: Edge<FlowNode | undefined, FlowEdge | undefined>,
    dst: FlowNode,
  ): PsdProfile {
    const key = this.serializeDirectedEdge(bridge, dst);

    if (!this.psdAfterBridgeCache.has(key)) {
      return new PsdProfile();
    }

    return this.psdAfterBridgeCache.get(key)!;
  }

  @TraceCalculation("Calculating PSD units that depend on this edge", (e) => [
    e.uid,
  ])
  getExcludedPsdUs(excludedEdge: FlowEdge): PsdProfile | false {
    const key = stringify(excludedEdge);
    if (this.parentBridgeOfWetEdge.has(key)) {
      if (this.allBridges.has(key)) {
        const visitedEdges = new Set<string>();
        return this.getPsdAfterBridge(
          this.allBridges.get(key)!,
          this.secondWet.get(key)!,
        );
      }

      const start = this.parentBridgeOfWetEdge.get(key)!;
      const visitedEdges = new Set<string>([start.uid, key]);

      const inThisGroup = new PsdProfile();
      const seenBridges = new Set<string>();

      this.flowGraph.dfs(
        start.to!,
        (n) => {
          const units = this.getTerminalPsdU(n);
          for (var i = 0; i < units.length; i++) {
            insertPsdProfile(inThisGroup, units[i]);
          }
        },
        undefined,
        (e) => {
          // Edge case: Do not push flow, including sewer flow, through vents.
          if (e.value.type === EdgeType.CONDUIT) {
            const pipe = this.globalStore.get(e.value.uid);
            if (
              pipe &&
              isPipeEntity(pipe.entity) &&
              isSewer(this.drawing.metadata.flowSystems[pipe.entity.systemUid])
            ) {
              if (pipe.entity.conduit.network === "vents") {
                return true;
              }
            }
          }

          const pc = this.globalStore.getOrCreateCalculation(
            (this.globalStore.get(e.value.uid) as CoreConduit).entity,
          );
          // Some pipes may have their flow directions fixed in an earlier step (such as return systems)
          if (pc.flowFrom) {
            if (e.from.connectable !== pc.flowFrom) {
              visitedEdges.delete(e.uid);
              return true;
            }
          }

          if (this.allBridges.has(e.uid)) {
            seenBridges.add(e.uid);
            return true;
          }
        },
        undefined,
        undefined,
        visitedEdges,
        true,
        false,
      );

      const excludedProfile = new PsdProfile();
      const existing = this.psdProfileWithinGroup.get(start.uid)!;
      for (const f of existing.values()) {
        insertPsdProfile(excludedProfile, f);
      }
      subtractPsdProfiles(excludedProfile, inThisGroup);

      for (const child of this.childBridges.get(start.uid)!) {
        if (!seenBridges.has(child.uid)) {
          const cpsd = this.psdAfterBridgeCache.get(
            this.serializeDirectedEdge(child, child.to),
          )!;
          for (const f of cpsd.values()) {
            insertPsdProfile(excludedProfile, f);
          }
        }
      }
      return excludedProfile;
    } else {
      // there was no flow to this edge anyway.
      return false;
    }
  }

  // This will be correct, with the bridge group cache optimization, as long as the excluded nodes and excluded
  // edges are in the same bi-connected component. If not, then this function will use the cache to potentially fetch
  // results from groups that would have changed if the nodes or edges were excluded.
  @TraceCalculation(
    "Calculating PSD units downstream of this edge",
    (s, _1, _2) => s.map((s) => [s.connection, s.connectable]).flat(),
  )
  getDownstreamPsdUs(
    starts: FlowNode[],
    excludedNodes: string[] = [],
    excludedEdges: string[] = [],
  ): PsdProfile {
    const seen = new Set<string>(excludedNodes);
    const seenEdges = new Set<string>(excludedEdges);

    const psdUs = new PsdProfile();

    for (const r of starts) {
      GlobalFDR.focusData([r.connection, r.connectable]);
      this.flowGraph.dfs(
        r,
        (n) => {
          const thisTerminal = this.getTerminalPsdU(n);
          for (var i = 0; i < thisTerminal.length; i++) {
            insertPsdProfile(psdUs, thisTerminal[i]);
          }
        },
        undefined,
        (e) => {
          if (!this.edgeCanCarryFlow(e)) {
            return true;
          }

          if (e.value.type === EdgeType.CONDUIT) {
            const pc = this.globalStore.getOrCreateCalculation(
              (this.globalStore.get(e.value.uid) as CoreConduit).entity,
            );

            // Some pipes may have their flow directions fixed in an earlier step (such as return systems)
            if (pc.flowFrom) {
              if (e.from.connectable !== pc.flowFrom) {
                //seenEdges.delete(e.uid);
                return true;
              }
            }
          }

          if (this.allBridges.has(e.uid)) {
            const result = this.getPsdAfterBridge(e, e.to);
            for (const r of result.values()) {
              insertPsdProfile(psdUs, r);
            }
            return true;
          }
        },
        undefined,
        seen,
        seenEdges,
      );
    }

    return psdUs;
  }

  sizeMixingValveForFlowRate(fr: number, manufacturer: string): number {
    switch (manufacturer) {
      case "galvin":
        if (fr > 0.51) {
          return 20;
        }

        return 15;
      case "enware":
        if (fr > 0.65) {
          return 2500;
        }

        return 1500;
      default:
        if (fr >= 0.49) {
          return 25;
        }

        return 15;
    }
  }

  getIndexNodePath() {
    this.sourceIndexNodeCache = this.getIndexNodePathFromSources();

    for (let flowNodes of [
      ...this.sourceIndexNodeCache.values(),
      ...this.pumpIndexNodeCache.values(),
    ]) {
      for (let flowNode of flowNodes) {
        if (flowNode.value) {
          let uid = flowNode.value.uid;
          const pipeD = this.getCalcByPipeId(uid);
          if (pipeD) {
            pipeD.pCalc.isIndexNodePath = true;
          }
        }
      }
    }
  }

  getIndexNodePathFromSources(): Map<FlowNode, IndexPath[]> {
    let adjList = this.flowGraph.adjacencyList;

    let sourceKey = this.flowGraph.sn(FLOW_SOURCE_ROOT_NODE);
    let sourceNodes = adjList.get(sourceKey);

    if (!sourceNodes) {
      console.warn("Empty graph?");
      return new Map();
    }

    this.pumpIndexNodeCache;
    let sourceIndexNodeCache: Map<FlowNode, IndexPath[]> = new Map();
    for (let sourceNode of sourceNodes) {
      let flowSource = this.globalStore.get<CoreFlowSource>(
        sourceNode.value.uid,
      );

      this.findIndexNodePathFromSource(
        flowSource,
        sourceNode.to,
        sourceIndexNodeCache,
      );
    }
    return sourceIndexNodeCache;
  }

  findIndexNodePathFromSource(
    flowSource: CoreFlowSource,
    sourceNode: FlowNode,
    sourceIndexNodeCache: Map<FlowNode, IndexPath[]>,
  ) {
    // Prepare for the trackback
    let disadvantageNode: FlowNode | null = null;
    let disadvantageNodePressure: number | null = null;
    let visitNodeFunc = (node: FlowNode): boolean | void => {
      let checkPressure = (flowSource: CoreFlowSource, node: FlowNode) => {
        let uid = node.connectable;
        let obj = this.globalStore.get(uid);

        if (obj) {
          switch (obj.type) {
            case EntityType.LOAD_NODE:
              let loadCalc = this.globalStore.getOrCreateCalculation(
                obj.entity,
              );
              if (!disadvantageNode && loadCalc.pressureKPA !== null) {
                disadvantageNode = node;
                disadvantageNodePressure = loadCalc.pressureKPA;
              } else {
                if (
                  loadCalc.pressureKPA &&
                  loadCalc.pressureKPA < disadvantageNodePressure!
                ) {
                  disadvantageNode = node;
                  disadvantageNodePressure = loadCalc.pressureKPA;
                }
              }
              break;
            case EntityType.SYSTEM_NODE:
              // Check if it's a fixture
              let sysNodeCalc = this.globalStore.getOrCreateCalculation(
                obj.entity,
              );
              let parentCalc = this.globalStore.get(obj.entity.parentUid || "");
              if (!parentCalc) return;
              if (parentCalc.type !== EntityType.FIXTURE) return;

              if (!disadvantageNode && sysNodeCalc.pressureKPA !== null) {
                disadvantageNode = node;
                disadvantageNodePressure = sysNodeCalc.pressureKPA;
              } else {
                if (
                  sysNodeCalc.pressureKPA &&
                  sysNodeCalc.pressureKPA < disadvantageNodePressure!
                ) {
                  disadvantageNode = node;
                  disadvantageNodePressure = sysNodeCalc.pressureKPA;
                }
              }
              break;
          }
        }
      };

      checkPressure(flowSource, node);
    };

    let visitEdgeFunc = (
      edge: Edge<FlowNode, FlowEdge>,
    ): boolean | VISIT_RESULT_WRONG_WAY | void => {
      prevEntity.set(this.flowGraph.sn(edge.to), {
        node: edge.from,
        value: edge.value,
      });
    };

    const prevEntity = new Map<string, IndexPath>();
    this.flowGraph?.dfs(sourceNode, visitNodeFunc, undefined, visitEdgeFunc);

    // Trace back
    if (disadvantageNode) {
      const path: IndexPath[] = trackBackPath(
        disadvantageNode,
        prevEntity,
        this,
      );

      sourceIndexNodeCache.set(disadvantageNode, path);
    }
  }
}
