import mixpanel from "mixpanel-browser";
import { setTracker } from "../../../../common/src/lib/analytics";
import { FeatureAccess } from "../../../../common/src/models/FeatureAccess";
import { orgTypeToLabel } from "../../../../common/src/models/Organization";
import {
  User,
  accessLevelEnumToLabel,
} from "../../../../common/src/models/User";
import { trackEvent } from "../mixpanel";

export class Mixpanel {
  static init(profile: User, featureAccess: FeatureAccess) {
    const org = profile?.organization;
    mixpanel.identify(profile?.username ?? "");
    mixpanel.people.set({
      $email: profile?.email,
      $name: profile?.name,
      username: profile?.username,
      accessLevel:
        profile?.accessLevel !== undefined
          ? accessLevelEnumToLabel(profile.accessLevel)
          : undefined,
      orgId: org?.id,
      orgName: org?.name,
      subscriptionStatus: org?.subscriptionStatus,
      orgType: org?.type !== undefined ? orgTypeToLabel(org.type) : undefined,
      featureAccess,
      enforceMFA: org?.enforceMFA,
    });
    if (org?.id) {
      mixpanel.set_group("company", org.id);
    }

    // Allows common to track events
    setTracker({
      trackEvent: trackEvent,
    });
  }
}
