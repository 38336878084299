import Flatten from "@flatten-js/core";
import {
  Coord,
  Coord3D,
  coordDot,
  coordMagnitude,
  coordNormalize,
} from "../../lib/coord";
import { GlobalStore } from "../../lib/globalstore/global-store";
import {
  canonizeAngleRad,
  is45AngleRad,
  isClockWise,
  isRightAngleRad,
  isStraightRad,
  pointInsidePolygon,
} from "../../lib/mathUtils/mathutils";
import {
  EPS,
  assertUnreachable,
  assertUnreachableAggressive,
  interpolateTable,
  parseCatalogNumberExact,
  upperBoundTable,
} from "../../lib/utils";
import { FilterCalculations } from "../calculations/filters/filter-calculations";
import {
  fittingFrictionLossMH,
  getFluidDensityOfSystem,
  head2kpa,
} from "../calculations/pressure-drops";
import {
  CoreContext,
  CostBreakdown,
  PressureLossResult,
  PressureLossSuccess,
  PressurePushMode,
} from "../calculations/types";
import { Catalog } from "../catalog/types";
import {
  StandardFlowSystemUids,
  isDrainage,
  isHeatingPlantSystem,
  isVentilation,
} from "../config";
import {
  default as CorePlant,
  DEFAULT_SYSTEM_NODE_SIZE,
  InletsOutletsSpec,
  RECIRCULATION_SYSTEM_NODE_SIZE,
} from "../coreObjects/corePlant";
import { isCalculated } from "../document/calculations-objects";
import ConduitCalculation, {
  ConduitLiveCalculation,
} from "../document/calculations-objects/conduit-calculations";
import FittingCalculation from "../document/calculations-objects/fitting-calculation";
import PlantCalculation from "../document/calculations-objects/plant-calculation";
import {
  DrawingState,
  SelectedMaterialManufacturer,
} from "../document/drawing";
import BigValveEntity from "../document/entities/big-valve/big-valve-entity";
import {
  ConnectableEntityConcrete,
  DrawableEntityConcrete,
  EdgeLikeEntity,
  isConnectableEntity,
} from "../document/entities/concrete-entity";
import ConduitEntity, {
  isDuctEntity,
  isPipeEntity,
} from "../document/entities/conduit-entity";
import DirectedValveEntity from "../document/entities/directed-valves/directed-valve-entity";
import { ValveType } from "../document/entities/directed-valves/valve-types";
import { EdgeEntity } from "../document/entities/edge-entity";
import { fillFixtureFields } from "../document/entities/fixtures/fixture-entity";
import {
  NodeType,
  VentilationNode,
  fillDefaultLoadNodeFields,
} from "../document/entities/load-node-entity";
import { fillPlantDefaults } from "../document/entities/plants/plant-defaults";
import PlantEntity, {
  FilterPlantEntity,
} from "../document/entities/plants/plant-entity";
import {
  PlantType,
  PressureMethod,
} from "../document/entities/plants/plant-types";
import {
  isDualSystemNodePlant,
  isMultiOutlets,
  isPlantPump,
} from "../document/entities/plants/utils";
import { EntityType } from "../document/entities/types";
import {
  WallEntity,
  getInternalWallWidth,
} from "../document/entities/wall-entity";
import {
  AUX_SYSTEM_NETWORKS,
  DEFAULT_VERTICAL_SYSTEM_NETWORKS,
  FlowSystem,
  FlowSystemNetworkKeyMap,
  FlowSystemRole,
  FlowSystemType,
  FlowSystemTypeMap,
  HORIZONTAL_SYSTEM_NETWORKS_BY_PRIORITY,
  NetworkType,
  flowSystemHasVent,
} from "../document/flow-systems";
import { getFlowSystem } from "../document/utils";
import { ICorePolygon } from "./core-traits/corePolygon";
import CoreConduit from "./coreConduit";
import CoreEdge from "./coreEdge";
import CoreFen from "./coreFenestration";
import CoreSystemNode from "./coreSystemNode";
import CoreVertex from "./coreVertex";
import CoreWall from "./coreWall";

export function getRpzdManufacturer(context: CoreContext): string {
  return (
    context.drawing.metadata.catalog.backflowValves
      .find((i) => i.uid === "RPZD")
      ?.manufacturer.split(/(?=[A-Z])/)[0] || "generic"
  );
}

export function largestPipeSizeNominalMM(
  context: CoreContext,
  entity: DrawableEntityConcrete,
): number | null {
  const sizes = context.globalStore.getConnections(entity.uid).map((uid) => {
    const p = context.globalStore.get(uid) as CoreConduit;
    if (!p || !isPipeEntity(p.entity)) {
      throw new Error("A non pipe object is connected to a valve");
    }

    const calculation = context.globalStore.getCalculation(p.entity);
    if (!calculation || calculation.realNominalPipeDiameterMM === null) {
      return null;
    } else {
      return calculation.realNominalPipeDiameterMM;
    }
  });

  const valids = sizes.filter((u) => u !== null) as number[];
  if (valids.length === 0) {
    return null;
  }
  return Math.max(...valids);
}

export function getHeightDiffPipeCostBreakdown(
  context: CoreContext,
  pipe: CoreConduit,
  inletOrOutletHeight: number,
): CostBreakdown | undefined {
  const floorHeightM =
    context.drawing.levels[context.globalStore.levelOfEntity.get(pipe.uid)!]
      .floorHeightM;
  const heightDiff = Math.abs(
    inletOrOutletHeight - (pipe.entity.heightAboveFloorM + floorHeightM),
  );
  const pipeCostBreakdown = pipe.costBreakdown(context);
  if (pipeCostBreakdown && heightDiff > EPS) {
    let heightDiffPipeCostBreakdown: CostBreakdown = pipeCostBreakdown;
    heightDiffPipeCostBreakdown.cost =
      (pipeCostBreakdown.cost / pipeCostBreakdown.breakdown[0].qty) *
      heightDiff;
    heightDiffPipeCostBreakdown.breakdown.forEach((ele) => {
      ele.qty = heightDiff;
    });
    return heightDiffPipeCostBreakdown;
  }
}

export function determineConnectableHeightAboveFloorM(
  globalStore: GlobalStore,
  value: ConnectableEntityConcrete,
): number | null {
  if (globalStore.getConnections(value.uid).length === 0) {
    return null;
  } else {
    return (
      globalStore.get(globalStore.getConnections(value.uid)[0]) as CoreConduit
    ).entity.heightAboveFloorM;
  }
}

export function determineConnectableDiameterMM(
  globalStore: GlobalStore,
  value: ConnectableEntityConcrete,
): number | null {
  if (globalStore.getConnections(value.uid).length === 0) {
    return null;
  } else {
    // We should be smarter in how we pick the resulting diameter.
    // There are some production cases where an overridden pipe size on one side causes the vertical
    // pipe produced to be way too small - imparting a much too large pressure drop.
    // So the rule is, don't override if null pipes exist, and otherwise pick the largest pipe size.
    // In the future, this could even be, the larger pipe size between the path flowing from and to, along
    // this pipe.
    const diameters = globalStore
      .getConnections(value.uid)
      .map((uid) => {
        return globalStore.get<CoreConduit>(uid);
      })
      .filter((p) => p && p.type === EntityType.CONDUIT)
      .map((p) => {
        if (p && (isPipeEntity(p.entity) || isDuctEntity(p.entity))) {
          return p.entity.conduit.diameterMM;
        } else {
          return null;
        }
      });

    if (diameters.includes(null)) {
      return null;
    } else {
      return Math.max(...(diameters as number[]));
    }
  }
}

export function determineConnectableNetwork<T extends FlowSystemType>(
  globalStore: GlobalStore,
  value: ConnectableEntityConcrete,
  system: FlowSystemTypeMap[T],
  preferMain: boolean = false,
): FlowSystemNetworkKeyMap[T] | undefined {
  let retVal = HORIZONTAL_SYSTEM_NETWORKS_BY_PRIORITY[system.type][0];
  if (value.type === EntityType.RISER) {
    retVal = DEFAULT_VERTICAL_SYSTEM_NETWORKS[system.type];
    if (
      flowSystemHasVent(system) &&
      value.riserType === "pipe" &&
      value.riser.isVent
    ) {
      retVal = "vents";
    }
  } else {
    const neighbouringNetworks: NetworkType[] = [];

    for (const conn of globalStore.getConnections(value.uid)) {
      const o = globalStore.get(conn) as CoreConduit;
      if (isPipeEntity(o.entity) || isDuctEntity(o.entity)) {
        neighbouringNetworks.push(o.entity.conduit.network);
      }
    }

    for (const n of [
      ...HORIZONTAL_SYSTEM_NETWORKS_BY_PRIORITY[system.type],
      ...AUX_SYSTEM_NETWORKS[system.type],
    ]) {
      if (neighbouringNetworks.includes(n)) {
        retVal = n;
        if (preferMain) {
          break;
        }
      }
    }
  }
  return retVal as FlowSystemNetworkKeyMap[T];
}

export function flowSystemsCompatible(
  a: string,
  b: string,
  drawing: DrawingState,
) {
  if (
    isDrainage(drawing.metadata.flowSystems[a]) &&
    isDrainage(drawing.metadata.flowSystems[b])
  ) {
    return true;
  }

  return a === b;
}

export function determineConnectableSystemUid(
  globalStore: GlobalStore,
  value: ConnectableEntityConcrete,
): StandardFlowSystemUids | undefined {
  switch (value.type) {
    case EntityType.FITTING:
    case EntityType.RISER:
    case EntityType.FLOW_SOURCE:
    case EntityType.SYSTEM_NODE:
      return value.systemUid as StandardFlowSystemUids;
    case EntityType.DIRECTED_VALVE:
    case EntityType.LOAD_NODE:
    case EntityType.MULTIWAY_VALVE:
      // system will depend on neighbours
      if (value.systemUidOption) {
        return value.systemUidOption as StandardFlowSystemUids;
      } else {
        // system will depend on neighbours
        if (globalStore.getConnections(value.uid).length === 0) {
          return undefined;
        } else {
          const systemPriority = (pipe: CoreConduit) => {
            return isDrainage(
              pipe.drawing.metadata.flowSystems[pipe.entity.systemUid],
            )
              ? 2
              : 1;
          };

          const pipe = globalStore
            .getConnections(value.uid)
            .map((i) => globalStore.get<CoreConduit>(i))
            .sort((a, b) => systemPriority(a) - systemPriority(b));

          return pipe[0].entity.systemUid as StandardFlowSystemUids;
        }
      }
    case EntityType.VERTEX:
      return undefined;
  }
  assertUnreachable(value);
}

export function determineConnectableModelConduit(
  globalStore: GlobalStore,
  value: ConnectableEntityConcrete,
): ConduitEntity | null {
  const connectionUids = globalStore.getConnections(value.uid);
  let longestLength = -1;
  let longestPipe: ConduitEntity | null = null;
  for (const cUid of connectionUids) {
    const p = globalStore.get(cUid) as CoreConduit;
    if (p.entity.type === EntityType.CONDUIT) {
      if ((p.entity.lengthM || p.computedLengthM) > longestLength) {
        longestLength = p.entity.lengthM || p.computedLengthM;
        longestPipe = p.entity;
      }
    }
  }

  return longestPipe;
}

export function determineConnectableVelocityMS(
  globalStore: GlobalStore,
  value: ConnectableEntityConcrete,
): number | null {
  switch (value.type) {
    case EntityType.RISER:
      return value.riser.maximumVelocityMS;
    case EntityType.FITTING:
    case EntityType.FLOW_SOURCE:
    case EntityType.SYSTEM_NODE:
    case EntityType.DIRECTED_VALVE:
    case EntityType.LOAD_NODE:
    case EntityType.MULTIWAY_VALVE:
      // system will depend on neighbours
      if (globalStore.getConnections(value.uid).length === 0) {
        return null;
      } else {
        const p = globalStore.get<CoreConduit>(
          globalStore.getConnections(value.uid)[0],
        );
        if (isPipeEntity(p.entity)) {
          return p.entity.conduit.maximumVelocityMS;
        } else {
          return null;
        }
      }
    case EntityType.VERTEX:
      return null;
  }
  assertUnreachable(value);
}

export function determineConnectablePressureDropRateKPAM(
  globalStore: GlobalStore,
  value: ConnectableEntityConcrete,
): number | null {
  switch (value.type) {
    case EntityType.RISER:
      switch (value.riserType) {
        case "duct":
          return value.riser.maximumPressureDropRateKPAM == null
            ? null
            : value.riser.maximumPressureDropRateKPAM / 1000;
        case "pipe":
          return value.riser.maximumPressureDropRateKPAM;
        default:
          assertUnreachable(value);
      }
    case EntityType.FITTING:
    case EntityType.FLOW_SOURCE:
    case EntityType.SYSTEM_NODE:
    case EntityType.DIRECTED_VALVE:
    case EntityType.LOAD_NODE:
    case EntityType.MULTIWAY_VALVE:
      // system will depend on neighbours
      if (globalStore.getConnections(value.uid).length === 0) {
        return null;
      } else {
        const p = globalStore.get<CoreConduit>(
          globalStore.getConnections(value.uid)[0],
        );
        if (isPipeEntity(p.entity)) {
          return p.entity.conduit.maximumPressureDropRateKPAM;
        } else {
          return null;
        }
      }
    case EntityType.VERTEX:
      return null;
  }
  assertUnreachable(value);
}

export function determineConnectableLivePipeCalcs(
  globalStore: GlobalStore,
  value: ConnectableEntityConcrete,
): ConduitLiveCalculation | null {
  switch (value.type) {
    case EntityType.RISER:
      return null;
    case EntityType.FITTING:
    case EntityType.FLOW_SOURCE:
    case EntityType.SYSTEM_NODE:
    case EntityType.DIRECTED_VALVE:
    case EntityType.LOAD_NODE:
    case EntityType.MULTIWAY_VALVE:
      // system will depend on neighbours
      if (globalStore.getConnections(value.uid).length === 0) {
        return null;
      } else {
        return globalStore.getOrCreateLiveCalculation(
          globalStore.get<CoreConduit>(globalStore.getConnections(value.uid)[0])
            .entity,
        );
      }
    case EntityType.VERTEX:
      return null;
  }
  assertUnreachable(value);
}

export function getEdgeLikeHeightAboveFloorM(
  entity: EdgeLikeEntity,
  connectable: ConnectableEntityConcrete,
  context: CoreContext,
  forRiser = false,
): number {
  switch (entity.type) {
    case EntityType.FIXTURE:
      const fe = fillFixtureFields(context, entity);
      return fe.outletAboveFloorM!;
    case EntityType.GAS_APPLIANCE:
      return entity.outletAboveFloorM;
    case EntityType.BIG_VALVE:
      return entity.heightAboveFloorM;
    case EntityType.CONDUIT:
      if (
        forRiser &&
        isDrainage(context.drawing.metadata.flowSystems[entity.systemUid])
      ) {
        return Math.max(entity.heightAboveFloorM, 1);
      }
      return entity.heightAboveFloorM;
    case EntityType.PLANT:
      const filled = fillPlantDefaults(context, entity);

      if (filled.inletUid === connectable.uid) {
        return filled.inletHeightAboveFloorM!;
      }

      switch (filled.plant.type) {
        case PlantType.RETURN_SYSTEM:
          for (const o of filled.plant.outlets) {
            if (
              o.outletUid === connectable.uid ||
              o.outletReturnUid === connectable.uid
            ) {
              return o.heightAboveFloorM!;
            }
          }

          for (const preheat of filled.plant.preheats) {
            if (
              preheat.inletUid === connectable.uid ||
              preheat.returnUid === connectable.uid
            ) {
              return preheat.heightAboveFloorM!;
            }
          }

          return filled.inletHeightAboveFloorM!;
        case PlantType.AHU_VENT:
          if (connectable.uid === filled.plant.supplyUid) {
            return filled.plant.supplyHeightAboveFloorM!;
          } else if (connectable.uid === filled.plant.extractUid) {
            return filled.plant.extractHeightAboveFloorM!;
          } else if (connectable.uid === filled.plant.intakeUid) {
            return filled.plant.intakeHeightAboveFloorM!;
          } else if (connectable.uid === filled.plant.exhaustUid) {
            return filled.plant.exhaustHeightAboveFloorM!;
          }
          return filled.plant.supplyHeightAboveFloorM!;

        case PlantType.AHU:
        case PlantType.FCU:
          if (
            filled.plant.heatingInletUid === connectable.uid ||
            filled.plant.heatingOutletUid === connectable.uid
          ) {
            return filled.plant.heatingHeightAboveFloorM!;
          }

          if (
            filled.plant.chilledInletUid === connectable.uid ||
            filled.plant.chilledOutletUid === connectable.uid
          ) {
            return filled.plant.chilledHeightAboveFloorM!;
          }

          return filled.plant.heatingHeightAboveFloorM!;
        case PlantType.RADIATOR:
        case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
        case PlantType.CUSTOM:
        case PlantType.DRAINAGE_PIT:
        case PlantType.TANK:
        case PlantType.PUMP:
        case PlantType.PUMP_TANK:
        case PlantType.VOLUMISER:
        case PlantType.MANIFOLD:
        case PlantType.UFH:
        case PlantType.FILTER:
        case PlantType.RO:
          if (filled.plant.outletUid === connectable.uid) {
            return filled.plant.outletHeightAboveFloorM!;
          }
          return filled.inletHeightAboveFloorM!;
        case PlantType.DUCT_MANIFOLD:
          return filled.inletHeightAboveFloorM!;
        default:
          assertUnreachable(filled.plant);
      }
      return filled.inletHeightAboveFloorM!;
    case EntityType.LOAD_NODE: {
      const filled = fillDefaultLoadNodeFields(context, entity);
      if (filled.node.type !== NodeType.VENTILATION) {
        throw new Error("only ventilation nodes have height above floor");
      }
      return filled.node.heightAboveFloorM!;
    }
    case EntityType.EDGE:
    case EntityType.WALL:
    case EntityType.FENESTRATION:
    case EntityType.ROOM:
      return 0;
  }
  assertUnreachable(entity);
}

export function getFloorHeight(
  globalStore: GlobalStore,
  drawing: DrawingState,
  entity: DrawableEntityConcrete,
) {
  const levelUid = globalStore.levelOfEntity.get(entity.uid);
  if (levelUid === null) {
    return 0;
  } else if (levelUid === undefined) {
    throw new Error("entity has no level");
  } else {
    return drawing.levels[levelUid]?.floorHeightM;
  }
}

export function getEdgeLikeHeightAboveGroundM(
  entity: EdgeLikeEntity,
  connectable: ConnectableEntityConcrete,
  context: CoreContext,
  forRiser = false,
): number {
  return +(
    getEdgeLikeHeightAboveFloorM(entity, connectable, context, forRiser) +
    getFloorHeight(context.globalStore, context.drawing, entity)
  ).toFixed(12);
}

export function getIdentityCalculationEntityUid(
  context: CoreContext,
  uid: string | null,
): string | null {
  if (!uid) {
    return null;
  }

  const obj = context.globalStore.get(uid);
  const entity = obj?.entity;
  if (!entity) {
    return null;
  }

  if (!isCalculated(entity)) {
    return uid;
  }

  return obj.getCalculationUid(context);
}

export function getValveK(
  catalogId: string,
  catalog: Catalog,
  pipeDiameterMM: number,
): number | null {
  const table = catalog.valves[catalogId].valvesBySize;
  return interpolateTable(table, pipeDiameterMM, false, (v) =>
    parseCatalogNumberExact(v.kValue),
  );
}

export function getRpzdPressureLossKPA(
  context: CoreContext,
  catalogId: string,
  size: number,
  flowLS: number,
  systemUid: string,
  type:
    | ValveType.RPZD_SINGLE
    | ValveType.RPZD_DOUBLE_SHARED
    | ValveType.RPZD_DOUBLE_ISOLATED,
  isolateOneWhenCalculatingHeadLoss: boolean = false,
): PressureLossResult {
  const manufacturer =
    context.drawing.metadata.catalog.backflowValves.find(
      (material: SelectedMaterialManufacturer) => material.uid === catalogId,
    )?.manufacturer || "generic";
  const rpzdEntry = upperBoundTable(
    context.catalog.backflowValves[catalogId].valvesBySize[manufacturer],
    size,
  );
  if (!rpzdEntry) {
    return {
      pressureLossKPA: null,
    };
  }

  if (type === ValveType.RPZD_DOUBLE_SHARED) {
    flowLS /= 2;
  } else if (
    type === ValveType.RPZD_DOUBLE_ISOLATED &&
    !isolateOneWhenCalculatingHeadLoss
  ) {
    flowLS /= 2;
  }

  const plKPA = interpolateTable(
    rpzdEntry.pressureLossKPAByFlowRateLS,
    flowLS,
    true,
  );
  if (plKPA === null) {
    if (Math.abs(flowLS) < EPS) {
      return { pressureLossKPA: 0 };
    }
    return { pressureLossKPA: null };
  }

  if (systemUid === undefined) {
    return { pressureLossKPA: null };
  }
  const density = getFluidDensityOfSystem(context, systemUid);
  if (density === null) {
    return { pressureLossKPA: null };
  }

  return {
    pressureLossKPA: plKPA,
  };
}

export interface PlantPressureLossOptions {
  flowLS?: number;
  pressurePushMode?: PressurePushMode;
  ignoreCalculatedDefaults?: boolean;
  outletSystemUid?: string; // used for duct manifold plants
}

export function getPlantPressureLoss(
  context: CoreContext,
  entity: PlantEntity,
  plantCalc: PlantCalculation,
  systemUid: string,
  options: PlantPressureLossOptions,
): PressureLossResult {
  const { flowLS, pressurePushMode, ignoreCalculatedDefaults } = options;
  const { globalStore } = context;
  const filled = fillPlantDefaults(context, entity, ignoreCalculatedDefaults);
  // if (!isVentilationPlant(filled.plant, context.drawing.metadata.flowSystems)) {
  if (!isMultiOutlets(filled.plant)) {
    if (!filled.plant.pressureLoss) {
      return { pressureLossKPA: null };
    }

    let result: PressureLossResult = { pressureLossKPA: null };

    switch (filled.plant.pressureLoss.pressureMethod) {
      case PressureMethod.PUMP_DUTY:
        result = {
          pressureLossKPA: -filled.plant.pressureLoss.pumpPressureKPA!,
        };
        break;
      case PressureMethod.FIXED_PRESSURE_LOSS:
        // TODO: this should be in fill defaults rather than here.
        if (filled.plant.type === PlantType.FILTER) {
          result = {
            pressureLossKPA: FilterCalculations.getFilterPressureDropKPA(
              context,
              filled as FilterPlantEntity,
            ),
          };
          break;
        }
        if (typeof flowLS !== "undefined") {
          if (flowLS === 0) {
            return { pressureLossKPA: 0 };
          }
        }
        result = {
          pressureLossKPA: filled.plant.pressureLoss.pressureLossKPA!,
        };
        break;
      case PressureMethod.STATIC_PRESSURE:
        result = {
          pressureLossKPA: 0,
          minPressureKPA: filled.plant.pressureLoss.staticPressureKPA!,
          maxPressureKPA: filled.plant.pressureLoss.staticPressureKPA!,
        };
        break;
      case PressureMethod.KV_PRESSURE_LOSS:
        const p = context.globalStore.get(entity.uid) as CorePlant;
        const fs = getFlowSystem(
          context.drawing.metadata.flowSystems,
          entity.inletSystemUid,
        )!;
        const pipe = p.getConnectedPipe(p.entity.inletUid!, fs)!;
        const pCalc = globalStore.getOrCreateCalculation(pipe.entity);

        if (!pCalc) {
          return { pressureLossKPA: null };
        }

        if (!plantCalc.kvValue) {
          return { pressureLossKPA: 0 };
        }

        const ga =
          context.drawing.metadata.calculationParams.gravitationalAcceleration;
        const fluid = context.catalog.fluids["water"];
        const velocityMS = pCalc.velocityRealMS ?? 0;

        return {
          pressureLossKPA: head2kpa(
            fittingFrictionLossMH(velocityMS, plantCalc.kvValue, ga),
            parseCatalogNumberExact(fluid.densityKGM3)!,
            ga,
          ),
        };
      default:
        assertUnreachable(filled.plant.pressureLoss);
    }

    if (isPlantPump(entity) && pressurePushMode === PressurePushMode.Static) {
      const calculation = globalStore.getOrCreateCalculation(entity);
      if (calculation.exitPressureKPA) {
        (result as PressureLossSuccess).minPressureKPA =
          calculation.exitPressureKPA ?? undefined;
        result.pressureLossKPA = 0;
      }
    }

    return result;
  } else if (isDualSystemNodePlant(filled.plant)) {
    if (isHeatingPlantSystem(context.drawing.metadata.flowSystems[systemUid])) {
      return {
        pressureLossKPA: filled.plant.heatingPressureLoss.pressureLossKPA,
      };
    } else {
      return {
        pressureLossKPA: filled.plant.chilledPressureLoss.pressureLossKPA,
      };
    }
  }

  if (filled.plant.type === PlantType.DUCT_MANIFOLD) {
    // pressure of only one outlet
    if (options.outletSystemUid) {
      const outletNode = context.globalStore.get<CoreSystemNode>(
        options.outletSystemUid,
      );
      for (const outlet of filled.plant.outlets) {
        if (outlet.uid === outletNode.uid) {
          return {
            pressureLossKPA: outlet.pressureLoss.pressureLossKPA,
          };
        }
      }
    }
    // total pressure of all outlets
    else {
      let pressureLossKPA = 0;
      for (const outlet of filled.plant.outlets) {
        pressureLossKPA += Number(outlet.pressureLoss.pressureLossKPA);
      }
      return { pressureLossKPA };
    }
  }

  return { pressureLossKPA: 0 };
}

export function getPressureDropKPAFromPressures(
  pressuresKPA: (number | null)[],
): FittingCalculation["pressureDropKPA"] {
  if (pressuresKPA.includes(null)) {
    return null;
  }

  pressuresKPA.sort((a, b) => Number(a) - Number(b)).reverse();

  if (pressuresKPA.length <= 1) {
    return null;
  } else {
    if (pressuresKPA.length > 2) {
      return [
        pressuresKPA[0]! - pressuresKPA[1]!,
        pressuresKPA[0]! - pressuresKPA[pressuresKPA.length - 1]!,
      ];
    } else {
      return pressuresKPA[0]! - pressuresKPA[1]!;
    }
  }
}

export function findPipeFittingKValue(
  fromCoord: Coord3D,
  toCoord: Coord3D,
  entityConnections: string[],
  catalog: Catalog,
  smallestDiameterNominalMM: number,
): number | null {
  let kValue: number | null = null;

  let angle: number = 0;
  if (coordMagnitude(fromCoord) > EPS && coordMagnitude(toCoord) > EPS) {
    angle = Math.abs(
      canonizeAngleRad(
        Math.acos(coordDot(coordNormalize(fromCoord), coordNormalize(toCoord))),
      ),
    );
  }
  // elbows
  if (entityConnections.length === 2) {
    if (isStraightRad(angle, Math.PI / 8)) {
      kValue = 0;
    } else if (is45AngleRad(angle, Math.PI / 8)) {
      kValue = getValveK("45Elbow", catalog, smallestDiameterNominalMM);
    } else if (isRightAngleRad(angle, Math.PI / 8)) {
      kValue = getValveK("90Elbow", catalog, smallestDiameterNominalMM);
    } else {
      kValue = getValveK("90Elbow", catalog, smallestDiameterNominalMM);
      if (kValue) {
        kValue *= 2;
      }
    }
    // tees
  } else if (entityConnections.length >= 3) {
    if (isStraightRad(angle, Math.PI / 8)) {
      kValue = getValveK("tThruFlow", catalog, smallestDiameterNominalMM);
    } else if (is45AngleRad(angle, Math.PI / 8)) {
      kValue = getValveK("tThruFlow", catalog, smallestDiameterNominalMM);
    } else if (isRightAngleRad(angle, Math.PI / 8)) {
      kValue = getValveK("tThruBranch", catalog, smallestDiameterNominalMM);
    } else {
      kValue = getValveK("tThruBranch", catalog, smallestDiameterNominalMM);
      if (kValue) {
        kValue *= 2;
      }
    }
  } else {
    throw new Error("edge shouldn't exist");
  }

  return kValue;
}

export function getFlowVelocityMS(
  crossSectionAreaM2: number,
  flowRateLS: number,
) {
  return flowRateLS / crossSectionAreaM2 / 1000;
}

// TODO: generalize sizing for all conduits, not just pipes.
export function determineSmallestDiameterPipe(pipeCalcs: ConduitCalculation[]) {
  let smallestDiameterMM: number | null = null;
  let largestDiameterMM: number | null = null;
  let smallestDiameterNominalMM: number | null = null;

  pipeCalcs.forEach((pCalc) => {
    if ("realInternalDiameterMM" in pCalc) {
      const thisDiameter = parseCatalogNumberExact(
        pCalc.realInternalDiameterMM,
      )!;
      const thisDiameterNominal = parseCatalogNumberExact(
        pCalc.realNominalPipeDiameterMM,
      )!;
      if (
        smallestDiameterMM == null ||
        (thisDiameter != null && thisDiameter < smallestDiameterMM)
      ) {
        smallestDiameterMM = thisDiameter;
        smallestDiameterNominalMM = thisDiameterNominal;
      }
      if (
        largestDiameterMM == null ||
        (thisDiameter != null && thisDiameter > largestDiameterMM)
      ) {
        largestDiameterMM = thisDiameter;
      }
    }
  });

  return {
    smallestDiameterMM,
    smallestDiameterNominalMM,
    largestDiameterMM,
  };
}

export function externalSegmentDetermineDirectionCW(
  context: CoreContext,
  segment: [Coord, Coord],
  polygonEdgeUids: string[],
): [Coord, Coord] {
  // Internal Wall
  if (polygonEdgeUids.length === 2) return segment;
  let polygonEdge = polygonEdgeUids[0];
  let polygonUid = context.globalStore.getPolygonsByEdge(polygonEdge)[0];
  let room = context.globalStore.get<ICorePolygon>(polygonUid);
  let coordinates = room.collectVerticesInOrder().map((v) => {
    return v.toWorldCoord();
  });

  if (isClockWise(coordinates)) {
    coordinates = coordinates.reverse();
  }

  let midPoint = {
    x: (segment[0].x + segment[1].x) / 2,
    y: (segment[0].y + segment[1].y) / 2,
  };
  let deltaX = segment[1].x - segment[0].x;
  let deltaY = segment[1].y - segment[0].y;
  let length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
  deltaX /= length;
  deltaY /= length;

  // TODO: figure out a better
  for (let distance = 100; distance <= 900; distance += 10) {
    let offsetPoint = {
      x: midPoint.x - deltaY * distance,
      y: midPoint.y + deltaX * distance,
    };

    // Check if the point inside the polygon
    if (pointInsidePolygon(offsetPoint, coordinates)) {
      return segment;
    }
  }
  return [segment[1], segment[0]];
}
// only for checking in drawing canvas
export function isNewWallManifested(
  context: CoreContext,
  entity: WallEntity,
): boolean {
  if (entity.polygonEdgeUid.length === 1) {
    return true;
  }
  return isInternalWallManifested(context, entity);
}

export function isInternalWallManifested(
  context: CoreContext,
  entity: WallEntity,
) {
  if (entity.polygonEdgeUid.length === 1) {
    throw new Error("Exterior wall passed into isInternalWallManifested");
  }
  let a = context.globalStore.get<CoreEdge>(entity.polygonEdgeUid[0]),
    b = context.globalStore.get<CoreEdge>(entity.polygonEdgeUid[1]);

  // Bug: some older documents wrongly generated internal walls on different levels.
  const levelA = context.globalStore.levelOfEntity.get(a.uid);
  const levelB = context.globalStore.levelOfEntity.get(b.uid);

  if (levelA !== levelB) {
    return false;
  }

  let posa = a.worldEndpoints(),
    posb = b.worldEndpoints();
  let veca = a.vector,
    vecb = b.vector;

  //calculate angle without direction
  let angle = Math.acos(
    (veca.x * vecb.x + veca.y * vecb.y) /
      (Math.sqrt(veca.x * veca.x + veca.y * veca.y) *
        Math.sqrt(vecb.x * vecb.x + vecb.y * vecb.y)),
  );
  angle = Math.min(angle, Math.PI - angle);
  if (angle > 0.07) {
    return false;
  }
  // calculate distance from endpoints to another edge
  let dist = (p: Coord, q: Coord, r: Coord) => {
    let a = q.y - p.y,
      b = p.x - q.x,
      c = a * p.x + b * p.y;
    return Math.abs(a * r.x + b * r.y - c) / Math.sqrt(a * a + b * b);
  };
  let dista = dist(posa[0], posa[1], posb[0]),
    distb = dist(posa[0], posa[1], posb[1]),
    distc = dist(posb[0], posb[1], posa[0]),
    distd = dist(posb[0], posb[1], posa[1]);
  let mindis = Math.min(dista, distb, distc, distd);
  if (mindis > Math.max(getInternalWallWidth(entity, context), 200)) {
    let crossProduct = (a: Coord, b: Coord) => {
      return a.x * b.y - a.y * b.x;
    };
    let va1 = { x: posb[0].x - posa[0].x, y: posb[0].y - posa[0].y },
      va2 = { x: posb[1].x - posa[0].x, y: posb[1].y - posa[0].y },
      vb1 = { x: posa[0].x - posb[0].x, y: posa[0].y - posb[0].y },
      vb2 = { x: posa[1].x - posb[0].x, y: posa[1].y - posb[0].y };

    if (
      crossProduct(veca, va1) * crossProduct(veca, va2) > 0 ||
      crossProduct(vecb, vb1) * crossProduct(vecb, vb2) > 0
    )
      return false;
  }
  let res: CoreVertex[] = [];
  entity.polygonEdgeUid?.forEach((uid) => {
    const edge = context.globalStore.get<CoreEdge>(uid);
    res.push(
      ...edge.entity.endpointUid.map((x) =>
        context.globalStore.get<CoreVertex>(x),
      ),
    );
  });
  let kx = res[0].toWorldCoord().x - res[1].toWorldCoord().x,
    ky = res[0].toWorldCoord().y - res[1].toWorldCoord().y;
  res.sort(
    (a, b) =>
      kx * (a.toWorldCoord().x - b.toWorldCoord().x) +
      ky * (a.toWorldCoord().y - b.toWorldCoord().y),
  );
  res = res.slice(0, 2);
  if (
    res.some((x) => x.uid == a.entity.endpointUid[0]) &&
    res.some((x) => x.uid == a.entity.endpointUid[1])
  )
    return false;
  if (
    res.some((x) => x.uid == b.entity.endpointUid[0]) &&
    res.some((x) => x.uid == b.entity.endpointUid[1])
  )
    return false;
  let f = () => {
    let worldEndpoints = context.globalStore
      .get<CoreEdge>(entity.polygonEdgeUid[0])
      .entity.endpointUid.map((uid) => {
        return context.globalStore.get<CoreVertex>(uid).entity.center;
      })
      .map((o) => new Flatten.Point(o.x, o.y));

    let lines = entity.polygonEdgeUid.map((uid) => {
      let edge = context.globalStore.get<CoreEdge>(uid);
      let endpoints = edge.worldEndpoints();
      return new Flatten.Line(
        new Flatten.Point(endpoints[0].x, endpoints[0].y + 0.01),
        new Flatten.Point(endpoints[1].x, endpoints[1].y),
      );
    });
    let points: Coord[] = [];
    for (let point of worldEndpoints) {
      let line = lines[0];
      if (line.distanceTo(point) < lines[1].distanceTo(point)) {
        line = lines[1];
      }
      let proj = point.projectionOn(line);
      points.push({ x: (proj.x + point.x) / 2, y: (proj.y + point.y) / 2 });
    }
    return [[points[0], points[1]]];
  };
  let [pointa, pointb] = f()[0];
  return (
    Math.sqrt(
      (pointa.x - pointb.x) * (pointa.x - pointb.x) +
        (pointa.y - pointb.y) * (pointa.y - pointb.y),
    ) > Math.max(getInternalWallWidth(entity, context), 200)
  );
}

export function movePerpendicularByDistanceCW(
  start: Coord,
  end: Coord,
  distance: number,
): [Coord, Coord] {
  // calculate the direction of the line
  let dx = end.x - start.x;
  let dy = end.y - start.y;

  // normalize the direction and make it perpendicular
  let mag = Math.sqrt(dx * dx + dy * dy);
  dx /= mag;
  dy /= mag;

  let temp = dx;
  dx = -dy;
  dy = temp;

  // move the point along the perpendicular direction
  let point1: Coord = {
    x: start.x - dx * distance,
    y: start.y - dy * distance,
  };
  let point2: Coord = {
    x: end.x - dx * distance,
    y: end.y - dy * distance,
  };

  return [point1, point2];
}

export function isOutletWithInletInPlant(
  context: CoreContext,
  entity: PlantEntity,
): boolean {
  if (isDualSystemNodePlant(entity.plant)) {
    return true;
  }

  return false;
}

export function getInletsOutletSpecsInPlant(
  context: CoreContext,
  entity: PlantEntity,
  all: boolean = false,
): InletsOutletsSpec[] {
  const result: InletsOutletsSpec[] = [];
  // fillPlantDefaults is required to get the default inlet/outlet heights
  const filled = fillPlantDefaults(context, entity);

  if (isMultiOutlets(filled.plant)) {
    if (isDualSystemNodePlant(filled.plant)) {
      if (filled.plant.heatingInletUid) {
        result.push({
          uid: filled.plant.heatingInletUid,
          systemUid: filled.plant.heatingSystemUid,
          type: "inlet",
          position: "left",
          isReturn: false,
          isRecirculation: false,
          length: DEFAULT_SYSTEM_NODE_SIZE,
          heightAboveFloorM: filled.inletHeightAboveFloorM!,
        });
      }

      if (filled.plant.heatingOutletUid) {
        result.push({
          uid: filled.plant.heatingOutletUid,
          systemUid: filled.plant.heatingSystemUid,
          type: "outlet",
          position: "left",
          isReturn: true,
          isRecirculation: false,
          length: DEFAULT_SYSTEM_NODE_SIZE,
          heightAboveFloorM: filled.plant.heatingHeightAboveFloorM!,
        });
      }

      if (filled.plant.chilledInletUid) {
        result.push({
          uid: filled.plant.chilledInletUid,
          systemUid: filled.plant.chilledSystemUid,
          type: "inlet",
          position: "left",
          isReturn: false,
          isRecirculation: false,
          length: DEFAULT_SYSTEM_NODE_SIZE,
          heightAboveFloorM: filled.plant.chilledHeightAboveFloorM!,
        });
      }

      if (filled.plant.chilledOutletUid) {
        result.push({
          uid: filled.plant.chilledOutletUid,
          systemUid: filled.plant.chilledSystemUid,
          type: "outlet",
          position: "left",
          isReturn: true,
          isRecirculation: false,
          length: DEFAULT_SYSTEM_NODE_SIZE,
          heightAboveFloorM: filled.plant.chilledHeightAboveFloorM!,
        });
      }

      if (filled.plant.type === PlantType.AHU_VENT) {
        if (filled.plant.supplyUid) {
          result.push({
            uid: filled.plant.supplyUid,
            systemUid: filled.plant.supplySystemUid,
            type: "outlet",
            position: "right",
            isReturn: false,
            isRecirculation: false,
            length: DEFAULT_SYSTEM_NODE_SIZE,
            heightAboveFloorM: filled.plant.supplyHeightAboveFloorM!,
          });
        }

        if (filled.plant.extractUid) {
          result.push({
            uid: filled.plant.extractUid,
            systemUid: filled.plant.extractSystemUid,
            type: "inlet",
            position: "right",
            isReturn: false,
            isRecirculation: false,
            length: DEFAULT_SYSTEM_NODE_SIZE,
            heightAboveFloorM: filled.plant.extractHeightAboveFloorM!,
          });
        }

        if (filled.plant.intakeUid) {
          result.push({
            uid: filled.plant.intakeUid,
            systemUid: filled.plant.intakeSystemUid,
            type: "inlet",
            position: "left",
            isReturn: false,
            isRecirculation: false,
            length: DEFAULT_SYSTEM_NODE_SIZE,
            heightAboveFloorM: filled.plant.intakeHeightAboveFloorM!,
          });
        }

        if (filled.plant.exhaustUid) {
          result.push({
            uid: filled.plant.exhaustUid,
            systemUid: filled.plant.exhaustSystemUid,
            type: "outlet",
            position: "left",
            isReturn: false,
            isRecirculation: false,
            length: DEFAULT_SYSTEM_NODE_SIZE,
            heightAboveFloorM: filled.plant.exhaustHeightAboveFloorM!,
          });
        }
      }
    } else if (filled.plant.type === PlantType.DUCT_MANIFOLD) {
      for (const outlet of filled.plant.outlets!) {
        result.push({
          uid: outlet.uid!,
          systemUid: filled.inletSystemUid,
          type: "outlet",
          position: "right",
          isReturn: false,
          isRecirculation: false,
          length: DEFAULT_SYSTEM_NODE_SIZE,
          heightAboveFloorM: filled.inletHeightAboveFloorM!,
        });
      }
    } else {
      filled.plant.outlets?.forEach((outlet) => {
        result.push({
          uid: outlet.outletUid!,
          systemUid: outlet.outletSystemUid,
          type: "outlet",
          position: "right",
          isReturn: false,
          isRecirculation: false,
          length: outlet.outletReturnUid
            ? RECIRCULATION_SYSTEM_NODE_SIZE
            : DEFAULT_SYSTEM_NODE_SIZE,
          heightAboveFloorM: outlet.heightAboveFloorM!,
        });
        if (outlet.outletReturnUid) {
          result.push({
            uid: outlet.outletReturnUid!,
            systemUid: outlet.outletSystemUid,
            type: "outlet",
            position: "right",
            isReturn: true,
            isRecirculation: true,
            length: RECIRCULATION_SYSTEM_NODE_SIZE,
            heightAboveFloorM: outlet.heightAboveFloorM!,
          });
        }
      });
    }
  } else {
    result.push({
      uid: filled.plant.outletUid,
      systemUid: filled.plant.outletSystemUid,
      type: "outlet",
      position: isOutletWithInletInPlant(context, filled) ? "left" : "right",
      isReturn: false,
      isRecirculation: false,
      length: DEFAULT_SYSTEM_NODE_SIZE,
      heightAboveFloorM: filled.plant.outletHeightAboveFloorM!,
    });
  }

  switch (filled.plant.type) {
    case PlantType.RETURN_SYSTEM:
      let manufacturer = "generic";
      manufacturer =
        context.drawing.metadata.catalog.hotWaterPlant?.find(
          (i) => i.uid === "hotWaterPlant",
        )?.manufacturer || manufacturer;

      if ((filled.plant.addColdWaterInlet || all) && filled.inletUid) {
        result.push({
          uid: filled.inletUid,
          systemUid: filled.inletSystemUid,
          type: "inlet",
          position: "left",
          isReturn: false,
          isRecirculation: false,
          length: DEFAULT_SYSTEM_NODE_SIZE,
          heightAboveFloorM: filled.inletHeightAboveFloorM!,
        });
      }

      if (
        (filled.plant.addGasInlet &&
          (manufacturer === "generic" ||
            (manufacturer === "rheem" &&
              CorePlant.withGas.includes(filled.plant.rheemVariant)))) ||
        (filled.plant.gasNodeUid && !!all)
      ) {
        result.push({
          uid: filled.plant.gasNodeUid!,
          systemUid:
            filled.plant.gasInletSystemUid || StandardFlowSystemUids.Gas,
          type: "inlet",
          position: "left",
          isReturn: false,
          isRecirculation: false,
          length: DEFAULT_SYSTEM_NODE_SIZE,
          heightAboveFloorM: filled.inletHeightAboveFloorM!,
        });
      }

      for (const preheat of filled.plant.preheats) {
        result.push({
          uid: preheat.inletUid,
          systemUid:
            filled.plant.preheatSystemUid || StandardFlowSystemUids.Heating,
          type: "inlet",
          position: "left",
          isReturn: false,
          isRecirculation: false,
          length: DEFAULT_SYSTEM_NODE_SIZE,
          heightAboveFloorM: filled.inletHeightAboveFloorM!,
        });

        result.push({
          uid: preheat.returnUid,
          systemUid:
            filled.plant.preheatSystemUid || StandardFlowSystemUids.Heating,
          type: "outlet",
          position: "left",
          isReturn: true,
          isRecirculation: false,
          length: DEFAULT_SYSTEM_NODE_SIZE,
          heightAboveFloorM: filled.inletHeightAboveFloorM!,
        });
      }

      break;
    case PlantType.TANK:
    case PlantType.CUSTOM:
    case PlantType.PUMP:
    case PlantType.DRAINAGE_PIT:
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
    case PlantType.RADIATOR:
    case PlantType.PUMP_TANK:
    case PlantType.VOLUMISER:
    case PlantType.MANIFOLD:
    case PlantType.UFH:
    case PlantType.FILTER:
    case PlantType.RO:
    case PlantType.DUCT_MANIFOLD:
      result.push({
        uid: filled.inletUid!,
        systemUid: filled.inletSystemUid,
        type: "inlet",
        position: "left",
        isReturn: false,
        isRecirculation: false,
        length: DEFAULT_SYSTEM_NODE_SIZE,
        heightAboveFloorM: filled.inletHeightAboveFloorM!,
      });
      break;
    case PlantType.FCU:
    case PlantType.AHU:
    case PlantType.AHU_VENT:
      break;
    default:
      assertUnreachable(filled.plant);
  }

  return result;
}

export const VALVE_HEIGHT_MM = 70;

export function getRpzdBigValveHeightMM(entity: BigValveEntity) {
  return (entity.pipeDistanceMM * VALVE_HEIGHT_MM) / 150;
}

export function isFenManifestOnWall(
  coreFens: CoreFen,
  coreWall: CoreWall,
): boolean {
  function isFenManifestOnWallSegment(
    feneSegment: [Coord, Coord],
    wallSegment: [Coord, Coord],
  ) {
    const totalPoints = 12;
    let pointsInside = 0;
    for (let i = 0; i <= totalPoints; i++) {
      const t = i / totalPoints;
      const interpolatedPoint = {
        x: feneSegment[0].x + t * (feneSegment[1].x - feneSegment[0].x),
        y: feneSegment[0].y + t * (feneSegment[1].y - feneSegment[0].y),
      };

      if (isPointOnSegment(wallSegment, interpolatedPoint)) {
        pointsInside++;
      }
    }

    const overlapPercentage = (pointsInside / totalPoints) * 100;
    return overlapPercentage >= 10;
  }

  const feneSegment = coreFens.getWorldSegments()[0];
  const wallSegments = coreWall.getWorldSegments();
  return wallSegments.some((segment) =>
    isFenManifestOnWallSegment(feneSegment, segment),
  );
}

export function isFenManifestedOnRoomEdge(
  coreFens: CoreFen,
  coreEdge: CoreEdge,
): boolean {
  function isFenManifestOnSegment(
    fene: [Coord, Coord],
    segment: [Coord, Coord],
  ) {
    const totalPoints = 12;
    let pointsInside = 0;
    for (let i = 0; i <= totalPoints; i++) {
      const t = i / totalPoints;
      const interpolatedPoint = {
        x: fene[0].x + t * (fene[1].x - fene[0].x),
        y: fene[0].y + t * (fene[1].y - fene[0].y),
      };

      if (isPointOnSegment(segment, interpolatedPoint)) {
        pointsInside++;
      }
    }

    const overlapPercentage = (pointsInside / totalPoints) * 100;
    return overlapPercentage >= 10;
  }

  const feneSegment = coreFens.getWorldSegments()[0];
  const edgeSegment = coreEdge.worldEndpoints();
  if (edgeSegment.length !== 2) {
    return false;
  }

  return isFenManifestOnSegment(feneSegment, [edgeSegment[0], edgeSegment[1]]);
}

/**
 * Check if fenestration can attach on top of the wall,
 * if it's offset by a lot then it shouldn't
 */
export function isPointOnSegment(
  segment: [Coord, Coord],
  point: Coord,
): boolean {
  const [c1, c2] = segment;
  // make sure basePoint is between c1 and c2
  const v1 = new Flatten.Vector(c2.x - c1.x, c2.y - c1.y);
  const v2 = new Flatten.Vector(point.x - c1.x, point.y - c1.y);

  if (v1.length < 1e-4) {
    return false;
  }

  if (v2.length < 1e-4) {
    return true;
  }

  const dot = v1.normalize().dot(v2.normalize());
  if (dot < 0) return false;
  return v2.length <= v1.length;
}

/**
 * Ignore tiny segments, can't be drawn
 */
export function isValidSegment(segment: [Coord, Coord], minSize: number = 1) {
  return (
    Math.abs(segment[0].x - segment[1].x) > minSize ||
    Math.abs(segment[0].y - segment[1].y) > minSize
  );
}

export const validGrillTypes: Partial<{
  [key in FlowSystemRole]: {
    fsRole: FlowSystemRole;
    grillName: string;
    flowDirection: VentilationNode["flowDirection"];
  }[];
}> = {
  "vent-supply": [
    {
      fsRole: "vent-supply",
      grillName: "Diffuser",
      flowDirection: "out",
    },
  ],
  "vent-extract": [
    {
      fsRole: "vent-extract",
      grillName: "Grill",
      flowDirection: "in",
    },
  ],
  "vent-intake": [
    {
      fsRole: "vent-intake",
      grillName: "Intake Vent",
      flowDirection: "in",
    },
  ],
  "vent-exhaust": [
    {
      fsRole: "vent-exhaust",
      grillName: "Exhaust Vent",
      flowDirection: "out",
    },
  ],
  "vent-fan-exhaust": [
    {
      fsRole: "vent-fan-exhaust",
      grillName: "Grill",
      flowDirection: "in",
    },
    {
      fsRole: "vent-fan-exhaust",
      grillName: "Exhaust Vent",
      flowDirection: "out",
    },
  ],
};

export function getGrillNameByFlowsystem(
  fs: FlowSystem,
  flowDirection: VentilationNode["flowDirection"],
) {
  if (!validGrillTypes[fs.role]) {
    console.error("Invalid flow system role for grill");
    return "";
  }

  const validGrills = validGrillTypes[fs.role]!;
  const validGrill = validGrills.find(
    (grill) => grill.flowDirection === flowDirection,
  );

  if (validGrill) {
    return validGrill.grillName;
  }

  return "Grill";
}

export function isDirectedValveFlowSystemValid(
  valve: DirectedValveEntity,
  fs: FlowSystem,
) {
  if (valve.valve.type === ValveType.FAN) {
    return (
      fs.role === "vent-fan-exhaust" ||
      fs.role === "vent-exhaust" ||
      fs.role === "vent-extract"
    );
  }

  return true;
}

export function isVentsEntity(
  globalStore: GlobalStore,
  flowsystems: DrawingState["metadata"]["flowSystems"],
  entity: DrawableEntityConcrete,
) {
  let fsUid;
  if (entity.type === EntityType.CONDUIT) {
    fsUid = entity.systemUid;
  } else if (isConnectableEntity(entity)) {
    fsUid = determineConnectableSystemUid(globalStore, entity);
  }

  if (fsUid !== undefined) {
    const fs = flowsystems[fsUid];
    if (fs) {
      return isVentilation(fs);
    }
  }

  return false;
}

export function getCommonVertexUid(first: EdgeEntity, second: EdgeEntity) {
  return first.endpointUid.find((uid) => second.endpointUid.includes(uid));
}

export function getEdgeContextInPolygon(
  context: CoreContext,
  edgeUids: string[],
  options: { edgeUid: string } | { edgeIndex: number },
) {
  const edgeIndex = (() => {
    if ("edgeIndex" in options) {
      return options.edgeIndex;
    }
    return edgeUids.indexOf(options.edgeUid);
  })();

  const prevIndex = (edgeIndex + edgeUids.length - 1) % edgeUids.length;
  const nextIndex = (edgeIndex + 1) % edgeUids.length;

  const edge = context.globalStore.get<CoreEdge>(edgeUids[edgeIndex]);
  const prevEdge = context.globalStore.get<CoreEdge>(edgeUids[prevIndex]);
  const nextEdge = context.globalStore.get<CoreEdge>(edgeUids[nextIndex]);
  const prevVertexUid = getCommonVertexUid(prevEdge.entity, edge.entity);
  const nextVertexUid = getCommonVertexUid(edge.entity, nextEdge.entity);
  if (!prevVertexUid) {
    throw new Error(
      `Cannot find the previous vertex for edge ${edge.uid}, possibly due to invalid polygon`,
    );
  }
  if (!nextVertexUid) {
    throw new Error(
      `Cannot find the previous vertex for edge ${edge.uid}, possible due to invalid polygon`,
    );
  }
  return {
    prevEdge,
    nextEdge,
    edge,
    prevVertexUid,
    nextVertexUid,
    edgeIndex,
  };
}

/** Traverse the polygon starting from start, visiting edges and vertices alternately.
 * If start is not specified, the traversal starts from the first edge in edgeUidsInOrder.
 * If reversed is not specified or is false, traverse in the order or edgeUidsInOrder.
 * Otherwise, traverse in the reversed order.
 * If shouldVisitEdge is not specified, the function does not visit the edge
 * if it is the starting edge.
 * If shouldVisitVertex is not specified, the function does not visit the vertex
 * if it is the starting vertex.
 */
export function traversePolygon(props: {
  context: CoreContext;
  edgeUidsInOrder: string[];
  onVisit: (e: CoreVertex | CoreEdge, firstEntityVisited: boolean) => void;
  reverse?: boolean;
  start?: CoreVertex | CoreEdge;
  shouldVisitEdge?: (props: { current: CoreVertex; next: CoreEdge }) => boolean;
  shouldVisitVertex?: (props: {
    current: CoreEdge;
    next: CoreVertex;
  }) => boolean;
}) {
  const { context, onVisit } = props;

  const reverse = props.reverse ?? false;
  const edgeUidsInOrder = reverse
    ? props.edgeUidsInOrder.slice().reverse()
    : props.edgeUidsInOrder.slice();

  const getCoreEdge = (edgeUid: string) =>
    context.globalStore.get<CoreEdge>(edgeUid);

  const { start, startEdgeIndex } = (() => {
    const optionsStart = props.start;
    if (!optionsStart) {
      const startEdgeIndex = 0;
      return {
        startEdgeIndex,
        start: getCoreEdge(edgeUidsInOrder[startEdgeIndex]),
      };
    }

    switch (optionsStart.type) {
      case EntityType.EDGE: {
        const startEdgeIndex = edgeUidsInOrder.indexOf(optionsStart.entity.uid);
        if (startEdgeIndex === -1) {
          throw new Error(
            `Start edge ${optionsStart.entity.uid} not in polygon with edges ${JSON.stringify(edgeUidsInOrder)}`,
          );
        }
        return {
          startEdgeIndex,
          start: optionsStart,
        };
      }
      case EntityType.VERTEX: {
        const startEdgeIndex = edgeUidsInOrder.findIndex(
          (euid, edgeIndex) =>
            getEdgeContextInPolygon(context, edgeUidsInOrder, { edgeIndex })
              .nextVertexUid === optionsStart.entity.uid,
        );
        if (startEdgeIndex === -1) {
          throw new Error(
            `Start vertex ${optionsStart.entity.uid} not in polygon with edges ${JSON.stringify(edgeUidsInOrder)}`,
          );
        }
        return {
          startEdgeIndex,
          start: optionsStart,
        };
      }
      default:
        assertUnreachableAggressive(optionsStart);
    }
  })();

  /** Do not revisit the starting object by default */
  const defaultShouldVisit = ({ next }: { next: CoreVertex | CoreEdge }) =>
    next.uid !== start.entity.uid;
  const shouldVisitVertex = props.shouldVisitVertex ?? defaultShouldVisit;
  const shouldVisitEdge = props.shouldVisitEdge ?? defaultShouldVisit;

  const _traverse = (
    currentEdgeIndex: number,
    current: CoreVertex | CoreEdge,
    firstEntityVisited: boolean,
  ) => {
    switch (current.type) {
      case EntityType.EDGE: {
        onVisit(current, firstEntityVisited);
        const { nextVertexUid } = getEdgeContextInPolygon(
          context,
          edgeUidsInOrder,
          {
            edgeIndex: currentEdgeIndex,
          },
        );
        const next = context.globalStore.get<CoreVertex>(nextVertexUid);
        if (shouldVisitVertex({ current, next })) {
          _traverse(currentEdgeIndex, next, false);
        }
        break;
      }
      case EntityType.VERTEX: {
        onVisit(current, firstEntityVisited);
        const nextEdgeIndex = (currentEdgeIndex + 1) % edgeUidsInOrder.length;
        const next = getCoreEdge(edgeUidsInOrder[nextEdgeIndex]);
        if (shouldVisitEdge({ current, next })) {
          _traverse(nextEdgeIndex, next, false);
        }
        break;
      }
      default:
        assertUnreachableAggressive(current);
    }
  };

  _traverse(startEdgeIndex, start, true);
}
