// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from "../flatbuffers/flatbuffers";

export class ToolEventRecord
  implements flatbuffers.IUnpackableObject<ToolEventRecordT>
{
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): ToolEventRecord {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsToolEventRecord(
    bb: flatbuffers.ByteBuffer,
    obj?: ToolEventRecord,
  ): ToolEventRecord {
    return (obj || new ToolEventRecord()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsToolEventRecord(
    bb: flatbuffers.ByteBuffer,
    obj?: ToolEventRecord,
  ): ToolEventRecord {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new ToolEventRecord()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  timestamp(): bigint {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.readUint64(this.bb_pos + offset) : BigInt("0");
  }

  userName(): string | null;
  userName(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  userName(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? this.bb!.__string(this.bb_pos + offset, optionalEncoding)
      : null;
  }

  documentId(): number {
    const offset = this.bb!.__offset(this.bb_pos, 8);
    return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
  }

  previousOrderIndex(): number {
    const offset = this.bb!.__offset(this.bb_pos, 10);
    return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
  }

  toolName(): string | null;
  toolName(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  toolName(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 12);
    return offset
      ? this.bb!.__string(this.bb_pos + offset, optionalEncoding)
      : null;
  }

  id(): number | null {
    const offset = this.bb!.__offset(this.bb_pos, 14);
    return offset ? this.bb!.readUint32(this.bb_pos + offset) : null;
  }

  static startToolEventRecord(builder: flatbuffers.Builder) {
    builder.startObject(6);
  }

  static addTimestamp(builder: flatbuffers.Builder, timestamp: bigint) {
    builder.addFieldInt64(0, timestamp, BigInt("0"));
  }

  static addUserName(
    builder: flatbuffers.Builder,
    userNameOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(1, userNameOffset, 0);
  }

  static addDocumentId(builder: flatbuffers.Builder, documentId: number) {
    builder.addFieldInt32(2, documentId, 0);
  }

  static addPreviousOrderIndex(
    builder: flatbuffers.Builder,
    previousOrderIndex: number,
  ) {
    builder.addFieldInt32(3, previousOrderIndex, 0);
  }

  static addToolName(
    builder: flatbuffers.Builder,
    toolNameOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(4, toolNameOffset, 0);
  }

  static addId(builder: flatbuffers.Builder, id: number) {
    builder.addFieldInt32(5, id, null);
  }

  static endToolEventRecord(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    builder.requiredField(offset, 6); // user_name
    return offset;
  }

  static createToolEventRecord(
    builder: flatbuffers.Builder,
    timestamp: bigint,
    userNameOffset: flatbuffers.Offset,
    documentId: number,
    previousOrderIndex: number,
    toolNameOffset: flatbuffers.Offset,
    id: number | null,
  ): flatbuffers.Offset {
    ToolEventRecord.startToolEventRecord(builder);
    ToolEventRecord.addTimestamp(builder, timestamp);
    ToolEventRecord.addUserName(builder, userNameOffset);
    ToolEventRecord.addDocumentId(builder, documentId);
    ToolEventRecord.addPreviousOrderIndex(builder, previousOrderIndex);
    ToolEventRecord.addToolName(builder, toolNameOffset);
    if (id !== null) ToolEventRecord.addId(builder, id);
    return ToolEventRecord.endToolEventRecord(builder);
  }

  unpack(): ToolEventRecordT {
    return new ToolEventRecordT(
      this.timestamp(),
      this.userName(),
      this.documentId(),
      this.previousOrderIndex(),
      this.toolName(),
      this.id(),
    );
  }

  unpackTo(_o: ToolEventRecordT): void {
    _o.timestamp = this.timestamp();
    _o.userName = this.userName();
    _o.documentId = this.documentId();
    _o.previousOrderIndex = this.previousOrderIndex();
    _o.toolName = this.toolName();
    _o.id = this.id();
  }
}

export class ToolEventRecordT implements flatbuffers.IGeneratedObject {
  _type = "ToolEventRecordT" as const;
  constructor(
    public timestamp: bigint = BigInt("0"),
    public userName: string | Uint8Array | null = null,
    public documentId: number = 0,
    public previousOrderIndex: number = 0,
    public toolName: string | Uint8Array | null = null,
    public id: number | null = null,
  ) {}

  pack(builder: flatbuffers.Builder): flatbuffers.Offset {
    const userName =
      this.userName !== null ? builder.createString(this.userName!) : 0;
    const toolName =
      this.toolName !== null ? builder.createString(this.toolName!) : 0;

    return ToolEventRecord.createToolEventRecord(
      builder,
      this.timestamp,
      userName,
      this.documentId,
      this.previousOrderIndex,
      toolName,
      this.id,
    );
  }
}
