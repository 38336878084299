import { ToolInfoPanel, UIState } from "../../store/document/types";
import { ToolConfig, ToolTypes } from "../../store/tools/types";
import CanvasContext from "../lib/canvas-context";
import { ToolHandler } from "../lib/tool";
import { DrawingContext } from "../lib/types";
import { MouseMoveResult } from "../types";
import { KeyHandler, KeySpec } from "./utils";

export function updateToolInfoPanel(
  uiState: UIState,
  toolInfoPanel: ToolInfoPanel | null = null,
) {
  if (toolInfoPanel) {
    Object.assign(uiState.activeOperationInfoPanel, toolInfoPanel);
  } else {
    Object.assign(uiState.activeOperationInfoPanel, {
      keyHandlers: [],
      information: [],
    });
  }
}

export abstract class BaseTool implements ToolHandler {
  get type() {
    return ToolTypes.BASE;
  }
  keyHandlers: Array<[KeySpec, KeyHandler]> = [];
  getInfoText: (() => string[]) | undefined;

  draw(context: DrawingContext) {
    if (!context.doc.uiState.hideKeyboardShortcuts) {
      updateToolInfoPanel(context.doc.uiState, {
        keyHandlers: this.keyHandlers,
        information: this.getInfoText ? this.getInfoText() : [],
      });
    }
  }
  abstract getTitle(): string;

  abstract config: ToolConfig;
  abstract onMouseDown(
    event: MouseEvent,
    context: CanvasContext,
  ): boolean | number;
  abstract onMouseMove(
    event: MouseEvent,
    context: CanvasContext,
  ): MouseMoveResult;
  abstract onMouseUp(event: MouseEvent, context: CanvasContext): boolean;
  abstract onMouseScroll(event: MouseEvent, context: CanvasContext): boolean;

  finish(context: CanvasContext, _interrupted: boolean, _displaced: boolean) {
    updateToolInfoPanel(context.document.uiState, null);
  }
  abstract dispose(): void;

  abstract beforeDraw(context: DrawingContext): void;
}
