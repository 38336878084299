import { Units, UnitsContext } from "../../../lib/measurements";
import { EntityHeatLoadCalculation } from "../../calculations/heatloss/heat-loss-result-type";
import { CoreContext } from "../../calculations/types";
import {
  FenEntity,
  FenType,
  getFenestrationName,
} from "../entities/fenestration-entity";
import { CalculationField, FieldCategory } from "./calculation-field";
import { HEATGAIN_RESULT_HEX, HEATLOSS_RESULT_HEX } from "./room-calculation";
import {
  CalcFieldSelector,
  Calculation,
  CalculationType,
  LiveCalculation,
} from "./types";

export default interface FenCalculation
  extends Calculation,
    EntityHeatLoadCalculation {
  type: CalculationType.FenCalculation;
  solarWPerMin: number | null;
  externalFacingDegree: number | null;
}

export interface FenLiveCalculation extends LiveCalculation {}

export const FastLiveFenCalculationFields: CalcFieldSelector<FenLiveCalculation> =
  {
    connected: true,
  };

export const StickyFenCalcualtionFields: CalcFieldSelector<FenCalculation> = {};

export function makeFenCalculationFields(
  context: CoreContext,
  entity: FenEntity,
): CalculationField[] {
  let result: CalculationField[] = [];
  let name: string = getFenestrationName(entity.fenType);
  result.push({
    property: "areaM2",
    title: `${name} Area`,
    short: "",
    units: Units.SquareMeters,
    category: FieldCategory.Size,
    layouts: ["mechanical"],
    defaultEnabled: false,
  });

  result.push({
    property: "lengthM",
    title: `${name} Length`,
    short: "",
    units: Units.Meters,
    category: FieldCategory.Size,
    layouts: ["mechanical"],
    defaultEnabled: false,
  });

  result.push({
    property: "heightM",
    title: `${name} Height`,
    short: "",
    units: Units.Meters,
    category: FieldCategory.Size,
    layouts: ["mechanical"],
    defaultEnabled: false,
  });

  result.push({
    property: "uValueW_M2K",
    title: `${name} U Value`,
    short: "",
    units: Units.None,
    category: FieldCategory.HeatLoss,
    layouts: ["mechanical"],
    defaultEnabled: false,
  });

  result.push({
    property: "heatLossThroughComponentWatt",
    title: `Heat Loss Through ${name}`,
    short: "",
    units: Units.Watts,
    category: FieldCategory.HeatLoss,
    layouts: ["mechanical"],
    defaultEnabled: false,
    color: HEATLOSS_RESULT_HEX,
    unitContext: UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
  });
  result.push({
    property: "heatGainThroughComponentWatt",
    title: `Heat Gain Through ${name}`,
    short: "",
    units: Units.Watts,
    category: FieldCategory.HeatLoss,
    layouts: ["mechanical"],
    defaultEnabled: false,
    color: HEATGAIN_RESULT_HEX,
    unitContext: UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
  });
  if (entity.fenType === FenType.WINDOW) {
    result.push({
      property: "solarWPerMin",
      title: `Solar Watt Per Minute Through ${name}`,
      short: "",
      units: Units.Watts,
      category: FieldCategory.HeatLoss,
      layouts: ["mechanical"],
      defaultEnabled: false,
      color: HEATGAIN_RESULT_HEX,
      unitContext: UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
    });
    result.push({
      property: "externalFacingDegree",
      title: `External Facing Degree`,
      short: "",
      units: Units.None,
      category: FieldCategory.HeatLoss,
      layouts: ["mechanical"],
      defaultEnabled: false,
    });
  }

  return result;
}

export function emptyFenLiveCalculation(): FenLiveCalculation {
  return {
    connected: null,
    warnings: [],
  };
}

export function emptyFenCalculation(): FenCalculation {
  return {
    warnings: [],
    costBreakdown: null,
    expandedEntities: null,
    type: CalculationType.FenCalculation,
    cost: null,
    heatLossThroughComponentWatt: null,
    heatGainThroughComponentWatt: null,
    uValueW_M2K: null,
    areaM2: null,
    lengthM: null,
    heightM: null,
    internalTemperatureC: null,
    externalWinterTemperatureC: null,
    externalSummerTemperatureC: null,
    solarWPerMin: null,
    externalFacingDegree: null,
  };
}
