import { isGas } from "../../../../../common/src/api/config";
import {
  fillMultiwayValveFields,
  MultiwayValveEntity,
} from "../../../../../common/src/api/document/entities/multiway-valves/multiway-valve-entity";
import { Units } from "../../../../../common/src/lib/measurements";
import { CoreContext } from "../../calculations/types";
import { CalculationField, FieldCategory } from "./calculation-field";
import {
  addPressureCalculationFields,
  CalcFieldSelector,
  Calculation,
  CalculationType,
  LiveCalculation,
  NameCalculation,
  PressureCalculation,
} from "./types";
import { getFlowSystemLayouts } from "./utils";

export default interface MultiwayValveCalculation
  extends Calculation,
    PressureCalculation,
    NameCalculation {
  type: CalculationType.MultiwayValveCalculation;
  flowRateLS: number | null;
  pressureDropKPA: number | null;
  pressureByEndpointKPA: { [key: string]: number | null };
  kvValue: number | null;
  sizeMM: number | null;
}

export interface MultiwayValveLiveCalculation extends LiveCalculation {}

export const FastLiveMultiwayValveCalculationFields: CalcFieldSelector<MultiwayValveLiveCalculation> =
  {
    connected: true,
  };

export function makeMultiwayValveCalculationFields(
  context: CoreContext,
  entity: MultiwayValveEntity,
): CalculationField[] {
  const { drawing, globalStore, catalog } = context;
  const systems = drawing.metadata.flowSystems;
  const filled = fillMultiwayValveFields(context, entity);

  if (globalStore.getConnections(entity.uid).length === 0) {
    return [];
  }
  const result: CalculationField[] = [];
  const fittingIsGas =
    catalog && isGas(drawing.metadata.flowSystems[filled.systemUidOption!]);
  const { layouts, layoutsExcept } = getFlowSystemLayouts(
    systems[filled.systemUidOption!],
  );

  result.push({
    property: "reference",
    title: "Reference",
    short: "",
    shortTitle: "",
    units: Units.None,
    category: FieldCategory.EntityName,
    systemUid: filled.systemUidOption || undefined,
    layouts: layouts,
    defaultEnabled: false,
  });

  if (!fittingIsGas && filled.systemUidOption) {
    result.push({
      property: "flowRateLS",
      title: "Peak Flow Rate",
      short: "",
      shortTitle: "",
      units: Units.LitersPerSecond,
      systemUid: filled.systemUidOption,
      category: FieldCategory.FlowRate,
      layouts: layoutsExcept("drainage"),
    });

    result.push({
      property: "pressureDropKPA",
      title: "Pressure Drop (PD)",
      short: "",
      shortTitle: "PD",
      units: Units.KiloPascals,
      systemUid: filled.systemUidOption,
      category: FieldCategory.Pressure,
      layouts: layoutsExcept("drainage"),
      format: (v: number | [number, number] | null) => {
        if (typeof v === "number") {
          return v.toFixed(3);
        } else if (v === null) {
          return "??";
        } else {
          return v[0].toFixed(3) + " to " + v[1].toFixed(3);
        }
      },
    });

    if (filled.systemUidOption) {
      addPressureCalculationFields(
        context,
        result,
        filled.systemUidOption,
        layouts,
      );
    }
  }

  return result;
}

export function emptyMultiwayValveCalculation(): MultiwayValveCalculation {
  return {
    type: CalculationType.MultiwayValveCalculation,
    costBreakdown: null,
    cost: null,
    expandedEntities: null,
    sizeMM: null,

    flowRateLS: null,
    pressureDropKPA: null,
    pressureKPA: null,
    staticPressureKPA: null,
    pressureByEndpointKPA: {},
    warnings: null,
    entityName: null,
    kvValue: null,
  };
}

export function emptyMultiwayValveLiveCalculation(): MultiwayValveLiveCalculation {
  return {
    connected: null,
    warnings: [],
  };
}
