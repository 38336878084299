import { Color } from "../../../../lib/color";
import { Units } from "../../../../lib/measurements";
import { cloneSimple } from "../../../../lib/utils";
import { CoreContext } from "../../../calculations/types";
import { determineConnectableSystemUid } from "../../../coreObjects/utils";
import { getFlowSystem } from "../../utils";
import { FieldType, PropertyField } from "../property-field";
import { ConnectableEntity, NamedEntity } from "../simple-entities";
import { EntityType } from "../types";
import { MultiwayValve } from "./multiway-valve-types";

export interface MultiwayValveEntity extends ConnectableEntity, NamedEntity {
  type: EntityType.MULTIWAY_VALVE;
  systemUidOption: string | null;
  color: Color | null;
  valve: MultiwayValve;
  valveSizeMM: number | null;
}

export function makeMultiwayValveFields(
  context: CoreContext,
  entity: MultiwayValveEntity,
) {
  const { drawing } = context;
  const fields: PropertyField[] = [];

  fields.push(
    {
      property: "entityName",
      title: "Name",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Text,
      params: null,
      multiFieldId: "entityName",
    },
    {
      property: "valveSizeMM",
      title: "Size",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Number,
      // min and step are weird number, to maintain existing project's valve sizes. The legacy
      // feature had this at a weird off-by-one increment but it is not worth
      // changing existing docs to fix.
      params: {
        min: 31.11111111111,
        max: 70,
        step: 3.8888888888888888888,
        displayMin: 0,
        displayMax: 100,
      },
      multiFieldId: "valveSizeMM",
      units: Units.Percent,
    },
    {
      property: "systemUidOption",
      title: "Flow System",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.FlowSystemChoice,
      params: {
        systems: drawing.metadata.flowSystemUidsInOrder.map(
          (uid) => drawing.metadata.flowSystems[uid],
        ),
      },
      multiFieldId: "systemUid",
    },
    {
      property: "color",
      title: "Color",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Color,
      params: null,
      multiFieldId: "color",
    },
  );

  return fields;
}

export function fillMultiwayValveFields(
  context: CoreContext,
  entity: MultiwayValveEntity,
) {
  const { globalStore, drawing } = context;
  const result = cloneSimple(entity);
  const systemUid = determineConnectableSystemUid(globalStore, entity);
  const system = getFlowSystem(drawing, systemUid);

  if (!!system) {
    if (result.color === null) {
      result.color = system.color;
    }
    if (result.systemUidOption === null) {
      result.systemUidOption = system.uid;
    }
  }

  return result;
}
