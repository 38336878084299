import { AuthSession, JWT, fetchAuthSession } from "aws-amplify/auth";

export const getAutoRefreshedAuthSession =
  async (): Promise<AuthSession | null> => {
    // https://docs.amplify.aws/react/build-a-backend/auth/connect-your-frontend/manage-user-sessions/
    const session = await fetchAuthSession();
    const idToken = session.tokens?.idToken;
    if (!idToken || !idToken.payload || !idToken.payload.exp) return null;
    const expiryTime = idToken.payload.exp * 1000; // Convert to milliseconds
    const currentTime = new Date().getTime();
    const refreshThreshold = 5 * 60 * 1000; // 5 minutes in milliseconds

    // Check if the token will expire within the next 5 minutes, or has already expired
    const effectivelyExpired = expiryTime - currentTime < refreshThreshold;
    if (!effectivelyExpired) return session;
    return await fetchAuthSession({ forceRefresh: true });
  };

export const getAutoRefreshedAccessToken = async (): Promise<JWT | null> => {
  const session = await getAutoRefreshedAuthSession();
  if (!session) return null;
  const idToken = session.tokens?.idToken;
  if (!idToken) return null;
  return idToken;
};
