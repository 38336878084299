import {
  assertUnreachable,
  EPS,
  lowerBoundTable,
  parseCatalogNumberExact,
} from "../../lib/utils";
import { isDrainage, isFire, RingMainCalculationMethod } from "../config";
import { CoreEdgeObjectConcrete } from "../coreObjects";
import CoreConduit from "../coreObjects/coreConduit";
import CoreDirectedValve from "../coreObjects/coreDirectedValve";
import { NoFlowAvailableReason } from "../document/calculations-objects/conduit-calculations";
import { getFlowSystemLayouts } from "../document/calculations-objects/utils";
import type { Warnings } from "../document/calculations-objects/warning-definitions";
import { addWarning } from "../document/calculations-objects/warnings";
import { isPipeEntity } from "../document/entities/conduit-entity";
import { ValveType } from "../document/entities/directed-valves/valve-types";
import { EntityType } from "../document/entities/types";
import CalculationEngine from "./calculation-engine";
import { TraceCalculation } from "./flight-data-recorder";
import { FlowAssignment } from "./flow-assignment";
import { adjustPathHardyCross } from "./flow-solver";
import { GlobalFDR } from "./global-fdr";
import { Edge } from "./graph";
import { EdgeType, FlowEdge, FlowNode, PipeConfiguration } from "./types";
import {
  compareWaterPsdCounts,
  countPsdProfile,
  lookupFlowRate,
  mergePsdProfile,
  PsdCountEntry,
  PsdProfile,
} from "./utils";

export class RingMainCalculator {
  engine: CalculationEngine;

  // Here it is assumed that all sizable branches have been sized.
  // We will only size isolated simple rings.
  constructor(engine: CalculationEngine) {
    this.engine = engine;
  }

  @TraceCalculation("Ring Main Calculator: Identifying unsized ring mains")
  findUnsizedRingMains(): Array<Array<Edge<FlowNode, FlowEdge>>> {
    // only pipes in undirected rings form ring mains.
    const res: Array<Array<Edge<FlowNode, FlowEdge>>> = [];
    const visitedEdges = new Set<string>();
    for (const e of this.engine.flowGraph.edgeList.values()) {
      GlobalFDR.focusData([e.uid]);
      if (e.value.type === EdgeType.CONDUIT && !visitedEdges.has(e.uid)) {
        const pipeD = this.engine.getCalcByPipeId(e.value.uid);
        if (!pipeD) {
          continue;
        }

        if (pipeD.pCalc.totalPeakFlowRateLS === null) {
          const ret = this.engine.flowGraph.getCycleCovering(
            visitedEdges,
            e,
            true,
            (e) => {
              switch (e.value.type) {
                case EdgeType.CONDUIT:
                  // don't size something that's already sized as a return. Ring mains can't be on returns.
                  const cycleD = this.engine.getCalcByPipeId(e.value.uid);
                  if (!cycleD) {
                    return false;
                  }

                  const isReturn =
                    !(
                      cycleD.pCalc.configuration === null ||
                      cycleD.pCalc.configuration === PipeConfiguration.NORMAL
                    ) && cycleD.pCalc.totalPeakFlowRateLS === null;
                  if (isReturn) {
                    return false;
                  }

                  // don't size something that is a drainage
                  return !isDrainage(
                    this.engine.drawing.metadata.flowSystems[
                      cycleD.pEntity.systemUid
                    ],
                  );
                case EdgeType.FITTING_FLOW:
                case EdgeType.ISOLATION_THROUGH:
                case EdgeType.BALANCING_THROUGH:
                  return true; // because undirected
                case EdgeType.BIG_VALVE_HOT_HOT:
                case EdgeType.BIG_VALVE_HOT_WARM:
                case EdgeType.BIG_VALVE_COLD_WARM:
                case EdgeType.BIG_VALVE_COLD_COLD:
                case EdgeType.FLOW_SOURCE_EDGE:
                case EdgeType.CHECK_THROUGH:
                case EdgeType.PLANT_THROUGH:
                case EdgeType.RETURN_PUMP:
                case EdgeType.PLANT_PREHEAT:
                  return false; // because directed, and can't form ring main
              }
              assertUnreachable(e.value.type);
            },
          );
          if (ret) {
            res.push(ret);
          }
        }
      }
    }

    return res;
  }

  static setNoFlowReasonForRing(
    context: CalculationEngine,
    ring: Array<Edge<FlowNode, FlowEdge>>,
    reason: NoFlowAvailableReason,
  ) {
    for (const r of ring) {
      if (r.value.type === EdgeType.CONDUIT) {
        const pipeD = context.getCalcByPipeId(r.value.uid);
        if (!pipeD) {
          // TODO: chuck a fit
          continue;
        }
        pipeD.pCalc.noFlowAvailableReason = reason;
      }
    }
  }

  @TraceCalculation("Adding warning for ring")
  static addWarningForRing(
    context: CalculationEngine,
    ring: Array<Edge<FlowNode, FlowEdge>>,
    warning: Warnings,
  ) {
    for (const r of ring) {
      if (r.value.type === EdgeType.CONDUIT) {
        const o = context.globalStore.get<CoreConduit>(r.value.uid);
        if (!isPipeEntity(o.entity)) continue;
        const calc = context.globalStore.getOrCreateCalculation(o.entity);
        if (calc.fireFlowRateLS) {
          continue;
        }
        const liveCalc = context.globalStore.getOrCreateLiveCalculation(
          o.entity,
        );
        const thisIsDrainage = isDrainage(
          context.drawing.metadata.flowSystems[o.entity.systemUid],
        );
        addWarning(context, warning, [o.entity], {
          mode: thisIsDrainage ? "drainage" : null,
        });
        liveCalc.warnings.push({
          instanceId: context.nextLiveWarningInstanceId++,
          type: warning,
          layouts: getFlowSystemLayouts(
            context.drawing.metadata.flowSystems[o.entity.systemUid],
          ).layouts,
        });
      }
    }
  }

  @TraceCalculation("Calculating ring demands and sources")
  static getRingAttributes(
    context: CalculationEngine,
    ring: Array<Edge<FlowNode, FlowEdge>>,
  ): {
    sourceNode: string | null;
    sinks: Array<[FlowNode, PsdProfile]>;
    totalPsd: PsdProfile;
    systemUid: string | null;
  } {
    // find source in ring. At the same time, eliminate cases where there are multiple sources,
    // ambiguous pipes leading in/out.
    const pipesInRing = new Set<string>();

    for (const r of ring) {
      if (r.value.type === EdgeType.CONDUIT) {
        pipesInRing.add(r.value.uid);
      }
    }

    let sourceNode: string | null = null;
    const sinks: Array<[FlowNode, PsdProfile]> = [];
    const totalPsd = new PsdProfile();
    let systemUid: string | null = null;
    for (const edge of ring) {
      GlobalFDR.focusData([edge.uid]);
      const nuid = edge.to.connectable;
      if (edge.value.type === EdgeType.CONDUIT) {
        systemUid = context.globalStore.get<CoreConduit>(edge.value.uid).entity
          .systemUid;
        const conns = context.globalStore.getConnections(nuid);

        const psd = new PsdProfile();

        for (const cuid of conns) {
          if (pipesInRing.has(cuid)) {
            continue;
          }
          const pipeD = context.getCalcByPipeId(cuid);
          if (!pipeD) {
            // TODO: chuck a fit, or maybe support all types of conduits.
            continue;
          }
          if (pipeD.pCalc.totalPeakFlowRateLS === null) {
            this.setNoFlowReasonForRing(
              context,
              ring,
              NoFlowAvailableReason.UNUSUAL_CONFIGURATION,
            );
            continue;
          }

          if (pipeD.pCalc.flowFrom === null) {
            if (pipeD.pCalc.totalPeakFlowRateLS !== 0) {
              // this can happen if there is a figure 8.
              // throw new Error("missing flow from attribute");
              console.error(
                "missing flow from attribute in a pipe in a ring, when it should be there. This could be caused by multiple conjoined loops.",
              );
            }
          }

          if (pipeD.pCalc.flowFrom !== nuid) {
            // is a source, flowing in.
            if (sourceNode) {
              this.setNoFlowReasonForRing(
                context,
                ring,
                NoFlowAvailableReason.TOO_MANY_FLOW_SOURCES,
              );
              continue;
            }
            sourceNode = nuid;
          } else {
            // is a sink, flowing out
            if (pipeD.pCalc.psdProfile !== null) {
              mergePsdProfile(psd, pipeD.pCalc.psdProfile);
            }
          }
        }

        if (psd.size !== 0) {
          mergePsdProfile(totalPsd, psd);
          sinks.push([edge.to, psd]);
        }
      }
    }

    return {
      sourceNode,
      systemUid,
      sinks,
      totalPsd,
    };
  }

  // Assigns flow equally via PSD.
  @TraceCalculation("Sizing single ring main")
  static sizeSingleRing(
    context: CalculationEngine,
    ring: Array<Edge<FlowNode, FlowEdge>>,
  ): FlowAssignment | null {
    const { sourceNode, systemUid, totalPsd, sinks } = this.getRingAttributes(
      context,
      ring,
    );

    if (sourceNode === null || systemUid === null) {
      // TODO: everything zero. But wait, this is kinda impossible since we should be sized already at 0...
      return null;
    }

    // now divide equally among us.
    const psdCountTotal = countPsdProfile(context, totalPsd);
    const totalFRLS = lookupFlowRate(
      context,
      psdCountTotal,
      systemUid,
      totalPsd,
    )!;

    const sinkFlowsLS = new Map<string, number>();
    for (const [suid, profile] of sinks) {
      GlobalFDR.focusData([suid.connectable]);
      const pcount = countPsdProfile(context, profile);
      if (totalFRLS.fromDwellings) {
        sinkFlowsLS.set(
          suid.connectable,
          (totalFRLS.flowRateLS * pcount.dwellings) / psdCountTotal.dwellings,
        );
      } else {
        const fromUnits = totalFRLS.flowRateLS - psdCountTotal.continuousFlowLS;
        const fromUnitsAdjusted = fromUnits
          ? (fromUnits * pcount.units) / psdCountTotal.units
          : 0;
        sinkFlowsLS.set(
          suid.connectable,
          fromUnitsAdjusted + pcount.continuousFlowLS,
        );
      }
    }
    // the total flow in the ring might be higher than the calculated one to adjust for correlation
    let actualTotalFlowLS = 0;
    for (const f of sinkFlowsLS.values()) {
      actualTotalFlowLS += f;
    }

    // initialize any flow from the only source to each of the sinks, along the ring.
    // Fix the flow at the start to zero. Recreate one possible flow scenario.

    let currFlow = 0;
    const assignment = new FlowAssignment();

    for (const r of ring) {
      GlobalFDR.focusData([r.value.uid]);
      if (r.value.type === EdgeType.CONDUIT) {
        if (sinkFlowsLS.has(r.from.connectable)) {
          currFlow -= sinkFlowsLS.get(r.from.connectable)!;
        }
        if (r.from.connectable === sourceNode) {
          currFlow += actualTotalFlowLS;
        }
      }

      assignment.addFlow(r.uid, context.flowGraph.sn(r.from), currFlow);
    }

    if (Math.abs(currFlow) > EPS) {
      throw new Error("somehow this didn't work");
    }

    // initial sizes
    for (const r of ring) {
      GlobalFDR.focusData([r.value.uid]);
      if (r.value.type === EdgeType.CONDUIT) {
        const pipeD = context.getCalcByPipeId(r.value.uid);
        if (!pipeD) {
          // TODO: chuck a fit
          continue;
        }
        let initialSize = lowerBoundTable(
          CoreConduit.getPipeManufacturerCatalogPage(context, pipeD.pEntity)!,
          0,
        );
        if (pipeD.pEntity.conduit.diameterMM) {
          // there is a custom diameter
          initialSize = context.getPipeByNominal(
            pipeD.pEntity,
            pipeD.pEntity.conduit.diameterMM,
          );
        }
        if (initialSize) {
          pipeD.pCalc.realNominalPipeDiameterMM = parseCatalogNumberExact(
            initialSize.diameterNominalMM,
          );
          pipeD.pCalc.realInternalDiameterMM = parseCatalogNumberExact(
            initialSize.diameterInternalMM,
          );
          pipeD.pCalc.realOutsideDiameterMM = parseCatalogNumberExact(
            initialSize.diameterOutsideMM,
          );
        }
      }
    }

    let peakFlowFromIsolation = new FlowAssignment();
    let psdAssignmentFromIsolation = new Map<string, PsdCountEntry>();

    switch (
      context.drawing.metadata.calculationParams.ringMainCalculationMethod
    ) {
      case RingMainCalculationMethod.ISOLATION_CASES:
      case RingMainCalculationMethod.MAX_DISTRIBUTED_AND_ISOLATION_CASES:
        [peakFlowFromIsolation, psdAssignmentFromIsolation] =
          this.sizeRingWithIsolationScenarios(
            context,
            ring,
            sourceNode,
            sinks,
            systemUid,
          );
        break;
      case RingMainCalculationMethod.PSD_FLOW_RATE_DISTRIBUTED:
        break;
      default:
        assertUnreachable(
          context.drawing.metadata.calculationParams.ringMainCalculationMethod,
        );
    }

    switch (
      context.drawing.metadata.calculationParams.ringMainCalculationMethod
    ) {
      case RingMainCalculationMethod.PSD_FLOW_RATE_DISTRIBUTED:
        break;
      case RingMainCalculationMethod.MAX_DISTRIBUTED_AND_ISOLATION_CASES:
        break;
      case RingMainCalculationMethod.ISOLATION_CASES:
        for (const r of ring) {
          GlobalFDR.focusData([r.value.uid]);
          if (r.value.type === EdgeType.CONDUIT) {
            const pipeD = context.getCalcByPipeId(r.value.uid);
            if (!pipeD) {
              // TODO: chuck a fit
              continue;
            }
            if (peakFlowFromIsolation.has(r.value.uid)) {
              context.setPipePSDFlowRate(
                pipeD.pEntity,
                Math.abs(peakFlowFromIsolation.getFlow(r.value.uid)),
              );
            }
            pipeD.pCalc.psdUnits =
              psdAssignmentFromIsolation.get(pipeD.pipe.uid) || null;
            pipeD.pCalc.configuration = PipeConfiguration.RING_MAIN;
          }
        }
        return peakFlowFromIsolation;
      default:
        assertUnreachable(
          context.drawing.metadata.calculationParams.ringMainCalculationMethod,
        );
    }
    // 10 MAX_ITERS
    for (let i = 0; i < 10; i++) {
      let adjustments = 0;

      switch (
        context.drawing.metadata.calculationParams.ringMainCalculationMethod
      ) {
        case RingMainCalculationMethod.PSD_FLOW_RATE_DISTRIBUTED:
        case RingMainCalculationMethod.MAX_DISTRIBUTED_AND_ISOLATION_CASES:
          adjustments = adjustPathHardyCross(
            assignment,
            ring,
            context.flowGraph,
            0,
            context,
          ).adj;
          break;
        default:
          assertUnreachable(
            context.drawing.metadata.calculationParams
              .ringMainCalculationMethod,
          );
      }

      for (const r of ring) {
        GlobalFDR.focusData([r.value.uid]);
        if (r.value.type === EdgeType.CONDUIT) {
          const pipeD = context.getCalcByPipeId(r.value.uid);

          if (!pipeD) {
            // TODO: chuck a fit
            continue;
          }

          context.setPipePSDFlowRate(
            pipeD.pEntity,
            Math.max(
              Math.abs(peakFlowFromIsolation.getFlow(r.value.uid)),
              Math.abs(assignment.getFlow(r.uid)),
            ),
          );
          pipeD.pCalc.configuration = PipeConfiguration.RING_MAIN;
        }
      }

      if (adjustments < EPS) {
        break;
      }
    }
    return assignment;
  }

  @TraceCalculation(
    "Identifying isolation valves on ring main",
    (_1, _2, s) => [s],
  )
  static getRingIsolationLocations(
    context: CalculationEngine,
    ring: Array<Edge<FlowNode, FlowEdge>>,
    sourceNode: string,
  ): {
    isolationLocations: number[];
    sourceIx: number;
  } {
    const sourceIx = ring.findIndex((e) => e.to.connectable === sourceNode);
    if (sourceIx === -1) {
      throw new Error("source not found");
    }

    // find extreme of the isolation valves
    const isolationLocations: number[] = [];
    for (let i = 0; i < ring.length; i++) {
      const ix = (sourceIx + i) % ring.length;
      if (ring[ix].value.type === EdgeType.ISOLATION_THROUGH) {
        const obj = context.globalStore.get<CoreDirectedValve>(
          ring[ix].to.connectable,
        );
        if (obj.entity.valve.type !== ValveType.ISOLATION_VALVE) {
          throw new Error("misconfigured flow graph");
        }
        if (obj.entity.valve.makeIsolationCaseOnRingMains) {
          isolationLocations.push(ix);
        }
      }
    }

    return { isolationLocations, sourceIx };
  }

  @TraceCalculation(
    "Sizing ring using isolation scenario strategy",
    (_1, _2, sn, _3, _4) => [sn],
  )
  static sizeRingWithIsolationScenarios(
    context: CalculationEngine,
    ring: Array<Edge<FlowNode, FlowEdge>>,
    sourceNode: string,
    sinks: Array<[FlowNode, PsdProfile]>,
    systemUid: string,
  ): [FlowAssignment, Map<string, PsdCountEntry>] {
    // For this problem, imagine that the source is at 12 o'clock as a visualization only.
    const { isolationLocations, sourceIx } = this.getRingIsolationLocations(
      context,
      ring,
      sourceNode,
    );

    if (isolationLocations.length < 2) {
      this.setNoFlowReasonForRing(
        context,
        ring,
        NoFlowAvailableReason.NO_ISOLATION_VALVES_ON_MAIN,
      );
      // this.addWarningForRing(
      //   context,
      //   ring,
      //   Warning.ISOLATION_VALVES_REQUIRED_ON_RING_MAIN
      // );
      return [new FlowAssignment(), new Map()];
    } else {
      // now give some pipe directions to the known pipes in the main - the ones
      // from the source to the first isolation valves left and right.
      for (const dir of [-1, 1]) {
        for (let i = 0; i < ring.length; i++) {
          const ix = (sourceIx + 1 + i * dir + ring.length) % ring.length;
          if (ix === isolationLocations[0] || ix === isolationLocations[1]) {
            break;
          }
          if (ring[ix].value.type === EdgeType.CONDUIT) {
            const calc = context.globalStore.getOrCreateCalculation(
              context.globalStore.get<CoreConduit>(ring[ix].value.uid).entity,
            );
            const myConnectables = [
              ring[ix].from.connectable,
              ring[ix].to.connectable,
            ];
            const prevConnectables = [
              ring[(ix + ring.length - dir) % ring.length].from.connectable,
              ring[(ix + ring.length - dir) % ring.length].to.connectable,
            ];
            calc.flowFrom = myConnectables.filter((c) =>
              prevConnectables.includes(c),
            )[0];
          }
        }
      }
    }

    const sinksByConnectable = new Map<string, PsdProfile>();
    for (const [fn, psd] of sinks) {
      sinksByConnectable.set(fn.connectable, psd);
    }

    const aggregatePsd = new Map<string, PsdCountEntry>();
    const leftToRight = new FlowAssignment();
    // From rightmost isolation valve, work backwards
    let psd = new PsdProfile();
    for (let i = 0; i < ring.length; i++) {
      const ix =
        (isolationLocations[isolationLocations.length - 1] - i + ring.length) %
        ring.length;
      if (ix === sourceIx) {
        break;
      }

      if (ring[ix].value.type === EdgeType.CONDUIT) {
        if (sinksByConnectable.has(ring[ix].to.connectable)) {
          mergePsdProfile(
            psd,
            sinksByConnectable.get(ring[ix].to.connectable)!,
          );
        }

        const psdCount = countPsdProfile(context, psd);
        const fr = lookupFlowRate(context, psdCount, systemUid)!;
        leftToRight.addFlow(
          ring[ix].value.uid,
          ring[ix].from.connectable,
          fr.flowRateLS,
        );
        aggregatePsd.set(ring[ix].value.uid, psdCount);
      }
    }
    // ditto for left to right
    const rightToLeft = new FlowAssignment();
    psd = new PsdProfile();
    for (let i = 0; i < ring.length; i++) {
      const ix = (isolationLocations[0] + i + ring.length) % ring.length;

      if (ring[ix].value.type === EdgeType.CONDUIT) {
        if (sinksByConnectable.has(ring[ix].from.connectable)) {
          mergePsdProfile(
            psd,
            sinksByConnectable.get(ring[ix].from.connectable)!,
          );
        }

        const psdCount = countPsdProfile(context, psd);
        const fr = lookupFlowRate(context, psdCount, systemUid)!;
        rightToLeft.addFlow(
          ring[ix].value.uid,
          ring[ix].to.connectable,
          fr.flowRateLS,
        );
        if (aggregatePsd.has(ring[ix].value.uid)) {
          const cmp = compareWaterPsdCounts(
            aggregatePsd.get(ring[ix].value.uid)!,
            psdCount,
          );
          if (cmp !== null && cmp < 0) {
            aggregatePsd.set(ring[ix].value.uid, psdCount);
          }
        } else {
          aggregatePsd.set(ring[ix].value.uid, psdCount);
        }
      }

      if (ix === sourceIx) {
        break;
      }
    }

    // aggregate left to right
    const aggregate = new FlowAssignment();
    for (const edge of new Set([
      ...leftToRight.keys(),
      ...rightToLeft.keys(),
    ])) {
      const o = context.globalStore.get(edge)!;

      if (
        Math.abs(rightToLeft.getFlow(edge)) >
        Math.abs(leftToRight.getFlow(edge))
      ) {
        aggregate.set(edge, rightToLeft.get(edge)!);
      } else {
        aggregate.set(edge, leftToRight.get(edge)!);
      }
    }
    return [aggregate, aggregatePsd];
  }

  @TraceCalculation("Calculating all ring mains")
  calculateAllRings() {
    const rings = this.findUnsizedRingMains();
    for (const r of rings) {
      let fire = true;
      for (const e of r) {
        const obj = this.engine.globalStore.get<CoreEdgeObjectConcrete>(
          e.value.uid,
        )!;
        if (obj.type !== EntityType.CONDUIT) continue;
        if (
          !isFire(
            this.engine.drawing.metadata.flowSystems[obj.entity.systemUid],
          )
        ) {
          fire = false;
        }
      }
      if (fire === false) RingMainCalculator.sizeSingleRing(this.engine, r);
    }
  }
}
