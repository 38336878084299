// this file used to rely on functionality only present in vue2 but not vue3
// mitt is a lightweight event emitter that serves the same purpose as the prior vue code.
// https://dev.to/razi91/event-bus-with-vue-3-and-typescript-2a6l

import mitt from "mitt";

const mittInstance = mitt();

// the prior vue2 code had methods like '.on' prefixed with '$' ala '.$on'.
// preserve this syntax
type Mitt = typeof mittInstance;
(mittInstance as any).$emit = mittInstance.emit;
(mittInstance as any).$on = mittInstance.on;
(mittInstance as any).$off = mittInstance.off;
type LegacyEmit = (key: string, arg?: any) => void;
type LegacyOn = (key: string, arg: any) => void;
type LegacyOff = (key: string, arg: any) => void;
type EnhancedMitt = Mitt & {
  $emit: LegacyEmit;
  $on: LegacyOn;
  $off: LegacyOff;
};

export const MainEventBus = mittInstance as EnhancedMitt;
