import { assertUnreachable } from "../../../lib/utils";
import { DuctPhysicalMaterial } from "../../config";
import { Manufacturer } from "../types";

export type DuctNominalDiameterMM = number;
export type DuctHeightMM = number;
export type DuctWidthMM = number;
export type DuctManufacturerId = string;
export type NumVanes = number;

export type DuctPriceTableMaterials =
  | "Hot Dip Galvanised Steel"
  | "Flexible Duct"
  | "Galvanised Steel"
  | "Carbon Steel"
  | "PVC"
  | "Aluminium"
  | "Fibrous Glass Duct"
  | "Semi-Rigid";

export type DuctManufacturer = Manufacturer<DuctPriceTableMaterials>;

export interface DuctManufacturerSpec {
  circularByDiameter: {
    // For ducts, nominal is inside usually.
    [key: DuctNominalDiameterMM]: DuctCircularSizeSpec;
  };
  rectangularByHeightWidth: {
    [key: DuctHeightMM]: { [key: DuctWidthMM]: DuctRectangularSizeSpec };
  };
  colebrookWhiteCoefficient: number;
}

export function isDuctMaterialFlex(material: DuctPhysicalMaterial) {
  switch (material) {
    case "aluminium":
    case "carbonSteel":
    case "galvanisedSteel":
    case "hotDipGalvanisedSteel":
    case "pvc":
      return false;
    case "fibrousGlass":
    case "flexibleDuct":
    case "semiRigid":
      return true;
    default:
      assertUnreachable(material);
  }
}

export interface DuctSpec {
  name: string;
  uid: string;
  abbreviation: string;
  manufacturers: DuctManufacturer[];

  data: {
    [key: DuctManufacturerId]: DuctManufacturerSpec;
  };
}

// Let's have this struct in case there's stuff like external diameters.
export interface DuctCircularSizeSpec {
  internalDiameterMM: number;
}

export interface DuctRectangularSizeSpec {
  internalHeightMM: number;
  internalWidthMM: number;
  WHRatio: number;
}

export type DuctCatalogPage = Partial<{
  [key in DuctPhysicalMaterial]: DuctSpec;
}>;

export const DUCTS_DATA: DuctCatalogPage = {
  aluminium: {
    name: "Aluminium",
    uid: "aluminium",
    abbreviation: "AL",
    manufacturers: [
      {
        name: "Generic",
        abbreviation: "Generic",
        priceTableName: "Aluminium",
        uid: "generic",
        isGeneric: true,
      },
    ],
    data: {
      generic: {
        circularByDiameter: {
          63: { internalDiameterMM: 63 },
          75: { internalDiameterMM: 75 },
          80: { internalDiameterMM: 80 },
          90: { internalDiameterMM: 90 },
          100: { internalDiameterMM: 100 },
          125: { internalDiameterMM: 125 },
          160: { internalDiameterMM: 160 },
          200: { internalDiameterMM: 200 },
          250: { internalDiameterMM: 250 },
          315: { internalDiameterMM: 315 },
          355: { internalDiameterMM: 355 },
          400: { internalDiameterMM: 400 },
          450: { internalDiameterMM: 450 },
          500: { internalDiameterMM: 500 },
          560: { internalDiameterMM: 560 },
          630: { internalDiameterMM: 630 },
          710: { internalDiameterMM: 710 },
          800: { internalDiameterMM: 800 },
          900: { internalDiameterMM: 900 },
          1000: { internalDiameterMM: 1000 },
          1120: { internalDiameterMM: 1120 },
          1250: { internalDiameterMM: 1250 },
        },
        rectangularByHeightWidth: {
          100: {
            100: {
              internalHeightMM: 100,
              internalWidthMM: 100,
              WHRatio: 1.0,
            },
            150: {
              internalHeightMM: 100,
              internalWidthMM: 150,
              WHRatio: 1.5,
            },
            200: {
              internalHeightMM: 100,
              internalWidthMM: 200,
              WHRatio: 2.0,
            },
            250: {
              internalHeightMM: 100,
              internalWidthMM: 250,
              WHRatio: 2.5,
            },
            300: {
              internalHeightMM: 100,
              internalWidthMM: 300,
              WHRatio: 3.0,
            },
            400: {
              internalHeightMM: 100,
              internalWidthMM: 400,
              WHRatio: 4.0,
            },
          },
          150: {
            100: {
              internalHeightMM: 150,
              internalWidthMM: 100,
              WHRatio: 0.6666666666666666,
            },
            150: {
              internalHeightMM: 150,
              internalWidthMM: 150,
              WHRatio: 1.0,
            },
            200: {
              internalHeightMM: 150,
              internalWidthMM: 200,
              WHRatio: 1.3333333333333333,
            },
            250: {
              internalHeightMM: 150,
              internalWidthMM: 250,
              WHRatio: 1.6666666666666667,
            },
            300: {
              internalHeightMM: 150,
              internalWidthMM: 300,
              WHRatio: 2.0,
            },
            400: {
              internalHeightMM: 150,
              internalWidthMM: 400,
              WHRatio: 2.6666666666666665,
            },
            500: {
              internalHeightMM: 150,
              internalWidthMM: 500,
              WHRatio: 3.3333333333333335,
            },
            600: {
              internalHeightMM: 150,
              internalWidthMM: 600,
              WHRatio: 4.0,
            },
          },
          200: {
            100: {
              internalHeightMM: 200,
              internalWidthMM: 100,
              WHRatio: 0.5,
            },
            150: {
              internalHeightMM: 200,
              internalWidthMM: 150,
              WHRatio: 0.75,
            },
            200: {
              internalHeightMM: 200,
              internalWidthMM: 200,
              WHRatio: 1.0,
            },
            250: {
              internalHeightMM: 200,
              internalWidthMM: 250,
              WHRatio: 1.25,
            },
            300: {
              internalHeightMM: 200,
              internalWidthMM: 300,
              WHRatio: 1.5,
            },
            400: {
              internalHeightMM: 200,
              internalWidthMM: 400,
              WHRatio: 2.0,
            },
            500: {
              internalHeightMM: 200,
              internalWidthMM: 500,
              WHRatio: 2.5,
            },
            600: {
              internalHeightMM: 200,
              internalWidthMM: 600,
              WHRatio: 3.0,
            },
            800: {
              internalHeightMM: 200,
              internalWidthMM: 800,
              WHRatio: 4.0,
            },
          },
          250: {
            100: {
              internalHeightMM: 250,
              internalWidthMM: 100,
              WHRatio: 0.4,
            },
            150: {
              internalHeightMM: 250,
              internalWidthMM: 150,
              WHRatio: 0.6,
            },
            200: {
              internalHeightMM: 250,
              internalWidthMM: 200,
              WHRatio: 0.8,
            },
            250: {
              internalHeightMM: 250,
              internalWidthMM: 250,
              WHRatio: 1.0,
            },
            300: {
              internalHeightMM: 250,
              internalWidthMM: 300,
              WHRatio: 1.2,
            },
            400: {
              internalHeightMM: 250,
              internalWidthMM: 400,
              WHRatio: 1.6,
            },
            500: {
              internalHeightMM: 250,
              internalWidthMM: 500,
              WHRatio: 2.0,
            },
            600: {
              internalHeightMM: 250,
              internalWidthMM: 600,
              WHRatio: 2.4,
            },
            800: {
              internalHeightMM: 250,
              internalWidthMM: 800,
              WHRatio: 3.2,
            },
            1000: {
              internalHeightMM: 250,
              internalWidthMM: 1000,
              WHRatio: 4.0,
            },
          },
          300: {
            100: {
              internalHeightMM: 300,
              internalWidthMM: 100,
              WHRatio: 0.3333333333333333,
            },
            150: {
              internalHeightMM: 300,
              internalWidthMM: 150,
              WHRatio: 0.5,
            },
            200: {
              internalHeightMM: 300,
              internalWidthMM: 200,
              WHRatio: 0.6666666666666666,
            },
            250: {
              internalHeightMM: 300,
              internalWidthMM: 250,
              WHRatio: 0.8333333333333334,
            },
            300: {
              internalHeightMM: 300,
              internalWidthMM: 300,
              WHRatio: 1.0,
            },
            400: {
              internalHeightMM: 300,
              internalWidthMM: 400,
              WHRatio: 1.3333333333333333,
            },
            500: {
              internalHeightMM: 300,
              internalWidthMM: 500,
              WHRatio: 1.6666666666666667,
            },
            600: {
              internalHeightMM: 300,
              internalWidthMM: 600,
              WHRatio: 2.0,
            },
            800: {
              internalHeightMM: 300,
              internalWidthMM: 800,
              WHRatio: 2.6666666666666665,
            },
            1000: {
              internalHeightMM: 300,
              internalWidthMM: 1000,
              WHRatio: 3.3333333333333335,
            },
            1200: {
              internalHeightMM: 300,
              internalWidthMM: 1200,
              WHRatio: 4.0,
            },
          },
          400: {
            100: {
              internalHeightMM: 400,
              internalWidthMM: 100,
              WHRatio: 0.25,
            },
            150: {
              internalHeightMM: 400,
              internalWidthMM: 150,
              WHRatio: 0.375,
            },
            200: {
              internalHeightMM: 400,
              internalWidthMM: 200,
              WHRatio: 0.5,
            },
            250: {
              internalHeightMM: 400,
              internalWidthMM: 250,
              WHRatio: 0.625,
            },
            300: {
              internalHeightMM: 400,
              internalWidthMM: 300,
              WHRatio: 0.75,
            },
            400: {
              internalHeightMM: 400,
              internalWidthMM: 400,
              WHRatio: 1.0,
            },
            500: {
              internalHeightMM: 400,
              internalWidthMM: 500,
              WHRatio: 1.25,
            },
            600: {
              internalHeightMM: 400,
              internalWidthMM: 600,
              WHRatio: 1.5,
            },
            800: {
              internalHeightMM: 400,
              internalWidthMM: 800,
              WHRatio: 2.0,
            },
            1000: {
              internalHeightMM: 400,
              internalWidthMM: 1000,
              WHRatio: 2.5,
            },
            1200: {
              internalHeightMM: 400,
              internalWidthMM: 1200,
              WHRatio: 3.0,
            },
            1400: {
              internalHeightMM: 400,
              internalWidthMM: 1400,
              WHRatio: 3.5,
            },
            1600: {
              internalHeightMM: 400,
              internalWidthMM: 1600,
              WHRatio: 4.0,
            },
          },
          500: {
            150: {
              internalHeightMM: 500,
              internalWidthMM: 150,
              WHRatio: 0.3,
            },
            200: {
              internalHeightMM: 500,
              internalWidthMM: 200,
              WHRatio: 0.4,
            },
            250: {
              internalHeightMM: 500,
              internalWidthMM: 250,
              WHRatio: 0.5,
            },
            300: {
              internalHeightMM: 500,
              internalWidthMM: 300,
              WHRatio: 0.6,
            },
            400: {
              internalHeightMM: 500,
              internalWidthMM: 400,
              WHRatio: 0.8,
            },
            500: {
              internalHeightMM: 500,
              internalWidthMM: 500,
              WHRatio: 1.0,
            },
            600: {
              internalHeightMM: 500,
              internalWidthMM: 600,
              WHRatio: 1.2,
            },
            800: {
              internalHeightMM: 500,
              internalWidthMM: 800,
              WHRatio: 1.6,
            },
            1000: {
              internalHeightMM: 500,
              internalWidthMM: 1000,
              WHRatio: 2.0,
            },
            1200: {
              internalHeightMM: 500,
              internalWidthMM: 1200,
              WHRatio: 2.4,
            },
            1400: {
              internalHeightMM: 500,
              internalWidthMM: 1400,
              WHRatio: 2.8,
            },
            1600: {
              internalHeightMM: 500,
              internalWidthMM: 1600,
              WHRatio: 3.2,
            },
            1800: {
              internalHeightMM: 500,
              internalWidthMM: 1800,
              WHRatio: 3.6,
            },
            2000: {
              internalHeightMM: 500,
              internalWidthMM: 2000,
              WHRatio: 4.0,
            },
          },
          600: {
            150: {
              internalHeightMM: 600,
              internalWidthMM: 150,
              WHRatio: 0.25,
            },
            200: {
              internalHeightMM: 600,
              internalWidthMM: 200,
              WHRatio: 0.3333333333333333,
            },
            250: {
              internalHeightMM: 600,
              internalWidthMM: 250,
              WHRatio: 0.4166666666666667,
            },
            300: {
              internalHeightMM: 600,
              internalWidthMM: 300,
              WHRatio: 0.5,
            },
            400: {
              internalHeightMM: 600,
              internalWidthMM: 400,
              WHRatio: 0.6666666666666666,
            },
            500: {
              internalHeightMM: 600,
              internalWidthMM: 500,
              WHRatio: 0.8333333333333334,
            },
            600: {
              internalHeightMM: 600,
              internalWidthMM: 600,
              WHRatio: 1.0,
            },
            800: {
              internalHeightMM: 600,
              internalWidthMM: 800,
              WHRatio: 1.3333333333333333,
            },
            1000: {
              internalHeightMM: 600,
              internalWidthMM: 1000,
              WHRatio: 1.6666666666666667,
            },
            1200: {
              internalHeightMM: 600,
              internalWidthMM: 1200,
              WHRatio: 2.0,
            },
            1400: {
              internalHeightMM: 600,
              internalWidthMM: 1400,
              WHRatio: 2.3333333333333335,
            },
            1600: {
              internalHeightMM: 600,
              internalWidthMM: 1600,
              WHRatio: 2.6666666666666665,
            },
            1800: {
              internalHeightMM: 600,
              internalWidthMM: 1800,
              WHRatio: 3.0,
            },
            2000: {
              internalHeightMM: 600,
              internalWidthMM: 2000,
              WHRatio: 3.3333333333333335,
            },
          },
          800: {
            200: {
              internalHeightMM: 800,
              internalWidthMM: 200,
              WHRatio: 0.25,
            },
            250: {
              internalHeightMM: 800,
              internalWidthMM: 250,
              WHRatio: 0.3125,
            },
            300: {
              internalHeightMM: 800,
              internalWidthMM: 300,
              WHRatio: 0.375,
            },
            400: {
              internalHeightMM: 800,
              internalWidthMM: 400,
              WHRatio: 0.5,
            },
            500: {
              internalHeightMM: 800,
              internalWidthMM: 500,
              WHRatio: 0.625,
            },
            600: {
              internalHeightMM: 800,
              internalWidthMM: 600,
              WHRatio: 0.75,
            },
            800: {
              internalHeightMM: 800,
              internalWidthMM: 800,
              WHRatio: 1.0,
            },
            1000: {
              internalHeightMM: 800,
              internalWidthMM: 1000,
              WHRatio: 1.25,
            },
            1200: {
              internalHeightMM: 800,
              internalWidthMM: 1200,
              WHRatio: 1.5,
            },
            1400: {
              internalHeightMM: 800,
              internalWidthMM: 1400,
              WHRatio: 1.75,
            },
            1600: {
              internalHeightMM: 800,
              internalWidthMM: 1600,
              WHRatio: 2.0,
            },
            1800: {
              internalHeightMM: 800,
              internalWidthMM: 1800,
              WHRatio: 2.25,
            },
            2000: {
              internalHeightMM: 800,
              internalWidthMM: 2000,
              WHRatio: 2.5,
            },
          },
          1000: {
            250: {
              internalHeightMM: 1000,
              internalWidthMM: 250,
              WHRatio: 0.25,
            },
            300: {
              internalHeightMM: 1000,
              internalWidthMM: 300,
              WHRatio: 0.3,
            },
            400: {
              internalHeightMM: 1000,
              internalWidthMM: 400,
              WHRatio: 0.4,
            },
            500: {
              internalHeightMM: 1000,
              internalWidthMM: 500,
              WHRatio: 0.5,
            },
            600: {
              internalHeightMM: 1000,
              internalWidthMM: 600,
              WHRatio: 0.6,
            },
            800: {
              internalHeightMM: 1000,
              internalWidthMM: 800,
              WHRatio: 0.8,
            },
            1000: {
              internalHeightMM: 1000,
              internalWidthMM: 1000,
              WHRatio: 1.0,
            },
            1200: {
              internalHeightMM: 1000,
              internalWidthMM: 1200,
              WHRatio: 1.2,
            },
            1400: {
              internalHeightMM: 1000,
              internalWidthMM: 1400,
              WHRatio: 1.4,
            },
            1600: {
              internalHeightMM: 1000,
              internalWidthMM: 1600,
              WHRatio: 1.6,
            },
            1800: {
              internalHeightMM: 1000,
              internalWidthMM: 1800,
              WHRatio: 1.8,
            },
            2000: {
              internalHeightMM: 1000,
              internalWidthMM: 2000,
              WHRatio: 2.0,
            },
          },
          1200: {
            300: {
              internalHeightMM: 1200,
              internalWidthMM: 300,
              WHRatio: 0.25,
            },
            400: {
              internalHeightMM: 1200,
              internalWidthMM: 400,
              WHRatio: 0.3333333333333333,
            },
            500: {
              internalHeightMM: 1200,
              internalWidthMM: 500,
              WHRatio: 0.4166666666666667,
            },
            600: {
              internalHeightMM: 1200,
              internalWidthMM: 600,
              WHRatio: 0.5,
            },
            800: {
              internalHeightMM: 1200,
              internalWidthMM: 800,
              WHRatio: 0.6666666666666666,
            },
            1000: {
              internalHeightMM: 1200,
              internalWidthMM: 1000,
              WHRatio: 0.8333333333333334,
            },
            1200: {
              internalHeightMM: 1200,
              internalWidthMM: 1200,
              WHRatio: 1.0,
            },
            1400: {
              internalHeightMM: 1200,
              internalWidthMM: 1400,
              WHRatio: 1.1666666666666667,
            },
            1600: {
              internalHeightMM: 1200,
              internalWidthMM: 1600,
              WHRatio: 1.3333333333333333,
            },
            1800: {
              internalHeightMM: 1200,
              internalWidthMM: 1800,
              WHRatio: 1.5,
            },
            2000: {
              internalHeightMM: 1200,
              internalWidthMM: 2000,
              WHRatio: 1.6666666666666667,
            },
          },
          1400: {
            400: {
              internalHeightMM: 1400,
              internalWidthMM: 400,
              WHRatio: 0.2857142857142857,
            },
            500: {
              internalHeightMM: 1400,
              internalWidthMM: 500,
              WHRatio: 0.35714285714285715,
            },
            600: {
              internalHeightMM: 1400,
              internalWidthMM: 600,
              WHRatio: 0.42857142857142855,
            },
            800: {
              internalHeightMM: 1400,
              internalWidthMM: 800,
              WHRatio: 0.5714285714285714,
            },
            1000: {
              internalHeightMM: 1400,
              internalWidthMM: 1000,
              WHRatio: 0.7142857142857143,
            },
            1200: {
              internalHeightMM: 1400,
              internalWidthMM: 1200,
              WHRatio: 0.8571428571428571,
            },
          },
          1600: {
            400: {
              internalHeightMM: 1600,
              internalWidthMM: 400,
              WHRatio: 0.25,
            },
            500: {
              internalHeightMM: 1600,
              internalWidthMM: 500,
              WHRatio: 0.3125,
            },
            600: {
              internalHeightMM: 1600,
              internalWidthMM: 600,
              WHRatio: 0.375,
            },
            800: {
              internalHeightMM: 1600,
              internalWidthMM: 800,
              WHRatio: 0.5,
            },
            1000: {
              internalHeightMM: 1600,
              internalWidthMM: 1000,
              WHRatio: 0.625,
            },
            1200: {
              internalHeightMM: 1600,
              internalWidthMM: 1200,
              WHRatio: 0.75,
            },
          },
          1800: {
            500: {
              internalHeightMM: 1800,
              internalWidthMM: 500,
              WHRatio: 0.2777777777777778,
            },
            600: {
              internalHeightMM: 1800,
              internalWidthMM: 600,
              WHRatio: 0.3333333333333333,
            },
            800: {
              internalHeightMM: 1800,
              internalWidthMM: 800,
              WHRatio: 0.4444444444444444,
            },
            1000: {
              internalHeightMM: 1800,
              internalWidthMM: 1000,
              WHRatio: 0.5555555555555556,
            },
            1200: {
              internalHeightMM: 1800,
              internalWidthMM: 1200,
              WHRatio: 0.6666666666666666,
            },
          },
          2000: {
            500: {
              internalHeightMM: 2000,
              internalWidthMM: 500,
              WHRatio: 0.25,
            },
            600: {
              internalHeightMM: 2000,
              internalWidthMM: 600,
              WHRatio: 0.3,
            },
            800: {
              internalHeightMM: 2000,
              internalWidthMM: 800,
              WHRatio: 0.4,
            },
            1000: {
              internalHeightMM: 2000,
              internalWidthMM: 1000,
              WHRatio: 0.5,
            },
            1200: {
              internalHeightMM: 2000,
              internalWidthMM: 1200,
              WHRatio: 0.6,
            },
          },
        },
        colebrookWhiteCoefficient: 0.03,
      },
    },
  },

  carbonSteel: {
    name: "Carbon Steel",
    uid: "carbonSteel",
    abbreviation: "CS",
    manufacturers: [
      {
        name: "Generic",
        abbreviation: "Generic",
        priceTableName: "Carbon Steel",
        uid: "generic",
        isGeneric: true,
      },
    ],
    data: {
      generic: {
        circularByDiameter: {
          63: { internalDiameterMM: 63 },
          75: { internalDiameterMM: 75 },
          80: { internalDiameterMM: 80 },
          90: { internalDiameterMM: 90 },
          100: { internalDiameterMM: 100 },
          125: { internalDiameterMM: 125 },
          160: { internalDiameterMM: 160 },
          200: { internalDiameterMM: 200 },
          250: { internalDiameterMM: 250 },
          315: { internalDiameterMM: 315 },
          355: { internalDiameterMM: 355 },
          400: { internalDiameterMM: 400 },
          450: { internalDiameterMM: 450 },
          500: { internalDiameterMM: 500 },
          560: { internalDiameterMM: 560 },
          630: { internalDiameterMM: 630 },
          710: { internalDiameterMM: 710 },
          800: { internalDiameterMM: 800 },
          900: { internalDiameterMM: 900 },
          1000: { internalDiameterMM: 1000 },
          1120: { internalDiameterMM: 1120 },
          1250: { internalDiameterMM: 1250 },
        },
        rectangularByHeightWidth: {
          100: {
            100: {
              internalHeightMM: 100,
              internalWidthMM: 100,
              WHRatio: 1.0,
            },
            150: {
              internalHeightMM: 100,
              internalWidthMM: 150,
              WHRatio: 1.5,
            },
            200: {
              internalHeightMM: 100,
              internalWidthMM: 200,
              WHRatio: 2.0,
            },
            250: {
              internalHeightMM: 100,
              internalWidthMM: 250,
              WHRatio: 2.5,
            },
            300: {
              internalHeightMM: 100,
              internalWidthMM: 300,
              WHRatio: 3.0,
            },
            400: {
              internalHeightMM: 100,
              internalWidthMM: 400,
              WHRatio: 4.0,
            },
          },
          150: {
            100: {
              internalHeightMM: 150,
              internalWidthMM: 100,
              WHRatio: 0.6666666666666666,
            },
            150: {
              internalHeightMM: 150,
              internalWidthMM: 150,
              WHRatio: 1.0,
            },
            200: {
              internalHeightMM: 150,
              internalWidthMM: 200,
              WHRatio: 1.3333333333333333,
            },
            250: {
              internalHeightMM: 150,
              internalWidthMM: 250,
              WHRatio: 1.6666666666666667,
            },
            300: {
              internalHeightMM: 150,
              internalWidthMM: 300,
              WHRatio: 2.0,
            },
            400: {
              internalHeightMM: 150,
              internalWidthMM: 400,
              WHRatio: 2.6666666666666665,
            },
            500: {
              internalHeightMM: 150,
              internalWidthMM: 500,
              WHRatio: 3.3333333333333335,
            },
            600: {
              internalHeightMM: 150,
              internalWidthMM: 600,
              WHRatio: 4.0,
            },
          },
          200: {
            100: {
              internalHeightMM: 200,
              internalWidthMM: 100,
              WHRatio: 0.5,
            },
            150: {
              internalHeightMM: 200,
              internalWidthMM: 150,
              WHRatio: 0.75,
            },
            200: {
              internalHeightMM: 200,
              internalWidthMM: 200,
              WHRatio: 1.0,
            },
            250: {
              internalHeightMM: 200,
              internalWidthMM: 250,
              WHRatio: 1.25,
            },
            300: {
              internalHeightMM: 200,
              internalWidthMM: 300,
              WHRatio: 1.5,
            },
            400: {
              internalHeightMM: 200,
              internalWidthMM: 400,
              WHRatio: 2.0,
            },
            500: {
              internalHeightMM: 200,
              internalWidthMM: 500,
              WHRatio: 2.5,
            },
            600: {
              internalHeightMM: 200,
              internalWidthMM: 600,
              WHRatio: 3.0,
            },
            800: {
              internalHeightMM: 200,
              internalWidthMM: 800,
              WHRatio: 4.0,
            },
          },
          250: {
            100: {
              internalHeightMM: 250,
              internalWidthMM: 100,
              WHRatio: 0.4,
            },
            150: {
              internalHeightMM: 250,
              internalWidthMM: 150,
              WHRatio: 0.6,
            },
            200: {
              internalHeightMM: 250,
              internalWidthMM: 200,
              WHRatio: 0.8,
            },
            250: {
              internalHeightMM: 250,
              internalWidthMM: 250,
              WHRatio: 1.0,
            },
            300: {
              internalHeightMM: 250,
              internalWidthMM: 300,
              WHRatio: 1.2,
            },
            400: {
              internalHeightMM: 250,
              internalWidthMM: 400,
              WHRatio: 1.6,
            },
            500: {
              internalHeightMM: 250,
              internalWidthMM: 500,
              WHRatio: 2.0,
            },
            600: {
              internalHeightMM: 250,
              internalWidthMM: 600,
              WHRatio: 2.4,
            },
            800: {
              internalHeightMM: 250,
              internalWidthMM: 800,
              WHRatio: 3.2,
            },
            1000: {
              internalHeightMM: 250,
              internalWidthMM: 1000,
              WHRatio: 4.0,
            },
          },
          300: {
            100: {
              internalHeightMM: 300,
              internalWidthMM: 100,
              WHRatio: 0.3333333333333333,
            },
            150: {
              internalHeightMM: 300,
              internalWidthMM: 150,
              WHRatio: 0.5,
            },
            200: {
              internalHeightMM: 300,
              internalWidthMM: 200,
              WHRatio: 0.6666666666666666,
            },
            250: {
              internalHeightMM: 300,
              internalWidthMM: 250,
              WHRatio: 0.8333333333333334,
            },
            300: {
              internalHeightMM: 300,
              internalWidthMM: 300,
              WHRatio: 1.0,
            },
            400: {
              internalHeightMM: 300,
              internalWidthMM: 400,
              WHRatio: 1.3333333333333333,
            },
            500: {
              internalHeightMM: 300,
              internalWidthMM: 500,
              WHRatio: 1.6666666666666667,
            },
            600: {
              internalHeightMM: 300,
              internalWidthMM: 600,
              WHRatio: 2.0,
            },
            800: {
              internalHeightMM: 300,
              internalWidthMM: 800,
              WHRatio: 2.6666666666666665,
            },
            1000: {
              internalHeightMM: 300,
              internalWidthMM: 1000,
              WHRatio: 3.3333333333333335,
            },
            1200: {
              internalHeightMM: 300,
              internalWidthMM: 1200,
              WHRatio: 4.0,
            },
          },
          400: {
            100: {
              internalHeightMM: 400,
              internalWidthMM: 100,
              WHRatio: 0.25,
            },
            150: {
              internalHeightMM: 400,
              internalWidthMM: 150,
              WHRatio: 0.375,
            },
            200: {
              internalHeightMM: 400,
              internalWidthMM: 200,
              WHRatio: 0.5,
            },
            250: {
              internalHeightMM: 400,
              internalWidthMM: 250,
              WHRatio: 0.625,
            },
            300: {
              internalHeightMM: 400,
              internalWidthMM: 300,
              WHRatio: 0.75,
            },
            400: {
              internalHeightMM: 400,
              internalWidthMM: 400,
              WHRatio: 1.0,
            },
            500: {
              internalHeightMM: 400,
              internalWidthMM: 500,
              WHRatio: 1.25,
            },
            600: {
              internalHeightMM: 400,
              internalWidthMM: 600,
              WHRatio: 1.5,
            },
            800: {
              internalHeightMM: 400,
              internalWidthMM: 800,
              WHRatio: 2.0,
            },
            1000: {
              internalHeightMM: 400,
              internalWidthMM: 1000,
              WHRatio: 2.5,
            },
            1200: {
              internalHeightMM: 400,
              internalWidthMM: 1200,
              WHRatio: 3.0,
            },
            1400: {
              internalHeightMM: 400,
              internalWidthMM: 1400,
              WHRatio: 3.5,
            },
            1600: {
              internalHeightMM: 400,
              internalWidthMM: 1600,
              WHRatio: 4.0,
            },
          },
          500: {
            150: {
              internalHeightMM: 500,
              internalWidthMM: 150,
              WHRatio: 0.3,
            },
            200: {
              internalHeightMM: 500,
              internalWidthMM: 200,
              WHRatio: 0.4,
            },
            250: {
              internalHeightMM: 500,
              internalWidthMM: 250,
              WHRatio: 0.5,
            },
            300: {
              internalHeightMM: 500,
              internalWidthMM: 300,
              WHRatio: 0.6,
            },
            400: {
              internalHeightMM: 500,
              internalWidthMM: 400,
              WHRatio: 0.8,
            },
            500: {
              internalHeightMM: 500,
              internalWidthMM: 500,
              WHRatio: 1.0,
            },
            600: {
              internalHeightMM: 500,
              internalWidthMM: 600,
              WHRatio: 1.2,
            },
            800: {
              internalHeightMM: 500,
              internalWidthMM: 800,
              WHRatio: 1.6,
            },
            1000: {
              internalHeightMM: 500,
              internalWidthMM: 1000,
              WHRatio: 2.0,
            },
            1200: {
              internalHeightMM: 500,
              internalWidthMM: 1200,
              WHRatio: 2.4,
            },
            1400: {
              internalHeightMM: 500,
              internalWidthMM: 1400,
              WHRatio: 2.8,
            },
            1600: {
              internalHeightMM: 500,
              internalWidthMM: 1600,
              WHRatio: 3.2,
            },
            1800: {
              internalHeightMM: 500,
              internalWidthMM: 1800,
              WHRatio: 3.6,
            },
            2000: {
              internalHeightMM: 500,
              internalWidthMM: 2000,
              WHRatio: 4.0,
            },
          },
          600: {
            150: {
              internalHeightMM: 600,
              internalWidthMM: 150,
              WHRatio: 0.25,
            },
            200: {
              internalHeightMM: 600,
              internalWidthMM: 200,
              WHRatio: 0.3333333333333333,
            },
            250: {
              internalHeightMM: 600,
              internalWidthMM: 250,
              WHRatio: 0.4166666666666667,
            },
            300: {
              internalHeightMM: 600,
              internalWidthMM: 300,
              WHRatio: 0.5,
            },
            400: {
              internalHeightMM: 600,
              internalWidthMM: 400,
              WHRatio: 0.6666666666666666,
            },
            500: {
              internalHeightMM: 600,
              internalWidthMM: 500,
              WHRatio: 0.8333333333333334,
            },
            600: {
              internalHeightMM: 600,
              internalWidthMM: 600,
              WHRatio: 1.0,
            },
            800: {
              internalHeightMM: 600,
              internalWidthMM: 800,
              WHRatio: 1.3333333333333333,
            },
            1000: {
              internalHeightMM: 600,
              internalWidthMM: 1000,
              WHRatio: 1.6666666666666667,
            },
            1200: {
              internalHeightMM: 600,
              internalWidthMM: 1200,
              WHRatio: 2.0,
            },
            1400: {
              internalHeightMM: 600,
              internalWidthMM: 1400,
              WHRatio: 2.3333333333333335,
            },
            1600: {
              internalHeightMM: 600,
              internalWidthMM: 1600,
              WHRatio: 2.6666666666666665,
            },
            1800: {
              internalHeightMM: 600,
              internalWidthMM: 1800,
              WHRatio: 3.0,
            },
            2000: {
              internalHeightMM: 600,
              internalWidthMM: 2000,
              WHRatio: 3.3333333333333335,
            },
          },
          800: {
            200: {
              internalHeightMM: 800,
              internalWidthMM: 200,
              WHRatio: 0.25,
            },
            250: {
              internalHeightMM: 800,
              internalWidthMM: 250,
              WHRatio: 0.3125,
            },
            300: {
              internalHeightMM: 800,
              internalWidthMM: 300,
              WHRatio: 0.375,
            },
            400: {
              internalHeightMM: 800,
              internalWidthMM: 400,
              WHRatio: 0.5,
            },
            500: {
              internalHeightMM: 800,
              internalWidthMM: 500,
              WHRatio: 0.625,
            },
            600: {
              internalHeightMM: 800,
              internalWidthMM: 600,
              WHRatio: 0.75,
            },
            800: {
              internalHeightMM: 800,
              internalWidthMM: 800,
              WHRatio: 1.0,
            },
            1000: {
              internalHeightMM: 800,
              internalWidthMM: 1000,
              WHRatio: 1.25,
            },
            1200: {
              internalHeightMM: 800,
              internalWidthMM: 1200,
              WHRatio: 1.5,
            },
            1400: {
              internalHeightMM: 800,
              internalWidthMM: 1400,
              WHRatio: 1.75,
            },
            1600: {
              internalHeightMM: 800,
              internalWidthMM: 1600,
              WHRatio: 2.0,
            },
            1800: {
              internalHeightMM: 800,
              internalWidthMM: 1800,
              WHRatio: 2.25,
            },
            2000: {
              internalHeightMM: 800,
              internalWidthMM: 2000,
              WHRatio: 2.5,
            },
          },
          1000: {
            250: {
              internalHeightMM: 1000,
              internalWidthMM: 250,
              WHRatio: 0.25,
            },
            300: {
              internalHeightMM: 1000,
              internalWidthMM: 300,
              WHRatio: 0.3,
            },
            400: {
              internalHeightMM: 1000,
              internalWidthMM: 400,
              WHRatio: 0.4,
            },
            500: {
              internalHeightMM: 1000,
              internalWidthMM: 500,
              WHRatio: 0.5,
            },
            600: {
              internalHeightMM: 1000,
              internalWidthMM: 600,
              WHRatio: 0.6,
            },
            800: {
              internalHeightMM: 1000,
              internalWidthMM: 800,
              WHRatio: 0.8,
            },
            1000: {
              internalHeightMM: 1000,
              internalWidthMM: 1000,
              WHRatio: 1.0,
            },
            1200: {
              internalHeightMM: 1000,
              internalWidthMM: 1200,
              WHRatio: 1.2,
            },
            1400: {
              internalHeightMM: 1000,
              internalWidthMM: 1400,
              WHRatio: 1.4,
            },
            1600: {
              internalHeightMM: 1000,
              internalWidthMM: 1600,
              WHRatio: 1.6,
            },
            1800: {
              internalHeightMM: 1000,
              internalWidthMM: 1800,
              WHRatio: 1.8,
            },
            2000: {
              internalHeightMM: 1000,
              internalWidthMM: 2000,
              WHRatio: 2.0,
            },
          },
          1200: {
            300: {
              internalHeightMM: 1200,
              internalWidthMM: 300,
              WHRatio: 0.25,
            },
            400: {
              internalHeightMM: 1200,
              internalWidthMM: 400,
              WHRatio: 0.3333333333333333,
            },
            500: {
              internalHeightMM: 1200,
              internalWidthMM: 500,
              WHRatio: 0.4166666666666667,
            },
            600: {
              internalHeightMM: 1200,
              internalWidthMM: 600,
              WHRatio: 0.5,
            },
            800: {
              internalHeightMM: 1200,
              internalWidthMM: 800,
              WHRatio: 0.6666666666666666,
            },
            1000: {
              internalHeightMM: 1200,
              internalWidthMM: 1000,
              WHRatio: 0.8333333333333334,
            },
            1200: {
              internalHeightMM: 1200,
              internalWidthMM: 1200,
              WHRatio: 1.0,
            },
            1400: {
              internalHeightMM: 1200,
              internalWidthMM: 1400,
              WHRatio: 1.1666666666666667,
            },
            1600: {
              internalHeightMM: 1200,
              internalWidthMM: 1600,
              WHRatio: 1.3333333333333333,
            },
            1800: {
              internalHeightMM: 1200,
              internalWidthMM: 1800,
              WHRatio: 1.5,
            },
            2000: {
              internalHeightMM: 1200,
              internalWidthMM: 2000,
              WHRatio: 1.6666666666666667,
            },
          },
          1400: {
            400: {
              internalHeightMM: 1400,
              internalWidthMM: 400,
              WHRatio: 0.2857142857142857,
            },
            500: {
              internalHeightMM: 1400,
              internalWidthMM: 500,
              WHRatio: 0.35714285714285715,
            },
            600: {
              internalHeightMM: 1400,
              internalWidthMM: 600,
              WHRatio: 0.42857142857142855,
            },
            800: {
              internalHeightMM: 1400,
              internalWidthMM: 800,
              WHRatio: 0.5714285714285714,
            },
            1000: {
              internalHeightMM: 1400,
              internalWidthMM: 1000,
              WHRatio: 0.7142857142857143,
            },
            1200: {
              internalHeightMM: 1400,
              internalWidthMM: 1200,
              WHRatio: 0.8571428571428571,
            },
          },
          1600: {
            400: {
              internalHeightMM: 1600,
              internalWidthMM: 400,
              WHRatio: 0.25,
            },
            500: {
              internalHeightMM: 1600,
              internalWidthMM: 500,
              WHRatio: 0.3125,
            },
            600: {
              internalHeightMM: 1600,
              internalWidthMM: 600,
              WHRatio: 0.375,
            },
            800: {
              internalHeightMM: 1600,
              internalWidthMM: 800,
              WHRatio: 0.5,
            },
            1000: {
              internalHeightMM: 1600,
              internalWidthMM: 1000,
              WHRatio: 0.625,
            },
            1200: {
              internalHeightMM: 1600,
              internalWidthMM: 1200,
              WHRatio: 0.75,
            },
          },
          1800: {
            500: {
              internalHeightMM: 1800,
              internalWidthMM: 500,
              WHRatio: 0.2777777777777778,
            },
            600: {
              internalHeightMM: 1800,
              internalWidthMM: 600,
              WHRatio: 0.3333333333333333,
            },
            800: {
              internalHeightMM: 1800,
              internalWidthMM: 800,
              WHRatio: 0.4444444444444444,
            },
            1000: {
              internalHeightMM: 1800,
              internalWidthMM: 1000,
              WHRatio: 0.5555555555555556,
            },
            1200: {
              internalHeightMM: 1800,
              internalWidthMM: 1200,
              WHRatio: 0.6666666666666666,
            },
          },
          2000: {
            500: {
              internalHeightMM: 2000,
              internalWidthMM: 500,
              WHRatio: 0.25,
            },
            600: {
              internalHeightMM: 2000,
              internalWidthMM: 600,
              WHRatio: 0.3,
            },
            800: {
              internalHeightMM: 2000,
              internalWidthMM: 800,
              WHRatio: 0.4,
            },
            1000: {
              internalHeightMM: 2000,
              internalWidthMM: 1000,
              WHRatio: 0.5,
            },
            1200: {
              internalHeightMM: 2000,
              internalWidthMM: 1200,
              WHRatio: 0.6,
            },
          },
        },
        colebrookWhiteCoefficient: 0.03,
      },
    },
  },

  fibrousGlass: {
    name: "Fibrous Glass Duct",
    uid: "fibrousGlassDuct",
    abbreviation: "FG",
    manufacturers: [
      {
        name: "Generic",
        abbreviation: "Generic",
        priceTableName: "Fibrous Glass Duct",
        uid: "generic",
        isGeneric: true,
      },
    ],
    data: {
      generic: {
        circularByDiameter: {
          63: { internalDiameterMM: 63 },
          75: { internalDiameterMM: 75 },
          80: { internalDiameterMM: 80 },
          90: { internalDiameterMM: 90 },
          100: { internalDiameterMM: 100 },
          125: { internalDiameterMM: 125 },
          160: { internalDiameterMM: 160 },
          200: { internalDiameterMM: 200 },
          250: { internalDiameterMM: 250 },
          315: { internalDiameterMM: 315 },
          355: { internalDiameterMM: 355 },
          400: { internalDiameterMM: 400 },
          450: { internalDiameterMM: 450 },
          500: { internalDiameterMM: 500 },
          560: { internalDiameterMM: 560 },
          630: { internalDiameterMM: 630 },
          710: { internalDiameterMM: 710 },
          800: { internalDiameterMM: 800 },
          900: { internalDiameterMM: 900 },
          1000: { internalDiameterMM: 1000 },
          1120: { internalDiameterMM: 1120 },
          1250: { internalDiameterMM: 1250 },
        },
        rectangularByHeightWidth: {
          100: {
            100: {
              internalHeightMM: 100,
              internalWidthMM: 100,
              WHRatio: 1.0,
            },
            150: {
              internalHeightMM: 100,
              internalWidthMM: 150,
              WHRatio: 1.5,
            },
            200: {
              internalHeightMM: 100,
              internalWidthMM: 200,
              WHRatio: 2.0,
            },
            250: {
              internalHeightMM: 100,
              internalWidthMM: 250,
              WHRatio: 2.5,
            },
            300: {
              internalHeightMM: 100,
              internalWidthMM: 300,
              WHRatio: 3.0,
            },
            400: {
              internalHeightMM: 100,
              internalWidthMM: 400,
              WHRatio: 4.0,
            },
          },
          150: {
            100: {
              internalHeightMM: 150,
              internalWidthMM: 100,
              WHRatio: 0.6666666666666666,
            },
            150: {
              internalHeightMM: 150,
              internalWidthMM: 150,
              WHRatio: 1.0,
            },
            200: {
              internalHeightMM: 150,
              internalWidthMM: 200,
              WHRatio: 1.3333333333333333,
            },
            250: {
              internalHeightMM: 150,
              internalWidthMM: 250,
              WHRatio: 1.6666666666666667,
            },
            300: {
              internalHeightMM: 150,
              internalWidthMM: 300,
              WHRatio: 2.0,
            },
            400: {
              internalHeightMM: 150,
              internalWidthMM: 400,
              WHRatio: 2.6666666666666665,
            },
            500: {
              internalHeightMM: 150,
              internalWidthMM: 500,
              WHRatio: 3.3333333333333335,
            },
            600: {
              internalHeightMM: 150,
              internalWidthMM: 600,
              WHRatio: 4.0,
            },
          },
          200: {
            100: {
              internalHeightMM: 200,
              internalWidthMM: 100,
              WHRatio: 0.5,
            },
            150: {
              internalHeightMM: 200,
              internalWidthMM: 150,
              WHRatio: 0.75,
            },
            200: {
              internalHeightMM: 200,
              internalWidthMM: 200,
              WHRatio: 1.0,
            },
            250: {
              internalHeightMM: 200,
              internalWidthMM: 250,
              WHRatio: 1.25,
            },
            300: {
              internalHeightMM: 200,
              internalWidthMM: 300,
              WHRatio: 1.5,
            },
            400: {
              internalHeightMM: 200,
              internalWidthMM: 400,
              WHRatio: 2.0,
            },
            500: {
              internalHeightMM: 200,
              internalWidthMM: 500,
              WHRatio: 2.5,
            },
            600: {
              internalHeightMM: 200,
              internalWidthMM: 600,
              WHRatio: 3.0,
            },
            800: {
              internalHeightMM: 200,
              internalWidthMM: 800,
              WHRatio: 4.0,
            },
          },
          250: {
            100: {
              internalHeightMM: 250,
              internalWidthMM: 100,
              WHRatio: 0.4,
            },
            150: {
              internalHeightMM: 250,
              internalWidthMM: 150,
              WHRatio: 0.6,
            },
            200: {
              internalHeightMM: 250,
              internalWidthMM: 200,
              WHRatio: 0.8,
            },
            250: {
              internalHeightMM: 250,
              internalWidthMM: 250,
              WHRatio: 1.0,
            },
            300: {
              internalHeightMM: 250,
              internalWidthMM: 300,
              WHRatio: 1.2,
            },
            400: {
              internalHeightMM: 250,
              internalWidthMM: 400,
              WHRatio: 1.6,
            },
            500: {
              internalHeightMM: 250,
              internalWidthMM: 500,
              WHRatio: 2.0,
            },
            600: {
              internalHeightMM: 250,
              internalWidthMM: 600,
              WHRatio: 2.4,
            },
            800: {
              internalHeightMM: 250,
              internalWidthMM: 800,
              WHRatio: 3.2,
            },
            1000: {
              internalHeightMM: 250,
              internalWidthMM: 1000,
              WHRatio: 4.0,
            },
          },
          300: {
            100: {
              internalHeightMM: 300,
              internalWidthMM: 100,
              WHRatio: 0.3333333333333333,
            },
            150: {
              internalHeightMM: 300,
              internalWidthMM: 150,
              WHRatio: 0.5,
            },
            200: {
              internalHeightMM: 300,
              internalWidthMM: 200,
              WHRatio: 0.6666666666666666,
            },
            250: {
              internalHeightMM: 300,
              internalWidthMM: 250,
              WHRatio: 0.8333333333333334,
            },
            300: {
              internalHeightMM: 300,
              internalWidthMM: 300,
              WHRatio: 1.0,
            },
            400: {
              internalHeightMM: 300,
              internalWidthMM: 400,
              WHRatio: 1.3333333333333333,
            },
            500: {
              internalHeightMM: 300,
              internalWidthMM: 500,
              WHRatio: 1.6666666666666667,
            },
            600: {
              internalHeightMM: 300,
              internalWidthMM: 600,
              WHRatio: 2.0,
            },
            800: {
              internalHeightMM: 300,
              internalWidthMM: 800,
              WHRatio: 2.6666666666666665,
            },
            1000: {
              internalHeightMM: 300,
              internalWidthMM: 1000,
              WHRatio: 3.3333333333333335,
            },
            1200: {
              internalHeightMM: 300,
              internalWidthMM: 1200,
              WHRatio: 4.0,
            },
          },
          400: {
            100: {
              internalHeightMM: 400,
              internalWidthMM: 100,
              WHRatio: 0.25,
            },
            150: {
              internalHeightMM: 400,
              internalWidthMM: 150,
              WHRatio: 0.375,
            },
            200: {
              internalHeightMM: 400,
              internalWidthMM: 200,
              WHRatio: 0.5,
            },
            250: {
              internalHeightMM: 400,
              internalWidthMM: 250,
              WHRatio: 0.625,
            },
            300: {
              internalHeightMM: 400,
              internalWidthMM: 300,
              WHRatio: 0.75,
            },
            400: {
              internalHeightMM: 400,
              internalWidthMM: 400,
              WHRatio: 1.0,
            },
            500: {
              internalHeightMM: 400,
              internalWidthMM: 500,
              WHRatio: 1.25,
            },
            600: {
              internalHeightMM: 400,
              internalWidthMM: 600,
              WHRatio: 1.5,
            },
            800: {
              internalHeightMM: 400,
              internalWidthMM: 800,
              WHRatio: 2.0,
            },
            1000: {
              internalHeightMM: 400,
              internalWidthMM: 1000,
              WHRatio: 2.5,
            },
            1200: {
              internalHeightMM: 400,
              internalWidthMM: 1200,
              WHRatio: 3.0,
            },
            1400: {
              internalHeightMM: 400,
              internalWidthMM: 1400,
              WHRatio: 3.5,
            },
            1600: {
              internalHeightMM: 400,
              internalWidthMM: 1600,
              WHRatio: 4.0,
            },
          },
          500: {
            150: {
              internalHeightMM: 500,
              internalWidthMM: 150,
              WHRatio: 0.3,
            },
            200: {
              internalHeightMM: 500,
              internalWidthMM: 200,
              WHRatio: 0.4,
            },
            250: {
              internalHeightMM: 500,
              internalWidthMM: 250,
              WHRatio: 0.5,
            },
            300: {
              internalHeightMM: 500,
              internalWidthMM: 300,
              WHRatio: 0.6,
            },
            400: {
              internalHeightMM: 500,
              internalWidthMM: 400,
              WHRatio: 0.8,
            },
            500: {
              internalHeightMM: 500,
              internalWidthMM: 500,
              WHRatio: 1.0,
            },
            600: {
              internalHeightMM: 500,
              internalWidthMM: 600,
              WHRatio: 1.2,
            },
            800: {
              internalHeightMM: 500,
              internalWidthMM: 800,
              WHRatio: 1.6,
            },
            1000: {
              internalHeightMM: 500,
              internalWidthMM: 1000,
              WHRatio: 2.0,
            },
            1200: {
              internalHeightMM: 500,
              internalWidthMM: 1200,
              WHRatio: 2.4,
            },
            1400: {
              internalHeightMM: 500,
              internalWidthMM: 1400,
              WHRatio: 2.8,
            },
            1600: {
              internalHeightMM: 500,
              internalWidthMM: 1600,
              WHRatio: 3.2,
            },
            1800: {
              internalHeightMM: 500,
              internalWidthMM: 1800,
              WHRatio: 3.6,
            },
            2000: {
              internalHeightMM: 500,
              internalWidthMM: 2000,
              WHRatio: 4.0,
            },
          },
          600: {
            150: {
              internalHeightMM: 600,
              internalWidthMM: 150,
              WHRatio: 0.25,
            },
            200: {
              internalHeightMM: 600,
              internalWidthMM: 200,
              WHRatio: 0.3333333333333333,
            },
            250: {
              internalHeightMM: 600,
              internalWidthMM: 250,
              WHRatio: 0.4166666666666667,
            },
            300: {
              internalHeightMM: 600,
              internalWidthMM: 300,
              WHRatio: 0.5,
            },
            400: {
              internalHeightMM: 600,
              internalWidthMM: 400,
              WHRatio: 0.6666666666666666,
            },
            500: {
              internalHeightMM: 600,
              internalWidthMM: 500,
              WHRatio: 0.8333333333333334,
            },
            600: {
              internalHeightMM: 600,
              internalWidthMM: 600,
              WHRatio: 1.0,
            },
            800: {
              internalHeightMM: 600,
              internalWidthMM: 800,
              WHRatio: 1.3333333333333333,
            },
            1000: {
              internalHeightMM: 600,
              internalWidthMM: 1000,
              WHRatio: 1.6666666666666667,
            },
            1200: {
              internalHeightMM: 600,
              internalWidthMM: 1200,
              WHRatio: 2.0,
            },
            1400: {
              internalHeightMM: 600,
              internalWidthMM: 1400,
              WHRatio: 2.3333333333333335,
            },
            1600: {
              internalHeightMM: 600,
              internalWidthMM: 1600,
              WHRatio: 2.6666666666666665,
            },
            1800: {
              internalHeightMM: 600,
              internalWidthMM: 1800,
              WHRatio: 3.0,
            },
            2000: {
              internalHeightMM: 600,
              internalWidthMM: 2000,
              WHRatio: 3.3333333333333335,
            },
          },
          800: {
            200: {
              internalHeightMM: 800,
              internalWidthMM: 200,
              WHRatio: 0.25,
            },
            250: {
              internalHeightMM: 800,
              internalWidthMM: 250,
              WHRatio: 0.3125,
            },
            300: {
              internalHeightMM: 800,
              internalWidthMM: 300,
              WHRatio: 0.375,
            },
            400: {
              internalHeightMM: 800,
              internalWidthMM: 400,
              WHRatio: 0.5,
            },
            500: {
              internalHeightMM: 800,
              internalWidthMM: 500,
              WHRatio: 0.625,
            },
            600: {
              internalHeightMM: 800,
              internalWidthMM: 600,
              WHRatio: 0.75,
            },
            800: {
              internalHeightMM: 800,
              internalWidthMM: 800,
              WHRatio: 1.0,
            },
            1000: {
              internalHeightMM: 800,
              internalWidthMM: 1000,
              WHRatio: 1.25,
            },
            1200: {
              internalHeightMM: 800,
              internalWidthMM: 1200,
              WHRatio: 1.5,
            },
            1400: {
              internalHeightMM: 800,
              internalWidthMM: 1400,
              WHRatio: 1.75,
            },
            1600: {
              internalHeightMM: 800,
              internalWidthMM: 1600,
              WHRatio: 2.0,
            },
            1800: {
              internalHeightMM: 800,
              internalWidthMM: 1800,
              WHRatio: 2.25,
            },
            2000: {
              internalHeightMM: 800,
              internalWidthMM: 2000,
              WHRatio: 2.5,
            },
          },
          1000: {
            250: {
              internalHeightMM: 1000,
              internalWidthMM: 250,
              WHRatio: 0.25,
            },
            300: {
              internalHeightMM: 1000,
              internalWidthMM: 300,
              WHRatio: 0.3,
            },
            400: {
              internalHeightMM: 1000,
              internalWidthMM: 400,
              WHRatio: 0.4,
            },
            500: {
              internalHeightMM: 1000,
              internalWidthMM: 500,
              WHRatio: 0.5,
            },
            600: {
              internalHeightMM: 1000,
              internalWidthMM: 600,
              WHRatio: 0.6,
            },
            800: {
              internalHeightMM: 1000,
              internalWidthMM: 800,
              WHRatio: 0.8,
            },
            1000: {
              internalHeightMM: 1000,
              internalWidthMM: 1000,
              WHRatio: 1.0,
            },
            1200: {
              internalHeightMM: 1000,
              internalWidthMM: 1200,
              WHRatio: 1.2,
            },
            1400: {
              internalHeightMM: 1000,
              internalWidthMM: 1400,
              WHRatio: 1.4,
            },
            1600: {
              internalHeightMM: 1000,
              internalWidthMM: 1600,
              WHRatio: 1.6,
            },
            1800: {
              internalHeightMM: 1000,
              internalWidthMM: 1800,
              WHRatio: 1.8,
            },
            2000: {
              internalHeightMM: 1000,
              internalWidthMM: 2000,
              WHRatio: 2.0,
            },
          },
          1200: {
            300: {
              internalHeightMM: 1200,
              internalWidthMM: 300,
              WHRatio: 0.25,
            },
            400: {
              internalHeightMM: 1200,
              internalWidthMM: 400,
              WHRatio: 0.3333333333333333,
            },
            500: {
              internalHeightMM: 1200,
              internalWidthMM: 500,
              WHRatio: 0.4166666666666667,
            },
            600: {
              internalHeightMM: 1200,
              internalWidthMM: 600,
              WHRatio: 0.5,
            },
            800: {
              internalHeightMM: 1200,
              internalWidthMM: 800,
              WHRatio: 0.6666666666666666,
            },
            1000: {
              internalHeightMM: 1200,
              internalWidthMM: 1000,
              WHRatio: 0.8333333333333334,
            },
            1200: {
              internalHeightMM: 1200,
              internalWidthMM: 1200,
              WHRatio: 1.0,
            },
            1400: {
              internalHeightMM: 1200,
              internalWidthMM: 1400,
              WHRatio: 1.1666666666666667,
            },
            1600: {
              internalHeightMM: 1200,
              internalWidthMM: 1600,
              WHRatio: 1.3333333333333333,
            },
            1800: {
              internalHeightMM: 1200,
              internalWidthMM: 1800,
              WHRatio: 1.5,
            },
            2000: {
              internalHeightMM: 1200,
              internalWidthMM: 2000,
              WHRatio: 1.6666666666666667,
            },
          },
          1400: {
            400: {
              internalHeightMM: 1400,
              internalWidthMM: 400,
              WHRatio: 0.2857142857142857,
            },
            500: {
              internalHeightMM: 1400,
              internalWidthMM: 500,
              WHRatio: 0.35714285714285715,
            },
            600: {
              internalHeightMM: 1400,
              internalWidthMM: 600,
              WHRatio: 0.42857142857142855,
            },
            800: {
              internalHeightMM: 1400,
              internalWidthMM: 800,
              WHRatio: 0.5714285714285714,
            },
            1000: {
              internalHeightMM: 1400,
              internalWidthMM: 1000,
              WHRatio: 0.7142857142857143,
            },
            1200: {
              internalHeightMM: 1400,
              internalWidthMM: 1200,
              WHRatio: 0.8571428571428571,
            },
          },
          1600: {
            400: {
              internalHeightMM: 1600,
              internalWidthMM: 400,
              WHRatio: 0.25,
            },
            500: {
              internalHeightMM: 1600,
              internalWidthMM: 500,
              WHRatio: 0.3125,
            },
            600: {
              internalHeightMM: 1600,
              internalWidthMM: 600,
              WHRatio: 0.375,
            },
            800: {
              internalHeightMM: 1600,
              internalWidthMM: 800,
              WHRatio: 0.5,
            },
            1000: {
              internalHeightMM: 1600,
              internalWidthMM: 1000,
              WHRatio: 0.625,
            },
            1200: {
              internalHeightMM: 1600,
              internalWidthMM: 1200,
              WHRatio: 0.75,
            },
          },
          1800: {
            500: {
              internalHeightMM: 1800,
              internalWidthMM: 500,
              WHRatio: 0.2777777777777778,
            },
            600: {
              internalHeightMM: 1800,
              internalWidthMM: 600,
              WHRatio: 0.3333333333333333,
            },
            800: {
              internalHeightMM: 1800,
              internalWidthMM: 800,
              WHRatio: 0.4444444444444444,
            },
            1000: {
              internalHeightMM: 1800,
              internalWidthMM: 1000,
              WHRatio: 0.5555555555555556,
            },
            1200: {
              internalHeightMM: 1800,
              internalWidthMM: 1200,
              WHRatio: 0.6666666666666666,
            },
          },
          2000: {
            500: {
              internalHeightMM: 2000,
              internalWidthMM: 500,
              WHRatio: 0.25,
            },
            600: {
              internalHeightMM: 2000,
              internalWidthMM: 600,
              WHRatio: 0.3,
            },
            800: {
              internalHeightMM: 2000,
              internalWidthMM: 800,
              WHRatio: 0.4,
            },
            1000: {
              internalHeightMM: 2000,
              internalWidthMM: 1000,
              WHRatio: 0.5,
            },
            1200: {
              internalHeightMM: 2000,
              internalWidthMM: 1200,
              WHRatio: 0.6,
            },
          },
        },
        colebrookWhiteCoefficient: 0.9,
      },
    },
  },
  flexibleDuct: {
    name: "Flexible Duct",
    uid: "flexibleDuct",
    abbreviation: "FD",
    manufacturers: [
      {
        name: "Generic",
        abbreviation: "Generic",
        priceTableName: "Flexible Duct",
        uid: "generic",
        isGeneric: true,
      },
    ],
    data: {
      generic: {
        circularByDiameter: {
          63: { internalDiameterMM: 63 },
          75: { internalDiameterMM: 75 },
          80: { internalDiameterMM: 80 },
          90: { internalDiameterMM: 90 },
          100: { internalDiameterMM: 100 },
          125: { internalDiameterMM: 125 },
          160: { internalDiameterMM: 160 },
          200: { internalDiameterMM: 200 },
          250: { internalDiameterMM: 250 },
          315: { internalDiameterMM: 315 },
          355: { internalDiameterMM: 355 },
          400: { internalDiameterMM: 400 },
          450: { internalDiameterMM: 450 },
          500: { internalDiameterMM: 500 },
          560: { internalDiameterMM: 560 },
          630: { internalDiameterMM: 630 },
          710: { internalDiameterMM: 710 },
          800: { internalDiameterMM: 800 },
          900: { internalDiameterMM: 900 },
          1000: { internalDiameterMM: 1000 },
          1120: { internalDiameterMM: 1120 },
          1250: { internalDiameterMM: 1250 },
        },
        rectangularByHeightWidth: {
          100: {
            100: {
              internalHeightMM: 100,
              internalWidthMM: 100,
              WHRatio: 1.0,
            },
            150: {
              internalHeightMM: 100,
              internalWidthMM: 150,
              WHRatio: 1.5,
            },
            200: {
              internalHeightMM: 100,
              internalWidthMM: 200,
              WHRatio: 2.0,
            },
            250: {
              internalHeightMM: 100,
              internalWidthMM: 250,
              WHRatio: 2.5,
            },
            300: {
              internalHeightMM: 100,
              internalWidthMM: 300,
              WHRatio: 3.0,
            },
            400: {
              internalHeightMM: 100,
              internalWidthMM: 400,
              WHRatio: 4.0,
            },
          },
          150: {
            100: {
              internalHeightMM: 150,
              internalWidthMM: 100,
              WHRatio: 0.6666666666666666,
            },
            150: {
              internalHeightMM: 150,
              internalWidthMM: 150,
              WHRatio: 1.0,
            },
            200: {
              internalHeightMM: 150,
              internalWidthMM: 200,
              WHRatio: 1.3333333333333333,
            },
            250: {
              internalHeightMM: 150,
              internalWidthMM: 250,
              WHRatio: 1.6666666666666667,
            },
            300: {
              internalHeightMM: 150,
              internalWidthMM: 300,
              WHRatio: 2.0,
            },
            400: {
              internalHeightMM: 150,
              internalWidthMM: 400,
              WHRatio: 2.6666666666666665,
            },
            500: {
              internalHeightMM: 150,
              internalWidthMM: 500,
              WHRatio: 3.3333333333333335,
            },
            600: {
              internalHeightMM: 150,
              internalWidthMM: 600,
              WHRatio: 4.0,
            },
          },
          200: {
            100: {
              internalHeightMM: 200,
              internalWidthMM: 100,
              WHRatio: 0.5,
            },
            150: {
              internalHeightMM: 200,
              internalWidthMM: 150,
              WHRatio: 0.75,
            },
            200: {
              internalHeightMM: 200,
              internalWidthMM: 200,
              WHRatio: 1.0,
            },
            250: {
              internalHeightMM: 200,
              internalWidthMM: 250,
              WHRatio: 1.25,
            },
            300: {
              internalHeightMM: 200,
              internalWidthMM: 300,
              WHRatio: 1.5,
            },
            400: {
              internalHeightMM: 200,
              internalWidthMM: 400,
              WHRatio: 2.0,
            },
            500: {
              internalHeightMM: 200,
              internalWidthMM: 500,
              WHRatio: 2.5,
            },
            600: {
              internalHeightMM: 200,
              internalWidthMM: 600,
              WHRatio: 3.0,
            },
            800: {
              internalHeightMM: 200,
              internalWidthMM: 800,
              WHRatio: 4.0,
            },
          },
          250: {
            100: {
              internalHeightMM: 250,
              internalWidthMM: 100,
              WHRatio: 0.4,
            },
            150: {
              internalHeightMM: 250,
              internalWidthMM: 150,
              WHRatio: 0.6,
            },
            200: {
              internalHeightMM: 250,
              internalWidthMM: 200,
              WHRatio: 0.8,
            },
            250: {
              internalHeightMM: 250,
              internalWidthMM: 250,
              WHRatio: 1.0,
            },
            300: {
              internalHeightMM: 250,
              internalWidthMM: 300,
              WHRatio: 1.2,
            },
            400: {
              internalHeightMM: 250,
              internalWidthMM: 400,
              WHRatio: 1.6,
            },
            500: {
              internalHeightMM: 250,
              internalWidthMM: 500,
              WHRatio: 2.0,
            },
            600: {
              internalHeightMM: 250,
              internalWidthMM: 600,
              WHRatio: 2.4,
            },
            800: {
              internalHeightMM: 250,
              internalWidthMM: 800,
              WHRatio: 3.2,
            },
            1000: {
              internalHeightMM: 250,
              internalWidthMM: 1000,
              WHRatio: 4.0,
            },
          },
          300: {
            100: {
              internalHeightMM: 300,
              internalWidthMM: 100,
              WHRatio: 0.3333333333333333,
            },
            150: {
              internalHeightMM: 300,
              internalWidthMM: 150,
              WHRatio: 0.5,
            },
            200: {
              internalHeightMM: 300,
              internalWidthMM: 200,
              WHRatio: 0.6666666666666666,
            },
            250: {
              internalHeightMM: 300,
              internalWidthMM: 250,
              WHRatio: 0.8333333333333334,
            },
            300: {
              internalHeightMM: 300,
              internalWidthMM: 300,
              WHRatio: 1.0,
            },
            400: {
              internalHeightMM: 300,
              internalWidthMM: 400,
              WHRatio: 1.3333333333333333,
            },
            500: {
              internalHeightMM: 300,
              internalWidthMM: 500,
              WHRatio: 1.6666666666666667,
            },
            600: {
              internalHeightMM: 300,
              internalWidthMM: 600,
              WHRatio: 2.0,
            },
            800: {
              internalHeightMM: 300,
              internalWidthMM: 800,
              WHRatio: 2.6666666666666665,
            },
            1000: {
              internalHeightMM: 300,
              internalWidthMM: 1000,
              WHRatio: 3.3333333333333335,
            },
            1200: {
              internalHeightMM: 300,
              internalWidthMM: 1200,
              WHRatio: 4.0,
            },
          },
          400: {
            100: {
              internalHeightMM: 400,
              internalWidthMM: 100,
              WHRatio: 0.25,
            },
            150: {
              internalHeightMM: 400,
              internalWidthMM: 150,
              WHRatio: 0.375,
            },
            200: {
              internalHeightMM: 400,
              internalWidthMM: 200,
              WHRatio: 0.5,
            },
            250: {
              internalHeightMM: 400,
              internalWidthMM: 250,
              WHRatio: 0.625,
            },
            300: {
              internalHeightMM: 400,
              internalWidthMM: 300,
              WHRatio: 0.75,
            },
            400: {
              internalHeightMM: 400,
              internalWidthMM: 400,
              WHRatio: 1.0,
            },
            500: {
              internalHeightMM: 400,
              internalWidthMM: 500,
              WHRatio: 1.25,
            },
            600: {
              internalHeightMM: 400,
              internalWidthMM: 600,
              WHRatio: 1.5,
            },
            800: {
              internalHeightMM: 400,
              internalWidthMM: 800,
              WHRatio: 2.0,
            },
            1000: {
              internalHeightMM: 400,
              internalWidthMM: 1000,
              WHRatio: 2.5,
            },
            1200: {
              internalHeightMM: 400,
              internalWidthMM: 1200,
              WHRatio: 3.0,
            },
            1400: {
              internalHeightMM: 400,
              internalWidthMM: 1400,
              WHRatio: 3.5,
            },
            1600: {
              internalHeightMM: 400,
              internalWidthMM: 1600,
              WHRatio: 4.0,
            },
          },
          500: {
            150: {
              internalHeightMM: 500,
              internalWidthMM: 150,
              WHRatio: 0.3,
            },
            200: {
              internalHeightMM: 500,
              internalWidthMM: 200,
              WHRatio: 0.4,
            },
            250: {
              internalHeightMM: 500,
              internalWidthMM: 250,
              WHRatio: 0.5,
            },
            300: {
              internalHeightMM: 500,
              internalWidthMM: 300,
              WHRatio: 0.6,
            },
            400: {
              internalHeightMM: 500,
              internalWidthMM: 400,
              WHRatio: 0.8,
            },
            500: {
              internalHeightMM: 500,
              internalWidthMM: 500,
              WHRatio: 1.0,
            },
            600: {
              internalHeightMM: 500,
              internalWidthMM: 600,
              WHRatio: 1.2,
            },
            800: {
              internalHeightMM: 500,
              internalWidthMM: 800,
              WHRatio: 1.6,
            },
            1000: {
              internalHeightMM: 500,
              internalWidthMM: 1000,
              WHRatio: 2.0,
            },
            1200: {
              internalHeightMM: 500,
              internalWidthMM: 1200,
              WHRatio: 2.4,
            },
            1400: {
              internalHeightMM: 500,
              internalWidthMM: 1400,
              WHRatio: 2.8,
            },
            1600: {
              internalHeightMM: 500,
              internalWidthMM: 1600,
              WHRatio: 3.2,
            },
            1800: {
              internalHeightMM: 500,
              internalWidthMM: 1800,
              WHRatio: 3.6,
            },
            2000: {
              internalHeightMM: 500,
              internalWidthMM: 2000,
              WHRatio: 4.0,
            },
          },
          600: {
            150: {
              internalHeightMM: 600,
              internalWidthMM: 150,
              WHRatio: 0.25,
            },
            200: {
              internalHeightMM: 600,
              internalWidthMM: 200,
              WHRatio: 0.3333333333333333,
            },
            250: {
              internalHeightMM: 600,
              internalWidthMM: 250,
              WHRatio: 0.4166666666666667,
            },
            300: {
              internalHeightMM: 600,
              internalWidthMM: 300,
              WHRatio: 0.5,
            },
            400: {
              internalHeightMM: 600,
              internalWidthMM: 400,
              WHRatio: 0.6666666666666666,
            },
            500: {
              internalHeightMM: 600,
              internalWidthMM: 500,
              WHRatio: 0.8333333333333334,
            },
            600: {
              internalHeightMM: 600,
              internalWidthMM: 600,
              WHRatio: 1.0,
            },
            800: {
              internalHeightMM: 600,
              internalWidthMM: 800,
              WHRatio: 1.3333333333333333,
            },
            1000: {
              internalHeightMM: 600,
              internalWidthMM: 1000,
              WHRatio: 1.6666666666666667,
            },
            1200: {
              internalHeightMM: 600,
              internalWidthMM: 1200,
              WHRatio: 2.0,
            },
            1400: {
              internalHeightMM: 600,
              internalWidthMM: 1400,
              WHRatio: 2.3333333333333335,
            },
            1600: {
              internalHeightMM: 600,
              internalWidthMM: 1600,
              WHRatio: 2.6666666666666665,
            },
            1800: {
              internalHeightMM: 600,
              internalWidthMM: 1800,
              WHRatio: 3.0,
            },
            2000: {
              internalHeightMM: 600,
              internalWidthMM: 2000,
              WHRatio: 3.3333333333333335,
            },
          },
          800: {
            200: {
              internalHeightMM: 800,
              internalWidthMM: 200,
              WHRatio: 0.25,
            },
            250: {
              internalHeightMM: 800,
              internalWidthMM: 250,
              WHRatio: 0.3125,
            },
            300: {
              internalHeightMM: 800,
              internalWidthMM: 300,
              WHRatio: 0.375,
            },
            400: {
              internalHeightMM: 800,
              internalWidthMM: 400,
              WHRatio: 0.5,
            },
            500: {
              internalHeightMM: 800,
              internalWidthMM: 500,
              WHRatio: 0.625,
            },
            600: {
              internalHeightMM: 800,
              internalWidthMM: 600,
              WHRatio: 0.75,
            },
            800: {
              internalHeightMM: 800,
              internalWidthMM: 800,
              WHRatio: 1.0,
            },
            1000: {
              internalHeightMM: 800,
              internalWidthMM: 1000,
              WHRatio: 1.25,
            },
            1200: {
              internalHeightMM: 800,
              internalWidthMM: 1200,
              WHRatio: 1.5,
            },
            1400: {
              internalHeightMM: 800,
              internalWidthMM: 1400,
              WHRatio: 1.75,
            },
            1600: {
              internalHeightMM: 800,
              internalWidthMM: 1600,
              WHRatio: 2.0,
            },
            1800: {
              internalHeightMM: 800,
              internalWidthMM: 1800,
              WHRatio: 2.25,
            },
            2000: {
              internalHeightMM: 800,
              internalWidthMM: 2000,
              WHRatio: 2.5,
            },
          },
          1000: {
            250: {
              internalHeightMM: 1000,
              internalWidthMM: 250,
              WHRatio: 0.25,
            },
            300: {
              internalHeightMM: 1000,
              internalWidthMM: 300,
              WHRatio: 0.3,
            },
            400: {
              internalHeightMM: 1000,
              internalWidthMM: 400,
              WHRatio: 0.4,
            },
            500: {
              internalHeightMM: 1000,
              internalWidthMM: 500,
              WHRatio: 0.5,
            },
            600: {
              internalHeightMM: 1000,
              internalWidthMM: 600,
              WHRatio: 0.6,
            },
            800: {
              internalHeightMM: 1000,
              internalWidthMM: 800,
              WHRatio: 0.8,
            },
            1000: {
              internalHeightMM: 1000,
              internalWidthMM: 1000,
              WHRatio: 1.0,
            },
            1200: {
              internalHeightMM: 1000,
              internalWidthMM: 1200,
              WHRatio: 1.2,
            },
            1400: {
              internalHeightMM: 1000,
              internalWidthMM: 1400,
              WHRatio: 1.4,
            },
            1600: {
              internalHeightMM: 1000,
              internalWidthMM: 1600,
              WHRatio: 1.6,
            },
            1800: {
              internalHeightMM: 1000,
              internalWidthMM: 1800,
              WHRatio: 1.8,
            },
            2000: {
              internalHeightMM: 1000,
              internalWidthMM: 2000,
              WHRatio: 2.0,
            },
          },
          1200: {
            300: {
              internalHeightMM: 1200,
              internalWidthMM: 300,
              WHRatio: 0.25,
            },
            400: {
              internalHeightMM: 1200,
              internalWidthMM: 400,
              WHRatio: 0.3333333333333333,
            },
            500: {
              internalHeightMM: 1200,
              internalWidthMM: 500,
              WHRatio: 0.4166666666666667,
            },
            600: {
              internalHeightMM: 1200,
              internalWidthMM: 600,
              WHRatio: 0.5,
            },
            800: {
              internalHeightMM: 1200,
              internalWidthMM: 800,
              WHRatio: 0.6666666666666666,
            },
            1000: {
              internalHeightMM: 1200,
              internalWidthMM: 1000,
              WHRatio: 0.8333333333333334,
            },
            1200: {
              internalHeightMM: 1200,
              internalWidthMM: 1200,
              WHRatio: 1.0,
            },
            1400: {
              internalHeightMM: 1200,
              internalWidthMM: 1400,
              WHRatio: 1.1666666666666667,
            },
            1600: {
              internalHeightMM: 1200,
              internalWidthMM: 1600,
              WHRatio: 1.3333333333333333,
            },
            1800: {
              internalHeightMM: 1200,
              internalWidthMM: 1800,
              WHRatio: 1.5,
            },
            2000: {
              internalHeightMM: 1200,
              internalWidthMM: 2000,
              WHRatio: 1.6666666666666667,
            },
          },
          1400: {
            400: {
              internalHeightMM: 1400,
              internalWidthMM: 400,
              WHRatio: 0.2857142857142857,
            },
            500: {
              internalHeightMM: 1400,
              internalWidthMM: 500,
              WHRatio: 0.35714285714285715,
            },
            600: {
              internalHeightMM: 1400,
              internalWidthMM: 600,
              WHRatio: 0.42857142857142855,
            },
            800: {
              internalHeightMM: 1400,
              internalWidthMM: 800,
              WHRatio: 0.5714285714285714,
            },
            1000: {
              internalHeightMM: 1400,
              internalWidthMM: 1000,
              WHRatio: 0.7142857142857143,
            },
            1200: {
              internalHeightMM: 1400,
              internalWidthMM: 1200,
              WHRatio: 0.8571428571428571,
            },
          },
          1600: {
            400: {
              internalHeightMM: 1600,
              internalWidthMM: 400,
              WHRatio: 0.25,
            },
            500: {
              internalHeightMM: 1600,
              internalWidthMM: 500,
              WHRatio: 0.3125,
            },
            600: {
              internalHeightMM: 1600,
              internalWidthMM: 600,
              WHRatio: 0.375,
            },
            800: {
              internalHeightMM: 1600,
              internalWidthMM: 800,
              WHRatio: 0.5,
            },
            1000: {
              internalHeightMM: 1600,
              internalWidthMM: 1000,
              WHRatio: 0.625,
            },
            1200: {
              internalHeightMM: 1600,
              internalWidthMM: 1200,
              WHRatio: 0.75,
            },
          },
          1800: {
            500: {
              internalHeightMM: 1800,
              internalWidthMM: 500,
              WHRatio: 0.2777777777777778,
            },
            600: {
              internalHeightMM: 1800,
              internalWidthMM: 600,
              WHRatio: 0.3333333333333333,
            },
            800: {
              internalHeightMM: 1800,
              internalWidthMM: 800,
              WHRatio: 0.4444444444444444,
            },
            1000: {
              internalHeightMM: 1800,
              internalWidthMM: 1000,
              WHRatio: 0.5555555555555556,
            },
            1200: {
              internalHeightMM: 1800,
              internalWidthMM: 1200,
              WHRatio: 0.6666666666666666,
            },
          },
          2000: {
            500: {
              internalHeightMM: 2000,
              internalWidthMM: 500,
              WHRatio: 0.25,
            },
            600: {
              internalHeightMM: 2000,
              internalWidthMM: 600,
              WHRatio: 0.3,
            },
            800: {
              internalHeightMM: 2000,
              internalWidthMM: 800,
              WHRatio: 0.4,
            },
            1000: {
              internalHeightMM: 2000,
              internalWidthMM: 1000,
              WHRatio: 0.5,
            },
            1200: {
              internalHeightMM: 2000,
              internalWidthMM: 1200,
              WHRatio: 0.6,
            },
          },
        },
        colebrookWhiteCoefficient: 3,
      },
    },
  },

  semiRigid: {
    name: "Semi-Rigid",
    uid: "semiRigid",
    abbreviation: "SRD",
    manufacturers: [
      {
        name: "Generic",
        abbreviation: "Generic",
        priceTableName: "Semi-Rigid",
        uid: "generic",
        isGeneric: true,
      },
    ],
    data: {
      generic: {
        circularByDiameter: {
          63: { internalDiameterMM: 63 },
          75: { internalDiameterMM: 75 },
          80: { internalDiameterMM: 80 },
          90: { internalDiameterMM: 90 },
          100: { internalDiameterMM: 100 },
          125: { internalDiameterMM: 125 },
          160: { internalDiameterMM: 160 },
          200: { internalDiameterMM: 200 },
          250: { internalDiameterMM: 250 },
          315: { internalDiameterMM: 315 },
          355: { internalDiameterMM: 355 },
          400: { internalDiameterMM: 400 },
          450: { internalDiameterMM: 450 },
          500: { internalDiameterMM: 500 },
          560: { internalDiameterMM: 560 },
          630: { internalDiameterMM: 630 },
          710: { internalDiameterMM: 710 },
          800: { internalDiameterMM: 800 },
          900: { internalDiameterMM: 900 },
          1000: { internalDiameterMM: 1000 },
          1120: { internalDiameterMM: 1120 },
          1250: { internalDiameterMM: 1250 },
        },
        rectangularByHeightWidth: {
          100: {
            100: {
              internalHeightMM: 100,
              internalWidthMM: 100,
              WHRatio: 1.0,
            },
            150: {
              internalHeightMM: 100,
              internalWidthMM: 150,
              WHRatio: 1.5,
            },
            200: {
              internalHeightMM: 100,
              internalWidthMM: 200,
              WHRatio: 2.0,
            },
            250: {
              internalHeightMM: 100,
              internalWidthMM: 250,
              WHRatio: 2.5,
            },
            300: {
              internalHeightMM: 100,
              internalWidthMM: 300,
              WHRatio: 3.0,
            },
            400: {
              internalHeightMM: 100,
              internalWidthMM: 400,
              WHRatio: 4.0,
            },
          },
          150: {
            100: {
              internalHeightMM: 150,
              internalWidthMM: 100,
              WHRatio: 0.6666666666666666,
            },
            150: {
              internalHeightMM: 150,
              internalWidthMM: 150,
              WHRatio: 1.0,
            },
            200: {
              internalHeightMM: 150,
              internalWidthMM: 200,
              WHRatio: 1.3333333333333333,
            },
            250: {
              internalHeightMM: 150,
              internalWidthMM: 250,
              WHRatio: 1.6666666666666667,
            },
            300: {
              internalHeightMM: 150,
              internalWidthMM: 300,
              WHRatio: 2.0,
            },
            400: {
              internalHeightMM: 150,
              internalWidthMM: 400,
              WHRatio: 2.6666666666666665,
            },
            500: {
              internalHeightMM: 150,
              internalWidthMM: 500,
              WHRatio: 3.3333333333333335,
            },
            600: {
              internalHeightMM: 150,
              internalWidthMM: 600,
              WHRatio: 4.0,
            },
          },
          200: {
            100: {
              internalHeightMM: 200,
              internalWidthMM: 100,
              WHRatio: 0.5,
            },
            150: {
              internalHeightMM: 200,
              internalWidthMM: 150,
              WHRatio: 0.75,
            },
            200: {
              internalHeightMM: 200,
              internalWidthMM: 200,
              WHRatio: 1.0,
            },
            250: {
              internalHeightMM: 200,
              internalWidthMM: 250,
              WHRatio: 1.25,
            },
            300: {
              internalHeightMM: 200,
              internalWidthMM: 300,
              WHRatio: 1.5,
            },
            400: {
              internalHeightMM: 200,
              internalWidthMM: 400,
              WHRatio: 2.0,
            },
            500: {
              internalHeightMM: 200,
              internalWidthMM: 500,
              WHRatio: 2.5,
            },
            600: {
              internalHeightMM: 200,
              internalWidthMM: 600,
              WHRatio: 3.0,
            },
            800: {
              internalHeightMM: 200,
              internalWidthMM: 800,
              WHRatio: 4.0,
            },
          },
          250: {
            100: {
              internalHeightMM: 250,
              internalWidthMM: 100,
              WHRatio: 0.4,
            },
            150: {
              internalHeightMM: 250,
              internalWidthMM: 150,
              WHRatio: 0.6,
            },
            200: {
              internalHeightMM: 250,
              internalWidthMM: 200,
              WHRatio: 0.8,
            },
            250: {
              internalHeightMM: 250,
              internalWidthMM: 250,
              WHRatio: 1.0,
            },
            300: {
              internalHeightMM: 250,
              internalWidthMM: 300,
              WHRatio: 1.2,
            },
            400: {
              internalHeightMM: 250,
              internalWidthMM: 400,
              WHRatio: 1.6,
            },
            500: {
              internalHeightMM: 250,
              internalWidthMM: 500,
              WHRatio: 2.0,
            },
            600: {
              internalHeightMM: 250,
              internalWidthMM: 600,
              WHRatio: 2.4,
            },
            800: {
              internalHeightMM: 250,
              internalWidthMM: 800,
              WHRatio: 3.2,
            },
            1000: {
              internalHeightMM: 250,
              internalWidthMM: 1000,
              WHRatio: 4.0,
            },
          },
          300: {
            100: {
              internalHeightMM: 300,
              internalWidthMM: 100,
              WHRatio: 0.3333333333333333,
            },
            150: {
              internalHeightMM: 300,
              internalWidthMM: 150,
              WHRatio: 0.5,
            },
            200: {
              internalHeightMM: 300,
              internalWidthMM: 200,
              WHRatio: 0.6666666666666666,
            },
            250: {
              internalHeightMM: 300,
              internalWidthMM: 250,
              WHRatio: 0.8333333333333334,
            },
            300: {
              internalHeightMM: 300,
              internalWidthMM: 300,
              WHRatio: 1.0,
            },
            400: {
              internalHeightMM: 300,
              internalWidthMM: 400,
              WHRatio: 1.3333333333333333,
            },
            500: {
              internalHeightMM: 300,
              internalWidthMM: 500,
              WHRatio: 1.6666666666666667,
            },
            600: {
              internalHeightMM: 300,
              internalWidthMM: 600,
              WHRatio: 2.0,
            },
            800: {
              internalHeightMM: 300,
              internalWidthMM: 800,
              WHRatio: 2.6666666666666665,
            },
            1000: {
              internalHeightMM: 300,
              internalWidthMM: 1000,
              WHRatio: 3.3333333333333335,
            },
            1200: {
              internalHeightMM: 300,
              internalWidthMM: 1200,
              WHRatio: 4.0,
            },
          },
          400: {
            100: {
              internalHeightMM: 400,
              internalWidthMM: 100,
              WHRatio: 0.25,
            },
            150: {
              internalHeightMM: 400,
              internalWidthMM: 150,
              WHRatio: 0.375,
            },
            200: {
              internalHeightMM: 400,
              internalWidthMM: 200,
              WHRatio: 0.5,
            },
            250: {
              internalHeightMM: 400,
              internalWidthMM: 250,
              WHRatio: 0.625,
            },
            300: {
              internalHeightMM: 400,
              internalWidthMM: 300,
              WHRatio: 0.75,
            },
            400: {
              internalHeightMM: 400,
              internalWidthMM: 400,
              WHRatio: 1.0,
            },
            500: {
              internalHeightMM: 400,
              internalWidthMM: 500,
              WHRatio: 1.25,
            },
            600: {
              internalHeightMM: 400,
              internalWidthMM: 600,
              WHRatio: 1.5,
            },
            800: {
              internalHeightMM: 400,
              internalWidthMM: 800,
              WHRatio: 2.0,
            },
            1000: {
              internalHeightMM: 400,
              internalWidthMM: 1000,
              WHRatio: 2.5,
            },
            1200: {
              internalHeightMM: 400,
              internalWidthMM: 1200,
              WHRatio: 3.0,
            },
            1400: {
              internalHeightMM: 400,
              internalWidthMM: 1400,
              WHRatio: 3.5,
            },
            1600: {
              internalHeightMM: 400,
              internalWidthMM: 1600,
              WHRatio: 4.0,
            },
          },
          500: {
            150: {
              internalHeightMM: 500,
              internalWidthMM: 150,
              WHRatio: 0.3,
            },
            200: {
              internalHeightMM: 500,
              internalWidthMM: 200,
              WHRatio: 0.4,
            },
            250: {
              internalHeightMM: 500,
              internalWidthMM: 250,
              WHRatio: 0.5,
            },
            300: {
              internalHeightMM: 500,
              internalWidthMM: 300,
              WHRatio: 0.6,
            },
            400: {
              internalHeightMM: 500,
              internalWidthMM: 400,
              WHRatio: 0.8,
            },
            500: {
              internalHeightMM: 500,
              internalWidthMM: 500,
              WHRatio: 1.0,
            },
            600: {
              internalHeightMM: 500,
              internalWidthMM: 600,
              WHRatio: 1.2,
            },
            800: {
              internalHeightMM: 500,
              internalWidthMM: 800,
              WHRatio: 1.6,
            },
            1000: {
              internalHeightMM: 500,
              internalWidthMM: 1000,
              WHRatio: 2.0,
            },
            1200: {
              internalHeightMM: 500,
              internalWidthMM: 1200,
              WHRatio: 2.4,
            },
            1400: {
              internalHeightMM: 500,
              internalWidthMM: 1400,
              WHRatio: 2.8,
            },
            1600: {
              internalHeightMM: 500,
              internalWidthMM: 1600,
              WHRatio: 3.2,
            },
            1800: {
              internalHeightMM: 500,
              internalWidthMM: 1800,
              WHRatio: 3.6,
            },
            2000: {
              internalHeightMM: 500,
              internalWidthMM: 2000,
              WHRatio: 4.0,
            },
          },
          600: {
            150: {
              internalHeightMM: 600,
              internalWidthMM: 150,
              WHRatio: 0.25,
            },
            200: {
              internalHeightMM: 600,
              internalWidthMM: 200,
              WHRatio: 0.3333333333333333,
            },
            250: {
              internalHeightMM: 600,
              internalWidthMM: 250,
              WHRatio: 0.4166666666666667,
            },
            300: {
              internalHeightMM: 600,
              internalWidthMM: 300,
              WHRatio: 0.5,
            },
            400: {
              internalHeightMM: 600,
              internalWidthMM: 400,
              WHRatio: 0.6666666666666666,
            },
            500: {
              internalHeightMM: 600,
              internalWidthMM: 500,
              WHRatio: 0.8333333333333334,
            },
            600: {
              internalHeightMM: 600,
              internalWidthMM: 600,
              WHRatio: 1.0,
            },
            800: {
              internalHeightMM: 600,
              internalWidthMM: 800,
              WHRatio: 1.3333333333333333,
            },
            1000: {
              internalHeightMM: 600,
              internalWidthMM: 1000,
              WHRatio: 1.6666666666666667,
            },
            1200: {
              internalHeightMM: 600,
              internalWidthMM: 1200,
              WHRatio: 2.0,
            },
            1400: {
              internalHeightMM: 600,
              internalWidthMM: 1400,
              WHRatio: 2.3333333333333335,
            },
            1600: {
              internalHeightMM: 600,
              internalWidthMM: 1600,
              WHRatio: 2.6666666666666665,
            },
            1800: {
              internalHeightMM: 600,
              internalWidthMM: 1800,
              WHRatio: 3.0,
            },
            2000: {
              internalHeightMM: 600,
              internalWidthMM: 2000,
              WHRatio: 3.3333333333333335,
            },
          },
          800: {
            200: {
              internalHeightMM: 800,
              internalWidthMM: 200,
              WHRatio: 0.25,
            },
            250: {
              internalHeightMM: 800,
              internalWidthMM: 250,
              WHRatio: 0.3125,
            },
            300: {
              internalHeightMM: 800,
              internalWidthMM: 300,
              WHRatio: 0.375,
            },
            400: {
              internalHeightMM: 800,
              internalWidthMM: 400,
              WHRatio: 0.5,
            },
            500: {
              internalHeightMM: 800,
              internalWidthMM: 500,
              WHRatio: 0.625,
            },
            600: {
              internalHeightMM: 800,
              internalWidthMM: 600,
              WHRatio: 0.75,
            },
            800: {
              internalHeightMM: 800,
              internalWidthMM: 800,
              WHRatio: 1.0,
            },
            1000: {
              internalHeightMM: 800,
              internalWidthMM: 1000,
              WHRatio: 1.25,
            },
            1200: {
              internalHeightMM: 800,
              internalWidthMM: 1200,
              WHRatio: 1.5,
            },
            1400: {
              internalHeightMM: 800,
              internalWidthMM: 1400,
              WHRatio: 1.75,
            },
            1600: {
              internalHeightMM: 800,
              internalWidthMM: 1600,
              WHRatio: 2.0,
            },
            1800: {
              internalHeightMM: 800,
              internalWidthMM: 1800,
              WHRatio: 2.25,
            },
            2000: {
              internalHeightMM: 800,
              internalWidthMM: 2000,
              WHRatio: 2.5,
            },
          },
          1000: {
            250: {
              internalHeightMM: 1000,
              internalWidthMM: 250,
              WHRatio: 0.25,
            },
            300: {
              internalHeightMM: 1000,
              internalWidthMM: 300,
              WHRatio: 0.3,
            },
            400: {
              internalHeightMM: 1000,
              internalWidthMM: 400,
              WHRatio: 0.4,
            },
            500: {
              internalHeightMM: 1000,
              internalWidthMM: 500,
              WHRatio: 0.5,
            },
            600: {
              internalHeightMM: 1000,
              internalWidthMM: 600,
              WHRatio: 0.6,
            },
            800: {
              internalHeightMM: 1000,
              internalWidthMM: 800,
              WHRatio: 0.8,
            },
            1000: {
              internalHeightMM: 1000,
              internalWidthMM: 1000,
              WHRatio: 1.0,
            },
            1200: {
              internalHeightMM: 1000,
              internalWidthMM: 1200,
              WHRatio: 1.2,
            },
            1400: {
              internalHeightMM: 1000,
              internalWidthMM: 1400,
              WHRatio: 1.4,
            },
            1600: {
              internalHeightMM: 1000,
              internalWidthMM: 1600,
              WHRatio: 1.6,
            },
            1800: {
              internalHeightMM: 1000,
              internalWidthMM: 1800,
              WHRatio: 1.8,
            },
            2000: {
              internalHeightMM: 1000,
              internalWidthMM: 2000,
              WHRatio: 2.0,
            },
          },
          1200: {
            300: {
              internalHeightMM: 1200,
              internalWidthMM: 300,
              WHRatio: 0.25,
            },
            400: {
              internalHeightMM: 1200,
              internalWidthMM: 400,
              WHRatio: 0.3333333333333333,
            },
            500: {
              internalHeightMM: 1200,
              internalWidthMM: 500,
              WHRatio: 0.4166666666666667,
            },
            600: {
              internalHeightMM: 1200,
              internalWidthMM: 600,
              WHRatio: 0.5,
            },
            800: {
              internalHeightMM: 1200,
              internalWidthMM: 800,
              WHRatio: 0.6666666666666666,
            },
            1000: {
              internalHeightMM: 1200,
              internalWidthMM: 1000,
              WHRatio: 0.8333333333333334,
            },
            1200: {
              internalHeightMM: 1200,
              internalWidthMM: 1200,
              WHRatio: 1.0,
            },
            1400: {
              internalHeightMM: 1200,
              internalWidthMM: 1400,
              WHRatio: 1.1666666666666667,
            },
            1600: {
              internalHeightMM: 1200,
              internalWidthMM: 1600,
              WHRatio: 1.3333333333333333,
            },
            1800: {
              internalHeightMM: 1200,
              internalWidthMM: 1800,
              WHRatio: 1.5,
            },
            2000: {
              internalHeightMM: 1200,
              internalWidthMM: 2000,
              WHRatio: 1.6666666666666667,
            },
          },
          1400: {
            400: {
              internalHeightMM: 1400,
              internalWidthMM: 400,
              WHRatio: 0.2857142857142857,
            },
            500: {
              internalHeightMM: 1400,
              internalWidthMM: 500,
              WHRatio: 0.35714285714285715,
            },
            600: {
              internalHeightMM: 1400,
              internalWidthMM: 600,
              WHRatio: 0.42857142857142855,
            },
            800: {
              internalHeightMM: 1400,
              internalWidthMM: 800,
              WHRatio: 0.5714285714285714,
            },
            1000: {
              internalHeightMM: 1400,
              internalWidthMM: 1000,
              WHRatio: 0.7142857142857143,
            },
            1200: {
              internalHeightMM: 1400,
              internalWidthMM: 1200,
              WHRatio: 0.8571428571428571,
            },
          },
          1600: {
            400: {
              internalHeightMM: 1600,
              internalWidthMM: 400,
              WHRatio: 0.25,
            },
            500: {
              internalHeightMM: 1600,
              internalWidthMM: 500,
              WHRatio: 0.3125,
            },
            600: {
              internalHeightMM: 1600,
              internalWidthMM: 600,
              WHRatio: 0.375,
            },
            800: {
              internalHeightMM: 1600,
              internalWidthMM: 800,
              WHRatio: 0.5,
            },
            1000: {
              internalHeightMM: 1600,
              internalWidthMM: 1000,
              WHRatio: 0.625,
            },
            1200: {
              internalHeightMM: 1600,
              internalWidthMM: 1200,
              WHRatio: 0.75,
            },
          },
          1800: {
            500: {
              internalHeightMM: 1800,
              internalWidthMM: 500,
              WHRatio: 0.2777777777777778,
            },
            600: {
              internalHeightMM: 1800,
              internalWidthMM: 600,
              WHRatio: 0.3333333333333333,
            },
            800: {
              internalHeightMM: 1800,
              internalWidthMM: 800,
              WHRatio: 0.4444444444444444,
            },
            1000: {
              internalHeightMM: 1800,
              internalWidthMM: 1000,
              WHRatio: 0.5555555555555556,
            },
            1200: {
              internalHeightMM: 1800,
              internalWidthMM: 1200,
              WHRatio: 0.6666666666666666,
            },
          },
          2000: {
            500: {
              internalHeightMM: 2000,
              internalWidthMM: 500,
              WHRatio: 0.25,
            },
            600: {
              internalHeightMM: 2000,
              internalWidthMM: 600,
              WHRatio: 0.3,
            },
            800: {
              internalHeightMM: 2000,
              internalWidthMM: 800,
              WHRatio: 0.4,
            },
            1000: {
              internalHeightMM: 2000,
              internalWidthMM: 1000,
              WHRatio: 0.5,
            },
            1200: {
              internalHeightMM: 2000,
              internalWidthMM: 1200,
              WHRatio: 0.6,
            },
          },
        },
        colebrookWhiteCoefficient: 0.5,
      },
    },
  },

  galvanisedSteel: {
    name: "Galvanised Steel",
    uid: "galvanisedSteel",
    abbreviation: "GS",
    manufacturers: [
      {
        name: "Generic",
        abbreviation: "Generic",
        priceTableName: "Galvanised Steel",
        uid: "generic",
        isGeneric: true,
      },
    ],
    data: {
      generic: {
        circularByDiameter: {
          63: { internalDiameterMM: 63 },
          75: { internalDiameterMM: 75 },
          80: { internalDiameterMM: 80 },
          90: { internalDiameterMM: 90 },
          100: { internalDiameterMM: 100 },
          125: { internalDiameterMM: 125 },
          160: { internalDiameterMM: 160 },
          200: { internalDiameterMM: 200 },
          250: { internalDiameterMM: 250 },
          315: { internalDiameterMM: 315 },
          355: { internalDiameterMM: 355 },
          400: { internalDiameterMM: 400 },
          450: { internalDiameterMM: 450 },
          500: { internalDiameterMM: 500 },
          560: { internalDiameterMM: 560 },
          630: { internalDiameterMM: 630 },
          710: { internalDiameterMM: 710 },
          800: { internalDiameterMM: 800 },
          900: { internalDiameterMM: 900 },
          1000: { internalDiameterMM: 1000 },
          1120: { internalDiameterMM: 1120 },
          1250: { internalDiameterMM: 1250 },
        },
        rectangularByHeightWidth: {
          100: {
            100: {
              internalHeightMM: 100,
              internalWidthMM: 100,
              WHRatio: 1.0,
            },
            150: {
              internalHeightMM: 100,
              internalWidthMM: 150,
              WHRatio: 1.5,
            },
            200: {
              internalHeightMM: 100,
              internalWidthMM: 200,
              WHRatio: 2.0,
            },
            250: {
              internalHeightMM: 100,
              internalWidthMM: 250,
              WHRatio: 2.5,
            },
            300: {
              internalHeightMM: 100,
              internalWidthMM: 300,
              WHRatio: 3.0,
            },
            400: {
              internalHeightMM: 100,
              internalWidthMM: 400,
              WHRatio: 4.0,
            },
          },
          150: {
            100: {
              internalHeightMM: 150,
              internalWidthMM: 100,
              WHRatio: 0.6666666666666666,
            },
            150: {
              internalHeightMM: 150,
              internalWidthMM: 150,
              WHRatio: 1.0,
            },
            200: {
              internalHeightMM: 150,
              internalWidthMM: 200,
              WHRatio: 1.3333333333333333,
            },
            250: {
              internalHeightMM: 150,
              internalWidthMM: 250,
              WHRatio: 1.6666666666666667,
            },
            300: {
              internalHeightMM: 150,
              internalWidthMM: 300,
              WHRatio: 2.0,
            },
            400: {
              internalHeightMM: 150,
              internalWidthMM: 400,
              WHRatio: 2.6666666666666665,
            },
            500: {
              internalHeightMM: 150,
              internalWidthMM: 500,
              WHRatio: 3.3333333333333335,
            },
            600: {
              internalHeightMM: 150,
              internalWidthMM: 600,
              WHRatio: 4.0,
            },
          },
          200: {
            100: {
              internalHeightMM: 200,
              internalWidthMM: 100,
              WHRatio: 0.5,
            },
            150: {
              internalHeightMM: 200,
              internalWidthMM: 150,
              WHRatio: 0.75,
            },
            200: {
              internalHeightMM: 200,
              internalWidthMM: 200,
              WHRatio: 1.0,
            },
            250: {
              internalHeightMM: 200,
              internalWidthMM: 250,
              WHRatio: 1.25,
            },
            300: {
              internalHeightMM: 200,
              internalWidthMM: 300,
              WHRatio: 1.5,
            },
            400: {
              internalHeightMM: 200,
              internalWidthMM: 400,
              WHRatio: 2.0,
            },
            500: {
              internalHeightMM: 200,
              internalWidthMM: 500,
              WHRatio: 2.5,
            },
            600: {
              internalHeightMM: 200,
              internalWidthMM: 600,
              WHRatio: 3.0,
            },
            800: {
              internalHeightMM: 200,
              internalWidthMM: 800,
              WHRatio: 4.0,
            },
          },
          250: {
            100: {
              internalHeightMM: 250,
              internalWidthMM: 100,
              WHRatio: 0.4,
            },
            150: {
              internalHeightMM: 250,
              internalWidthMM: 150,
              WHRatio: 0.6,
            },
            200: {
              internalHeightMM: 250,
              internalWidthMM: 200,
              WHRatio: 0.8,
            },
            250: {
              internalHeightMM: 250,
              internalWidthMM: 250,
              WHRatio: 1.0,
            },
            300: {
              internalHeightMM: 250,
              internalWidthMM: 300,
              WHRatio: 1.2,
            },
            400: {
              internalHeightMM: 250,
              internalWidthMM: 400,
              WHRatio: 1.6,
            },
            500: {
              internalHeightMM: 250,
              internalWidthMM: 500,
              WHRatio: 2.0,
            },
            600: {
              internalHeightMM: 250,
              internalWidthMM: 600,
              WHRatio: 2.4,
            },
            800: {
              internalHeightMM: 250,
              internalWidthMM: 800,
              WHRatio: 3.2,
            },
            1000: {
              internalHeightMM: 250,
              internalWidthMM: 1000,
              WHRatio: 4.0,
            },
          },
          300: {
            100: {
              internalHeightMM: 300,
              internalWidthMM: 100,
              WHRatio: 0.3333333333333333,
            },
            150: {
              internalHeightMM: 300,
              internalWidthMM: 150,
              WHRatio: 0.5,
            },
            200: {
              internalHeightMM: 300,
              internalWidthMM: 200,
              WHRatio: 0.6666666666666666,
            },
            250: {
              internalHeightMM: 300,
              internalWidthMM: 250,
              WHRatio: 0.8333333333333334,
            },
            300: {
              internalHeightMM: 300,
              internalWidthMM: 300,
              WHRatio: 1.0,
            },
            400: {
              internalHeightMM: 300,
              internalWidthMM: 400,
              WHRatio: 1.3333333333333333,
            },
            500: {
              internalHeightMM: 300,
              internalWidthMM: 500,
              WHRatio: 1.6666666666666667,
            },
            600: {
              internalHeightMM: 300,
              internalWidthMM: 600,
              WHRatio: 2.0,
            },
            800: {
              internalHeightMM: 300,
              internalWidthMM: 800,
              WHRatio: 2.6666666666666665,
            },
            1000: {
              internalHeightMM: 300,
              internalWidthMM: 1000,
              WHRatio: 3.3333333333333335,
            },
            1200: {
              internalHeightMM: 300,
              internalWidthMM: 1200,
              WHRatio: 4.0,
            },
          },
          400: {
            100: {
              internalHeightMM: 400,
              internalWidthMM: 100,
              WHRatio: 0.25,
            },
            150: {
              internalHeightMM: 400,
              internalWidthMM: 150,
              WHRatio: 0.375,
            },
            200: {
              internalHeightMM: 400,
              internalWidthMM: 200,
              WHRatio: 0.5,
            },
            250: {
              internalHeightMM: 400,
              internalWidthMM: 250,
              WHRatio: 0.625,
            },
            300: {
              internalHeightMM: 400,
              internalWidthMM: 300,
              WHRatio: 0.75,
            },
            400: {
              internalHeightMM: 400,
              internalWidthMM: 400,
              WHRatio: 1.0,
            },
            500: {
              internalHeightMM: 400,
              internalWidthMM: 500,
              WHRatio: 1.25,
            },
            600: {
              internalHeightMM: 400,
              internalWidthMM: 600,
              WHRatio: 1.5,
            },
            800: {
              internalHeightMM: 400,
              internalWidthMM: 800,
              WHRatio: 2.0,
            },
            1000: {
              internalHeightMM: 400,
              internalWidthMM: 1000,
              WHRatio: 2.5,
            },
            1200: {
              internalHeightMM: 400,
              internalWidthMM: 1200,
              WHRatio: 3.0,
            },
            1400: {
              internalHeightMM: 400,
              internalWidthMM: 1400,
              WHRatio: 3.5,
            },
            1600: {
              internalHeightMM: 400,
              internalWidthMM: 1600,
              WHRatio: 4.0,
            },
          },
          500: {
            150: {
              internalHeightMM: 500,
              internalWidthMM: 150,
              WHRatio: 0.3,
            },
            200: {
              internalHeightMM: 500,
              internalWidthMM: 200,
              WHRatio: 0.4,
            },
            250: {
              internalHeightMM: 500,
              internalWidthMM: 250,
              WHRatio: 0.5,
            },
            300: {
              internalHeightMM: 500,
              internalWidthMM: 300,
              WHRatio: 0.6,
            },
            400: {
              internalHeightMM: 500,
              internalWidthMM: 400,
              WHRatio: 0.8,
            },
            500: {
              internalHeightMM: 500,
              internalWidthMM: 500,
              WHRatio: 1.0,
            },
            600: {
              internalHeightMM: 500,
              internalWidthMM: 600,
              WHRatio: 1.2,
            },
            800: {
              internalHeightMM: 500,
              internalWidthMM: 800,
              WHRatio: 1.6,
            },
            1000: {
              internalHeightMM: 500,
              internalWidthMM: 1000,
              WHRatio: 2.0,
            },
            1200: {
              internalHeightMM: 500,
              internalWidthMM: 1200,
              WHRatio: 2.4,
            },
            1400: {
              internalHeightMM: 500,
              internalWidthMM: 1400,
              WHRatio: 2.8,
            },
            1600: {
              internalHeightMM: 500,
              internalWidthMM: 1600,
              WHRatio: 3.2,
            },
            1800: {
              internalHeightMM: 500,
              internalWidthMM: 1800,
              WHRatio: 3.6,
            },
            2000: {
              internalHeightMM: 500,
              internalWidthMM: 2000,
              WHRatio: 4.0,
            },
          },
          600: {
            150: {
              internalHeightMM: 600,
              internalWidthMM: 150,
              WHRatio: 0.25,
            },
            200: {
              internalHeightMM: 600,
              internalWidthMM: 200,
              WHRatio: 0.3333333333333333,
            },
            250: {
              internalHeightMM: 600,
              internalWidthMM: 250,
              WHRatio: 0.4166666666666667,
            },
            300: {
              internalHeightMM: 600,
              internalWidthMM: 300,
              WHRatio: 0.5,
            },
            400: {
              internalHeightMM: 600,
              internalWidthMM: 400,
              WHRatio: 0.6666666666666666,
            },
            500: {
              internalHeightMM: 600,
              internalWidthMM: 500,
              WHRatio: 0.8333333333333334,
            },
            600: {
              internalHeightMM: 600,
              internalWidthMM: 600,
              WHRatio: 1.0,
            },
            800: {
              internalHeightMM: 600,
              internalWidthMM: 800,
              WHRatio: 1.3333333333333333,
            },
            1000: {
              internalHeightMM: 600,
              internalWidthMM: 1000,
              WHRatio: 1.6666666666666667,
            },
            1200: {
              internalHeightMM: 600,
              internalWidthMM: 1200,
              WHRatio: 2.0,
            },
            1400: {
              internalHeightMM: 600,
              internalWidthMM: 1400,
              WHRatio: 2.3333333333333335,
            },
            1600: {
              internalHeightMM: 600,
              internalWidthMM: 1600,
              WHRatio: 2.6666666666666665,
            },
            1800: {
              internalHeightMM: 600,
              internalWidthMM: 1800,
              WHRatio: 3.0,
            },
            2000: {
              internalHeightMM: 600,
              internalWidthMM: 2000,
              WHRatio: 3.3333333333333335,
            },
          },
          800: {
            200: {
              internalHeightMM: 800,
              internalWidthMM: 200,
              WHRatio: 0.25,
            },
            250: {
              internalHeightMM: 800,
              internalWidthMM: 250,
              WHRatio: 0.3125,
            },
            300: {
              internalHeightMM: 800,
              internalWidthMM: 300,
              WHRatio: 0.375,
            },
            400: {
              internalHeightMM: 800,
              internalWidthMM: 400,
              WHRatio: 0.5,
            },
            500: {
              internalHeightMM: 800,
              internalWidthMM: 500,
              WHRatio: 0.625,
            },
            600: {
              internalHeightMM: 800,
              internalWidthMM: 600,
              WHRatio: 0.75,
            },
            800: {
              internalHeightMM: 800,
              internalWidthMM: 800,
              WHRatio: 1.0,
            },
            1000: {
              internalHeightMM: 800,
              internalWidthMM: 1000,
              WHRatio: 1.25,
            },
            1200: {
              internalHeightMM: 800,
              internalWidthMM: 1200,
              WHRatio: 1.5,
            },
            1400: {
              internalHeightMM: 800,
              internalWidthMM: 1400,
              WHRatio: 1.75,
            },
            1600: {
              internalHeightMM: 800,
              internalWidthMM: 1600,
              WHRatio: 2.0,
            },
            1800: {
              internalHeightMM: 800,
              internalWidthMM: 1800,
              WHRatio: 2.25,
            },
            2000: {
              internalHeightMM: 800,
              internalWidthMM: 2000,
              WHRatio: 2.5,
            },
          },
          1000: {
            250: {
              internalHeightMM: 1000,
              internalWidthMM: 250,
              WHRatio: 0.25,
            },
            300: {
              internalHeightMM: 1000,
              internalWidthMM: 300,
              WHRatio: 0.3,
            },
            400: {
              internalHeightMM: 1000,
              internalWidthMM: 400,
              WHRatio: 0.4,
            },
            500: {
              internalHeightMM: 1000,
              internalWidthMM: 500,
              WHRatio: 0.5,
            },
            600: {
              internalHeightMM: 1000,
              internalWidthMM: 600,
              WHRatio: 0.6,
            },
            800: {
              internalHeightMM: 1000,
              internalWidthMM: 800,
              WHRatio: 0.8,
            },
            1000: {
              internalHeightMM: 1000,
              internalWidthMM: 1000,
              WHRatio: 1.0,
            },
            1200: {
              internalHeightMM: 1000,
              internalWidthMM: 1200,
              WHRatio: 1.2,
            },
            1400: {
              internalHeightMM: 1000,
              internalWidthMM: 1400,
              WHRatio: 1.4,
            },
            1600: {
              internalHeightMM: 1000,
              internalWidthMM: 1600,
              WHRatio: 1.6,
            },
            1800: {
              internalHeightMM: 1000,
              internalWidthMM: 1800,
              WHRatio: 1.8,
            },
            2000: {
              internalHeightMM: 1000,
              internalWidthMM: 2000,
              WHRatio: 2.0,
            },
          },
          1200: {
            300: {
              internalHeightMM: 1200,
              internalWidthMM: 300,
              WHRatio: 0.25,
            },
            400: {
              internalHeightMM: 1200,
              internalWidthMM: 400,
              WHRatio: 0.3333333333333333,
            },
            500: {
              internalHeightMM: 1200,
              internalWidthMM: 500,
              WHRatio: 0.4166666666666667,
            },
            600: {
              internalHeightMM: 1200,
              internalWidthMM: 600,
              WHRatio: 0.5,
            },
            800: {
              internalHeightMM: 1200,
              internalWidthMM: 800,
              WHRatio: 0.6666666666666666,
            },
            1000: {
              internalHeightMM: 1200,
              internalWidthMM: 1000,
              WHRatio: 0.8333333333333334,
            },
            1200: {
              internalHeightMM: 1200,
              internalWidthMM: 1200,
              WHRatio: 1.0,
            },
            1400: {
              internalHeightMM: 1200,
              internalWidthMM: 1400,
              WHRatio: 1.1666666666666667,
            },
            1600: {
              internalHeightMM: 1200,
              internalWidthMM: 1600,
              WHRatio: 1.3333333333333333,
            },
            1800: {
              internalHeightMM: 1200,
              internalWidthMM: 1800,
              WHRatio: 1.5,
            },
            2000: {
              internalHeightMM: 1200,
              internalWidthMM: 2000,
              WHRatio: 1.6666666666666667,
            },
          },
          1400: {
            400: {
              internalHeightMM: 1400,
              internalWidthMM: 400,
              WHRatio: 0.2857142857142857,
            },
            500: {
              internalHeightMM: 1400,
              internalWidthMM: 500,
              WHRatio: 0.35714285714285715,
            },
            600: {
              internalHeightMM: 1400,
              internalWidthMM: 600,
              WHRatio: 0.42857142857142855,
            },
            800: {
              internalHeightMM: 1400,
              internalWidthMM: 800,
              WHRatio: 0.5714285714285714,
            },
            1000: {
              internalHeightMM: 1400,
              internalWidthMM: 1000,
              WHRatio: 0.7142857142857143,
            },
            1200: {
              internalHeightMM: 1400,
              internalWidthMM: 1200,
              WHRatio: 0.8571428571428571,
            },
          },
          1600: {
            400: {
              internalHeightMM: 1600,
              internalWidthMM: 400,
              WHRatio: 0.25,
            },
            500: {
              internalHeightMM: 1600,
              internalWidthMM: 500,
              WHRatio: 0.3125,
            },
            600: {
              internalHeightMM: 1600,
              internalWidthMM: 600,
              WHRatio: 0.375,
            },
            800: {
              internalHeightMM: 1600,
              internalWidthMM: 800,
              WHRatio: 0.5,
            },
            1000: {
              internalHeightMM: 1600,
              internalWidthMM: 1000,
              WHRatio: 0.625,
            },
            1200: {
              internalHeightMM: 1600,
              internalWidthMM: 1200,
              WHRatio: 0.75,
            },
          },
          1800: {
            500: {
              internalHeightMM: 1800,
              internalWidthMM: 500,
              WHRatio: 0.2777777777777778,
            },
            600: {
              internalHeightMM: 1800,
              internalWidthMM: 600,
              WHRatio: 0.3333333333333333,
            },
            800: {
              internalHeightMM: 1800,
              internalWidthMM: 800,
              WHRatio: 0.4444444444444444,
            },
            1000: {
              internalHeightMM: 1800,
              internalWidthMM: 1000,
              WHRatio: 0.5555555555555556,
            },
            1200: {
              internalHeightMM: 1800,
              internalWidthMM: 1200,
              WHRatio: 0.6666666666666666,
            },
          },
          2000: {
            500: {
              internalHeightMM: 2000,
              internalWidthMM: 500,
              WHRatio: 0.25,
            },
            600: {
              internalHeightMM: 2000,
              internalWidthMM: 600,
              WHRatio: 0.3,
            },
            800: {
              internalHeightMM: 2000,
              internalWidthMM: 800,
              WHRatio: 0.4,
            },
            1000: {
              internalHeightMM: 2000,
              internalWidthMM: 1000,
              WHRatio: 0.5,
            },
            1200: {
              internalHeightMM: 2000,
              internalWidthMM: 1200,
              WHRatio: 0.6,
            },
          },
        },
        colebrookWhiteCoefficient: 0.09,
      },
    },
  },

  hotDipGalvanisedSteel: {
    name: "Hot Dip Galvanised Steel",
    uid: "hotDipGalvanisedSteel",
    abbreviation: "HDGS",
    manufacturers: [
      {
        name: "Generic",
        abbreviation: "Generic",
        priceTableName: "Hot Dip Galvanised Steel",
        uid: "generic",
        isGeneric: true,
      },
    ],
    data: {
      generic: {
        circularByDiameter: {
          63: { internalDiameterMM: 63 },
          75: { internalDiameterMM: 75 },
          80: { internalDiameterMM: 80 },
          90: { internalDiameterMM: 90 },
          100: { internalDiameterMM: 100 },
          125: { internalDiameterMM: 125 },
          160: { internalDiameterMM: 160 },
          200: { internalDiameterMM: 200 },
          250: { internalDiameterMM: 250 },
          315: { internalDiameterMM: 315 },
          355: { internalDiameterMM: 355 },
          400: { internalDiameterMM: 400 },
          450: { internalDiameterMM: 450 },
          500: { internalDiameterMM: 500 },
          560: { internalDiameterMM: 560 },
          630: { internalDiameterMM: 630 },
          710: { internalDiameterMM: 710 },
          800: { internalDiameterMM: 800 },
          900: { internalDiameterMM: 900 },
          1000: { internalDiameterMM: 1000 },
          1120: { internalDiameterMM: 1120 },
          1250: { internalDiameterMM: 1250 },
        },
        rectangularByHeightWidth: {
          100: {
            100: {
              internalHeightMM: 100,
              internalWidthMM: 100,
              WHRatio: 1.0,
            },
            150: {
              internalHeightMM: 100,
              internalWidthMM: 150,
              WHRatio: 1.5,
            },
            200: {
              internalHeightMM: 100,
              internalWidthMM: 200,
              WHRatio: 2.0,
            },
            250: {
              internalHeightMM: 100,
              internalWidthMM: 250,
              WHRatio: 2.5,
            },
            300: {
              internalHeightMM: 100,
              internalWidthMM: 300,
              WHRatio: 3.0,
            },
            400: {
              internalHeightMM: 100,
              internalWidthMM: 400,
              WHRatio: 4.0,
            },
          },
          150: {
            100: {
              internalHeightMM: 150,
              internalWidthMM: 100,
              WHRatio: 0.6666666666666666,
            },
            150: {
              internalHeightMM: 150,
              internalWidthMM: 150,
              WHRatio: 1.0,
            },
            200: {
              internalHeightMM: 150,
              internalWidthMM: 200,
              WHRatio: 1.3333333333333333,
            },
            250: {
              internalHeightMM: 150,
              internalWidthMM: 250,
              WHRatio: 1.6666666666666667,
            },
            300: {
              internalHeightMM: 150,
              internalWidthMM: 300,
              WHRatio: 2.0,
            },
            400: {
              internalHeightMM: 150,
              internalWidthMM: 400,
              WHRatio: 2.6666666666666665,
            },
            500: {
              internalHeightMM: 150,
              internalWidthMM: 500,
              WHRatio: 3.3333333333333335,
            },
            600: {
              internalHeightMM: 150,
              internalWidthMM: 600,
              WHRatio: 4.0,
            },
          },
          200: {
            100: {
              internalHeightMM: 200,
              internalWidthMM: 100,
              WHRatio: 0.5,
            },
            150: {
              internalHeightMM: 200,
              internalWidthMM: 150,
              WHRatio: 0.75,
            },
            200: {
              internalHeightMM: 200,
              internalWidthMM: 200,
              WHRatio: 1.0,
            },
            250: {
              internalHeightMM: 200,
              internalWidthMM: 250,
              WHRatio: 1.25,
            },
            300: {
              internalHeightMM: 200,
              internalWidthMM: 300,
              WHRatio: 1.5,
            },
            400: {
              internalHeightMM: 200,
              internalWidthMM: 400,
              WHRatio: 2.0,
            },
            500: {
              internalHeightMM: 200,
              internalWidthMM: 500,
              WHRatio: 2.5,
            },
            600: {
              internalHeightMM: 200,
              internalWidthMM: 600,
              WHRatio: 3.0,
            },
            800: {
              internalHeightMM: 200,
              internalWidthMM: 800,
              WHRatio: 4.0,
            },
          },
          250: {
            100: {
              internalHeightMM: 250,
              internalWidthMM: 100,
              WHRatio: 0.4,
            },
            150: {
              internalHeightMM: 250,
              internalWidthMM: 150,
              WHRatio: 0.6,
            },
            200: {
              internalHeightMM: 250,
              internalWidthMM: 200,
              WHRatio: 0.8,
            },
            250: {
              internalHeightMM: 250,
              internalWidthMM: 250,
              WHRatio: 1.0,
            },
            300: {
              internalHeightMM: 250,
              internalWidthMM: 300,
              WHRatio: 1.2,
            },
            400: {
              internalHeightMM: 250,
              internalWidthMM: 400,
              WHRatio: 1.6,
            },
            500: {
              internalHeightMM: 250,
              internalWidthMM: 500,
              WHRatio: 2.0,
            },
            600: {
              internalHeightMM: 250,
              internalWidthMM: 600,
              WHRatio: 2.4,
            },
            800: {
              internalHeightMM: 250,
              internalWidthMM: 800,
              WHRatio: 3.2,
            },
            1000: {
              internalHeightMM: 250,
              internalWidthMM: 1000,
              WHRatio: 4.0,
            },
          },
          300: {
            100: {
              internalHeightMM: 300,
              internalWidthMM: 100,
              WHRatio: 0.3333333333333333,
            },
            150: {
              internalHeightMM: 300,
              internalWidthMM: 150,
              WHRatio: 0.5,
            },
            200: {
              internalHeightMM: 300,
              internalWidthMM: 200,
              WHRatio: 0.6666666666666666,
            },
            250: {
              internalHeightMM: 300,
              internalWidthMM: 250,
              WHRatio: 0.8333333333333334,
            },
            300: {
              internalHeightMM: 300,
              internalWidthMM: 300,
              WHRatio: 1.0,
            },
            400: {
              internalHeightMM: 300,
              internalWidthMM: 400,
              WHRatio: 1.3333333333333333,
            },
            500: {
              internalHeightMM: 300,
              internalWidthMM: 500,
              WHRatio: 1.6666666666666667,
            },
            600: {
              internalHeightMM: 300,
              internalWidthMM: 600,
              WHRatio: 2.0,
            },
            800: {
              internalHeightMM: 300,
              internalWidthMM: 800,
              WHRatio: 2.6666666666666665,
            },
            1000: {
              internalHeightMM: 300,
              internalWidthMM: 1000,
              WHRatio: 3.3333333333333335,
            },
            1200: {
              internalHeightMM: 300,
              internalWidthMM: 1200,
              WHRatio: 4.0,
            },
          },
          400: {
            100: {
              internalHeightMM: 400,
              internalWidthMM: 100,
              WHRatio: 0.25,
            },
            150: {
              internalHeightMM: 400,
              internalWidthMM: 150,
              WHRatio: 0.375,
            },
            200: {
              internalHeightMM: 400,
              internalWidthMM: 200,
              WHRatio: 0.5,
            },
            250: {
              internalHeightMM: 400,
              internalWidthMM: 250,
              WHRatio: 0.625,
            },
            300: {
              internalHeightMM: 400,
              internalWidthMM: 300,
              WHRatio: 0.75,
            },
            400: {
              internalHeightMM: 400,
              internalWidthMM: 400,
              WHRatio: 1.0,
            },
            500: {
              internalHeightMM: 400,
              internalWidthMM: 500,
              WHRatio: 1.25,
            },
            600: {
              internalHeightMM: 400,
              internalWidthMM: 600,
              WHRatio: 1.5,
            },
            800: {
              internalHeightMM: 400,
              internalWidthMM: 800,
              WHRatio: 2.0,
            },
            1000: {
              internalHeightMM: 400,
              internalWidthMM: 1000,
              WHRatio: 2.5,
            },
            1200: {
              internalHeightMM: 400,
              internalWidthMM: 1200,
              WHRatio: 3.0,
            },
            1400: {
              internalHeightMM: 400,
              internalWidthMM: 1400,
              WHRatio: 3.5,
            },
            1600: {
              internalHeightMM: 400,
              internalWidthMM: 1600,
              WHRatio: 4.0,
            },
          },
          500: {
            150: {
              internalHeightMM: 500,
              internalWidthMM: 150,
              WHRatio: 0.3,
            },
            200: {
              internalHeightMM: 500,
              internalWidthMM: 200,
              WHRatio: 0.4,
            },
            250: {
              internalHeightMM: 500,
              internalWidthMM: 250,
              WHRatio: 0.5,
            },
            300: {
              internalHeightMM: 500,
              internalWidthMM: 300,
              WHRatio: 0.6,
            },
            400: {
              internalHeightMM: 500,
              internalWidthMM: 400,
              WHRatio: 0.8,
            },
            500: {
              internalHeightMM: 500,
              internalWidthMM: 500,
              WHRatio: 1.0,
            },
            600: {
              internalHeightMM: 500,
              internalWidthMM: 600,
              WHRatio: 1.2,
            },
            800: {
              internalHeightMM: 500,
              internalWidthMM: 800,
              WHRatio: 1.6,
            },
            1000: {
              internalHeightMM: 500,
              internalWidthMM: 1000,
              WHRatio: 2.0,
            },
            1200: {
              internalHeightMM: 500,
              internalWidthMM: 1200,
              WHRatio: 2.4,
            },
            1400: {
              internalHeightMM: 500,
              internalWidthMM: 1400,
              WHRatio: 2.8,
            },
            1600: {
              internalHeightMM: 500,
              internalWidthMM: 1600,
              WHRatio: 3.2,
            },
            1800: {
              internalHeightMM: 500,
              internalWidthMM: 1800,
              WHRatio: 3.6,
            },
            2000: {
              internalHeightMM: 500,
              internalWidthMM: 2000,
              WHRatio: 4.0,
            },
          },
          600: {
            150: {
              internalHeightMM: 600,
              internalWidthMM: 150,
              WHRatio: 0.25,
            },
            200: {
              internalHeightMM: 600,
              internalWidthMM: 200,
              WHRatio: 0.3333333333333333,
            },
            250: {
              internalHeightMM: 600,
              internalWidthMM: 250,
              WHRatio: 0.4166666666666667,
            },
            300: {
              internalHeightMM: 600,
              internalWidthMM: 300,
              WHRatio: 0.5,
            },
            400: {
              internalHeightMM: 600,
              internalWidthMM: 400,
              WHRatio: 0.6666666666666666,
            },
            500: {
              internalHeightMM: 600,
              internalWidthMM: 500,
              WHRatio: 0.8333333333333334,
            },
            600: {
              internalHeightMM: 600,
              internalWidthMM: 600,
              WHRatio: 1.0,
            },
            800: {
              internalHeightMM: 600,
              internalWidthMM: 800,
              WHRatio: 1.3333333333333333,
            },
            1000: {
              internalHeightMM: 600,
              internalWidthMM: 1000,
              WHRatio: 1.6666666666666667,
            },
            1200: {
              internalHeightMM: 600,
              internalWidthMM: 1200,
              WHRatio: 2.0,
            },
            1400: {
              internalHeightMM: 600,
              internalWidthMM: 1400,
              WHRatio: 2.3333333333333335,
            },
            1600: {
              internalHeightMM: 600,
              internalWidthMM: 1600,
              WHRatio: 2.6666666666666665,
            },
            1800: {
              internalHeightMM: 600,
              internalWidthMM: 1800,
              WHRatio: 3.0,
            },
            2000: {
              internalHeightMM: 600,
              internalWidthMM: 2000,
              WHRatio: 3.3333333333333335,
            },
          },
          800: {
            200: {
              internalHeightMM: 800,
              internalWidthMM: 200,
              WHRatio: 0.25,
            },
            250: {
              internalHeightMM: 800,
              internalWidthMM: 250,
              WHRatio: 0.3125,
            },
            300: {
              internalHeightMM: 800,
              internalWidthMM: 300,
              WHRatio: 0.375,
            },
            400: {
              internalHeightMM: 800,
              internalWidthMM: 400,
              WHRatio: 0.5,
            },
            500: {
              internalHeightMM: 800,
              internalWidthMM: 500,
              WHRatio: 0.625,
            },
            600: {
              internalHeightMM: 800,
              internalWidthMM: 600,
              WHRatio: 0.75,
            },
            800: {
              internalHeightMM: 800,
              internalWidthMM: 800,
              WHRatio: 1.0,
            },
            1000: {
              internalHeightMM: 800,
              internalWidthMM: 1000,
              WHRatio: 1.25,
            },
            1200: {
              internalHeightMM: 800,
              internalWidthMM: 1200,
              WHRatio: 1.5,
            },
            1400: {
              internalHeightMM: 800,
              internalWidthMM: 1400,
              WHRatio: 1.75,
            },
            1600: {
              internalHeightMM: 800,
              internalWidthMM: 1600,
              WHRatio: 2.0,
            },
            1800: {
              internalHeightMM: 800,
              internalWidthMM: 1800,
              WHRatio: 2.25,
            },
            2000: {
              internalHeightMM: 800,
              internalWidthMM: 2000,
              WHRatio: 2.5,
            },
          },
          1000: {
            250: {
              internalHeightMM: 1000,
              internalWidthMM: 250,
              WHRatio: 0.25,
            },
            300: {
              internalHeightMM: 1000,
              internalWidthMM: 300,
              WHRatio: 0.3,
            },
            400: {
              internalHeightMM: 1000,
              internalWidthMM: 400,
              WHRatio: 0.4,
            },
            500: {
              internalHeightMM: 1000,
              internalWidthMM: 500,
              WHRatio: 0.5,
            },
            600: {
              internalHeightMM: 1000,
              internalWidthMM: 600,
              WHRatio: 0.6,
            },
            800: {
              internalHeightMM: 1000,
              internalWidthMM: 800,
              WHRatio: 0.8,
            },
            1000: {
              internalHeightMM: 1000,
              internalWidthMM: 1000,
              WHRatio: 1.0,
            },
            1200: {
              internalHeightMM: 1000,
              internalWidthMM: 1200,
              WHRatio: 1.2,
            },
            1400: {
              internalHeightMM: 1000,
              internalWidthMM: 1400,
              WHRatio: 1.4,
            },
            1600: {
              internalHeightMM: 1000,
              internalWidthMM: 1600,
              WHRatio: 1.6,
            },
            1800: {
              internalHeightMM: 1000,
              internalWidthMM: 1800,
              WHRatio: 1.8,
            },
            2000: {
              internalHeightMM: 1000,
              internalWidthMM: 2000,
              WHRatio: 2.0,
            },
          },
          1200: {
            300: {
              internalHeightMM: 1200,
              internalWidthMM: 300,
              WHRatio: 0.25,
            },
            400: {
              internalHeightMM: 1200,
              internalWidthMM: 400,
              WHRatio: 0.3333333333333333,
            },
            500: {
              internalHeightMM: 1200,
              internalWidthMM: 500,
              WHRatio: 0.4166666666666667,
            },
            600: {
              internalHeightMM: 1200,
              internalWidthMM: 600,
              WHRatio: 0.5,
            },
            800: {
              internalHeightMM: 1200,
              internalWidthMM: 800,
              WHRatio: 0.6666666666666666,
            },
            1000: {
              internalHeightMM: 1200,
              internalWidthMM: 1000,
              WHRatio: 0.8333333333333334,
            },
            1200: {
              internalHeightMM: 1200,
              internalWidthMM: 1200,
              WHRatio: 1.0,
            },
            1400: {
              internalHeightMM: 1200,
              internalWidthMM: 1400,
              WHRatio: 1.1666666666666667,
            },
            1600: {
              internalHeightMM: 1200,
              internalWidthMM: 1600,
              WHRatio: 1.3333333333333333,
            },
            1800: {
              internalHeightMM: 1200,
              internalWidthMM: 1800,
              WHRatio: 1.5,
            },
            2000: {
              internalHeightMM: 1200,
              internalWidthMM: 2000,
              WHRatio: 1.6666666666666667,
            },
          },
          1400: {
            400: {
              internalHeightMM: 1400,
              internalWidthMM: 400,
              WHRatio: 0.2857142857142857,
            },
            500: {
              internalHeightMM: 1400,
              internalWidthMM: 500,
              WHRatio: 0.35714285714285715,
            },
            600: {
              internalHeightMM: 1400,
              internalWidthMM: 600,
              WHRatio: 0.42857142857142855,
            },
            800: {
              internalHeightMM: 1400,
              internalWidthMM: 800,
              WHRatio: 0.5714285714285714,
            },
            1000: {
              internalHeightMM: 1400,
              internalWidthMM: 1000,
              WHRatio: 0.7142857142857143,
            },
            1200: {
              internalHeightMM: 1400,
              internalWidthMM: 1200,
              WHRatio: 0.8571428571428571,
            },
          },
          1600: {
            400: {
              internalHeightMM: 1600,
              internalWidthMM: 400,
              WHRatio: 0.25,
            },
            500: {
              internalHeightMM: 1600,
              internalWidthMM: 500,
              WHRatio: 0.3125,
            },
            600: {
              internalHeightMM: 1600,
              internalWidthMM: 600,
              WHRatio: 0.375,
            },
            800: {
              internalHeightMM: 1600,
              internalWidthMM: 800,
              WHRatio: 0.5,
            },
            1000: {
              internalHeightMM: 1600,
              internalWidthMM: 1000,
              WHRatio: 0.625,
            },
            1200: {
              internalHeightMM: 1600,
              internalWidthMM: 1200,
              WHRatio: 0.75,
            },
          },
          1800: {
            500: {
              internalHeightMM: 1800,
              internalWidthMM: 500,
              WHRatio: 0.2777777777777778,
            },
            600: {
              internalHeightMM: 1800,
              internalWidthMM: 600,
              WHRatio: 0.3333333333333333,
            },
            800: {
              internalHeightMM: 1800,
              internalWidthMM: 800,
              WHRatio: 0.4444444444444444,
            },
            1000: {
              internalHeightMM: 1800,
              internalWidthMM: 1000,
              WHRatio: 0.5555555555555556,
            },
            1200: {
              internalHeightMM: 1800,
              internalWidthMM: 1200,
              WHRatio: 0.6666666666666666,
            },
          },
          2000: {
            500: {
              internalHeightMM: 2000,
              internalWidthMM: 500,
              WHRatio: 0.25,
            },
            600: {
              internalHeightMM: 2000,
              internalWidthMM: 600,
              WHRatio: 0.3,
            },
            800: {
              internalHeightMM: 2000,
              internalWidthMM: 800,
              WHRatio: 0.4,
            },
            1000: {
              internalHeightMM: 2000,
              internalWidthMM: 1000,
              WHRatio: 0.5,
            },
            1200: {
              internalHeightMM: 2000,
              internalWidthMM: 1200,
              WHRatio: 0.6,
            },
          },
        },
        colebrookWhiteCoefficient: 0.15,
      },
    },
  },

  pvc: {
    name: "PVC",
    uid: "pvc",
    abbreviation: "PVC",
    manufacturers: [
      {
        name: "Generic",
        abbreviation: "Generic",
        priceTableName: "PVC",
        uid: "generic",
        isGeneric: true,
      },
    ],
    data: {
      generic: {
        circularByDiameter: {
          63: { internalDiameterMM: 63 },
          75: { internalDiameterMM: 75 },
          80: { internalDiameterMM: 80 },
          90: { internalDiameterMM: 90 },
          100: { internalDiameterMM: 100 },
          125: { internalDiameterMM: 125 },
          160: { internalDiameterMM: 160 },
          200: { internalDiameterMM: 200 },
          250: { internalDiameterMM: 250 },
          315: { internalDiameterMM: 315 },
          355: { internalDiameterMM: 355 },
          400: { internalDiameterMM: 400 },
          450: { internalDiameterMM: 450 },
          500: { internalDiameterMM: 500 },
          560: { internalDiameterMM: 560 },
          630: { internalDiameterMM: 630 },
          710: { internalDiameterMM: 710 },
          800: { internalDiameterMM: 800 },
          900: { internalDiameterMM: 900 },
          1000: { internalDiameterMM: 1000 },
          1120: { internalDiameterMM: 1120 },
          1250: { internalDiameterMM: 1250 },
        },
        rectangularByHeightWidth: {
          100: {
            100: {
              internalHeightMM: 100,
              internalWidthMM: 100,
              WHRatio: 1.0,
            },
            150: {
              internalHeightMM: 100,
              internalWidthMM: 150,
              WHRatio: 1.5,
            },
            200: {
              internalHeightMM: 100,
              internalWidthMM: 200,
              WHRatio: 2.0,
            },
            250: {
              internalHeightMM: 100,
              internalWidthMM: 250,
              WHRatio: 2.5,
            },
            300: {
              internalHeightMM: 100,
              internalWidthMM: 300,
              WHRatio: 3.0,
            },
            400: {
              internalHeightMM: 100,
              internalWidthMM: 400,
              WHRatio: 4.0,
            },
          },
          150: {
            100: {
              internalHeightMM: 150,
              internalWidthMM: 100,
              WHRatio: 0.6666666666666666,
            },
            150: {
              internalHeightMM: 150,
              internalWidthMM: 150,
              WHRatio: 1.0,
            },
            200: {
              internalHeightMM: 150,
              internalWidthMM: 200,
              WHRatio: 1.3333333333333333,
            },
            250: {
              internalHeightMM: 150,
              internalWidthMM: 250,
              WHRatio: 1.6666666666666667,
            },
            300: {
              internalHeightMM: 150,
              internalWidthMM: 300,
              WHRatio: 2.0,
            },
            400: {
              internalHeightMM: 150,
              internalWidthMM: 400,
              WHRatio: 2.6666666666666665,
            },
            500: {
              internalHeightMM: 150,
              internalWidthMM: 500,
              WHRatio: 3.3333333333333335,
            },
            600: {
              internalHeightMM: 150,
              internalWidthMM: 600,
              WHRatio: 4.0,
            },
          },
          200: {
            100: {
              internalHeightMM: 200,
              internalWidthMM: 100,
              WHRatio: 0.5,
            },
            150: {
              internalHeightMM: 200,
              internalWidthMM: 150,
              WHRatio: 0.75,
            },
            200: {
              internalHeightMM: 200,
              internalWidthMM: 200,
              WHRatio: 1.0,
            },
            250: {
              internalHeightMM: 200,
              internalWidthMM: 250,
              WHRatio: 1.25,
            },
            300: {
              internalHeightMM: 200,
              internalWidthMM: 300,
              WHRatio: 1.5,
            },
            400: {
              internalHeightMM: 200,
              internalWidthMM: 400,
              WHRatio: 2.0,
            },
            500: {
              internalHeightMM: 200,
              internalWidthMM: 500,
              WHRatio: 2.5,
            },
            600: {
              internalHeightMM: 200,
              internalWidthMM: 600,
              WHRatio: 3.0,
            },
            800: {
              internalHeightMM: 200,
              internalWidthMM: 800,
              WHRatio: 4.0,
            },
          },
          250: {
            100: {
              internalHeightMM: 250,
              internalWidthMM: 100,
              WHRatio: 0.4,
            },
            150: {
              internalHeightMM: 250,
              internalWidthMM: 150,
              WHRatio: 0.6,
            },
            200: {
              internalHeightMM: 250,
              internalWidthMM: 200,
              WHRatio: 0.8,
            },
            250: {
              internalHeightMM: 250,
              internalWidthMM: 250,
              WHRatio: 1.0,
            },
            300: {
              internalHeightMM: 250,
              internalWidthMM: 300,
              WHRatio: 1.2,
            },
            400: {
              internalHeightMM: 250,
              internalWidthMM: 400,
              WHRatio: 1.6,
            },
            500: {
              internalHeightMM: 250,
              internalWidthMM: 500,
              WHRatio: 2.0,
            },
            600: {
              internalHeightMM: 250,
              internalWidthMM: 600,
              WHRatio: 2.4,
            },
            800: {
              internalHeightMM: 250,
              internalWidthMM: 800,
              WHRatio: 3.2,
            },
            1000: {
              internalHeightMM: 250,
              internalWidthMM: 1000,
              WHRatio: 4.0,
            },
          },
          300: {
            100: {
              internalHeightMM: 300,
              internalWidthMM: 100,
              WHRatio: 0.3333333333333333,
            },
            150: {
              internalHeightMM: 300,
              internalWidthMM: 150,
              WHRatio: 0.5,
            },
            200: {
              internalHeightMM: 300,
              internalWidthMM: 200,
              WHRatio: 0.6666666666666666,
            },
            250: {
              internalHeightMM: 300,
              internalWidthMM: 250,
              WHRatio: 0.8333333333333334,
            },
            300: {
              internalHeightMM: 300,
              internalWidthMM: 300,
              WHRatio: 1.0,
            },
            400: {
              internalHeightMM: 300,
              internalWidthMM: 400,
              WHRatio: 1.3333333333333333,
            },
            500: {
              internalHeightMM: 300,
              internalWidthMM: 500,
              WHRatio: 1.6666666666666667,
            },
            600: {
              internalHeightMM: 300,
              internalWidthMM: 600,
              WHRatio: 2.0,
            },
            800: {
              internalHeightMM: 300,
              internalWidthMM: 800,
              WHRatio: 2.6666666666666665,
            },
            1000: {
              internalHeightMM: 300,
              internalWidthMM: 1000,
              WHRatio: 3.3333333333333335,
            },
            1200: {
              internalHeightMM: 300,
              internalWidthMM: 1200,
              WHRatio: 4.0,
            },
          },
          400: {
            100: {
              internalHeightMM: 400,
              internalWidthMM: 100,
              WHRatio: 0.25,
            },
            150: {
              internalHeightMM: 400,
              internalWidthMM: 150,
              WHRatio: 0.375,
            },
            200: {
              internalHeightMM: 400,
              internalWidthMM: 200,
              WHRatio: 0.5,
            },
            250: {
              internalHeightMM: 400,
              internalWidthMM: 250,
              WHRatio: 0.625,
            },
            300: {
              internalHeightMM: 400,
              internalWidthMM: 300,
              WHRatio: 0.75,
            },
            400: {
              internalHeightMM: 400,
              internalWidthMM: 400,
              WHRatio: 1.0,
            },
            500: {
              internalHeightMM: 400,
              internalWidthMM: 500,
              WHRatio: 1.25,
            },
            600: {
              internalHeightMM: 400,
              internalWidthMM: 600,
              WHRatio: 1.5,
            },
            800: {
              internalHeightMM: 400,
              internalWidthMM: 800,
              WHRatio: 2.0,
            },
            1000: {
              internalHeightMM: 400,
              internalWidthMM: 1000,
              WHRatio: 2.5,
            },
            1200: {
              internalHeightMM: 400,
              internalWidthMM: 1200,
              WHRatio: 3.0,
            },
            1400: {
              internalHeightMM: 400,
              internalWidthMM: 1400,
              WHRatio: 3.5,
            },
            1600: {
              internalHeightMM: 400,
              internalWidthMM: 1600,
              WHRatio: 4.0,
            },
          },
          500: {
            150: {
              internalHeightMM: 500,
              internalWidthMM: 150,
              WHRatio: 0.3,
            },
            200: {
              internalHeightMM: 500,
              internalWidthMM: 200,
              WHRatio: 0.4,
            },
            250: {
              internalHeightMM: 500,
              internalWidthMM: 250,
              WHRatio: 0.5,
            },
            300: {
              internalHeightMM: 500,
              internalWidthMM: 300,
              WHRatio: 0.6,
            },
            400: {
              internalHeightMM: 500,
              internalWidthMM: 400,
              WHRatio: 0.8,
            },
            500: {
              internalHeightMM: 500,
              internalWidthMM: 500,
              WHRatio: 1.0,
            },
            600: {
              internalHeightMM: 500,
              internalWidthMM: 600,
              WHRatio: 1.2,
            },
            800: {
              internalHeightMM: 500,
              internalWidthMM: 800,
              WHRatio: 1.6,
            },
            1000: {
              internalHeightMM: 500,
              internalWidthMM: 1000,
              WHRatio: 2.0,
            },
            1200: {
              internalHeightMM: 500,
              internalWidthMM: 1200,
              WHRatio: 2.4,
            },
            1400: {
              internalHeightMM: 500,
              internalWidthMM: 1400,
              WHRatio: 2.8,
            },
            1600: {
              internalHeightMM: 500,
              internalWidthMM: 1600,
              WHRatio: 3.2,
            },
            1800: {
              internalHeightMM: 500,
              internalWidthMM: 1800,
              WHRatio: 3.6,
            },
            2000: {
              internalHeightMM: 500,
              internalWidthMM: 2000,
              WHRatio: 4.0,
            },
          },
          600: {
            150: {
              internalHeightMM: 600,
              internalWidthMM: 150,
              WHRatio: 0.25,
            },
            200: {
              internalHeightMM: 600,
              internalWidthMM: 200,
              WHRatio: 0.3333333333333333,
            },
            250: {
              internalHeightMM: 600,
              internalWidthMM: 250,
              WHRatio: 0.4166666666666667,
            },
            300: {
              internalHeightMM: 600,
              internalWidthMM: 300,
              WHRatio: 0.5,
            },
            400: {
              internalHeightMM: 600,
              internalWidthMM: 400,
              WHRatio: 0.6666666666666666,
            },
            500: {
              internalHeightMM: 600,
              internalWidthMM: 500,
              WHRatio: 0.8333333333333334,
            },
            600: {
              internalHeightMM: 600,
              internalWidthMM: 600,
              WHRatio: 1.0,
            },
            800: {
              internalHeightMM: 600,
              internalWidthMM: 800,
              WHRatio: 1.3333333333333333,
            },
            1000: {
              internalHeightMM: 600,
              internalWidthMM: 1000,
              WHRatio: 1.6666666666666667,
            },
            1200: {
              internalHeightMM: 600,
              internalWidthMM: 1200,
              WHRatio: 2.0,
            },
            1400: {
              internalHeightMM: 600,
              internalWidthMM: 1400,
              WHRatio: 2.3333333333333335,
            },
            1600: {
              internalHeightMM: 600,
              internalWidthMM: 1600,
              WHRatio: 2.6666666666666665,
            },
            1800: {
              internalHeightMM: 600,
              internalWidthMM: 1800,
              WHRatio: 3.0,
            },
            2000: {
              internalHeightMM: 600,
              internalWidthMM: 2000,
              WHRatio: 3.3333333333333335,
            },
          },
          800: {
            200: {
              internalHeightMM: 800,
              internalWidthMM: 200,
              WHRatio: 0.25,
            },
            250: {
              internalHeightMM: 800,
              internalWidthMM: 250,
              WHRatio: 0.3125,
            },
            300: {
              internalHeightMM: 800,
              internalWidthMM: 300,
              WHRatio: 0.375,
            },
            400: {
              internalHeightMM: 800,
              internalWidthMM: 400,
              WHRatio: 0.5,
            },
            500: {
              internalHeightMM: 800,
              internalWidthMM: 500,
              WHRatio: 0.625,
            },
            600: {
              internalHeightMM: 800,
              internalWidthMM: 600,
              WHRatio: 0.75,
            },
            800: {
              internalHeightMM: 800,
              internalWidthMM: 800,
              WHRatio: 1.0,
            },
            1000: {
              internalHeightMM: 800,
              internalWidthMM: 1000,
              WHRatio: 1.25,
            },
            1200: {
              internalHeightMM: 800,
              internalWidthMM: 1200,
              WHRatio: 1.5,
            },
            1400: {
              internalHeightMM: 800,
              internalWidthMM: 1400,
              WHRatio: 1.75,
            },
            1600: {
              internalHeightMM: 800,
              internalWidthMM: 1600,
              WHRatio: 2.0,
            },
            1800: {
              internalHeightMM: 800,
              internalWidthMM: 1800,
              WHRatio: 2.25,
            },
            2000: {
              internalHeightMM: 800,
              internalWidthMM: 2000,
              WHRatio: 2.5,
            },
          },
          1000: {
            250: {
              internalHeightMM: 1000,
              internalWidthMM: 250,
              WHRatio: 0.25,
            },
            300: {
              internalHeightMM: 1000,
              internalWidthMM: 300,
              WHRatio: 0.3,
            },
            400: {
              internalHeightMM: 1000,
              internalWidthMM: 400,
              WHRatio: 0.4,
            },
            500: {
              internalHeightMM: 1000,
              internalWidthMM: 500,
              WHRatio: 0.5,
            },
            600: {
              internalHeightMM: 1000,
              internalWidthMM: 600,
              WHRatio: 0.6,
            },
            800: {
              internalHeightMM: 1000,
              internalWidthMM: 800,
              WHRatio: 0.8,
            },
            1000: {
              internalHeightMM: 1000,
              internalWidthMM: 1000,
              WHRatio: 1.0,
            },
            1200: {
              internalHeightMM: 1000,
              internalWidthMM: 1200,
              WHRatio: 1.2,
            },
            1400: {
              internalHeightMM: 1000,
              internalWidthMM: 1400,
              WHRatio: 1.4,
            },
            1600: {
              internalHeightMM: 1000,
              internalWidthMM: 1600,
              WHRatio: 1.6,
            },
            1800: {
              internalHeightMM: 1000,
              internalWidthMM: 1800,
              WHRatio: 1.8,
            },
            2000: {
              internalHeightMM: 1000,
              internalWidthMM: 2000,
              WHRatio: 2.0,
            },
          },
          1200: {
            300: {
              internalHeightMM: 1200,
              internalWidthMM: 300,
              WHRatio: 0.25,
            },
            400: {
              internalHeightMM: 1200,
              internalWidthMM: 400,
              WHRatio: 0.3333333333333333,
            },
            500: {
              internalHeightMM: 1200,
              internalWidthMM: 500,
              WHRatio: 0.4166666666666667,
            },
            600: {
              internalHeightMM: 1200,
              internalWidthMM: 600,
              WHRatio: 0.5,
            },
            800: {
              internalHeightMM: 1200,
              internalWidthMM: 800,
              WHRatio: 0.6666666666666666,
            },
            1000: {
              internalHeightMM: 1200,
              internalWidthMM: 1000,
              WHRatio: 0.8333333333333334,
            },
            1200: {
              internalHeightMM: 1200,
              internalWidthMM: 1200,
              WHRatio: 1.0,
            },
            1400: {
              internalHeightMM: 1200,
              internalWidthMM: 1400,
              WHRatio: 1.1666666666666667,
            },
            1600: {
              internalHeightMM: 1200,
              internalWidthMM: 1600,
              WHRatio: 1.3333333333333333,
            },
            1800: {
              internalHeightMM: 1200,
              internalWidthMM: 1800,
              WHRatio: 1.5,
            },
            2000: {
              internalHeightMM: 1200,
              internalWidthMM: 2000,
              WHRatio: 1.6666666666666667,
            },
          },
          1400: {
            400: {
              internalHeightMM: 1400,
              internalWidthMM: 400,
              WHRatio: 0.2857142857142857,
            },
            500: {
              internalHeightMM: 1400,
              internalWidthMM: 500,
              WHRatio: 0.35714285714285715,
            },
            600: {
              internalHeightMM: 1400,
              internalWidthMM: 600,
              WHRatio: 0.42857142857142855,
            },
            800: {
              internalHeightMM: 1400,
              internalWidthMM: 800,
              WHRatio: 0.5714285714285714,
            },
            1000: {
              internalHeightMM: 1400,
              internalWidthMM: 1000,
              WHRatio: 0.7142857142857143,
            },
            1200: {
              internalHeightMM: 1400,
              internalWidthMM: 1200,
              WHRatio: 0.8571428571428571,
            },
          },
          1600: {
            400: {
              internalHeightMM: 1600,
              internalWidthMM: 400,
              WHRatio: 0.25,
            },
            500: {
              internalHeightMM: 1600,
              internalWidthMM: 500,
              WHRatio: 0.3125,
            },
            600: {
              internalHeightMM: 1600,
              internalWidthMM: 600,
              WHRatio: 0.375,
            },
            800: {
              internalHeightMM: 1600,
              internalWidthMM: 800,
              WHRatio: 0.5,
            },
            1000: {
              internalHeightMM: 1600,
              internalWidthMM: 1000,
              WHRatio: 0.625,
            },
            1200: {
              internalHeightMM: 1600,
              internalWidthMM: 1200,
              WHRatio: 0.75,
            },
          },
          1800: {
            500: {
              internalHeightMM: 1800,
              internalWidthMM: 500,
              WHRatio: 0.2777777777777778,
            },
            600: {
              internalHeightMM: 1800,
              internalWidthMM: 600,
              WHRatio: 0.3333333333333333,
            },
            800: {
              internalHeightMM: 1800,
              internalWidthMM: 800,
              WHRatio: 0.4444444444444444,
            },
            1000: {
              internalHeightMM: 1800,
              internalWidthMM: 1000,
              WHRatio: 0.5555555555555556,
            },
            1200: {
              internalHeightMM: 1800,
              internalWidthMM: 1200,
              WHRatio: 0.6666666666666666,
            },
          },
          2000: {
            500: {
              internalHeightMM: 2000,
              internalWidthMM: 500,
              WHRatio: 0.25,
            },
            600: {
              internalHeightMM: 2000,
              internalWidthMM: 600,
              WHRatio: 0.3,
            },
            800: {
              internalHeightMM: 2000,
              internalWidthMM: 800,
              WHRatio: 0.4,
            },
            1000: {
              internalHeightMM: 2000,
              internalWidthMM: 1000,
              WHRatio: 0.5,
            },
            1200: {
              internalHeightMM: 2000,
              internalWidthMM: 1200,
              WHRatio: 0.6,
            },
          },
        },
        colebrookWhiteCoefficient: 0.03,
      },
    },
  },
};
