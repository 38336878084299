import axios from "axios";
import {
  MagicPlanProjectDataRes,
  MagicplanListProjectsRes,
  MagicplanValidateRequest,
} from "../../../common/src/api/integrations/magicplan";
import { getFailureFromAxiosRequestErrorEvent } from "../lib/axios-utils";
import { APIResult } from "./../../../common/src/api/document/types";

export async function validateTokens(
  tokens: MagicplanValidateRequest,
): Promise<APIResult<boolean>> {
  try {
    return (await axios.post("/api/magicplan/validate", tokens)).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function listProjects(): Promise<
  APIResult<MagicplanListProjectsRes>
> {
  try {
    return (await axios.get("/api/magicplan/projects")).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function getProjectData(
  projectId: string,
): Promise<APIResult<MagicPlanProjectDataRes>> {
  try {
    return (await axios.get(`/api/magicplan/projects/${projectId}`)).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}
