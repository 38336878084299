import Flatten from "@flatten-js/core";
import uuid from "uuid";
import { canonizeAngleRad } from "../../../../lib/mathUtils/mathutils";
import Graph, { Edge } from "../../graph";
import { PolygonVisFixture } from "../loop-generator/types";
import {
  GraphEdge,
  GraphNode,
  GraphNodeEntryData,
  Polygon,
  findFarNeighbours,
  serializeNode,
} from "./utils";

// Used to construct a room's wall graph including heated areas.
export class RoomGraph extends Graph<GraphNode, GraphEdge> {
  node2entry = new Map<string, GraphNodeEntryData[]>();

  constructor(room: Polygon) {
    super(
      serializeNode,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      (e) => ({
        align: e.align,
        normal: Flatten.vector(e.normal.x, e.normal.y),
        siteUid: e.siteUid,
      }),
    );

    const uids = room.vertices.map((v) => uuid.v4());

    for (let i = 0; i < room.vertices.length; i++) {
      const v1 = room.vertices[i];
      const v2 = room.vertices[(i + 1) % room.vertices.length];

      this.addEdge(
        {
          point: v1,
          uid: uids[i],
        },
        {
          point: v2,
          uid: uids[(i + 1) % uids.length],
        },
        {
          normal: Flatten.vector(v1, v2).rotate90CCW().normalize(),
          align: "ccw",
          // A final heated area representing the room will be added which
          // will create the edges with siteUids. Leave the siteUid null at the start
          // so the door doesn't *have* to do the loop for the room, and can traverse
          // to the larger heated area of the room down the line.
          siteUid: null,
        },
      );
    }
  }

  addEntryToNode(node: GraphNode, entry: GraphNodeEntryData) {
    if (!this.node2entry.has(node.uid)) {
      this.node2entry.set(node.uid, []);
    }
    this.node2entry.get(node.uid)!.push(entry);
  }

  // Shove in vertices
  // Give it the clipped version, so vertices are the only thing needed to be checked.
  addHeatedArea(area: Polygon, tolerance: number) {
    // First check the existing room vertices and add them to the area.

    const nodes = Array.from(this.id2Node.values());
    for (const node of nodes) {
      const nodePoint = node.point;
      for (let j = 0; j < area.vertices.length; j++) {
        const [v0, v2] = findFarNeighbours(area, j);
        const nextPoint = area.vertices[(j + 1) % area.vertices.length];
        const v1 = area.vertices[j];
        const segment = Flatten.segment(v1, v2);

        if (
          v1.distanceTo(nodePoint)[0] < tolerance ||
          nextPoint.distanceTo(nodePoint)[0] < tolerance
        ) {
          if (v1.distanceTo(nodePoint)[0] < tolerance) {
            const vec1 = Flatten.vector(v1, v0);
            const vec2 = Flatten.vector(v1, v2);
            if (
              Math.abs(canonizeAngleRad(vec1.angleTo(vec2))) <
              Math.PI * 0.1
            ) {
              continue;
            }

            // This node is going to be a potential entry for this heated area.
            this.addEntryToNode(node, {
              type: "auto-entry",
              entrySiteUid: area.uid,
              numEntries: 1,
              pivot: v1,
              neighbor1: v0,
              neighbor2: v2,
            });
            break;
          }
        } else if (nodePoint.distanceTo(segment)[0] < tolerance) {
          // We need to split the segment.
          const newPoint = nodePoint.distanceTo(segment)[1].pe;
          area.vertices.splice(j + 1, 0, newPoint);
          break;
        }
      }
    }

    // Now all critical points are on the heated area vertices and so we won't miss anything
    // if we just now go through its vertices.
    let areaVertexFound: Array<GraphNode | null> = [];

    const debug: PolygonVisFixture = {
      name: "Snapped",
      polygons: [
        {
          points: [],
          color: "#ff0000",
        },
      ],
    };

    for (let i = 0; i < area.vertices.length; i++) {
      const v = area.vertices[i];

      const [v0, v1] = findFarNeighbours(area, i);
      const edges = Array.from(this.edgeList.values());
      let found: GraphNode | null = null;
      const deletedEdges = new Set<string>();
      while (edges.length > 0) {
        const edge = edges.shift()!;
        if (deletedEdges.has(edge.uid)) {
          console.log("Skipping deleted edge", edge.uid);
          continue;
        }
        const edgeStart = edge.from.point;
        const edgeEnd = edge.to.point;
        const edgeNormal = edge.value.normal;
        const edgeSegment = Flatten.segment(edgeStart, edgeEnd);

        if (v.distanceTo(edgeStart)[0] < tolerance) {
          found = edge.from;
          break;
        } else if (v.distanceTo(edgeEnd)[0] < tolerance) {
          // No-op - we don't want to split anything and we have added
          // the entry to the point already in the prior step.
          found = edge.to;
          break;
        } else if (v.distanceTo(edgeSegment)[0] < tolerance) {
          // We need to split the edge.

          const newPoint = v.distanceTo(edgeSegment)[1].pe;
          const newNode = {
            point: newPoint,
            uid: uuid.v4(),
          };

          deletedEdges.add(edge.uid);

          edges.push(...this.splitUndirectedEdge(edge, newNode));

          this.addEntryToNode(newNode, {
            type: "auto-entry",
            entrySiteUid: area.uid,
            numEntries: 1,
            pivot: v,
            neighbor1: v0,
            neighbor2: v1,
          });
          found = newNode;
          break;
        }
      }

      areaVertexFound.push(found);
    }

    // Now add the (non-duplicating) edges of the area in, latching onto
    // existing (or created) critical vertices as needed, to form the floor
    // plan graph traversed by the manifold search.
    for (let i = 0; i < area.vertices.length; i++) {
      const v1 = area.vertices[i];
      const v2 = area.vertices[(i + 1) % area.vertices.length];
      const found1 = areaVertexFound[i];
      const found2 = areaVertexFound[(i + 1) % area.vertices.length];
      const midpoint = v1.translate(Flatten.vector(v1, v2).multiply(0.5));
      const mySegment = Flatten.segment(v1, v2);

      debug.polygons[0].points.push(found1 ? found1.point : v1);

      if (found1 && found2) {
        // check midpoint
        let midpointFound = false;

        const edges = Array.from(this.edgeList.values());
        for (const edge of edges) {
          const edgeSegment = Flatten.segment(edge.from.point, edge.to.point);

          if (midpoint.distanceTo(edgeSegment)[0] < tolerance) {
            midpointFound = true;
            break;
          }
        }

        let coveredEdges: Edge<GraphNode, GraphEdge>[] = [];
        for (const edge of edges) {
          if (
            edge.from.point.distanceTo(mySegment)[0] < tolerance &&
            edge.to.point.distanceTo(mySegment)[0] < tolerance
          ) {
            coveredEdges.push(edge);
          }
        }

        for (const edge of coveredEdges) {
          // We should update the siteUid. This is only important on the perimeter (so doors know
          // whether they are entering a wall or a heated area).
          // and on the perimeter this should only happen once. So don't worry about overwriting
          // existing values (in the case of edges inside the room).

          edge.value.siteUid = area.uid;
        }

        if (midpointFound) {
          // Edge already exists, so we don't need to add it.

          continue;
        }
      }

      this.addEdge(
        found1 ?? {
          point: v1,
          uid: uuid.v4(),
        },
        found2 ?? {
          point: v2,
          uid: uuid.v4(),
        },
        {
          normal: Flatten.vector(v1, v2).rotate90CCW().normalize(),
          align: "ccw",
          siteUid: area.uid,
        },
      );

      debug.polygons.push({
        points: [found1 ? found1.point : v1, found2 ? found2.point : v2],
        color: "#0000ff",
      });
    }

    debug.polygons = debug.polygons.map((p) => ({
      ...p,
      points: p.points.map((point) => ({
        x: point.x,
        y: point.y,
      })),
    }));

    // console.log("Added heated area", area.uid, debug);
  }
}
