import axios from "axios";
import { groupBy } from "lodash";
import { Builder } from "../../../../flatbuffers/flatbuffers/builder";
import { ReplayEventCollectionT } from "../../../../flatbuffers/generated/replay-event-collection";
import { ReplayEventUnion } from "../../../../flatbuffers/generated/replay-event-union";
import { ReplayEventUnionT } from "../../../../flatbuffers/replays-enhanced";
import { DocumentState } from "../../../src/store/document/types";
import store from "../../../src/store/store";

let cache = [] as ReplayEventUnionT[];

const allowedWindowLocation = () => {
  if (!window.location.pathname) return false;
  // only log on /document view (prevent logging of replay events on anything except main canvas UI for now.)
  return window.location.pathname.slice(0, 9) == "/document";
};

export const logReplayEvent = async (event: ReplayEventUnionT) => {
  if (!allowedWindowLocation()) {
    return;
  }
  cache.push(event);
  if (event._type == "DiffRecordT") {
    await sendCache(); // always send when diff occurs
  }
};

const sendCache = async () => {
  const _cache = [...cache];
  cache = [];
  if (!_cache.length) {
    return; // nothing to do
  }
  const documentId = (store.getters["document/document"] as DocumentState)
    .documentId;

  // group by previousOrderIndex
  const grouped = groupBy(_cache, (event) => event.previousOrderIndex);

  const promises = Object.entries(grouped).map(
    ([previousOrderIndex, events]) => {
      // serialise using flatbuffers
      const builder = new Builder();
      const collection = new ReplayEventCollectionT(
        // event record types
        events.map((e) => {
          const nonTString = e._type.slice(0, e._type.length - 1);
          return ReplayEventUnion[nonTString as keyof typeof ReplayEventUnion];
        }),
        // event records
        events,
      );
      const collectionOffset = collection.pack(builder);
      builder.finish(collectionOffset);

      // send bytes to server
      const blob = new Blob([builder.asUint8Array()]);
      const formData = new FormData();
      formData.append("data", blob);

      const url = `/api/replay/events?documentId=${documentId}&previousOrderIndex=${previousOrderIndex}`;

      return axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
  );

  await Promise.all(promises);
};

const loggingInterval = 15000; // ms
setInterval(() => {
  sendCache();
}, loggingInterval);
