import Color from "color";
export const VALVE_INACTIVE_COLOR = { hex: "rgba(150, 150, 150, 0.65)" };

export function pickNewColor(colors: string[]): string {
  const colorSpace = 360;
  const stepSize = Math.floor(colorSpace / (colors.length + 1));
  const hslColors = colors.map((color) => Color(color).hsl().object());
  let maxDistance = 0;
  let maxDistanceIndex = 0;

  for (let i = 0; i < colorSpace; i += stepSize) {
    let minDistance = Number.MAX_VALUE;
    for (const hslColor of hslColors) {
      const distance = Math.abs(hslColor.h - i);
      minDistance = Math.min(minDistance, distance);
    }
    if (minDistance > maxDistance) {
      maxDistance = minDistance;
      maxDistanceIndex = i;
    }
  }

  const newColor = Color.hsl(maxDistanceIndex, 100, 50).hex();
  return newColor;
}
