import { Coord } from "../../../lib/coord";

export function normalizeVector(vector: Coord): Coord {
  const length = Math.sqrt(vector.x * vector.x + vector.y * vector.y);
  return { x: vector.x / length, y: vector.y / length };
}

export function subtractVectors(vector1: Coord, vector2: Coord): Coord {
  return { x: vector1.x - vector2.x, y: vector1.y - vector2.y };
}
export function addVectors(vector1: Coord, vector2: Coord): Coord {
  return { x: vector1.x + vector2.x, y: vector1.y + vector2.y };
}

export function multiplyVector(vector: Coord, multiplier: number): Coord {
  return { x: vector.x * multiplier, y: vector.y * multiplier };
}

export function crossProduct(vector1: Coord, vector2: Coord): number {
  return vector1.x * vector2.y - vector1.y * vector2.x;
}

export function dotProduct(vector1: Coord, vector2: Coord): number {
  return vector1.x * vector2.x + vector1.y * vector2.y;
}

export function vectorLength(vector: Coord): number {
  return Math.sqrt(vector.x * vector.x + vector.y * vector.y);
}
export function calculateProjectionPoint(
  point: Coord,
  line: [Coord, Coord],
): Coord {
  const [start, end] = line;
  let pointProjection = calculateProjectionLength(point, line);
  return { x: start.x + pointProjection.x, y: start.y + pointProjection.y };
}
export function calculateProjectionLength(
  point: Coord,
  line: [Coord, Coord],
): Coord {
  const [start, end] = line;
  const lineVector = { x: end.x - start.x, y: end.y - start.y };
  const pointVector = { x: point.x - start.x, y: point.y - start.y };
  const lineLength = Math.sqrt(
    lineVector.x * lineVector.x + lineVector.y * lineVector.y,
  );
  const pointProjectionLength =
    (lineVector.x * pointVector.x + lineVector.y * pointVector.y) / lineLength;
  const pointProjection = {
    x: (pointProjectionLength * lineVector.x) / lineLength,
    y: (pointProjectionLength * lineVector.y) / lineLength,
  };
  return { x: pointProjection.x, y: pointProjection.y };
}
