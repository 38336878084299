import {
  Units,
  UnitsContext,
} from "../../../../../common/src/lib/measurements";
import { CoreContext } from "../../calculations/types";
import { getPsdUnitName } from "../../calculations/utils";
import { isVentilation } from "../../config";
import { SystemNodeEntity } from "../entities/system-node-entity";
import { SystemNodeRole, findSystemNodeRole } from "../entities/utils";
import { CalculationField, FieldCategory } from "./calculation-field";
import {
  CalcFieldSelector,
  Calculation,
  CalculationType,
  LiveCalculation,
  PressureCalculation,
  PsdCalculation,
  addPressureCalculationFields,
} from "./types";
import { getFlowSystemLayouts } from "./utils";

export default interface SystemNodeCalculation
  extends PsdCalculation,
    Calculation,
    PressureCalculation {
  type: CalculationType.SystemNodeCalculation;
  flowRateLS: number | null;
  returnLoopHeatLossKW: number | null;
  returnHeatingSystemLoopHeatLossKW: number | null;
  circulationPumpDutyString: string | null;
  circulationFlowRateLS: number | null;
  circulationPressureLoss: number | null;
  circulatingPumpModel: string | null;
}

export interface SystemNodeLiveCalculation extends LiveCalculation {}
export const FastLiveSystemNodeCalculationFields: CalcFieldSelector<SystemNodeLiveCalculation> =
  {
    connected: true,
  };
export function makeSystemNodeCalculationFields(
  context: CoreContext,
  entity: SystemNodeEntity,
): CalculationField[] {
  const { drawing, globalStore, locale } = context;
  const psdUnit = getPsdUnitName(
    drawing.metadata.calculationParams.psdMethod,
    locale,
  );
  const result: CalculationField[] = [];

  const { layouts } = getFlowSystemLayouts(
    drawing.metadata.flowSystems[entity.systemUid],
  );

  const system = drawing.metadata.flowSystems[entity.systemUid];
  const iAmVentilation = isVentilation(system);

  result.push({
    property: "reference",
    title: "Reference",
    short: "",
    shortTitle: "",
    units: Units.None,
    category: FieldCategory.EntityName,
    systemUid: entity.systemUid,
    defaultEnabled: false,
    layouts,
  });

  addPressureCalculationFields(context, result, entity.systemUid, layouts);

  let role = findSystemNodeRole(entity, context);
  result.push({
    property: "flowRateLS",
    title: "Peak Flow Rate",
    short: "",
    shortTitle: "",
    unitContext: iAmVentilation ? UnitsContext.VENTILATION : undefined,
    units: Units.LitersPerSecond,
    systemUid: entity.systemUid,
    category: FieldCategory.FlowRate,
    layouts,
  });

  if (role === SystemNodeRole.RECIRCULATION_PUMP) {
    result.push(
      {
        property: `circulationPumpDutyString`,
        title: "Recirculation Pump Duty",
        short: "",
        shortTitle: "",
        units: Units.None,
        category: FieldCategory.Pressure,
        layouts,
        defaultEnabled: false,
        bold: true,
      },
      {
        property: `returnLoopHeatLossKW`,
        title: "Recirculation System Heat Load",
        short: "",
        shortTitle: "Recirc.",
        units: Units.KiloWatts,
        layouts,
        category: FieldCategory.HeatLoss,
        unitContext: UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
      },
      {
        property: `returnHeatingSystemLoopHeatLossKW`,
        title: "Recirculation Heating System Heat Load",
        short: "",
        shortTitle: "Recirc.",
        units: Units.KiloWatts,
        layouts,
        category: FieldCategory.HeatLoss,
        unitContext: UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
      },
      {
        property: `circulatingPumpModel`,
        title: "Recirculation Pump Model",
        short: "",
        shortTitle: "",
        units: Units.None,
        layouts,
        category: FieldCategory.PumpManufacturer,
        defaultEnabled: false,
      },
    );
  }
  return result;
}

export function emptySystemNodeCalculation(): SystemNodeCalculation {
  return {
    type: CalculationType.SystemNodeCalculation,
    cost: null,
    costBreakdown: null,
    expandedEntities: null,

    flowRateLS: null,
    psdUnits: null,
    pressureKPA: null,
    staticPressureKPA: null,
    warnings: null,
    returnLoopHeatLossKW: null,
    returnHeatingSystemLoopHeatLossKW: null,
    circulationPumpDutyString: null,
    circulationFlowRateLS: null,
    circulationPressureLoss: null,
    circulatingPumpModel: null,
  };
}

export function emptySystemNodeLiveCalculation(): SystemNodeLiveCalculation {
  return {
    connected: null,
    warnings: [],
  };
}
