import Flatten from "@flatten-js/core";
import { Coord } from "../../../../lib/coord";
import { LoopDrawingTestCase } from "../loop.experiment/fixtures";
import { LoopObstacle, loopObstacleToSimple } from "./loop-obstacles";

export class LoopTestCaseGenerator {
  static generatedTestCases: LoopDrawingTestCase[] = [];

  static generateTestCase(
    roomObstacles: LoopObstacle[],
    otherObstacles: LoopObstacle[],
    roomName: string,
    entry: Flatten.Point,
    normal: Flatten.Vector,
    loopSpacingMM: number,
  ) {
    const newTestCases: LoopDrawingTestCase[] = [];
    for (const flip of [1, -1]) {
      for (const rotate of [0, 45, 90]) {
        const rotateRAD = (rotate * Math.PI) / 180;
        const transformPoint = (p: Coord) => {
          let newP = {
            x: p.x,
            y: p.y * flip,
          };
          newP = {
            x: newP.x * Math.cos(rotateRAD) - newP.y * Math.sin(rotateRAD),
            y: newP.x * Math.sin(rotateRAD) + newP.y * Math.cos(rotateRAD),
          };
          return newP;
        };
        const thisRO = roomObstacles.map(loopObstacleToSimple);
        const thisOO = otherObstacles.map(loopObstacleToSimple);
        for (const obj of [...thisRO, ...thisOO]) {
          obj.segment = obj.segment.map(transformPoint);
        }
        const newEntry = transformPoint(entry);
        const newNormal = transformPoint(normal);

        let minX = Infinity;
        let minY = Infinity;
        let maxX = -Infinity;
        let maxY = -Infinity;
        for (const obj of thisRO) {
          for (const vertex of obj.segment) {
            minX = Math.min(minX, vertex.x);
            minY = Math.min(minY, vertex.y);
            maxX = Math.max(maxX, vertex.x);
            maxY = Math.max(maxY, vertex.y);
          }
        }

        for (const obj of [...thisRO, ...thisOO]) {
          for (const vertex of obj.segment) {
            vertex.x -= minX;
            vertex.y -= minY;
          }
        }

        const testCase: LoopDrawingTestCase = {
          name:
            roomName +
            " " +
            (flip === 1 ? "" : "Flip") +
            (rotate ? ` ${rotate}°` : ""),
          obstacles: [...thisRO, ...thisOO],
          entry: {
            x: newEntry.x - minX,
            y: newEntry.y - minY,
          },
          normal: {
            x: newNormal.x,
            y: newNormal.y,
          },
          loopSpacingMM,
          maxX: maxX - minX,
          maxY: maxY - minY,
        };

        newTestCases.push(testCase);
      }
    }

    console.log("Generated test cases for room", roomName, newTestCases);
    this.generatedTestCases.push(...newTestCases);
  }
}
