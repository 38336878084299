import { MutationTree } from "vuex";
import CatalogState from "../../../src/store/catalog/types";

export const mutations: MutationTree<CatalogState> = {
  setDefault(state, payload) {
    state.defaultCatalog = payload;
  },

  setLoaded(state, loaded) {
    state.loaded = loaded;
  },

  setUpgradeCount(state, count: number) {
    state.upgradeCount = count;
  },
};
