import { PumpData, PumpManufacturer } from "../../types";

export const DutyPointBoosterManufacturer: PumpManufacturer = {
  name: "Dutypoint",
  abbreviation: "Dutypoint",
  uid: "dutypoint",
  priceTableName: "Pump",
  componentName: "Dutypoint",
  displayOnWizard: true,
};

export const DutyPointBoosterPumpsStandard: PumpData = {
  type: "sparse-table",
  customManufFields: { variant: "standard" },
  table: {
    "duty-assist-standby": {
      250: {
        2.4: "VG3-3HME05-LSM",
        3.8: "VG3-5HME04-LSM",
        5: "VG3-5HME06-LSM",
      },
      300: {
        2.2: "VG3-3HME05-LSM",
        3.4: "VG3-5HME04-LSM",
        4.6: "VG3-5HME06-LSM",
      },
      350: {
        1.8: "VG3-3HME05-LSM",
        2.8: "VG3-5HME04-LSM",
        4: "VG3-5HME06-LSM",
      },
      400: {
        1.6: "VG3-3HME05-LSM",
        2.2: "VG3-5HME04-LSM",
        3.6: "VG3-5HME06-LSM",
      },
      450: {
        3.2: "VG3-5HME06-LSM",
      },
      500: {
        3: "VG3-5HME06-LSM",
      },
      600: {
        2.2: "VG3-5HME06-LSM",
      },
      700: {
        2.4: "VG3-5HME08-LSM",
      },
    },

    "duty-standby": {
      250: {
        0.9: "VG2-3HME05-LSM",
        1.9: "VG2-5HME04-LSM",
        2.5: "VG2-5HME06-LSM",
      },
      300: {
        1.1: "VG2-3HME05-LSM",
        1.7: "VG2-5HME04-LSM",
        2.3: "VG2-5HME06-LSM",
      },
      350: {
        0.9: "VG2-3HME05-LSM",
        1.4: "VG2-5HME04-LSM",
        2: "VG2-5HME06-LSM",
      },
      400: {
        0.8: "VG2-3HME05-LSM",
        1.1: "VG2-5HME04-LSM",
        1.8: "VG2-5HME06-LSM",
      },
      450: {
        1.6: "VG2-5HME06-LSM",
      },
      500: {
        1.5: "VG2-5HME06-LSM",
      },
      600: {
        1.1: "VG2-5HME06-LSM",
      },
    },
  },
};

export const DutyPointBoosterPumpsPremium: PumpData = {
  type: "sparse-table",
  customManufFields: { variant: "premium" },
  table: {
    "duty-standby": {
      250: {
        1.6: "VT2-05-007M/T",
        2.4: "VT2-05-011M/T",
        4.3: "VT2-10-022M/T",
        5.6: "VT2-15-030M/T",
        7.6: "VT2-15-040M/T",
        8.1: "VT2-20-040M/T",
        9.4: "VT2-20-055T",
      },
      300: {
        2.3: "VT2-05-011M/T",
        4.5: "VT2-10-022M/T",
        7.1: "VT2-15-040M/T",
        8.9: "VT2-20-055T",
      },
      350: {
        2.0: "VT2-05-011M/T",
        2.5: "VT2-05-015M/T",
        4.1: "VT2-10-022M/T",
        4.7: "VT2-10-030M/T",
        6.6: "VT2-15-040M/T",
        7.5: "VT2-15-055T",
        8.3: "VT2-20-055T",
      },
      400: {
        1.7: "VT2-05-011M/T",
        2.4: "VT2-05-015M/T",
        3.7: "VT2-10-022M/T",
        4.4: "VT2-10-030M/T",
        5.9: "VT2-15-040M/T",
        7.1: "VT2-15-055T",
        7.8: "VT2-20-055T",
      },
      450: {
        2.2: "VT2-05-015M/T",
        3.2: "VT2-10-022M/T",
        4.1: "VT2-10-030M/T",
        5.2: "VT2-15-040M/T",
        6.7: "VT2-15-055T",
        7.2: "VT2-20-055T",
      },
      500: {
        2.1: "VT2-05-015M/T",
        2.5: "VT2-10-022M/T",
        3.8: "VT2-10-030M/T",
        4.7: "VT2-15-040M/T",
        6.3: "VT2-15-055T",
        6.4: "VT2-20-055T",
      },
      600: {
        1.6: "VT2-05-015M/T",
        2.3: "VT2-05-022M/T",
        3.0: "VT2-10-030M/T",
        4.3: "VT2-10-040M/T",
        5.2: "VT2-15-055T",
      },
      700: {
        2.0: "VT2-05-022M/T",
        2.4: "VT2-05-030M/T",
        3.8: "VT2-10-040M/T",
        4.5: "VT2-10-055T",
      },
      800: {
        1.7: "VT2-05-022M/T",
        2.3: "VT2-05-030M/T",
        3.3: "VT2-10-040M/T",
        4.2: "VT2-10-055T",
      },
      900: {
        1.3: "VT2-05-022M/T",
        2.1: "VT2-05-030M/T",
        2.6: "VT2-10-040M/T",
        3.8: "VT2-10-055T",
      },
    },
    "duty-assist": {
      250: {
        3.3: "VT2-05-007M/T",
        4.9: "VT2-05-011M/T",
        8.6: "VT2-10-022M/T",
        11.1: "VT2-15-030M/T",
        15.1: "VT2-15-040M/T",
        16.1: "VT2-20-040M/T",
        18.9: "VT2-20-055T",
      },
      300: {
        4.6: "VT2-05-011M/T",
        9.0: "VT2-10-022M/T",
        14.2: "VT2-15-040M/T",
        17.8: "VT2-20-055T",
      },
      350: {
        4.1: "VT2-05-011M/T",
        5.0: "VT2-05-015M/T",
        8.3: "VT2-10-022M/T",
        9.3: "VT2-10-030M/T",
        1.3: "VT2-15-040M/T",
        15.0: "VT2-15-055T",
        16.7: "VT2-20-055T",
      },
      400: {
        3.3: "VT2-05-011M/T",
        4.8: "VT2-05-015M/T",
        7.4: "VT2-10-022M/T",
        8.8: "VT2-10-030M/T",
        11.8: "VT2-15-040M/T",
        14.2: "VT2-15-055T",
        15.6: "VT2-20-055T",
      },
      450: {
        4.4: "VT2-05-015M/T",
        6.4: "VT2-10-022M/T",
        8.3: "VT2-10-030M/T",
        10.3: "VT2-15-040M/T",
        13.3: "VT2-15-055T",
        14.4: "VT2-20-055T",
      },
      500: {
        4.1: "VT2-05-015M/T",
        5.0: "VT2-10-022M/T",
        7.7: "VT2-10-030M/T",
        9.4: "VT2-15-040M/T",
        12.7: "VT2-15-055T",
        12.9: "VT2-20-055T",
      },
      600: {
        3.2: "VT2-05-015M/T",
        4.6: "VT2-05-022M/T",
        6.1: "VT2-10-030M/T",
        8.6: "VT2-10-040M/T",
        10.4: "VT2-15-055T",
      },
      700: {
        4.1: "VT2-05-022M/T",
        4.8: "VT2-05-030M/T",
        7.7: "VT2-10-040M/T",
        9.0: "VT2-10-055T",
      },
      800: {
        3.4: "VT2-05-022M/T",
        4.6: "VT2-05-030M/T",
        6.6: "VT2-10-040M/T",
        8.4: "VT2-10-055T",
      },
      900: {
        2.7: "VT2-05-022M/T",
        4.2: "VT2-05-030M/T",
        5.2: "VT2-10-040M/T",
        7.7: "VT2-10-055T",
      },
    },
    "duty-assist-standby": {
      250: {
        3.3: "VT3-05-007M/T",
        4.9: "VT3-05-011M/T",
        8.6: "VT3-10-022M/T",
        11.1: "VT3-15-030M/T",
        15.1: "VT3-15-040M/T",
        16.1: "VT3-20-040M/T",
        18.9: "VT3-20-055T",
      },
      300: {
        4.6: "VT3-05-011M/T",
        9.0: "VT3-10-022M/T",
        14.2: "VT3-15-040M/T",
        17.8: "VT3-20-055T",
      },
      350: {
        4.1: "VT3-05-011M/T",
        5.0: "VT3-05-015M/T",
        8.3: "VT3-10-022M/T",
        9.3: "VT3-10-030M/T",
        13.3: "VT3-15-040M/T",
        15.0: "VT3-15-055T",
        16.7: "VT3-20-055T",
      },
      400: {
        3.3: "VT3-05-011M/T",
        4.8: "VT3-05-015M/T",
        7.4: "VT3-10-022M/T",
        8.8: "VT3-10-030M/T",
        11.8: "VT3-15-040M/T",
        14.2: "VT3-15-055T",
        15.6: "VT3-20-055T",
        18.4: "VT3-20-075T",
      },
      450: {
        4.4: "VT3-05-015M/T",
        6.4: "VT3-10-022M/T",
        8.3: "VT3-10-030M/T",
        10.3: "VT3-15-040M/T",
        13.3: "VT3-15-055T",
        14.4: "VT3-20-055T",
        17.8: "VT3-20-075T",
      },
      500: {
        4.1: "VT3-05-015M/T",
        5.0: "VT3-05-022M/T",
        7.7: "VT3-10-030M/T",
        9.4: "VT3-10-040M/T",
        12.7: "VT3-15-055T",
        15.1: "VT3-15-075T",
        12.9: "VT3-20-055T",
        17.1: "VT3-20-075T",
      },
      600: {
        3.2: "VT3-05-015M/T",
        4.6: "VT3-05-022M/T",
        6.1: "VT3-10-030M/T",
        8.6: "VT3-10-040M/T",
        10.4: "VT3-15-055T",
        13.8: "VT3-15-075T",
        15.1: "VT3-20-075T",
      },
      700: {
        4.1: "VT3-05-022M/T",
        4.8: "VT3-05-030M/T",
        7.7: "VT3-10-040M/T",
        9.0: "VT3-15-055T",
        12.2: "VT3-15-075T",
        12.5: "VT3-20-075T",
      },
      800: {
        3.4: "VT3-05-022M/T",
        4.6: "VT3-05-030M/T",
        6.6: "VT3-10-040M/T",
        8.4: "VT3-10-055T",
        9.0: "VT3-10-075T",
        10: "VT3-15-075T",
      },
      900: {
        2.7: "VT3-05-022M/T",
        4.2: "VT3-05-030M/T",
        5.2: "VT3-10-040M/T",
        7.7: "VT3-10-055T",
        8.4: "VT3-10-075T",
        7.2: "VT3-15-075T",
      },
    },
    "duty-assist-assist": {
      250: {
        4.9: "VT3-05-007M/T",
        7.3: "VT3-05-011M/T",
        12.8: "VT3-10-022M/T",
        16.7: "VT3-15-030M/T",
        22.7: "VT3-15-040M/T",
        24.2: "VT3-20-040M/T",
        28.3: "VT3-20-055T",
      },
      300: {
        6.8: "VT3-05-011M/T",
        13.5: "VT3-10-022M/T",
        21.3: "VT3-15-040M/T",
        26.7: "VT3-20-055T",
      },
      350: {
        6.1: "VT3-05-011M/T",
        7.5: "VT3-05-015M/T",
        12.4: "VT3-10-022M/T",
        14.0: "VT3-10-030M/T",
        19.9: "VT3-15-040M/T",
        22.5: "VT3-15-055T",
        25.0: "VT3-20-055T",
      },
      400: {
        5.0: "VT3-05-011M/T",
        7.2: "VT3-05-015M/T",
        11.2: "VT3-10-022M/T",
        13.3: "VT3-10-030M/T",
        17.7: "VT3-15-040M/T",
        21.3: "VT3-15-055T",
        23.3: "VT3-20-055T",
        27.6: "VT3-20-075T",
      },
      450: {
        6.7: "VT3-05-015M/T",
        9.7: "VT3-10-022M/T",
        12.4: "VT3-10-030M/T",
        15.5: "VT3-15-040M/T",
        20.0: "VT3-15-055T",
        21.7: "VT3-20-055T",
        26.7: "VT3-20-075T",
      },
      500: {
        6.2: "VT3-05-015M/T",
        7.5: "VT3-05-022M/T",
        11.5: "VT3-10-030M/T",
        14.2: "VT3-10-040M/T",
        19.0: "VT3-15-055T",
        22.6: "VT3-15-075T",
        19.3: "VT3-20-055T",
        25.7: "VT3-20-075T",
      },
      600: {
        4.8: "VT3-05-015M/T",
        6.8: "VT3-05-022M/T",
        9.1: "VT3-10-030M/T",
        12.8: "VT3-10-040M/T",
        15.7: "VT3-15-055T",
        20.8: "VT3-15-075T",
        22.7: "VT3-20-075T",
      },
      700: {
        6.1: "VT3-05-022M/T",
        7.2: "VT3-05-030M/T",
        11.5: "VT3-10-040M/T",
        13.5: "VT3-15-055T",
        18.3: "VT3-15-075T",
        18.8: "VT3-20-075T",
      },
      800: {
        5.2: "VT3-05-022M/T",
        6.8: "VT3-05-030M/T",
        9.9: "VT3-10-040M/T",
        12.6: "VT3-10-055T",
        13.5: "VT3-10-075T",
        15.0: "VT3-15-075T",
      },
      900: {
        4.0: "VT3-05-022M/T",
        6.3: "VT3-05-030M/T",
        7.8: "VT3-10-040M/T",
        11.5: "VT3-10-055T",
        12.6: "VT3-10-075T",
        10.8: "VT3-15-075T",
      },
    },
  },
};
