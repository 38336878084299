var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"height":"100%"}},[_c('MainNavBar'),_c('div',{staticStyle:{"overflow":"auto","max-height":"calc(100% - 61px)"}},[_c('b-container',{staticClass:"home"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"title"},[_vm._v("Users")])]),(_vm.isSuperuser)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{staticClass:"mb-5 mt-5",staticStyle:{"text-align":"initial"},attrs:{"border-variant":"info","title":"Daily Active Users Graph"}},[_c('div',{attrs:{"id":"date-container"}},[_c('b-form-datepicker',{attrs:{"id":"active-from","placeholder":"From","hide-header":"","reset-button":"","today-button":"","reset-value":_vm.initDate.activeFrom,"date-format-options":{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                }},model:{value:(_vm.reactiveDate.activeFrom),callback:function ($$v) {_vm.$set(_vm.reactiveDate, "activeFrom", $$v)},expression:"reactiveDate.activeFrom"}}),_c('b-form-datepicker',{staticClass:"ml-2",attrs:{"id":"active-to","placeholder":"To","hide-header":"","reset-button":"","today-button":"","reset-value":_vm.initDate.activeTo,"date-format-options":{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                }},model:{value:(_vm.reactiveDate.activeTo),callback:function ($$v) {_vm.$set(_vm.reactiveDate, "activeTo", $$v)},expression:"reactiveDate.activeTo"}})],1),(_vm.isChartLoaded)?_c('ApexChart',{staticClass:"mt-4",attrs:{"type":"bar","options":_vm.options,"series":_vm.series,"height":"400"},on:{"dataPointSelection":_vm.onDailyUsersGraphBarClicked}}):_vm._e()],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',[(_vm.allUsers.length === 0 && _vm.isLoaded)?_c('b-alert',{attrs:{"variant":"success","show":""}},[_vm._v(" There are no users right now. Wait what? Then WHO ARE YOU. ")]):_vm._e()],1)],1),(_vm.profile && _vm.profile.accessLevel <= _vm.AccessLevel.MANAGER)?_c('b-row',{staticStyle:{"margin-top":"30px","margin-bottom":"30px"}},[_c('b-col',[_c('b-button',{attrs:{"size":"lg","variant":"primary","to":"/users/create","disabled":_vm.reachedMaxAccountsNum}},[_c('strong',[_vm._v("Create User ")])]),_vm._v("   "),(_vm.reachedMaxAccountsNum)?_c('b-icon',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
              'Your organization has reached the max accounts limit'
            ),expression:"\n              'Your organization has reached the max accounts limit'\n            ",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"exclamation-circle-fill","variant":"warning","font-scale":"2","shift-v":"-3"}}):_vm._e()],1)],1):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"flex flex-col items-end gap-4"},[_c('div',{staticClass:"flex justify-center items-center gap-2"},[_c('div',[_vm._v("Activation status")]),_c('div',[_c('b-form-select',{staticClass:"text-center",attrs:{"options":_vm.shownActivationStatusOptions,"size":"sm"},model:{value:(_vm.shownActivationStatus),callback:function ($$v) {_vm.shownActivationStatus=$$v},expression:"shownActivationStatus"}})],1)]),(_vm.activeUsersFilter)?_c('div',{staticClass:"flex justify-center items-center gap-2"},[_c('div',[_vm._v(" Active users on "+_vm._s(_vm.activeUsersFilter.date.toDateString())+" ")]),_c('b-button',{on:{"click":_vm.clearDateFilter}},[_vm._v("Clear date filter")])],1):_vm._e(),_c('b-table',{staticStyle:{"background-color":"white"},attrs:{"items":_vm.usersRendered,"fields":_vm.fields,"selectable":""},on:{"row-clicked":_vm.userRowClicked}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }