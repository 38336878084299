import { evaluatePolynomial } from "../../lib/polynomials";
import { assertUnreachable } from "../../lib/utils";
import {
  StandardFlowSystemUids,
  isClosedSystem,
  isHeating,
  isHeatingPlantSystem,
  isMechanical,
} from "../config";
import {
  AIR_PROPERTIES,
  SURFACE_EMISSIVITY,
  THERMAL_CONDUCTIVITY,
} from "../constants/air-properties";
import CoreBigValve from "../coreObjects/coreBigValve";
import CoreConduit from "../coreObjects/coreConduit";
import CoreDirectedValve from "../coreObjects/coreDirectedValve";
import CoreSystemNode from "../coreObjects/coreSystemNode";
import { PipeCalculation } from "../document/calculations-objects/conduit-calculations";
import type {
  WarningDefinitions,
  Warnings,
} from "../document/calculations-objects/warning-definitions";
import { addWarning } from "../document/calculations-objects/warnings";
import { BigValveType } from "../document/entities/big-valve/big-valve-entity";
import ConduitEntity, {
  fillDefaultConduitFields,
  isPipeEntity,
} from "../document/entities/conduit-entity";
import {
  ValveType,
  isReturnBalancingValve,
} from "../document/entities/directed-valves/valve-types";
import { isDiverterValve } from "../document/entities/multiway-valves/utils";
import { fillPlantDefaults } from "../document/entities/plants/plant-defaults";
import { ReturnSystemPlantEntity } from "../document/entities/plants/plant-entity";
import {
  PlantType,
  VolumiserPlant,
} from "../document/entities/plants/plant-types";
import { EntityType } from "../document/entities/types";
import { flowSystemNetworkHasSpareCapacity } from "../document/flow-systems";
import { getFlowSystem } from "../document/utils";
import CalculationEngine from "./calculation-engine";
import { TraceCalculation } from "./flight-data-recorder";
import { GlobalFDR } from "./global-fdr";
import { Edge, VISIT_RESULT_WRONG_WAY } from "./graph";
import {
  HeatLossResult,
  MINIMUM_BALANCING_VALVE_PRESSURE_DROP_KPA,
  ReturnCalculations,
  SPReturnRecord,
  SPReturnRecordPressureLoss,
} from "./returns";
import { SPGraph, SPNode } from "./series-parallel";
import {
  ConnectableUid,
  CoreContext,
  DrawingLayout,
  EdgeType,
  FlowEdge,
  FlowNode,
  PressurePushMode,
} from "./types";
import {
  IndexCircuitPath,
  addWarningForMisplacedHeatEmitters,
  applySpareCapacity,
  buildIndexCircultPathObj,
  calculatePipeVolumeL,
  findReturnSystemNodeCalc,
  getConnectedPipeNetwork,
  mergePath,
} from "./utils";

const MAX_ITER_CHANGE = 1e-7;
const RETURNS_RESIZE_MAX_ITER = 10;

interface BalanceCheckResult {
  balanced: boolean;
  warning: Warnings | null;
  leafSeries: boolean;
}

interface BalanceResult {
  valveUid: string | null;
  leafSeries: boolean;
  minPressure: number | null;
  maxPressure: number | null;
}

// Context: hot water through a pipe loses temperature as it goes. How much temperature it loses depends on its current
// temperature, among other things. So to find the heat loss of a whole segment of pipe, we stop at every small
// temperature change to re-evaluate. Hence this function, which is called many times along a piece of pipe in order
// to simulate its true heat loss.
// Using cheguide's iterative formula to calculate heat loss, a port of the spreadsheet.
export class SPReturnCalculator {
  @TraceCalculation("Calculating heat loss moment of pipe", (c, p, _1, _2) => [
    p.uid,
  ])
  static getHeatLossOfPipeMomentWATT_M(
    context: CoreContext,
    pipe: CoreConduit,
    tempC: number,
    windSpeedMS: number,
  ): number | null {
    if (!isPipeEntity(pipe.entity)) {
      // TODO: chuck a fit.
      return null;
    }
    const pCalc = context.globalStore.getOrCreateCalculation(pipe.entity);
    const filled = fillDefaultConduitFields(context, pipe.entity);
    const ga =
      context.drawing.metadata.calculationParams.gravitationalAcceleration;

    if (
      !pCalc.realNominalPipeDiameterMM ||
      !pCalc.realInternalDiameterMM ||
      !pCalc.realOutsideDiameterMM
    ) {
      return null;
    }

    const flowSystem = getFlowSystem<"pressure" | "mechanical">(
      context.drawing,
      pipe.entity.systemUid,
    )!;

    const DELTA_INIT = 1;
    const ambientTemperatureC =
      context.drawing.metadata.calculationParams.roomTemperatureC;
    let surfaceTempC = ambientTemperatureC + DELTA_INIT;
    let interfaceTempC = tempC - DELTA_INIT;

    const insulationThicknessMM = flowSystem.return.insulation.thicknessMM;
    const bareOutsideDiameter = pCalc.realOutsideDiameterMM;
    const totalOutsideDiameter =
      bareOutsideDiameter + insulationThicknessMM * 2;

    let oldHeatLoss = -Infinity;

    let iters = 0;
    while (true) {
      iters += 1;
      if (iters > 30) {
        console.log(
          "Too many iterations, giving up",
          pipe.entity.uid,
          oldHeatLoss,
          surfaceTempC,
          interfaceTempC,
        );
        return null;
      }
      // Calculation for insulated pipe
      const averageFilmTemperatureC =
        (surfaceTempC +
          context.drawing.metadata.calculationParams.roomTemperatureC) /
        2;
      const averageFilmTemperatureK = averageFilmTemperatureC + 273.15;
      const thermalConductivityW_MK =
        evaluatePolynomial(
          AIR_PROPERTIES.thermalConductivityW_MK_3,
          averageFilmTemperatureK,
        ) / 1e3;
      const viscosity_N_SM2 =
        evaluatePolynomial(
          AIR_PROPERTIES.viscosityNS_M2_7,
          averageFilmTemperatureK,
        ) / 1e7;
      const prandtlNumber = evaluatePolynomial(
        AIR_PROPERTIES.prandtlNumber,
        averageFilmTemperatureK,
      );
      const expansionCoefficient_1_K = 1 / averageFilmTemperatureK;
      const airDensity_KG_M3 = 29 / 0.0820575 / averageFilmTemperatureK;
      const kinematicViscosityM2_S =
        evaluatePolynomial(
          AIR_PROPERTIES.kinematicViscosityM2_S_6,
          averageFilmTemperatureK,
        ) / 1e6;
      const specificHeatKJ_KGK = evaluatePolynomial(
        AIR_PROPERTIES.specificHeatKJ_KGK,
        averageFilmTemperatureK,
      );
      const alphaM2_S =
        evaluatePolynomial(
          AIR_PROPERTIES.alphaM2_S_6,
          averageFilmTemperatureK,
        ) / 1e6;
      const reynoldsNumber =
        ((totalOutsideDiameter / 1000) * windSpeedMS) / kinematicViscosityM2_S;
      const rayleighNumber =
        (ga *
          expansionCoefficient_1_K *
          Math.abs(surfaceTempC - ambientTemperatureC) *
          (totalOutsideDiameter / 1000) ** 3) /
        (kinematicViscosityM2_S * alphaM2_S);

      const surfaceEmissivity =
        SURFACE_EMISSIVITY[flowSystem.return.insulation.jacket];
      // Air film resistance
      const radiationW_M2K =
        (0.00000005670373 *
          surfaceEmissivity *
          ((surfaceTempC + 273.15) ** 4 -
            (ambientTemperatureC + 273.15) ** 4)) /
          (surfaceTempC + 273.15 - (ambientTemperatureC + 273.15)) || 0;

      const nu_forced =
        0.3 +
        (0.62 *
          Math.sqrt(reynoldsNumber) *
          Math.pow(prandtlNumber, 1 / 3) *
          (1 + (reynoldsNumber / 282000) ** (5 / 8)) ** (4 / 5)) /
          (1 + (0.4 / prandtlNumber) ** (2 / 3)) ** (1 / 4);
      const forcedConvectionW_M2K =
        (nu_forced * thermalConductivityW_MK) / (totalOutsideDiameter / 1000);

      const nu_free =
        (0.6 +
          (0.387 * rayleighNumber ** (1 / 6)) /
            (1 + (0.559 / prandtlNumber) ** (9 / 16)) ** (8 / 27)) **
        2;
      const freeConvectionW_M2K =
        (nu_free * thermalConductivityW_MK) / (totalOutsideDiameter / 1000);

      const nu_combined = (nu_forced ** 4 + nu_free ** 4) ** (1 / 4);
      const combinedConvectionW_M2K =
        (nu_combined * thermalConductivityW_MK) / (totalOutsideDiameter / 1000);
      const overallAirSideHtcW_M2K = combinedConvectionW_M2K + radiationW_M2K;

      // Pipe Resistance
      const pipeThermalConductivityW_MK = evaluatePolynomial(
        THERMAL_CONDUCTIVITY[filled.conduit.material!],
        tempC + 273.15,
      );
      const pipeWallResistanceM2K_W =
        ((totalOutsideDiameter / 1000) *
          Math.log(bareOutsideDiameter / pCalc.realInternalDiameterMM)) /
        (2 * pipeThermalConductivityW_MK);

      // Insulation Resistance
      const averageInsulationTempK =
        (surfaceTempC + interfaceTempC) / 2 + 273.15;
      const insulationThermalConductivityW_MK = evaluatePolynomial(
        THERMAL_CONDUCTIVITY[flowSystem.return.insulation.material],
        averageInsulationTempK,
      );
      const insulationResistanceM2K_W =
        ((totalOutsideDiameter / 1000) *
          Math.log(totalOutsideDiameter / bareOutsideDiameter)) /
        (2 * insulationThermalConductivityW_MK);

      // Overall Resistance
      const overallResistanceM2_KW =
        insulationResistanceM2K_W +
        pipeWallResistanceM2K_W +
        1 / overallAirSideHtcW_M2K;
      const heatFlowW_M2 =
        (tempC - ambientTemperatureC) / overallResistanceM2_KW;

      interfaceTempC = tempC - heatFlowW_M2 * pipeWallResistanceM2K_W;

      surfaceTempC = interfaceTempC - heatFlowW_M2 * insulationResistanceM2K_W;

      const heatLossPerUnitLengthW_M =
        heatFlowW_M2 * Math.PI * (totalOutsideDiameter / 1000);

      if (true) {
        // console.log(iters);
        // console.log(filled.material);
        // console.log(JSON.stringify(THERMAL_CONDUCTIVITY[filled.material!]));
        // console.log(typeof tempC);
        // console.log(tempC + 273.15);
        // console.log('special guys:');
        // console.log('averageFilmTemperatureC: ' + averageFilmTemperatureC);
        // console.log('averageFilmTemperatureK: ' + averageFilmTemperatureK);
        // console.log('thermalConductivityW_MK: ' + thermalConductivityW_MK);
        // console.log('viscosity_N_SM2: ' + viscosity_N_SM2);
        // console.log('prandtlNumber: ' + prandtlNumber);
        // console.log('expansionCoefficient_1_K: ' + expansionCoefficient_1_K);
        // console.log('airDensity_KG_M3: ' + airDensity_KG_M3);
        // console.log('kinematicViscosityM2_S: ' + kinematicViscosityM2_S);
        // console.log('specificHeatKJ_KGK: ' + specificHeatKJ_KGK);
        // console.log('alphaM2_S: ' + alphaM2_S);
        // console.log('reynoldsNumber: ' + reynoldsNumber);
        // console.log('rayleighNumber: ' + rayleighNumber);
        // console.log('surfaceEmissivity: ' + surfaceEmissivity);
        //     console.log('radiationW_M2K: ' + radiationW_M2K);
        // console.log('nu_forced: ' + nu_forced);
        // console.log('forcedConvectionW_M2K: ' + forcedConvectionW_M2K);
        // console.log('nu_free: ' + nu_free);
        // console.log('freeConvectionW_M2K: ' + freeConvectionW_M2K);
        // console.log('nu_combined: ' + nu_combined);
        // console.log('combinedConvectionW_M2K: ' + combinedConvectionW_M2K);
        // console.log('overallAirSideHtcW_M2K: ' + overallAirSideHtcW_M2K);
        //     console.log('pipeThermalConductivityW_MK: ' + pipeThermalConductivityW_MK);
        // console.log('pipeWallResistanceM2K_W: ' + pipeWallResistanceM2K_W);
        //     console.log('averageInsulationTempK: ' + averageInsulationTempK);
        // console.log('insulationThermalConductivityW_MK: ' + insulationThermalConductivityW_MK);
        // console.log('insulationResistanceM2K_W: ' + insulationResistanceM2K_W);
        //     console.log('overallResistanceM2_KW: ' + overallResistanceM2_KW);
        // console.log('heatFlowW_M2: ' + heatFlowW_M2);
        // console.log('interfaceTempC: ' + interfaceTempC);
        // console.log('surfaceTempC: ' + surfaceTempC);
        // console.log('heatLossPerUnitLengthW_M: ' + heatLossPerUnitLengthW_M);
      }

      if (
        Math.abs(oldHeatLoss - heatLossPerUnitLengthW_M) < MAX_ITER_CHANGE &&
        iters > 5
      ) {
        return heatLossPerUnitLengthW_M;
      }
      oldHeatLoss = heatLossPerUnitLengthW_M;
    }
  }

  @TraceCalculation("Calculating heat loss of pipe", (c, p, _) => [p.uid])
  static getHeatLossOfPipeWATT(
    context: CoreContext,
    pipe: CoreConduit,
    tempC: number,
  ) {
    const filled = fillDefaultConduitFields(context, pipe.entity);
    const moment = this.getHeatLossOfPipeMomentWATT_M(
      context,
      pipe,
      tempC,
      Number(context.drawing.metadata.calculationParams.windSpeedForHeatLossMS),
    );
    if (moment === null || filled.lengthM === null) {
      return null;
    }

    return moment * filled.lengthM;
  }

  static getOrIgnoreHeatLossOfPipeWATT(
    context: CoreContext,
    pipe: CoreConduit,
    tempC: number,
    calculatePipeHeatLoad: boolean = true,
  ) {
    if (!calculatePipeHeatLoad) {
      return 0;
    }
    return this.getHeatLossOfPipeWATT(context, pipe, tempC);
  }

  @TraceCalculation("Get node heat loss", (c, n, _1, _2, _3, _4) => [
    n.type,
    n.edge,
    n.type === "leaf" ? n.edgeConcrete.value.uid : "",
  ])
  static getNodeHeatLossWATT(
    context: CoreContext,
    node: SPNode<Edge<unknown, FlowEdge>>,
    tempC: number,
    returnTempC: number,
    cache: Map<string, HeatLossResult | null>,
    calculatePipeHeatLoad: boolean,
  ): HeatLossResult | null {
    if (cache.has(node.edge)) {
      return cache.get(node.edge)!;
    }

    let tot: HeatLossResult | null = {
      totalWATT: 0,
      closedWATT: 0,
      domesticWATT: 0,
      numberOfClosedAppliances: 0,
      numberOfDomesticAppliances: 0,
    };

    switch (node.type) {
      case "parallel":
        let isDiverted = false;
        if (node.siblings.length > 1) {
          const componentEndpoints = SPGraph.dse(node.edge);
          const a = context.globalStore.get(componentEndpoints[0]);
          const b = context.globalStore.get(componentEndpoints[1]);
          if (isDiverterValve(a.entity) || isDiverterValve(b.entity)) {
            isDiverted = true;
          }
        }

        for (const sib of node.siblings) {
          const res = this.getNodeHeatLossWATT(
            context,
            sib,
            tempC,
            returnTempC,
            cache,
            calculatePipeHeatLoad,
          );
          if (res === null || tot === null) {
            tot = null;
          } else {
            if (isDiverted) {
              tot.totalWATT = Math.max(tot.totalWATT, res.totalWATT);
              tot.closedWATT = Math.min(tot.closedWATT, res.closedWATT);
              tot.domesticWATT = Math.min(tot.domesticWATT, res.domesticWATT);
              // tot.numberOfAppliances += res.numberOfAppliances;// wrong
            } else {
              tot.closedWATT += res.closedWATT;
              tot.domesticWATT += res.domesticWATT;
              tot.totalWATT += res.totalWATT;
              tot.numberOfClosedAppliances += res.numberOfClosedAppliances;
              tot.numberOfDomesticAppliances += res.numberOfDomesticAppliances;
            }
          }
        }
        break;
      case "series":
        for (const sib of node.children) {
          const res = this.getNodeHeatLossWATT(
            context,
            sib,
            tempC,
            returnTempC,
            cache,
            calculatePipeHeatLoad,
          );
          if (res === null || tot === null) {
            tot = null;
          } else {
            tot.closedWATT += res.closedWATT;
            tot.domesticWATT += res.domesticWATT;
            tot.totalWATT += res.totalWATT;
            tot.numberOfClosedAppliances += res.numberOfClosedAppliances;
            tot.numberOfDomesticAppliances += res.numberOfDomesticAppliances;
          }
        }
        break;
      case "leaf":
        switch (node.edgeConcrete.value.type) {
          case EdgeType.CONDUIT:
            const pipe = context.globalStore.get<CoreConduit>(
              node.edgeConcrete.value.uid,
            );
            let res = this.getOrIgnoreHeatLossOfPipeWATT(
              context,
              pipe,
              tempC,
              calculatePipeHeatLoad,
            );
            if (res === null) {
              tot = null;
            } else {
              tot.totalWATT = res;
            }
            break;
          case EdgeType.PLANT_THROUGH:
            tot = ReturnCalculations.getPlantThroughHeatLoss(
              context,
              node.edgeConcrete.value.uid,
              node.edgeConcrete.from as string,
            );
            break;
          case EdgeType.PLANT_PREHEAT:
            tot = ReturnCalculations.getPlantPreheatHeatLoss(
              context,
              node.edgeConcrete.value.uid,
              node.edgeConcrete.from as string,
              tempC,
              returnTempC,
            );
            break;
          case EdgeType.BIG_VALVE_HOT_HOT:
          case EdgeType.BIG_VALVE_HOT_WARM:
          case EdgeType.BIG_VALVE_COLD_WARM:
          case EdgeType.BIG_VALVE_COLD_COLD:
          case EdgeType.FITTING_FLOW:
          case EdgeType.FLOW_SOURCE_EDGE:
          case EdgeType.CHECK_THROUGH:
          case EdgeType.ISOLATION_THROUGH:
          case EdgeType.RETURN_PUMP:
          case EdgeType.BALANCING_THROUGH:
            break;
          default:
            assertUnreachable(node.edgeConcrete.value.type);
        }
        break;
      default:
        assertUnreachable(node);
    }

    cache.set(node.edge, tot);
    return tot;
  }

  // The strategy is to split flow rate down the paths, proportional to their heat loss.
  // also, return the delta in pipe sizes that occurred, because yeah one of our processes want dat
  @TraceCalculation(
    "Setting subgraph flow rates",
    (_, fr, r, c, _1, _2, _3) => [
      fr.uid,
      "Subgraph type: " + c.type,
      "Subgraph uid: " + c.edge,
    ],
  )
  static setFlowRatesNode(
    context: CalculationEngine,
    filledReturn: ReturnSystemPlantEntity,
    record: SPReturnRecord,
    currNode: SPNode<Edge<unknown, FlowEdge>>,
    // currFlowRate: number,
    heatLoss: HeatLossResult,
    heatLossCache: Map<string, HeatLossResult | null>,
    isCIBSEDivisifiedSystem: boolean,
  ): number | null {
    // if (isNaN(currFlowRate)) {
    //   throw new Error("flow rate is NaN");
    // }
    let currFlowRateLS: number | null = null;
    const selectedOutlet = filledReturn.plant.outlets.filter(
      (outlet) => outlet.outletUid === record.outletUid,
    )[0];

    let res: number | null = 0;
    switch (currNode.type) {
      case "parallel":
        const totalHeatLossWATT = heatLossCache.get(currNode.edge)!;
        if (totalHeatLossWATT !== null) {
          for (const n of currNode.siblings) {
            const thisHeatLossWATT = heatLossCache.get(n.edge)!;
            if (thisHeatLossWATT === null) {
              throw new Error(
                "wat impossible - totalHeatLossWATT would have to be null then",
              );
            }

            let propHeatLoss: HeatLossResult;
            if (totalHeatLossWATT.totalWATT !== 0) {
              // propHeatLoss =
              //   (currFlowRate * thisHeatLossWATT) /totalHeatLossWATT;

              let currentHeatFactor =
                thisHeatLossWATT.totalWATT / totalHeatLossWATT.totalWATT;
              propHeatLoss = {
                totalWATT:
                  thisHeatLossWATT.totalWATT +
                  currentHeatFactor *
                    (heatLoss.totalWATT - totalHeatLossWATT.totalWATT),
                closedWATT:
                  thisHeatLossWATT.closedWATT +
                  currentHeatFactor *
                    (heatLoss.closedWATT - totalHeatLossWATT.closedWATT),
                domesticWATT:
                  thisHeatLossWATT.domesticWATT +
                  currentHeatFactor *
                    (heatLoss.domesticWATT - totalHeatLossWATT.domesticWATT),
                numberOfClosedAppliances:
                  thisHeatLossWATT.numberOfClosedAppliances +
                  currentHeatFactor *
                    (heatLoss.numberOfClosedAppliances -
                      totalHeatLossWATT.numberOfClosedAppliances),
                numberOfDomesticAppliances:
                  thisHeatLossWATT.numberOfDomesticAppliances +
                  currentHeatFactor *
                    (heatLoss.numberOfDomesticAppliances -
                      totalHeatLossWATT.numberOfDomesticAppliances),
              };
            } else {
              propHeatLoss = {
                totalWATT: heatLoss.totalWATT / currNode.siblings.length,
                closedWATT: heatLoss.closedWATT / currNode.siblings.length,
                domesticWATT: heatLoss.domesticWATT / currNode.siblings.length,
                numberOfClosedAppliances: heatLoss.numberOfClosedAppliances,
                numberOfDomesticAppliances: heatLoss.numberOfDomesticAppliances,
              };
            }

            const tmp = this.setFlowRatesNode(
              context,
              filledReturn,
              record,
              n,
              propHeatLoss,
              heatLossCache,
              isCIBSEDivisifiedSystem,
            );

            if (tmp === null || res === null) {
              res = null;
            } else {
              res += tmp;
            }
          }
        }
        break;
      case "series":
        for (const n of currNode.children) {
          const tmp = this.setFlowRatesNode(
            context,
            filledReturn,
            record,
            n,
            heatLoss,
            heatLossCache,
            isCIBSEDivisifiedSystem,
          );

          if (tmp === null || res === null) {
            res = null;
          } else {
            res += tmp;
          }
        }
        break;
      case "leaf":
        currFlowRateLS = ReturnCalculations.heatLoadToFlowRateLS(
          context,
          selectedOutlet.outletSystemUid,
          selectedOutlet.outletTemperatureC!,
          selectedOutlet.returnLimitTemperatureC!,
          heatLoss.totalWATT / 1000,
        );
        // physically set the flow rate for the pipe.
        if (currNode.edgeConcrete.value.type === EdgeType.CONDUIT) {
          const pipeD = context.getCalcByPipeId(
            currNode.edgeConcrete.value.uid,
          );
          if (!pipeD) {
            throw new Error(
              "non-pipe conduit in return system " +
                currNode.edgeConcrete.value.uid,
            );
          }

          const pCalc = context.globalStore.getOrCreateCalculation(
            pipeD.pEntity,
          );
          const filled = fillDefaultConduitFields(context, pipeD.pEntity);
          const isMechanicalSystem = isMechanical(
            getFlowSystem(context.drawing, pipeD.pEntity.systemUid),
          );
          // formula: Diversity Heat Loss=(0.62+(0.38/number of appliances))*Un-diversified Heat Loss
          pCalc.rawReturnFlowRateLS = currFlowRateLS;
          pCalc.returnFlowRateLS = pCalc.rawReturnFlowRateLS;
          // write calculation result to pCalc
          if (isMechanicalSystem) {
            pCalc.totalKW = Math.abs(heatLoss.totalWATT / 1000);
            pCalc.closedKW = Math.abs(heatLoss.closedWATT / 1000);
            pCalc.domesticKW = Math.abs(heatLoss.domesticWATT / 1000);
            if (isCIBSEDivisifiedSystem) {
              pCalc.diversifiedTotalKW = Math.abs(
                ReturnCalculations.diversifyDistrictW(heatLoss) / 1000,
              );
              pCalc.diversifiedClosedKW = Math.abs(
                ReturnCalculations.diversifyDistrictClosed(heatLoss) / 1000,
              );
              pCalc.diversifiedDomesticKW = Math.abs(
                ReturnCalculations.diversifyDistrictDomestic(heatLoss) / 1000,
              );
            }
          }
          // Apply spare capacity
          pCalc.returnFlowRateLS = applySpareCapacity(
            context,
            pipeD.pipe,
            pCalc.rawReturnFlowRateLS,
          );

          const origSize = pCalc.realNominalPipeDiameterMM;

          let peakFlowRate = pCalc.PSDFlowRateLS;
          if (peakFlowRate !== null) {
            if (selectedOutlet.addReturnToPSDFlowRate) {
              peakFlowRate += pCalc.returnFlowRateLS;
            }
            pCalc.totalPeakFlowRateLS = Math.max(
              peakFlowRate,
              pCalc.returnFlowRateLS,
            );
          }

          context.sizePipeForFlowRate(pipeD.pEntity, [
            [peakFlowRate, filled.conduit.maximumVelocityMS!],
            [
              pCalc.returnFlowRateLS,
              Math.min(
                filled.conduit.maximumVelocityMS!,
                selectedOutlet.returnVelocityMS!,
              ),
            ],
          ]);

          if (pCalc.realNominalPipeDiameterMM === null || origSize === null) {
            res = null;
          } else {
            res = Math.abs(pCalc.realNominalPipeDiameterMM - origSize);
          }
        } else if (
          currNode.edgeConcrete.value.type === EdgeType.BIG_VALVE_HOT_HOT ||
          currNode.edgeConcrete.value.type === EdgeType.BIG_VALVE_HOT_WARM
        ) {
          // Size the RPZDs too
          const o = context.globalStore.get<CoreBigValve>(
            currNode.edgeConcrete.value.uid,
          );
          const calc = context.globalStore.getOrCreateCalculation(o.entity);
          if (o.entity.valve.type === BigValveType.RPZD_HOT_COLD) {
            calc.hotReturnFlowRateLS = currFlowRateLS;
            if (calc.hotPeakFlowRate !== null) {
              if (selectedOutlet.addReturnToPSDFlowRate) {
                calc.hotTotalFlowRateLS =
                  calc.hotPeakFlowRate + calc.hotReturnFlowRateLS;
              }
            }
            calc.rpzdSizeMM![StandardFlowSystemUids.HotWater] =
              context.sizeRpzdForFlowRate(
                o.entity.valve.catalogId,
                ValveType.RPZD_SINGLE,
                calc.hotTotalFlowRateLS!,
              );
          }
        }
        break;
      default:
        assertUnreachable(currNode);
    }
    return res;
  }

  // Takes a look at the return loop, and calculates the Recirculation Flow Rates of each pipe. Pipes are found as leaf nodes
  // in the series parallel tree.
  // Calculation engine is coreContext
  @TraceCalculation(
    "Calculating return flow rates single iteration (Series Parallel)",
    (c, r) => [r.plant.uid],
  )
  static setFlowRatesForSPReturn(
    context: CalculationEngine,
    record: SPReturnRecord,
  ): number | null {
    const filled = fillPlantDefaults(context, record.plant);

    const selectedOutletIndex = filled.plant.outlets.findIndex(
      (outlet) => outlet.outletUid === record.outletUid,
    );
    const selectedOutlet = filled.plant.outlets[selectedOutletIndex];
    let outletCalc = findReturnSystemNodeCalc(context, selectedOutlet);
    if (outletCalc === null) {
      throw new Error("Failed to find outlet calc");
    }

    const node2heatLoss = new Map<string, HeatLossResult>();
    // calculate the total heat loss for this record (network)
    const totalHeatLossW = this.getNodeHeatLossWATT(
      context,
      record.spTree,
      selectedOutlet.outletTemperatureC!,
      selectedOutlet.returnLimitTemperatureC!,
      node2heatLoss,
      selectedOutlet.calculatePipeHeatLoad ||
        !isClosedSystem(
          context.drawing.metadata.flowSystems[selectedOutlet.outletSystemUid],
        ),
    );

    const pCalc = context.globalStore.getOrCreateCalculation(record.plant);

    if (
      !pCalc.returnLoopHeatLossKW ||
      pCalc.returnLoopHeatLossKW.length === 0
    ) {
      pCalc.returnLoopHeatLossKW = [];
      for (let i = 0; i < filled.plant.outlets.length; i++) {
        pCalc.returnLoopHeatLossKW.push(null);
        pCalc.returnHeatingSystemLoopHeatLossKW.push;
      }
    }

    const system = getFlowSystem(
      context.drawing,
      selectedOutlet.outletSystemUid,
    );
    if (totalHeatLossW) {
      if (record.isCooling) {
        pCalc.returnLoopHeatLossKW[selectedOutletIndex] =
          totalHeatLossW.totalWATT / 1000;
      } else {
        // heating
        if (isHeating(system)) {
          pCalc.returnHeatingSystemLoopHeatLossKW[selectedOutletIndex] =
            ReturnCalculations.diversifyDistrictW(totalHeatLossW) / 1000;
        }
        pCalc.returnLoopHeatLossKW[selectedOutletIndex] =
          ReturnCalculations.diversifyDistrictW(totalHeatLossW) / 1000;

        if (outletCalc) {
          if (isHeating(system)) {
            outletCalc.returnHeatingSystemLoopHeatLossKW =
              pCalc.returnHeatingSystemLoopHeatLossKW[selectedOutletIndex];
          }

          outletCalc.returnLoopHeatLossKW =
            pCalc.returnLoopHeatLossKW[selectedOutletIndex];
        }
      }
    }

    if (!system) {
      throw new Error("Flow system not found");
    }

    if (totalHeatLossW === null) {
      // can't.
      return null;
    }

    // calculate the total flow rate
    const flowRateLSRaw = ReturnCalculations.heatLoadToFlowRateLS(
      context,
      selectedOutlet.outletSystemUid,
      selectedOutlet.outletTemperatureC!,
      selectedOutlet.returnLimitTemperatureC!,
      ReturnCalculations.diversifyDistrictW(totalHeatLossW) / 1000,
    );

    let flowRateLS = flowRateLSRaw;
    if (flowSystemNetworkHasSpareCapacity(system)) {
      const pipeNetwork = getConnectedPipeNetwork(
        selectedOutlet.outletUid!,
        context,
      );
      if (pipeNetwork) {
        flowRateLS *=
          1 + Number(system.networks[pipeNetwork]!.spareCapacityPCT) / 100;
      }
    }
    // calculate the heat loss for Heat Source
    pCalc.circulationFlowRateLS[selectedOutletIndex] = flowRateLS;
    outletCalc.circulationFlowRateLS = flowRateLS;

    // if system is CIBSE diversified
    let isCIBSEDivisifiedSystem: boolean =
      totalHeatLossW.numberOfClosedAppliances > 0 ||
      totalHeatLossW.numberOfDomesticAppliances > 0;

    return this.setFlowRatesNode(
      context,
      filled,
      record,
      record.spTree,
      totalHeatLossW,
      node2heatLoss,
      isCIBSEDivisifiedSystem,
    );
  }

  @TraceCalculation("Setting node warnings (Series Parallel)", (c, r, _) => [
    r.edge,
  ])
  static setNodeWarningRecursive<T extends Warnings>(
    engine: CalculationEngine,
    node: SPNode<Edge<unknown, FlowEdge>>,
    build?: ConduitEntity[],
  ): ConduitEntity[] {
    if (!build) {
      build = [];
    }
    switch (node.type) {
      case "parallel":
        for (const n of node.siblings) {
          this.setNodeWarningRecursive(engine, n, build);
        }
        break;
      case "series":
        for (const n of node.children) {
          this.setNodeWarningRecursive(engine, n, build);
        }
        break;
      case "leaf":
        GlobalFDR.focusData([node.edgeConcrete.value.uid]);
        if (node.edgeConcrete.value.type === EdgeType.CONDUIT) {
          const pipeD = engine.getCalcByPipeId(node.edgeConcrete.value.uid);
          if (!pipeD) {
            throw new Error("non-pipe conduit in return system " + node.edge);
          }

          build.push(pipeD.pEntity);

          pipeD.pCalc.rawReturnFlowRateLS = null; // Create dotted line
        }
        break;
      default:
        assertUnreachable(node);
    }
    return build;
  }

  static getAllLeafSeriesEntities(
    engine: CalculationEngine,
    node: SPNode<Edge<string, FlowEdge>>,
  ) {
    const result = new Set<string>();
    switch (node.type) {
      case "parallel":
        for (const n of node.siblings) {
          const res = this.getAllLeafSeriesEntities(engine, n);
          for (const r of res) {
            result.add(r);
          }
        }
        break;
      case "series":
        for (const n of node.children) {
          const res = this.getAllLeafSeriesEntities(engine, n);
          for (const r of res) {
            result.add(r);
          }
        }
        break;
      case "leaf":
        result.add(node.edgeConcrete.value.uid);
        break;
      default:
        assertUnreachable(node);
    }
    return result;
  }

  @TraceCalculation("Finding downstream point of this segment")
  static findDownstreamPipeOfLeafSeries(
    engine: CalculationEngine,
    node: SPNode<Edge<string, FlowEdge>>,
  ): FlowNode | null {
    const allLeaves = this.getAllLeafSeriesEntities(engine, node);
    const anyLeaf = Array.from(allLeaves.values())
      .map((uid) => engine.globalStore.get(uid))
      .filter(
        (o) =>
          engine.globalStore.get(o.uid.split(".")[0])?.type ===
          EntityType.CONDUIT,
      )[0] as CoreConduit;

    if (!anyLeaf) {
      return null;
    }

    let result: FlowNode | null = null;
    engine.flowGraph.dfs(
      {
        connectable: anyLeaf.entity.endpointUid[0],
        connection: anyLeaf.uid,
      },
      undefined,
      undefined,
      (e) => {
        if (e.value.type === EdgeType.CONDUIT) {
          const pipe = engine.globalStore.get<CoreConduit>(e.value.uid);
          const pipeCalc = engine.globalStore.getOrCreateCalculation(
            pipe.entity,
          );
          if (pipeCalc.flowFrom !== e.from.connectable) {
            return VISIT_RESULT_WRONG_WAY;
          }

          const drawable = engine.globalStore.get(e.value.uid.split(".")[0]);
          if (drawable.type !== EntityType.CONDUIT) {
            // could be a riser, vertical pipe, etc which we can't put a
            // balancing valve on
            return true;
          }

          if (!allLeaves.has(pipe.uid)) {
            return true;
          }

          result = e.to;
        }
      },
    );
    return result;
  }

  static setNodeWarning<T extends Warnings>(
    engine: CalculationEngine,
    node: SPNode<Edge<string, FlowEdge>>,
    warning: T,
    params?: WarningDefinitions[T] extends (...args: infer A) => void
      ? A[0]
      : never,
  ) {
    const entities = this.setNodeWarningRecursive(engine, node);

    const layout: DrawingLayout = isMechanical(
      engine.drawing.metadata.flowSystems[entities[0].systemUid],
    )
      ? "mechanical"
      : "pressure";

    addWarning(engine as any, warning, entities, {
      mode: layout,
      params: params,
    });
  }

  @TraceCalculation(
    "Creating missing balancing valve warnings (Series Parallel)",
  )
  static warnMissingBalancingValvesRecursive(
    engine: CalculationEngine,
    node: SPNode<Edge<string, FlowEdge>>,
    defaultWarning:
      | "MISSING_LOCKSHIELD_VALVE_FOR_RETURN"
      | "MISSING_BALANCING_VALVE_FOR_RETURN",
  ): BalanceCheckResult {
    switch (node.type) {
      case "parallel": {
        let balanced = true;
        let warning: Warnings | null = null;
        let leafSeries = node.siblings.length === 1;

        for (const c of node.siblings) {
          const res = this.warnMissingBalancingValvesRecursive(
            engine,
            c,
            defaultWarning,
          );
          balanced = balanced && res.balanced;
          leafSeries = leafSeries && res.leafSeries;
          warning = warning || res.warning;

          if (!res.balanced && node.siblings.length > 1 && res.leafSeries) {
            const end = this.findDownstreamPipeOfLeafSeries(engine, c);
            if (end) {
              this.setNodeWarning(engine, c, defaultWarning, {
                pipeUid: end.connection,
                connectableUid: end.connectable,
              });
            }
          } else if (
            res.warning &&
            node.siblings.length > 1 &&
            res.leafSeries
          ) {
            this.setNodeWarning(engine, c, res.warning);
          }
        }

        // don't propagate warnings beyond their loop
        return {
          balanced,
          leafSeries,
          warning: node.siblings.length > 1 ? null : warning,
        };
      }
      case "series": {
        let balanced = false;
        let leafSeries = true;
        let warning: Warnings | null = null;
        for (const c of node.children) {
          const res = this.warnMissingBalancingValvesRecursive(
            engine,
            c,
            defaultWarning,
          );
          balanced = balanced || res.balanced;
          leafSeries = leafSeries && res.leafSeries;
          warning = warning || res.warning;
        }
        return { balanced, leafSeries, warning };
      }
      case "leaf":
        GlobalFDR.focusData([node.edgeConcrete.value.uid]);
        if (node.edgeConcrete.value.type === EdgeType.CONDUIT) {
          let connectedToBalancingValve = false;
          let warning: Warnings | null = null;
          const po = engine.globalStore.get<CoreConduit>(
            node.edgeConcrete.value.uid,
          );
          const pCalc = engine.globalStore.getOrCreateCalculation(po.entity);

          const layout: DrawingLayout = isMechanical(
            engine.drawing.metadata.flowSystems[po.entity.systemUid],
          )
            ? "mechanical"
            : "pressure";

          if (!pCalc.flowFrom) {
            throw new Error("expected flow from");
          }

          let uid = pCalc.flowFrom;
          const o = engine.globalStore.get(uid)!;
          if (o.entity.type === EntityType.DIRECTED_VALVE) {
            switch (o.entity.valve.type) {
              case ValveType.ISOLATION_VALVE:
              case ValveType.WATER_METER:
              case ValveType.CSV:
              case ValveType.STRAINER:
              case ValveType.RV:
              case ValveType.FILTER:
              case ValveType.FLOOR_WASTE:
              case ValveType.INSPECTION_OPENING:
              case ValveType.REFLUX_VALVE:
              case ValveType.TRV:
              case ValveType.CUSTOM_VALVE:
              case ValveType.SMOKE_DAMPER:
              case ValveType.FIRE_DAMPER:
              case ValveType.VOLUME_CONTROL_DAMPER:
              case ValveType.ATTENUATOR:
                // whatevs, not directed
                break;
              case ValveType.CHECK_VALVE:
              case ValveType.GAS_REGULATOR:
              case ValveType.RPZD_SINGLE:
              case ValveType.RPZD_DOUBLE_SHARED:
              case ValveType.RPZD_DOUBLE_ISOLATED:
              case ValveType.FAN:
                if (node.edgeConcrete.value.uid === o.entity.sourceUid) {
                  addWarning(engine, "VALVE_IN_WRONG_DIRECTION", [o.entity], {
                    mode: layout,
                  });
                }
                break;
              case ValveType.PRV_SINGLE:
              case ValveType.PRV_DOUBLE:
              case ValveType.PRV_TRIPLE:
                addWarning(engine, "PRVS_ARE_FORBIDDEN_HERE", [o.entity], {
                  mode: layout,
                });
                break;
              case ValveType.BALANCING:
              case ValveType.LSV:
              case ValveType.PICV:
                connectedToBalancingValve = true;
                break;
              default:
                assertUnreachable(o.entity.valve);
            }
          }

          return {
            warning,
            balanced: connectedToBalancingValve,
            leafSeries: true,
          };
        } else {
          // Big valve
          return {
            warning: null,
            balanced: false, // always connected to system node directly
            leafSeries: true,
          };
        }
    }
    assertUnreachable(node);
  }

  @TraceCalculation(
    "Creating missing balancing valve warnings (Series Parallel)",
    (e, r) => [r.plant.uid],
  )
  static warnMissingBalancingValves(
    engine: CalculationEngine,
    record: SPReturnRecord,
  ): boolean {
    const systemNode = engine.globalStore.get<CoreSystemNode>(record.outletUid);
    const flowSystem = getFlowSystem(
      engine.drawing,
      systemNode.entity.systemUid,
    );

    const warning = isMechanical(flowSystem)
      ? "MISSING_LOCKSHIELD_VALVE_FOR_RETURN"
      : "MISSING_BALANCING_VALVE_FOR_RETURN";
    const res = this.warnMissingBalancingValvesRecursive(
      engine,
      record.spTree,
      warning,
    );
    if (!res.balanced && res.leafSeries) {
      const end = this.findDownstreamPipeOfLeafSeries(engine, record.spTree);
      if (end) {
        this.setNodeWarning(engine, record.spTree, warning, {
          pipeUid: end.connection,
          connectableUid: end.connectable,
        });
      }
    }
    return !res.balanced;
  }

  @TraceCalculation("Calculate return flow rates (Series Parallel)", (e, r) => [
    r.plant.uid,
  ])
  static SPReturnFlowRates(engine: CalculationEngine, ret: SPReturnRecord) {
    // Set flow rates of returns, then resize the pipes if necessary.
    for (let i = 0; i < RETURNS_RESIZE_MAX_ITER; i++) {
      const diff = this.setFlowRatesForSPReturn(engine, ret);
      if (!diff) {
        break;
      }
    }

    // Identify segments that don't have balancing valves
    const hasMissing = this.warnMissingBalancingValves(engine, ret);
  }

  @TraceCalculation("Calculating balancing valve imbalances")
  static findValveImbalances(
    engine: CalculationEngine,
    nodePressureKPA: Map<string, number | null>,
    valveUids: Map<string, string | null>,
    pressureDropKPA: Map<string, number | null>,
    isLeafSeries: Map<string, boolean>,
    node: SPNode<Edge<string, FlowEdge>>,
  ): BalanceResult {
    let valveUid: string | null = null;
    let leafSeries = true;
    let minPressure: number | null = Infinity;
    let maxPressure: number | null = -Infinity;

    switch (node.type) {
      case "parallel": {
        leafSeries = node.siblings.length === 1;

        for (const c of node.siblings) {
          const res = this.findValveImbalances(
            engine,
            nodePressureKPA,
            valveUids,
            pressureDropKPA,
            isLeafSeries,
            c,
          );
          leafSeries = leafSeries && res.leafSeries;
          if (res.minPressure === null || minPressure === null) {
            minPressure = null;
          } else {
            minPressure = Math.min(minPressure, res.minPressure);
          }

          if (res.maxPressure === null || maxPressure === null) {
            maxPressure = null;
          } else {
            maxPressure = Math.max(maxPressure, res.maxPressure);
          }
        }

        break;
      }
      case "series": {
        for (const c of node.children) {
          const res = this.findValveImbalances(
            engine,
            nodePressureKPA,
            valveUids,
            pressureDropKPA,
            isLeafSeries,
            c,
          );
          valveUid = valveUid || res.valveUid;
          leafSeries = leafSeries && res.leafSeries;
          if (res.minPressure === null || minPressure === null) {
            minPressure = null;
          } else {
            minPressure = Math.min(minPressure, res.minPressure);
          }

          if (res.maxPressure === null || maxPressure === null) {
            maxPressure = null;
          } else {
            maxPressure = Math.max(maxPressure, res.maxPressure);
          }
        }
        break;
      }
      case "leaf": {
        GlobalFDR.focusData([node.edgeConcrete.value.uid]);
        valveUid = null;
        leafSeries = true;

        const pressures = [];

        for (const uid of [node.edgeConcrete.from, node.edgeConcrete.to]) {
          const p = nodePressureKPA.get(
            engine.serializeNode({
              connectable: uid,
              connection: node.edgeConcrete.value.uid,
            }),
          );
          pressures.push(p === undefined ? null : p);
        }

        // only account for a valve once
        if (node.edgeConcrete.value.type === EdgeType.CONDUIT) {
          const flowFrom = engine.globalStore.getOrCreateCalculation(
            engine.globalStore.get<CoreConduit>(node.edgeConcrete.value.uid)
              .entity,
          ).flowFrom;

          if (!flowFrom) {
            // cannot balance deez valves since we can't agree on a canonical direction.
          } else {
            const o = engine.globalStore.get(flowFrom)!;
            if (o.entity.type === EntityType.DIRECTED_VALVE) {
              if (isReturnBalancingValve(o.entity.valve)) {
                valveUid = o.entity.uid;
              }
            }
          }
        }
        // prefer null over numbers, to ensure that if we make a min or max exist, it is because every dependent
        // value was defined.
        minPressure = null;
        maxPressure = null;
        if (pressures[0] !== null && pressures[1] !== null) {
          minPressure = Math.min(pressures[0], pressures[1]);
          maxPressure = Math.max(pressures[0], pressures[1]);
        }
        break;
      }
      default:
        assertUnreachable(node);
    }
    if (maxPressure !== null && minPressure !== null) {
      pressureDropKPA.set(node.edge, maxPressure - minPressure);
    } else {
      pressureDropKPA.set(node.edge, null);
    }

    isLeafSeries.set(node.edge, leafSeries);
    valveUids.set(node.edge, valveUid);

    return { valveUid, leafSeries, maxPressure, minPressure };
  }

  // Returns true if the pressure drop was consumed, for the purposes of only having one segment in a series consume it.
  @TraceCalculation("Distributing balancing valve pressure drops")
  static setValveBalances(
    engine: CalculationEngine,
    leafValveUid: Map<string, string | null>,
    pressureDropKPA: Map<string, number | null>,
    isLeafSeries: Map<string, boolean>,
    node: SPNode<Edge<string, FlowEdge>>,
    pressureDropDifferentialKPA: number,
    adjustPressureDropByManufacturer: number,
  ): boolean {
    switch (node.type) {
      case "parallel": {
        let consumed = false;
        let highestPressureDrop: number | null = -Infinity;
        for (const c of node.siblings) {
          const val = pressureDropKPA.get(c.edge);
          if (
            highestPressureDrop === null ||
            val === null ||
            val === undefined
          ) {
            highestPressureDrop = null;
          } else {
            highestPressureDrop = Math.max(highestPressureDrop, val);
          }
        }

        if (highestPressureDrop !== null) {
          for (const c of node.siblings) {
            const val = pressureDropKPA.get(c.edge)!;
            const seen = this.setValveBalances(
              engine,
              leafValveUid,
              pressureDropKPA,
              isLeafSeries,
              c,
              pressureDropDifferentialKPA + highestPressureDrop - val,
              adjustPressureDropByManufacturer,
            );
            if (seen) {
              consumed = true;
            }
          }
        }

        return consumed; // assumes that the network was valid in the first place
      }
      case "series": {
        let consumed = false;

        for (const c of node.children) {
          consumed =
            this.setValveBalances(
              engine,
              leafValveUid,
              pressureDropKPA,
              isLeafSeries,
              c,
              pressureDropDifferentialKPA,
              adjustPressureDropByManufacturer,
            ) || consumed;
          if (consumed) {
            pressureDropDifferentialKPA = 0;
          }
        }
        return consumed;
      }
      case "leaf":
        GlobalFDR.focusData([node.edgeConcrete.value.uid]);
        if (leafValveUid.get(node.edge)) {
          // this thang has a valve.
          const o = engine.globalStore.get<CoreDirectedValve>(
            leafValveUid.get(node.edge)!,
          );
          if (!isReturnBalancingValve(o.entity.valve)) {
            throw new Error("Expected a balancing valve");
          }

          const vCalc = engine.globalStore.getOrCreateCalculation(o.entity);
          // pressureDropDifferentialKPA is the missing pressure in this leg ASSUMING that the balancing valves were
          // ALREADY at min_ba... so that's why we add MINIMUM_... here.
          vCalc.pressureDropKPA =
            pressureDropDifferentialKPA +
            MINIMUM_BALANCING_VALVE_PRESSURE_DROP_KPA +
            adjustPressureDropByManufacturer;
          // hl = (kValue * velocityMS ** 2)) / (2 * ga);
          // hl * 2 ga / vms**2 = kValue

          const bar = vCalc.pressureDropKPA / 100;
          const conns = engine.globalStore.getConnections(o.uid);
          const pipeD = engine.getCalcByPipeId(conns[0]);
          if (!pipeD) {
            throw new Error("non-pipe conduit in return system " + conns[0]);
          }
          const flowLS = pipeD.pCalc.totalPeakFlowRateLS!;
          const flowM3H = (flowLS / 1000) * 60 * 60;

          vCalc.kvValue = flowM3H * Math.sqrt(1 / bar);
          return true;
        } else {
          // Nada.
          return false;
        }
    }
    assertUnreachable(node);
  }

  @TraceCalculation("Calculating return pressure drops")
  static SPReturnBalanceValves(
    context: CalculationEngine,
    record: SPReturnRecord,
  ): SPReturnRecordPressureLoss {
    // calculate pressures through the return based on return rates only.
    const flowOut: FlowNode = {
      connectable: record.outletUid,
      connection: record.plant.uid,
    };
    const pressureAtOutlet = 0; // for purposes of just determining pressure differences, it's OK to start with an unknown initial pressure.
    const pressuresInStaticReturnKPA = new Map<string, number | null>();

    context.pushPressureThroughNetwork({
      start: flowOut,
      pressureKPA: pressureAtOutlet,
      entityMaxPressuresKPA: new Map<string, number | null>(),
      nodePressureKPA: pressuresInStaticReturnKPA,
      pressurePushMode: PressurePushMode.CirculationFlowOnly,
    });

    const pressureDropKPA = new Map<string, number | null>();
    const isLeafSeries = new Map<string, boolean>();
    const valveUids = new Map<string, string | null>();

    this.findValveImbalances(
      context,
      pressuresInStaticReturnKPA,
      valveUids,
      pressureDropKPA,
      isLeafSeries,
      record.spTree,
    );

    const pCalc = context.globalStore.getOrCreateCalculation(record.plant);
    const circulationPressureLoss = pressureDropKPA.get(record.spTree.edge)!;
    const checkAdjustment =
      ReturnCalculations.adjustPlantPressureDropByManufacturer({
        plant: record.plant,
        pCalc,
        catalog: context.catalog,
        drawing: context.drawing,
        pressureDrop: circulationPressureLoss,
      });

    const internalPressureDropKPA =
      record.plant.plant.outlets.find((o) => o.outletUid === record.outletUid)!
        .pressureDropKPA ?? 0;

    if (
      !record.plant.plant.outlets.find((o) => o.outletUid === record.outletUid)
    ) {
      throw new Error("Outlet not found");
    }
    pCalc.circulationPressureLoss.push(
      circulationPressureLoss + checkAdjustment.total + internalPressureDropKPA,
    );
    pCalc.circulatingPumpModel.push(checkAdjustment.manufacturer);

    const selectedOutlet = record.plant.plant.outlets.find(
      (outlet) => outlet.outletUid === record.outletUid,
    )!;
    const outletCalc = findReturnSystemNodeCalc(context, selectedOutlet);
    if (!outletCalc) {
      throw new Error("Outlet calc not found");
    }
    outletCalc.circulationPressureLoss =
      circulationPressureLoss + checkAdjustment.total + internalPressureDropKPA;
    outletCalc.circulatingPumpModel = checkAdjustment.manufacturer;

    this.setValveBalances(
      context,
      valveUids,
      pressureDropKPA,
      isLeafSeries,
      record.spTree,
      0,
      checkAdjustment.total,
    );

    return {
      ...record,
      pressureDropKPA: pressureDropKPA,
    };
  }

  @TraceCalculation("Detecting misplaced heat emitters (Series Parallel)")
  static detectMisplacedHeatEmittersSP(
    engine: CalculationEngine,
    node: SPNode<Edge<unknown, FlowEdge>>,
  ) {
    // iterate through the SP Tree to find heat emitters
    switch (node.type) {
      case "parallel":
        for (const sib of node.siblings) {
          this.detectMisplacedHeatEmittersSP(engine, sib);
        }
        break;
      case "series":
        for (const child of node.children) {
          this.detectMisplacedHeatEmittersSP(engine, child);
        }
        break;
      case "leaf":
        GlobalFDR.focusData([node.edgeConcrete.value.uid]);
        switch (node.edgeConcrete.value.type) {
          case EdgeType.PLANT_THROUGH:
            addWarningForMisplacedHeatEmitters(
              engine,
              node.edgeConcrete.value.uid,
            );
            break;
          case EdgeType.CONDUIT:
          case EdgeType.PLANT_PREHEAT:
          case EdgeType.BIG_VALVE_HOT_HOT:
          case EdgeType.BIG_VALVE_HOT_WARM:
          case EdgeType.BIG_VALVE_COLD_WARM:
          case EdgeType.BIG_VALVE_COLD_COLD:
          case EdgeType.FITTING_FLOW:
          case EdgeType.FLOW_SOURCE_EDGE:
          case EdgeType.CHECK_THROUGH:
          case EdgeType.ISOLATION_THROUGH:
          case EdgeType.RETURN_PUMP:
          case EdgeType.BALANCING_THROUGH:
            break;
          default:
            assertUnreachable(node.edgeConcrete.value.type);
        }
        break;
      default:
        assertUnreachable(node);
    }
  }

  @TraceCalculation("Calculating return pressure drops (Series Parallel)")
  static returnTotalVolumeSP(
    engine: CalculationEngine,
    node: SPNode<Edge<ConnectableUid, FlowEdge>>,
  ): {
    totalPipeVolumeL: number;
    totalHeatEmitterVolumeL: number;
    totalPlantVolumeL: number;
  } {
    let totalPipeVolumeL = 0;
    let totalHeatEmitterVolumeL = 0;
    let totalPlantVolumeL = 0;

    switch (node.type) {
      case "parallel":
        for (const sib of node.siblings) {
          const res = this.returnTotalVolumeSP(engine, sib);
          totalPipeVolumeL += res.totalPipeVolumeL;
          totalHeatEmitterVolumeL += res.totalHeatEmitterVolumeL;
          totalPlantVolumeL += res.totalPlantVolumeL;
        }
        break;
      case "series":
        for (const child of node.children) {
          const res = this.returnTotalVolumeSP(engine, child);
          totalPipeVolumeL += res.totalPipeVolumeL;
          totalHeatEmitterVolumeL += res.totalHeatEmitterVolumeL;
          totalPlantVolumeL += res.totalPlantVolumeL;
        }
        break;
      case "leaf":
        GlobalFDR.focusData([node.edgeConcrete.value.uid]);
        switch (node.edgeConcrete.value.type) {
          case EdgeType.CONDUIT:
            const pipeCalc = engine.globalStore.calculationStore.get(
              node.edgeConcrete.value.uid,
            ) as PipeCalculation;

            return {
              totalHeatEmitterVolumeL: 0,
              totalPlantVolumeL: 0,
              totalPipeVolumeL: calculatePipeVolumeL(pipeCalc),
            };
          case EdgeType.PLANT_THROUGH:
            const plant = engine.globalStore.get(node.edgeConcrete.value.uid);
            if (plant.entity.type === EntityType.PLANT) {
              const plantCalc = engine.globalStore.getOrCreateCalculation(
                plant.entity,
              );
              const p = plant.entity.plant;

              switch (p.type) {
                case PlantType.RADIATOR:
                case PlantType.MANIFOLD:
                case PlantType.UFH:
                  const { volumeL, internalVolumeL } =
                    ReturnCalculations.calculateRadiatorEffectiveVolume(
                      engine,
                      plant.entity,
                    );
                  plantCalc.volumeL = volumeL ?? 0;
                  plantCalc.internalVolumeL = internalVolumeL;

                  return {
                    totalPipeVolumeL: 0,
                    totalPlantVolumeL: 0,
                    totalHeatEmitterVolumeL: volumeL ?? 0,
                  };

                case PlantType.FCU:
                  const ns = engine.globalStore.get<CoreSystemNode>(
                    node.edgeConcrete.from as string,
                  );

                  if (
                    isHeatingPlantSystem(
                      engine.drawing.metadata.flowSystems[ns.entity.systemUid],
                    )
                  ) {
                    return {
                      totalPipeVolumeL: 0,
                      totalPlantVolumeL: 0,
                      totalHeatEmitterVolumeL:
                        // ratingKW is positive even for cold water so we use this without Math.abs.
                        p.heatingCapacityRateLKW *
                        (plantCalc.heatingRatingKW || 0),
                    };
                  } else {
                    return {
                      totalPipeVolumeL: 0,
                      totalPlantVolumeL: 0,
                      totalHeatEmitterVolumeL:
                        // ratingKW is positive even for cold water so we use this without Math.abs.
                        p.chilledCapacityRateLKW *
                        (plantCalc.chilledRatingKW || 0),
                    };
                  }
                case PlantType.VOLUMISER:
                  const filled = fillPlantDefaults(engine, plant.entity);
                  return {
                    totalPipeVolumeL: 0,
                    totalPlantVolumeL: (filled.plant as VolumiserPlant)
                      .volumeL!,
                    totalHeatEmitterVolumeL: 0,
                  };
                case PlantType.CUSTOM:
                case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
                case PlantType.DRAINAGE_PIT:
                case PlantType.PUMP:
                case PlantType.PUMP_TANK:
                case PlantType.RETURN_SYSTEM:
                case PlantType.TANK:
                case PlantType.FILTER:
                case PlantType.RO:
                case PlantType.AHU:
                case PlantType.AHU_VENT:
                case PlantType.DUCT_MANIFOLD:
                  break;
                default:
                  assertUnreachable(p);
              }
            }
            break;
          case EdgeType.PLANT_PREHEAT:
            const preHeatPlant = engine.globalStore.get(
              node.edgeConcrete.value.uid,
            );
            if (
              preHeatPlant.entity.type === EntityType.PLANT &&
              preHeatPlant.entity.plant.type === PlantType.RETURN_SYSTEM
            ) {
              for (const preheat of preHeatPlant.entity.plant.preheats) {
                if (
                  preheat.inletUid === node.edgeConcrete.from ||
                  preheat.returnUid === node.edgeConcrete.from
                ) {
                  return {
                    totalPipeVolumeL: 0,
                    totalHeatEmitterVolumeL: 0,
                    totalPlantVolumeL: preheat.volumeL ?? 0,
                  };
                }
              }
              throw new Error(
                "Preheat not found " +
                  node.edgeConcrete.from +
                  " plant: " +
                  preHeatPlant.entity.uid,
              );
            }
            break;
          case EdgeType.BIG_VALVE_HOT_HOT:
          case EdgeType.BIG_VALVE_HOT_WARM:
          case EdgeType.BIG_VALVE_COLD_WARM:
          case EdgeType.BIG_VALVE_COLD_COLD:
          case EdgeType.FITTING_FLOW:
          case EdgeType.FLOW_SOURCE_EDGE:
          case EdgeType.CHECK_THROUGH:
          case EdgeType.ISOLATION_THROUGH:
          case EdgeType.RETURN_PUMP:
          case EdgeType.BALANCING_THROUGH:
            break;
          default:
            assertUnreachable(node.edgeConcrete.value.type);
        }
        break;
      default:
        assertUnreachable(node);
    }

    return { totalPipeVolumeL, totalHeatEmitterVolumeL, totalPlantVolumeL };
  }

  @TraceCalculation(
    "Finding downstream preheat returns (Series Parallel)",
    (e, r) => [r.plant.uid],
  )
  static getDownstreamReturnsSP(
    engine: CalculationEngine,
    returnRecord: SPReturnRecord,
  ) {
    const downstreamReturns: string[] = [];

    const helper = (node: SPNode<Edge<unknown, FlowEdge>>) => {
      switch (node.type) {
        case "parallel":
          for (const sib of node.siblings) {
            helper(sib);
          }
          break;
        case "series":
          for (const child of node.children) {
            helper(child);
          }
          break;
        case "leaf":
          GlobalFDR.focusData([node.edgeConcrete.value.uid]);
          switch (node.edgeConcrete.value.type) {
            case EdgeType.PLANT_PREHEAT:
              const plant = engine.globalStore.get(node.edgeConcrete.value.uid);
              if (
                plant.entity.type === EntityType.PLANT &&
                plant.entity.plant.type === PlantType.RETURN_SYSTEM
              ) {
                downstreamReturns.push(node.edgeConcrete.value.uid);
              }
              break;
          }
          break;
        default:
          assertUnreachable(node);
      }
    };

    helper(returnRecord.spTree);

    return downstreamReturns;
  }

  @TraceCalculation("Calculating return index circuit (Series Parallel)")
  static calculateReturnIndexCircultSP(
    engine: CalculationEngine,
    currNode: SPNode<Edge<unknown, FlowEdge>>,
    pressureDropKPA: Map<String, number | null>,
  ): IndexCircuitPath {
    // iterate through the SP Tree to find the pressure drop
    // with backtracking
    switch (currNode.type) {
      case "parallel":
        // Multiple path, however there is no concret class
        // Put into an array and choose from
        let siblingPath = [];
        for (const sib of currNode.siblings) {
          let childPath = this.calculateReturnIndexCircultSP(
            engine,
            sib,
            pressureDropKPA,
          );
          siblingPath.push(childPath);
        }

        // Edge case: Return a base object
        if (siblingPath.length === 0) {
          return buildIndexCircultPathObj();
        }

        // Then pick the longest and return it
        siblingPath.sort((a, b) => {
          return b?.pressureDropKPA - a?.pressureDropKPA;
        });
        return siblingPath[0];
      case "series":
        // Series means a single path
        // Iterate through the children and merge them into one
        let seriesPath = buildIndexCircultPathObj();
        for (const child of currNode.children) {
          let childPath = this.calculateReturnIndexCircultSP(
            engine,
            child,
            pressureDropKPA,
          );

          seriesPath = mergePath(seriesPath, childPath);
        }
        return seriesPath;
      case "leaf":
        GlobalFDR.focusData([currNode.edgeConcrete.value.uid]);
        // Leaf is a single Node, simply return an empty object
        let thisPressureDropKPA =
          pressureDropKPA.get(currNode.edge) === undefined
            ? 0
            : pressureDropKPA.get(currNode.edge)!;

        // Legacy: exclude plant_preheats from pressure drop in index loop. (wtf?)
        switch (currNode.edgeConcrete.value.type) {
          case EdgeType.CONDUIT:
          case EdgeType.FITTING_FLOW:
          case EdgeType.CHECK_THROUGH:
          case EdgeType.ISOLATION_THROUGH:
          case EdgeType.PLANT_THROUGH:
            break;
          case EdgeType.PLANT_PREHEAT:
          case EdgeType.BIG_VALVE_HOT_HOT:
          case EdgeType.BIG_VALVE_HOT_WARM:
          case EdgeType.BIG_VALVE_COLD_WARM:
          case EdgeType.BIG_VALVE_COLD_COLD:
          case EdgeType.FLOW_SOURCE_EDGE:
          case EdgeType.RETURN_PUMP:
          case EdgeType.BALANCING_THROUGH:
            thisPressureDropKPA = 0;
            break;
          default:
            assertUnreachable(currNode.edgeConcrete.value.type);
        }
        return {
          path: [currNode.edgeConcrete],
          lengthM: ReturnCalculations.getEdgeLengthM(
            engine,
            currNode.edgeConcrete,
          ),
          pressureDropKPA: thisPressureDropKPA,
        };
        break;
      default:
        assertUnreachable(currNode);
    }
    return buildIndexCircultPathObj();
  }

  @TraceCalculation("Calculating return index circuit (Series Parallel)")
  static returnIndexCircultSP(
    engine: CalculationEngine,
    returnRecord: SPReturnRecordPressureLoss,
  ): IndexCircuitPath {
    return this.calculateReturnIndexCircultSP(
      engine,
      returnRecord.spTree,
      returnRecord.pressureDropKPA,
    );
  }
}
