import { immutableAppend } from "../../../../common/src/lib/utils";
import { LevelChangeRecordT } from "../../../../flatbuffers/generated/level-change-record";
import { ActiveReplayState } from "../../../src/store/document/types";
import store from "../../../src/store/store";

export const stringifyLevelChangeEvent = (e: LevelChangeRecordT) => {
  return `${e.userName} is looking at level ${e.levelUid}`;
};

export const renderLevelChangeRecord = async (
  event: LevelChangeRecordT,
  replayState: ActiveReplayState,
) => {
  store.dispatch("document/setCurrentLevelUid", event.levelUid);
  const newReplayState: ActiveReplayState = {
    ...replayState,
    eventsToLog: immutableAppend(replayState.eventsToLog, event),
  };
  return newReplayState;
};
