
import Vue from "vue";
import Component from "vue-class-component";

import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";

import "prismjs/themes/prism-solarizedlight.css"; // import syntax highlighting styles

import axios from "axios";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere
import { CURRENT_VERSION } from "../../common/src/api/config";
import VersionNumber from "../src/components/VersionNumber.vue";
import { MainEventBus } from "../src/store/main-event-bus";

@Component({
  components: {
    VersionNumber,
  },
})
export default class App extends Vue {
  upToDate = true;
  targetVersion: string | null = null;

  mounted() {
    (window as any).axios = axios;
    axios.defaults.headers.common["drawing-version"] = CURRENT_VERSION;

    document.onkeydown = (evt) => {
      if (
        document.activeElement === null ||
        document.activeElement.nodeName.toLowerCase() !== "input"
      ) {
        MainEventBus.$emit("keydown", evt);
      }

      let isEscape = false;
      if ("key" in evt) {
        isEscape = evt.key === "Escape" || evt.key === "Esc";
      }
      if (isEscape) {
        MainEventBus.$emit("escape-pressed-pass");
      }
      if (
        evt.key === "Delete" ||
        evt.key === "Del" ||
        evt.key === "Backspace"
      ) {
        if (
          document.activeElement === null ||
          (document.activeElement.nodeName.toLowerCase() !== "input" &&
            document.activeElement.nodeName.toLowerCase() !== "textarea")
        ) {
          MainEventBus.$emit("delete-pressed");
        }
      }
    };

    document.onkeyup = (evt) => {
      if (
        document.activeElement === null ||
        document.activeElement.nodeName.toLowerCase() !== "input"
      ) {
        MainEventBus.$emit("keyup", evt);
      }
    };

    MainEventBus.$on("update-available", () => {
      this.upToDate = false;
    });
  }
}
