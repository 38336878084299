import { StandardFlowSystemUids } from "../../../../../common/src/api/config";
import GasApplianceEntity from "../../../../../common/src/api/document/entities/gas-appliance";
import {
  Units,
  UnitsContext,
} from "../../../../../common/src/lib/measurements";
import { CoreContext } from "../../calculations/types";
import { CalculationField, FieldCategory } from "./calculation-field";
import {
  CalcFieldSelector,
  Calculation,
  CalculationType,
  LiveCalculation,
} from "./types";

export default interface GasApplianceCalculation extends Calculation {
  type: CalculationType.GasApplianceCalculation;
  demandMJH: number | null;
  gasPressureKPA: number | null;
}

export interface GasApplianceLiveCalculation extends LiveCalculation {}

export const FastLiveGasApplianceCalculationFields: CalcFieldSelector<GasApplianceLiveCalculation> =
  {
    connected: true,
  };

export const StickyGasApplianceCalculationFields: CalcFieldSelector<GasApplianceCalculation> =
  {
    gasPressureKPA: true,
  };

export function makeGasApplianceCalculationFields(
  context: CoreContext,
  entity: GasApplianceEntity,
): CalculationField[] {
  return [
    {
      property: "reference",
      title: "Reference",
      short: "",
      shortTitle: "",
      units: Units.None,
      category: FieldCategory.EntityName,
      defaultEnabled: false,
      layouts: ["pressure"],
    },
    {
      property: "demandMJH",
      title: "Gas Demand",
      short: "",
      shortTitle: "Demand",
      units: Units.MegajoulesPerHour,
      category: FieldCategory.FlowRate,
      systemUid: StandardFlowSystemUids.Gas,
      defaultEnabled: false,
      layouts: ["pressure"],
      unitContext: UnitsContext.GAS_ENERGY_MEASUREMENT,
    },
  ];
}

export function emptyGasApplianceCalculation(): GasApplianceCalculation {
  return {
    type: CalculationType.GasApplianceCalculation,
    warnings: null,
    costBreakdown: null,
    cost: null,
    expandedEntities: null,
    demandMJH: null,
    gasPressureKPA: null,
  };
}

export function emptyGasApplianceLiveCalculation(): GasApplianceLiveCalculation {
  return {
    connected: null,
    warnings: [],
  };
}
