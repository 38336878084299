import { Module } from "vuex";
import { RootState } from "../types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import HotKeyState from "./types";

export const state: HotKeyState = {
  setting: {},
  loaded: false,
};

const namespaced: boolean = true;

export const hotKey: Module<HotKeyState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
