export type PrimitiveFunction =
  | ConstPrimitiveFunction
  | VariablePrimitiveFunction
  | AddPrimitiveFunction
  | PolynomialPrimitiveFunction
  | MultiplyPrimitiveFunction;

export type ConstPrimitiveFunction = {
  type: "const";
  value: number;
};

export type VariablePrimitiveFunction = {
  type: "variable";
  variableName: string;
};

export type AddPrimitiveFunction = {
  type: "add";
  f1: PrimitiveFunction;
  f2: PrimitiveFunction;
};

export type PolynomialPrimitiveFunction = {
  type: "polynomial";
  coefficients: number[];
  variableName: string;
};

export type MultiplyPrimitiveFunction = {
  type: "multiply";
  f1: PrimitiveFunction;
  f2: PrimitiveFunction;
};

export function evaluatePrimitiveFunction(
  primitiveFunction: PrimitiveFunction,
  params: { [key: string]: number },
): number {
  switch (primitiveFunction.type) {
    case "const":
      return primitiveFunction.value;
    case "variable":
      if (!(primitiveFunction.variableName in params)) {
        throw new Error("Variable not found in params");
      }
      return params[primitiveFunction.variableName];
    case "add":
      return (
        evaluatePrimitiveFunction(primitiveFunction.f1, params) +
        evaluatePrimitiveFunction(primitiveFunction.f2, params)
      );
    case "polynomial":
      if (!(primitiveFunction.variableName in params)) {
        throw new Error("Variable not found in params");
      }
      const xPoly = params[primitiveFunction.variableName];
      let polyRes = 0;
      for (let i = primitiveFunction.coefficients.length - 1; i >= 0; i--) {
        polyRes *= xPoly;
        polyRes += primitiveFunction.coefficients[i];
      }
      return polyRes;
    case "multiply":
      return (
        evaluatePrimitiveFunction(primitiveFunction.f1, params) *
        evaluatePrimitiveFunction(primitiveFunction.f2, params)
      );
    default:
      throw new Error("Unhandled primitive function type");
  }
}

export type FunctionByParts = {
  ranges: {
    min: number | null;
    max: number | null;
    // null means -Infinity or Infinity, i.e., no restriction
    function: PrimitiveFunction;
  }[];
  variableName: string;
};
// assuming it is a continuous function
// therefore, if two intervals share an endpoint, we assume they take the same value at that endpoint

export function evaluateFunctionByParts(
  functionByParts: FunctionByParts,
  x: number,
): number | null {
  for (const { min, max, function: f } of functionByParts.ranges) {
    if ((min == null || min <= x) && (max == null || x <= max)) {
      return evaluatePrimitiveFunction(f, {
        [functionByParts.variableName]: x,
      });
    }
  }
  return null;
}

export function functionByPartsInDomain(
  functionByParts: FunctionByParts,
  x: number,
): boolean {
  return functionByParts.ranges.some(
    ({ min, max }) => (min == null || min <= x) && (max == null || x <= max),
  );
}

export type SingleVariableContinuousFunction = FunctionByParts;
