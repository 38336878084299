import { FluidsSpec, State } from "../types";

export const INITIAL_FLUIDS: Record<string, FluidsSpec> = {
  water: {
    name: "Water",
    densityKGM3: "997",
    dynamicViscosityByTemperature: {
      10: "0.0013076",
      15: "0.0011373",
      18: "0.0010518",
      20: ".00100005",
      25: "0.0008891",
      38: "0.0006791",
      40: "0.0006539",
      43: "0.0006188",
      45: "0.000597",
      50: "0.0005474",
      60: "0.0004656",
      65: "0.000432",
      70: "0.0004024",
    },
    state: State.LIQUID,
    /* https://www.engineeringtoolbox.com/specific-heat-capacity-water-d_660.html */
    specificHeatByTemperatureIsochoricKJ_KGK: {
      0.01: "4.2174",
      10: "4.191",
      20: "4.157",
      25: "4.1379",
      30: "4.1175",
      40: "4.0737",
      50: "4.0264",
      60: "3.9767",
      70: "3.9252",
      80: "3.8729",
      90: "3.8204",
      100: "3.7682",
      110: "3.7167",
      120: "3.6662",
      140: "3.5694",
      160: "3.4788",
      180: "3.3949",
      200: "3.3179",
      220: "3.2479",
      240: "3.185",
      260: "3.1301",
      280: "3.0849",
      300: "3.053",
      320: "3.0428",
      340: "3.0781",
      360: "3.2972",
    },
    specificHeatByTemperatureIsobaricKJ_KGK: {
      0.01: "4.2199",
      10: "4.1955",
      20: "4.1844",
      25: "4.1816",
      30: "4.1801",
      40: "4.1796",
      50: "4.1815",
      60: "4.1851",
      70: "4.1902",
      80: "4.1969",
      90: "4.2053",
      100: "4.2157",
      110: "4.2283",
      120: "4.2435",
      140: "4.2826",
      160: "4.3354",
      180: "4.405",
      200: "4.4958",
      220: "4.6146",
      240: "4.7719",
      260: "4.9856",
      280: "5.2889",
      300: "5.7504",
      320: "6.5373",
      340: "8.208",
      360: "15.004",
    },
  },
  water_glycol_80_20: {
    name: "Ethylene Glycol/Water Solution 20% (by mass)",
    densityKGM3: "1030",
    state: State.LIQUID,
    dynamicViscosityByTemperature: {
      // Derived by formula. See details at ../glycol_data_reference.txt
      [-1.1]: "0.003272",
      [10.0]: "0.002138",
      [37.8]: "0.001045",
      [65.6]: "0.000624",
      [93.3]: "0.000422",
    },
    specificHeatByTemperatureIsochoricKJ_KGK: null,
    specificHeatByTemperatureIsobaricKJ_KGK: {
      [0]: "3.918",
      [10]: "3.935",
      [20]: "3.951",
      [30]: "3.968",
      [40]: "3.985",
      [50]: "4.002",
      [60]: "4.018",
      [70]: "4.035",
      [80]: "4.052",
      [90]: "4.069",
      [100]: "4.085",
    },
  },
  water_glycol_75_25: {
    name: "Ethylene Glycol/Water Solution 25% (by mass)",
    densityKGM3: "1038",
    // Derived by linear polynomial fit vs concentration
    // Details in ../glycol_data_reference.txt

    state: State.LIQUID,
    dynamicViscosityByTemperature: {
      // Derived by formula. See details at ../glycol_data_reference.txt
      [-1.1]: "0.003777",
      [10.0]: "0.002458",
      [37.8]: "0.001173",
      [65.6]: "0.00069",
      [93.3]: "0.000461",
    },
    specificHeatByTemperatureIsochoricKJ_KGK: null,
    specificHeatByTemperatureIsobaricKJ_KGK: {
      // Derived by linear polynomial fit vs concentration (to dreive 25%)
      // Details in ../glycol_data_reference.txt
      [0]: "3.759",
      [10]: "3.789",
      [20]: "3.82",
      [30]: "3.85",
      [40]: "3.881",
      [50]: "3.911",
      [60]: "3.942",
      [70]: "3.972",
      [80]: "4.003",
      [90]: "4.033",
    },
  },
  water_glycol_70_30: {
    name: "Ethylene Glycol/Water Solution 30% (by mass)",
    densityKGM3: "1046",
    state: State.LIQUID,
    dynamicViscosityByTemperature: {
      // Derived by formula. See details at ../glycol_data_reference.txt
      [-1.1]: "0.004378",
      [10.0]: "0.002836",
      [37.8]: "0.001322",
      [65.6]: "0.000764",
      [93.3]: "0.000504",
    },
    specificHeatByTemperatureIsochoricKJ_KGK: null,
    specificHeatByTemperatureIsobaricKJ_KGK: {
      [-10]: "3.742",
      [0]: "3.764",
      [10]: "3.785",
      [20]: "3.807",
      [30]: "3.828",
      [40]: "3.85",
      [50]: "3.872",
      [60]: "3.893",
      [70]: "3.915",
      [80]: "3.936",
      [90]: "3.958",
      [100]: "3.979",
    },
  },
  water_glycol_60_40: {
    name: "Ethylene Glycol/Water Solution 40% (by mass)",
    densityKGM3: "1063",
    state: State.LIQUID,
    dynamicViscosityByTemperature: {
      // Derived by formula. See details at ../glycol_data_reference.txt
      [-17.8]: "0.014196",
      [-1.1]: "0.005962",
      [10.0]: "0.003819",
      [37.8]: "0.001693",
      [65.6]: "0.000944",
      [93.3]: "0.000608",
    },
    specificHeatByTemperatureIsochoricKJ_KGK: null,
    specificHeatByTemperatureIsobaricKJ_KGK: {
      [-20]: "3.542",
      [-10]: "3.569",
      [0]: "3.595",
      [10]: "3.621",
      [20]: "3.647",
      [30]: "3.674",
      [40]: "3.7",
      [50]: "3.726",
      [60]: "3.752",
      [70]: "3.778",
      [80]: "3.805",
      [90]: "3.831",
      [100]: "3.857",
    },
  },
  water_glycol_50_50: {
    name: "Ethylene Glycol/Water Solution 50% (by mass)",
    densityKGM3: "1079",
    state: State.LIQUID,
    dynamicViscosityByTemperature: {
      // Derived by formula. See details at ../glycol_data_reference.txt
      [-17.8]: "0.019969",
      [-1.1]: "0.008277",
      [10.0]: "0.005223",
      [37.8]: "0.002198",
      [65.6]: "0.00118",
      [93.3]: "0.000739",
    },
    specificHeatByTemperatureIsochoricKJ_KGK: null,
    specificHeatByTemperatureIsobaricKJ_KGK: {
      [-30]: "3.32",
      [-20]: "3.35",
      [-10]: "3.381",
      [0]: "3.412",
      [10]: "3.442",
      [20]: "3.473",
      [30]: "3.504",
      [40]: "3.534",
      [50]: "3.565",
      [60]: "3.596",
      [70]: "3.626",
      [80]: "3.657",
      [90]: "3.688",
      [100]: "3.718",
    },
  },
  water_glycol_40_60: {
    name: "Ethylene Glycol/Water Solution 60% (by mass)",
    densityKGM3: "1095",
    state: State.LIQUID,
    dynamicViscosityByTemperature: {
      // Derived by formula. See details at ../glycol_data_reference.txt
      [-17.8]: "0.028974",
      [-1.1]: "0.01174",
      [10.0]: "0.00727",
      [37.8]: "0.002895",
      [65.6]: "0.001493",
      [93.3]: "0.000908",
    },

    specificHeatByTemperatureIsochoricKJ_KGK: null,
    specificHeatByTemperatureIsobaricKJ_KGK: {
      [-50]: "3.04",
      [-40]: "3.075",
      [-30]: "3.11",
      [-20]: "3.145",
      [-10]: "3.179",
      [0]: "3.214",
      [10]: "3.249",
      [20]: "3.284",
      [30]: "3.319",
      [40]: "3.354",
      [50]: "3.389",
      [60]: "3.424",
      [70]: "3.458",
      [80]: "3.493",
      [90]: "3.528",
      [100]: "3.563",
    },
  },
  water_glycol_30_70: {
    name: "Ethylene Glycol/Water Solution 70% (by mass)",
    densityKGM3: "1110",
    state: State.LIQUID,
    dynamicViscosityByTemperature: {
      // Derived by formula. See details at ../glycol_data_reference.txt
      [-17.8]: "0.043559",
      [-1.1]: "0.017043",
      [10.0]: "0.010309",
      [37.8]: "0.003874",
      [65.6]: "0.001916",
      [93.3]: "0.001129",
    },
    specificHeatByTemperatureIsochoricKJ_KGK: null,
    specificHeatByTemperatureIsobaricKJ_KGK: {
      [-50]: "2.808",
      [-40]: "2.847",
      [-30]: "2.886",
      [-20]: "2.925",
      [-10]: "2.963",
      [0]: "3.002",
      [10]: "3.041",
      [20]: "3.08",
      [30]: "3.119",
      [40]: "3.158",
      [50]: "3.197",
      [60]: "3.236",
      [70]: "3.275",
      [80]: "3.313",
      [90]: "3.352",
      [100]: "3.391",
    },
  },
  LPG: {
    name: "LPG Gas",
    densityKGM3: "1.898",
    dynamicViscosityByTemperature: {
      0: "0",
    },
    specificHeatByTemperatureIsochoricKJ_KGK: null,
    specificHeatByTemperatureIsobaricKJ_KGK: {
      0: "0",
    },
    state: State.GAS,
  },
  naturalGas: {
    name: "Natural Gas",
    densityKGM3: "0.8",
    dynamicViscosityByTemperature: {
      0: "0",
    },
    specificHeatByTemperatureIsochoricKJ_KGK: null,
    specificHeatByTemperatureIsobaricKJ_KGK: {
      0: "0",
    },
    state: State.GAS,
  },
  sewage: {
    name: "Sewage",
    densityKGM3: "1000",
    dynamicViscosityByTemperature: {
      0: "0",
    },
    specificHeatByTemperatureIsochoricKJ_KGK: null,
    specificHeatByTemperatureIsobaricKJ_KGK: {
      0: "0",
    },
    state: State.SEWAGE,
  },
  stormwater: {
    name: "Stormwater",
    densityKGM3: "1000",
    dynamicViscosityByTemperature: {
      0: "0",
    },
    specificHeatByTemperatureIsochoricKJ_KGK: null,
    specificHeatByTemperatureIsobaricKJ_KGK: {
      0: "0",
    },
    state: State.LIQUID,
  },
  air: {
    name: "Air",
    densityKGM3: "1.2",
    dynamicViscosityByTemperature: {
      0: "0.00001825",
    },
    specificHeatByTemperatureIsochoricKJ_KGK: null,
    specificHeatByTemperatureIsobaricKJ_KGK: {
      0: "1.006",
    },
    state: State.AIR,
  },
};
