import { assertUnreachable } from "../../../../lib/utils";
import { DrawableEntityConcrete } from "../concrete-entity";
import { EntityType } from "../types";
import { MultiwayValveEntity } from "./multiway-valve-entity";

export function isDiverterValve(
  entity: DrawableEntityConcrete,
): entity is MultiwayValveEntity {
  if (entity.type === EntityType.MULTIWAY_VALVE) {
    switch (entity.valve.type) {
      case "diverter-valve":
        return true;
    }
    assertUnreachable(entity.valve.type);
  } else {
    return false;
  }
}
