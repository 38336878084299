
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { signOut } from "aws-amplify/auth";
import axios from "axios";
import mixpanel from "mixpanel-browser";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { SupportedLocales } from "../../../common/src/api/locale";
import { OrganizationType } from "../../../common/src/models/Organization";
import { AccessLevel, User } from "../../../common/src/models/User";
import router from "../../src/router";
import CalculationReportManager from "../api/calculationReportManager";
import { ENABLE_MIXPANEL } from "../config";
import { DocumentState } from "../store/document/types";
import { isViewOnly } from "../store/document/utils";

@Component({
  components: {
    FontAwesomeIcon,
  },
})
export default class ProfileMenuItem extends Vue {
  @Prop({ required: true }) disabled: boolean;
  mounted() {
    this.fetchOnboardingStatus();
  }

  faUser = faUser;

  get profile(): User {
    const profile = this.$store.getters["profile/profile"];
    if (!profile) {
      return {
        username: "",
        accessLevel: AccessLevel.USER,
        organization: {
          id: "",
          name: "",
          type: OrganizationType.BUILDING_SERVICES,
        },
        email: "",
        subscribed: false,
        name: "",
        passwordHash: "",
        eulaAcceptedVersion: 0,
        eulaAcceptedOn: null,
        lastActivityOn: null,
        lastNoticeSeenOn: null,
        temporaryOrganizationName: null,
        temporaryUser: false,
      } as User;
    } else {
      return profile;
    }
  }

  get isViewOnly() {
    return isViewOnly(this.document.uiState);
  }

  async fetchOnboardingStatus() {
    if (!this.isViewOnly) {
      const { onboardingActive } = await this.$store.dispatch(
        "profile/getProperties",
        ["onboardingActive"],
      );
      if (onboardingActive) {
        // disable the old onboarding for now
        // this.document.uiState.isOnboardingActive = true;
      }
    }
  }

  async logout() {
    try {
      await signOut();
      delete axios.defaults.headers.common["access-token"];
      CalculationReportManager.onLogout();
      if (ENABLE_MIXPANEL) {
        mixpanel.reset();
      }
      this.$store
        .dispatch("profile/setProfile", null)
        .then(() => router.push({ name: "login" }));
    } catch (error: any) {
      this.$bvToast.toast(error.message, {
        title: "Could not log out",
        variant: "danger",
      });
    }
  }

  get AccessLevel() {
    return AccessLevel;
  }

  get isManufacturer() {
    return this.profile.organization?.type === OrganizationType.MANUFACTURER;
  }

  get isBuildingServices() {
    return (
      this.profile.organization?.type === OrganizationType.BUILDING_SERVICES
    );
  }

  get document(): DocumentState {
    return this.$store.getters["document/document"];
  }

  get onboardingActive() {
    return this.document.uiState.isOnboardingActive;
  }

  changePassword() {
    router.push({
      name: "changePassword",
      query: {
        next: this.$router.currentRoute.fullPath,
      },
    });
  }

  handleOnboardingClick() {
    this.$store.dispatch("profile/setProperties", {
      onboardingActive: !this.document.uiState.isOnboardingActive,
    });
    if (document) {
      this.document.uiState.isOnboardingActive =
        !this.document.uiState.isOnboardingActive;
    }
  }

  get locale(): SupportedLocales {
    return this.$store.getters["profile/locale"];
  }
}
