// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from "../flatbuffers/flatbuffers";

export class KeyboardEventRecord
  implements flatbuffers.IUnpackableObject<KeyboardEventRecordT>
{
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): KeyboardEventRecord {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsKeyboardEventRecord(
    bb: flatbuffers.ByteBuffer,
    obj?: KeyboardEventRecord,
  ): KeyboardEventRecord {
    return (obj || new KeyboardEventRecord()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsKeyboardEventRecord(
    bb: flatbuffers.ByteBuffer,
    obj?: KeyboardEventRecord,
  ): KeyboardEventRecord {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new KeyboardEventRecord()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  timestamp(): bigint {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.readUint64(this.bb_pos + offset) : BigInt("0");
  }

  userName(): string | null;
  userName(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  userName(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? this.bb!.__string(this.bb_pos + offset, optionalEncoding)
      : null;
  }

  documentId(): number {
    const offset = this.bb!.__offset(this.bb_pos, 8);
    return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
  }

  previousOrderIndex(): number {
    const offset = this.bb!.__offset(this.bb_pos, 10);
    return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
  }

  key(): string | null;
  key(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  key(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 12);
    return offset
      ? this.bb!.__string(this.bb_pos + offset, optionalEncoding)
      : null;
  }

  dataCy(): string | null;
  dataCy(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  dataCy(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 14);
    return offset
      ? this.bb!.__string(this.bb_pos + offset, optionalEncoding)
      : null;
  }

  outerHtml(): string | null;
  outerHtml(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  outerHtml(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 16);
    return offset
      ? this.bb!.__string(this.bb_pos + offset, optionalEncoding)
      : null;
  }

  tag(): string | null;
  tag(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  tag(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 18);
    return offset
      ? this.bb!.__string(this.bb_pos + offset, optionalEncoding)
      : null;
  }

  id(): number | null {
    const offset = this.bb!.__offset(this.bb_pos, 20);
    return offset ? this.bb!.readUint32(this.bb_pos + offset) : null;
  }

  static startKeyboardEventRecord(builder: flatbuffers.Builder) {
    builder.startObject(9);
  }

  static addTimestamp(builder: flatbuffers.Builder, timestamp: bigint) {
    builder.addFieldInt64(0, timestamp, BigInt("0"));
  }

  static addUserName(
    builder: flatbuffers.Builder,
    userNameOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(1, userNameOffset, 0);
  }

  static addDocumentId(builder: flatbuffers.Builder, documentId: number) {
    builder.addFieldInt32(2, documentId, 0);
  }

  static addPreviousOrderIndex(
    builder: flatbuffers.Builder,
    previousOrderIndex: number,
  ) {
    builder.addFieldInt32(3, previousOrderIndex, 0);
  }

  static addKey(builder: flatbuffers.Builder, keyOffset: flatbuffers.Offset) {
    builder.addFieldOffset(4, keyOffset, 0);
  }

  static addDataCy(
    builder: flatbuffers.Builder,
    dataCyOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(5, dataCyOffset, 0);
  }

  static addOuterHtml(
    builder: flatbuffers.Builder,
    outerHtmlOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(6, outerHtmlOffset, 0);
  }

  static addTag(builder: flatbuffers.Builder, tagOffset: flatbuffers.Offset) {
    builder.addFieldOffset(7, tagOffset, 0);
  }

  static addId(builder: flatbuffers.Builder, id: number) {
    builder.addFieldInt32(8, id, null);
  }

  static endKeyboardEventRecord(
    builder: flatbuffers.Builder,
  ): flatbuffers.Offset {
    const offset = builder.endObject();
    builder.requiredField(offset, 6); // user_name
    builder.requiredField(offset, 12, "keyboard key"); // key
    builder.requiredField(offset, 18); // tag
    return offset;
  }

  static createKeyboardEventRecord(
    builder: flatbuffers.Builder,
    timestamp: bigint,
    userNameOffset: flatbuffers.Offset,
    documentId: number,
    previousOrderIndex: number,
    keyOffset: flatbuffers.Offset,
    dataCyOffset: flatbuffers.Offset,
    outerHtmlOffset: flatbuffers.Offset,
    tagOffset: flatbuffers.Offset,
    id: number | null,
  ): flatbuffers.Offset {
    KeyboardEventRecord.startKeyboardEventRecord(builder);
    KeyboardEventRecord.addTimestamp(builder, timestamp);
    KeyboardEventRecord.addUserName(builder, userNameOffset);
    KeyboardEventRecord.addDocumentId(builder, documentId);
    KeyboardEventRecord.addPreviousOrderIndex(builder, previousOrderIndex);
    KeyboardEventRecord.addKey(builder, keyOffset);
    KeyboardEventRecord.addDataCy(builder, dataCyOffset);
    KeyboardEventRecord.addOuterHtml(builder, outerHtmlOffset);
    KeyboardEventRecord.addTag(builder, tagOffset);
    if (id !== null) KeyboardEventRecord.addId(builder, id);
    return KeyboardEventRecord.endKeyboardEventRecord(builder);
  }

  unpack(): KeyboardEventRecordT {
    return new KeyboardEventRecordT(
      this.timestamp(),
      this.userName(),
      this.documentId(),
      this.previousOrderIndex(),
      this.key(),
      this.dataCy(),
      this.outerHtml(),
      this.tag(),
      this.id(),
    );
  }

  unpackTo(_o: KeyboardEventRecordT): void {
    _o.timestamp = this.timestamp();
    _o.userName = this.userName();
    _o.documentId = this.documentId();
    _o.previousOrderIndex = this.previousOrderIndex();
    _o.key = this.key();
    _o.dataCy = this.dataCy();
    _o.outerHtml = this.outerHtml();
    _o.tag = this.tag();
    _o.id = this.id();
  }
}

export class KeyboardEventRecordT implements flatbuffers.IGeneratedObject {
  _type = "KeyboardEventRecordT" as const;
  constructor(
    public timestamp: bigint = BigInt("0"),
    public userName: string | Uint8Array | null = null,
    public documentId: number = 0,
    public previousOrderIndex: number = 0,
    public key: string | Uint8Array | null = null,
    public dataCy: string | Uint8Array | null = null,
    public outerHtml: string | Uint8Array | null = null,
    public tag: string | Uint8Array | null = null,
    public id: number | null = null,
  ) {}

  pack(builder: flatbuffers.Builder): flatbuffers.Offset {
    const userName =
      this.userName !== null ? builder.createString(this.userName!) : 0;
    const key = this.key !== null ? builder.createString(this.key!) : 0;
    const dataCy =
      this.dataCy !== null ? builder.createString(this.dataCy!) : 0;
    const outerHtml =
      this.outerHtml !== null ? builder.createString(this.outerHtml!) : 0;
    const tag = this.tag !== null ? builder.createString(this.tag!) : 0;

    return KeyboardEventRecord.createKeyboardEventRecord(
      builder,
      this.timestamp,
      userName,
      this.documentId,
      this.previousOrderIndex,
      key,
      dataCy,
      outerHtml,
      tag,
      this.id,
    );
  }
}
