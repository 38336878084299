import {
  Units,
  UnitsContext,
} from "../../../../../common/src/lib/measurements";
import { CoreContext } from "../../calculations/types";
import DamperEntity from "../entities/damper-entity";
import { CalculationField, FieldCategory } from "./calculation-field";
import {
  CalcFieldSelector,
  Calculation,
  CalculationType,
  LiveCalculation,
} from "./types";

export default interface DamperCalculation extends Calculation {
  type: CalculationType.DamperCalculation;
  pressureDropKPA: number | null;
}

export interface DamperLiveCalculation extends LiveCalculation {}

export const FastLiveDamperCalculationFields: CalcFieldSelector<DamperLiveCalculation> =
  {
    connected: true,
  };

export function makeDamperCalculationFields(
  context: CoreContext,
  entity: DamperEntity,
): CalculationField[] {
  const { catalog, drawing, globalStore } = context;
  const systems = drawing.metadata.flowSystems;
  let fields: CalculationField[] = [];

  fields.push(
    {
      property: "reference",
      title: "Reference",
      short: "",
      shortTitle: "",
      units: Units.None,
      category: FieldCategory.EntityName,
      layouts: ["ventilation"],
      defaultEnabled: false,
    },
    {
      property: "pressureDropKPA",
      title: "Pressure Drop (PD)",
      short: "",
      shortTitle: "PD",
      defaultEnabled: false,
      units: Units.KiloPascals,
      unitContext: UnitsContext.VENTILATION,
      category: FieldCategory.Pressure,
      layouts: ["ventilation"],
    },
  );

  return fields;
}

export function emptyDamperCalculation(): DamperCalculation {
  return {
    type: CalculationType.DamperCalculation,
    costBreakdown: null,
    cost: null,
    expandedEntities: null,
    pressureDropKPA: null,
    warnings: [],
  };
}

export function emptyDamperLiveCalculation(): DamperLiveCalculation {
  return {
    connected: null,
    warnings: [],
  };
}
