/*
  Calculations in this file help determine connectivity and cycles for display while drawing.
  They will be super fast dfs.
*/

import { assertUnreachable } from "../../../lib/utils";
import { isSewer } from "../../config";
import {
  CoreCalculatedObjectConcrete,
  CoreConnectableObjectConcrete,
} from "../../coreObjects";
import CoreConduit from "../../coreObjects/coreConduit";
import { getFlowSystemLayouts } from "../../document/calculations-objects/utils";
import { isPipeEntity } from "../../document/entities/conduit-entity";
import { EntityType } from "../../document/entities/types";
import CalculationEngine from "../calculation-engine";
import Graph, { Edge, SubGraph } from "../graph";
import { EdgeType, FlowEdge, FlowNode, isEdgeBacked } from "../types";
import { FLOW_SOURCE_EDGE, isClosedSystemSource, isFlowSource } from "../utils";

export function determineConnectivity(engine: CalculationEngine) {
  for (const o of engine.networkObjects()) {
    if (
      isFlowSource(o.entity, engine) ||
      isClosedSystemSource(o.entity, engine)
    ) {
      const n = isFlowSource(o.entity, engine)
        ? { connectable: o.uid, connection: FLOW_SOURCE_EDGE }
        : {
            connectable: o.uid,
            connection: o.entity.parentUid!,
          };

      engine.flowGraph.dfs(n, undefined, undefined, (edge) => {
        const eo = engine.globalStore.get<CoreCalculatedObjectConcrete>(
          edge.value.uid,
        );
        if (eo) {
          const calc = engine.globalStore.getOrCreateLiveCalculation(eo.entity);
          calc.connected = true;
        }
        const o = engine.globalStore.get<
          CoreConnectableObjectConcrete | undefined
        >(edge.to.connectable);
        if (o) {
          const calc = engine.globalStore.getOrCreateLiveCalculation(o.entity);
          calc.connected = true;

          if (o.type === EntityType.SYSTEM_NODE) {
            const po = engine.globalStore.get<CoreCalculatedObjectConcrete>(
              o.entity.parentUid!,
            );
            if (po) {
              const calc = engine.globalStore.getOrCreateLiveCalculation(
                po.entity,
              );
              calc.connected = true;
            }
          }
        }
      });
    }
  }
}

export function edgeCanFormCycles(
  engine: CalculationEngine,
  edge: Edge<FlowNode, FlowEdge>,
) {
  // special case: Edges between vent and non-vent drainage pipes are not allowed to form cycles.
  switch (edge.value.type) {
    case EdgeType.FITTING_FLOW:
    case EdgeType.BALANCING_THROUGH:
    case EdgeType.CHECK_THROUGH:
    case EdgeType.ISOLATION_THROUGH:
      const from = engine.globalStore.get<CoreConduit>(edge.from.connection);
      const to = engine.globalStore.get<CoreConduit>(edge.to.connection);
      const fromIsVent =
        from &&
        isPipeEntity(from.entity) &&
        isSewer(engine.drawing.metadata.flowSystems[from.entity.systemUid]) &&
        from.entity.conduit.network === "vents";
      const toIsVent =
        to &&
        isPipeEntity(to.entity) &&
        isSewer(engine.drawing.metadata.flowSystems[to.entity.systemUid]) &&
        to.entity.conduit.network === "vents";
      if (fromIsVent !== toIsVent) {
        return false;
      }
      return true;
    case EdgeType.BIG_VALVE_COLD_COLD:
    case EdgeType.BIG_VALVE_COLD_WARM:
    case EdgeType.BIG_VALVE_HOT_HOT:
    case EdgeType.BIG_VALVE_HOT_WARM:
    case EdgeType.CONDUIT:
    case EdgeType.FLOW_SOURCE_EDGE:
    case EdgeType.PLANT_PREHEAT:
    case EdgeType.PLANT_THROUGH:
    case EdgeType.RETURN_PUMP:
      return true;
    default:
      assertUnreachable(edge.value.type);
  }
}

export function determineCycles(engine: CalculationEngine) {
  let [nodes, edges] = engine.flowGraph.toSubgraph();
  edges = edges.filter((e) => {
    return edgeCanFormCycles(engine, e);
  });
  const cycleCandidateGraph = Graph.fromSubgraph(
    [nodes, edges],
    engine.flowGraph.sn,
  );
  const [bridges, subgraphs] =
    cycleCandidateGraph.findBridgeSeparatedComponents();
  // all pipes in subgraphs are cyclic.
  let i = 0;
  for (const subgraph of subgraphs) {
    i++;
    if (subgraph[1].length > 1) {
      for (const edge of subgraph[1]) {
        if (isEdgeBacked(edge.value.type)) {
          const o = engine.globalStore.get<CoreCalculatedObjectConcrete>(
            edge.value.uid,
          );
          if (o && o.type === EntityType.CONDUIT) {
            const calc = engine.globalStore.getOrCreateLiveCalculation(
              o.entity,
            );
            calc.cycle = i;
          }
        }
      }
    }
  }
}

export function makeConnectivityWarnings(engine: CalculationEngine) {
  for (const o of engine.networkObjects()) {
    if (o.type === EntityType.FITTING) {
      const connections = engine.globalStore.getConnections(o.uid);
      if (connections.length === 1) {
        const calc = engine.globalStore.getOrCreateLiveCalculation(o.entity);
        calc.warnings.push({
          type: "INVALID_CAP_END",
          instanceId: engine.nextLiveWarningInstanceId++,
          layouts: getFlowSystemLayouts(
            engine.drawing.metadata.flowSystems[o.entity.systemUid],
          ).layouts,
        });
      }
    }
  }
}

// Returns a clean loop of ALL pipes in the subgraph, in order, or null if it's a complex cycle or empty.
export function getSimpleCycleOfBiconnectedComponent(
  engine: CalculationEngine,
  subgraph: SubGraph<FlowNode, FlowEdge>,
): Edge<FlowNode, FlowEdge>[] | null {
  const pipesInRing = new Set<string>();
  const connections = new Map<string, string[]>();
  const pipeToEdge = new Map<string, Edge<FlowNode, FlowEdge>>();
  for (const edge of subgraph[1]) {
    if (isEdgeBacked(edge.value.type)) {
      const o = engine.globalStore.get<CoreCalculatedObjectConcrete>(
        edge.value.uid,
      );
      if (o && o.type === EntityType.CONDUIT) {
        pipeToEdge.set(edge.value.uid, edge);
        pipesInRing.add(edge.value.uid);
        for (const connectableUid of o.entity.endpointUid) {
          if (!connections.has(connectableUid)) {
            connections.set(connectableUid, []);
          }
          connections.get(connectableUid)!.push(edge.value.uid);
        }
      }
    }
  }

  for (const [connectableUid, pipeUids] of connections) {
    if (pipeUids.length !== 2) {
      return null;
    }
  }

  const res: Edge<FlowNode, FlowEdge>[] = [];
  const startEdge = subgraph[1][0];
  let currentEdge = startEdge;
  let currentPipeUid = startEdge.value.uid;
  while (true) {
    res.push(currentEdge);
    pipesInRing.delete(currentPipeUid);
    const nextPipeUid = connections
      .get(currentEdge.to.connectable)!
      .find((uid) => uid !== currentPipeUid)!;
    if (nextPipeUid === startEdge.value.uid) {
      break;
    }
    currentPipeUid = nextPipeUid;
    currentEdge = pipeToEdge.get(nextPipeUid)!;
  }

  if (pipesInRing.size > 0) {
    return null;
  }

  return res;
}
