import { bar2kpa } from "../../../../lib/measurements";
import { PumpTankData, PumpTankManufacturer } from "../../types";

export const DutyPointPumpTankManufacturer: PumpTankManufacturer = {
  name: "Dutypoint",
  abbreviation: "Dutypoint",
  uid: "dutypoint",
  priceTableName: "Pump/Tank",
  componentName: "Dutypoint",
  displayOnWizard: true,
};

export const DutyPointPumpTankData: PumpTankData = {
  type: "model-capacity-dependent",

  sizes: {
    "WX1-3040-175": {
      widthMM: 600.0,
      heightMM: 600.0,
    },
    "WX1-3060-175": {
      widthMM: 600.0,
      heightMM: 600.0,
    },
    "WX1-3080-175": {
      widthMM: 600.0,
      heightMM: 600.0,
    },
    "WX1-5040-175": {
      widthMM: 600.0,
      heightMM: 600.0,
    },
    "WX1-5060-175": {
      widthMM: 600.0,
      heightMM: 600.0,
    },
    "WX1-5080-175": {
      widthMM: 600.0,
      heightMM: 600.0,
    },
    "WX1-3040-300": {
      widthMM: 600.0,
      heightMM: 600.0,
    },
    "WX1-3060-300": {
      widthMM: 600.0,
      heightMM: 600.0,
    },
    "WX1-3080-300": {
      widthMM: 600.0,
      heightMM: 600.0,
    },
    "WX1-5040-300": {
      widthMM: 600.0,
      heightMM: 600.0,
    },
    "WX1-5060-300": {
      widthMM: 600.0,
      heightMM: 600.0,
    },
    "WX1-5080-300": {
      widthMM: 600.0,
      heightMM: 600.0,
    },
    "WX1-3040-375": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX1-3060-375": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX1-3080-375": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX1-5040-375": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX1-5060-375": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX1-5080-375": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX1-9040-375": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX1-9060-375": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX1-3040-440": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX1-3060-440": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX1-3080-440": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX1-5040-440": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX1-5060-440": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX1-5080-440": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX1-9040-440": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX1-9060-440": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX1-3040-490": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX1-3060-490": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX1-3080-490": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX1-5040-490": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX1-5060-490": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX1-5080-490": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX1-9040-490": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX1-9060-490": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX1-3040-575": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX1-3060-575": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX1-3080-575": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX1-5040-575": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX1-5060-575": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX1-5080-575": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX1-9040-575": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX1-9060-575": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX1-3040-650": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX1-3060-650": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX1-3080-650": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX1-5040-650": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX1-5060-650": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX1-5080-650": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX1-9040-650": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX1-9060-650": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX1-3040-750": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX1-3060-750": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX1-3080-750": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX1-5040-750": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX1-5060-750": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX1-5080-750": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX1-9040-750": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX1-9060-750": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX1-3040-800": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX1-3060-800": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX1-3080-800": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX1-5040-800": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX1-5060-800": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX1-5080-800": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX1-9040-800": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX1-9060-800": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX1-3040-1050": {
      widthMM: 800.0,
      heightMM: 1500.0,
    },
    "WX1-3060-1050": {
      widthMM: 800.0,
      heightMM: 1500.0,
    },
    "WX1-3080-1050": {
      widthMM: 800.0,
      heightMM: 1500.0,
    },
    "WX1-5040-1050": {
      widthMM: 800.0,
      heightMM: 1500.0,
    },
    "WX1-5060-1050": {
      widthMM: 800.0,
      heightMM: 1500.0,
    },
    "WX1-5080-1050": {
      widthMM: 800.0,
      heightMM: 1500.0,
    },
    "WX1-9040-1050": {
      widthMM: 800.0,
      heightMM: 1500.0,
    },
    "WX1-9060-1050": {
      widthMM: 800.0,
      heightMM: 1500.0,
    },
    "WX1-3040-1250": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX1-3060-1250": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX1-3080-1250": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX1-5040-1250": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX1-5060-1250": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX1-5080-1250": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX1-9040-1250": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX1-9060-1250": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX1-3040-1650": {
      widthMM: 1160.0,
      heightMM: 1500.0,
    },
    "WX1-3060-1650": {
      widthMM: 1160.0,
      heightMM: 1500.0,
    },
    "WX1-3080-1650": {
      widthMM: 1160.0,
      heightMM: 1500.0,
    },
    "WX1-5040-1650": {
      widthMM: 1160.0,
      heightMM: 1500.0,
    },
    "WX1-5060-1650": {
      widthMM: 1160.0,
      heightMM: 1500.0,
    },
    "WX1-5080-1650": {
      widthMM: 1160.0,
      heightMM: 1500.0,
    },
    "WX1-9040-1650": {
      widthMM: 1160.0,
      heightMM: 1500.0,
    },
    "WX1-9060-1650": {
      widthMM: 1160.0,
      heightMM: 1500.0,
    },
    "WX1-3040-2250": {
      widthMM: 1160.0,
      heightMM: 2000.0,
    },
    "WX1-3060-2250": {
      widthMM: 1160.0,
      heightMM: 2000.0,
    },
    "WX1-3080-2250": {
      widthMM: 1160.0,
      heightMM: 2000.0,
    },
    "WX1-5040-2250": {
      widthMM: 1160.0,
      heightMM: 2000.0,
    },
    "WX1-5060-2250": {
      widthMM: 1160.0,
      heightMM: 2000.0,
    },
    "WX1-5080-2250": {
      widthMM: 1160.0,
      heightMM: 2000.0,
    },
    "WX1-9040-2250": {
      widthMM: 1160.0,
      heightMM: 2000.0,
    },
    "WX1-9060-2250": {
      widthMM: 1160.0,
      heightMM: 2000.0,
    },
    "WX2-3040-375": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX2-3060-375": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX2-3080-375": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX2-5040-375": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX2-5060-375": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX2-5080-375": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX2-9040-375": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX2-9060-375": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX2-3040-440": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX2-3060-440": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX2-3080-440": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX2-5040-440": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX2-5060-440": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX2-5080-440": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX2-9040-440": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX2-9060-440": {
      widthMM: 600.0,
      heightMM: 950.0,
    },
    "WX2-3040-490": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX2-3060-490": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX2-3080-490": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX2-5040-490": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX2-5060-490": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX2-5080-490": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX2-9040-490": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX2-9060-490": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX2-3040-575": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX2-3060-575": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX2-3080-575": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX2-5040-575": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX2-5060-575": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX2-5080-575": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX2-9040-575": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX2-9060-575": {
      widthMM: 750.0,
      heightMM: 950.0,
    },
    "WX2-3040-650": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX2-3060-650": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX2-3080-650": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX2-5040-650": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX2-5060-650": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX2-5080-650": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX2-9040-650": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX2-9060-650": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX2-3040-750": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX2-3060-750": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX2-3080-750": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX2-5040-750": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX2-5060-750": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX2-5080-750": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX2-9040-750": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX2-9060-750": {
      widthMM: 750.0,
      heightMM: 1200.0,
    },
    "WX2-3040-800": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX2-3060-800": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX2-3080-800": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX2-5040-800": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX2-5060-800": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX2-5080-800": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX2-9040-800": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX2-9060-800": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX2-3040-1050": {
      widthMM: 800.0,
      heightMM: 1500.0,
    },
    "WX2-3060-1050": {
      widthMM: 800.0,
      heightMM: 1500.0,
    },
    "WX2-3080-1050": {
      widthMM: 800.0,
      heightMM: 1500.0,
    },
    "WX2-5040-1050": {
      widthMM: 800.0,
      heightMM: 1500.0,
    },
    "WX2-5060-1050": {
      widthMM: 800.0,
      heightMM: 1500.0,
    },
    "WX2-5080-1050": {
      widthMM: 800.0,
      heightMM: 1500.0,
    },
    "WX2-9040-1050": {
      widthMM: 800.0,
      heightMM: 1500.0,
    },
    "WX2-9060-1050": {
      widthMM: 800.0,
      heightMM: 1500.0,
    },
    "WX2-3040-1250": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX2-3060-1250": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX2-3080-1250": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX2-5040-1250": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX2-5060-1250": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX2-5080-1250": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX2-9040-1250": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX2-9060-1250": {
      widthMM: 1160.0,
      heightMM: 1160.0,
    },
    "WX2-3040-1650": {
      widthMM: 1160.0,
      heightMM: 1500.0,
    },
    "WX2-3060-1650": {
      widthMM: 1160.0,
      heightMM: 1500.0,
    },
    "WX2-3080-1650": {
      widthMM: 1160.0,
      heightMM: 1500.0,
    },
    "WX2-5040-1650": {
      widthMM: 1160.0,
      heightMM: 1500.0,
    },
    "WX2-5060-1650": {
      widthMM: 1160.0,
      heightMM: 1500.0,
    },
    "WX2-5080-1650": {
      widthMM: 1160.0,
      heightMM: 1500.0,
    },
    "WX2-9040-1650": {
      widthMM: 1160.0,
      heightMM: 1500.0,
    },
    "WX2-9060-1650": {
      widthMM: 1160.0,
      heightMM: 1500.0,
    },
    "WX2-3040-2250": {
      widthMM: 1160.0,
      heightMM: 2000.0,
    },
    "WX2-3060-2250": {
      widthMM: 1160.0,
      heightMM: 2000.0,
    },
    "WX2-3080-2250": {
      widthMM: 1160.0,
      heightMM: 2000.0,
    },
    "WX2-5040-2250": {
      widthMM: 1160.0,
      heightMM: 2000.0,
    },
    "WX2-5060-2250": {
      widthMM: 1160.0,
      heightMM: 2000.0,
    },
    "WX2-5080-2250": {
      widthMM: 1160.0,
      heightMM: 2000.0,
    },
    "WX2-9040-2250": {
      widthMM: 1160.0,
      heightMM: 2000.0,
    },
    "WX2-9060-2250": {
      widthMM: 1160.0,
      heightMM: 2000.0,
    },
  },

  configuration: {
    duty: {
      referenceLookup: {
        [bar2kpa(2)]: {
          "1.1": "1",
          "2": "4",
          "3.9": "8",
        },
        [bar2kpa(2.5)]: {
          "1.2": "2",
          "2": "5",
          "3.4": "7",
          "3.8": "8",
        },
        [bar2kpa(3)]: {
          "1.1": "2",
          "2": "5",
          "2.9": "7",
          "3.6": "8",
        },
        [bar2kpa(3.5)]: {
          "1": "2",
          "1.2": "3",
          "1.8": "5",
          "2.6": "7",
          "3.4": "8",
        },
        [bar2kpa(4)]: {
          "0.9": "2",
          "1.1": "3",
          "1.6": "5",
          "2": "6",
          "3.1": "7",
        },
        [bar2kpa(4.5)]: {
          "0.8": "2",
          "1": "3",
          "1.5": "5",
          "1.9": "6",
          "2.9": "8",
        },
        [bar2kpa(5)]: {
          "0.6": "2",
          "0.9": "3",
          "1.1": "5",
          "1.8": "6",
          "2.5": "7",
        },
        [bar2kpa(6)]: {
          "0.8": "3",
          "1.5": "6",
          "1.6": "8",
        },
      },

      capacityLookup: {
        "175": {
          "1": "WX1-3040-175",
          "2": "WX1-3060-175",
          "3": "WX1-3080-175",
          "4": "WX1-5040-175",
          "5": "WX1-5060-175",
          "6": "WX1-5080-175",
          "7": "",
          "8": "",
        },
        "300": {
          "1": "WX1-3040-300",
          "2": "WX1-3060-300",
          "3": "WX1-3080-300",
          "4": "WX1-5040-300",
          "5": "WX1-5060-300",
          "6": "WX1-5080-300",
          "7": "",
          "8": "",
        },
        "375": {
          "1": "WX1-3040-375",
          "2": "WX1-3060-375",
          "3": "WX1-3080-375",
          "4": "WX1-5040-375",
          "5": "WX1-5060-375",
          "6": "WX1-5080-375",
          "7": "WX1-9040-375",
          "8": "WX1-9060-375",
        },
        "440": {
          "1": "WX1-3040-440",
          "2": "WX1-3060-440",
          "3": "WX1-3080-440",
          "4": "WX1-5040-440",
          "5": "WX1-5060-440",
          "6": "WX1-5080-440",
          "7": "WX1-9040-440",
          "8": "WX1-9060-440",
        },
        "490": {
          "1": "WX1-3040-490",
          "2": "WX1-3060-490",
          "3": "WX1-3080-490",
          "4": "WX1-5040-490",
          "5": "WX1-5060-490",
          "6": "WX1-5080-490",
          "7": "WX1-9040-490",
          "8": "WX1-9060-490",
        },
        "575": {
          "1": "WX1-3040-575",
          "2": "WX1-3060-575",
          "3": "WX1-3080-575",
          "4": "WX1-5040-575",
          "5": "WX1-5060-575",
          "6": "WX1-5080-575",
          "7": "WX1-9040-575",
          "8": "WX1-9060-575",
        },
        "650": {
          "1": "WX1-3040-650",
          "2": "WX1-3060-650",
          "3": "WX1-3080-650",
          "4": "WX1-5040-650",
          "5": "WX1-5060-650",
          "6": "WX1-5080-650",
          "7": "WX1-9040-650",
          "8": "WX1-9060-650",
        },
        "750": {
          "1": "WX1-3040-750",
          "2": "WX1-3060-750",
          "3": "WX1-3080-750",
          "4": "WX1-5040-750",
          "5": "WX1-5060-750",
          "6": "WX1-5080-750",
          "7": "WX1-9040-750",
          "8": "WX1-9060-750",
        },
        "800": {
          "1": "WX1-3040-800",
          "2": "WX1-3060-800",
          "3": "WX1-3080-800",
          "4": "WX1-5040-800",
          "5": "WX1-5060-800",
          "6": "WX1-5080-800",
          "7": "WX1-9040-800",
          "8": "WX1-9060-800",
        },
        "1050": {
          "1": "WX1-3040-1050",
          "2": "WX1-3060-1050",
          "3": "WX1-3080-1050",
          "4": "WX1-5040-1050",
          "5": "WX1-5060-1050",
          "6": "WX1-5080-1050",
          "7": "WX1-9040-1050",
          "8": "WX1-9060-1050",
        },
        "1250": {
          "1": "WX1-3040-1250",
          "2": "WX1-3060-1250",
          "3": "WX1-3080-1250",
          "4": "WX1-5040-1250",
          "5": "WX1-5060-1250",
          "6": "WX1-5080-1250",
          "7": "WX1-9040-1250",
          "8": "WX1-9060-1250",
        },
        "1650": {
          "1": "WX1-3040-1650",
          "2": "WX1-3060-1650",
          "3": "WX1-3080-1650",
          "4": "WX1-5040-1650",
          "5": "WX1-5060-1650",
          "6": "WX1-5080-1650",
          "7": "WX1-9040-1650",
          "8": "WX1-9060-1650",
        },
        "2250": {
          "1": "WX1-3040-2250",
          "2": "WX1-3060-2250",
          "3": "WX1-3080-2250",
          "4": "WX1-5040-2250",
          "5": "WX1-5060-2250",
          "6": "WX1-5080-2250",
          "7": "WX1-9040-2250",
          "8": "WX1-9060-2250",
        },
      },
    },

    "duty-standby": {
      referenceLookup: {
        [bar2kpa(2)]: {
          "1.1": "1",
          "2": "4",
          "3.9": "8",
        },
        [bar2kpa(2.5)]: {
          "1.2": "2",
          "2": "5",
          "3.4": "7",
          "3.8": "8",
        },
        [bar2kpa(3)]: {
          "1.1": "2",
          "2": "5",
          "2.9": "7",
          "3.6": "8",
        },
        [bar2kpa(3.5)]: {
          "1": "2",
          "1.2": "3",
          "1.8": "5",
          "2.6": "7",
          "3.4": "8",
        },
        [bar2kpa(4)]: {
          "0.9": "2",
          "1.1": "3",
          "1.6": "5",
          "2": "6",
          "3.1": "7",
        },
        [bar2kpa(4.5)]: {
          "0.8": "2",
          "1": "3",
          "1.5": "5",
          "1.9": "6",
          "2.9": "8",
        },
        [bar2kpa(5)]: {
          "0.6": "2",
          "0.9": "3",
          "1.1": "5",
          "1.8": "6",
          "2.5": "7",
        },
        [bar2kpa(6)]: {
          "0.8": "3",
          "1.5": "6",
          "1.6": "8",
        },
      },

      capacityLookup: {
        "375": {
          "1": "WX2-3040-375",
          "2": "WX2-3060-375",
          "3": "WX2-3080-375",
          "4": "WX2-5040-375",
          "5": "WX2-5060-375",
          "6": "WX2-5080-375",
          "7": "WX2-9040-375",
          "8": "WX2-9060-375",
        },
        "440": {
          "1": "WX2-3040-440",
          "2": "WX2-3060-440",
          "3": "WX2-3080-440",
          "4": "WX2-5040-440",
          "5": "WX2-5060-440",
          "6": "WX2-5080-440",
          "7": "WX2-9040-440",
          "8": "WX2-9060-440",
        },
        "490": {
          "1": "WX2-3040-490",
          "2": "WX2-3060-490",
          "3": "WX2-3080-490",
          "4": "WX2-5040-490",
          "5": "WX2-5060-490",
          "6": "WX2-5080-490",
          "7": "WX2-9040-490",
          "8": "WX2-9060-490",
        },
        "575": {
          "1": "WX2-3040-575",
          "2": "WX2-3060-575",
          "3": "WX2-3080-575",
          "4": "WX2-5040-575",
          "5": "WX2-5060-575",
          "6": "WX2-5080-575",
          "7": "WX2-9040-575",
          "8": "WX2-9060-575",
        },
        "650": {
          "1": "WX2-3040-650",
          "2": "WX2-3060-650",
          "3": "WX2-3080-650",
          "4": "WX2-5040-650",
          "5": "WX2-5060-650",
          "6": "WX2-5080-650",
          "7": "WX2-9040-650",
          "8": "WX2-9060-650",
        },
        "750": {
          "1": "WX2-3040-750",
          "2": "WX2-3060-750",
          "3": "WX2-3080-750",
          "4": "WX2-5040-750",
          "5": "WX2-5060-750",
          "6": "WX2-5080-750",
          "7": "WX2-9040-750",
          "8": "WX2-9060-750",
        },
        "800": {
          "1": "WX2-3040-800",
          "2": "WX2-3060-800",
          "3": "WX2-3080-800",
          "4": "WX2-5040-800",
          "5": "WX2-5060-800",
          "6": "WX2-5080-800",
          "7": "WX2-9040-800",
          "8": "WX2-9060-800",
        },
        "1050": {
          "1": "WX2-3040-1050",
          "2": "WX2-3060-1050",
          "3": "WX2-3080-1050",
          "4": "WX2-5040-1050",
          "5": "WX2-5060-1050",
          "6": "WX2-5080-1050",
          "7": "WX2-9040-1050",
          "8": "WX2-9060-1050",
        },
        "1250": {
          "1": "WX2-3040-1250",
          "2": "WX2-3060-1250",
          "3": "WX2-3080-1250",
          "4": "WX2-5040-1250",
          "5": "WX2-5060-1250",
          "6": "WX2-5080-1250",
          "7": "WX2-9040-1250",
          "8": "WX2-9060-1250",
        },
        "1650": {
          "1": "WX2-3040-1650",
          "2": "WX2-3060-1650",
          "3": "WX2-3080-1650",
          "4": "WX2-5040-1650",
          "5": "WX2-5060-1650",
          "6": "WX2-5080-1650",
          "7": "WX2-9040-1650",
          "8": "WX2-9060-1650",
        },
        "2250": {
          "1": "WX2-3040-2250",
          "2": "WX2-3060-2250",
          "3": "WX2-3080-2250",
          "4": "WX2-5040-2250",
          "5": "WX2-5060-2250",
          "6": "WX2-5080-2250",
          "7": "WX2-9040-2250",
          "8": "WX2-9060-2250",
        },
      },
    },

    "duty-assist": {
      referenceLookup: {
        [bar2kpa(2)]: {
          "2.2": "1",
          "4": "4",
          "7.8": "8",
        },
        [bar2kpa(2.5)]: {
          "2.4": "2",
          "4": "5",
          "6.7": "7",
          "7.5": "8",
        },
        [bar2kpa(3)]: {
          "2.2": "2",
          "3.9": "5",
          "5.8": "7",
          "7.1": "8",
        },
        [bar2kpa(3.5)]: {
          "2": "2",
          "2.4": "3",
          "3.5": "5",
          "5.1": "7",
          "6.7": "8",
        },
        [bar2kpa(4)]: {
          "1.8": "2",
          "2.2": "3",
          "3.2": "5",
          "4": "6",
          "6.2": "7",
        },
        [bar2kpa(4.5)]: {
          "1.5": "2",
          "2": "3",
          "2.9": "5",
          "3.7": "6",
          "5.7": "8",
        },
        [bar2kpa(5)]: {
          "1.2": "2",
          "1.8": "3",
          "2.2": "5",
          "3.5": "6",
          "5": "7",
        },
        [bar2kpa(6)]: {
          "1.5": "3",
          "2.9": "6",
          "3.2": "8",
        },
      },

      capacityLookup: {
        "375": {
          "1": "WX2-3040-375",
          "2": "WX2-3060-375",
          "3": "WX2-3080-375",
          "4": "WX2-5040-375",
          "5": "WX2-5060-375",
          "6": "WX2-5080-375",
          "7": "WX2-9040-375",
          "8": "WX2-9060-375",
        },
        "440": {
          "1": "WX2-3040-440",
          "2": "WX2-3060-440",
          "3": "WX2-3080-440",
          "4": "WX2-5040-440",
          "5": "WX2-5060-440",
          "6": "WX2-5080-440",
          "7": "WX2-9040-440",
          "8": "WX2-9060-440",
        },
        "490": {
          "1": "WX2-3040-490",
          "2": "WX2-3060-490",
          "3": "WX2-3080-490",
          "4": "WX2-5040-490",
          "5": "WX2-5060-490",
          "6": "WX2-5080-490",
          "7": "WX2-9040-490",
          "8": "WX2-9060-490",
        },
        "575": {
          "1": "WX2-3040-575",
          "2": "WX2-3060-575",
          "3": "WX2-3080-575",
          "4": "WX2-5040-575",
          "5": "WX2-5060-575",
          "6": "WX2-5080-575",
          "7": "WX2-9040-575",
          "8": "WX2-9060-575",
        },
        "650": {
          "1": "WX2-3040-650",
          "2": "WX2-3060-650",
          "3": "WX2-3080-650",
          "4": "WX2-5040-650",
          "5": "WX2-5060-650",
          "6": "WX2-5080-650",
          "7": "WX2-9040-650",
          "8": "WX2-9060-650",
        },
        "750": {
          "1": "WX2-3040-750",
          "2": "WX2-3060-750",
          "3": "WX2-3080-750",
          "4": "WX2-5040-750",
          "5": "WX2-5060-750",
          "6": "WX2-5080-750",
          "7": "WX2-9040-750",
          "8": "WX2-9060-750",
        },
        "800": {
          "1": "WX2-3040-800",
          "2": "WX2-3060-800",
          "3": "WX2-3080-800",
          "4": "WX2-5040-800",
          "5": "WX2-5060-800",
          "6": "WX2-5080-800",
          "7": "WX2-9040-800",
          "8": "WX2-9060-800",
        },
        "1050": {
          "1": "WX2-3040-1050",
          "2": "WX2-3060-1050",
          "3": "WX2-3080-1050",
          "4": "WX2-5040-1050",
          "5": "WX2-5060-1050",
          "6": "WX2-5080-1050",
          "7": "WX2-9040-1050",
          "8": "WX2-9060-1050",
        },
        "1250": {
          "1": "WX2-3040-1250",
          "2": "WX2-3060-1250",
          "3": "WX2-3080-1250",
          "4": "WX2-5040-1250",
          "5": "WX2-5060-1250",
          "6": "WX2-5080-1250",
          "7": "WX2-9040-1250",
          "8": "WX2-9060-1250",
        },
        "1650": {
          "1": "WX2-3040-1650",
          "2": "WX2-3060-1650",
          "3": "WX2-3080-1650",
          "4": "WX2-5040-1650",
          "5": "WX2-5060-1650",
          "6": "WX2-5080-1650",
          "7": "WX2-9040-1650",
          "8": "WX2-9060-1650",
        },
        "2250": {
          "1": "WX2-3040-2250",
          "2": "WX2-3060-2250",
          "3": "WX2-3080-2250",
          "4": "WX2-5040-2250",
          "5": "WX2-5060-2250",
          "6": "WX2-5080-2250",
          "7": "WX2-9040-2250",
          "8": "WX2-9060-2250",
        },
      },
    },
  },
};
