export class SeededRandom {
  seed: number;
  constructor(seed: number) {
    this.seed = seed;
  }

  random() {
    this.seed = (this.seed * 9301 + 69297) % 629001 >>> 0;
    let x = Math.cos(this.seed) * 3427;
    x += Math.sin(this.seed) * 2321;
    x += Math.asinh(this.seed) * 321;
    return x - Math.floor(x);
  }
}
