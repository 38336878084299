import { isGas } from "../../../../../common/src/api/config";
import LoadNodeEntity, {
  NodeType,
} from "../../../../../common/src/api/document/entities/load-node-entity";
import {
  Units,
  UnitsContext,
} from "../../../../../common/src/lib/measurements";
import { CoreContext } from "../../calculations/types";
import { getPsdUnitName } from "../../calculations/utils";
import { determineConnectableSystemUid } from "../../coreObjects/utils";
import { CalculationField, FieldCategory } from "./calculation-field";
import {
  CalcFieldSelector,
  Calculation,
  CalculationType,
  LiveCalculation,
  PressureCalculation,
  PsdCalculation,
  addPressureCalculationFields,
} from "./types";
import { getFlowSystemLayouts } from "./utils";

export default interface LoadNodeCalculation
  extends Calculation,
    PressureCalculation,
    PsdCalculation {
  type: CalculationType.LoadNodeCalculation;
  flowRateLS: number | null;
  gasFlowRateMJH: number | null;
  gasPressureKPA: number | null;
  maxiumumSimutaneousNode: number | null;
  pressureDropKPA: number | null;
}

export interface LoadNodeLiveCalculation extends LiveCalculation {
  flowRateLS: number | null;
}

export const FastLiveLoadNodeCalculationFields: CalcFieldSelector<LoadNodeLiveCalculation> =
  {
    connected: true,
    flowRateLS: true,
  };

export const StickyLoadNodeCalcualtionFields: CalcFieldSelector<LoadNodeCalculation> =
  {
    gasPressureKPA: true,
  };

export function makeLoadNodeCalculationFields(
  context: CoreContext,
  entity: LoadNodeEntity,
): CalculationField[] {
  const { catalog, drawing, globalStore, locale } = context;
  const result: CalculationField[] = [];

  const systemUid = determineConnectableSystemUid(globalStore, entity);

  const { layouts, layoutsExcept, onlyLayouts } = getFlowSystemLayouts(
    drawing.metadata.flowSystems[systemUid!],
  );

  result.push({
    property: "reference",
    title: "Reference",
    short: "",
    shortTitle: "",
    units: Units.None,
    category: FieldCategory.EntityName,
    systemUid: systemUid,
    layouts,
    defaultEnabled: false,
  });

  addPressureCalculationFields(
    context,
    result,
    entity.systemUidOption || undefined,
    [],
    "",
    { defaultEnabled: false, layouts: [...layouts, "pressure"] },
    { defaultEnabled: false, layouts: [...layouts, "drainage"] },
  );

  const thisIsGas = isGas(drawing.metadata.flowSystems[systemUid!]);

  if (thisIsGas) {
    result.push({
      property: "gasFlowRateMJH",
      title: "Flow Rate",
      short: "",
      units: Units.MegajoulesPerHour,
      systemUid,
      category: FieldCategory.FlowRate,
      layouts,
      unitContext: UnitsContext.GAS_ENERGY_MEASUREMENT,
    });
  } else {
    result.push({
      property: "flowRateLS",
      title: "Flow Rate",
      short: "",
      units: Units.LitersPerSecond,
      systemUid,
      category: FieldCategory.FlowRate,
      layouts,
    });
  }

  const psdUnit = getPsdUnitName(
    drawing.metadata.calculationParams.psdMethod,
    locale,
  );
  switch (entity.node.type) {
    case NodeType.LOAD_NODE:
      if (!thisIsGas) {
        result.push({
          property: "psdUnits.units",
          title: psdUnit.name,
          short: psdUnit.abbreviation,
          shortTitle: "",
          units: Units.None,
          category: FieldCategory.LoadingUnits,
          systemUid,
          layouts: onlyLayouts("pressure"),
        });
      }
      break;
    case NodeType.DWELLING:
      result.push({
        property: "psdUnits.dwellings",
        title: "Dwellings",
        short: "dwlg",
        units: Units.None,
        category: FieldCategory.LoadingUnits,
        systemUid,
        layouts: onlyLayouts("pressure"),
      });
      break;
    case NodeType.FIRE:
      result.push({
        property: "maxiumumSimutaneousNode",
        title: "Maximum Simultaneous Operated Nodes (MSON)",
        short: "",
        shortTitle: "MSON",
        units: Units.None,
        category: FieldCategory.Size,
        systemUid,
        layouts: layoutsExcept("drainage"),
      });
      result.push({
        property: "pressureDropKPA",
        title: "Pressure Drop Through Node's kV Value (PDT)",
        short: "",
        shortTitle: "PDT",
        units: Units.KiloPascals,
        category: FieldCategory.Pressure,
        systemUid,
        layouts: layoutsExcept("drainage"),
      });
      break;
    case NodeType.VENTILATION:
      result.push({
        property: "pressureDropKPA",
        title: "Pressure Drop (PD)",
        short: "",
        shortTitle: "PD",
        units: Units.KiloPascals,
        unitContext: UnitsContext.VENTILATION,
        category: FieldCategory.Pressure,
        systemUid,
        layouts: layoutsExcept("drainage"),
      });
  }

  return result;
}

export function emptyLoadNodeCalculation(): LoadNodeCalculation {
  return {
    type: CalculationType.LoadNodeCalculation,
    costBreakdown: null,
    cost: null,
    expandedEntities: null,

    pressureKPA: null,
    psdUnits: null,
    staticPressureKPA: null,
    flowRateLS: null,
    gasFlowRateMJH: null,
    gasPressureKPA: null,
    warnings: null,
    maxiumumSimutaneousNode: null,
    pressureDropKPA: null,
  };
}

export function emptyLoadNodeLiveCalculation(): LoadNodeLiveCalculation {
  return {
    connected: null,
    flowRateLS: null,
    warnings: [],
  };
}
