import { CoreObjectConcrete } from "../coreObjects";
import {
  CoreContext,
  FlowNode,
  isPressureLossSuccess,
  PressureLossResult,
  PressurePushMode,
} from "./types";

export interface GetPressureLossOptions {
  context: CoreContext;
  flowLS: number;
  from: FlowNode;
  to: FlowNode;
  signed: boolean;
  pressurePushMode: PressurePushMode;
  ignoreCalculatedDefaults?: boolean;
}

export interface GetPressureLossOptionsGlobal extends GetPressureLossOptions {
  object: CoreObjectConcrete;
}

export function getObjectFrictionPressureLossKPA(
  options: GetPressureLossOptionsGlobal,
): PressureLossResult {
  const { object } = options;
  return object.getFrictionPressureLossKPA(options);
}

export type PressureLossPropagation =
  | {
      pressureLossKPA: number;
      isValid: true;
    }
  | {
      pressureLossKPA: number | null;
      isValid: false;
    };

export interface PropagatePressureLossOptions {
  initialPressureKPA: number | null;
  pressureLossResult: PressureLossResult;
}

export function applyPressureLoss(
  options: PropagatePressureLossOptions,
): PressureLossPropagation {
  const { initialPressureKPA: initialPressureLossKPA, pressureLossResult } =
    options;

  if (!isPressureLossSuccess(pressureLossResult)) {
    return {
      pressureLossKPA: null,
      isValid: false,
    };
  }

  let actualPressureLossKPA: number | null = null;
  if (
    typeof pressureLossResult.minPressureKPA === "number" ||
    typeof pressureLossResult.maxPressureKPA === "number"
  ) {
    if (initialPressureLossKPA === null) {
      // for static pressure cases, if the prior pressure is unknown, we can't know the pressure loss.
      return {
        pressureLossKPA: null,
        isValid: false,
      };
    }

    let nextPressureKPA = initialPressureLossKPA;
    nextPressureKPA -= pressureLossResult.pressureLossKPA;

    if (pressureLossResult.minPressureKPA !== undefined) {
      nextPressureKPA = Math.max(
        nextPressureKPA,
        pressureLossResult.minPressureKPA,
      );
    }
    if (pressureLossResult.maxPressureKPA !== undefined) {
      nextPressureKPA = Math.min(
        nextPressureKPA,
        pressureLossResult.maxPressureKPA,
      );
    }

    actualPressureLossKPA = initialPressureLossKPA - nextPressureKPA;
  } else {
    actualPressureLossKPA = pressureLossResult.pressureLossKPA;
  }

  return {
    pressureLossKPA: actualPressureLossKPA,
    isValid: true,
  };
}
