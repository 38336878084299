import { InternalHeatSource } from "../../drawing";

export const INITIAL_INTERNAL_HEATSOURCE: InternalHeatSource = {
  Occupant: { heatSourceName: "Occupant", heatSourceWatts: 100 },
  Lighting: { heatSourceName: "Lighting", heatSourceWatts: 20 },
  Computers: { heatSourceName: "Computers", heatSourceWatts: 100 },
  Laptops: { heatSourceName: "Laptops", heatSourceWatts: 30 },
  Monitors: { heatSourceName: "Monitors", heatSourceWatts: 30 },
  Printers: { heatSourceName: "Printers", heatSourceWatts: 300 },
  Photocopiers: { heatSourceName: "Photocopiers", heatSourceWatts: 1000 },
  "Kitchen stoves": { heatSourceName: "Kitchen stoves", heatSourceWatts: 2000 },
  Oven: { heatSourceName: "Oven", heatSourceWatts: 3000 },
  Microwaves: { heatSourceName: "Microwaves", heatSourceWatts: 1000 },
  Refrigerators: { heatSourceName: "Refrigerators", heatSourceWatts: 200 },
  Freezers: { heatSourceName: "Freezers", heatSourceWatts: 500 },
  "Water coolers": { heatSourceName: "Water coolers", heatSourceWatts: 300 },
  Dishwashers: { heatSourceName: "Dishwashers", heatSourceWatts: 1500 },
  Televisions: { heatSourceName: "Televisions", heatSourceWatts: 100 },
  "Electric fans": { heatSourceName: "Electric fans", heatSourceWatts: 75 },
  "Server racks": { heatSourceName: "Server racks", heatSourceWatts: 5000 },
  "Air handling units": {
    heatSourceName: "Air handling units",
    heatSourceWatts: 3000,
  },
  "Small kitchen appliances": {
    heatSourceName: "Small kitchen appliances",
    heatSourceWatts: 1000,
  },
  "Elevator motors": {
    heatSourceName: "Elevator motors",
    heatSourceWatts: 10000,
  },
};

export type RoomInternalHeatSourceItem = {
  name: string;
  size: number;
  heatSourceRatingWatts: number | undefined;
};

export type RoomInternalHeatSource = {
  [key in string]: RoomInternalHeatSourceItem;
};
