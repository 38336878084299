import Flatten from "@flatten-js/core";
import * as TM from "transformation-matrix";
import { Matrix } from "transformation-matrix";
import { matrixScale } from "../../../common/src/api/coreObjects/lib/utils";
import { Coord } from "../../../common/src/lib/coord";
import { tm2flatten } from "./lib/utils";
import { polygonsOverlap } from "./utils";

export type ViewPortInternals = {
  surfaceToWorld_a: number;
  surfaceToWorld_b: number;
  surfaceToWorld_c: number;
  surfaceToWorld_d: number;
  surfaceToWorld_e: number;
  surfaceToWorld_f: number;
  width: number;
  height: number;
  screenScale: number;
};

/*
 * A transformation specifically for the screen, with a center co-ordinate, rotation, and scale.
 */
export class ViewPort {
  static default() {
    return new ViewPort(
      TM.transform(TM.translate(0, 0), TM.scale(50)),
      5000,
      5000,
    );
  }

  public surfaceToWorld: Matrix;
  public width: number;
  public height: number;
  public screenScale: number;
  public onChange?: (vp: ViewPort) => void;

  constructor(
    position: Matrix,
    width: number,
    height: number,
    screenScale: number = 1,
    hash: string | null = null,
  ) {
    this.width = width;
    this.height = height;
    this.surfaceToWorld = TM.transform(position);
    this.screenScale = screenScale;
    if (hash && hash.startsWith("#loc:")) {
      this.setFromHash(hash);
    }
  }

  get screenToSurface() {
    return TM.transform(
      TM.scale(this.screenScale),
      TM.translate(-this.width / 2, -this.height / 2),
    );
  }

  copy(): ViewPort {
    return new ViewPort(
      this.surfaceToWorld,
      this.width,
      this.height,
      this.screenScale,
    );
  }

  copyInternals(): ViewPortInternals {
    return {
      surfaceToWorld_a: this.surfaceToWorld.a,
      surfaceToWorld_b: this.surfaceToWorld.b,
      surfaceToWorld_c: this.surfaceToWorld.c,
      surfaceToWorld_d: this.surfaceToWorld.d,
      surfaceToWorld_e: this.surfaceToWorld.e,
      surfaceToWorld_f: this.surfaceToWorld.f,
      width: this.width,
      height: this.height,
      screenScale: this.screenScale,
    };
  }

  static makeFromInternals(
    /** the state / internals of the viewport when it was captured */
    internals: ViewPortInternals,
    /** the current width of the HTMLCanvas in the DOM */
    availableWidth: number,
    /** the current height of the HTMLCanvas in the DOM */
    availableHeight: number,
  ): ViewPort {
    const vp = new ViewPort(TM.identity(), 1, 1, 1);
    vp.surfaceToWorld = {
      a: internals.surfaceToWorld_a,
      b: internals.surfaceToWorld_b,
      c: internals.surfaceToWorld_c,
      d: internals.surfaceToWorld_d,
      e: internals.surfaceToWorld_e,
      f: internals.surfaceToWorld_f,
    };
    vp.width = availableWidth;
    vp.height = availableHeight;
    const screenScaleMultiplier = (() => {
      const srcWidth = internals.width;
      const widthRatio = srcWidth / availableWidth;
      const srcHeight = internals.height;
      const heightRatio = srcHeight / availableHeight;
      return Math.max(widthRatio, heightRatio);
    })();
    vp.screenScale = internals.screenScale * screenScaleMultiplier; // making this number larger zooms OUT
    return vp;
  }

  /**
   * Rescales with anchorX and anchorY as screen coordinates
   * Only works if screenToSurface is original scale (lol) but there's currently no use case for otherwise.
   */
  rescale(factor: number, sx: number, sy: number) {
    const world = this.toWorldCoord({ x: sx, y: sy });
    // Move, then scale, then move again
    this.surfaceToWorld = TM.transform(this.surfaceToWorld, TM.scale(factor));
    const world2 = this.toWorldCoord({ x: sx, y: sy });
    this.surfaceToWorld = TM.transform(
      TM.translate(-(world2.x - world.x), -(world2.y - world.y)),
      this.surfaceToWorld,
    );
    this.onChange && this.onChange(this);
  }

  /**
   * Pans the given screen pixels. Note the y coordinates are inverted
   * @param dx
   * @param dy
   */
  panAbs(dsx: number, dsy: number) {
    const world1 = this.toWorldCoord({ x: 0, y: 0 });
    const world2 = this.toWorldCoord({ x: dsx, y: dsy });

    this.surfaceToWorld = TM.transform(
      TM.translate(world2.x - world1.x, world2.y - world1.y),
      this.surfaceToWorld,
    );
    this.onChange && this.onChange(this);
  }

  panToWc(point: Coord) {
    const toCenter = TM.applyToPoint(TM.inverse(this.surfaceToWorld), point);
    this.panAbs(toCenter.x, toCenter.y);
  }

  /**
   * Take a world coordinate and project to the screen
   */
  toScreenCoord(world: Coord): Coord {
    const inv: Matrix = TM.inverse(
      TM.transform(this.surfaceToWorld, this.screenToSurface),
    );
    return TM.applyToPoint(inv, world);
  }

  toSurfaceCoord(world: Coord): Coord {
    const inv: Matrix = TM.inverse(TM.transform(this.surfaceToWorld));
    return TM.applyToPoint(inv, world);
  }

  toScreenLength(worldLen: number): number {
    return (
      worldLen /
      matrixScale(this.surfaceToWorld) /
      matrixScale(this.screenToSurface)
    );
  }

  toSurfaceLength(worldLen: number): number {
    return worldLen / matrixScale(this.surfaceToWorld);
  }

  surfaceToWorldLength(surfaceLen: number): number {
    return surfaceLen * matrixScale(this.surfaceToWorld);
  }

  toWorldLength(screenLen: number): number {
    return (
      screenLen *
      matrixScale(this.screenToSurface) *
      matrixScale(this.surfaceToWorld)
    );
  }

  /**
   * Prepares the context so that drawing to it with real world coordinates will draw to screen
   * appropriately.
   *
   */
  prepareContext(ctx: CanvasRenderingContext2D, ...transform: Matrix[]) {
    const m =
      transform.length > 0
        ? TM.transform(
            TM.inverse(this.screenToSurface),
            TM.inverse(this.surfaceToWorld),
            ...transform,
          )
        : TM.transform(
            TM.inverse(this.screenToSurface),
            TM.inverse(this.surfaceToWorld),
          );
    ctx.setTransform(m);
  }

  get world2ScreenMatrix(): Matrix {
    return TM.transform(
      TM.inverse(this.screenToSurface),
      TM.inverse(this.surfaceToWorld),
    );
  }

  get screen2worldMatrix(): Matrix {
    return TM.transform(this.surfaceToWorld, this.screenToSurface);
  }

  /**
   * Takes a screen coordinate and finds the world coordinate
   */
  toWorldCoord(screen: Coord): Coord {
    return TM.applyToPoint(
      TM.transform(this.surfaceToWorld, this.screenToSurface),
      screen,
    );
  }

  someOnScreen(
    worldShape:
      | Flatten.Polygon
      | Flatten.Segment
      | Flatten.Point
      | Flatten.Circle,
  ): boolean {
    const screen = this.screenWorldShape;
    if (worldShape instanceof Flatten.Polygon) {
      return polygonsOverlap(screen, worldShape);
    } else if (worldShape instanceof Flatten.Segment) {
      return (
        screen.contains(worldShape) || screen.intersect(worldShape).length > 0
      );
    } else if (worldShape instanceof Flatten.Point) {
      return screen.contains(worldShape);
    } else if (worldShape instanceof Flatten.Circle) {
      return screen.contains(worldShape.center); // TODO: boundaries if we really need dat
    } else {
      throw new Error("unknown shape");
    }
  }

  get screenWorldShape(): Flatten.Polygon {
    const screenBox = new Flatten.Box(0, 0, this.width, this.height);
    let p = new Flatten.Polygon();
    p.addFace(screenBox);
    p = p.transform(tm2flatten(TM.inverse(this.world2ScreenMatrix)));
    return p;
  }

  currToScreenScale(ctx: CanvasRenderingContext2D): number {
    return matrixScale(this.currToScreenTransform(ctx));
  }

  currToSurfaceScale(ctx: CanvasRenderingContext2D): number {
    return matrixScale(this.currToSurfaceTransform(ctx));
  }

  currToScreenTransform(ctx: CanvasRenderingContext2D): Matrix {
    return ctx.getTransform();
  }

  currToSurfaceTransform(ctx: CanvasRenderingContext2D) {
    return TM.transform(ctx.getTransform(), this.screenToSurface);
  }

  resetCtxTransformToScreen(ctx: CanvasRenderingContext2D) {
    ctx.resetTransform();
  }

  toHash(levelUid: string): `loc:${string}` {
    const topLeft = this.toWorldCoord({ x: 0, y: 0 });
    const bottomRight = this.toWorldCoord({ x: this.width, y: this.height });

    return `loc:${[
      levelUid,
      topLeft.x.toFixed(0),
      topLeft.y.toFixed(0),
      (bottomRight.x - topLeft.x).toFixed(0),
      (bottomRight.y - topLeft.y).toFixed(0),
    ].join(",")}`;
  }

  // returns the level UID as well since it's encoded in the loc: style hashes
  setFromHash(hash: string): { levelUid: string; panSuccess: boolean } {
    const parts = hash.split(";")[0].split(":")[1].split(",");
    const levelUid = parts[0];
    if (parts.slice(1).some((x) => isNaN(parseFloat(x)))) {
      return { levelUid, panSuccess: false };
    }
    const topLeft = {
      x: parseFloat(parts[1]),
      y: parseFloat(parts[2]),
    };
    const width = parseFloat(parts[3]);
    const height = parseFloat(parts[4]);

    const middle = {
      x: topLeft.x + width / 2,
      y: topLeft.y + height / 2,
    };

    if (isNaN(topLeft.x) || isNaN(topLeft.y) || isNaN(width) || isNaN(height)) {
      return { levelUid, panSuccess: false };
    } else {
      this.panToWc(middle);

      const currWidth = this.toWorldLength(this.width);

      this.rescale(width / currWidth, this.width / 2, this.height / 2);

      return { levelUid, panSuccess: true };
    }
  }
}
