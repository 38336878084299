import axios from "axios";
import { AllUserProperties } from "../../../common/src/api/allUserProperties";
import { APIResult } from "./../../../common/src/api/document/types";

// The types work as well as you have hoped!
export async function getOwnUserProperties<T extends keyof AllUserProperties>(
  fields: T[],
): Promise<APIResult<Partial<{ [key in T]: AllUserProperties[key] }>>> {
  try {
    return (
      await axios.get("/api/userProperties/own", {
        params: { key: fields },
      })
    ).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function setOwnUserProperties(
  properties: Partial<AllUserProperties>,
): Promise<APIResult<void>> {
  try {
    return (await axios.post("/api/userProperties/own", properties)).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}
