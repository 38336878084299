import assert from "assert";
import { percentString } from "../../../lib/mathUtils/mathutils";
import { Units, UnitsContext } from "../../../lib/measurements";
import PlantCalculation, {
  ManifoldLoopStat,
} from "../../document/calculations-objects/plant-calculation";
import { RoomCalculation } from "../../document/calculations-objects/room-calculation";
import { UnitsParameters } from "../../document/drawing";
import PlantEntity from "../../document/entities/plants/plant-entity";
import { PlantType } from "../../document/entities/plants/plant-types";
import { isManifoldPlant } from "../../document/entities/plants/utils";
import {
  HeatEmitterSpecType,
  HeatEmitterSpecs,
  createEntryWithUnit,
} from "./heat-loss-result-type";

export type HeatEmitterResultInputs = {
  uid: string;
  roomUid: string | null;
  name: string;
  width: number;
  height: number;
  type: PlantType;
  entity: PlantEntity;
  calc: PlantCalculation;
  roomCalc: RoomCalculation;
  returnAverage: number;
  returnDelta: number;
  fiftyDelta: number | null;
  heatingRatingWatt: number;
  outletUid: string | null;
  targetDemandWatt: number;
  flowRateLS: number;
  loopId: string | undefined;
  heatingAreaM2: number | null;
};

export function getHeatEmitterResult(
  units: UnitsParameters,
  heatEmitter: HeatEmitterResultInputs,
): { specs: HeatEmitterSpecs[]; ratingKW: number } {
  return {
    specs: getHeatEmitterSpec(units, heatEmitter),
    ratingKW: heatEmitter.heatingRatingWatt / 1000,
  };
}

function getHeatEmitterSpec(
  units: UnitsParameters,
  heatEmitter: HeatEmitterResultInputs,
): HeatEmitterSpecs[] {
  switch (heatEmitter.type) {
    case "RADIATOR":
      return [getBasicHeatEmitterResult(units, heatEmitter, "RAD")];
    case "MANIFOLD":
      const plant = heatEmitter.entity.plant;
      assert(isManifoldPlant(plant));
      if (plant.ufhMode === "approximate") {
        return [getBasicHeatEmitterResult(units, heatEmitter, "UFH")];
      } else {
        return heatEmitter.calc.manifoldLoopStats
          .filter((l) => l.roomUid === heatEmitter.roomUid)
          .map((l) => getLoopHeatEmitterResult(units, heatEmitter, "UFH", l));
      }
    case "AHU_VENT":
      return [getBasicHeatEmitterResult(units, heatEmitter, "AHU")];
    default:
      console.warn("Unknown heat emitter type: " + heatEmitter.type);
      return [];
  }
}

function getLoopHeatEmitterResult(
  units: UnitsParameters,
  heatEmitter: HeatEmitterResultInputs,
  type: HeatEmitterSpecType,
  loop: ManifoldLoopStat,
): HeatEmitterSpecs {
  const overriddenInputs: HeatEmitterResultInputs = {
    ...heatEmitter,
    loopId: loop.loopId,
    flowRateLS: loop.flowRateLS ?? heatEmitter.flowRateLS,
    heatingAreaM2: loop.heatingAreaM2,
    returnAverage:
      heatEmitter.roomCalc.underfloorHeating.meanWaterTempC ??
      heatEmitter.returnAverage,
    returnDelta:
      heatEmitter.roomCalc.underfloorHeating.deltaT ?? heatEmitter.returnDelta,
    heatingRatingWatt: loop.heatOutputW ?? heatEmitter.heatingRatingWatt,
  };

  return getBasicHeatEmitterResult(units, overriddenInputs, type);
}

function getBasicHeatEmitterResult(
  units: UnitsParameters,
  heatEmitter: HeatEmitterResultInputs,
  type: HeatEmitterSpecType,
): HeatEmitterSpecs {
  return {
    type,
    uid: heatEmitter.uid,
    name: heatEmitter.name,
    outletUid: heatEmitter.outletUid ?? "",
    loopId: heatEmitter.loopId,
    heatingAreaM2: heatEmitter.heatingAreaM2,
    width: createEntryWithUnit(units, heatEmitter.width, Units.Millimeters),
    height: createEntryWithUnit(units, heatEmitter.height, Units.Millimeters),
    returnAverage: createEntryWithUnit(
      units,
      heatEmitter.returnAverage,
      Units.Celsius,
      1,
    ),
    returnDelta: createEntryWithUnit(
      units,
      heatEmitter.returnDelta,
      Units.Celsius,
      1,
    ),
    fiftyDelta: heatEmitter.fiftyDelta
      ? createEntryWithUnit(
          units,
          heatEmitter.fiftyDelta,
          Units.Watts,
          1,
          UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
        )
      : "-",
    heatingRating: createEntryWithUnit(
      units,
      heatEmitter.heatingRatingWatt,
      Units.Watts,
      0,
      UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
    ),
    flowRate: createEntryWithUnit(
      units,
      heatEmitter.flowRateLS,
      Units.LitersPerSecond,
      2,
    ),
    demandMet: percentString(
      heatEmitter.heatingRatingWatt,
      heatEmitter.targetDemandWatt,
    ),
    _fiftyDeltaWatt: heatEmitter.fiftyDelta,
    _heatingRatingWatt: heatEmitter.heatingRatingWatt,
    _flowRateLS: heatEmitter.flowRateLS,
  } satisfies HeatEmitterSpecs;
}
