import { ActionTree } from "vuex";
import { Catalog } from "../../../../common/src/api/catalog/types";
import CatalogState from "../../../src/store/catalog/types";
import { RootState } from "../../../src/store/types";
import { loadCatalog } from "../../api/catalog";
import store from "../store";

export const actions: ActionTree<CatalogState, RootState> = {
  setDefault({ commit }, payload: Catalog) {
    commit("setDefault", payload);
    commit("setLoaded", true);
  },

  reset({ commit }) {
    commit("setDefault", undefined);
    commit("setLoaded", false);
  },

  async load(
    { commit },
    payload: { documentId: number } | { shareToken: string },
  ) {
    const result = await loadCatalog(payload);
    if (result.success) {
      commit("setDefault", result.data.catalog);
      commit("setUpgradeCount", result.data.upgradeCount);
      commit("setLoaded", true);
      return {
        success: true,
      };
    } else {
      commit("setDefault", undefined);
      commit("setLoaded", false);
      return {
        success: false,
        message: result.message,
      };
    }
  },

  async reload({ commit }, documentId: number) {
    const result = await loadCatalog({ documentId });
    if (result.success) {
      commit("setDefault", result.data.catalog);
      commit("setUpgradeCount", result.data.upgradeCount);
      commit("setLoaded", true);
      store.dispatch("document/replaceCatalog");
      return {
        success: true,
      };
    } else {
      commit("setDefault", undefined);
      commit("setLoaded", false);
      return {
        success: false,
        message: result.message,
      };
    }
  },
};
