import { CoreContext } from "../../calculations/types";
import { VertexEntity } from "../entities/vertices/vertex-entity";
import { CalculationField } from "./calculation-field";
import {
  CalcFieldSelector,
  Calculation,
  CalculationType,
  LiveCalculation,
} from "./types";

export default interface VertexCalculation extends Calculation {
  type: CalculationType.VertexCalculation;
  // TODO: Add fields
}

export interface VertexLiveCalculation extends LiveCalculation {}

export const FastLiveVertexCalculationFields: CalcFieldSelector<VertexLiveCalculation> =
  {
    connected: true,
  };

export const StickyVertexCalcualtionFields: CalcFieldSelector<VertexCalculation> =
  {};

export function makeVertexCalculationFields(
  context: CoreContext,
  entity: VertexEntity,
): CalculationField[] {
  return [];
}

export function emptyVertexLiveCalculation(): VertexLiveCalculation {
  return {
    connected: null,
    warnings: [],
  };
}

export function emptyVertexCalculation(): VertexCalculation {
  return {
    warnings: [],
    costBreakdown: null,
    expandedEntities: null,
    type: CalculationType.VertexCalculation,
    cost: null,
  };
}
