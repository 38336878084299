import Flatten from "@flatten-js/core";
import { CoreObjectConcrete } from ".";
import { assertUnreachable } from "../../lib/utils";
import {
  CoreContext,
  CostBreakdown,
  PressureLossResult,
} from "../calculations/types";
import { CalculationConcrete } from "../document/calculations-objects/calculation-concrete";
import {
  AnnotationEntity,
  fillDefaultAnnotationFields,
} from "../document/entities/annotations/annotation-entity";
import { CalculatableEntityConcrete } from "../document/entities/concrete-entity";
import { EntityType } from "../document/entities/types";
import CoreCentered from "./core-traits/coreCentered";
import CoreBaseBackedObject from "./lib/coreBaseBackedObject";
import { SelectionTarget } from "./lib/types";

const Base = CoreCentered(CoreBaseBackedObject<AnnotationEntity>);

export default class CoreAnnotation extends Base {
  type: EntityType.ANNOTATION = EntityType.ANNOTATION;

  getCalculationUid(context: CoreContext): string {
    return this.entity.uid;
  }

  preCalculationValidation(context: CoreContext): SelectionTarget | null {
    return null;
  }

  getCoreNeighbours(): CoreObjectConcrete[] {
    return [];
  }

  getFrictionPressureLossKPA(): PressureLossResult {
    throw new Error("Annotation entity does not have head loss");
  }

  getCalculationEntities(context: CoreContext): CalculatableEntityConcrete[] {
    return [];
  }

  collectCalculations(context: CoreContext): CalculationConcrete {
    throw new Error("Not applicable to annotation entity");
  }

  costBreakdown(context: CoreContext): CostBreakdown | null {
    return null;
  }

  get shape() {
    const filled = fillDefaultAnnotationFields(this.context, this.entity);

    switch (this.entity.annoType) {
      case "box":
        const p = new Flatten.Polygon();
        const l = -filled.anno.widthMM! / 2;
        const r = filled.anno.widthMM! / 2;
        const t = -filled.anno.heightMM! / 2;
        const b = filled.anno.heightMM! / 2;

        const tl = this.toWorldCoord({ x: l, y: t });
        const tr = this.toWorldCoord({ x: r, y: t });
        const bl = this.toWorldCoord({ x: l, y: b });
        const br = this.toWorldCoord({ x: r, y: b });
        const tlp = Flatten.point(tl.x, tl.y);
        const trp = Flatten.point(tr.x, tr.y);
        const blp = Flatten.point(bl.x, bl.y);
        const brp = Flatten.point(br.x, br.y);

        p.addFace([
          Flatten.segment(tlp, trp),
          Flatten.segment(trp, brp),
          Flatten.segment(brp, blp),
          Flatten.segment(blp, tlp),
        ]);

        return p;
      default:
        assertUnreachable(this.entity.annoType);
    }

    return new Flatten.Polygon();
  }
}
