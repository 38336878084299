import { GetterTree } from "vuex";
import { buildNodes } from "../../../../common/src/api/context";
import { RootState } from "../types";
import CustomEntityState from "./types";

export const getters: GetterTree<CustomEntityState, RootState> = {
  nodes(state) {
    return buildNodes(state.nodes || []);
  },
  loaded(state) {
    return state.loaded;
  },
};
