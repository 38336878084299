import { getServiceWorkerUrl } from "./webworker-utils";

// generated by chat-gpt

export const activateServiceWorker = async () => {
  if (!navigator.serviceWorker) {
    return alert(
      "Service Workers are not supported in this browser. Please use a more modern browser like Google Chrome.",
    );
  }

  const registration = await navigator.serviceWorker.register(
    getServiceWorkerUrl(),
    { scope: "/" },
  );
  // console.log("Service Worker registered with scope:", registration.scope);

  if (registration.active) {
    // console.log("Service Worker already active");
    return;
  }

  await new Promise<void>((resolve) => {
    const onControllerChange = () => {
      if (registration.active) {
        // console.log("service worker onControllerChange, active");
        return resolve();
      }
      // console.log("service worker onControllerChange, not active");
    };

    navigator.serviceWorker.addEventListener(
      "controllerchange",
      onControllerChange,
    );

    const onInstallationStateChange = () => {
      if (registration.active) {
        // console.log("Service worker onInstallationStateChange, active");
        registration.installing?.removeEventListener(
          "statechange",
          onInstallationStateChange,
        );
        return resolve();
      }
      // console.log("Service worker onInstallationStateChange, not active");
    };

    const onWaitingStateChange = () => {
      if (registration.active) {
        // console.log("Service worker onWaitingStateChange, active");
        registration.waiting?.removeEventListener(
          "statechange",
          onWaitingStateChange,
        );
        return resolve();
      }
      // console.log("Service worker onWaitingStateChange, not active");
    };

    if (registration.installing || registration.installing) {
      if (registration.installing) {
        registration.installing.addEventListener(
          "statechange",
          onInstallationStateChange,
        );
      }
      if (registration.waiting) {
        registration.waiting.addEventListener(
          "statechange",
          onWaitingStateChange,
        );
      }
    } else {
      navigator.serviceWorker.removeEventListener(
        "controllerchange",
        onControllerChange,
      );
      resolve();
    }
  });

  // console.log("Service Worker is registered, activated, and running");
};
