import { AnalyticsPayload } from "../api/document/analytics/analytics";

export var TRACKER: AnalyticsTracker | null = null;

export function setTracker(tracker: AnalyticsTracker): void {
  TRACKER = tracker;
}

export interface AnalyticsTracker {
  trackEvent: (payload: AnalyticsPayload) => void;
}
