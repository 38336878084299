
import Vue from "vue";
import Component from "vue-class-component";
import {
  defaultPasswordRequirements,
  OrganizationType,
} from "../../../common/src/models/Organization";
import { AccessLevel, User } from "../../../common/src/models/User";
import ProfileMenuItem from "../../src/components/ProfileMenuItem.vue";

@Component({
  components: { ProfileMenuItem },
})
export default class MainNavBar extends Vue {
  emailVerificationSent: boolean = false;
  isLoading: boolean = false;
  form: {
    confirmEmail: string;
    isLoading: boolean;
  } = {
    confirmEmail: "",
    isLoading: false,
  };

  get profile(): User {
    const profile = this.$store.getters["profile/profile"];
    if (!profile) {
      return {
        username: "",
        accessLevel: AccessLevel.USER,
        organization: {
          id: "",
          name: "",
          type: OrganizationType.BUILDING_SERVICES,
          passwordRequirements: defaultPasswordRequirements,
        },
        email: "",
        subscribed: false,
        name: "",
        passwordHash: "",
        eulaAcceptedOn: null,
        lastActivityOn: null,
        lastNoticeSeenOn: null,
        temporaryOrganizationName: null,
        temporaryUser: false,
      } as User;
    } else {
      return profile;
    }
  }

  get isAdmin() {
    return this.profile.accessLevel <= AccessLevel.ADMIN;
  }

  get isManufacturer() {
    return this.profile.organization?.type === OrganizationType.MANUFACTURER;
  }

  get isBuildingServices() {
    return (
      this.profile.organization?.type === OrganizationType.BUILDING_SERVICES
    );
  }
}
