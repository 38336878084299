import _ from "lodash";
import { GetterTree } from "vuex";
import { Catalog } from "../../../../common/src/api/catalog/types";
import { Choice } from "../../../../common/src/lib/utils";
import CatalogState from "../../../src/store/catalog/types";
import { RootState } from "../../../src/store/types";

export const getters: GetterTree<CatalogState, RootState> = {
  loaded(state) {
    return state.loaded;
  },

  default(state) {
    return state.defaultCatalog;
  },

  upgradeCount(state) {
    return state.upgradeCount;
  },

  defaultValveChoices(state) {
    const result: Choice[] = [];
    _.forOwn(state.defaultCatalog.valves, (val, key) => {
      result.push({ key, name: val.name });
    });
    return result;
  },
};

export function getPipeMaterialChoices(catalog: Catalog) {
  const result: Choice[] = [];
  for (const [key, val] of Object.entries(catalog.pipes)) {
    result.push({ key, name: val.name });
  }
  return result;
}
