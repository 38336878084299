// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import { CanvasMouseEventRecord } from "./canvas-mouse-event-record";
import { CanvasMouseMovementRecord } from "./canvas-mouse-movement-record";
import { DiffRecord } from "./diff-record";
import { KeyboardEventRecord } from "./keyboard-event-record";
import { LevelChangeRecord } from "./level-change-record";
import { ToolEventRecord } from "./tool-event-record";
import { ViewPortRecord } from "./view-port-record";

export enum ReplayEventUnion {
  NONE = 0,
  CanvasMouseMovementRecord = 1,
  ViewPortRecord = 2,
  DiffRecord = 3,
  KeyboardEventRecord = 4,
  LevelChangeRecord = 5,
  CanvasMouseEventRecord = 6,
  ToolEventRecord = 7,
}

export function unionToReplayEventUnion(
  type: ReplayEventUnion,
  accessor: (
    obj:
      | CanvasMouseEventRecord
      | CanvasMouseMovementRecord
      | DiffRecord
      | KeyboardEventRecord
      | LevelChangeRecord
      | ToolEventRecord
      | ViewPortRecord,
  ) =>
    | CanvasMouseEventRecord
    | CanvasMouseMovementRecord
    | DiffRecord
    | KeyboardEventRecord
    | LevelChangeRecord
    | ToolEventRecord
    | ViewPortRecord
    | null,
):
  | CanvasMouseEventRecord
  | CanvasMouseMovementRecord
  | DiffRecord
  | KeyboardEventRecord
  | LevelChangeRecord
  | ToolEventRecord
  | ViewPortRecord
  | null {
  switch (ReplayEventUnion[type]) {
    case "NONE":
      return null;
    case "CanvasMouseMovementRecord":
      return accessor(
        new CanvasMouseMovementRecord(),
      )! as CanvasMouseMovementRecord;
    case "ViewPortRecord":
      return accessor(new ViewPortRecord())! as ViewPortRecord;
    case "DiffRecord":
      return accessor(new DiffRecord())! as DiffRecord;
    case "KeyboardEventRecord":
      return accessor(new KeyboardEventRecord())! as KeyboardEventRecord;
    case "LevelChangeRecord":
      return accessor(new LevelChangeRecord())! as LevelChangeRecord;
    case "CanvasMouseEventRecord":
      return accessor(new CanvasMouseEventRecord())! as CanvasMouseEventRecord;
    case "ToolEventRecord":
      return accessor(new ToolEventRecord())! as ToolEventRecord;
    default:
      return null;
  }
}

export function unionListToReplayEventUnion(
  type: ReplayEventUnion,
  accessor: (
    index: number,
    obj:
      | CanvasMouseEventRecord
      | CanvasMouseMovementRecord
      | DiffRecord
      | KeyboardEventRecord
      | LevelChangeRecord
      | ToolEventRecord
      | ViewPortRecord,
  ) =>
    | CanvasMouseEventRecord
    | CanvasMouseMovementRecord
    | DiffRecord
    | KeyboardEventRecord
    | LevelChangeRecord
    | ToolEventRecord
    | ViewPortRecord
    | null,
  index: number,
):
  | CanvasMouseEventRecord
  | CanvasMouseMovementRecord
  | DiffRecord
  | KeyboardEventRecord
  | LevelChangeRecord
  | ToolEventRecord
  | ViewPortRecord
  | null {
  switch (ReplayEventUnion[type]) {
    case "NONE":
      return null;
    case "CanvasMouseMovementRecord":
      return accessor(
        index,
        new CanvasMouseMovementRecord(),
      )! as CanvasMouseMovementRecord;
    case "ViewPortRecord":
      return accessor(index, new ViewPortRecord())! as ViewPortRecord;
    case "DiffRecord":
      return accessor(index, new DiffRecord())! as DiffRecord;
    case "KeyboardEventRecord":
      return accessor(index, new KeyboardEventRecord())! as KeyboardEventRecord;
    case "LevelChangeRecord":
      return accessor(index, new LevelChangeRecord())! as LevelChangeRecord;
    case "CanvasMouseEventRecord":
      return accessor(
        index,
        new CanvasMouseEventRecord(),
      )! as CanvasMouseEventRecord;
    case "ToolEventRecord":
      return accessor(index, new ToolEventRecord())! as ToolEventRecord;
    default:
      return null;
  }
}
