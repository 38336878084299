import pjson from "../package.json";

const version = pjson ? (pjson.version ?? "") : "";

export const getCalcsWebworkerUrl = () => {
  return "/js/webworkers/calcs" + version + ".js";
};

export const getLiveCalcsWebworkerUrl = () => {
  return "/js/webworkers/live-calcs" + version + ".js";
};

export const getServiceWorkerUrl = () => {
  return "/service-worker" + version + ".js";
};
