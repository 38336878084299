import Vue from "vue";
import { ActionTree } from "vuex";
import { AllUserProperties } from "../../../../common/src/api/allUserProperties";
import { SupportedLocales } from "../../../../common/src/api/locale";
import { User } from "../../../../common/src/models/User";
import ProfileState from "../../../src/store/profile/types";
import { CommandAi } from "../../api/integrations/CommandAi";
import { Mixpanel } from "../../api/integrations/Mixpanel";
import { Pendo } from "../../api/integrations/Pendo";
import { Sentry } from "../../api/integrations/Sentry";
import {
  getOwnUserProperties,
  setOwnUserProperties,
} from "../../api/user-properties";
import {
  ENABLE_COMMAND_AI,
  ENABLE_MIXPANEL,
  ENABLE_PENDO,
  ENABLE_SENTRY,
} from "../../config";
import { useFeatureAccess } from "../../lib/hooks/use-feature-access";
import { RootState } from "../types";

export const actions: ActionTree<ProfileState, RootState> = {
  async setProfile({ commit, dispatch }, profile: User) {
    commit("setProfile", profile);
    commit("clearProperties");

    if (profile) {
      if (ENABLE_PENDO) {
        Pendo.init(profile);
      }

      if (ENABLE_SENTRY) {
        Sentry.updateUser(profile, useFeatureAccess());
      }

      if (ENABLE_COMMAND_AI) {
        CommandAi.init(profile);
      }

      if (ENABLE_MIXPANEL) {
        Mixpanel.init(profile, useFeatureAccess());
      }
    }

    dispatch("featureFlags/identifyUser", { root: true });
  },
  // Gets from cache or fetches from server the user properties.
  // eg:
  // const { examplePreference } = await this.$store.dispatch("profile/getProperties", [
  //     "examplePreference",
  //   ]);
  // }
  async getProperties({ commit, state }, fields: (keyof AllUserProperties)[]) {
    let res: () => void = () => {};
    const p = new Promise<void>((r) => {
      res = r;
    });
    const missingProperties = fields.filter(
      (field) =>
        state.properties[field] === undefined &&
        state.inFlightProperties[field] === undefined,
    );

    for (const field of missingProperties) {
      Vue.set(state.inFlightProperties, field, p);
    }

    if (missingProperties.length > 0) {
      const res = await getOwnUserProperties(missingProperties);
      if (res.success) {
        commit("setProperties", res.data);
      } else {
        throw new Error(res.message);
      }
    }

    for (const field of missingProperties) {
      Vue.delete(state.inFlightProperties, field);
    }
    res();

    // Wait for the properties that we didn't fetch - because earlier
    // calls to this function were already fetching them - to resolve.
    await Promise.all(
      fields.map((field) => state.inFlightProperties[field]).filter(Boolean),
    );

    // Return the properties object directly so that vue will watch it
    // when the caller goes `properties.xyz` and `xyz` changes.
    return state.properties;
  },

  // Same as getProperties, but it always fetches from the server.
  // This doesn't update or require inFlightProperties because it's
  // usage should be more deliberate one-off calls.
  async fetchProperties(
    { commit, state },
    fields: (keyof AllUserProperties)[],
  ) {
    const res = await getOwnUserProperties(fields);
    if (res.success) {
      commit("setProperties", res.data);
    } else {
      throw new Error(res.message);
    }

    // Return the properties object directly so that vue will watch it
    // when the caller goes `properties.xyz` and `xyz` changes.
    return state.properties;
  },

  async setProperties({ commit }, properties: Partial<AllUserProperties>) {
    const res = await setOwnUserProperties(properties);
    if (!res.success) {
      throw new Error(res.message);
    }

    commit("setProperties", properties);
  },

  setLocale({ commit }, payload: SupportedLocales) {
    commit("setLocale", payload);
  },
};
