import { Manufacturer } from "../types";

export type HeatLoadSpec = {
  material: HeatLoadMaterialSpec;
  airChangeRate: AirChangeRateSpec;
  roomTemperatureC: RoomTemperatureSpec;
  chimneySpec: ChimneySpec;
};

export type HeatLoadSpecFiltered = {
  material: HeatLoadMaterialSpec;
  ventAirChangeRate: AirChangeRateSpecData;
  heatingAirChangeRate: AirChangeRateSpecData;
  roomTemperatureC: RoomTemperatureSpecData;
  chimneySpec: ChimneySpec;
};

export enum HeatLoadMaterialRoleType {
  WALL = "wall",
  WINDOW = "window",
  ROOF = "roof",
  DOOR = "door",
  FLOOR = "floor",
}

export type HeatLoadItem =
  | "External Wall"
  | "Internal Wall"
  | "Party Wall"
  | "Window"
  | "External Door"
  | "Internal Door"
  | "Roof"
  | "Bottom Floor"
  | "Suspended Floor"
  | "Party Floor";

export type HeatLoadBase = {
  type: HeatLoadMaterialRoleType;
  table: HeatLossMaterialTable;
};

export type HeatLossMaterialManufacturer = Manufacturer<string>;

export type HeatLossMaterialSpec = {
  name: string;
  uid: string;
  abbreviation: string;
  manufacturer: HeatLossMaterialManufacturer[];
  thermal_transmittance_W_per_m2K: number;
};

export type HeatLossMaterialTable = {
  [key: string]: HeatLossMaterialSpec;
};

export type HeatLoadMaterialSpec = {
  [key in HeatLoadItem]: HeatLoadBase;
};

export enum SpaceType {
  Lounge = "Lounge",
  Living = "Living",
  Breakfast = "Breakfast",
  Dining = "Dining",
  Kitchen = "Kitchen",
  Family = "Family",
  Hall = "Hall",
  CloaksWC = "Cloaks/WC",
  Toilet = "Toilet",
  Utility = "Utility",
  Study = "Study",
  Games = "Games",
  Bedroom = "Bedroom",
  Bedsitting = "Bedsitting",
  BedEnsuite = "Bed & Ensuite",
  Internal = "Internal",
  BedStudy = "Bed/Study",
  Landing = "Landing",
  Bath = "Bath",
  Shower = "Shower",
  Dressing = "Dressing",
  Store = "Store",
  General = "General",
  Bathroom = "Bathroom",
  HotelBathroom = "Hotel Bathroom",
  ChangingRooms = "Changing Rooms",
  LivingRoom = "Living Room",
  AssemblyHalls = "Assembly Halls",
  Dwelling = "Dwelling",
  BackOfHouse = "Back of House",
  Laboratory = "Laboratory",
  OperatingTheatre = "Operating Theatre",
  HospitalRecoveryRoom = "Hospital Recovery Room",
  HospitalTreatmentRoom = "Hospital Treatment Room",
  SwimmingPoolHall = "Swimming Pool Hall",
  Darkroom = "Darkroom",
  Horticulture = "Horticulture",
  SquashCourt = "Squash Court",
  IceRink = "Ice Rink",
  Office = "Office",
  OfficeCommonSpace = "Office Common Space",
  Kitchenette = "Kitchenette",
  PrintRoom = "Print Room",
  CarPark = "Car Park",
  BasementCarPark = "Basement Car Park",
  CarParkQueueArea = "Car Park Queue Area",
  AnimalFacility = "Animal Facility",
  CorrectionalFacility = "Correctional Facility",
  ArtRoom = "Art Room",
  Classroom = "Classroom",
  Library = "Library",
  LectureHall = "Lecture Hall",
  CommercialKitchen = "Commercial Kitchen",
  Bar = "Bar",
  MeetingRoom = "Meeting Room",
  CommercialLaundry = "Commercial Laundry",
  Laundry = "Laundry",
  Corridor = "Corridor",
  ComputerRoom = "Computer Room",
  Manufacturing = "Manufacturing",
  Pharmacy = "Pharmacy",
  PhotoStudio = "Photo Studio",
  Warehouse = "Warehouse",
  Auditorium = "Auditorium",
  Museum = "Museum",
  ReligiousWorship = "Religious Worship",
  Barbershop = "Barbershop",
  Salon = "Salon",
  Mall = "Mall",
  Supermarket = "Supermarket",
  Retail = "Retail",
  Gym = "Gym",
  BowlingAlley = "Bowling Alley",
  DanceFloor = "Dance Floor",
  Casino = "Casino",
  Arcade = "Arcade",
  SportsArena = "Sports Arena",
  Trash = "Trash",
  Cafe = "Cafe",
  ClassroomAbove16 = "Classroom (Above 16)",
  ClassroomBelow16 = "Classroom (Below 16)",
  ConferenceRoomLarge = "Conference Room (Large)",
  ConferenceRoomSmall = "Conference Room (Small)",
  DiningRoom = "Dining Room",
  Dormitory = "Dormitory",
  Lobby = "Lobby",
  Restroom = "Restroom",
  StoreRoom = "Store Room",
  WaitingArea = "Waiting Area",
  BeautySalon = "Beauty Salon",
  Chapel = "Chapel",
  ConsultationRoom = "Consultation Room",
  ExerciseRoom = "Exercise Room",
  FastFoodOutlet = "Fast Food Outlet",
  FittingRoom = "Fitting Room",
  Florist = "Florist",
  Foyer = "Foyer",
  GamesRoom = "Games Room",
  HealthSpa = "Health Spa",
  HighActivityArea = "High Activity Area",
  LowActivityArea = "Low Activity Area",
  MediumActivityArea = "Medium Activity Area",
  MusicRoom = "Music Room",
  NightClub = "Night Club",
  PatientRoom = "Patient Room",
  PhysicalTherapyRoom = "Physical Therapy Room",
  SalesFloorGround = "Sales Floor (Ground)",
  SalesFloorUpper = "Sales Floor (Upper)",
  Studio = "Studio",
  TrainingShop = "Training Shop",
  AirportGates = "Airport Gates",
  AirportHangars = "Airport Hangars",
  Amphitheatres = "Amphitheatres",
  AnimalRoom = "Animal Room",
  AutopsyRoom = "Autopsy Room",
  Ballroom = "Ballroom",
  Bank = "Bank",
  Brothel = "Brothel",
  Cabarets = "Cabarets",
  CameraRoom = "Camera Room",
  ChangeRoom = "Change Room",
  ConcertHall = "Concert Hall",
  Concourses = "Concourses",
  DeliveryRoom = "Delivery Room",
  DressingRoom = "Dressing Room",
  EatingHall = "Eating Hall",
  ElectricalRoom = "Electrical Room",
  FoodPrep = "Food Prep",
  GarbageRoom = "Garbage Room",
  Greenhouse = "Greenhouse",
  Hairdresser = "Hairdresser",
  IntensiveCareRoom = "Intensive Care Room",
  Kiosk = "Kiosk",
  LaundryCoinOperated = "Laundry (Coin Operated)",
  LaundryStorage = "Laundry (Storage)",
  MeatProcessing = "Meat Processing",
  OperatingRoom = "Operating Room",
  PlantRoom = "Plant Room",
  PrintingRoom = "Printing Room",
  PrisonCell = "Prison Cell",
  PrisonGuardStation = "Prison Guard Station",
  ProcedureArea = "Procedure Area",
  RecoveryRoom = "Recovery Room",
  SpectatorArea = "Spectator Area",
  SportsPlayingFloor = "Sports Playing Floor",
  Stairs = "Stairs",
  Suite = "Suite",
  SwimmingPoolArea = "Swimming Pool Area",
  TicketAreas = "Ticket Areas",
  UtilityRoom = "Utility Room",
}

export function hasBedroom(spaceType: string): boolean {
  return (
    spaceType === SpaceType.Bedroom ||
    spaceType === SpaceType.BedEnsuite ||
    spaceType === SpaceType.BedStudy ||
    spaceType === SpaceType.Bedsitting
  );
}

export type ChimneyType =
  | "with throat restrictor"
  | "without throat restrictor"
  | "no";

export type AirChangeRateStandards =
  | "MCSPre2000"
  | "MCSPost2000"
  | "MCSPost2006"
  | "UKBuildingRegs2021Dwellings"
  | "UKBuildingRegs2021NonDwellings"
  | "CIBSE2016"
  | "ASHRAE62.1"
  | "AS1668.2-2012";

export enum ACRUnits {
  ACH = "ACH",
  Ls = "L/s",
  LsPerM2 = "L/s/m2",
  LsPerPerson = "L/s/person",
  LsPerShower = "L/s/shower",
  LsPerBath = "L/s/bath",
  LsPerWC = "L/s/WC",
  LsPerUrinal = "L/s/urinal",
  LsPerMachine = "L/s/machine",
  LsPerRoom = "L/s/room",
  M3s = "m3/s",
  CfmPerF2 = "ft3/min/f2",
  Cfm = "ft3/min",
}

export type AirChangeRateValueSpec = {
  unit: ACRUnits;
  coeff: number;
};

export type AirChangeRateSpecData = Record<
  SpaceType | string,
  AirChangeRateValueSpec[]
>;

export type AirChangeRateSpec = {
  [key in AirChangeRateStandards]: {
    data: AirChangeRateSpecData;
  };
};

export type RoomTemperatureSpec = {
  [key in AirChangeRateStandards]: RoomTemperatureSpecData;
};

export type RoomTemperatureSpecData = Record<SpaceType | string, number>;

export type ChimneySpec = {
  [key in ChimneyType]: {
    [key: number]: number;
  };
};

// DEPRECATED TYPES
export type DeprecatedTemperatureDevYear = "pre2000" | "post2000" | "post2006";
export type DeprecatedAirChangeDevYear =
  | "pre2000"
  | "post2000"
  | "post2006"
  | "partf2021";
