import Flatten from "@flatten-js/core";
import RBush from "rbush";
import { CorePolygonObjectConcrete } from "../../../coreObjects";
import AreaSegmentEntity from "../../../document/entities/area-segment-entity";
import { RoomRoomEntity } from "../../../document/entities/rooms/room-entity";
import { EntityType } from "../../../document/entities/types";
import { SpatialIndex } from "../../../types";
import { CoreContext } from "../../types";
import { IGNORE_COLLISION_EPS } from "./loop-generator";

export interface LoopObstacle {
  segment: Flatten.Segment;
  radiusMM: number;
}

export function loopObstacleToSimple(obstacle: LoopObstacle) {
  return {
    segment: [
      { x: obstacle.segment.start.x, y: obstacle.segment.start.y },
      { x: obstacle.segment.end.x, y: obstacle.segment.end.y },
    ],
    radiusMM: obstacle.radiusMM,
  };
}

export function getRoomLoopObstacles(
  context: CoreContext,
  uaCache: Map<string, RBush<SpatialIndex>>,
  entity: RoomRoomEntity | AreaSegmentEntity,
  otherObstacles: LoopObstacle[] = [],
): LoopObstacle[] {
  // site walls first
  const result: LoopObstacle[] = [];
  const boundary: LoopObstacle[] = [];

  const roomObj = context.globalStore.get<CorePolygonObjectConcrete>(
    entity.uid,
  );
  for (const edge of roomObj.collectEdgesInOrderCCW()) {
    const eps = edge.worldEndpoints();
    const seg = Flatten.segment(
      Flatten.point(eps[0].x, eps[0].y),
      Flatten.point(eps[1].x, eps[1].y),
    );
    boundary.push({
      segment: seg,
      radiusMM: 0,
    });
  }

  result.push(...boundary);

  const levelUid = context.globalStore.levelOfEntity.get(entity.uid);
  if (!levelUid) {
    return result;
  }

  const rBush = uaCache.get(levelUid);
  if (!rBush) {
    return result.concat(filterObstaclesInBounds(otherObstacles, boundary));
  }

  const box = roomObj.shape.box;

  const searchResults = rBush.search({
    minX: box.xmin,
    minY: box.ymin,
    maxX: box.xmax,
    maxY: box.ymax,
  });
  for (const obj of searchResults) {
    // If we are a heatedArea ourselves, we are doubling up on the edges, so this check is needed.
    if (obj.uid === entity.uid) {
      continue;
    }

    const o = context.globalStore.get(obj.uid);

    if (
      o.type !== EntityType.AREA_SEGMENT ||
      (o.entity.areaType !== "unheated-area" &&
        // Heated area edges also form boundaries and so are included here.
        o.entity.areaType !== "heated-area")
    ) {
      continue;
    }

    for (const edge of o.collectEdgesInOrderCCW()) {
      const eps = edge.worldEndpoints();
      const seg = Flatten.segment(
        Flatten.point(eps[0].x, eps[0].y),
        Flatten.point(eps[1].x, eps[1].y),
      );
      result.push({
        segment: seg,
        // Let pipes go right next to unheated areas.
        radiusMM: 0,
      });
    }
  }

  return result.concat(filterObstaclesInBounds(otherObstacles, boundary));
}

function filterObstaclesInBounds(
  obstacles: LoopObstacle[],
  boundary: LoopObstacle[],
) {
  const polygon = new Flatten.Polygon();
  const face: Flatten.Point[] = [];

  for (let i = 0; i < boundary.length; i++) {
    const thisObs = boundary[i];
    const nextObs = boundary[(i + 1) % boundary.length];
    const s1 = [thisObs.segment.start, thisObs.segment.end];
    const s2 = [nextObs.segment.start, nextObs.segment.end];
    const commonPoint = s2.some(
      (p) => p.distanceTo(s1[0])[0] < IGNORE_COLLISION_EPS,
    )
      ? s1[0]
      : s1[1];
    face.push(commonPoint);
  }
  polygon.addFace(face);

  return obstacles.filter((obs) => {
    const seg = obs.segment;
    return polygon.contains(seg.start) || polygon.contains(seg.end);
  });
}
