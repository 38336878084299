import Vue from "vue";
import { MutationTree } from "vuex";
import { SupportedLocales } from "../../../../common/src/api/locale";
import { User } from "../../../../common/src/models/User";
import { savePreference } from "../../../src/lib/localStorage";
import ProfileState from "../../../src/store/profile/types";

export const mutations: MutationTree<ProfileState> = {
  setProfile(state, profile: User | null) {
    state.profile = profile;
  },

  setViewedVideoIds(state, viewedVideoIds: string[]) {
    state.viewedVideoIds.splice(
      0,
      state.viewedVideoIds.length,
      ...viewedVideoIds,
    );
  },

  setNumDrawingsCreated(state, num: number) {
    state.numDrawingsCreated = num;
  },

  setNumFeedbackSubmitted(state, num: number) {
    state.numFeedbackSubmitted = num;
  },

  setLocale(state, payload: SupportedLocales) {
    savePreference(window, "locale", payload);
    state.locale = payload;
  },

  setProperties(state, payload: { [key: string]: any }) {
    for (const key in payload) {
      Vue.set(state.properties, key, payload[key]);
    }
  },

  clearProperties(state) {
    state.properties = {};
  },
};
