import Flatten from "@flatten-js/core";
import * as TM from "transformation-matrix";
import CoreFixture from "../../../../common/src/api/coreObjects/coreFixture";
import { CalculationData } from "../../../../common/src/api/document/calculations-objects/calculation-field";
import { isLiveWarningVisible } from "../../../../common/src/api/document/calculations-objects/warnings";
import BigValveEntity from "../../../../common/src/api/document/entities/big-valve/big-valve-entity";
import { DrawableEntityConcrete } from "../../../../common/src/api/document/entities/concrete-entity";
import FixtureEntity, {
  fillFixtureFields,
} from "../../../../common/src/api/document/entities/fixtures/fixture-entity";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { getFlowSystem } from "../../../../common/src/api/document/utils";
import { Coord } from "../../../../common/src/lib/coord";
import { DEFAULT_FONT_NAME } from "../../config";
import { rgb2style } from "../../lib/utils";
import { getGlobalContext } from "../../store/globalCoreContext";
import { drawSystemNodeAccentIfNeeded } from "../helpers/draw-helper";
import {
  MoveIntent,
  getCoolDragCorrelations,
} from "../lib/black-magic/cool-drag";
import CanvasContext from "../lib/canvas-context";
import { EntityDrawingArgs } from "../lib/drawable-object";
import { LiveWarnings } from "../lib/entity-popups/live-warnings";
import { EntityPopupContent } from "../lib/entity-popups/types";
import {
  HeatmapMode,
  generateFixtureHeatmap,
  isHeatmapEnabled,
} from "../lib/heatmap/heatmap";
import { Interaction, InteractionType } from "../lib/interaction";
import { CalculatedObject } from "../lib/object-traits/calculated-object";
import { CenteredObject } from "../lib/object-traits/centered-object";
import CoolDraggableObject from "../lib/object-traits/cool-draggable-object";
import { Core2Drawable } from "../lib/object-traits/core2drawable";
import {
  HoverSiblingResult,
  HoverableObject,
} from "../lib/object-traits/hoverable-object";
import { SelectableObject } from "../lib/object-traits/selectable";
import {
  SnapIntention,
  SnapTarget,
  SnappableObject,
  getBlockLikeSnapLocations,
} from "../lib/object-traits/snappable-object";
import { DrawingContext, ObjectConstructArgs } from "../lib/types";

import { DEFAULT_SYSTEM_NODE_SIZE } from "../../../../common/src/api/coreObjects/corePlant";
import { flowSystemsCompatible } from "../../../../common/src/api/coreObjects/utils";
import { FlowSystem } from "../../../../common/src/api/document/flow-systems";
import { AttachableObject } from "../lib/object-traits/attachable-object";
import { getHighlightColor } from "../lib/utils";
import { DrawingMode } from "../types";
import { DrawableObjectConcrete } from "./concrete-object";
import DrawableSystemNode from "./drawableSystemNode";
import { createCalculationBoxes, isFlowSystemActive } from "./utils";

const Base = HoverableObject(
  CalculatedObject(
    AttachableObject(
      SelectableObject(
        CoolDraggableObject(
          CenteredObject(SnappableObject(Core2Drawable(CoreFixture))),
        ),
      ),
    ),
  ),
);

export default class DrawableFixture extends Base {
  // We could not add this to the base drawable class because
  // of some typescript thing so they have to be added at the concrete class.
  constructor(args: ObjectConstructArgs<FixtureEntity>) {
    super(args.context, args.obj);
    this.onSelect = args.onSelect;
    this.onInteractionComplete = args.onInteractionComplete;
    this.document = args.document;
  }

  getSnapTargets(request: SnapIntention[], mouseWc: Coord): SnapTarget[] {
    return getBlockLikeSnapLocations(
      request,
      mouseWc,
      this.getInlets(),
      this.toWorldCoord(),
      this.toWorldAngleDeg(0) * (Math.PI / 180),
    );
  }

  locateCalculationBoxWorld(
    _context: DrawingContext,
    data: CalculationData[],
    scale: number,
  ): TM.Matrix[] {
    return createCalculationBoxes({
      wc: this.toWorldCoord(),
      angle: (this.toWorldAngleDeg(0) / 180) * Math.PI,
      scale,
      distanceCandidates: [
        this.entity.pipeDistanceMM * 2,
        this.entity.pipeDistanceMM * 6,
      ],
      data,
    });
  }

  drawEntity(
    context: DrawingContext,
    { selected, overrideColorList, heatmapMode }: EntityDrawingArgs,
  ): void {
    const scale = context.vp.currToSurfaceScale(context.ctx);
    const ww = Math.max(10 / this.toWorldLength(1), 1 / scale);

    const { ctx } = context;

    if (isHeatmapEnabled(this.document) && heatmapMode !== undefined) {
      const filledEntity = this.displayObject();
      const calculation = context.globalStore.getCalculation(this.entity);
      if (calculation) {
        const color = generateFixtureHeatmap(
          heatmapMode,
          calculation,
          filledEntity,
          getGlobalContext(),
        );
        if (color) {
          ctx.fillStyle = color;
          ctx.strokeStyle = color;
        }
      }
    }

    ctx.lineWidth = ww;

    const xm1 =
      -this.entity.pipeDistanceMM + (this.entity.pipeDistanceMM * -1) / 4;
    const x0 = -this.entity.pipeDistanceMM;
    const x1 =
      -this.entity.pipeDistanceMM + (this.entity.pipeDistanceMM * 1) / 4;
    const x2 =
      -this.entity.pipeDistanceMM + (this.entity.pipeDistanceMM * 2) / 4;
    const x3 =
      -this.entity.pipeDistanceMM + (this.entity.pipeDistanceMM * 3) / 4;
    const x4 =
      -this.entity.pipeDistanceMM + (this.entity.pipeDistanceMM * 4) / 4;
    const x5 =
      -this.entity.pipeDistanceMM + (this.entity.pipeDistanceMM * 5) / 4;
    const x6 =
      -this.entity.pipeDistanceMM + (this.entity.pipeDistanceMM * 6) / 4;
    const x7 =
      -this.entity.pipeDistanceMM + (this.entity.pipeDistanceMM * 7) / 4;
    const x8 =
      -this.entity.pipeDistanceMM + (this.entity.pipeDistanceMM * 8) / 4;
    const x9 =
      -this.entity.pipeDistanceMM + (this.entity.pipeDistanceMM * 9) / 4;

    const ym1 = 0 + (this.entity.pipeDistanceMM * -1) / 4;
    const y0 = 0;
    const y1 = 0 + (this.entity.pipeDistanceMM * 1) / 4;
    const y2 = 0 + (this.entity.pipeDistanceMM * 2) / 4;
    const y3 = 0 + (this.entity.pipeDistanceMM * 3) / 4;
    const y4 = 0 + (this.entity.pipeDistanceMM * 4) / 4;
    const y6 = 0 + (this.entity.pipeDistanceMM * 6) / 4;
    const y7 = 0 + (this.entity.pipeDistanceMM * 7) / 4;

    if (
      heatmapMode === HeatmapMode.Off ||
      context.doc.uiState.drawingMode !== DrawingMode.Calculations
    ) {
      if (this.isActive()) {
        ctx.fillStyle = "rgba(230, 255, 230, 0.8)";
        ctx.strokeStyle = "#000";
      } else {
        ctx.fillStyle = "rgba(200, 200, 200, 0.3)";
        ctx.strokeStyle = "#AAA";
      }
    }

    ctx.beginPath();
    if (this.entity.roughInsInOrder.length > 2) {
      ctx.fillRect(xm1, ym1, x9 - xm1, y7 - ym1);
      ctx.rect(xm1, ym1, x9 - xm1, y7 - ym1);
    } else {
      ctx.fillRect(x2, ym1, x6 - x2, y4 - ym1);
      ctx.rect(x2, ym1, x6 - x2, y4 - ym1);
    }
    ctx.stroke();

    if (
      (selected || overrideColorList.length) &&
      (heatmapMode === HeatmapMode.Off ||
        context.doc.uiState.drawingMode !== DrawingMode.Calculations)
    ) {
      ctx.fillStyle = rgb2style(
        getHighlightColor(selected, overrideColorList, { hex: "#A0C8A0" }),
        0.6,
      );
      if (this.entity.roughInsInOrder.length > 2) {
        ctx.fillRect(xm1, ym1, x9 - xm1, y7 - ym1);
      } else {
        ctx.fillRect(x2, ym1, x6 - x2, y4 - ym1);
      }
    }

    if (this.isActive()) {
      ctx.strokeStyle = "#228800";
    } else {
      ctx.strokeStyle = "#AAA";
    }

    ctx.beginPath();
    if (this.entity.roughInsInOrder.length > 2) {
      // double (cross)
      ctx.moveTo(x0, y1);
      ctx.lineTo(x8, y1);
      ctx.moveTo(x2, y0);
      ctx.lineTo(x2, y3);
      ctx.moveTo(x6, y0);
      ctx.lineTo(x6, y3);

      ctx.moveTo(x1, y2);
      ctx.lineTo(x3, y2);
      ctx.moveTo(x5, y2);
      ctx.lineTo(x7, y2);

      ctx.moveTo(x4, y1);
      ctx.lineTo(x4, y6);
    } else {
      // single
      ctx.moveTo(x4, y0);
      ctx.lineTo(x4, y3);
      ctx.moveTo(x3, y1);
      ctx.lineTo(x5, y1);
    }

    ctx.stroke();

    for (const systemUid of Object.keys(this.entity.roughIns)) {
      const system = getFlowSystem(context.doc.drawing, systemUid);
      if (!system) {
        continue;
      }

      const roughIn = this.entity.roughIns[systemUid];
      const systemNode = context.globalStore.get(
        roughIn.uid,
      ) as DrawableSystemNode;

      if (this.isFlowSystemActive(system)) {
        drawSystemNodeAccentIfNeeded({
          context,
          systemNode,
          style: "cap",
          orientation: "vertical",
          isReturn: false,
          isRecirculation: false,
          length: DEFAULT_SYSTEM_NODE_SIZE,
        });
      }
    }

    this.withWorldAngle(
      context,
      { x: 0, y: this.entity.pipeDistanceMM * 1.2 },
      () => {
        ctx.font = this.entity.pipeDistanceMM / 2 + "pt " + DEFAULT_FONT_NAME;
        const abbreviation = this.entity.abbreviation
          ? this.entity.abbreviation
          : "";
        const bounds = ctx.measureText(abbreviation);
        const left = -bounds.width / 2;
        if (this.isActive()) {
          ctx.fillStyle = "#000";
        } else {
          ctx.fillStyle = "#AAA";
        }
        // this.entityBacked.type==EntityType.FIXTURE ?20:-10

        ctx.fillTextStable(
          abbreviation,
          left,
          this.entity.pipeDistanceMM * 0.3 +
            (this.entity.roughInsInOrder.length <= 2 ? 20 : -20),
        );
      },
    );

    // Display Entity Name
    this.withWorldAngle(
      context,
      { x: 0, y: this.entity.pipeDistanceMM * 2 },
      () => {
        if (this.entity.entityName) {
          const name = this.entity.entityName;
          ctx.font = 70 + "pt " + DEFAULT_FONT_NAME;
          const nameWidth = ctx.measureText(name).width;
          const offsetx = -nameWidth / 2;

          ctx.fillStyle = "rgba(0, 255, 20, 0.13)";
          // the 70 represents the height of the font
          const textHight = 70;
          const offsetY = textHight / 2;
          ctx.fillRect(offsetx, offsetY, nameWidth, 70);
          ctx.fillStyle = "#000";
          ctx.fillTextStable(name, offsetx, offsetY - 4, undefined, "top");
        }
      },
    );

    if (this.isHovering) {
      // draw white transparent box over whole thing
      ctx.fillStyle = "rgba(255,255,255,0.5)";
      if (this.entity.roughInsInOrder.length > 2) {
        ctx.fillRect(xm1, ym1, x9 - xm1, y7 - ym1);
        //ctx.rect(xm1, ym1, x9 - xm1, y7 - ym1);
      } else {
        ctx.fillRect(x2, ym1, x6 - x2, y4 - ym1);
        //ctx.rect(x2, ym1, x6 - x2, y4 - ym1);
      }
    }

    // ctx.shadowBlur = 0;
  }

  displayObject(): FixtureEntity {
    return fillFixtureFields(getGlobalContext(), this.entity);
  }

  isFlowSystemActive(FS: FlowSystem): boolean {
    return isFlowSystemActive(this.context, this.document.uiState, FS.uid);
  }

  refreshObjectInternal(_obj: BigValveEntity): void {
    //
  }

  inBounds(objectCoord: Coord) {
    if (!this.isActive()) {
      return false;
    }
    if (this.entity.roughInsInOrder.length > 2) {
      // double
      if (
        objectCoord.x >= -this.entity.pipeDistanceMM * (6 / 4) &&
        objectCoord.x <= this.entity.pipeDistanceMM * (6 / 4)
      ) {
        if (
          objectCoord.y >= this.entity.pipeDistanceMM * (-3 / 8) &&
          objectCoord.y <= this.entity.pipeDistanceMM * 2
        ) {
          return true;
        }
      }
      return false;
    } else {
      // single
      if (
        objectCoord.x >= -this.entity.pipeDistanceMM * (2 / 3) &&
        objectCoord.x <= this.entity.pipeDistanceMM * (2 / 3)
      ) {
        if (
          objectCoord.y >= -this.entity.pipeDistanceMM / 2 &&
          objectCoord.y <= (this.entity.pipeDistanceMM * 3) / 2
        ) {
          return true;
        }
      }
      return false;
    }
  }

  isActive(): boolean {
    for (const systemUid of this.entity.roughInsInOrder) {
      if (isFlowSystemActive(this.context, this.document.uiState, systemUid)) {
        return true;
      }
    }
    return false;
  }

  prepareDelete(
    context: CanvasContext,
    _calleeEntityUid?: string,
  ): DrawableObjectConcrete[] {
    const result: DrawableObjectConcrete[] = [];
    for (const suid of this.entity.roughInsInOrder) {
      result.push(
        ...(this.drawableStore
          .get(this.entity.roughIns[suid].uid)
          ?.prepareDelete(context) ?? []),
      );
    }
    result.push(this);

    return result.filter(Boolean);
  }

  offerJoiningInteraction(
    systemUid: string | undefined,
    interaction: Interaction,
  ) {
    if (!systemUid) {
      // return closest inlet.
      const inlets = this.entity.roughInsInOrder.map(
        (suid) => this.entity.roughIns[suid],
      );
      if (!inlets.length) {
        return null;
      }

      let closest: DrawableSystemNode | undefined;
      let closestDistance: number | undefined = undefined;

      for (const inlet of inlets) {
        const node = this.globalStore.get(inlet.uid)! as DrawableSystemNode;

        if (!node.offerInteraction(interaction)) {
          continue;
        }
        const distance = node.shape?.distanceTo(
          Flatten.point(interaction.worldCoord.x, interaction.worldCoord.y),
        );
        if (
          distance !== undefined &&
          (!closestDistance || distance[0] < closestDistance)
        ) {
          closest = node;
          closestDistance = distance[0];
        }
      }

      if (closest) {
        return [closest.entity, this.entity];
      } else {
        return null;
      }
    }

    for (const systemUidIter of Object.keys(this.entity.roughIns)) {
      if (
        flowSystemsCompatible(systemUid, systemUidIter, this.document.drawing)
      ) {
        const obj = this.globalStore.get<DrawableObjectConcrete>(
          this.entity.roughIns[systemUidIter].uid,
        );
        if (obj && obj.offerInteraction(interaction)) {
          return [obj.entity, this.entity];
        }
      }
    }
    return null;
  }

  offerInteraction(interaction: Interaction): DrawableEntityConcrete[] | null {
    switch (interaction.type) {
      case InteractionType.CONTINUING_CONDUIT:
      case InteractionType.STARTING_CONDUIT:
        return this.offerJoiningInteraction(
          interaction.system?.uid,
          interaction,
        );
      case InteractionType.SNAP_ONTO_RECEIVE:
        if (interaction.src.type === EntityType.FITTING) {
          return this.offerJoiningInteraction(
            interaction.src.systemUid,
            interaction,
          );
        }
        return null;
      case InteractionType.INSERT:
      case InteractionType.SNAP_ONTO_SEND:
      case InteractionType.EXTEND_NETWORK:
        return null;
      case InteractionType.LINK_ENTITY:
        return [this.entity];
    }
  }

  getCoolDragCorrelations(
    myMove: MoveIntent,
    _from?: DrawableObjectConcrete | undefined,
  ): { object: DrawableObjectConcrete; move: MoveIntent }[] {
    return getCoolDragCorrelations({
      globalStore: this.globalStore,
      systemNodes: Object.values(this.entity.roughIns).map(
        (roughIn) => this.globalStore.get(roughIn.uid) as DrawableSystemNode,
      ),
      self: this,
      includedDistanceMM: 300,
      includedShape: this.shape || undefined,
      selfMove: myMove,
    });
  }

  getCopiedObjects(): DrawableObjectConcrete[] {
    const res: DrawableObjectConcrete[] = [this];

    for (const suid of this.entity.roughInsInOrder) {
      res.push(this.globalStore.get(this.entity.roughIns[suid].uid)!);
    }

    return res;
  }

  onUpdate() {
    super.onUpdate();

    const filled = fillFixtureFields(getGlobalContext(), this.entity);

    const pipeDistanceMM = filled.pipeDistanceMM!;
    const xMat = [[], [], [0.0, 0.0], [0.0, -0.5, 0.5]];
    const yMat = [[], [], [-0.2, 0.0], [-0.2, 0.0, 0.0]];
    for (let i = 0; i < filled.roughInsInOrder.length; i++) {
      const suid = filled.roughInsInOrder[i];
      const ri = filled.roughIns[suid];
      const s = this.globalStore.get(ri.uid) as DrawableSystemNode;

      // sync the allowAllSystems setting
      if (s && ri.allowAllSystems !== s.entity.allowAllSystems) {
        s.entity.allowAllSystems = ri.allowAllSystems;
      }

      // update physical location of systemNodes
      if (s) {
        s.entity.center.x =
          pipeDistanceMM * xMat[filled.roughInsInOrder.length][i];
        s.entity.center.y =
          pipeDistanceMM * yMat[filled.roughInsInOrder.length][i];
      }
    }
  }

  getPopupContent() {
    const liveCalcs = this.globalStore.getOrCreateLiveCalculation(this.entity);
    const result: EntityPopupContent[] = [];
    for (const warning of liveCalcs.warnings) {
      switch (warning.type) {
        case "CONNECT_THE_FIXTURE_TO_A_FLOW_SYSTEM":
          if (
            isLiveWarningVisible({
              warning,
              drawingLayout: this.document.uiState.drawingLayout,
            })
          ) {
            result.push(LiveWarnings.UNCONNECTED_FIXTURE);
          }
      }
    }
    return result;
  }

  getHoverSiblings(): HoverSiblingResult[] {
    return [];
  }

  getAttachCoords(): [Coord, Coord, Coord, Coord] {
    if (this.entity.roughInsInOrder.length > 2) {
      return [
        // left
        {
          x: (-5 * this.entity.pipeDistanceMM) / 4 - this.attachmentOffset,
          y: (3 * this.entity.pipeDistanceMM) / 4,
        },
        // right
        {
          x: (5 * this.entity.pipeDistanceMM) / 4 + this.attachmentOffset,
          y: (3 * this.entity.pipeDistanceMM) / 4,
        },
        // top
        { x: 0, y: -this.entity.pipeDistanceMM / 4 - this.attachmentOffset },
        // bottom
        {
          x: 0,
          y: (7 * this.entity.pipeDistanceMM) / 4 + this.attachmentOffset,
        },
      ];
    }

    return [
      // left
      {
        x: -this.entity.pipeDistanceMM / 2 - this.attachmentOffset,
        y: (3 * this.entity.pipeDistanceMM) / 8,
      },
      // right
      {
        x: this.entity.pipeDistanceMM / 2 + this.attachmentOffset,
        y: (3 * this.entity.pipeDistanceMM) / 8,
      },
      // top
      {
        x: 0,
        y: -this.entity.pipeDistanceMM / 4 - this.attachmentOffset,
      },
      // bottom
      {
        x: 0,
        y: this.entity.pipeDistanceMM + this.attachmentOffset,
      },
    ];
  }
}
