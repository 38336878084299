import Flatten from "@flatten-js/core";
import RBush, { BBox } from "rbush";
import { EntityType } from "../../../../../common/src/api/document/entities/types";
import CanvasContext from "../../lib/canvas-context";
import {
  isPolygonObject,
  PolygonObjectConcrete,
} from "../../objects/concrete-object";
import DrawableEdge from "../../objects/drawableEdge";
import DrawableWall from "../../objects/drawableWall";

type CacheNode = {
  uid: string;
  type: "internal-wall" | "external-wall" | "polygon-edge";
  edgeUid: string;
} & BBox;

export interface EdgeCache {
  tree: RBush<CacheNode>;
  polygons: Record<string, Flatten.Polygon>;
  segments: Record<string, Flatten.Segment>;
  normals: Record<string, Flatten.Vector>;
}

export interface PolygonCache {
  tree: RBush<{ uid: string } & BBox>;
  polygons: Record<string, Flatten.Polygon>;
}

export function buildWallCache(context: CanvasContext) {
  return buildEdgeCache(context, {
    wallFilter: (wall) => wall.isManifested,
    edgeFilter: (_edge) => false,
    polygonFilter: () => false,
  });
}

export function buildEdgeCache(
  context: CanvasContext,
  options: {
    wallFilter: (wall: DrawableWall) => boolean;
    edgeFilter: (edge: DrawableEdge) => boolean;
    polygonFilter: (polygon: PolygonObjectConcrete) => boolean;
  },
): EdgeCache {
  const tree = new RBush<CacheNode>();
  const build: Array<CacheNode> = [];

  const segments: Record<string, Flatten.Segment> = {};
  const normals: Record<string, Flatten.Vector> = {};
  const polygons: Record<string, Flatten.Polygon> = {};

  for (const uid of context.globalStore.entitiesInLevel.get(
    context.document.uiState.levelUid!,
  ) || []) {
    const obj = context.globalStore.get(uid)!;
    if (obj.type === EntityType.WALL && options.wallFilter(obj)) {
      const thisSegments = obj.getWorldSegments();
      const edgeObject = context.globalStore.get<DrawableEdge>(
        obj.entity.polygonEdgeUid[0],
      )!;
      const normal = edgeObject.normal;

      for (let i = 0; i < thisSegments.length; i++) {
        const [a, b] = thisSegments[i];
        const segment = Flatten.segment(
          Flatten.point(a.x, a.y),
          Flatten.point(b.x, b.y),
        );

        build.push({
          uid: obj.uid + "." + i,
          edgeUid: obj.uid,
          type: obj.isAutoInternalWall() ? "internal-wall" : "external-wall",
          minX: segment.box.xmin,
          minY: segment.box.ymin,
          maxX: segment.box.xmax,
          maxY: segment.box.ymax,
        });
        segments[obj.uid + "." + i] = segment;
        normals[obj.uid + "." + i] = normal;
      }
    } else if (obj.type === EntityType.EDGE && options.edgeFilter(obj)) {
      const normal = obj.normal;

      const box = obj.shape.box;
      build.push({
        uid: obj.uid,
        edgeUid: obj.uid,
        type: "polygon-edge",
        minX: box.xmin,
        minY: box.ymin,
        maxX: box.xmax,
        maxY: box.ymax,
      });
      segments[obj.uid] = obj.segment;
      normals[obj.uid] = normal;
    }
  }

  for (const uid of context.globalStore.entitiesInLevel.get(
    context.document.uiState.levelUid!,
  ) || []) {
    const obj = context.globalStore.get(uid)!;
    if (isPolygonObject(obj) && options.polygonFilter(obj)) {
      polygons[obj.uid] = obj.shape;
    }
  }

  return {
    tree: tree.load(build),
    segments,
    normals,
    polygons: polygons,
  };
}

export function buildPolygonTree(
  context: CanvasContext,
  options: {
    polygonFilter: (polygon: PolygonObjectConcrete) => boolean;
  },
): PolygonCache {
  const tree = new RBush<{ uid: string } & BBox>();
  const build: Array<{ uid: string } & BBox> = [];

  const polygons: Record<string, Flatten.Polygon> = {};
  for (const uid of context.globalStore.entitiesInLevel.get(
    context.document.uiState.levelUid!,
  ) || []) {
    const obj = context.globalStore.get(uid)!;
    if (!isPolygonObject(obj)) continue;
    if (!options.polygonFilter(obj)) continue;
    const shape = obj.shape;
    build.push({
      uid: obj.uid,
      minX: shape.box.xmin,
      minY: shape.box.ymin,
      maxX: shape.box.xmax,
      maxY: shape.box.ymax,
    });
    polygons[obj.uid] = shape;
  }

  return {
    tree: tree.load(build),
    polygons,
  };
}
