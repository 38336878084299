
import {
  UpdateUserAttributeOutput,
  confirmUserAttribute,
  fetchMFAPreference,
  fetchUserAttributes,
  sendUserAttributeVerificationCode,
  updateMFAPreference,
  updateUserAttribute,
} from "aws-amplify/auth";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { assertUnreachable } from "../../../common/src/lib/utils";
import { adminDisableMFA } from "../api/users";
import { ProfileWithMFA } from "../router";

@Component({
  components: {
    VueTelInput,
  },
})
export default class MFA extends Vue {
  @Prop({ required: true }) editingMyself!: boolean;
  @Prop({ required: true }) mfaStatus!: boolean;
  @Prop({ required: true }) username!: string;

  // Non-modal variables
  initialLoading = true;
  verifiedPhone: string | null = null;
  loading2 = false;

  // Modal variables
  phone = "";
  code = "";
  phoneIsValid = false;
  validPhone = "";
  loading = false;
  step: "enter_number" | "enter_code" | "number_verified" | "success" =
    "enter_number";
  dropdownOptions = {
    disabled: false,
    showDialCodeInList: true,
    showDialCodeInSelection: true,
    showFlags: true,
    showSearchBox: true,
    searchBoxPlaceholder: "Search...",
    tabindex: 0,
  };

  updated() {
    console.log("mfa status", this.mfaStatus);
  }

  async mounted() {
    await this.updateState();
  }

  modalHidden() {
    this.step = "enter_number";
    this.phone = "";
    this.code = "";
    this.phoneIsValid = false;
    this.validPhone = "";
    this.loading = false;
    this.updateState();
  }

  async updateState() {
    this.initialLoading = true;
    const user = await fetchUserAttributes();
    const mfa = await fetchMFAPreference();

    if (mfa.enabled) {
      if (mfa.enabled.includes("SMS")) {
        this.mfaStatus = true;
      }
    }

    if (user.phone_number_verified === "true") {
      this.verifiedPhone = String(user.phone_number);
    }

    this.initialLoading = false;
  }

  handlePhoneInput(number: String, phoneObject: any) {
    this.phoneIsValid = phoneObject.valid;
    this.validPhone = phoneObject.number;
  }

  async updateUserPhoneNumber() {
    this.loading = true;
    try {
      const output = await updateUserAttribute({
        userAttribute: {
          attributeKey: "phone_number",
          value: this.validPhone,
        },
      });
      this.step = "enter_code";
      this.handleUpdateUserAttributeNextSteps(output);
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  handleUpdateUserAttributeNextSteps(output: UpdateUserAttributeOutput) {
    const { nextStep } = output;

    switch (nextStep.updateAttributeStep) {
      case "CONFIRM_ATTRIBUTE_WITH_CODE":
        this.sendUserPhoneVerifyCode();
        break;
      case "DONE":
        this.sendUserPhoneVerifyCode();
        break;
      default:
        assertUnreachable(nextStep.updateAttributeStep);
    }
  }

  async sendUserPhoneVerifyCode() {
    this.loading = true;
    try {
      const output = await sendUserAttributeVerificationCode({
        userAttributeKey: "phone_number",
      });
      console.log("output", output);
    } catch (e: any) {
      this.handleError(e);
    } finally {
      this.loading = false;
    }
  }

  async handleConfirmUserPhoneNumber() {
    this.loading = true;
    try {
      await confirmUserAttribute({
        userAttributeKey: "phone_number",
        confirmationCode: this.code,
      });
      this.step = "number_verified";
    } catch (e: any) {
      this.handleError(e);
    } finally {
      this.loading = false;
    }
  }

  // the reason we need this is the profile takes a bit of time to update the MFA status on the AWS Cognito side
  updateProfileMFA(enabled: boolean) {
    if (this.editingMyself) {
      this.$store.dispatch("profile/setProfile", {
        ...this.profile,
        mfaStatus: enabled,
      });
    }
    this.$emit("updateUserMFA", enabled);
  }

  async enableMFA() {
    this.loading = true;
    try {
      await updateMFAPreference({ sms: "ENABLED" });
      this.updateProfileMFA(true);
      this.step = "success";
    } catch (e: any) {
      this.handleError(e);
    } finally {
      this.loading = false;
    }
  }

  async disableMFA() {
    this.loading2 = true;
    try {
      await updateMFAPreference({ sms: "DISABLED" });
      this.updateProfileMFA(false);
    } catch (e: any) {
      this.handleError(e);
    } finally {
      this.loading2 = false;
    }
  }

  async adminDisableMFA() {
    this.loading2 = true;
    try {
      const { success } = await adminDisableMFA(this.username);
      if (!success) {
        throw new Error("Failed to disable MFA");
      }
      this.mfaStatus = false;
    } catch (e: any) {
      this.handleError(e);
    } finally {
      this.loading2 = false;
    }
  }

  handleError(e: Error) {
    this.$bvToast.toast(e.message, {
      variant: "warning",
      toaster: " b-toaster-top-center",
      title: "Something is not right",
      noAutoHide: true,
    });
  }

  get profile(): ProfileWithMFA {
    return this.$store.getters["profile/profile"];
  }
}
