
import { defineComponent } from "vue";
import {
  ErrorReport,
  ErrorStatus,
} from "../../../common/src/models/ErrorReport";
import { User } from "../../../common/src/models/User";
import MainNavBar from "../../src/components/MainNavBar.vue";
import { getErrorReports } from "../api/error-report";

export default defineComponent({
  components: {
    MainNavBar,
  },
  data() {
    const filters: ErrorStatus[] = [ErrorStatus.NEW, ErrorStatus.DOING];
    const isLoaded: boolean = false;
    const errors: ErrorReport[] = [];

    return {
      errors,
      isLoaded,
      triedToLoad: new Set<number>(),
      filters,
    };
  },
  computed: {
    profile(): User {
      return this.$store.getters["profile/profile"];
    },
    ErrorStatus() {
      return ErrorStatus;
    },
  },
  mounted() {
    // fill documents
    this.loadMoreErrors();
  },
  methods: {
    loadMoreErrors() {
      this.triedToLoad.add(this.errors.length);
      getErrorReports(this.filters, this.errors.length, 50).then((res) => {
        if (res.success) {
          this.errors.push(...res.data);
          this.isLoaded = true;
        } else {
          this.$bvToast.toast(res.message, {
            variant: "danger",
            title: "Error retrieving error list (How ironic)",
          });
        }
      });
    },
    toggleFilter(val: ErrorStatus) {
      if (this.filters.includes(val)) {
        this.filters.splice(this.filters.indexOf(val), 1);
      } else {
        this.filters.push(val);
      }
      this.errors.splice(0);
      this.triedToLoad.clear();
      this.isLoaded = false;
      this.loadMoreErrors();
    },
  },
});
