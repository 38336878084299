import { isDrainage } from "../../../../../common/src/api/config";
import FixtureEntity from "../../../../../common/src/api/document/entities/fixtures/fixture-entity";
import { getFlowSystem } from "../../../../../common/src/api/document/utils";
import { Units } from "../../../../../common/src/lib/measurements";
import { CoreContext } from "../../calculations/types";
import { CalculationField, FieldCategory } from "./calculation-field";
import {
  CalcFieldSelector,
  Calculation,
  CalculationType,
  LiveCalculation,
  PressureCalculation,
  addLiquidPressureCalculationFields,
} from "./types";
import { getFlowSystemLayouts } from "./utils";

export default interface FixtureCalculation extends Calculation {
  type: CalculationType.FixtureCalculation;
  inlets: {
    [key: string]: {
      deadlegVolumeL: number | null;
      deadlegLengthM: number | null;
      deadlegWaitTimeS: number | null;
    } & PressureCalculation;
  };
}

export interface FixtureLiveCalculation extends LiveCalculation {}

export const FastLiveFixtureCalculationFields: CalcFieldSelector<FixtureLiveCalculation> =
  {
    connected: true,
  };

export function makeFixtureCalculationFields(
  context: CoreContext,
  entity: FixtureEntity,
): CalculationField[] {
  const { globalStore, drawing } = context;
  const fCalc = globalStore.getOrCreateCalculation(entity);

  const result: CalculationField[] = [];

  entity.roughInsInOrder.forEach((suid) => {
    if (isDrainage(drawing.metadata.flowSystems[suid])) {
      return [];
    }

    const { layouts, layoutsExcept, onlyLayouts } = getFlowSystemLayouts(
      drawing.metadata.flowSystems[suid],
    );

    const system = getFlowSystem(drawing, suid);
    if (!system) {
      throw new Error("System not found");
    }

    if (!result.find((e) => e.property === "reference")) {
      result.push({
        property: "reference",
        title: "Reference",
        short: "",
        shortTitle: "",
        systemUid: suid,
        units: Units.None,
        category: FieldCategory.EntityName,
        defaultEnabled: false,
        layouts: ["pressure", "drainage"],
      });
    }

    addLiquidPressureCalculationFields(
      result,
      suid,
      layouts,
      "inlets." + suid + ".",
      { defaultEnabled: false },
      { defaultEnabled: false },
    );

    if (fCalc.inlets[suid] && fCalc.inlets[suid].deadlegVolumeL !== null) {
      result.push({
        property: "inlets." + suid + ".deadlegVolumeL",
        title: system.name + " Dead Leg Volume",
        short: "",
        systemUid: suid,
        units: Units.Liters,
        category: FieldCategory.Volume,
        defaultEnabled: false,
        layouts: onlyLayouts("pressure"),
      });
    }

    if (fCalc.inlets[suid] && fCalc.inlets[suid].deadlegLengthM !== null) {
      result.push({
        property: "inlets." + suid + ".deadlegLengthM",
        title: system.name + " Dead Leg Length",
        short: "",
        shortTitle: "Dead Leg Length",
        systemUid: suid,
        units: Units.Meters,
        category: FieldCategory.Length,
        defaultEnabled: false,
        layouts: onlyLayouts("pressure"),
      });
    }

    if (fCalc.inlets[suid] && fCalc.inlets[suid].deadlegWaitTimeS !== null) {
      result.push({
        property: "inlets." + suid + ".deadlegWaitTimeS",
        title: system.name + " Dead Leg Wait Time",
        short: "",
        shortTitle: "Dead Leg Wait Time",
        systemUid: suid,
        units: Units.Seconds,
        category: FieldCategory.Time,
        defaultEnabled: false,
        layouts: onlyLayouts("pressure"),
      });
    }
  });
  return result;
}

export function emptyFixtureCalculation(
  entity: FixtureEntity,
): FixtureCalculation {
  const result: FixtureCalculation = {
    type: CalculationType.FixtureCalculation,
    costBreakdown: null,
    cost: null,
    expandedEntities: null,
    inlets: {},
    warnings: null,
  };
  for (const suid of entity.roughInsInOrder) {
    result.inlets[suid] = {
      deadlegLengthM: null,
      deadlegVolumeL: null,
      deadlegWaitTimeS: null,
      pressureKPA: null,
      staticPressureKPA: null,
    };
  }

  return result;
}

export function emptyFixtureLiveCalculation(): FixtureLiveCalculation {
  return {
    connected: null,
    warnings: [],
  };
}
