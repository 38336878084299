/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import Flatten from "@flatten-js/core";
import { ach2ls, cfm2ls, ls2ach, m2tof2 } from "../../../lib/measurements";
import { assertType, assertUnreachable } from "../../../lib/utils";
import {
  ACRUnits,
  AirChangeRateValueSpec,
  ChimneyType,
  hasBedroom,
} from "../../catalog/heatload/types";
import CoreConduit from "../../coreObjects/coreConduit";
import CoreDirectedValve from "../../coreObjects/coreDirectedValve";
import CoreRoom from "../../coreObjects/coreRoom";
import { determineConnectableSystemUid } from "../../coreObjects/utils";
import { isDuctEntity } from "../../document/entities/conduit-entity";
import {
  FanEntity,
  isFanEntity,
} from "../../document/entities/directed-valves/directed-valve-entity";
import { ValveType } from "../../document/entities/directed-valves/valve-types";
import LoadNodeEntity, {
  NodeType,
  VentilationNode,
} from "../../document/entities/load-node-entity";
import { fillPlantDefaults } from "../../document/entities/plants/plant-defaults";
import { AirHandlingUnitVentPlantEntity } from "../../document/entities/plants/plant-entity";
import { PlantType } from "../../document/entities/plants/plant-types";
import { isPlantMVHR } from "../../document/entities/plants/utils";
import {
  RoomACRFields,
  RoomEntity,
  RoomType,
  fillDefaultRoomFields,
  isRoomRoomEntity,
} from "../../document/entities/rooms/room-entity";
import { EntityType } from "../../document/entities/types";
import { FlowSystem } from "../../document/flow-systems";
import { getFlowSystem } from "../../document/utils";
import CalculationEngine from "../calculation-engine";
import { TraceCalculation } from "../flight-data-recorder";
import { Edge, VISIT_RESULT_WRONG_WAY } from "../graph";
import { PumpCalculations } from "../pump-calculations";
import { ReturnCalculations } from "../returns";
import {
  CoreContext,
  FlowEdge,
  FlowNode,
  PressurePushMode,
  isEdgeBacked,
} from "../types";
import {
  getBuildingsFromRooms,
  selectLeaderRoomsPerFloor,
} from "../underfloor-heating/utils";
import { FLOW_SOURCE_EDGE, getEffectiveHeatLoad, isFlowSource } from "../utils";
import { DuctCalculations } from "./ducts";

export type VentRecord = {
  sourceUid: string;
  systemUid: string;
  pressureKPA: number;
  upstreamRecords: string[];
  hasMoreThanOneDamper?: boolean;
} & (
  | {
      role: "vent-supply" | "vent-intake" | "vent-exhaust" | "vent-extract";
      parentType: "ahu";
      parent: AirHandlingUnitVentPlantEntity;
    }
  | {
      role: "vent-fan-exhaust" | "vent-extract" | "vent-exhaust";
      parentType: "fan";
      parent: FanEntity;
    }
);

export interface VentGrilleEntry {
  entity: LoadNodeEntity;
  roomUid: string | null;
  flowRate:
    | {
        type: "constant";
        valueLS: number;
      }
    | {
        type: "variable";
        share: number;
      }
    | {
        type: "percentage";
        percentage: number;
      }
    | {
        type: "room";
      }
    | { type: "room-size" };
}

export interface MVHREntry {
  entity: AirHandlingUnitVentPlantEntity;
  efficiencyPct: number;
}

export interface BaseVentZoneRecord {
  supplies: VentGrilleEntry[];
  extracts: VentGrilleEntry[];
  fanExhaustGrilles: VentGrilleEntry[];

  rooms: RoomEntity[];
  // One lead room per separate floor the zone is on
  leadRooms: RoomEntity[];
}

export interface VentZoneRecordWithMVHR extends BaseVentZoneRecord {
  // SEED-1183 add MVHR efficiencies
  mvhrs: MVHREntry[];
}

export interface VentZoneRecordWithResults extends VentZoneRecordWithMVHR {
  totalLS: number;
  dirtyExhaustLS: number;
}

function isRoomDirty(room: RoomEntity) {
  // TODO:
  return false;
}

function getFlowRateEntry(
  context: CoreContext,
  node: VentilationNode,
): VentGrilleEntry["flowRate"] {
  if (node.flowRateType === null) {
    node.flowRateType =
      context.drawing.metadata.heatLoss.grilleFlowRateType || "room";
  }

  switch (node.flowRateType) {
    case "constant":
      return {
        type: "constant",
        valueLS: node.continuousFlowLS,
      };
    case "variable":
      return {
        type: "variable",
        share: node.variableFlowShares,
      };
    case "percentage":
      return {
        type: "percentage",
        percentage: node.percentageFlow,
      };
    case "room":
      return { type: "room" };
    case "room-size":
      return { type: "room-size" };
  }
  assertUnreachable(node.flowRateType);
}

export class VentCalculations {
  @TraceCalculation("Identifying vent systems")
  static identifyVentSystems(
    context: CalculationEngine,
  ): Map<string, VentRecord> {
    const result: Map<string, VentRecord> = new Map();

    for (const obj of context.networkObjects()) {
      if (obj.type === EntityType.PLANT) {
        if (obj.entity.plant.type === PlantType.AHU_VENT) {
          if (obj.entity.plant.supplyUid) {
            result.set(obj.entity.plant.supplyUid, {
              sourceUid: obj.entity.plant.supplyUid,
              systemUid: obj.entity.plant.supplySystemUid,
              pressureKPA: 0,
              role: "vent-supply",
              parentType: "ahu",
              parent: obj.entity as AirHandlingUnitVentPlantEntity,
              upstreamRecords: [],
            });
          }

          if (obj.entity.plant.exhaustUid) {
            result.set(obj.entity.plant.exhaustUid, {
              sourceUid: obj.entity.plant.exhaustUid,
              systemUid: obj.entity.plant.exhaustSystemUid,
              pressureKPA: 0,
              role: "vent-exhaust",
              parentType: "ahu",
              parent: obj.entity as AirHandlingUnitVentPlantEntity,
              upstreamRecords: [],
            });
          }

          if (obj.entity.plant.intakeUid) {
            result.set(obj.entity.plant.intakeUid, {
              sourceUid: obj.entity.plant.intakeUid,
              systemUid: obj.entity.plant.intakeSystemUid,
              pressureKPA: 0,
              role: "vent-intake",
              parentType: "ahu",
              parent: obj.entity as AirHandlingUnitVentPlantEntity,
              upstreamRecords: [],
            });
          }

          if (obj.entity.plant.extractUid) {
            result.set(obj.entity.plant.extractUid, {
              sourceUid: obj.entity.plant.extractUid,
              systemUid: obj.entity.plant.extractSystemUid,
              pressureKPA: 0,
              role: "vent-extract",
              parentType: "ahu",
              parent: obj.entity as AirHandlingUnitVentPlantEntity,
              upstreamRecords: [],
            });
          }
        }
        // TODO: FCU
      }

      if (obj.type === EntityType.DIRECTED_VALVE) {
        if (obj.entity.valve.type === ValveType.FAN) {
          assertType<FanEntity>(obj.entity);
          const fsUid = determineConnectableSystemUid(
            context.globalStore,
            obj.entity,
          );
          if (!fsUid) {
            continue;
          }
          const fs = context.drawing.metadata.flowSystems[fsUid];
          assertType<"vent-fan-exhaust" | "vent-extract" | "vent-exhaust">(
            fs.role,
          );
          result.set(obj.uid, {
            sourceUid: obj.uid,
            systemUid: fs.uid,
            pressureKPA: 0,
            role: fs.role,
            parentType: "fan",
            parent: obj.entity,
            upstreamRecords: [],
          });
        }
      }
    }

    return result;
  }

  @TraceCalculation("Determining AHU and FCU flow rates")
  static determinePlantFlowRates(
    context: CalculationEngine,
    ventRecords: Map<string, VentRecord>,
  ) {
    for (const vent of ventRecords.values()) {
      switch (vent.parentType) {
        case "ahu":
          let flowRateLS = 0;
          const calc = context.globalStore.getOrCreateCalculation(vent.parent);
          const conns = context.globalStore.getConnections(vent.sourceUid);
          if (conns && conns.length > 0) {
            const conduit = context.globalStore.get(conns[0]);
            if (conduit && isDuctEntity(conduit.entity)) {
              const cCalc = context.globalStore.getOrCreateCalculation(
                conduit.entity,
              );
              flowRateLS = cCalc.totalPeakFlowRateLS || 0;
            }
          }

          switch (vent.role) {
            case "vent-supply":
              calc.supplyFlowRateLS = flowRateLS;
            case "vent-extract":
              calc.extractFlowRateLS = flowRateLS;
            case "vent-exhaust":
            case "vent-intake":
              break;
            default:
              assertUnreachable(vent);
          }
          break;
        case "fan":
          const fan = context.globalStore.get<CoreDirectedValve>(
            vent.sourceUid,
          );
          const fCalc = context.globalStore.getOrCreateCalculation(fan.entity);
          const duct = context.globalStore.get<CoreConduit>(
            fan.entity.sourceUid,
          );
          const dCalc = context.globalStore.getOrCreateCalculation(
            duct?.entity,
          );
          fCalc.flowRateLS = dCalc.totalPeakFlowRateLS || 0;

          break;
        default:
          assertUnreachable(vent);
      }
    }
  }

  @TraceCalculation("Determining AHU and FCU fan duties")
  static determinePlantFanDuties(
    context: CalculationEngine,
    ventRecords: Map<string, VentRecord>,
  ) {
    for (const vent of ventRecords.values()) {
      switch (vent.parentType) {
        case "ahu":
          const calc = context.globalStore.getOrCreateCalculation(vent.parent);
          const filled = fillPlantDefaults(context, vent.parent);

          if (
            calc.supplyIndexCircuitPressureDropKPA != null &&
            calc.intakeIndexCircuitPressureDropKPA != null
          ) {
            calc.supplyFanDutyKPA =
              calc.supplyIndexCircuitPressureDropKPA +
              calc.intakeIndexCircuitPressureDropKPA +
              filled.plant.supplyPressureDropPA! / 1000;

            calc.supplyFanDutyString = PumpCalculations.getPumpDutyString(
              context,
              calc.supplyFanDutyKPA,
              calc.supplyFlowRateLS!,
            );
          }

          if (
            calc.extractIndexCircuitPressureDropKPA != null &&
            calc.exhaustIndexCircuitPressureDropKPA != null
          ) {
            calc.extractFanDutyKPA =
              calc.extractIndexCircuitPressureDropKPA +
              calc.exhaustIndexCircuitPressureDropKPA +
              filled.plant.extractPressureDropPA / 1000!;

            calc.extractFanDutyString = PumpCalculations.getPumpDutyString(
              context,
              calc.extractFanDutyKPA,
              calc.extractFlowRateLS!,
            );
          }
          break;
        case "fan":
          const dCalc = context.globalStore.getOrCreateCalculation(vent.parent);
          dCalc.fanDutyKPA =
            (dCalc.exteriorPressureDropKPA || 0) +
            (dCalc.interiorPressureDropKPA || 0) +
            (dCalc.pressureDropKPA || 0);
          break;
        default:
          assertUnreachable(vent);
      }
    }
  }

  /*
  This step needs to be done after supply and extract flow rates are calculated
  because it needs to know the total flow rate of the system.
  */
  @TraceCalculation("Calculating intake and exhaust ventilation flows")
  static calculateExteriorVentilation(
    context: CalculationEngine,
    ventRecords: Map<string, VentRecord>,
  ) {
    for (const vent of ventRecords.values()) {
      if (
        vent.role !== "vent-intake" &&
        vent.role !== "vent-exhaust" &&
        vent.role !== "vent-fan-exhaust"
      ) {
        continue;
      }

      let flowRateLS;
      switch (vent.parentType) {
        case "ahu":
          const pCalc = context.globalStore.getOrCreateCalculation(vent.parent);
          flowRateLS =
            vent.role === "vent-exhaust"
              ? pCalc.extractFlowRateLS
              : pCalc.supplyFlowRateLS;
          break;
        case "fan":
          const fan = context.globalStore.get<CoreDirectedValve>(
            vent.sourceUid,
          );
          const duct = context.globalStore.get<CoreConduit>(
            vent.parent.sourceUid,
          );
          const dCalc = context.globalStore.getOrCreateCalculation(
            duct?.entity,
          );
          flowRateLS = dCalc.totalPeakFlowRateLS || 0;
          break;
        default:
          assertUnreachable(vent);
      }

      if (flowRateLS == undefined) {
        continue;
      }

      const startNode = VentCalculations.getExteriorDfsStartNode(context, vent);
      let { indexNode, indexConnectable, prev } =
        VentCalculations.findExteriorIndexPath(context, startNode, vent);

      if (indexNode && indexConnectable) {
        // size ducts for fans only when they are a flow source
        if (
          (vent.parentType === "fan" && isFlowSource(vent.parent, context)) ||
          vent.parentType === "ahu"
        ) {
          // Size all ducts leading to the index node, ignore everything else.
          let curr = indexNode;
          while (prev.has(curr)) {
            const edge = prev.get(curr)!;
            const conduit = context.globalStore.get(edge.value.uid);

            if (vent.parentType === "ahu") {
              const connectable = edge.to.connectable;
              const ent = context.globalStore.get(connectable);

              if (isFanEntity(ent.entity)) {
                indexConnectable = connectable;
              }
            }

            if (isDuctEntity(conduit.entity)) {
              const cCalc = context.globalStore.getOrCreateCalculation(
                conduit.entity,
              );
              cCalc.isIndexNodePath = true;
              const existingFlowRateLS = cCalc.totalPeakFlowRateLS || 0;
              DuctCalculations.setDuctSizeForFlowRate(
                context,
                conduit.entity,
                flowRateLS + existingFlowRateLS,
              );
            }

            curr = context.flowGraph.sn(edge.from);
          }
        }

        const entityMaxPressuresKPA = new Map<string, number | null>();
        const nodePressureKPA = new Map<string, number | null>();
        context.pushPressureThroughNetwork({
          start: startNode,
          pressureKPA: 0,
          pressurePushMode: PressurePushMode.PSD,
          entityMaxPressuresKPA,
          nodePressureKPA,
        });

        let indexPressureKPA = entityMaxPressuresKPA.get(indexConnectable) || 0;

        // add grill pressure drop if it exists
        const indexObj = context.globalStore.get(indexConnectable);
        if (indexObj.type === EntityType.LOAD_NODE) {
          if (indexObj.entity.node.type === NodeType.VENTILATION) {
            const grillPressureDropKPA = indexObj.getComponentPressureLossKPA();

            if (grillPressureDropKPA.pressureLossKPA) {
              indexPressureKPA -= grillPressureDropKPA.pressureLossKPA;
            }
          }
        }

        switch (vent.parentType) {
          case "ahu":
            const pCalc = context.globalStore.getOrCreateCalculation(
              vent.parent,
            );
            const innerPressureLossKPA =
              vent.role === "vent-exhaust"
                ? pCalc.extractIndexCircuitPressureDropKPA
                : pCalc.supplyIndexCircuitPressureDropKPA;

            if (indexPressureKPA != null && innerPressureLossKPA != null) {
              if (vent.role === "vent-exhaust") {
                pCalc.exhaustIndexCircuitPressureDropKPA = -indexPressureKPA;
              } else {
                pCalc.intakeIndexCircuitPressureDropKPA = -indexPressureKPA;
              }
            }
            break;
          case "fan":
            const dCalc = context.globalStore.getOrCreateCalculation(
              vent.parent,
            );
            if (
              indexPressureKPA != null &&
              dCalc.interiorPressureDropKPA != null
            ) {
              dCalc.exteriorPressureDropKPA = -indexPressureKPA;
            }
            break;
          default:
            assertUnreachable(vent);
        }
      } else {
        // The endpoint was not connected - all pressures 0
        switch (vent.parentType) {
          case "ahu":
            const pCalc = context.globalStore.getOrCreateCalculation(
              vent.parent,
            );
            if (vent.role === "vent-exhaust") {
              pCalc.exhaustIndexCircuitPressureDropKPA = 0;
            } else if (vent.role === "vent-intake") {
              pCalc.intakeIndexCircuitPressureDropKPA = 0;
            }
            break;
          case "fan":
            const dCalc = context.globalStore.getOrCreateCalculation(
              vent.parent,
            );
            dCalc.exteriorPressureDropKPA = 0;
            break;
          default:
            assertUnreachable(vent);
        }
      }
    }
  }

  @TraceCalculation("Find exterior index path for ventilation flow rates")
  static findExteriorIndexPath(
    context: CalculationEngine,
    startNode: FlowNode,
    vent: VentRecord,
  ): {
    indexNode: string | undefined;
    indexConnectable: string | undefined;
    prev: Map<string, Edge<FlowNode, FlowEdge>>;
  } {
    const cumulLength: Map<string, number> = new Map();
    const prev: Map<string, Edge<FlowNode, FlowEdge>> = new Map();
    let indexNode: string | undefined;
    let indexConnectable: string | undefined;
    let indexLength = 0;
    let foundExhaustVent = false;
    let foundFan = false;

    if (!startNode.connectable || !startNode.connection) {
      return { indexNode, indexConnectable, prev };
    }

    context.flowGraph.dfs(startNode, undefined, undefined, (edge) => {
      if (vent.parentType === "fan" && edge.value.uid === vent.sourceUid) {
        return VISIT_RESULT_WRONG_WAY;
      }
      if (foundFan) {
        return;
      }

      const fromS = context.flowGraph.sn(edge.from);
      const toS = context.flowGraph.sn(edge.to);
      const length = cumulLength.get(fromS) || 0;
      let thisLength = 0;

      main: {
        if (!isEdgeBacked(edge.value.type)) {
          break main;
        }
        const conduit = context.globalStore.get(edge.value.uid);
        if (conduit.type !== EntityType.CONDUIT) {
          break main;
        }
        const cCalc = context.globalStore.getOrCreateCalculation(
          conduit.entity,
        );

        thisLength = cCalc.lengthM ?? conduit.computedLengthM;

        const connectable = edge.to.connectable;
        const fs =
          context.drawing.metadata.flowSystems[conduit.entity.systemUid];

        const ent = context.globalStore.get(connectable);

        // when the search started from a fan the search should stop at another fan
        // this is to let the dfs to continue for ahus to find the index node
        const isFan =
          isFanEntity(ent?.entity) && ent.entity.uid !== startNode.connectable;
        if (vent.parentType === "fan" && isFan) {
          foundFan = true;
          indexLength = thisLength + length;
          indexConnectable = edge.to.connectable;
          indexNode = toS;
          break main;
        }

        const isExhaustVent =
          ent.entity.type === EntityType.LOAD_NODE &&
          ent.entity.node.type === NodeType.VENTILATION &&
          ent.entity.node.flowDirection === this.exteriorFlowDirection(fs);
        const isDeadEnd =
          ent.entity.type === EntityType.SYSTEM_NODE ||
          (ent.type === EntityType.FITTING &&
            this.isExteriorPath(fs) &&
            context.globalStore.getConnections(connectable).length === 1);

        if (isExhaustVent) {
          // Update if a longer path is found or no exhaust vent has been found yet
          if (!foundExhaustVent || thisLength + length > indexLength) {
            indexLength = thisLength + length;
            indexConnectable = edge.to.connectable;
            indexNode = toS;
          }
          foundExhaustVent = true;
        } else if (
          thisLength + length > indexLength &&
          !foundExhaustVent &&
          isDeadEnd
        ) {
          // Update if a longer path is found and no exhaust vent has been found yet
          indexLength = thisLength + length;
          indexConnectable = edge.to.connectable;
          indexNode = toS;
        }
      }

      prev.set(toS, edge);
      cumulLength.set(toS, thisLength + length);
    });

    return { indexNode, indexConnectable, prev };
  }

  @TraceCalculation("Identifying ventilation zones for grille sizing")
  static identifyVentilationZones(context: CalculationEngine) {
    // Rooms separated by internal walls are part of the same zone.
    const rooms: CoreRoom[] = [];
    const roomsByLevel = new Map<string, CoreRoom[]>();
    const supplyGrilles = new Map<string, VentGrilleEntry>();
    const extractGrills = new Map<string, VentGrilleEntry>();
    const fanExhaustGrilles = new Map<string, VentGrilleEntry>();
    const room2grilles = new Map<string, Set<string>>();

    for (const obj of context.networkObjects()) {
      if (
        obj.type === EntityType.ROOM &&
        obj.entity.room.roomType === RoomType.ROOM
      ) {
        rooms.push(obj);
        const levelUid = context.globalStore.levelOfEntity.get(obj.uid);
        if (!levelUid) {
          continue;
        }
        if (!roomsByLevel.has(levelUid)) {
          roomsByLevel.set(levelUid, []);
        }
        roomsByLevel.get(levelUid)!.push(obj);
      }
    }

    for (const obj of context.networkObjects()) {
      if (obj.type !== EntityType.LOAD_NODE) continue;
      if (obj.entity.node.type !== NodeType.VENTILATION) continue;
      const system = getFlowSystem(
        context.drawing.metadata.flowSystems,
        determineConnectableSystemUid(context.globalStore, obj.entity),
      );
      if (!system) continue;

      const zoneRoles = ["vent-supply", "vent-extract", "vent-fan-exhaust"];
      const isValidFanExhaust =
        system.role !== "vent-fan-exhaust" ||
        obj.entity.node.flowDirection === "in";
      if (!zoneRoles.includes(system.role) || !isValidFanExhaust) continue;

      // find associated room for grille
      const wc = obj.toWorldCoord();
      const levelUid = context.globalStore.levelOfEntity.get(obj.uid);
      const candidates = levelUid ? roomsByLevel.get(levelUid) || [] : [];

      const ixRooms = candidates?.filter((roomObj) => {
        if (roomObj && roomObj.type === EntityType.ROOM) {
          return roomObj.shape.contains(Flatten.point(wc.x, wc.y));
        }
      });

      if (ixRooms?.length) {
        if (!room2grilles.has(ixRooms[0].uid)) {
          room2grilles.set(ixRooms[0].uid, new Set());
        }
        room2grilles.get(ixRooms[0].uid)!.add(obj.uid);
      }

      const ventGrilleEntry: VentGrilleEntry = {
        entity: obj.entity,
        roomUid: ixRooms?.[0]?.uid ?? null,
        flowRate: getFlowRateEntry(context, obj.entity.node),
      };

      switch (system.role) {
        case "vent-supply":
          supplyGrilles.set(obj.uid, ventGrilleEntry);
          break;
        case "vent-extract":
          extractGrills.set(obj.uid, ventGrilleEntry);
          break;
        case "vent-fan-exhaust":
          fanExhaustGrilles.set(obj.uid, ventGrilleEntry);
          break;
      }
    }

    const zones: BaseVentZoneRecord[] = [];

    const buildings = getBuildingsFromRooms(context, rooms);

    for (const building of buildings) {
      const zone: BaseVentZoneRecord = {
        supplies: [],
        extracts: [],
        fanExhaustGrilles: [],

        rooms: [],
        leadRooms: [],
      };

      for (const room of building) {
        zone.rooms.push(room.entity);

        for (const grilleUid of room2grilles.get(room.uid) || []) {
          if (supplyGrilles.has(grilleUid)) {
            zone.supplies.push(supplyGrilles.get(grilleUid)!);
          } else if (extractGrills.has(grilleUid)) {
            zone.extracts.push(extractGrills.get(grilleUid)!);
          } else if (fanExhaustGrilles.has(grilleUid)) {
            zone.fanExhaustGrilles.push(fanExhaustGrilles.get(grilleUid)!);
          }
        }
      }
      zones.push(zone);

      zone.leadRooms = Array.from(
        selectLeaderRoomsPerFloor(context, zone.rooms).values(),
      );

      for (const lead of zone.leadRooms) {
        const leadRoomCalc = context.globalStore.getOrCreateCalculation(lead);
        leadRoomCalc.isZoneLeader = true;
      }
    }
    return zones;
  }

  static addVentFlowRateToZones(
    context: CalculationEngine,
    zones: VentZoneRecordWithMVHR[],
  ): VentZoneRecordWithResults[] {
    const newZones = zones.map((z) => {
      return {
        ...z,
        totalLS: 0,
        dirtyExhaustLS: 0,
        totalAreaM2: 0,
      } as VentZoneRecordWithResults;
    });
    for (const zone of newZones) {
      let totalAreaM2 = 0;
      let numRooms = 0;
      let numBedrooms = 0;
      for (const room of zone.rooms) {
        if (room.room.roomType === RoomType.ROOM) {
          numRooms++;
          if (room.room.spaceType && hasBedroom(room.room.spaceType)) {
            numBedrooms++;
          }
        }
        const roomCalc = context.globalStore.getOrCreateCalculation(room);

        if (roomCalc.areaM2) {
          totalAreaM2 += roomCalc.areaM2;
        }

        if (roomCalc.ventilationFlowRateLS != null) {
          zone.totalLS += roomCalc.ventilationFlowRateLS;
          if (isRoomDirty(room)) {
            zone.dirtyExhaustLS += roomCalc.ventilationFlowRateLS;
          }
        }
      }

      if (
        context.drawing.metadata.heatLoss.ventAirChangesRateStandard ===
        "UKBuildingRegs2021Dwellings"
      ) {
        const bedroomBasedLimitLS = numRooms === 1 ? 13 : 13 + 6 * numBedrooms;

        let areaBasedLimitLS = totalAreaM2 * 0.3;

        zone.totalLS = Math.max(
          bedroomBasedLimitLS,
          areaBasedLimitLS,
          zone.totalLS,
        );
      }
    }

    return newZones;
  }

  static addMVHRToZones(
    context: CalculationEngine,
    zones: BaseVentZoneRecord[],
    records: Map<string, VentRecord>,
  ): VentZoneRecordWithMVHR[] {
    const newZones = zones.map((z) => {
      return {
        ...z,
        mvhrs: [],
      } as VentZoneRecordWithMVHR;
    });

    for (const vent of records.values()) {
      if (vent.parentType !== "ahu") continue;
      if (!isPlantMVHR(vent.parent.plant)) continue;
      if (vent.role !== "vent-supply") continue;

      // with mvhr we only care about heating
      for (const room of vent.parent.plant.heatingRooms ?? []) {
        const zone = newZones.find((z) => z.rooms.some((r) => r.uid === room));

        if (!zone) continue;
        if (zone.mvhrs.some((m) => m.entity.uid === vent.parent.uid)) continue;

        const filled = fillPlantDefaults(context, vent.parent);
        zone.mvhrs.push({
          entity: vent.parent,
          efficiencyPct: filled.plant.heatRecoveryEfficiencyPct!,
        });
      }
    }

    return newZones;
  }

  static calculateRoomAirChangeRateLS(
    roomACRFields: RoomACRFields,
    areaM2: number,
    volumeM3: number,
    specs: AirChangeRateValueSpec[],
  ) {
    let acrLS = 0;
    for (const spec of specs) {
      switch (spec.unit) {
        case ACRUnits.ACH:
          acrLS += roomACRFields.achACR! * ((volumeM3 * 1000) / 3600);
          break;
        case ACRUnits.CfmPerF2:
          acrLS += cfm2ls(roomACRFields.cfmft2ACR! * m2tof2(areaM2));
          break;
        case ACRUnits.Cfm:
          acrLS += cfm2ls(roomACRFields.cfmACR!);
          break;
        case ACRUnits.Ls:
          acrLS += roomACRFields.lpsACR!;
          break;
        case ACRUnits.LsPerBath:
          acrLS += roomACRFields.lpsBathACR! * (roomACRFields.bathCount ?? 0);
          break;
        case ACRUnits.LsPerM2:
          acrLS += roomACRFields.lpsm2ACR! * areaM2;
          break;
        case ACRUnits.LsPerMachine:
          acrLS +=
            roomACRFields.lpsMachineACR! * (roomACRFields.machineCount ?? 0);
          break;
        case ACRUnits.LsPerPerson:
          acrLS +=
            roomACRFields.lpsPersonACR! * (roomACRFields.personCount ?? 0);
          break;
        case ACRUnits.LsPerShower:
          acrLS +=
            roomACRFields.lpsShowerACR! * (roomACRFields.showerCount ?? 0);
          break;
        case ACRUnits.LsPerUrinal:
          acrLS +=
            roomACRFields.lpsUrinalACR! * (roomACRFields.urinalCount ?? 0);
          break;
        case ACRUnits.LsPerWC:
          acrLS += roomACRFields.lpsWCACR! * (roomACRFields.wcCount ?? 0);
          break;
        case ACRUnits.LsPerRoom:
          acrLS += roomACRFields.lpsRoomACR! * (roomACRFields.roomCount ?? 0);
          break;
        case ACRUnits.M3s:
          acrLS += roomACRFields.cmsACR! * 1000;
          break;
        default:
          assertUnreachable(spec.unit);
      }
    }

    return acrLS;
  }

  static calculateChimneyAirChangeRateLS(
    context: CoreContext,
    chimneySetting: ChimneyType,
    volumeM3: number,
  ) {
    const chimneySpec = getEffectiveHeatLoad(context.catalog, context.drawing)
      .chimneySpec[chimneySetting];

    if (!chimneySpec) return 0;

    /**
     * Find the range
     */
    const parseSpec: {
      key: number;
      value: number;
    }[] = Object.entries(chimneySpec)
      .map(([key, value]) => {
        return {
          key: Number(key),
          value: Number(value),
        };
      })
      .sort((a, b) => {
        return a.key - b.key;
      });

    for (const ent of parseSpec) {
      if (ent.key >= volumeM3) {
        return ach2ls(Number(ent.value), volumeM3);
      }
    }
    return ach2ls(Number(parseSpec[parseSpec.length - 1].value), volumeM3);
  }

  static dfsHelperFromGrilleToVentSourceUid(
    engine: CalculationEngine,
    start: { connectable: string; connection: string },
    vent: VentRecord,
  ) {
    engine.flowGraph.dfsRecursive(start, (node) => {
      // detect grills
      const connectable = engine.globalStore.get(node.connectable);
      if (
        connectable.type === EntityType.LOAD_NODE &&
        connectable.entity.node.type === NodeType.VENTILATION
      ) {
        engine.ventLoadNodeToVentRecord.set(connectable.uid, vent.sourceUid);
      }
    });
  }

  @TraceCalculation("Calculating room flow rates")
  static calculateRoomFlowRates(context: CalculationEngine) {
    const networkObjects = context.networkObjects();
    for (const o of networkObjects) {
      if (o.type === EntityType.ROOM && isRoomRoomEntity(o.entity)) {
        const filled = fillDefaultRoomFields(context, o.entity).room;

        const calc = context.globalStore.getOrCreateCalculation(o.entity);
        const volumeM3 = calc.volumeM3 || 0;
        const effectiveHeatLoad = getEffectiveHeatLoad(
          context.catalog,
          context.drawing,
        );

        const ventRoomAirChangeRateLS = this.calculateRoomAirChangeRateLS(
          filled.ventACR,
          o.areaM2,
          volumeM3,
          effectiveHeatLoad.ventAirChangeRate[filled.spaceType!],
        );

        const heatingRoomAirChangeRateLS = this.calculateRoomAirChangeRateLS(
          filled.heatingACR,
          o.areaM2,
          volumeM3,
          effectiveHeatLoad.heatingAirChangeRate[filled.heatingSpaceType!],
        );

        let chimneyAirChangeRateLS = 0;
        if (filled.chimneySetting !== "no") {
          chimneyAirChangeRateLS += this.calculateChimneyAirChangeRateLS(
            context,
            filled.chimneySetting,
            volumeM3,
          );
        }

        const ventTotalAirChangeRateLS =
          ventRoomAirChangeRateLS + chimneyAirChangeRateLS;
        const heatingTotalAirChangeRateLS =
          heatingRoomAirChangeRateLS + chimneyAirChangeRateLS;

        calc.ventAirChangeRatePerHour = ls2ach(
          ventTotalAirChangeRateLS,
          volumeM3,
        );
        calc.heatingAirChangeRatePerHour = ls2ach(
          heatingTotalAirChangeRateLS,
          volumeM3,
        );
        calc.ventilationFlowRateLS = ventTotalAirChangeRateLS;
        calc.heatingFlowRateLS = heatingTotalAirChangeRateLS;
      }
    }
  }

  static calculateGrilleFlowRates(
    context: CalculationEngine,
    zones: VentZoneRecordWithResults[],
  ) {
    const { globalStore, ventRecords } = context;
    for (const vent of ventRecords.values()) {
      if (
        vent.role !== "vent-supply" &&
        vent.role !== "vent-extract" &&
        vent.role !== "vent-fan-exhaust" &&
        vent.role !== "vent-exhaust"
      ) {
        continue;
      }
      if (vent.parentType !== "ahu") continue;

      if (isPlantMVHR(vent.parent.plant)) {
        this.dfsHelperFromGrilleToVentSourceUid(
          context,
          {
            connectable: vent.sourceUid,
            connection: FLOW_SOURCE_EDGE,
          },
          vent,
        );
      }
    }

    for (const zone of zones) {
      let roomTempWeightedSum = 0;
      let roomVolumeSum = 0;
      for (const room of zone.rooms) {
        const roomCalc = globalStore.getOrCreateCalculation(room);
        if (!roomCalc.volumeM3) continue;
        const filledRoom = fillDefaultRoomFields(context, room);
        if (filledRoom.room.roomType !== RoomType.ROOM) continue;
        roomTempWeightedSum +=
          filledRoom.room.roomTemperatureC! * roomCalc.volumeM3!;
        roomVolumeSum += roomCalc.volumeM3!;
      }
      const roomTemp =
        roomVolumeSum > 0 ? roomTempWeightedSum / roomVolumeSum : 21;
      let zoneExtractVentFlowRateAddressedLS = 0;
      let zoneSupplyVentFlowRateAddressedLS = 0;
      for (const [systemType, grilleSet] of [
        ["supply", zone.supplies],
        // Note: Currently we don't support fan exhaust grilles.
        ["extract", zone.extracts.concat(zone.fanExhaustGrilles)],
      ] as ["extract" | "supply", VentGrilleEntry[]][]) {
        const findMVHRForGrille = (
          grille: VentGrilleEntry,
        ): AirHandlingUnitVentPlantEntity | null => {
          const grillUid = grille.entity.uid;
          const ventSourceUid = context.ventLoadNodeToVentRecord.get(grillUid);
          if (!ventSourceUid) return null;
          const vent = ventRecords.get(ventSourceUid);
          if (
            !vent ||
            vent.parentType !== "ahu" ||
            !isPlantMVHR(vent.parent.plant)
          )
            return null;
          return vent.parent;
        };
        const distributeFlowRateToGrilles = (
          totalLS: number,
          grilleFiltered: VentGrilleEntry[],
          accessRoomRateLS: (room: CoreRoom) => number,
        ): Map<VentGrilleEntry, number> => {
          const flowRateCache = new Map<VentGrilleEntry, number>();
          let remainingLS = totalLS;
          // calculate flow rates for percentage and constant flow rates
          for (const grille of grilleFiltered) {
            flowRateCache.set(grille, 0);

            if (grille.flowRate.type === "constant") {
              flowRateCache.set(grille, grille.flowRate.valueLS);
            }

            if (grille.flowRate.type === "percentage") {
              flowRateCache.set(
                grille,
                (grille.flowRate.percentage / 100) * totalLS,
              );
            }

            remainingLS -= flowRateCache.get(grille)!;
          }

          // create a room to grilles cache
          let totalGrillArea = 0;
          const room2Grill = new Map<string, VentGrilleEntry[]>();
          for (const grille of grilleFiltered) {
            if (
              grille.flowRate.type === "room-size" ||
              grille.flowRate.type === "room"
            ) {
              if (!grille.roomUid) continue;
              const room = globalStore.get(grille.roomUid);
              if (!room || room.type !== EntityType.ROOM) continue;

              if (!room2Grill.has(room.uid)) {
                room2Grill.set(room.uid, []);
                const roomCalc = globalStore.getOrCreateCalculation(
                  room.entity,
                );
                totalGrillArea += roomCalc.areaM2 ?? 0;
              }
              room2Grill.get(room.uid)!.push(grille);
            }
          }

          // calculate flow rates for room grilles
          for (const [roomUid, grilles] of room2Grill) {
            const room = globalStore.get(roomUid);
            if (!room || room.type !== EntityType.ROOM) continue;

            const roomGrilles = grilles.filter(
              (g) => g.flowRate.type === "room",
            );

            for (const grille of roomGrilles) {
              flowRateCache.set(
                grille,
                accessRoomRateLS(room) / roomGrilles.length,
              );

              remainingLS -= flowRateCache.get(grille)!;
            }
          }

          // calculate flow rates for room-size grilles
          if (totalGrillArea > 0) {
            const litrePerM2 = remainingLS / totalGrillArea;
            for (const [roomUid, grilles] of room2Grill) {
              const room = globalStore.get(roomUid);
              if (!room || room.type !== EntityType.ROOM) continue;

              const roomSizeGrilles = grilles.filter(
                (g) => g.flowRate.type === "room-size",
              );

              const roomCalc = globalStore.getOrCreateCalculation(room.entity);
              for (const grille of roomSizeGrilles) {
                flowRateCache.set(
                  grille,
                  (litrePerM2 * (roomCalc.areaM2 ?? 0)) /
                    roomSizeGrilles.length,
                );
                remainingLS -= flowRateCache.get(grille)!;
              }
            }
          }

          // calculate flow rates for variable grilles
          let totalShares = 0;
          for (const grille of grilleFiltered) {
            if (grille.flowRate.type === "variable") {
              totalShares += grille.flowRate.share;
            }
          }
          if (totalShares > 0) {
            const variableFlowRatePerShare = Math.max(
              0,
              remainingLS / totalShares,
            );
            for (const grille of grilleFiltered) {
              if (grille.flowRate.type === "variable") {
                flowRateCache.set(
                  grille,
                  grille.flowRate.share * variableFlowRatePerShare,
                );
                remainingLS -= flowRateCache.get(grille)!;
              }
            }
          }
          return flowRateCache;
        };

        let involvedMVHREntity: AirHandlingUnitVentPlantEntity | null = null;
        // Assume there is at most 1 unique MVHR in real life
        for (const grille of grilleSet) {
          const mvhr = findMVHRForGrille(grille);
          if (mvhr) {
            involvedMVHREntity = mvhr;
            break;
          }
        }

        let remainingLS = zone.totalLS;
        if (
          involvedMVHREntity &&
          involvedMVHREntity.plant.increaseFlowRateBasedOnHeatLoad
        ) {
          const involvedMVHREntiryfilled = fillPlantDefaults(
            context,
            involvedMVHREntity,
          );
          const involvedMVHR = involvedMVHREntiryfilled.plant;
          const ans1 = distributeFlowRateToGrilles(
            remainingLS,
            grilleSet,
            (r) =>
              context.globalStore.getOrCreateCalculation(r.entity)
                .ventilationFlowRateLS ?? 0,
          );
          const system = getFlowSystem(
            context.drawing,
            involvedMVHR.supplySystemUid,
          );
          if (!system) {
            throw new Error(
              "System not found for uid " + involvedMVHR.supplySystemUid,
            );
          }
          const airTempMVHR = involvedMVHR.airTemperatureMVHR ?? 50;
          const heatNeededTotalW = zone.rooms.reduce(
            (prevSum: number, roomEnt: RoomEntity): number =>
              prevSum +
              globalStore.getOrCreateCalculation(roomEnt).totalHeatLossWatt!,
            0,
          );
          const airDensity = Number(
            context.catalog.fluids[system.fluid].densityKGM3,
          );
          const flowRateNeededForHeatLS =
            ReturnCalculations.heatLoadToFlowRateLS(
              context,
              involvedMVHR.supplySystemUid,
              airTempMVHR,
              roomTemp,
              heatNeededTotalW / 1000,
            );
          const ans2 = distributeFlowRateToGrilles(
            flowRateNeededForHeatLS,
            grilleSet.filter((g) => {
              const mvhr = findMVHRForGrille(g);
              return mvhr && isPlantMVHR(mvhr.plant);
            }),
            (room: CoreRoom) => {
              const roomCalc = context.globalStore.getOrCreateCalculation(
                room.entity,
              );
              return Math.max(
                ReturnCalculations.heatLoadToFlowRateLS(
                  context,
                  involvedMVHR.supplySystemUid,
                  airTempMVHR,
                  roomTemp,
                  roomCalc.totalHeatLossWatt! / 1000,
                ),
                roomCalc.ventilationFlowRateLS ?? 0,
              );
            },
          );
          for (const [grille, flowRateLS] of ans2) {
            ans1.set(grille, Math.max(flowRateLS, ans1.get(grille) ?? 0));
            remainingLS -= ans1.get(grille)!;
          }
          const grilleSetRemaining = grilleSet.filter((g) => !ans2.has(g));
          const ans3 = distributeFlowRateToGrilles(
            Math.max(0, remainingLS),
            grilleSetRemaining,
            (r) =>
              context.globalStore.getOrCreateCalculation(r.entity)
                .ventilationFlowRateLS ?? 0,
          );
          for (const [grille, flowRateLS] of ans3) {
            ans1.set(grille, flowRateLS);
            remainingLS -= flowRateLS;
          }
          for (const [grille, flowRateLS] of ans1) {
            const grilleCalc = context.globalStore.getOrCreateCalculation(
              grille.entity,
            );
            grilleCalc.flowRateLS = flowRateLS;
          }
        } else {
          const ansToUpdate = distributeFlowRateToGrilles(
            remainingLS,
            grilleSet,
            (r) =>
              context.globalStore.getOrCreateCalculation(r.entity)
                .ventilationFlowRateLS ?? 0,
          );
          for (const [grille, flowRateLS] of ansToUpdate) {
            const grilleCalc = context.globalStore.getOrCreateCalculation(
              grille.entity,
            );
            remainingLS -= grilleCalc.flowRateLS = flowRateLS;
          }
        }

        switch (systemType) {
          case "extract":
            zoneExtractVentFlowRateAddressedLS = zone.totalLS - remainingLS;
            break;
          case "supply":
            zoneSupplyVentFlowRateAddressedLS = zone.totalLS - remainingLS;
            break;
          default:
            assertUnreachable(systemType);
        }
      }

      // propagate zone results to all rooms
      for (const room of zone.rooms) {
        const calc = context.globalStore.getOrCreateCalculation(room);
        calc.zoneVentFlowRateLS = zone.totalLS;
        calc.zoneSupplyVentFlowRateAddressedLS =
          zoneSupplyVentFlowRateAddressedLS;
        calc.zoneExtractVentFlowRateAddressedLS =
          zoneExtractVentFlowRateAddressedLS;
      }
    }
  }

  static getExteriorDfsStartNode(
    context: CalculationEngine,
    vent: VentRecord,
  ): FlowNode {
    switch (vent.parentType) {
      case "ahu":
        return {
          connectable: vent.sourceUid,
          connection: FLOW_SOURCE_EDGE,
        };
      case "fan":
        const conns = context.globalStore.getConnections(vent.parent.uid);
        const other = conns.find((c) => c !== vent.parent.sourceUid)!;
        return {
          connectable: vent.parent.uid,
          connection: other,
        };
    }

    assertUnreachable(vent);
  }

  static isExteriorPath(fs: FlowSystem) {
    return fs.role === "vent-exhaust" || fs.role === "vent-intake";
  }

  static exteriorFlowDirection(fs: FlowSystem) {
    if (fs.role === "vent-intake") {
      return "in";
    }
    return "out";
  }

  @TraceCalculation("Calculating ventilation flow rates")
  static setExteriorFlowDirections(
    context: CalculationEngine,
    ventRecords: Map<string, VentRecord>,
  ) {
    for (const vent of ventRecords.values()) {
      if (vent.role !== "vent-fan-exhaust") {
        continue;
      }
      const startNode = VentCalculations.getExteriorDfsStartNode(context, vent);
      const { indexNode, indexConnectable, prev } =
        VentCalculations.findExteriorIndexPath(context, startNode, vent);

      if (indexNode && indexConnectable) {
        let curr = indexNode;

        while (prev.has(curr)) {
          const edge = prev.get(curr)!;
          const conduit = context.globalStore.get(edge.value.uid);
          if (isDuctEntity(conduit.entity)) {
            const cCalc = context.globalStore.getOrCreateLiveCalculation(
              conduit.entity,
            );
            const oCalc = context.globalStore.getOrCreateCalculation(
              conduit.entity,
            );
            cCalc.connected = true;
            const flowFrom = edge.to.connectable;
            cCalc.flowFrom = flowFrom;
            oCalc.flowFrom = flowFrom;
          }

          curr = context.flowGraph.sn(edge.from);
        }
      }
    }
  }
}
