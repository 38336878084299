import { assertUnreachable } from "../../lib/utils";
import {
  isCoolingPlantSystem,
  isDrainage,
  isGas,
  isVentilation,
} from "../config";
import { isCalculated } from "../document/calculations-objects";
import { CalculatableEntityConcrete } from "../document/entities/concrete-entity";
import { ValveType } from "../document/entities/directed-valves/valve-types";
import { PlantType } from "../document/entities/plants/plant-types";
import {
  RoomEntity,
  fillDefaultRoomFields,
} from "../document/entities/rooms/room-entity";
import { EntityType } from "../document/entities/types";
import CalculationEngine from "./calculation-engine";
import { CoreContext } from "./types";
import { getEntitySystem } from "./utils";

/**
 * Assign reference to entity in hydraulic entities
 */
export function addHydraulicReferences(context: CalculationEngine): void {
  const consecutiveReferences = new Map<string, number>();
  const usedReferences = new Map<string, Set<number>>();

  function getBaseKey(entity: CalculatableEntityConcrete): string | null {
    const entitySystemUid = getEntitySystem(entity, context.globalStore)!;
    const firstRef = systemLayoutRef(context, entitySystemUid, entity);
    const secondRef = getEntityReferenceName(entity, context);
    const levelUid = context.globalStore.levelOfEntity.get(entity.uid);

    if (firstRef && secondRef) {
      const thirdRef =
        // R assuming risers are the only entities that don't have a level.
        context.drawing.levels[levelUid || ""]?.abbreviation || "R";
      const key = `${firstRef}-${secondRef}-${thirdRef}`;
      const id = getNextId([key]);

      return key;
    } else {
      return null;
    }
  }

  function getNextId(keys: string[]) {
    const consecutiveCandidate = Math.max(
      ...keys.map((key) => consecutiveReferences.get(key) || 1),
    );

    let idToUse = consecutiveCandidate;
    for (const key of keys) {
      if (!usedReferences.has(key)) {
        usedReferences.set(key, new Set());
      }
    }

    while (keys.some((key) => usedReferences.get(key)!.has(idToUse))) {
      idToUse++;
    }

    return idToUse;
  }

  function markId(keys: string[], idUsed: number) {
    for (const key of keys) {
      if (!usedReferences.has(key)) {
        usedReferences.set(key, new Set());
      }

      usedReferences.get(key)!.add(idUsed);

      let curr = consecutiveReferences.get(key) || 0;
      while (usedReferences.get(key)!.has(curr)) {
        curr++;
      }

      consecutiveReferences.set(key, curr);
    }
  }

  function getEntityReferenceName(
    entity: CalculatableEntityConcrete,
    context: CoreContext,
  ): string | null {
    switch (entity.type) {
      case EntityType.FLOW_SOURCE:
        return "FS";
      case EntityType.CONDUIT:
        return "P";
      case EntityType.FITTING:
        return "F";
      case EntityType.FIXTURE:
      case EntityType.LOAD_NODE:
        return "X";
      case EntityType.DIRECTED_VALVE:
        if (entity.valve.type === ValveType.GAS_REGULATOR) {
          return "R";
        } else {
          return "V";
        }
      case EntityType.BIG_VALVE:
        return "V";
      case EntityType.PLANT:
        switch (entity.plant.type) {
          case PlantType.CUSTOM:
            return "CP";
          case PlantType.RETURN_SYSTEM:
            switch (entity.plant.returnType) {
              case "heatSource":
                if (
                  isCoolingPlantSystem(
                    context.drawing.metadata.flowSystems[
                      entity.plant.outlets[0].outletSystemUid
                    ],
                  )
                ) {
                  return "CWP";
                } else {
                  return "HWP";
                }
              case "pressure":
                return "HWP";
              default:
                assertUnreachable(entity.plant.returnType);
            }
          case PlantType.TANK:
            return "TP";
          case PlantType.PUMP:
            return "PP";
          case PlantType.DRAINAGE_PIT:
            return "PT";
          case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
            return "GT";
        }
      case EntityType.GAS_APPLIANCE:
        return "A";
      case EntityType.DAMPER:
        return "DP";
      case EntityType.SYSTEM_NODE:
      case EntityType.MULTIWAY_VALVE:
      case EntityType.RISER:
      case EntityType.COMPOUND:
      case EntityType.VERTEX:
      case EntityType.EDGE:
      case EntityType.ROOM:
      case EntityType.WALL:
      case EntityType.FENESTRATION:
      case EntityType.ARCHITECTURE_ELEMENT:
      case EntityType.AREA_SEGMENT:
        return null;
    }
    assertUnreachable(entity);
  }

  function systemLayoutRef(
    context: CoreContext,
    flowSystemUid: string,
    entity: CalculatableEntityConcrete,
  ): string {
    switch (entity.type) {
      case EntityType.GAS_APPLIANCE:
        return "G";
      case EntityType.PLANT:
        switch (entity.plant.type) {
          case PlantType.DRAINAGE_PIT:
          case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
            return "S";
          case PlantType.RETURN_SYSTEM:
            switch (entity.plant.returnType) {
              case "heatSource":
                if (
                  isCoolingPlantSystem(
                    context.drawing.metadata.flowSystems[
                      entity.plant.outlets[0].outletSystemUid
                    ],
                  )
                ) {
                  return "W";
                } else {
                  return "G";
                }
              case "pressure":
                return "G";
              default:
                assertUnreachable(entity.plant.returnType);
            }
          case PlantType.AHU_VENT:
            return "V";
          case PlantType.CUSTOM:
          case PlantType.TANK:
          case PlantType.PUMP:
          default:
            break;
        }
      case EntityType.FLOW_SOURCE:
      case EntityType.CONDUIT:
      case EntityType.FITTING:
      case EntityType.FIXTURE:
      case EntityType.LOAD_NODE:
      case EntityType.DIRECTED_VALVE:
      case EntityType.BIG_VALVE:
      case EntityType.RISER:
      default:
        break;
    }

    if (isDrainage(context.drawing.metadata.flowSystems[flowSystemUid])) {
      return "S";
    }

    if (isGas(context.drawing.metadata.flowSystems[flowSystemUid])) {
      return "G";
    }

    if (isVentilation(context.drawing.metadata.flowSystems[flowSystemUid])) {
      return "V";
    }

    return "W";
  }

  for (const obj of Array.from(context.drawableObjects())) {
    if (!isCalculated(obj.entity)) {
      continue;
    }

    const drawableCalc = context.globalStore.getOrCreateCalculation(obj.entity);
    if (!drawableCalc.expandedEntities) {
      continue;
    }

    if (drawableCalc.expandedEntities.length === 1) {
      // Format ex: H-P-G-1
      const key = getBaseKey(drawableCalc.expandedEntities[0]);

      if (key) {
        const id = getNextId([key]);
        const calculation = context.globalStore.getOrCreateCalculation(
          drawableCalc.expandedEntities[0],
        );
        const ogCalc = context.globalStore.getOrCreateCalculation(obj.entity);

        calculation.reference = ogCalc.reference = `${key}-${id}`;

        markId([key], id);
      }
    } else if (drawableCalc.expandedEntities.length > 1) {
      // Format ex: H-P-1-1 where first number is group id, second is id within group.
      // The id of the group will be the smallest id that is available for all the types
      // in the group. That group ID will also be used up for all item types in the group.
      // The effect is, ids for an item type are not necessaily consecutive, and are still
      // unique, and within a group, the third item in the reference is stable.
      const keys: string[] = [];
      for (const e of drawableCalc.expandedEntities) {
        const key = getBaseKey(e);
        if (key) {
          keys.push(key);
        }
      }

      const id = getNextId(keys);

      let secondaryId = 1;
      let backupKey: string | null = null;
      for (const e of drawableCalc.expandedEntities) {
        const key = getBaseKey(e);
        if (key) {
          const calculation = context.globalStore.getOrCreateCalculation(e);

          calculation.reference = `${key}-${id}-${secondaryId}`;

          secondaryId++;
          backupKey = backupKey ?? key;
        }
      }

      const ogKey = getBaseKey(obj.entity);
      if (ogKey ?? backupKey) {
        const ogCalc = context.globalStore.getOrCreateCalculation(obj.entity);
        ogCalc.reference = `${ogKey ?? backupKey}-${id}`;
      }

      markId(keys, id);
    }
  }
}

/**
 * Assign reference to architecture entities
 * - Room
 */
export function addArchitectureReference(context: CalculationEngine): void {
  function filterRoomEntities(context: CalculationEngine): RoomEntity[] {
    let roomEntities: RoomEntity[] = [];
    for (const obj of Array.from(context.networkObjects())) {
      if (obj.type === EntityType.ROOM) {
        roomEntities.push(obj.entity);
      }
    }

    return roomEntities;
  }

  const namingCollisions = new Map<string, Set<RoomEntity>>();
  const nameSet = new Set<string>();
  const roomEntities = filterRoomEntities(context);

  for (const room of roomEntities) {
    let filledRoomEntity = fillDefaultRoomFields(context, room);
    const roomName = filledRoomEntity.entityName;

    if (roomName != null) {
      if (!namingCollisions.has(roomName)) {
        namingCollisions.set(roomName, new Set());
      }
      const roomSet = namingCollisions.get(roomName);
      roomSet?.add(room);
      nameSet.add(roomName);
    } else {
      // Fill default should handle assign a stub name. If it didn't, something went wrong
      throw new Error("Room entity without assigned entity name.");
    }
  }

  namingCollisions.forEach((roomSet, roomName) => {
    if (roomSet.size === 1) {
      const room = [...roomSet.values()][0];
      const calculation = context.globalStore.getOrCreateCalculation(room);
      calculation.reference = roomName;
    } else {
      let id = 1;
      for (const room of roomSet) {
        const calculation = context.globalStore.getOrCreateCalculation(room);

        let reference = `${roomName} ${id}`;
        while (nameSet.has(reference)) {
          id++;
          reference = `${roomName} ${id}`;
        }
        calculation.reference = `${roomName} ${id}`;
        id++;
      }
    }
  });
}
