import {
  CoreContext,
  CostBreakdown,
  PressureLossResult,
} from "../calculations/types";
import { CalculationConcrete } from "../document/calculations-objects/calculation-concrete";
import { CalculatableEntityConcrete } from "../document/entities/concrete-entity";
import { LineEntity } from "../document/entities/lines/line-entity";
import { EntityType } from "../document/entities/types";
import { CoreBaseEdge } from "./core-traits/coreBaseEdge";
import CoreBaseBackedObject from "./lib/coreBaseBackedObject";

const Base = CoreBaseEdge(CoreBaseBackedObject<LineEntity>);

export default class CoreLine extends Base {
  type: EntityType.LINE = EntityType.LINE;
  getFrictionPressureLossKPA(): PressureLossResult {
    throw new Error("Line entity does not have head loss");
  }

  getCalculationEntities(context: CoreContext): CalculatableEntityConcrete[] {
    return [];
  }

  collectCalculations(context: CoreContext): CalculationConcrete {
    throw new Error("Not applicable to line entity");
  }

  costBreakdown(context: CoreContext): CostBreakdown | null {
    return null;
  }
}
