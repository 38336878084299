import { isGas, isVentilation } from "../../../../../common/src/api/config";
import DirectedValveEntity, {
  fillDirectedValveFields,
} from "../../../../../common/src/api/document/entities/directed-valves/directed-valve-entity";
import { ValveType } from "../../../../../common/src/api/document/entities/directed-valves/valve-types";
import {
  Units,
  UnitsContext,
  convertPipeDiameterFromMetric,
} from "../../../../../common/src/lib/measurements";
import { assertUnreachable } from "../../../../../common/src/lib/utils";
import { CoreContext } from "../../calculations/types";
import { CalculationField, FieldCategory } from "./calculation-field";
import {
  CalcFieldSelector,
  Calculation,
  CalculationType,
  LiveCalculation,
  NameCalculation,
  PressureCalculation,
  addPressureCalculationFields,
} from "./types";
import { getFlowSystemLayouts } from "./utils";

export default interface DirectedValveCalculation
  extends Calculation,
    PressureCalculation,
    NameCalculation {
  type: CalculationType.DirectedValveCalculation;
  flowRateLS: number | null;
  pressureDropKPA: number | null;
  kvValue: number | null;
  sizeMM: number | null;
  // SEED-1159 add Fan fields
  interiorPressureDropKPA: number | null; // from grilles -> fan
  exteriorPressureDropKPA: number | null; // from fan -> exhaust vent
  fanDutyKPA: number | null; // total pressure from interior + exterior + fan internal pressure drop
}

export interface DirectedValveLiveCalculation extends LiveCalculation {}

export const FastLiveDirectedValveCalculationFields: CalcFieldSelector<DirectedValveLiveCalculation> =
  {
    connected: true,
  };

export function makeDirectedValveCalculationFields(
  context: CoreContext,
  entity: DirectedValveEntity,
): CalculationField[] {
  const { catalog, drawing, globalStore } = context;
  const systems = drawing.metadata.flowSystems;
  const filled = fillDirectedValveFields(context, entity);
  const systemUid = filled.systemUidOption!;

  const valveIsGas = catalog && systemUid && isGas(systems[systemUid]);
  const valveIsVentilation =
    catalog && systemUid && isVentilation(systems[systemUid]);

  let fields: CalculationField[] = [];

  const { layouts, layoutsExcept, onlyLayouts } = getFlowSystemLayouts(
    systems[filled.systemUidOption!],
  );

  fields.push({
    property: "reference",
    title: "Reference",
    short: "",
    shortTitle: "",
    units: Units.None,
    category: FieldCategory.EntityName,
    systemUid: systemUid,
    layouts,
    defaultEnabled: false,
  });

  if (!valveIsGas) {
    fields.push(
      {
        property: "flowRateLS",
        title: "Peak Flow Rate",
        short: "",
        shortTitle: "",
        units: Units.LitersPerSecond,
        unitContext: valveIsVentilation ? UnitsContext.VENTILATION : undefined,
        category: FieldCategory.FlowRate,
        layouts: layoutsExcept("drainage"),
        systemUid,
      },
      {
        property: "pressureDropKPA",
        title: "Pressure Drop (PD)",
        short: "",
        shortTitle: "PD",
        defaultEnabled: false,
        units: Units.KiloPascals,
        unitContext: valveIsVentilation ? UnitsContext.VENTILATION : undefined,
        category: FieldCategory.Pressure,
        layouts: layoutsExcept("drainage"),
        systemUid,
      },
    );
  }

  fields.push({
    property: "kvValue",
    title: "Kv Value",
    short: "",
    shortTitle: "Kv",
    units: Units.Kv,
    category: FieldCategory.Pressure,
    layouts: layoutsExcept("drainage"),
    systemUid,
  });

  if (!valveIsGas) {
    addPressureCalculationFields(
      context,
      fields,
      systemUid,
      layoutsExcept("drainage"),
    );
  }

  if (filled.systemUidOption) {
    fields = fields.map((f) => {
      f.systemUid = entity.systemUidOption!;
      return f;
    });
  }

  let manufacturer = "generic";
  let abbreviation = "";
  switch (filled.valve.type) {
    case ValveType.GAS_REGULATOR:
      if (valveIsGas) {
        fields.push({
          property: "pressureKPA",
          title: "Pressure Out (PO)",
          short: "",
          shortTitle: "PO",
          units: Units.KiloPascals,
          systemUid,
          layouts: layoutsExcept("drainage"),
          category: FieldCategory.Pressure,
        });
      }
      break;
    case ValveType.WATER_METER:
    case ValveType.CSV:
    case ValveType.FILTER:
      if (valveIsGas) {
        fields.push({
          property: "pressureDropKPA",
          title: "Pressure Drop (PD)",
          short: "",
          shortTitle: "PD",
          defaultEnabled: false,
          units: Units.KiloPascals,
          category: FieldCategory.Pressure,
          layouts: layoutsExcept("drainage"),
          systemUid,
        });
      }
      break;
    case ValveType.BALANCING:
      manufacturer =
        (drawing.metadata.catalog.balancingValves &&
          drawing.metadata.catalog.balancingValves[0]?.manufacturer) ||
        manufacturer;
      abbreviation =
        (manufacturer !== "generic" &&
          catalog?.balancingValves.manufacturer.find(
            (manufacturerObj) => manufacturerObj.uid === manufacturer,
          )?.abbreviation) ||
        abbreviation;

      fields.push({
        property: "sizeMM",
        title: "Size",
        short: abbreviation,
        units: Units.Millimeters,
        layouts,
        category: FieldCategory.Size,
      });

      break;
    case ValveType.LSV:
    case ValveType.PICV:
      fields.push({
        property: "sizeMM",
        title: "Size",
        short: abbreviation,
        units: Units.PipeDiameterMM,
        layouts,
        category: FieldCategory.Size,
      });
      break;
    case ValveType.RPZD_SINGLE:
    case ValveType.RPZD_DOUBLE_SHARED:
    case ValveType.RPZD_DOUBLE_ISOLATED:
      manufacturer =
        drawing.metadata.catalog.backflowValves.find(
          (material) => material.uid === entity.valve.catalogId,
        )?.manufacturer || manufacturer;
      abbreviation =
        (manufacturer !== "generic" &&
          catalog?.backflowValves[entity.valve.catalogId].manufacturer.find(
            (manufacturerObj) => manufacturerObj.uid === manufacturer,
          )?.abbreviation) ||
        "";

      fields.push({
        property: "sizeMM",
        title: "Size",
        short: abbreviation,
        units: Units.PipeDiameterMM,
        layouts,
        category: FieldCategory.Size,
      });

      break;
    case ValveType.PRV_SINGLE:
    case ValveType.PRV_DOUBLE:
    case ValveType.PRV_TRIPLE:
      manufacturer =
        (drawing.metadata.catalog.prv &&
          drawing.metadata.catalog.prv[0]?.manufacturer) ||
        manufacturer;
      abbreviation =
        (manufacturer !== "generic" &&
          catalog?.prv.manufacturer.find(
            (manufacturerObj) => manufacturerObj.uid === manufacturer,
          )?.abbreviation) ||
        "";

      fields.push({
        property: "sizeMM",
        title: "Size",
        short: abbreviation,
        units: Units.PipeDiameterMM,
        category: FieldCategory.Size,
        layouts,
        convert: (prefs, units, sizeMM) => {
          const settings = Object.entries(catalog!.prv.size[manufacturer]).find(
            ([key, value]) => value.diameterNominalMM === sizeMM,
          )!;
          return settings && convertPipeDiameterFromMetric(prefs, settings[0]);
        },
      });

      break;
    case ValveType.FLOOR_WASTE:
      manufacturer =
        (drawing.metadata.catalog.floorWaste &&
          drawing.metadata.catalog.floorWaste[0]?.manufacturer) ||
        manufacturer;

      if (manufacturer === "blucher" && filled.valve.variant === "bucketTrap") {
        fields.push({
          property: "sizeMM",
          title: "Size",
          short: "BLUCHER FW/BT",
          units: Units.Millimeters,
          category: FieldCategory.FloorWaste,
          layouts: onlyLayouts("drainage"),
        });
      }

      break;
    case ValveType.INSPECTION_OPENING:
      manufacturer =
        (drawing.metadata.catalog.inspectionOpening &&
          drawing.metadata.catalog.inspectionOpening[0]?.manufacturer) ||
        manufacturer;

      if (manufacturer === "blucher") {
        fields.push({
          property: "sizeMM",
          title: "Size",
          short: "BLUCHER I.O.",
          units: Units.PipeDiameterMM,
          category: FieldCategory.Size,
          layouts: onlyLayouts("drainage"),
        });
      }

      break;
    case ValveType.SMOKE_DAMPER:
    case ValveType.FIRE_DAMPER:
    case ValveType.VOLUME_CONTROL_DAMPER:
    case ValveType.ATTENUATOR:
    case ValveType.REFLUX_VALVE:
    case ValveType.CHECK_VALVE:
    case ValveType.ISOLATION_VALVE:
    case ValveType.STRAINER:
    case ValveType.RV:
    case ValveType.TRV:
    case ValveType.CUSTOM_VALVE:
      // Nothing for these
      break;
    case ValveType.FAN:
      fields.push(
        {
          property: "fanDutyKPA",
          title: "Fan Duty",
          short: "",
          units: Units.KiloPascals,
          unitContext: UnitsContext.VENTILATION,
          layouts: onlyLayouts("ventilation"),
          category: FieldCategory.Pressure,
        },
        {
          property: "flowRateLS",
          title: "Flow Rate",
          short: "",
          units: Units.LitersPerSecond,
          unitContext: UnitsContext.VENTILATION,
          layouts: onlyLayouts("ventilation"),
          category: FieldCategory.FlowRate,
        },
      );
      break;
    default:
      assertUnreachable(filled.valve);
  }

  return fields;
}

export function emptyDirectedValveCalculation(): DirectedValveCalculation {
  return {
    type: CalculationType.DirectedValveCalculation,
    costBreakdown: null,
    cost: null,
    expandedEntities: null,

    flowRateLS: null,
    pressureDropKPA: null,
    staticPressureKPA: null,
    kvValue: null,
    pressureKPA: null,
    warnings: null,
    sizeMM: null,

    entityName: null,
    exteriorPressureDropKPA: null,
    interiorPressureDropKPA: null,
    fanDutyKPA: null,
  };
}

export function emptyDirectedValveLiveCalculation(): DirectedValveLiveCalculation {
  return {
    connected: null,
    warnings: [],
  };
}
