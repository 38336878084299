import uuid from "uuid";
import {
  ConnectableEntityConcrete,
  DrawableEntityConcrete,
  TerminusEntityConcrete,
} from "../../../../../common/src/api/document/entities/concrete-entity";
import { makeFittingEntity } from "../../../../../common/src/api/document/entities/fitting-entity";
import { FLOW_SYSTEM_TO_CONDUIT_TYPE } from "../../../../../common/src/api/document/flow-systems";
import { Coord } from "../../../../../common/src/lib/coord";
import { MagicResult } from "../../../../src/htmlcanvas/lib/black-magic/index";
import CanvasContext from "../../../../src/htmlcanvas/lib/canvas-context";
import DrawableConduit from "../../objects/drawableConduit";
import { makeConduitEntity } from "./utils";

export function addValveAndSplitPipe(
  context: CanvasContext,
  conduit: DrawableConduit,
  hoverWc: Coord,
  system?: string,
  minDistToEndpoint: number = 0,
  newValve?: ConnectableEntityConcrete,
): MagicResult {
  hoverWc = conduit.project(hoverWc, minDistToEndpoint);
  const levelUid = context.globalStore.levelOfEntity.get(conduit.uid)!;

  let wasInteractive: boolean = false;
  if (
    context.interactive &&
    context.interactive.findIndex((obj) => obj.uid === conduit.uid) !== -1
  ) {
    wasInteractive = true;
  }

  if (system === undefined || system === "") {
    system = conduit.entity.systemUid;
  }

  const systemObj = context.drawing.metadata.flowSystems[system];
  const pipe1uid = uuid();
  const pipe2uid = uuid();

  const created: DrawableEntityConcrete[] = [];

  if (newValve === undefined) {
    newValve = makeFittingEntity(context, {
      center: hoverWc,
      parentUid: null,
      calculationHeightM: null,
      systemUid: systemObj.uid,
      uid: uuid(),
      color: conduit.entity.color,
      entityName: null,
      fittingType: FLOW_SYSTEM_TO_CONDUIT_TYPE[systemObj.type],
    });
    created.push(newValve);

    context.$store.dispatch("document/addEntityOn", {
      entity: newValve,
      levelUid,
    });
  } else {
    newValve.center = hoverWc;
    newValve.parentUid = null;
  }

  const newPipe1 = makeConduitEntity({
    fields: {
      endpointUid: [newValve.uid, conduit.entity.endpointUid[0]],
      uid: pipe1uid,
    },
    modelConduit: conduit.entity,
    objectStore: context.globalStore,
  });

  const newPipe2 = makeConduitEntity({
    fields: {
      endpointUid: [newValve.uid, conduit.entity.endpointUid[1]],
      uid: pipe2uid,
    },
    modelConduit: conduit.entity,
    objectStore: context.globalStore,
  });

  {
    // update terminus (e.g. balancing damper) edgeUid
    const terminiUids = context.globalStore.getTerminiByEdge(conduit.uid);
    for (const terminusUid of terminiUids) {
      const terminus = context.globalStore.get(terminusUid);

      if (!terminus) {
        continue;
      }

      const termEntity = terminus.entity as TerminusEntityConcrete;

      const newEdgeUid = termEntity.endpointIdx === 0 ? pipe1uid : pipe2uid;

      const newTerminus: TerminusEntityConcrete = {
        ...termEntity,
        uid: uuid(),
        edgeUid: newEdgeUid,
        endpointIdx: 1,
      };

      context.$store.dispatch("document/addEntityOn", {
        entity: newTerminus,
        levelUid,
      });
      context.$store.dispatch("document/deleteEntityOn", {
        entity: termEntity,
        levelUid,
      });
    }
  }

  const puid = conduit.uid;
  context.$store.dispatch("document/deleteEntityOn", {
    entity: conduit.entity,
    levelUid,
  });
  context.$store.dispatch("document/addEntityOn", {
    entity: newPipe1,
    levelUid,
  });
  context.$store.dispatch("document/addEntityOn", {
    entity: newPipe2,
    levelUid,
  });

  created.push(newPipe1, newPipe2);

  if (wasInteractive) {
    context.interactive!.push(newPipe1, newPipe2);
  }

  return {
    deleted: [puid],
    created,
    focus: newValve,
  };
}
