import {
  MarketplaceItem,
  MarketplaceVisibility,
} from "../../models/marketplace/MarketplaceItem";
import { MarketplaceAuditTrailType } from "../../models/marketplace/MarketplaceItemAuditTrail";
import {
  MarketplaceItemVersion,
  MarketplaceSchema,
} from "../../models/marketplace/MarketplaceItemVersion";

export interface MarketplaceCreatorData {
  users: {
    [username: string]: {
      name: string;
    };
  };
  orgs: {
    [orgId: string]: {
      id: string;
      name: string;
      verified: boolean;
    };
  };
}

export interface MarketplaceCreateItemRequest {
  title: string;
  description: string;
  version: string;
  versionNotes: string;
  schemaType: MarketplaceSchemaType;
  data: MarketplaceSchema;
  visibility: MarketplaceVisibility;
}

export interface MarketplaceNewVersionRequest {
  marketplaceItemId: string;
  version: string;
  versionNotes: string;
  data: MarketplaceSchema;
}

export interface MarketplaceUpdateItemRequest {
  title: string;
  description: string;
  versionNotes: string;
}

export interface MarketplaceGetItemsResult {
  items: MarketplaceItem[];
  creators: MarketplaceCreatorData;
}

export type MarketplaceViews = "global" | "organization" | "me";
export interface MarketplaceGetItemsQuery {
  view: MarketplaceViews;
  text?: string; // to be implemented
}

export interface MarketplaceGetItemVersionResult {
  item: MarketplaceItemVersion;
  downloadPath: string;
  creators: MarketplaceCreatorData;
  versions: {
    version: string;
    id: string;
    createdOn: Date;
  }[];
}

export interface MarketplaceInstallData {
  items: (MarketplaceItem & { installedVersion: MarketplaceItemVersion })[];
  creators: MarketplaceCreatorData;
}

export interface MarketplaceAuditTrailRequest {
  documentId: number;
  marketplaceItemId: string;
  type: MarketplaceAuditTrailType;
  // null for installation
  fromVersionId: string | null;
  // null for uninstallation
  toVersionId: string | null;
}

export enum MarketplaceSchemaType {
  RADIATORS = "RADIATORS",
}
