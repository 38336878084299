import { Level, Workflows } from "../../../../common/src/api/document/drawing";
import { BackgroundEntity } from "../../../../common/src/api/document/entities/background-entity";
import { SupportedLocales } from "../../../../common/src/api/locale";
import { AddressRecord } from "../../components/editor/lib/settings2/settingsfields2";

export type LevelWithoutEntities = Omit<Level, "entities">;
type BackgroundId = string;
type LevelId = string;
type DocumentId = number;

export const initWorkflows: Workflows = {
  heat_gain: {
    enabled: false,
  },
  heat_loss: {
    enabled: false,
  },
  mech_heating: {
    enabled: false,
  },
  mech_ventilation: {
    enabled: false,
  },
  mech_underfloor_heating: {
    enabled: false,
  },
  mech_chilled: {
    enabled: false,
  },
  plumbing_water: {
    enabled: false,
  },
  plumbing_gas: {
    enabled: false,
  },
  plumbing_wastewater: {
    enabled: false,
  },
  plumbing_stormwater: {
    enabled: false,
  },
  fire_sprinklers: {
    enabled: false,
  },
  fire_hydrants: {
    enabled: false,
  },
  fire_hosereels: {
    enabled: false,
  },
};

export const getInitProjectSetupState = () => {
  return {
    // magicplan stuff for future
    shouldImportFromMagicplan: false as boolean,
    magicplanCustomerId: null as string | null,
    magicplanAccessToken: null as string | null,

    /** alter routing if no existing documents for this user */
    anyDocuments: true as boolean,
    address: null as AddressRecord | null,
    name: null as string | null,
    /** (project number) */
    number: null as string | null,
    locale: null as SupportedLocales | null,
    levels: [
      { abbreviation: "G", floorHeightM: 0, name: "Ground", uid: "ground" }, // must have a floor with uid "ground" zzz
    ] as LevelWithoutEntities[],
    backgrounds: [] as BackgroundEntity[],
    backgroundToLevelAssignments: {} as Record<
      BackgroundId,
      LevelId | undefined
    >,
    shouldImportSettingsFromAnotherProject: false as boolean,
    importSettingsFrom: null as DocumentId | null,
    workflows: initWorkflows as Workflows,
  };
};

export type ProjectSetupState = ReturnType<typeof getInitProjectSetupState>;
