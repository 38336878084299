import Flatten from "@flatten-js/core";
import { GetPressureLossOptions } from "../../../../common/src/api/calculations/entity-pressure-drops";
import { PressureLossResult } from "../../../../common/src/api/calculations/types";
import { getWallByFen } from "../../../../common/src/api/calculations/utils";
import { isUnderfloor } from "../../../../common/src/api/config";
import CoreFen from "../../../../common/src/api/coreObjects/coreFenestration";
import { calculateProjectionLength } from "../../../../common/src/api/coreObjects/lib/geometry-utils";
import {
  externalSegmentDetermineDirectionCW,
  isPointOnSegment,
  movePerpendicularByDistanceCW,
} from "../../../../common/src/api/coreObjects/utils";
import {
  DrawableEntityConcrete,
  isEdgeEntity,
} from "../../../../common/src/api/document/entities/concrete-entity";
import {
  DoorType,
  FenEntity,
  FenType,
  fillDefaultFenFields,
} from "../../../../common/src/api/document/entities/fenestration-entity";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { fillDefaultWallFields } from "../../../../common/src/api/document/entities/wall-entity";
import { getFlowSystem } from "../../../../common/src/api/document/utils";
import { Coord } from "../../../../common/src/lib/coord";
import {
  assertType,
  assertUnreachable,
} from "../../../../common/src/lib/utils";
import { lerpCSSColor } from "../../lib/utils";
import {
  drawBiFoldingDoor,
  drawLoopEntry,
  drawSingleDoor,
  drawWindow,
} from "../helpers/draw-helper";
import CanvasContext from "../lib/canvas-context";
import { DrawingArgs, EntityDrawingArgs } from "../lib/drawable-object";
import { EntityPopupContent } from "../lib/entity-popups/types";
import { Interaction, InteractionType } from "../lib/interaction";
import { CalculatedObject } from "../lib/object-traits/calculated-object";
import { Core2Drawable } from "../lib/object-traits/core2drawable";
import { DraggableObject } from "../lib/object-traits/draggable-object";
import {
  HoverSiblingResult,
  HoverableObject,
} from "../lib/object-traits/hoverable-object";
import { SelectableObject } from "../lib/object-traits/selectable";
import { SnappableObject } from "../lib/object-traits/snappable-object";
import { VirtualEdgeObject } from "../lib/object-traits/virtual-edge-object";
import { DrawingContext, ObjectConstructArgs } from "../lib/types";
import { DrawingMode } from "../types";
import {
  DrawableObjectConcrete,
  PolygonObjectConcrete,
} from "./concrete-object";
import { MIN_PIPE_PIXEL_WIDTH } from "./drawableConduit";
import DrawableEdge from "./drawableEdge";
import DrawableVertex from "./drawableVertex";
import DrawableWall from "./drawableWall";

const Base = CalculatedObject(
  SelectableObject(
    DraggableObject(
      HoverableObject(
        SnappableObject(VirtualEdgeObject(Core2Drawable(CoreFen))),
      ),
    ),
  ),
);

const FEN_SNAP_TO_WALL_RADIUS_PX = 100;

interface FenestrationDragState {
  startingPolygonUids: string[];
}

export default class DrawableFen extends Base {
  type: EntityType.FENESTRATION = EntityType.FENESTRATION;

  // We could not add this to the base drawable class because
  // of some typescript thing so they have to be added at the concrete class.
  constructor(args: ObjectConstructArgs<FenEntity>) {
    super(args.context, args.obj);
    this.onSelect = args.onSelect;
    this.onInteractionComplete = args.onInteractionComplete;
    this.document = args.document;
  }
  getFrictionPressureLossKPA(
    _options: GetPressureLossOptions,
  ): PressureLossResult {
    throw new Error("Method not implemented.");
  }
  getHoverSiblings(): HoverSiblingResult[] {
    return [];
  }
  getPopupContent(): EntityPopupContent[] | null {
    return null;
  }
  drawInternal(context: DrawingContext, args: DrawingArgs): void {
    if (this.shouldRenderInternal(context) || args.forExport) {
      super.drawInternal(context, args);
    }
    return;
  }

  drawEntity(context: DrawingContext, args: EntityDrawingArgs): void {
    if (!this.isManifested) {
      return;
    }

    // easier when pipes are same as world coord.
    const { graphics, ctx } = context;
    const s = graphics.worldToSurfaceScale;
    const filledFens = fillDefaultFenFields(context, this.entity);

    const wallUid = getWallByFen(context.globalStore, this);
    let targetWidth = 0;
    if (wallUid) {
      const wall = context.globalStore.get<DrawableWall>(wallUid);

      const filledWall = fillDefaultWallFields(context, wall.entity);
      targetWidth = filledWall.widthMM ?? 200;
    }

    const baseWidth = Math.max(
      MIN_PIPE_PIXEL_WIDTH / s,
      targetWidth / graphics.unitWorldLength,
      (MIN_PIPE_PIXEL_WIDTH / s) * (5 + Math.log(s)),
    );
    this.lastDrawnWidthInternal = baseWidth;

    const baseColor: string = fillDefaultFenFields(context, this.entity).color!
      .hex;

    ctx.strokeStyle = baseColor;
    ctx.fillStyle = baseColor;
    ctx.lineWidth = 1.2 * baseWidth;
    this.getWorldSegments().forEach((drawCoords) => {
      drawCoords = externalSegmentDetermineDirectionCW(
        context,
        drawCoords,
        this.entity.polygonEdgeUid ?? [],
      );

      if (this.isInternalFene()) {
        drawCoords = movePerpendicularByDistanceCW(
          drawCoords[0],
          drawCoords[1],
          baseWidth / 2,
        );
      } else {
        drawCoords = movePerpendicularByDistanceCW(
          drawCoords[0],
          drawCoords[1],
          baseWidth / 2,
        );
      }

      let color = filledFens.color?.hex ?? "#000000";
      let roomColorHex = this.roomColorHex(context, args);

      if (this.isHovering) {
        color = lerpCSSColor(color, "#ffff00", 0.1);
        roomColorHex = lerpCSSColor(roomColorHex, "#ffff00", 0.1);
      }

      switch (filledFens.fenType) {
        case FenType.WINDOW:
          if (this.isInternalFene() && filledFens.rotation === 180) {
            drawCoords = [drawCoords[1], drawCoords[0]];
          }
          drawWindow(
            context,
            ctx,
            drawCoords,
            baseWidth,
            color,
            roomColorHex,
            filledFens.color?.hex ?? "#000000",
          );
          break;
        case FenType.DOOR:
          let isOpenTowardInside = true;
          let isOpenCW = true;
          if (filledFens.rotation === 90) {
            isOpenCW = false;
          }
          if (filledFens.rotation === 180) {
            isOpenTowardInside = false;
          }
          if (filledFens.rotation === 270) {
            isOpenTowardInside = false;
            isOpenCW = false;
          }

          if (!isOpenTowardInside) {
            drawCoords = [drawCoords[1], drawCoords[0]];
          }

          switch (filledFens.fenestration.doorType) {
            case DoorType.SINGLE:
              drawSingleDoor(
                context,
                ctx,
                drawCoords,
                baseWidth,
                isOpenTowardInside,
                isOpenCW,
                color,
                roomColorHex,
                filledFens.color?.hex ?? "#000000",
              );
              break;
            case DoorType.BI_FOLDING:
              drawBiFoldingDoor(
                context,
                ctx,
                drawCoords,
                baseWidth,
                isOpenTowardInside,
                isOpenCW,
                color,
                roomColorHex,
                filledFens.color?.hex ?? "#000000",
              );
              break;
            default:
              console.error(filledFens);
              assertUnreachable(filledFens.fenestration.doorType);
          }

          break;
        case FenType.LOOP_ENTRY: {
          if (this.shouldShowLoopEntry()) {
            const roomUid = context.globalStore.getPolygonsByEdge(
              this.entity.polygonEdgeUid![0],
            )[0];
            // TODO: support loop entry on multi-edges (only happening on heated area segment)
            // and make sure we know which direction our segment is.
            const room =
              context.globalStore.get<PolygonObjectConcrete>(roomUid);
            const floorColor = room.baseColor(context, args);

            let wallColor = "#000000";
            const wallUid = getWallByFen(context.globalStore, this);
            if (wallUid) {
              const wall = context.globalStore.get<DrawableWall>(wallUid);
              const filledWall = fillDefaultWallFields(context, wall.entity);
              wallColor = filledWall.color!.hex;
            }

            const color = lerpCSSColor(floorColor, wallColor, 0.3);

            drawLoopEntry(context, ctx, drawCoords, baseWidth, color);
          }
          break;
        }
        default:
          assertUnreachable(filledFens);
      }
    });
  }

  shouldShowLoopEntry() {
    if (this.entity.fenType !== FenType.LOOP_ENTRY) {
      return false;
    }

    switch (this.document.uiState.drawingMode) {
      case DrawingMode.FloorPlan:
        return true;
      case DrawingMode.Design:
      case DrawingMode.Calculations:
      case DrawingMode.Export:
      case DrawingMode.History:
        if (this.document.uiState.drawingMode === DrawingMode.Design) {
          if (
            isUnderfloor(
              getFlowSystem(this.drawing, this.document.activeflowSystemUid),
            )
          ) {
            return true;
          }
        }
        return false;
    }
    assertUnreachable(this.document.uiState.drawingMode);
  }

  isActive() {
    switch (this.entity.fenType) {
      case FenType.LOOP_ENTRY:
        return this.shouldShowLoopEntry();
      case FenType.WINDOW:
      case FenType.DOOR:
        return true;
    }
    assertUnreachable(this.entity);
  }

  prepareDelete(
    _context: CanvasContext,
    _calleeEntityUid?: string,
  ): DrawableObjectConcrete[] {
    return [this];
  }
  offerInteraction(_interaction: Interaction): DrawableEntityConcrete[] | null {
    return null;
  }

  onDragStart(
    _event: MouseEvent,
    _objectCoord: Coord,
    context: CanvasContext,
    _isMultiDrag: boolean,
  ): FenestrationDragState {
    const polygonUids = context.globalStore.getPolygonsByEdge(
      this.entity.polygonEdgeUid![0],
    );
    return { startingPolygonUids: polygonUids };
  }
  onDrag(
    _event: MouseEvent,
    _grabbedObjectCoord: Coord,
    eventObjectCoord: Coord,
    grabState: any,
    context: CanvasContext,
    isMultiDrag: boolean,
  ): void {
    assertType<FenestrationDragState>(grabState);
    if (context.$store.getters["document/isViewOnly"] || isMultiDrag) {
      return;
    }
    const lockedPolygonUids: string[] = [];

    const worldCoord = this.toWorldCoord(eventObjectCoord);
    const interactive = context.offerInteraction(
      {
        entityType: EntityType.FENESTRATION,
        worldCoord: eventObjectCoord,
        systemUid: null,
        worldRadius: context.viewPort.toWorldLength(FEN_SNAP_TO_WALL_RADIUS_PX),
        type: InteractionType.INSERT,
      },
      (o) => {
        if (o[0].type !== EntityType.EDGE) return false;

        if (lockedPolygonUids.length > 0) {
          const thisPolygons = context.globalStore.getPolygonsByEdge(o[0].uid);
          for (const polygonUid of thisPolygons) {
            if (!lockedPolygonUids.includes(polygonUid)) {
              return false;
            }
          }
        }

        // make sure the destination of the fenestration is on the edge.
        const ro = context.globalStore.get<DrawableEdge>(o[0].uid);
        const basePoint = ro.shape.distanceTo(
          Flatten.point(worldCoord.x, worldCoord.y),
        )[1].pe;
        const [c1, c2] = ro.worldEndpoints();
        return isPointOnSegment([c1, c2], basePoint);
      },
      (o) => {
        const obj = context.globalStore.get(o[0].uid);
        if (!obj) return -Infinity;
        return -obj.shape!.distanceTo(
          Flatten.point(worldCoord.x, worldCoord.y),
        )[0];
      },
    );
    if (interactive && isEdgeEntity(interactive[0])) {
      this.entity.polygonEdgeUid = [interactive[0].uid];
      const vec = calculateProjectionLength(eventObjectCoord, [
        context.globalStore
          .get<DrawableVertex>(interactive[0].endpointUid[0])
          .toWorldCoord(),
        context.globalStore
          .get<DrawableVertex>(interactive[0].endpointUid[1])
          .toWorldCoord(),
      ]);
      this.entity.offsetPosMM = Math.sqrt(vec.x * vec.x + vec.y * vec.y);
      context.scheduleDraw();
    }
  }
  onDragFinish(
    event: MouseEvent,
    _context: CanvasContext,
    _isMultiDrag: boolean,
  ): void {
    this.onInteractionComplete(event);
  }

  shouldRenderInternal(context: DrawingContext): boolean {
    // Insert non-fens or dragging non-fens will not render
    if (
      context.doc.uiState.toolHandlerName === "insert-fenestration" ||
      context.doc.uiState.draggingEntities.some((e) => {
        return e.type === EntityType.FENESTRATION;
      }) ||
      context.doc.uiState.draggingEntities.some((e) => {
        return e.type === EntityType.WALL;
      }) ||
      context.doc.uiState.draggingEntities.some((e) => {
        return e.type === EntityType.PLANT;
      })
    ) {
      return true;
    }

    if (
      !context.doc.uiState.toolHandlerName &&
      context.doc.uiState.draggingEntities.length === 0
    ) {
      return true;
    }

    if (
      context.doc.uiState.toolHandlerName === "Insert Heated Area" ||
      context.doc.uiState.toolHandlerName === "Insert Radiators" ||
      context.doc.uiState.toolHandlerName === "insert-plant" ||
      context.doc.uiState.toolHandlerName === "Route Radiator Pipes"
    ) {
      return true;
    }

    return false;
  }

  roomColorHex(context: DrawingContext, args: EntityDrawingArgs): string {
    if (this.entity.polygonEdgeUid === null) {
      return "#ffffff";
    }
    const roomEdgeUid = this.entity.polygonEdgeUid[0];
    const polygonUid = context.globalStore.getPolygonsByEdge(roomEdgeUid)[0];
    const drawableRoom =
      context.globalStore.get<PolygonObjectConcrete>(polygonUid);

    return drawableRoom.baseColor(context, args);
  }
}
