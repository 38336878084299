import { CalculatableEntityConcrete } from "../../../../../common/src/api/document/entities/concrete-entity";
import {
  Units,
  UnitsContext,
} from "../../../../../common/src/lib/measurements";
import {
  CoreContext,
  CostBreakdownEntry,
  DrawingLayout,
} from "../../calculations/types";
import { Cost, PsdCountEntry } from "../../calculations/utils";
import { isVentilation } from "../../config";
import { NamedEntity } from "../entities/simple-entities";
import { CalculationField, FieldCategory } from "./calculation-field";
import { LiveWarningDetail, WarningDetail } from "./warnings";

export enum CalculationType {
  RiserCalculation,
  PipeCalculation,
  BigValveCalculation,
  DirectedValveCalculation,
  FittingCalculation,
  FixtureCalculation,
  SystemNodeCalculation,
  LoadNodeCalculation,
  FlowSourceCalculation,
  PlantCalculation,
  GasApplianceCalculation,
  CompoundCalculation,
  MultiwayValveCalculation,
  VertexCalculation,
  EdgeCalculation,
  RoomCalculation,
  WallCalculation,
  FenCalculation,
  ArchitectureElementCalculation,
  DamperCalculation,
  AreaSegmentCalculation,
}

// Specifies paths within a calculation object to keep.
// Usage: const S: StickyCriteria<XYZ> = {
//   foo: true; // want to keep it
//   // bar: true; omit bar to discard it
//   baz: ["A", "B"]; // want to keep baz only if it's A or B
// }
// use __all__ in the key to indicate all fields within a string record type.
//
export type CalcFieldSelector<T> = Partial<{
  [K in keyof T]: T[K] extends Array<infer U>
    ? CalcFieldSelector<U> | true
    : NonNullable<T[K]> extends object
      ? CalcFieldSelector<NonNullable<T[K]>> | true
      : Array<T[K]> | true;
}>;

export interface PsdCalculation {
  psdUnits: PsdCountEntry | null;
}

export interface NameCalculation extends NamedEntity {}

// fast and cosmetically used in drawing mode.
export interface LiveCalculation {
  connected: boolean | null;
  warnings: LiveWarningDetail[];
}

export interface Calculation {
  type: CalculationType;
  warnings: WarningDetail[] | null;
  cost: Cost | null;
  costBreakdown: CostBreakdownEntry[] | null;
  expandedEntities: CalculatableEntityConcrete[] | null;
  reference?: string;
}

export interface PressureCalculation {
  pressureKPA: number | null;
  staticPressureKPA: number | null;
}

export function addPressureCalculationFields(
  context: CoreContext,
  fields: CalculationField[],
  systemUid: string | undefined,
  layouts: DrawingLayout[],
  propPrefix: string = "",
  extraPressureProps?: Partial<CalculationField>,
  extraStaticProps?: Partial<CalculationField>,
) {
  const system = context.drawing.metadata.flowSystems[systemUid!];
  if (system && isVentilation(system)) {
    addVentilationPressureCalculationFields(
      fields,
      systemUid,
      layouts,
      propPrefix,
      extraPressureProps,
    );
  } else {
    addLiquidPressureCalculationFields(
      fields,
      systemUid,
      layouts,
      propPrefix,
      extraPressureProps,
      extraStaticProps,
    );
  }
}

export function addLiquidPressureCalculationFields(
  fields: CalculationField[],
  systemUid: string | undefined,
  layouts: DrawingLayout[],
  propPrefix: string = "",
  extraPressureProps?: Partial<CalculationField>,
  extraStaticProps?: Partial<CalculationField>,
) {
  fields.push(
    {
      property: propPrefix + "pressureKPA",
      title: "Residual Pressure",
      short: "",
      shortTitle: "Residual",
      units: Units.KiloPascals,
      systemUid,
      category: FieldCategory.Pressure,
      layouts,
      ...extraPressureProps,
    },
    {
      property: propPrefix + "staticPressureKPA",
      title: "Static Pressure",
      short: "",
      shortTitle: "Static",
      units: Units.KiloPascals,
      systemUid,
      category: FieldCategory.Pressure,
      layouts,
      ...extraStaticProps,
    },
  );
}

// Same as water but no static pressure
export function addVentilationPressureCalculationFields(
  fields: CalculationField[],
  systemUid: string | undefined,
  layouts: DrawingLayout[],
  propPrefix: string = "",
  extraPressureProps?: Partial<CalculationField>,
) {
  fields.push({
    property: propPrefix + "pressureKPA",
    title: "Pressure",
    short: "",
    shortTitle: "Pressure",
    units: Units.KiloPascals,
    unitContext: UnitsContext.VENTILATION,
    systemUid,
    category: FieldCategory.Pressure,
    layouts,
    ...extraPressureProps,
  });
}
