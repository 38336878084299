import { NodeProps } from "../models/CustomEntity";
import { EntityType } from "./document/entities/types";

export const initialNodes: NodeProps[] = [
  {
    name: "Ensuite",
    minPressure: 250,
    maxPressure: 500,
    fixtures: ["basin", "shower", "wc"],
    uid: "ensuite",
    dwelling: "",
    type: EntityType.LOAD_NODE,
  },
  {
    name: "WC & Basin",
    minPressure: 250,
    maxPressure: 500,
    fixtures: ["basin", "wc"],
    uid: "wc&basin",
    dwelling: "",
    type: EntityType.LOAD_NODE,
  },
  {
    name: "Dwelling",
    minPressure: 250,
    maxPressure: 450,
    fixtures: [
      "basin",
      "shower",
      "wc",
      "kitchenSink",
      "laundryTrough",
      "dishwasher",
      "washingMachine",
    ],
    uid: "dwelling",
    dwelling: true,
    type: EntityType.LOAD_NODE,
  },
  {
    name: "Basin",
    minPressure: 200,
    maxPressure: 500,
    fixtures: ["basin"],
    uid: "basin",
    dwelling: false,
    type: EntityType.LOAD_NODE,
  },
  {
    name: "Bath",
    minPressure: 200,
    maxPressure: 500,
    fixtures: ["bath"],
    uid: "bath",
    dwelling: false,
    type: EntityType.LOAD_NODE,
  },
  {
    name: "Shower",
    minPressure: 200,
    maxPressure: 500,
    fixtures: ["shower"],
    uid: "shower",
    dwelling: false,
    type: EntityType.LOAD_NODE,
  },
  {
    name: "Cleaners Sink",
    minPressure: 200,
    maxPressure: 500,
    fixtures: ["cleanersSink"],
    uid: "cleanersSink",
    dwelling: false,
    type: EntityType.LOAD_NODE,
  },
  {
    name: "Kitchen Sink",
    minPressure: 200,
    maxPressure: 500,
    fixtures: ["kitchenSink"],
    uid: "kitchenSink",
    dwelling: false,
    type: EntityType.LOAD_NODE,
  },
  {
    name: "Dishwasher",
    minPressure: 200,
    maxPressure: 500,
    fixtures: ["dishwasher"],
    uid: "dishwasher",
    dwelling: false,
    type: EntityType.LOAD_NODE,
  },
  {
    name: "Washing Machine",
    minPressure: 200,
    maxPressure: 500,
    fixtures: ["washingMachine"],
    uid: "washingMachine",
    dwelling: false,
    type: EntityType.LOAD_NODE,
  },
];
