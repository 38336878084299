import { Module } from "vuex";
import { DEFAULT_TOOL } from "../../../src/htmlcanvas/lib/tool";
import { actions } from "../../../src/store/tools/actions";
import { getters } from "../../../src/store/tools/getters";
import { mutations } from "../../../src/store/tools/mutations";
import { RootState } from "../../../src/store/types";
import ToolState from "./types";

export const state: ToolState = {
  currentTool: DEFAULT_TOOL,
};

const namespaced: boolean = true;

export const tools: Module<ToolState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
