export type QBranchByQMain = number;
export type ABranchByAMain = number;
export type AngleDEG = number;
export type HeightByWidth = number;
export type RadiusByWidth = number;
export type Coefficient = number;
export type VPEntryByVPExit = number;
export type VPBranchByVPMain = number;
export type AreaBranchByAreaMain = number;
export type ABigByASmall = number;

export interface AreaAirflowTableData {
  type: "area-airflow-table";
  AQTable: { [key: ABranchByAMain]: { [key: QBranchByQMain]: Coefficient } };
}

export interface AirflowAreaTableData {
  type: "airflow-area-table";
  QATable: { [key: QBranchByQMain]: { [key: ABranchByAMain]: Coefficient } };
}

export interface AirflowTableData {
  type: "airflow-table";
  QTable: { [key: QBranchByQMain]: Coefficient };
}

export interface AngleDimRatioTableData {
  type: "angle-dim-ratio";
  AnDrTable: { [key: AngleDEG]: { [key: HeightByWidth]: Coefficient } };
}

export interface RadiusDimRatioTableData {
  type: "radius-dim-ratio";
  RDrTable: { [key: RadiusByWidth]: { [key: HeightByWidth]: Coefficient } };
}

export interface AngleTableData {
  type: "angle";
  AnTable: { [key: AngleDEG]: Coefficient };
}

export interface AngleRadiusTableData {
  type: "angle-radius";
  AnRTable: { [key: AngleDEG]: { [key: RadiusByWidth]: Coefficient } };
}

export interface VelocityPressureMainTableData {
  type: "velocity-pressure-main";
  VPMainTable: { [key: VPEntryByVPExit]: Coefficient };
}

export interface VPAirflowTableData {
  type: "velocity-pressure-airflow";
  VPQTable: { [key: VPBranchByVPMain]: { [key: ABranchByAMain]: Coefficient } };
}

export interface VPAreaTableData {
  type: "velocity-pressure-area";
  VPATable: {
    [key: VPBranchByVPMain]: { [key: ABranchByAMain]: Coefficient };
  };
}

export interface AngleAreaBigSmallTableData {
  type: "angle-area-big-small";
  AnATable: { [key: AngleDEG]: { [key: ABigByASmall]: Coefficient } };
}

export type CoefficientData =
  | AreaAirflowTableData
  | AirflowAreaTableData
  | AirflowTableData
  | AngleDimRatioTableData
  | RadiusDimRatioTableData
  | AngleTableData
  | AngleRadiusTableData
  | VelocityPressureMainTableData
  | VPAirflowTableData
  | VPAreaTableData
  | AngleAreaBigSmallTableData;

// Elbows ==============
export interface RectangularElbowSpec {
  shape: "rectangular";
  jointType: "square" | "smooth";
  vanes?: number;
}

export interface RectangularVanesElbowSpec {
  shape: "rectangular";
  jointType: "smooth-vanes";
  vanes: number;
}

export interface RectangularMitredVanesElbowSpec {
  shape: "rectangular";
  jointType: "square-vanes";
  vanes: number;
}

export interface CircularElbowSpec {
  shape: "circular";
  jointType: "square" | "multi-piece" | "smooth";
}

export type DuctElbowSpec =
  | RectangularElbowSpec
  | CircularElbowSpec
  | RectangularVanesElbowSpec
  | RectangularMitredVanesElbowSpec;

export type DuctElbow = DuctElbowSpec &
  CoefficientData & {
    name: string;
  };

// Converging Tees ================

export interface RectangularSymmetricalConvergingTeeSpec {
  jointType: "rectangular-symmetrical-converging-tee";
  mainInlet: "rectangular";
  branchShape: "rectangular"; // In this case, branch is identical to main and any choice of branch is valid.
  mainOutlet: "rectangular";
  branchJoint: "square" | "45" | "smooth";
}

export interface CircularSymmetricalConvergingTeeSpec {
  jointType: "circular-symmetrical-converging-tee";
  mainInlet: "circular";
  branchShape: "circular";
  mainOutlet: "circular";
  branchJoint: "square" | "45";
}

export interface RectangularConvergingTeeSpec {
  jointType: "rectangular-converging-tee";
  mainInlet: "rectangular";
  branchShape: "rectangular" | "circular";
  mainOutlet: "rectangular";
  branchJoint: "square" | "45";
}

export interface CircularConvergingTeeSpec {
  jointType: "circular-converging-tee";
  mainInlet: "circular";
  branchShape: "circular";
  mainOutlet: "circular";
  branchAngle: number;
}

export type DuctConvergingTeeSpec =
  | RectangularSymmetricalConvergingTeeSpec
  | RectangularConvergingTeeSpec
  | CircularConvergingTeeSpec
  | CircularSymmetricalConvergingTeeSpec;

// TODO: more complicated shapes like angle branch tees with tapers
// and angle branch tees, with bend of branches.

// For rectangular branch and rectangular main
// the main coefficient is same as TeesConvergingMainCircular
// But in the catalog we have a separate entry for this.

export type DuctConvergingTee = DuctConvergingTeeSpec & {
  name: string;
  mainC: CoefficientData;
  branchC: CoefficientData;
};

// Diverging tees ==============
export interface RectangularSymmetricalDivergingTeeSpec {
  jointType: "rectangular-symmetrical-diverging-tee";
  mainInlet: "rectangular";
  branchShape: "rectangular";
  mainOutlet: "rectangular";
  branchJoint: "square" | "45" | "smooth";
}

export interface CircularSymmetricalDivergingTeeSpec {
  jointType: "circular-symmetrical-diverging-tee";
  mainInlet: "circular";
  branchShape: "circular";
  mainOutlet: "circular";
  branchJoint: "square" | "45";
}

export interface RectangularDivergingTeeSpec {
  jointType: "rectangular-diverging-tee";
  mainInlet: "rectangular";
  branchShape: "rectangular" | "circular";
  mainOutlet: "rectangular";
  branchJoint: "square" | "45";
}

export interface CircularDivergingTeeSpec {
  jointType: "circular-diverging-tee";
  mainInlet: "circular";
  branchShape: "circular";
  mainOutlet: "circular";
  branchAngle: number;
}

export type DuctDivergingTeeSpec =
  | RectangularSymmetricalDivergingTeeSpec
  | RectangularDivergingTeeSpec
  | CircularDivergingTeeSpec
  | CircularSymmetricalDivergingTeeSpec;

export type SymmetricConvergingTeeSpec =
  | RectangularSymmetricalConvergingTeeSpec
  | CircularSymmetricalConvergingTeeSpec;

export type SymmetricDivergingTeeSpec =
  | RectangularSymmetricalDivergingTeeSpec
  | CircularSymmetricalDivergingTeeSpec;

export type DuctDivergingTee = DuctDivergingTeeSpec & {
  name: string;
  mainC: CoefficientData;
  branchC: CoefficientData;
};

export type DuctTransitionSpec = {
  inletShape: "rectangular" | "circular";
  outletShape: "rectangular" | "circular";
  direction: "converging" | "diverging";
  taperAngleDEG: number;
};

export type DuctTransition = Omit<DuctTransitionSpec, "taperAngleDEG"> & {
  name: string;
  data: CoefficientData;
};

export interface DuctFittingsCatalogPage {
  elbows: Record<string, DuctElbow>;
  convergingTees: Record<string, DuctConvergingTee>;
  divergingTees: Record<string, DuctDivergingTee>;
  transitions: Record<string, DuctTransition>;
}

export const DUCT_FITTINGS_DATA: DuctFittingsCatalogPage = {
  elbows: {
    "rectangular-miterd": {
      type: "angle-dim-ratio",
      jointType: "square",
      name: "Rectangular Mitred Elbow",
      shape: "rectangular",
      AnDrTable: {
        90.0: {
          0.5: 1.3,
          1.0: 1.2,
          1.5: 1.1,
          2.0: 1.1,
          3.0: 0.98,
          4.0: 0.92,
        },
        60.0: {
          0.5: 0.59,
          1.0: 0.55,
          1.5: 0.52,
          2.0: 0.49,
          3.0: 0.46,
          4.0: 0.43,
        },
        45.0: {
          0.5: 0.37,
          1.0: 0.34,
          1.5: 0.33,
          2.0: 0.31,
          3.0: 0.28,
          4.0: 0.27,
        },
        30.0: {
          0.5: 0.17,
          1.0: 0.16,
          1.5: 0.15,
          2.0: 0.15,
          3.0: 0.13,
          4.0: 0.13,
        },
      },
    },
    "rectangular-miterd-with-vanes": {
      type: "angle-dim-ratio",
      jointType: "square-vanes",
      name: "Rectangular Mitred Elbow with Vanes",
      shape: "rectangular",
      vanes: 1, // any number actually
      AnDrTable: {
        90.0: {
          0.5: 0.65,
          1.0: 0.6,
          1.5: 0.55,
          2.0: 0.55,
          3.0: 0.49,
          4.0: 0.46,
        },
        60.0: {
          0.5: 0.29,
          1.0: 0.27,
          1.5: 0.26,
          2.0: 0.24,
          3.0: 0.23,
          4.0: 0.21,
        },
        45.0: {
          0.5: 0.18,
          1.0: 0.17,
          1.5: 0.16,
          2.0: 0.15,
          3.0: 0.14,
          4.0: 0.13,
        },
        30.0: {
          0.5: 0.09,
          1.0: 0.08,
          1.5: 0.08,
          2.0: 0.07,
          3.0: 0.07,
          4.0: 0.06,
        },
      },
    },
    "rectangular-smooth": {
      type: "radius-dim-ratio",
      jointType: "smooth",
      shape: "rectangular",
      name: "Rectangular Smooth Elbow",
      RDrTable: {
        0.5: { 0.5: 1.4, 1.0: 1.2, 1.5: 1.1, 2.0: 1.0, 3.0: 1.0, 4.0: 1.1 },
        1.0: {
          0.5: 0.25,
          1.0: 0.21,
          1.5: 0.19,
          2.0: 0.18,
          3.0: 0.18,
          4.0: 0.19,
        },
        1.5: {
          0.5: 0.2,
          1.0: 0.17,
          1.5: 0.15,
          2.0: 0.14,
          3.0: 0.14,
          4.0: 0.15,
        },
        2.0: {
          0.5: 0.18,
          1.0: 0.15,
          1.5: 0.4,
          2.0: 0.13,
          3.0: 0.13,
          4.0: 0.14,
        },
      },
    },
    "rectangular-smooth-with-vanes-1": {
      type: "radius-dim-ratio",
      jointType: "smooth-vanes",
      shape: "rectangular",
      name: "Rectangular Smooth Elbow with 1 Vane",
      vanes: 1,
      RDrTable: {
        0.55: {
          0.25: 0.52,
          0.5: 0.4,
          1.0: 0.43,
          1.5: 0.49,
          2.0: 0.55,
          3.0: 0.66,
          4.0: 0.75,
          5.0: 0.84,
          6.0: 0.93,
          7.0: 1.01,
          8.0: 1.09,
        },
        0.6: {
          0.25: 0.36,
          0.5: 0.27,
          1.0: 0.25,
          1.5: 0.28,
          2.0: 0.3,
          3.0: 0.35,
          4.0: 0.39,
          5.0: 0.42,
          6.0: 0.46,
          7.0: 0.49,
          8.0: 0.52,
        },
        0.65: {
          0.25: 0.28,
          0.5: 0.21,
          1.0: 0.18,
          1.5: 0.19,
          2.0: 0.2,
          3.0: 0.22,
          4.0: 0.25,
          5.0: 0.26,
          6.0: 0.28,
          7.0: 0.3,
          8.0: 0.32,
        },
        0.7: {
          0.25: 0.22,
          0.5: 0.16,
          1.0: 0.14,
          1.5: 0.14,
          2.0: 0.15,
          3.0: 0.16,
          4.0: 0.17,
          5.0: 0.18,
          6.0: 0.19,
          7.0: 0.2,
          8.0: 0.21,
        },
        0.75: {
          0.25: 0.18,
          0.5: 0.13,
          1.0: 0.11,
          1.5: 0.11,
          2.0: 0.11,
          3.0: 0.12,
          4.0: 0.13,
          5.0: 0.14,
          6.0: 0.14,
          7.0: 0.15,
          8.0: 0.15,
        },
        0.8: {
          0.25: 0.15,
          0.5: 0.11,
          1.0: 0.09,
          1.5: 0.09,
          2.0: 0.09,
          3.0: 0.09,
          4.0: 0.1,
          5.0: 0.1,
          6.0: 0.11,
          7.0: 0.11,
          8.0: 0.12,
        },
        0.9: {
          0.25: 0.11,
          0.5: 0.08,
          1.0: 0.07,
          1.5: 0.06,
          2.0: 0.06,
          3.0: 0.06,
          4.0: 0.06,
          5.0: 0.07,
          6.0: 0.07,
          7.0: 0.07,
          8.0: 0.07,
        },
        1.0: {
          0.25: 0.09,
          0.5: 0.06,
          1.0: 0.05,
          1.5: 0.05,
          2.0: 0.04,
          3.0: 0.04,
          4.0: 0.04,
          5.0: 0.05,
          6.0: 0.05,
          7.0: 0.05,
          8.0: 0.05,
        },
      },
    },
    "rectangular-smooth-with-vanes-2": {
      type: "radius-dim-ratio",
      jointType: "smooth-vanes",
      shape: "rectangular",
      name: "Rectangular Smooth Elbow with 2 Vanes",
      vanes: 2,
      RDrTable: {
        0.55: {
          0.25: 0.26,
          0.5: 0.2,
          1.0: 0.22,
          1.5: 0.25,
          2.0: 0.28,
          3.0: 0.33,
          4.0: 0.37,
          5.0: 0.41,
          6.0: 0.45,
          7.0: 0.48,
          8.0: 0.51,
        },
        0.6: {
          0.25: 0.17,
          0.5: 0.13,
          1.0: 0.11,
          1.5: 0.12,
          2.0: 0.13,
          3.0: 0.15,
          4.0: 0.16,
          5.0: 0.17,
          6.0: 0.19,
          7.0: 0.2,
          8.0: 0.21,
        },
        0.65: {
          0.25: 0.12,
          0.5: 0.09,
          1.0: 0.08,
          1.5: 0.08,
          2.0: 0.08,
          3.0: 0.09,
          4.0: 0.1,
          5.0: 0.1,
          6.0: 0.11,
          7.0: 0.11,
          8.0: 0.11,
        },
        0.7: {
          0.25: 0.09,
          0.5: 0.07,
          1.0: 0.06,
          1.5: 0.05,
          2.0: 0.06,
          3.0: 0.06,
          4.0: 0.06,
          5.0: 0.06,
          6.0: 0.07,
          7.0: 0.07,
          8.0: 0.07,
        },
        0.75: {
          0.25: 0.08,
          0.5: 0.05,
          1.0: 0.04,
          1.5: 0.04,
          2.0: 0.04,
          3.0: 0.04,
          4.0: 0.05,
          5.0: 0.05,
          6.0: 0.05,
          7.0: 0.05,
          8.0: 0.05,
        },
        0.8: {
          0.25: 0.06,
          0.5: 0.04,
          1.0: 0.03,
          1.5: 0.03,
          2.0: 0.03,
          3.0: 0.03,
          4.0: 0.03,
          5.0: 0.03,
          6.0: 0.04,
          7.0: 0.04,
          8.0: 0.04,
        },
        0.9: {
          0.25: 0.05,
          0.5: 0.03,
          1.0: 0.03,
          1.5: 0.02,
          2.0: 0.02,
          3.0: 0.02,
          4.0: 0.02,
          5.0: 0.02,
          6.0: 0.02,
          7.0: 0.02,
          8.0: 0.02,
        },
        1.0: {
          0.25: 0.03,
          0.5: 0.02,
          1.0: 0.02,
          1.5: 0.02,
          2.0: 0.02,
          3.0: 0.01,
          4.0: 0.01,
          5.0: 0.01,
          6.0: 0.01,
          7.0: 0.01,
          8.0: 0.01,
        },
      },
    },
    "rectangular-smooth-with-vanes-3": {
      type: "radius-dim-ratio",
      jointType: "smooth-vanes",
      shape: "rectangular",
      name: "Rectangular Smooth Elbow with 3 Vanes",
      vanes: 3,
      RDrTable: {
        0.55: {
          0.25: 0.11,
          0.5: 0.1,
          1.0: 0.12,
          1.5: 0.13,
          2.0: 0.14,
          3.0: 0.16,
          4.0: 0.18,
          5.0: 0.19,
          6.0: 0.21,
          7.0: 0.22,
          8.0: 0.23,
        },
        0.6: {
          0.25: 0.07,
          0.5: 0.05,
          1.0: 0.06,
          1.5: 0.06,
          2.0: 0.06,
          3.0: 0.07,
          4.0: 0.07,
          5.0: 0.08,
          6.0: 0.08,
          7.0: 0.08,
          8.0: 0.09,
        },
        0.65: {
          0.25: 0.05,
          0.5: 0.04,
          1.0: 0.04,
          1.5: 0.04,
          2.0: 0.04,
          3.0: 0.04,
          4.0: 0.04,
          5.0: 0.04,
          6.0: 0.04,
          7.0: 0.05,
          8.0: 0.05,
        },
        0.7: {
          0.25: 0.03,
          0.5: 0.03,
          1.0: 0.03,
          1.5: 0.03,
          2.0: 0.03,
          3.0: 0.03,
          4.0: 0.03,
          5.0: 0.03,
          6.0: 0.03,
          7.0: 0.03,
          8.0: 0.03,
        },
        0.75: {
          0.25: 0.03,
          0.5: 0.02,
          1.0: 0.02,
          1.5: 0.02,
          2.0: 0.02,
          3.0: 0.02,
          4.0: 0.02,
          5.0: 0.02,
          6.0: 0.02,
          7.0: 0.02,
          8.0: 0.02,
        },
        0.8: {
          0.25: 0.03,
          0.5: 0.02,
          1.0: 0.02,
          1.5: 0.02,
          2.0: 0.02,
          3.0: 0.01,
          4.0: 0.01,
          5.0: 0.01,
          6.0: 0.01,
          7.0: 0.01,
          8.0: 0.01,
        },
        0.9: {
          0.25: 0.02,
          0.5: 0.01,
          1.0: 0.01,
          1.5: 0.01,
          2.0: 0.01,
          3.0: 0.01,
          4.0: 0.01,
          5.0: 0.01,
          6.0: 0.01,
          7.0: 0.01,
          8.0: 0.01,
        },
        1.0: {
          0.25: 0.01,
          0.5: 0.01,
          1.0: 0.01,
          1.5: 0.01,
          2.0: 0.01,
          3.0: 0.01,
          4.0: 0.01,
          5.0: 0.01,
          6.0: 0.01,
          7.0: 0.01,
          8.0: 0.01,
        },
      },
    },

    "circular-mitred": {
      type: "angle",
      shape: "circular",
      jointType: "square",
      name: "Circular Mitred Elbow",
      AnTable: { 90.0: 1.2, 60.0: 0.55, 45.0: 0.34, 30.0: 0.16 },
    },

    "circular-round-3-5-pcs": {
      type: "angle-radius",
      shape: "circular",
      jointType: "multi-piece",
      name: "Circular Round Elbow 3-5 pcs",
      AnRTable: {
        90.0: { 1: 0.37, 1.5: 0.27, 2: 0.24 },
        60.0: { 1: 0.2886, 1.5: 0.2106, 2: 0.1872 },
        45.0: { 1: 0.222, 1.5: 0.162, 2: 0.144 },
        30.0: { 1: 0.1665, 1.5: 0.1215, 2: 0.108 },
      },
    },

    "circular-smooth": {
      type: "angle-radius",
      shape: "circular",
      jointType: "smooth",
      name: "Circular Smooth Elbow",
      AnRTable: {
        90.0: { 1: 0.22, 1.5: 0.15, 2: 0.13 },
        60.0: { 1: 0.1716, 1.5: 0.117, 2: 0.1014 },
        45.0: { 1: 0.132, 1.5: 0.09, 2: 0.078 },
        30.0: { 1: 0.099, 1.5: 0.0675, 2: 0.0585 },
      },
    },
  },
  divergingTees: {
    "rectangular-mitred": {
      jointType: "rectangular-diverging-tee",
      name: "Rectangular Mitred Diverging Tee",
      branchJoint: "square",
      branchShape: "rectangular",
      mainInlet: "rectangular",
      mainOutlet: "rectangular",
      branchC: {
        type: "velocity-pressure-airflow",
        VPQTable: {
          0.2: { 0.1: 1.03 },
          0.4: { 0.1: 1.04, 0.2: 1.01 },
          0.6: { 0.1: 1.11, 0.2: 1.03, 0.3: 1.05 },
          0.8: { 0.1: 1.16, 0.2: 1.21, 0.3: 1.17, 0.4: 1.12 },
          1.0: { 0.1: 1.38, 0.2: 1.4, 0.3: 1.3, 0.4: 1.36, 0.5: 1.27 },
          1.2: {
            0.1: 1.52,
            0.2: 1.61,
            0.3: 1.68,
            0.4: 1.91,
            0.5: 1.47,
            0.6: 1.66,
          },
          1.4: {
            0.1: 1.79,
            0.2: 2.01,
            0.3: 1.9,
            0.4: 2.31,
            0.5: 2.28,
            0.6: 2.2,
            0.7: 1.95,
          },
          1.6: {
            0.1: 2.07,
            0.2: 2.28,
            0.3: 2.13,
            0.4: 2.71,
            0.5: 2.99,
            0.6: 2.81,
            0.7: 2.09,
            0.8: 2.2,
          },
          1.8: {
            0.1: 2.32,
            0.2: 2.54,
            0.3: 2.64,
            0.4: 3.09,
            0.5: 3.72,
            0.6: 3.48,
            0.7: 2.21,
            0.8: 2.29,
            0.9: 2.57,
          },
        },
      },
      mainC: {
        type: "velocity-pressure-main",
        VPMainTable: {
          0.0: 0.35,
          0.1: 0.28,
          0.2: 0.22,
          0.3: 0.17,
          0.4: 0.13,
          0.5: 0.09,
          0.6: 0.06,
          0.8: 0.02,
          1.0: 0.0,
        },
      },
    },
    "rectangular-45": {
      jointType: "rectangular-diverging-tee",
      name: "Rectangular 45° exit Diverging Tee",
      branchJoint: "45",
      mainInlet: "rectangular",
      mainOutlet: "rectangular",
      branchShape: "rectangular",
      branchC: {
        type: "velocity-pressure-airflow",
        VPQTable: {
          0.2: { 0.1: 0.91 },
          0.4: { 0.1: 0.81, 0.2: 0.79 },
          0.6: { 0.1: 0.77, 0.2: 0.72, 0.3: 0.7 },
          0.8: { 0.1: 0.78, 0.2: 0.73, 0.3: 0.69, 0.4: 0.66 },
          1.0: { 0.1: 0.78, 0.2: 0.98, 0.3: 0.85, 0.4: 0.79, 0.5: 0.74 },
          1.2: {
            0.1: 0.9,
            0.2: 1.11,
            0.3: 1.16,
            0.4: 1.23,
            0.5: 1.03,
            0.6: 0.86,
          },
          1.4: {
            0.1: 1.19,
            0.2: 1.22,
            0.3: 1.26,
            0.4: 1.29,
            0.5: 1.54,
            0.6: 1.25,
            0.7: 0.92,
          },
          1.6: {
            0.1: 1.35,
            0.2: 1.42,
            0.3: 1.55,
            0.4: 1.59,
            0.5: 1.63,
            0.6: 1.5,
            0.7: 1.31,
            0.8: 1.09,
          },
          1.8: {
            0.1: 1.44,
            0.2: 1.5,
            0.3: 1.75,
            0.4: 1.74,
            0.5: 1.72,
            0.6: 2.24,
            0.7: 1.63,
            0.8: 1.4,
            0.9: 1.17,
          },
        },
      },
      mainC: {
        type: "velocity-pressure-main",
        VPMainTable: {
          0.0: 0.35,
          0.1: 0.28,
          0.2: 0.22,
          0.3: 0.17,
          0.4: 0.13,
          0.5: 0.09,
          0.6: 0.06,
          0.8: 0.02,
          1.0: 0.0,
        },
      },
    },
    "rectangular-circular": {
      jointType: "rectangular-diverging-tee",
      branchJoint: "square",
      name: "Rectangular Main to Circular Branch Diverging Tee",
      mainInlet: "rectangular",
      mainOutlet: "rectangular",
      branchShape: "circular",
      branchC: {
        type: "velocity-pressure-airflow",
        VPQTable: {
          0.2: { 0.1: 1.0 },
          0.4: { 0.1: 1.01, 0.2: 1.07 },
          0.6: { 0.1: 1.14, 0.2: 1.1, 0.3: 1.08 },
          0.8: { 0.1: 1.18, 0.2: 1.31, 0.3: 1.12, 0.4: 1.13 },
          1.0: { 0.1: 1.3, 0.2: 1.38, 0.3: 1.2, 0.4: 1.23, 0.5: 1.26 },
          1.2: {
            0.1: 1.46,
            0.2: 1.58,
            0.3: 1.45,
            0.4: 1.31,
            0.5: 1.39,
            0.6: 1.48,
          },
          1.4: {
            0.1: 1.7,
            0.2: 1.82,
            0.3: 1.65,
            0.4: 1.51,
            0.5: 1.56,
            0.6: 1.64,
            0.7: 1.71,
          },
          1.6: {
            0.1: 1.93,
            0.2: 2.06,
            0.3: 2.0,
            0.4: 1.85,
            0.5: 1.7,
            0.6: 1.76,
            0.7: 1.8,
            0.8: 1.88,
          },
          1.8: {
            0.1: 2.06,
            0.2: 2.17,
            0.3: 2.2,
            0.4: 2.13,
            0.5: 2.06,
            0.6: 1.98,
            0.7: 1.99,
            0.8: 2.0,
            0.9: 2.07,
          },
        },
      },
      mainC: {
        type: "velocity-pressure-main",
        VPMainTable: {
          0.0: 0.35,
          0.1: 0.28,
          0.2: 0.22,
          0.3: 0.17,
          0.4: 0.13,
          0.5: 0.09,
          0.6: 0.06,
          0.8: 0.02,
          1.0: 0.0,
        },
      },
    },
    "circular-wye-30": {
      jointType: "circular-diverging-tee",
      name: "Circular Wye 30° exit Diverging Tee",
      branchAngle: 30,
      branchShape: "circular",
      mainInlet: "circular",
      mainOutlet: "circular",
      branchC: {
        type: "area-airflow-table",
        AQTable: {
          0.8: {
            0.1: 0.75,
            0.2: 0.55,
            0.3: 0.4,
            0.4: 0.28,
            0.5: 0.21,
            0.6: 0.16,
            0.7: 0.15,
            0.8: 0.16,
            0.9: 0.19,
          },
          0.7: {
            0.1: 0.72,
            0.2: 0.51,
            0.3: 0.36,
            0.4: 0.25,
            0.5: 0.18,
            0.6: 0.15,
            0.7: 0.16,
            0.8: 0.2,
            0.9: 0.26,
          },
          0.6: {
            0.1: 0.69,
            0.2: 0.46,
            0.3: 0.31,
            0.4: 0.21,
            0.5: 0.17,
            0.6: 0.16,
            0.7: 0.2,
            0.8: 0.28,
            0.9: 0.39,
          },
          0.5: {
            0.1: 0.65,
            0.2: 0.41,
            0.3: 0.26,
            0.4: 0.19,
            0.5: 0.18,
            0.6: 0.22,
            0.7: 0.32,
            0.8: 0.47,
            0.9: 0.67,
          },
          0.4: {
            0.1: 0.59,
            0.2: 0.33,
            0.3: 0.21,
            0.4: 0.2,
            0.5: 0.27,
            0.6: 0.4,
            0.7: 0.62,
            0.8: 0.92,
            0.9: 1.3,
          },
          0.3: {
            0.1: 0.55,
            0.2: 0.28,
            0.3: 0.24,
            0.4: 0.38,
            0.5: 0.76,
            0.6: 1.3,
            0.7: 2.0,
          },
          0.2: { 0.1: 0.4, 0.2: 0.26, 0.3: 0.58, 0.4: 1.3, 0.5: 2.5 },
          0.1: { 0.1: 0.28, 0.2: 1.5 },
        },
      },
      mainC: {
        type: "velocity-pressure-main",
        VPMainTable: {
          0.0: 0.35,
          0.1: 0.28,
          0.2: 0.22,
          0.3: 0.17,
          0.4: 0.13,
          0.5: 0.09,
          0.6: 0.06,
          0.8: 0.02,
          1.0: 0.0,
        },
      },
    },
    "circular-wye-60": {
      jointType: "circular-diverging-tee",
      name: "Circular Wye 60° exit Diverging Tee",
      branchAngle: 60,
      branchShape: "circular",
      mainInlet: "circular",
      mainOutlet: "circular",
      branchC: {
        type: "area-airflow-table",
        AQTable: {
          0.8: {
            0.1: 0.83,
            0.2: 0.71,
            0.3: 0.62,
            0.4: 0.56,
            0.5: 0.52,
            0.6: 0.5,
            0.7: 0.53,
            0.8: 0.6,
            0.9: 0.68,
          },
          0.7: {
            0.1: 0.82,
            0.2: 0.69,
            0.3: 0.61,
            0.4: 0.56,
            0.5: 0.54,
            0.6: 0.54,
            0.7: 0.6,
            0.8: 0.7,
            0.9: 0.82,
          },
          0.6: {
            0.1: 0.81,
            0.2: 0.68,
            0.3: 0.6,
            0.4: 0.57,
            0.5: 0.58,
            0.6: 0.61,
            0.7: 0.72,
            0.8: 0.87,
            0.9: 1.1,
          },
          0.5: {
            0.1: 0.79,
            0.2: 0.66,
            0.3: 0.61,
            0.4: 0.62,
            0.5: 0.68,
            0.6: 0.76,
            0.7: 0.94,
            0.8: 1.2,
            0.9: 1.5,
          },
          0.4: {
            0.1: 0.76,
            0.2: 0.65,
            0.3: 0.65,
            0.4: 0.74,
            0.5: 0.89,
            0.6: 1.1,
            0.7: 1.4,
            0.8: 1.8,
            0.9: 2.3,
          },
          0.3: {
            0.1: 0.8,
            0.2: 0.75,
            0.3: 0.89,
            0.4: 1.2,
            0.5: 1.8,
            0.6: 2.6,
            0.7: 3.5,
          },
          0.2: { 0.1: 0.77, 0.2: 0.96, 0.3: 1.6, 0.4: 2.5 },
          0.1: { 0.1: 1.0, 0.2: 2.9 },
        },
      },
      mainC: {
        type: "velocity-pressure-main",
        VPMainTable: {
          0.0: 0.35,
          0.1: 0.28,
          0.2: 0.22,
          0.3: 0.17,
          0.4: 0.13,
          0.5: 0.09,
          0.6: 0.06,
          0.8: 0.02,
          1.0: 0.0,
        },
      },
    },
    "circular-wye-90": {
      jointType: "circular-diverging-tee",
      name: "Circular Wye 90° exit Diverging Tee",
      branchAngle: 90,
      branchShape: "circular",
      mainInlet: "circular",
      mainOutlet: "circular",
      branchC: {
        type: "area-airflow-table",
        AQTable: {
          0.8: {
            0.1: 0.95,
            0.2: 0.92,
            0.3: 0.92,
            0.4: 0.93,
            0.5: 0.94,
            0.6: 0.95,
            0.7: 1.1,
            0.8: 1.2,
            0.9: 1.4,
          },
          0.7: {
            0.1: 0.95,
            0.2: 0.94,
            0.3: 0.95,
            0.4: 0.98,
            0.5: 1.0,
            0.6: 1.1,
            0.7: 1.2,
            0.8: 1.4,
            0.9: 1.6,
          },
          0.6: {
            0.1: 0.96,
            0.2: 0.97,
            0.3: 1.0,
            0.4: 1.1,
            0.5: 1.1,
            0.6: 1.2,
            0.7: 1.4,
            0.8: 1.7,
            0.9: 2.0,
          },
          0.5: {
            0.1: 0.97,
            0.2: 1.0,
            0.3: 1.1,
            0.4: 1.2,
            0.5: 1.4,
            0.6: 1.5,
            0.7: 1.8,
            0.8: 2.1,
            0.9: 2.5,
          },
          0.4: {
            0.1: 0.99,
            0.2: 1.1,
            0.3: 1.3,
            0.4: 1.5,
            0.5: 1.7,
            0.6: 2.0,
            0.7: 2.4,
          },
          0.3: { 0.1: 1.1, 0.2: 1.4, 0.3: 1.8, 0.4: 2.3 },
          0.2: { 0.1: 1.3, 0.2: 1.9, 0.3: 2.9 },
          0.1: { 0.1: 2.1 },
        },
      },
      mainC: {
        type: "velocity-pressure-main",
        VPMainTable: {
          0.0: 0.35,
          0.1: 0.28,
          0.2: 0.22,
          0.3: 0.17,
          0.4: 0.13,
          0.5: 0.09,
          0.6: 0.06,
          0.8: 0.02,
          1.0: 0.0,
        },
      },
    },
  },
  convergingTees: {
    "rectangular-mitred": {
      jointType: "rectangular-converging-tee",
      name: "Rectangular Mitred Converging Tee",
      branchJoint: "square",
      branchShape: "rectangular",
      mainInlet: "rectangular",
      mainOutlet: "rectangular",

      branchC: {
        type: "airflow-table",
        QTable: {
          0.1: -0.75,
          0.2: -0.53,
          0.3: -0.03,
          0.4: 0.33,
          0.5: 1.03,
          0.6: 1.1,
          0.7: 2.15,
          0.8: 2.93,
          0.9: 4.18,
          1.0: 4.78,
        },
      },
      mainC: {
        type: "airflow-table",
        QTable: {
          0.1: 0.16,
          0.2: 0.27,
          0.3: 0.38,
          0.4: 0.46,
          0.5: 0.53,
          0.6: 0.57,
          0.7: 0.59,
          0.8: 0.6,
          0.9: 0.59,
          1.0: 0.55,
        },
      },
    },
    "rectangular-45": {
      jointType: "rectangular-converging-tee",
      name: "Rectangular 45° entry Converging Tee",
      branchJoint: "45",
      branchShape: "rectangular",
      mainInlet: "rectangular",
      mainOutlet: "rectangular",
      branchC: {
        type: "airflow-table",
        QTable: {
          0.1: -0.83,
          0.2: -0.68,
          0.3: -0.3,
          0.4: 0.28,
          0.5: 0.55,
          0.6: 1.03,
          0.7: 1.5,
          0.8: 1.93,
          0.9: 2.5,
          1.0: 3.03,
        },
      },

      mainC: {
        type: "airflow-table",
        QTable: {
          0.1: 0.16,
          0.2: 0.27,
          0.3: 0.38,
          0.4: 0.46,
          0.5: 0.53,
          0.6: 0.57,
          0.7: 0.59,
          0.8: 0.6,
          0.9: 0.59,
          1.0: 0.55,
        },
      },
    },
    "rectangular-circular": {
      jointType: "rectangular-converging-tee",
      name: "Rectangular Main to Circular Branch Converging Tee",
      branchJoint: "square",
      branchShape: "circular",
      mainInlet: "rectangular",
      mainOutlet: "rectangular",

      // -0.63	-0.55	0.13	0.23	0.78	1.3	1.93	3.1	4.88	5.6
      branchC: {
        type: "airflow-table",
        QTable: {
          0.1: -0.63,
          0.2: -0.55,
          0.3: 0.13,
          0.4: 0.23,
          0.5: 0.78,
          0.6: 1.3,
          0.7: 1.93,
          0.8: 3.1,
          0.9: 4.88,
          1.0: 5.6,
        },
      },
      mainC: {
        type: "airflow-table",
        QTable: {
          0.1: 0.16,
          0.2: 0.27,
          0.3: 0.38,
          0.4: 0.46,
          0.5: 0.53,
          0.6: 0.57,
          0.7: 0.59,
          0.8: 0.6,
          0.9: 0.59,
          1.0: 0.55,
        },
      },
    },
    "circular-wye-45": {
      jointType: "circular-converging-tee",
      name: "Circular Wye 45° entry Converging Tee",
      branchAngle: 45,
      branchShape: "circular",
      mainInlet: "circular",
      mainOutlet: "circular",

      branchC: {
        type: "velocity-pressure-area",
        VPATable: {
          0.1: {
            0.1: 0.4,
            0.2: -0.37,
            0.3: -0.51,
            0.4: -0.46,
            0.5: -0.48,
            0.6: -0.5,
            0.7: -0.51,
            0.8: -0.51,
            0.9: -0.52,
            1.0: -0.52,
          },
          0.2: {
            0.1: 3.8,
            0.2: 0.72,
            0.3: 0.17,
            0.4: -0.02,
            0.5: -0.08,
            0.6: -0.14,
            0.7: -0.16,
            0.8: -0.18,
            0.9: -0.21,
            1.0: -0.24,
          },
          0.3: {
            0.1: 9.2,
            0.2: 2.3,
            0.3: 1.0,
            0.4: 0.44,
            0.5: 0.33,
            0.6: 0.21,
            0.7: 0.16,
            0.8: 0.11,
            0.9: 0.02,
            1.0: -0.08,
          },
          0.4: {
            0.1: 16.0,
            0.2: 4.3,
            0.3: 2.1,
            0.4: 0.94,
            0.5: 0.74,
            0.6: 0.54,
            0.7: 0.47,
            0.8: 0.4,
            0.9: 0.36,
            1.0: 0.32,
          },
          0.5: {
            0.1: 26.0,
            0.2: 6.8,
            0.3: 3.2,
            0.4: 1.1,
            0.5: 0.88,
            0.6: 0.66,
            0.7: 0.58,
            0.8: 0.49,
            0.9: 0.46,
            1.0: 0.42,
          },
          0.6: {
            0.1: 37.0,
            0.2: 9.7,
            0.3: 4.7,
            0.4: 1.6,
            0.5: 1.26,
            0.6: 0.92,
            0.7: 0.81,
            0.8: 0.69,
            0.9: 0.63,
            1.0: 0.57,
          },
          0.7: {
            0.1: 43.0,
            0.2: 13.0,
            0.3: 6.3,
            0.4: 2.1,
            0.5: 1.65,
            0.6: 1.2,
            0.7: 1.04,
            0.8: 0.88,
            0.9: 0.8,
            1.0: 0.72,
          },
          0.8: {
            0.1: 65.0,
            0.2: 17.0,
            0.3: 7.9,
            0.4: 2.7,
            0.5: 2.1,
            0.6: 1.5,
            0.7: 1.3,
            0.8: 1.1,
            0.9: 0.98,
            1.0: 0.86,
          },
          0.9: {
            0.1: 82.0,
            0.2: 21.0,
            0.3: 9.7,
            0.4: 3.4,
            0.5: 2.6,
            0.6: 1.8,
            0.7: 1.5,
            0.8: 1.2,
            0.9: 1.1,
            1.0: 0.99,
          },
          1.0: {
            0.1: 101.0,
            0.2: 26.0,
            0.3: 12.0,
            0.4: 4.0,
            0.5: 3.05,
            0.6: 2.1,
            0.7: 1.75,
            0.8: 1.4,
            0.9: 1.25,
            1.0: 1.1,
          },
        },
      },

      mainC: {
        type: "airflow-table",
        QTable: {
          0.1: 0.16,
          0.2: 0.27,
          0.3: 0.38,
          0.4: 0.46,
          0.5: 0.53,
          0.6: 0.57,
          0.7: 0.59,
          0.8: 0.6,
          0.9: 0.59,
          1.0: 0.55,
        },
      },
    },
    "circular-wye-90": {
      jointType: "circular-converging-tee",
      name: "Circular Wye 90° entry Converging Tee",
      branchAngle: 90,
      branchShape: "circular",
      mainInlet: "circular",
      mainOutlet: "circular",

      branchC: {
        type: "airflow-area-table",
        QATable: {
          0.1: {
            0.1: 0.4,
            0.2: -0.37,
            0.3: -0.51,
            0.4: -0.46,
            0.5: -0.48,
            0.6: -0.5,
            0.7: -0.51,
            0.8: -0.51,
            0.9: -0.52,
            1.0: -0.52,
          },
          0.2: {
            0.1: 3.8,
            0.2: 0.72,
            0.3: 0.17,
            0.4: -0.02,
            0.5: -0.08,
            0.6: -0.14,
            0.7: -0.16,
            0.8: -0.18,
            0.9: -0.21,
            1.0: -0.24,
          },
          0.3: {
            0.1: 9.2,
            0.2: 2.3,
            0.3: 1.0,
            0.4: 0.44,
            0.5: 0.33,
            0.6: 0.21,
            0.7: 0.16,
            0.8: 0.11,
            0.9: 0.02,
            1.0: -0.08,
          },
          0.4: {
            0.1: 16.0,
            0.2: 4.3,
            0.3: 2.1,
            0.4: 0.94,
            0.5: 0.74,
            0.6: 0.54,
            0.7: 0.47,
            0.8: 0.4,
            0.9: 0.36,
            1.0: 0.32,
          },
          0.5: {
            0.1: 26.0,
            0.2: 6.8,
            0.3: 3.2,
            0.4: 1.1,
            0.5: 0.88,
            0.6: 0.66,
            0.7: 0.58,
            0.8: 0.49,
            0.9: 0.46,
            1.0: 0.42,
          },
          0.6: {
            0.1: 37.0,
            0.2: 9.7,
            0.3: 4.7,
            0.4: 1.6,
            0.5: 1.26,
            0.6: 0.92,
            0.7: 0.81,
            0.8: 0.69,
            0.9: 0.63,
            1.0: 0.57,
          },
          0.7: {
            0.1: 43.0,
            0.2: 13.0,
            0.3: 6.3,
            0.4: 2.1,
            0.5: 1.65,
            0.6: 1.2,
            0.7: 1.04,
            0.8: 0.88,
            0.9: 0.8,
            1.0: 0.72,
          },
          0.8: {
            0.1: 65.0,
            0.2: 17.0,
            0.3: 7.9,
            0.4: 2.7,
            0.5: 2.1,
            0.6: 1.5,
            0.7: 1.3,
            0.8: 1.1,
            0.9: 0.98,
            1.0: 0.86,
          },
          0.9: {
            0.1: 82.0,
            0.2: 21.0,
            0.3: 9.7,
            0.4: 3.4,
            0.5: 2.6,
            0.6: 1.8,
            0.7: 1.5,
            0.8: 1.2,
            0.9: 1.1,
            1.0: 0.99,
          },
          1.0: {
            0.1: 101.0,
            0.2: 26.0,
            0.3: 12.0,
            0.4: 4.0,
            0.5: 3.05,
            0.6: 2.1,
            0.7: 1.75,
            0.8: 1.4,
            0.9: 1.25,
            1.0: 1.1,
          },
        },
      },
      mainC: {
        type: "airflow-table",
        QTable: {
          0.1: 0.16,
          0.2: 0.27,
          0.3: 0.38,
          0.4: 0.46,
          0.5: 0.53,
          0.6: 0.57,
          0.7: 0.59,
          0.8: 0.6,
          0.9: 0.59,
          1.0: 0.55,
        },
      },
    },
  },
  transitions: {
    circularDiverging: {
      name: "Circular Diverging Transition",
      direction: "diverging",
      inletShape: "circular",
      outletShape: "circular",
      data: {
        type: "angle-area-big-small",
        AnATable: {
          16.0: { 2.0: 0.07, 4.0: 0.15, 6.0: 0.19, 10.0: 0.2, 16.0: 0.21 },
          20.0: { 2.0: 0.12, 4.0: 18.0, 6.0: 0.28, 10.0: 0.24, 16.0: 0.28 },
          30.0: { 2.0: 0.23, 4.0: 0.36, 6.0: 0.44, 10.0: 0.43, 16.0: 0.52 },
          45.0: { 2.0: 0.28, 4.0: 0.55, 6.0: 0.9, 10.0: 0.76, 16.0: 0.76 },
          60.0: { 2.0: 0.27, 4.0: 0.59, 6.0: 0.7, 10.0: 0.8, 16.0: 0.87 },
          90.0: { 2.0: 0.27, 4.0: 0.59, 6.0: 0.71, 10.0: 0.81, 16.0: 0.87 },
          120.0: { 2.0: 0.27, 4.0: 0.58, 6.0: 0.71, 10.0: 0.81, 16.0: 0.87 },
          180.0: { 2.0: 0.26, 4.0: 0.57, 6.0: 0.69, 10.0: 0.81, 16.0: 0.87 },
        },
      },
    },
    rectDiverging: {
      name: "Rectangular Diverging Transition",
      direction: "diverging",
      inletShape: "rectangular",
      outletShape: "rectangular",
      data: {
        type: "angle-area-big-small",
        AnATable: {
          16.0: { 2.0: 0.07, 4.0: 0.15, 6.0: 0.19, 10.0: 0.2, 16.0: 0.21 },
          20.0: { 2.0: 0.12, 4.0: 18.0, 6.0: 0.28, 10.0: 0.24, 16.0: 0.28 },
          30.0: { 2.0: 0.23, 4.0: 0.36, 6.0: 0.44, 10.0: 0.43, 16.0: 0.52 },
          45.0: { 2.0: 0.28, 4.0: 0.55, 6.0: 0.9, 10.0: 0.76, 16.0: 0.76 },
          60.0: { 2.0: 0.27, 4.0: 0.59, 6.0: 0.7, 10.0: 0.8, 16.0: 0.87 },
          90.0: { 2.0: 0.27, 4.0: 0.59, 6.0: 0.71, 10.0: 0.81, 16.0: 0.87 },
          120.0: { 2.0: 0.27, 4.0: 0.58, 6.0: 0.71, 10.0: 0.81, 16.0: 0.87 },
          180.0: { 2.0: 0.26, 4.0: 0.57, 6.0: 0.69, 10.0: 0.81, 16.0: 0.87 },
        },
      },
    },
    rectCircDiverging: {
      name: "Rectangular to Circular Diverging Transition",
      direction: "diverging",
      inletShape: "rectangular",
      outletShape: "circular",
      data: {
        type: "angle-area-big-small",
        AnATable: {
          16.0: { 2.0: 0.07, 4.0: 0.15, 6.0: 0.19, 10.0: 0.2, 16.0: 0.21 },
          20.0: { 2.0: 0.12, 4.0: 18.0, 6.0: 0.28, 10.0: 0.24, 16.0: 0.28 },
          30.0: { 2.0: 0.23, 4.0: 0.36, 6.0: 0.44, 10.0: 0.43, 16.0: 0.52 },
          45.0: { 2.0: 0.28, 4.0: 0.55, 6.0: 0.9, 10.0: 0.76, 16.0: 0.76 },
          60.0: { 2.0: 0.27, 4.0: 0.59, 6.0: 0.7, 10.0: 0.8, 16.0: 0.87 },
          90.0: { 2.0: 0.27, 4.0: 0.59, 6.0: 0.71, 10.0: 0.81, 16.0: 0.87 },
          120.0: { 2.0: 0.27, 4.0: 0.58, 6.0: 0.71, 10.0: 0.81, 16.0: 0.87 },
          180.0: { 2.0: 0.26, 4.0: 0.57, 6.0: 0.69, 10.0: 0.81, 16.0: 0.87 },
        },
      },
    },
    circRectDiverging: {
      name: "Circular to Rectangular Diverging Transition",
      direction: "diverging",
      inletShape: "circular",
      outletShape: "rectangular",
      data: {
        type: "angle-area-big-small",
        AnATable: {
          16.0: { 2.0: 0.07, 4.0: 0.15, 6.0: 0.19, 10.0: 0.2, 16.0: 0.21 },
          20.0: { 2.0: 0.12, 4.0: 18.0, 6.0: 0.28, 10.0: 0.24, 16.0: 0.28 },
          30.0: { 2.0: 0.23, 4.0: 0.36, 6.0: 0.44, 10.0: 0.43, 16.0: 0.52 },
          45.0: { 2.0: 0.28, 4.0: 0.55, 6.0: 0.9, 10.0: 0.76, 16.0: 0.76 },
          60.0: { 2.0: 0.27, 4.0: 0.59, 6.0: 0.7, 10.0: 0.8, 16.0: 0.87 },
          90.0: { 2.0: 0.27, 4.0: 0.59, 6.0: 0.71, 10.0: 0.81, 16.0: 0.87 },
          120.0: { 2.0: 0.27, 4.0: 0.58, 6.0: 0.71, 10.0: 0.81, 16.0: 0.87 },
          180.0: { 2.0: 0.26, 4.0: 0.57, 6.0: 0.69, 10.0: 0.81, 16.0: 0.87 },
        },
      },
    },
    circConverging: {
      name: "Circular Converging Transition",
      direction: "converging",
      inletShape: "circular",
      outletShape: "circular",
      data: {
        type: "angle-area-big-small",
        AnATable: {
          10.0: { 2.0: 0.05, 4.0: 0.05, 6.0: 0.05, 10.0: 0.05 },
          20.0: { 2.0: 0.05, 4.0: 0.04, 6.0: 0.04, 10.0: 0.05 },
          60.0: { 2.0: 0.06, 4.0: 0.07, 6.0: 0.07, 10.0: 0.08 },
          90.0: { 2.0: 0.12, 4.0: 0.017, 6.0: 0.18, 10.0: 0.19 },
          120.0: { 2.0: 0.18, 4.0: 0.27, 6.0: 0.28, 10.0: 0.29 },
          150.0: { 2.0: 0.24, 4.0: 0.35, 6.0: 0.36, 10.0: 0.37 },
          180.0: { 2.0: 0.26, 4.0: 0.41, 6.0: 0.42, 10.0: 0.43 },
        },
      },
    },

    rectConverging: {
      name: "Rectangular Converging Transition",
      direction: "converging",
      inletShape: "rectangular",
      outletShape: "rectangular",
      data: {
        type: "angle-area-big-small",
        AnATable: {
          10.0: { 2.0: 0.05, 4.0: 0.05, 6.0: 0.05, 10.0: 0.05 },
          20.0: { 2.0: 0.05, 4.0: 0.04, 6.0: 0.04, 10.0: 0.05 },
          60.0: { 2.0: 0.06, 4.0: 0.07, 6.0: 0.07, 10.0: 0.08 },
          90.0: { 2.0: 0.12, 4.0: 0.017, 6.0: 0.18, 10.0: 0.19 },
          120.0: { 2.0: 0.18, 4.0: 0.27, 6.0: 0.28, 10.0: 0.29 },
          150.0: { 2.0: 0.24, 4.0: 0.35, 6.0: 0.36, 10.0: 0.37 },
          180.0: { 2.0: 0.26, 4.0: 0.41, 6.0: 0.42, 10.0: 0.43 },
        },
      },
    },
    rectCircConverging: {
      name: "Rectangular to Circular Converging Transition",
      direction: "converging",
      inletShape: "rectangular",
      outletShape: "circular",
      data: {
        type: "angle-area-big-small",
        AnATable: {
          10.0: { 2.0: 0.05, 4.0: 0.05, 6.0: 0.05, 10.0: 0.05 },
          20.0: { 2.0: 0.05, 4.0: 0.04, 6.0: 0.04, 10.0: 0.05 },
          60.0: { 2.0: 0.06, 4.0: 0.07, 6.0: 0.07, 10.0: 0.08 },
          90.0: { 2.0: 0.12, 4.0: 0.017, 6.0: 0.18, 10.0: 0.19 },
          120.0: { 2.0: 0.18, 4.0: 0.27, 6.0: 0.28, 10.0: 0.29 },
          150.0: { 2.0: 0.24, 4.0: 0.35, 6.0: 0.36, 10.0: 0.37 },
          180.0: { 2.0: 0.26, 4.0: 0.41, 6.0: 0.42, 10.0: 0.43 },
        },
      },
    },
    circRectConverging: {
      name: "Circular Rectangular Converging Transition",
      direction: "converging",
      inletShape: "circular",
      outletShape: "rectangular",
      data: {
        type: "angle-area-big-small",
        AnATable: {
          10.0: { 2.0: 0.05, 4.0: 0.05, 6.0: 0.05, 10.0: 0.05 },
          20.0: { 2.0: 0.05, 4.0: 0.04, 6.0: 0.04, 10.0: 0.05 },
          60.0: { 2.0: 0.06, 4.0: 0.07, 6.0: 0.07, 10.0: 0.08 },
          90.0: { 2.0: 0.12, 4.0: 0.017, 6.0: 0.18, 10.0: 0.19 },
          120.0: { 2.0: 0.18, 4.0: 0.27, 6.0: 0.28, 10.0: 0.29 },
          150.0: { 2.0: 0.24, 4.0: 0.35, 6.0: 0.36, 10.0: 0.37 },
          180.0: { 2.0: 0.26, 4.0: 0.41, 6.0: 0.42, 10.0: 0.43 },
        },
      },
    },
  },
};
