import { Color } from "../../../../lib/color";
import { Units } from "../../../../lib/measurements";
import { cloneSimple, parseCatalogNumberOrMin } from "../../../../lib/utils";
import { CoreContext } from "../../../calculations/types";
import { SelectedMaterialManufacturer } from "../../drawing";
import { FieldType, PropertyField } from "../property-field";
import {
  DrawableEntity,
  NamedEntity,
  PositionedEntity,
} from "../simple-entities";
import { EntityType } from "../types";

export enum BigValveType {
  TMV = "TMV",
  RPZD_HOT_COLD = "RPZD_HOT_COLD",
  TEMPERING = "TEMPERING",
}

export interface TmvValve {
  type: BigValveType.TMV;
  catalogId: "tmv";
  warmOutputUid: string;
  coldOutputUid: string;
}

export interface TemperingValve {
  type: BigValveType.TEMPERING;
  catalogId: "temperingValve";
  warmOutputUid: string;
}

export interface RpzdHotColdValve {
  type: BigValveType.RPZD_HOT_COLD;
  catalogId: "RPZD";
  hotOutputUid: string;
  coldOutputUid: string;
}

export default interface BigValveEntity
  extends DrawableEntity,
    NamedEntity,
    PositionedEntity {
  type: EntityType.BIG_VALVE;
  coldRoughInUid: string;
  hotRoughInUid: string;
  valve: TmvValve | TemperingValve | RpzdHotColdValve;

  pipeDistanceMM: number;
  valveLengthMM: number;
  heightAboveFloorM: number;

  outputTemperatureC: number;

  minInletPressureKPA: number | null;
  maxInletPressureKPA: number | null;
  maxHotColdPressureDifferentialPCT: number | null;
  minFlowRateLS: number | null;
  maxFlowRateLS: number | null;
}

export function makeBigValveFields(
  context: CoreContext,
  entity: BigValveEntity,
): PropertyField[] {
  return [
    {
      property: "entityName",
      title: "Name",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Text,
      params: null,
      multiFieldId: "entityName",
    },
    {
      property: "pipeDistanceMM",
      title: "Size",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 75, max: 150, step: 7.5, displayMin: 50, displayMax: 100 },
      multiFieldId: "pipeDistanceMM",
      units: Units.Percent,
    },
    {
      property: "rotation",
      title: "Rotation: (Degrees)",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Rotation,
      params: {
        step: 45,
        disableFreeInput: false,
      },
      multiFieldId: "rotation",
    },

    {
      property: "heightAboveFloorM",
      title: "Height Above Floor",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: null, max: null },
      multiFieldId: "heightAboveFloorM",
      units: Units.Meters,
    },

    {
      property: "outputTemperatureC",
      title: "Output Temperature",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: 100 },
      multiFieldId: "outputTemperatureC",
      units: Units.Celsius,
    },

    {
      property: "minInletPressureKPA",
      title: "Min. Inlet Pressure",
      hasDefault: true,
      isCalculated: false,
      highlightOnOverride: Color.YELLOW,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "minInletPressureKPA",
      units: Units.KiloPascals,
    },

    {
      property: "maxInletPressureKPA",
      title: "Max. Inlet Pressure",
      hasDefault: true,
      highlightOnOverride: Color.YELLOW,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "maxInletPressureKPA",
      units: Units.KiloPascals,
    },

    {
      property: "minFlowRateLS",
      title: "Min. Flow Rate",
      hasDefault: true,
      isCalculated: false,
      highlightOnOverride: Color.YELLOW,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "minFlowRateLS",
      units: Units.LitersPerSecond,
    },

    {
      property: "maxFlowRateLS",
      title: "Max. Flow Rate",
      hasDefault: true,
      isCalculated: false,
      highlightOnOverride: Color.YELLOW,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "maxFlowRateLS",
      units: Units.LitersPerSecond,
    },
  ];
}

export function fillDefaultBigValveFields(
  context: CoreContext,
  entity: BigValveEntity,
) {
  const { catalog, drawing } = context;
  const result = cloneSimple(entity);

  const checkSelectedMaterialManufacturer =
    drawing.metadata.catalog.mixingValves.find(
      (material: SelectedMaterialManufacturer) => material.uid === "tmv",
    );
  const manufacturer =
    (checkSelectedMaterialManufacturer &&
      checkSelectedMaterialManufacturer.manufacturer) ||
    "generic";

  const arr: Array<
    | "minInletPressureKPA"
    | "maxInletPressureKPA"
    | "minFlowRateLS"
    | "maxFlowRateLS"
  > = [
    "minInletPressureKPA",
    "maxInletPressureKPA",
    "minFlowRateLS",
    "maxFlowRateLS",
  ];

  arr.forEach((field) => {
    if (result[field] === null) {
      result[field] = parseCatalogNumberOrMin(
        catalog.mixingValves.tmv[field][manufacturer],
      );
    }
  });

  if (!entity.pipeDistanceMM) {
    // it was known as 150 in the old project (100%)
    result.pipeDistanceMM = 150;
  }
  return result;
}

export enum FlowConfiguration {
  INPUT,
  OUTPUT,
  BOTH,
}
