import { ManufacturerRadiatorData } from "../../types";

// used by UK catalogue
// stelrad radiators must have nominalOutputFiftyDeltaWatt and nCoefficient
export const StelradRadiators: ManufacturerRadiatorData[] = [
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 300 H x 500 W",
    widthMM: 500,
    heightMM: 300,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.491,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.5 * 3.7,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 300 H x 1000 W",
    widthMM: 1000,
    heightMM: 300,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.983,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1 * 3.7,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 300 H x 1400 W",
    widthMM: 1400,
    heightMM: 300,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.376,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.4 * 3.7,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 300 H x 1500 W",
    widthMM: 1500,
    heightMM: 300,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.259,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.5 * 3.7,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 300 H x 2000 W",
    widthMM: 2000,
    heightMM: 300,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.964,
    depthMM: 140,
    kvValue: 20,
    volumeL: 2 * 3.7,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 300 H x 2500 W",
    widthMM: 2500,
    heightMM: 300,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.455,
    depthMM: 140,
    kvValue: 20,
    volumeL: 2.5 * 3.7,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 300 H x 3000 W",
    widthMM: 3000,
    heightMM: 300,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.946,
    depthMM: 140,
    kvValue: 20,
    volumeL: 3 * 3.7,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 450 H x 400 W",
    widthMM: 400,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.548,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.4 * 5.15,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 450 H x 500 W",
    widthMM: 500,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.686,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.5 * 5.15,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 450 H x 600 W",
    widthMM: 600,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.823,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.6 * 5.15,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 450 H x 700 W",
    widthMM: 700,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.96,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.7 * 5.15,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 450 H x 800 W",
    widthMM: 800,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.097,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.8 * 5.15,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 450 H x 900 W",
    widthMM: 900,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.234,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.8 * 5.15,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 450 H x 1000 W",
    widthMM: 1000,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.371,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1 * 5.15,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 450 H x 1400 W",
    widthMM: 1400,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.919,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.4 * 5.15,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 450 H x 1600 W",
    widthMM: 1600,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.194,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.6 * 5.15,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 450 H x 1800 W",
    widthMM: 1800,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.468,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.8 * 5.15,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 450 H x 2000 W",
    widthMM: 2000,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.742,
    depthMM: 140,
    kvValue: 20,
    volumeL: 2 * 5.15,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 450 H x 2200 W",
    widthMM: 2200,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 3.016,
    depthMM: 140,
    kvValue: 20,
    volumeL: 2.2 * 5.15,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 450 H x 2400 W",
    widthMM: 2400,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 3.29,
    depthMM: 140,
    kvValue: 20,
    volumeL: 2.4 * 5.15,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 450 H x 2600 W",
    widthMM: 2600,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 3.565,
    depthMM: 140,
    kvValue: 20,
    volumeL: 2.6 * 5.15,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 450 H x 2800 W",
    widthMM: 2800,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 3.839,
    depthMM: 140,
    kvValue: 20,
    volumeL: 2.8 * 5.15,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 450 H x 3000 W",
    widthMM: 3000,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 4.113,
    depthMM: 140,
    kvValue: 20,
    volumeL: 3 * 5.15,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 600 H x 300 W",
    widthMM: 300,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.52,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.3 * 6.6,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 600 H x 600 W",
    widthMM: 600,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.048,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.6 * 6.6,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 600 H x 800 W",
    widthMM: 800,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.398,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.8 * 6.6,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 600 H x 900 W",
    widthMM: 900,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.572,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.9 * 6.6,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 600 H x 1000 W",
    widthMM: 1000,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.747,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1 * 6.6,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 600 H x 1200 W",
    widthMM: 1200,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.096,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.2 * 6.6,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 600 H x 1400 W",
    widthMM: 1400,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.446,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.4 * 6.6,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 600 H x 1600 W",
    widthMM: 1600,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.795,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.6 * 6.6,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 600 H x 2000 W",
    widthMM: 2000,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 3.494,
    depthMM: 140,
    kvValue: 20,
    volumeL: 2 * 6.6,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 600 H x 2200 W",
    widthMM: 2200,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 3.81,
    depthMM: 140,
    kvValue: 20,
    volumeL: 2.2 * 6.6,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 600 H x 2400 W",
    widthMM: 2400,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 4.157,
    depthMM: 140,
    kvValue: 20,
    volumeL: 2.4 * 6.6,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 600 H x 2600 W",
    widthMM: 2600,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 4.503,
    depthMM: 140,
    kvValue: 20,
    volumeL: 2.6 * 6.6,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 600 H x 2800 W",
    widthMM: 2800,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 4.85,
    depthMM: 140,
    kvValue: 20,
    volumeL: 2.8 * 6.6,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 600 H x 3000 W",
    widthMM: 3000,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 5.196,
    depthMM: 140,
    kvValue: 20,
    volumeL: 3 * 6.6,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 700 H x 300 W",
    widthMM: 300,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 0.588,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.3 * 7.63,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 700 H x 400 W",
    widthMM: 400,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 0.785,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.4 * 7.63,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 700 H x 500 W",
    widthMM: 500,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 0.982,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.5 * 7.63,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 700 H x 600 W",
    widthMM: 600,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 1.178,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.6 * 7.63,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 700 H x 700 W",
    widthMM: 700,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 1.374,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.7 * 7.63,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 700 H x 900 W",
    widthMM: 900,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 1.767,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.9 * 7.63,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 700 H x 1000 W",
    widthMM: 1000,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 1.963,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1 * 7.63,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 700 H x 1100 W",
    widthMM: 1100,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 2.159,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.1 * 7.63,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 700 H x 1600 W",
    widthMM: 1600,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 3.138,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.6 * 7.63,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 700 H x 1800 W",
    widthMM: 1800,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 3.53,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.8 * 7.63,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 700 H x 2000 W",
    widthMM: 2000,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 3.922,
    depthMM: 140,
    kvValue: 20,
    volumeL: 2 * 7.63,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 700 H x 2200 W",
    widthMM: 2200,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 4.314,
    depthMM: 140,
    kvValue: 20,
    volumeL: 2.2 * 7.63,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 700 H x 2400 W",
    widthMM: 2400,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 4.706,
    depthMM: 140,
    kvValue: 20,
    volumeL: 2.4 * 7.63,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 700 H x 3000 W",
    widthMM: 3000,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 5.883,
    depthMM: 140,
    kvValue: 20,
    volumeL: 3 * 7.63,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 900 H x 500 W",
    widthMM: 500,
    heightMM: 900,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.35,
    nominalDeltaT50KW: 1.198,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.5 * 9.55,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 900 H x 700 W",
    widthMM: 700,
    heightMM: 900,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.35,
    nominalDeltaT50KW: 1.677,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.7 * 9.55,
  },
  {
    rangeType: "Compact K2",
    model: "Stelrad Compact K2 900 H x 1000 W",
    widthMM: 1000,
    heightMM: 900,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.35,
    nominalDeltaT50KW: 2.395,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1 * 9.55,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 300 H x 1000 W",
    widthMM: 1000,
    heightMM: 300,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.31,
    nominalDeltaT50KW: 1.349,
    depthMM: 150,
    kvValue: 20,
    volumeL: 1 * 5.4,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 300 H x 2000 W",
    widthMM: 2000,
    heightMM: 300,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.31,
    nominalDeltaT50KW: 2.698,
    depthMM: 150,
    kvValue: 20,
    volumeL: 2 * 5.4,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 300 H x 2000 W",
    widthMM: 2000,
    heightMM: 300,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.31,
    nominalDeltaT50KW: 2.968,
    depthMM: 150,
    kvValue: 20,
    volumeL: 2 * 5.4,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 500 H x 600 W",
    widthMM: 600,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 1.234,
    depthMM: 150,
    kvValue: 20,
    volumeL: 0.5 * 8.33,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 500 H x 700 W",
    widthMM: 700,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 1.439,
    depthMM: 150,
    kvValue: 20,
    volumeL: 0.7 * 8.33,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 500 H x 800 W",
    widthMM: 800,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 1.645,
    depthMM: 150,
    kvValue: 20,
    volumeL: 0.8 * 8.33,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 500 H x 900 W",
    widthMM: 900,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 1.85,
    depthMM: 150,
    kvValue: 20,
    volumeL: 0.9 * 8.33,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 500 H x 1000 W",
    widthMM: 1000,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 2.056,
    depthMM: 150,
    kvValue: 20,
    volumeL: 1 * 8.33,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 500 H x 1100 W",
    widthMM: 1100,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 2.262,
    depthMM: 150,
    kvValue: 20,
    volumeL: 1.1 * 8.33,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 500 H x 1200 W",
    widthMM: 1200,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 2.467,
    depthMM: 150,
    kvValue: 20,
    volumeL: 1.2 * 8.33,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 500 H x 1400 W",
    widthMM: 1400,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 2.878,
    depthMM: 150,
    kvValue: 20,
    volumeL: 1.4 * 8.33,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 500 H x 1600 W",
    widthMM: 1600,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 3.29,
    depthMM: 150,
    kvValue: 20,
    volumeL: 1.6 * 8.33,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 500 H x 1800 W",
    widthMM: 1800,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 3.701,
    depthMM: 150,
    kvValue: 20,
    volumeL: 1.8 * 8.33,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 500 H x 2000 W",
    widthMM: 2000,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 4.112,
    depthMM: 150,
    kvValue: 20,
    volumeL: 2 * 8.33,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 500 H x 2400 W",
    widthMM: 2400,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 4.934,
    depthMM: 150,
    kvValue: 20,
    volumeL: 2.4 * 8.33,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 600 H x 400 W",
    widthMM: 400,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 0.956,
    depthMM: 150,
    kvValue: 20,
    volumeL: 0.4 * 9.8,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 600 H x 500 W",
    widthMM: 500,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 1.195,
    depthMM: 150,
    kvValue: 20,
    volumeL: 0.5 * 9.8,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 600 H x 600 W",
    widthMM: 600,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 1.433,
    depthMM: 150,
    kvValue: 20,
    volumeL: 0.6 * 9.8,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 600 H x 700 W",
    widthMM: 700,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 1.672,
    depthMM: 150,
    kvValue: 20,
    volumeL: 0.7 * 9.8,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 600 H x 800 W",
    widthMM: 800,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 1.911,
    depthMM: 150,
    kvValue: 20,
    volumeL: 0.8 * 9.8,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 600 H x 900 W",
    widthMM: 900,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 2.15,
    depthMM: 150,
    kvValue: 20,
    volumeL: 0.9 * 9.8,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 600 H x 1000 W",
    widthMM: 1000,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 2.389,
    depthMM: 150,
    kvValue: 20,
    volumeL: 1 * 9.8,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 600 H x 1100 W",
    widthMM: 1100,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 2.628,
    depthMM: 150,
    kvValue: 20,
    volumeL: 1.1 * 9.8,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 600 H x 1200 W",
    widthMM: 1200,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 2.867,
    depthMM: 150,
    kvValue: 20,
    volumeL: 1.2 * 9.8,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 600 H x 1400 W",
    widthMM: 1400,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 3.345,
    depthMM: 150,
    kvValue: 20,
    volumeL: 1.4 * 9.8,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 600 H x 1600 W",
    widthMM: 1600,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 3.822,
    depthMM: 150,
    kvValue: 20,
    volumeL: 1.6 * 9.8,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 600 H x 1800 W",
    widthMM: 1800,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 4.3,
    depthMM: 150,
    kvValue: 20,
    volumeL: 1.8 * 9.8,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 600 H x 2000 W",
    widthMM: 2000,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 4.778,
    depthMM: 150,
    kvValue: 20,
    volumeL: 2 * 9.8,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 600 H x 2400 W",
    widthMM: 2400,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 5.734,
    depthMM: 150,
    kvValue: 20,
    volumeL: 2.4 * 9.8,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 700 H x 500 W",
    widthMM: 500,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 1.356,
    depthMM: 150,
    kvValue: 20,
    volumeL: 0.5 * 11.37,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 700 H x 600 W",
    widthMM: 600,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 1.627,
    depthMM: 150,
    kvValue: 20,
    volumeL: 0.6 * 11.37,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 700 H x 700 W",
    widthMM: 700,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 1.898,
    depthMM: 150,
    kvValue: 20,
    volumeL: 0.7 * 11.37,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 700 H x 800 W",
    widthMM: 800,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 2.17,
    depthMM: 150,
    kvValue: 20,
    volumeL: 0.8 * 11.37,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 700 H x 900 W",
    widthMM: 900,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 2.441,
    depthMM: 150,
    kvValue: 20,
    volumeL: 0.9 * 11.37,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 700 H x 1000 W",
    widthMM: 1000,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 2.712,
    depthMM: 150,
    kvValue: 20,
    volumeL: 1 * 11.37,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 700 H x 1100 W",
    widthMM: 1100,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 2.983,
    depthMM: 150,
    kvValue: 20,
    volumeL: 1.1 * 11.37,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 700 H x 1200 W",
    widthMM: 1200,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 3.254,
    depthMM: 150,
    kvValue: 20,
    volumeL: 1.2 * 11.37,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 700 H x 1400 W",
    widthMM: 1400,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 3.797,
    depthMM: 150,
    kvValue: 20,
    volumeL: 1.4 * 11.37,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 700 H x 1600 W",
    widthMM: 1600,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 4.439,
    depthMM: 150,
    kvValue: 20,
    volumeL: 1.6 * 11.37,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 700 H x 1800 W",
    widthMM: 1800,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 4.882,
    depthMM: 150,
    kvValue: 20,
    volumeL: 1.8 * 11.37,
  },
  {
    rangeType: "Compact K3",
    model: "Stelrad Compact K3 700 H x 2000 W",
    widthMM: 2000,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 5.424,
    depthMM: 150,
    kvValue: 20,
    volumeL: 2 * 11.37,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 300 H x 500 W",
    widthMM: 500,
    heightMM: 300,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.373,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.5 * 3.7,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 300 H x 1000 W",
    widthMM: 1000,
    heightMM: 300,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.745,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1 * 3.7,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 300 H x 1500 W",
    widthMM: 1500,
    heightMM: 300,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.118,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.5 * 3.7,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 300 H x 2500 W",
    widthMM: 2500,
    heightMM: 300,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.863,
    depthMM: 130,
    kvValue: 20,
    volumeL: 2.5 * 3.7,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 450 H x 400 W",
    widthMM: 400,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.422,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.4 * 5.15,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 450 H x 500 W",
    widthMM: 500,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.528,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.5 * 5.15,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 450 H x 600 W",
    widthMM: 600,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.633,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.6 * 5.15,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 450 H x 700 W",
    widthMM: 700,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.739,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.7 * 5.15,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 450 H x 800 W",
    widthMM: 800,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.844,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.8 * 5.15,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 450 H x 900 W",
    widthMM: 900,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.95,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.9 * 5.15,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 450 H x 1100 W",
    widthMM: 1100,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.161,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.1 * 5.15,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 450 H x 1400 W",
    widthMM: 1400,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.477,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.4 * 5.15,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 450 H x 1600 W",
    widthMM: 1600,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.688,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.6 * 5.15,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 450 H x 1800 W",
    widthMM: 1800,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.899,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.8 * 5.15,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 450 H x 2000 W",
    widthMM: 2000,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.11,
    depthMM: 130,
    kvValue: 20,
    volumeL: 2 * 5.15,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 450 H x 2200 W",
    widthMM: 2200,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.321,
    depthMM: 130,
    kvValue: 20,
    volumeL: 2.2 * 5.15,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 450 H x 2400 W",
    widthMM: 2400,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.532,
    depthMM: 130,
    kvValue: 20,
    volumeL: 2.4 * 5.15,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 450 H x 2600 W",
    widthMM: 2600,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.743,
    depthMM: 130,
    kvValue: 20,
    volumeL: 2.6 * 5.15,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 450 H x 2800 W",
    widthMM: 2800,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.954,
    depthMM: 130,
    kvValue: 20,
    volumeL: 2.8 * 5.15,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 450 H x 3000 W",
    widthMM: 3000,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 3.165,
    depthMM: 130,
    kvValue: 20,
    volumeL: 3 * 5.15,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 600 H x 400 W",
    widthMM: 400,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.545,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.4 * 6.6,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 600 H x 500 W",
    widthMM: 500,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.681,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.5 * 6.6,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 600 H x 700 W",
    widthMM: 700,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.953,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.7 * 6.6,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 600 H x 1100 W",
    widthMM: 1100,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.498,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.1 * 6.6,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 600 H x 1600 W",
    widthMM: 1600,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.179,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.6 * 6.6,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 600 H x 1800 W",
    widthMM: 1800,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.452,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.8 * 6.6,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 600 H x 2000 W",
    widthMM: 2000,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.69,
    depthMM: 130,
    kvValue: 20,
    volumeL: 2 * 6.6,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 600 H x 2200 W",
    widthMM: 2200,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.959,
    depthMM: 130,
    kvValue: 20,
    volumeL: 2.2 * 6.6,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 700 H x 400 W",
    widthMM: 400,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 0.612,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.4 * 7.63,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 700 H x 500 W",
    widthMM: 500,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 0.77,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.5 * 7.63,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 700 H x 600 W",
    widthMM: 600,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 0.923,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.6 * 7.63,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 700 H x 700 W",
    widthMM: 700,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 1.071,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.7 * 7.63,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 700 H x 800 W",
    widthMM: 800,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 1.569,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.8 * 7.63,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 700 H x 800 W",
    widthMM: 800,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 1.224,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.8 * 7.63,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 700 H x 900 W",
    widthMM: 900,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 1.377,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.9 * 7.63,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 700 H x 1000 W",
    widthMM: 1000,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 1.53,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1 * 7.63,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 700 H x 1100 W",
    widthMM: 1100,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 1.683,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.1 * 7.63,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 700 H x 1200 W",
    widthMM: 1200,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 1.836,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.2 * 7.63,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 700 H x 1400 W",
    widthMM: 1400,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 2.142,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.4 * 7.63,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 700 H x 1600 W",
    widthMM: 1600,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 2.448,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.6 * 7.63,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 700 H x 1800 W",
    widthMM: 1800,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 2.754,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.8 * 7.63,
  },
  {
    rangeType: "Compact P+",
    model: "Stelrad Compact P+ 700 H x 2000 W",
    widthMM: 2000,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 3.06,
    depthMM: 130,
    kvValue: 20,
    volumeL: 2 * 7.63,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 300 H x 1000 W",
    widthMM: 1000,
    heightMM: 300,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.983,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1 * 3.7,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 300 H x 1400 W",
    widthMM: 1400,
    heightMM: 300,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.376,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.4 * 3.7,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 300 H x 1800 W",
    widthMM: 1800,
    heightMM: 300,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.769,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.8 * 3.7,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 400 H x 800 W",
    widthMM: 800,
    heightMM: 400,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.007,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.8 * 4.75,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 400 H x 1000 W",
    widthMM: 1000,
    heightMM: 400,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.259,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1 * 4.75,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 400 H x 1200 W",
    widthMM: 1200,
    heightMM: 400,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.511,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.2 * 4.75,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 400 H x 1400 W",
    widthMM: 1400,
    heightMM: 400,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.763,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.4 * 4.75,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 400 H x 1600 W",
    widthMM: 1600,
    heightMM: 400,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.014,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.6 * 4.75,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 400 H x 1800 W",
    widthMM: 1800,
    heightMM: 400,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.266,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.8 * 4.75,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 400 H x 2000 W",
    widthMM: 2000,
    heightMM: 400,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.518,
    depthMM: 140,
    kvValue: 20,
    volumeL: 2 * 4.75,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 500 H x 600 W",
    widthMM: 600,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.908,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.6 * 5.83,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 500 H x 800 W",
    widthMM: 800,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.21,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.8 * 5.83,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 500 H x 900 W",
    widthMM: 900,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.362,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.9 * 5.83,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 500 H x 1000 W",
    widthMM: 1000,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.513,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1 * 5.83,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 500 H x 1100 W",
    widthMM: 1100,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.664,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.1 * 5.83,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 500 H x 1200 W",
    widthMM: 1200,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.816,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.2 * 5.83,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 500 H x 1400 W",
    widthMM: 1400,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.118,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.4 * 5.83,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 500 H x 1600 W",
    widthMM: 1600,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.421,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.6 * 5.83,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 500 H x 1800 W",
    widthMM: 1800,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.723,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.8 * 5.83,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 600 H x 400 W",
    widthMM: 400,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.693,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.4 * 6.6,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 600 H x 500 W",
    widthMM: 500,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.874,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.5 * 6.6,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 600 H x 600 W",
    widthMM: 600,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.048,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.6 * 6.6,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 600 H x 700 W",
    widthMM: 700,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.223,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.7 * 6.6,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 600 H x 800 W",
    widthMM: 800,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.398,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.8 * 6.6,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 600 H x 900 W",
    widthMM: 900,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.572,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.9 * 6.6,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 600 H x 1000 W",
    widthMM: 1000,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.747,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1 * 6.6,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 600 H x 1100 W",
    widthMM: 1100,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.922,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.1 * 6.6,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 600 H x 1200 W",
    widthMM: 1200,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.096,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.2 * 6.6,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 600 H x 1300 W",
    widthMM: 1300,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.271,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.3 * 6.6,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 600 H x 1400 W",
    widthMM: 1400,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.446,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.4 * 6.6,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 600 H x 1600 W",
    widthMM: 1600,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.795,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.6 * 6.6,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 600 H x 1800 W",
    widthMM: 1800,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 3.145,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.8 * 6.6,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 600 H x 2000 W",
    widthMM: 2000,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 3.494,
    depthMM: 140,
    kvValue: 20,
    volumeL: 2 * 6.6,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 700 H x 400 W",
    widthMM: 400,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 0.785,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.4 * 7.63,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 700 H x 500 W",
    widthMM: 500,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 0.982,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.5 * 7.63,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 700 H x 600 W",
    widthMM: 600,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 1.178,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.6 * 7.63,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 700 H x 700 W",
    widthMM: 700,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 1.374,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.7 * 7.63,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 700 H x 800 W",
    widthMM: 800,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 1.57,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.8 * 7.63,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 700 H x 900 W",
    widthMM: 900,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 1.767,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.9 * 7.63,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 700 H x 1000 W",
    widthMM: 1000,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 1.963,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1 * 7.63,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 700 H x 1100 W",
    widthMM: 1100,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 2.159,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.1 * 7.63,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 700 H x 1200 W",
    widthMM: 1200,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 2.356,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.2 * 7.63,
  },
  {
    rangeType: "Halcyon K2",
    model: "Stelrad Halcyon K2 700 H x 1400 W",
    widthMM: 1400,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 2.748,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.4 * 7.63,
  },
  {
    rangeType: "Halcyon P+",
    model: "Stelrad Halcyon P+ 400 H x 1000 W",
    widthMM: 1000,
    heightMM: 400,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.977,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.4 * 4.7,
  },
  {
    rangeType: "Halcyon P+",
    model: "Stelrad Halcyon P+ 500 H x 800 W",
    widthMM: 800,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.94,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.5 * 5.83,
  },
  {
    rangeType: "Halcyon P+",
    model: "Stelrad Halcyon P+ 500 H x 1000 W",
    widthMM: 1000,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.175,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1 * 5.83,
  },
  {
    rangeType: "Halcyon P+",
    model: "Stelrad Halcyon P+ 500 H x 1200 W",
    widthMM: 1200,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.172,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.2 * 5.83,
  },
  {
    rangeType: "Halcyon P+",
    model: "Stelrad Halcyon P+ 500 H x 1200 W",
    widthMM: 1200,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.41,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.2 * 5.83,
  },
  {
    rangeType: "Halcyon P+",
    model: "Stelrad Halcyon P+ 500 H x 1400 W",
    widthMM: 1400,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.645,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.4 * 5.83,
  },
  {
    rangeType: "Halcyon P+",
    model: "Stelrad Halcyon P+ 500 H x 1600 W",
    widthMM: 1600,
    heightMM: 500,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.88,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.6 * 5.83,
  },
  {
    rangeType: "Halcyon P+",
    model: "Stelrad Halcyon P+ 600 H x 400 W",
    widthMM: 400,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.545,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.4 * 6.6,
  },
  {
    rangeType: "Halcyon P+",
    model: "Stelrad Halcyon P+ 600 H x 500 W",
    widthMM: 500,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.681,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.5 * 6.6,
  },
  {
    rangeType: "Halcyon P+",
    model: "Stelrad Halcyon P+ 600 H x 600 W",
    widthMM: 600,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.817,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.6 * 6.6,
  },
  {
    rangeType: "Halcyon P+",
    model: "Stelrad Halcyon P+ 600 H x 700 W",
    widthMM: 700,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.953,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.7 * 6.6,
  },
  {
    rangeType: "Halcyon P+",
    model: "Stelrad Halcyon P+ 600 H x 800 W",
    widthMM: 800,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.09,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.8 * 6.6,
  },
  {
    rangeType: "Halcyon P+",
    model: "Stelrad Halcyon P+ 600 H x 900 W",
    widthMM: 900,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.226,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.9 * 6.6,
  },
  {
    rangeType: "Halcyon P+",
    model: "Stelrad Halcyon P+ 600 H x 1000 W",
    widthMM: 1000,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.362,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1 * 6.6,
  },
  {
    rangeType: "Halcyon P+",
    model: "Stelrad Halcyon P+ 600 H x 1100 W",
    widthMM: 1100,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.498,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.1 * 6.6,
  },
  {
    rangeType: "Halcyon P+",
    model: "Stelrad Halcyon P+ 600 H x 1200 W",
    widthMM: 1200,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.634,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.2 * 6.6,
  },
  {
    rangeType: "Halcyon P+",
    model: "Stelrad Halcyon P+ 600 H x 1400 W",
    widthMM: 1400,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.907,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.4 * 6.6,
  },
  {
    rangeType: "Halcyon P+",
    model: "Stelrad Halcyon P+ 600 H x 1600 W",
    widthMM: 1600,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.179,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.6 * 6.6,
  },
  {
    rangeType: "Halcyon P+",
    model: "Stelrad Halcyon P+ 600 H x 1800 W",
    widthMM: 1800,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.452,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.8 * 6.6,
  },
  {
    rangeType: "Halcyon P+",
    model: "Stelrad Halcyon P+ 700 H x 500 W",
    widthMM: 500,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 0.77,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.5 * 7.63,
  },
  {
    rangeType: "Halcyon P+",
    model: "Stelrad Halcyon P+ 700 H x 600 W",
    widthMM: 600,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 0.923,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.6 * 7.63,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 300 H x 500 W",
    widthMM: 500,
    heightMM: 300,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.491,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.3 * 3.7,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 300 H x 1000 W",
    widthMM: 1000,
    heightMM: 300,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.983,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1 * 3.7,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 300 H x 1500 W",
    widthMM: 1500,
    heightMM: 300,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.259,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.5 * 3.7,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 300 H x 2000 W",
    widthMM: 2000,
    heightMM: 300,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.964,
    depthMM: 140,
    kvValue: 20,
    volumeL: 2 * 3.7,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 400 H x 400 W",
    widthMM: 400,
    heightMM: 400,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.693,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.4 * 4.7,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 450 H x 400 W",
    widthMM: 400,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.548,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.4 * 5.15,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 450 H x 500 W",
    widthMM: 500,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.686,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.5 * 5.15,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 450 H x 600 W",
    widthMM: 600,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.823,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.6 * 5.15,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 450 H x 700 W",
    widthMM: 700,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.96,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.7 * 5.15,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 450 H x 800 W",
    widthMM: 800,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.097,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.8 * 5.15,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 450 H x 900 W",
    widthMM: 900,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.234,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.9 * 5.15,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 450 H x 1000 W",
    widthMM: 1000,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.371,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1 * 5.15,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 450 H x 1100 W",
    widthMM: 1100,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.508,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.1 * 5.15,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 450 H x 1200 W",
    widthMM: 1200,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.645,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.2 * 5.15,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 450 H x 1400 W",
    widthMM: 1400,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.919,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.4 * 5.15,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 450 H x 1600 W",
    widthMM: 1600,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.194,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.6 * 5.15,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 450 H x 1800 W",
    widthMM: 1800,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.468,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.8 * 5.15,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 450 H x 2000 W",
    widthMM: 2000,
    heightMM: 450,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.742,
    depthMM: 140,
    kvValue: 20,
    volumeL: 2 * 5.15,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 600 H x 400 W",
    widthMM: 400,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.693,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.4 * 6.6,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 600 H x 500 W",
    widthMM: 500,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.874,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.5 * 6.6,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 600 H x 600 W",
    widthMM: 600,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.048,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.6 * 6.6,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 600 H x 700 W",
    widthMM: 700,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.223,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.7 * 6.6,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 600 H x 800 W",
    widthMM: 800,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.398,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.8 * 6.6,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 600 H x 900 W",
    widthMM: 900,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.572,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.9 * 6.6,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 600 H x 1000 W",
    widthMM: 1000,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.747,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1 * 6.6,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 600 H x 1100 W",
    widthMM: 1100,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.922,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.1 * 6.6,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 600 H x 1200 W",
    widthMM: 1200,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.096,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.2 * 6.6,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 600 H x 1400 W",
    widthMM: 1400,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.446,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.4 * 6.6,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 600 H x 1600 W",
    widthMM: 1600,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 2.795,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.6 * 6.6,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 600 H x 1800 W",
    widthMM: 1800,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 3.145,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.8 * 6.6,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 600 H x 2000 W",
    widthMM: 2000,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 3.494,
    depthMM: 140,
    kvValue: 20,
    volumeL: 2 * 6.6,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 700 H x 400 W",
    widthMM: 400,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 0.785,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.4 * 7.63,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 700 H x 500 W",
    widthMM: 500,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 0.982,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.5 * 7.63,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 700 H x 600 W",
    widthMM: 600,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 1.178,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.6 * 7.63,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 700 H x 700 W",
    widthMM: 700,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 1.374,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.7 * 7.63,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 700 H x 800 W",
    widthMM: 800,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 1.57,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.8 * 7.63,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 700 H x 900 W",
    widthMM: 900,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 1.767,
    depthMM: 140,
    kvValue: 20,
    volumeL: 0.9 * 7.63,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 700 H x 1000 W",
    widthMM: 1000,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 1.963,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1 * 7.63,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 700 H x 1100 W",
    widthMM: 1100,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 2.159,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.1 * 7.63,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 700 H x 1200 W",
    widthMM: 1200,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 2.356,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.2 * 7.63,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 700 H x 1400 W",
    widthMM: 1400,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 2.748,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.4 * 7.63,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 700 H x 1600 W",
    widthMM: 1600,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 3.138,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.6 * 7.63,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 700 H x 1800 W",
    widthMM: 1800,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 3.53,
    depthMM: 140,
    kvValue: 20,
    volumeL: 1.8 * 7.63,
  },
  {
    rangeType: "Softline K2",
    model: "Stelrad Softline K2 700 H x 2000 W",
    widthMM: 2000,
    heightMM: 700,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.34,
    nominalDeltaT50KW: 3.922,
    depthMM: 140,
    kvValue: 20,
    volumeL: 2 * 7.63,
  },
  {
    rangeType: "Softline K3",
    model: "Stelrad Softline K3 600 H x 1200 W",
    widthMM: 1200,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 2.867,
    depthMM: 150,
    kvValue: 20,
    volumeL: 1.2 * 9.8,
  },
  {
    rangeType: "Softline K3",
    model: "Stelrad Softline K3 600 H x 1400 W",
    widthMM: 1400,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.32,
    nominalDeltaT50KW: 3.345,
    depthMM: 150,
    kvValue: 20,
    volumeL: 1.4 * 9.8,
  },
  {
    rangeType: "Softline P+",
    model: "Stelrad Softline P+ 600 H x 500 W",
    widthMM: 500,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 0.681,
    depthMM: 130,
    kvValue: 20,
    volumeL: 0.5 * 6.6,
  },
  {
    rangeType: "Softline P+",
    model: "Stelrad Softline P+ 600 H x 1200 W",
    widthMM: 1200,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.172,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.2 * 6.6,
  },
  {
    rangeType: "Softline P+",
    model: "Stelrad Softline P+ 600 H x 1400 W",
    widthMM: 1400,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.907,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.4 * 6.6,
  },
  {
    rangeType: "Softline P+",
    model: "Stelrad Softline P+ 600 H x 1600 W",
    widthMM: 1600,
    heightMM: 600,
    ratingDataType: "n-coefficient",
    nCoefficient: 1.33,
    nominalDeltaT50KW: 1.88,
    depthMM: 130,
    kvValue: 20,
    volumeL: 1.6 * 6.6,
  },
];
