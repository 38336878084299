import Flatten from "@flatten-js/core";
import * as TM from "transformation-matrix";
import { Coord, Coord3D } from "../../../lib/coord";
import {
  EPS,
  assertType,
  assertUnreachable,
  cloneSimple,
} from "../../../lib/utils";
import { GetPressureLossOptions } from "../../calculations/entity-pressure-drops";
import { getFluidDensityOfSystem } from "../../calculations/pressure-drops";
import { CoreContext, PressureLossResult } from "../../calculations/types";
import {
  ComponentPressureLossMethod,
  StandardFlowSystemUids,
  isSewer as isSewage,
} from "../../config";
import {
  ConnectableEntityConcrete,
  EdgeLikeEntity,
} from "../../document/entities/concrete-entity";
import ConduitEntity, {
  DuctConduitEntity,
  PipeConduitEntity,
  isDuctEntity,
  isPipeEntity,
} from "../../document/entities/conduit-entity";
import {
  FittingEntity,
  makeFittingEntity,
} from "../../document/entities/fitting-entity";
import { EntityType } from "../../document/entities/types";
import {
  FLOW_SYSTEM_TO_CONDUIT_TYPE,
  getSimilarNetwork,
} from "../../document/flow-systems";
import CoreConduit from "../coreConduit";
import {
  CoreEdgeObjectConcrete,
  CoreObjectConcrete,
  isCoreEdgeObject,
} from "../index";
import Cached from "../lib/cached";
import CoreBaseBackedObject from "../lib/coreBaseBackedObject";
import { getVelocityPressureKPA } from "../lib/ductFittingPressureDrop";
import { GuessEntity } from "../lib/types";
import {
  determineConnectableDiameterMM,
  determineConnectableHeightAboveFloorM,
  determineConnectableModelConduit,
  determineConnectableNetwork,
  determineConnectablePressureDropRateKPAM,
  determineConnectableSystemUid,
  determineConnectableVelocityMS,
  getEdgeLikeHeightAboveGroundM,
} from "../utils";

const MAX_PIPE_GROUP_SEPARATION_M = 0.05;

export interface ICoreConnectable {
  getRadials(exclude?: string | null): Array<[Coord3D, CoreObjectConcrete]>;
  getAngleDiffs(): number[];
  getSortedAnglesRAD(ignoreUids?: string[]): {
    angles: number[];
    ret: { uid: string; angle: number }[];
  };
  getAngleOfRad(connection: string): number;
  get3DOffset(connection: string): Coord3D;
  getFrictionPressureLossKPA(
    options: GetPressureLossOptions,
  ): PressureLossResult;
  onConnect(uid: string): void;
  onDisconnect(uid: string): void;
  getImplicitCalculationConnections(): string[];
  getCalculationConnectionGroups(context: CoreContext): EdgeLikeEntity[][];
  getCalculationTower(
    context: CoreContext,
    forRiser?: boolean,
  ): Array<[FittingEntity, ConduitEntity] | [FittingEntity]>;
  largestPipeSizeInternal(context: CoreContext): number | null;
  largestPipeSizeNominalMM(context: CoreContext): number | null;
  getCalculationNode(
    context: CoreContext,
    connectionUid: string,
  ): ConnectableEntityConcrete;
  getConnectionCoord(connectionUid: string): Coord3D;
}

export function CoreConnectable<
  T extends abstract new (...args: any[]) => CoreBaseBackedObject<I>,
  I extends ConnectableEntityConcrete = GuessEntity<T>,
>(Base: T) {
  abstract class Generated extends Base implements ICoreConnectable {
    radius = 30;
    abstract getComponentPressureLossKPA(
      options: GetPressureLossOptions,
    ): PressureLossResult;

    get position(): TM.Matrix {
      return this.getPositionWithCenter(this.effectiveCenter);
    }

    getPositionWithCenter(center: Coord): TM.Matrix {
      const scale = 1 / this.fromParentToWorldLength(1);
      return TM.transform(
        TM.translate(center.x, center.y),
        TM.scale(scale, scale),
      );
    }

    get effectiveCenter() {
      const goal = this.parent?.getCorrectPositionOfChild(this.uid);
      if (goal) {
        return goal;
      }
      return this.entity.center;
    }

    @Cached(
      (kek) => {
        const temp = [kek]
          .map((n) => [n, n.getParentChain(), n.getCoreNeighbours()])
          .flat(2)
          .map((n) => [n, n.getParentChain(), n.getCoreNeighbours()])
          .flat(2);
        if (temp.some((n) => n === undefined)) {
          console.error(temp, kek);
        }
        return new Set(
          temp
            .map((n) => n.getParentChain())
            .flat()
            .map((o) => o.uid),
        );
      },
      (exclude) => exclude,
    )

    // Get all the neighbours of this connectable,
    // Coord3D represent neighbour entity's position
    getRadials(
      exclude: string | null = null,
    ): Array<[Coord3D, CoreEdgeObjectConcrete]> {
      const result: Array<[Coord3D, CoreEdgeObjectConcrete]> = [];
      this.globalStore.getConnections(this.entity.uid).forEach((uid) => {
        if (uid === exclude) {
          return;
        }

        const connected = this.globalStore.get(uid);

        if (connected === undefined) {
          throw new Error(
            "connectable not found: " +
              uid +
              " of " +
              JSON.stringify(this.entity),
          );
        }

        if (isCoreEdgeObject(connected)) {
          const [other] = connected.worldEndpoints(this.uid);
          if (connected.worldEndpoints(this.uid).length > 1) {
            throw new Error(
              "pipe object we are connected to doesn't connect to us: \n" +
                JSON.stringify(connected.entity.endpointUid) +
                " " +
                connected.uid +
                "\n" +
                JSON.stringify(
                  this.globalStore.getConnections(this.entity.uid),
                ) +
                " \n" +
                JSON.stringify(this.globalStore.get(this.uid)!.entity),
            );
          }

          if (other) {
            result.push([other, connected]);
          }
        }
      });
      return result;
    }

    getSortedRadials() {
      const wc = this.toWorldCoord();
      const radials = this.getRadials();
      radials.sort((a, b) => {
        const aCoord = a[0];
        const bCoord = b[0];
        const aAngle = Math.atan2(aCoord.y - wc.y, aCoord.x - wc.x);
        const bAngle = Math.atan2(bCoord.y - wc.y, bCoord.x - wc.x);
        return aAngle - bAngle;
      });
      return radials;
    }

    getAngleDiffs(): number[] {
      const ret = [];
      const radials = this.getRadials();
      const angles = radials
        .map((r) => this.toObjectCoord(r[0]))
        .map((r) =>
          r.x === r.y && r.y === 0
            ? NaN
            : (Math.atan2(r.y, r.x) + 2 * Math.PI) % (2 * Math.PI),
        );
      angles.sort((a, b) => a - b);
      for (let i = 0; i < angles.length; i++) {
        const diff = angles[(i + 1) % angles.length] - angles[i];
        ret.push(((diff * 180) / Math.PI + 360) % 360);
      }
      let sum = 0;
      ret.forEach((n) => (sum += n));
      // assert(Math.abs(sum - 360) <= EPS || Math.abs(sum) <= EPS);
      return ret;
    }

    // Only valid in 3D
    hasElevationChangeConnection(): boolean {
      if (this.entity.calculationHeightM == undefined) {
        return false;
      }
      const radials = this.getRadials();
      for (const radial of radials) {
        if (Math.abs(radial[0].z - this.entity.calculationHeightM) > EPS) {
          return true;
        }
      }
      return false;
    }

    hasVerticalConnection(): boolean {
      if (this.entity.calculationHeightM == undefined) {
        return false;
      }
      const radials = this.getRadials();
      const myCoord = this.toWorldCoord();
      for (const radial of radials) {
        if (Math.abs(radial[0].z - this.entity.calculationHeightM) > EPS) {
          const otherCoord = radial[0];
          if (
            Math.abs(myCoord.x - otherCoord.x) < EPS &&
            Math.abs(myCoord.y - otherCoord.y) < EPS
          ) {
            return true;
          }
        }
      }
      return false;
    }

    // Angles are 0 when the pipe is pointing to the right
    // angles are in radians
    getSortedAnglesRAD(ignoreUids?: string[]): {
      angles: number[];
      ret: { uid: string; edgeUid: string; angle: number; index: number }[];
    } {
      let radials = this.getRadials();
      if (ignoreUids) {
        radials = radials.filter(
          (r) =>
            !ignoreUids.includes(r[1].uid) &&
            !ignoreUids.some((u) => r[1].entity.endpointUid.includes(u)),
        );
      }
      const angles: number[] = [];
      const ret: {
        uid: string;
        edgeUid: string;
        angle: number;
        index: number;
      }[] = [];
      radials.forEach((radial, index) => {
        const r = this.toObjectCoord(radial[0]);
        const angle = (Math.atan2(r.y, r.x) + 2 * Math.PI) % (2 * Math.PI);
        angles.push(angle);
        const fittingUids = (radial[1] as CoreConduit).entity.endpointUid;
        ret.push({
          uid: fittingUids[0] === this.uid ? fittingUids[1] : fittingUids[0],
          index,
          edgeUid: radial[1].uid,
          angle,
        });
      });
      angles.sort((a, b) => a - b);
      return { angles, ret };
    }

    /*
            @Cached(
                (kek) => new Set(
                    [kek]
                        .map((n) => [n, n.getParentChain(), n.getNeighbours()])
                        .flat(2)
                        .map((n) => [n, n.getParentChain(), n.getNeighbours()])
                        .flat(2)
                        .map((n) => n.getParentChain())
                        .flat()
                        .map((o) => o.uid),
                ),
                (exclude) => exclude,
            )*/
    getAngleOfRad(connection: string): number {
      if (!this.getRadials().find((a) => a[1].uid === connection)) {
        throw new Error(
          new Error(
            "connection not in radials " +
              connection +
              " \n" +
              "" +
              JSON.stringify(this.getRadials()),
          ) +
            "\n" +
            "" +
            JSON.stringify(this.entity),
        );
      }
      const c = this.toObjectCoord(
        this.getRadials().find((a) => a[1].uid === connection)![0],
      );
      return Math.atan2(c.y, c.x);
    }

    getHash(): string {
      const key = this.entity.center.x + " " + this.entity.center.y;
      // const hash = createHash("sha256");
      // hash.update(key.toString());
      // return hash.digest("hex");
      return key;
    }

    get3DOffset(connection: string): Coord3D {
      if (!this.getRadials().find((a) => a[1].uid === connection)) {
        throw new Error(
          new Error(
            "connection not in radials " +
              connection +
              " \n" +
              "" +
              JSON.stringify(this.getRadials()),
          ) +
            "\n" +
            "" +
            JSON.stringify(this.entity),
        );
      }
      const c = this.getRadials().find((a) => a[1].uid === connection)!;
      if (this.entity.calculationHeightM === null) {
        return { ...this.toObjectCoord(c[0]), z: 0 };
      } else {
        return {
          ...this.toObjectCoord(c[0]),
          z: this.entity.calculationHeightM * 1000 - c[0].z,
        };
      }
    }

    // @Cached(
    //     (kek) =>
    //         new Set(
    //             [kek]
    //                 .map((n) => [n, n.getParentChain(), n.getNeighbours()])
    //                 .flat(2)
    //                 .map((n) => [n, n.getParentChain(), n.getNeighbours()])
    //                 .flat(2)
    //                 .map((n) => n.getParentChain())
    //                 .flat()
    //                 .map((o) => o.uid)
    //         ),
    //     (context, flowLS, from, to, signed, mode, pipeSizes) =>
    //         flowLS + from.connectable + to.connectable + signed + mode + stringify(pipeSizes)
    // )
    getFrictionPressureLossKPA(
      options: GetPressureLossOptions,
    ): PressureLossResult {
      let { context, flowLS, from, to, signed } = options;

      // We going to do pipe size changes here for any connectable.
      const ga =
        context.drawing.metadata.calculationParams.gravitationalAcceleration;

      const oFrom = from;
      const oTo = to;
      const oFlowLS = flowLS;

      // automatically figure out pipe size change cost and add it to any other custom
      // object specific friction loss calculations.
      let sign = 1;
      if (flowLS < 0) {
        const oldFrom = from;
        from = to;
        to = oldFrom;
        flowLS = -flowLS;
        if (signed) {
          sign = -1;
        }
      }

      let componentHL = this.getComponentPressureLossKPA({
        ...options,
        flowLS: oFlowLS,
        from: oFrom,
        to: oTo,
      });

      if (this.entity.type === EntityType.SYSTEM_NODE) {
        // @ts-ignore
        return componentHL;
      }

      switch (
        context.drawing.metadata.calculationParams.componentPressureLossMethod
      ) {
        case ComponentPressureLossMethod.INDIVIDUALLY:
          // Find pressure loss from pipe size changes
          break;
        case ComponentPressureLossMethod.PERCENT_ON_TOP_OF_PIPE:
          return componentHL;
        default:
          assertUnreachable(
            context.drawing.metadata.calculationParams
              .componentPressureLossMethod,
          );
      }

      const fromo = this.globalStore.get(from.connection);
      const too = this.globalStore.get(to.connection);
      if (
        !fromo ||
        fromo.type !== EntityType.CONDUIT ||
        !too ||
        too.type !== EntityType.CONDUIT
      ) {
        return { pressureLossKPA: 0 };
      }

      return componentHL;
    }

    onConnect(uid: string) {
      // default no-op
    }

    onDisconnect(uid: string) {
      // default no-op
    }

    getImplicitCalculationConnections(): string[] {
      return [];
    }

    getCalculationConnections(): string[] {
      return [
        ...this.globalStore.getConnections(this.uid),
        ...this.getImplicitCalculationConnections(),
      ];
    }

    getCalculationConnectionGroups(context: CoreContext): EdgeLikeEntity[][] {
      const edgeLikes = this.getCalculationConnections()
        .map((puid) => this.globalStore.get(puid)!.entity as EdgeLikeEntity)
        .sort(
          (a, b) =>
            getEdgeLikeHeightAboveGroundM(a, this.entity, context) -
            getEdgeLikeHeightAboveGroundM(b, this.entity, context),
        );

      const res: EdgeLikeEntity[][] = [];
      let group: EdgeLikeEntity[] = [];
      edgeLikes.forEach((entity) => {
        if (
          group.length === 0 ||
          getEdgeLikeHeightAboveGroundM(entity, this.entity, context) <=
            getEdgeLikeHeightAboveGroundM(group[0], this.entity, context) +
              MAX_PIPE_GROUP_SEPARATION_M
        ) {
          group.push(entity);
        } else {
          res.push(group);
          group = [entity];
        }
      });

      if (group.length) {
        res.push(group);
      }

      return res;
    }

    /**
     *         etc...
     *           |
     * ---------uid.2-------puid-uid-- (btw pipes are done in pipe entities, not here)
     *           |
     *         uid.1.p
     *           |
     * ---------uid.1-------puid-uid--
     *           |
     *         uid.0.p
     *           |
     *         uid.0--------puid-uid--
     * This function returns the un-visible pipe together represent riser
     * Currently the function only works for two groups, assumably pressure pipe and drainage pipe
     * Prioritize the system uid of expanded pipes to be the same as drainage pipe
     */
    getCalculationTower(
      context: CoreContext,
      forRiser = false,
    ): Array<[FittingEntity, ConduitEntity] | [FittingEntity]> {
      const groups = this.getCalculationConnectionGroups(context);

      if (groups.length === 0) {
        return [];
      }

      const result: Array<[FittingEntity, ConduitEntity] | [FittingEntity]> =
        [];
      const mySystemUid = determineConnectableSystemUid(
        this.globalStore,
        this.entity,
      );

      if (!mySystemUid) {
        return result;
      }

      const mySystem = context.drawing.metadata.flowSystems[mySystemUid];

      let previousGroup: EdgeLikeEntity[] | undefined;

      const spawnedPipeNetwork = determineConnectableNetwork(
        context.globalStore,
        this.entity,
        context.drawing.metadata.flowSystems[mySystemUid],
      );

      const modelPipe = determineConnectableModelConduit(
        context.globalStore,
        this.entity,
      );

      const velocityMS = determineConnectableVelocityMS(
        context.globalStore,
        this.entity,
      );

      const pressureDropKPAM = determineConnectablePressureDropRateKPAM(
        context.globalStore,
        this.entity,
      );

      const heightAboveFloorM = determineConnectableHeightAboveFloorM(
        context.globalStore,
        this.entity,
      );

      const diameterMM = determineConnectableDiameterMM(
        context.globalStore,
        this.entity,
      );

      let i = 0;

      groups.forEach((g) => {
        const minHeight = getEdgeLikeHeightAboveGroundM(
          g[0],
          this.entity,
          context,
          forRiser,
        );
        const maxHeight = getEdgeLikeHeightAboveGroundM(
          g[g.length - 1],
          this.entity,
          context,
          forRiser,
        );

        const ce: FittingEntity = makeFittingEntity(context, {
          center: this.toWorldCoord(),
          parentUid: null,
          calculationHeightM: (minHeight + maxHeight) / 2,
          systemUid:
            g[0].type === EntityType.CONDUIT
              ? (g[0].systemUid as StandardFlowSystemUids)
              : mySystemUid,
          uid: this.uid + "." + i,
          fittingType: FLOW_SYSTEM_TO_CONDUIT_TYPE[mySystem.type],
        });

        // TODO: Not sure how to best abstract this. I think with intentions we need to think this out better.
        // Perhaps each network entitity has a reference to its parent entity after all.
        if (
          ce.fittingType === "duct" &&
          this.entity.type === EntityType.FITTING &&
          this.entity.fittingType === "duct"
        ) {
          ce.fitting = cloneSimple(this.entity.fitting);
        }

        if (previousGroup) {
          // Check if there is drainage pipe in the neighbour, if there is, set the expanded pipe's system Uid as it
          const neighbourDrainagePipe = [g[0], previousGroup[0]].find(
            (pipe) =>
              pipe.type === EntityType.CONDUIT &&
              isSewage(context.drawing.metadata.flowSystems[pipe.systemUid]),
          ) as ConduitEntity;

          const drainageSystemUid = neighbourDrainagePipe
            ? neighbourDrainagePipe.systemUid
            : null;

          const conduitType = FLOW_SYSTEM_TO_CONDUIT_TYPE[mySystem.type];
          if (
            g.some(
              (e) =>
                e.type === EntityType.CONDUIT && e.conduitType !== conduitType,
            )
          ) {
            // TODO: risers of non-pipe conduits
            throw new Error(
              "Non-pipe conduits not implemented yet for calculation entities of coreConnectable",
            );
          }

          const groupSystemUid = drainageSystemUid
            ? drainageSystemUid
            : g[0].type === EntityType.CONDUIT
              ? (g[0].systemUid as StandardFlowSystemUids)
              : mySystemUid;
          const groupSystem =
            context.drawing.metadata.flowSystems[groupSystemUid];
          const groupNetwork = getSimilarNetwork(
            spawnedPipeNetwork!,
            groupSystem.type,
          );

          switch (conduitType) {
            case "pipe": {
              assertType<PipeConduitEntity | null>(modelPipe);
              const pe: ConduitEntity = {
                color: null, // TODO: these values should be copied from neighbouring pipe(s).
                conduitType,
                lengthM: null,
                conduit: {
                  diameterMM: diameterMM,
                  material: modelPipe?.conduit.material || null,
                  maximumVelocityMS: velocityMS,
                  maximumPressureDropRateKPAM: pressureDropKPAM,
                  network: groupNetwork,
                  configurationCosmetic: null,
                  gradePCT: null,
                },
                endpointUid: [this.uid + "." + i, this.uid + "." + (i - 1)],
                heightAboveFloorM: heightAboveFloorM || 0,

                parentUid: null,
                systemUid: drainageSystemUid
                  ? drainageSystemUid
                  : g[0].type === EntityType.CONDUIT
                    ? (g[0].systemUid as StandardFlowSystemUids)
                    : mySystemUid,

                type: EntityType.CONDUIT,
                uid: this.uid + "." + i + ".p",
                entityName: null,
              };
              result.push([ce, pe]);
              break;
            }
            case "duct": {
              assertType<DuctConduitEntity>(modelPipe);
              const pe: ConduitEntity = {
                color: null, // TODO: these values should be copied from neighbouring pipe(s).
                conduitType,
                lengthM: null,
                conduit: {
                  diameterMM: diameterMM,
                  material: modelPipe?.conduit.material || null,
                  maximumVelocityMS: velocityMS,
                  maximumPressureDropRateKPAM: pressureDropKPAM,
                  network: groupNetwork,
                  angleDEG: modelPipe?.conduit.angleDEG ?? null,
                  heightMM: modelPipe?.conduit.heightMM ?? null,
                  widthMM: modelPipe?.conduit.widthMM ?? null,
                  maxHeightMM: modelPipe?.conduit.maxHeightMM ?? null,
                  maxWidthMM: modelPipe?.conduit.maxWidthMM ?? null,
                  rectSizingMethod: modelPipe?.conduit.rectSizingMethod ?? null,
                  shape: modelPipe?.conduit.shape ?? null,
                  targetWHRatio: modelPipe?.conduit.targetWHRatio ?? null,
                  sizingIncrementMM:
                    modelPipe?.conduit.sizingIncrementMM ?? null,
                  sizingMode: modelPipe?.conduit.sizingMode ?? null,
                },
                endpointUid: [this.uid + "." + i, this.uid + "." + (i - 1)],
                heightAboveFloorM: heightAboveFloorM || 0,

                parentUid: null,
                systemUid: drainageSystemUid
                  ? drainageSystemUid
                  : g[0].type === EntityType.CONDUIT
                    ? (g[0].systemUid as StandardFlowSystemUids)
                    : mySystemUid,

                type: EntityType.CONDUIT,
                uid: this.uid + "." + i + ".p",
                entityName: null,
              };
              result.push([ce, pe]);
              break;
            }
          }
        } else {
          result.push([ce]);
        }
        previousGroup = g;

        i++;
      });

      return result;
    }

    largestPipeSizeInternal(context: CoreContext): number | null {
      const sizes = this.globalStore
        .getConnections(this.entity.uid)
        .map((uid) => {
          const p = this.globalStore.get(uid) as CoreConduit;
          if (!p || !isPipeEntity(p.entity)) {
            throw new Error(
              "A non pipe object is connected to a valve. non-pipe conduits are not implemented yet",
            );
          }

          const calculation = context.globalStore.getCalculation(p.entity);
          if (!calculation || calculation.realInternalDiameterMM === null) {
            return null;
          } else {
            return calculation.realInternalDiameterMM;
          }
        });

      const valids = sizes.filter((u) => u !== null) as number[];
      if (valids.length === 0) {
        return null;
      }
      return Math.max(...valids);
    }

    largestPipeSizeNominalMM(context: CoreContext): number | null {
      const sizes = this.globalStore
        .getConnections(this.entity.uid)
        .map((uid) => {
          const p = this.globalStore.get(uid) as CoreConduit;
          if (!p || !isPipeEntity(p.entity)) {
            throw new Error("A non pipe object is connected to a valve");
          }

          const calculation = context.globalStore.getCalculation(p.entity);
          if (!calculation || calculation.realNominalPipeDiameterMM === null) {
            return null;
          } else {
            return calculation.realNominalPipeDiameterMM;
          }
        });

      const valids = sizes.filter((u) => u !== null) as number[];
      if (valids.length === 0) {
        return null;
      }
      return Math.max(...valids);
    }

    getCalculationNode(
      context: CoreContext,
      connectionUid: string,
    ): ConnectableEntityConcrete {
      const groups = this.getCalculationConnectionGroups(context);
      const index = groups.findIndex((l) =>
        l.find((pe) => pe.uid === connectionUid),
      );
      if (index === -1) {
        throw new Error(
          "Requesting calculation node from a non neighbour. I am " +
            JSON.stringify(this.entity),
        );
      }

      return this.getCalculationTower(context)[index][0];
    }

    getConnectedSidePipe(pipeUid: string): CoreConduit[] {
      const connectionUids = this.globalStore.getConnections(this.uid)!;
      const sidePipeUids = connectionUids.filter((uid) => uid !== pipeUid);
      return sidePipeUids.map(
        (uid) => this.globalStore.get(uid)! as CoreConduit,
      );
    }

    getCoreNeighbours(): CoreObjectConcrete[] {
      const conn = this.globalStore.getConnections(this.uid);
      const res = [...conn.map((uid) => this.globalStore.get(uid)!)];
      return res;
    }

    get shape() {
      const point = this.toWorldCoord({ x: 0, y: 0 });
      return Flatten.circle(Flatten.point(point.x, point.y), this.radius);
    }

    getConnectionCoord(connectionUid: string): Coord3D {
      const coord = this.toWorldCoord();
      return {
        x: coord.x,
        y: coord.y,
        z: (this.entity.calculationHeightM || 0) * 1000,
      };
    }

    getVentVelocityPressureKPA(): number | null {
      const conns = this.globalStore.getConnections(this.entity.uid);

      if (conns.length === 0) {
        return null;
      }

      const connectedDuct = this.globalStore.get(conns[0]) as CoreConduit;

      if (!isDuctEntity(connectedDuct.entity)) {
        console.error(
          "non duct entity is connected to connectable",
          this.entity,
          connectedDuct,
        );
        return null;
      }

      const { crossSectionAreaM2, totalPeakFlowRateLS } =
        this.globalStore.getOrCreateCalculation(connectedDuct.entity);

      const flowDensity = getFluidDensityOfSystem(
        this.context,
        determineConnectableSystemUid(this.globalStore, this.entity)!,
      );

      if (totalPeakFlowRateLS && crossSectionAreaM2 && flowDensity) {
        return getVelocityPressureKPA(
          totalPeakFlowRateLS,
          crossSectionAreaM2,
          flowDensity,
        );
      }
      return null;
    }
  }

  return Generated;
}
