import "./lib/prototype-extensions/prototype-extensions";

// organize-imports-ignore
// tslint:disable:ordered-imports
import { isInternetExplorer } from "./browser-check";
if (isInternetExplorer()) {
  alert(
    "Internet Explorer is no longer supported for use with h2x. Please use a more modern browser (such as Google Chrome).",
  );
}

import { activateServiceWorker } from "./server-worker-utils";
if (!(window as any).Cypress) {
  // Cypress doesn't seem to like service workers..
  // https://github.com/mswjs/msw/issues/1560#issuecomment-1566595355
  await activateServiceWorker();
  // console.log("Service worker control restored to main.ts");
}

import Vue from "vue";
import App from "./App.vue";
import "./main.css";
import router from "./router";
import store from "./store/store";
import "./tailwind.css";

import filters from "./views/filters";
Vue.use(filters);
// @ts-ignore
import VueInputAutowidth from "vue-input-autowidth";
import Multiselect from "vue-multiselect";

import {
  BootstrapVue,
  BootstrapVueIcons,
  InputGroupPlugin,
} from "bootstrap-vue";
import VueCookies from "vue-cookies";
import VueDragDrop from "vue-drag-drop";
import "./lib/replays/replays";

Vue.config.productionTip = false;
Vue.use(VueDragDrop);

Vue.use(VueCookies);

Vue.use(VueInputAutowidth);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(InputGroupPlugin);
Vue.component("MultiSelect", Multiselect);

import { reportError } from "./api/error-report";

/* antd start (if borken thing exsits, will be removed / moved to local ref) */
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
Vue.use(Antd);
/* antd end */

/* extend console start */
console.logVue = function (...args: any[]): void {
  console.log(
    ...args.map((arg) => (!arg ? arg : JSON.parse(JSON.stringify(arg)))),
  );
};
const baseConsoleError = console.error;
/** known issue with font awesome and vue, vue reports false errors sometimes */
const isSpuriousFontAwesomeError = (...args: any[]) => {
  if (!args.length) return false;
  return args[0] === "Could not find one or more icon(s)";
};
console.error = (...args: any[]) => {
  if (isSpuriousFontAwesomeError(...args)) return;
  baseConsoleError(...args);
};
/* extend console end */

/* chart start */
import { Amplify } from "aws-amplify";
import axios from "axios";
import { init as initCommandBar } from "commandbar";
import qs from "qs";
import VueApexCharts from "vue-apexcharts";
import { AMPLIFY_CONFIG } from "./auth/amplify.config";
import { ENABLE_COMMAND_AI, ENABLE_MIXPANEL } from "./config";
import { SvgPathLoader } from "./htmlcanvas/lib/svg-path-loader";
import mixpanel from "mixpanel-browser";
import { Sentry } from "./api/integrations/Sentry";

// AWS Amplify configuration (for Amazon Cognito)
Amplify.configure(AMPLIFY_CONFIG);

Vue.use(VueApexCharts);
Vue.component("ApexChart", VueApexCharts);

/* chart end */

Vue.config.errorHandler = async (err, _vm, _msg) => {
  await reportError(err.message, err);
  throw err;
};

window.onerror = async (message, _source, _lineno, _colno, error) => {
  if (error) {
    await reportError(message.toString(), error);
    throw error;
  }
};

axios.defaults.paramsSerializer = function (params) {
  return qs.stringify(params, { indices: false }); // param=value1&param=value2
};

Sentry.init();

export const vue = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

// @ts-ignore
document.vue = vue;
// @ts-ignore
document.store = store;

store.dispatch("featureFlags/initialize");

SvgPathLoader.loadSvgPathData();

if (ENABLE_COMMAND_AI) {
  initCommandBar("d9c69368");
}

if (ENABLE_MIXPANEL) {
  mixpanel.init("5a856ecd091a14fa664d751feed23a47", {
    track_pageview: true,
    ignore_dnt: true,
  });
}
