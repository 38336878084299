import { CoreFeatureFlags } from "../../lib/feature-flags";
import { GlobalStore } from "../../lib/globalstore/global-store";
import { assertUnreachable } from "../../lib/utils";
import { NodeProps } from "../../models/CustomEntity";
import { FeatureAccess } from "../../models/FeatureAccess";
import { PriceTable } from "../catalog/price-table";
import { Catalog, HotWaterPlantName } from "../catalog/types";
import { DuctSize } from "../coreObjects/lib/ductFittingPrimitives";
import { DrawingState } from "../document/drawing";
import { SpecifyRadiatorPlant } from "../document/entities/plants/plant-types";
import { SupportedLocales } from "../locale";

export enum PipeConfiguration {
  NORMAL = "NORMAL",
  RING_MAIN = "RING_MAIN",
  RETURN_OUT = "RETURN_OUT",
  RETURN_IN = "RETURN_IN",
}
export interface CoreContext {
  drawing: DrawingState;
  catalog: Catalog;
  priceTable: PriceTable;
  globalStore: GlobalStore;
  nodes: NodeProps[];
  locale: SupportedLocales;
  featureAccess: FeatureAccess;
  featureFlags: CoreFeatureFlags;
}

export enum PressurePushMode {
  PSD,
  CirculationFlowOnly,
  Static,
}

export type SkippableCalcs = Partial<{
  skipFireCalcs: boolean;
}>;

export interface ResolvePlantEntityProps {
  widthMM: number;
  depthMM: number;
  gasRequirement: number;
  gasPressure: number;
}

export function isFunctionalBreakdown(
  breakdown: CostBreakdownEntry,
): breakdown is FunctionBreakdown<any> {
  return "params" in breakdown;
}

export function isFittingBreakdown(
  breakdown: CostBreakdownEntry,
): breakdown is FittingBreakdown {
  return breakdown.type === "fitting";
}

export function isValveBreakdown(
  breakdown: CostBreakdownEntry,
): breakdown is ValveBreakdown {
  return breakdown.type === "valve";
}

export function isHeightDiffPipeBreakdown(
  breakdown: CostBreakdownEntry,
): breakdown is HeightDiffPipeBreakdown {
  return breakdown.type === "heightDiffPipe";
}

export function isLoadNodeBreakdown(
  breakdown: CostBreakdownEntry,
): breakdown is LoadNodeBreakdown {
  return breakdown.type === "loadNode";
}

export function isRoomUnderFloorHeatingBreakdown(
  breakdown: CostBreakdownEntry,
): breakdown is RoomUnderFloorHeatingBreakdown {
  return breakdown.type === "room-ufh";
}

export function isPlantBreakdown(
  breakdown: CostBreakdownEntry,
): breakdown is PlantBreakdown {
  return (
    breakdown.type === "plant" &&
    (breakdown.path.split(".")[1] === HotWaterPlantName.HOT_WATER_PLANT ||
      breakdown.path.split(".")[1] === "Filter")
  );
}

export function isRadiatorBreakdown(
  breakdown: CostBreakdownEntry,
): breakdown is RadiatorBreakdown {
  return breakdown.type === "radiator";
}

export function isAirHandlingUnitBreakdown(
  breakdown: CostBreakdownEntry,
): breakdown is AirHandlingUnitBreakdown {
  return breakdown.type === "ahu";
}

export function isFanCoilUnitBreakdown(
  breakdown: CostBreakdownEntry,
): breakdown is FanCoilUnitBreakdown {
  return breakdown.type === "fcu";
}

export function isPumpPackBreakdown(
  breakdown: CostBreakdownEntry,
): breakdown is PumpPackBreakdown {
  return breakdown.type === "pump-pack";
}

export function isManifoldBreakdown(
  breakdown: CostBreakdownEntry,
): breakdown is ManifoldBreakdown {
  return breakdown.type === "manifold";
}

export function isBallValveBreakdown(
  breakdown: CostBreakdownEntry,
): breakdown is BallValveBreakdown {
  return breakdown.type === "ball-valve";
}

export function isActuatorBreakdown(
  breakdown: CostBreakdownEntry,
): breakdown is UnderfloorHeatingActuatorBreakdown {
  return breakdown.type === "ufh-actuator";
}

export function isUfhCoilBreakdown(
  breakdown: CostBreakdownEntry,
): breakdown is UnderFloorHeatingCoilBreakdown {
  return breakdown.type === "ufh-coil";
}

export function isEdgeFoamBreakdown(
  breakdown: CostBreakdownEntry,
): breakdown is UnderfloorHeatingEdgeExpansionFoamBreakdown {
  return breakdown.type === "ufh-edge-expansion-foam";
}

export function isUnderFloorHeatingBreakdown(
  breakdown: CostBreakdownEntry,
): breakdown is UnderFloorHeatingBreakdown {
  return breakdown.type === "ufh";
}

export interface BasicBreakdown {
  type?: string;
  qty: number;
  path: string;
  manufacturer?: string;

  // UIDs are for nesting.
  uid?: string;
  parentUid?: string;

  // only used for marketplace items (radiator for now) when the price is defined by the manufacturer
  costOverride?: number;

  // Allows customizing product descriptions to show on the price table
  description?: string;
}

export interface FunctionBreakdown<T> extends BasicBreakdown {
  params: T[];
}

export interface FittingBreakdown extends BasicBreakdown {
  pipeSizes: number[];
}

export interface DuctFittingBreakdown extends FunctionBreakdown<number> {
  size: DuctSize;
}

export interface ValveBreakdown extends BasicBreakdown {
  pipeSize: number;
}

export interface HeightDiffPipeBreakdown extends BasicBreakdown {
  systemUid: string;
}

export interface LoadNodeBreakdown extends BasicBreakdown {
  customNodeId: string;
}

export interface PlantBreakdown extends BasicBreakdown {
  name: string;
}

export interface RadiatorBreakdown extends BasicBreakdown {
  type: "radiator";
  widthMM: number;
  heightMM: number;
  manufacturer: string;
  rangeType: SpecifyRadiatorPlant["rangeType"] | null;
  ratingKW: number;
  model?: string;
  productKey?: string;
}

export interface AirHandlingUnitBreakdown extends BasicBreakdown {
  type: "ahu";
  widthMM: number;
  heightMM: number;
  chilledRatingKW: number;
  heatingRatingKW: number;
}

export interface FanCoilUnitBreakdown extends BasicBreakdown {
  type: "fcu";
  widthMM: number;
  heightMM: number;
  chilledRatingKW: number;
  heatingRatingKW: number;
}

export interface ManifoldBreakdown extends BasicBreakdown {
  type: "manifold";
  ports?: number;
  widthMM: number;
  heightMM: number;
  ratingKW: number;
  manufacturer?: string;
  range?: string;
  model?: string;
  productKey?: string;
}

// TODO: we eventually want to call the manifold one something different
// entirely when we move the ball valves to under the manifold for organization.
export interface BallValveBreakdown extends BasicBreakdown {
  type: "ball-valve";
  sizeMM?: number;
  productKey?: string;
  manufacturer?: string;
  model?: string;
}

export interface PumpPackBreakdown extends BasicBreakdown {
  type: "pump-pack";
  manufacturer?: string;
  model?: string;
  productKey?: string;
  pumpDescription?: string;
  blendingValveDescription?: string;
}

export interface UfhPumpBreakdown extends BasicBreakdown {
  type: "ufh-pump";
  manufacturer?: string;
  model?: string;
  productKey?: string;
}

export interface BlendingValveBreakdown extends BasicBreakdown {
  type: "blending-valve";
  manufacturer?: string;
  model?: string;
  productKey?: string;
}

export interface UnderFloorHeatingBreakdown extends BasicBreakdown {
  type: "ufh";
  widthMM: number;
  heightMM: number;
  ratingKW: number;
}

export interface UnderFloorHeatingCoilBreakdown extends BasicBreakdown {
  type: "ufh-coil";
  lengthM: number;
  pipeSizeMM: number;
  pipeMaterial: string;
  manufacturer?: string;
}

export interface RoomUnderFloorHeatingBreakdown extends BasicBreakdown {
  type: "room-ufh";
  areaM2: number;
  roomName: string;
}

export interface UnderfloorHeatingActuatorBreakdown extends BasicBreakdown {
  type: "ufh-actuator";
  manufacturer?: string;
  model?: string;
  productKey?: string;
}

export interface UnderfloorHeatingEdgeExpansionFoamBreakdown
  extends BasicBreakdown {
  type: "ufh-edge-expansion-foam";
  perimeterLengthM: number;
  rollLengthM: number | null;
  manufacturer?: string;
  model?: string;
  productKey?: string;
}

export type CostBreakdownEntry =
  | BasicBreakdown
  | FittingBreakdown
  | DuctFittingBreakdown
  | ValveBreakdown
  | HeightDiffPipeBreakdown
  | LoadNodeBreakdown
  | PlantBreakdown
  | RadiatorBreakdown
  | AirHandlingUnitBreakdown
  | FanCoilUnitBreakdown
  | ManifoldBreakdown
  | UnderfloorHeatingActuatorBreakdown
  | UnderFloorHeatingBreakdown
  | UnderFloorHeatingCoilBreakdown
  | UnderfloorHeatingEdgeExpansionFoamBreakdown
  | RoomUnderFloorHeatingBreakdown
  | BallValveBreakdown
  | PumpPackBreakdown
  | UfhPumpBreakdown
  | BlendingValveBreakdown;

export interface CostBreakdown {
  cost: number;
  breakdown: CostBreakdownEntry[];
}

export type PressureLossResult = PressureLossSuccess | PressureLossFailure;

export interface PressureLossSuccess {
  pressureLossKPA: number;

  // pressure loss of all termini attached to the conduit termini (e.g. balancing dampers)
  terminiPressureLossKPA?: number;

  // min outlet pressure - implies that the pressure will not drop below this value
  // and overrides all effect on pressureLossKPA. This is useful for example for pumps.
  minPressureKPA?: number;

  // ditto but for max pressure. This is
  maxPressureKPA?: number;
}

// We need to use this because just checking for null doesn't work with non-strict
// compilation.
export function isPressureLossSuccess(
  result: PressureLossResult,
): result is PressureLossSuccess {
  return result.pressureLossKPA !== null;
}

export interface PressureLossFailure {
  pressureLossKPA: null;
  terminiPressureLossKPA?: null;
  error?: PressureLossError;
}

export enum PressureLossError {
  PipeExceedsValveDiameter,
}

export enum EdgeType {
  CONDUIT,
  BIG_VALVE_HOT_HOT,
  BIG_VALVE_HOT_WARM,
  BIG_VALVE_COLD_WARM,
  BIG_VALVE_COLD_COLD,
  FITTING_FLOW,
  FLOW_SOURCE_EDGE,

  // reserve some for check valve, pump and isolation types.
  CHECK_THROUGH,
  ISOLATION_THROUGH,
  BALANCING_THROUGH,

  PLANT_THROUGH,
  PLANT_PREHEAT,
  RETURN_PUMP,
}

export enum EnergyEdgeType {
  DEFAULT,
  PLANT,
}

export function isEdgeBacked(edgeType: EdgeType) {
  switch (edgeType) {
    case EdgeType.FLOW_SOURCE_EDGE:
      return false;

    case EdgeType.CONDUIT:
    case EdgeType.BIG_VALVE_HOT_HOT:
    case EdgeType.BIG_VALVE_HOT_WARM:
    case EdgeType.BIG_VALVE_COLD_WARM:
    case EdgeType.BIG_VALVE_COLD_COLD:
    case EdgeType.FITTING_FLOW:
    case EdgeType.CHECK_THROUGH:
    case EdgeType.ISOLATION_THROUGH:
    case EdgeType.BALANCING_THROUGH:
    case EdgeType.PLANT_PREHEAT:
    case EdgeType.PLANT_THROUGH:
    case EdgeType.RETURN_PUMP:
      return true;
  }
  assertUnreachable(edgeType);
}

export function isEdgeTypeConnectable(edgeType: EdgeType) {
  switch (edgeType) {
    case EdgeType.CONDUIT:
    case EdgeType.FITTING_FLOW:
    case EdgeType.FLOW_SOURCE_EDGE:
    case EdgeType.CHECK_THROUGH:
    case EdgeType.ISOLATION_THROUGH:
    case EdgeType.BALANCING_THROUGH:
      return true;
    case EdgeType.BIG_VALVE_HOT_HOT:
    case EdgeType.BIG_VALVE_HOT_WARM:
    case EdgeType.BIG_VALVE_COLD_WARM:
    case EdgeType.BIG_VALVE_COLD_COLD:
    case EdgeType.PLANT_THROUGH:
    case EdgeType.RETURN_PUMP:
    case EdgeType.PLANT_PREHEAT:
      return false;
  }
  assertUnreachable(edgeType);
}

export interface FlowEdge {
  type: EdgeType;
  uid: string;
}

export interface EnergyEdge {
  type: EnergyEdgeType;
  uid: string;
  energyRatio?: number;
}

export type ConnectableUid = string;
export type ConnectionUid = string;

export interface FlowNode {
  // Connection is the pipe attach to connectable, it's the edge in graph
  // Connectable the attachment able to connect with pipe, it's the node in graph
  connection: ConnectionUid;
  connectable: ConnectableUid;
}

export interface EnergyNode {
  connection: ConnectionUid;
  connectable: ConnectableUid;
}

// A frontend thing, but the calculations are responsible for
// generating them, so it's here.
export type DrawingLayout =
  | "mechanical"
  | "pressure"
  | "drainage"
  | "ventilation";
