import { HeatLoadMaterialRoleType, HeatLoadMaterialSpec } from "./types";

export const HEATLOAD_MATERIAL_DATA: HeatLoadMaterialSpec = {
  "External Wall": {
    type: HeatLoadMaterialRoleType.WALL,
    table: {
      "Building Regs 2022 Limit": {
        name: "Building Regs 2022 Limit",
        uid: "Building Regs 2022 Limit",
        abbreviation: "Building Regs 2022 Limit",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Building Regs 2022 Limit",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.26,
      },
      "Solid Brick": {
        name: "Solid Brick",
        uid: "Solid Brick",
        abbreviation: "Solid Brick",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Solid Brick",
          },
        ],
        thermal_transmittance_W_per_m2K: 2.1,
      },
      "Solid Brick Insulated": {
        name: "Solid Brick Insulated",
        uid: "Solid Brick Insulated",
        abbreviation: "Solid Brick Insulated",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Solid Brick Insulated",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.28,
      },
      "Solid Stone": {
        name: "Solid Stone",
        uid: "Solid Stone",
        abbreviation: "Solid Stone",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Solid Stone",
          },
        ],
        thermal_transmittance_W_per_m2K: 2.25,
      },
      "Solid Stone Insulated": {
        name: "Solid Stone Insulated",
        uid: "Solid Stone Insulated",
        abbreviation: "Solid Stone Insulated",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Solid Stone Insulated",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.32,
      },
      "Solid Concrete": {
        name: "Solid Concrete",
        uid: "Solid Concrete",
        abbreviation: "Solid Concrete",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Solid Concrete",
          },
        ],
        thermal_transmittance_W_per_m2K: 3,
      },
      "Solid Concrete Insulated": {
        name: "Solid Concrete Insulated",
        uid: "Solid Concrete Insulated",
        abbreviation: "Solid Concrete Insulated",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Solid Concrete Insulated",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.31,
      },
      "Cavity Wall Uninsulated": {
        name: "Cavity Wall Uninsulated",
        uid: "Cavity Wall Uninsulated",
        abbreviation: "Cavity Wall Uninsulated",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Cavity Wall Uninsulated",
          },
        ],
        thermal_transmittance_W_per_m2K: 1.3,
      },
      "Cavity Wall Insulated": {
        name: "Cavity Wall Insulated",
        uid: "Cavity Wall Insulated",
        abbreviation: "Cavity Wall Insulated",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Cavity Wall Insulated",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.55,
      },
      Hardwood: {
        name: "Hardwood",
        uid: "Hardwood",
        abbreviation: "Hardwood",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Hardwood",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.18,
      },
      Softwood: {
        name: "Softwood",
        uid: "Softwood",
        abbreviation: "Softwood",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Softwood",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.13,
      },

      "Partition Uninsulated": {
        name: "Partition Uninsulated",
        uid: "Partition Uninsulated",
        abbreviation: "Partition Uninsulated",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Partition Uninsulated",
          },
        ],
        thermal_transmittance_W_per_m2K: 1.75,
      },
      "Partition Insulated": {
        name: "Partition Insulated",
        uid: "Partition Insulated",
        abbreviation: "Partition Insulated",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Partition Insulated",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.75,
      },
    },
  },
  "Internal Wall": {
    type: HeatLoadMaterialRoleType.WALL,
    table: {
      "Building Regs 2022 Limit": {
        name: "Building Regs 2022 Limit",
        uid: "Building Regs 2022 Limit",
        abbreviation: "Building Regs 2022 Limit",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Building Regs 2022 Limit",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.26,
      },
      "Solid Brick": {
        name: "Solid Brick",
        uid: "Solid Brick",
        abbreviation: "Solid Brick",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Solid Brick",
          },
        ],
        thermal_transmittance_W_per_m2K: 2.1,
      },
      "Solid Brick Insulated": {
        name: "Solid Brick Insulated",
        uid: "Solid Brick Insulated",
        abbreviation: "Solid Brick Insulated",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Solid Brick Insulated",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.28,
      },
      "Solid Stone": {
        name: "Solid Stone",
        uid: "Solid Stone",
        abbreviation: "Solid Stone",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Solid Stone",
          },
        ],
        thermal_transmittance_W_per_m2K: 2.25,
      },
      "Solid Stone Insulated": {
        name: "Solid Stone Insulated",
        uid: "Solid Stone Insulated",
        abbreviation: "Solid Stone Insulated",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Solid Stone Insulated",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.32,
      },
      "Solid Concrete": {
        name: "Solid Concrete",
        uid: "Solid Concrete",
        abbreviation: "Solid Concrete",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Solid Concrete",
          },
        ],
        thermal_transmittance_W_per_m2K: 3,
      },
      "Solid Concrete Insulated": {
        name: "Solid Concrete Insulated",
        uid: "Solid Concrete Insulated",
        abbreviation: "Solid Concrete Insulated",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Solid Concrete Insulated",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.31,
      },
      "Cavity Wall Uninsulated": {
        name: "Cavity Wall Uninsulated",
        uid: "Cavity Wall Uninsulated",
        abbreviation: "Cavity Wall Uninsulated",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Cavity Wall Uninsulated",
          },
        ],
        thermal_transmittance_W_per_m2K: 1.3,
      },
      "Cavity Wall Insulated": {
        name: "Cavity Wall Insulated",
        uid: "Cavity Wall Insulated",
        abbreviation: "Cavity Wall Insulated",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Cavity Wall Insulated",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.55,
      },
      Hardwood: {
        name: "Hardwood",
        uid: "Hardwood",
        abbreviation: "Hardwood",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Hardwood",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.18,
      },
      Softwood: {
        name: "Softwood",
        uid: "Softwood",
        abbreviation: "Softwood",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Softwood",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.13,
      },

      "Partition Uninsulated": {
        name: "Partition Uninsulated",
        uid: "Partition Uninsulated",
        abbreviation: "Partition Uninsulated",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Partition Uninsulated",
          },
        ],
        thermal_transmittance_W_per_m2K: 1.75,
      },
      "Partition Insulated": {
        name: "Partition Insulated",
        uid: "Partition Insulated",
        abbreviation: "Partition Insulated",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Partition Insulated",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.75,
      },
    },
  },
  "Party Wall": {
    type: HeatLoadMaterialRoleType.WALL,
    table: {
      "Building Regs 2022 Limit": {
        name: "Building Regs 2022 Limit",
        uid: "Building Regs 2022 Limit",
        abbreviation: "Building Regs 2022 Limit",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Building Regs 2022 Limit",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.26,
      },
      "Solid Brick": {
        name: "Solid Brick",
        uid: "Solid Brick",
        abbreviation: "Solid Brick",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Solid Brick",
          },
        ],
        thermal_transmittance_W_per_m2K: 2.1,
      },
      "Solid Brick Insulated": {
        name: "Solid Brick Insulated",
        uid: "Solid Brick Insulated",
        abbreviation: "Solid Brick Insulated",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Solid Brick Insulated",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.28,
      },
      "Solid Stone": {
        name: "Solid Stone",
        uid: "Solid Stone",
        abbreviation: "Solid Stone",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Solid Stone",
          },
        ],
        thermal_transmittance_W_per_m2K: 2.25,
      },
      "Solid Stone Insulated": {
        name: "Solid Stone Insulated",
        uid: "Solid Stone Insulated",
        abbreviation: "Solid Stone Insulated",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Solid Stone Insulated",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.32,
      },
      "Solid Concrete": {
        name: "Solid Concrete",
        uid: "Solid Concrete",
        abbreviation: "Solid Concrete",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Solid Concrete",
          },
        ],
        thermal_transmittance_W_per_m2K: 3,
      },
      "Solid Concrete Insulated": {
        name: "Solid Concrete Insulated",
        uid: "Solid Concrete Insulated",
        abbreviation: "Solid Concrete Insulated",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Solid Concrete Insulated",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.31,
      },
      "Cavity Wall Uninsulated": {
        name: "Cavity Wall Uninsulated",
        uid: "Cavity Wall Uninsulated",
        abbreviation: "Cavity Wall Uninsulated",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Cavity Wall Uninsulated",
          },
        ],
        thermal_transmittance_W_per_m2K: 1.3,
      },
      "Cavity Wall Insulated": {
        name: "Cavity Wall Insulated",
        uid: "Cavity Wall Insulated",
        abbreviation: "Cavity Wall Insulated",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Cavity Wall Insulated",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.55,
      },
      Hardwood: {
        name: "Hardwood",
        uid: "Hardwood",
        abbreviation: "Hardwood",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Hardwood",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.18,
      },
      Softwood: {
        name: "Softwood",
        uid: "Softwood",
        abbreviation: "Softwood",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Softwood",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.13,
      },

      "Partition Uninsulated": {
        name: "Partition Uninsulated",
        uid: "Partition Uninsulated",
        abbreviation: "Partition Uninsulated",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Partition Uninsulated",
          },
        ],
        thermal_transmittance_W_per_m2K: 1.75,
      },
      "Partition Insulated": {
        name: "Partition Insulated",
        uid: "Partition Insulated",
        abbreviation: "Partition Insulated",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Partition Insulated",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.75,
      },
    },
  },
  Window: {
    type: HeatLoadMaterialRoleType.WINDOW,
    table: {
      "Building Regs 2022 Limit": {
        name: "Building Regs 2022 Limit",
        uid: "Building Regs 2022 Limit",
        abbreviation: "Building Regs 2022 Limit",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Building Regs 2022 Limit",
          },
        ],
        thermal_transmittance_W_per_m2K: 1.6,
      },
      "Metal Single Glazed": {
        name: "Metal Single Glazed",
        uid: "Metal Single Glazed",
        abbreviation: "Metal Single Glazed",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Metal Single Glazed",
          },
        ],
        thermal_transmittance_W_per_m2K: 5.7,
      },
      "Metal Double Glazed": {
        name: "Metal Double Glazed",
        uid: "Metal Double Glazed",
        abbreviation: "Metal Double Glazed",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Metal Double Glazed",
          },
        ],
        thermal_transmittance_W_per_m2K: 3.4,
      },
      "Metal Triple Glazed": {
        name: "Metal Triple Glazed",
        uid: "Metal Triple Glazed",
        abbreviation: "Metal Triple Glazed",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Metal Triple Glazed",
          },
        ],
        thermal_transmittance_W_per_m2K: 2.6,
      },
      "Wood Single Glazed": {
        name: "Wood Single Glazed",
        uid: "Wood Single Glazed",
        abbreviation: "Wood Single Glazed",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Wood Single Glazed",
          },
        ],
        thermal_transmittance_W_per_m2K: 4.8,
      },
      "Wood Double Glazed": {
        name: "Wood Double Glazed",
        uid: "Wood Double Glazed",
        abbreviation: "Wood Double Glazed",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Wood Double Glazed",
          },
        ],
        thermal_transmittance_W_per_m2K: 2.8,
      },
      "Wood Triple Glazed": {
        name: "Wood Triple Glazed",
        uid: "Wood Triple Glazed",
        abbreviation: "Wood Triple Glazed",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Wood Triple Glazed",
          },
        ],
        thermal_transmittance_W_per_m2K: 2.1,
      },
      "PVC Single Glazed": {
        name: "PVC Single Glazed",
        uid: "PVC Single Glazed",
        abbreviation: "PVC Single Glazed",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "PVC Single Glazed",
          },
        ],
        thermal_transmittance_W_per_m2K: 4.8,
      },
      "PVC Double Glazed": {
        name: "PVC Double Glazed",
        uid: "PVC Double Glazed",
        abbreviation: "PVC Double Glazed",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "PVC Double Glazed",
          },
        ],
        thermal_transmittance_W_per_m2K: 2.8,
      },
      "PVC Triple Glazed": {
        name: "PVC Triple Glazed",
        uid: "PVC Triple Glazed",
        abbreviation: "PVC Triple Glazed",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "PVC Triple Glazed",
          },
        ],
        thermal_transmittance_W_per_m2K: 2.1,
      },
    },
  },
  "External Door": {
    type: HeatLoadMaterialRoleType.DOOR,
    table: {
      "Building Regs 2022 Limit": {
        name: "Building Regs 2022 Limit",
        uid: "Building Regs 2022 Limit",
        abbreviation: "Building Regs 2022 Limit",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Building Regs 2022 Limit",
          },
        ],
        thermal_transmittance_W_per_m2K: 1.6,
      },
      "Solid Wood Door": {
        name: "Solid Wood Door",
        uid: "Solid Wood Door",
        abbreviation: "Solid Wood Door",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Solid Wood Door",
          },
        ],
        thermal_transmittance_W_per_m2K: 3,
      },
      "Glazed Wood Single": {
        name: "Glazed Wood Single",
        uid: "Glazed Wood Single",
        abbreviation: "Glazed Wood Single",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Glazed Wood Single",
          },
        ],
        thermal_transmittance_W_per_m2K: 5.7,
      },
      "Glazed Wood Double": {
        name: "Glazed Wood Double",
        uid: "Glazed Wood Double",
        abbreviation: "Glazed Wood Double",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Glazed Wood Double",
          },
        ],
        thermal_transmittance_W_per_m2K: 3.4,
      },
      "Glazed Wood Triple": {
        name: "Glazed Wood Triple",
        uid: "Glazed Wood Triple",
        abbreviation: "Glazed Wood Triple",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Glazed Wood Triple",
          },
        ],
        thermal_transmittance_W_per_m2K: 2.6,
      },
      "Metal Single": {
        name: "Metal Single",
        uid: "Metal Single",
        abbreviation: "Metal Single",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Metal Single",
          },
        ],
        thermal_transmittance_W_per_m2K: 5.7,
      },
      "Metal Double": {
        name: "Metal Double",
        uid: "Metal Double",
        abbreviation: "Metal Double",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Metal Double",
          },
        ],
        thermal_transmittance_W_per_m2K: 3.4,
      },
      "Metal Triple": {
        name: "Metal Triple",
        uid: "Metal Triple",
        abbreviation: "Metal Triple",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Metal Triple",
          },
        ],
        thermal_transmittance_W_per_m2K: 2.6,
      },
      "Insulated Internal": {
        name: "Insulated Internal",
        uid: "Insulated Internal",
        abbreviation: "Insulated Internal",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Insulated Internal",
          },
        ],
        thermal_transmittance_W_per_m2K: 1.5,
      },
    },
  },
  "Internal Door": {
    type: HeatLoadMaterialRoleType.DOOR,
    table: {
      "Building Regs 2022 Limit": {
        name: "Building Regs 2022 Limit",
        uid: "Building Regs 2022 Limit",
        abbreviation: "Building Regs 2022 Limit",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Building Regs 2022 Limit",
          },
        ],
        thermal_transmittance_W_per_m2K: 1.6,
      },
      "Solid Wood Door": {
        name: "Solid Wood Door",
        uid: "Solid Wood Door",
        abbreviation: "Solid Wood Door",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Solid Wood Door",
          },
        ],
        thermal_transmittance_W_per_m2K: 3,
      },
      "Glazed Wood Single": {
        name: "Glazed Wood Single",
        uid: "Glazed Wood Single",
        abbreviation: "Glazed Wood Single",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Glazed Wood Single",
          },
        ],
        thermal_transmittance_W_per_m2K: 5.7,
      },
      "Glazed Wood Double": {
        name: "Glazed Wood Double",
        uid: "Glazed Wood Double",
        abbreviation: "Glazed Wood Double",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Glazed Wood Double",
          },
        ],
        thermal_transmittance_W_per_m2K: 3.4,
      },
      "Glazed Wood Triple": {
        name: "Glazed Wood Triple",
        uid: "Glazed Wood Triple",
        abbreviation: "Glazed Wood Triple",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Glazed Wood Triple",
          },
        ],
        thermal_transmittance_W_per_m2K: 2.6,
      },
      "Metal Single": {
        name: "Metal Single",
        uid: "Metal Single",
        abbreviation: "Metal Single",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Metal Single",
          },
        ],
        thermal_transmittance_W_per_m2K: 5.7,
      },
      "Metal Double": {
        name: "Metal Double",
        uid: "Metal Double",
        abbreviation: "Metal Double",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Metal Double",
          },
        ],
        thermal_transmittance_W_per_m2K: 3.4,
      },
      "Metal Triple": {
        name: "Metal Triple",
        uid: "Metal Triple",
        abbreviation: "Metal Triple",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Metal Triple",
          },
        ],
        thermal_transmittance_W_per_m2K: 2.6,
      },
      "Insulated Internal": {
        name: "Insulated Internal",
        uid: "Insulated Internal",
        abbreviation: "Insulated Internal",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Insulated Internal",
          },
        ],
        thermal_transmittance_W_per_m2K: 1.5,
      },
    },
  },
  Roof: {
    type: HeatLoadMaterialRoleType.ROOF,
    table: {
      "Building Regs 2022 Limit": {
        name: "Building Regs 2022 Limit",
        uid: "Building Regs 2022 Limit",
        abbreviation: "Building Regs 2022 Limit",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Building Regs 2022 Limit",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.16,
      },
      "Aerated Concrete": {
        name: "Aerated Concrete",
        uid: "Aerated Concrete",
        abbreviation: "Aerated Concrete",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Aerated Concrete",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.18,
      },
      Aerated: {
        name: "Aerated",
        uid: "Aerated",
        abbreviation: "Aerated",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Aerated",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.5,
      },
      "Felt/Bitumen": {
        name: "Felt/Bitumen",
        uid: "Felt/Bitumen",
        abbreviation: "Felt/Bitumen",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Felt/Bitumen",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.3,
      },
      Screed: {
        name: "Screed",
        uid: "Screed",
        abbreviation: "Screed",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Screed",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.41,
      },
      "Stone Chippings": {
        name: "Stone Chippings",
        uid: "Stone Chippings",
        abbreviation: "Stone Chippings",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Stone Chippings",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.96,
      },
      "Clay Tiles": {
        name: "Clay Tiles",
        uid: "Clay Tiles",
        abbreviation: "Clay Tiles",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Clay Tiles",
          },
        ],
        thermal_transmittance_W_per_m2K: 1,
      },
      "Concrete Tiles": {
        name: "Concrete Tiles",
        uid: "Concrete Tiles",
        abbreviation: "Concrete Tiles",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Concrete Tiles",
          },
        ],
        thermal_transmittance_W_per_m2K: 1.5,
      },
      "Wood/Wool": {
        name: "Wood/Wool",
        uid: "Wood/Wool",
        abbreviation: "Wood/Wool",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Wood/Wool",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.1,
      },
    },
  },
  "Bottom Floor": {
    type: HeatLoadMaterialRoleType.FLOOR,
    table: {
      "Building Regs 2022 Limit": {
        name: "Building Regs 2022 Limit",
        uid: "Building Regs 2022 Limit",
        abbreviation: "Building Regs 2022 Limit",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Building Regs 2022 Limit",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.18,
      },
      Concrete: {
        name: "Concrete",
        uid: "Concrete",
        abbreviation: "Concrete",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Concrete",
          },
        ],
        thermal_transmittance_W_per_m2K: 1.35,
      },
      Steel: {
        name: "Steel",
        uid: "Steel",
        abbreviation: "Steel",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Steel",
          },
        ],
        thermal_transmittance_W_per_m2K: 50,
      },
      Screed: {
        name: "Screed",
        uid: "Screed",
        abbreviation: "Screed",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Screed",
          },
        ],
        thermal_transmittance_W_per_m2K: 1.2,
      },
      Softwood: {
        name: "Softwood",
        uid: "Softwood",
        abbreviation: "Softwood",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Softwood",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.13,
      },
      Hardwood: {
        name: "Hardwood",
        uid: "Hardwood",
        abbreviation: "Hardwood",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Hardwood",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.18,
      },
      "Wood Blocks": {
        name: "Wood Blocks",
        uid: "Wood Blocks",
        abbreviation: "Wood Blocks",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Wood Blocks",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.14,
      },
    },
  },
  "Suspended Floor": {
    type: HeatLoadMaterialRoleType.FLOOR,
    table: {
      "Building Regs 2022 Limit": {
        name: "Building Regs 2022 Limit",
        uid: "Building Regs 2022 Limit",
        abbreviation: "Building Regs 2022 Limit",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Building Regs 2022 Limit",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.18,
      },
      Concrete: {
        name: "Concrete",
        uid: "Concrete",
        abbreviation: "Concrete",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Concrete",
          },
        ],
        thermal_transmittance_W_per_m2K: 1.35,
      },
      Steel: {
        name: "Steel",
        uid: "Steel",
        abbreviation: "Steel",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Steel",
          },
        ],
        thermal_transmittance_W_per_m2K: 50,
      },
      Screed: {
        name: "Screed",
        uid: "Screed",
        abbreviation: "Screed",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Screed",
          },
        ],
        thermal_transmittance_W_per_m2K: 1.2,
      },
      Softwood: {
        name: "Softwood",
        uid: "Softwood",
        abbreviation: "Softwood",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Softwood",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.13,
      },
      Hardwood: {
        name: "Hardwood",
        uid: "Hardwood",
        abbreviation: "Hardwood",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Hardwood",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.18,
      },
      "Wood Blocks": {
        name: "Wood Blocks",
        uid: "Wood Blocks",
        abbreviation: "Wood Blocks",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Wood Blocks",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.14,
      },
    },
  },
  "Party Floor": {
    type: HeatLoadMaterialRoleType.FLOOR,
    table: {
      "Building Regs 2022 Limit": {
        name: "Building Regs 2022 Limit",
        uid: "Building Regs 2022 Limit",
        abbreviation: "Building Regs 2022 Limit",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Building Regs 2022 Limit",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.18,
      },
      Concrete: {
        name: "Concrete",
        uid: "Concrete",
        abbreviation: "Concrete",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Concrete",
          },
        ],
        thermal_transmittance_W_per_m2K: 1.35,
      },
      Steel: {
        name: "Steel",
        uid: "Steel",
        abbreviation: "Steel",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Steel",
          },
        ],
        thermal_transmittance_W_per_m2K: 50,
      },
      Screed: {
        name: "Screed",
        uid: "Screed",
        abbreviation: "Screed",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Screed",
          },
        ],
        thermal_transmittance_W_per_m2K: 1.2,
      },
      Softwood: {
        name: "Softwood",
        uid: "Softwood",
        abbreviation: "Softwood",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Softwood",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.13,
      },
      Hardwood: {
        name: "Hardwood",
        uid: "Hardwood",
        abbreviation: "Hardwood",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Hardwood",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.18,
      },
      "Wood Blocks": {
        name: "Wood Blocks",
        uid: "Wood Blocks",
        abbreviation: "Wood Blocks",
        manufacturer: [
          {
            name: "Generic",
            abbreviation: "Generic",
            uid: "generic",
            priceTableName: "Wood Blocks",
          },
        ],
        thermal_transmittance_W_per_m2K: 0.14,
      },
    },
  },
};
