import { isNumber } from "lodash";
import { UnitsParameters } from "../api/document/drawing";
import { CurrencySymbol } from "./currency";
import { EPS, assertType, assertUnreachable } from "./utils";

export enum LiquidVolumeUnits {
  Liters = "L",
  Gallons = "gal",
  USGallons = "US gal",
}
export enum VolumeUnits {
  CubicMeters = "m³",
  CubicFeet = "ft³",
}
export enum VelocityUnits {
  MetersPerSecond = "m/s",
  FeetPerSecond = "ft/s",
}
export enum RainfallUnits {
  MmPerHour = "mm/hr",
  InchPerHour = "inch/hr",
}
export enum TemperatureUnits {
  Celsius = "°C",
  Fahrenheit = "°F",
}
export enum SoundLevelUnits {
  Decibel = "dB",
}
export enum AreaUnits {
  SquareMeters = "m²",
  SquareFeet = "ft²",
}
export enum TimeUnits {
  Minutes = "min",
  Seconds = "s",
}
export enum EnergyUnits {
  KiloWattHour = "kWh",
}
export enum FlowRateUnits {
  LitersPerSecond = "L/s",
  GallonsPerMinute = "gal/min",
  USGallonsPerMinute = "US gal/min",
  CubicFeetPerMinute = "ft³/min",
  LitersPerMinute = "L/min",
  CubicFeetPerHour = "ft³/hr",
  MetersCubedPerHour = "m³/hr",
}
export enum LengthUnits {
  Micron = "µm",
  Millimeters = "mm",
  Meters = "m",
  Feet = "ft",
  Inches = "in",
  InchesShort = '"',
}
export enum PressureUnits {
  KiloPascals = "kPa",
  Pascals = "Pa",
  Psi = "psi",
  Bar = "bar",
  Mbar = "mbar",
  GasKiloPascals = "gaskPa",
}
export enum PressureDropUnits {
  KiloPascalsPerMeter = "kPa/m",
  PascalsPerMeter = "Pa/m",
  PsiPer100Feet = "psi/100ft",
}
export enum AccelerationUnits {
  MetersPerSecondSquared = "m/s²",
  FeetPerSecondSquared = "ft/s²",
}
export enum PowerUnits {
  BtuPerHour = "Btu/hr",
  MegajoulesPerHour = "MJ/hr",
  Watts = "W",
  KiloWatts = "kW",
}
export enum HeatFluxUnits {
  WattsPerSquareMeter = "W/m²",
  BtuHourSquareFoot = "Btu/h·ft²",
}
export enum ThermalTransmittanceUnits {
  WattsPerSquareMeterKelvin = "W/m²·K",
  BtuHourSquareFootFahrenheit = "Btu/h·ft²·°F",
}
export enum CapacityRateUnits {
  LitersPerKiloWatts = "L/kW",
  GallonsPerKiloWatts = "gal/kW",
  USGallonsPerKiloWatts = "US gal/kW",
}
export enum NoUnits {
  None = "",
}
export enum KvUnits {
  Kv = "Kv",
}
export enum PercentUnits {
  Percent = "%",
}
export enum PipeDiameterUnits {
  PipeDiameterMM = "pmm",
}
export enum RotationUnits {
  Degrees = "°",
}

export const Units = {
  ...LiquidVolumeUnits,
  ...VolumeUnits,
  ...VelocityUnits,
  ...RainfallUnits,
  ...TemperatureUnits,
  ...SoundLevelUnits,
  ...AreaUnits,
  ...TimeUnits,
  ...EnergyUnits,
  ...FlowRateUnits,
  ...LengthUnits,
  ...PressureUnits,
  ...PressureDropUnits,
  ...AccelerationUnits,
  ...PowerUnits,
  ...HeatFluxUnits,
  ...ThermalTransmittanceUnits,
  ...CapacityRateUnits,
  ...NoUnits,
  ...KvUnits,
  ...PercentUnits,
  ...PipeDiameterUnits,
  ...RotationUnits,
};

export type Units =
  | LiquidVolumeUnits
  | VolumeUnits
  | VelocityUnits
  | RainfallUnits
  | TemperatureUnits
  | SoundLevelUnits
  | AreaUnits
  | TimeUnits
  | EnergyUnits
  | FlowRateUnits
  | LengthUnits
  | PressureUnits
  | PressureDropUnits
  | AccelerationUnits
  | PowerUnits
  | HeatFluxUnits
  | ThermalTransmittanceUnits
  | CapacityRateUnits
  | NoUnits
  | KvUnits
  | PercentUnits
  | PipeDiameterUnits
  | RotationUnits;

// Units Type Map
type UTM = {
  [LiquidVolumeUnits.Gallons]: LiquidVolumeUnits;
  [LiquidVolumeUnits.Liters]: LiquidVolumeUnits;
  [LiquidVolumeUnits.USGallons]: LiquidVolumeUnits;
  [VolumeUnits.CubicMeters]: VolumeUnits;
  [VolumeUnits.CubicFeet]: VolumeUnits;
  [VelocityUnits.MetersPerSecond]: VelocityUnits;
  [VelocityUnits.FeetPerSecond]: VelocityUnits;
  [RainfallUnits.MmPerHour]: RainfallUnits;
  [RainfallUnits.InchPerHour]: RainfallUnits;
  [TemperatureUnits.Celsius]: TemperatureUnits;
  [TemperatureUnits.Fahrenheit]: TemperatureUnits;
  [SoundLevelUnits.Decibel]: SoundLevelUnits;
  [AreaUnits.SquareMeters]: AreaUnits;
  [AreaUnits.SquareFeet]: AreaUnits;
  [TimeUnits.Minutes]: TimeUnits;
  [TimeUnits.Seconds]: TimeUnits;
  [EnergyUnits.KiloWattHour]: EnergyUnits;
  [FlowRateUnits.LitersPerSecond]: FlowRateUnits;
  [FlowRateUnits.GallonsPerMinute]: FlowRateUnits;
  [FlowRateUnits.USGallonsPerMinute]: FlowRateUnits;
  [FlowRateUnits.LitersPerMinute]: FlowRateUnits;
  [FlowRateUnits.CubicFeetPerHour]: FlowRateUnits;
  [FlowRateUnits.CubicFeetPerMinute]: FlowRateUnits;
  [FlowRateUnits.MetersCubedPerHour]: FlowRateUnits;
  [LengthUnits.Micron]: LengthUnits;
  [LengthUnits.Millimeters]: LengthUnits;
  [LengthUnits.Meters]: LengthUnits;
  [LengthUnits.Feet]: LengthUnits;
  [LengthUnits.Inches]: LengthUnits;
  [LengthUnits.InchesShort]: LengthUnits;
  [PressureUnits.KiloPascals]: PressureUnits;
  [PressureUnits.Pascals]: PressureUnits;
  [PressureUnits.Psi]: PressureUnits;
  [PressureUnits.Bar]: PressureUnits;
  [PressureUnits.Mbar]: PressureUnits;
  [PressureUnits.GasKiloPascals]: PressureUnits;
  [PressureDropUnits.KiloPascalsPerMeter]: PressureDropUnits;
  [PressureDropUnits.PascalsPerMeter]: PressureDropUnits;
  [PressureDropUnits.PsiPer100Feet]: PressureDropUnits;
  [AccelerationUnits.MetersPerSecondSquared]: AccelerationUnits;
  [AccelerationUnits.FeetPerSecondSquared]: AccelerationUnits;
  [PowerUnits.BtuPerHour]: PowerUnits;
  [PowerUnits.MegajoulesPerHour]: PowerUnits;
  [PowerUnits.Watts]: PowerUnits;
  [PowerUnits.KiloWatts]: PowerUnits;
  [HeatFluxUnits.WattsPerSquareMeter]: HeatFluxUnits;
  [HeatFluxUnits.BtuHourSquareFoot]: HeatFluxUnits;
  [ThermalTransmittanceUnits.WattsPerSquareMeterKelvin]: ThermalTransmittanceUnits;
  [ThermalTransmittanceUnits.BtuHourSquareFootFahrenheit]: ThermalTransmittanceUnits;
  [CapacityRateUnits.LitersPerKiloWatts]: CapacityRateUnits;
  [CapacityRateUnits.GallonsPerKiloWatts]: CapacityRateUnits;
  [CapacityRateUnits.USGallonsPerKiloWatts]: CapacityRateUnits;
  [NoUnits.None]: NoUnits;
  [KvUnits.Kv]: KvUnits;
  [PercentUnits.Percent]: PercentUnits;
  [PipeDiameterUnits.PipeDiameterMM]: PipeDiameterUnits;
  [RotationUnits.Degrees]: RotationUnits;
};

type UnitContextMap = {
  [LiquidVolumeUnits.Gallons]: UnitsContext.NONE;
  [LiquidVolumeUnits.Liters]: UnitsContext.NONE;
  [LiquidVolumeUnits.USGallons]: UnitsContext.NONE;
  [VolumeUnits.CubicMeters]: UnitsContext.NONE;
  [VolumeUnits.CubicFeet]: UnitsContext.NONE;
  [VelocityUnits.MetersPerSecond]: UnitsContext.NONE;
  [VelocityUnits.FeetPerSecond]: UnitsContext.NONE;
  [RainfallUnits.MmPerHour]: UnitsContext.NONE;
  [RainfallUnits.InchPerHour]: UnitsContext.NONE;
  [TemperatureUnits.Celsius]: UnitsContext.NONE;
  [TemperatureUnits.Fahrenheit]: UnitsContext.NONE;
  [SoundLevelUnits.Decibel]: UnitsContext.NONE;
  [AreaUnits.SquareMeters]: UnitsContext.NONE;
  [AreaUnits.SquareFeet]: UnitsContext.NONE;
  [TimeUnits.Minutes]: UnitsContext.NONE;
  [TimeUnits.Seconds]: UnitsContext.NONE;
  [EnergyUnits.KiloWattHour]: UnitsContext.NONE;
  [LengthUnits.Micron]: UnitsContext.NONE;
  [LengthUnits.Millimeters]: UnitsContext.NONE;
  [LengthUnits.Meters]: UnitsContext.NONE;
  [LengthUnits.Feet]: UnitsContext.NONE;
  [LengthUnits.Inches]: UnitsContext.NONE;
  [LengthUnits.InchesShort]: UnitsContext.NONE;
  [AccelerationUnits.MetersPerSecondSquared]: UnitsContext.NONE;
  [AccelerationUnits.FeetPerSecondSquared]: UnitsContext.NONE;
  [HeatFluxUnits.WattsPerSquareMeter]: UnitsContext.NONE;
  [HeatFluxUnits.BtuHourSquareFoot]: UnitsContext.NONE;
  [ThermalTransmittanceUnits.WattsPerSquareMeterKelvin]: UnitsContext.NONE;
  [ThermalTransmittanceUnits.BtuHourSquareFootFahrenheit]: UnitsContext.NONE;
  [CapacityRateUnits.LitersPerKiloWatts]: UnitsContext.NONE;
  [CapacityRateUnits.GallonsPerKiloWatts]: UnitsContext.NONE;
  [CapacityRateUnits.USGallonsPerKiloWatts]: UnitsContext.NONE;
  [NoUnits.None]: UnitsContext.NONE;
  [KvUnits.Kv]: UnitsContext.NONE;
  [PercentUnits.Percent]: UnitsContext.NONE;
  [PipeDiameterUnits.PipeDiameterMM]: UnitsContext.NONE;
  [RotationUnits.Degrees]: UnitsContext.NONE;
} & {
  [key in PressureUnits]:
    | UnitsContext.VENTILATION
    | UnitsContext.SMALL_PRESSURE
    | UnitsContext.NONE;
} & {
  [key in PowerUnits]:
    | UnitsContext.MECHANICAL_ENERGY_MEASUREMENT
    | UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT
    | UnitsContext.GAS_ENERGY_MEASUREMENT;
} & {
  [key in PressureDropUnits]:
    | UnitsContext.VENTILATION
    | UnitsContext.SMALL_PRESSURE
    | UnitsContext.NONE;
} & {
  [key in FlowRateUnits]: UnitsContext.VENTILATION | UnitsContext.NONE;
};

export type ConvertTable = {
  [key in Units]: number | ((v: number) => number);
};

export const commonFrom: ConvertTable = {
  [Units.None]: 1,
  [Units.Kv]: 1,
  [Units.Percent]: 1,
  [Units.PipeDiameterMM]: 1,
  [Units.CubicMeters]: 1,
  [Units.CubicFeet]: 1 / 35.3147,
  [Units.Liters]: 1,
  [Units.Gallons]: 4.54609,
  [Units.USGallons]: 3.785411784,
  [Units.SquareMeters]: 1,
  [Units.SquareFeet]: 1 / 10.76391042,
  [Units.LitersPerSecond]: 1,
  [Units.LitersPerMinute]: 1 / 60,
  [Units.MetersCubedPerHour]: 1 / 3.6,
  [Units.GallonsPerMinute]: 0.0757683211,
  [Units.USGallonsPerMinute]: 1 / 15.850323140625,
  [Units.CubicFeetPerHour]: 0.00786579072,
  [Units.CubicFeetPerMinute]: 1 / 2.11888,
  [Units.Seconds]: 1,
  [Units.Minutes]: 60,
  [Units.Meters]: 1,
  [Units.Micron]: 0.000001,
  [Units.Millimeters]: 0.001,
  [Units.Feet]: 0.3048,
  [Units.Inches]: 0.0254,
  [Units.InchesShort]: 0.0254,
  [Units.Pascals]: 1,
  [Units.KiloPascals]: 1000,
  [Units.GasKiloPascals]: 1000,
  [Units.Psi]: 6894.7572931783,
  [Units.Bar]: 100000,
  [Units.Mbar]: 100,
  [Units.PascalsPerMeter]: 1,
  [Units.KiloPascalsPerMeter]: 1000,
  [Units.PsiPer100Feet]: 226.2059479,
  [Units.MetersPerSecond]: 1,
  [Units.FeetPerSecond]: 0.3048,
  [Units.MetersPerSecondSquared]: 1,
  [Units.FeetPerSecondSquared]: 0.3048,
  [Units.MmPerHour]: 1,
  [Units.InchPerHour]: 25.4,
  [Units.Celsius]: 1,
  [Units.Fahrenheit]: (f) => (f - 32) / 1.8,
  [Units.Watts]: 1,
  [Units.KiloWatts]: 1000,
  [Units.MegajoulesPerHour]: 1 / 0.0036,
  [Units.BtuPerHour]: 1 / 3.4121416331,
  [Units.KiloWattHour]: 1,
  [Units.LitersPerKiloWatts]: 1,
  [Units.GallonsPerKiloWatts]: 4.54609,
  [Units.USGallonsPerKiloWatts]: 3.78541,
  [Units.WattsPerSquareMeter]: 1,
  [Units.BtuHourSquareFoot]: 3.1545907451,
  [Units.WattsPerSquareMeterKelvin]: 1,
  [Units.BtuHourSquareFootFahrenheit]: 3.1545907451,
  [Units.Decibel]: 1,
  [Units.Degrees]: 1,
};

export const commonTo: ConvertTable = {
  [Units.None]: 1,
  [Units.Kv]: 1,
  [Units.Percent]: 1,
  [Units.PipeDiameterMM]: 1,
  [Units.CubicMeters]: 1,
  [Units.CubicFeet]: 35.3147,
  [Units.Liters]: 1,
  [Units.Gallons]: 1 / 4.54609,
  [Units.USGallons]: 1 / 3.785411784,
  [Units.SquareMeters]: 1,
  [Units.SquareFeet]: 10.76391042,
  [Units.LitersPerSecond]: 1,
  [Units.LitersPerMinute]: 60,
  [Units.MetersCubedPerHour]: 3.6,
  [Units.GallonsPerMinute]: 1 / 0.0757683211,
  [Units.USGallonsPerMinute]: 15.850323140625,
  [Units.CubicFeetPerHour]: 1 / 0.00786579072,
  [Units.CubicFeetPerMinute]: 2.11888,
  [Units.Seconds]: 1,
  [Units.Minutes]: 1 / 60,
  [Units.Meters]: 1,
  [Units.Micron]: 1000000,
  [Units.Millimeters]: 1000,
  [Units.Feet]: 1 / 0.3048,
  [Units.Inches]: 1 / 0.0254,
  [Units.InchesShort]: 1 / 0.0254,
  [Units.Pascals]: 1,
  [Units.KiloPascals]: 0.001,
  [Units.GasKiloPascals]: 0.001,
  [Units.Psi]: 1 / 6894.7572931783,
  [Units.Bar]: 0.00001,
  [Units.Mbar]: 0.01,
  [Units.PascalsPerMeter]: 1,
  [Units.KiloPascalsPerMeter]: 0.001,
  [Units.PsiPer100Feet]: 1 / 226.2059479,
  [Units.MetersPerSecond]: 1,
  [Units.FeetPerSecond]: 1 / 1 / 0.3048,
  [Units.MetersPerSecondSquared]: 1,
  [Units.FeetPerSecondSquared]: 1 / 0.3048,
  [Units.MmPerHour]: 1,
  [Units.InchPerHour]: 1 / 25.4,
  [Units.Celsius]: 1,
  [Units.Fahrenheit]: (c) => c * 1.8 + 32,
  [Units.Watts]: 1,
  [Units.KiloWatts]: 0.001,
  [Units.MegajoulesPerHour]: 0.0036,
  [Units.BtuPerHour]: 3.4121416331,
  [Units.KiloWattHour]: 1,
  [Units.LitersPerKiloWatts]: 1,
  [Units.GallonsPerKiloWatts]: 1 / 4.54609,
  [Units.USGallonsPerKiloWatts]: 1 / 3.785411784,
  [Units.WattsPerSquareMeter]: 1,
  [Units.BtuHourSquareFoot]: 1 / 3.1545907451,
  [Units.WattsPerSquareMeterKelvin]: 1,
  [Units.BtuHourSquareFootFahrenheit]: 1 / 3.1545907451,
  [Units.Decibel]: 1,
  [Units.Degrees]: 1,
};

export enum UnitsContext {
  NONE = "none",
  GAS_ENERGY_MEASUREMENT = "gas",
  MECHANICAL_ENERGY_MEASUREMENT = "mechanical",
  // Use Watt, and mechanical context
  HEAT_LOAD_ENERGY_MEASUREMENT = "heatload",

  // Ventilation have separate set of pressure drop units
  VENTILATION = "ventilation",
  SMALL_PRESSURE = "small_pressure_drop",
}

export enum GasEnergyMeasurementSystem {
  METRIC = "METRIC",
  IMPERIAL = "IMPERIAL",
  UNIVERSAL = "UNIVERSAL",
}

export enum MechanicalEnergyMeasurementSystem {
  IMPERIAL = "IMPERIAL",
  UNIVERSAL = "UNIVERSAL",
}

export enum MeasurementSystem {
  METRIC = "METRIC",
  IMPERIAL = "IMPERIAL",
}

export enum PressureMeasurementSystem {
  METRIC = "METRIC",
  METRIC_SMALL = "METRIC_SMALL",
  IMPERIAL = "IMPERIAL",
  UK = "UK",
}

export enum PressureDropMeasurementSystem {
  METRIC = "METRIC",
  METRIC_SMALL = "METRIC_SMALL",
  IMPERIAL = "IMPERIAL",
  UK = "UK",
}

export enum VolumeMeasurementSystem {
  METRIC = "METRIC",
  IMPERIAL = "IMPERIAL",
  US = "US",
}

export enum VelocityMeasurementSystem {
  METRIC = "METRIC",
  IMPERIAL = "IMPERIAL",
}

export enum RainfallMeasurementSystem {
  METRIC = "METRIC",
  IMPERIAL = "IMPERIAL",
}

export enum AreaMeasurementSystem {
  METRIC = "METRIC",
  IMPERIAL = "IMPERIAL",
}

export enum FlowRateMeasurementSystem {
  METRIC = "METRIC",
  IMPERIAL = "IMPERIAL",
  IMPERIAL_CFM = "IMPERIAL_CFM",
  US = "US",
  METRIC_MIN = "METRIC_MIN",
}

export enum PrecisionNumber {
  // Precision used for input fields for the user
  FIELD = 9,
  // Save precision to the database - this number is NOT used
  // We use the total precision offered by JS
  FULL = 52,
}

export enum PrecisionString {
  // Precision used for displaying values in the UI
  DISPLAY_SHORT = 2,
  DISPLAY = 3,
}

export const Precision = Object.assign({}, PrecisionNumber, PrecisionString);
export type Precision = PrecisionNumber | PrecisionString;

export function convertMeasurementSystemNonNull<T extends keyof UTM>(
  unitsPrefs: UnitsParameters,
  units: T,
  valueRaw: number | string,
  unitContext: UnitContextMap[T],
  targetUnits?: T,
): [UTM[T], number | string | null] {
  const value = Number(valueRaw);

  if (units === Units.PipeDiameterMM) {
    const [units, val] = convertPipeDiameterFromMetric(unitsPrefs, value);
    return [units as UTM[T], val];
  }

  const targetUnit =
    targetUnits ?? getTargetUnits(unitsPrefs, units, unitContext);

  const val = convertUnits(value, units, targetUnit);
  return [targetUnit as UTM[T], val];
}

export function convertUnits<T extends keyof UTM>(
  value: number,
  fromUnits: T,
  toUnits: T,
): number {
  const common = (() => {
    const toCommonRatio = commonFrom[fromUnits];
    if (typeof toCommonRatio === "function") {
      return toCommonRatio(value);
    } else if (isNumber(toCommonRatio)) {
      return toCommonRatio * value;
    } else {
      throw new Error("Invalid conversion ratio");
    }
  })();

  const toTargetRatio = commonTo[toUnits];
  if (typeof toTargetRatio === "function") {
    return toTargetRatio(common);
  } else {
    return Number(toTargetRatio) * common;
  }
}

export function getTargetUnits<T extends Units>(
  unitsPrefs: UnitsParameters,
  units_: T,
  unitContext_: UnitContextMap[T],
): UTM[T] {
  const units = units_ as Units;
  const unitContext = unitContext_ as UnitContextMap[typeof units];
  // TODO some of the case statements can be merged
  switch (units) {
    case Units.None:
      return Units.None as UTM[T];
    case Units.Degrees:
      return Units.Degrees as UTM[T];
    case Units.Kv:
      return Units.Kv as UTM[T];
    case Units.Percent:
      return Units.Percent as UTM[T];
    case Units.PipeDiameterMM:
      return Units.PipeDiameterMM as UTM[T];
    case Units.Minutes:
      return Units.Minutes as UTM[T];
    case Units.Seconds:
      return Units.Seconds as UTM[T];
    case Units.Decibel:
      return Units.Decibel as UTM[T];
    case Units.KiloWattHour:
      return Units.KiloWattHour as UTM[T];
    case Units.Watts:
    case Units.KiloWatts:
    case Units.BtuPerHour:
    case Units.MegajoulesPerHour:
      assertType<UnitContextMap[typeof units]>(unitContext);
      switch (unitContext) {
        case UnitsContext.MECHANICAL_ENERGY_MEASUREMENT:
          switch (unitsPrefs.mechanicalEnergyMeasurementSystem) {
            case MechanicalEnergyMeasurementSystem.IMPERIAL:
              return Units.BtuPerHour as UTM[T];
            case MechanicalEnergyMeasurementSystem.UNIVERSAL:
              return Units.KiloWatts as UTM[T];
            default:
              assertUnreachable(unitsPrefs.mechanicalEnergyMeasurementSystem);
          }
          break;
        case UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT:
          switch (unitsPrefs.mechanicalEnergyMeasurementSystem) {
            case MechanicalEnergyMeasurementSystem.IMPERIAL:
              return Units.BtuPerHour as UTM[T];
            case MechanicalEnergyMeasurementSystem.UNIVERSAL:
              return Units.Watts as UTM[T];
            default:
              assertUnreachable(unitsPrefs.mechanicalEnergyMeasurementSystem);
          }
          break;
        case UnitsContext.GAS_ENERGY_MEASUREMENT:
          switch (unitsPrefs.gasEnergyMeasurementSystem) {
            case GasEnergyMeasurementSystem.METRIC:
              return Units.MegajoulesPerHour as UTM[T];
            case GasEnergyMeasurementSystem.IMPERIAL:
              return Units.BtuPerHour as UTM[T];
            case GasEnergyMeasurementSystem.UNIVERSAL:
              if (units === Units.Watts) {
                return Units.Watts as UTM[T];
              }
              return Units.KiloWatts as UTM[T];
            default:
              assertUnreachable(unitsPrefs.gasEnergyMeasurementSystem);
          }
          break;
        default:
          assertUnreachable(unitContext);
      }
    case Units.LitersPerKiloWatts:
    case Units.GallonsPerKiloWatts:
    case Units.USGallonsPerKiloWatts: {
      switch (unitsPrefs.volumeMeasurementSystem) {
        case VolumeMeasurementSystem.METRIC:
          return Units.LitersPerKiloWatts as UTM[T];
        case VolumeMeasurementSystem.IMPERIAL:
          return Units.GallonsPerKiloWatts as UTM[T];
        case VolumeMeasurementSystem.US:
          return Units.USGallonsPerKiloWatts as UTM[T];
      }
      assertUnreachable(unitsPrefs.volumeMeasurementSystem);
      break;
    }
    case Units.LitersPerSecond:
    case Units.GallonsPerMinute:
    case Units.USGallonsPerMinute:
    case Units.LitersPerMinute:
    case Units.MetersCubedPerHour:
    case Units.CubicFeetPerHour:
      assertType<UnitContextMap[typeof units]>(unitContext);
      switch (unitContext) {
        case UnitsContext.VENTILATION:
          switch (unitsPrefs.ventilationFlowRateMeasurementSystem) {
            case FlowRateMeasurementSystem.METRIC:
              return Units.LitersPerSecond as UTM[T];
            case FlowRateMeasurementSystem.IMPERIAL:
              return Units.GallonsPerMinute as UTM[T];
            case FlowRateMeasurementSystem.US:
              return Units.USGallonsPerMinute as UTM[T];
            case FlowRateMeasurementSystem.METRIC_MIN:
              return Units.LitersPerMinute as UTM[T];
            case FlowRateMeasurementSystem.IMPERIAL_CFM:
              return Units.CubicFeetPerMinute as UTM[T];
          }
          assertUnreachable(unitsPrefs.ventilationFlowRateMeasurementSystem);
          break;
        case UnitsContext.NONE:
          switch (unitsPrefs.flowRateMeasurementSystem) {
            case FlowRateMeasurementSystem.METRIC:
              return Units.LitersPerSecond as UTM[T];
            case FlowRateMeasurementSystem.IMPERIAL:
              return Units.GallonsPerMinute as UTM[T];
            case FlowRateMeasurementSystem.US:
              return Units.USGallonsPerMinute as UTM[T];
            case FlowRateMeasurementSystem.METRIC_MIN:
              return Units.LitersPerMinute as UTM[T];
            case FlowRateMeasurementSystem.IMPERIAL_CFM:
              return Units.CubicFeetPerMinute as UTM[T];
          }
          assertUnreachable(unitsPrefs.flowRateMeasurementSystem);
          break;
          break;
        default:
          assertUnreachable(unitContext);
      }
      break;
    case Units.Millimeters:
    case Units.Inches:
    case Units.InchesShort:
      switch (unitsPrefs.lengthMeasurementSystem) {
        case MeasurementSystem.METRIC:
          return Units.Millimeters as UTM[T];
        case MeasurementSystem.IMPERIAL:
          return Units.Inches as UTM[T];
      }
      assertUnreachable(unitsPrefs.lengthMeasurementSystem);
      break;
    case Units.Meters:
    case Units.Feet:
      switch (unitsPrefs.lengthMeasurementSystem) {
        case MeasurementSystem.METRIC:
          return Units.Meters as UTM[T];
        case MeasurementSystem.IMPERIAL:
          return Units.Feet as UTM[T];
      }
      assertUnreachable(unitsPrefs.lengthMeasurementSystem);
      break;
    case Units.Micron:
      switch (unitsPrefs.lengthMeasurementSystem) {
        case MeasurementSystem.METRIC:
          return Units.Micron as UTM[T];
        case MeasurementSystem.IMPERIAL:
          return Units.Inches as UTM[T];
      }
      assertUnreachable(unitsPrefs.lengthMeasurementSystem);
      break;
    case Units.GasKiloPascals:
      switch (unitsPrefs.pressureMeasurementSystem) {
        case PressureMeasurementSystem.METRIC:
          return Units.KiloPascals as UTM[T];
        case PressureMeasurementSystem.IMPERIAL:
          return Units.Psi as UTM[T];
        case PressureMeasurementSystem.UK:
          return Units.Mbar as UTM[T];
        case PressureMeasurementSystem.METRIC_SMALL:
          return Units.Pascals as UTM[T];
      }
      assertUnreachable(unitsPrefs.pressureMeasurementSystem);
      break;
    case Units.Mbar:
      switch (unitsPrefs.pressureMeasurementSystem) {
        case PressureMeasurementSystem.METRIC:
          return Units.Pascals as UTM[T];
        case PressureMeasurementSystem.IMPERIAL:
          return Units.Psi as UTM[T];
        case PressureMeasurementSystem.UK:
          return Units.Mbar as UTM[T];
        case PressureMeasurementSystem.METRIC_SMALL:
          return Units.Pascals as UTM[T];
      }
      assertUnreachable(unitsPrefs.pressureMeasurementSystem);
      break;
    case Units.Pascals:
    case Units.KiloPascals:
    case Units.Psi:
    case Units.Bar:
      assertType<UnitContextMap[typeof units]>(unitContext);
      switch (unitContext) {
        case UnitsContext.SMALL_PRESSURE:
          switch (unitsPrefs.pressureMeasurementSystem) {
            case PressureMeasurementSystem.METRIC:
              return Units.Pascals as UTM[T];
            case PressureMeasurementSystem.IMPERIAL:
              return Units.Psi as UTM[T];
            case PressureMeasurementSystem.UK:
              return Units.Mbar as UTM[T];
            case PressureMeasurementSystem.METRIC_SMALL:
              return Units.Pascals as UTM[T];
          }
          assertUnreachable(unitsPrefs.pressureMeasurementSystem);
          break;
        case UnitsContext.VENTILATION:
          switch (unitsPrefs.ventilationPressureMeasurementSystem) {
            case PressureMeasurementSystem.METRIC:
              return Units.Pascals as UTM[T];
            case PressureMeasurementSystem.IMPERIAL:
              return Units.Psi as UTM[T];
            case PressureMeasurementSystem.UK:
              return Units.Mbar as UTM[T];
            case PressureMeasurementSystem.METRIC_SMALL:
              return Units.Pascals as UTM[T];
          }
          assertUnreachable(unitsPrefs.ventilationPressureMeasurementSystem);
          break;
        case UnitsContext.NONE:
          switch (unitsPrefs.pressureMeasurementSystem) {
            case PressureMeasurementSystem.METRIC:
              return Units.KiloPascals as UTM[T];
            case PressureMeasurementSystem.IMPERIAL:
              return Units.Psi as UTM[T];
            case PressureMeasurementSystem.UK:
              return Units.Bar as UTM[T];
            case PressureMeasurementSystem.METRIC_SMALL:
              return Units.Pascals as UTM[T];
          }
          assertUnreachable(unitsPrefs.pressureMeasurementSystem);
          break;
        default:
          assertUnreachable(unitContext);
      }

    case Units.MetersPerSecond:
    case Units.FeetPerSecond:
      switch (unitsPrefs.velocityMeasurementSystem) {
        case VelocityMeasurementSystem.METRIC:
          return Units.MetersPerSecond as UTM[T];
        case VelocityMeasurementSystem.IMPERIAL:
          return Units.FeetPerSecond as UTM[T];
      }
      assertUnreachable(unitsPrefs.velocityMeasurementSystem);
    case Units.MetersPerSecondSquared:
    case Units.FeetPerSecondSquared:
      switch (unitsPrefs.lengthMeasurementSystem) {
        case MeasurementSystem.METRIC:
          return Units.MetersPerSecondSquared as UTM[T];
        case MeasurementSystem.IMPERIAL:
          return Units.FeetPerSecondSquared as UTM[T];
      }
      assertUnreachable(unitsPrefs.lengthMeasurementSystem);
    case Units.Celsius:
    case Units.Fahrenheit:
      switch (unitsPrefs.temperatureMeasurementSystem) {
        case MeasurementSystem.METRIC:
          return Units.Celsius as UTM[T];
        case MeasurementSystem.IMPERIAL:
          return Units.Fahrenheit as UTM[T];
      }
      assertUnreachable(unitsPrefs.temperatureMeasurementSystem);
    case Units.Liters:
    case Units.Gallons:
    case Units.USGallons:
      switch (unitsPrefs.volumeMeasurementSystem) {
        case VolumeMeasurementSystem.METRIC:
          return Units.Liters as UTM[T];
        case VolumeMeasurementSystem.IMPERIAL:
          return Units.Gallons as UTM[T];
        case VolumeMeasurementSystem.US:
          return Units.USGallons as UTM[T];
      }
      assertUnreachable(unitsPrefs.volumeMeasurementSystem);
    case Units.KiloPascalsPerMeter:
    case Units.PsiPer100Feet:
    case Units.PascalsPerMeter:
      assertType<UnitContextMap[typeof units]>(unitContext);
      switch (unitContext) {
        case UnitsContext.SMALL_PRESSURE:
          switch (unitsPrefs.pressureDropMeasurementSystem) {
            case PressureDropMeasurementSystem.METRIC:
            case PressureDropMeasurementSystem.METRIC_SMALL:
              return Units.PascalsPerMeter as UTM[T];
            case PressureDropMeasurementSystem.IMPERIAL:
              return Units.PsiPer100Feet as UTM[T];
            case PressureDropMeasurementSystem.UK:
              return Units.PascalsPerMeter as UTM[T];
          }
          assertUnreachable(unitsPrefs.pressureDropMeasurementSystem);
          break;
        case UnitsContext.VENTILATION:
          switch (unitsPrefs.ventilationPressureMeasurementSystem) {
            case PressureMeasurementSystem.METRIC:
            case PressureMeasurementSystem.METRIC_SMALL:
              return Units.PascalsPerMeter as UTM[T];
            case PressureMeasurementSystem.IMPERIAL:
              return Units.PsiPer100Feet as UTM[T];
            case PressureMeasurementSystem.UK:
              return Units.PascalsPerMeter as UTM[T];
          }
          assertUnreachable(unitsPrefs.ventilationPressureMeasurementSystem);
          break;
        case UnitsContext.NONE:
          switch (unitsPrefs.pressureDropMeasurementSystem) {
            case PressureDropMeasurementSystem.METRIC:
              return Units.KiloPascalsPerMeter as UTM[T];
            case PressureDropMeasurementSystem.METRIC_SMALL:
              return Units.PascalsPerMeter as UTM[T];
            case PressureDropMeasurementSystem.IMPERIAL:
              return Units.PsiPer100Feet as UTM[T];
            case PressureDropMeasurementSystem.UK:
              return Units.PascalsPerMeter as UTM[T];
          }
          assertUnreachable(unitsPrefs.pressureDropMeasurementSystem);
          break;
        default:
          assertUnreachable(unitContext);
      }
    case Units.MmPerHour:
    case Units.InchPerHour:
      switch (unitsPrefs.rainfallMeasurementSystem) {
        case RainfallMeasurementSystem.METRIC:
          return Units.MmPerHour as UTM[T];
        case RainfallMeasurementSystem.IMPERIAL:
          return Units.InchPerHour as UTM[T];
      }
      assertUnreachable(unitsPrefs.rainfallMeasurementSystem);
    case Units.SquareMeters:
    case Units.SquareFeet:
      switch (unitsPrefs.areaMeasurementSystem) {
        case AreaMeasurementSystem.METRIC:
          return Units.SquareMeters as UTM[T];
        case AreaMeasurementSystem.IMPERIAL:
          return Units.SquareFeet as UTM[T];
      }
      assertUnreachable(unitsPrefs.areaMeasurementSystem);
    case Units.CubicMeters:
    case Units.CubicFeet:
      switch (unitsPrefs.volumeMeasurementSystem) {
        case VolumeMeasurementSystem.METRIC:
          return Units.CubicMeters as UTM[T];
        case VolumeMeasurementSystem.IMPERIAL:
        case VolumeMeasurementSystem.US:
          return Units.CubicFeet as UTM[T];
      }
      assertUnreachable(unitsPrefs.volumeMeasurementSystem);
    case Units.WattsPerSquareMeter:
    case Units.BtuHourSquareFoot:
      switch (unitsPrefs.mechanicalEnergyMeasurementSystem) {
        case MechanicalEnergyMeasurementSystem.UNIVERSAL:
          return Units.WattsPerSquareMeter as UTM[T];
        case MechanicalEnergyMeasurementSystem.IMPERIAL:
          return Units.BtuHourSquareFoot as UTM[T];
      }
      assertUnreachable(unitsPrefs.mechanicalEnergyMeasurementSystem);
    case Units.WattsPerSquareMeterKelvin:
    case Units.BtuHourSquareFootFahrenheit:
      switch (unitsPrefs.mechanicalEnergyMeasurementSystem) {
        case MechanicalEnergyMeasurementSystem.UNIVERSAL:
          return Units.WattsPerSquareMeterKelvin as UTM[T];
        case MechanicalEnergyMeasurementSystem.IMPERIAL:
          return Units.BtuHourSquareFootFahrenheit as UTM[T];
      }
      assertUnreachable(unitsPrefs.mechanicalEnergyMeasurementSystem);
  }

  throw new Error(`Invalid units ${units_}`);
}

export function roundToPrecision(
  value: number,
  precision: PrecisionNumber,
): number;
export function roundToPrecision(
  value: number,
  precision: PrecisionString,
): string;
export function roundToPrecision(
  value: number,
  precision: Precision | number,
): number | string {
  switch (precision) {
    case Precision.FULL:
      return value;
    case Precision.FIELD:
      return Number(value.toPrecision(precision));
    case Precision.DISPLAY:
      return value.toFixed(Precision.DISPLAY);
    case Precision.DISPLAY_SHORT:
      return value.toFixed(Precision.DISPLAY_SHORT);
    default:
      return +value.toFixed(precision);
  }
}

// This function is required to enforce the input types for overloading
function roundToPrecisionWrapper(
  value: number,
  precision: Precision,
): number | string {
  switch (precision) {
    case Precision.FULL:
    case Precision.FIELD:
      return roundToPrecision(value, precision);
    case Precision.DISPLAY:
      return roundToPrecision(value, precision);
    case Precision.DISPLAY_SHORT:
      return roundToPrecision(value, precision);
    default:
      assertUnreachable(precision);
      return value;
  }
}

export function convertMeasurementSystem(
  unitsPrefs: UnitsParameters,
  units: Units,
  value: number | string,
  precision: PrecisionString,
  unitContext?: UnitsContext,
): [Units, string];
export function convertMeasurementSystem(
  unitsPrefs: UnitsParameters,
  units: Units,
  value: null,
  precision: PrecisionString,
  unitContext?: UnitsContext,
): [Units, null];
export function convertMeasurementSystem(
  unitsPrefs: UnitsParameters,
  units: Units,
  value: number | string | null,
  precision: PrecisionString,
  unitContext?: UnitsContext,
): [Units, string | null];
export function convertMeasurementSystem(
  unitsPrefs: UnitsParameters,
  units: Units,
  value: null,
  precision?: Precision,
  unitContext?: UnitsContext,
): [Units, null];
export function convertMeasurementSystem(
  unitsPrefs: UnitsParameters,
  units: PipeDiameterUnits,
  value: number | string,
  precision?: Precision,
  unitContext?: UnitsContext,
): [PipeDiameterUnits, number | string];
export function convertMeasurementSystem(
  unitsPrefs: UnitsParameters,
  units: Units,
  value: number | string,
  precision?: Precision,
  unitContext?: UnitsContext,
): [Units, number];
export function convertMeasurementSystem(
  unitsPrefs: UnitsParameters,
  units: Units,
  value: number | string | null,
  precision?: Precision,
  unitContext?: UnitsContext,
): [Units, number | null];
export function convertMeasurementSystem(
  unitsPrefs: UnitsParameters,
  units: Units,
  value: number | string | null,
  precision: Precision = Precision.FULL,
  unitContext: UnitsContext = UnitsContext.NONE,
): [Units, number | string | null] {
  if (value === null) {
    const [newUnits] = convertMeasurementSystemNonNull(
      unitsPrefs,
      units,
      1,
      unitContext ? unitContext : UnitsContext.NONE,
    );
    return [newUnits, null];
  } else {
    let [unit, val] = convertMeasurementSystemNonNull(
      unitsPrefs,
      units,
      value,
      unitContext ? unitContext : UnitsContext.NONE,
    );
    if (isNumber(val)) {
      val = roundToPrecisionWrapper(val, precision);
    }
    return [unit, val];
  }
}
export function convertMeasurementToMetric(
  units: Units,
  value: null,
  unitContext: UnitsContext,
): [Units, null];
export function convertMeasurementToMetric(
  units: Units,
  value: number,
  unitContext: UnitsContext,
): [Units, number];
// Always store in metric, no matter the document properties.
export function convertMeasurementToMetric(
  units: Units,
  value: number | null,
  unitContext: UnitsContext,
) {
  return convertMeasurementSystem(
    {
      lengthMeasurementSystem: MeasurementSystem.METRIC,
      temperatureMeasurementSystem: MeasurementSystem.METRIC,
      velocityMeasurementSystem: VelocityMeasurementSystem.METRIC,
      pressureMeasurementSystem: PressureMeasurementSystem.METRIC,
      ventilationPressureMeasurementSystem:
        PressureMeasurementSystem.METRIC_SMALL,
      volumeMeasurementSystem: VolumeMeasurementSystem.METRIC,
      gasEnergyMeasurementSystem: GasEnergyMeasurementSystem.METRIC,
      mechanicalEnergyMeasurementSystem:
        MechanicalEnergyMeasurementSystem.UNIVERSAL,
      pressureDropMeasurementSystem: PressureDropMeasurementSystem.METRIC,
      rainfallMeasurementSystem: RainfallMeasurementSystem.METRIC,
      areaMeasurementSystem: AreaMeasurementSystem.METRIC,
      flowRateMeasurementSystem: FlowRateMeasurementSystem.METRIC,
      ventilationFlowRateMeasurementSystem: FlowRateMeasurementSystem.METRIC,
      currency: {
        symbol: CurrencySymbol.POUNDS,
        multiplierPct: 100,
      },
    },
    units,
    value,
    Precision.FULL,
    unitContext,
  );
}

export function friendlyNumber(
  unitPrefs: UnitsParameters,
  units: Units,
  value: number,
  baseIncrements?: [number, number[]],
): [Units, number] {
  const [newUnits, newValue] = convertMeasurementSystem(
    unitPrefs,
    units,
    value,
    Precision.FULL,
  );
  if (!baseIncrements) {
    baseIncrements = friendlyBase(newUnits, Number(newValue));
  }
  const [base, increments] = baseIncrements;

  const sign = Math.sign(Number(newValue));
  const absValue = Math.abs(Number(newValue));

  const lowBase = Math.floor(Math.log10(absValue) / Math.log10(base));
  const low = Math.pow(base, lowBase);

  let roundValue = Infinity;

  for (const num of increments) {
    const test = low * num;
    if (Math.abs(test - absValue) < Math.abs(roundValue - absValue)) {
      roundValue = test;
    }
  }

  return [newUnits, sign * roundValue];
}

export function friendlyBase(units: Units, value: number): [number, number[]] {
  if (units === Units.Inches && value < 1) {
    return [2, [0.5, 1, 1.5, 2]];
  }
  return [10, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]];
}

export function friendlyNumberMetric(
  unitPrefs: UnitsParameters,
  units: Units,
  value: number,
  unitContext: UnitsContext,
  baseIncrements?: [number, number[]],
): [Units, number] {
  const [newUnits, newValue] = friendlyNumber(
    unitPrefs,
    units,
    value,
    baseIncrements,
  );

  const [metricUnits, metricValue] = convertMeasurementToMetric(
    newUnits,
    Number(newValue),
    unitContext,
  );

  return [metricUnits, Number(metricValue)];
}

//
export function chooseByUnitsMetric(
  unitPrefs: UnitsParameters,
  alternatives: Record<LiquidVolumeUnits, number>,
  unitContext: UnitsContext.NONE,
): [LiquidVolumeUnits.Liters, number];
export function chooseByUnitsMetric(
  unitPrefs: UnitsParameters,
  alternatives: Record<FlowRateUnits, number>,
  unitContext: UnitsContext.NONE,
): [FlowRateUnits.LitersPerSecond, number];
export function chooseByUnitsMetric(
  unitPrefs: UnitsParameters,
  alternatives: Record<FlowRateUnits, number>,
  unitContext: UnitsContext.NONE,
): [FlowRateUnits.MetersCubedPerHour, number];
export function chooseByUnitsMetric(
  unitPrefs: UnitsParameters,
  alternatives: Record<LengthUnits.Meters | LengthUnits.Feet, number>,
  unitContext: UnitsContext.NONE,
): [LengthUnits.Meters, number];
export function chooseByUnitsMetric(
  unitPrefs: UnitsParameters,
  alternatives: Record<LengthUnits.Millimeters | LengthUnits.Inches, number>,
  unitContext: UnitsContext.NONE,
): [LengthUnits.Millimeters, number];
export function chooseByUnitsMetric(
  unitPrefs: UnitsParameters,
  alternatives: Record<PressureUnits, number>,
  unitContext: UnitsContext.NONE,
): [PressureUnits.KiloPascals, number];
export function chooseByUnitsMetric(
  unitPrefs: UnitsParameters,
  alternatives: Record<PressureDropUnits, number>,
  unitContext: UnitsContext.NONE,
): [PressureDropUnits.KiloPascalsPerMeter, number];
export function chooseByUnitsMetric(
  unitPrefs: UnitsParameters,
  alternatives: Record<VelocityUnits, number>,
  unitContext: UnitsContext.NONE,
): [VelocityUnits.MetersPerSecond, number];
export function chooseByUnitsMetric(
  unitPrefs: UnitsParameters,
  alternatives: Record<AccelerationUnits, number>,
  unitContext: UnitsContext.NONE,
): [AccelerationUnits.MetersPerSecondSquared, number];
export function chooseByUnitsMetric(
  unitPrefs: UnitsParameters,
  alternatives: Record<TemperatureUnits, number>,
  unitContext: UnitsContext.NONE,
): [TemperatureUnits.Celsius, number];
export function chooseByUnitsMetric(
  unitPrefs: UnitsParameters,
  alternatives: Record<PowerUnits, number>,
  unitContext: UnitsContext,
): [PowerUnits.MegajoulesPerHour, number];
export function chooseByUnitsMetric(
  unitPrefs: UnitsParameters,
  alternatives: Record<CapacityRateUnits, number>,
  unitContext: UnitsContext.NONE,
): [CapacityRateUnits.LitersPerKiloWatts, number];
export function chooseByUnitsMetric(
  unitPrefs: UnitsParameters,
  alternatives: Partial<Record<Units, number>>,
  unitContext: UnitsContext,
): [Units, number] {
  const [targetUnit] = convertMeasurementSystem(
    unitPrefs,
    Object.keys(alternatives)[0] as Units,
    null,
    undefined,
    unitContext,
  );
  if (alternatives[targetUnit] != undefined) {
    const [metricUnit, metricValue] = convertMeasurementToMetric(
      targetUnit,
      alternatives[targetUnit]!,
      unitContext,
    );
    return [metricUnit, metricValue!];
  }

  const [metricUnit, metricValue] = convertMeasurementToMetric(
    targetUnit,
    alternatives[Object.keys(alternatives)[0] as Units]!,
    unitContext,
  );
  return [metricUnit, metricValue!];
}

// used for inches only really.
// If no fraction is suitable, it returns [value, 0, 0]
// If the denominator is 1 (whole number is best), it returns [value, 0, 1]
export function floatToBase2Fraction(
  value: number,
  eps = EPS,
  maxDenominator = 32,
): [number, number, number] {
  let bestNum = 1;
  let bestDen = 1;
  for (let den = 1; den <= maxDenominator; den *= 2) {
    const num = Math.round(value * den);
    if (Math.abs(value - num / den) < Math.abs(value - bestNum / bestDen)) {
      bestNum = num;
      bestDen = den;
    }
  }
  if (Math.abs(value - bestNum / bestDen) < eps) {
    return [Math.floor(bestNum / bestDen), bestNum % bestDen, bestDen];
  } else {
    return [value, 0, 0];
  }
}

export function displayValueForEdit(
  units: Units,
  value: number,
  precision: PrecisionString = Precision.DISPLAY,
): string {
  return displayValue(units, value, "/", false, precision);
}

export function displayValue(
  units: Units,
  value: number,
  fractionChar = "\u2044",
  appendUnits = true,
  precision: PrecisionString = Precision.DISPLAY,
): string {
  if (units === Units.Feet) {
    // break down into x' y" with fractional inches
    const feet = Math.floor(value + EPS);
    const inches = (value - feet) * 12;
    const [whole, num, den] = floatToBase2Fraction(inches);
    if (num === 0 && whole === 0) {
      return `${feet}'`;
    }
    if (num === 0 && den === 0) {
      return `${feet}' ${roundToPrecision(whole, precision)}"`;
    }
    if (num === 0) {
      return `${feet}' ${Math.round(whole)}"`;
    }
    return `${feet}'${whole ? " " + whole : ""} ${num}${fractionChar}${den}"`;
  } else if (units === Units.Inches) {
    const [whole, num, den] = floatToBase2Fraction(value);
    // If no fraction is suitable, it returns [value, 0, 0]
    if (num === 0 && den === 0) {
      return `${roundToPrecision(value, precision)}"`;
    }
    if (den === 1) {
      return `${Math.round(whole)}"`;
    }
    if (den === 0) {
      return `${roundToPrecision(num, precision)}"`;
    }
    if (whole === 0) {
      return `${num}/${den}"`;
    } else {
      return `${whole} ${num}/${den}"`;
    }
  }
  if (appendUnits) {
    return `${roundToPrecision(value, precision)} ${units}`;
  } else {
    return `${roundToPrecision(value, precision)}`;
  }
}

export function fromDisplayValue(units: Units, value: string): number | null {
  if (unitsInFeet(units) || unitsInInches(units)) {
    if (!isNaN(Number(value))) {
      return Number(value);
    }

    const multiplierFromFeet = unitsInInches(units) ? 12 : 1;
    value = value
      .replace('"', '" ')
      .replace("'", "' ")
      .trim()
      .replace(/ +/g, " ");

    // sigle bare number
    const singleNumber = /^([0-9]*(?:\.[0-9]+)?)(['"])?$/.exec(value);
    if (singleNumber) {
      if (singleNumber[2] === "'") {
        return parseFloat(singleNumber[1]) * multiplierFromFeet;
      } else if (singleNumber[2] === '"') {
        return (parseFloat(singleNumber[1]) / 12) * multiplierFromFeet;
      }
      return parseFloat(singleNumber[1]);
    }

    // single fraction
    const singleFraction = /^([0-9]+)[/]([0-9]+)(['"])?$/.exec(value);
    if (singleFraction) {
      const num = parseFloat(singleFraction[1]);
      const den = parseFloat(singleFraction[2]);
      if (singleFraction[3] === "'") {
        return (num / den) * multiplierFromFeet;
      } else if (singleFraction[3] === '"') {
        return (num / den / 12) * multiplierFromFeet;
      }
      return num / den;
    }

    // single-split number. eg 1' 2.5" or 1 2.5" or 1' 2.5 or 1 2.5
    const singleSplitNumber = /^([0-9]+)[']? ([0-9]*(?:\.[0-9]+)?)["]?$/.exec(
      value,
    );
    if (singleSplitNumber) {
      const feet = parseFloat(singleSplitNumber[1]);
      const inches = parseFloat(singleSplitNumber[2]);
      return (feet + inches / 12) * multiplierFromFeet;
    }

    // single split number with fraction. eg 1' 1/2" or 1 1/2" or 1' 1/2 or 1 1/2
    const singleSplitNumberWithFraction =
      /^([0-9]+)(['])? ([0-9]+)[/]([0-9]+)(["])?$/.exec(value);
    if (singleSplitNumberWithFraction) {
      const whole = parseFloat(singleSplitNumberWithFraction[1]);
      const wholeIsFeet =
        singleSplitNumberWithFraction[2] === "'" || unitsInFeet(units);
      const num = parseFloat(singleSplitNumberWithFraction[3]);
      const den = parseFloat(singleSplitNumberWithFraction[4]);
      if (wholeIsFeet) {
        return (whole + num / den / 12) * multiplierFromFeet;
      } else {
        return ((whole + num / den) / 12) * multiplierFromFeet;
      }
    }

    // double split number. eg 1' 2 3/4" or 1 2 3/4" or 1' 2 3/4 or 1 2 3/4
    const doubleSplitNumber =
      /^([0-9]+)[']? ([0-9]+) ([0-9]+)[/]([0-9]+)["]?$/.exec(value);
    if (doubleSplitNumber) {
      const feet = parseFloat(doubleSplitNumber[1]);
      const whole = parseFloat(doubleSplitNumber[2]);
      const num = parseFloat(doubleSplitNumber[3]);
      const den = parseFloat(doubleSplitNumber[4]);
      return (feet + whole / 12 + num / den / 12) * multiplierFromFeet;
    }

    return null;
  }
  return parseFloat(value);
}

export function mm2IN(mm: number) {
  return mm / 25.4;
}

export function in2MM(inches: number) {
  return inches * 25.4;
}

export function m2FT(m: number) {
  return mm2IN(m * 1000) / 12;
}

export function m2mm(m: number) {
  return m * 1000;
}

export function mm2FT(m: number) {
  return mm2IN(m) / 12;
}

export function ft2mm(ft: number) {
  return in2MM(ft * 12);
}

export function ft2M(ft: number) {
  return in2MM(ft * 12) / 1000;
}

export function kpa2PSI(kpa: number) {
  return kpa / 6.894757293168361;
}

export function psi2KPA(psi: number) {
  return psi * 6.894757293168361;
}

export function kpa2bar(kpa: number) {
  return kpa / 100;
}

export function barToMBar(bar: number) {
  return bar * 1000;
}

export function mbarToBar(bar: number) {
  return bar / 1000;
}

export function paToKpa(pa: number) {
  return pa / 1000;
}

export function bar2kpa(bar: number) {
  return bar * 100;
}

export function c2F(celsius: number) {
  return (celsius * 9) / 5 + 32;
}

export function f2C(fahrenheit: number) {
  return ((fahrenheit - 32) * 5) / 9;
}

export function feetToDisplay(feet: number) {
  return feet * 12;
}

export function cfm2ls(cfm: number) {
  return cfm * (28.3168 / 60);
}

export function m2tof2(m2: number) {
  return m2 * 10.76364864;
}

export function ach2ls(ach: number, volumeM3: number) {
  return (ach * volumeM3 * 1000) / 3600;
}

export function ls2ach(ls: number, volumeM3: number) {
  return (ls * 3600) / (volumeM3 * 1000);
}

export function ls2m3h(ls: number) {
  return ls * 3.6;
}

export function rad2deg(rad: number) {
  return (rad * 180) / Math.PI;
}

export function deg2rad(deg: number) {
  return (deg * Math.PI) / 180;
}

const validConverts: { [key: number]: string } = {
  0.125: "⅛",
  0.25: "¼",
  0.375: "⅜",
  0.5: "½",
  0.75: "¾",
  1: "1",
  1.25: "1¼",
  1.5: "1½",
  2: "2",
  2.5: "2½",
  3: "3",
  3.5: "3½",
  4: "4",
  4.5: "4½",
  5: "5",
  5.5: "5½",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  10: "10",
  11: "11",
  12: "12",
  13: "13",
  14: "14",
  15: "15",
  16: "16",
  17: "17",
  18: "18",
  20: "20",
  24: "24",
};

export const validConvertsReverse: { [key: string]: number } = {
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7,
  "8": 8,
  "9": 9,
  "10": 10,
  "11": 11,
  "12": 12,
  "13": 13,
  "14": 14,
  "15": 15,
  "16": 16,
  "17": 17,
  "18": 18,
  "¼": 0.25,
  "½": 0.5,
  "¾": 0.75,
  "1¼": 1.25,
  "1½": 1.5,
  "2½": 2.5,
  "3½": 3.5,
  "4½": 4.5,
  "5½": 5.5,
};

export function closestImperialPipe(valueMM: number) {
  let closestDist = Infinity;
  let value: string | number | null = mm2IN(valueMM);

  const inches = mm2IN(valueMM);
  if (inches > 24) {
    return Math.round(inches);
  }
  for (const [num, val] of Object.entries(validConverts)) {
    if (Math.abs(Number(num) - inches) < closestDist) {
      closestDist = Math.abs(Number(num) - inches);
      value = val;
    }
  }

  return value;
}

export function convertPipeDiameterFromMetric(
  unitPrefs: UnitsParameters,
  valueRawMM: number | string | null,
): [Units, number | string | null] {
  const valueMM = Number(valueRawMM);
  switch (unitPrefs.lengthMeasurementSystem) {
    case MeasurementSystem.METRIC:
      return [Units.Millimeters, valueRawMM];
    case MeasurementSystem.IMPERIAL:
      if (valueMM === null || valueRawMM === null) {
        return [Units.Inches, valueMM];
      }
      if (typeof valueRawMM === "string" && valueRawMM.includes("+")) {
        // parse [Ax]B+C syntax
        let [b, c] = valueRawMM.split("+");
        let prefix = "";
        if (b.includes("x")) {
          prefix = b.split("x")[0] + " × ";
          b = b.split("x")[1];
        }
        return [
          Units.None,
          prefix +
            closestImperialPipe(Number(b)) +
            "″ + " +
            closestImperialPipe(Number(c)) +
            "″",
        ];
      }
      return [Units.None, closestImperialPipe(valueMM) + "″"];
  }
  assertUnreachable(unitPrefs.lengthMeasurementSystem);
}

export function unitsInInches(unit: Units) {
  switch (unit) {
    case Units.Inches:
    case Units.InchesShort:
    case Units.InchPerHour:
      return true;
    case Units.LitersPerSecond:
    case Units.None:
    case Units.Micron:
    case Units.Millimeters:
    case Units.Meters:
    case Units.Seconds:
    case Units.KiloPascals:
    case Units.GasKiloPascals:
    case Units.MetersPerSecond:
    case Units.MetersPerSecondSquared:
    case Units.Celsius:
    case Units.KiloWatts:
    case Units.Watts:
    case Units.Kv:
    case Units.Liters:
    case Units.MetersCubedPerHour:
    case Units.MegajoulesPerHour:
    case Units.KiloPascalsPerMeter:
    case Units.PascalsPerMeter:
    case Units.GallonsPerMinute:
    case Units.USGallonsPerMinute:
    case Units.Feet:
    case Units.FeetPerSecondSquared:
    case Units.Psi:
    case Units.Bar:
    case Units.Mbar:
    case Units.Pascals:
    case Units.FeetPerSecond:
    case Units.Fahrenheit:
    case Units.Gallons:
    case Units.USGallons:
    case Units.BtuPerHour:
    case Units.PsiPer100Feet:
    case Units.PipeDiameterMM:
    case Units.CubicFeetPerHour:
    case Units.CubicFeetPerMinute:
    case Units.Percent:
    case Units.Minutes:
    case Units.LitersPerKiloWatts:
    case Units.GallonsPerKiloWatts:
    case Units.USGallonsPerKiloWatts:
    case Units.MmPerHour:
    case Units.SquareFeet:
    case Units.SquareMeters:
    case Units.CubicFeet:
    case Units.CubicMeters:
    case Units.KiloWattHour:
    case Units.WattsPerSquareMeter:
    case Units.BtuHourSquareFoot:
    case Units.WattsPerSquareMeterKelvin:
    case Units.BtuHourSquareFootFahrenheit:
    case Units.LitersPerMinute:
    case Units.Decibel:
    case Units.Degrees:
      return false;
  }
  assertUnreachable(unit);
}

export function unitsInFeet(unit: Units) {
  switch (unit) {
    case Units.Feet:
    case Units.FeetPerSecondSquared:
    case Units.FeetPerSecond:
    case Units.SquareFeet:
    case Units.CubicFeet:
      return true;
    case Units.LitersPerSecond:
    case Units.None:
    case Units.Micron:
    case Units.Millimeters:
    case Units.Meters:
    case Units.Seconds:
    case Units.KiloPascals:
    case Units.GasKiloPascals:
    case Units.MetersPerSecond:
    case Units.MetersPerSecondSquared:
    case Units.Celsius:
    case Units.KiloWatts:
    case Units.Watts:
    case Units.Kv:
    case Units.Liters:
    case Units.MetersCubedPerHour:
    case Units.MegajoulesPerHour:
    case Units.KiloPascalsPerMeter:
    case Units.PascalsPerMeter:
    case Units.GallonsPerMinute:
    case Units.USGallonsPerMinute:
    case Units.Inches:
    case Units.InchesShort:
    case Units.Fahrenheit:
    case Units.Gallons:
    case Units.USGallons:
    case Units.BtuPerHour:
    case Units.PsiPer100Feet:
    case Units.PipeDiameterMM:
    case Units.CubicFeetPerHour:
    case Units.CubicFeetPerMinute:
    case Units.Percent:
    case Units.Minutes:
    case Units.Psi:
    case Units.Bar:
    case Units.Mbar:
    case Units.Pascals:
    case Units.LitersPerKiloWatts:
    case Units.GallonsPerKiloWatts:
    case Units.USGallonsPerKiloWatts:
    case Units.MmPerHour:
    case Units.InchPerHour:
    case Units.SquareMeters:
    case Units.CubicMeters:
    case Units.WattsPerSquareMeter:
    case Units.BtuHourSquareFoot:
    case Units.WattsPerSquareMeterKelvin:
    case Units.BtuHourSquareFootFahrenheit:
    case Units.KiloWattHour:
    case Units.LitersPerMinute:
    case Units.Decibel:
    case Units.Degrees:
      return false;
  }
  assertUnreachable(unit);
}
