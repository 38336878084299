import { Module } from "vuex";
import { actions } from "../../../src/store/catalog/actions";
import { getters } from "../../../src/store/catalog/getters";
import { mutations } from "../../../src/store/catalog/mutations";
import CatalogState, { defaultCatalog } from "../../../src/store/catalog/types";
import { RootState } from "../../../src/store/types";

export const state: CatalogState = {
  defaultCatalog: defaultCatalog,
  upgradeCount: 0,
  loaded: false,
};

const namespaced: boolean = true;

export const catalog: Module<CatalogState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
