var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',[_c('b-navbar',{attrs:{"type":"light"}},[_c('b-navbar-nav',[_c('b-navbar-brand',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({ title: 'Home Page' }),expression:"{ title: 'Home Page' }",modifiers:{"hover":true}}],attrs:{"href":"/"}},[_c('img',{staticClass:"d-inline-block mr-0 align-top",attrs:{"src":require("@/assets/h2x.png"),"height":"30"}})])],1),(_vm.isAdmin)?[_c('b-navbar-nav',[_c('b-nav-item',{attrs:{"data-cy":"projects-button","to":{ name: 'home' },"active-class":"active","exact":""}},[_vm._v("D-Projects")]),_c('b-nav-item',{attrs:{"to":{
            name: 'manufacturer/dashboard',
            query: { organizationId: _vm.$route.query.organizationId },
          },"active-class":"active","exact":""}},[_vm._v("M-Dashboard")]),_c('b-nav-item',{attrs:{"to":{
            name: 'manufacturer/projects',
            query: { organizationId: _vm.$route.query.organizationId },
          },"active-class":"active","exact":""}},[_vm._v("M-Projects")])],1)]:(_vm.isBuildingServices)?[_c('b-navbar-nav',[_c('b-nav-item',{attrs:{"data-cy":"projects-button","to":{ name: 'home' },"active-class":"active","exact":""}},[_vm._v("Projects")])],1)]:(_vm.isManufacturer)?[_c('b-navbar-nav',[_c('b-nav-item',{attrs:{"to":{ name: 'manufacturer/dashboard' },"active-class":"active","exact":""}},[_vm._v("Dashboard")]),_c('b-nav-item',{attrs:{"to":{ name: 'manufacturer/projects' },"active-class":"active","exact":""}},[_vm._v("Projects")])],1)]:_vm._e(),_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('ProfileMenuItem',{attrs:{"disabled":false}})],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }