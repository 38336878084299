import { Units } from "../../../../lib/measurements";
import { CoreContext } from "../../../calculations/types";
import { FieldType, PropertyField } from "../property-field";
import { DuctManifoldPlantEntity } from "./plant-entity";
import { PlantFieldHandlers } from "./types";

export function makeDuctManifoldPlantIO(
  context: CoreContext,
  filled: DuctManifoldPlantEntity,
  handlers?: PlantFieldHandlers,
): PropertyField[] {
  const { drawing } = context;
  const availableSystems = drawing.metadata.flowSystemUidsInOrder.map(
    (uid) => drawing.metadata.flowSystems[uid],
  );
  const currentSystem = drawing.metadata.flowSystems[filled.inletSystemUid];
  const sameRoleSystems = availableSystems.filter(
    (system) => system.role === currentSystem.role,
  );

  const res: PropertyField[] = [
    {
      property: `inletSystemUid`,
      title: "Flow System",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.FlowSystemChoice,
      params: { systems: sameRoleSystems },
      multiFieldId: `inletSystemUid`,
    },
    {
      property: `inletHeightAboveFloorM`,
      title: "Height Above Floor",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: null, max: null },
      multiFieldId: `inletHeightAboveFloorM`,
      units: Units.Meters,
    },
  ];

  for (const [index, _outlet] of filled.plant.outlets.entries()) {
    res.push(
      {
        property: "plant.separator",
        title: "",
        hasDefault: false,
        isCalculated: false,
        type: FieldType.Divider,
        multiFieldId: "plant-separator",
      },
      {
        property: "plant.deleteOutlet",
        title: `Outlet ${index + 1}`,
        type: FieldType.Custom,
        multiFieldId: null,
        hasDefault: false,
        isCalculated: false,
        slot: true,
        params: {
          handler: async () => {
            handlers?.removeDuctManifoldOutletHandler?.(
              context,
              filled.uid,
              index,
            );
          },
        },
      },
      {
        property: `plant.outlets.${index}.pressureLoss.pressureLossKPA`,
        title: "Pressure Loss",
        hasDefault: true,
        isCalculated: false,
        type: FieldType.Number,
        params: { min: 0, max: null },
        multiFieldId: "plant-pressureLoss-pressureLossKPA",
        units: Units.KiloPascals,
      },
    );
  }

  res.push(
    {
      property: "plant.separator",
      title: "",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Divider,
      multiFieldId: "plant-separator",
    },
    {
      type: FieldType.Button,
      property: "addPlantOutlet",
      title: "+ Add a New Outlet",
      hasDefault: false,
      isCalculated: false,
      multiFieldId: null,
      size: "sm",
      pill: true,
      variant: "outline-success",
      params: {
        handler: async () =>
          handlers?.addNewDuctManifoldOutletHandler?.(context, filled.uid),
      },
    },
  );

  return res;
}
