
import { defineComponent } from "vue";
import { defaultFeatureAccess } from "../../../common/src/models/FeatureAccess";
import {
  Organization as IOrganization,
  OrganizationSubscriptionStatus,
  OrganizationType,
  defaultPasswordRequirements,
} from "../../../common/src/models/Organization";
import { User } from "../../../common/src/models/User";
import { createOrganization } from "../api/organizations";
import MainNavBar from "../components/MainNavBar.vue";

export default defineComponent({
  components: { MainNavBar },
  data() {
    const org: IOrganization = {
      id: "",
      name: "",
      address: "",
      phoneNumber: "",
      type: OrganizationType.BUILDING_SERVICES,
      maxAccountsNum: null,
      subscriptionStatus: OrganizationSubscriptionStatus.FREE,
      maxPasswordAttemptsBeforeLock: null,
      passwordRequirements: defaultPasswordRequirements,
      featureAccess: defaultFeatureAccess,
      enforceMFA: false,
    } as any;

    return {
      org,
    };
  },
  computed: {
    types() {
      return OrganizationType;
    },
    profile(): User {
      return this.$store.getters["profile/profile"];
    },
    organizationSubscriptionStatus() {
      return OrganizationSubscriptionStatus;
    },
  },
  methods: {
    create() {
      createOrganization(
        this.org.id,
        this.org.name,
        this.org.type,
        this.org.maxAccountsNum || null,
        this.org.subscriptionStatus,
      ).then((res) => {
        if (res.success) {
          this.$router.push("/organizations");
        } else {
          this.$bvToast.toast(res.message, {
            title: "Error creating organization",
            variant: "danger",
          });
        }
      });
    },
  },
});
