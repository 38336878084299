import CoreBaseBackedObject from "../../../../../common/src/api/coreObjects/lib/coreBaseBackedObject";
import { DrawableEntityConcrete } from "../../../../../common/src/api/document/entities/concrete-entity";
import {
  DrawableObjectConcrete,
  HoverableObjectConcrete,
} from "../../objects/concrete-object";
import { EntityPopupContent } from "../entity-popups/types";
import { IDrawableObject } from "./core2drawable";

export type HoverSiblingResult =
  | {
      object: HoverableObjectConcrete;
      cascade: boolean;
    }
  | {
      object: DrawableObjectConcrete;
      cascade: false;
    };

export function HoverableObject<
  /// I extends DrawableEntityConcrete,
  T extends abstract new (
    ...args: any[]
  ) => CoreBaseBackedObject<DrawableEntityConcrete> & IDrawableObject,
>(constructor: T) {
  abstract class Hoverable extends constructor {
    isHoverable: true = true;
    isHovering: boolean = false;

    //     onMouseMove(event: MouseEvent, context: CanvasContext): MouseMoveResult {
    //       //       if (context.document.uiState.drawingMode === DrawingMode.Calculations) {
    //       //         return UNHANDLED;
    //       //       }
    //       //
    //       //
    //       const wc = context.viewPort.toWorldCoord({
    //         x: event.clientX,
    //         y: event.clientY,
    //       });
    //       if (this.inBounds(this.toObjectCoord(wc))) {
    //         this.isHovering = true;
    //       } else {
    //         this.isHovering = false;
    //       }
    //
    //       const result2: MouseMoveResult = super.onMouseMove(event, context);
    //       if (result2.handled) {
    //         return result2;
    //       }
    //
    //       return UNHANDLED;
    //     }

    abstract getHoverSiblings(): HoverSiblingResult[];

    abstract getPopupContent(): EntityPopupContent[] | null;

    get isInteractable(): boolean {
      return true;
    }
  }

  return Hoverable;
}
