// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from "../flatbuffers/flatbuffers";

export class CanvasMouseMovementRecord
  implements flatbuffers.IUnpackableObject<CanvasMouseMovementRecordT>
{
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): CanvasMouseMovementRecord {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsCanvasMouseMovementRecord(
    bb: flatbuffers.ByteBuffer,
    obj?: CanvasMouseMovementRecord,
  ): CanvasMouseMovementRecord {
    return (obj || new CanvasMouseMovementRecord()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsCanvasMouseMovementRecord(
    bb: flatbuffers.ByteBuffer,
    obj?: CanvasMouseMovementRecord,
  ): CanvasMouseMovementRecord {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new CanvasMouseMovementRecord()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  timestamp(): bigint {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.readUint64(this.bb_pos + offset) : BigInt("0");
  }

  userName(): string | null;
  userName(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  userName(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? this.bb!.__string(this.bb_pos + offset, optionalEncoding)
      : null;
  }

  documentId(): number {
    const offset = this.bb!.__offset(this.bb_pos, 8);
    return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
  }

  previousOrderIndex(): number {
    const offset = this.bb!.__offset(this.bb_pos, 10);
    return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
  }

  worldX(): number {
    const offset = this.bb!.__offset(this.bb_pos, 12);
    return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
  }

  worldY(): number {
    const offset = this.bb!.__offset(this.bb_pos, 14);
    return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
  }

  id(): number | null {
    const offset = this.bb!.__offset(this.bb_pos, 16);
    return offset ? this.bb!.readUint32(this.bb_pos + offset) : null;
  }

  static startCanvasMouseMovementRecord(builder: flatbuffers.Builder) {
    builder.startObject(7);
  }

  static addTimestamp(builder: flatbuffers.Builder, timestamp: bigint) {
    builder.addFieldInt64(0, timestamp, BigInt("0"));
  }

  static addUserName(
    builder: flatbuffers.Builder,
    userNameOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(1, userNameOffset, 0);
  }

  static addDocumentId(builder: flatbuffers.Builder, documentId: number) {
    builder.addFieldInt32(2, documentId, 0);
  }

  static addPreviousOrderIndex(
    builder: flatbuffers.Builder,
    previousOrderIndex: number,
  ) {
    builder.addFieldInt32(3, previousOrderIndex, 0);
  }

  static addWorldX(builder: flatbuffers.Builder, worldX: number) {
    builder.addFieldInt32(4, worldX, 0);
  }

  static addWorldY(builder: flatbuffers.Builder, worldY: number) {
    builder.addFieldInt32(5, worldY, 0);
  }

  static addId(builder: flatbuffers.Builder, id: number) {
    builder.addFieldInt32(6, id, null);
  }

  static endCanvasMouseMovementRecord(
    builder: flatbuffers.Builder,
  ): flatbuffers.Offset {
    const offset = builder.endObject();
    builder.requiredField(offset, 6); // user_name
    return offset;
  }

  static createCanvasMouseMovementRecord(
    builder: flatbuffers.Builder,
    timestamp: bigint,
    userNameOffset: flatbuffers.Offset,
    documentId: number,
    previousOrderIndex: number,
    worldX: number,
    worldY: number,
    id: number | null,
  ): flatbuffers.Offset {
    CanvasMouseMovementRecord.startCanvasMouseMovementRecord(builder);
    CanvasMouseMovementRecord.addTimestamp(builder, timestamp);
    CanvasMouseMovementRecord.addUserName(builder, userNameOffset);
    CanvasMouseMovementRecord.addDocumentId(builder, documentId);
    CanvasMouseMovementRecord.addPreviousOrderIndex(
      builder,
      previousOrderIndex,
    );
    CanvasMouseMovementRecord.addWorldX(builder, worldX);
    CanvasMouseMovementRecord.addWorldY(builder, worldY);
    if (id !== null) CanvasMouseMovementRecord.addId(builder, id);
    return CanvasMouseMovementRecord.endCanvasMouseMovementRecord(builder);
  }

  unpack(): CanvasMouseMovementRecordT {
    return new CanvasMouseMovementRecordT(
      this.timestamp(),
      this.userName(),
      this.documentId(),
      this.previousOrderIndex(),
      this.worldX(),
      this.worldY(),
      this.id(),
    );
  }

  unpackTo(_o: CanvasMouseMovementRecordT): void {
    _o.timestamp = this.timestamp();
    _o.userName = this.userName();
    _o.documentId = this.documentId();
    _o.previousOrderIndex = this.previousOrderIndex();
    _o.worldX = this.worldX();
    _o.worldY = this.worldY();
    _o.id = this.id();
  }
}

export class CanvasMouseMovementRecordT
  implements flatbuffers.IGeneratedObject
{
  _type = "CanvasMouseMovementRecordT" as const;
  constructor(
    public timestamp: bigint = BigInt("0"),
    public userName: string | Uint8Array | null = null,
    public documentId: number = 0,
    public previousOrderIndex: number = 0,
    public worldX: number = 0,
    public worldY: number = 0,
    public id: number | null = null,
  ) {}

  pack(builder: flatbuffers.Builder): flatbuffers.Offset {
    const userName =
      this.userName !== null ? builder.createString(this.userName!) : 0;

    return CanvasMouseMovementRecord.createCanvasMouseMovementRecord(
      builder,
      this.timestamp,
      userName,
      this.documentId,
      this.previousOrderIndex,
      this.worldX,
      this.worldY,
      this.id,
    );
  }
}
