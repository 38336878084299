import {
  convertPipeDiameterFromMetric,
  MeasurementSystem,
  Units,
  UnitsContext,
} from "../../../lib/measurements";
import { assertUnreachable } from "../../../lib/utils";
import { CoreContext, PipeConfiguration } from "../../calculations/types";
import {
  getDrainageUnitName,
  getPsdUnitName,
  PsdProfile,
} from "../../calculations/utils";
import { isDrainage, isGas } from "../../config";
import { SupportedLocales } from "../../locale";
import { I18N } from "../../locale/values";
import { UnitsParameters } from "../drawing";
import ConduitEntity, {
  DuctConduitEntity,
  fillDefaultConduitFields,
  isDuctEntity,
  isPipeEntity,
  PipeConduitEntity,
} from "../entities/conduit-entity";
import { CalculationField, FieldCategory } from "./calculation-field";
import {
  CalcFieldSelector,
  Calculation,
  CalculationType,
  LiveCalculation,
  NameCalculation,
  PsdCalculation,
} from "./types";
import { getFlowSystemLayouts } from "./utils";

export enum NoFlowAvailableReason {
  NO_SOURCE = "NO_SOURCE",
  NO_LOADS_CONNECTED = "NO_LOADS_CONNECTED",
  TOO_MANY_FLOW_SOURCES = "TOO_MANY_FLOW_SOURCES",
  UNUSUAL_CONFIGURATION = "UNUSUAL_CONFIGURATION",
  NO_ISOLATION_VALVES_ON_MAIN = "NO_ISOLATION_VALVES_ON_MAIN",
  LOADING_UNITS_OUT_OF_BOUNDS = "LOADING_UNITS_OUT_OF_BOUNDS",
  NO_SUITABLE_PIPE_SIZE = "NO_SUITABLE_PIPE_SIZE",
  INVALID_RETURN_NETWORK = "INVALID_RETURN_NETWORK",
  GAS_SUPPLY_PRESSURE_TOO_LOW = "GAS_SUPPLY_PRESSURE_TOO_LOW",
  NO_FLOW_TO_OUTLET_OR_RETURN = "NO_FLOW_TO_OUTLET_OR_RETURN",
  FLOW_DIRECTION_IS_REVERSABLE = "FLOW_DIRECTION_IS_REVERSABLE",
  NONE = "",
}

export interface ConduitCalculationBase extends Calculation, NameCalculation {
  type: CalculationType.PipeCalculation;
  heightM: number | null;
  lengthM: number | null;
  flowFrom: string | null;

  debug?: {
    showArrows?: boolean;
    highlight?: string;
  };
}

export interface PipeCalculation
  extends ConduitCalculationBase,
    PsdCalculation {
  totalPeakFlowRateLS: number | null;
  PSDFlowRateLS: number | null;

  noFlowAvailableReason: NoFlowAvailableReason | null;

  rawPSDFlowRateLS: number | null;
  rawReturnFlowRateLS: number | null;
  returnFlowRateLS: number | null;
  optimalInnerPipeDiameterMM: number | null;
  realNominalPipeDiameterMM: number | null;
  realInternalDiameterMM: number | null;
  realOutsideDiameterMM: number | null;
  pressureDropKPA: number | null; // includes termini pressure drops
  pressureDropKPAPerMeter: number | null;
  pressureDropExcludingTerminiKPA: number | null;
  pressureDropExcludingTerminiKPAPerMeter: number | null;
  psdProfile: PsdProfile | null;
  configuration: PipeConfiguration | null;
  materialName: string | null;

  velocityRealMS: number | null;

  temperatureRange: string | null;
  gradePCT: number | null;

  gasMJH: number | null;

  // Vent specific calcs;
  // An invisible, transactional value during calculations specifically for stacks.
  stackDedicatedVentSize: number | null;
  ventRoot: string | null;
  ventTooFarDist: boolean | null;
  ventTooFarWC: boolean | null;
  fallM: number | null;

  // For render indexCircult
  isIndexCircult: boolean | null;
  isIndexNodePath: boolean | null;

  // Heat Load
  totalKW: number | null;
  diversifiedKW: number | null;
  closedKW: number | null;
  domesticKW: number | null;
  diversifiedTotalKW: number | null;
  diversifiedClosedKW: number | null;
  diversifiedDomesticKW: number | null;

  // Fire water
  fireFlowRateLS: number | null;
}

export interface DuctCalculation
  extends ConduitCalculationBase,
    PsdCalculation {
  diameterMM: number | null;
  widthMM: number | null;
  heightMM: number | null;

  crossSectionAreaM2: number | null;

  psdProfile: PsdProfile | null;
  materialName: string | null;

  rawFlowRateLS: number | null;
  totalPeakFlowRateLS: number | null;
  velocityRealMS: number | null;

  pressureDropKPA: number | null; // includes termini pressure drops
  pressureDropKPAPerMeter: number | null;
  pressureDropExcludingTerminiKPA: number | null;
  pressureDropExcludingTerminiKPAPerMeter: number | null;

  isIndexNodePath: boolean | null;
}

type ConduitCalculation = PipeCalculation | DuctCalculation;

export default ConduitCalculation;

export const StickyConduitCalculationFields: CalcFieldSelector<ConduitCalculation> =
  {
    configuration: [PipeConfiguration.RETURN_IN, PipeConfiguration.RETURN_OUT],
    realNominalPipeDiameterMM: true,
    realInternalDiameterMM: true,
    pressureDropKPA: true,
    totalPeakFlowRateLS: true,
  };

export interface ConduitLiveCalculationBase extends LiveCalculation {
  flowFrom: string | null;
  cycle: number | null;
}

export interface PipeLiveCalculation extends ConduitLiveCalculationBase {
  unbalanced: boolean | null;
  configuration: PipeConfiguration | null;
  overlapped: boolean | null;
}

export interface DuctLiveCalculation extends ConduitLiveCalculationBase {
  diameterMM: number | null;
  widthMM: number | null;
  heightMM: number | null;
  overlapped: boolean | null;
}

export type ConduitLiveCalculation = PipeLiveCalculation | DuctLiveCalculation;

export const FastLivePipeCalculationFields: CalcFieldSelector<ConduitLiveCalculation> =
  {
    connected: true,
    cycle: true,
  };

export function makeConduitCalculationFields(
  context: CoreContext,
  entity: ConduitEntity,
): CalculationField[] {
  const { drawing } = context;
  const result: CalculationField[] = [];

  const filled = fillDefaultConduitFields(context, entity);

  const { layouts, layoutsExcept, onlyLayouts } = getFlowSystemLayouts(
    drawing.metadata.flowSystems[entity.systemUid],
  );

  result.push({
    property: "reference",
    title: "Reference",
    short: "",
    shortTitle: "",
    units: Units.None,
    category: FieldCategory.EntityName,
    systemUid: entity.systemUid,
    layouts,
    defaultEnabled: false,
    hideOnHover: true,
  });

  result.push({
    property: "heightM",
    title: "Height Above Floor",
    shortTitle: "H",
    short: "",
    units: Units.Meters,
    category: FieldCategory.Location,
    systemUid: entity.systemUid,
    layouts: layoutsExcept("drainage"),
  });

  if (isPipeEntity(filled)) {
    result.push(...makePipeCalculationFields(context, filled));
  }

  if (isDuctEntity(filled)) {
    result.push(...makeDuctCalculationFields(context, filled));
  }

  return result;
}

function makeDuctCalculationFields(
  context: CoreContext,
  filled: DuctConduitEntity,
) {
  const { catalog, globalStore, drawing, locale } = context;

  const result: CalculationField[] = [];

  const { layouts, layoutsExcept, onlyLayouts } = getFlowSystemLayouts(
    drawing.metadata.flowSystems[filled.systemUid],
  );

  result.push({
    property: "totalPeakFlowRateLS",
    title: "Peak Flow Rate",
    short: "",
    shortTitle: "",
    units: Units.LitersPerSecond,
    unitContext: UnitsContext.VENTILATION,
    category: FieldCategory.FlowRate,
    systemUid: filled.systemUid,
    defaultEnabled: false,
    layouts,
  });

  result.push({
    property: "shape",
    title: "Shape",
    short: "s",
    shortTitle: "Shape",
    units: Units.None,
    category: FieldCategory.Size,
    systemUid: filled.systemUid,
    layouts,
  });
  if (filled.conduit.shape === "circular") {
    result.push({
      property: "diameterMM",
      title: "Internal Diameter (ID)",
      short: "",
      shortTitle: "ID",
      units: Units.PipeDiameterMM,
      category: FieldCategory.Size,
      systemUid: filled.systemUid,
      defaultEnabled: true,
      layouts,
    });
  }
  if (filled.conduit.shape === "rectangular") {
    result.push(
      {
        property: "widthMM",
        title: "Internal Width (IW)",
        short: "",
        shortTitle: "IW",
        units: Units.PipeDiameterMM,
        category: FieldCategory.Size,
        systemUid: filled.systemUid,
        defaultEnabled: true,
        layouts,
      },
      {
        property: "heightMM",
        title: "Internal Height (IH)",
        short: "",
        shortTitle: "IH",
        units: Units.PipeDiameterMM,
        category: FieldCategory.Size,
        systemUid: filled.systemUid,
        defaultEnabled: true,
        layouts,
      },
    );
  }
  result.push(
    {
      property: "crossSectionAreaM2",
      title: "Cross Section Area",
      short: "",
      shortTitle: "CS",
      units: Units.SquareMeters,
      category: FieldCategory.Size,
      systemUid: filled.systemUid,
      layouts,
    },
    {
      property: "materialName",
      title: isPipeEntity(filled) ? "Pipe Material" : "Duct Material",
      short: "",
      shortTitle: "Material",
      units: Units.None,
      category: FieldCategory.Material,
      systemUid: filled.systemUid,
      layouts,
    },
  );

  result.push(
    {
      property: "lengthM",
      title: "Length",
      shortTitle: "L",
      short: "",
      units: Units.Meters,
      category: FieldCategory.Length,
      systemUid: filled.systemUid,
      layouts,
    },
    {
      property: "velocityRealMS",
      title: "Velocity",
      short: "",
      units: Units.MetersPerSecond,
      category: FieldCategory.Velocity,
      systemUid: filled.systemUid,

      layouts,
    },
  );

  result.push({
    property: "pressureDropExcludingTerminiKPA",
    title: "Pressure Drop (PD)",
    short: "",
    shortTitle: "PD",
    units: Units.KiloPascals,
    unitContext: UnitsContext.VENTILATION,
    category: FieldCategory.Pressure,
    systemUid: filled.systemUid,
    layouts: layoutsExcept("drainage"),
  });

  result.push({
    property: "isIndexNodePath",
    title: "Show Index Node Path",
    short: "",
    units: Units.None,
    category: FieldCategory.HighLightInfo,
    systemUid: filled.systemUid,
    defaultEnabled: false,
    layouts,
    format: (v: boolean | null) => (v === null ? "false" : "true"),
  });

  return result;
}

function makePipeCalculationFields(
  context: CoreContext,
  entity: PipeConduitEntity,
) {
  const { catalog, globalStore, drawing, locale } = context;

  const psdUnit = getPsdUnitName(
    drawing.metadata.calculationParams.psdMethod,
    locale,
  );
  const drainageUnits = getDrainageUnitName(
    drawing.metadata.calculationParams.drainageMethod,
    drawing.metadata.units.volumeMeasurementSystem,
  );

  const pipeIsGas =
    catalog && isGas(drawing.metadata.flowSystems[entity.systemUid]);
  const pipeIsDrainage = isDrainage(
    drawing.metadata.flowSystems[entity.systemUid],
  );

  const pCalc = globalStore.getOrCreateCalculation(entity);

  const { layouts, layoutsExcept, onlyLayouts } = getFlowSystemLayouts(
    drawing.metadata.flowSystems[entity.systemUid],
  );

  const result: CalculationField[] = [];
  if (!pipeIsGas) {
    if (pCalc.totalPeakFlowRateLS) {
      result.push({
        property: "PSDFlowRateLS",
        title: "Peak Flow Rate",
        short: "",
        shortTitle: "",
        units: Units.LitersPerSecond,
        category: FieldCategory.FlowRate,
        systemUid: entity.systemUid,
        defaultEnabled: false,
        layouts,
      });
    }
  }

  if (isPipeReturn(pCalc) && !pipeIsGas) {
    result.push({
      property: "returnFlowRateLS",
      title: "Recirculation Flow Rate",
      short: "",
      shortTitle: "Recirc.",
      units: Units.LitersPerSecond,
      category: FieldCategory.FlowRate,
      systemUid: entity.systemUid,
      defaultEnabled: false,
      layouts: layoutsExcept("drainage"),
      format: (v: number | null) => (v === null ? "??" : v.toFixed(2)),
    });
    result.push({
      property: "isIndexCircult",
      title: "Show Index Circult",
      short: "",
      units: Units.None,
      category: FieldCategory.HighLightInfo,
      systemUid: entity.systemUid,
      defaultEnabled: false,
      layouts,
      format: (v: boolean | null) => (v === null ? "false" : "true"),
    });
  }

  result.push({
    property: "isIndexNodePath",
    title: "Show Index Node Path",
    short: "",
    units: Units.None,
    category: FieldCategory.HighLightInfo,
    systemUid: entity.systemUid,
    defaultEnabled: false,
    layouts,
    format: (v: boolean | null) => (v === null ? "false" : "true"),
  });

  result.push(
    {
      property: "realNominalPipeDiameterMM",
      title: "Pipe Diameter (\u00f8)",
      shortTitle: "",
      short: "\u00f8",
      hideUnits: true,
      units: Units.Millimeters,
      category: FieldCategory.Size,
      bold: true,
      systemUid: entity.systemUid,
      defaultEnabled: true,
      convert: (
        unitPrefs: UnitsParameters,
        unit: Units,
        value: number | null,
      ) => {
        if (unit !== Units.Millimeters) {
          throw new Error("wat");
        }

        // we need cool names.
        switch (unitPrefs.lengthMeasurementSystem) {
          case MeasurementSystem.METRIC:
            return [Units.None, value === null ? null : value.toFixed(0)];
          case MeasurementSystem.IMPERIAL:
            return convertPipeDiameterFromMetric(unitPrefs, value);
        }
        assertUnreachable(unitPrefs.lengthMeasurementSystem);
      },
      layouts,
    },
    {
      property: "realInternalDiameterMM",
      title: "Internal Diameter (ID)",
      short: "",
      shortTitle: "ID",
      units: Units.Millimeters,
      category: FieldCategory.Size,
      systemUid: entity.systemUid,
      defaultEnabled: true,
      layouts,
    },
    {
      property: "materialName",
      title: "Pipe Material",
      short: "",
      shortTitle: "Material",
      units: Units.None,
      category: FieldCategory.Material,
      systemUid: entity.systemUid,
      layouts,
    },
  );

  if (pipeIsGas) {
    result.push({
      property: "gasMJH",
      title: "Peak Flow Rate",
      short: "",
      shortTitle: "",
      units: Units.MegajoulesPerHour,
      category: FieldCategory.FlowRate,
      systemUid: entity.systemUid,
      layouts: onlyLayouts("pressure"),
      unitContext: UnitsContext.GAS_ENERGY_MEASUREMENT,
    });
  } else {
    result.push({
      property: "pressureDropExcludingTerminiKPA",
      title: "Pressure Drop (PD)",
      short: "",
      shortTitle: "PD",
      units: Units.KiloPascals,
      category: FieldCategory.Pressure,
      systemUid: entity.systemUid,
      layouts: layoutsExcept("drainage"),
    });

    result.push({
      property: "pressureDropExcludingTerminiKPAPerMeter",
      title: "Pressure Drop Rate",
      short: "",
      shortTitle: "",
      units: Units.KiloPascalsPerMeter,
      category: FieldCategory.Pressure,
      systemUid: entity.systemUid,
      layouts: layoutsExcept("drainage"),
    });
  }

  result.push(
    {
      property: "lengthM",
      title: "Length",
      shortTitle: "L",
      short: "",
      units: Units.Meters,
      category: FieldCategory.Length,
      systemUid: entity.systemUid,
      layouts,
    },
    {
      property: "velocityRealMS",
      title: "Velocity",
      short: "",
      units: Units.MetersPerSecond,
      category: FieldCategory.Velocity,
      systemUid: entity.systemUid,

      layouts,
    },
  );

  if (!pipeIsGas) {
    if (drawing.metadata.calculationParams.psdMethod !== null) {
      result.push({
        property: "psdUnits.units",
        title: `${psdUnit.name} (${psdUnit.abbreviation})`,
        short: "", // psdUnit.abbreviation,
        shortTitle: psdUnit.abbreviation,
        units: Units.None,
        category: FieldCategory.LoadingUnits,
        systemUid: entity.systemUid,
        layouts: onlyLayouts("pressure"),
      });
    }

    if (drawing.metadata.calculationParams.dwellingMethod !== null) {
      result.push({
        property: "psdUnits.dwellings",
        title: "Dwellings",
        short: "",
        units: Units.None,
        category: FieldCategory.LoadingUnits,
        systemUid: entity.systemUid,
        layouts: onlyLayouts("pressure"),
      });
    }

    if (isDrainage(drawing.metadata.flowSystems[entity.systemUid])) {
      result.push({
        property: "psdUnits.drainageUnits",
        title: drainageUnits.name,
        short: "", // drainageUnits.abbreviation,
        units: Units.None,
        category: FieldCategory.LoadingUnits,
        systemUid: entity.systemUid,
        defaultEnabled: false,
        layouts: onlyLayouts("drainage"),
      });

      if (entity.conduit.network === "pipes") {
        result.push(
          {
            property: "gradePCT",
            title: "Grade",
            shortTitle: "Pipe Grade",
            short: "",
            units: Units.Percent,
            category: FieldCategory.Location,
            systemUid: entity.systemUid,
            defaultEnabled: false,
            layouts: onlyLayouts("drainage"),
          },

          {
            property: "fallM",
            title: "Fall",
            shortTitle: "Pipe Fall",
            short: "",
            units: Units.Meters,
            systemUid: entity.systemUid,
            defaultEnabled: false,
            category: FieldCategory.Length,
            layouts: onlyLayouts("drainage"),
          },
        );
      }
    }

    result.push({
      property: "totalKW",
      title: "Total Heat Load (THL)",
      short: "",
      shortTitle: "THL",
      units: Units.KiloWatts,
      significantDigits: 3,
      category: FieldCategory.HeatLoss,
      systemUid: entity.systemUid,
      defaultEnabled: false,
      layouts,
      unitContext: UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
    });
  }
  // To use for debug, uncomment the following lines
  //
  //   result.push({
  //     property: "closedKW",
  //     title: "Total Heating Heat Load",
  //     short: "",
  //     units: Units.KiloWatts,
  //     significantDigits: 3,
  //     category: FieldCategory.HeatLoss,
  //     systemUid: entity.systemUid,
  //     defaultEnabled: false,
  //     layouts,
  //   });
  //   result.push({
  //     property: "domesticKW",
  //     title: "Total Domestic Water Heat Load",
  //     short: "",
  //     units: Units.KiloWatts,
  //     significantDigits: 3,
  //     category: FieldCategory.HeatLoss,
  //     systemUid: entity.systemUid,
  //     defaultEnabled: false,
  //     layouts,
  //   });
  //
  //   result.push({
  //     property: "diversifiedTotalKW",
  //     title: "Diversified Heat Load",
  //     short: "",
  //     units: Units.KiloWatts,
  //     significantDigits: 3,
  //     category: FieldCategory.HeatLoss,
  //     systemUid: entity.systemUid,
  //     defaultEnabled: false,
  //     layouts,
  //   });
  //   result.push({
  //     property: "diversifiedClosedKW",
  //     title: "Diversified Heating Heat Load",
  //     short: "",
  //     units: Units.KiloWatts,
  //     significantDigits: 3,
  //     category: FieldCategory.HeatLoss,
  //     systemUid: entity.systemUid,
  //     defaultEnabled: false,
  //     layouts,
  //   });
  //   result.push({
  //     property: "diversifiedDomesticKW",
  //     title: "Diversified Domestic Water Heat Load",
  //     short: "",
  //     units: Units.KiloWatts,
  //     significantDigits: 3,
  //     category: FieldCategory.HeatLoss,
  //     systemUid: entity.systemUid,
  //     defaultEnabled: false,
  //     layouts,
  //   });
  return result;
}

export function isPipeReturn(
  pCalc: ConduitCalculation | ConduitLiveCalculation,
) {
  if ("configuration" in pCalc) {
    switch (pCalc.configuration) {
      case PipeConfiguration.RETURN_IN:
      case PipeConfiguration.RETURN_OUT:
        return true;
      case PipeConfiguration.NORMAL:
      case PipeConfiguration.RING_MAIN:
      case null:
        return false;
    }
    assertUnreachable(pCalc.configuration);
  } else {
    return false;
  }
}

export function emptyConduitCalculation(
  entity: ConduitEntity,
): ConduitCalculation {
  switch (entity.conduitType) {
    case "pipe":
      return {
        type: CalculationType.PipeCalculation,
        entityName: "",
        cost: null,
        costBreakdown: null,
        expandedEntities: null,

        gasMJH: null,

        totalPeakFlowRateLS: null,
        heightM: null,
        PSDFlowRateLS: null,
        noFlowAvailableReason: null,
        rawPSDFlowRateLS: null,
        rawReturnFlowRateLS: null,
        returnFlowRateLS: null,
        psdUnits: null,
        optimalInnerPipeDiameterMM: null,
        materialName: null,

        realInternalDiameterMM: null,
        pressureDropKPA: null,
        pressureDropKPAPerMeter: null,
        pressureDropExcludingTerminiKPA: null,
        pressureDropExcludingTerminiKPAPerMeter: null,
        realNominalPipeDiameterMM: null,
        realOutsideDiameterMM: null,
        lengthM: null,
        temperatureRange: null,
        configuration: null,
        velocityRealMS: null,
        warnings: null,
        psdProfile: null,
        flowFrom: null,
        gradePCT: null,

        stackDedicatedVentSize: null,
        ventRoot: null,
        ventTooFarWC: null,
        ventTooFarDist: null,
        fallM: null,

        isIndexCircult: null,
        isIndexNodePath: null,

        totalKW: null,
        closedKW: null,
        domesticKW: null,
        diversifiedTotalKW: null,
        diversifiedClosedKW: null,
        diversifiedDomesticKW: null,
        diversifiedKW: null,

        fireFlowRateLS: null,
      };
    case "duct":
      return {
        type: CalculationType.PipeCalculation,
        entityName: "",
        widthMM: null,
        diameterMM: null,
        heightMM: null,
        heightM: null,
        lengthM: null,
        crossSectionAreaM2: null,
        velocityRealMS: null,
        materialName: null,
        flowFrom: null,
        warnings: null,
        psdProfile: null,
        psdUnits: null,
        cost: null,
        costBreakdown: null,
        expandedEntities: null,
        rawFlowRateLS: null,
        totalPeakFlowRateLS: null,
        pressureDropKPA: null,
        pressureDropKPAPerMeter: null,
        pressureDropExcludingTerminiKPA: null,
        pressureDropExcludingTerminiKPAPerMeter: null,
        isIndexNodePath: null,
      };
    case "cable":
      throw new Error("Cable conduit not implemented");
  }
}

export function emptyConduitLiveCalculation(): ConduitLiveCalculation {
  return {
    connected: null,
    warnings: [],
    flowFrom: null,
    cycle: null,
    unbalanced: null,
    configuration: null,
    overlapped: null,
  };
}

export function getAmbiguousMessage(
  pCalc: PipeCalculation,
  locale: SupportedLocales,
): string {
  if (pCalc.noFlowAvailableReason) {
    switch (pCalc.noFlowAvailableReason) {
      case NoFlowAvailableReason.NO_SOURCE:
        return "NO FLOW SOURCE";
      case NoFlowAvailableReason.NO_LOADS_CONNECTED:
        return "NO FIXTURES CONNECTED";
      case NoFlowAvailableReason.TOO_MANY_FLOW_SOURCES:
        return "MULTIPLE FLOW SOURCES";
      case NoFlowAvailableReason.UNUSUAL_CONFIGURATION:
        return "UNUSUAL CONFIGURATION";
      case NoFlowAvailableReason.NO_ISOLATION_VALVES_ON_MAIN:
        return "NO ISOLATION VALVES ON MAIN";
      case NoFlowAvailableReason.LOADING_UNITS_OUT_OF_BOUNDS:
        return I18N.loadingUnits[locale].toLocaleUpperCase() + " OUT OF BOUNDS";
      case NoFlowAvailableReason.NO_SUITABLE_PIPE_SIZE:
        return "NO SUITABLE PIPE SIZE";
      case NoFlowAvailableReason.INVALID_RETURN_NETWORK:
        return "INVALID RETURN NETWORK";
      case NoFlowAvailableReason.GAS_SUPPLY_PRESSURE_TOO_LOW:
        return "GAS SUPPLY PRESSURE TOO LOW";
      case NoFlowAvailableReason.FLOW_DIRECTION_IS_REVERSABLE:
        return "FLOW DIRECTION IS REVERSIBLE";
      case NoFlowAvailableReason.NO_FLOW_TO_OUTLET_OR_RETURN:
        return "NO FLOW TO OUTLET OR RETURN";
      default:
        assertUnreachable(pCalc.noFlowAvailableReason);
    }
  }
  return "NOT CALCULATED";
}
