import { Coord } from "../../../common/src/lib/coord";
import { assertUnreachable } from "../../../common/src/lib/utils";

export enum DrawingMode {
  FloorPlan = 0,
  Design = 1,
  Calculations = 2,
  History = 3,
  Export = 4,
}

export function drawingModeEnumToLabel(mode: DrawingMode): string {
  switch (mode) {
    case DrawingMode.FloorPlan:
      return "Floor Plan";
    case DrawingMode.Design:
      return "Design";
    case DrawingMode.Calculations:
      return "Calculations";
    case DrawingMode.History:
      return "History";
    case DrawingMode.Export:
      return "Export";
  }
  assertUnreachable(mode);
}

export interface MouseMoveResult {
  handled: boolean;
  cursor: string | null;
}

export const UNHANDLED: MouseMoveResult = {
  handled: false,
  cursor: null,
};

export enum Direction {
  Horizontal = "horizontal",
  Vertical = "vertical",
}

export interface TouchStartState {
  touchesWC: Coord[];
}
