import axios from "axios";
import { APIResult } from "../../../common/src/api/document/types";
import { CreateErrorRequest } from "../../../common/src/api/error/Error";
import {
  ErrorReport,
  ErrorStatus,
} from "../../../common/src/models/ErrorReport";

export async function getErrorReports(
  statuses?: ErrorStatus[],
  from?: number,
  count?: number,
): Promise<APIResult<ErrorReport[]>> {
  try {
    return (
      await axios.get("/api/errors/", { params: { statuses, from, count } })
    ).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function getErrorReport(
  id: number,
): Promise<APIResult<ErrorReport>> {
  try {
    return (await axios.get("/api/errors/" + id)).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function submitErrorReport(
  appVersion: string,
  message: string,
  name: string,
  trace: string,
  url: string,
): Promise<APIResult<ErrorReport>> {
  try {
    const request: CreateErrorRequest = {
      appVersion,
      message,
      name,
      threwOn: new Date(),
      trace,
      url,
    };
    return (await axios.post("/api/errors/", request)).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

const sentErrors = new Set<string>();

export async function reportWarning(message: string) {
  await reportError("[WARNING] " + message, new Error(message), false);
}

export async function reportError(
  message: string,
  error: Error | null,
  showDialog: boolean = true,
) {
  // @ts-ignore
  const vue = document.vue;
  // @ts-ignore
  const store = document.store;

  if (!error) {
    error = new Error("reportError");
  }

  if (sentErrors.has(message.toString())) {
    return;
  }
  sentErrors.add(message.toString());
  console.log(error.stack);
  submitErrorReport(
    store.getters.appVersion,
    message ? message.toString() : "[No Message]",
    error.name,
    error.stack || "No Stack",
    window.location.href,
  )
    .then(() => {
      if (showDialog) {
        const msgstr =
          "An error occurred: " +
          message.toString() +
          "If the issue persists and is preventing you from working, " +
          "please contact our team and we will assist.";

        if (vue) {
          vue.$bvToast.toast(
            "If you experience problems with the page from now on, please refresh. If the issue persists and is preventing you from working, " +
              "please contact our team and we will assist.\n",
            {
              title: "An error has occured: " + message.toString(),
              variant: "danger",
            },
          );
        } else {
          window.alert(msgstr);
        }
      }
    })
    .catch(() => {
      if (showDialog) {
        const msgstr =
          "An error occurred, but we couldn't report the error!\n" +
          message.toString();
        if (vue) {
          vue.$bvModal.msgBoxOk(msgstr, { title: "Error" });
        } else {
          window.alert(msgstr);
        }
      }
    });
}
