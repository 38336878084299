import Flatten from "@flatten-js/core";
import { PipeConfiguration } from "../../../../common/src/api/calculations/types";
import {
  isFlowReversed,
  isMechanical,
} from "../../../../common/src/api/config";
import CoreConduit from "../../../../common/src/api/coreObjects/coreConduit";
import {
  determineConnectableSystemUid,
  flowSystemsCompatible,
} from "../../../../common/src/api/coreObjects/utils";
import {
  DuctCalculation,
  NoFlowAvailableReason,
  PipeCalculation,
  isPipeReturn,
} from "../../../../common/src/api/document/calculations-objects/conduit-calculations";

import RBush, { BBox } from "rbush";
import { isDuctMaterialFlex } from "../../../../common/src/api/catalog/ventilation/ducts";
import {
  DrawableEntityConcrete,
  isConnectableEntity,
} from "../../../../common/src/api/document/entities/concrete-entity";
import ConduitEntity, {
  fillDefaultConduitFields,
  isDuctEntity,
  isPipeEntity,
} from "../../../../common/src/api/document/entities/conduit-entity";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import {
  getFlowSystem,
  getFlowSystemColor,
} from "../../../../common/src/api/document/utils";
import { lighten } from "../../../../common/src/lib/color";
import { Coord } from "../../../../common/src/lib/coord";
import { GlobalStore } from "../../../../common/src/lib/globalstore/global-store";
import { isParallelRad } from "../../../../common/src/lib/mathUtils/mathutils";
import {
  EPS,
  assertUnreachable,
  cloneSimple,
} from "../../../../common/src/lib/utils";
import { DEFAULT_FONT_NAME } from "../../config";
import { rgb2style } from "../../lib/utils";
import { getGlobalContext } from "../../store/globalCoreContext";
import { SIGNIFICANT_FLOW_THRESHOLD } from "../layers/calculation-layer";
import { PIPE_STUB_MAX_LENGTH_MM } from "../lib/black-magic/auto-connect";
import CanvasContext from "../lib/canvas-context";
import { EntityDrawingArgs } from "../lib/drawable-object";
import { LiveWarnings } from "../lib/entity-popups/live-warnings";
import { EntityPopupContent } from "../lib/entity-popups/types";
import {
  HeatmapMode,
  generateConduitHeatmap,
  isHeatmapEnabled,
} from "../lib/heatmap/heatmap";
import { Interaction, InteractionType } from "../lib/interaction";
import { CalculatedObject } from "../lib/object-traits/calculated-object";
import CoolDraggableObject from "../lib/object-traits/cool-draggable-object";
import { Core2Drawable } from "../lib/object-traits/core2drawable";
import { EdgeObject } from "../lib/object-traits/edge-object";
import {
  HoverSiblingResult,
  HoverableObject,
} from "../lib/object-traits/hoverable-object";
import { SelectableObject } from "../lib/object-traits/selectable";
import { SnappableObject } from "../lib/object-traits/snappable-object";
import { DrawingContext, ObjectConstructArgs } from "../lib/types";
import {
  getHighlightColor,
  getLineDash,
  pipeConfigurationCompatible,
} from "../lib/utils";
import { DrawingMode } from "../types";
import { DrawableObjectConcrete, isConnectableObject } from "./concrete-object";
import DrawableDamper from "./drawableDamper";
import { isFlowSystemActive } from "./utils";

export const TEXT_MAX_SCALE = 0.4;
export const MIN_PIPE_PIXEL_WIDTH = 1.5;

export const INTERNAL_ARROW_MIN_SCALE_DRAWN = 0.05;

export const MAX_CONDUIT_ARROWS = 2000;

const Base = CalculatedObject(
  SelectableObject(
    CoolDraggableObject(
      HoverableObject(SnappableObject(EdgeObject(Core2Drawable(CoreConduit)))),
    ),
  ),
);

interface HighlightPipeAccent {
  type: "highlight";
  color?: string | ((baseColor: string) => string); // if missing, uses the base color.
  lighten?: number;
  alpha?: number;
  widthBU: number | ((baseWidth: number, s: number) => number);
}

interface ExternalArrowPipeAccent {
  type: "externalArrow";
  aUid: string;
  bUid: string;
  directed: boolean;
}

interface InternalArrowPipeAccent {
  type: "internalArrow";
  aUid: string;
  bUid: string;
  directed: boolean;
  color?: string;
  scale?: number | ((baseWidth: number, s: number) => number);
}

interface TildePipeAccent {
  type: "tilde";
  color: string;
  amplitudeS: number; // wave width in screen coordinates
  lineWidthS: number; // width in screen coordinates
  distanceBU: number | ((baseWidth: number, s: number) => number);

  rendered?: {
    adjustments: [[number, number], [number, number]];
  };
}

interface OverlappedPipeAccent {
  type: "overlapped";
  color: string;
  amplitudeS: number; // wave width in screen coordinates
  lineWidthS: number; // width in screen coordinates
  distanceBU: number | ((baseWidth: number, s: number) => number);
  offset: [number, number];

  rendered?: {
    adjustments: [[number, number], [number, number]];
  };
}

interface UnderlappedPipeAccent {
  type: "underlapped";
  color: string;
  offset: [number, number];
}

interface LineDashAccent {
  type: "lineDash";
  pattern: number[];
}
interface BaseColorAccent {
  type: "baseColor";
  color: string;
}

// draws the pipe itself
interface PipeBase {
  type: "pipe";
}

// Grayify Setting, used to gray out pipe in a high level
// that applys before all other settings
interface GreyifyPipe {
  type: "greyify";
}

interface RectDuct {
  type: "rectDuct";
  variant: "flexDuct" | null;
  width: number;
}

interface CircDuct {
  type: "circDuct";
  variant: "flexDuct" | null;
  diameter: number;
}

// Different annotations, highlights and accents that can be applied to a pipe.
// A list of these is passed to the pipe drawing function. The order matters - in
// particular, annotations are drawn first to last and any baseColor accents encountered
// changes the default color used by following accents as well.
export type ConduitLayer =
  | HighlightPipeAccent
  | ExternalArrowPipeAccent
  | TildePipeAccent
  | LineDashAccent
  | BaseColorAccent
  | PipeBase
  | InternalArrowPipeAccent
  | GreyifyPipe
  | OverlappedPipeAccent
  | UnderlappedPipeAccent
  | RectDuct
  | CircDuct;

interface PipeRender {
  layers: ConduitLayer[];
  worldEndpoints: [Coord, Coord];
  worldByUid: { [uid: string]: Coord };
  computedLengthM: number;
  baseColor: string;
}

export default class DrawableConduit extends Base {
  type: EntityType.CONDUIT = EntityType.CONDUIT;

  // We could not add this to the base drawable class because
  // of some typescript thing so they have to be added at the concrete class.
  constructor(args: ObjectConstructArgs<ConduitEntity>) {
    super(args.context, args.obj);
    this.onSelect = args.onSelect;
    this.onInteractionComplete = args.onInteractionComplete;
    this.document = args.document;
  }

  lastDrawnLine!: Flatten.Segment | Flatten.Point;
  declare snapHoverTimeoutMS: 0;

  isActive(): boolean {
    return isFlowSystemActive(
      this.context,
      this.document.uiState,
      this.entity.systemUid,
    );
  }

  baseDrawnColor(context: DrawingContext) {
    let result = this.entity.color;
    if (isPipeEntity(this.entity) || isDuctEntity(this.entity)) {
      result =
        result ||
        getFlowSystemColor(
          getFlowSystem(context.doc.drawing, this.entity.systemUid)!,
          this.entity.conduit.network,
          this.effectiveConfiguration,
        );
    }

    if (!result) {
      throw new Error("No color for pipe");
    }
    return result;
  }

  applyPipeHighLightField(
    calculation: PipeCalculation | undefined,
    context: DrawingContext,
    result: ConduitLayer[],
  ) {
    if (
      calculation &&
      context.doc.uiState.drawingMode === DrawingMode.Calculations
    ) {
      if (
        calculation.isIndexCircult &&
        this.document?.uiState?.calculationFilters?.Pipe?.filters &&
        this.document?.uiState?.calculationFilters?.Pipe?.filters[
          "Show Index Circult"
        ]?.enabled &&
        this.isActive()
      ) {
        result.push({
          type: "highlight",
          widthBU: (baseWidth, s) => baseWidth + 9.0 / s,
          color: (_baseColor) => lighten("#a865d6", 20, 0.5),
        });
      }

      if (
        calculation.isIndexNodePath &&
        this.document?.uiState?.calculationFilters?.Pipe?.filters &&
        this.document?.uiState?.calculationFilters?.Pipe?.filters[
          "Show Index Node Path"
        ]?.enabled &&
        this.isActive()
      ) {
        result.push({
          type: "highlight",
          widthBU: (baseWidth, s) => baseWidth + 9.0 / s,
          color: (baseColor) => lighten(baseColor, 20, 0.5),
        });
      }
    }
  }

  applyDuctHighLightField(
    calculation: DuctCalculation | undefined,
    context: DrawingContext,
    result: ConduitLayer[],
  ) {
    if (
      calculation &&
      context.doc.uiState.drawingMode === DrawingMode.Calculations
    ) {
      if (
        calculation.isIndexNodePath &&
        this.document?.uiState?.calculationFilters?.Duct?.filters &&
        this.document?.uiState?.calculationFilters?.Duct?.filters[
          "Show Index Node Path"
        ]?.enabled &&
        this.isActive()
      ) {
        result.push({
          type: "highlight",
          widthBU: (baseWidth, s) => baseWidth + 9.0 / s,
          color: (baseColor) => lighten(baseColor, 20, 0.5),
        });
      }
    }
  }

  getHighLightFieldHash(context: DrawingContext): string {
    return (
      context.doc.uiState.drawingLayout +
      (this.document?.uiState?.calculationFilters?.Pipe?.filters
        ? this.document?.uiState?.calculationFilters?.Pipe?.filters[
            "Show Index Circult"
          ]?.enabled
        : "undefined") +
      (this.document?.uiState?.calculationFilters?.Pipe?.filters
        ? this.document?.uiState?.calculationFilters?.Pipe?.filters[
            "Show Index Node Path"
          ]?.enabled
        : "undefined") +
      (this.document?.uiState?.calculationFilters?.Duct?.filters
        ? this.document?.uiState?.calculationFilters?.Duct?.filters[
            "Show Index Node Path"
          ]?.enabled
        : "undefined")
    );
  }

  getPipeLayers(
    context: DrawingContext,
    drawArgs: EntityDrawingArgs,
  ): ConduitLayer[] {
    const {
      selected,
      withCalculation,
      overrideColorList,
      heatmapMode,
      forExport,
    } = drawArgs;
    if (!isPipeEntity(this.entity)) {
      return []; // TODO: non-pipes.
    }
    const liveCalcs = context.globalStore.getOrCreateLiveCalculation(
      this.entity,
    );
    const calculation = context.globalStore.getCalculation(this.entity);
    const overlapResult = detectOverlap(this.uid, context.globalStore);

    const result: ConduitLayer[] = [
      {
        type: "baseColor",
        color: this.baseDrawnColor(context).hex,
      },
    ];

    if (overlapResult.overlapped && !forExport) {
      if (overlapResult.underlapped) {
        result.push({
          type: "underlapped",
          color: "black",
          offset: overlapResult.overlappedSegment,
        });
      } else {
        liveCalcs.overlapped = true;
        result.push({
          type: "overlapped",
          color: "blue",
          amplitudeS: 6,
          lineWidthS: 1,
          offset: overlapResult.overlappedSegment,
          distanceBU: (baseWidth, s) => baseWidth + 3.0 / s,
        });
      }
    }

    if (!this.isActive()) {
      result.push({
        type: "baseColor",
        color: "rgba(150, 150, 150, 0.65)",
      });
    }

    if (calculation && calculation.debug && calculation.debug.highlight) {
      result.push({
        type: "highlight",
        color: calculation.debug.highlight,
        widthBU: (baseWidth, s) => baseWidth + 6.0 / s,
      });
    }

    if (withCalculation) {
      if (
        calculation &&
        (calculation.totalPeakFlowRateLS === null ||
          calculation.totalPeakFlowRateLS < SIGNIFICANT_FLOW_THRESHOLD)
      ) {
        if (
          calculation.noFlowAvailableReason !==
          NoFlowAvailableReason.NO_LOADS_CONNECTED
        ) {
          result.push({
            type: "baseColor",
            color: "#aaaaaa",
          });
        }
      }

      if (
        isHeatmapEnabled(this.document) &&
        this.document.uiState.drawingLayout !== "ventilation"
      ) {
        const filledEntity = this.displayObject();
        if (
          heatmapMode !== undefined &&
          calculation !== undefined &&
          (isPipeEntity(filledEntity) || isDuctEntity(filledEntity))
        ) {
          const color = generateConduitHeatmap(
            this,
            getGlobalContext(),
            heatmapMode,
            calculation,
            filledEntity,
          );
          if (color !== undefined) {
            result.push({
              type: "baseColor",
              color,
            });
          } else {
            return [];
          }
        }
      }
      if (
        !calculation ||
        (calculation.rawReturnFlowRateLS === null && isPipeReturn(calculation))
      ) {
        // return loop error
        if (!forExport) {
          result.push({
            type: "tilde",
            color: "#DE0808",
            amplitudeS: 6,
            lineWidthS: 1,
            distanceBU: (baseWidth, s) => baseWidth + 3.0 / s,
          });
        }
      } else if (
        !calculation ||
        (calculation.PSDFlowRateLS === null &&
          calculation.optimalInnerPipeDiameterMM === null)
      ) {
        // No flow calculatable error
        if (!forExport) {
          result.push({
            type: "tilde",
            color: "#DE0808",
            amplitudeS: 6,
            lineWidthS: 1,
            distanceBU: (baseWidth, s) => baseWidth + 3.0 / s,
          });
        }
      }
    } else {
      if (liveCalcs.unbalanced) {
        if (!forExport && !withCalculation) {
          result.push({
            type: "tilde",
            color: "#DE0808",
            amplitudeS: 6,
            lineWidthS: 1,
            distanceBU: (baseWidth, s) => baseWidth + 3.0 / s,
          });
        }
      }
    }

    const lineDash = getLineDash(this.entity, this.document);
    if (lineDash) {
      result.push({
        type: "lineDash",
        pattern: lineDash,
      });
    }

    // Check if it's index circuit or node path
    this.applyPipeHighLightField(calculation, context, result);

    result.push({
      type: "pipe",
    });
    result.push({
      type: "lineDash",
      pattern: [],
    });

    const flowFrom = calculation?.flowFrom || liveCalcs.flowFrom;
    if (
      !forExport &&
      ((calculation && isPipeReturn(calculation)) ||
        (!forExport && liveCalcs && isPipeReturn(liveCalcs)) ||
        calculation?.debug?.showArrows ||
        (!forExport && liveCalcs.cycle))
    ) {
      const endpoints = [
        this.entity.endpointUid[0],
        this.entity.endpointUid[1],
      ];
      if (flowFrom && flowFrom.includes(this.entity.endpointUid[1])) {
        const tmp = endpoints[1];
        endpoints[1] = endpoints[0];
        endpoints[0] = tmp;
      }
      if (
        isFlowReversed(
          this.context.drawing.metadata.flowSystems[this.entity.systemUid],
        )
      ) {
        const tmp = endpoints[1];
        endpoints[1] = endpoints[0];
        endpoints[0] = tmp;
      }

      result.push({
        type: "externalArrow",
        aUid: endpoints[0],
        bUid: endpoints[1],
        directed: !!flowFrom,
      });
    }

    if (!forExport && !withCalculation && liveCalcs.warnings.length > 0) {
      result.push({
        type: "tilde",
        color: "#DE0808",
        amplitudeS: 6,
        lineWidthS: 1,
        distanceBU: (baseWidth, s) => baseWidth * 1.5 + 3.0 / s,
      });
    }
    if (
      selected ||
      overrideColorList.length ||
      isHeatmapEnabled(this.document)
    ) {
      if (heatmapMode !== HeatmapMode.Off) {
        result.push({
          type: "highlight",
          widthBU: (baseWidth, s) => baseWidth + 6.0 / s,
          alpha: 0.2,
        });
      } else {
        result.push({
          type: "highlight",
          widthBU: (baseWidth, s) => baseWidth + 6.0 / s,
          color: (baseColor) =>
            rgb2style(
              getHighlightColor(selected, overrideColorList, {
                hex: lighten(baseColor, 0),
              }),
              0.5,
            ),
        });
      }
    }

    if (this.isHovering) {
      result.push({
        type: "highlight",
        widthBU: (baseWidth, s) => baseWidth + 6.0 / s,
        color: (baseColor) => lighten(baseColor, 50, 0.5),
      });
    }

    if (
      !forExport &&
      flowFrom &&
      calculation &&
      !isPipeReturn(calculation) &&
      liveCalcs &&
      !isPipeReturn(liveCalcs) &&
      !liveCalcs.cycle
    ) {
      const endpoints = [
        this.entity.endpointUid[0],
        this.entity.endpointUid[1],
      ];
      if (flowFrom && flowFrom.includes(this.entity.endpointUid[1])) {
        const tmp = endpoints[1];
        endpoints[1] = endpoints[0];
        endpoints[0] = tmp;
      }
      if (
        isFlowReversed(
          this.context.drawing.metadata.flowSystems[this.entity.systemUid],
        )
      ) {
        const tmp = endpoints[1];
        endpoints[1] = endpoints[0];
        endpoints[0] = tmp;
      }

      result.push({
        type: "internalArrow",
        aUid: endpoints[0],
        bUid: endpoints[1],
        directed: !!flowFrom,
        scale: this.isHovering
          ? (baseWidth, s) => (baseWidth + 6.0 / s) / baseWidth
          : undefined,
      });
    }

    return result;
  }

  getDuctLayers(
    context: DrawingContext,
    drawArgs: EntityDrawingArgs,
  ): ConduitLayer[] {
    const {
      selected,
      withCalculation,
      overrideColorList,
      heatmapMode,
      forExport,
    } = drawArgs;
    if (!isDuctEntity(this.entity)) {
      return []; // TODO: non-pipes.
    }
    const liveCalcs = context.globalStore.getOrCreateLiveCalculation(
      this.entity,
    );
    const calculation = context.globalStore.getCalculation(this.entity);
    const overlapResult = detectOverlap(this.uid, context.globalStore);

    const result: ConduitLayer[] = [
      {
        type: "baseColor",
        color: this.baseDrawnColor(context).hex,
      },
    ];

    if (overlapResult.overlapped && !forExport) {
      if (overlapResult.underlapped) {
        result.push({
          type: "underlapped",
          color: "black",
          offset: overlapResult.overlappedSegment,
        });
      } else {
        liveCalcs.overlapped = true;
        result.push({
          type: "overlapped",
          color: "blue",
          amplitudeS: 6,
          lineWidthS: 1,
          offset: overlapResult.overlappedSegment,
          distanceBU: (baseWidth, s) => baseWidth + 3.0 / s,
        });
      }
    }

    if (!this.isActive()) {
      result.push({
        type: "baseColor",
        color: "rgba(150, 150, 150, 0.65)",
      });
    }

    const lineDash = getLineDash(this.entity, this.document);
    if (lineDash) {
      result.push({
        type: "lineDash",
        pattern: lineDash,
      });
    }

    this.applyDuctHighLightField(calculation, context, result);

    let physicalDrawn = false;
    if (
      context.doc.uiState.ductView === "physical" &&
      context.doc.uiState.drawingMode === DrawingMode.Calculations
    ) {
      const filled = fillDefaultConduitFields(context, this.entity);

      const isFlex = isDuctMaterialFlex(filled.conduit.material!);
      const variant = isFlex ? "flexDuct" : null;
      switch (filled.conduit.shape) {
        case "circular": {
          const diameter = liveCalcs.diameterMM ?? calculation?.diameterMM;
          if (diameter != null) {
            result.push({
              type: "circDuct",
              variant,
              diameter,
            });
            physicalDrawn = true;
          }
          break;
        }
        case "rectangular": {
          const width = liveCalcs.widthMM ?? calculation?.widthMM;
          // const height = liveCalcs.heightMM ?? calculation?.heightMM;
          if (width != null) {
            result.push({
              type: "rectDuct",
              variant,
              width,
            });
            physicalDrawn = true;
          }
          break;
        }
        case null:
          break;
        default:
          assertUnreachable(filled.conduit.shape);
      }
    }

    if (!physicalDrawn) {
      result.push({
        type: "pipe",
      });
      result.push({
        type: "lineDash",
        pattern: [],
      });
    }

    if (withCalculation) {
      if (
        isHeatmapEnabled(this.document) &&
        this.document.uiState.drawingLayout === "ventilation"
      ) {
        const filledEntity = this.displayObject();
        if (
          heatmapMode !== undefined &&
          calculation !== undefined &&
          (isPipeEntity(filledEntity) || isDuctEntity(filledEntity))
        ) {
          const color = generateConduitHeatmap(
            this,
            getGlobalContext(),
            heatmapMode,
            calculation,
            filledEntity,
          );
          if (color !== undefined) {
            result.push({
              type: "baseColor",
              color,
            });
          } else {
            return [];
          }
        }
      }
    }

    const flowFrom = calculation?.flowFrom || liveCalcs.flowFrom;
    if (
      !forExport &&
      ((calculation && isPipeReturn(calculation)) ||
        (!forExport && liveCalcs && isPipeReturn(liveCalcs)) ||
        calculation?.debug?.showArrows ||
        (!forExport && liveCalcs.cycle))
    ) {
      const endpoints = [
        this.entity.endpointUid[0],
        this.entity.endpointUid[1],
      ];
      if (flowFrom && flowFrom.includes(this.entity.endpointUid[1])) {
        const tmp = endpoints[1];
        endpoints[1] = endpoints[0];
        endpoints[0] = tmp;
      }
      if (
        isFlowReversed(
          this.context.drawing.metadata.flowSystems[this.entity.systemUid],
        )
      ) {
        const tmp = endpoints[1];
        endpoints[1] = endpoints[0];
        endpoints[0] = tmp;
      }

      result.push({
        type: "externalArrow",
        aUid: endpoints[0],
        bUid: endpoints[1],
        directed: !!flowFrom,
      });
    }

    if (
      !forExport &&
      !withCalculation &&
      (liveCalcs.warnings.length > 0 || this.physicallyInversed)
    ) {
      result.push({
        type: "tilde",
        color: "#DE0808",
        amplitudeS: 6,
        lineWidthS: 1,
        distanceBU: (baseWidth, s) => baseWidth * 1.5 + 3.0 / s,
      });
    }
    if (
      selected ||
      overrideColorList.length ||
      isHeatmapEnabled(this.document)
    ) {
      if (
        heatmapMode !== HeatmapMode.Off &&
        this.document.uiState.drawingLayout === "ventilation"
      ) {
        result.push({
          type: "highlight",
          widthBU: calculation?.diameterMM ?? calculation?.widthMM ?? 0,
          alpha: 0.8,
        });
      } else {
        result.push({
          type: "highlight",
          widthBU: (baseWidth, s) => baseWidth + 6.0 / s,
          color: (baseColor) =>
            rgb2style(
              getHighlightColor(selected, overrideColorList, {
                hex: lighten(baseColor, 0),
              }),
              0.5,
            ),
        });
      }
    }

    if (this.isHovering) {
      result.push({
        type: "highlight",
        widthBU: (baseWidth, s) => baseWidth + 6.0 / s,
        color: (baseColor) => lighten(baseColor, 50, 0.5),
      });
    }
    if (
      !forExport &&
      flowFrom &&
      calculation &&
      !isPipeReturn(calculation) &&
      liveCalcs &&
      !isPipeReturn(liveCalcs) &&
      !liveCalcs.cycle
    ) {
      const endpoints = [
        this.entity.endpointUid[0],
        this.entity.endpointUid[1],
      ];
      if (flowFrom && flowFrom.includes(this.entity.endpointUid[1])) {
        const tmp = endpoints[1];
        endpoints[1] = endpoints[0];
        endpoints[0] = tmp;
      }
      if (
        isFlowReversed(
          this.context.drawing.metadata.flowSystems[this.entity.systemUid],
        )
      ) {
        const tmp = endpoints[1];
        endpoints[1] = endpoints[0];
        endpoints[0] = tmp;
      }

      result.push({
        type: "internalArrow",
        aUid: endpoints[0],
        bUid: endpoints[1],
        directed: !!flowFrom,
        scale: this.isHovering
          ? (baseWidth, s) => (baseWidth + 6.0 / s) / baseWidth
          : undefined,
      });
    }

    return result;
  }
  getConduitLayers(
    context: DrawingContext,
    drawArgs: EntityDrawingArgs,
  ): ConduitLayer[] {
    if (isPipeEntity(this.entity)) {
      return this.getPipeLayers(context, drawArgs);
    } else if (isDuctEntity(this.entity)) {
      return this.getDuctLayers(context, drawArgs);
    } else {
      throw new Error("Not implemented");
    }
  }

  cachedRender: Record<string, PipeRender> = {};

  onRedrawNeeded() {
    super.onRedrawNeeded();
    this.cachedRender = {};
  }

  getRender(context: DrawingContext, drawArgs: EntityDrawingArgs): PipeRender {
    const drawArgsKey =
      JSON.stringify(drawArgs) +
      this.isHovering +
      this.getHighLightFieldHash(context);
    if (!this.cachedRender[drawArgsKey]) {
      this.applyRender(context, drawArgs, drawArgsKey);
    }

    return this.cachedRender[drawArgsKey];
  }

  applyRender(
    context: DrawingContext,
    drawArgs: EntityDrawingArgs,
    drawArgsKey: string,
  ) {
    // lol what are our coordinates
    const [aw, bw] = this.physicalWorldEndpoints();
    const layers = this.getConduitLayers(context, drawArgs);

    this.cachedRender[drawArgsKey] = {
      layers,
      worldEndpoints: [aw, bw],
      computedLengthM: this.computedLengthM,
      worldByUid: {
        [this.entity.endpointUid[0]]: aw,
        [this.entity.endpointUid[1]]: bw,
      },
      baseColor: this.baseDrawnColor(context).hex,
    };

    for (const layer of layers) {
      switch (layer.type) {
        case "tilde": {
          const vec = Flatten.vector([bw.x - aw.x, bw.y - aw.y]).normalize();
          const aConnections = context.globalStore.getConnections(
            this.entity.endpointUid[0],
          );
          const bConnections = context.globalStore.getConnections(
            this.entity.endpointUid[1],
          );

          const connections = [aConnections, bConnections];
          const adjustments = [
            [0, 0],
            [0, 0],
          ];
          const multiple = [-1, 1];
          const ews = [aw, bw];

          // Remember that our Y coordinates is top down flipped when you are reading this
          // code lol.
          for (const side of [0, 1]) {
            let leftmostAngle = Math.PI;
            let rightmostAngle = -Math.PI;
            for (const conn of connections[side]) {
              if (conn === this.entity.uid) continue;
              const otherPipe = context.globalStore.get<DrawableConduit>(conn);
              const otherConnectableUid =
                otherPipe.entity.endpointUid[0] ===
                this.entity.endpointUid[side]
                  ? otherPipe.entity.endpointUid[1]
                  : otherPipe.entity.endpointUid[0];
              const otherConnectable =
                context.globalStore.get(otherConnectableUid);
              const otherConnectablePos = otherConnectable.toWorldCoord();
              const otherVector = Flatten.vector([
                otherConnectablePos.x - ews[side].x,
                otherConnectablePos.y - ews[side].y,
              ]).normalize();

              let angle = vec.multiply(multiple[side]).angleTo(otherVector);
              if (angle > Math.PI) angle -= Math.PI * 2;

              if (angle < leftmostAngle) leftmostAngle = angle;
              if (angle > rightmostAngle) rightmostAngle = angle;
            }

            const rightEndAdjust = isParallelRad(0, leftmostAngle, Math.PI / 4)
              ? 0
              : Math.sin(leftmostAngle * multiple[side]);
            const leftEndAdjust = isParallelRad(0, rightmostAngle, Math.PI / 4)
              ? 0
              : Math.sin(-rightmostAngle * multiple[side]);
            adjustments[side] = [rightEndAdjust, leftEndAdjust];
          }

          layer.rendered = {
            adjustments: adjustments as [[number, number], [number, number]],
          };
        }
      }
    }
  }

  drawEntity(context: DrawingContext, drawArgs: EntityDrawingArgs): void {
    // easier when pipes are same as world coord.
    const { graphics, ctx } = context;

    const render: PipeRender = this.getRender(context, drawArgs);
    const isGrayify = render.layers.find((layer) => layer.type === "greyify");

    // Kinda masks problems.
    if (render.computedLengthM < EPS) {
      console.warn("Pipe length is 0", this.entity.uid);
      return;
    }

    const [aw, bw] = render.worldEndpoints;

    const s = graphics.worldToSurfaceScale;

    const targetWWidth = 15;

    const baseWidth = Math.max(
      MIN_PIPE_PIXEL_WIDTH / s,
      targetWWidth / graphics.unitWorldLength,
      (MIN_PIPE_PIXEL_WIDTH / s) * (5 + Math.log(s)),
    );
    this.lastDrawnWidthInternal = baseWidth;

    ctx.lineCap = "round";

    let baseColor: string = render.baseColor;
    if (isGrayify) {
      console.log("Setting to gray");
      ctx.filter = `grayscale(85%)`;
    }
    for (const layer of render.layers) {
      switch (layer.type) {
        case "baseColor": {
          baseColor = layer.color;
          break;
        }
        case "pipe": {
          ctx.strokeStyle = baseColor;
          ctx.fillStyle = baseColor;
          ctx.beginPath();
          ctx.strokeStyle = baseColor;
          ctx.lineWidth = baseWidth;

          ctx.moveTo(render.worldEndpoints[0].x, render.worldEndpoints[0].y);
          ctx.lineTo(render.worldEndpoints[1].x, render.worldEndpoints[1].y);
          ctx.stroke();
          break;
        }
        case "rectDuct": {
          // draw line schematic
          ctx.beginPath();
          ctx.strokeStyle = baseColor;
          const normal = Flatten.vector([
            render.worldEndpoints[1].x - render.worldEndpoints[0].x,
            render.worldEndpoints[1].y - render.worldEndpoints[0].y,
          ]).normalize();
          const orth = normal.rotate90CCW();
          ctx.lineWidth = Math.max(1 / s, baseWidth / 8);

          const width = layer.width;
          const halfWidth = width / 2;

          const ax1 = render.worldEndpoints[0].x + orth.x * halfWidth;
          const ax2 = render.worldEndpoints[1].x + orth.x * halfWidth;
          const ay1 = render.worldEndpoints[0].y + orth.y * halfWidth;
          const ay2 = render.worldEndpoints[1].y + orth.y * halfWidth;
          const bx1 = render.worldEndpoints[0].x - orth.x * halfWidth;
          const bx2 = render.worldEndpoints[1].x - orth.x * halfWidth;
          const by1 = render.worldEndpoints[0].y - orth.y * halfWidth;
          const by2 = render.worldEndpoints[1].y - orth.y * halfWidth;

          // Draw transparent rect with no outline
          ctx.beginPath();
          ctx.fillStyle = baseColor;
          ctx.globalAlpha = 0.1;
          ctx.moveTo(ax1, ay1);
          ctx.lineTo(ax2, ay2);
          ctx.lineTo(bx2, by2);
          ctx.lineTo(bx1, by1);
          ctx.closePath();
          ctx.fill();

          ctx.globalAlpha = 1;

          // Now draw side borders
          ctx.beginPath();
          ctx.moveTo(ax1, ay1);
          ctx.lineTo(ax2, ay2);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(bx1, by1);
          ctx.lineTo(bx2, by2);
          ctx.stroke();

          const oldLineDash = ctx.getLineDash();
          ctx.lineCap = "butt";
          if (layer.variant === "flexDuct") {
            ctx.lineWidth *= 3;
            // 50 for spiral spacing of 50mm
            const loopGap = 50 - ctx.lineWidth / 3;
            ctx.setLineDash([ctx.lineWidth / 3, loopGap]);

            ctx.beginPath();
            ctx.moveTo(ax1, ay1);
            ctx.lineTo(ax2, ay2);
            ctx.stroke();
            ctx.beginPath();
            ctx.moveTo(bx1, by1);
            ctx.lineTo(bx2, by2);
            ctx.stroke();

            ctx.setLineDash(oldLineDash);
          }

          break;
        }
        case "circDuct": {
          // draw line schematic
          ctx.beginPath();
          ctx.strokeStyle = baseColor;
          const normal = Flatten.vector([
            render.worldEndpoints[1].x - render.worldEndpoints[0].x,
            render.worldEndpoints[1].y - render.worldEndpoints[0].y,
          ]).normalize();
          const orth = normal.rotate90CCW();
          ctx.lineWidth = Math.max(1 / s, baseWidth / 8);

          const width = layer.diameter;
          const halfWidth = width / 2;

          const ax1 = render.worldEndpoints[0].x + orth.x * halfWidth;
          const ax2 = render.worldEndpoints[1].x + orth.x * halfWidth;
          const ay1 = render.worldEndpoints[0].y + orth.y * halfWidth;
          const ay2 = render.worldEndpoints[1].y + orth.y * halfWidth;
          const bx1 = render.worldEndpoints[0].x - orth.x * halfWidth;
          const bx2 = render.worldEndpoints[1].x - orth.x * halfWidth;
          const by1 = render.worldEndpoints[0].y - orth.y * halfWidth;
          const by2 = render.worldEndpoints[1].y - orth.y * halfWidth;

          // Draw transparent rect with no outline
          ctx.beginPath();
          ctx.fillStyle = baseColor;
          ctx.globalAlpha = 0.1;
          ctx.moveTo(ax1, ay1);
          ctx.lineTo(ax2, ay2);
          ctx.lineTo(bx2, by2);
          ctx.lineTo(bx1, by1);
          ctx.closePath();
          ctx.fill();

          ctx.globalAlpha = 1;

          // Now draw side borders
          ctx.beginPath();
          ctx.moveTo(ax1, ay1);
          ctx.lineTo(ax2, ay2);
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(bx1, by1);
          ctx.lineTo(bx2, by2);
          ctx.stroke();

          const oldLineDash = ctx.getLineDash();
          ctx.lineCap = "butt";
          if (layer.variant === "flexDuct") {
            ctx.lineWidth *= 3;
            // 50 for spiral spacing of 50mm
            const loopGap = 50 - ctx.lineWidth / 3;
            ctx.setLineDash([ctx.lineWidth / 3, loopGap]);

            ctx.beginPath();
            ctx.moveTo(ax1, ay1);
            ctx.lineTo(ax2, ay2);
            ctx.stroke();
            ctx.beginPath();
            ctx.moveTo(bx1, by1);
            ctx.lineTo(bx2, by2);
            ctx.stroke();

            ctx.setLineDash(oldLineDash);
          }

          // For circular ducts, do a dotted line in the middle
          ctx.beginPath();
          const oldDash = ctx.getLineDash();
          ctx.lineWidth = Math.max(1 / s, baseWidth / 12);
          ctx.setLineDash([3, 5, 10, 5]);
          ctx.moveTo(render.worldEndpoints[0].x, render.worldEndpoints[0].y);
          ctx.lineTo(render.worldEndpoints[1].x, render.worldEndpoints[1].y);
          ctx.stroke();
          ctx.setLineDash(oldDash);
          break;
        }
        case "externalArrow": {
          ctx.strokeStyle = baseColor;
          ctx.fillStyle = baseColor;

          const endpoints = [
            render.worldByUid[layer.aUid],
            render.worldByUid[layer.bUid],
          ];

          const gap = baseWidth * 10;
          const vecRaw = Flatten.vector([
            endpoints[1].x - endpoints[0].x,
            endpoints[1].y - endpoints[0].y,
          ]);
          const vec = vecRaw.normalize();
          const orth = vec.rotate90CCW();

          let i = 0;
          for (let dist = gap; dist < vecRaw.length; dist += gap) {
            i++;
            if (i > MAX_CONDUIT_ARROWS) {
              break;
            }
            const base = { x: endpoints[0].x, y: endpoints[0].y };
            base.x += vec.x * dist;
            base.y += vec.y * dist;

            ctx.beginPath();
            if (layer.directed || i % 2 === 0) {
              base.x -= vec.x * baseWidth * 1.25;
              base.y -= vec.y * baseWidth * 1.25;
              ctx.moveTo(base.x, base.y);
              ctx.lineTo(
                base.x + orth.x * baseWidth * 1.5,
                base.y + orth.y * baseWidth * 1.5,
              );
              ctx.lineTo(
                base.x + vec.x * baseWidth * 2.5,
                base.y + vec.y * baseWidth * 2.5,
              );
              ctx.lineTo(
                base.x - orth.x * baseWidth * 1.5,
                base.y - orth.y * baseWidth * 1.5,
              );
            } else {
              ctx.moveTo(base.x, base.y);
              ctx.lineTo(
                base.x + orth.x * baseWidth * 1.5,
                base.y + orth.y * baseWidth * 1.5,
              );
              ctx.lineTo(
                base.x - vec.x * baseWidth * 2.5,
                base.y - vec.y * baseWidth * 2.5,
              );
              ctx.lineTo(
                base.x - orth.x * baseWidth * 1.5,
                base.y - orth.y * baseWidth * 1.5,
              );
            }

            ctx.fill();
          }

          break;
        }
        case "internalArrow": {
          if (s < INTERNAL_ARROW_MIN_SCALE_DRAWN && !this.isHovering) {
            continue;
          }

          ctx.strokeStyle = layer.color || "white";
          ctx.fillStyle = layer.color || "white";

          let scale = 1;
          if (typeof layer.scale === "function") {
            scale = layer.scale(baseWidth, graphics.worldToSurfaceScale);
          } else {
            scale = layer.scale || 1;
          }

          const endpoints = [
            render.worldByUid[layer.aUid],
            render.worldByUid[layer.bUid],
          ];

          const gap = baseWidth * 15 * (scale > 1 ? 2 : 1);
          const vecRaw = Flatten.vector([
            endpoints[1].x - endpoints[0].x,
            endpoints[1].y - endpoints[0].y,
          ]);
          const vec = vecRaw.normalize();
          const orth = vec.rotate90CCW();

          let i = 0;
          if (layer.bUid.includes("ed2fb617-18f5-4005-ae4e-3d1b5fffe677")) {
            console.log("Drawing internal arrow", {
              layer,
              length: vecRaw.length,
            });
          }
          for (let dist = gap; dist < vecRaw.length; dist += gap) {
            i++;
            if (i > MAX_CONDUIT_ARROWS) {
              break;
            }
            const base = { x: endpoints[0].x, y: endpoints[0].y };
            base.x += vec.x * dist;
            base.y += vec.y * dist;

            const INTERNAL_ARROW_HALF_WIDTH = 0.55 * scale;
            const INTERNAL_ARROW_SPEAR_LENGTH = 0.9 * scale;
            const INTERNAL_ARROW_TAIL_HALF_WIDTH = 0.15 * scale;
            const INTERNAL_ARROW_TAIL_LENGTH = 1.5 * scale;

            ctx.beginPath();
            if (layer.directed || i % 2 === 0) {
              base.x -= vec.x * baseWidth * INTERNAL_ARROW_HALF_WIDTH;
              base.y -= vec.y * baseWidth * INTERNAL_ARROW_HALF_WIDTH;
              ctx.moveTo(base.x, base.y);
              ctx.lineTo(
                base.x + orth.x * baseWidth * INTERNAL_ARROW_HALF_WIDTH,
                base.y + orth.y * baseWidth * INTERNAL_ARROW_HALF_WIDTH,
              );
              ctx.lineTo(
                base.x + vec.x * baseWidth * INTERNAL_ARROW_SPEAR_LENGTH,
                base.y + vec.y * baseWidth * INTERNAL_ARROW_SPEAR_LENGTH,
              );
              ctx.lineTo(
                base.x - orth.x * baseWidth * INTERNAL_ARROW_HALF_WIDTH,
                base.y - orth.y * baseWidth * INTERNAL_ARROW_HALF_WIDTH,
              );
              ctx.lineTo(
                base.x - orth.x * baseWidth * INTERNAL_ARROW_TAIL_HALF_WIDTH,
                base.y - orth.y * baseWidth * INTERNAL_ARROW_TAIL_HALF_WIDTH,
              );
              ctx.lineTo(
                base.x -
                  vec.x * baseWidth * INTERNAL_ARROW_TAIL_LENGTH -
                  orth.x * baseWidth * INTERNAL_ARROW_TAIL_HALF_WIDTH,
                base.y -
                  vec.y * baseWidth * INTERNAL_ARROW_TAIL_LENGTH -
                  orth.y * baseWidth * INTERNAL_ARROW_TAIL_HALF_WIDTH,
              );
              ctx.lineTo(
                base.x -
                  vec.x * baseWidth * INTERNAL_ARROW_TAIL_LENGTH +
                  orth.x * baseWidth * INTERNAL_ARROW_TAIL_HALF_WIDTH,
                base.y -
                  vec.y * baseWidth * INTERNAL_ARROW_TAIL_LENGTH +
                  orth.y * baseWidth * INTERNAL_ARROW_TAIL_HALF_WIDTH,
              );
              ctx.lineTo(
                base.x + orth.x * baseWidth * INTERNAL_ARROW_TAIL_HALF_WIDTH,
                base.y + orth.y * baseWidth * INTERNAL_ARROW_TAIL_HALF_WIDTH,
              );
            } else {
              ctx.moveTo(base.x, base.y);
              ctx.lineTo(
                base.x + orth.x * baseWidth * INTERNAL_ARROW_HALF_WIDTH,
                base.y + orth.y * baseWidth * INTERNAL_ARROW_HALF_WIDTH,
              );
              ctx.lineTo(
                base.x - vec.x * baseWidth * INTERNAL_ARROW_SPEAR_LENGTH,
                base.y - vec.y * baseWidth * INTERNAL_ARROW_SPEAR_LENGTH,
              );
              ctx.lineTo(
                base.x - orth.x * baseWidth * INTERNAL_ARROW_HALF_WIDTH,
                base.y - orth.y * baseWidth * INTERNAL_ARROW_HALF_WIDTH,
              );
            }

            ctx.fill();
          }

          break;
        }
        case "highlight": {
          ctx.beginPath();
          if (typeof layer.widthBU === "number") {
            ctx.lineWidth = layer.widthBU;
          } else {
            ctx.lineWidth = layer.widthBU(baseWidth, s);
          }
          if (typeof layer.color === "function") {
            ctx.strokeStyle = layer.color(baseColor);
          } else {
            let color = layer.color || baseColor;
            if (layer.lighten !== undefined || layer.alpha !== undefined) {
              color = lighten(color, layer.lighten || 0, layer.alpha);
            }
            ctx.strokeStyle = color;
          }

          ctx.moveTo(render.worldEndpoints[0].x, render.worldEndpoints[0].y);
          ctx.lineTo(render.worldEndpoints[1].x, render.worldEndpoints[1].y);
          ctx.stroke();
          break;
        }
        case "tilde": {
          const vec = Flatten.vector([bw.x - aw.x, bw.y - aw.y]).normalize();
          let orth = vec.rotate90CCW();

          if (!layer.rendered) {
            throw new Error("Tilde layer must be rendered before drawing");
          }

          for (const side of [0, 1]) {
            orth = orth.rotate90CW().rotate90CW();
            const pixelLength = render.computedLengthM * 1000;
            if (pixelLength < layer.amplitudeS * 4) return;

            ctx.beginPath();
            const amplitudeO = layer.amplitudeS / s;

            ctx.strokeStyle = layer.color;
            ctx.lineWidth = layer.lineWidthS / s;
            let i = 0;

            let distanceO = 1;
            if (typeof layer.distanceBU === "number") {
              distanceO = layer.distanceBU * baseWidth;
            } else if (typeof layer.distanceBU === "function") {
              distanceO = layer.distanceBU(baseWidth, s);
            }

            for (
              let run =
                layer.rendered.adjustments[0][1 - side] * Math.abs(distanceO);
              run <
              pixelLength +
                layer.rendered.adjustments[1][side] * Math.abs(distanceO);
              run += amplitudeO / 2
            ) {
              i++;
              const base = { x: aw.x, y: aw.y };
              base.x += vec.x * run;
              base.y += vec.y * run;
              base.x += orth.x * distanceO;
              base.y += orth.y * distanceO;

              if (i % 2 === 0) {
                base.x += (orth.x * amplitudeO) / 4;
                base.y += (orth.y * amplitudeO) / 4;
              } else {
                base.x -= (orth.x * amplitudeO) / 4;
                base.y -= (orth.y * amplitudeO) / 4;
              }

              ctx.lineTo(base.x, base.y);
            }
            ctx.stroke();
          }
          break;
        }
        case "overlapped": {
          const vec = Flatten.vector([bw.x - aw.x, bw.y - aw.y]).normalize();
          let orth = vec.rotate90CCW();

          for (const _side of [0, 1]) {
            orth = orth.rotate90CW().rotate90CW();
            const pixelLength = render.computedLengthM * 1000;
            if (pixelLength < layer.amplitudeS * 4) return;

            ctx.beginPath();
            const amplitudeO = layer.amplitudeS / s;

            ctx.strokeStyle = layer.color;
            ctx.lineWidth = layer.lineWidthS / s;
            let i = 0;

            let distanceO = 1;
            if (typeof layer.distanceBU === "number") {
              distanceO = layer.distanceBU * baseWidth;
            } else if (typeof layer.distanceBU === "function") {
              distanceO = layer.distanceBU(baseWidth, s);
            }

            for (
              let run = layer.offset[0];
              run < layer.offset[1];
              run += amplitudeO / 2
            ) {
              i++;
              const base = { x: aw.x, y: aw.y };
              base.x += vec.x * run;
              base.y += vec.y * run;
              base.x += orth.x * distanceO;
              base.y += orth.y * distanceO;

              if (i % 2 === 0) {
                base.x += (orth.x * amplitudeO) / 4;
                base.y += (orth.y * amplitudeO) / 4;
              } else {
                base.x -= (orth.x * amplitudeO) / 4;
                base.y -= (orth.y * amplitudeO) / 4;
              }

              ctx.lineTo(base.x, base.y);
            }
            ctx.stroke();
          }

          break;
        }
        case "underlapped": {
          const vec = Flatten.vector([bw.x - aw.x, bw.y - aw.y]).normalize();
          let orth = vec.rotate90CCW();

          for (const _side of [0, 1]) {
            orth = orth.rotate90CW().rotate90CW();

            ctx.beginPath();

            ctx.strokeStyle = layer.color;
            ctx.lineWidth = baseWidth / 1.5;

            ctx.lineTo(
              aw.x + vec.x * layer.offset[0] + orth.x * (baseWidth + 3),
              aw.y + vec.y * layer.offset[0] + orth.y * (baseWidth + 3),
            );
            ctx.lineTo(
              aw.x + vec.x * layer.offset[1] + orth.x * (baseWidth + 3),
              aw.y + vec.y * layer.offset[1] + orth.y * (baseWidth + 3),
            );

            ctx.stroke();
          }

          break;
        }
        case "lineDash": {
          ctx.setLineDash(layer.pattern.map((x) => x * baseWidth));
          break;
        }
        case "greyify": {
          break;
        }
        default:
          assertUnreachable(layer);
      }
    }
    if (isGrayify) {
      console.log("Setting back");
      ctx.filter = ``;
    }
    ctx.setLineDash([]);

    if (aw.x === bw.x && aw.y === bw.y) {
      // Because flatten throws an error when creating a line with two equal points, we make a point here instead.
      this.lastDrawnLine = new Flatten.Point(aw.x, aw.y);
    } else {
      this.lastDrawnLine = new Flatten.Segment(
        new Flatten.Point(aw.x, aw.y),
        new Flatten.Point(bw.x, bw.y),
      );
    }

    // Display Entity Name
    const drawEntityName = (
      ctx: CanvasRenderingContext2D,
      name: string,
      baseFontSz: number,
      baseColor: string,
      backgroundColor: string,
    ) => {
      this.prepareTransformForName(context);
      ctx.font = baseFontSz + "pt " + DEFAULT_FONT_NAME;

      const nameWidth = ctx.measureText(name).width;
      const offsetx = -nameWidth / 2;
      const offsetY = baseFontSz;

      ctx.fillStyle = backgroundColor;
      ctx.fillRect(offsetx, offsetY, nameWidth, baseFontSz);

      ctx.fillStyle = baseColor;
      ctx.fillTextStable(name, offsetx, offsetY - 4, undefined, "top");
    };

    if (this.entity.entityName) {
      drawEntityName(
        ctx,
        this.entity.entityName,
        70,
        baseColor,
        "rgba(0, 255, 20, 0.13)",
      );
    }
  }

  displayObject(): ConduitEntity {
    return fillDefaultConduitFields(this.context, this.entity);
  }

  prepareDelete(
    context: CanvasContext,
    _calleeEntityUid?: string,
  ): DrawableObjectConcrete[] {
    const result: DrawableObjectConcrete[] = [this];
    const origEndpoints = cloneSimple(this.entity.endpointUid);
    if (this.globalStore instanceof GlobalStore) {
      context.$store.dispatch("document/updatePipeEndpoints", {
        entity: this.entity,
        endpoints: [undefined, undefined],
      });
      for (let i = 0; i < 2; i++) {
        if (!origEndpoints[i]) {
          // this edge case happens occasionally with
          // |
          // |
          // +--+
          // |
          // |
          // and deleting the middle. The pipe's delete function for one of
          // the straight ones will be called twice. The first time it will
          // make the endpoint undefined, the second time it will crash if
          // we don't check for undefined.
          continue;
        }
        const a = this.drawableStore.get(origEndpoints[i]);
        if (isConnectableObject(a)) {
          result.push(...a.prepareDeleteConnection(this.entity.uid, context));
        } else {
          throw new Error(
            "endpoint non existent on pipe. non existing is " +
              JSON.stringify(a) +
              " " +
              JSON.stringify(origEndpoints) +
              " entity is " +
              JSON.stringify(this.entity) +
              " " +
              JSON.stringify(a ? a.entity : undefined),
          );
        }
      }

      const termini = this.globalStore.getTerminiByEdge(this.uid);
      for (const term of termini) {
        const t = this.globalStore.get(term) as DrawableDamper;
        if (t) {
          result.push(...t.prepareDelete(context));
        }
      }
    } else {
      throw new Error("Can only delete with global store");
    }
    return result;
  }

  offerInteraction(interaction: Interaction): DrawableEntityConcrete[] | null {
    if (!this.isActive()) {
      return null;
    }

    switch (interaction.type) {
      case InteractionType.INSERT:
        if (
          interaction.systemUid &&
          !flowSystemsCompatible(
            interaction.systemUid,
            this.entity.systemUid,
            this.document.drawing,
          )
        ) {
          return null;
        }
        return [this.entity];
      case InteractionType.SNAP_ONTO_RECEIVE: {
        if (this.entity.endpointUid.indexOf(interaction.src.uid) !== -1) {
          return null;
        }
        // We can receive valves.
        if (isConnectableEntity(interaction.src)) {
          const systemUid = determineConnectableSystemUid(
            this.globalStore,
            interaction.src,
          );

          if (
            systemUid &&
            !flowSystemsCompatible(
              systemUid,
              this.entity.systemUid,
              this.document.drawing,
            )
          ) {
            return null;
          }

          return [this.entity];
        } else {
          return null;
        }
      }
      case InteractionType.CONTINUING_CONDUIT:
      case InteractionType.STARTING_CONDUIT:
        if (
          interaction.system &&
          !flowSystemsCompatible(
            interaction.system.uid,
            this.entity.systemUid,
            this.document.drawing,
          )
        ) {
          return null;
        }
        if (
          interaction.configuration &&
          !pipeConfigurationCompatible(
            interaction.configuration,
            this.effectiveConfiguration,
          )
        ) {
          return null;
        }
        return [this.entity];
      case InteractionType.SNAP_ONTO_SEND:
        return null;
      case InteractionType.EXTEND_NETWORK:
        if (
          this.globalStore.get(this.entity.endpointUid[0])!.type ===
            EntityType.SYSTEM_NODE ||
          this.globalStore.get(this.entity.endpointUid[1])!.type ===
            EntityType.SYSTEM_NODE
        ) {
          if (this.computedLengthM < PIPE_STUB_MAX_LENGTH_MM / 1000) {
            // we can't be the stub pipe of the system node
            return null;
          }
        }
        if (
          interaction.systemUid === null ||
          flowSystemsCompatible(
            interaction.systemUid,
            this.entity.systemUid,
            this.document.drawing,
          )
        ) {
          return [this.entity];
        } else {
          return null;
        }
      case InteractionType.LINK_ENTITY:
        return null;
    }
  }

  get effectiveConfiguration() {
    if (!isPipeEntity(this.entity)) {
      return null;
    }
    let calc = this.context.globalStore.getCalculation(this.entity);
    if (this.document.uiState.alwaysShowCalculations) {
      calc = undefined;
    }
    if (
      this.entity.conduit.configurationCosmetic ===
        PipeConfiguration.RETURN_IN &&
      calc?.totalPeakFlowRateLS === null
    ) {
      if (calc?.configuration === PipeConfiguration.RETURN_OUT) {
        return PipeConfiguration.RETURN_OUT;
      }
    } else if (calc?.configuration) {
      return calc.configuration;
    }
    return this.entity.conduit.configurationCosmetic;
  }

  protected refreshObjectInternal(_obj: ConduitEntity): void {
    //
  }

  getHoverSiblings(): HoverSiblingResult[] {
    const result: HoverSiblingResult[] = [];

    const lCalc = this.context.globalStore.getLiveCalculation(this.entity);
    if (lCalc && lCalc.cycle) {
      const cycle = this.globalStore.objectsInCycle.get(lCalc.cycle);
      if (!cycle) {
        return result;
      }
      for (const eUid of cycle) {
        const e = this.globalStore.get(eUid);
        if (e && e.type === EntityType.CONDUIT) {
          result.push({
            object: this.globalStore.get<DrawableConduit>(e.uid),
            cascade: false,
          });
          result.push({
            object: this.globalStore.get(e.entity.endpointUid[0]),
            cascade: false,
          });
          result.push({
            object: this.globalStore.get(e.entity.endpointUid[1]),
            cascade: false,
          });
        }
      }
    }

    return result;
  }

  getPopupContent() {
    const e = this.entity;
    switch (this.entity.conduitType) {
      case "pipe":
        const lCalc = this.context.globalStore.getOrCreateLiveCalculation(
          this.entity,
        );
        const result: EntityPopupContent[] = [];
        if (!lCalc.connected) {
          result.push(LiveWarnings.UNCONNECTED_PIPES);
        }

        if (lCalc.warnings) {
          for (const warning of lCalc.warnings) {
            switch (warning.type) {
              case "ISOLATION_VALVES_REQUIRED_ON_RING_MAIN":
                result.push(LiveWarnings.RING_MAIN_NON_RECIRCULATION);
                break;
            }
          }
        }

        if (lCalc.unbalanced) {
          if (isMechanical(getFlowSystem(this.context.drawing, e.systemUid))) {
            result.push(LiveWarnings.MISSING_LOCKSHIELD_VALVE_FOR_RETURN);
          } else {
            result.push(LiveWarnings.MISSING_BALANCING_VALVE_FOR_RETURN);
          }
        }

        if (lCalc.overlapped) {
          result.push(LiveWarnings.OVERLAPPING_PIPES);
        }

        return result;
      case "duct":
        return null;
      case "cable":
        return null;
    }
  }
}

export interface AngleSpatialIndex extends BBox {
  uid: string;
}

// this is a map of levelUid to a map of angle to r tree
// each angle is a bucket for conduit angles
const pipeIndex = new Map<string, Map<number, RBush<AngleSpatialIndex>>>();
// the number of r trees (or bucket angles)
const NUM_BUCKETS = 45;
const COLLISION_TOLERANCE = 10;
const SHAPE_COLLISION_TOLERANCE = 15;
const ANGLE_TOLERANCE = 0.1;

function indexKeyToRadian(indexKey: number) {
  return indexKey * (180 / NUM_BUCKETS) * (Math.PI / 180);
}

export function initializePipeSpatialIndex(globalStore: GlobalStore) {
  pipeIndex.clear();
  const updateQueue = new Set(globalStore.keys());
  updatePipeSpatialIndex(globalStore, updateQueue);
}

export function updatePipeSpatialIndex(
  globalStore: GlobalStore,
  updateQueue: Set<string>,
) {
  for (const uid of updateQueue) {
    const o = globalStore.get(uid);
    if (o && isPipeEntity(o.entity)) {
      // convert 2pi range to pi range (we don't care about direction)
      const angleRad = (o.shape as Flatten.Segment).slope % Math.PI;

      // convert to angle
      const angleDeg = angleRad * (180 / Math.PI);
      const indexKey = Math.floor(angleDeg / (180 / NUM_BUCKETS));

      // rotate around the origin to make the bounding box flat
      const rotated = (o.shape as Flatten.Segment).rotate(
        -indexKeyToRadian(indexKey),
      );

      // add to spatial index
      const lvlUid = globalStore.levelOfEntity.get(uid)!;
      if (!pipeIndex.has(lvlUid)) {
        pipeIndex.set(lvlUid, new Map());
      }

      if (!pipeIndex.get(lvlUid)!.has(indexKey)) {
        pipeIndex.get(lvlUid)!.set(indexKey, new RBush<AngleSpatialIndex>());
      }

      pipeIndex.get(lvlUid)!.get(indexKey)!.insert({
        minX: rotated.box.xmin,
        minY: rotated.box.ymin,
        maxX: rotated.box.xmax,
        maxY: rotated.box.ymax,
        uid,
      });
    }
  }

  (window as any).H2X_CANVAS_DEBUG.pipeIndex = pipeIndex;
}

type OverlapResult = OverlapResultSuccess | OverlapResultFailure;

interface OverlapResultFailure {
  overlapped: false;
}

interface OverlapResultSuccess {
  overlapped: true;
  underlapped: boolean;
  overlappedSegment: [number, number]; // offset from start of pipe
}

export function detectOverlap(
  uid: string,
  globalStore: GlobalStore,
): OverlapResult {
  const o = globalStore.get(uid);
  if (o && isPipeEntity(o.entity)) {
    const lvlUid = globalStore.levelOfEntity.get(uid)!;
    const angleRad = (o.shape as Flatten.Segment).slope % Math.PI;

    // convert to angle
    const angleDeg = angleRad * (180 / Math.PI);
    const indexKey = Math.floor(angleDeg / (180 / NUM_BUCKETS));

    // find the keys to search for in r trees
    const indexKeyPlus1 = (indexKey + 1) % NUM_BUCKETS;
    const indexKeyMinus1 = (indexKey - 1 + NUM_BUCKETS) % NUM_BUCKETS;
    const keysToCheck = [indexKey, indexKeyPlus1, indexKeyMinus1];

    for (const key of keysToCheck) {
      if (!pipeIndex.has(lvlUid) || !pipeIndex.get(lvlUid)!.has(key)) {
        continue;
      }

      const rTree = pipeIndex.get(lvlUid)!.get(key)!;

      // rotate the object with respect to the index
      const rotated = (o.shape as Flatten.Segment).rotate(
        -indexKeyToRadian(key),
      );

      const candidates = rTree.search({
        minX: rotated.box.xmin + 2,
        minY: rotated.box.ymin - COLLISION_TOLERANCE,
        maxX: rotated.box.xmax - 2,
        maxY: rotated.box.ymax + COLLISION_TOLERANCE,
      });

      for (const candidate of candidates) {
        if (candidate.uid !== uid) {
          const m = globalStore.get(candidate.uid);

          if (!m || !m.shape) {
            continue;
          }

          if (!isPipeEntity(m.entity)) {
            continue;
          }

          if (m.entity.systemUid !== o.entity.systemUid) {
            continue;
          }

          if (
            !(o.shape instanceof Flatten.Segment) ||
            !(m.shape instanceof Flatten.Segment)
          ) {
            continue;
          }

          // make sure the two segments have roughly the same slope
          const angleDiff = Math.abs(m.shape.slope - o.shape.slope) % Math.PI;
          if (
            angleDiff > ANGLE_TOLERANCE &&
            Math.PI - angleDiff > ANGLE_TOLERANCE
          ) {
            continue;
          }

          // check if the two pipes are close enough
          // then find the overlapping segments
          const other = m.shape;
          const dist1 = other.pe.distanceTo(o.shape);
          const dist2 = other.ps.distanceTo(o.shape);
          if (
            dist1[0] < SHAPE_COLLISION_TOLERANCE ||
            dist2[0] < SHAPE_COLLISION_TOLERANCE
          ) {
            const d1 = o.shape.start.distanceTo(dist1[1].end)[0];
            const d2 = o.shape.start.distanceTo(dist2[1].end)[0];

            const overlappedSegment = [d1, d2].sort((a, b) => a - b) as [
              number,
              number,
            ];

            return {
              overlapped: true,
              underlapped:
                o.entity.heightAboveFloorM !== m.entity.heightAboveFloorM,
              overlappedSegment,
            };
          }
        }
      }
    }
  }

  return {
    overlapped: false,
  };
}
