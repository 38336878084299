import {
  CORE_FEATURE_FLAG_DEFAULTS,
  CoreFeatureFlags,
} from "../lib/feature-flags";
import { GlobalStore } from "../lib/globalstore/global-store";
import { DeepPartial, assertUnreachable, cloneSimple } from "../lib/utils";
import { NodeProps } from "../models/CustomEntity";
import { FeatureAccess, defaultFeatureAccess } from "../models/FeatureAccess";
import { MarketplaceItemVersion } from "../models/marketplace/MarketplaceItemVersion";
import { CoreContext } from "./calculations/types";
import { CatalogsByLocale } from "./catalog";
import { defaultPriceTable } from "./catalog/default-price-table";
import { PriceTable } from "./catalog/price-table";
import { Catalog } from "./catalog/types";
import CoreObjectFactory from "./document/coreObjectFactory";
import { DrawingState } from "./document/drawing";
import { applyDiffNative } from "./document/state-ot-apply";
import { initialNodes } from "./initialNodes";
import { SupportedLocales } from "./locale";
import { MarketplaceSchemaType } from "./marketplace/marketplace";

export function buildCoreContext(options: {
  drawing: DrawingState;
  locale: SupportedLocales;
  customNodes: NodeProps[];
  catalog: Catalog;
  featureAccess: FeatureAccess | null;
  featureFlags?: CoreFeatureFlags;
  storeNetworkEntities?: boolean;
}): CoreContext {
  const globalStore = new GlobalStore();

  const context = {
    globalStore,
    catalog: options.catalog,
    drawing: options.drawing,
    locale: options.locale,
    nodes: buildNodes(options.customNodes),
    priceTable: buildPriceTable(options.drawing.metadata.priceTable),
    featureAccess: options.featureAccess ?? defaultFeatureAccess,
    featureFlags: options.featureFlags ?? CORE_FEATURE_FLAG_DEFAULTS,
  } satisfies CoreContext;

  for (const entity of Object.values(options.drawing.shared)) {
    CoreObjectFactory.build(entity, context, null);
  }
  for (const [lvlId, lvl] of Object.entries(options.drawing.levels)) {
    for (const entity of Object.values(lvl.entities)) {
      CoreObjectFactory.build(entity, context, lvlId);
    }
  }

  if (options.storeNetworkEntities) {
    const coreObjects = Array.from(globalStore.values());
    for (const coreObject of coreObjects) {
      for (const entity of coreObject.getCalculationEntities(context)) {
        CoreObjectFactory.build(
          entity,
          context,
          globalStore.levelOfEntity.get(entity.uid) || null,
        );
      }
    }
  }

  return context;
}

export function buildNodes(nodes: NodeProps[]) {
  for (var i = 0; i < initialNodes.length; i++) {
    if (nodes?.findIndex((node) => node.uid === initialNodes[i].uid) === -1) {
      nodes.splice(i, 0, initialNodes[i]);
    }
  }

  if (nodes && nodes.length) {
    return nodes;
  } else {
    return initialNodes;
  }
}

export function buildCatalog(
  locale: SupportedLocales,
  marketplaceItems: MarketplaceItemVersion[],
) {
  const catalog = cloneSimple(CatalogsByLocale[locale]);

  for (const item of marketplaceItems) {
    switch (item.marketplaceItem.schemaType) {
      case MarketplaceSchemaType.RADIATORS:
        catalog.heatEmitters.radiators.manufacturer.push({
          name: item.marketplaceItem.title,
          abbreviation: item.marketplaceItem.title,
          priceTableName: "Radiator",
          uid: item.id,
        });
        catalog.heatEmitters.radiators.datasheet[item.id] = item.data;
        break;
      default:
        assertUnreachable(item.marketplaceItem.schemaType);
    }
  }

  return catalog;
}

export function buildPriceTable(priceTableDiff: DeepPartial<PriceTable>) {
  const initialPriceTable = cloneSimple(defaultPriceTable);

  return applyDiffNative(initialPriceTable, priceTableDiff);
}
