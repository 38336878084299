import { Units, UnitsContext } from "../../../../lib/measurements";
import { assertType } from "../../../../lib/utils";
import { ReturnCalculations } from "../../../calculations/returns";
import { CoreContext } from "../../../calculations/types";
import { getManufacturersByPlant } from "../../../catalog/manufacturers/utils";
import { isUnderfloor } from "../../../config";
import CorePlant from "../../../coreObjects/corePlant";
import { getTooltip } from "../../../tooltips/tooltips";
import { FieldType, PropertyField } from "../property-field";
import { RoomType } from "../rooms/room-entity";
import { EntityType } from "../types";
import { ConditionalPropertyField, filterConditionalFields } from "../utils";
import PlantEntity, { isManifold, plantHasSingleRating } from "./plant-entity";
import { ManifoldPlant, PlantType, PressureMethod } from "./plant-types";
import { PlantFieldHandlers } from "./types";
import {
  getActuatorModelOptions,
  getBallValveModelOptions,
  getBlendingValveModelOptions,
  getManifoldModelOptions,
  getManifoldRangeOptions,
  getPumpPackModelOptions,
  getUfhPumpModelOptions,
  isManifoldPlantEntity,
  isMultiOutlets,
  isReadonly,
  isVentilationPlant,
} from "./utils";

export function createManifoldDimensionsTabFields(
  filled: PlantEntity,
  context: CoreContext,
): PropertyField[] {
  const { drawing } = context;
  assertType<ManifoldPlant>(filled.plant);
  const isManifoldManufacturerGeneric =
    filled.plant.manifoldManufacturer === "generic";

  const res: ConditionalPropertyField[] = [
    {
      property: "plant.color",
      title: "Color",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Color,
      multiFieldId: "plant-color",
      isShown: true,
      params: null,
    },
    {
      property: "widthMM",
      title: "Width",
      hasDefault: isManifoldManufacturerGeneric,
      isCalculated: !isManifoldManufacturerGeneric,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: null,
      units: Units.Millimeters,
      readonly: isReadonly(drawing, filled),
      isShown: true,
    },

    {
      property: "depthMM",
      title: "Depth",
      hasDefault: isManifoldManufacturerGeneric,
      isCalculated: !isManifoldManufacturerGeneric,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: null,
      units: Units.Millimeters,
      isShown: true,
    },
  ];
  return filterConditionalFields(res);
}

export function createManifoldTechnicalTabFields(
  filled: PlantEntity,
  context: CoreContext,
  original: PlantEntity,
  handlers?: PlantFieldHandlers,
): PropertyField[] {
  if (!isManifoldPlantEntity(filled)) return [];
  if (!isManifoldPlantEntity(original)) return [];
  const { drawing, catalog } = context;
  const liveCalcs = context.globalStore.getOrCreateLiveCalculation(filled);
  const isPressureMethodFixedPressureLoss =
    !isMultiOutlets(filled.plant) &&
    !isVentilationPlant(filled.plant, drawing.metadata.flowSystems) &&
    filled.plant.pressureLoss.pressureMethod ===
      PressureMethod.FIXED_PRESSURE_LOSS;
  const isFlowRateLoad =
    plantHasSingleRating(filled.plant) &&
    filled.plant.rating.type === "flow-rate";
  const isKWLoad =
    plantHasSingleRating(filled.plant) && filled.plant.rating.type === "energy";
  const isManifoldCapacityRateSpecified =
    filled.plant.type === PlantType.MANIFOLD &&
    filled.plant.capacityRateLKW !== null;
  const isManifoldVolumeSpecified =
    filled.plant.type === PlantType.MANIFOLD && filled.plant.volumeL !== null;
  const ufhFlowSystems = context.drawing.metadata.flowSystemUidsInOrder
    .map((u) => context.drawing.metadata.flowSystems[u])
    .filter(isUnderfloor);

  const actuatorManufacturers = getManufacturersByPlant(
    filled.plant,
    catalog,
    "actuator",
  );
  const isManifoldManufGeneric =
    filled.plant.manifoldManufacturer === "generic";
  const isBallValveManufGeneric =
    filled.plant.ballValveManufacturer === "generic";
  const isActuatorManufGeneric =
    filled.plant.actuatorManufacturer === "generic";
  const isPumpPackManufGeneric =
    filled.plant.pumpPackManufacturer === "generic";
  const manifoldManufacturers = getManufacturersByPlant(
    filled.plant,
    catalog,
    "manifold",
  );
  const ballValveManufacturers = getManufacturersByPlant(
    filled.plant,
    catalog,
    "ballValve",
  );
  const pumpPackManufacturers = getManufacturersByPlant(
    filled.plant,
    catalog,
    "pumpPack",
  );
  // Ignore ufhPump and blending valve manufacturer option for now as it will
  // clutter the UI and is not really needed until we have them separately available.
  const manifoldRanges = getManifoldRangeOptions(
    context,
    filled.plant.manifoldManufacturer!,
  );
  const manifoldModels = getManifoldModelOptions(
    context,
    filled.plant.manifoldManufacturer!,
    filled.plant.manifoldRange!,
    (model) => model.outletsCount >= liveCalcs.manifoldLoopStats.length,
  );
  const ballValveModels = getBallValveModelOptions(
    context,
    filled.plant.ballValveManufacturer!,
    (model) => filled.plant.hasRecirculationPump === model.needsPumpPack,
  );
  const actuatorModels = getActuatorModelOptions(
    context,
    filled.plant.actuatorManufacturer!,
  );
  const pumpPackModels = getPumpPackModelOptions(
    context,
    filled.plant.pumpPackManufacturer!,
  );
  const ufhPumpOptions = getUfhPumpModelOptions(
    context,
    filled.plant.pumpPackManufacturer!,
    filled.plant.pumpPackModel!,
  );
  const blendingValveOptions = getBlendingValveModelOptions(
    context,
    filled.plant.pumpPackManufacturer!,
    filled.plant.pumpPackModel!,
  );
  const ufhv3Enabled = context.featureAccess.fullUnderfloorHeatingLoops;

  const levelUid = context.globalStore.levelOfEntity.get(filled.uid) ?? null;
  const res: ConditionalPropertyField[] = [
    {
      // Ghost property
      property: "manifoldRoomsSelection",
      title: "Connected Rooms",
      type: FieldType.EntityPicker,
      params: {
        type: "multiple",
        entityTypes: [EntityType.ROOM],
        levelUid,
        filter: (r) =>
          r.type === EntityType.ROOM && r.room.roomType === RoomType.ROOM,
        getOptionName: (r) =>
          (r.type === EntityType.ROOM && r.entityName) || "",
        getColor: (r) => {
          // pick the manifold's colour if connected
          if (r.type !== EntityType.ROOM) return null;
          if (r.room.roomType !== RoomType.ROOM) return null;

          if (!r.room.underfloorHeating.manifoldUid) return null;
          const manifold = context.globalStore.get<CorePlant>(
            r.room.underfloorHeating.manifoldUid,
          );
          if (!manifold) return null;

          assertType<ManifoldPlant>(manifold.entity.plant);
          return manifold.entity.plant.color.hex;
        },
      },
      units: Units.None,
      hasDefault: false,
      isCalculated: false,
      slot: true,
      multiFieldId: "manifoldRoomSelection",
      isShown: true,
    },
  ];

  if (ufhv3Enabled) {
    res.push(
      {
        type: FieldType.Button,
        property: `selectRooms`,
        title: "Room Selection Tool",
        hasDefault: false,
        isCalculated: false,
        multiFieldId: null,
        size: "sm",
        pill: false,
        variant: "primary",
        params: {
          handler: async () =>
            handlers?.selectRoomForManifoldHandler?.(context, filled.uid),
        },
        isShown: true,
      },
      {
        property: "plant.ufhMode",
        type: FieldType.Choice,
        params: {
          choices: [
            { name: "Approximate", key: "approximate" },
            { name: "Full Loops", key: "full" },
          ],
        },
        hasDefault: false,
        isCalculated: false,
        multiFieldId: "plant-ufhMode",
        title: "Underfloor Mode",
        isShown: true,
      },
      {
        property: "plant.ufhSystemUid",
        title: "Underfloor Flow System",
        hasDefault: false,
        isCalculated: false,
        type: FieldType.FlowSystemChoice,
        params: {
          systems: ufhFlowSystems.filter(
            (v) => v.role === "underfloor-heating",
          ),
        },
        multiFieldId: "plant-ufhSystemUid",
        isShown: true,
      },
    );
  }

  res.push(
    {
      property: "plant.rating.type",
      type: FieldType.Choice,
      params: {
        choices: [
          { name: "Power", key: "energy" },
          { name: "Flow Rate", key: "flow-rate" },
        ],
      },
      hasDefault: false,
      isCalculated: false,
      multiFieldId: "plant-rating-type",
      title: "Rating Method",
      hint: getTooltip("Manifold", "Rating Method"),
      isShown: true,
      beforeSet: (newVal) => {
        const plantLiveCalculation =
          context.globalStore.getOrCreateLiveCalculation(filled);
        const obj = context.globalStore.get<CorePlant>(filled.uid);
        const plant = obj.entity.plant as ManifoldPlant;

        if (newVal === "flow-rate") {
          if (plant.rating.type === "energy") {
            if (
              plantLiveCalculation.returnDeltaC != null &&
              plantLiveCalculation.returnAverageC != null &&
              plant.rating.KW != null
            ) {
              plant.rating = {
                ...plant.rating,
                type: "flow-rate",
                LS: ReturnCalculations.KW2LS(
                  context,
                  obj.entity.inletSystemUid,
                  plant.rating.KW,
                  plantLiveCalculation.returnDeltaC,
                  plantLiveCalculation.returnAverageC,
                ),
              };
            } else {
              plant.rating = {
                ...plant.rating,
                type: "flow-rate",
                LS: null,
              };
            }
          }
        } else if (newVal === "energy") {
          if (plant.rating.type === "flow-rate") {
            if (
              plantLiveCalculation.returnDeltaC != null &&
              plantLiveCalculation.returnAverageC != null &&
              plant.rating.LS != null
            ) {
              plant.rating = {
                ...plant.rating,
                type: "energy",
                KW: ReturnCalculations.LS2KW(
                  context,
                  obj.entity.inletSystemUid,
                  plant.rating.LS,
                  plantLiveCalculation.returnDeltaC,
                  plantLiveCalculation.returnAverageC,
                ),
              };
            } else {
              plant.rating = {
                ...plant.rating,
                type: "energy",
                KW: null,
              };
            }
          }
        }
      },
    },
    {
      property: "plant.rating.KW",
      title: "Rating",
      hasDefault: false,
      isCalculated: true,
      type: FieldType.Number,
      params: {
        min: 0,
        max: null,
        initialValue: 0,
      },
      multiFieldId: "plant-ratingKW",
      units: Units.KiloWatts,
      isShown: isKWLoad,
      unitContext: UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
    },
    {
      property: "plant.rating.LS",
      title: "Flow Rate Rating",
      hasDefault: false,
      isCalculated: true,
      type: FieldType.Number,
      params: {
        min: 0,
        max: null,
        initialValue: 0,
      },
      multiFieldId: "plant-ratingLS",
      units: Units.LitersPerSecond,
      isShown: isFlowRateLoad,
    },
    {
      property: "plant.volumeL",
      title: "Volume",
      hint: getTooltip("Manifold", "Volume"),
      hasDefault: false,
      isCalculated: true,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "volumeL",
      units: Units.Liters,
      isShown: true,
    },
    {
      property: "plant.internalVolumeL",
      title: "Internal Volume",
      hint: getTooltip("Manifold", "Internal Volume"),
      hasDefault: false,
      isCalculated: true,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "internalVolumeL",
      units: Units.Liters,
      isShown: !isManifoldVolumeSpecified,
    },
    {
      property: "plant.capacityRateLKW",
      title: "Volume Rate",
      hint: getTooltip("Manifold", "Volume"),
      hasDefault: false,
      requiresInput: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "capacityRateLKW",
      units: Units.LitersPerKiloWatts,
      // It is a legacy option now - manifolds are replaced with proper underfloor heating systems
      // Show it if the entity needs it.
      isShown: isManifoldCapacityRateSpecified,
      unitContext: UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
    },
    {
      property: "plant.pressureLoss.pressureLossKPA",
      title: "Pressure Loss",
      hint: getTooltip("Manifold", "Pressure Drop"),
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "plant-pressureLoss-pressureLossKPA",
      units: Units.KiloPascals,
      isShown: isPressureMethodFixedPressureLoss,
    },
    {
      property: "plant.hasRecirculationPump",
      title: "Has Recirculation Pump",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Boolean,
      params: null,
      multiFieldId: "plant-hasRecirculationPump",
      isShown: ufhv3Enabled,
    },
    {
      property: "plant.manifoldManufacturer",
      title: "Manifold Manufacturer",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      params: {
        choices: manifoldManufacturers.map((m) => ({
          name: m.name,
          key: m.uid,
        })),
      },
      multiFieldId: "plant-manifoldManufacturer",
      isShown: ufhv3Enabled,
      beforeSet: () => {
        const obj = context.globalStore.get<CorePlant>(filled.uid);
        if (isManifold(obj.entity)) {
          // reset any manifold range or model selection if the manufacturer changes
          obj.entity.plant.manifoldRange = null;
          obj.entity.plant.manifoldModel = null;
        }
      },
    },
    {
      property: "plant.manifoldRange",
      title: "Manifold Range",
      hasDefault: false,
      isCalculated: true,
      type: FieldType.Choice,
      params: {
        choices: manifoldRanges,
      },
      multiFieldId: "plant-manifoldRange",
      isShown: ufhv3Enabled && !isManifoldManufGeneric,
      beforeSet: () => {
        const obj = context.globalStore.get<CorePlant>(filled.uid);
        if (isManifold(obj.entity)) {
          // reset any manifold model selection if the range changes
          obj.entity.plant.manifoldModel = null;
        }
      },
    },
    {
      property: "plant.manifoldModel",
      title: "Manifold Model",
      hasDefault: false,
      isCalculated: true,
      type: FieldType.Choice,
      params: {
        choices: manifoldModels,
      },
      multiFieldId: "plant-manifoldModel",
      isShown: ufhv3Enabled && !isManifoldManufGeneric,
    },
    {
      property: "plant.ballValveManufacturer",
      title: "Ball Valve Manufacturer",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      params: {
        choices: ballValveManufacturers.map((m) => ({
          name: m.name,
          key: m.uid,
        })),
      },
      beforeSet: () => {
        original.plant.ballValveModel = null;
      },
      multiFieldId: "plant-ballValveManufacturer",
      isShown: ufhv3Enabled,
    },
    {
      property: "plant.ballValveModel",
      title: "Ball Valve Model",
      hasDefault: false,
      isCalculated: true,
      type: FieldType.Choice,
      params: {
        choices: ballValveModels,
      },
      multiFieldId: "plant-ballValveModel",
      isShown: ufhv3Enabled && !isBallValveManufGeneric,
    },
    {
      property: "plant.actuatorManufacturer",
      title: "Actuator Manufacturer",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      params: {
        choices: actuatorManufacturers.map((m) => {
          return {
            name: m.name,
            key: m.uid,
          };
        }),
      },
      beforeSet: () => {
        original.plant.actuatorModel = null;
      },
      multiFieldId: "plant-actuatorManufacturer",
      isShown: ufhv3Enabled,
    },
    {
      property: "plant.actuatorModel",
      title: "Actuator Model",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      params: {
        choices: actuatorModels,
      },
      multiFieldId: "plant-actuatorModel",
      isShown: ufhv3Enabled && !isActuatorManufGeneric,
    },
    {
      property: "plant.pumpPackManufacturer",
      title: "Pump Pack Manufacturer",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      params: {
        choices: pumpPackManufacturers.map((m) => {
          return {
            name: m.name,
            key: m.uid,
          };
        }),
      },
      beforeSet: () => {
        original.plant.pumpPackModel = null;
        original.plant.pumpManufacturer = null;
        original.plant.pumpModel = null;
        original.plant.blendingValveManufacturer = null;
        original.plant.blendingValveModel = null;
      },
      multiFieldId: "plant-pumpPackManufacturer",
      isShown: ufhv3Enabled && filled.plant.hasRecirculationPump,
    },
    {
      property: "plant.pumpPackModel",
      title: "Pump Pack Model",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      params: {
        choices: pumpPackModels,
      },
      beforeSet: () => {
        original.plant.pumpManufacturer = null;
        original.plant.pumpModel = null;
        original.plant.blendingValveManufacturer = null;
        original.plant.blendingValveModel = null;
      },
      multiFieldId: "plant-pumpPackModel",
      isShown:
        ufhv3Enabled &&
        filled.plant.hasRecirculationPump &&
        !isPumpPackManufGeneric,
    },
    {
      // We will leave out the pump manufacturer for now as we are just selecting the pump
      // from the available options and the model alone should be enough.
      property: "plant.pumpModel",
      title: "Pump Model",
      hasDefault: false,
      isCalculated: true,
      type: FieldType.Choice,
      params: {
        choices: ufhPumpOptions,
      },
      beforeSet: (newVal) => {
        const selected = ufhPumpOptions.find((p) => p.key === newVal);
        original.plant.pumpManufacturer = selected?.manufacturer ?? null;
      },
      multiFieldId: "plant-pumpModel",
      isShown:
        ufhv3Enabled &&
        filled.plant.hasRecirculationPump &&
        filled.plant.pumpPackModel === null &&
        !isPumpPackManufGeneric,
    },

    {
      // We will leave out the blending valve manufacturer for now as we are just selecting the blending valve
      // from the available options and the model alone should be enough.
      property: "plant.blendingValveModel",
      title: "Blending Valve Model",
      hasDefault: false,
      isCalculated: true,
      type: FieldType.Choice,
      params: {
        choices: blendingValveOptions,
      },
      beforeSet: (newVal) => {
        const selected = blendingValveOptions.find((p) => p.key === newVal);
        original.plant.blendingValveManufacturer =
          selected?.manufacturer ?? null;
      },
      multiFieldId: "plant-blendingValveModel",
      isShown:
        ufhv3Enabled &&
        filled.plant.hasRecirculationPump &&
        filled.plant.pumpPackModel === null &&
        !isPumpPackManufGeneric,
    },

    {
      property: "plant.ufhFlowTempC",
      title: "UFH Flow Temperature",
      hasDefault: false,
      isCalculated: true,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "plant-ufhFlowTempC",
      units: Units.Celsius,
      isShown: true,
    },
    {
      property: "plant.ufhReturnTempC",
      title: "UFH Return Temperature",
      hasDefault: false,
      isCalculated: true,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "plant-ufhReturnTempC",
      units: Units.Celsius,
      isShown: true,
    },
  );
  return filterConditionalFields(res);
}
