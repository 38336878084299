import { assertUnreachable } from "../lib/utils";
import { HotKey } from "./HotKey";
import { Organization } from "./Organization";
import { UserBlock } from "./UserBlocks";

export const LATEST_EULA_VERSION = 2;

export enum AccessLevel {
  /** Can access all data on the platform */
  SUPERUSER = 0,
  /** A h2x member - can manage users and companies */
  ADMIN = 1,
  /** Customer managers - can manage company projects and users within their license. */
  MANAGER = 2,
  /** Regular user - has access to the product and their projects. */
  USER = 3,
}

export interface SignUpCreate {
  firstname: string;
  lastname: string;
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  subscribed?: boolean;
  organization?: string;
  accessLevel?: AccessLevel;
  temporaryUser?: boolean;
}

export interface AdminsCreate {
  firstname: string;
  lastname: string;
  username: string;
  email: string;
  password: string;
  subscribed?: boolean;
  organization: string;
  accessLevel: AccessLevel;
  temporaryUser?: boolean;
  forcePasswordChangeOnSignup: boolean;
  noInitialPassword: boolean;
}

export interface User {
  username: string;
  name: string;
  passwordHash: string;
  consecutiveFailedLoginAttempts: number;
  lastFailedLoginAttempt: Date | null;
  accessLevel: AccessLevel;
  email: string | null;
  subscribed: boolean;
  organization: Organization | null;
  organizationId: string | null;
  forcePasswordChange: boolean;
  eulaAcceptedVersion: number;
  eulaAcceptedOn: Date | null;
  lastActivityOn: Date | null;
  lastNoticeSeenOn: Date | null;
  temporaryOrganizationName: String | null;
  temporaryUser: boolean | null;
  email_verified_at: Date | null;
  email_verification_token: string | null;
  email_verification_dt: Date | null;
  password_reset_token: string | null;
  password_reset_dt: Date | null;
  lastname: string | null;
  hot_key: HotKey | null;
  activated: boolean;
  numBlocks: number;
}

export function canRemoveUserBlock(target: User, block: UserBlock, user: User) {
  if (target.username !== block.userUsername) {
    return false;
  }
  if (user.accessLevel > block.minUnblockLevel) {
    return false;
  }
  if (user.accessLevel >= AccessLevel.MANAGER) {
    return target.organizationId === user.organizationId;
  }
  return true;
}

export function canBlockUser(target: User, user: User) {
  if (target.username === user.username) {
    return false;
  }
  if (user.accessLevel > target.accessLevel) {
    return false;
  }
  if (user.accessLevel > AccessLevel.MANAGER) {
    return false;
  }
  if (user.accessLevel >= AccessLevel.MANAGER) {
    return target.organizationId === user.organizationId;
  }
  return true;
}

export function accessLevelEnumToLabel(level: AccessLevel) {
  switch (level) {
    case AccessLevel.SUPERUSER:
      return "Super User";
    case AccessLevel.ADMIN:
      return "Admin";
    case AccessLevel.MANAGER:
      return "Manager";
    case AccessLevel.USER:
      return "User";
  }
  assertUnreachable(level);
}
