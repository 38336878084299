import Flatten from "@flatten-js/core";
import * as TM from "transformation-matrix";
import { CoreConnectableObjectConcrete, CoreEdgeObjectConcrete } from "..";
import { Coord } from "../../../lib/coord";
import { GetPressureLossOptions } from "../../calculations/entity-pressure-drops";
import { CoreContext, PressureLossResult } from "../../calculations/types";
import { TerminusEntityConcrete } from "../../document/entities/concrete-entity";
import CoreBaseBackedObject from "../lib/coreBaseBackedObject";
import { GuessEntity } from "../lib/types";

export const TERMINUS_OFFSET = 50;

export interface ICoreTerminus {}

export function CoreTerminus<
  T extends abstract new (...args: any[]) => CoreBaseBackedObject<I>,
  I extends TerminusEntityConcrete = GuessEntity<T>,
>(Base: T) {
  abstract class Generated extends Base implements ICoreTerminus {
    radius = 30;
    abstract getComponentPressureLossKPA(
      options: GetPressureLossOptions,
    ): PressureLossResult;

    get position(): TM.Matrix {
      return this.getPositionWithCenter(this.effectiveCenter);
    }

    // gets the vector of the edge from the endpoint terminus is connected to
    // to the other endpoint
    get edgeVector(): Flatten.Vector {
      const ends = this.edge.worldEndpoints();
      const eIdx = this.entity.endpointIdx;

      const pointA = new Flatten.Point(ends[eIdx].x, ends[eIdx].y);
      const pointB = new Flatten.Point(
        ends[(eIdx + 1) % 2].x,
        ends[(eIdx + 1) % 2].y,
      );

      return Flatten.vector(pointA, pointB);
    }

    get edge(): CoreEdgeObjectConcrete {
      return this.globalStore.get(
        this.entity.edgeUid,
      ) as CoreEdgeObjectConcrete;
    }

    get connectable(): CoreConnectableObjectConcrete {
      return this.globalStore.get(
        this.edge.entity.endpointUid[this.entity.endpointIdx],
      );
    }

    getPositionWithCenter(center: Coord): TM.Matrix {
      const angle = this.edgeVector.angleTo(Flatten.vector(0, 1));
      const scale = 1 / this.fromParentToWorldLength(1);

      return TM.transform(
        TM.translate(this.effectiveCenter.x, this.effectiveCenter.y),
        TM.scale(scale, scale),
        TM.rotate(-angle),
        TM.translate(0, TERMINUS_OFFSET),
      );
    }

    get effectiveCenter() {
      const edge = this.globalStore.get(
        this.entity.edgeUid,
      ) as CoreEdgeObjectConcrete;
      const fitting = this.globalStore.get(
        edge.entity.endpointUid[this.entity.endpointIdx],
      ) as CoreConnectableObjectConcrete;

      return fitting.getConnectionCoord(edge.uid);
    }

    getHash(): string {
      const key = this.effectiveCenter.x + " " + this.effectiveCenter.y;
      return key;
    }

    getFrictionPressureLossKPA(
      options: GetPressureLossOptions,
    ): PressureLossResult {
      let { context, flowLS, from, to, signed } = options;
      throw new Error("Not implemented yet");
    }

    get shape() {
      const point = this.toWorldCoord({ x: 0, y: 0 });
      return Flatten.circle(Flatten.point(point.x, point.y), this.radius);
    }

    preCalculationValidation(context: CoreContext) {
      return null;
    }
  }

  return Generated;
}
