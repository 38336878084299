import { f2C } from "../../../lib/measurements";
import { RoomTemperatureSpec, SpaceType } from "./types";

const DEFAULT_ASHRAE_TEMP_C = f2C(70);
const DEFAULT_AS1668_TEMP_C = 21;

export const ROOM_TEMPERATURE_DATA: RoomTemperatureSpec = {
  MCSPre2000: {
    [SpaceType.Lounge]: 21,
    [SpaceType.Living]: 21,
    [SpaceType.Breakfast]: 21,
    [SpaceType.Dining]: 21,
    [SpaceType.Kitchen]: 18,
    [SpaceType.Family]: 21,
    [SpaceType.Hall]: 18,
    [SpaceType.CloaksWC]: 18,
    [SpaceType.Toilet]: 18,
    [SpaceType.Utility]: 18,
    [SpaceType.Study]: 21,
    [SpaceType.Games]: 21,
    [SpaceType.Bedroom]: 18,
    [SpaceType.Bedsitting]: 21,
    [SpaceType.BedEnsuite]: 21,
    [SpaceType.Internal]: 18,
    [SpaceType.BedStudy]: 21,
    [SpaceType.Landing]: 18,
    [SpaceType.Bath]: 22,
    [SpaceType.Shower]: 22,
    [SpaceType.Dressing]: 18,
    [SpaceType.Store]: 16,
  },
  MCSPost2000: {
    [SpaceType.Lounge]: 21,
    [SpaceType.Living]: 21,
    [SpaceType.Breakfast]: 21,
    [SpaceType.Dining]: 21,
    [SpaceType.Kitchen]: 18,
    [SpaceType.Family]: 21,
    [SpaceType.Hall]: 18,
    [SpaceType.CloaksWC]: 18,
    [SpaceType.Toilet]: 18,
    [SpaceType.Utility]: 18,
    [SpaceType.Study]: 21,
    [SpaceType.Games]: 21,
    [SpaceType.Bedroom]: 18,
    [SpaceType.Bedsitting]: 21,
    [SpaceType.BedEnsuite]: 21,
    [SpaceType.Internal]: 18,
    [SpaceType.BedStudy]: 21,
    [SpaceType.Landing]: 18,
    [SpaceType.Bath]: 22,
    [SpaceType.Shower]: 22,
    [SpaceType.Dressing]: 18,
    [SpaceType.Store]: 16,
  },
  MCSPost2006: {
    [SpaceType.Lounge]: 21,
    [SpaceType.Living]: 21,
    [SpaceType.Breakfast]: 21,
    [SpaceType.Dining]: 21,
    [SpaceType.Kitchen]: 21,
    [SpaceType.Family]: 21,
    [SpaceType.Hall]: 21,
    [SpaceType.CloaksWC]: 21,
    [SpaceType.Toilet]: 21,
    [SpaceType.Utility]: 21,
    [SpaceType.Study]: 21,
    [SpaceType.Games]: 21,
    [SpaceType.Bedroom]: 21,
    [SpaceType.Bedsitting]: 21,
    [SpaceType.BedEnsuite]: 21,
    [SpaceType.Internal]: 21,
    [SpaceType.BedStudy]: 21,
    [SpaceType.Landing]: 21,
    [SpaceType.Bath]: 22,
    [SpaceType.Shower]: 21,
    [SpaceType.Dressing]: 21,
    [SpaceType.Store]: 16,
  },
  UKBuildingRegs2021Dwellings: {
    [SpaceType.Lounge]: 21,
    [SpaceType.Living]: 21,
    [SpaceType.Breakfast]: 21,
    [SpaceType.Dining]: 21,
    [SpaceType.Kitchen]: 18,
    [SpaceType.Family]: 21,
    [SpaceType.Hall]: 21,
    [SpaceType.CloaksWC]: 21,
    [SpaceType.Toilet]: 21,
    [SpaceType.Utility]: 18,
    [SpaceType.Study]: 21,
    [SpaceType.Games]: 21,
    [SpaceType.Bedroom]: 18,
    [SpaceType.Bedsitting]: 21,
    [SpaceType.BedEnsuite]: 21,
    [SpaceType.Internal]: 18,
    [SpaceType.BedStudy]: 21,
    [SpaceType.Landing]: 21,
    [SpaceType.Bath]: 21,
    [SpaceType.Shower]: 21,
    [SpaceType.Dressing]: 21,
    [SpaceType.Store]: 18,
  },
  UKBuildingRegs2021NonDwellings: {
    [SpaceType.Office]: 21,
    [SpaceType.OfficeCommonSpace]: 21,
    [SpaceType.Bathroom]: 26,
    [SpaceType.Kitchen]: 18,
    [SpaceType.Kitchenette]: 18,
    [SpaceType.PrintRoom]: 21,
    [SpaceType.CarPark]: 16,
    [SpaceType.BasementCarPark]: 16,
    [SpaceType.CarParkQueueArea]: 16,
    [SpaceType.General]: 21,
    [SpaceType.OperatingTheatre]: 18,
    [SpaceType.ChangingRooms]: 24,
    [SpaceType.SwimmingPoolHall]: 23,
    [SpaceType.SquashCourt]: 11,
    [SpaceType.IceRink]: 12,
    [SpaceType.AssemblyHalls]: 21,
    [SpaceType.HotelBathroom]: 26,
    [SpaceType.HospitalRecoveryRoom]: 22,
    [SpaceType.HospitalTreatmentRoom]: 22,
    [SpaceType.BackOfHouse]: 21,
    [SpaceType.Laboratory]: 21,
    [SpaceType.Darkroom]: 21,
    [SpaceType.Horticulture]: 16,
  },
  CIBSE2016: {
    [SpaceType.General]: 21,
    [SpaceType.Toilet]: 21,
    [SpaceType.Bathroom]: 26,
    [SpaceType.HotelBathroom]: 26,
    [SpaceType.ChangingRooms]: 24,
    [SpaceType.Kitchen]: 18,
    [SpaceType.Bedroom]: 18,
    [SpaceType.LivingRoom]: 21,
    [SpaceType.AssemblyHalls]: 21,
    [SpaceType.Dwelling]: 21,
    [SpaceType.BackOfHouse]: 21,
    [SpaceType.Laboratory]: 21,
    [SpaceType.OperatingTheatre]: 18,
    [SpaceType.HospitalRecoveryRoom]: 22,
    [SpaceType.HospitalTreatmentRoom]: 22,
    [SpaceType.SwimmingPoolHall]: 23,
    [SpaceType.Darkroom]: 21,
    [SpaceType.Horticulture]: 16,
    [SpaceType.SquashCourt]: 11,
    [SpaceType.IceRink]: 12,
  },
  "ASHRAE62.1": {
    [SpaceType.AnimalFacility]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.CorrectionalFacility]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.ArtRoom]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Classroom]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Library]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.LectureHall]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Laboratory]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Kitchen]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.CommercialKitchen]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Bar]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Dining]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Office]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.MeetingRoom]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Bedroom]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.LivingRoom]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.CommercialLaundry]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Laundry]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Corridor]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.ComputerRoom]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Manufacturing]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Pharmacy]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.PhotoStudio]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Warehouse]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Auditorium]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Museum]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.ReligiousWorship]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Barbershop]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Salon]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Mall]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Supermarket]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Retail]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Gym]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.BowlingAlley]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.DanceFloor]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Casino]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Arcade]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.SportsArena]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Toilet]: DEFAULT_ASHRAE_TEMP_C,
    [SpaceType.Trash]: DEFAULT_ASHRAE_TEMP_C,
  },
  "AS1668.2-2012": {
    [SpaceType.Bar]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Bathroom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Bedroom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Cafe]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.ClassroomAbove16]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.ClassroomBelow16]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.ConferenceRoomLarge]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.ConferenceRoomSmall]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.DiningRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Dormitory]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Kitchen]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Library]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.LivingRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Lobby]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Lounge]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.MeetingRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Office]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Restroom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.StoreRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Supermarket]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Toilet]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.WaitingArea]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Arcade]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Barbershop]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Salon]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Casino]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Chapel]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.ConsultationRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Corridor]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.ExerciseRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.FastFoodOutlet]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.FittingRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Florist]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Foyer]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.GamesRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.HealthSpa]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.HighActivityArea]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Laboratory]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.LowActivityArea]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Mall]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.MediumActivityArea]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Museum]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.MusicRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.NightClub]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.PatientRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Pharmacy]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.PhysicalTherapyRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.SalesFloorGround]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.SalesFloorUpper]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Studio]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.TrainingShop]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.AirportGates]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.AirportHangars]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Amphitheatres]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.AnimalRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.AutopsyRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Ballroom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Bank]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.BowlingAlley]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Brothel]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Cabarets]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.CameraRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.ChangeRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.ConcertHall]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Concourses]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.DeliveryRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.DressingRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.EatingHall]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.ElectricalRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.FoodPrep]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.GarbageRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Greenhouse]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Hairdresser]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.IntensiveCareRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Kiosk]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.LaundryCoinOperated]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.LaundryStorage]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.MeatProcessing]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.OperatingRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.PlantRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.PrintingRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.PrisonCell]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.PrisonGuardStation]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.ProcedureArea]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.RecoveryRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.SpectatorArea]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.SportsPlayingFloor]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Stairs]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Suite]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.SwimmingPoolArea]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.TicketAreas]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.UtilityRoom]: DEFAULT_AS1668_TEMP_C,
    [SpaceType.Warehouse]: DEFAULT_AS1668_TEMP_C,
  },
};
