import { CoreObjectConcrete } from ".";
import { Coord, Coord3D } from "../../lib/coord";
import {
  isParallelDeg,
  isParallelRad,
  isRightAngleRad,
} from "../../lib/mathUtils/mathutils";
import { evaluatePolynomial } from "../../lib/polynomials";
import {
  EPS,
  assertUnreachable,
  lowerBoundNumberTable,
  tc,
} from "../../lib/utils";
import { Vector3 } from "../../lib/vector3";
import { GetPressureLossOptions } from "../calculations/entity-pressure-drops";
import {
  fittingFrictionLossMH,
  getFluidDensityOfSystem,
  head2kpa,
} from "../calculations/pressure-drops";
import {
  CoreContext,
  CostBreakdown,
  CostBreakdownEntry,
  PressureLossResult,
} from "../calculations/types";
import { FLOW_SOURCE_EDGE } from "../calculations/utils";
import { DuctFittingsTable, PipesTable } from "../catalog/price-table";
import { ComponentPressureLossMethod, isFlowReversed } from "../config";
import ConduitCalculation from "../document/calculations-objects/conduit-calculations";
import FittingCalculation, {
  FittingLiveCalculation,
  emptyFittingCalculation,
  emptyFittingLiveCalculation,
} from "../document/calculations-objects/fitting-calculation";
import { isConduitConnectableEntity } from "../document/entities/concrete-entity";
import PipeEntity, {
  fillDefaultConduitFields,
  isPipeEntity,
} from "../document/entities/conduit-entity";
import {
  CableFittingEntity,
  DuctFittingEntity,
  FittingEntity,
  PipeFittingEntity,
  computeClockwiseRotateAngle,
} from "../document/entities/fitting-entity";
import { EntityType } from "../document/entities/types";
import { CoreConnectable } from "./core-traits/coreConnectable";
import CoreConduit from "./coreConduit";
import CoreBaseBackedObject from "./lib/coreBaseBackedObject";
import { calculateDuctFittingPressureLoss } from "./lib/ductFittingPressureDrop";
import {
  ductSizeAreaCmp,
  getDuctFittingPrimitives,
  getPhysicalEndpoints,
} from "./lib/ductFittingPrimitives";
import {
  determineConnectableSystemUid,
  determineSmallestDiameterPipe,
  findPipeFittingKValue,
  getPressureDropKPAFromPressures,
} from "./utils";

export enum FittingEntryType {
  CONDUIT = "conduit",
  FITTING = "fitting",
  VERTICAL_CONDUIT = "vertical_conduit",
}
export type FittingEntryBase = {
  type: FittingEntryType;
  ref: string;
};

export interface ConduitEntry extends FittingEntryBase {
  type: FittingEntryType.CONDUIT;
  entity: PipeEntity;
  segment: Coord[];
  heightM: number;
  angleRefCenterFittingRotateDegCW: number;
}
export interface FittingEntry extends FittingEntryBase {
  type: FittingEntryType.FITTING;
  entity: PipeFittingEntity | DuctFittingEntity | CableFittingEntity;
  center: Coord;
  heightM: number | null;
}
export interface VerticalEntry extends FittingEntryBase {
  type: FittingEntryType.VERTICAL_CONDUIT;
  entity: PipeEntity;
  center: Coord;
  heightSegmentM: [number, number];
}

export type FittingFlowRoot = {
  conduitUid: string;
  flowDirection: "flow-in" | "flow-out";
};
export type FittingReference = {
  referenceMap: Record<string, ConduitEntry | FittingEntry | VerticalEntry>;
  flowRoot: FittingFlowRoot | null;
};

export default class CoreFitting extends CoreConnectable(
  CoreBaseBackedObject<FittingEntity>,
) {
  iAmFitting() {
    return true;
  }
  type: EntityType.FITTING = EntityType.FITTING;
  getComponentPressureLossKPA({
    context,
    flowLS,
    from,
    to,
    signed,
  }: GetPressureLossOptions): PressureLossResult {
    if (
      from.connection === FLOW_SOURCE_EDGE ||
      to.connection === FLOW_SOURCE_EDGE
    ) {
      throw new Error("I don't like it");
    }

    if (Math.abs(flowLS) < EPS) {
      return { pressureLossKPA: 0 };
    }

    switch (
      context.drawing.metadata.calculationParams.componentPressureLossMethod
    ) {
      case ComponentPressureLossMethod.INDIVIDUALLY:
        // Find pressure loss from components
        break;
      case ComponentPressureLossMethod.PERCENT_ON_TOP_OF_PIPE:
        return { pressureLossKPA: 0 };
      default:
        assertUnreachable(
          context.drawing.metadata.calculationParams
            .componentPressureLossMethod,
        );
    }

    const ga =
      context.drawing.metadata.calculationParams.gravitationalAcceleration;

    let sign = 1;
    if (flowLS < 0) {
      const oldFrom = from;
      from = to;
      to = oldFrom;
      flowLS = -flowLS;
      if (signed) {
        sign = -1;
      }
    }

    // determine smallest diameter pipe on currently computed to-from path
    const pipeCalcs = [
      this.globalStore.calculationStore.get(
        from.connection,
      )! as ConduitCalculation,
      this.globalStore.calculationStore.get(
        to.connection,
      )! as ConduitCalculation,
    ];

    const fromc = this.get3DOffset(from.connection);
    const toc = this.get3DOffset(to.connection);

    const calc = this.globalStore.getOrCreateCalculation(this.entity);
    const flowDensity = getFluidDensityOfSystem(
      context,
      determineConnectableSystemUid(context.globalStore, this.entity)!,
    )!;

    switch (this.entity.fittingType) {
      case "pipe": {
        const {
          smallestDiameterMM,
          smallestDiameterNominalMM,
          largestDiameterMM,
        } = determineSmallestDiameterPipe(pipeCalcs);

        if (
          smallestDiameterMM == null ||
          largestDiameterMM == null ||
          smallestDiameterNominalMM == null
        ) {
          // Neighbouring pipes are unsized.
          return { pressureLossKPA: null };
        }

        // determine kValue
        let kValue = findPipeFittingKValue(
          fromc,
          toc,
          this.globalStore.getConnections(this.entity.uid),
          context.catalog,
          smallestDiameterNominalMM,
        );

        if (kValue === null) {
          throw new Error("could not find k value of fitting");
        }
        calc.kvValue = kValue;

        const volLM = (smallestDiameterMM ** 2 * Math.PI) / 4 / 1000;
        const velocityMS = flowLS / volLM;

        return {
          pressureLossKPA:
            sign *
            head2kpa(
              fittingFrictionLossMH(velocityMS, kValue, ga),
              flowDensity,
              ga,
            ),
        };
      }

      case "duct":
        const spec = getDuctFittingPrimitives(this.context, this);
        if (!spec.success) {
          console.warn("no spec for duct fitting", this.entity);
          // TODO: auto-generate enough of the specs to return null here.
          // For now do this.
          return { pressureLossKPA: 0 };
        }
        const impliedZeta = this.entity.fitting.zetaByConnection[to.connection];

        const pressureLoss = calculateDuctFittingPressureLoss(
          spec.pressureLoss,
          flowDensity,
          context.catalog,
          from.connection,
          to.connection,
          impliedZeta,
        );
        if (pressureLoss == null) {
          console.warn("Unable to calculate pressure loss for duct fitting", {
            spec,
            from,
            to,
          });
          // return { pressureLossKPA: null };
          // For demo, just return 0
          return { pressureLossKPA: 0 };
        }
        for (const key in pressureLoss.zetaByConnection) {
          if (pressureLoss.zetaByConnection.hasOwnProperty(key)) {
            calc.zetaByConnection[key] = pressureLoss.zetaByConnection[key];
          }
        }
        return {
          pressureLossKPA: sign * pressureLoss.pressureLossKPA,
        };
      case "cable":
        throw new Error("cable fitting not implemented");
    }
  }

  getCalculationEntities(
    context: CoreContext,
  ): Array<FittingEntity | PipeEntity> {
    return this.getCalculationTower(context).flat();
  }

  collectCalculations(context: CoreContext): FittingCalculation {
    const tower = this.getCalculationTower(context);
    if (tower.length === 0) {
      return emptyFittingCalculation();
    }

    const calc = context.globalStore.getOrCreateCalculation(tower[0][0]);

    const res = emptyFittingCalculation();
    res.flowRateLS = calc.flowRateLS;
    res.pressureDropKPA = calc.pressureDropKPA;
    res.pressureKPA = calc.pressureKPA;
    res.warnings = calc.warnings;
    res.pressureByEndpointKPA = {};
    res.staticPressureKPA = calc.staticPressureKPA;
    res.kvValue = calc.kvValue;
    res.reference = calc.reference;

    if (this.entity.fittingType === "duct") {
      res.physicalDuctPrimitives = [];
      res.pressureLossDuctPrimitives = [];
      for (const key in this.entity.fitting.zetaByConnection) {
        if (this.entity.fitting.zetaByConnection.hasOwnProperty(key)) {
          res.zetaByConnection[key] = this.entity.fitting.zetaByConnection[key];
        }
      }

      for (const g of tower) {
        const calc = context.globalStore.getOrCreateCalculation(g[0]);
        if (calc.physicalDuctPrimitives) {
          res.physicalDuctPrimitives.push(...calc.physicalDuctPrimitives);
        }
        if (calc.pressureLossDuctPrimitives) {
          res.pressureLossDuctPrimitives.push(
            ...calc.pressureLossDuctPrimitives,
          );
        }
      }
    }

    // determine the ranges of pressure drops available. Take the min, and the pressure going out to each pipe.
    const calculationNeighbours = new Set(
      context.globalStore
        .getConnections(this.uid)
        .map(
          (uid) =>
            context.globalStore.get(uid)!.getCalculationEntities(context)[0]
              .uid,
        ),
    );

    const pressures: (number | null)[] = [];
    for (const e of this.getCalculationEntities(context)) {
      switch (e.type) {
        case EntityType.FITTING:
          const eCalc = context.globalStore.getOrCreateCalculation(e);
          for (const endpoint of Object.keys(eCalc.pressureByEndpointKPA)) {
            if (calculationNeighbours.has(endpoint)) {
              const pv = eCalc.pressureByEndpointKPA[endpoint];
              pressures.push(pv);
            }
            res.pressureByEndpointKPA[endpoint] =
              eCalc.pressureByEndpointKPA[endpoint];
            if (this.entity.fittingType === "duct") {
              for (const key in eCalc.zetaByConnection) {
                if (eCalc.zetaByConnection.hasOwnProperty(key)) {
                  res.zetaByConnection[key] = eCalc.zetaByConnection[key];
                }
              }
            }
          }
          break;
        case EntityType.CONDUIT:
          break;
        default:
          assertUnreachable(e);
      }
    }

    if (this.getCalculationConnectionGroups(context).flat().length !== 2) {
      res.flowRateLS = null;
    }

    res.pressureDropKPA = getPressureDropKPAFromPressures(pressures);

    tower.forEach(([v, p]) => {
      res.warnings = [
        ...(res.warnings || []),
        ...(context.globalStore.getOrCreateCalculation(v).warnings || []),
      ];
      res.isVentExit =
        res.isVentExit ||
        context.globalStore.getOrCreateCalculation(v).isVentExit;
    });

    return res;
  }

  collectLiveCalculations(context: CoreContext): FittingLiveCalculation {
    if (this.getCalculationTower(context).length === 0) {
      return emptyFittingLiveCalculation();
    }

    const liveCalcs = context.globalStore.getOrCreateLiveCalculation(
      this.getCalculationTower(context)[0][0],
    );
    const standardCalcs = this.collectCalculations(context);
    return {
      ...liveCalcs,
      isVentExit: standardCalcs.isVentExit,
      physicalDuctPrimitives: standardCalcs.physicalDuctPrimitives,
      pressureLossDuctPrimitives: standardCalcs.pressureLossDuctPrimitives,
    };
  }

  costBreakdown(context: CoreContext): CostBreakdown | null {
    // TODO: make these work for ducts and vents.
    if (this.entity.fittingType === "pipe") {
      const vectors: Vector3[] = [];

      for (const puid of context.globalStore.getConnections(this.uid)) {
        const pipe = context.globalStore.get(puid) as CoreConduit;
        if (pipe) {
          const eps = pipe.worldEndpoints();
          vectors.push(
            new Vector3(
              eps[0].x - eps[1].x,
              eps[0].y - eps[1].y,
              eps[0].z - eps[1].z,
            ),
          );
        }
      }

      const materials: [keyof PipesTable, number][] = [];
      const pipeSizes: number[] = [];
      for (const puid of context.globalStore.getConnections(this.entity.uid)) {
        const pipe = context.globalStore.get(puid) as CoreConduit;
        if (!pipe || !isPipeEntity(pipe.entity)) {
          // bleh
        } else {
          const filled = fillDefaultConduitFields(context, pipe.entity);
          const manufacturer =
            context.drawing.metadata.catalog.pipes.find(
              (po) => po.uid === filled.conduit.material,
            )?.manufacturer || "generic";

          const manufacturerCatalog = context.catalog.pipes[
            filled.conduit.material!
          ].manufacturer.find((mo) => mo.uid === manufacturer);

          const size = context.globalStore.getOrCreateCalculation(
            pipe.entity,
          ).realNominalPipeDiameterMM;

          if (size) {
            materials.push([manufacturerCatalog!.priceTableName, size]);
            pipeSizes.push(size);
          }
        }
      }

      if (materials.length === 0) {
        return null;
      }

      if (vectors.length === 3) {
        // can only be tee
        let largestSize = 0;
        let selectedPrice = 0;
        let selectedPath = "";
        for (const [mat, siz] of materials) {
          const thisSiz = lowerBoundNumberTable(
            context.priceTable.Fittings.Tee[mat],
            siz,
          );
          if (thisSiz && thisSiz > largestSize) {
            largestSize = thisSiz;
            selectedPrice = context.priceTable.Fittings.Tee[mat][thisSiz];
            selectedPath = `Fittings.Tee.${mat}.${thisSiz}`;
          } else if (
            thisSiz &&
            thisSiz == largestSize &&
            context.priceTable.Fittings.Tee[mat][thisSiz] > selectedPrice
          ) {
            selectedPrice = context.priceTable.Fittings.Tee[mat][thisSiz];
            selectedPath = `Fittings.Tee.${mat}.${thisSiz}`;
          }
        }

        // Use [side, side, center]
        // side, side will have greatest angle between them
        // side       side
        // ---------------
        //        |
        // center |
        //        |
        //        |
        let neighbourPipes: Array<[Coord3D, CoreObjectConcrete]> =
          this.getRadials().filter((r: [Coord3D, CoreObjectConcrete]) => {
            if (r[1].type === EntityType.CONDUIT) {
              return true;
            }
            return false;
          });
        let pipeCoord: Coord3D[] = neighbourPipes.map(
          (r: [Coord3D, CoreObjectConcrete]) => r[0],
        );
        let teeSizes = neighbourPipes.map((r) => {
          let pipeConcre = r[1];
          if (isPipeEntity(pipeConcre.entity)) {
            const size = context.globalStore.getOrCreateCalculation(
              pipeConcre.entity,
            ).realNominalPipeDiameterMM;

            if (size) return size;
            // The default smallest size is 15
            return 15;
          } else {
            // Bleh?
            return 15;
          }
        });

        // Find common intersection between three
        // intersection either be index 0 or index 1 in coords
        let interSection: Coord3D = {
          ...this.toWorldCoord(),
          // Coordinate unit is mm
          z: this.entity.calculationHeightM
            ? this.entity.calculationHeightM * 1000
            : 0,
        };

        // Construct correspond vectors
        let teeVectors: Vector3[] = [];
        for (const p of pipeCoord) {
          teeVectors.push(
            new Vector3(
              p.x - interSection.x,
              p.y - interSection.y,
              p.z - interSection.z,
            ),
          );
        }

        let angles: number[] = [];
        for (let i = 0; i < 3; i++) {
          angles.push(teeVectors[i].angleTo(teeVectors[(i + 1) % 3]));
        }
        const maxAngle = Math.max(...angles);
        const maxAngleIndex = angles.indexOf(maxAngle);
        let pipeSizeOrder: number[] = [];
        if (maxAngleIndex === 0) {
          pipeSizeOrder = teeSizes;
        } else if (maxAngleIndex === 1) {
          pipeSizeOrder = [teeSizes[1], teeSizes[2], teeSizes[0]];
        } else if (maxAngleIndex === 2) {
          pipeSizeOrder = [teeSizes[0], teeSizes[2], teeSizes[1]];
        } else {
          // Something went wrong
          console.error("Unable to determine pipe angle", teeVectors, angles);
          pipeSizeOrder = teeSizes;
        }

        return {
          cost: selectedPrice,
          breakdown: [
            {
              type: "fitting",
              qty: 1,
              path: selectedPath,
              pipeSizes: pipeSizeOrder,
            },
          ],
        };
      }
      if (vectors.length === 2) {
        // assume is bend :O TODO: input different size for straights
        let mostExpensive = 0;
        let mostExpensivePath = "";
        if (isRightAngleRad(vectors[0].angleTo(vectors[1]), Math.PI / 3)) {
          for (const [mat, siz] of materials) {
            const thisSiz = lowerBoundNumberTable(
              context.priceTable.Fittings.Elbow[mat],
              siz,
            );
            if (thisSiz) {
              if (
                context.priceTable.Fittings.Elbow[mat][thisSiz] > mostExpensive
              ) {
                mostExpensive = context.priceTable.Fittings.Elbow[mat][thisSiz];
                mostExpensivePath = `Fittings.Elbow.${mat}.${thisSiz}`;
              }
            }
          }
        } else {
          for (const [mat, siz] of materials) {
            const thisSiz = lowerBoundNumberTable(
              context.priceTable.Fittings.Reducer[mat],
              siz,
            );
            if (thisSiz) {
              if (siz in context.priceTable.Fittings.Reducer[mat]) {
                if (
                  context.priceTable.Fittings.Reducer[mat][thisSiz] >
                  mostExpensive
                ) {
                  mostExpensive =
                    context.priceTable.Fittings.Reducer[mat][thisSiz];
                  mostExpensivePath = `Fittings.Reducer.${mat}.${thisSiz}`;
                }
              }
            }
          }
        }
        return {
          cost: mostExpensive,
          breakdown: [
            {
              qty: 1,
              path: mostExpensivePath,
            },
          ],
        };
      }

      if (vectors.length === 1) {
        // deadleg cap - no worries.
        return { cost: 0, breakdown: [{ qty: 0, path: "" }] };
      }

      if (vectors.length === 0) {
        // Invalid thingymabob.
        return { cost: 0, breakdown: [{ qty: 0, path: "" }] };
      }

      // otherwise no info.
      return null;
    } else if (this.entity.fittingType === "duct") {
      const calc = context.globalStore.getOrCreateCalculation(this.entity);
      const breakdown: CostBreakdownEntry[] = [];
      let totalCost = 0;
      for (const prim of calc.physicalDuctPrimitives || []) {
        const catalogEntry = context.catalog.ducts[prim.material];

        if (!catalogEntry) {
          console.warn("No catalog entry for duct", prim);
          continue;
        }

        const manufacturer =
          context.drawing.metadata.catalog.ducts.find(
            (d) => d.uid === prim.material,
          )?.manufacturer || "generic";
        const manufacturerEntry =
          catalogEntry?.manufacturers.find((me) => me.uid === manufacturer) ||
          catalogEntry?.manufacturers[0];
        const priceTableName = manufacturerEntry?.priceTableName;
        switch (prim.type) {
          case "nipple":
            // no-op
            break;
          case "conduit":
            // TODO: intermittent ducts in fittings
            break;
          case "elbow": {
            switch (prim.jointType) {
              case "square": {
                if (prim.eps[0].size.type === "circ") {
                  const thisCost = evaluatePolynomial(
                    context.priceTable.DuctFittings[priceTableName][
                      "Elbow - Circular (Mitred)"
                    ],
                    prim.eps[0].size.diameterMM,
                  );
                  breakdown.push({
                    path: `DuctFittings.${priceTableName}.Elbow - Circular (Mitred)`,
                    qty: 1,
                    params: [prim.eps[0].size.diameterMM],
                    manufacturer: manufacturer,
                    size: prim.eps[0].size,
                  });
                  totalCost += thisCost;
                } else {
                  // RectSquare
                  const thisCost = evaluatePolynomial(
                    context.priceTable.DuctFittings[priceTableName][
                      "Elbow - Rectangular (Mitred)"
                    ],
                    prim.eps[0].size.heightMM,
                    prim.eps[0].size.widthMM,
                  );

                  breakdown.push({
                    path: `DuctFittings.${priceTableName}.Elbow - Rectangular (Mitred)`,
                    qty: 1,
                    params: [
                      prim.eps[0].size.heightMM,
                      prim.eps[0].size.widthMM,
                    ],
                    manufacturer: manufacturer,
                    size: prim.eps[0].size,
                  });
                  totalCost += thisCost;
                }
                break;
              }
              case "square-vanes": {
                if (prim.eps[0].size.type === "circ") {
                  throw new Error("CircSquare with vanes is not valid");
                }
                // RectSquareVanes
                const thisCost = evaluatePolynomial(
                  context.priceTable.DuctFittings[priceTableName][
                    "Elbow - Rectangular (Mitred with Vanes)"
                  ],
                  prim.eps[0].size.heightMM,
                  prim.eps[0].size.widthMM,
                );

                breakdown.push({
                  path: `DuctFittings.${priceTableName}.Elbow - Rectangular (Mitred with Vanes)`,
                  qty: 1,
                  params: [prim.eps[0].size.heightMM, prim.eps[0].size.widthMM],
                  manufacturer: manufacturer,
                  size: prim.eps[0].size,
                });
                totalCost += thisCost;
                break;
              }
              case "smooth": {
                if (prim.eps[0].size.type === "circ") {
                  // CircSmooth
                  const thisCost = evaluatePolynomial(
                    context.priceTable.DuctFittings[priceTableName][
                      "Elbow - Circular (Smooth)"
                    ],
                    prim.eps[0].size.diameterMM,
                  );
                  breakdown.push({
                    path: `DuctFittings.${priceTableName}.Elbow - Circular (Smooth)`,
                    qty: 1,
                    params: [prim.eps[0].size.diameterMM],
                    manufacturer: manufacturer,
                    size: prim.eps[0].size,
                  });
                  totalCost += thisCost;
                } else {
                  // RectSmooth
                  const thisCost = evaluatePolynomial(
                    context.priceTable.DuctFittings[priceTableName][
                      "Elbow - Rectangular (Smooth)"
                    ],
                    prim.eps[0].size.heightMM,
                    prim.eps[0].size.widthMM,
                  );

                  breakdown.push({
                    path: `DuctFittings.${priceTableName}.Elbow - Rectangular (Smooth)`,
                    qty: 1,
                    params: [
                      prim.eps[0].size.heightMM,
                      prim.eps[0].size.widthMM,
                    ],
                    manufacturer: manufacturer,
                    size: prim.eps[0].size,
                  });
                  totalCost += thisCost;
                }
                break;
              }
              case "smooth-vanes": {
                if (prim.eps[0].size.type === "circ") {
                  throw new Error("CircSmooth with vanes is not valid");
                }
                if (!prim.vanes) {
                  throw new Error("No vanes specified for duct fitting");
                }
                // RectSmoothVanes
                let name: keyof DuctFittingsTable["Aluminium"];
                switch (prim.vanes) {
                  case 1:
                    name = "Elbow - Rectangular (Smooth with 1 Vane)";
                    break;
                  case 2:
                    name = "Elbow - Rectangular (Smooth with 2 Vanes)";
                    break;
                  case 3:
                    name = "Elbow - Rectangular (Smooth with 3 Vanes)";
                    break;
                  default:
                    throw new Error("Invalid number of vanes");
                }

                const thisCost = evaluatePolynomial(
                  context.priceTable.DuctFittings[priceTableName][name],
                  prim.eps[0].size.heightMM,
                  prim.eps[0].size.widthMM,
                );

                breakdown.push({
                  path: `DuctFittings.${priceTableName}.${name}.${prim.vanes}`,
                  qty: 1,
                  params: [prim.eps[0].size.heightMM, prim.eps[0].size.widthMM],
                  manufacturer: manufacturer,
                  size: prim.eps[0].size,
                });
                totalCost += thisCost;
                break;
              }
              case "multi-piece": {
                if (prim.eps[0].size.type === "rect") {
                  throw new Error("Rectangular multi-piece is not valid");
                }

                const thisCost = evaluatePolynomial(
                  context.priceTable.DuctFittings[priceTableName][
                    "Elbow - Circular (Multi Piece)"
                  ],
                  prim.eps[0].size.diameterMM,
                );
                breakdown.push({
                  path: `DuctFittings.${priceTableName}.Elbow - Circular (Multi Piece)`,
                  qty: 1,
                  params: [prim.eps[0].size.diameterMM],
                  manufacturer: manufacturer,
                  size: prim.eps[0].size,
                });
                totalCost += thisCost;
                break;
              }
            }
            break;
          }
          case "takeoff": {
            let thisName: keyof DuctFittingsTable["Aluminium"];
            let thisParams: number[];
            switch (prim.branch.size.type) {
              case "circ": {
                switch (prim.style) {
                  case "bell": {
                    thisName = "Takeoff - Circular (bell)";
                    thisParams = [prim.branch.size.diameterMM];
                    break;
                  }
                  case "shoe": {
                    thisName = "Takeoff - Circular (shoe)";
                    thisParams = [prim.branch.size.diameterMM];
                    break;
                  }
                  case "square": {
                    thisName = "Takeoff - Circular (mitred)";
                    thisParams = [prim.branch.size.diameterMM];
                    break;
                  }
                  default:
                    assertUnreachable(prim);
                }
                break;
              }
              case "rect": {
                switch (prim.style) {
                  case "bell": {
                    thisName = "Takeoff - Rectangular (bell)";
                    thisParams = [
                      prim.branch.size.heightMM,
                      prim.branch.size.widthMM,
                    ];
                    break;
                  }
                  case "shoe": {
                    thisName = "Takeoff - Rectangular (shoe)";
                    thisParams = [
                      prim.branch.size.heightMM,
                      prim.branch.size.widthMM,
                    ];
                    break;
                  }
                  case "square": {
                    thisName = "Takeoff - Rectangular (mitred)";
                    thisParams = [
                      prim.branch.size.heightMM,
                      prim.branch.size.widthMM,
                    ];
                    break;
                  }
                  default:
                    assertUnreachable(prim);
                }
                break;
              }
              default:
                assertUnreachable(prim.branch.size);
            }

            const thisCost = evaluatePolynomial(
              context.priceTable.DuctFittings[priceTableName][thisName!],
              ...thisParams!,
            );
            breakdown.push({
              path: `DuctFittings.${priceTableName}.${thisName!}`,
              qty: 1,
              params: thisParams!,
              manufacturer: manufacturer,
              size: prim.branch.size,
            });
            totalCost += thisCost;
            break;
          }
          case "transition": {
            const largestEps =
              ductSizeAreaCmp(prim.eps[0].size, prim.eps[1].size) > 0
                ? prim.eps[0]
                : prim.eps[1];

            switch (largestEps.size.type) {
              case "circ": {
                const thisCost = evaluatePolynomial(
                  context.priceTable.DuctFittings[priceTableName][
                    "Transition - (Circular biggest)"
                  ],
                  largestEps.size.diameterMM,
                );
                breakdown.push({
                  path: `DuctFittings.${priceTableName}.Transition - (Circular biggest)`,
                  qty: 1,
                  params: [largestEps.size.diameterMM],
                  manufacturer: manufacturer,
                  size: largestEps.size,
                });
                totalCost += thisCost;
                break;
              }
              case "rect": {
                const thisCost = evaluatePolynomial(
                  context.priceTable.DuctFittings[priceTableName][
                    "Transition - (Rectangular biggest)"
                  ],
                  largestEps.size.heightMM,
                  largestEps.size.widthMM,
                );
                breakdown.push({
                  path: `DuctFittings.${priceTableName}.Transition - (Rectangular biggest)`,
                  qty: 1,
                  params: [largestEps.size.heightMM, largestEps.size.widthMM],
                  manufacturer: manufacturer,
                  size: largestEps.size,
                });
                totalCost += thisCost;
                break;
              }
            }
            break;
          }
          case "y": {
            let thisName: keyof DuctFittingsTable["Aluminium"];
            let thisParams: number[];
            switch (prim.main.size.type) {
              case "circ": {
                switch (prim.style) {
                  case "breech": {
                    thisName = "Symmetrical Tee - Circular (Breech)";
                    thisParams = [prim.main.size.diameterMM];
                    break;
                  }
                  case "square": {
                    thisName = "Symmetrical Tee - Circular (Mitred)";
                    thisParams = [prim.main.size.diameterMM];
                    break;
                  }
                  case "y-piece": {
                    thisName = "Symmetrical Tee - Circular (Y Piece)";
                    thisParams = [prim.main.size.diameterMM];
                    break;
                  }
                  default:
                    assertUnreachable(prim);
                }
                break;
              }
              case "rect": {
                // can only be mitred
                thisName = "Symmetrical Tee - Rectangular (Mitred)";
                thisParams = [prim.main.size.heightMM, prim.main.size.widthMM];
                break;
              }
              default:
                assertUnreachable(prim.main.size);
            }

            const thisCost = evaluatePolynomial(
              context.priceTable.DuctFittings[priceTableName][thisName!],
              ...thisParams!,
            );
            breakdown.push({
              path: `DuctFittings.${priceTableName}.${thisName!}`,
              qty: 1,
              params: thisParams!,
              manufacturer: manufacturer,
              size: prim.main.size,
            });
            totalCost += thisCost;

            break;
          }
        }
      }
      if (totalCost === 0 && breakdown.length === 0) {
        return null;
      }
      return { cost: totalCost, breakdown };
    }
    return null;
  }

  getCalculationUid(context: CoreContext): string {
    return this.getCalculationEntities(context)[0].uid;
  }

  preCalculationValidation(context: CoreContext) {
    return null;
  }

  get friendlyTypeName(): string {
    const system =
      this.context.drawing.metadata.flowSystems[this.entity.systemUid];
    const connections = this.globalStore.getConnections(this.entity.uid);
    const sizes = connections.map((c) => {
      const e = this.globalStore.get<CoreConduit>(c)!;
      const filled = fillDefaultConduitFields(this.context, e.entity);
      if (filled.type === EntityType.CONDUIT) {
        switch (filled.conduitType) {
          case "duct":
            switch (filled.conduit.shape) {
              case "rectangular":
                return `${filled.conduit.heightMM}x${filled.conduit.widthMM}`;
              case "circular":
                return filled.conduit.diameterMM;
            }
          case "pipe":
            return filled.conduit.diameterMM;
          case "cable":
            return null;
        }
      }
    });
    const shapes = connections.map((c) => {
      const e = this.globalStore.get<CoreConduit>(c)!;
      const filled = fillDefaultConduitFields(this.context, e.entity);
      if (filled.type === EntityType.CONDUIT) {
        switch (filled.conduitType) {
          case "duct":
            return filled.conduit.shape;
          case "cable":
          case "pipe":
            return null;
        }
      }
    });

    switch (this.entity.fittingType) {
      case "pipe":
        if (connections.length === 4) {
          return "Cross fitting";
        } else if (connections.length === 3) {
          return "Tee fitting";
        } else if (connections.length === 2) {
          return "Elbow/Coupling fitting";
        } else if (connections.length === 1) {
          return "Cap End";
        } else {
          return connections.length + "-way fitting";
        }
      case "duct":
        // TODO: move this to a reusable duct fitting function
        if (connections.length === 1) {
          return "Duct fitting";
        } else if (connections.length === 2) {
          const angle = this.getAngleDiffs()[0];
          const isStraight = isParallelDeg(angle, 0, 30);
          if (shapes[0] !== shapes[1]) {
            return `${shapes[0]}-${shapes[1]} ${
              isStraight ? "duct transition" : "duct elbow w/ transition"
            }`;
          } else if (sizes[0] !== sizes[1]) {
            return `${sizes[0]}-${sizes[1]} ${
              isStraight ? "duct reducer" : "duct elbow w/ reducer"
            }`;
          } else if (isStraight) {
            return "Duct connector";
          } else {
            return "Duct elbow";
          }
        } else if (connections.length === 3) {
          const { ret } = this.getSortedAnglesRAD();
          ret.sort((a, b) => a.angle - b.angle);

          let straightIndex = null;
          for (let i = 0; i < 3; i++) {
            if (isParallelRad(ret[i].angle, ret[(i + 1) % 3].angle, 30)) {
              straightIndex = i;
              break;
            }
          }

          if (straightIndex === null) {
            return "Wye fitting";
          }

          const m1i = ret[straightIndex].index;
          const m2i = ret[(straightIndex + 1) % 3].index;
          const branchi = ret[(straightIndex + 2) % 3].index;

          const mainSameShape = shapes[m1i] === shapes[m2i];
          const mainSameSize = sizes[m1i] === sizes[m2i];
          const branchShape = shapes[branchi];

          if (mainSameShape && mainSameSize) {
            if (branchShape === shapes[m1i]) {
              return `${tc(shapes[m1i])} Tee Fitting`;
            } else {
              return `${tc(shapes[m1i])}-${tc(branchShape)} Tee Fitting`;
            }
          } else if (mainSameShape) {
            if (mainSameSize) {
              if (branchShape === shapes[m1i]) {
                return `${tc(shapes[m1i])} Tee Fitting`;
              } else {
                return `${tc(shapes[m1i])}-${tc(branchShape)} Tee Fitting`;
              }
            }
            if (branchShape === shapes[m1i]) {
              return `${tc(shapes[m1i])} Tee Fitting + Reducer`;
            } else {
              return `${tc(shapes[m1i])}-${tc(
                branchShape,
              )} Tee Fitting + Reducer`;
            }
          } else {
            if (branchShape === "rectangular") {
              return `Rectangular Tee Fitting + Transition`;
            } else {
              return `Circ-Rect Tee Fitting + Transition`;
            }
          }
        } else {
          // TODO: this is more for network objects but this drawable one should have been fine
          return "Invalid duct fitting (too many connections)";
        }
        return "Duct fitting";
      case "cable":
        throw new Error("cable fitting not implemented");
    }
    assertUnreachable(this.entity);
  }

  getCrossSectionBreakdown(context: CoreContext): FittingReference {
    // Why can't I use this.getCalculation,
    let calculation = this.getCalculationEntities(context);

    // Sort connection base on the direction
    let fittingNetworkEntitiesUids =
      calculation?.filter(isConduitConnectableEntity).map((entity) => {
        return entity.uid;
      }) || [];
    let ductNetworkEntitiesUids =
      calculation
        ?.filter((entity) => {
          return entity.type === EntityType.CONDUIT;
        })
        .map((entity) => {
          return entity.uid;
        }) || [];

    // Write a function that maps uid to sorted character
    let referenceMap: Record<
      string,
      ConduitEntry | FittingEntry | VerticalEntry
    > = {};

    let currIdx = 0;

    const nextId = () => {
      return (currIdx++)
        .toString(26)
        .split("")
        .map((c) => {
          return String.fromCharCode(parseInt(c, 26) + 65);
        })
        .join();
    };

    for (const [otherCoord, conduit] of this.getRadials()) {
      let character = nextId();

      let coreConduit = context.globalStore.get(conduit.uid);
      if (coreConduit.type !== EntityType.CONDUIT) {
        throw new Error(`Entity ${conduit.uid} is not a conduit`);
      }

      const calcEntity = coreConduit.getCalculationEntities(context)[0];

      referenceMap[calcEntity.uid] = {
        entity: calcEntity,
        ref: character,
        type: FittingEntryType.CONDUIT,
        segment: coreConduit.worldEndpoints(),
        heightM: coreConduit.entity.heightAboveFloorM,
        angleRefCenterFittingRotateDegCW: computeClockwiseRotateAngle(
          this.toWorldCoord(),
          otherCoord,
        ),
      };
    }

    for (const uid of fittingNetworkEntitiesUids) {
      let character = nextId();

      let entity = calculation?.find((entity) => {
        return entity.uid === uid;
      });
      if (!entity || !isConduitConnectableEntity(entity)) {
        throw new Error(`Entity ${uid} is not a conduit`);
      }

      referenceMap[uid] = {
        entity: entity,
        ref: character,
        type: FittingEntryType.FITTING,
        heightM: entity.calculationHeightM,
        center: entity.center,
      };
    }

    for (const uid of ductNetworkEntitiesUids) {
      let character = nextId();

      let entity = calculation?.find((entity) => {
        return entity.uid === uid;
      });
      if (!entity || entity.type !== EntityType.CONDUIT) {
        throw new Error(`Entity ${uid} is not a conduit`);
      }

      switch (entity.conduitType) {
        case "pipe":
        case "cable":
          break;
        case "duct":
          let [endpointA, endPointB] = entity.endpointUid;
          let fittingAEntry = referenceMap[endpointA];
          let fittingBEntry = referenceMap[endPointB];
          if (
            fittingAEntry.type !== FittingEntryType.FITTING ||
            fittingBEntry.type !== FittingEntryType.FITTING
          ) {
            throw new Error("Orphan vertical duct");
          }

          referenceMap[uid] = {
            entity: entity,
            ref: character,
            type: FittingEntryType.VERTICAL_CONDUIT,
            center: fittingAEntry.center,
            heightSegmentM: [
              Math.min(fittingAEntry.heightM!, fittingBEntry.heightM!),
              Math.max(fittingAEntry.heightM!, fittingBEntry.heightM!),
            ],
          };
          break;
        default:
          assertUnreachable(entity);
      }
    }

    let extractFlowFrom = (
      referenceMap: Record<string, ConduitEntry | FittingEntry | VerticalEntry>,
      pipeUids: string[],
    ): FittingFlowRoot | null => {
      const system =
        this.context.drawing.metadata.flowSystems[this.entity.systemUid];
      let flowRoot: FittingFlowRoot | null = null;
      for (const uid of pipeUids) {
        let coreConduit = context.globalStore.get(uid);
        if (coreConduit.type !== EntityType.CONDUIT) {
          throw new Error(`Fitting does not connect to ${uid} a conduit`);
        }

        let liveCalcs = context.globalStore.getOrCreateLiveCalculation(
          coreConduit.entity,
        );
        let calc = context.globalStore.getOrCreateCalculation(
          coreConduit.entity,
        );

        let flowFrom = calc.flowFrom ?? liveCalcs.flowFrom;

        if (flowFrom === null) continue;
        // Check if flowFrom is in referenceMap, if so it's definitely not the root
        if (flowFrom in referenceMap) continue;
        else {
          // If flowFrom is not in referenceMap, it's the root
          flowRoot = {
            conduitUid: coreConduit.getCalculationUid(context),
            flowDirection: isFlowReversed(system) ? "flow-out" : "flow-in",
          };
        }
      }

      return flowRoot;
    };

    let flowRoot = extractFlowFrom(
      referenceMap,
      context.globalStore.getConnections(this.uid),
    );
    return { referenceMap, flowRoot };
  }

  getConnectionCoord(connectionUid: string): Coord3D {
    const liveCalcs = this.context.globalStore.getOrCreateLiveCalculation(
      this.entity,
    );
    const calcs = this.context.globalStore.getOrCreateCalculation(this.entity);

    const primitives =
      liveCalcs.physicalDuctPrimitives &&
      liveCalcs.physicalDuctPrimitives.length
        ? liveCalcs.physicalDuctPrimitives
        : calcs.physicalDuctPrimitives;

    if (!primitives || !primitives.length) {
      return super.getConnectionCoord(connectionUid);
    }

    for (const p of primitives) {
      const endpoints = getPhysicalEndpoints(p);
      for (const e of endpoints) {
        // We use includes because the primitives are network objects and we need
        // this in the drawable stage. The proper way to do this was to compute
        // the shape of the conduit in its own calculations instead of here :/
        if (
          e.type === "external" &&
          e.isDestEndpoint &&
          e.connection.includes(connectionUid)
        ) {
          return e.coord;
        }
      }
    }
    return super.getConnectionCoord(connectionUid);
  }
}
