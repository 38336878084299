import { Coord3D, coord3DDist } from "../coord";
import { crossSectionalAreaM2 } from "./geometry-utils";

export function LStoM3S(ls: number): number {
  return ls / 1000;
}

export function mm3toL(mm3: number): number {
  return mm3 / 1e6;
}

export function velocityMS(
  flowRateM3S: number,
  internalDiameterMM: number,
): number {
  const csaM2 = crossSectionalAreaM2(internalDiameterMM);
  if (csaM2 === 0) {
    return 0;
  }
  return flowRateM3S / csaM2;
}

export function cylinderVolumeBetweenPointsMM3(
  a: Coord3D,
  b: Coord3D,
  internalDiameterMM: number,
): number {
  return cylinderVolumeMM3(coord3DDist(a, b), internalDiameterMM);
}

export function cylinderVolumeMM3(
  lengthMM: number,
  internalDiameterMM: number,
): number {
  return lengthMM * Math.PI * (internalDiameterMM / 2) ** 2;
}

export function torusVolumeMM3(
  radiusMM: number,
  internalPipeDiameterMM: number,
  totalAngleRad: number,
): number {
  return Math.PI * (internalPipeDiameterMM / 2) ** 2 * radiusMM * totalAngleRad;
}
