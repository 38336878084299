import { Module } from "vuex";
import { CORE_FEATURE_FLAG_DEFAULTS } from "../../../../common/src/lib/feature-flags";
import { RootState } from "../types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import FeatureFlagState, { FrontendFeatureFlags } from "./types";

// Note: For now just supporting boolean flags cause its easy, let me (David) know
// if you need to support numeric and/or object flags and ill add support.
export const FRONTEND_FEATURE_FLAG_DEFAULTS: FrontendFeatureFlags = {
  ...CORE_FEATURE_FLAG_DEFAULTS,
  "test-flag": false,
};

export const state: FeatureFlagState = {
  launchDarklyClient: null,
  featureFlags: FRONTEND_FEATURE_FLAG_DEFAULTS,
};

const namespaced: boolean = true;

export const featureFlags: Module<FeatureFlagState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
