import { GetterTree } from "vuex";
import {
  getBrowserLocale,
  toSupportedLocale,
} from "../../../../common/src/api/locale";
import {
  FEATURE_ACCESS_OVERRIDE,
  FeatureAccess,
  defaultFeatureAccess,
} from "../../../../common/src/models/FeatureAccess";
import { getSavedPreferenceOrDefault } from "../../../src/lib/localStorage";
import ProfileState from "../../../src/store/profile/types";
import { RootState } from "../../../src/store/types";

export const getters: GetterTree<ProfileState, RootState> = {
  profile(state) {
    return state.profile;
  },

  accessLevel(state) {
    if (!state.profile) return null;
    return state.profile.accessLevel;
  },

  viewedVideoIds(state) {
    return state.viewedVideoIds;
  },

  numDrawingsCreated(state) {
    return state.numDrawingsCreated;
  },

  numFeedbackSubmitted(state) {
    return state.numFeedbackSubmitted;
  },

  locale(state) {
    if (state.locale) {
      return state.locale;
    }
    if (localStorage.getItem("locale")) {
      return toSupportedLocale(
        getSavedPreferenceOrDefault(window, "locale", getBrowserLocale()),
      );
    } else {
      return toSupportedLocale(getBrowserLocale());
    }
  },

  properties(state) {
    return state.properties;
  },

  featureAccess(state) {
    try {
      if (localStorage.getItem(FEATURE_ACCESS_OVERRIDE)) {
        return JSON.parse(
          localStorage.getItem(FEATURE_ACCESS_OVERRIDE)!,
        ) as FeatureAccess;
      }
    } catch (e) {
      console.log("failed to parse feature access override");
    }

    return state.profile?.organization?.featureAccess ?? defaultFeatureAccess;
  },
};
