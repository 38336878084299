import { CoreContext } from "../../calculations/types";
import { CompoundEntity } from "../entities/compound-entities/compound-entity";
import { CalculationField } from "./calculation-field";
import {
  CalcFieldSelector,
  Calculation,
  CalculationType,
  LiveCalculation,
} from "./types";

export interface CompoundLiveCalculation extends LiveCalculation {}

export const FastCompoundCalculationFields: CalcFieldSelector<CompoundLiveCalculation> =
  {
    connected: true,
  };

export default interface CompoundCalculation extends Calculation {
  type: CalculationType.CompoundCalculation;
  pressureDropKPA: number | null;
  flowRateLS: number | null;
}

export function makeCompoundCalculationFields(
  context: CoreContext,
  entity: CompoundEntity,
): CalculationField[] {
  return [];
}

export function emptyCompoundCalculation(): CompoundCalculation {
  return {
    warnings: [],
    costBreakdown: null,
    expandedEntities: null,
    type: CalculationType.CompoundCalculation,
    cost: null,
    pressureDropKPA: null,
    flowRateLS: null,
  };
}

export function emptyCompoundLiveCalculation(): CompoundLiveCalculation {
  return {
    connected: null,
    warnings: [],
  };
}
