export function fillTextStable(
  this: CanvasRenderingContext2D,
  text: string,
  x: number,
  y: number,
  maxWidth?: number | undefined,
  textBaseline?: CanvasTextBaseline,
) {
  this.textBaseline = textBaseline || "alphabetic";
  this.fillText(text, x, y, maxWidth);
  // minor note:
  // there is no need to set textBaseline back to it's original value or even alphabetic because if all text drawing
  // is done through this function then we know that all textBaseline behaviour is explicit, not implicit
}
