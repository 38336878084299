import { DrawableEntityConcrete } from "../../../../../common/src/api/document/entities/concrete-entity";
import { EntityType } from "../../../../../common/src/api/document/entities/types";
import { assertUnreachable } from "../../../../../common/src/lib/utils";

export function isHydraulicEntity(entity: DrawableEntityConcrete) {
  switch (entity.type) {
    case EntityType.RISER:
    case EntityType.CONDUIT:
    case EntityType.FITTING:
    case EntityType.BIG_VALVE:
    case EntityType.SYSTEM_NODE:
    case EntityType.FIXTURE:
    case EntityType.DIRECTED_VALVE:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.LOAD_NODE:
    case EntityType.PLANT:
    case EntityType.GAS_APPLIANCE:
    case EntityType.COMPOUND:
    case EntityType.FLOW_SOURCE:
    case EntityType.DAMPER:
      return true;
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.EDGE:
    case EntityType.WALL:
    case EntityType.VERTEX:
    case EntityType.FENESTRATION:
    case EntityType.ROOM:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
    case EntityType.AREA_SEGMENT:
      return false;
    default:
      assertUnreachable(entity);
  }
  return false;
}

export function isArchitecturalEntity(entity: DrawableEntityConcrete) {
  switch (entity.type) {
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.RISER:
    case EntityType.CONDUIT:
    case EntityType.FITTING:
    case EntityType.BIG_VALVE:
    case EntityType.SYSTEM_NODE:
    case EntityType.FIXTURE:
    case EntityType.DIRECTED_VALVE:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.LOAD_NODE:
    case EntityType.PLANT:
    case EntityType.GAS_APPLIANCE:
    case EntityType.COMPOUND:
    case EntityType.FLOW_SOURCE:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
    case EntityType.DAMPER:
      return false;
    case EntityType.EDGE:
    case EntityType.WALL:
    case EntityType.VERTEX:
    case EntityType.FENESTRATION:
    case EntityType.ROOM:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.AREA_SEGMENT:
      return true;
    default:
      assertUnreachable(entity);
  }
  return false;
}
