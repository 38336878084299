
import { defineComponent } from "vue";
import { AccessLevel, User as IUser } from "../../../common/src/models/User";
import { getOrganizations } from "../api/organizations";
import { createUser } from "../api/users";
import MainNavBar from "../components/MainNavBar.vue";
// import Organization from "./Organization.vue";
import {
  customOrgsLabel,
  defaultPasswordRequirements,
  Organization,
} from "../../../common/src/models/Organization";
import {
  emailValidation,
  passwordValidation,
  usernameValidation,
  UserValidationResult,
  validateUser,
} from "../../../common/src/models/User.validation";

export default defineComponent({
  components: {
    MainNavBar,
  },
  data() {
    const organizations: Organization[] = [];
    const selectedOrganization = null as Organization | null;
    const isSubmitted: boolean = false;

    return {
      user: {
        accessLevel: AccessLevel.USER,
        firstname: "",
        lastname: "",
        password: "",
        username: "",
        email: "",
        subscribed: true,
        eulaAcceptedVersion: 0,
        eulaAcceptedOn: null,
        lastActivityOn: null,
        lastNoticeSeenOn: null,
        temporaryOrganizationName: null,
        temporaryUser: false,
        forcePasswordChangeOnSignup: true,
        noInitialPassword: true,
      },
      isSubmitted,
      selectedOrganization,
      organizations,
    };
  },
  computed: {
    usernameValidationResult(): UserValidationResult {
      return usernameValidation(this.editedOrSuggestedUserName);
    },
    emailValidationResult(): UserValidationResult {
      return emailValidation(this.user.email);
    },
    passwordValidationResult(): UserValidationResult {
      if (this.user.noInitialPassword) {
        return { success: true };
      } else {
        let passwordRequirements = defaultPasswordRequirements;
        if (this.selectedOrganization) {
          passwordRequirements = this.selectedOrganization.passwordRequirements;
        }

        return passwordValidation(this.user.password, passwordRequirements);
      }
    },
    editedOrSuggestedUserName: {
      get(): string {
        return (
          this.user.username ||
          (this.user.firstname && this.user.lastname
            ? this.userNameFrom(this.user.firstname + this.user.lastname)
            : "")
        );
      },
      set(value: string) {
        this.user.username = value;
      },
    },
    customOrgsSelectLabel() {
      return customOrgsLabel;
    },
    profile(): IUser {
      return this.$store.getters["profile/profile"];
    },
    levels() {
      if (this.profile) {
        return [
          {
            name: "Super User",
            level: AccessLevel.SUPERUSER,
            disabled: this.profile.accessLevel > AccessLevel.SUPERUSER,
          },
          {
            name: "Admin",
            level: AccessLevel.ADMIN,
            disabled: this.profile.accessLevel >= AccessLevel.ADMIN,
          },
          {
            name: "User",
            level: AccessLevel.USER,
            disabled: this.profile.accessLevel > AccessLevel.MANAGER,
          },
        ];
      } else {
        return [];
      }
    },
    AccessLevel() {
      return AccessLevel;
    },
  },
  mounted() {
    if (this.profile && this.profile.accessLevel <= AccessLevel.ADMIN) {
      // fill documents

      getOrganizations().then((res) => {
        if (res.success) {
          this.organizations.splice(0, this.organizations.length, ...res.data);
        } else {
          this.$bvToast.toast(res.message, {
            variant: "danger",
            title: "Error retrieving org list",
          });
        }
      });
    } else if (this.profile) {
      this.selectedOrganization = this.profile.organization;
    }
  },
  methods: {
    userNameFrom(name: string): string {
      return name.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
    },
    create(andNew: boolean) {
      this.isSubmitted = true;
      this.user.username = this.editedOrSuggestedUserName;
      let passwordRequirements = defaultPasswordRequirements;
      if (this.selectedOrganization) {
        passwordRequirements = this.selectedOrganization.passwordRequirements;
      }
      if (
        validateUser(
          this.user.username,
          this.user.password,
          this.user.email,
          passwordRequirements,
          this.user.noInitialPassword,
        ).success
      ) {
        if (this.selectedOrganization) {
          createUser({
            username: this.user.username,
            firstname: this.user.firstname,
            lastname: this.user.lastname,
            email: this.user.email.toLowerCase(),
            password: this.user.password,
            accessLevel: this.user.accessLevel,
            organization: this.selectedOrganization.id,
            subscribed: this.user.subscribed,
            forcePasswordChangeOnSignup: this.user.forcePasswordChangeOnSignup,
            noInitialPassword: this.user.noInitialPassword,
          }).then((res) => {
            if (res.success) {
              if (andNew) {
                window.location.reload();
              } else {
                this.back();
              }
            } else {
              this.$bvToast.toast(res.message, {
                title: "Error creating user",
                variant: "danger",
              });
            }
          });
        }
      }
    },
    back() {
      if (this.$route.query.orgId)
        this.$router.push("/organizations/id/" + this.$route.query.orgId);
      else this.$router.push("/users");
    },
  },
});
