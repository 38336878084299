import { Lines, Stroke } from "./types";

// TODO: need to update per specs
export const STROKES: Stroke[] = [
  {
    id: "soild",
    dash: [],
  },
  {
    id: "dashed",
    dash: [6, 2],
  },
  {
    id: "dotted",
    dash: [2],
  },
  {
    id: "dot-dash",
    dash: [2, 2, 6, 2],
  },
  {
    id: "dash-dot-dot",
    dash: [6, 2, 2, 2, 2, 2],
  },
  {
    id: "medium-dash",
    dash: [8, 4],
  },
  {
    id: "short-dash",
    dash: [4, 2],
  },
  {
    id: "long-dash",
    dash: [12, 6],
  },
  {
    id: "dot-dot-dot",
    dash: [2, 2, 2, 2, 2, 6],
  },
  {
    id: "dash-dash-dot",
    dash: [6, 2, 6, 2, 2, 2],
  },
  {
    id: "dash-dash-dot-dot",
    dash: [6, 2, 6, 2, 2, 2, 2, 2],
  },
  {
    id: "long-dash-dot",
    dash: [12, 4, 4, 4],
  },

  {
    id: "long-dash-dot-dot",
    dash: [12, 4, 4, 4, 4, 4],
  },
  {
    id: "long-dash-dash-dot",
    dash: [12, 4, 12, 4, 4, 4],
  },
  {
    id: "long-dash-dash-dot-dot",
    dash: [12, 4, 12, 4, 4, 4, 4, 4],
  },
];

export const DEFAULT_LINES: Lines = {
  belowFloor: {
    name: "Below Floor",
    strokeId: "soild",
    level: {
      lt: 0,
    },
  },
  lowLevel: {
    name: "Low Level",
    strokeId: "soild",
    level: {
      gte: 0,
      lt: 1.5,
    },
  },
  highLevel: {
    name: "High Level",
    strokeId: "soild",
    level: {
      gte: 1.5,
      lt: 3,
    },
  },
  aboveCeiling: {
    name: "Above Ceiling",
    strokeId: "soild",
    level: {
      gte: 3,
    },
  },
};
