import {
  assertUnreachable,
  immutableAppend,
} from "../../../../common/src/lib/utils";
import { CanvasMouseEventRecordT } from "../../../../flatbuffers/generated/canvas-mouse-event-record";
import { ActiveReplayState } from "../../../src/store/document/types";

export enum PointerEventEnum {
  PointerDown = 0,
  PointerUp = 1,
}

const getFriendlyNameOfEvent = (event: PointerEventEnum) => {
  switch (event) {
    case PointerEventEnum.PointerUp: {
      return `lifted`;
    }
    case PointerEventEnum.PointerDown: {
      return `pressed`;
    }
    default:
      assertUnreachable(event);
  }
};

const getFriendlyNameOfButton = (button: number) => {
  switch (button) {
    case 0:
      return "left mouse button";
    case 1:
      return "middle mouse button";
    case 2:
      return "right mouse button";
    default:
      return `button ${button}`;
  }
};

export const stringifyCanvasMouseEvent = (e: CanvasMouseEventRecordT) => {
  return `${e.userName} ${getFriendlyNameOfEvent(
    e.eventType,
  )} ${getFriendlyNameOfButton(e.button)} on the canvas`;
};

export const renderCanvasMouseEventRecord = async (
  event: CanvasMouseEventRecordT,
  replayState: ActiveReplayState,
) => {
  const eventsToLog = immutableAppend(replayState.eventsToLog, event);

  const newReplayState: ActiveReplayState = {
    ...replayState,
    eventsToLog,
    lastCanvasMouseEvent: event,
  };
  return newReplayState;
};
