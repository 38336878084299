import axios from "axios";
import {
  APIResult,
  FloorPlanRenders,
} from "../../../common/src/api/document/types";
import { PaperSize, PaperSizeName } from "../../../common/src/api/paper-config";

export type PDFRenderResultSingle = {
  scaleName: string;
  scale: number;
  paperSize: PaperSize;
  key: string;
  pageNumber: number;
  totalPages: number;
  floorName: string | null;
};
export type PDFRenderResult = PDFRenderResultSingle[];

export interface ImageLinkResult {
  get: string;
  head: string;
}

export const renderPdf = async (
  file: File,
  paperSize?: PaperSizeName,
  scale?: string,
): Promise<APIResult<PDFRenderResult>> => {
  const maxSizeMB = 20; // in theory the backend should accept 50MB but it doesn't.
  const maxSizeB = maxSizeMB * 0.95 * 1024 * 1024;
  if (file.size > maxSizeB * 0.95) {
    // ^ 0.95 fudge to ensure we capture the error without hitting the backend
    const failMessage = `The selected PDF file size is too large. Please reduce the quality of your PDF or upload a smaller one. Max size is ${maxSizeMB}MB.`;
    return { success: false, message: failMessage };
  }
  const formData = new FormData();
  formData.append("pdf", file);
  paperSize && formData.append("paperSize", paperSize);
  scale && formData.append("scale", scale);

  try {
    return (
      await axios.post("/api/uploadPdf", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    ).data;
  } catch (e: any) {
    if (e.response && e.response.status === 504) {
      return {
        success: false,
        message:
          "The selected PDF file size is too large. Please reduce the quality of your PDF or upload a smaller one.",
      };
    } else if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
};

export async function getImageLink(
  key: string,
): Promise<APIResult<ImageLinkResult>> {
  try {
    return (await axios.get("/api/uploadPdf/" + key)).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}

export async function getFloorPlanRenders(
  key: string,
): Promise<APIResult<FloorPlanRenders>> {
  try {
    return (await axios.get("/api/uploadPdf/" + key + "/renders")).data;
  } catch (e: any) {
    if (e.response && e.response.data && e.response.data.message) {
      return { success: false, message: e.response.data.message };
    } else {
      return { success: false, message: e.message };
    }
  }
}
