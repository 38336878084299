
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class VersionNumber extends Vue {
  get appVersion() {
    return this.$store.getters.appVersion;
  }
  get shouldHide() {
    if (this.$route.query.hideVersion === "true") {
      return true;
    }
    return false;
  }
}
