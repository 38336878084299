import { GetterTree } from "vuex";
import { RootState } from "../../src/store/types";

export const getters: GetterTree<RootState, RootState> = {
  appVersion(state): string {
    return state.packageVersion;
  },

  effectiveCatalog(state: any) {
    const defaultCatalog = state.catalog.defaultCatalog;
    return defaultCatalog;
  },
};
