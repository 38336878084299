export enum SVG_PATH {
  SMOKE_DAMPER = "/img/canvas/smoke-damper.svg",
  FIRE_DAMPER = "/img/canvas/fire-damper.svg",
  VOLUME_CONTROL_DAMPER = "/img/canvas/volume-control-damper.svg",
  ATTENUATOR = "/img/canvas/attenuator.svg",
  INTAKE_SLOT_HORZ = "/img/canvas/intake-slot-horz.svg",
  INTAKE_SLOT_VERT = "/img/canvas/intake-slot-vert.svg",
  INTAKE_CIRC_HORZ = "/img/canvas/intake-circ-horz.svg",
  INTAKE_CIRC_VERT = "/img/canvas/intake-circ-vert.svg",
  EXHAUST_SLOT_HORZ = "/img/canvas/intake-slot-horz.svg",
  EXHAUST_SLOT_VERT = "/img/canvas/intake-slot-vert.svg",
  EXHAUST_CIRC_HORZ = "/img/canvas/intake-circ-horz.svg",
  EXHAUST_CIRC_VERT = "/img/canvas/intake-circ-vert.svg",
  SUPPLY_SLOT_HORZ = "/img/canvas/supply-slot-horz.svg",
  SUPPLY_SLOT_VERT = "/img/canvas/supply-slot-vert.svg",
  SUPPLY_CIRC_HORZ = "/img/canvas/supply-circ-horz.svg",
  SUPPLY_CIRC_VERT = "/img/canvas/supply-circ-vert.svg",
  EXTRACT_SLOT_HORZ = "/img/canvas/extract-slot-horz.svg",
  EXTRACT_SLOT_VERT = "/img/canvas/extract-slot-vert.svg",
  EXTRACT_CIRC_HORZ = "/img/canvas/extract-circ-horz.svg",
  EXTRACT_CIRC_VERT = "/img/canvas/supply-circ-vert.svg",
}

export class SvgPathLoader {
  static PATH_DATA = new Map<SVG_PATH, Path2D>();

  static get(path: SVG_PATH) {
    return this.PATH_DATA.get(path) || new Path2D();
  }

  static async loadSvgPathData() {
    for (const value of Object.values(SVG_PATH)) {
      const path2D = await this.getSvgPath2D(value);
      this.PATH_DATA.set(value, path2D);
    }
  }

  static async getSvgPath2D(url: string) {
    try {
      const response = await fetch(window.location.origin + url);
      const svgText = await response.text();
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgText, "image/svg+xml");
      // @ts-ignore
      const svgElement = svgDoc.documentElement as SVGElement;
      return this.convertSVGElementToPath2D(svgElement);
    } catch (e) {
      return new Path2D();
    }
  }

  static convertSVGElementToPath2D(svgElement: SVGElement): Path2D {
    let path = new Path2D();
    for (const child of svgElement.children) {
      path = new Path2D(path);
      switch (child.tagName.toLowerCase()) {
        case "rect":
          const x: number = parseFloat(child.getAttribute("x") || "0");
          const y: number = parseFloat(child.getAttribute("y") || "0");
          const width: number = parseFloat(child.getAttribute("width") || "0");
          const height: number = parseFloat(
            child.getAttribute("height") || "0",
          );
          path.rect(x, y, width, height);
          break;
        case "line":
          const x1: number = parseFloat(child.getAttribute("x1") || "0");
          const y1: number = parseFloat(child.getAttribute("y1") || "0");
          const x2: number = parseFloat(child.getAttribute("x2") || "0");
          const y2: number = parseFloat(child.getAttribute("y2") || "0");
          path.moveTo(x1, y1);
          path.lineTo(x2, y2);
          break;
        case "circle":
          const cx: number = parseFloat(child.getAttribute("cx") || "0");
          const cy: number = parseFloat(child.getAttribute("cy") || "0");
          const r: number = parseFloat(child.getAttribute("r") || "0");
          path.arc(cx, cy, r, 0, 2 * Math.PI);
          break;
        case "ellipse":
          const ecx: number = parseFloat(child.getAttribute("cx") || "0");
          const ecy: number = parseFloat(child.getAttribute("cy") || "0");
          const rx: number = parseFloat(child.getAttribute("rx") || "0");
          const ry: number = parseFloat(child.getAttribute("ry") || "0");
          if (path.ellipse) {
            path.ellipse(ecx, ecy, rx, ry, 0, 0, 2 * Math.PI);
          } else {
            console.warn("Ellipse not directly supported in this context");
          }
          break;
        case "polygon":
          const pointsString: string = child.getAttribute("points") || "";
          const points: number[] = pointsString
            .trim()
            .split(/\s+|,/)
            .map(Number);
          for (let i = 0; i < points.length; i += 2) {
            const px: number = points[i];
            const py: number = points[i + 1];
            if (i === 0) {
              path.moveTo(px, py);
            } else {
              path.lineTo(px, py);
            }
          }
          path.closePath(); // Close the path if it's a polygon
          break;
        case "path":
          const d: string = child.getAttribute("d") || "";
          path.addPath(new Path2D(d));
          break;
        // Additional SVG elements can be added here
        default:
          console.warn(`Unsupported SVG element: ${child.tagName}`);
          break;
      }
    }
    // store source svgElement for later retrieval in canvas2svg (we draw the source svg directly)
    //@ts-ignore
    path.svgElement = svgElement;
    return path;
  }
}
