import Flatten from "@flatten-js/core";
import { isCoreEdgeObject } from ".";
import { Coord } from "../../lib/coord";
import { assertUnreachable, cloneSimple } from "../../lib/utils";
import { GetPressureLossOptions } from "../calculations/entity-pressure-drops";
import {
  CoreContext,
  CostBreakdown,
  PressureLossResult,
} from "../calculations/types";
import { CalculationConcrete } from "../document/calculations-objects/calculation-concrete";
import { VertexLiveCalculation } from "../document/calculations-objects/vertex-calculation";
import { EntityType } from "../document/entities/types";
import { VertexEntity } from "../document/entities/vertices/vertex-entity";
import { CoreConnectable } from "./core-traits/coreConnectable";
import CoreBaseBackedObject from "./lib/coreBaseBackedObject";
import { SelectionTarget } from "./lib/types";
import { getIdentityCalculationEntityUid } from "./utils";

export const VERTEX_ARROW_LENGTH = 40;
export const VERTEX_ARROW_HEIGHT = 20;

export default class CoreVertex extends CoreConnectable(
  CoreBaseBackedObject<VertexEntity>,
) {
  type: EntityType.VERTEX = EntityType.VERTEX;
  getComponentPressureLossKPA(
    options: GetPressureLossOptions,
  ): PressureLossResult {
    throw new Error("Method not implemented.");
  }
  getCalculationEntities(context: CoreContext): [VertexEntity] {
    // Vertex calculation entities are there.
    let res: VertexEntity = {
      ...cloneSimple(this.entity),
    };
    res.uid = this.getCalculationUid(context);
    res.parentUid = getIdentityCalculationEntityUid(context, res.parentUid);
    res.calculationHeightM = 0;
    return [res];
  }
  collectCalculations(context: CoreContext): CalculationConcrete {
    // There are no calculations
    return context.globalStore.getOrCreateCalculation(
      this.getCalculationEntities(context)[0],
    );
  }
  getCalculationNode(
    context: CoreContext,
    polygonEdgeUid: string,
  ): VertexEntity {
    let res = cloneSimple(this.entity);
    res.uid = this.getCalculationUid(context);
    return res;
  }
  collectLiveCalculations(context: CoreContext): VertexLiveCalculation {
    return context.globalStore.getOrCreateLiveCalculation(
      this.getCalculationEntities(context)[0],
    );
  }

  get effectiveCenter(): Coord {
    return this.entity.center;
  }

  costBreakdown(context: CoreContext): CostBreakdown | null {
    return null;
  }
  getCalculationUid(context: CoreContext): string {
    return this.entity.uid + ".calculation";
  }
  preCalculationValidation(context: CoreContext): SelectionTarget | null {
    return null;
  }
  get point(): Flatten.Point {
    return new Flatten.Point(this.entity.center.x, this.entity.center.y);
  }
  getHash(): string {
    return this.effectiveCenter.x + " " + this.effectiveCenter.y;
  }

  // @ts-ignore
  get shape() {
    let coord = this.toWorldCoord();

    switch (this.entity.shape) {
      case "arrow":
        const lines = this.globalStore.getConnections(this.uid);
        if (lines && lines.length === 1) {
          const line = this.globalStore.get(lines[0]);

          if (isCoreEdgeObject(line)) {
            const [aw, bw] = line.worldEndpoints();
            const vec = Flatten.vector([bw.x - aw.x, bw.y - aw.y]).normalize();
            const horz = -VERTEX_ARROW_LENGTH / 2;
            const x = vec.x * horz;
            const y = vec.y * horz;

            coord = this.toWorldCoord({ x, y });
          }
        }

        break;
      case "circle":
      case "nothing":
      case undefined:
        break;
      default:
        assertUnreachable(this.entity.shape);
    }

    return Flatten.circle(Flatten.point(coord.x, coord.y), this.radius);
  }
}
