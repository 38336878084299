import * as TM from "transformation-matrix";
import CoreAnnotation from "../../../../common/src/api/coreObjects/coreAnnotation";
import {
  AnnotationEntity,
  fillDefaultAnnotationFields,
} from "../../../../common/src/api/document/entities/annotations/annotation-entity";
import {
  findAnnotationGroup,
  findVertexAFromBox,
  findVertexBFromBox,
} from "../../../../common/src/api/document/entities/annotations/utils";
import { DrawableEntityConcrete } from "../../../../common/src/api/document/entities/concrete-entity";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import {
  LinkPosition,
  LinkedVertexEntity,
} from "../../../../common/src/api/document/entities/vertices/vertex-types";
import { Coord } from "../../../../common/src/lib/coord";
import { assertUnreachable } from "../../../../common/src/lib/utils";
import { MoveIntent } from "../lib/black-magic/cool-drag";
import CanvasContext from "../lib/canvas-context";
import { getTextWrappedLines } from "../lib/drawable-annotation-utils";
import { EntityDrawingArgs } from "../lib/drawable-object";
import { EntityPopupContent } from "../lib/entity-popups/types";
import { Interaction, InteractionType } from "../lib/interaction";
import { AttachableObject } from "../lib/object-traits/attachable-object";
import { CenteredObject } from "../lib/object-traits/centered-object";
import CoolDraggableObject from "../lib/object-traits/cool-draggable-object";
import { Core2Drawable } from "../lib/object-traits/core2drawable";
import {
  HoverSiblingResult,
  HoverableObject,
} from "../lib/object-traits/hoverable-object";
import { SelectableObject } from "../lib/object-traits/selectable";
import {
  SnapIntention,
  SnapTarget,
  SnappableObject,
} from "../lib/object-traits/snappable-object";
import { DrawingContext, ObjectConstructArgs } from "../lib/types";
import {
  DrawableObjectConcrete,
  isHoverableObjectAny,
} from "./concrete-object";
import DrawableVertex from "./drawableVertex";
import { applyHoverEffects } from "./utils";

const Base = SelectableObject(
  AttachableObject(
    CoolDraggableObject(
      HoverableObject(
        CenteredObject(SnappableObject(Core2Drawable(CoreAnnotation))),
      ),
    ),
  ),
);

export default class DrawableAnnotation extends Base {
  type: EntityType.ANNOTATION = EntityType.ANNOTATION;
  attachmentOffset = 10;
  textAreaActive = false;

  // We could not add this to the base drawable class because
  // of some typescript thing so they have to be added at the concrete class.
  constructor(args: ObjectConstructArgs<AnnotationEntity>) {
    super(args.context, args.obj);
    this.onSelect = args.onSelect;
    this.onInteractionComplete = args.onInteractionComplete;
    this.document = args.document;
  }

  getSnapTargets(_request: SnapIntention[], _mouseWc: Coord): SnapTarget[] {
    throw new Error("Method not implemented.");
  }

  getHoverSiblings(): HoverSiblingResult[] {
    const result: HoverSiblingResult[] = [];

    // find the connected vertex if exists
    const vertexA = findVertexAFromBox(this.context, this);

    if (vertexA && isHoverableObjectAny(vertexA)) {
      result.push({
        object: vertexA,
        cascade: true,
      });
    }

    return result;
  }

  getPopupContent(): EntityPopupContent[] | null {
    return null;
  }

  drawEntity(context: DrawingContext, _args: EntityDrawingArgs): void {
    if (!this.isActive()) {
      return;
    }

    const { ctx } = context;
    const filled = fillDefaultAnnotationFields(context, this.entity);
    const fontSize = this.document.drawing.metadata.annotations.fontSize;
    applyHoverEffects(context, this);

    switch (filled.annoType) {
      case "box":
        this.withWorld(
          context,
          { x: -filled.anno.widthMM! / 2, y: -filled.anno.heightMM! / 2 },
          () => {
            ctx.fillStyle = filled.anno.color!.hex;
            ctx.lineWidth = 1;
            ctx.font = `normal 100 ${fontSize}px Arial`;
            if (this.isHovering) {
              ctx.strokeRect(0, 0, filled.anno.widthMM!, filled.anno.heightMM!);
            }

            let lineIdx = 1;
            for (const line of this.getTextWrappedLines(
              ctx,
              this.entity.anno.text,
              filled.anno.widthMM!,
            )) {
              // the 'verticalNudge' here nudges the text vertically up a bit to better align with the HTML textarea
              const placeholder = "Your note...";
              const isBeingPlaced = this.entity.anno.text === placeholder;
              const verticalNudge = isBeingPlaced ? 0 : 0.2;
              ctx.fillTextStable(line, 1, (lineIdx - verticalNudge) * fontSize);
              lineIdx++;
            }
          },
        );
        break;
      default:
        assertUnreachable(filled.annoType);
    }
  }

  getTextWrappedLines(
    ctx: CanvasRenderingContext2D,
    text: string,
    maxWidth: number,
  ): string[] {
    const measureTextWidth = (s: string) => ctx.measureText(s).width;
    return getTextWrappedLines(measureTextWidth, text, maxWidth);
  }

  isActive(): boolean {
    if (this.entity.drawingLayout) {
      const activeLayout = this.document.uiState.drawingLayout;
      if (activeLayout !== this.entity.drawingLayout) {
        return false;
      }
    }

    if (this.annoGroup.type === "withArrow") {
      const parentUid = this.annoGroup.vertexC.entity.parentUid;
      if (parentUid) {
        const parent = this.globalStore.get(parentUid);
        if (parent) {
          return Boolean((parent as DrawableObjectConcrete).isActive());
        }
      }
    }

    return true;
  }

  inBounds(objectCoord: Coord, _objectRadius?: number | undefined): boolean {
    const filled = fillDefaultAnnotationFields(this.context, this.entity);

    switch (filled.annoType) {
      case "box":
        if (
          objectCoord.x >= -filled.anno.widthMM! / 2 &&
          objectCoord.x <= filled.anno.widthMM! / 2 &&
          objectCoord.y >= -filled.anno.heightMM! / 2 &&
          objectCoord.y <= filled.anno.heightMM! / 2
        ) {
          return true;
        }
        break;
      default:
        assertUnreachable(filled.annoType);
    }

    return false;
  }

  prepareDelete(
    context: CanvasContext,
    _calleeEntityUid?: string,
  ): DrawableObjectConcrete[] {
    const deleteQueue: DrawableObjectConcrete[] = [this];
    // find any connected vertex if exists
    for (const [_, o] of this.globalStore) {
      if (o.entity.parentUid === this.uid) {
        deleteQueue.push(
          ...(o as DrawableObjectConcrete).prepareDelete(context, this.uid),
        );
      }
    }

    return deleteQueue;
  }

  onMouseDown(event: MouseEvent, context: CanvasContext): boolean;
  onMouseDown(event: MouseEvent, context: CanvasContext): boolean {
    context.scheduleDraw();
    this.textAreaActive = false;
    return super.onMouseDown(event, context);
  }

  wasDragged = false;
  onDragStart(
    _event: MouseEvent,
    _objectCoord: Coord,
    _context: CanvasContext,
    _isMultiDrag: boolean,
  ): any {
    this.wasDragged = false;
  }

  get annoGroup() {
    return findAnnotationGroup(this.context, this);
  }

  getValidVertexAPos(): LinkPosition[] {
    const validPos: LinkPosition[] = [];

    if (this.annoGroup.type !== "withArrow") {
      return validPos;
    }

    const linkedPos = this.annoGroup.vertexC.toWorldCoord();
    const anchorPos = this.annoGroup.vertexB.toWorldCoord();

    if (anchorPos.x >= linkedPos.x) {
      validPos.push("left");
    }

    if (anchorPos.x <= linkedPos.x) {
      validPos.push("right");
    }

    if (anchorPos.y >= linkedPos.y) {
      validPos.push("top");
    }

    if (anchorPos.y <= linkedPos.y) {
      validPos.push("bottom");
    }

    return validPos;
  }

  swapArrowSide(validPos: LinkPosition[]) {
    if (this.annoGroup.type !== "withArrow") {
      return;
    }

    const attachCoords = this.getAttachCoords();
    const linkedPos = this.annoGroup.vertexC.toWorldCoord();
    const annoBoxCenter = this.annoGroup.annotationBox.toWorldCoord();

    for (const pos of validPos) {
      switch (pos) {
        case "left":
          const leftCoord = this.toWorldCoord(attachCoords[0]);
          if (leftCoord.x - 250 >= linkedPos.x) {
            (
              this.annoGroup.vertexA.entity as LinkedVertexEntity
            ).vertex.relativePos = "left";
            this.annoGroup.vertexB.entity.center.x = leftCoord.x - 250;
            this.annoGroup.vertexB.entity.center.y = annoBoxCenter.y;
            return;
          }
          break;
        case "right":
          const rightCoord = this.toWorldCoord(attachCoords[1]);
          if (rightCoord.x + 250 <= linkedPos.x) {
            (
              this.annoGroup.vertexA.entity as LinkedVertexEntity
            ).vertex.relativePos = "right";
            this.annoGroup.vertexB.entity.center.x = rightCoord.x + 250;
            this.annoGroup.vertexB.entity.center.y = annoBoxCenter.y;
            return;
          }
          break;
        case "top":
          const topCoord = this.toWorldCoord(attachCoords[2]);
          if (topCoord.y - 250 >= linkedPos.y) {
            (
              this.annoGroup.vertexA.entity as LinkedVertexEntity
            ).vertex.relativePos = "top";
            this.annoGroup.vertexB.entity.center.x = annoBoxCenter.x;
            this.annoGroup.vertexB.entity.center.y = topCoord.y - 250;
          }

          break;
        case "bottom":
          const bottomCoord = this.toWorldCoord(attachCoords[3]);
          if (bottomCoord.y + 250 <= linkedPos.y) {
            (
              this.annoGroup.vertexA.entity as LinkedVertexEntity
            ).vertex.relativePos = "bottom";
            this.annoGroup.vertexB.entity.center.x = annoBoxCenter.x;
            this.annoGroup.vertexB.entity.center.y = bottomCoord.y + 250;
          }
          break;
        default:
          assertUnreachable(pos);
      }
    }
  }

  onDrag(
    _event: MouseEvent,
    grabbedObjectCoord: Coord,
    eventObjectCoord: Coord,
    _grabState: any,
    context: CanvasContext,
    _isMultiDrag: boolean,
  ): void {
    this.wasDragged = true;
    const before = TM.applyToPoint(this.position, grabbedObjectCoord);
    const after = TM.applyToPoint(this.position, eventObjectCoord);

    this.entity.center.x += after.x - before.x;
    this.entity.center.y += after.y - before.y;

    if (this.annoGroup.type === "withArrow") {
      this.annoGroup.vertexB.entity.center.x += after.x - before.x;
      this.annoGroup.vertexB.entity.center.y += after.y - before.y;

      const validPos = this.getValidVertexAPos();
      const vertexA = this.annoGroup.vertexA.entity as LinkedVertexEntity;

      if (!validPos.includes(vertexA.vertex.relativePos)) {
        this.swapArrowSide(validPos);
      }
    }

    context.scheduleDraw();
  }

  onDragFinish(event: MouseEvent, _context: CanvasContext): void {
    this.textAreaActive = !this.wasDragged;
    this.onInteractionComplete(event);
  }

  getCoolDragCorrelations(
    _myMove: MoveIntent,
    _from?: DrawableObjectConcrete | undefined,
  ): { object: DrawableObjectConcrete; move: MoveIntent }[] {
    const res: DrawableObjectConcrete[] = [this];

    const vertexB = findVertexBFromBox(this.context, this);
    if (vertexB) {
      res.push(vertexB as DrawableVertex);
    }

    return res.map((obj: DrawableObjectConcrete) => {
      return {
        object: obj,
        move: {
          type: "literal",
          dontPreserveAngle: true,
        },
      };
    });
  }

  offerInteraction(interaction: Interaction): DrawableEntityConcrete[] | null {
    if (interaction.type === InteractionType.LINK_ENTITY) {
      return [this.entity];
    }

    return null;
  }

  getCopiedObjects(): DrawableObjectConcrete[] {
    return [this];
  }

  getAttachCoords(): [Coord, Coord, Coord, Coord] {
    const filled = fillDefaultAnnotationFields(this.context, this.entity);

    switch (filled.annoType) {
      case "box":
        return [
          // left
          {
            x: -filled.anno.widthMM! / 2 - this.attachmentOffset,
            y: 0,
          },
          // right
          {
            x: filled.anno.widthMM! / 2 + this.attachmentOffset,
            y: 0,
          },
          // top
          {
            x: 0,
            y: -filled.anno.heightMM! / 2 - this.attachmentOffset,
          },
          // bottom
          {
            x: 0,
            y: filled.anno.heightMM! / 2 + this.attachmentOffset,
          },
        ];
      default:
        assertUnreachable(filled.annoType);
    }

    return [
      { x: 0, y: 0 },
      { x: 0, y: 0 },
      { x: 0, y: 0 },
      { x: 0, y: 0 },
    ];
  }
}
