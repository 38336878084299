import { assertUnreachable } from "../lib/utils";
import { FeatureAccess } from "./FeatureAccess";

export interface mergeOrganizationResult {
  migratedUsersNum: number;
  migratedDocumentsNum: number;
  migratedPartAssociationsNum: number;
  migratedPartCompetitorsNum?: number;
}

export enum OrganizationType {
  BUILDING_SERVICES = 0,
  MANUFACTURER = 1,
}

export interface PasswordRequirements {
  minLength: number;
  minNumbers: number;
  minSpecialChars: number;
  minLowercase: number;
  minUppercase: number;
}

export const defaultPasswordRequirements: PasswordRequirements = {
  minLength: 8,
  minNumbers: 1,
  minSpecialChars: 1,
  minLowercase: 1,
  minUppercase: 1,
};

export enum OrganizationSubscriptionStatus {
  FREE = "Free",
  PAID = "Paid",
  CHURNED = "Churned",
}

export interface Organization {
  id: string;
  name: string;
  maxAccountsNum: number | null;
  maxPasswordAttemptsBeforeLock: number | null;
  type: OrganizationType;
  passwordRequirements: PasswordRequirements;
  featureAccess: FeatureAccess;
  subscriptionStatus: OrganizationSubscriptionStatus;
  address: string;
  phoneNumber: string;
  enforceMFA: boolean;
}

export function customOrgsLabel(organization: Organization) {
  return `${organization.name} ( ${organization.id} )`;
}

export function orgTypeToLabel(type: OrganizationType) {
  switch (type) {
    case OrganizationType.BUILDING_SERVICES:
      return "Building Services";
    case OrganizationType.MANUFACTURER:
      return "Manufacturer";
  }
  assertUnreachable(type);
}
