import { isStormwater } from "../../config";
import { PipeConduitEntity } from "../../document/entities/conduit-entity";
import { flowSystemNetworkHasSpareCapacity } from "../../document/flow-systems";
import { getFlowSystem } from "../../document/utils";
import { CalculationHelper } from "../calculation-helper";
import { CoreContext } from "../types";
import { PsdCountEntry } from "../utils";

export function fillStormwaterPipeCalcResult(
  entity: PipeConduitEntity,
  context: CoreContext,
  overridePsdUnits?: PsdCountEntry,
) {
  const calc = context.globalStore.getOrCreateCalculation(entity);
  const system = getFlowSystem(context.drawing, entity.systemUid);
  const spareCapacityPCT =
    system && flowSystemNetworkHasSpareCapacity(system)
      ? system.networks[entity.conduit.network]!.spareCapacityPCT
      : 0;

  const psdUnits = overridePsdUnits || calc.psdUnits;

  if (!psdUnits || !psdUnits.continuousFlowLS) {
    return;
  }

  let flowRateLS = psdUnits?.continuousFlowLS ?? null;

  if (flowRateLS) {
    flowRateLS = CalculationHelper.fillPipeFlowRateCalcResult(
      calc,
      spareCapacityPCT,
      flowRateLS,
    );
  }

  if (!system || !isStormwater(system)) {
    return;
  }

  CalculationHelper.sizeDrainagePipe(entity, context, system, flowRateLS, calc);
}
