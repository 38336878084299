
import _ from "lodash";
import { defineComponent } from "vue";
import { getManufacturerDirectory } from "../../../common/src/api/catalog/manufacturers/utils";
import {
  customOrgsLabel,
  Organization as IOrganization,
  OrganizationSubscriptionStatus,
  OrganizationType,
  orgTypeToLabel,
} from "../../../common/src/models/Organization";
import { AccessLevel, User } from "../../../common/src/models/User";
import { validatePasswordRequirement } from "../../../common/src/models/User.validation";
import {
  getPartCompetitorByOrganization,
  updatePartCompetitors,
} from "../api/manufacturer";
import {
  getOrganization,
  getOrganizations,
  getOrgLogoUrl,
  mergeOrganization,
  updateOrganization,
  uploadOrgLogo,
} from "../api/organizations";
import { getUsers } from "../api/users";
import MainNavBar from "../components/MainNavBar.vue";
export default defineComponent({
  components: { MainNavBar },
  data() {
    const remoteLogoWideSrcUrl = null as null | string;
    const transientLogoWide = null as null | File;
    const remoteLogoSquareSrcUrl = null as null | string;
    const transientLogoSquare = null as null | File;
    const partTypes: String[] = getManufacturerDirectory().map(
      (ele) => ele.partType,
    );
    const partCompetitors: String[] = [];
    const currentPartCompetitors: String[] = [];
    const users: User[] = [];
    const mergeTargetOrganization = null as IOrganization | null;
    const organizations: IOrganization[] = [];
    const org = null as IOrganization | null;

    return {
      org,
      organizations,
      mergeTargetOrganization,
      users,
      currentPartCompetitors,
      partCompetitors,
      partTypes,
      transientLogoSquare,
      remoteLogoSquareSrcUrl,
      transientLogoWide,
      remoteLogoWideSrcUrl,
      enableSecurity: false,
      orgTypeToLabel: orgTypeToLabel,
    };
  },
  computed: {
    filteredOrganizations() {
      return this.organizations.filter(
        (organization) => organization.type === this.org?.type,
      );
    },
    types() {
      return OrganizationType;
    },
    subscriptionStatusEnum() {
      return OrganizationSubscriptionStatus;
    },
    profile(): User {
      return this.$store.getters["profile/profile"];
    },
    canEditOrganizationType() {
      return this.profile.accessLevel <= AccessLevel.ADMIN;
    },
    canEditOrganizationMaxAccountsNum() {
      return this.profile.accessLevel <= AccessLevel.ADMIN;
    },
    canEditMaxPasswordAttemptsBeforeLock() {
      return this.profile.accessLevel <= AccessLevel.MANAGER;
    },
    canMergeOrganization() {
      return this.profile.accessLevel <= AccessLevel.ADMIN;
    },
    canEditOrganizationSubscriptionStatus() {
      return this.profile.accessLevel <= AccessLevel.ADMIN;
    },
    canEditEnforceMFA() {
      return this.profile.accessLevel <= AccessLevel.MANAGER;
    },
    canEditFeatureAccess() {
      return this.profile.accessLevel <= AccessLevel.ADMIN;
    },
    customOrgsSelectLabel() {
      return customOrgsLabel;
    },
    usersNum() {
      if (this.users.length === 0) {
        return "Loading...";
      }
      return this.users.filter((ele) => ele.organization?.id === this.org?.id)
        .length;
    },
    AccessLevel() {
      return AccessLevel;
    },
    OrganizationType() {
      return OrganizationType;
    },
    getTransientLogoSquareSrc() {
      if (!this.transientLogoSquare) return null;
      return URL.createObjectURL(this.transientLogoSquare);
    },
    getTransientLogoWideSrc() {
      if (!this.transientLogoWide) return null;
      return URL.createObjectURL(this.transientLogoWide);
    },
  },

  mounted() {
    if (this.profile && this.profile.accessLevel <= AccessLevel.ADMIN) {
      // fill documents
      getOrganizations().then((res) => {
        if (res.success) {
          this.organizations.splice(0, this.organizations.length, ...res.data);
        } else {
          this.$bvToast.toast(res.message, {
            variant: "danger",
            title: "Error retrieving org list",
          });
        }
      });
    }
    getUsers().then((res) => {
      if (res.success) {
        this.users.splice(0, this.users.length, ...res.data);
      } else {
        this.$bvToast.toast(res.message, {
          variant: "danger",
          title: "Error retrieving user list",
        });
      }
    });

    // OV semantically this is wrong but it's how it was in the past as a class component so I leave this
    // confusingly as called 'beforeCreate'
    this.beforeCreate();
  },
  methods: {
    async beforeCreate() {
      // get the organization
      const orgRequest = await getOrganization(this.$route.params.id);
      if (!orgRequest.success) {
        this.$bvToast.toast(orgRequest.message, {
          title: "Error retrieving data about organization",
          variant: "danger",
        });
        return;
      }

      // set in state
      this.org = orgRequest.data;

      // get remote logo urls
      (async () => {
        const urlRes = await getOrgLogoUrl(this.org!.id, "medium", "square");
        if (!urlRes.success) return;
        this.remoteLogoSquareSrcUrl = urlRes.data;
      })();
      (async () => {
        const urlRes = await getOrgLogoUrl(this.org!.id, "medium", "wide");
        if (!urlRes.success) return;
        this.remoteLogoWideSrcUrl = urlRes.data;
      })();

      if (
        this.org &&
        this.org.type === OrganizationType.MANUFACTURER &&
        this.profile.accessLevel <= AccessLevel.ADMIN
      ) {
        getPartCompetitorByOrganization(this.org.id).then((res) => {
          if (res.success) {
            this.partCompetitors = res.data.map((ele) => ele.partType);
            this.currentPartCompetitors = this.partCompetitors;
          } else {
            this.$bvToast.toast(res.message, {
              title: "Error retriving part competitors",
              variant: "danger",
            });
          }
        });
      }
    },
    mergeOrg() {
      if (this.mergeTargetOrganization == null) {
        this.$bvToast.toast(
          "Please check your target organization for merging again",
          {
            title: "Error merging organizations",
            variant: "danger",
          },
        );
      } else if (this.org == null) {
        this.$bvToast.toast("Current organization can't be null'", {
          title: "Error merging organizations",
          variant: "danger",
        });
      } else if (this.org.id === this.mergeTargetOrganization.id) {
        this.$bvToast.toast("You cannot merge to the same organization", {
          title: "Error merging organizations",
          variant: "danger",
        });
      } else if (this.org.type !== this.mergeTargetOrganization.type) {
        this.$bvToast.toast(
          "Target organization should have the same type as the current organization",
          {
            title: "Error merging organizations",
            variant: "danger",
          },
        );
      } else {
        mergeOrganization(this.org!.id, this.mergeTargetOrganization!.id).then(
          (res) => {
            if (res.success) {
              const message = `You have successfully merged ${res.data.migratedUsersNum} users, ${res.data.migratedDocumentsNum} documents, ${res.data.migratedPartAssociationsNum} part associations and ${res.data.migratedPartCompetitorsNum} part competitors from ${this.org?.name} to ${this.mergeTargetOrganization?.name}`;
              this.$router.push("/organizations").then(() => {
                this.$bvToast.toast(message, {
                  title: "Merged organizations successfully",
                  variant: "primary",
                });
              });
            } else {
              this.$bvToast.toast(res.message, {
                title: "Error merging organizations",
                variant: "danger",
              });
            }
          },
        );
      }
    },
    async save() {
      const processOrgMetaData = async () => {
        if (!this.org) return;

        // coerce maxAccountsNum value
        // @ts-ignore: number can't equal to empty string error
        if (this.org.maxAccountsNum === "") {
          this.org.maxAccountsNum = null;
        }

        // validate security settings
        const passwordReqValidation = validatePasswordRequirement(
          this.org.passwordRequirements,
        );
        if (!passwordReqValidation.success) {
          this.$bvToast.toast(passwordReqValidation.message, {
            title: "Error in your password requirements",
            variant: "danger",
          });
          return;
        }

        // send plain metadata to backend
        const metaDataUploadResult = await updateOrganization(
          this.org.id,
          this.org.name,
          this.org.type,
          this.org.maxAccountsNum,
          this.org.maxPasswordAttemptsBeforeLock,
          this.org.passwordRequirements,
          this.org.subscriptionStatus,
          this.org.featureAccess,
          this.org.address,
          this.org.phoneNumber,
          this.org.enforceMFA,
        );
        if (!metaDataUploadResult.success) {
          this.$bvToast.toast(metaDataUploadResult.message, {
            title: "Error saving organization metadata",
            variant: "danger",
          });
          return;
        }
        this.$bvToast.toast(
          "You have successfully saved changes to the organization",
          {
            title: "Saved organization successfully",
            variant: "primary",
          },
        );
      };

      const processSquareLogo = async () => {
        if (!this.org) return;
        if (this.transientLogoSquare) {
          const file = this.transientLogoSquare;
          const fileUploadResult = await uploadOrgLogo(
            this.org.id,
            file,
            "square",
          );
          if (!fileUploadResult.success) {
            this.$bvToast.toast(fileUploadResult.message, {
              title: "Error uploading square organization logo",
              variant: "danger",
            });
            return;
          }
          setTimeout(async () => {
            const urlRes = await getOrgLogoUrl(
              this.org!.id,
              "medium",
              "square",
            ); // regen url for cache-busting
            if (!urlRes.success) return;
            this.remoteLogoSquareSrcUrl = urlRes.data;
            this.transientLogoSquare = null; // clear the preview so it defaults to remote
          }, 1000);

          this.$bvToast.toast(
            "You have successfully uploaded square organization logo",
            {
              title: "Uploaded organization logo successfully",
              variant: "primary",
            },
          );
        }
      };

      const processWideLogo = async () => {
        if (!this.org) return;
        if (this.transientLogoWide) {
          const file = this.transientLogoWide;
          const fileUploadResult = await uploadOrgLogo(
            this.org.id,
            file,
            "wide",
          );
          if (!fileUploadResult.success) {
            this.$bvToast.toast(fileUploadResult.message, {
              title: "Error uploading wide organization logo",
              variant: "danger",
            });
            return;
          }
          setTimeout(async () => {
            const urlRes = await getOrgLogoUrl(this.org!.id, "medium", "wide"); // regen url for cache-busting
            if (!urlRes.success) return;
            this.remoteLogoWideSrcUrl = urlRes.data;
            this.transientLogoWide = null; // clear the preview so it defaults to remote
          }, 1000);
          this.$bvToast.toast(
            "You have successfully uploaded wide organization logo",
            {
              title: "Uploaded organization logo successfully",
              variant: "primary",
            },
          );
        }
      };

      const processPartCompetitors = async () => {
        if (!this.org) return;
        const partCompetitorsHasChanged = !_.isEqual(
          this.partCompetitors.sort(),
          this.currentPartCompetitors.sort(),
        );
        if (partCompetitorsHasChanged) {
          if (this.profile.accessLevel > AccessLevel.ADMIN) {
            // ^ if less privileged than admin...
            this.$bvToast.toast(
              "You are not allowed to update part competitors",
              {
                title: "Error updating part competitors",
                variant: "danger",
              },
            );
            return;
          }
          const partCompetitorsResult = await updatePartCompetitors(
            this.org.id,
            this.partCompetitors,
          );
          if (!partCompetitorsResult.success) {
            this.$bvToast.toast(partCompetitorsResult.message, {
              title: "Error updating part competitors",
              variant: "danger",
            });
          }
          this.$bvToast.toast(
            "You have successfully updated part competitors",
            {
              title: "Updating part competitors successfully",
              variant: "primary",
            },
          );
        }
      };

      await Promise.all([
        processOrgMetaData(),
        processSquareLogo(),
        processWideLogo(),
        processPartCompetitors(),
      ]);
    },
  },
});
