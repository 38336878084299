import { Module } from "vuex";
import { actions } from "../../../src/store/profile/actions";
import { getters } from "../../../src/store/profile/getters";
import { mutations } from "../../../src/store/profile/mutations";
import ProfileState from "../../../src/store/profile/types";
import { RootState } from "../../../src/store/types";

export const state: ProfileState = {
  profile: null,
  viewedVideoIds: [],
  numDrawingsCreated: 0,
  numFeedbackSubmitted: 0,
  locale: null,

  properties: {},
  inFlightProperties: {},
};

const namespaced: boolean = true;

export const profile: Module<ProfileState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
