import Flatten from "@flatten-js/core";
import { minBy } from "lodash";
import {
  canonizeAngleRad,
  isASimplyConnectedRegion,
  isClockWise,
  pointInsidePolygon,
  polygonDifference,
  polygonDirectedAreaM2,
} from "../../../../lib/mathUtils/mathutils";
import { EPS, assertUnreachable } from "../../../../lib/utils";
import { CorePolygonObjectConcrete } from "../../../coreObjects";
import { PolygonVisFixture } from "../loop-generator/types";
import { calculateCentroidFlatten } from "../roof-calculation/utils";

const MIN_ROOM_AREA_MM2 = 100 * 100;

export interface GraphNodeFenEntryData {
  type: "fen-entry";
  entrySiteUid: string;
  numEntries: number;
  normal: Flatten.Vector;
}

export interface GraphNodeAutoEntryData {
  type: "auto-entry";
  entrySiteUid: string;
  numEntries: number;
  pivot: Flatten.Point;

  // neighbour1 -> pivot -> neighbour2 should form the surrounding edges of the entry
  // in CW order.
  neighbor1: Flatten.Point;
  neighbor2: Flatten.Point;

  // temporarily have a normal here for debugging for now
  // normal: Flatten.Vector;
}

export const AUTO_ENTRY_MIN_EDGE_LENGTH_MM = 10;

export function findFarNeighbours(
  polygon: Polygon,
  index: number,
): [Flatten.Point, Flatten.Point] {
  const v = polygon.vertices[index];

  let v0: Flatten.Point =
    polygon.vertices[
      (index - 1 + polygon.vertices.length) % polygon.vertices.length
    ];
  let v1 = polygon.vertices[(index + 1) % polygon.vertices.length];
  for (let j = 1; j < polygon.vertices.length; j++) {
    const thisV =
      polygon.vertices[
        (index - j + polygon.vertices.length) % polygon.vertices.length
      ];
    if (v.distanceTo(thisV)[0] > AUTO_ENTRY_MIN_EDGE_LENGTH_MM) {
      v0 = thisV;
      break;
    }
  }
  for (let j = 1; j < polygon.vertices.length; j++) {
    const thisV = polygon.vertices[(index + j) % polygon.vertices.length];
    if (v.distanceTo(thisV)[0] > AUTO_ENTRY_MIN_EDGE_LENGTH_MM) {
      v1 = thisV;
      break;
    }
  }
  return [v0, v1];
}

export type GraphNodeEntryData = GraphNodeFenEntryData | GraphNodeAutoEntryData;

export function getEntryNormal(entry: GraphNodeEntryData): Flatten.Vector {
  return Flatten.vector(1, 0);
}

export function getEntryCableNormal(entry: GraphNodeEntryData): Flatten.Vector {
  switch (entry.type) {
    case "auto-entry": {
      // Even though we don't know the actual normal, we can set it between
      // the wall neighbours and be sure it's the correct order, which is
      // the only thing we need for the cable graph.
      const v1 = Flatten.vector(entry.pivot, entry.neighbor1).normalize();
      const v2 = Flatten.vector(entry.neighbor2, entry.pivot).normalize();

      // special case of parallel - it means we are entering via a straight wall.
      // In this case, we go perpendicular into the area.
      if (v1.add(v2.multiply(-1)).length < 0.01) {
        return v1.rotate90CCW();
      }

      const bisect = v1.add(v2.multiply(-1)).normalize();

      if (v1.cross(v2) < 0) {
        return bisect.multiply(1);
      } else {
        return bisect;
      }
    }
    case "fen-entry": {
      return entry.normal;
    }
  }
  assertUnreachable(entry);
}

// We use the graph class to generate the wall graph itself.
export interface GraphNode {
  point: Flatten.Point;
  uid: string;
}
export function serializeNode(node: GraphNode): string {
  return `${node.point.x},${node.point.y}-${node.uid}`;
}

export interface GraphEdge {
  // siteUid is for fenestrations to know which heated area (or room) to connect to.
  // So, even though edges inside of a room are connected to 2 sites, we only care about
  // the perimeter for fenestrations so we only need one siteUid.
  siteUid: string | null;
  normal: Flatten.Vector;

  // "ccw" is the default for walls. "cw" and "ccw" is used for left-right alignment through
  // doors that are close to walls.
  align: "cw" | "ccw" | "center" | "cw-thru" | "ccw-thru";
}

export interface Polygon {
  uid: string;
  vertices: Flatten.Point[];
}

export function toPolygon(obj: CorePolygonObjectConcrete): Polygon {
  return {
    uid: obj.uid,
    vertices: obj
      .collectVerticesInOrderCCW()
      .map((v) => Flatten.point(v.toWorldCoord().x, v.toWorldCoord().y)),
  };
}

export interface IntentionLine {
  start: Flatten.Point;
  end: Flatten.Point;
  normal: Flatten.Vector | null; // null means line was center oriented.

  // Justification of edge connected to the end of the intention.
  // Used to figure out what to do in parallel cases (eg with doors, causing
  // a shift in the line).
  adjAlign: "cw" | "ccw" | "center" | "cw-thru" | "ccw-thru" | null;
  adjRawDir: "cw" | "ccw" | null;
  shiftIndex: number;
}

// Note: will mutate path to make entrance better, and append "end cap" intention
// that finishes the pipe off at the end when expanding.
export function tweakEntranceAndFinishPath(
  entry: GraphNodeEntryData,
  path: IntentionLine[],
  lastCrowdedness: number,
  transitSpacingMM: number,
  loopSpacingMM: number,
): {
  normal: Flatten.Vector;
  center: Flatten.Point;
} {
  const lastPath = path[path.length - 1];
  const lastVector = Flatten.vector(lastPath.start, lastPath.end).normalize();

  let normal: Flatten.Vector;
  let center: Flatten.Point;
  switch (entry.type) {
    case "auto-entry": {
      const { center: newCenter, normal: newNormal } = getAutoEntryLine(
        entry,
        path,
        lastCrowdedness,
        transitSpacingMM,
        loopSpacingMM,
      );
      center = newCenter;
      normal = newNormal;
      break;
    }
    case "fen-entry": {
      lastPath.end = lastPath.end.translate(
        lastVector.multiply(transitSpacingMM * (2 * lastCrowdedness + 1)),
      );

      normal = entry.normal;
      center = lastPath.end;
      break;
    }
    default: {
      assertUnreachable(entry);
    }
  }

  const trueLastPath = path[path.length - 1];
  const trueLastVector = Flatten.vector(trueLastPath.start, trueLastPath.end);

  const capLine = {
    start: trueLastPath.end,
    end: trueLastPath.end.translate(trueLastVector.rotate90CCW()),
    normal: null,
    shiftIndex: 0,
    adjRawDir: null,
    adjAlign: null,
  } satisfies IntentionLine;
  path.push(capLine);

  return {
    normal: normal!,
    center: center!,
  };
}

function getAutoEntryLine(
  entry: GraphNodeAutoEntryData,
  path: IntentionLine[],
  lastCrowdedness: number,
  transitSpacingMM: number,
  loopSpacingMM: number,
): {
  normal: Flatten.Vector;
  center: Flatten.Point;
} {
  // Check out heated-area-entry-angle-cases.jpeg for a diagram.
  const lastPath = path[path.length - 1];
  if (!lastPath.normal) {
    throw new Error(
      "Expected last path to have a normal when entering a heated area",
    );
  }

  const prevVec = Flatten.vector(lastPath.start, lastPath.end).normalize();
  const prevLine = Flatten.line(lastPath.start, lastPath.end);

  // Diagram goes last vector -> normal clockwise.
  const lastVectorIsCCW = prevVec.cross(lastPath.normal) > 0;
  const isFlipped = lastVectorIsCCW;
  const flipMul = isFlipped ? -1 : 1;

  const srcRadialVec = prevVec.multiply(-1);

  const neighbourVec1 = Flatten.vector(
    entry.pivot,
    entry.neighbor1,
  ).normalize();
  const neighbourVec2 = Flatten.vector(
    entry.pivot,
    entry.neighbor2,
  ).normalize();

  // Looks like it is flipped but now it is correct.
  const v1 = isFlipped ? neighbourVec1 : neighbourVec2;
  const v2 = isFlipped ? neighbourVec2 : neighbourVec1;
  const v1Line = Flatten.line(entry.pivot, entry.pivot.translate(v1));

  let v1Angle = isFlipped ? v1.angleTo(srcRadialVec) : srcRadialVec.angleTo(v1);
  if (v1Angle > Math.PI * 1.99) {
    v1Angle = 0;
  }
  let v2Angle = isFlipped ? v2.angleTo(srcRadialVec) : srcRadialVec.angleTo(v2);
  if (v2Angle < Math.PI * 0.01) {
    v2Angle = Math.PI * 2;
  }
  const vAngleDiff = v2Angle - v1Angle;

  if (v2Angle < v1Angle) {
    // Rare, unintentional edge case.
    console.error({ v1Angle, v2Angle, entry, isFlipped, v1, v2, srcRadialVec });
    // throw new Error(
    //   "v2Angle should be greater than v1Angle - perhaps polygon is not in CW order when generating the auto entry?",
    // );

    // Just bisect, don't throw
    return {
      normal: srcRadialVec.rotate((v1Angle + v2Angle) / 2 + Math.PI),
      center: entry.pivot,
    };
  }

  const bisectAngle = canonizeAngleRad((v1Angle + v2Angle) / 2);

  // We limit the corner angle to 125 degrees so we don't get extreme cases
  // when V2 nearly u-turns on the entry.
  const cornerAngle = Math.min(v2Angle / 2, Math.PI * 0.75);

  const needsNewLine = v1Angle > cornerAngle;

  const trueCornerVector = srcRadialVec.rotate(cornerAngle * flipMul);

  const trueCornerLine = Flatten.line(
    entry.pivot,
    entry.pivot.translate(trueCornerVector),
  );

  let normalAngle: number | null = null;
  // 4 cases like the diagram
  if (v1Angle < Math.PI / 2) {
    // V2 / bisect
    if (vAngleDiff < Math.PI * 0.95) {
      normalAngle = v2Angle;
    } else {
      normalAngle = bisectAngle;
    }
  } else if (v1Angle < Math.PI * 0.95) {
    // V2 / 90 / bisect
    if (vAngleDiff < Math.PI * 0.55) {
      normalAngle = v2Angle;
    } else if (vAngleDiff < Math.PI * 0.95) {
      normalAngle = v1Angle + Math.PI / 2;
    } else {
      normalAngle = bisectAngle;
    }
  } else if (v1Angle < Math.PI * 1.25) {
    // min(270, V2 angle)
    normalAngle = Math.min(v2Angle, Math.PI * 1.5);
  } else {
    // always bisect
    normalAngle = bisectAngle;
  }
  const trueNormalAngle = normalAngle! * flipMul;

  const trueNormalStart = prevLine.intersect(trueCornerLine)[0];
  const trueNormal = srcRadialVec.rotate(trueNormalAngle);
  const trueNormalLine = Flatten.line(
    trueNormalStart,
    trueNormalStart.translate(trueNormal),
  );
  const trueNormalEnd = trueNormalLine.intersect(v1Line)[0];

  const oldEnd = lastPath.end;
  path[path.length - 1].end = trueNormalStart;

  if (needsNewLine) {
    const newLine = {
      start: trueNormalStart,
      end: trueNormalEnd,
      normal: trueNormal,
      shiftIndex: 0,
      adjAlign: null,
      adjRawDir: null,
    } as IntentionLine;
    path.push(newLine);
  }

  const beforeSpace = path[path.length - 1].end;
  // Go half a loop spacing away from the wall.
  const spaceVector = trueNormal
    .rotate((Math.PI / 4) * flipMul * -1)
    .multiply((loopSpacingMM / 2 - transitSpacingMM / 2) * Math.sqrt(2));

  return {
    normal: trueNormal,
    center: beforeSpace.translate(spaceVector),
  };
}

export function extendPolygonFromVertex(
  polygonCCW: Flatten.Point[],
  vertexIndex: number,
  proposedPoint: Flatten.Point,
): Flatten.Point[] {
  const isCToTheRightOfAB = (
    a: Flatten.Point,
    b: Flatten.Point,
    c: Flatten.Point,
  ) => isClockWise([a, b, c]);

  const vertex = polygonCCW[vertexIndex];
  const prev =
    polygonCCW[(vertexIndex + polygonCCW.length - 1) % polygonCCW.length];
  const next = polygonCCW[(vertexIndex + 1) % polygonCCW.length];

  const isToTheRightOfPrevEdge = isCToTheRightOfAB(prev, vertex, proposedPoint);
  const isToTheRightOfNextEdge = isCToTheRightOfAB(vertex, next, proposedPoint);

  const potentialNewPoly = (() => {
    if (isToTheRightOfPrevEdge && isToTheRightOfNextEdge) {
      // Replaces point with proposedPoint
      return [
        ...polygonCCW.slice(0, vertexIndex),
        proposedPoint,
        ...polygonCCW.slice(vertexIndex + 1),
      ];
    } else if (isToTheRightOfPrevEdge) {
      // Insert proposedPoint between prev and point
      return [
        ...polygonCCW.slice(0, vertexIndex),
        proposedPoint,
        ...polygonCCW.slice(vertexIndex),
      ];
    } else if (isToTheRightOfNextEdge) {
      // Insert proposedPoint between point and next
      return [
        ...polygonCCW.slice(0, vertexIndex + 1),
        proposedPoint,
        ...polygonCCW.slice(vertexIndex + 1),
      ];
    }
    return polygonCCW.slice();
  })();

  // Check for self-cutting
  if (isASimplyConnectedRegion(potentialNewPoly)) {
    return potentialNewPoly;
  }
  return polygonCCW.slice();
}

/** Snaps the polygon onto the site, guaranteeing that the new polygon contains
 * the original polygon. Returns the new polygon in CCW order. */
export function snapPolygonToSite(
  polyVerticesInOrder: Flatten.Point[],
  siteVerticesInOrder: Flatten.Point[],
  tolerance: number,
) {
  const siteSegments = siteVerticesInOrder.map((vertex, i) => {
    const nextVertex =
      siteVerticesInOrder[(i + 1) % siteVerticesInOrder.length];
    return Flatten.segment(vertex, nextVertex);
  });

  const polyVerticesCCW = isClockWise(polyVerticesInOrder)
    ? polyVerticesInOrder.slice().reverse()
    : polyVerticesInOrder.slice();

  const centroid = calculateCentroidFlatten(polyVerticesCCW);

  const proposeTarget = (vertex: Flatten.Point) => {
    const notInSite = !pointInsidePolygon(vertex, siteVerticesInOrder, false);
    if (notInSite) {
      return null;
    }

    const candidates = siteSegments
      .map((segment) => {
        const [dist, { pe: projection }] = vertex.distanceTo(segment);
        return {
          dist,
          projection,
        };
      })
      .filter((candidate) => {
        return candidate.dist < tolerance;
      });

    const best = minBy(candidates, (candidate) => candidate.dist);
    if (!best) {
      return null;
    }

    const centroidToBest = Flatten.vector(centroid, best.projection);
    const overshot =
      centroidToBest.length > EPS
        ? best.projection.translate(centroidToBest.normalize())
        : best.projection;

    // Deduplicate
    if (overshot.distanceTo(vertex)[0] < EPS) {
      return null;
    }

    return overshot;
  };

  const snappedPolygonCCW = polyVerticesCCW.reduce((currentPolyCCW, vertex) => {
    const indexInCurrentPoly = currentPolyCCW.findIndex(
      (p) => p.distanceTo(vertex)[0] < EPS,
    );
    if (indexInCurrentPoly === -1) return currentPolyCCW;

    const proposedTarget = proposeTarget(vertex);

    return proposedTarget
      ? extendPolygonFromVertex(
          currentPolyCCW,
          indexInCurrentPoly,
          proposedTarget,
        )
      : currentPolyCCW;
  }, polyVerticesCCW.slice());

  return snappedPolygonCCW;
}

export function purePolygon(poly: Polygon | Flatten.Point[]) {
  if (Array.isArray(poly)) {
    return poly.map((v) => ({ x: v.x, y: v.y }));
  }
  return poly.vertices.map((v) => ({ x: v.x, y: v.y }));
}

export function getLargestPolygonGap(
  parent: Polygon,
  holes: Polygon[],
): Flatten.Point[] {
  let candidates: Flatten.Point[][] = [Array.from(parent.vertices)];

  const debug: PolygonVisFixture[] = [];

  for (const h of holes) {
    debug.push({
      name: "Hole",
      polygons: [
        {
          points: purePolygon(h),
          color: "#00ff00",
        },
      ],
    });

    for (const candidate of candidates) {
      debug[debug.length - 1].polygons.push({
        points: candidate.map((p) => ({ x: p.x, y: p.y })),
        color: "#ff0000",
      });
    }

    const newCandidates: Flatten.Point[][] = [];
    for (const candidate of candidates) {
      const diff = polygonDifference(candidate, h.vertices);
      for (const d of diff) {
        const areaM2 = Math.abs(polygonDirectedAreaM2(d));
        if (areaM2 > MIN_ROOM_AREA_MM2 / 1000 / 1000) {
          newCandidates.push(d.map((p) => Flatten.point(p.x, p.y)));
        }
      }
    }
    candidates = newCandidates;
    for (const candidate of candidates) {
      debug[debug.length - 1].polygons.push({
        points: candidate.map((p) => ({ x: p.x, y: p.y })),
        color: "#0000ff",
      });
    }
  }

  let largestAreaMM2 = 0;
  let largestCandidate: Flatten.Point[] = [];
  for (const candidate of candidates) {
    const areaM2 = Math.abs(polygonDirectedAreaM2(candidate));
    if (areaM2 > largestAreaMM2) {
      largestAreaMM2 = areaM2;
      largestCandidate = candidate;
    }
  }

  debug.push({
    name: "Largest",
    polygons: [
      {
        points: purePolygon(largestCandidate),
        color: "#ff0000",
      },
    ],
  });

  return largestCandidate;
}
