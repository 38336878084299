
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { ChangeLogMessage } from "../../../common/src/models/ChangeLogMessage";
import { User } from "../../../common/src/models/User";
import MainNavBar from "../../src/components/MainNavBar.vue";
import {
  deleteChangeLogMessage,
  getChangeLogMessages,
  saveChangeLogMessage,
} from "../api/change-log";
import Markdown from "../components/molecules/Markdown.vue";

@Component({
  components: {
    MainNavBar,
    Markdown,
  },
})
export default class ChangeLogs extends Vue {
  logMessages: ChangeLogMessage[] = [];
  isLoaded: boolean = false;
  value: string = "";
  version: string = "";
  tags: string = "";
  message: string = "";
  deleteId: number | null = null;

  onSubmit(event: any) {
    saveChangeLogMessage(this.message, this.tags, this.version).then((res) => {
      if (res.success) {
        this.logMessages.unshift(res.data);
        this.message = "";
        this.tags = "";
        this.version = "";
      } else {
        this.$bvToast.toast(res.message, {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    });
    // prevent default action
    event.preventDefault();
  }

  get lastMonth() {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date;
  }

  deleteChangelog(id: number) {
    deleteChangeLogMessage(id).then((res) => {
      if (res.success) {
        this.logMessages = this.logMessages.filter((log) => log.id !== id);
      } else {
        this.$bvToast.toast(res.message, {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    });
  }

  mounted() {
    // get all change log messages
    getChangeLogMessages(this.lastMonth).then((res) => {
      if (res.success) {
        this.logMessages.splice(0, this.logMessages.length, ...res.data);
        this.isLoaded = true;
      }
    });
  }

  get profile(): User {
    return this.$store.getters["profile/profile"];
  }
}
