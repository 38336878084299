export const GROUND_FLOOR_MIN_HEIGHT_M = -0.5;

export const ALL_KEY_VERSION = 5;

export interface CombineFilters {
  [key: string]: CombineFilter[];
}

export interface CombineFilter {
  name: string;
  fields: RegExp[];
}

export interface OnboardingStep {
  id: ProgressId;
  title: string;
  isDone: boolean;
  steps: {
    id: ProgressId;
    title: string;
    isDone: boolean;
    hints?: string[];
    isManual?: boolean;
    description?: string;
    videoLink?: string;
  }[];
}

// Progresses when it progresses automatically as the user interacts with the app
export enum ProgressId {
  SingUpStep = "SingUpStep",
  CreateProjectStep = "CreateProjectStep",
  FloorPlanStep = "FloorPlanStep",
  LevelsStep = "LevelsStep",
  DesignStep = "DesignStep",
  ResultsStep = "ResultsStep",
  ExportStep = "ExportStep",
  FloorPlanMode = "FloorPlanMode",
  DesignMode = "DesignMode",
  ResultsMode = "ResultsMode",
  ExportMode = "ExportMode",
  MechanicalDrawingLayout = "MechanicalDrawingLayout",
  PressureDrawingLayout = "PressureDrawingLayout",
  DrainageDrawingLayout = "DrainageDrawingLayout",
  UploadPDFButton = "UploadPDFButton",
  StartRoomDrawing = "StartRoomDrawing",
  SelectBackground = "SelectBackground",
  OpenLevelsBox = "OpenLevelsBox",
  PickDesignMode = "PickDesignMode",
  InsertHeatInterfaceUnit = "InsertHeatInterfaceUnit",
  InsertHeatSource = "InsertHeatSource",
  CalibratePDF = "CalibratePDF",
  AddNewLevel = "AddNewLevel",
  AlignPDFs = "AlignPDFs",
  InsertHotWaterCylinder = "InsertHotWaterCylinder",
  DrawPipes = "DrawPipes",
  AddRisers = "AddRisers",
  AddHeatEmitters = "AddHeatEmitters",
  AddValves = "AddValves",
  PickFlowSystem = "PickFlowSystem",
  InsertFlowSource = "InsertFlowSource",
  InsertStorageTank = "InsertStorageTank",
  InsertBoosterPump = "InsertBoosterPump",
  InsertHotWaterPlant = "InsertHotWaterPlant",
  InsertNodes = "InsertNodes",
  InsertDucts = "InsertDucts",
  InsertFixtures = "InsertFixtures",
  InsertAppliances = "InsertAppliances",
  InsertStacks = "InsertStacks",
  InsertVents = "InsertVents",
  InsertValvesAndAccessories = "InsertValvesAndAccessories",
  InsertPitsTanksTraps = "InsertPitsTanksTraps",
  ResultsFilters = "ResultsFilters",
  ResultsWarnings = "ResultsWarnings",
  ViewSettings = "ViewSettings",
  ExportReadyOnly = "ExportReadyOnly",
  ExportPDF = "ExportPDF",
  ExportBillOfMaterials = "ExportBillOfMaterials",
  ExportDesignReport = "ExportDesignReport",
  ExportAutoCAD = "ExportAutoCAD",
  ExportRevit = "ExportRevit",
}

export enum DesignModes {
  HeatingChilled = "Heating/Chilled",
  Water = "Water",
  Gas = "Gas",
  Wastewater = "Wastewater",
}

export interface OnboardingSavedProgress {
  manualStepsDone: { [key: string]: boolean };
  mainStepsDone: { [key: string]: boolean };
  activeStep: number;
  activeSubStep: number;
  selectedMode: DesignModes | null;
}

export type ExportItemKey =
  | "pdf"
  | "bom"
  | "heatLossReport"
  | "designReport"
  | "autoCad"
  | "revit";
