import { Units, UnitsContext } from "../../../lib/measurements";
import { EntityHeatLoadCalculation } from "../../calculations/heatloss/heat-loss-result-type";
import { CoreContext } from "../../calculations/types";
import { WallEntity } from "../entities/wall-entity";
import { CalculationField, FieldCategory } from "./calculation-field";
import { HEATGAIN_RESULT_HEX, HEATLOSS_RESULT_HEX } from "./room-calculation";
import {
  CalcFieldSelector,
  Calculation,
  CalculationType,
  LiveCalculation,
} from "./types";

export default interface WallCalculation
  extends Calculation,
    EntityHeatLoadCalculation {
  type: CalculationType.WallCalculation;
}

export interface WallLiveCalculation extends LiveCalculation {}

export const FastLiveWallCalculationFields: CalcFieldSelector<WallLiveCalculation> =
  {
    connected: true,
  };

export const StickyWallCalcualtionFields: CalcFieldSelector<WallCalculation> =
  {};

export function makeWallCalculationFields(
  context: CoreContext,
  entity: WallEntity,
): CalculationField[] {
  let result: CalculationField[] = [];
  result.push({
    property: "areaM2",
    title: "Wall Area",
    short: "",
    units: Units.SquareMeters,
    category: FieldCategory.Size,
    layouts: ["mechanical"],
    defaultEnabled: false,
  });

  result.push({
    property: "lengthM",
    title: "Wall Length",
    short: "",
    units: Units.Meters,
    category: FieldCategory.Length,
    layouts: ["mechanical"],
    defaultEnabled: false,
  });

  result.push({
    property: "heightM",
    title: "Wall Height",
    short: "",
    units: Units.Meters,
    category: FieldCategory.Length,
    layouts: ["mechanical"],
    defaultEnabled: false,
  });

  result.push({
    property: "uValueW_M2K",
    title: "Wall U Value",
    short: "",
    units: Units.None,
    category: FieldCategory.HeatLoss,
    layouts: ["mechanical"],
    defaultEnabled: false,
  });

  result.push({
    property: "heatLossThroughComponentWatt",
    title: "Heat Loss Through Wall",
    short: "",
    units: Units.Watts,
    category: FieldCategory.HeatLoss,
    layouts: ["mechanical"],
    defaultEnabled: false,
    color: HEATLOSS_RESULT_HEX,
    unitContext: UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
  });

  result.push({
    property: "heatGainThroughComponentWatt",
    title: "Heat Gain Through Wall",
    short: "",
    units: Units.Watts,
    category: FieldCategory.HeatLoss,
    layouts: ["mechanical"],
    defaultEnabled: false,
    color: HEATGAIN_RESULT_HEX,
    unitContext: UnitsContext.HEAT_LOAD_ENERGY_MEASUREMENT,
  });

  return result;
}

export function emptyWallLiveCalculation(): WallLiveCalculation {
  return {
    connected: null,
    warnings: [],
  };
}

export function emptyWallCalculation(): WallCalculation {
  return {
    warnings: [],
    costBreakdown: null,
    expandedEntities: null,
    type: CalculationType.WallCalculation,
    cost: null,
    heatLossThroughComponentWatt: null,
    heatGainThroughComponentWatt: null,
    uValueW_M2K: null,
    areaM2: null,
    lengthM: null,
    heightM: null,
    // if wall is an internal wall, it don't have internalTemperatureC field
    internalTemperatureC: null,
    externalWinterTemperatureC: null,
    externalSummerTemperatureC: null,
  };
}
