import { EntityType } from "../../../../common/src/api/document/entities/types";
import { getLevelBelowUid } from "../../../../common/src/api/document/entities/utils";
import { DocumentState } from "../../store/document/types";
import CanvasContext from "../lib/canvas-context";
import { DrawingContext } from "../lib/types";

export function shouldShowOnFloorAbove(
  uid: string,
  context: DrawingContext | CanvasContext,
  doc: DocumentState,
) {
  const entity = context.globalStore.get(uid);
  if (entity.type !== EntityType.ROOM) {
    return false;
  }
  return (
    context.globalStore.levelOfEntity.get(uid) ===
    getLevelBelowUid(context.drawing, doc.uiState.levelUid)
  );
}
