import { Rectangle } from "../../../../../common/src/lib/rectangle";
import DrawableObject from "../../../../src/htmlcanvas/lib/drawable-object";

export interface Sizeable {
  boundary: Rectangle;
  sizable: true;
}

export type SizeableObject = Sizeable & DrawableObject;

export function isSizable(obj: DrawableObject): obj is SizeableObject {
  return obj.sizable === true;
}
