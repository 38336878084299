import CoreSystemNode from "../../../../../common/src/api/coreObjects/coreSystemNode";
import CoreBaseBackedObject from "../../../../../common/src/api/coreObjects/lib/coreBaseBackedObject";
import { DrawableEntityConcrete } from "../../../../../common/src/api/document/entities/concrete-entity";
import {
  Coord,
  coordAdd,
  coordDist2,
  coordSub,
} from "../../../../../common/src/lib/coord";

export interface SnapIntention {
  // how much is this target offset by from the mouse pointer? (thus the result will be the snap point minus this offset)
  offset: Coord;
  systemUid: string | null;

  // should we snap to the center of fixtures, plants, other full polygon objects?
  // "point" is for drawing pipes that need to connect to a flow system
  // "block" is for drawing fixtures that need to be placed next to other fixtures and such.
  accept: "point" | "block";
}

export interface PointSnapTarget {
  type: "point";
  wc: Coord;
  validAnglesRad: number[];
  offset: Coord;
}

export interface LineSnapTarget {
  type: "line";
  wcA: Coord;
  wcB: Coord;
  offset: Coord;
}

export type SnapTarget = PointSnapTarget | LineSnapTarget;

export function SnappableObject<
  // I extends DrawableEntityConcrete,
  T extends abstract new (
    ...args: any[]
  ) => CoreBaseBackedObject<DrawableEntityConcrete>,
>(Base: T) {
  abstract class Generated extends Base {
    abstract getSnapTargets(
      request: SnapIntention[],
      mouseWc: Coord,
      uidsToIgnore: string[],
    ): SnapTarget[];
  }
  return Generated;
}

export function getBlockLikeSnapLocations(
  request: SnapIntention[],
  mouseWc: Coord,
  io: CoreSystemNode[],
  centerWc: Coord,
  angleRad: number,
) {
  const result: SnapTarget[] = [];

  for (const r of request) {
    switch (r.accept) {
      case "block":
        result.push({
          type: "point",
          wc: centerWc,
          offset: r.offset,
          validAnglesRad: [angleRad, angleRad + Math.PI],
        });
        break;
      case "point":
        let closestDist = Infinity;
        let closest: SnapTarget | null = null;
        for (const i of io) {
          if (!r.systemUid || r.systemUid === i.entity.systemUid) {
            const dist = coordDist2(
              i.toWorldCoord(),
              coordAdd(mouseWc, r.offset),
            );
            if (dist < closestDist) {
              closestDist = dist;
              closest = {
                type: "point",
                wc: i.toWorldCoord(),
                offset: r.offset,
                validAnglesRad: [angleRad, angleRad + Math.PI],
              };
            }
          }
        }
        if (closest) {
          result.push(closest);
        }
        break;
    }
  }

  return result;
}

export function makeBLockLikeSnapIntentions(
  blockWC: Coord,
  blockOffset: Coord,
  io: CoreSystemNode[],
): SnapIntention[] {
  const result: SnapIntention[] = [
    {
      accept: "block",
      systemUid: null,
      offset: blockOffset,
    },
  ];

  for (const i of io) {
    result.push({
      accept: "point",
      systemUid: i.entity.systemUid,
      offset: coordSub(i.toWorldCoord(), blockWC),
    });
  }

  return result;
}
