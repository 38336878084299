import { getCurrentInstance } from "vue";

export const useBvModal = (): any => {
  const app = getCurrentInstance();

  if (app) {
    return app.proxy.$root.$bvModal;
  }
};

export const useBvToast = (): any => {
  const app = getCurrentInstance();

  if (app) {
    return app.proxy.$root.$bvToast;
  }
};
