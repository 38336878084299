export const initialHotKey = {
  floorPlan: {
    name: "Floor Plan",
    default: "alt+f",
  },
  plumbing: {
    name: "Plumbing",
    default: "alt+p",
  },
  results: {
    name: "Results",
    default: "alt+r",
  },
  exportSettings: {
    name: "Export",
    default: "alt+e",
  },
  settings: {
    name: "Settings",
    default: "alt+s",
  },
  coldWater: {
    name: "Cold Water",
    default: "c",
  },
  hotWater: {
    name: "Hot Water",
    default: "h",
  },
  warmWater: {
    name: "Warm Water",
    default: "w",
  },
  waterSource: {
    name: "Water Source",
    default: "s",
  },
  reticulationPipe: {
    name: "Main Conduit",
    default: "p",
  },
  multiPipe: {
    name: "Multi-Pipes",
    default: "m",
  },
  riser: {
    name: "Riser",
    default: "r",
  },
  connectionPipe: {
    name: "Branch Pipe",
    default: "n",
  },
  rpzd: {
    name: "RPZD",
    default: "z",
  },
  tmv: {
    name: "TMV",
    default: "t",
  },
  temperingValve: {
    name: "Tempering Valve",
    default: "v",
  },
  hotWaterPlant: {
    name: "Hot Water Plant",
    default: "alt+h",
  },
  heatSource: {
    name: "Heat Source",
    default: "alt+b",
  },
  tank: {
    name: "Tank",
    default: "alt+t",
  },
  pump: {
    name: "Pump",
    default: "alt+u",
  },
  basin: {
    name: "Basin",
    default: "shift+b",
  },
  bath: {
    name: "Bath",
    default: "shift+a",
  },
  shower: {
    name: "Shower",
    default: "shift+s",
  },
  wc: {
    name: "WC",
    default: "alt+c",
  },
  laundryTrough: {
    name: "Laundry Trough Warm",
    default: "shift+l",
  },
  laundryTroughHot: {
    name: "Laundry Trough Hot",
    default: "shift+t",
  },
  kitchenSink: {
    name: "Sink Warm",
    default: "shift+w",
  },
  kitchenSinkHot: {
    name: "Sink Hot",
    default: "shift+h",
  },
  washingMachine: {
    name: "Washing Machine",
    default: "shift+m",
  },
  urinal: {
    name: "Urinal",
    default: "shift+u",
  },
  dishwasher: {
    name: "Dishwasher",
    default: "shift+d",
  },
  hoseTap: {
    name: "Hose Tap",
    default: "shift+x",
  },
  birthingPool: {
    name: "Birthing Pool",
    default: "shift+j",
  },
  cleanersSink: {
    name: "Cleaners Sink",
    default: "shift+c",
  },
  beverageBay: {
    name: "Beverage Bay",
    default: "shift+e",
  },
  flushingRimSink: {
    name: "Flushing Rim Sink",
    default: "shift+f",
  },
  bedpanSanitiser: {
    name: "Bedpan Sanitiser",
    default: "shift+r",
  },
  prv: {
    name: "PRV",
    default: "shift+v",
  },
  ballValve: {
    name: "Ball Valve",
    default: "a",
  },
  gateValve: {
    name: "Gate Valve",
    default: "g",
  },
  butterflyValve: {
    name: "Butterfly Valve",
    default: "x",
  },
  balancing: {
    name: "Balancing Valve",
    default: "b",
  },
  strainer: {
    name: "Strainer",
    default: "y",
  },
  waterMeter: {
    name: "Water Meter",
    default: "alt+w",
  },
  checkValve: {
    name: "Check Valve",
    default: "k",
  },
  nodePair: {
    name: "Node Pair",
    default: "shift+n",
  },
  continuousFlowNode: {
    name: "Continuous Flow Node",
    default: "shift+o",
  },
  dwellingNodePair: {
    name: "Dwelling Node Pair",
    default: "shift+p",
  },
  annotationBox: {
    name: "Annotation",
    default: "l",
  },
};
