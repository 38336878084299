
import { micromark } from "micromark";
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    value: {
      required: true,
      type: String,
    },
  },
  watch: {
    value: [
      {
        handler: "updateMarkdown",
      },
    ],
  },
  mounted() {
    this.updateMarkdown();
  },
  methods: {
    updateMarkdown() {
      if (!this.$props.value || !this.$refs.markdown) {
        return;
      }
      const element = this.$refs.markdown as HTMLElement;
      element.innerHTML = micromark(this.$props.value);

      // make all links open in a new tab
      const links = element.querySelectorAll("a");
      links.forEach((link) => {
        link.setAttribute("target", "_blank");
      });
    },
  },
});
