import { bar2kpa } from "../../../../lib/measurements";
import { PumpTankData, PumpTankManufacturer } from "../../types";

export const FlowtechPumpTankManufacturer: PumpTankManufacturer = {
  name: "Flowtech",
  abbreviation: "Flowtech",
  uid: "flowtech",
  priceTableName: "Pump/Tank",
  componentName: "Flowtech Flowpac",
  displayOnWizard: true,
};

export const FlowtechPumpTankData: PumpTankData = {
  type: "model-capacity-dependent",

  configuration: {
    duty: {
      // Model references
      // Reference	Model
      // 1	        1V.E.E.XXXX.4008
      // 2	        1V.E.E.XXXX.8012
      // 3	        1V.E.E.XXXX.8015
      // 4	        1V.E.E.XXXX.8020
      // 5	        1V.E.C.XXXX.0507
      // 6	        1V.E.C.XXXX.0509
      // 7	        1V.E.C.XXXX.0904
      // 8	        1V.E.C.XXXX.0905
      // 9	        1V.E.C.XXXX.0906
      // 10	        1V.E.C.XXXX.0907
      referenceLookup: {
        [bar2kpa(2)]: {
          "1": "1",
          "1.75": "2",
        },
        [bar2kpa(2.25)]: {
          "1": "1",
          "1.5": "2",
          "1.75": "3",
        },
        [bar2kpa(2.5)]: {
          "1": "1",
          "1.5": "2",
          "1.75": "3",
          "2": "5",
          "2.5": "7",
        },
        [bar2kpa(2.75)]: {
          "0.75": "1",
          "1.5": "2",
          "1.75": "4",
          "2": "5",
          "2.5": "7",
        },
        [bar2kpa(3)]: {
          "0.75": "1",
          "1.25": "2",
          "1.5": "3",
          "1.75": "4",
          "2": "5",
          "2.5": "7",
        },
        [bar2kpa(3.25)]: {
          "0.75": "1",
          "1.25": "2",
          "1.5": "3",
          "1.75": "4",
          "2": "5",
          "2.5": "8",
        },
        [bar2kpa(3.5)]: {
          "0.5": "1",
          "1": "2",
          "1.25": "3",
          "1.5": "4",
          "1.75": "5",
          "2": "6",
          "2.5": "8",
        },
        [bar2kpa(3.75)]: {
          "0.5": "1",
          "1": "2",
          "1.25": "3",
          "1.5": "4",
          "1.75": "5",
          "2": "6",
          "2.5": "8",
        },
        [bar2kpa(4)]: {
          "0.5": "1",
          "0.75": "2",
          "1.25": "3",
          "1.5": "4",
          "1.75": "5",
          "2": "6",
          "2.25": "8",
          "2.5": "9",
        },
        [bar2kpa(4.25)]: {
          "0.5": "2",
          "1": "3",
          "1.25": "4",
          "1.75": "5",
          "2": "6",
          "2.5": "9",
        },
        [bar2kpa(4.5)]: {
          "0.5": "2",
          "1": "3",
          "1.25": "4",
          "1.5": "5",
          "1.75": "6",
          "2.5": "9",
        },
        [bar2kpa(4.75)]: {
          "1": "3",
          "1.25": "4",
          "1.5": "5",
          "1.75": "6",
          "2.25": "9",
          "2.5": "10",
        },
        [bar2kpa(5)]: {
          "0.75": "3",
          "1.25": "4",
          "1.5": "5",
          "1.75": "6",
          "2.25": "9",
          "2.5": "10",
        },
        [bar2kpa(5.25)]: {
          "0.5": "3",
          "1": "4",
          "1.25": "5",
          "1.75": "6",
          "2": "9",
          "2.25": "10",
        },
        [bar2kpa(5.5)]: {
          "0.5": "3",
          "1": "4",
          "1.25": "5",
          "1.75": "6",
          "2.5": "10",
        },
        [bar2kpa(5.75)]: {
          "1": "4",
          "1.25": "5",
          "1.5": "6",
          "2.5": "10",
        },
        [bar2kpa(6)]: {
          "0.75": "4",
          "1": "5",
          "1.5": "6",
          "2.25": "10",
        },
      },
      capacityLookup: {
        "450": {
          "1": "1V.E.E.600.4008",
          "2": "1V.E.E.600.8012",
          "3": "1V.E.E.600.8015",
          "4": "1V.E.E.600.8020",
          "5": "1V.E.C.600.0507",
          "6": "1V.E.C.600.0509",
          "7": "1V.E.C.600.0904",
          "8": "1V.E.C.600.0905",
          "9": "1V.E.C.600.0906",
          "10": "1V.E.C.600.0907",
        },
        "675": {
          "1": "1V.E.E.1000.4008",
          "2": "1V.E.E.1000.8012",
          "3": "1V.E.E.1000.8015",
          "4": "1V.E.E.1000.8020",
          "5": "1V.E.C.1000.0507",
          "6": "1V.E.C.1000.0509",
          "7": "1V.E.C.1000.0904",
          "8": "1V.E.C.1000.0905",
          "9": "1V.E.C.1000.0906",
          "10": "1V.E.C.1000.0907",
        },
        "900": {
          "1": "1V.E.E.1220.4008",
          "2": "1V.E.E.1220.8012",
          "3": "1V.E.E.1220.8015",
          "4": "1V.E.E.1220.8020",
          "5": "1V.E.C.1220.0507",
          "6": "1V.E.C.1220.0509",
          "7": "1V.E.C.1220.0904",
          "8": "1V.E.C.1220.0905",
          "9": "1V.E.C.1220.0906",
          "10": "1V.E.C.1220.0907",
        },
        "1125": {
          "1": "1V.E.E.1501.4008",
          "2": "1V.E.E.1501.8012",
          "3": "1V.E.E.1501.8015",
          "4": "1V.E.E.1501.8020",
          "5": "1V.E.C.1501.0507",
          "6": "1V.E.C.1501.0509",
          "7": "1V.E.C.1501.0904",
          "8": "1V.E.C.1501.0905",
          "9": "1V.E.C.1501.0906",
          "10": "1V.E.C.1501.0907",
        },
        "1350": {
          "1": "1V.E.E.1818.4008",
          "2": "1V.E.E.1818.8012",
          "3": "1V.E.E.1818.8015",
          "4": "1V.E.E.1818.8020",
          "5": "1V.E.C.1818.0507",
          "6": "1V.E.C.1818.0509",
          "7": "1V.E.C.1818.0904",
          "8": "1V.E.C.1818.0905",
          "9": "1V.E.C.1818.0906",
          "10": "1V.E.C.1818.0907",
        },
        "1575": {
          "1": "1V.E.E.2250.4008",
          "2": "1V.E.E.2250.8012",
          "3": "1V.E.E.2250.8015",
          "4": "1V.E.E.2250.8020",
          "5": "1V.E.C.2250.0507",
          "6": "1V.E.C.2250.0509",
          "7": "1V.E.C.2250.0904",
          "8": "1V.E.C.2250.0905",
          "9": "1V.E.C.2250.0906",
          "10": "1V.E.C.2250.0907",
        },
        "1800": {
          "1": "1V.E.E.2250.4008",
          "2": "1V.E.E.2250.8012",
          "3": "1V.E.E.2250.8015",
          "4": "1V.E.E.2250.8020",
          "5": "1V.E.C.2250.0507",
          "6": "1V.E.C.2250.0509",
          "7": "1V.E.C.2250.0904",
          "8": "1V.E.C.2250.0905",
          "9": "1V.E.C.2250.0906",
          "10": "1V.E.C.2250.0907",
        },
        "2025": {
          "1": "1V.E.E.2501.4008",
          "2": "1V.E.E.2501.8012",
          "3": "1V.E.E.2501.8015",
          "4": "1V.E.E.2501.8020",
          "5": "1V.E.C.2501.0507",
          "6": "1V.E.C.2501.0509",
          "7": "1V.E.C.2501.0904",
          "8": "1V.E.C.2501.0905",
          "9": "1V.E.C.2501.0906",
          "10": "1V.E.C.2501.0907",
        },
        "2250": {
          "1": "1V.E.E.3001.4008",
          "2": "1V.E.E.3001.8012",
          "3": "1V.E.E.3001.8015",
          "4": "1V.E.E.3001.8020",
          "5": "1V.E.C.3001.0507",
          "6": "1V.E.C.3001.0509",
          "7": "1V.E.C.3001.0904",
          "8": "1V.E.C.3001.0905",
          "9": "1V.E.C.3001.0906",
          "10": "1V.E.C.3001.0907",
        },
        "1000000": {
          "1": "1V.E.E.XXXX.4008",
          "2": "1V.E.E.XXXX.8012",
          "3": "1V.E.E.XXXX.8015",
          "4": "1V.E.E.XXXX.8020",
          "5": "1V.E.C.XXXX.0507",
          "6": "1V.E.C.XXXX.0509",
          "7": "1V.E.C.XXXX.0904",
          "8": "1V.E.C.XXXX.0905",
          "9": "1V.E.C.XXXX.0906",
          "10": "1V.E.C.XXXX.0907",
        },
      },
    },

    "duty-standby": {
      // Model references
      // Reference	Model
      // 1	        2V.E.E.XXXX.4008
      // 2	        2V.E.E.XXXX.8012
      // 3	        2V.E.E.XXXX.8015
      // 4	        2V.E.E.XXXX.8020
      // 5	        2V.E.C.XXXX.0507
      // 6	        2V.E.C.XXXX.0509
      // 7	        2V.E.C.XXXX.0904
      // 8	        2V.E.C.XXXX.0905
      // 9	        2V.E.C.XXXX.0906
      // 10	        2V.E.C.XXXX.0907
      referenceLookup: {
        [bar2kpa(2)]: {
          "1": "1",
          "1.75": "2",
        },
        [bar2kpa(2.25)]: {
          "1": "1",
          "1.5": "2",
          "1.75": "3",
        },
        [bar2kpa(2.5)]: {
          "1": "1",
          "1.5": "2",
          "1.75": "3",
          "2": "5",
          "2.5": "7",
        },
        [bar2kpa(2.75)]: {
          "0.75": "1",
          "1.5": "2",
          "1.75": "4",
          "2": "5",
          "2.5": "7",
        },
        [bar2kpa(3)]: {
          "0.75": "1",
          "1.25": "2",
          "1.5": "3",
          "1.75": "4",
          "2": "5",
          "2.5": "7",
        },
        [bar2kpa(3.25)]: {
          "0.75": "1",
          "1.25": "2",
          "1.5": "3",
          "1.75": "4",
          "2": "5",
          "2.5": "8",
        },
        [bar2kpa(3.5)]: {
          "0.5": "1",
          "1": "2",
          "1.25": "3",
          "1.5": "4",
          "1.75": "5",
          "2": "6",
          "2.5": "8",
        },
        [bar2kpa(3.75)]: {
          "0.5": "1",
          "1": "2",
          "1.25": "3",
          "1.5": "4",
          "1.75": "5",
          "2": "6",
          "2.5": "8",
        },
        [bar2kpa(4)]: {
          "0.5": "1",
          "0.75": "2",
          "1.25": "3",
          "1.5": "4",
          "1.75": "5",
          "2": "6",
          "2.25": "8",
          "2.5": "9",
        },
        [bar2kpa(4.25)]: {
          "0.5": "2",
          "1": "3",
          "1.25": "4",
          "1.75": "5",
          "2": "6",
          "2.5": "9",
        },
        [bar2kpa(4.5)]: {
          "0.5": "2",
          "1": "3",
          "1.25": "4",
          "1.5": "5",
          "1.75": "6",
          "2.5": "9",
        },
        [bar2kpa(4.75)]: {
          "1": "3",
          "1.25": "4",
          "1.5": "5",
          "1.75": "6",
          "2.25": "9",
          "2.5": "10",
        },
        [bar2kpa(5)]: {
          "0.75": "3",
          "1.25": "4",
          "1.5": "5",
          "1.75": "6",
          "2.25": "9",
          "2.5": "10",
        },
        [bar2kpa(5.25)]: {
          "0.5": "3",
          "1": "4",
          "1.25": "5",
          "1.75": "6",
          "2": "9",
          "2.25": "10",
        },
        [bar2kpa(5.5)]: {
          "0.5": "3",
          "1": "4",
          "1.25": "5",
          "1.75": "6",
          "2.5": "10",
        },
        [bar2kpa(5.75)]: {
          "1": "4",
          "1.25": "5",
          "1.5": "6",
          "2.5": "10",
        },
        [bar2kpa(6)]: {
          "0.75": "4",
          "1": "5",
          "1.5": "6",
          "2.25": "10",
        },
      },
      capacityLookup: {
        "450": {
          "1": "2V.E.E.600.4008",
          "2": "2V.E.E.600.8012",
          "3": "2V.E.E.600.8015",
          "4": "2V.E.E.600.8020",
          "5": "2V.E.C.600.0507",
          "6": "2V.E.C.600.0509",
          "7": "2V.E.C.600.0904",
          "8": "2V.E.C.600.0905",
          "9": "2V.E.C.600.0906",
          "10": "2V.E.C.600.0907",
        },
        "675": {
          "1": "2V.E.E.1000.4008",
          "2": "2V.E.E.1000.8012",
          "3": "2V.E.E.1000.8015",
          "4": "2V.E.E.1000.8020",
          "5": "2V.E.C.1000.0507",
          "6": "2V.E.C.1000.0509",
          "7": "2V.E.C.1000.0904",
          "8": "2V.E.C.1000.0905",
          "9": "2V.E.C.1000.0906",
          "10": "2V.E.C.1000.0907",
        },
        "900": {
          "1": "2V.E.E.1220.4008",
          "2": "2V.E.E.1220.8012",
          "3": "2V.E.E.1220.8015",
          "4": "2V.E.E.1220.8020",
          "5": "2V.E.C.1220.0507",
          "6": "2V.E.C.1220.0509",
          "7": "2V.E.C.1220.0904",
          "8": "2V.E.C.1220.0905",
          "9": "2V.E.C.1220.0906",
          "10": "2V.E.C.1220.0907",
        },
        "1125": {
          "1": "2V.E.E.1501.4008",
          "2": "2V.E.E.1501.8012",
          "3": "2V.E.E.1501.8015",
          "4": "2V.E.E.1501.8020",
          "5": "2V.E.C.1501.0507",
          "6": "2V.E.C.1501.0509",
          "7": "2V.E.C.1501.0904",
          "8": "2V.E.C.1501.0905",
          "9": "2V.E.C.1501.0906",
          "10": "2V.E.C.1501.0907",
        },
        "1350": {
          "1": "2V.E.E.1818.4008",
          "2": "2V.E.E.1818.8012",
          "3": "2V.E.E.1818.8015",
          "4": "2V.E.E.1818.8020",
          "5": "2V.E.C.1818.0507",
          "6": "2V.E.C.1818.0509",
          "7": "2V.E.C.1818.0904",
          "8": "2V.E.C.1818.0905",
          "9": "2V.E.C.1818.0906",
          "10": "2V.E.C.1818.0907",
        },
        "1575": {
          "1": "2V.E.E.2250.4008",
          "2": "2V.E.E.2250.8012",
          "3": "2V.E.E.2250.8015",
          "4": "2V.E.E.2250.8020",
          "5": "2V.E.C.2250.0507",
          "6": "2V.E.C.2250.0509",
          "7": "2V.E.C.2250.0904",
          "8": "2V.E.C.2250.0905",
          "9": "2V.E.C.2250.0906",
          "10": "2V.E.C.2250.0907",
        },
        "1800": {
          "1": "2V.E.E.2250.4008",
          "2": "2V.E.E.2250.8012",
          "3": "2V.E.E.2250.8015",
          "4": "2V.E.E.2250.8020",
          "5": "2V.E.C.2250.0507",
          "6": "2V.E.C.2250.0509",
          "7": "2V.E.C.2250.0904",
          "8": "2V.E.C.2250.0905",
          "9": "2V.E.C.2250.0906",
          "10": "2V.E.C.2250.0907",
        },
        "2025": {
          "1": "2V.E.E.2501.4008",
          "2": "2V.E.E.2501.8012",
          "3": "2V.E.E.2501.8015",
          "4": "2V.E.E.2501.8020",
          "5": "2V.E.C.2501.0507",
          "6": "2V.E.C.2501.0509",
          "7": "2V.E.C.2501.0904",
          "8": "2V.E.C.2501.0905",
          "9": "2V.E.C.2501.0906",
          "10": "2V.E.C.2501.0907",
        },
        "2250": {
          "1": "2V.E.E.3001.4008",
          "2": "2V.E.E.3001.8012",
          "3": "2V.E.E.3001.8015",
          "4": "2V.E.E.3001.8020",
          "5": "2V.E.C.3001.0507",
          "6": "2V.E.C.3001.0509",
          "7": "2V.E.C.3001.0904",
          "8": "2V.E.C.3001.0905",
          "9": "2V.E.C.3001.0906",
          "10": "2V.E.C.3001.0907",
        },
        "1000000": {
          "1": "2V.E.E.XXXX.4008",
          "2": "2V.E.E.XXXX.8012",
          "3": "2V.E.E.XXXX.8015",
          "4": "2V.E.E.XXXX.8020",
          "5": "2V.E.C.XXXX.0507",
          "6": "2V.E.C.XXXX.0509",
          "7": "2V.E.C.XXXX.0904",
          "8": "2V.E.C.XXXX.0905",
          "9": "2V.E.C.XXXX.0906",
          "10": "2V.E.C.XXXX.0907",
        },
      },
    },

    "duty-assist": {
      // Model references
      // Reference	Model
      // 1	        2V.E.E.XXXX.4008
      // 2	        2V.E.E.XXXX.8012
      // 3	        2V.E.E.XXXX.8015
      // 4	        2V.E.E.XXXX.8020
      // 5	        2V.E.C.XXXX.0507
      // 6	        2V.E.C.XXXX.0509
      referenceLookup: {
        [bar2kpa(2)]: {
          "2.25": "1",
          "2.5": "2",
        },
        [bar2kpa(2.25)]: {
          "2": "1",
          "2.5": "2",
        },
        [bar2kpa(2.5)]: {
          "2": "1",
          "2.5": "2",
        },
        [bar2kpa(2.75)]: {
          "1.75": "1",
          "2.5": "2",
        },
        [bar2kpa(3)]: {
          "1.75": "1",
          "2.5": "2",
        },
        [bar2kpa(3.25)]: {
          "1.5": "1",
          "2.5": "2",
        },
        [bar2kpa(3.5)]: {
          "1": "1",
          "2": "2",
          "2.5": "3",
        },
        [bar2kpa(3.75)]: {
          "1": "1",
          "2": "2",
          "2.5": "3",
        },
        [bar2kpa(4)]: {
          "1": "1",
          "1.75": "2",
          "2.5": "3",
        },
        [bar2kpa(4.25)]: {
          "0.5": "1",
          "1.25": "2",
          "2.25": "3",
          "2.5": "4",
        },
        [bar2kpa(4.5)]: {
          "1": "2",
          "2.25": "3",
          "2.5": "4",
        },
        [bar2kpa(4.75)]: {
          "2": "3",
          "2.5": "4",
        },
        [bar2kpa(5)]: {
          "1.75": "3",
          "2.5": "4",
        },
        [bar2kpa(5.25)]: {
          "1.25": "3",
          "2.25": "4",
          "2.5": "5",
        },
        [bar2kpa(5.5)]: {
          "1.25": "3",
          "2": "4",
          "2.5": "5",
        },
        [bar2kpa(5.75)]: {
          "0.5": "3",
          "2": "4",
          "2.5": "5",
        },
        [bar2kpa(6)]: {
          "1.75": "4",
          "2.25": "5",
          "2.5": "6",
        },
      },
      capacityLookup: {
        "450": {
          "1": "2V.E.E.600.4008",
          "2": "2V.E.E.600.8012",
          "3": "2V.E.E.600.8015",
          "4": "2V.E.E.600.8020",
          "5": "2V.E.C.600.0507",
          "6": "2V.E.C.600.0509",
          "7": "2V.E.C.600.0904",
          "8": "2V.E.C.600.0905",
          "9": "2V.E.C.600.0906",
          "10": "2V.E.C.600.0907",
        },
        "675": {
          "1": "2V.E.E.1000.4008",
          "2": "2V.E.E.1000.8012",
          "3": "2V.E.E.1000.8015",
          "4": "2V.E.E.1000.8020",
          "5": "2V.E.C.1000.0507",
          "6": "2V.E.C.1000.0509",
          "7": "2V.E.C.1000.0904",
          "8": "2V.E.C.1000.0905",
          "9": "2V.E.C.1000.0906",
          "10": "2V.E.C.1000.0907",
        },
        "900": {
          "1": "2V.E.E.1220.4008",
          "2": "2V.E.E.1220.8012",
          "3": "2V.E.E.1220.8015",
          "4": "2V.E.E.1220.8020",
          "5": "2V.E.C.1220.0507",
          "6": "2V.E.C.1220.0509",
          "7": "2V.E.C.1220.0904",
          "8": "2V.E.C.1220.0905",
          "9": "2V.E.C.1220.0906",
          "10": "2V.E.C.1220.0907",
        },
        "1125": {
          "1": "2V.E.E.1501.4008",
          "2": "2V.E.E.1501.8012",
          "3": "2V.E.E.1501.8015",
          "4": "2V.E.E.1501.8020",
          "5": "2V.E.C.1501.0507",
          "6": "2V.E.C.1501.0509",
          "7": "2V.E.C.1501.0904",
          "8": "2V.E.C.1501.0905",
          "9": "2V.E.C.1501.0906",
          "10": "2V.E.C.1501.0907",
        },
        "1350": {
          "1": "2V.E.E.1818.4008",
          "2": "2V.E.E.1818.8012",
          "3": "2V.E.E.1818.8015",
          "4": "2V.E.E.1818.8020",
          "5": "2V.E.C.1818.0507",
          "6": "2V.E.C.1818.0509",
          "7": "2V.E.C.1818.0904",
          "8": "2V.E.C.1818.0905",
          "9": "2V.E.C.1818.0906",
          "10": "2V.E.C.1818.0907",
        },
        "1575": {
          "1": "2V.E.E.2250.4008",
          "2": "2V.E.E.2250.8012",
          "3": "2V.E.E.2250.8015",
          "4": "2V.E.E.2250.8020",
          "5": "2V.E.C.2250.0507",
          "6": "2V.E.C.2250.0509",
          "7": "2V.E.C.2250.0904",
          "8": "2V.E.C.2250.0905",
          "9": "2V.E.C.2250.0906",
          "10": "2V.E.C.2250.0907",
        },
        "1800": {
          "1": "2V.E.E.2250.4008",
          "2": "2V.E.E.2250.8012",
          "3": "2V.E.E.2250.8015",
          "4": "2V.E.E.2250.8020",
          "5": "2V.E.C.2250.0507",
          "6": "2V.E.C.2250.0509",
          "7": "2V.E.C.2250.0904",
          "8": "2V.E.C.2250.0905",
          "9": "2V.E.C.2250.0906",
          "10": "2V.E.C.2250.0907",
        },
        "2025": {
          "1": "2V.E.E.2501.4008",
          "2": "2V.E.E.2501.8012",
          "3": "2V.E.E.2501.8015",
          "4": "2V.E.E.2501.8020",
          "5": "2V.E.C.2501.0507",
          "6": "2V.E.C.2501.0509",
          "7": "2V.E.C.2501.0904",
          "8": "2V.E.C.2501.0905",
          "9": "2V.E.C.2501.0906",
          "10": "2V.E.C.2501.0907",
        },
        "2250": {
          "1": "2V.E.E.3001.4008",
          "2": "2V.E.E.3001.8012",
          "3": "2V.E.E.3001.8015",
          "4": "2V.E.E.3001.8020",
          "5": "2V.E.C.3001.0507",
          "6": "2V.E.C.3001.0509",
          "7": "2V.E.C.3001.0904",
          "8": "2V.E.C.3001.0905",
          "9": "2V.E.C.3001.0906",
          "10": "2V.E.C.3001.0907",
        },
        "1000000": {
          "1": "2V.E.E.XXXX.4008",
          "2": "2V.E.E.XXXX.8012",
          "3": "2V.E.E.XXXX.8015",
          "4": "2V.E.E.XXXX.8020",
          "5": "2V.E.C.XXXX.0507",
          "6": "2V.E.C.XXXX.0509",
          "7": "2V.E.C.XXXX.0904",
          "8": "2V.E.C.XXXX.0905",
          "9": "2V.E.C.XXXX.0906",
          "10": "2V.E.C.XXXX.0907",
        },
      },
    },
  },

  sizes: {
    "1V.E.E.600.4008": {
      widthMM: 700,
      heightMM: 1740,
      depthMM: 1200,
    },
    "1V.E.E.600.8012": {
      widthMM: 700,
      heightMM: 1740,
      depthMM: 1200,
    },
    "1V.E.E.600.8015": {
      widthMM: 700,
      heightMM: 1740,
      depthMM: 1200,
    },
    "1V.E.E.600.8020": {
      widthMM: 700,
      heightMM: 1740,
      depthMM: 1200,
    },
    "1V.E.C.600.0507": {
      widthMM: 700,
      heightMM: 1740,
      depthMM: 1200,
    },
    "1V.E.C.600.0509": {
      widthMM: 700,
      heightMM: 1740,
      depthMM: 1200,
    },
    "1V.E.C.600.0904": {
      widthMM: 700,
      heightMM: 1740,
      depthMM: 1200,
    },
    "1V.E.C.600.0905": {
      widthMM: 700,
      heightMM: 1740,
      depthMM: 1200,
    },
    "1V.E.C.600.0906": {
      widthMM: 700,
      heightMM: 1740,
      depthMM: 1200,
    },
    "1V.E.C.600.0907": {
      widthMM: 700,
      heightMM: 1740,
      depthMM: 1200,
    },
    "1V.E.E.1000.4008": {
      widthMM: 1200,
      heightMM: 1600,
      depthMM: 1200,
    },
    "1V.E.E.1000.8012": {
      widthMM: 1200,
      heightMM: 1600,
      depthMM: 1200,
    },
    "1V.E.E.1000.8015": {
      widthMM: 1200,
      heightMM: 1600,
      depthMM: 1200,
    },
    "1V.E.E.1000.8020": {
      widthMM: 1200,
      heightMM: 1600,
      depthMM: 1200,
    },
    "1V.E.C.1000.0507": {
      widthMM: 1200,
      heightMM: 1600,
      depthMM: 1200,
    },
    "1V.E.C.1000.0509": {
      widthMM: 1200,
      heightMM: 1600,
      depthMM: 1200,
    },
    "1V.E.C.1000.0904": {
      widthMM: 1200,
      heightMM: 1600,
      depthMM: 1200,
    },
    "1V.E.C.1000.0905": {
      widthMM: 1200,
      heightMM: 1600,
      depthMM: 1200,
    },
    "1V.E.C.1000.0906": {
      widthMM: 1200,
      heightMM: 1600,
      depthMM: 1200,
    },
    "1V.E.C.1000.0907": {
      widthMM: 1200,
      heightMM: 1600,
      depthMM: 1200,
    },
    "1V.E.E.1220.4008": {
      widthMM: 1400,
      heightMM: 1820,
      depthMM: 1200,
    },
    "1V.E.E.1220.8012": {
      widthMM: 1400,
      heightMM: 1820,
      depthMM: 1200,
    },
    "1V.E.E.1220.8015": {
      widthMM: 1400,
      heightMM: 1820,
      depthMM: 1200,
    },
    "1V.E.E.1220.8020": {
      widthMM: 1400,
      heightMM: 1820,
      depthMM: 1200,
    },
    "1V.E.C.1220.0507": {
      widthMM: 1400,
      heightMM: 1820,
      depthMM: 1200,
    },
    "1V.E.C.1220.0509": {
      widthMM: 1400,
      heightMM: 1820,
      depthMM: 1200,
    },
    "1V.E.C.1220.0904": {
      widthMM: 1400,
      heightMM: 1820,
      depthMM: 1200,
    },
    "1V.E.C.1220.0905": {
      widthMM: 1400,
      heightMM: 1820,
      depthMM: 1200,
    },
    "1V.E.C.1220.0906": {
      widthMM: 1400,
      heightMM: 1820,
      depthMM: 1200,
    },
    "1V.E.C.1220.0907": {
      widthMM: 1400,
      heightMM: 1820,
      depthMM: 1200,
    },
    "1V.E.E.1501.4008": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1200,
    },
    "1V.E.E.1501.8012": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1200,
    },
    "1V.E.E.1501.8015": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1200,
    },
    "1V.E.E.1501.8020": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1200,
    },
    "1V.E.C.1501.0507": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1200,
    },
    "1V.E.C.1501.0509": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1200,
    },
    "1V.E.C.1501.0904": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1200,
    },
    "1V.E.C.1501.0905": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1200,
    },
    "1V.E.C.1501.0906": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1200,
    },
    "1V.E.C.1501.0907": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1200,
    },
    "1V.E.E.1818.4008": {
      widthMM: 1400,
      heightMM: 1810,
      depthMM: 1400,
    },
    "1V.E.E.1818.8012": {
      widthMM: 1400,
      heightMM: 1810,
      depthMM: 1400,
    },
    "1V.E.E.1818.8015": {
      widthMM: 1400,
      heightMM: 1810,
      depthMM: 1400,
    },
    "1V.E.E.1818.8020": {
      widthMM: 1400,
      heightMM: 1810,
      depthMM: 1400,
    },
    "1V.E.C.1818.0507": {
      widthMM: 1400,
      heightMM: 1810,
      depthMM: 1400,
    },
    "1V.E.C.1818.0509": {
      widthMM: 1400,
      heightMM: 1810,
      depthMM: 1400,
    },
    "1V.E.C.1818.0904": {
      widthMM: 1400,
      heightMM: 1810,
      depthMM: 1400,
    },
    "1V.E.C.1818.0905": {
      widthMM: 1400,
      heightMM: 1810,
      depthMM: 1400,
    },
    "1V.E.C.1818.0906": {
      widthMM: 1400,
      heightMM: 1810,
      depthMM: 1400,
    },
    "1V.E.C.1818.0907": {
      widthMM: 1400,
      heightMM: 1810,
      depthMM: 1400,
    },
    "1V.E.E.2250.4008": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1700,
    },
    "1V.E.E.2250.8012": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1700,
    },
    "1V.E.E.2250.8015": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1700,
    },
    "1V.E.E.2250.8020": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1700,
    },
    "1V.E.C.2250.0507": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1700,
    },
    "1V.E.C.2250.0509": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1700,
    },
    "1V.E.C.2250.0904": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1700,
    },
    "1V.E.C.2250.0905": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1700,
    },
    "1V.E.C.2250.0906": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1700,
    },
    "1V.E.C.2250.0907": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1700,
    },
    "1V.E.E.2501.4008": {
      widthMM: 1200,
      heightMM: 1850,
      depthMM: 2200,
    },
    "1V.E.E.2501.8012": {
      widthMM: 1200,
      heightMM: 1850,
      depthMM: 2200,
    },
    "1V.E.E.2501.8015": {
      widthMM: 1200,
      heightMM: 1850,
      depthMM: 2200,
    },
    "1V.E.E.2501.8020": {
      widthMM: 1200,
      heightMM: 1850,
      depthMM: 2200,
    },
    "1V.E.C.2501.0507": {
      widthMM: 1200,
      heightMM: 1850,
      depthMM: 2200,
    },
    "1V.E.C.2501.0509": {
      widthMM: 1200,
      heightMM: 1850,
      depthMM: 2200,
    },
    "1V.E.C.2501.0904": {
      widthMM: 1200,
      heightMM: 1850,
      depthMM: 2200,
    },
    "1V.E.C.2501.0905": {
      widthMM: 1200,
      heightMM: 1850,
      depthMM: 2200,
    },
    "1V.E.C.2501.0906": {
      widthMM: 1200,
      heightMM: 1850,
      depthMM: 2200,
    },
    "1V.E.C.2501.0907": {
      widthMM: 1200,
      heightMM: 1850,
      depthMM: 2200,
    },
    "1V.E.E.3001.4008": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 2200,
    },
    "1V.E.E.3001.8012": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 2200,
    },
    "1V.E.E.3001.8015": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 2200,
    },
    "1V.E.E.3001.8020": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 2200,
    },
    "1V.E.C.3001.0507": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 2200,
    },
    "1V.E.C.3001.0509": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 2200,
    },
    "1V.E.C.3001.0904": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 2200,
    },
    "1V.E.C.3001.0905": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 2200,
    },
    "1V.E.C.3001.0906": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 2200,
    },
    "1V.E.C.3001.0907": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 2200,
    },

    "2V.E.E.600.4008": {
      widthMM: 700,
      heightMM: 1740,
      depthMM: 1200,
    },
    "2V.E.E.600.8012": {
      widthMM: 700,
      heightMM: 1740,
      depthMM: 1200,
    },
    "2V.E.E.600.8015": {
      widthMM: 700,
      heightMM: 1740,
      depthMM: 1200,
    },
    "2V.E.E.600.8020": {
      widthMM: 700,
      heightMM: 1740,
      depthMM: 1200,
    },
    "2V.E.C.600.0507": {
      widthMM: 700,
      heightMM: 1740,
      depthMM: 1200,
    },
    "2V.E.C.600.0509": {
      widthMM: 700,
      heightMM: 1740,
      depthMM: 1200,
    },
    "2V.E.C.600.0904": {
      widthMM: 700,
      heightMM: 1740,
      depthMM: 1200,
    },
    "2V.E.C.600.0905": {
      widthMM: 700,
      heightMM: 1740,
      depthMM: 1200,
    },
    "2V.E.C.600.0906": {
      widthMM: 700,
      heightMM: 1740,
      depthMM: 1200,
    },
    "2V.E.C.600.0907": {
      widthMM: 700,
      heightMM: 1740,
      depthMM: 1200,
    },
    "2V.E.E.1000.4008": {
      widthMM: 1200,
      heightMM: 1600,
      depthMM: 1200,
    },
    "2V.E.E.1000.8012": {
      widthMM: 1200,
      heightMM: 1600,
      depthMM: 1200,
    },
    "2V.E.E.1000.8015": {
      widthMM: 1200,
      heightMM: 1600,
      depthMM: 1200,
    },
    "2V.E.E.1000.8020": {
      widthMM: 1200,
      heightMM: 1600,
      depthMM: 1200,
    },
    "2V.E.C.1000.0507": {
      widthMM: 1200,
      heightMM: 1600,
      depthMM: 1200,
    },
    "2V.E.C.1000.0509": {
      widthMM: 1200,
      heightMM: 1600,
      depthMM: 1200,
    },
    "2V.E.C.1000.0904": {
      widthMM: 1200,
      heightMM: 1600,
      depthMM: 1200,
    },
    "2V.E.C.1000.0905": {
      widthMM: 1200,
      heightMM: 1600,
      depthMM: 1200,
    },
    "2V.E.C.1000.0906": {
      widthMM: 1200,
      heightMM: 1600,
      depthMM: 1200,
    },
    "2V.E.C.1000.0907": {
      widthMM: 1200,
      heightMM: 1600,
      depthMM: 1200,
    },
    "2V.E.E.1220.4008": {
      widthMM: 1400,
      heightMM: 1820,
      depthMM: 1200,
    },
    "2V.E.E.1220.8012": {
      widthMM: 1400,
      heightMM: 1820,
      depthMM: 1200,
    },
    "2V.E.E.1220.8015": {
      widthMM: 1400,
      heightMM: 1820,
      depthMM: 1200,
    },
    "2V.E.E.1220.8020": {
      widthMM: 1400,
      heightMM: 1820,
      depthMM: 1200,
    },
    "2V.E.C.1220.0507": {
      widthMM: 1400,
      heightMM: 1820,
      depthMM: 1200,
    },
    "2V.E.C.1220.0509": {
      widthMM: 1400,
      heightMM: 1820,
      depthMM: 1200,
    },
    "2V.E.C.1220.0904": {
      widthMM: 1400,
      heightMM: 1820,
      depthMM: 1200,
    },
    "2V.E.C.1220.0905": {
      widthMM: 1400,
      heightMM: 1820,
      depthMM: 1200,
    },
    "2V.E.C.1220.0906": {
      widthMM: 1400,
      heightMM: 1820,
      depthMM: 1200,
    },
    "2V.E.C.1220.0907": {
      widthMM: 1400,
      heightMM: 1820,
      depthMM: 1200,
    },
    "2V.E.E.1501.4008": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1200,
    },
    "2V.E.E.1501.8012": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1200,
    },
    "2V.E.E.1501.8015": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1200,
    },
    "2V.E.E.1501.8020": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1200,
    },
    "2V.E.C.1501.0507": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1200,
    },
    "2V.E.C.1501.0509": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1200,
    },
    "2V.E.C.1501.0904": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1200,
    },
    "2V.E.C.1501.0905": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1200,
    },
    "2V.E.C.1501.0906": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1200,
    },
    "2V.E.C.1501.0907": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1200,
    },
    "2V.E.E.1818.4008": {
      widthMM: 1400,
      heightMM: 1810,
      depthMM: 1400,
    },
    "2V.E.E.1818.8012": {
      widthMM: 1400,
      heightMM: 1810,
      depthMM: 1400,
    },
    "2V.E.E.1818.8015": {
      widthMM: 1400,
      heightMM: 1810,
      depthMM: 1400,
    },
    "2V.E.E.1818.8020": {
      widthMM: 1400,
      heightMM: 1810,
      depthMM: 1400,
    },
    "2V.E.C.1818.0507": {
      widthMM: 1400,
      heightMM: 1810,
      depthMM: 1400,
    },
    "2V.E.C.1818.0509": {
      widthMM: 1400,
      heightMM: 1810,
      depthMM: 1400,
    },
    "2V.E.C.1818.0904": {
      widthMM: 1400,
      heightMM: 1810,
      depthMM: 1400,
    },
    "2V.E.C.1818.0905": {
      widthMM: 1400,
      heightMM: 1810,
      depthMM: 1400,
    },
    "2V.E.C.1818.0906": {
      widthMM: 1400,
      heightMM: 1810,
      depthMM: 1400,
    },
    "2V.E.C.1818.0907": {
      widthMM: 1400,
      heightMM: 1810,
      depthMM: 1400,
    },
    "2V.E.E.2250.4008": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1700,
    },
    "2V.E.E.2250.8012": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1700,
    },
    "2V.E.E.2250.8015": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1700,
    },
    "2V.E.E.2250.8020": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1700,
    },
    "2V.E.C.2250.0507": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1700,
    },
    "2V.E.C.2250.0509": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1700,
    },
    "2V.E.C.2250.0904": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1700,
    },
    "2V.E.C.2250.0905": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1700,
    },
    "2V.E.C.2250.0906": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1700,
    },
    "2V.E.C.2250.0907": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 1700,
    },
    "2V.E.E.2501.4008": {
      widthMM: 1200,
      heightMM: 1850,
      depthMM: 2200,
    },
    "2V.E.E.2501.8012": {
      widthMM: 1200,
      heightMM: 1850,
      depthMM: 2200,
    },
    "2V.E.E.2501.8015": {
      widthMM: 1200,
      heightMM: 1850,
      depthMM: 2200,
    },
    "2V.E.E.2501.8020": {
      widthMM: 1200,
      heightMM: 1850,
      depthMM: 2200,
    },
    "2V.E.C.2501.0507": {
      widthMM: 1200,
      heightMM: 1850,
      depthMM: 2200,
    },
    "2V.E.C.2501.0509": {
      widthMM: 1200,
      heightMM: 1850,
      depthMM: 2200,
    },
    "2V.E.C.2501.0904": {
      widthMM: 1200,
      heightMM: 1850,
      depthMM: 2200,
    },
    "2V.E.C.2501.0905": {
      widthMM: 1200,
      heightMM: 1850,
      depthMM: 2200,
    },
    "2V.E.C.2501.0906": {
      widthMM: 1200,
      heightMM: 1850,
      depthMM: 2200,
    },
    "2V.E.C.2501.0907": {
      widthMM: 1200,
      heightMM: 1850,
      depthMM: 2200,
    },
    "2V.E.E.3001.4008": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 2200,
    },
    "2V.E.E.3001.8012": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 2200,
    },
    "2V.E.E.3001.8015": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 2200,
    },
    "2V.E.E.3001.8020": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 2200,
    },
    "2V.E.C.3001.0507": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 2200,
    },
    "2V.E.C.3001.0509": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 2200,
    },
    "2V.E.C.3001.0904": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 2200,
    },
    "2V.E.C.3001.0905": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 2200,
    },
    "2V.E.C.3001.0906": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 2200,
    },
    "2V.E.C.3001.0907": {
      widthMM: 1200,
      heightMM: 2100,
      depthMM: 2200,
    },
  },
};

// Script to generate sizes
// const config = "duty";
// const results = {};
// const models = new Set<string>();
// const modelsMap = {
//   "600": {
//     widthMM: 700,
//     heightMM: 1740,
//     depthMM: 1200,
//   },
//   "1000": {
//     widthMM: 1200,
//     heightMM: 1740, // needs to be updated
//     depthMM: 1200,
//   },
//   "1220": {
//     widthMM: 1400,
//     heightMM: 1820,
//     depthMM: 1200,
//   },
//   "1501": {
//     widthMM: 1200,
//     heightMM: 2100,
//     depthMM: 1200,
//   },
//   "1818": {
//     widthMM: 1400,
//     heightMM: 1810,
//     depthMM: 1400,
//   },
//   "2250": {
//     widthMM: 1200,
//     heightMM: 2100,
//     depthMM: 1700,
//   },
//   "2501": {
//     widthMM: 1200,
//     heightMM: 1850,
//     depthMM: 2200,
//   },
//   "3001": {
//     widthMM: 1200,
//     heightMM: 2100,
//     depthMM: 2200,
//   },
// };
//
// const capacityLookup =
//   FlowtechPumpTankData.configuration[config].capacityLookup;
// for (const prop in capacityLookup) {
//   for (const prop2 in capacityLookup[prop]) {
//     models.add(capacityLookup[prop][prop2]);
//   }
// }
//
// models.forEach((model) => {
//   for (const mapping in modelsMap) {
//     if (model.includes(mapping.toString())) {
//       results[model] = modelsMap[mapping];
//       break;
//     }
//   }
// });
//
// console.log(results);
