import { Failure } from "../../../common/src/api/document/types";

/**
 * usage:
 * ```
 * try {
 *   await axios....
 *   // do stuff
 * } catch (e: any) {
 *   return getFailureFromAxiosRequestErrorEvent(e);
 * }
 * ```
 */
export const getFailureFromAxiosRequestErrorEvent = (e: Error): Failure => {
  const response = (e as any).response;
  if (response && response.data && response.data.message) {
    return { success: false, message: response.data.message };
  }
  return { success: false, message: e.message };
};
