import { Coord } from "../../../../common/src/lib/coord";
import CanvasContext from "../../../src/htmlcanvas/lib/canvas-context";
import { DrawingContext } from "../../../src/htmlcanvas/lib/types";
import { UNHANDLED } from "../../../src/htmlcanvas/types";
import { MainEventBus } from "../../../src/store/main-event-bus";
import { ToolConfig } from "../../../src/store/tools/types";
import { KeyCode } from "../utils";
import { BaseTool } from "./base-tool";
import { KeyHandlers, keyspecMatches } from "./utils";

/**
 * How many screen pixels a "move" event needs to be before we skip "Click/Pointer" events.
 */
const EVENT_SKIP_PIXEL_TOLERANCE = 20;

export type PointToolArgs = {
  onFinish: (interrupted: boolean, displaced: boolean) => void;
  onDispose?: () => void;
  onMove?: (
    worldCoord: Coord,
    event: MouseEvent,
    angleBounds?: { min: number; max: number } | null,
  ) => void;
  onPointChosen: (worldCoord: Coord, event: MouseEvent) => void;
  clickActionName: string;
  keyHandlers?: KeyHandlers;
  getInfoText?: () => string[];
  getTitleCallback: () => string;
  name: string;
  pickPointOnRightClick?: boolean;
};
export default class PointTool extends BaseTool {
  onPointChosen: (worldCoord: Coord, event: MouseEvent) => void;
  onMove?: (
    worldCoord: Coord,
    event: MouseEvent,
    angleBounds?: { min: number; max: number } | null,
  ) => void;
  onFinish: (interrupted: boolean, displaced: boolean) => void;
  onDispose?: () => void;
  moved: boolean = false;
  moveOrigin: Coord | null = null;
  onKeyDown: (event: KeyboardEvent) => void;
  onKeyUp: (event: KeyboardEvent) => void;
  getTitleCallBack: () => string;

  escapeCallback: (context: CanvasContext) => void;
  clickActionName: string;

  isFinishing = false;
  images: HTMLImageElement[] = [];

  lastEvent!: MouseEvent;
  lastWc!: Coord;

  name: string;

  keyDown: Map<KeyCode, boolean> = new Map();

  pickPointOnRightClick: boolean;
  constructor(args: PointToolArgs) {
    super();
    const {
      onFinish,
      onDispose,
      onMove,
      onPointChosen,
      clickActionName,
      keyHandlers,
      getInfoText,
    } = args;
    this.onPointChosen = onPointChosen;
    this.onDispose = onDispose;
    this.onMove = onMove;
    this.onFinish = onFinish;
    this.escapeCallback = (context: CanvasContext) => {
      this.finish(context, true, false);
    };
    this.onKeyDown = (event: KeyboardEvent) => {
      this.keyDown.set(event.keyCode, true);
      this.keyHandlers.forEach(([k, h]) => {
        if (keyspecMatches(event, k)) {
          h.fn(event, () => this.refresh(), this.keyDown);
        }
      });
    };
    this.onKeyUp = (event: KeyboardEvent) => {
      this.keyDown.set(event.keyCode, false);
    };
    this.getInfoText = getInfoText;
    this.pickPointOnRightClick = args.pickPointOnRightClick ?? false;

    this.keyHandlers = [];
    this.keyHandlers.push(...(keyHandlers || []));

    MainEventBus.$on("escape-pressed", this.escapeCallback);
    MainEventBus.$on("right-clicked", this.escapeCallback);

    MainEventBus.$on("keydown", this.onKeyDown);
    MainEventBus.$on("keyup", this.onKeyUp);
    this.clickActionName = clickActionName;
    this.name = args.name;
    this.getTitleCallBack = args.getTitleCallback;
  }

  getTitle(): string {
    return this.getTitleCallBack();
  }

  beforeDraw(_context: DrawingContext): void {
    // nop
  }

  get config(): ToolConfig {
    return {
      name: this.name,
      defaultCursor: "Crosshair",
      focusSelectedObject: true,
      icon: "dot-circle",
      modesEnabled: false,
      modesVisible: false,
      escapeVisible: true,
      text: "Select a Point",
      tooltip: "point",
      propertiesEnabled: false,
      propertiesVisible: false,
      toolbarEnabled: false,
      toolbarVisible: false,
    };
  }

  onMouseDown(_event: MouseEvent, _context: CanvasContext): boolean | number {
    this.moved = false;
    console.log("Resetting move origin");
    this.moveOrigin = null;
    return false;
  }

  onMouseMove(event: MouseEvent, context: CanvasContext) {
    if (!this.moved) {
      this.moveOrigin = { x: event.clientX, y: event.clientY };
    }
    this.moved = true;
    this.lastEvent = event;

    this.lastWc = context.viewPort.toWorldCoord({
      x: event.clientX,
      y: event.clientY,
    });
    if (this.onMove) {
      this.onMove(this.lastWc, event);
    }
    return UNHANDLED;
  }

  /**
   * We start skipping "pointer" events when the mouse has moved under a drag.
   * this allows tools such as pan etc. to function nicely. Those tools should use the inverse of this calculation
   */
  hasMovedEnoughToSkipEvents(event: MouseEvent): boolean {
    if (!this.moveOrigin) {
      return false;
    }

    const diffX = Math.abs(event.clientX - this.moveOrigin.x);
    const diffY = Math.abs(event.clientY - this.moveOrigin.y);
    return (
      diffX >= EVENT_SKIP_PIXEL_TOLERANCE || diffY >= EVENT_SKIP_PIXEL_TOLERANCE
    );
  }

  onMouseScroll(_event: MouseEvent, _context: CanvasContext) {
    return false;
  }

  finish(context: CanvasContext, interrupted: boolean, displaced: boolean) {
    if (!this.isFinishing) {
      this.isFinishing = true;
      MainEventBus.$off("escape-pressed", this.escapeCallback);
      MainEventBus.$off("right-clicked", this.escapeCallback);
      MainEventBus.$off("keydown", this.onKeyDown);
      MainEventBus.$off("keyup", this.onKeyUp);
      this.onDispose && this.onDispose();
      this.onFinish(interrupted, displaced);
    }

    super.finish(context, interrupted, displaced);
  }

  dispose() {
    if (this.onDispose) {
      this.onDispose();
    }
  }

  onMouseUp(event: MouseEvent, context: CanvasContext) {
    if (
      this.moved &&
      this.hasMovedEnoughToSkipEvents(event) &&
      !context.document.uiState.clickableBounds
    ) {
      return false;
    } else {
      if (
        event.button === 0 ||
        (event.button === 2 && this.pickPointOnRightClick)
      ) {
        // End event.
        this.onPointChosen(
          context.viewPort.toWorldCoord({ x: event.clientX, y: event.clientY }),
          event,
        );
        this.finish(context, false, false);
        return true;
      } else {
        return false;
      }
    }
  }

  refresh() {
    if (this.onMove) {
      this.onMove(this.lastWc, this.lastEvent);
    }
  }
}
