import { CalculationField } from "../../../../../common/src/api/document/calculations-objects/calculation-field";
import { Calculation } from "../../../../../common/src/api/document/calculations-objects/types";
import { getPropertyByString } from "../../../../../common/src/lib/utils";
import { FilterKey, UIState } from "../../../store/document/types";

export function shouldShowField<T extends Calculation>(
  field: CalculationField,
  calculation: T,
  uiState?: UIState,
  filter?: { [key: string]: FilterKey },
) {
  if (field.hide) {
    return false;
  }
  if (filter && !(field.title in filter)) {
    return false;
  }
  if (filter && !filter[field.title].enabled) {
    return false;
  }
  const propertyByString = getPropertyByString(
    calculation,
    field.property,
    true,
  );

  if (
    propertyByString === undefined ||
    propertyByString === null ||
    propertyByString === "" ||
    (typeof propertyByString === "number" &&
      (isNaN(propertyByString) || !isFinite(propertyByString)))
  ) {
    return false;
  }

  if (uiState && field.layouts && !uiState.showAllLayouts) {
    return field.layouts.includes(uiState.drawingLayout);
  }

  return true;
}
