export const ROOM_PATTERNS = {
  HATCHED: (color: string) => {
    return `
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="300px" height="300px">
  <defs>
    <style>
      .cls-1 {
        fill: none;
        stroke: ${color};
        stroke-miterlimit: 10;
        stroke-width: 6px;
      }
    </style>
  </defs>
  <line class="cls-1" x1="0" y1="0" x2="100" y2="100"/>
  <line class="cls-1" x1="100" y1="0" x2="0" y2="100"/>
</svg>
    `;
  },
};
