import { OmitStatics } from "../../lib/utils";
import { TraceCalculation } from "./flight-data-recorder";
import { GlobalFDR } from "./global-fdr";
import Graph, { DijkstraOptions, Edge, SubGraph } from "./graph";
import { EnergyEdge, EnergyNode } from "./types";

// The extends syntax is a bit weird to override the static method fromSubgraph properly.
// https://github.com/microsoft/TypeScript/issues/4628
class Base extends Graph<EnergyNode, EnergyEdge> {}

// A graph used for energy transfer across entities
// a connection means there is some energy transfer between the two connectables
// an example would be from plant preheat -> plant outlets (in buffer tanks)

export class EnergyGraph extends (Base as OmitStatics<
  typeof Base,
  "fromSubgraph"
>) {
  constructor(sn?: (node: EnergyNode) => string) {
    if (sn) {
      super(sn);
    } else {
      super((node: EnergyNode) => node.connection + " " + node.connectable);
    }
  }

  @TraceCalculation("Traversing the network")
  dijkstra(options: DijkstraOptions<EnergyNode, EnergyEdge>) {
    if (options.visitEdge) {
      const old = options.visitEdge;
      options.visitEdge = (edge, weight) => {
        GlobalFDR.focusData([
          edge.from.connectable,
          edge.from.connection,
          edge.value.uid,
          edge.to.connectable,
          edge.to.connection,
        ]);
        return old(edge, weight);
      };
    }
    if (options.visitNode) {
      const old = options.visitNode;
      options.visitNode = (node) => {
        GlobalFDR.focusData([
          node.parent?.from.connectable || "",
          node.parent?.from.connection || "",
          node.node.connection,
          node.node.connectable,
          node.parent?.to.connectable || "",
          node.parent?.to.connection || "",
        ]);
        return old(node);
      };
    }
    if (options.getDistance) {
      const old = options.getDistance;
      options.getDistance = (edge, weight) => {
        GlobalFDR.focusData([
          edge.from.connectable,
          edge.from.connection,
          edge.value.uid,
          edge.to.connectable,
          edge.to.connection,
        ]);
        return old(edge, weight);
      };
    }
    this.DAGFlowPathCover;
    return super.dijkstra(options);
  }

  @TraceCalculation("Traversing the network")
  dfsRecursive(
    start: EnergyNode,
    visitNode?: ((node: EnergyNode) => boolean | void) | undefined,
    leaveNode?:
      | ((node: EnergyNode, wasCancelled?: boolean | undefined) => void)
      | undefined,
    visitEdge?:
      | ((edge: Edge<EnergyNode, EnergyEdge>) => boolean | void)
      | undefined,
    leaveEdge?:
      | ((
          edge: Edge<EnergyNode, EnergyEdge>,
          wasCancelled?: boolean | undefined,
        ) => void)
      | undefined,
    seen?: Set<string> | undefined,
    seenEdges?: Set<string> | undefined,
    directed?: boolean,
    reversed?: boolean,
    tryBothDirections?: boolean | undefined,
    randomize?: boolean,
  ): void {
    if (visitEdge) {
      const old = visitEdge;
      visitEdge = (edge) => {
        GlobalFDR.focusData([
          edge.from.connectable,
          edge.from.connection,
          edge.value.uid,
          edge.to.connectable,
          edge.to.connection,
        ]);
        return old(edge);
      };
    }
    if (leaveEdge) {
      const old = leaveEdge;
      leaveEdge = (edge, wasCancelled) => {
        GlobalFDR.focusData([
          edge.from.connectable,
          edge.from.connection,
          edge.value.uid,
          edge.to.connectable,
          edge.to.connection,
        ]);
        return old(edge, wasCancelled);
      };
    }
    if (visitNode) {
      const old = visitNode;
      visitNode = (node) => {
        GlobalFDR.focusData([node.connection, node.connectable]);
        return old(node);
      };
    }
    if (leaveNode) {
      const old = leaveNode;
      leaveNode = (node, wasCancelled) => {
        GlobalFDR.focusData([node.connection, node.connectable]);
        return old(node, wasCancelled);
      };
    }

    return super.dfsRecursive(
      start,
      visitNode,
      leaveNode,
      visitEdge,
      leaveEdge,
      seen,
      seenEdges,
      directed,
      reversed,
      tryBothDirections,
      randomize,
    );
  }

  @TraceCalculation("Traversing the network")
  graphSearch(
    mode: "dfs" | "bfs",
    start: EnergyNode,
    visitNode?: ((node: EnergyNode) => boolean | void) | undefined,
    leaveNode?:
      | ((node: EnergyNode, wasCancelled?: boolean | undefined) => void)
      | undefined,
    visitEdge?:
      | ((edge: Edge<EnergyNode, EnergyEdge>) => boolean | void | -1)
      | undefined,
    leaveEdge?:
      | ((
          edge: Edge<EnergyNode, EnergyEdge>,
          wasCancelled?: boolean | undefined,
        ) => void)
      | undefined,
    seen?: Set<string> | undefined,
    seenEdges?: Set<string> | undefined,
    directed?: boolean,
    reversed?: boolean,
    tryBothDirections?: boolean | undefined,
  ): void {
    if (visitNode) {
      const old = visitNode;
      visitNode = (node) => {
        GlobalFDR.focusData([node.connection, node.connectable]);
        return old(node);
      };
    }
    if (leaveNode) {
      const old = leaveNode;
      leaveNode = (node, wasCancelled) => {
        GlobalFDR.focusData([node.connection, node.connectable]);
        return old(node, wasCancelled);
      };
    }
    if (visitEdge) {
      const old = visitEdge;
      visitEdge = (edge) => {
        GlobalFDR.focusData([
          edge.from.connectable,
          edge.from.connection,
          edge.value.uid,
          edge.to.connectable,
          edge.to.connection,
        ]);
        return old(edge);
      };
    }
    if (leaveEdge) {
      const old = leaveEdge;
      leaveEdge = (edge, wasCancelled) => {
        GlobalFDR.focusData([
          edge.from.connectable,
          edge.from.connection,
          edge.value.uid,
          edge.to.connectable,
          edge.to.connection,
        ]);
        return old(edge, wasCancelled);
      };
    }
    return super.graphSearch(
      mode,
      start,
      visitNode,
      leaveNode,
      visitEdge,
      leaveEdge,
      seen,
      seenEdges,
      directed,
      reversed,
      tryBothDirections,
    );
  }

  @TraceCalculation("Preparing the graph by covering it with paths", (o) => [
    "Sources:",
    ...o.sources.map((s) => s.connection),
    "Sinks:",
    ...o.sinks.map((s) => s.connection),
  ])
  DAGFlowPathCover(options: { sources: EnergyNode[]; sinks: EnergyNode[] }) {
    return super.DAGFlowPathCover({
      sources: options.sources,
      sinks: options.sinks,
      onEdge: (edge, msg) => {
        GlobalFDR.focusData([
          "Edge: ",
          edge.from.connectable,
          edge.value.uid,
          edge.to.connectable,
          ...(msg ? [msg] : []),
        ]);
      },
    });
  }

  static fromSubgraph(
    sub: SubGraph<EnergyNode, EnergyEdge>,
    sn: (node: EnergyNode) => string,
  ): Graph<EnergyNode, EnergyEdge> {
    const graph = new EnergyGraph(sn);
    graph._loadFromSubgraph(sub);
    return graph;
  }

  clone(): EnergyGraph {
    return EnergyGraph.fromSubgraph(this.toSubgraph(), this.sn);
  }
}
