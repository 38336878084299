import {
  isCenteredEntity,
  isConduitConnectableEntity,
  isConnectableEntity,
} from "../../../../common/src/api/document/entities/concrete-entity";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { assertUnreachable } from "../../../../common/src/lib/utils";
import DrawableAnnotation from "./drawableAnnotation";
import DrawableVelux from "./drawableArchitectureElement";
import DrawableAreaSegment from "./drawableAreaSegment";
import DrawableBackgroundImage from "./drawableBackgroundImage";
import DrawableBigValve from "./drawableBigValve";
import DrawableCompound from "./drawableCompound";
import DrawableConduit from "./drawableConduit";
import DrawableDamper from "./drawableDamper";
import DrawableDirectedValve from "./drawableDirectedValve";
import DrawableEdge from "./drawableEdge";
import DrawableFen from "./drawableFenestration";
import DrawableFitting from "./drawableFitting";
import DrawableFixture from "./drawableFixture";
import DrawableFlowSource from "./drawableFlowSource";
import DrawableGasAppliance from "./drawableGasAppliance";
import DrawableLine from "./drawableLine";
import DrawableLoadNode from "./drawableLoadNode";
import DrawableMultiwayValve from "./drawableMultiwayValve";
import DrawablePlant from "./drawablePlant";
import DrawableRiser from "./drawableRiser";
import DrawableRoom from "./drawableRoom";
import DrawableSystemNode from "./drawableSystemNode";
import DrawableVertex from "./drawableVertex";
import DrawableWall from "./drawableWall";

export type DrawableObjectConcrete =
  | DrawableBackgroundImage
  | DrawableFitting
  | DrawableConduit
  | DrawableRiser
  | DrawableSystemNode
  | DrawableBigValve
  | DrawableFixture
  | DrawableDirectedValve
  | DrawableLoadNode
  | DrawableFlowSource
  | DrawablePlant
  | DrawableGasAppliance
  | DrawableCompound
  | DrawableMultiwayValve
  | DrawableEdge
  | DrawableWall
  | DrawableVertex
  | DrawableRoom
  | DrawableFen
  | DrawableLine
  | DrawableAnnotation
  | DrawableVelux
  | DrawableDamper
  | DrawableAreaSegment;

export type ConnectableObjectConcrete =
  | DrawableFitting
  | DrawableRiser
  | DrawableSystemNode
  | DrawableDirectedValve
  | DrawableLoadNode
  | DrawableFlowSource
  | DrawableMultiwayValve
  | DrawableVertex;

export function isConnectableObject(
  obj: DrawableObjectConcrete,
): obj is ConnectableObjectConcrete {
  return isConnectableEntity(obj.entity);
}

export type ConduitConnectableObjectConcrete = Exclude<
  ConnectableObjectConcrete,
  DrawableVertex
>;

export function isConduitConnectableObject(
  obj: DrawableObjectConcrete,
): obj is ConduitConnectableObjectConcrete {
  return isConduitConnectableEntity(obj.entity);
}

export type CenteredObjectConcrete =
  | DrawableFitting
  | DrawableRiser
  | DrawableSystemNode
  | DrawableBigValve
  | DrawableFixture
  | DrawableDirectedValve
  | DrawableLoadNode
  | DrawableFlowSource
  | DrawablePlant
  | DrawableGasAppliance
  | DrawableCompound
  | DrawableMultiwayValve
  | DrawableVertex
  | DrawableAreaSegment;

export function isCenteredObject(
  obj: DrawableObjectConcrete,
): obj is CenteredObjectConcrete {
  return isCenteredEntity(obj.entity);
}

export type RotatedObjectConcrete =
  | DrawableBigValve
  | DrawableFixture
  | DrawablePlant
  | DrawableGasAppliance
  | DrawableCompound;

export function isRotatedObject(
  obj: DrawableObjectConcrete,
): obj is RotatedObjectConcrete {
  switch (obj.type) {
    case EntityType.BIG_VALVE:
    case EntityType.FIXTURE:
    case EntityType.PLANT:
    case EntityType.GAS_APPLIANCE:
    case EntityType.COMPOUND:
      return true;
    case EntityType.CONDUIT:
    case EntityType.FITTING:
    case EntityType.RISER:
    case EntityType.SYSTEM_NODE:
    case EntityType.DIRECTED_VALVE:
    case EntityType.LOAD_NODE:
    case EntityType.FLOW_SOURCE:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.EDGE:
    case EntityType.VERTEX:
    case EntityType.ROOM:
    case EntityType.WALL:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.FENESTRATION:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return false;
  }
  assertUnreachable(obj);
}

export type EdgeLikeObjectConcrete =
  | DrawableConduit
  | DrawableFixture
  | DrawableBigValve
  | DrawablePlant
  | DrawableGasAppliance;

export type CoolDragObjectConcrete =
  | ConnectableObjectConcrete
  | EdgeLikeObjectConcrete;

export type EdgeObjectConcrete = DrawableConduit | DrawableEdge;

export type PolygonObjectConcrete = DrawableRoom | DrawableAreaSegment;

export type DrawableCalculatedObjectConcrete =
  | DrawableFitting
  | DrawableConduit
  | DrawableRiser
  | DrawableSystemNode
  | DrawableBigValve
  | DrawableFixture
  | DrawableDirectedValve
  | DrawableLoadNode
  | DrawableFlowSource
  | DrawablePlant
  | DrawableGasAppliance
  | DrawableCompound
  | DrawableMultiwayValve
  | DrawableDamper;

export function isDrawableCalculatedObject(
  obj: DrawableObjectConcrete,
): obj is DrawableCalculatedObjectConcrete {
  switch (obj.type) {
    case EntityType.FITTING:
    case EntityType.CONDUIT:
    case EntityType.RISER:
    case EntityType.SYSTEM_NODE:
    case EntityType.BIG_VALVE:
    case EntityType.FIXTURE:
    case EntityType.DIRECTED_VALVE:
    case EntityType.LOAD_NODE:
    case EntityType.FLOW_SOURCE:
    case EntityType.PLANT:
    case EntityType.GAS_APPLIANCE:
    case EntityType.COMPOUND:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.DAMPER:
      return true;
    case EntityType.BACKGROUND_IMAGE:
    case EntityType.EDGE:
    case EntityType.VERTEX:
    case EntityType.ROOM:
    case EntityType.WALL:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.FENESTRATION:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
    case EntityType.AREA_SEGMENT:
      return false;
  }
  assertUnreachable(obj);
}

export type HoverableObjectConcrete =
  | DrawableConduit
  | DrawableFitting
  | DrawableFixture
  | DrawablePlant
  | DrawableWall
  | DrawableRoom
  | DrawableVertex
  | DrawableEdge
  | DrawableFen
  | DrawableBigValve
  | DrawableDirectedValve
  | DrawableFlowSource
  | DrawableGasAppliance
  | DrawableLoadNode
  | DrawableMultiwayValve
  | DrawableLine
  | DrawableAnnotation
  | DrawableAreaSegment;

export function isHoverableObject(
  obj: DrawableObjectConcrete,
): obj is HoverableObjectConcrete {
  return isHoverableObjectAny(obj);
}

export function isHoverableObjectAny(obj: any): obj is HoverableObjectConcrete {
  return (
    obj instanceof DrawableConduit ||
    obj instanceof DrawableFitting ||
    obj instanceof DrawableFixture ||
    obj instanceof DrawablePlant ||
    obj instanceof DrawableWall ||
    obj instanceof DrawableRoom ||
    obj instanceof DrawableVertex ||
    obj instanceof DrawableEdge ||
    obj instanceof DrawableFen ||
    obj instanceof DrawableBigValve ||
    obj instanceof DrawableDirectedValve ||
    obj instanceof DrawableFlowSource ||
    obj instanceof DrawableGasAppliance ||
    obj instanceof DrawableLoadNode ||
    obj instanceof DrawableMultiwayValve ||
    obj instanceof DrawableLine ||
    obj instanceof DrawableAnnotation ||
    obj instanceof DrawableAreaSegment
  );
}

export type SnappableObjectConcrete =
  | DrawableBigValve
  | DrawableDirectedValve
  | DrawableFitting
  | DrawableFixture
  | DrawableFlowSource
  | DrawableGasAppliance
  | DrawableLoadNode
  | DrawableMultiwayValve
  | DrawableConduit
  | DrawablePlant
  | DrawableRiser
  | DrawableVertex;

export function isSnappableObject(
  obj: DrawableObjectConcrete,
): obj is SnappableObjectConcrete {
  return isSnappableObjectAny(obj);
}

export function isSnappableObjectAny(obj: any): obj is SnappableObjectConcrete {
  return (
    obj instanceof DrawableBigValve ||
    obj instanceof DrawableDirectedValve ||
    obj instanceof DrawableFitting ||
    obj instanceof DrawableFixture ||
    obj instanceof DrawableFlowSource ||
    obj instanceof DrawableGasAppliance ||
    obj instanceof DrawableLoadNode ||
    obj instanceof DrawableMultiwayValve ||
    obj instanceof DrawableConduit ||
    obj instanceof DrawablePlant ||
    obj instanceof DrawableRiser ||
    obj instanceof DrawableVertex
  );
}

export type AttachableObjectConcrete =
  | DrawableRiser
  | DrawableBigValve
  | DrawableFixture
  | DrawableDirectedValve
  | DrawableLoadNode
  | DrawableFlowSource
  | DrawablePlant
  | DrawableGasAppliance
  | DrawableMultiwayValve
  | DrawableAnnotation;

export function isAttachableObject(
  obj: DrawableObjectConcrete,
): obj is AttachableObjectConcrete {
  return isAttachableObjectAny(obj);
}

export function isAttachableObjectAny(
  obj: any,
): obj is AttachableObjectConcrete {
  return (
    obj instanceof DrawableRiser ||
    obj instanceof DrawableBigValve ||
    obj instanceof DrawableFixture ||
    obj instanceof DrawableDirectedValve ||
    obj instanceof DrawableLoadNode ||
    obj instanceof DrawableFlowSource ||
    obj instanceof DrawablePlant ||
    obj instanceof DrawableGasAppliance ||
    obj instanceof DrawableMultiwayValve ||
    obj instanceof DrawableAnnotation
  );
}

export function isPolygonObject(
  obj: DrawableObjectConcrete,
): obj is PolygonObjectConcrete {
  return obj instanceof DrawableRoom || obj instanceof DrawableAreaSegment;
}
