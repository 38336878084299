import { closestPipeSize } from "../catalog/manufacturers/utils";
import { isSewer } from "../config";
import {
  NoFlowAvailableReason,
  PipeCalculation,
} from "../document/calculations-objects/conduit-calculations";
import { addWarning } from "../document/calculations-objects/warnings";
import {
  PipeConduitEntity,
  fillDefaultConduitFields,
} from "../document/entities/conduit-entity";
import {
  SewerFlowSystem,
  StormwaterFlowSystem,
} from "../document/flow-systems";
import { DrainageCalculations } from "./drainage";
import { TraceCalculation } from "./flight-data-recorder";
import { CoreContext } from "./types";

export class CalculationHelper {
  // Common logic to determine the pipe size of Sewer and Stormwater pipe
  // drainageUnits = isSewer ? drainageUnits : FlowRate
  @TraceCalculation("Sizing drainage pipe", (e, _1, _2, _3, _4) => [e.uid])
  static sizeDrainagePipe(
    entity: PipeConduitEntity,
    context: CoreContext,
    system: SewerFlowSystem | StormwaterFlowSystem,
    drainageUnits: number | null,
    calc: PipeCalculation,
  ) {
    const filled = fillDefaultConduitFields(context, entity);
    if (drainageUnits !== null) {
      switch (filled.conduit.network) {
        case "stacks":
          system.stackPipeSizing.sort((a, b) => a.maxUnits - b.maxUnits);

          for (const size of system.stackPipeSizing) {
            if (size.maxUnits >= drainageUnits) {
              calc.realNominalPipeDiameterMM = calc.optimalInnerPipeDiameterMM =
                closestPipeSize(
                  size.sizeMM,
                  filled.conduit.material!,
                  context.catalog,
                  context.drawing,
                );
              break;
            }
          }
          if (calc.realNominalPipeDiameterMM === null) {
            calc.noFlowAvailableReason =
              NoFlowAvailableReason.NO_SUITABLE_PIPE_SIZE;
          }
          if (isSewer(system)) {
            if (system.stackDedicatedVent) {
              calc.stackDedicatedVentSize = DrainageCalculations.getSizeOfVent(
                system,
                drainageUnits,
              );
            }
          }
          break;
        case "pipes":
          system.horizontalPipeSizing.sort((a, b) => a.maxUnits - b.maxUnits);

          for (const size of system.horizontalPipeSizing) {
            if (size.maxUnits >= drainageUnits) {
              calc.realNominalPipeDiameterMM = calc.optimalInnerPipeDiameterMM =
                closestPipeSize(
                  size.sizeMM,
                  filled.conduit.material!,
                  context.catalog,
                  context.drawing,
                );
              calc.gradePCT = size.gradePCT;
              if (entity.conduit.gradePCT !== null) {
                calc.gradePCT = entity.conduit.gradePCT; // user override grade
              }
              break;
            }
          }

          if (calc.realNominalPipeDiameterMM === null) {
            addWarning(context, "UPDATE_FLOW_SYSTEM_SETTINGS", [entity], {
              mode: "drainage",
            });
            calc.noFlowAvailableReason =
              NoFlowAvailableReason.NO_SUITABLE_PIPE_SIZE;
          }
          break;
        case "vents":
          break;
      }
    }

    if (entity.conduit.diameterMM !== null) {
      calc.realNominalPipeDiameterMM = calc.optimalInnerPipeDiameterMM =
        entity.conduit.diameterMM;
    }
  }

  static fillPipeFlowRateCalcResult(
    calc: PipeCalculation,
    spareCapacityPCT: number,
    flowRateLS: number,
  ): number {
    calc.rawPSDFlowRateLS = flowRateLS;

    flowRateLS = flowRateLS * (1 + Number(spareCapacityPCT) / 100);

    calc.PSDFlowRateLS = flowRateLS;
    calc.totalPeakFlowRateLS = flowRateLS;
    return flowRateLS;
  }
}
