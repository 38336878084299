import { GenericRadiatorData } from "../../types";

// m2 being height * width of radiator
// analysis done here (public link) https://docs.google.com/spreadsheets/d/1gBdSwlXStO_FEgxoFYexThwOikbaoIt0/edit?gid=1373818535#gid=1373818535
export const RADIATOR_TYPE11_VOLUME_PER_M2 = 5.56;
export const RADIATOR_TYPE21_VOLUME_PER_M2 = 11.13;
export const RADIATOR_TYPE22_VOLUME_PER_M2 = 11.13;
export const RADIATOR_TYPE33_VOLUME_PER_M2 = 16.81;

// used by AU, UK and US catalogue
export const GenericRadiators: GenericRadiatorData[] = [
  {
    rangeType: "11",
    ratingDataType: "KWperM2",
    KWperM2ByDeltaT: {
      10: 0.38,
      15: 0.456,
      20: 0.547,
      25: 0.657,
      30: 0.788,
      35: 0.946,
      40: 1.135,
      45: 1.362,
      50: 1.634,
      55: 1.961,
      60: 2.353,
    },
    model: "11",
    depthMM: 120,
  },
  {
    rangeType: "21",
    ratingDataType: "KWperM2",
    KWperM2ByDeltaT: {
      10: 0.55,
      15: 0.66,
      20: 0.792,
      25: 0.95,
      30: 1.14,
      35: 1.369,
      40: 1.642,
      45: 1.971,
      50: 2.365,
      55: 2.838,
      60: 3.405,
    },
    model: "21",
    depthMM: 130,
  },
  {
    rangeType: "22",
    ratingDataType: "KWperM2",
    KWperM2ByDeltaT: {
      10: 0.73,
      15: 0.876,
      20: 1.051,
      25: 1.261,
      30: 1.514,
      35: 1.816,
      40: 2.18,
      45: 2.616,
      50: 3.139,
      55: 3.767,
      60: 4.52,
    },
    model: "22",
    depthMM: 140,
  },
  {
    rangeType: "33",
    ratingDataType: "KWperM2",
    KWperM2ByDeltaT: {
      10: 1.04,
      15: 1.248,
      20: 1.498,
      25: 1.797,
      30: 2.157,
      35: 2.588,
      40: 3.105,
      45: 3.727,
      50: 4.472,
      55: 5.366,
      60: 6.439,
    },
    model: "33",
    depthMM: 150,
  },
];
