import { betterObjectEntries, betterObjectValues } from "../../../lib/utils";
import { CoreContext } from "../../calculations/types";
import { ManifoldPlantEntity } from "../../document/entities/plants/plant-entity";
import {
  ActuatorModel,
  ActuatorSpec,
  BallValveModel,
  BallValveSpec,
  BlendingValveModel,
  ComponentId,
  PumpPackModel,
  PumpPackSpec,
  UFHPumpModel,
  componentIdEquals,
} from "./ufh-types";

type manufacturer = string;
type model = string;

interface ManifoldCompatibleItem {
  model: string;
  compatibleManifolds: ComponentId[];
}

function getCompatibleItems<T extends ManifoldCompatibleItem>(
  items: Record<manufacturer, Record<model, T>>,
  manifoldSpecs: ComponentId,
): Record<manufacturer, Record<model, T>> {
  const [manifoldManuf, manifoldModel] = manifoldSpecs;

  return betterObjectEntries(items).reduce(
    (filteredDatasheet, [manufacturer, itemData]) => {
      const filteredModels = betterObjectValues(itemData).filter((data) =>
        data.compatibleManifolds.some(
          ([manuf, model]) =>
            manuf === manifoldManuf && model === manifoldModel,
        ),
      );

      if (filteredModels.length > 0) {
        filteredDatasheet[manufacturer] = Object.fromEntries(
          filteredModels.map((modelData) => [modelData.model, modelData]),
        );
      }

      return filteredDatasheet;
    },
    {} as Record<manufacturer, Record<model, T>>,
  );
}

export function getCompatibleActuators(
  actuators: ActuatorSpec["datasheet"],
  manifoldSpecs: ComponentId,
): ActuatorSpec["datasheet"] {
  return getCompatibleItems(actuators, manifoldSpecs);
}

export function getCompatiblePumpPacks(
  pumpPacks: PumpPackSpec["datasheet"],
  manifoldSpecs: ComponentId,
): PumpPackSpec["datasheet"] {
  return getCompatibleItems(pumpPacks, manifoldSpecs);
}

export function getCompatibleBallValves(
  ballValves: BallValveSpec["datasheet"],
  manifoldSpecs: ComponentId,
): BallValveSpec["datasheet"] {
  return getCompatibleItems(ballValves, manifoldSpecs);
}

export function isPumpPackCompatible(
  pumpPack: PumpPackModel,
  manifoldSpecs: ComponentId,
): boolean {
  return pumpPack.compatibleManifolds.some((id) =>
    componentIdEquals(id, manifoldSpecs),
  );
}

export function isBallValveCompatible(
  ballValve: BallValveModel,
  manifoldSpecs: ComponentId,
): boolean {
  return ballValve.compatibleManifolds.some((id) =>
    componentIdEquals(id, manifoldSpecs),
  );
}

export function isActuatorCompatible(
  actuator: ActuatorModel,
  manifoldSpecs: ComponentId,
): boolean {
  return actuator.compatibleManifolds.some((id) =>
    componentIdEquals(id, manifoldSpecs),
  );
}

export function lookupUFHPump(
  context: CoreContext,
  ufhPumpSpecs: ComponentId | null,
): UFHPumpModel | null {
  if (!ufhPumpSpecs) {
    return null;
  }
  const [manuf, model] = ufhPumpSpecs;
  return (
    context.catalog.underfloorHeating.pump.datasheet[manuf]?.[model] ?? null
  );
}

export function lookupBlendingValve(
  context: CoreContext,
  blendingValveSpecs: ComponentId | null,
): BlendingValveModel | null {
  if (!blendingValveSpecs) {
    return null;
  }
  const [manuf, model] = blendingValveSpecs;
  return (
    context.catalog.underfloorHeating.blendingValve.datasheet[manuf]?.[model] ??
    null
  );
}

export function lookupActuator(
  context: CoreContext,
  actuatorSpec: ComponentId | null,
): ActuatorModel | null {
  if (!actuatorSpec) {
    return null;
  }
  const [manuf, model] = actuatorSpec;
  return (
    context.catalog.underfloorHeating.actuator.datasheet[manuf]?.[model] ?? null
  );
}

export function lookupPumpPack(
  context: CoreContext,
  pumpPackSpec: ComponentId | null,
): PumpPackModel | null {
  if (!pumpPackSpec) {
    return null;
  }
  const [manuf, model] = pumpPackSpec;
  return (
    context.catalog.underfloorHeating.pumpPack.datasheet[manuf]?.[model] ?? null
  );
}

export function lookupBallValve(
  context: CoreContext,
  ballValveSpec: ComponentId | null,
): BallValveModel | null {
  if (!ballValveSpec) {
    return null;
  }
  const [manuf, model] = ballValveSpec;
  return (
    context.catalog.underfloorHeating.ballValve.datasheet[manuf]?.[model] ??
    null
  );
}

// TODO: in the future we need to support manifolds that have blending valves
// or pumps without the pump pack.
export function manifoldHasPumpPack(
  context: CoreContext,
  filled: ManifoldPlantEntity,
): boolean {
  return filled.plant.hasRecirculationPump;
}

export function manifoldHasUfhPump(
  context: CoreContext,
  filled: ManifoldPlantEntity,
) {
  return filled.plant.hasRecirculationPump;
}

export function manifoldHasBlendingValve(
  context: CoreContext,
  filled: ManifoldPlantEntity,
) {
  return filled.plant.hasRecirculationPump;
}
