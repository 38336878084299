import { assertUnreachable } from "../../../../lib/utils";
import { CoreContext } from "../../../calculations/types";
import {
  isClosedSystem,
  isCoolingPlantSystem,
  isHeating,
  isHeatingPlantSystem,
  isPressure,
} from "../../../config";
import { getFlowSystem } from "../../utils";
import { DrawableEntityConcrete } from "../concrete-entity";
import {
  CenteredEntity,
  DrawableEntity,
  PositionedEntity,
} from "../simple-entities";
import { EntityType } from "../types";
import {
  AirHandlingUnitVentPlant,
  CustomPlant,
  DHWCylinderPlant,
  DrainageGreaseInterceptorTrap,
  DrainagePit,
  DualSystemPlant,
  DuctManifoldPlant,
  FanCoilUnitPlant,
  FilterPlant,
  HeatLoadPlant,
  HeatPumpPlant,
  ManifoldPlant,
  PlantConcrete,
  PlantType,
  PumpPlant,
  PumpTank,
  ROPlant,
  RadiatorPlant,
  ReturnSystemPlant,
  SpecifyRadiatorPlant,
  TankPlant,
  UnderfloorHeatingPlant,
  VolumiserPlant,
} from "./plant-types";
import {
  getFilterPlantName,
  getPumpConfigurationName,
  isManifoldPlant,
  isRadiatorPlant,
  isReturnPlantCIBSEDiversified,
} from "./utils";

export default interface PlantEntity extends CenteredEntity, PositionedEntity {
  rightToLeft: boolean;

  inletUid: string | null;
  inletSystemUid: string;
  // outletUid and outletSystemUid have been moved to Plant
  // because ReturnSystems have multiple outlets defined in plant.outlets[]

  name: string | null;
  type: EntityType.PLANT;

  widthMM: number | null;
  depthMM: number | null;

  inletHeightAboveFloorM: number | null;

  plant: PlantConcrete;
}

export interface ReturnSystemPlantEntity extends PlantEntity {
  plant: ReturnSystemPlant;
}

export interface PumpPlantEntity extends PlantEntity {
  plant: PumpPlant;
}

export interface PumpTankPlantEntity extends PlantEntity {
  plant: PumpTank;
}

export interface TankPlantEntity extends PlantEntity {
  plant: TankPlant;
}

export interface RadiatorPlantEntity extends PlantEntity {
  plant: RadiatorPlant;
}

export interface HeatPumpPlantEntity extends PlantEntity {
  plant: HeatPumpPlant;
}

export interface DHWCylinderPlantEntity extends PlantEntity {
  plant: DHWCylinderPlant;
}

export interface DualSystemPlantEntity extends PlantEntity {
  plant: DualSystemPlant;
}

export interface FilterPlantEntity extends PlantEntity {
  plant: FilterPlant;
}

export interface ROPlantEntity extends PlantEntity {
  plant: ROPlant;
}

export interface DuctManifoldPlantEntity extends PlantEntity {
  plant: DuctManifoldPlant;
}

export interface AirHandlingUnitVentPlantEntity extends PlantEntity {
  plant: AirHandlingUnitVentPlant;
}

export interface ManifoldPlantEntity extends PlantEntity {
  plant: ManifoldPlant;
}

export function plantDefaultName(
  context: CoreContext,
  entity: PlantEntity,
): string {
  const { drawing } = context;

  switch (entity.plant.type) {
    case PlantType.RADIATOR:
      return "Radiator";
    case PlantType.MANIFOLD:
      return "Manifold";
    case PlantType.UFH:
      return "Under Floor Heating";
    case PlantType.AHU:
    case PlantType.AHU_VENT:
      return "Air Handling Unit";
    case PlantType.FCU:
      return "Fan Coil Unit";
    case PlantType.PUMP_TANK:
      // TODO: manufacturer stuff
      return (
        getPumpConfigurationName(entity.plant.configuration, "short") + " Tank"
      ).trim();
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
      return "Grease Interceptor Trap";
    case PlantType.RETURN_SYSTEM:
      if (
        isHeating(
          drawing.metadata.flowSystems[entity.plant.outlets[0].outletSystemUid],
        )
      ) {
        return "Heat Source";
      } else if (
        isPressure(
          drawing.metadata.flowSystems[entity.plant.outlets[0].outletSystemUid],
        )
      ) {
        return "Hot water system";
      }
      return "Return system";
    case PlantType.PUMP:
      return (
        getPumpConfigurationName(entity.plant.configuration, "short") + " Pump"
      ).trim();
    case PlantType.TANK:
      return "Tank";
    case PlantType.CUSTOM:
      return "Custom";
    case PlantType.DRAINAGE_PIT:
      return "Drainage Pit";
    case PlantType.VOLUMISER:
      return "Volumiser";
    case PlantType.FILTER:
      return getFilterPlantName(entity.plant);
    case PlantType.RO:
      return "Reverse Osmosis System";
    case PlantType.DUCT_MANIFOLD:
      return "Duct Manifold";
  }
  assertUnreachable(entity.plant);
}

export function isRatingDerived(
  context: CoreContext,
  plant: ReturnSystemPlant,
) {
  for (const outlet of plant.outlets) {
    const outSystem = getFlowSystem(context.drawing, outlet.outletSystemUid);
    if (!outSystem) {
      continue;
    }

    if (!isClosedSystem(outSystem)) {
      return false;
    }

    if (
      !isCoolingPlantSystem(outSystem) &&
      !isHeatingPlantSystem(outSystem) &&
      !isReturnPlantCIBSEDiversified(plant)
    ) {
      return false;
    }
  }

  return true;
}

export function plantHasSingleRating(
  plant: PlantConcrete,
): plant is
  | RadiatorPlant
  | ReturnSystemPlant
  | ManifoldPlant
  | UnderfloorHeatingPlant {
  switch (plant.type) {
    case PlantType.RADIATOR:
    case PlantType.RETURN_SYSTEM:
    case PlantType.MANIFOLD:
    case PlantType.UFH:
      return true;
    case PlantType.FCU:
    case PlantType.CUSTOM:
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
    case PlantType.DRAINAGE_PIT:
    case PlantType.PUMP:
    case PlantType.PUMP_TANK:
    case PlantType.TANK:
    case PlantType.VOLUMISER:
    case PlantType.FILTER:
    case PlantType.AHU:
    case PlantType.AHU_VENT:
    case PlantType.RO:
    case PlantType.DUCT_MANIFOLD:
      return false;
  }
  assertUnreachable(plant);
}

export function plantHasDualRating(
  plant: PlantConcrete,
): plant is
  | FanCoilUnitPlant
  | CustomPlant
  | DrainageGreaseInterceptorTrap
  | DrainagePit
  | PumpPlant
  | PumpTank
  | TankPlant
  | VolumiserPlant
  | FilterPlant
  | AirHandlingUnitVentPlant {
  switch (plant.type) {
    case PlantType.RADIATOR:
    case PlantType.RETURN_SYSTEM:
    case PlantType.MANIFOLD:
    case PlantType.UFH:
    case PlantType.DUCT_MANIFOLD:
      return false;
    case PlantType.AHU_VENT:
    case PlantType.AHU:
    case PlantType.FCU:
    case PlantType.CUSTOM:
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
    case PlantType.DRAINAGE_PIT:
    case PlantType.PUMP:
    case PlantType.PUMP_TANK:
    case PlantType.TANK:
    case PlantType.VOLUMISER:
    case PlantType.FILTER:
    case PlantType.RO:
      return true;
  }
  assertUnreachable(plant);
}

export function isHeatLoadPlant(plant: PlantConcrete): plant is HeatLoadPlant {
  switch (plant.type) {
    case PlantType.RADIATOR:
    case PlantType.RETURN_SYSTEM:
    case PlantType.MANIFOLD:
    case PlantType.UFH:
    case PlantType.AHU:
    case PlantType.AHU_VENT:
    case PlantType.FCU:
      return true;
    case PlantType.CUSTOM:
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
    case PlantType.DRAINAGE_PIT:
    case PlantType.PUMP:
    case PlantType.PUMP_TANK:
    case PlantType.TANK:
    case PlantType.VOLUMISER:
    case PlantType.FILTER:
    case PlantType.RO:
    case PlantType.DUCT_MANIFOLD:
      return false;
    default:
      assertUnreachable(plant);
  }

  return false;
}

export function plantRatingIsProvided(plant: HeatLoadPlant) {
  if (plantHasSingleRating(plant)) {
    switch (plant.rating.type) {
      case "energy":
        return plant.rating.KW !== null;
      case "flow-rate":
        return plant.rating.LS !== null;
      default:
        assertUnreachable(plant.rating);
    }
  }
  return false;
}

export function isSpecifyRadiator(
  plant: PlantConcrete,
): plant is SpecifyRadiatorPlant {
  return isRadiatorPlant(plant) && plant.radiatorType === "specify";
}

export function isAHUVent(
  entity: DrawableEntityConcrete,
): entity is AirHandlingUnitVentPlantEntity {
  return (
    entity.type === EntityType.PLANT && entity.plant.type === PlantType.AHU_VENT
  );
}

export function isPlant(entity: DrawableEntity): entity is PlantEntity {
  return entity.type === EntityType.PLANT;
}

export type ManifoldEntity = PlantEntity & { plant: ManifoldPlant };

export function isManifold(entity: DrawableEntity): entity is ManifoldEntity {
  return isPlant(entity) && isManifoldPlant(entity.plant);
}

export function getDefaultHeatPumpScop() {
  return Array.from({ length: 65 - 35 + 1 }, (_, index) => ({
    SCOP: 0,
    temperatureC: 35 + index,
  }));
}
