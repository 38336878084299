// please also maintain the equivalent messages in the backend code.
import { Coord } from "../../lib/coord";
import { HeatLoadItem } from "../catalog/heatload/types";
import * as OT from "./operation-transforms";

export enum DocumentWSMessageType {
  UPDATE = "UPDATE",
  OPERATION = "OPERATION",
  DOCUMENT_DELETED = "DOCUMENT_DELETED",
  DOCUMENT_LOADED = "DOCUMENT_LOADED",
  DOCUMENT_ERROR = "DOCUMENT_ERROR",
  DOCUMENT_UPDATE = "DOCUMENT_UPDATE",
}

export interface DocumentMessage {
  type: DocumentWSMessageType;
}

export interface OperationMessage extends DocumentMessage {
  type: DocumentWSMessageType.OPERATION;
  operation: OT.OperationTransformConcrete;
}

export interface DocumentErrorMessage extends DocumentMessage {
  type: DocumentWSMessageType.DOCUMENT_ERROR;
  message: string;
}

export interface DocumentUpdateMessage extends DocumentMessage {
  type: DocumentWSMessageType.DOCUMENT_UPDATE;
  message: string;
}

export interface DocumentUpdate {
  type: DocumentWSMessageType.UPDATE;
  nextOpId: number;
}

export interface DocumentDeletedMessage extends DocumentMessage {
  type: DocumentWSMessageType.DOCUMENT_DELETED;
}

export interface DocumentLoadedMessage extends DocumentMessage {
  type: DocumentWSMessageType.DOCUMENT_LOADED;
}

export type DocumentClientMessageSingle =
  | OperationMessage
  | DocumentDeletedMessage
  | DocumentLoadedMessage
  | DocumentErrorMessage
  | DocumentUpdateMessage;
export type DocumentClientMessage = DocumentClientMessageSingle[];
export type DocumentInternalEvent = DocumentUpdate | DocumentDeletedMessage;

export interface Success<T> {
  success: true;
  data: T;
  message?: string;
  redirect?: boolean;
}

export interface Failure {
  success: false;
  message: string;
  redirect?: boolean;
}

export type APIResult<T> = Success<T> | Failure;

export interface FloorPlanRenders {
  bySize: { [key: string]: FloorPlanRender };
}

export interface FloorPlanRender {
  images: FloorPlanImage[];
  width: number;
  height: number;
}

export interface FloorPlanImage {
  topLeft: Coord;
  width: number;
  height: number;
  key: string;
}

export interface LoginSuccess {
  type: "login-success";
  token: string;
}

export interface PasswordReset {
  type: "password-reset";
  url: string;
}

export type LoginResult = LoginSuccess | PasswordReset;

export enum EntityResourceType {
  FLOW_SYSTEM,
  MATERIAL,
  FIRE_NODE,
  LOAD_NODE,
}

export type FlowSystemResource = {
  type: EntityResourceType.FLOW_SYSTEM;
  uid: string;
};
export type MaterialResource = {
  type: EntityResourceType.MATERIAL;
  uid: string;
  role: HeatLoadItem;
};
export type FireNodeResource = {
  type: EntityResourceType.FIRE_NODE;
  customNodeId: string;
};
export type LoadNodeResource = {
  type: EntityResourceType.LOAD_NODE;
  customNodeId: string | number;
};

export type EntityResource =
  | FlowSystemResource
  | MaterialResource
  | FireNodeResource
  | LoadNodeResource;
