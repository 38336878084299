import Flatten from "@flatten-js/core";
import { Coord, Coord3D, asCoord } from "../../../lib/coord";
import { arc2d, arc3d } from "../../../lib/mathUtils/geometry-utils";
import { floatEq } from "../../../lib/utils";
import { Vector3 } from "../../../lib/vector3";
import { UnderfloorHeatingLoopCalculation } from "../../document/calculations-objects/underfloor-heating-loop-calculation";
import { CoilCoord3D } from "./coil-coords";

export type StraightBreakdown2D = {
  type: "straight";
  coords: [Coord, Coord];
};

// 2D Bend Breakdowns differ from 3d in that have start/end absolute angles.
// This can be useful for drawing systems.
export type BendBreakdown2D = {
  type: "bend";
  center: Coord;
  isCw: boolean;
  radiusMM: number;
  startAngleRAD: number;
  endAngleRAD: number;
};

export type LoopBreakdown2D = StraightBreakdown2D | BendBreakdown2D;

export type StraightBreakdown3D = {
  type: "straight";
  coords: [Coord3D, Coord3D];
};

export type BendBreakdown3D = {
  type: "bend";
  center: Coord3D;
  isCw: boolean;
  radiusMM: number;
  totalAngleRAD: number;
};

export type LoopBreakdown3D = StraightBreakdown3D | BendBreakdown3D;

export function fullLoopBreakdown2D(
  loop: UnderfloorHeatingLoopCalculation,
): LoopBreakdown2D[] {
  return loopBreakdown2D([
    ...loop.fromManifold,
    ...loop.roomLoop,
    ...loop.toManifold,
  ]);
}

/**
 * Breaks down a coordinate list into individual straight and bend segments on the XY Plane.
 */
export function loopBreakdown2D(loops: CoilCoord3D[]): LoopBreakdown2D[] {
  if (loops.length < 2) {
    return [];
  }
  const ret: LoopBreakdown2D[] = [];

  let prevPoint: Coord3D = loops[0];
  for (let i = 1; i < loops.length - 1; i++) {
    const c1 = prevPoint;
    const c2 = loops[i];
    const c3 = loops[i + 1];

    if (
      Vector3.between(c1, c2).isParallelTo(
        Vector3.between(c2, c3),
        Math.PI * 0.01,
      )
    ) {
      ret.push({
        type: "straight",
        coords: [asCoord(c1), asCoord(c2)],
      });
      prevPoint = c2;
      continue;
    }

    const arc = arc2d([c1, c2, c3], c2.turnRadiusMM);

    if (!arc) {
      // Special case:  Loops which are becoming vertical, eg.
      if (!floatEq(c1.x, c2.x, 1) || !floatEq(c1.y, c2.y, 1)) {
        ret.push({
          type: "straight",
          coords: [asCoord(c1), asCoord(c2)],
        });
      }
      prevPoint = c2;
      continue;
    }

    const p1 = Flatten.point(c1.x, c1.y);
    const p2 = Flatten.point(c2.x, c2.y);
    const p3 = Flatten.point(c3.x, c3.y);
    const minLen = Math.min(
      Flatten.vector(p1, p2).length,
      Flatten.vector(p2, p3).length,
    );
    const centerP = Flatten.point(arc.center.x, arc.center.y);
    const centerOffset = Flatten.vector(p2, centerP).length;
    const loopsCross = Flatten.vector(p1, p2).cross(Flatten.vector(p2, p3));
    const arcCross1 = Flatten.vector(p1, p2).cross(
      Flatten.vector(p2, Flatten.point(arc.center.x, arc.center.y)),
    );
    const arcCross2 = Flatten.vector(p2, p3).cross(
      Flatten.vector(p2, Flatten.point(arc.center.x, arc.center.y)),
    );
    const isArcInside =
      loopsCross * arcCross1 > 0 &&
      loopsCross * arcCross2 > 0 &&
      centerOffset < minLen * 3;

    // console.log({ loopsCross, arcCross1, arcCross2, isArcInside });

    const thisEP = isArcInside ? arc.thisEp : c2;
    const nextEP = isArcInside ? arc.nextEp : c3;

    ret.push({
      type: "straight",
      coords: [asCoord(c1), asCoord(thisEP)],
    });

    if (isArcInside) {
      ret.push({
        type: "bend",
        isCw: arc.isCw,
        center: arc.center,
        radiusMM: arc.radiusMM,
        startAngleRAD: arc.startAngleRAD,
        endAngleRAD: arc.endAngleRAD,
      });
    }

    prevPoint = nextEP;
  }

  ret.push({
    type: "straight",
    coords: [asCoord(prevPoint), asCoord(loops[loops.length - 1])],
  });

  return ret;
}

export function fullLoopBreakdown3D(
  loop: UnderfloorHeatingLoopCalculation,
): LoopBreakdown3D[] {
  return loopBreakdown3D([
    ...loop.fromManifold,
    ...loop.roomLoop,
    ...loop.toManifold,
  ]);
}

/**
 * Breaks down a coordinate list into individual straight and bend segments.
 */
export function loopBreakdown3D(loops: CoilCoord3D[]): LoopBreakdown3D[] {
  if (loops.length < 2) {
    return [];
  }
  const ret: LoopBreakdown3D[] = [];

  let prevPoint: Coord3D = loops[0];
  for (let i = 1; i < loops.length - 1; i++) {
    const c1 = prevPoint;
    const c2 = loops[i];
    const c3 = loops[i + 1];
    const arc = arc3d([c1, c2, c3], c2.turnRadiusMM);

    if (!arc) {
      console.warn("UFH Cannot create arc3d:", c1, c2, c3, c2.turnRadiusMM);
      continue;
    }

    ret.push({
      type: "straight",
      coords: [c1, arc.thisEp],
    });

    const loopsCross = Vector3.between(c1, c2).cross(Vector3.between(c2, c3));
    const arcCross = Vector3.between(c2, c3).cross(
      Vector3.between(c2, arc.center),
    );
    const isArcInside = loopsCross.dot(arcCross) > 0;

    if (isArcInside) {
      ret.push({
        type: "bend",
        isCw: arc.isCw,
        center: arc.center,
        radiusMM: arc.radiusMM,
        totalAngleRAD: arc.totalAngleRAD,
      });
    }

    if (isNaN(arc.nextEp.x)) {
      console.error("Next EP NAN", arc);
    }

    prevPoint = arc.nextEp;
  }

  ret.push({
    type: "straight",
    coords: [prevPoint, loops[loops.length - 1]],
  });

  return ret;
}
