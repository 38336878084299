import { CoreContext, DrawingLayout } from "../../calculations/types";
import { CalculatableEntityConcrete } from "../entities/concrete-entity";
import type { WarningDefinitions, Warnings } from "./warning-definitions";

export interface WarningDescription {
  readonly title: string | null;
  readonly shortTitle?: string | null;
  readonly description?: string | null;
  readonly helpLink?: string | null;
}

// warning instance id
export type WIID = number;

// TODO: part of refactor, this is how warnings should be defined
// on the entity's calculations.
export interface LiveWarningDetail {
  type: Warnings;
  instanceId?: WIID;
  layouts?: DrawingLayout[];
}

export interface WarningDetail {
  uid: string;
  type: Warnings;
  instanceId: WIID;
  layouts?: DrawingLayout[];
}

export function full2liveLayouts(
  layouts: DrawingLayout[] | DrawingLayout | null | undefined,
): DrawingLayout[] | undefined {
  if (!layouts) {
    return undefined;
  }
  if (Array.isArray(layouts)) {
    return layouts;
  }
  return [layouts];
}

export type WarningUi = WarningDetail & {
  entityUids: string[];
  warningId: Warnings;
  levelUid: string;
};

export interface WarningTree {
  levelUid: string;
  count: number;
  visible: boolean;
  data: WarningTreeData[];
}

export type RenderedWarning = WarningDescription &
  Omit<WarningDetail, "type"> & {
    entityUids: string[];
    levelUid: string;
  };

export type WarningTreeData = RenderedWarning & {
  uid: string;
  _rowVariant: string;
  label: string | null;
  children?: WarningTreeData[];
  _showDetails?: boolean;
  hidden?: boolean;
};

// Contains minimum representation of a warning.
// An intermediate structure while warnings are to be refactored.
export interface WarningInstance {
  entities: string[];
  type: Warnings;
  instanceId: number;
  uid: string;
  layouts: DrawingLayout[] | undefined;

  params?: { [key: string]: string | number | null };
}

export function addWarning<T extends keyof WarningDefinitions>(
  context: CoreContext & WarningDefinitions[T] extends (...args: any[]) => any
    ? "THIS WARNING REQUIRES PARAMETERS"
    : any,
  warning: T,
  entities: CalculatableEntityConcrete[],
  args?: {
    mode?: DrawingLayout | DrawingLayout[] | null;
    replaceSameWarnings?: boolean;
  } & (WarningDefinitions[T] extends (...args: any[]) => any
    ? { params: Parameters<WarningDefinitions[T]>[0] }
    : {}),
): void;
export function addWarning<T extends keyof WarningDefinitions>(
  context: CoreContext & WarningDefinitions[T] extends (...args: any[]) => any
    ? any
    : never,
  warning: T,
  entities: CalculatableEntityConcrete[],
  args: {
    mode?: DrawingLayout | DrawingLayout[] | null;
    replaceSameWarnings?: boolean;
  } & (WarningDefinitions[T] extends (...args: any[]) => any
    ? { params: Parameters<WarningDefinitions[T]>[0] }
    : {}),
): void;
export function addWarning<T extends keyof WarningDefinitions>(
  context: CoreContext,
  warning: T,
  entities: CalculatableEntityConcrete[],
  args?: {
    mode?: DrawingLayout | DrawingLayout[] | null;
    replaceSameWarnings?: boolean;
  } & (WarningDefinitions[T] extends (...args: any[]) => any
    ? { params: Parameters<WarningDefinitions[T]>[0] }
    : {}),
) {
  const firstEntityCalc = context.globalStore.getOrCreateCalculation(
    entities[0],
  );

  if (args?.replaceSameWarnings) {
    for (const e of entities) {
      const calc = context.globalStore.getOrCreateCalculation(e);
      calc.warnings = calc.warnings || [];
      const toRemove = calc.warnings.filter((w) => w.type === warning);
      calc.warnings = calc.warnings.filter((w) => w.type !== warning);
      for (const w of toRemove) {
        const wData = context.globalStore.calculationWarnings.get(w.instanceId);
        wData?.entities.splice(
          wData.entities.findIndex((e) => e === w.uid),
          1,
        );
      }
    }
  }

  const uid = `${entities[0].uid}.${warning}.${
    firstEntityCalc.warnings?.length || 0
  }}`;
  const instanceId = context.globalStore.nextWarningInstanceId++;
  for (const e of entities) {
    const calc = context.globalStore.getOrCreateCalculation(e);
    calc.warnings = calc.warnings || [];
    calc.warnings.push({
      type: warning,
      uid,
      instanceId,
      layouts: args?.mode ? full2liveLayouts(args.mode) : undefined,
    });
  }
  context.globalStore.calculationWarnings.set(instanceId, {
    entities: entities.map((e) => e.uid),
    instanceId,
    type: warning,
    uid: uid,
    layouts: args?.mode ? full2liveLayouts(args.mode) : undefined,
    params: args && "params" in args ? args.params : undefined,
  });
}

export function isWarningVisible(options: {
  showHiddenWarnings?: boolean;
  hiddenUids?: string[];
  drawingLayout: DrawingLayout;
  warning: WarningDetail;
}) {
  const { showHiddenWarnings, hiddenUids, drawingLayout, warning } = options;
  return (
    ((showHiddenWarnings || !hiddenUids?.includes(warning.uid)) &&
      warning.layouts &&
      warning.layouts.includes(drawingLayout)) ||
    (drawingLayout === "pressure" && !warning.layouts)
  );
}

export function isLiveWarningVisible(options: {
  drawingLayout: DrawingLayout;
  warning: LiveWarningDetail;
}) {
  const { drawingLayout, warning } = options;
  return (
    (drawingLayout === "pressure" && !warning.layouts) ||
    warning.layouts?.includes(drawingLayout)
  );
}
