import { PsdStandard, PSDStandardType } from "./types";

export type HCAAFixtureType =
  | "shower"
  | "basin"
  | "laundry"
  | "kitchen"
  | "toilet"
  | "washingMachine"
  | "dishwasher"
  | "bath";

export const HCAAFixtureDisplayNames: { [key in HCAAFixtureType]: string } = {
  shower: "Shower",
  basin: "Basin",
  laundry: "Laundry",
  kitchen: "Kitchen",
  toilet: "Toilet",
  washingMachine: "Washing Machine",
  dishwasher: "Dishwasher",
  bath: "Bath",
};

// Variables used in the HCAA equation for different fixture types
export interface HCAAFixtureParams {
  pk1: number;
  c1: number;
  c2: number;
  c3: number;
  c4: number;
}

export default interface HCAAEquation extends PsdStandard {
  type: PSDStandardType.HCAA_EQUATION;
  name: string;
  variables: { [key in HCAAFixtureType]: HCAAFixtureParams };
  z99: number;
}
