import {
  assertUnreachable,
  lowerBoundTable,
  parseCatalogNumberExact,
  parseCatalogNumberOrMin,
  upperBoundTable,
} from "../../lib/utils";
import { isGas } from "../config";
import CoreConduit from "../coreObjects/coreConduit";
import CoreDirectedValve from "../coreObjects/coreDirectedValve";
import { determineConnectableSystemUid } from "../coreObjects/utils";
import { NoFlowAvailableReason } from "../document/calculations-objects/conduit-calculations";
import {
  PipeConduitEntity,
  fillDefaultConduitFields,
} from "../document/entities/conduit-entity";
import DirectedValveEntity from "../document/entities/directed-valves/directed-valve-entity";
import {
  ValveType,
  hasFixedPressureDrop,
} from "../document/entities/directed-valves/valve-types";
import { fillGasApplianceFields } from "../document/entities/gas-appliance";
import LoadNodeEntity, {
  NodeType,
  fillDefaultLoadNodeFields,
} from "../document/entities/load-node-entity";
import { fillPlantDefaults } from "../document/entities/plants/plant-defaults";
import {
  PlantType,
  ReturnSystemPlant,
} from "../document/entities/plants/plant-types";
import { hasFixedGasRequirements } from "../document/entities/plants/utils";
import { EntityType } from "../document/entities/types";
import { FlowSystem } from "../document/flow-systems";
import { getFlowSystem } from "../document/utils";
import CalculationEngine from "./calculation-engine";
import { TraceCalculation } from "./flight-data-recorder";
import { GlobalFDR } from "./global-fdr";
import { EdgeType } from "./types";
import { FLOW_SOURCE_EDGE, FLOW_SOURCE_ROOT_NODE, isFlowSource } from "./utils";

export interface GasComponent {
  pipes: Set<string>;
  regulatorUid?: string;
  mainRunLengthM: number;
  supplyPressureKPA: number;
  maxPressureRequiredKPA: number;
}

export enum GasType {
  NATURAL_GAS = "naturalGas",
  LPG = "LPG",
}

export function system2Gas(system: FlowSystem) {
  if (system.fluid === "LPG") {
    return GasType.LPG;
  } else if (system.fluid === "naturalGas") {
    return GasType.NATURAL_GAS;
  }
  return null;
}

export class GasCalculations {
  @TraceCalculation("Setting gas pressure demands downstream of regulators")
  static setDownstreamGasPressureDemands(context: CalculationEngine) {
    for (const o of context.networkObjects()) {
      GlobalFDR.focusData([o.uid]);
      if (o.entity.type === EntityType.DIRECTED_VALVE) {
        const connections = context.globalStore.getConnections(o.entity.uid);
        const systemUid = determineConnectableSystemUid(
          context.globalStore,
          o.entity,
        );
        const thisIsGas = isGas(
          context.drawing.metadata.flowSystems[systemUid!],
        );

        if (
          o.entity.valve.type === ValveType.GAS_REGULATOR &&
          thisIsGas &&
          connections.length == 2
        ) {
          const regulator = context.globalStore.get(o.entity.uid)
            .entity as DirectedValveEntity;

          const downStreamPressureKPA = o.entity.valve.downStreamPressureKPA;

          const outletConn =
            regulator.sourceUid === connections[0]
              ? connections[1]
              : connections[0];

          context.flowGraph.dfs(
            {
              connectable: o.entity.uid,
              connection: outletConn,
            },
            (node) => {
              const no = context.globalStore.get(node.connectable);

              if (!no) {
                return true;
              }
              if (
                no.uid !== o.uid &&
                (isFlowSource(no.entity, context) ||
                  (no.entity.type === EntityType.DIRECTED_VALVE &&
                    no.entity.valve.type === ValveType.GAS_REGULATOR))
              ) {
                return true;
              }

              switch (no.entity.type) {
                case EntityType.SYSTEM_NODE: {
                  const po = context.globalStore.get(no.entity.parentUid!);
                  if (!po) {
                    return true;
                  }

                  switch (po.entity.type) {
                    case EntityType.GAS_APPLIANCE: {
                      const calc = context.globalStore.getOrCreateCalculation(
                        po.entity,
                      );
                      calc.gasPressureKPA =
                        po.entity.inletPressureKPA ?? downStreamPressureKPA;
                      break;
                    }
                    case EntityType.PLANT: {
                      if (po.entity.plant.type === PlantType.RETURN_SYSTEM) {
                        if (
                          !hasFixedGasRequirements(
                            context.drawing,
                            po.entity.plant,
                          )
                        ) {
                          const calc =
                            context.globalStore.getOrCreateCalculation(
                              po.entity,
                            );
                          calc.gasPressureKPA =
                            po.entity.plant.gasPressureKPA ??
                            downStreamPressureKPA;
                        }
                      }
                    }
                    case EntityType.CONDUIT:
                    case EntityType.BIG_VALVE:
                    case EntityType.FLOW_SOURCE:
                    case EntityType.FITTING:
                    case EntityType.DIRECTED_VALVE:
                    case EntityType.BACKGROUND_IMAGE:
                    case EntityType.FIXTURE:
                    case EntityType.RISER:
                    case EntityType.COMPOUND:
                    case EntityType.LOAD_NODE:
                    case EntityType.SYSTEM_NODE:
                    case EntityType.MULTIWAY_VALVE:
                    case EntityType.EDGE:
                    case EntityType.VERTEX:
                    case EntityType.ROOM:
                    case EntityType.WALL:
                    case EntityType.FENESTRATION:
                    case EntityType.LINE:
                    case EntityType.ANNOTATION:
                    case EntityType.ARCHITECTURE_ELEMENT:
                    case EntityType.DAMPER:
                    case EntityType.AREA_SEGMENT:
                      break;
                    default:
                      assertUnreachable(po.entity);
                  }
                  break;
                }
                case EntityType.LOAD_NODE: {
                  const calc = context.globalStore.getOrCreateCalculation(
                    no.entity,
                  );

                  calc.gasPressureKPA =
                    this.getGasEntityPressureDrop(no.entity) ??
                    downStreamPressureKPA;
                }
                case EntityType.CONDUIT:
                case EntityType.BIG_VALVE:
                case EntityType.FLOW_SOURCE:
                case EntityType.FITTING:
                case EntityType.DIRECTED_VALVE:
                case EntityType.PLANT:
                case EntityType.BACKGROUND_IMAGE:
                case EntityType.FIXTURE:
                case EntityType.GAS_APPLIANCE:
                case EntityType.RISER:
                case EntityType.COMPOUND:
                case EntityType.MULTIWAY_VALVE:
                case EntityType.EDGE:
                case EntityType.VERTEX:
                case EntityType.ROOM:
                case EntityType.WALL:
                case EntityType.FENESTRATION:
                case EntityType.LINE:
                case EntityType.ANNOTATION:
                case EntityType.ARCHITECTURE_ELEMENT:
                case EntityType.DAMPER:
                case EntityType.AREA_SEGMENT:
                  break;
                default:
                  assertUnreachable(no.entity);
              }
            },
          );
        }
      }
    }
  }

  // Assume that regulators dictate the pressure, even if the source doens't provide enough pressure.
  @TraceCalculation("Calculating gas system")
  static calculateGas(engine: CalculationEngine) {
    this.setDownstreamGasPressureDemands(engine);
    const components = this.getAndFillInGasComponent(engine);
    for (const component of components) {
      if (component.regulatorUid) {
        const regulator = engine.globalStore.get<CoreDirectedValve>(
          component.regulatorUid,
        );
        if (regulator.entity.valve.type === ValveType.GAS_REGULATOR) {
          const rCalc = engine.globalStore.getOrCreateCalculation(
            regulator.entity,
          );
          rCalc.pressureKPA = regulator.entity.valve.outletPressureKPA;
        }
      }

      for (const puid of component.pipes) {
        GlobalFDR.focusData([puid]);
        const pipeD = engine.getCalcByPipeId(puid);
        if (!pipeD) {
          // TODO: chuck a fit
          continue;
        }

        if (pipeD.pCalc.psdUnits) {
          const system = getFlowSystem<"gas">(
            engine.drawing,
            pipeD.pEntity.systemUid,
          )!;
          const gasType = system2Gas(system);
          if (gasType) {
            if (
              component.supplyPressureKPA <= component.maxPressureRequiredKPA
            ) {
              pipeD.pCalc.noFlowAvailableReason =
                NoFlowAvailableReason.GAS_SUPPLY_PRESSURE_TOO_LOW;
            } else {
              let inside = this.sizeGasPipeInside(
                pipeD.pCalc.psdUnits.gasMJH,
                component.mainRunLengthM,
                component.supplyPressureKPA,
                component.maxPressureRequiredKPA,
                gasType,
              );
              let p = lowerBoundTable(
                CoreConduit.getPipeManufacturerCatalogPage(
                  engine,
                  pipeD.pEntity,
                )!,
                inside,
                (t) => parseCatalogNumberOrMin(t.diameterInternalMM)!,
              );

              while (true) {
                if (p) {
                  pipeD.pCalc.optimalInnerPipeDiameterMM = inside;
                  pipeD.pCalc.realNominalPipeDiameterMM =
                    parseCatalogNumberOrMin(p.diameterNominalMM);
                  pipeD.pCalc.realInternalDiameterMM = parseCatalogNumberOrMin(
                    p.diameterInternalMM,
                  );
                  pipeD.pCalc.realOutsideDiameterMM = parseCatalogNumberOrMin(
                    p.diameterOutsideMM,
                  );

                  // Is the flow rate OK?
                  const network = pipeD.pEntity.conduit.network;
                  const maxSpeed = system.networks[network]!.velocityMS;

                  const velocity = this.getGasVelocityRealMs(
                    engine,
                    pipeD.pEntity,
                    gasType,
                  );
                  pipeD.pCalc.velocityRealMS = velocity ? velocity.ms : null;
                  pipeD.pCalc.gasMJH = velocity ? velocity.mjh : null;

                  if (
                    !pipeD.pCalc.velocityRealMS ||
                    pipeD.pCalc.velocityRealMS <= maxSpeed
                  ) {
                    break;
                  }

                  // increase pipe size.
                  p = lowerBoundTable(
                    CoreConduit.getPipeManufacturerCatalogPage(
                      engine,
                      pipeD.pEntity,
                    )!,
                    parseCatalogNumberExact(p.diameterInternalMM)! + 0.1,
                    (t) => parseCatalogNumberOrMin(t.diameterInternalMM)!,
                  );
                } else {
                  pipeD.pCalc.optimalInnerPipeDiameterMM = inside;
                  pipeD.pCalc.noFlowAvailableReason =
                    NoFlowAvailableReason.NO_SUITABLE_PIPE_SIZE;
                  break;
                }
              }
            }
          }
        }

        if (pipeD.pEntity.conduit.diameterMM) {
          // manual diameter
          let p = lowerBoundTable(
            CoreConduit.getPipeManufacturerCatalogPage(engine, pipeD.pEntity)!,
            pipeD.pEntity.conduit.diameterMM,
            (t) => parseCatalogNumberOrMin(t.diameterNominalMM)!,
          );
          if (p) {
            pipeD.pCalc.realNominalPipeDiameterMM = parseCatalogNumberOrMin(
              p.diameterNominalMM,
            );
            pipeD.pCalc.realInternalDiameterMM = parseCatalogNumberOrMin(
              p.diameterInternalMM,
            );
            pipeD.pCalc.realOutsideDiameterMM = parseCatalogNumberOrMin(
              p.diameterOutsideMM,
            );
          }
        }
      }
    }
  }

  @TraceCalculation("Get and fill in gas component")
  static getAndFillInGasComponent(engine: CalculationEngine) {
    const result: GasComponent[] = [];

    const groupOf = new Map<string, string>();
    const lengthOfGroup = new Map<string, number>();
    let block = new Set<string>();
    for (const o of engine.networkObjects()) {
      GlobalFDR.focusData([o.entity.uid]);
      let isRoot = false;
      let regulatorUid: string | undefined = undefined;
      let connection = "";
      let pressureKPA = 0;

      switch (o.entity.type) {
        case EntityType.FLOW_SOURCE: {
          const systemUid = determineConnectableSystemUid(
            engine.globalStore,
            o.entity,
          );
          const thisIsGas = isGas(
            engine.drawing.metadata.flowSystems[systemUid!],
          );

          if (thisIsGas) {
            isRoot = true;
            pressureKPA = o.entity.minPressureKPA!;
            connection = FLOW_SOURCE_EDGE;
            block.add(engine.serializeNode(FLOW_SOURCE_ROOT_NODE));
          }
          break;
        }
        case EntityType.DIRECTED_VALVE: {
          const systemUid = determineConnectableSystemUid(
            engine.globalStore,
            o.entity,
          );
          const connections = engine.globalStore.getConnections(o.entity.uid);
          const thisIsGas = isGas(
            engine.drawing.metadata.flowSystems[systemUid!],
          );
          if (
            o.entity.valve.type === ValveType.GAS_REGULATOR &&
            thisIsGas &&
            connections.length === 2
          ) {
            const p0 = engine.globalStore.get<CoreConduit>(connections[0]);
            const p0Calc = engine.globalStore.getOrCreateCalculation(p0.entity);
            const p1 = engine.globalStore.get<CoreConduit>(connections[1]);
            const p1Calc = engine.globalStore.getOrCreateCalculation(p1.entity);

            isRoot = true;
            regulatorUid = o.entity.uid;
            pressureKPA = o.entity.valve.outletPressureKPA!;
            if (p0Calc.flowFrom === o.entity.uid) {
              connection = p0.uid;
              block.add(
                engine.serializeNode({
                  connectable: o.entity.uid,
                  connection: p1.uid,
                }),
              );
            } else if (p1Calc.flowFrom === o.entity.uid) {
              connection = p1.uid;
              block.add(
                engine.serializeNode({
                  connectable: o.entity.uid,
                  connection: p0.uid,
                }),
              );
            } else {
              isRoot = false;
            }
          }

          if (thisIsGas && hasFixedPressureDrop(o.entity.valve)) {
            const calc = engine.globalStore.getOrCreateCalculation(o.entity);
            calc.pressureDropKPA = o.entity.valve.pressureDropKPA;
          }

          break;
        }
        case EntityType.LOAD_NODE: {
          const systemUid = determineConnectableSystemUid(
            engine.globalStore,
            o.entity,
          );
          const thisIsGas = isGas(
            engine.drawing.metadata.flowSystems[systemUid!],
          );

          if (thisIsGas) {
            const calc = engine.globalStore.getOrCreateCalculation(o.entity);
            switch (o.entity.node.type) {
              case NodeType.LOAD_NODE:
                calc.gasFlowRateMJH = o.entity.node.gasFlowRateMJH;
                break;
              case NodeType.DWELLING:
                calc.gasFlowRateMJH =
                  o.entity.node.gasFlowRateMJH * o.entity.node.dwellings!;
                break;
              // TODO: Again, need to be fixed later add role of flow system
              case NodeType.FIRE:
              case NodeType.VENTILATION:
                calc.gasFlowRateMJH = null;
                break;
              default:
                assertUnreachable(o.entity.node);
            }

            // TODO: remove later
            if (
              o.entity.node.type === NodeType.FIRE ||
              o.entity.node.type === NodeType.VENTILATION
            ) {
              calc.pressureKPA = null;
              calc.staticPressureKPA = null;
            } else {
              calc.pressureKPA = o.entity.node.gasPressureKPA;
              calc.staticPressureKPA = o.entity.node.gasPressureKPA;
            }
          }

          break;
        }
        case EntityType.PLANT:
          if (o.entity.plant.type === PlantType.RETURN_SYSTEM) {
            const filled = fillPlantDefaults(engine, o.entity);
            const calc = engine.globalStore.getOrCreateCalculation(o.entity);
            calc.gasPressureKPA = (
              filled.plant as ReturnSystemPlant
            ).gasPressureKPA;

            calc.gasFlowRateMJH = (
              filled.plant as ReturnSystemPlant
            ).gasConsumptionMJH;
          }
          break;
        case EntityType.SYSTEM_NODE:
        case EntityType.FITTING:
        case EntityType.CONDUIT:
        case EntityType.RISER:
        case EntityType.BIG_VALVE:
        case EntityType.FIXTURE:
        case EntityType.GAS_APPLIANCE:
        case EntityType.COMPOUND:
        case EntityType.MULTIWAY_VALVE:
        case EntityType.EDGE:
        case EntityType.VERTEX:
        case EntityType.ROOM:
        case EntityType.WALL:
        case EntityType.FENESTRATION:
        case EntityType.ARCHITECTURE_ELEMENT:
        case EntityType.DAMPER:
        case EntityType.AREA_SEGMENT:
          break;
        default:
          assertUnreachable(o.entity);
      }

      if (isRoot) {
        const distTo = new Map<string, number>();
        const pressureDropTo = new Map<string, number>();
        distTo.set(o.entity.uid, 0);
        pressureDropTo.set(o.entity.uid, 0);
        let mainRunLengthM = 0;
        const pipes = new Set<string>();
        const supplyPressureKPA = pressureKPA;
        let maxPressureRequiredKPA = 0;

        engine.flowGraph.dfs(
          {
            connectable: o.entity.uid,
            connection,
          },
          (node) => {
            const prevDist = distTo.get(node.connectable);
            const prevDrop = pressureDropTo.get(node.connectable);
            const no = engine.globalStore.get(node.connectable);
            if (!no) {
              return true;
            }

            if (
              no.entity.type === EntityType.DIRECTED_VALVE &&
              no.entity.valve.type === ValveType.GAS_REGULATOR
            ) {
              mainRunLengthM = Math.max(mainRunLengthM, prevDist || 0);
            } else if (
              no.entity.type === EntityType.SYSTEM_NODE &&
              no.entity.uid !== o.entity.uid
            ) {
              const sno = engine.globalStore.get(no.entity.uid)!;
              const parent = sno.parent!;
              if (parent.entity.type === EntityType.GAS_APPLIANCE) {
                const filled = fillGasApplianceFields(engine, parent.entity);
                mainRunLengthM = Math.max(mainRunLengthM, prevDist || 0);
                maxPressureRequiredKPA = Math.max(
                  maxPressureRequiredKPA,
                  filled.inletPressureKPA! + prevDrop!,
                );
              } else if (parent.entity.type === EntityType.PLANT) {
                if (parent.entity.plant.type === PlantType.RETURN_SYSTEM) {
                  const filled = fillPlantDefaults(engine, parent.entity);
                  mainRunLengthM = Math.max(mainRunLengthM, prevDist || 0);
                  maxPressureRequiredKPA = Math.max(
                    maxPressureRequiredKPA,
                    (filled.plant as ReturnSystemPlant).gasPressureKPA! +
                      prevDrop!,
                  );
                }
              }
            } else if (no.entity.type === EntityType.LOAD_NODE) {
              const systemUid = determineConnectableSystemUid(
                engine.globalStore,
                no.entity,
              );
              const nodeIsGas = isGas(
                engine.drawing.metadata.flowSystems[systemUid!],
              );
              const filled = fillDefaultLoadNodeFields(engine, no.entity);

              if (nodeIsGas) {
                mainRunLengthM = Math.max(mainRunLengthM, prevDist || 0);
                let gasPressureKPA = this.getGasEntityPressureDrop(filled);
                maxPressureRequiredKPA = Math.max(
                  maxPressureRequiredKPA,
                  gasPressureKPA! + prevDrop!,
                );
              }
            }

            if (
              no.entity.type === EntityType.FLOW_SOURCE &&
              no.entity.uid !== o.entity.uid
            ) {
              return true;
            }
            if (
              no.entity.type === EntityType.DIRECTED_VALVE &&
              no.entity.uid !== o.entity.uid
            ) {
              if (no.entity.valve.type === ValveType.GAS_REGULATOR) {
                maxPressureRequiredKPA = Math.max(
                  maxPressureRequiredKPA,
                  no.entity.valve.outletPressureKPA! + prevDrop!,
                );
                return true;
              }
            }
          },
          undefined,
          (e) => {
            let addedDist = 0;
            let addedDrop = 0;
            switch (e.value.type) {
              case EdgeType.CONDUIT: {
                const o = engine.globalStore.get<CoreConduit>(e.value.uid);
                const p = fillDefaultConduitFields(engine, o.entity);
                addedDist += p.lengthM!;

                // Special case: add something at the end for a fitting.
                const co = engine.globalStore.get(e.to.connectable);
                if (co && co.entity.type === EntityType.FITTING) {
                  addedDist += 2;
                }
                pipes.add(e.value.uid);
                break;
              }
              case EdgeType.ISOLATION_THROUGH:
              case EdgeType.CHECK_THROUGH:
              case EdgeType.BALANCING_THROUGH: {
                if (e.value.type === EdgeType.CHECK_THROUGH) {
                  const object = engine.globalStore.get<CoreDirectedValve>(
                    e.value.uid,
                  );
                  if (
                    object.entity.valve.type === ValveType.FILTER ||
                    object.entity.valve.type === ValveType.WATER_METER
                  ) {
                    addedDrop += object.entity.valve.pressureDropKPA!;
                  }
                }
                addedDist = 2;
                break;
              }
              case EdgeType.FITTING_FLOW:
                // Ignore - imply one fitting flow for every physical fitting at the end of the path,
                // we want to avoid this case:       / B
                //                              A -<|
                //                                  \  C  where the flow from A->C goes through ABC, longer way
                // and counting the fitting twice.
                addedDist = 0;
                break;
              case EdgeType.BIG_VALVE_HOT_HOT:
              case EdgeType.BIG_VALVE_HOT_WARM:
              case EdgeType.BIG_VALVE_COLD_WARM:
              case EdgeType.BIG_VALVE_COLD_COLD:
              case EdgeType.FLOW_SOURCE_EDGE:
              case EdgeType.PLANT_THROUGH:
              case EdgeType.RETURN_PUMP:
              case EdgeType.PLANT_PREHEAT:
                break;
              default:
                assertUnreachable(e.value.type);
            }

            const prevDist = distTo.get(e.from.connectable);
            const prevDrop = pressureDropTo.get(e.from.connectable);
            distTo.set(e.to.connectable, (prevDist || 0) + addedDist);
            pressureDropTo.set(e.to.connectable, (prevDrop || 0) + addedDrop);
          },
          undefined,
          block,
        );

        result.push({
          pipes,
          mainRunLengthM,
          supplyPressureKPA,
          maxPressureRequiredKPA,
          regulatorUid,
        });
      }
    }
    return result;
  }

  static getBestInsideDiameterSmall(
    inputRateCFH: number,
    pipeLengthFT: number,
    headLossIN: number,
    type: GasType,
  ) {
    const cr = type === GasType.NATURAL_GAS ? 0.6094 : 1.2462;
    const Y = type === GasType.NATURAL_GAS ? 0.9992 : 0.991;

    return (
      inputRateCFH ** 0.381 /
      (19.17 * (headLossIN / (cr * pipeLengthFT)) ** 0.206)
    );
  }

  static getBestInsideDiameterLarge(
    inputRateCFH: number,
    pipeLengthFT: number,
    upstreamPSI: number,
    downstreamPSI: number,
    type: GasType,
  ) {
    const cr = type === GasType.NATURAL_GAS ? 0.6094 : 1.2462;
    const Y = type === GasType.NATURAL_GAS ? 0.9992 : 0.991;

    return (
      inputRateCFH ** 0.381 /
      (18.93 *
        ((((upstreamPSI + 14.7) ** 2 - (downstreamPSI + 14.7) ** 2) * Y) /
          (cr * pipeLengthFT)) **
          0.206)
    );
  }

  static sizeGasPipeInside(
    inputRateMJH: number,
    pipeLengthM: number,
    startKPA: number,
    endKPA: number,
    type: GasType,
  ) {
    const inputRateCFH =
      type === GasType.NATURAL_GAS
        ? inputRateMJH * 0.94782
        : inputRateMJH / 2.62;
    const pipeLengthFT = pipeLengthM * 3.28084;
    const headLossIN = (startKPA - endKPA) * 0.10199773339984054 * 39.3701;
    const upstreamPSI = startKPA * 0.145038;
    const downstreamPSI = endKPA * 0.145038;

    if ((startKPA + endKPA) / 2 >= 10.3) {
      return (
        this.getBestInsideDiameterLarge(
          inputRateCFH,
          pipeLengthFT,
          upstreamPSI,
          downstreamPSI,
          type,
        ) *
        2.54 *
        10
      );
    } else {
      return (
        this.getBestInsideDiameterSmall(
          inputRateCFH,
          pipeLengthFT,
          headLossIN,
          type,
        ) *
        2.54 *
        10
      );
    }
  }

  @TraceCalculation("Clcaulating gas pipe velocity", (_1, p, _2) => [p.uid])
  static getGasVelocityRealMs(
    context: CalculationEngine,
    pipe: PipeConduitEntity,
    type: GasType,
  ): { mjh: number; ms: number } | undefined {
    const calculation = context.globalStore.getOrCreateCalculation(pipe);
    if (calculation.psdUnits) {
      if (calculation.psdUnits.gasMJH) {
        let nDwellings = 0;
        let dwellingMJH = 0;
        let prediversifiedMJH = calculation.psdUnits.gasDiversifiedMJH;
        let highestSingularMJH = calculation.psdUnits.gasHighestMJH;
        if (calculation.psdProfile) {
          for (const c of calculation.psdProfile.values()) {
            nDwellings += c.dwellings;
            dwellingMJH += c.gasUndiversifiedMJH;
          }
        }

        const dwellingDiversification =
          lowerBoundTable(context.catalog.gasDiversification, nDwellings) ||
          upperBoundTable(context.catalog.gasDiversification, nDwellings) ||
          0;
        const diversifiedMJH = Math.max(
          highestSingularMJH,
          dwellingMJH * dwellingDiversification + prediversifiedMJH,
        );

        let m3h = 0;
        switch (type) {
          case GasType.NATURAL_GAS: {
            // http://agnatural.pt/documentos/ver/natural-gas-conversion-guide_cb4f0ccd80ccaf88ca5ec336a38600867db5aaf1.pdf
            m3h = diversifiedMJH / 38.7;
            break;
          }
          case GasType.LPG: {
            // https://www.elgas.com.au/blog/389-lpg-conversions-kg-litres-mj-kwh-and-m3

            const liters = diversifiedMJH * 0.042;
            const m3 = liters / 3.7;
            m3h = m3;
            break;
          }
          default:
            assertUnreachable(type);
        }

        const LS = m3h * 0.2777777777777777;

        const res =
          (4000 * LS) /
          (Math.PI *
            parseCatalogNumberExact(calculation.realInternalDiameterMM)! ** 2);

        return {
          mjh: diversifiedMJH,
          ms: res,
        };
      }
    }
  }

  static getGasEntityPressureDrop(entity: LoadNodeEntity): number {
    return entity.node.type !== NodeType.FIRE &&
      entity.node.type !== NodeType.VENTILATION
      ? entity.node.gasPressureKPA!
      : 0;
  }
}
