import { DrawingContext } from "../../../src/htmlcanvas/lib/types";
import { MouseMoveResult } from "../../../src/htmlcanvas/types";
import { ToolConfig, ToolTypes } from "../../../src/store/tools/types";
import CanvasContext from "./canvas-context";

export interface ToolHandler {
  type: ToolTypes;
  config: ToolConfig;
  onMouseDown: (event: MouseEvent, context: CanvasContext) => boolean | number;
  onMouseMove: (event: MouseEvent, context: CanvasContext) => MouseMoveResult;
  onMouseUp: (event: MouseEvent, context: CanvasContext) => boolean;
  onMouseScroll: (event: MouseEvent, context: CanvasContext) => boolean;

  finish: (
    context: CanvasContext,
    interrupted: boolean,
    displaced: boolean,
  ) => void;
  dispose: () => void;

  draw(context: DrawingContext): void;
  beforeDraw(context: DrawingContext): void;
  getTitle(): string;
}

export const DEFAULT_TOOL: ToolConfig = {
  name: "default",
  defaultCursor: "Default",
  focusSelectedObject: false,
  icon: "mouse-pointer",
  modesEnabled: true,
  modesVisible: true,
  escapeVisible: false,
  paperSnapshotTopBar: false,
  text: "Default Mode",
  tooltip: "Default",
  propertiesEnabled: true,
  propertiesVisible: true,
  toolbarEnabled: true,
  toolbarVisible: true,
};
