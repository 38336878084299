// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from "../flatbuffers/flatbuffers";

export class ViewPortRecord
  implements flatbuffers.IUnpackableObject<ViewPortRecordT>
{
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): ViewPortRecord {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsViewPortRecord(
    bb: flatbuffers.ByteBuffer,
    obj?: ViewPortRecord,
  ): ViewPortRecord {
    return (obj || new ViewPortRecord()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsViewPortRecord(
    bb: flatbuffers.ByteBuffer,
    obj?: ViewPortRecord,
  ): ViewPortRecord {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new ViewPortRecord()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  timestamp(): bigint {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.readUint64(this.bb_pos + offset) : BigInt("0");
  }

  userName(): string | null;
  userName(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
  userName(optionalEncoding?: any): string | Uint8Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset
      ? this.bb!.__string(this.bb_pos + offset, optionalEncoding)
      : null;
  }

  documentId(): number {
    const offset = this.bb!.__offset(this.bb_pos, 8);
    return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
  }

  previousOrderIndex(): number {
    const offset = this.bb!.__offset(this.bb_pos, 10);
    return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
  }

  surfaceToWorldA(): number {
    const offset = this.bb!.__offset(this.bb_pos, 12);
    return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
  }

  surfaceToWorldB(): number {
    const offset = this.bb!.__offset(this.bb_pos, 14);
    return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
  }

  surfaceToWorldC(): number {
    const offset = this.bb!.__offset(this.bb_pos, 16);
    return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
  }

  surfaceToWorldD(): number {
    const offset = this.bb!.__offset(this.bb_pos, 18);
    return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
  }

  surfaceToWorldE(): number {
    const offset = this.bb!.__offset(this.bb_pos, 20);
    return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
  }

  surfaceToWorldF(): number {
    const offset = this.bb!.__offset(this.bb_pos, 22);
    return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
  }

  width(): number {
    const offset = this.bb!.__offset(this.bb_pos, 24);
    return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
  }

  height(): number {
    const offset = this.bb!.__offset(this.bb_pos, 26);
    return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
  }

  screenScale(): number {
    const offset = this.bb!.__offset(this.bb_pos, 28);
    return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
  }

  id(): number | null {
    const offset = this.bb!.__offset(this.bb_pos, 30);
    return offset ? this.bb!.readUint32(this.bb_pos + offset) : null;
  }

  static startViewPortRecord(builder: flatbuffers.Builder) {
    builder.startObject(14);
  }

  static addTimestamp(builder: flatbuffers.Builder, timestamp: bigint) {
    builder.addFieldInt64(0, timestamp, BigInt("0"));
  }

  static addUserName(
    builder: flatbuffers.Builder,
    userNameOffset: flatbuffers.Offset,
  ) {
    builder.addFieldOffset(1, userNameOffset, 0);
  }

  static addDocumentId(builder: flatbuffers.Builder, documentId: number) {
    builder.addFieldInt32(2, documentId, 0);
  }

  static addPreviousOrderIndex(
    builder: flatbuffers.Builder,
    previousOrderIndex: number,
  ) {
    builder.addFieldInt32(3, previousOrderIndex, 0);
  }

  static addSurfaceToWorldA(
    builder: flatbuffers.Builder,
    surfaceToWorldA: number,
  ) {
    builder.addFieldInt32(4, surfaceToWorldA, 0);
  }

  static addSurfaceToWorldB(
    builder: flatbuffers.Builder,
    surfaceToWorldB: number,
  ) {
    builder.addFieldInt32(5, surfaceToWorldB, 0);
  }

  static addSurfaceToWorldC(
    builder: flatbuffers.Builder,
    surfaceToWorldC: number,
  ) {
    builder.addFieldInt32(6, surfaceToWorldC, 0);
  }

  static addSurfaceToWorldD(
    builder: flatbuffers.Builder,
    surfaceToWorldD: number,
  ) {
    builder.addFieldInt32(7, surfaceToWorldD, 0);
  }

  static addSurfaceToWorldE(
    builder: flatbuffers.Builder,
    surfaceToWorldE: number,
  ) {
    builder.addFieldInt32(8, surfaceToWorldE, 0);
  }

  static addSurfaceToWorldF(
    builder: flatbuffers.Builder,
    surfaceToWorldF: number,
  ) {
    builder.addFieldInt32(9, surfaceToWorldF, 0);
  }

  static addWidth(builder: flatbuffers.Builder, width: number) {
    builder.addFieldInt32(10, width, 0);
  }

  static addHeight(builder: flatbuffers.Builder, height: number) {
    builder.addFieldInt32(11, height, 0);
  }

  static addScreenScale(builder: flatbuffers.Builder, screenScale: number) {
    builder.addFieldFloat32(12, screenScale, 0.0);
  }

  static addId(builder: flatbuffers.Builder, id: number) {
    builder.addFieldInt32(13, id, null);
  }

  static endViewPortRecord(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    builder.requiredField(offset, 6); // user_name
    return offset;
  }

  static createViewPortRecord(
    builder: flatbuffers.Builder,
    timestamp: bigint,
    userNameOffset: flatbuffers.Offset,
    documentId: number,
    previousOrderIndex: number,
    surfaceToWorldA: number,
    surfaceToWorldB: number,
    surfaceToWorldC: number,
    surfaceToWorldD: number,
    surfaceToWorldE: number,
    surfaceToWorldF: number,
    width: number,
    height: number,
    screenScale: number,
    id: number | null,
  ): flatbuffers.Offset {
    ViewPortRecord.startViewPortRecord(builder);
    ViewPortRecord.addTimestamp(builder, timestamp);
    ViewPortRecord.addUserName(builder, userNameOffset);
    ViewPortRecord.addDocumentId(builder, documentId);
    ViewPortRecord.addPreviousOrderIndex(builder, previousOrderIndex);
    ViewPortRecord.addSurfaceToWorldA(builder, surfaceToWorldA);
    ViewPortRecord.addSurfaceToWorldB(builder, surfaceToWorldB);
    ViewPortRecord.addSurfaceToWorldC(builder, surfaceToWorldC);
    ViewPortRecord.addSurfaceToWorldD(builder, surfaceToWorldD);
    ViewPortRecord.addSurfaceToWorldE(builder, surfaceToWorldE);
    ViewPortRecord.addSurfaceToWorldF(builder, surfaceToWorldF);
    ViewPortRecord.addWidth(builder, width);
    ViewPortRecord.addHeight(builder, height);
    ViewPortRecord.addScreenScale(builder, screenScale);
    if (id !== null) ViewPortRecord.addId(builder, id);
    return ViewPortRecord.endViewPortRecord(builder);
  }

  unpack(): ViewPortRecordT {
    return new ViewPortRecordT(
      this.timestamp(),
      this.userName(),
      this.documentId(),
      this.previousOrderIndex(),
      this.surfaceToWorldA(),
      this.surfaceToWorldB(),
      this.surfaceToWorldC(),
      this.surfaceToWorldD(),
      this.surfaceToWorldE(),
      this.surfaceToWorldF(),
      this.width(),
      this.height(),
      this.screenScale(),
      this.id(),
    );
  }

  unpackTo(_o: ViewPortRecordT): void {
    _o.timestamp = this.timestamp();
    _o.userName = this.userName();
    _o.documentId = this.documentId();
    _o.previousOrderIndex = this.previousOrderIndex();
    _o.surfaceToWorldA = this.surfaceToWorldA();
    _o.surfaceToWorldB = this.surfaceToWorldB();
    _o.surfaceToWorldC = this.surfaceToWorldC();
    _o.surfaceToWorldD = this.surfaceToWorldD();
    _o.surfaceToWorldE = this.surfaceToWorldE();
    _o.surfaceToWorldF = this.surfaceToWorldF();
    _o.width = this.width();
    _o.height = this.height();
    _o.screenScale = this.screenScale();
    _o.id = this.id();
  }
}

export class ViewPortRecordT implements flatbuffers.IGeneratedObject {
  _type = "ViewPortRecordT" as const;
  constructor(
    public timestamp: bigint = BigInt("0"),
    public userName: string | Uint8Array | null = null,
    public documentId: number = 0,
    public previousOrderIndex: number = 0,
    public surfaceToWorldA: number = 0,
    public surfaceToWorldB: number = 0,
    public surfaceToWorldC: number = 0,
    public surfaceToWorldD: number = 0,
    public surfaceToWorldE: number = 0,
    public surfaceToWorldF: number = 0,
    public width: number = 0,
    public height: number = 0,
    public screenScale: number = 0.0,
    public id: number | null = null,
  ) {}

  pack(builder: flatbuffers.Builder): flatbuffers.Offset {
    const userName =
      this.userName !== null ? builder.createString(this.userName!) : 0;

    return ViewPortRecord.createViewPortRecord(
      builder,
      this.timestamp,
      userName,
      this.documentId,
      this.previousOrderIndex,
      this.surfaceToWorldA,
      this.surfaceToWorldB,
      this.surfaceToWorldC,
      this.surfaceToWorldD,
      this.surfaceToWorldE,
      this.surfaceToWorldF,
      this.width,
      this.height,
      this.screenScale,
      this.id,
    );
  }
}
