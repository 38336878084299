import { Choice, assertUnreachable, cloneSimple } from "../../../../lib/utils";
import { CoreContext, DrawingLayout } from "../../../calculations/types";
import CoreAnnotation from "../../../coreObjects/coreAnnotation";
import { FieldType, PropertyField } from "../property-field";
import { CenteredEntity } from "../simple-entities";
import { EntityType } from "../types";
import {
  AnnoConcrete,
  AnnoType,
  AnnotationBoxEntity,
} from "./annotation-types";
import { findVertexAFromBox } from "./utils";

export interface AnnotationEntityBase extends CenteredEntity {
  type: EntityType.ANNOTATION;
  drawingLayout: DrawingLayout | null;
  annoType: AnnoType;
  anno: AnnoConcrete;
}

export type AnnotationEntity = AnnotationBoxEntity;

export function fillDefaultAnnotationFields(
  context: CoreContext,
  entity: AnnotationEntity,
) {
  let result = cloneSimple(entity);

  switch (result.annoType) {
    case "box":
      if (!result.anno.heightMM) {
        result.anno.heightMM = 10;
      }
      if (!result.anno.widthMM) {
        result.anno.widthMM = 10;
      }
      if (!result.anno.color) {
        result.anno.color = {
          hex: "#000000",
        };
      }
      break;
    default:
      assertUnreachable(result.annoType);
  }

  return result;
}

export function makeAnnotationFields(
  context: CoreContext,
  entity: AnnotationEntity,
): PropertyField[] {
  const box = context.globalStore.get(entity.uid) as CoreAnnotation;

  const vertexA = findVertexAFromBox(context, box);
  const drawingLayoutChoices: Choice<DrawingLayout | null>[] = [
    { name: "All", key: null },
    {
      name: "Mechanical",
      key: "mechanical",
    },
    {
      name: "Pressure",
      key: "pressure",
    },
    {
      name: "Drainage",
      key: "drainage",
    },
    {
      name: "Ventilation",
      key: "ventilation",
    },
  ];

  switch (entity.annoType) {
    case "box":
      const fields: PropertyField[] = [
        {
          property: "anno.color",
          title: "Color",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Color,
          params: null,
          multiFieldId: "anno-color",
        },
        {
          property: "drawingLayout",
          title: "Visibility",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Choice,
          multiFieldId: "drawingLayout",
          hint: "This is the drawing layout that this annotation will be visible in",
          params: {
            choices: drawingLayoutChoices,
          },
        },
        {
          property: "globalFontSize",
          title: "Global Font Size",
          hasDefault: false,
          isCalculated: false,
          slot: true,
          type: FieldType.Number,
          params: {
            min: null,
            max: null,
          },
          multiFieldId: "globalFontSize",
          hint: "This is the font size for all annotations",
        },
      ];

      if (vertexA) {
        fields.push({
          property: "arrowPosition",
          title: "Arrow Position",
          hasDefault: false,
          isCalculated: false,
          slot: true,
          type: FieldType.Choice,
          params: {
            choices: [],
          },
          multiFieldId: "arrowPosition",
        });
      }

      return fields;
  }
}
