import { immutableAppend } from "../../../../common/src/lib/utils";
import { ToolEventRecordT } from "../../../../flatbuffers/generated/tool-event-record";
import {
  ActiveReplayState,
  ReplayState,
} from "../../../src/store/document/types";

export const stringifyToolEvent = (e: ToolEventRecordT) => {
  if (!e.toolName) return `${e.userName} isn't using any tool`;
  return `${e.userName} is using the '${e.toolName}' tool`;
};

export const renderToolEventRecord = async (
  event: ToolEventRecordT,
  replayState: ActiveReplayState,
) => {
  const newReplayState: ReplayState = {
    ...replayState,
    eventsToLog: immutableAppend(replayState.eventsToLog, event),
  };
  return newReplayState;
};
