import Flatten from "@flatten-js/core";
import { RoomCalculation } from "../../../document/calculations-objects/room-calculation";
import { CoilCoord3D } from "../../underfloor-heating/coil-coords";
import { UFH_BELOW_FLOOR_HEIGHT_MM } from "../../underfloor-heating/consts";
import { LoopSite } from "../../underfloor-heating/underfloor-heating";
import {
  IGNORE_COLLISION_EPS,
  IntentionNode,
  LOOP_WALL_SPACING_MULT,
  LoopGenerator,
  foreachIntention,
} from "./loop-generator";
import { LoopObstacle } from "./loop-obstacles";
import { SerpentineIntentionNode } from "./serpentine-generator";
import {
  SpiralExtension,
  SpiralGenerator,
  serializeSpiralExtension,
} from "./spiral-generator";

const MAX_LINEAR_EXTENSION_ITERATIONS = 20;
const MIN_LINEAR_WALL_FOLLOW_LENGTH_RADII = 2;

const serializeLinearExtension = serializeSpiralExtension;

export type LinearExtension = SpiralExtension & {
  wallSide: "cw" | "ccw";
};

export class LinearGenerator {
  static generateLinearLoop(
    obstacles: LoopObstacle[],
    site: LoopSite,
    transitSpacingMM: number,
    loopSpacingMM: number,
    normal: Flatten.Vector,
    center: Flatten.Point,
    fromManifold: CoilCoord3D[],
    toManifold: CoilCoord3D[],
    debugObj?: RoomCalculation["debug"],
  ) {
    LoopGenerator.heatedAreaEntranceHack(site, normal, center);

    const { intentionTree, debug } = this.generateLinearIntentions(
      obstacles,
      Flatten.point(center.x, center.y),
      Flatten.vector(normal.x, normal.y),
      loopSpacingMM,
      transitSpacingMM,
      site.underfloorHeating.loopDirectionDEG,
      debugObj,
    );

    let i = 0;
    foreachIntention(intentionTree, (from, to) => {
      i += 1;
      debugObj?.push({
        color: "#FF77" + ((i * 0x11) % 0xff).toString(16).padStart(2, "0"),
        chain: [from.coord, to.coord],
        text: i.toString(),
        dash: [1, i % 5],
        role: "loop",
      });
    });

    const incidentLines: Flatten.Line[] =
      LoopGenerator.getIncidentLines(intentionTree);

    // TODO: Abstract together these postprocessing steps across
    // the three loops which are relatively similar, but will do after
    // merging in case of conflicts with David's detailed loop changes.

    // Remove consecutive parallel lines
    for (let i = incidentLines.length - 1; i > 0; i--) {
      if (incidentLines[i].parallelTo(incidentLines[i - 1])) {
        incidentLines.splice(i, 1);
      }
    }

    let roomLoop: CoilCoord3D[] = [];

    for (let i = 0; i < incidentLines.length - 1; i++) {
      const intersection = incidentLines[i].intersect(incidentLines[i + 1]);

      if (intersection.length > 0) {
        roomLoop.push({
          x: intersection[0].x,
          y: intersection[0].y,
          z: UFH_BELOW_FLOOR_HEIGHT_MM,
          turnRadiusMM: loopSpacingMM / 2,
        });
      }
    }

    // remove duplicates
    for (let i = roomLoop.length - 1; i > 0; i--) {
      const curr = roomLoop[i];
      const prev = roomLoop[i - 1];
      const currP = Flatten.point(curr.x, curr.y);
      const prevP = Flatten.point(prev.x, prev.y);

      if (currP.distanceTo(prevP)[0] < 10) {
        roomLoop.splice(i, 1);
      }
      if (currP.distanceTo(prevP)[0] < 50) {
        curr.maxRadiusMM = 10;
        curr.turnRadiusMM = 10;
        prev.maxRadiusMM = 10;
        prev.turnRadiusMM = 10;
      }
    }

    return LoopGenerator.smoothOutWholeLoop(
      {
        roomLoop,
        toManifold,
        fromManifold,
      },
      {
        minRadiusMM: site.underfloorHeating.minBendRadiusMM!,
        avoidCenterBulb: true,
        loopSpacingMM,
        correctLongKnots: true,
      },
    );
  }

  static generateLinearIntentions(
    obstacles: LoopObstacle[],
    center: Flatten.Point,
    normal: Flatten.Vector,
    loopSpacingMM: number,
    transitSpacingMM: number,
    loopDirectionDEG: number | null,
    debugObj?: RoomCalculation["debug"],
  ): {
    intentionTree: IntentionNode;
    debug: {
      color: string;
      chain: Flatten.Point[];
    }[];
  } {
    // Follow wall initially. Both directions need to be covered
    // so follow whichever one.

    const { directionVector, paths } =
      LoopGenerator.followWallBothSidesFromStart(
        obstacles,
        center,
        normal,
        loopSpacingMM,
        transitSpacingMM,
        loopDirectionDEG,
        "angle",
        debugObj,
      );

    // We want to span both ways

    const intentionTree: SerpentineIntentionNode = {
      coord: center,
      state: "straight",
      next: [],
      spacingMM: transitSpacingMM,
    };

    //     const longestPath = paths.sort(
    //       (a, b) =>
    //         b.lengthMM +
    //         (b.startOffsetMM ?? 0) -
    //         a.lengthMM -
    //         (a.startOffsetMM ?? 0),
    //     )[0];
    //
    //     const ccwPerpVector = directionVector.rotate90CCW().normalize();
    //     let furtherestDistCCWMM = 0;
    //     for (const point of longestPath.path) {
    //       const vec = Flatten.vector(center, point);
    //       const dist = vec.dot(ccwPerpVector);
    //       if (Math.abs(dist) > Math.abs(furtherestDistCCWMM)) {
    //         furtherestDistCCWMM = dist;
    //       }
    //     }

    const ccwPerpVector = directionVector.rotate90CCW().normalize();
    const dists: number[] = [];
    // There should be at most 2 paths
    for (const path of paths) {
      let furtherestDistCCWMM = 0;
      for (const point of path.path) {
        const vec = Flatten.vector(center, point);
        const dist = vec.dot(ccwPerpVector);
        if (Math.abs(dist) > Math.abs(furtherestDistCCWMM)) {
          furtherestDistCCWMM = dist;
        }
      }
      dists.push(furtherestDistCCWMM);
    }

    while (dists.length < 2) {
      dists.push(0);
    }
    const centerDistCCWMM = (dists[0] + dists[1]) / 2;

    // Step 1. Seed it.
    // We just need to make one path left and one path right before using
    // the extension system to draw more candidates and thus complete the loop.

    const alignmentPoint = center.translate(
      ccwPerpVector.multiply(centerDistCCWMM),
    );

    const angle = normal.angleTo(directionVector);

    const extraDebug: {
      color: string;
      chain: Flatten.Point[];
    }[] = [];

    const pathsByOffset = paths.sort(
      (a, b) =>
        (a.startOffsetMM ?? loopSpacingMM * LOOP_WALL_SPACING_MULT) -
        (b.startOffsetMM ?? loopSpacingMM * LOOP_WALL_SPACING_MULT),
    );

    let lastIntention: SerpentineIntentionNode = intentionTree;
    let lastOffset = -Infinity;

    const intentionOfPath = new Map<
      (typeof paths)[0],
      SerpentineIntentionNode
    >();

    const parentIntentionOfPath = new Map<
      (typeof paths)[0],
      SerpentineIntentionNode
    >();

    lastIntention = intentionTree;
    for (const path of pathsByOffset) {
      const thisOffset =
        path.startOffsetMM ?? loopSpacingMM * LOOP_WALL_SPACING_MULT;
      if (thisOffset > lastOffset + IGNORE_COLLISION_EPS) {
        const thisIntention: SerpentineIntentionNode = {
          coord: center.translate(normal.multiply(thisOffset)),
          state: "perimeter",
          next: [],
          spacingMM: transitSpacingMM,
        };
        lastIntention.next.push(thisIntention);
        parentIntentionOfPath.set(path, lastIntention);
        lastIntention = thisIntention;
        lastOffset = thisOffset;
      }
      intentionOfPath.set(path, lastIntention);
    }

    for (const path of pathsByOffset) {
      const tip = intentionOfPath.get(path)!;
      const parent = parentIntentionOfPath.get(path);
      this.generateLinearPart(
        obstacles,
        tip,
        path.side === "cw" ? normal.rotate90CW() : normal.rotate90CCW(),
        path.side,
        loopSpacingMM,
        transitSpacingMM,
        directionVector,
        parent ? [parent] : [],
        alignmentPoint,
        true,
      );
    }

    // Step 2. Extend it.

    const seenExtensions = new Set<string>();

    const extensions: LinearExtension[] = [];
    const compareExtensions = (a: LinearExtension, b: LinearExtension) => {
      /* we no longer record shavedLength
      if (a.shavedLength !== b.shavedLength) {
        return a.shavedLength - b.shavedLength;
      }
        */
      // if (a.intentionNumber !== b.intentionNumber) {
      //   // Higher is better - we want to backtrack and start close to the end.
      //   return b.intentionNumber - a.intentionNumber;
      // }
      // Further from the end of the intention, the better.
      const aDist = a.intention.coord.distanceTo(a.startCoord)[0];
      const bDist = b.intention.coord.distanceTo(b.startCoord)[0];

      return bDist - aDist;
    };

    for (let i = 0; i < 1000; i++) {
      extensions.splice(0, extensions.length);
      extensions.push(
        ...this.getLinearExtensions(
          intentionTree,
          obstacles,
          loopSpacingMM,
        ).filter((ext) => !seenExtensions.has(serializeLinearExtension(ext))),
      );

      extensions.sort(compareExtensions);

      if (i >= MAX_LINEAR_EXTENSION_ITERATIONS) {
        break;
      }

      for (const ext of extensions) {
        const newIntention: IntentionNode = {
          coord: ext.intention.coord,
          next: ext.intention.next,
          spacingMM: loopSpacingMM,
        };
        ext.intention.coord = ext.startCoord;
        ext.intention.next = [newIntention];

        const generated = this.generateLinearPart(
          obstacles,
          ext.intention,
          ext.direction,
          ext.wallSide,
          loopSpacingMM,
          transitSpacingMM,
          directionVector,
          [],
        );

        if (generated) {
          seenExtensions.add(serializeLinearExtension(ext));
          break;
        } else {
          // backtrack the intention split
          ext.intention.coord = ext.startCoord;
          ext.intention.next = newIntention.next;
        }
      }
    }

    return {
      intentionTree: LoopGenerator.sanitizeIntentionTree(intentionTree),
      debug: extraDebug
        .concat(
          extensions.map((ext) => ({
            color: "#777700",
            chain: [
              ext.startCoord,
              ext.startCoord.translate(ext.direction.normalize().multiply(200)),
            ],
            role: "extension",
          })),
        )
        .concat([
          {
            color: "#FF00FF",
            chain: [center, center.translate(directionVector.multiply(200))],
          },
        ])
        .concat(
          paths.map((path) => ({
            color: "#33AA33",
            chain: path.path,
            role: "wall-path",
          })),
        )
        .concat(
          obstacles.map((obs) => ({
            color: "#FF0000",
            chain: [obs.segment.start, obs.segment.end],
            role: "obstacle",
          })),
        )
        .concat(
          debugObj
            ? debugObj.map((d) => ({
                color: d.color,
                chain: d.chain.map((c) => Flatten.point(c.x, c.y)),
                role: d.role,
              }))
            : [],
        ),
    };
  }

  static generateLinearPart(
    obstacles: LoopObstacle[],
    startIntention: IntentionNode,
    startDirection: Flatten.Vector,
    wallSide: "cw" | "ccw",
    loopSpacingMM: number,
    transitSpacingMM: number,
    directionVector: Flatten.Vector,

    // Paths before the specified intention point (which is used to start the wall run)
    // that are to be included in the vertical fill.
    prependWallPath: IntentionNode[],

    // Special case for initial loop - the entrance can be in between two
    // rungs in the optimal arrangement so an offset is used here left and
    // right of the entrance to create the desired rung placement.
    alignmentPoint?: Flatten.Point,
    isInitial: boolean = false,
  ) {
    directionVector = directionVector.normalize();
    startDirection = startDirection.normalize();
    if (!alignmentPoint) {
      alignmentPoint = startIntention.coord;
    }

    const fillVertically = (intention: IntentionNode) => {
      let generated = 0;

      for (const direction of [1, -1]) {
        const result = LoopGenerator.traceToEnd({
          curr: intention.coord,
          radius: loopSpacingMM,
          obstacles,
          direction: directionVector.multiply(direction),
          hitLenienceMM: loopSpacingMM * 0.51,
          onlyBlocks: true,
          minLengthMM: isInitial ? loopSpacingMM * 0.5 : loopSpacingMM,
        });
        if (result && result.type !== "miss") {
          const newIntention: IntentionNode = {
            coord: result.point,
            spacingMM: loopSpacingMM,
            next: [],
          };
          intention.next.push(newIntention);
          generated += result.length;
          obstacles.push({
            segment: Flatten.segment(intention.coord, newIntention.coord),
            radiusMM: loopSpacingMM,
          });
        }
      }
      return generated;
    };

    const wallPath = LoopGenerator.followWall(
      obstacles,
      startIntention.coord,
      startDirection,
      loopSpacingMM,
      directionVector,
      wallSide,
      "angle",
    );

    const intentionJumps: [IntentionNode, IntentionNode][] = [];
    let wallFollowPathLengthMM = wallPath.lengthMM;
    let wallFollowSweepedSide = wallPath.sweepedSide;
    for (let i = 0; i < prependWallPath.length - 1; i++) {
      intentionJumps.push([prependWallPath[i], prependWallPath[i + 1]]);
    }

    if (prependWallPath.length > 0) {
      intentionJumps.push([
        prependWallPath[prependWallPath.length - 1],
        startIntention,
      ]);
      if (!wallFollowSweepedSide) {
        let cutoffPerp = directionVector.rotate90CCW();

        if (startDirection.dot(cutoffPerp) < 0) {
          cutoffPerp = cutoffPerp.multiply(-1);
          wallFollowSweepedSide = "cw";
        } else {
          wallFollowSweepedSide = "ccw";
        }
      }
    }

    for (const [from, to] of intentionJumps) {
      wallFollowPathLengthMM += from.coord.distanceTo(to.coord)[0];
    }

    if (
      !wallFollowPathLengthMM ||
      !wallFollowSweepedSide ||
      wallFollowPathLengthMM <
        MIN_LINEAR_WALL_FOLLOW_LENGTH_RADII * loopSpacingMM
    ) {
      return fillVertically(startIntention);
    }

    const sweepVector =
      wallFollowSweepedSide === "cw"
        ? directionVector.rotate90CW()
        : directionVector.rotate90CCW();

    let generatedMM = 0;
    let currIntention = startIntention;
    for (const path of wallPath.path) {
      const newIntention: IntentionNode = {
        coord: path,
        next: [],
        spacingMM: loopSpacingMM,
      };
      generatedMM += path.distanceTo(currIntention.coord)[0];
      currIntention.next.push(newIntention);
      if (path.distanceTo(currIntention.coord)[0] > 0) {
        obstacles.push({
          segment: Flatten.segment(currIntention.coord, newIntention.coord),
          radiusMM: loopSpacingMM,
        });
      }
      currIntention = newIntention;
    }

    const fullOffsetMM = Flatten.vector(
      startIntention.coord,
      alignmentPoint,
    ).dot(sweepVector);

    const intentionSpacingMM = loopSpacingMM * 2;
    const offsetMM =
      ((fullOffsetMM % intentionSpacingMM) + intentionSpacingMM) %
      intentionSpacingMM;

    // These 2 variables are helping to remove the "deadleg" bit at the end.
    // as any needed to avoid type issues (unfortunately)
    let lastLeafIntention: IntentionNode | null = null as any;
    let lastLeafParent: IntentionNode | null = null as any;

    foreachIntention(startIntention, (from, to) => {
      intentionJumps.push([from, to]);
    });

    const fillStart =
      prependWallPath.length > 0 ? prependWallPath[0] : startIntention;

    let fills = 0;
    for (
      let currOffsetMM = offsetMM;
      currOffsetMM <= wallFollowPathLengthMM + IGNORE_COLLISION_EPS;
      currOffsetMM += intentionSpacingMM
    ) {
      const p1 = fillStart.coord.translate(sweepVector.multiply(currOffsetMM));

      const sweepLine = Flatten.line(p1, p1.translate(directionVector));
      let found = false;

      // We need to iterate these intentionJumps (rather than foreach intention)
      // because the given prependWallPath may be a tree with existing other branches
      // that we don't want to follow.
      for (let i = 0; i < intentionJumps.length; i++) {
        const [from, to] = intentionJumps[i];
        if (from.coord.distanceTo(to.coord)[0] < IGNORE_COLLISION_EPS) {
          // Intention too short
          continue;
        }
        const line = Flatten.line(from.coord, to.coord);
        const intersection = line.intersect(sweepLine);

        if (intersection.length < 1) {
          continue;
        }

        const ixVec = Flatten.vector(from.coord, intersection[0]);
        const myVec = Flatten.vector(from.coord, to.coord);
        const ixDot = myVec.normalize().dot(ixVec);

        if (
          ixDot < -IGNORE_COLLISION_EPS ||
          ixDot > myVec.length + IGNORE_COLLISION_EPS
        ) {
          continue;
        }
        found = true;

        let usedIntention: IntentionNode | null = null;
        if (ixDot <= IGNORE_COLLISION_EPS) {
          usedIntention = from;
          lastLeafIntention = to;
          lastLeafParent = from;
        } else if (ixDot >= myVec.length - IGNORE_COLLISION_EPS) {
          usedIntention = to;
          lastLeafIntention = null;
          lastLeafParent = null;
        } else {
          const newIntention: IntentionNode = {
            coord: intersection[0],
            spacingMM: loopSpacingMM,
            next: [to],
          };

          intentionJumps[i] = [from, newIntention];
          intentionJumps.splice(i + 1, 0, [newIntention, to]);

          lastLeafIntention = to;
          lastLeafParent = newIntention;
          const idx = from.next.indexOf(to);
          from.next.splice(idx, 1, newIntention);
          usedIntention = newIntention;
        }

        const thisGenerated = fillVertically(usedIntention);
        generatedMM += thisGenerated;
        if (thisGenerated > 0) {
          fills++;
        }
        break;
      }

      if (!found && fills === 0) {
        generatedMM += fillVertically(startIntention);
      }
    }

    // Trim trailing bit
    if (lastLeafIntention && lastLeafParent && fills > 0) {
      const idx = lastLeafParent.next.indexOf(lastLeafIntention);
      lastLeafParent.next.splice(idx, 1);
    }

    return generatedMM;
  }

  static getLinearExtensions(
    intentionNode: IntentionNode,
    obstacles: LoopObstacle[],
    loopSpacingMM: number,
  ): LinearExtension[] {
    const cwExtensions = SpiralGenerator.getSpiralExtensions(
      intentionNode,
      obstacles,
      loopSpacingMM,
      "cw",
    );
    const ccwExtensions = SpiralGenerator.getSpiralExtensions(
      intentionNode,
      obstacles,
      loopSpacingMM,
      "ccw",
    );

    return [
      // Reverse cw<=>ccw is not a mistake.
      ...cwExtensions.map((ext) => ({ ...ext, wallSide: "cw" as const })),
      ...ccwExtensions.map((ext) => ({ ...ext, wallSide: "ccw" as const })),
    ];
  }
}
