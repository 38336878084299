import { PumpData, PumpManufacturer } from "../../types";

export const DABBoosterManufacturer: PumpManufacturer = {
  name: "DAB",
  abbreviation: "DAB",
  uid: "dab",
  priceTableName: "Pump",
  componentName: "DAB",
  displayOnWizard: true,
};

export const DABBoosterPumps: PumpData = {
  type: "sparse-table",
  table: {
    duty: {
      200: {
        1.65: "E.SYBOX",
        3.9: "ESYBOX MAX 60/120",
        4.5: "ESYBOX MAX 85/120",
      },
      400: {
        1.17: "E.SYBOX",
        3.0: "ESYBOX MAX 60/120",
        3.7: "ESYBOX MAX 85/120",
      },
      600: {
        0.33: "E.SYBOX",
        2.0: "ESYBOX MAX 60/120",
        2.8: "ESYBOX MAX 85/120",
      },
      676: {
        1.5: "ESYBOX MAX 60/120",
        2.1: "ESYBOX MAX 85/120",
      },
      940: {
        1.83: "ESYBOX MAX 85/120",
      },
    },

    "duty-assist": {
      200: {
        3.33: "E.SYTWIN",
        8: "2 ESYBOX MAX 60/120",
        8.5: "2 ESYBOX MAX 85/120",
      },
      400: {
        2.3: "E.SYTWIN",
        6: "2 ESYBOX MAX 60/120",
        6.5: "2 ESYBOX MAX 85/120",
      },
      600: {
        0.67: "E.SYTWIN",
        3.67: "2 ESYBOX MAX 60/120",
        5.7: "2 ESYBOX MAX 85/120",
      },
      676: {
        3.33: "2 ESYBOX MAX 60/120",
        5.1: "2 ESYBOX MAX 85/120",
      },
      940: {
        3.33: "2 ESYBOX MAX 85/120",
      },
    },

    "duty-standby": {
      200: {
        1.65: "E.SYTWIN",
        4: "2 ESYBOX MAX 60/120",
        4.4: "2 ESYBOX MAX 85/120",
      },
      400: {
        1.2: "E.SYTWIN",
        3: "2 ESYBOX MAX 60/120",
        3.45: "2 ESYBOX MAX 85/120",
      },
      600: {
        0.33: "E.SYTWIN",
        1.83: "2 ESYBOX MAX 60/120",
        2.75: "2 ESYBOX MAX 85/120",
      },
      676: {
        1.67: "2 ESYBOX MAX 60/120",
        2.55: "2 ESYBOX MAX 85/120",
      },
      940: {
        1.67: "2 ESYBOX MAX 85/120",
      },
    },

    "duty-assist-assist": {
      200: {
        12: "3 ESYBOX MAX 60/120",
        13: "3 ESYBOX MAX 85/120",
      },
      400: {
        9: "3 ESYBOX MAX 60/120",
        10.3: "3 ESYBOX MAX 85/120",
      },
      600: {
        6: "3 ESYBOX MAX 60/120",
        8.3: "3 ESYBOX MAX 85/120",
      },
      676: {
        5: "3 ESYBOX MAX 60/120",
        7.7: "3 ESYBOX MAX 85/120",
      },
      940: {
        5: "3 ESYBOX MAX 85/120",
      },
    },

    "duty-assist-standby": {
      200: {
        8: "3 ESYBOX MAX 60/120",
        8.5: "3 ESYBOX MAX 85/120",
      },
      400: {
        6: "3 ESYBOX MAX 60/120",
        6.9: "3 ESYBOX MAX 85/120",
      },
      600: {
        4: "3 ESYBOX MAX 60/120",
        5.5: "3 ESYBOX MAX 85/120",
      },
      676: {
        3.33: "3 ESYBOX MAX 60/120",
        5.1: "3 ESYBOX MAX 85/120",
      },
      940: {
        3.33: "3 ESYBOX MAX 85/120",
      },
    },

    "duty-assist-assist-assist": {
      200: {
        16: "4 ESYBOX MAX 60/120",
        17: "4 ESYBOX MAX 85/120",
      },
      400: {
        12: "4 ESYBOX MAX 60/120",
        13.8: "4 ESYBOX MAX 85/120",
      },
      600: {
        8: "4 ESYBOX MAX 60/120",
        10.9: "4 ESYBOX MAX 85/120",
      },
      676: {
        6.67: "4 ESYBOX MAX 60/120",
        10.2: "4 ESYBOX MAX 85/120",
      },
      940: {
        6.67: "4 ESYBOX MAX 85/120",
      },
    },

    "duty-assist-assist-standby": {
      200: {
        12: "4 ESYBOX MAX 60/120",
        12.5: "4 ESYBOX MAX 85/120",
      },
      400: {
        9: "4 ESYBOX MAX 60/120",
        10.3: "4 ESYBOX MAX 85/120",
      },
      600: {
        6: "4 ESYBOX MAX 60/120",
        8.2: "4 ESYBOX MAX 85/120",
      },
      676: {
        5: "4 ESYBOX MAX 60/120",
        7.6: "4 ESYBOX MAX 85/120",
      },
      940: {
        5: "4 ESYBOX MAX 85/120",
      },
    },
  },

  sizes: {
    "E.SYBOX": {
      heightMM: 263,
      widthMM: 352,
    },
    "ESYBOX MAX 60/120": {
      heightMM: 384,
      widthMM: 375,
    },
    "ESYBOX MAX 85/120": {
      heightMM: 384,
      widthMM: 375,
    },
    "E.SYTWIN": {
      heightMM: 752,
      widthMM: 358,
    },
    "2 ESYBOX MAX 60/120": {
      heightMM: 811,
      widthMM: 384,
    },
    "2 ESYBOX MAX 85/120": {
      heightMM: 811,
      widthMM: 384,
    },
    "3 ESYBOX MAX 60/120": {
      heightMM: 1250,
      widthMM: 384,
    },
    "3 ESYBOX MAX 85/120": {
      heightMM: 1250,
      widthMM: 384,
    },
    "4 ESYBOX MAX 60/120": {
      heightMM: 1692,
      widthMM: 384,
    },
    "4 ESYBOX MAX 85/120": {
      heightMM: 1692,
      widthMM: 384,
    },
  },
};
