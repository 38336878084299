import Flatten from "@flatten-js/core";
import { SeededRandom } from "./random";

export function placeContentInPolygon(polygon: Flatten.Polygon): {
  center: Flatten.Point;
  width: number;
  height: number;
} | null {
  const random = new SeededRandom(801);
  const pbox = polygon.box;
  const edges = Array.from(polygon.edges.values()) as Flatten.Edge[];

  const byLeft = (box: Flatten.Box, by: number) => {
    return new Flatten.Box(box.xmin - by, box.ymin, box.xmax, box.ymax);
  };
  const byRight = (box: Flatten.Box, by: number) => {
    return new Flatten.Box(box.xmin, box.ymin, box.xmax + by, box.ymax);
  };
  const byBottom = (box: Flatten.Box, by: number) => {
    return new Flatten.Box(box.xmin, box.ymin - by, box.xmax, box.ymax);
  };
  const byTop = (box: Flatten.Box, by: number) => {
    return new Flatten.Box(box.xmin, box.ymin, box.xmax, box.ymax + by);
  };

  let bestArea: number | null = null;
  let bestBox = new Flatten.Box();

  let successes = 0;
  for (let i = 0; i < 40 && successes < 7; i++) {
    const point = new Flatten.Point(
      random.random() * (pbox.xmax - pbox.xmin) + pbox.xmin,
      random.random() * (pbox.ymax - pbox.ymin) + pbox.ymin,
    );

    if (!polygon.contains(point)) {
      continue;
    }

    successes++;

    let distance = Infinity;
    for (const edge of edges) {
      distance = Math.min(distance, edge.shape.distanceTo(point)[0]);
    }

    const sqSideHalf = (1 / Math.sqrt(2)) * distance;

    {
      // try horizontal, then vertical
      let box = new Flatten.Box(
        point.x - sqSideHalf,
        point.y - sqSideHalf,
        point.x + sqSideHalf,
        point.y + sqSideHalf,
      );

      box = expand(polygon, box, pbox, byLeft);
      box = expand(polygon, box, pbox, byRight);
      box = expand(polygon, box, pbox, byBottom);
      box = expand(polygon, box, pbox, byTop);

      const area = (box.xmax - box.xmin) * (box.ymax - box.ymin);
      if (bestArea == null || area > bestArea) {
        bestArea = area;
        bestBox = box;
      }
    }

    {
      // try vertical, then horizontal
      let box = new Flatten.Box(
        point.x - sqSideHalf,
        point.y - sqSideHalf,
        point.x + sqSideHalf,
        point.y + sqSideHalf,
      );

      box = expand(polygon, box, pbox, byBottom);
      box = expand(polygon, box, pbox, byTop);
      box = expand(polygon, box, pbox, byLeft);
      box = expand(polygon, box, pbox, byRight);

      const area = (box.xmax - box.xmin) * (box.ymax - box.ymin);
      if (bestArea == null || area > bestArea) {
        bestArea = area;
        bestBox = box;
      }
    }
  }

  if (bestArea == null) {
    return null;
  }

  return {
    center: bestBox.center,
    width: bestBox.xmax - bestBox.xmin,
    height: bestBox.ymax - bestBox.ymin,
  };
}

function expand(
  polygon: Flatten.Polygon,
  rect: Flatten.Box,
  fullBounds: Flatten.Box,
  expand: (box: Flatten.Box, by: number) => Flatten.Box,
): Flatten.Box {
  let newRect = rect;

  let high = Math.max(
    fullBounds.xmax - fullBounds.xmin,
    fullBounds.ymax - fullBounds.ymin,
  );
  let low = 0;
  for (let i = 0; i < 15; i++) {
    const mid = (high + low) / 2;

    newRect = expand(rect, mid);
    const boxCorners = [
      Flatten.point(newRect.xmin, newRect.ymin),
      Flatten.point(newRect.xmax, newRect.ymin),
      Flatten.point(newRect.xmax, newRect.ymax),
      Flatten.point(newRect.xmin, newRect.ymax),
    ];
    const boxSegments = [
      Flatten.segment(boxCorners[0], boxCorners[1]),
      Flatten.segment(boxCorners[1], boxCorners[2]),
      Flatten.segment(boxCorners[2], boxCorners[3]),
      Flatten.segment(boxCorners[3], boxCorners[0]),
    ];

    // Check segment inclusion - polygon inclusion returns
    // undefined for some reason.
    if (
      boxSegments.every((s) => polygon.contains(s)) &&
      !boxSegments.some((s) => polygon.intersect(s)?.length > 0)
    ) {
      low = mid;
    } else {
      high = mid;
    }
  }

  newRect = expand(rect, low);

  return newRect;
}

// Because Flatten.JS's polygon.contains() is broken, we need to implement our own.
export function polygonPolygonContains(
  polygon: Flatten.Polygon,
  other: Flatten.Polygon,
): boolean {
  if (!polygon || !other) {
    return false;
  }

  for (const vertex of other.vertices) {
    if (!polygon.contains(vertex)) {
      return false;
    }
  }

  for (const edge of other.edges) {
    const ix = polygon.intersect(edge) as Flatten.Point[] | undefined;
    if (ix && ix.length > 0) {
      return false;
    }
  }

  return true;
}

export function polygonPolygonIntersects(
  a: Flatten.Polygon,
  b: Flatten.Polygon,
): boolean {
  if (!a || !b) {
    return false;
  }

  for (const vertex of b.vertices) {
    if (a.contains(vertex)) {
      return true;
    }
  }

  for (const vertex of a.vertices) {
    if (b.contains(vertex)) {
      return true;
    }
  }

  for (const edge of b.edges) {
    const ix = a.intersect(edge) as Flatten.Point[] | undefined;
    if (ix && ix.length > 0) {
      return true;
    }
  }

  return false;
}
