import {
  isDrainage,
  isSewer,
  isVentilation,
  LEVEL_HEIGHT_DIFF_M,
} from "../../../../../common/src/api/config";
import RiserEntity, {
  DuctRiserEntity,
  fillRiserDefaults,
} from "../../../../../common/src/api/document/entities/riser-entity";
import { getFlowSystem } from "../../../../../common/src/api/document/utils";
import {
  Units,
  UnitsContext,
} from "../../../../../common/src/lib/measurements";
import { assertType, assertUnreachable } from "../../../lib/utils";
import { CoreContext } from "../../calculations/types";
import { getDrainageUnitName, getPsdUnitName } from "../../calculations/utils";
import { CalculationField, FieldCategory } from "./calculation-field";
import {
  CalcFieldSelector,
  Calculation,
  CalculationType,
  LiveCalculation,
  NameCalculation,
  PressureCalculation,
  PsdCalculation,
} from "./types";
import { getFlowSystemLayouts } from "./utils";

export default interface RiserCalculation extends Calculation, NameCalculation {
  type: CalculationType.RiserCalculation;

  // Contains calculation by level.
  // The object at [levelUid] is the calculation at the _floor_ of that level.
  // (so often, the bottom most level will not have a calculation, but the top most will)
  heights: {
    [levelUid: string]: {
      reference: string | undefined;
      flowRateLS: number | null;
      heightAboveGround: number | null;

      // size is repurposed to diameterMM for ducts.
      sizeMM: number | null;

      // Used only for ducts
      shape: "circular" | "rectangular" | null;
      widthMM: number | null;
      heightMM: number | null;

      ventSizeMM: number | null;
      velocityRealMS: number | null;
      isVentExit: boolean | null;
      axialRotDEG: number | null;
    } & PressureCalculation &
      PsdCalculation;
  };
  bottomHeightM: number | null;
  topHeightM: number | null;
}

export interface RiserLiveCalculation extends LiveCalculation {
  heights: {
    [levelUid: string]: {
      isVentExit: boolean | null;
    };
  };
}

export const FastLiveRiserCalculationFields: CalcFieldSelector<RiserLiveCalculation> =
  {
    connected: true,
    heights: {
      __all__: {
        isVentExit: true,
      },
    },
  };

export function makeRiserCalculationFields(
  context: CoreContext,
  entity: RiserEntity,
  levelUid: string | null, // special UI specific context for risers needed.
): CalculationField[] {
  const { globalStore, drawing, locale } = context;
  const calc = globalStore.getOrCreateCalculation(entity);
  const filled = fillRiserDefaults(context, entity);

  const { layouts, layoutsExcept, onlyLayouts } = getFlowSystemLayouts(
    drawing.metadata.flowSystems[filled.systemUid],
  );

  const result: CalculationField[] = [];

  const psdUnit = getPsdUnitName(
    drawing.metadata.calculationParams.psdMethod,
    locale,
  );
  const drainageUnit = getDrainageUnitName(
    drawing.metadata.calculationParams.drainageMethod,
    drawing.metadata.units.volumeMeasurementSystem,
  );

  const sortedLevels = Object.values(drawing.levels).sort(
    (a, b) => a.floorHeightM - b.floorHeightM,
  );
  const lvlIndex = sortedLevels.findIndex((lvl) => lvl.uid === levelUid);
  let lvlAboveUid: string | null = null;
  if (lvlIndex !== -1 && lvlIndex !== sortedLevels.length - 1) {
    lvlAboveUid = sortedLevels[lvlIndex + 1].uid;
  }
  const system = getFlowSystem(drawing, entity.systemUid);

  const extendsToBottom =
    calc?.bottomHeightM! < drawing.levels[levelUid!].floorHeightM;
  const extendsToTop = lvlAboveUid
    ? calc?.topHeightM! > drawing.levels[lvlAboveUid].floorHeightM
    : calc?.topHeightM! >
      drawing.levels[levelUid!].floorHeightM + LEVEL_HEIGHT_DIFF_M;

  // TODO uncomment and fix DEV-325
  // addPressureCalculationFields(result, entity.systemUid, "heights." + lvlUid + ".",
  // {
  //     title: "Pressure At Floor",
  //     short: "at floor",
  // },
  // {
  //     title: "Static Pressure At Floor",
  //     short: "at floor"
  // });

  const iAmDrainage = isDrainage(
    drawing.metadata.flowSystems[entity.systemUid],
  );
  const iAmVent = isVentilation(drawing.metadata.flowSystems[entity.systemUid]);

  if (extendsToBottom) {
    result.push({
      property: "heights." + levelUid + ".reference",
      title: "Reference To Below",
      short: "",
      shortTitle: "To Below",
      units: Units.None,
      category: FieldCategory.EntityName,
      systemUid: entity.systemUid,
      layouts: layouts,
      defaultEnabled: false,
    });

    result.push({
      property: "heights." + levelUid + ".flowRateLS",
      title: "Flow Rate To Below",
      short: "",
      shortTitle: "To Below",
      unitContext: iAmVent ? UnitsContext.VENTILATION : undefined,
      units: Units.LitersPerSecond,
      systemUid: entity.systemUid,
      layouts,
      category: FieldCategory.FlowRate,
    });

    if (drawing.metadata.calculationParams.psdMethod !== null) {
      result.push({
        property: "heights." + levelUid + ".psdUnits.units",
        title: psdUnit.name + " To Below",
        short: "", // psdUnit.abbreviation + " to below",
        shortTitle: "To Below",
        units: Units.None,
        category: FieldCategory.LoadingUnits,
        layouts,
        systemUid: entity.systemUid,
        format: (v) => "" + Number((v ? v : 0).toFixed(5)),
      });
    }

    if (isDrainage(drawing.metadata.flowSystems[entity.systemUid])) {
      result.push({
        property: "heights." + levelUid + ".psdUnits.drainageUnits",
        title: drainageUnit.name + " To Below",
        short: "", // drainageUnit.abbreviation + " to below",
        shortTitle: "To Below",
        units: Units.None,
        category: FieldCategory.LoadingUnits,
        systemUid: entity.systemUid,
        layouts,
        format: (v) => "" + Number((v ? v : 0).toFixed(5)),
      });
    }

    if (iAmVent) {
      assertType<DuctRiserEntity>(filled);
      switch (filled.riser.shape) {
        case "rectangular":
          result.push({
            property: "heights." + levelUid + ".widthMM",
            title: "Width To Below",
            short: "w",
            units: Units.PipeDiameterMM,
            systemUid: entity.systemUid,
            category: FieldCategory.Size,
            defaultEnabled: false,
            layouts,
          });
          result.push({
            property: "heights." + levelUid + ".heightMM",
            title: "Depth To Below",
            short: "d",
            units: Units.PipeDiameterMM,
            systemUid: entity.systemUid,
            category: FieldCategory.Size,
            defaultEnabled: false,
            layouts,
          });
          break;
        case "circular":
          result.push({
            property: "heights." + levelUid + ".sizeMM",
            title: "Size To Below",
            short: "\u00f8",
            shortTitle: "To Below",
            units: Units.PipeDiameterMM,
            systemUid: entity.systemUid,
            category: FieldCategory.Size,
            defaultEnabled: false,
            layouts,
          });
          break;
        case null:
          break;
        default:
          assertUnreachable(filled.riser.shape);
      }
    } else if (iAmDrainage) {
      result.push({
        property: "heights." + levelUid + ".sizeMM",
        title: "Size To Below",
        short: "\u00f8",
        shortTitle: "To Below",
        units: Units.PipeDiameterMM,
        systemUid: entity.systemUid,
        category: FieldCategory.Size,
        defaultEnabled: false,
        layouts,
      });
    } else {
      result.push(
        {
          property: "heights." + levelUid + ".sizeMM",
          title: "Size To Below",
          short: "\u00f8",
          shortTitle: "To Below",
          units: Units.PipeDiameterMM,
          systemUid: entity.systemUid,
          category: FieldCategory.Size,
          hideUnits: true,
          defaultEnabled: false,
          layouts,
          significantDigits: 0,
        },
        {
          property: "heights." + levelUid + ".velocityRealMS",
          title: "Velocity To Below",
          short: "",
          shortTitle: "To Below",
          units: Units.MetersPerSecond,
          systemUid: entity.systemUid,
          category: FieldCategory.Velocity,
          layouts,
        },
      );
    }

    if (drawing.metadata.calculationParams.dwellingMethod !== null) {
      result.push({
        property: "heights." + levelUid + ".psdUnits.dwellings",
        title: "Dwellings To Below",
        short: "",
        shortTitle: "To Below",
        units: Units.None,
        category: FieldCategory.LoadingUnits,
        systemUid: entity.systemUid,
        layouts,
      });
    }
  }

  if (lvlAboveUid && extendsToTop) {
    result.push({
      property: "heights." + lvlAboveUid + ".reference",
      title: "Reference To Above",
      short: "",
      shortTitle: "To Above",
      units: Units.None,
      category: FieldCategory.EntityName,
      systemUid: entity.systemUid,
      layouts: layouts,
      defaultEnabled: false,
    });

    result.push({
      property: "heights." + lvlAboveUid + ".flowRateLS",
      title: "Flow Rate To Above",
      short: "",
      shortTitle: "To Above",
      unitContext: iAmVent ? UnitsContext.VENTILATION : undefined,
      units: Units.LitersPerSecond,
      systemUid: entity.systemUid,
      category: FieldCategory.FlowRate,
      layouts,
    });

    if (iAmVent) {
      assertType<DuctRiserEntity>(filled);
      switch (filled.riser.shape) {
        case "rectangular":
          result.push({
            property: "heights." + lvlAboveUid + ".widthMM",
            title: "Width To Above",
            short: "w",
            units: Units.PipeDiameterMM,
            systemUid: entity.systemUid,
            category: FieldCategory.Size,
            defaultEnabled: false,
            layouts,
          });
          result.push({
            property: "heights." + lvlAboveUid + ".heightMM",
            title: "Depth To Above",
            short: "d",
            units: Units.PipeDiameterMM,
            systemUid: entity.systemUid,
            category: FieldCategory.Size,
            defaultEnabled: false,
            layouts,
          });
          break;
        case "circular":
          result.push({
            property: "heights." + lvlAboveUid + ".sizeMM",
            title: "Size To Above",
            short: "\u00f8",
            shortTitle: "To Above",
            units: Units.PipeDiameterMM,
            systemUid: entity.systemUid,
            category: FieldCategory.Size,
            defaultEnabled: false,
            layouts,
          });
          break;
        case null:
          break;
        default:
          assertUnreachable(filled.riser.shape);
      }
    } else if (iAmDrainage) {
      result.push({
        property: "heights." + lvlAboveUid + ".sizeMM",
        title: "Size To Above",
        short: "\u00f8",
        shortTitle: "To Above",
        units: Units.PipeDiameterMM,
        systemUid: entity.systemUid,
        defaultEnabled: false,
        category: FieldCategory.Size,
        layouts,
      });
    } else {
      result.push(
        {
          property: "heights." + lvlAboveUid + ".sizeMM",
          title: "Size To Above",
          short: "\u00f8",
          shortTitle: "To Above",
          units: Units.PipeDiameterMM,
          systemUid: entity.systemUid,
          category: FieldCategory.Size,
          defaultEnabled: false,
          hideUnits: true,
          layouts,
          significantDigits: 0,
        },
        {
          property: "heights." + lvlAboveUid + ".velocityRealMS",
          title: "Velocity To Above",
          short: "",
          shortTitle: "To Above",
          units: Units.MetersPerSecond,
          systemUid: entity.systemUid,
          category: FieldCategory.Velocity,
          layouts,
        },
      );
    }

    if (drawing.metadata.calculationParams.psdMethod !== null) {
      result.push({
        property: "heights." + lvlAboveUid + ".psdUnits.units",
        title: psdUnit.name + " To Above",
        short: "", // psdUnit.abbreviation + " to above",
        shortTitle: "To Above",
        units: Units.None,
        category: FieldCategory.LoadingUnits,
        systemUid: entity.systemUid,
        layouts,
        format: (v) => "" + Number((v ? v : 0).toFixed(5)),
      });
    }

    if (isSewer(system)) {
      result.push({
        property: "heights." + lvlAboveUid + ".psdUnits.drainageUnits",
        title: drainageUnit.name + " To Above",
        short: "", // drainageUnit.abbreviation + " to above",
        shortTitle: "To Above",
        units: Units.None,
        category: FieldCategory.LoadingUnits,
        systemUid: entity.systemUid,
        layouts,
        format: (v) => "" + Number((v ? v : 0).toFixed(5)),
      });

      if (system && system.stackDedicatedVent) {
        result.push({
          property: "heights." + lvlAboveUid + ".ventSizeMM",
          title: "Dedicated Vent Size",
          short: "",
          units: Units.PipeDiameterMM,
          category: FieldCategory.LoadingUnits,
          systemUid: entity.systemUid,
          layouts,
        });
      }
    }

    if (system) {
      result.push({
        property: "heights." + lvlAboveUid + ".psdUnits.dwellings",
        title: "Dwellings To Above",
        shortTitle: "To Above",
        short: "",
        units: Units.None,
        category: FieldCategory.LoadingUnits,
        systemUid: entity.systemUid,
        layouts,
      });
    }
  }

  return result;
}

export function emptyRiserCalculations(): RiserCalculation {
  return {
    type: CalculationType.RiserCalculation,

    cost: null,
    costBreakdown: null,
    expandedEntities: null,

    warnings: null,
    heights: {},

    entityName: null,

    bottomHeightM: null,
    topHeightM: null,
  };
}

export function emptyRiserLiveCalculation(): RiserLiveCalculation {
  return {
    connected: null,
    warnings: [],
    heights: {},
  };
}
