import { assertType } from "./utils";

export type Polynomial = number[]; // constant first, highest order last.

export type BiPolynomial = Polynomial[]; // first index is polynomial in y * x^0, second index is polynomial in y * x^2, etc.

export type NPolynomial = Polynomial | NPolynomial[];

export function evaluatePolynomial(
  p: NPolynomial,
  ...values: number[]
): number {
  if (values.length === 1) {
    assertType<Polynomial>(p);
    let res = 0;
    for (let i = 0; i < p.length; i++) {
      res += p[i] * Math.pow(values[0], i);
    }
    return res;
  }

  let res = 0;
  for (let i = 0; i < p.length; i++) {
    res +=
      evaluatePolynomial(p[i] as NPolynomial, ...values.slice(1)) *
      Math.pow(values[0], i);
  }
  return res;
}

function polynomialExcelFormulaHelper(
  p: NPolynomial,
  accumulated: string[],
  ...locations: string[]
): string[] {
  if (locations.length === 1) {
    assertType<Polynomial>(p);
    const res: string[] = [];
    for (let i = 0; i < p.length; i++) {
      if (p[i] === 0) {
        continue;
      }

      const terms: string[] = accumulated.slice();
      if (p[i] !== 1 || (i === 0 && terms.length === 0)) {
        // opposite of push
        terms.unshift(p[i].toString());
      }

      if (i > 1) {
        terms.push(locations[0] + "^" + i);
      }
      if (i === 1) {
        terms.push(locations[0]);
      }

      res.push(terms.join("*"));
    }
    return res;
  }

  const res: string[] = [];
  for (let i = 0; i < p.length; i++) {
    let nextAccumul = accumulated.slice();
    if (i > 1) {
      nextAccumul.push(locations[0] + "^" + i);
    }
    if (i === 1) {
      nextAccumul.push(locations[0]);
    }
    res.push(
      ...polynomialExcelFormulaHelper(
        p[i] as NPolynomial,
        nextAccumul,
        ...locations.slice(1),
      ),
    );
  }
  return res;
}

export function polynomialExcelFormula(p: NPolynomial, ...locations: string[]) {
  return polynomialExcelFormulaHelper(p, [], ...locations).join("+");
}
