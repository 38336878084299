import Axios from "axios";
import { CalculationConcrete } from "../../../common/src/api/document/calculations-objects/calculation-concrete";
import { REPORT_S3_LINKS_EXPIRY_S } from "../../../common/src/api/reports/stickyCalculationReport";
import { getReportLinks } from "./reports";

export interface ReportS3Links {
  stickyCalculations: {
    get: string;
    head: string;
    put: string;
  };
  fullCalculations: {
    get: string;
    head: string;
    put: string;
  };
}

export default class CalculationReportManager {
  static links: Map<
    number,
    {
      links: ReportS3Links;
      lastUpdate: number;
      lastOperationId: number;
    }
  > = new Map();

  static onLogout() {
    this.links.clear();
  }

  static async ensureLinks(
    docId: number,
    lastOperationId: number,
    needsLatest: boolean = true,
  ): Promise<ReportS3Links | null> {
    const lastUpdate = this.links.get(docId)?.lastUpdate ?? 0;
    const lastLastOperationId = this.links.get(docId)?.lastOperationId ?? -1;
    let success = false;
    let needsUpdate = false;

    if (lastUpdate + REPORT_S3_LINKS_EXPIRY_S * 1000 < Date.now() + 30 * 1000) {
      needsUpdate = true;
    }
    if (needsLatest && lastLastOperationId !== lastOperationId) {
      needsUpdate = true;
    }

    if (needsUpdate) {
      const result = await getReportLinks(docId, lastOperationId);
      if (result.success) {
        this.links.set(docId, {
          links: result.data,
          lastOperationId,
          lastUpdate: Date.now(),
        });
        success = true;
      }
    } else {
      success = true;
    }
    if (!success) {
      return null;
    } else {
      return this.links.get(docId)?.links ?? null;
    }
  }

  static async putStickyCalculations(
    docId: number,
    lastOperationId: number,
    results: Record<string, Partial<CalculationConcrete>>,
  ): Promise<boolean> {
    const links = await this.ensureLinks(docId, lastOperationId, true);
    if (!links) {
      return false;
    }
    const calculationsJson = JSON.stringify(results);
    try {
      await Axios.put(links.stickyCalculations.put, calculationsJson, {
        headers: {
          "Content-Type": "application/json",
          "x-amz-meta-lastoperationid": lastOperationId.toString(),
        },
      });
      return true;
    } catch (e) {
      return false;
    }
  }

  static async getUpToDateStickyCalculations(
    docId: number,
    lastOperationId: number,
  ): Promise<Record<string, Partial<CalculationConcrete>> | null> {
    const links = await this.ensureLinks(docId, lastOperationId);
    if (!links) {
      return null;
    }
    try {
      const head = await Axios.head(links.stickyCalculations.head);
      if (
        head.headers["x-amz-meta-lastOperationId"].toString() ===
        lastOperationId.toString()
      ) {
        const response = await Axios.get(links.stickyCalculations.get, {
          headers: {
            "x-amz-meta-lastOperationId": lastOperationId.toString(),
          },
        });
        return JSON.parse(response.data);
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  }

  static async getStickyCalculations(
    docId: number,
    lastOperationId: number,
  ): Promise<Record<string, Partial<CalculationConcrete>> | null> {
    const links = await this.ensureLinks(docId, lastOperationId);
    if (!links) {
      return null;
    }
    try {
      const response = await Axios.get(links.stickyCalculations.get);
      return response.data;
    } catch (e) {
      return null;
    }
  }
}
