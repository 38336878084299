import { Precision, Units, UnitsContext } from "../../../lib/measurements";
import { getPropertyByString } from "../../../lib/utils";
import { DrawingLayout } from "../../calculations/types";
import { UnitsParameters } from "../drawing";
import { CalculatableEntityConcrete } from "../entities/concrete-entity";
import { EntityType } from "../entities/types";
import { Calculation } from "./types";

export enum FieldCategory {
  Pressure,
  Velocity,
  FlowRate,
  Size,
  Temperature,
  LoadingUnits,
  FixtureUnits,
  Height,
  HeatLoss,
  Volume,
  Length,
  Location,
  GreaseInterceptorTrap,
  FloorWaste,
  InspectionOpening,
  EntityName,
  PumpManufacturer,
  Time,
  Material,
  // This will make calculation result
  // not shown on the entity
  HighLightInfo,

  FilterManufacturer,
  RoManufacturer,
  ManifoldManufacturer,
}

/**
 * Given an object like
 *
 *  foo: {
 *    bar: {
 *      volumeL: 23
 *    }
 *  }
 *
 *  nestCalculationFields('foo', fields[])
 *
 *  Ensures that the fields part is correctly typed
 *
 *  But also makes all properties in those fields as `foo.bar`
 */
export function nestCalculationFields<A extends object, C extends object>(
  prefix: keyof A,
  fields: CalculationField<C>[],
): CalculationField<A>[] {
  return fields.map((f) => ({
    ...f,
    property: `${String(prefix)}.${String(f.property)}`,
  })) as unknown as CalculationField<A>[];
}

/**
 * Given an object like
 *
 *  foo: {
 *    bar: [{
 *      volumeL: 23
 *    }]
 *  }
 *
 *  nestCalculationFields('foo', 0 , fields[])
 *
 *  Ensures that the fields part is correctly typed
 *
 *  But also makes all properties in those fields as `foo.0.bar`
 *
 *  Hack: We shouldn't need to do this, but allows extracting an element of an array
 *  We hack it back to `CalculationField<A>[]` so we still get typesafety moving forward.
 *  Really we want to either
 *  A: Make sure entities do not have calculation lists
 *  B: Make calculation lists a first class citizen
 */
export function nestArrayCalculationFields<A extends object, C extends object>(
  prefix: keyof A,
  arrayIdx: number,
  fields: CalculationField<C>[],
): CalculationField<A>[] {
  return fields.map((f) => ({
    ...f,
    property: `${String(prefix)}.${arrayIdx}.${String(f.property)}`,
  })) as unknown as CalculationField<A>[];
}

// Allows nested properties. eg `foo.bar` to deeply grab calc objects
export interface NestedCalculationField<T extends object = any>
  extends Omit<CalculationField<T>, "property"> {
  property: keyof T | string;
}

export interface CalculationField<T extends object = any> {
  property: Extract<keyof T, string>;
  title: string;
  shortTitle?: string;
  short: string;
  units: Units;
  category: FieldCategory;
  defaultEnabled?: boolean;
  significantDigits?: number;
  fontMultiplier?: number;
  hideUnits?: boolean;
  hideOnHover?: boolean;
  attachUid?: string;
  systemUid?: string;
  bold?: boolean;
  format?: (v: any) => string;
  hide?: boolean;
  convert?: (
    unitPrefs: UnitsParameters,
    units: Units,
    value: number | null,
    precision?: Precision,
    unitContext?: UnitsContext,
  ) => [Units, number | string | null];
  static?: boolean;
  color?: string | null;

  // If missing, assume it is just for ['pressure'].
  layouts: DrawingLayout[];

  // If missing, assume None
  unitContext?: UnitsContext;
}

export enum CalculationDataType {
  VALUE,
  MESSAGE,
}

export interface CalculationFieldWithValue extends CalculationField {
  type: CalculationDataType.VALUE;
  attachUid: string;
  value: number | null;
  color: string | null | undefined;
}

export interface CalculationMessage {
  type: CalculationDataType.MESSAGE;
  attachUid: string;
  message: string;
  systemUid?: string;
}

export type CalculationData = CalculationMessage | CalculationFieldWithValue;

export function toCalculationFieldWithValue<T extends Calculation>(
  entityUid: string,
  field: CalculationField<T>,
  calculation: T,
): CalculationData {
  return {
    ...field,
    type: CalculationDataType.VALUE,
    value: getPropertyByString(calculation, field.property, true),
    attachUid: field.attachUid || entityUid,
    color: field.color,
  };
}

export function calculationResultOrder(
  entity: CalculatableEntityConcrete,
): number {
  switch (entity.type) {
    case EntityType.CONDUIT:
      return 10;
    case EntityType.PLANT:
      return 9;
    case EntityType.FLOW_SOURCE:
    case EntityType.SYSTEM_NODE:
    case EntityType.FIXTURE:
    case EntityType.FITTING:
    case EntityType.RISER:
    case EntityType.BIG_VALVE:
    case EntityType.DIRECTED_VALVE:
    case EntityType.MULTIWAY_VALVE:
      return 8;
    case EntityType.LOAD_NODE:
    case EntityType.GAS_APPLIANCE:
    case EntityType.COMPOUND:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return 2;
    case EntityType.ROOM:
      return 1;
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.EDGE:
    case EntityType.WALL:
    case EntityType.VERTEX:
    case EntityType.FENESTRATION:
      return 0;
    default:
      return -1;
  }
}
