import { ChimneySpec } from "./types";

export const CHIMNEY_DATA: ChimneySpec = {
  "with throat restrictor": {
    40: 3,
    70: 2,
  },
  "without throat restrictor": {
    40: 5,
    70: 4,
  },
  no: {},
};
