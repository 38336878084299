import {
  CalculatableEntityConcrete,
  DrawableEntityConcrete,
} from "../../document/entities/concrete-entity";

import { EntityType } from "aws-sdk/clients/iam";
import * as TM from "transformation-matrix";
import { CoreObjectConcrete } from "..";
import { Coord } from "../../../lib/coord";
import { GlobalStore } from "../../../lib/globalstore/global-store";
import { GetPressureLossOptions } from "../../calculations/entity-pressure-drops";
import {
  CoreContext,
  CostBreakdown,
  PressureLossResult,
} from "../../calculations/types";
import { CalculationConcrete } from "../../document/calculations-objects/calculation-concrete";
import { DrawingState } from "../../document/drawing";
import CoreWorldObject from "./coreWorldObject";
import { SelectionTarget } from "./types";

export interface ICoreBaseBackedObject<T extends DrawableEntityConcrete> {
  entityBacked: DrawableEntityConcrete;
  globalStore: GlobalStore;
  drawing: DrawingState;
  context: CoreContext;
  cache: Map<string, any>;
  entity(): T;
  parent(): CoreBaseBackedObject<DrawableEntityConcrete> | null;
  getParentChain(): CoreBaseBackedObject<DrawableEntityConcrete>[];
  get position(): TM.Matrix;
  getFrictionPressureLossKPA(
    options: GetPressureLossOptions,
  ): PressureLossResult;
  getCalculationEntities(context: CoreContext): DrawableEntityConcrete[];
  collectCalculations(context: CoreContext): CalculationConcrete;
  costBreakdown(context: CoreContext): CostBreakdown | null;
  getCalculationUid(context: CoreContext): string;
  preCalculationValidation(context: CoreContext): SelectionTarget | null;
}

export default abstract class CoreBaseBackedObject<
  T extends DrawableEntityConcrete,
> extends CoreWorldObject {
  entityBacked: DrawableEntityConcrete;
  globalStore: GlobalStore;
  drawing: DrawingState;
  context: CoreContext;

  cache = new Map<string, any>();

  get entity(): T {
    return this.entityBacked as T;
  }

  get uid(): string {
    return this.entity.uid;
  }

  abstract type: EntityType;
  abstract getHash(): string;

  get parent(): CoreObjectConcrete | null {
    if (this.entity.parentUid === null) {
      return null;
    } else {
      const result = this.globalStore.get(this.entity.parentUid);
      if (result) {
        return result;
      }
      console.error(
        "Parent object not created. parent uid: " +
          this.entity.parentUid +
          " this uid " +
          this.entity.uid,
      );
      return null;
    }
  }

  getParentChain(): CoreObjectConcrete[] {
    if (this.parent) {
      const res = this.parent.getParentChain();
      // note that these lines assume only Concrete Core Objects are
      // derived from this base.
      res.push(this as any as CoreObjectConcrete);
      return res;
    } else {
      return [this as any as CoreObjectConcrete];
    }
  }

  // Override this to give positions to children that respect delegated positions.
  // Return the correct object coord of the child, or null if unknown.
  getCorrectPositionOfChild(childUid: string): Coord | null {
    return null;
  }

  constructor(context: CoreContext, obj: T) {
    super(null);
    this.entityBacked = obj;
    this.drawing = context.drawing;
    this.globalStore = context.globalStore;
    this.context = context;
  }

  // Could be abstract, but entities usually have no need to update.
  onUpdate() {
    //
  }

  // Called by the engine when the object needs to be redrawn, due to changes in itself
  // or other entities it depends on for its own visuals.
  onRedrawNeeded() {
    //
  }

  // The entities which, if changed, would cause this entity's graphics to be invalidated, including
  // shape, position, or colour, etc.
  // This list should be stable as long as the entity itself is not changed.
  getVisualDeps(): string[] {
    if (this.entity.parentUid) {
      return [this.entity.parentUid];
    }
    return [];
  }

  abstract get position(): TM.Matrix;

  // Quantity, and string into index.
  abstract getFrictionPressureLossKPA(
    options: GetPressureLossOptions,
  ): PressureLossResult;

  abstract getCalculationEntities(
    context: CoreContext,
  ): CalculatableEntityConcrete[];

  abstract collectCalculations(context: CoreContext): CalculationConcrete;

  abstract costBreakdown(context: CoreContext): CostBreakdown | null;

  abstract getCalculationUid(context: CoreContext): string;

  abstract preCalculationValidation(
    context: CoreContext,
  ): SelectionTarget | null;

  abstract getCoreNeighbours(): CoreObjectConcrete[];
}
