import { isTestOrDev } from "../../config";
import { fillTextStable } from "../../htmlcanvas/helpers/ctx-extensions";

// gradually this timer can be eased off, especially in dev. eager for now.
const conflictCheckInterval = isTestOrDev ? 250 : 60 * 1000;

const applyExtensionMethod = (
  prototype: any,
  prototypeName: string,
  key: any,
  method: any,
) => {
  // check something else hasn't already assigned to this method
  if (prototype[key]) {
    throw new Error(
      `Prototype ${prototypeName} already has a method on key ${key}. h2x has some custom prototype extensions. In this case the extension could not be applied because something else, perhaps an npm package, has already assigned it.`,
    );
  }

  // assign it
  Object.defineProperty(prototype, key, {
    value: method,
    writable: true,
    enumerable: false, // This hides the method from Vue.js
  });

  // on a timer, check that the prototype hasn't been modified.
  // we do this at runtime because, in theory, lazy loading code or npm packages COULD (theoretically) modify the prototype.
  const interval = setInterval(() => {
    if (prototype[key] !== method) {
      // ^ object reference of the function / method has changed
      clearInterval(interval as any);
      throw new Error(
        `Prototype ${prototypeName} has been unexpectedly modified on key ${key}. h2x has some custom prototype extensions that have been assigned, and have since been altered, perhaps by an imported npm package or lazy loaded code bundle.`,
      );
    }
  }, conflictCheckInterval);
};

applyExtensionMethod(
  Object.prototype,
  "Object",
  "toEntries",
  function (this: Record<any, any>) {
    return Object.entries(this);
  },
);
applyExtensionMethod(
  Object.prototype,
  "Object",
  "toValues",
  function (this: Record<any, any>) {
    return Object.values(this);
  },
);
applyExtensionMethod(
  Object.prototype,
  "Object",
  "forEachValue",
  function (this: Record<any, any>, callback: (v: any) => void) {
    for (const key in this) {
      callback(this[key]);
    }
  },
);
applyExtensionMethod(
  Object.prototype,
  "Object",
  "toKeys",
  function (this: Record<any, any>) {
    return Object.keys(this);
  },
);
applyExtensionMethod(
  Array.prototype,
  "Array",
  "toObject",
  function (this: [key: any, value: any][]) {
    return Object.fromEntries(this);
  },
);
applyExtensionMethod(
  Array.prototype,
  "Array",
  "toObjectFromKVPs",
  function (this: { key: any; value: any }[]) {
    const acc: Record<any, any> = {};
    for (const { key, value } of this) {
      acc[key] = value;
    }
    return acc;
  },
);
applyExtensionMethod(
  CanvasRenderingContext2D.prototype,
  "CanvasRenderingContext2D",
  "fillTextStable",
  fillTextStable,
);

// example usage
// const o = { a: 10, b: 66 };
// const _o = o
//   .toEntries()
//   .map(([k, v]) => [k, v + 1] as [string, number]) // awkward type explication needed
//   .toObject(); // { a: 11, b: 67 }
// const _o2 = o
//   .toEntries()
//   .map(([key, value]) => ({ key, value: value + 1 })) // better type inference
//   .toObjectFromKVPs(); // { a: 11, b: 67 }
