import axios from "axios";
import { APIResult, Success } from "../../../common/src/api/document/types";
import { objectifyError } from "../../../common/src/lib/utils";
import { FeatureAccess } from "../../../common/src/models/FeatureAccess";
import {
  Organization,
  OrganizationSubscriptionStatus,
  OrganizationType,
  PasswordRequirements,
  mergeOrganizationResult,
} from "../../../common/src/models/Organization";
import { getFailureFromAxiosRequestErrorEvent } from "../../src/lib/axios-utils";

export async function getOrganizations(params?: {
  type: OrganizationType;
}): Promise<APIResult<Organization[]>> {
  try {
    return (await axios.get("/api/organizations/", { params })).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function getOrganization(
  id: string,
): Promise<APIResult<Organization>> {
  try {
    return (await axios.get("/api/organizations/" + id)).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function createOrganization(
  id: string,
  name: string,
  type: OrganizationType,
  maxAccountsNum: number | null,
  subscriptionStatus: OrganizationSubscriptionStatus,
): Promise<APIResult<Organization>> {
  try {
    return (
      await axios.post("/api/organizations/", {
        id,
        name,
        type,
        maxAccountsNum,
        subscriptionStatus,
      })
    ).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function updateOrganization(
  id: string,
  name: string,
  type: OrganizationType,
  maxAccountsNum: number | null,
  maxPasswordAttemptsBeforeLock: number | null,
  passwordRequirements: PasswordRequirements,
  subscriptionStatus: OrganizationSubscriptionStatus,
  featureAccess: FeatureAccess,
  address: string,
  phoneNumber: string,
  enforceMFA: boolean,
): Promise<APIResult<Organization>> {
  try {
    return (
      await axios.put("/api/organizations/" + id, {
        name,
        type,
        maxAccountsNum,
        maxPasswordAttemptsBeforeLock,
        passwordRequirements,
        subscriptionStatus,
        featureAccess,
        address,
        phoneNumber,
        enforceMFA,
      })
    ).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export async function uploadOrgLogo(
  orgId: string,
  file: File,
  variant: "square" | "wide",
): Promise<APIResult<string>> {
  try {
    const formData = new FormData();
    formData.append("logo", file);
    await axios.post(
      `/api/organizations/${orgId}/logo?variant=${variant}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );

    return { success: true, data: "success" };
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}

export const getOrgLogoUrl = async (
  orgId: string,
  size?: "small" | "medium" | "large" | "raw",
  variant?: "square" | "wide",
): Promise<APIResult<string>> => {
  try {
    const querySize = !size ? "small" : size;
    const queryVariant = !variant ? "square" : variant;
    const logoUrlRequestResult = await axios.get<Success<string>>(
      `/api/organizations/${orgId}/logourl?size=${querySize}&variant=${queryVariant}`,
    );
    return logoUrlRequestResult.data;
  } catch (e: any) {
    console.error(
      `Failed to get org logo url for orgId ${orgId}`,
      objectifyError(e),
    );
    return getFailureFromAxiosRequestErrorEvent(e);
  }
};

export async function mergeOrganization(
  currentOrgID: string,
  targetOrgID: string,
): Promise<APIResult<mergeOrganizationResult>> {
  try {
    return (
      await axios.post("/api/organizations/merge-organization", {
        currentOrgID,
        targetOrgID,
      })
    ).data;
  } catch (e: any) {
    return getFailureFromAxiosRequestErrorEvent(e);
  }
}
