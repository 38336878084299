import CoreDamper from "../../../../common/src/api/coreObjects/coreDamper";
import { CalculationData } from "../../../../common/src/api/document/calculations-objects/calculation-field";
import { DuctCalculation } from "../../../../common/src/api/document/calculations-objects/conduit-calculations";
import { DrawableEntityConcrete } from "../../../../common/src/api/document/entities/concrete-entity";
import DamperEntity from "../../../../common/src/api/document/entities/damper-entity";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { Coord } from "../../../../common/src/lib/coord";
import CanvasContext from "../lib/canvas-context";
import { EntityDrawingArgs } from "../lib/drawable-object";
import { EntityPopupContent } from "../lib/entity-popups/types";
import { Interaction } from "../lib/interaction";
import { CalculatedObject } from "../lib/object-traits/calculated-object";
import CoolDraggableObject from "../lib/object-traits/cool-draggable-object";
import { Core2Drawable } from "../lib/object-traits/core2drawable";
import {
  HoverSiblingResult,
  HoverableObject,
} from "../lib/object-traits/hoverable-object";
import { SelectableObject } from "../lib/object-traits/selectable";
import { DrawingContext, ObjectConstructArgs } from "../lib/types";
import { DrawingMode } from "../types";
import { DrawableObjectConcrete, EdgeObjectConcrete } from "./concrete-object";
import { createCalculationBoxes } from "./utils";

const Base = CalculatedObject(
  SelectableObject(
    CoolDraggableObject(HoverableObject(Core2Drawable(CoreDamper))),
  ),
);

export default class DrawableDamper extends Base {
  type: EntityType.DAMPER = EntityType.DAMPER;
  heightMM = 30;

  // We could not add this to the base drawable class because
  // of some typescript thing so they have to be added at the concrete class.
  constructor(args: ObjectConstructArgs<DamperEntity>) {
    super(args.context, args.obj);
    this.onSelect = args.onSelect;
    this.onInteractionComplete = args.onInteractionComplete;
    this.document = args.document;
  }

  isActive() {
    const edge = this.globalStore.get(
      this.entity.edgeUid,
    ) as EdgeObjectConcrete;
    return edge.isActive();
  }

  inBounds(objectCoord: Coord, _radius?: number) {
    if (!this.isActive()) {
      return false;
    }

    if (
      objectCoord.x > -this.widthMM &&
      objectCoord.x < this.widthMM &&
      objectCoord.y < this.heightMM &&
      objectCoord.y > -this.heightMM
    ) {
      return true;
    }

    return false;
  }

  get edgeWidthMM() {
    const edgeCalc = this.globalStore.calculationStore.get(
      this.entity.edgeUid,
    ) as DuctCalculation;
    if (edgeCalc) {
      if (edgeCalc.widthMM) {
        return edgeCalc.widthMM;
      }
      if (edgeCalc.diameterMM) {
        return edgeCalc.diameterMM;
      }
    }
    return 120;
  }

  get widthMM() {
    if (
      this.document.uiState.ductView === "physical" &&
      this.document.uiState.drawingMode === DrawingMode.Calculations
    ) {
      return this.edgeWidthMM;
    }
    return 120;
  }

  drawEntity(context: DrawingContext, _drawArgs: EntityDrawingArgs): void {
    const { ctx } = context;
    const prevAlpha = ctx.globalAlpha;
    const width = this.widthMM + this.heightMM;

    ctx.beginPath();
    ctx.moveTo(-width / 2, 0);
    ctx.lineTo(width / 2, 0);
    ctx.lineTo(width / 2, this.heightMM);
    ctx.lineWidth = 15;
    ctx.globalAlpha = this.isActive() ? 1 : prevAlpha;
    ctx.strokeStyle = this.isActive() ? "black" : "grey";
    ctx.stroke();
  }

  prepareDelete(
    _context: CanvasContext,
    _calleeEntityUid?: string,
  ): DrawableObjectConcrete[] {
    return [this];
  }

  offerInteraction(_interaction: Interaction): DrawableEntityConcrete[] | null {
    return null;
  }

  getPopupContent(): EntityPopupContent[] | null {
    return null;
  }

  locateCalculationBoxWorld(
    _context: DrawingContext,
    data: CalculationData[],
    scale: number,
  ) {
    return createCalculationBoxes({
      wc: this.toWorldCoord(),
      angle: (this.toWorldAngleDeg(0) / 180) * Math.PI,
      scale,
      distanceCandidates: [this.widthMM / 2],
      data,
    });
  }

  getHoverSiblings(): HoverSiblingResult[] {
    const result: HoverSiblingResult[] = [];

    return result;
  }
}
