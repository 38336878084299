import { EPS, EPS_ABS, parseCatalogNumberExact } from "../../lib/utils";
import { getFlowSystem } from "../document/utils";
import { TraceCalculation } from "./flight-data-recorder";
import { CoreContext } from "./types";

export class PressureDropCalculations {
  @TraceCalculation("Calculating reynold's number")
  static getReynoldsNumber(
    densityKGM3: number,
    velocityMS: number,
    pipeInternalDiameterMM: number,
    dynamicViscosityNSM2: number,
  ) {
    const internalDiameterM = pipeInternalDiameterMM / 1000;
    const res =
      (densityKGM3 * velocityMS * internalDiameterM) / dynamicViscosityNSM2;
    if (isNaN(res)) {
      throw new Error("Reynolds number is NaN");
    } else {
      return res;
    }
  }

  @TraceCalculation("Getting friction factor")
  static getWaterPipeFrictionFactor(
    pipeInternalDiameterMM: number,
    pipeRoughness: number,
    reynoldsNumber: number,
  ) {
    if (reynoldsNumber < 10) {
      // numerically unstable for values of 0.81, and legitimate reynolds numbers below 10 are rare.
      return 0;
    }
    let curr =
      (1.14 + 2 * Math.log10(pipeInternalDiameterMM / pipeRoughness)) ** -2;
    let iter = 0;
    while (true) {
      iter++;
      const next =
        (-2 *
          Math.log10(
            pipeRoughness / pipeInternalDiameterMM / 3.7 +
              2.51 / (reynoldsNumber * Math.sqrt(curr)),
          )) **
        -2;
      if (
        Math.abs(next - curr) < EPS ||
        Math.abs(next - curr) / curr < EPS_ABS
      ) {
        break;
      }
      curr = next;
      if (iter > 500) {
        throw new Error(
          "infinite loop in friction calculation. reynolds Number: " +
            reynoldsNumber,
        );
      }
    }
    return curr;
  }

  static getDuctFrictionFactor(
    internalDiameterMM: number,
    roughnessMM: number,
    reynoldsNumber: number,
  ) {
    return (
      0.11 * (roughnessMM / internalDiameterMM + 68 / reynoldsNumber) ** 0.25
    );
  }

  static getDarcyWeisbachMH(
    frictionFactor: number,
    pipeLengthM: number,
    internalDiameterMM: number,
    velocityMS: number,
    ga: number,
  ) {
    return (
      (frictionFactor * pipeLengthM * velocityMS ** 2) /
      ((internalDiameterMM / 1000) * ga * 2)
    );
  }

  static getDarcyWeisbachFlatMH(
    internalDiameterMM: number,
    pipeRoughness: number,
    densityKGM3: number,
    dynamicViscosity: number,
    pipeLengthM: number,
    velocityMS: number,
    ga: number,
  ) {
    return this.getDarcyWeisbachMH(
      this.getWaterPipeFrictionFactor(
        internalDiameterMM,
        pipeRoughness,
        this.getReynoldsNumber(
          densityKGM3,
          velocityMS,
          internalDiameterMM,
          dynamicViscosity,
        ),
      ),
      pipeLengthM,
      internalDiameterMM,
      velocityMS,
      ga,
    );
  }

  static kValueToPressureDropKPA(
    context: CoreContext,
    fluid: string,
    kValue: number,
    velocityMS: number,
  ) {
    const ga =
      context.drawing.metadata.calculationParams.gravitationalAcceleration;
    const densityKGM3 = parseCatalogNumberExact(
      context.catalog.fluids[fluid].densityKGM3,
    );
    if (densityKGM3 == null) {
      throw new Error("Fluid not found in catalog: " + fluid);
    }

    return head2kpa(
      fittingFrictionLossMH(velocityMS, kValue, ga),
      densityKGM3,
      ga,
    );
  }

  static kValueToWaterPressureDropKPA(kValue: number, velocityMS: number) {
    return head2kpa(
      fittingFrictionLossMH(velocityMS, kValue, 9.81),
      1000,
      9.81,
    );
  }
}

export function getHazenWilliamsMH(
  flowRateMS: number,
  pipeInternalDiameterMM: number,
  pipeRoughness: number,
) {
  return (
    ((3.3 * 1e6 * flowRateMS) /
      (pipeInternalDiameterMM ** 2.63 * pipeRoughness)) **
    1.852
  );
}

export function fittingFrictionLossMH(
  velocityMS: number,
  kValue: number,
  ga: number,
) {
  return (kValue * velocityMS ** 2) / (ga * 2);
}

export function head2kpa(mh: number, densityKGM3: number, ga: number): number {
  return (densityKGM3 * ga * mh) / 1000;
}

export function kpa2head(kpa: number, densityKGM3: number, ga: number): number {
  return (1000 * kpa) / (densityKGM3 * ga);
}

export function getFluidDensityOfSystem(
  context: CoreContext,
  systemUid: string,
): number | null {
  const { drawing, catalog } = context;
  const system = getFlowSystem(drawing, systemUid);
  if (!system) {
    return null;
  }

  const fluid = catalog.fluids[system.fluid];
  if (!fluid) {
    return null;
  }

  return parseCatalogNumberExact(fluid.densityKGM3);
}
