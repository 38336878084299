import {
  assertUnreachable,
  cloneSimple,
  lowerBoundNumberTable,
} from "../../lib/utils";
import { GetPressureLossOptions } from "../calculations/entity-pressure-drops";
import {
  fittingFrictionLossMH,
  getFluidDensityOfSystem,
  head2kpa,
} from "../calculations/pressure-drops";
import {
  CoreContext,
  CostBreakdown,
  PressureLossResult,
} from "../calculations/types";
import { ComponentPressureLossMethod } from "../config";
import ConduitCalculation from "../document/calculations-objects/conduit-calculations";
import MultiwayValveCalculation, {
  MultiwayValveLiveCalculation,
  emptyMultiwayValveCalculation,
  emptyMultiwayValveLiveCalculation,
} from "../document/calculations-objects/multiway-valve-calculation";
import {
  CalculatableEntityConcrete,
  ConnectableEntityConcrete,
} from "../document/entities/concrete-entity";
import PipeEntity from "../document/entities/conduit-entity";
import { MultiwayValveEntity } from "../document/entities/multiway-valves/multiway-valve-entity";
import { EntityType } from "../document/entities/types";
import { CoreConnectable } from "./core-traits/coreConnectable";
import CoreBaseBackedObject from "./lib/coreBaseBackedObject";
import {
  determineConnectableSystemUid,
  determineSmallestDiameterPipe,
  findPipeFittingKValue,
  getIdentityCalculationEntityUid,
} from "./utils";

export default class CoreMultiwayValve extends CoreConnectable(
  CoreBaseBackedObject<MultiwayValveEntity>,
) {
  type: EntityType.MULTIWAY_VALVE = EntityType.MULTIWAY_VALVE;
  getComponentPressureLossKPA(
    options: GetPressureLossOptions,
  ): PressureLossResult {
    let { context, flowLS, from, to, signed } = options;
    const ga =
      context.drawing.metadata.calculationParams.gravitationalAcceleration;

    let sign = 1;
    if (flowLS < 0) {
      const oldFrom = from;
      from = to;
      to = oldFrom;
      flowLS = -flowLS;
      if (signed) {
        sign = -1;
      }
    }

    // 1. Pressure Loss Method
    switch (
      context.drawing.metadata.calculationParams.componentPressureLossMethod
    ) {
      case ComponentPressureLossMethod.INDIVIDUALLY:
        // Find pressure loss from components
        break;
      case ComponentPressureLossMethod.PERCENT_ON_TOP_OF_PIPE:
        return { pressureLossKPA: 0 };
      default:
        assertUnreachable(
          context.drawing.metadata.calculationParams
            .componentPressureLossMethod,
        );
    }

    // let's calculate the kValue based on T fittings
    let kValue: number | null = null;

    const pipeCalcs = [
      this.globalStore.calculationStore.get(
        from.connection,
      )! as ConduitCalculation,
      this.globalStore.calculationStore.get(
        to.connection,
      )! as ConduitCalculation,
    ];

    const { smallestDiameterNominalMM } =
      determineSmallestDiameterPipe(pipeCalcs);

    if (smallestDiameterNominalMM) {
      const fromc = this.get3DOffset(from.connection);
      const toc = this.get3DOffset(to.connection);

      kValue = findPipeFittingKValue(
        fromc,
        toc,
        this.globalStore.getConnections(this.entity.uid),
        context.catalog,
        smallestDiameterNominalMM,
      );
    }

    const volLM =
      (this.largestPipeSizeInternal(context)! ** 2 * Math.PI) / 4 / 1000;
    const velocityMS = flowLS / volLM;

    if (kValue) {
      return {
        pressureLossKPA:
          sign *
          head2kpa(
            fittingFrictionLossMH(velocityMS, kValue, ga),
            getFluidDensityOfSystem(
              context,
              determineConnectableSystemUid(this.globalStore, this.entity)!,
            )!,
            ga,
          ),
      };
    } else {
      return { pressureLossKPA: 0 };
    }
  }
  getCalculationEntities(context: CoreContext): CalculatableEntityConcrete[] {
    const tower: Array<
      [ConnectableEntityConcrete, PipeEntity] | [ConnectableEntityConcrete]
    > = this.getCalculationTower(context);
    const groups = this.getCalculationConnectionGroups(context);

    // a valve, importantly, cannot be split arbitrarily. For a valid calculation the heights must
    // be such that all paths meet at a single point (the valve).
    // There are 3 cases:
    // 1. All pipes on same height. Place at the only intersection.
    // 2. Two different heights, one of the heights have only one pipe. Place at the intersection with many pipes.
    // 3. Three different heights, top and bottom heights have only one pipe. Place in the middle intersection.
    // All other cases, including four or more different heights, cannot be done.

    const me: MultiwayValveEntity = cloneSimple(this.entity);
    me.parentUid = getIdentityCalculationEntityUid(
      context,
      this.entity.parentUid,
    );
    const swapIn = (
      fitting: ConnectableEntityConcrete,
    ): MultiwayValveEntity => {
      me.calculationHeightM = fitting.calculationHeightM;
      me.uid = fitting.uid;
      return me;
    };

    if (tower.length === 0) {
      return [];
    } else if (tower.length === 1) {
      tower[0][0] = swapIn(tower[0][0]);
    } else if (tower.length === 2) {
      if (groups[0].length === 1) {
        tower[1][0] = swapIn(tower[1][0]);
      } else if (groups[1].length === 1) {
        tower[0][0] = swapIn(tower[0][0]);
      } else {
        throw new Error(
          "MultiwayValve: Invalid calculation attempt - cannot place valve in 3d to cover all paths - 2 different heights and both have more than 1 path",
        );
      }
    } else if (tower.length === 3) {
      if (groups[0].length === 1 && groups[3].length === 1) {
        tower[1][0] = swapIn(tower[1][0]);
      } else {
        throw new Error(
          "MultiwayValve: Invalid calculation attempt - cannot place valve in 3d to cover all paths - 3 different heights but a tip has more than 2 paths",
        );
      }
    } else {
      throw new Error(
        "MultiwayValve: Invalid calculation attempt - cannot place valve in 3d to cover all paths - 4 different heights",
      );
    }

    return tower.flat();
  }
  collectCalculations(context: CoreContext): MultiwayValveCalculation {
    const me = this.getCalculationEntities(context).find(
      (e) => e.type === EntityType.MULTIWAY_VALVE,
    ) as MultiwayValveEntity;

    if (!me) {
      if (this.getCalculationEntities(context).length === 0) {
        return emptyMultiwayValveCalculation();
      } else {
        throw new Error(
          "Can't find self in calculations " +
            this.uid +
            " " +
            this.entity.valve.type,
        );
      }
    } else {
      return context.globalStore.getOrCreateCalculation(me);
    }
  }

  collectLiveCalculations(context: CoreContext): MultiwayValveLiveCalculation {
    const me = this.getCalculationEntities(context).find(
      (e) => e.type === EntityType.MULTIWAY_VALVE,
    ) as MultiwayValveEntity;

    if (!me) {
      if (this.getCalculationEntities(context).length === 0) {
        return emptyMultiwayValveLiveCalculation();
      } else {
        throw new Error(
          "Can't find self in calculations " +
            this.uid +
            " " +
            this.entity.valve.type,
        );
      }
    } else {
      return context.globalStore.getOrCreateLiveCalculation(me);
    }
  }

  costBreakdown(context: CoreContext): CostBreakdown | null {
    let pipeSize = this.largestPipeSizeNominalMM(context);
    let size = pipeSize;

    switch (this.entity.valve.type) {
      case "diverter-valve":
        size = lowerBoundNumberTable(
          context.priceTable.Equipment["Diverter Valve"],
          size,
        );
        if (size) {
          return {
            cost: context.priceTable.Equipment["Diverter Valve"][size],
            breakdown: [
              {
                type: "valve",
                qty: 1,
                path: `Equipment.Diverter Valve.${size}`,
                pipeSize: pipeSize!,
              },
            ],
          };
        }
        break;
      default:
        assertUnreachable(this.entity.valve.type);
    }

    return null;
  }

  getCalculationUid(context: CoreContext): string {
    return this.getCalculationEntities(context).find(
      (e) => e.type === EntityType.MULTIWAY_VALVE,
    )!.uid;
  }
  preCalculationValidation(context: CoreContext) {
    return null;
  }
}
