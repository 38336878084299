import axios from "axios";
import { sortBy, throttle } from "lodash";
import { v4 } from "uuid";
import { DrawingState } from "../../../../common/src/api/document/drawing";
import {
  assertUnreachable,
  assertUnreachableAggressive,
  immutableAppend,
  syncReduce,
} from "../../../../common/src/lib/utils";
import { User } from "../../../../common/src/models/User";
import { ByteBuffer } from "../../../../flatbuffers/flatbuffers/flatbuffers";
import {
  CanvasMouseEventRecordT,
  CanvasMouseMovementRecordT,
  DiffRecordT,
  KeyboardEventRecordT,
  LevelChangeRecordT,
  ReplayEventCollection,
  ToolEventRecordT,
  ViewPortRecordT,
} from "../../../../flatbuffers/generated/replays";
import { ReplayEventUnionT } from "../../../../flatbuffers/replays-enhanced";
import {
  ActiveReplayState,
  ReplayState,
} from "../../../src/store/document/types";
import { MainEventBus } from "../../../src/store/main-event-bus";
import store from "../../../src/store/store";
import { cooperativeYield } from "../../htmlcanvas/utils";
import { renderCanvasMouseEventRecord } from "./replays-canvas-mouse-events";
import { renderCanvasMouseMovementRecord } from "./replays-canvas-mouse-moves";
import {
  getDrawingStateAtOrderIndex,
  renderDiffRecord,
  renderDrawingState,
} from "./replays-diffs";
import {
  initKeyboardLogging,
  renderKeyboardEventRecord,
} from "./replays-keyboard";
import { renderLevelChangeRecord } from "./replays-level-changes";
import { logReplayEvent } from "./replays-storage";
import { renderToolEventRecord } from "./replays-tools";
import {
  getLiveCanvasDimensions,
  renderViewPortRecord,
} from "./replays-viewport";

type RenderFunction<T extends ReplayEventUnionT> = (
  record: T,
  replayState: ActiveReplayState,
) => Promise<ActiveReplayState>;

/** lax typing on this as cbf typing it out perfectly */
const getRenderFunction = (record: ReplayEventUnionT): RenderFunction<any> => {
  switch (record._type) {
    case "CanvasMouseEventRecordT":
      return renderCanvasMouseEventRecord;
    case "CanvasMouseMovementRecordT":
      return renderCanvasMouseMovementRecord;
    case "DiffRecordT":
      return renderDiffRecord;
    case "KeyboardEventRecordT":
      return renderKeyboardEventRecord;
    case "LevelChangeRecordT":
      return renderLevelChangeRecord;
    case "ToolEventRecordT":
      return renderToolEventRecord;
    case "ViewPortRecordT":
      return renderViewPortRecord;
    default:
      assertUnreachableAggressive(record);
  }
};

const getReplayState = () => {
  // fuck I hate this syntax
  return store.getters["document/replayState"] as ReplayState;
};

const getActiveReplayState = () => {
  const state = store.getters["document/replayState"] as ReplayState;
  if (state.isReplaying == false) throw "replayState is not active";
  return state;
};

const getRawEvents = () => {
  return getActiveReplayState().rawEvents;
};

const setReplayState = (replayState: ReplayState) => {
  store.commit("document/setReplayState", replayState);
};

const getUserName = () => {
  const user = store.getters["profile/profile"] as User | null;
  return user ? user.username : null;
};

export type ReplaySettings = {
  from: {
    documentId: number;
    orderIndex: number;
    timestamp: number | null;
  };
  to: {
    orderIndex: number;
  };
  playbackRate: number;
  skipInactivity:
    | {
        enabled: true;
        inactivityThreshold_ms: number;
      }
    | { enabled: false };
  continuation:
    | {
        type: "ContinueThroughDiffs";
      }
    | { type: "NoContinuation" }
    | { type: "LoopSingle" };
  cache?: {
    drawingStatePromise?: Promise<DrawingState>;
    replayEventsPromise?: Promise<ReplayEventUnionT[]>;
    nextDrawingStatePromise?: Promise<DrawingState> | null;
    nextReplayEventsPromise?: Promise<ReplayEventUnionT[]> | null;
  };
};

const thirtyFPS_ms = Math.floor(1000 / 30);

export const replay = {
  getReplayState,
  getActiveReplayState,
  setReplayState,
  getUserName,
  getRawEvents,

  getReplayRecords: async (
    documentId: number,
    orderIndex: number,
  ): Promise<ReplayEventUnionT[]> => {
    type ResponseType = {
      success: true;
      data: { type: "Buffer"; data: number[] };
    };
    // get events from server
    const response = await axios.get<ResponseType>(
      `/api/replay/events?documentId=${documentId}&previousOrderIndex=${orderIndex}`,
    );
    // deserialise (flatbuffers)
    const bytes = response.data.data.data; // axios sure loves 'data'
    const root = ReplayEventCollection.getRootAsReplayEventCollection(
      new ByteBuffer(new Uint8Array(bytes)),
    );
    const events = root.unpack().events;
    // enforce sorted by timestamp... server doesn't do this for us
    const sorted = sortBy(events, (e) => e.timestamp);
    // inject ids.. database doesn't give ids anymore
    let i = 0;
    events.forEach((event) => {
      event.id = i;
      i++;
    });
    return sorted;
  },

  /** storage of mouse movements on the canvas in world coords. throttled as very frequent / expensive */
  storeCanvasMouseMovementRecord: throttle(
    async (e: CanvasMouseMovementRecordT) => {
      if (getReplayState().isReplaying) return;
      logReplayEvent(e);
    },
    thirtyFPS_ms,
    { leading: true, trailing: true },
  ),

  /** storage of viewport state. throttled as very frequent while panning/zooming. important that final state is captured correctly via `trailing=false` */
  storeViewPortRecord: throttle(
    async (e: ViewPortRecordT) => {
      if (getReplayState().isReplaying) return;
      logReplayEvent(e);
    },
    thirtyFPS_ms,
    { leading: true, trailing: true },
  ),

  /** storage of clicks, pointer events on the canvas, specifically, in world coords. not throttled */
  storeCanvasMouseEventRecord: async (e: CanvasMouseEventRecordT) => {
    if (getReplayState().isReplaying) return;
    logReplayEvent(e);
  },

  /** storage of when diffs are triggered. not throttled */
  storeDiffRecord: async (e: DiffRecordT) => {
    if (getReplayState().isReplaying) return;
    replay.previousOrderIndex = e.orderIndex;
    logReplayEvent(e);
  },

  /** storage of keyboard strokes and what elements they are triggered on. not throttled */
  storeKeyboardEventRecord: async (e: KeyboardEventRecordT) => {
    if (getReplayState().isReplaying) return;
    logReplayEvent(e);
  },

  /** storage of what level the user is looking at */
  storeLevelChangeEventRecord: async (e: LevelChangeRecordT) => {
    if (getReplayState().isReplaying) return;
    logReplayEvent(e);
  },

  /** storage of what tool (if any) the user is using */
  storeToolEventEventRecord: async (e: ToolEventRecordT) => {
    if (getReplayState().isReplaying) return;
    logReplayEvent(e);
  },

  /** when a diff replay event gets stored remotely, this variable is udpated */
  previousOrderIndex: null as number | null,

  /** trigger replay to start */
  startReplay: async (settings: ReplaySettings): Promise<void> => {
    const { documentId, orderIndex } = settings.from;

    // generate unique id for this replay session. used to check if replay session has been restarted from outside (id will change)
    const replayId = v4();

    /** util. prevent mutation of Vuex state because this replay session has been terminated in some */
    const blockedFromSettingState = () => {
      const replayState = getReplayState();
      if (!replayState.isReplaying) return true; // cancelled
      if (replayState.id != replayId) return true; // restarted / changed
      if (replayState.paused) return true; // paused
      return false;
    };

    // fire queries to get remote info, or load the promises from cache
    const drawingStatePromise =
      settings.cache?.drawingStatePromise ??
      getDrawingStateAtOrderIndex(documentId, orderIndex);
    const replayEventsPromise =
      settings.cache?.replayEventsPromise ??
      replay.getReplayRecords(documentId, orderIndex);

    // apply promises to cache
    settings = {
      ...settings,
      cache: {
        ...settings.cache,
        drawingStatePromise,
        replayEventsPromise,
      },
    };

    // set init state
    setReplayState({
      id: replayId,
      documentId,
      orderIndex,
      virtualTime: null, // we don't know the time straight away, need to get events from server first
      rawEvents: null, // usually need to wait for server call
      isReplaying: true,
      lastCanvasMouseMovements: [], // most recent chunk of mouse movements for rendering (for showing mouse movement trails)
      lastCanvasMouseEvent: null, // most recent canvas mouse click etc (for showing clicks on canvas)
      eventsToLog: [],
      settings,
    });

    // retrieve replay events from the server
    const replayEvents = await replayEventsPromise;

    // store in state
    if (blockedFromSettingState()) return;
    setReplayState({ ...getActiveReplayState(), rawEvents: replayEvents });

    const renderLoop = () =>
      new Promise<"finished" | "cancelled" | "restarted" | "paused">(
        (resolve) => {
          const minVirtualTime =
            settings.from.timestamp ?? Number(replayEvents[0].timestamp);
          const timeRenderLoopStarted = performance.now();
          let accumulatedSkipInactivityTime = 0;
          let index = 0;
          let liveCanvasDimensions = getLiveCanvasDimensions();
          const onAnimationFrame = async (realTime: number) => {
            const state = getReplayState();
            if (state.isReplaying == false) {
              return resolve("cancelled"); // cancelled
            }
            if (state.id != replayId) {
              return resolve("restarted"); // session changed (replay was called again)
            }
            if (state.paused) {
              return resolve("paused");
            }
            const finished = index >= replayEvents.length;
            if (finished) {
              return resolve("finished");
            }

            const getRecordsToRenderAndIncrementIndex = () => {
              // loops upwards through events array, returns minimum set below our timestamp and increments the index.
              const getIfRecordSatisfiesRenderCondition = (
                record: ReplayEventUnionT,
              ) => {
                const virtualTime = Number(record.timestamp);
                const shouldRender =
                  (realTime - timeRenderLoopStarted) * settings.playbackRate +
                    accumulatedSkipInactivityTime >=
                  virtualTime - minVirtualTime; // real elapsed time in the replay system has exceeded virtual time since the replay started
                return shouldRender;
              };

              const candidateRecords: ReplayEventUnionT[] = [];
              // eslint-disable-next-line no-constant-condition
              while (true) {
                if (index >= replayEvents.length) return candidateRecords; // exit condition - out of bounds
                const record = replayEvents[index];
                const shouldRender =
                  getIfRecordSatisfiesRenderCondition(record);
                if (!shouldRender) {
                  return candidateRecords; // exit condition - we hit a record that shouldn't be rendered (too soon)
                }
                candidateRecords.push(record); // accumulation condition - record is OK, go to next
                index++;
                continue;
              }
            };

            // 'replayState' is the working variable for state as we process this animation frame.
            // final state gets set in Vuex at the end.
            let replayState = getActiveReplayState();

            // the events we need to render in this frame
            const recordsToRender = getRecordsToRenderAndIncrementIndex();

            // run each event through its render function, replayState gets sequentially accumulated (reduced)
            const reducer = async (
              replayState: ActiveReplayState,
              record: ReplayEventUnionT,
            ): Promise<ActiveReplayState> => {
              const renderFunction = getRenderFunction(record);
              return await renderFunction(record, replayState);
            };
            replayState = await syncReduce(
              recordsToRender,
              replayState,
              reducer,
            );

            // if we need to react to a change in canvas dimensions (user changed size of window whilst replaying), re-render the latest viewport
            {
              const _liveCanvasDimensions = getLiveCanvasDimensions();
              const canvasDimensionsChanged = (() => {
                if (_liveCanvasDimensions && !liveCanvasDimensions) return true;
                if (!_liveCanvasDimensions) return false;
                return (
                  _liveCanvasDimensions.width != liveCanvasDimensions!.width ||
                  _liveCanvasDimensions.height != liveCanvasDimensions!.height
                );
              })();
              if (canvasDimensionsChanged) {
                // re-render the most recent viewport setting
                for (let i = index - 1; i >= 0; i--) {
                  const event = replayEvents[i];
                  if (event._type != "ViewPortRecordT") continue;
                  replayState = await renderViewPortRecord(event, replayState);
                  break;
                }
              }
              liveCanvasDimensions = _liveCanvasDimensions;
            }

            // redraw exactly once per animation frame
            MainEventBus.$emit("redraw");
            await cooperativeYield();

            if (settings.skipInactivity.enabled) {
              (() => {
                if (!replayState.virtualTime) return;
                // find if the time gap to our next event is too large..
                const event = replayEvents[index - 1]; // -1 because this is after rendering...
                const nextEvent = replayEvents[index];
                if (!event || !nextEvent) return;
                const timeDelta =
                  Number(nextEvent.timestamp) - replayState.virtualTime;
                const threshold =
                  settings.skipInactivity.inactivityThreshold_ms;
                const inactivity = timeDelta > threshold;
                if (!inactivity) return;
                // we need to skip forward by the delta
                // skip forward in 'real' time by the minimum time delta (shifted FORWARDS a bit to ensure we don't trigger twice)
                accumulatedSkipInactivityTime +=
                  Number(timeDelta) + threshold * 0.1;

                // add time skip to event log
                const virtualTime =
                  Number(minVirtualTime) +
                  accumulatedSkipInactivityTime +
                  (realTime - timeRenderLoopStarted) * settings.playbackRate;
                replayState = {
                  ...replayState,
                  eventsToLog: immutableAppend(replayState.eventsToLog, {
                    timestamp: virtualTime,
                    id: v4(),
                    _type: "InactivitySkip",
                    time_ms: Number(timeDelta),
                  }),
                };
              })();
            }

            {
              const virtualTime =
                Number(minVirtualTime) +
                accumulatedSkipInactivityTime +
                (realTime - timeRenderLoopStarted) * settings.playbackRate;
              replayState = { ...replayState, virtualTime };
            }

            if (blockedFromSettingState()) return;
            setReplayState(replayState);

            requestAnimationFrame(onAnimationFrame);
          };
          requestAnimationFrame(onAnimationFrame);
        },
      );

    // util
    const getNextOrderIndex = () => {
      const shouldStop = orderIndex >= settings.to.orderIndex;
      if (shouldStop) return null;
      if (orderIndex == 0) {
        return 1;
      }
      return orderIndex + 2;
    };

    // get drawing state as it was for this diff
    const drawingState = await drawingStatePromise;

    // if needed, eagerly get info for next diff
    const nextDrawingStatePromise = (() => {
      if (settings.cache?.nextDrawingStatePromise)
        return settings.cache.nextDrawingStatePromise;
      switch (settings.continuation.type) {
        case "ContinueThroughDiffs": {
          const nextOrderIndex = getNextOrderIndex();
          if (nextOrderIndex == null) return null;
          return getDrawingStateAtOrderIndex(documentId, nextOrderIndex);
        }
        default:
          return null;
      }
    })();
    const nextReplayEventsPromise = (() => {
      if (settings.cache?.nextReplayEventsPromise)
        return settings.cache.nextReplayEventsPromise;
      switch (settings.continuation.type) {
        case "ContinueThroughDiffs": {
          const nextOrderIndex = getNextOrderIndex();
          if (nextOrderIndex == null) return null;
          return replay.getReplayRecords(documentId, nextOrderIndex);
        }
        default:
          return null;
      }
    })();

    // apply promises (for next state) in state
    settings = {
      ...settings,
      cache: {
        ...settings.cache,
        nextDrawingStatePromise,
        nextReplayEventsPromise,
      },
    };
    if (blockedFromSettingState()) return;
    replay.setReplayState({ ...getActiveReplayState(), settings });

    // render the current drawing state
    renderDrawingState(drawingState);

    // do the replay
    if (replayEvents.length) {
      const exitState = await renderLoop();
      if (exitState == "cancelled") return; // no need for continuation
      if (exitState == "restarted") return; // no need for continuation
      if (exitState == "paused") return; // if 'paused', we just leave state lingering around so the user can review it and restart
    }

    // continue to next diff as needed
    if (blockedFromSettingState()) return;
    switch (settings.continuation.type) {
      case "ContinueThroughDiffs": {
        const nextOrderIndex = getNextOrderIndex();
        if (
          nextOrderIndex == null ||
          nextDrawingStatePromise == null ||
          nextReplayEventsPromise == null
        ) {
          return replay.pauseReplay(getActiveReplayState().virtualTime!);
        }
        // default condition, start replay of next diff
        await replay.startReplay({
          ...settings,
          from: { documentId, orderIndex: nextOrderIndex, timestamp: null },
          cache: {
            drawingStatePromise: nextDrawingStatePromise,
            replayEventsPromise: nextReplayEventsPromise,
          },
        });
        return;
      }
      case "NoContinuation": {
        return replay.pauseReplay(getActiveReplayState().virtualTime!);
      }
      case "LoopSingle": {
        // restart replay with same settings..
        await replay.startReplay({
          ...settings,
          cache: { drawingStatePromise, replayEventsPromise },
        });
        return;
      }
      default:
        assertUnreachable(settings.continuation);
    }
  },
  stopReplay: () => {
    setReplayState({ isReplaying: false });
  },
  pauseReplay: (timestamp: number) => {
    const replayState = replay.getReplayState();
    if (!replayState.isReplaying) return;
    setReplayState({ ...replayState, paused: { timestamp } });
  },
  resumeReplay: () => {
    const replayState = replay.getReplayState();
    if (!replayState.isReplaying) return;
    if (!replayState.paused) return;
    const settings = replayState.settings;
    replay.startReplay({
      ...settings,
      from: { ...settings.from, timestamp: replayState.paused.timestamp },
    });
  },
  restartReplayFromCurrentDiff: () => {
    const replayState = replay.getReplayState();
    if (!replayState.isReplaying) return;
    const settings = replayState.settings;
    replay.startReplay({
      ...settings,
      from: {
        ...settings.from,
        timestamp: null,
      },
    });
  },
};

(window as any).replay = replay;
initKeyboardLogging();
