import Flatten from "@flatten-js/core";
import * as TM from "transformation-matrix";
import { GetPressureLossOptions } from "../calculations/entity-pressure-drops";
import {
  CoreContext,
  CostBreakdown,
  PressureLossResult,
} from "../calculations/types";
import { CalculationConcrete } from "../document/calculations-objects/calculation-concrete";
import { BackgroundEntity } from "../document/entities/background-entity";
import { CalculatableEntityConcrete } from "../document/entities/concrete-entity";
import { EntityType } from "../document/entities/types";
import CoreBaseBackedObject from "./lib/coreBaseBackedObject";
import { SelectionTarget } from "./lib/types";

export default class CoreBackgroundImage extends CoreBaseBackedObject<BackgroundEntity> {
  type: EntityType.BACKGROUND_IMAGE = EntityType.BACKGROUND_IMAGE;
  getHash(): string {
    const key = this.entity.center.x + " " + this.entity.center.y;
    // const hash = createHash("sha256");
    // hash.update(key.toString());
    // return hash.digest("hex");
    return key;
  }

  getFrictionPressureLossKPA(
    options: GetPressureLossOptions,
  ): PressureLossResult {
    throw new Error("Background entity does not have head loss");
  }
  getCalculationEntities(context: CoreContext): CalculatableEntityConcrete[] {
    return [];
  }
  collectCalculations(context: CoreContext): CalculationConcrete {
    throw new Error("Not applicable to background entity");
  }
  costBreakdown(context: CoreContext): CostBreakdown | null {
    return null;
  }
  getCalculationUid(context: CoreContext): string {
    // throw new Error("Not applicable to background entit");
    return this.entity.uid;
  }
  preCalculationValidation(context: CoreContext): SelectionTarget | null {
    return null;
  }

  get position() {
    return TM.transform(
      TM.translate(this.entity.center.x, this.entity.center.y),
      TM.rotateDEG(this.entity.rotation),
      TM.scale(this.entity.scaleFactor),
    );
  }

  getCoreNeighbours() {
    return [];
  }

  get boundary() {
    return this.entity.crop;
  }

  get shape() {
    const a = this.toWorldCoord({ x: this.boundary.x, y: this.boundary.y });
    const b = this.toWorldCoord({
      x: this.boundary.x + this.boundary.w,
      y: this.boundary.y,
    });
    const c = this.toWorldCoord({
      x: this.boundary.x,
      y: this.boundary.y + this.boundary.h,
    });
    const d = this.toWorldCoord({
      x: this.boundary.x + this.boundary.w,
      y: this.boundary.y + this.boundary.h,
    });

    const shape = new Flatten.Polygon();
    shape.addFace([a, b, c, d].map((p) => Flatten.point(p.x, p.y)));
    return shape;
  }
}
