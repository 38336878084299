import assert from "assert";
import {
  CoreEdgeObjectConcrete,
  CorePolygonObjectConcrete,
} from "../../../../../common/src/api/coreObjects";
import {
  isConnectableEntity,
  isEdgeEntity,
} from "../../../../../common/src/api/document/entities/concrete-entity";
import { EntityType } from "../../../../../common/src/api/document/entities/types";
import {
  Coord,
  coordDist,
  coordMagnitude,
} from "../../../../../common/src/lib/coord";
import {
  assertUnreachable,
  cloneSimple,
} from "../../../../../common/src/lib/utils";
import { addValveAndSplitPipe } from "../../../../src/htmlcanvas/lib/black-magic/split-pipe";
import CanvasContext from "../../../../src/htmlcanvas/lib/canvas-context";
import { ConnectableObjectConcrete } from "../../objects/concrete-object";
import DrawableBigValve from "../../objects/drawableBigValve";
import DrawableConduit from "../../objects/drawableConduit";
import DrawableFixture from "../../objects/drawableFixture";
import DrawableGasAppliance from "../../objects/drawableGasAppliance";
import DrawablePlant from "../../objects/drawablePlant";
import DrawableSystemNode from "../../objects/drawableSystemNode";
import { canConnectValve } from "../../tools/insert-directed-valve";
import { InteractionType } from "../interaction";
import { COLLECTIVE_SNAP_TOLERANCE_MM } from "./cool-drag";

/**
 * Moves object join source to object dest, regardless of compatability, and keeps
 * only the object with highest dragPriority.
 *
 * Returns the losing object, which is deleted (default) or optionally kept.
 */
export function moveOnto(
  source: ConnectableObjectConcrete,
  dest: ConnectableObjectConcrete | DrawableConduit,
  context: CanvasContext,
) {
  if (!canConnectValve(context, [dest.entity], source.entity)) {
    return;
  }
  if (dest instanceof DrawableConduit) {
    const { focus } = addValveAndSplitPipe(
      context,
      dest,
      source.toWorldCoord({ x: 0, y: 0 }),
      dest.entity.systemUid,
      10,
      source.entity,
    );
    assert(focus!.uid === source.uid);
  } else {
    const entity = dest.entity;
    const levelUid =
      context.globalStore.levelOfEntity.get(entity.uid) ||
      context.globalStore.levelOfEntity.get(source.uid);
    const finalCenter = dest.toWorldCoord();
    // delete incidental pipes

    let survivor = source;
    let loser = dest;

    if (dest.dragPriority > source.dragPriority) {
      const tmp = survivor;
      survivor = loser;
      loser = tmp;
    }

    const incidental = context.globalStore
      .getConnections(source.uid)
      .filter((puid) =>
        context.globalStore.getConnections(dest.uid).includes(puid),
      );
    incidental.forEach((pipe) => {
      // Non cascadingly delete
      context.$store.dispatch("document/deleteEntityOn", {
        entity: context.globalStore.get(pipe)!.entity,
        levelUid,
      });

      // Side effect for polygons: This special operation requires removal
      context.globalStore.getPolygonsByEdge(pipe).forEach((polygon) => {
        const polygonE =
          context.globalStore.get<CorePolygonObjectConcrete>(polygon)!.entity;
        if (polygonE.edgeUid.indexOf(pipe) !== -1) {
          (polygonE as any).edgeUid = polygonE.edgeUid.filter(
            (x) => x !== pipe,
          );
        }
      });

      // Side effect 2: dependent virtual edges.
      // NitDO: this function (and globalstore) should worry only about
      // virtual edges, and not into implementation of them: walls and fens
      [
        ...context.globalStore.getWallsByRoomEdge(pipe),
        ...context.globalStore.getFensByRoomEdge(pipe),
      ].forEach((wall) => {
        context.$store.dispatch("document/deleteEntityOn", {
          entity: context.globalStore.get(wall)!.entity,
          levelUid,
        });
      });
    });

    survivor.repositionCenterToWC(finalCenter);

    cloneSimple(context.globalStore.getConnections(loser.uid)).forEach(
      (puid) => {
        const pipe =
          context.globalStore.get<CoreEdgeObjectConcrete>(puid)!.entity;
        assert(isEdgeEntity(pipe));

        assert(
          (pipe.endpointUid[0] === loser.uid) !==
            (pipe.endpointUid[1] === loser.uid),
        );

        context.$store.dispatch("document/updatePipeEndpoints", {
          entity: pipe,
          endpoints: [
            pipe.endpointUid[0] === loser.uid
              ? survivor.uid
              : pipe.endpointUid[0],
            pipe.endpointUid[1] === loser.uid
              ? survivor.uid
              : pipe.endpointUid[1],
          ],
        });
      },
    );

    if (context.globalStore.has(loser.uid)) {
      context.deleteEntity(loser, false);
    }
  }
}

export function snapSystemNodesOnto(
  context: CanvasContext,
  sources: DrawableSystemNode[],
  options: {
    radiusMM: number;
  },
): {
  from: DrawableSystemNode;
  toUid: string;
  delta: Coord;
}[] {
  const result: {
    from: DrawableSystemNode;
    toUid: string;
    delta: Coord;
  }[] = [];

  for (const systemNode of sources) {
    const interaction = context.offerInteraction(
      {
        type: InteractionType.SNAP_ONTO_RECEIVE,
        src: systemNode.entity,
        worldCoord: systemNode.toWorldCoord(),
        worldRadius: options.radiusMM,
      },
      (o) => {
        const reciprocate = systemNode.offerInteraction({
          type: InteractionType.SNAP_ONTO_SEND,
          dest: o[0],
          worldCoord: systemNode.toWorldCoord(),
          worldRadius: options.radiusMM,
        });
        return !!reciprocate;
      },
    );

    if (interaction) {
      const [dest] = interaction;

      if (isConnectableEntity(dest)) {
        const destO = context.globalStore.get(
          dest.uid,
        ) as ConnectableObjectConcrete;
        const destWC = destO.toWorldCoord();
        const myWC = systemNode.toWorldCoord();
        const delta = {
          x: destWC.x - myWC.x,
          y: destWC.y - myWC.y,
        };

        result.push({
          from: systemNode,
          toUid: dest.uid,
          delta,
        });
      }
    }
  }

  return result;
}

export function getChildSystemNodes(
  context: CanvasContext,
  object:
    | DrawableBigValve
    | DrawableFixture
    | DrawableGasAppliance
    | DrawablePlant,
): DrawableSystemNode[] {
  switch (object.type) {
    case EntityType.PLANT:
    case EntityType.BIG_VALVE: {
      return object.getInletsOutlets();
    }
    case EntityType.FIXTURE: {
      return Object.values(object.entity.roughIns).map((roughIn) =>
        context.globalStore.get<DrawableSystemNode>(roughIn.uid),
      );
    }
    case EntityType.GAS_APPLIANCE: {
      return [
        context.globalStore.get<DrawableSystemNode>(object.entity.inletUid),
      ];
    }
  }
  assertUnreachable(object);
}

export function snapChildSystemNodes(
  context: CanvasContext,
  object:
    | DrawableBigValve
    | DrawableFixture
    | DrawableGasAppliance
    | DrawablePlant,
  options: {
    radiusPX: number;
  },
) {
  const childSystemNodes = getChildSystemNodes(context, object);
  const snapResult = snapSystemNodesOnto(context, childSystemNodes, {
    radiusMM: context.viewPort.toWorldLength(options.radiusPX),
  });

  let closestDiff = null;
  for (const sn of snapResult) {
    if (
      closestDiff === null ||
      coordMagnitude(sn.delta) < coordMagnitude(closestDiff)
    ) {
      closestDiff = sn.delta;
    }
  }

  if (snapResult && snapResult.length && closestDiff) {
    object.entity.center.x += closestDiff.x;
    object.entity.center.y += closestDiff.y;

    for (const sn of snapResult) {
      if (coordDist(sn.delta, closestDiff) < COLLECTIVE_SNAP_TOLERANCE_MM) {
        moveOnto(sn.from, context.globalStore.get(sn.toUid), context);
      }
    }
  }
}
