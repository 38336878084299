import { ViewPortRecordT } from "../../../../flatbuffers/generated/view-port-record";
import { ViewPort } from "../../../src/htmlcanvas/viewport";
import { ActiveReplayState } from "../../../src/store/document/types";
import { MainEventBus } from "../../../src/store/main-event-bus";
import store from "../../../src/store/store";

const setCanvasViewport = (viewPort: ViewPort) => {
  MainEventBus.$emit("set-viewport", viewPort);
};

export const getCanvasViewport = (): ViewPort | null => {
  return store.getters["document/viewPort"] as ViewPort | null;
};

export const getLiveCanvasDimensions = () => {
  const canvas = document.getElementById(
    "drawingCanvas",
  ) as HTMLCanvasElement | null;
  if (!canvas) return null;
  return { width: canvas.width, height: canvas.height };
};

export const renderViewPortRecord = async (
  event: ViewPortRecordT,
  replayState: ActiveReplayState,
) => {
  const canvasDims = getLiveCanvasDimensions();
  if (!canvasDims) return replayState;
  const reconstructedViewport = ViewPort.makeFromInternals(
    {
      // don't love making an object reference here
      surfaceToWorld_a: event.surfaceToWorldA,
      surfaceToWorld_b: event.surfaceToWorldB,
      surfaceToWorld_c: event.surfaceToWorldC,
      surfaceToWorld_d: event.surfaceToWorldD,
      surfaceToWorld_e: event.surfaceToWorldE,
      surfaceToWorld_f: event.surfaceToWorldF,
      width: event.width,
      height: event.height,
      screenScale: event.screenScale * 1.2, // zoom out a little bit from the src material
    },
    canvasDims.width,
    canvasDims.height,
  );
  setCanvasViewport(reconstructedViewport);
  return replayState;
};
