import Flatten from "@flatten-js/core";
import { Coord, coordSub } from "../../../../common/src/lib/coord";
import { EPS } from "../../../../common/src/lib/utils";
import CanvasContext from "../lib/canvas-context";
import {
  LineSnapTarget,
  PointSnapTarget,
  SnapTarget,
} from "../lib/object-traits/snappable-object";
import { CONNECTABLE_SNAP_RADIUS_PX } from "./snapping-insert-tool";

export const LINE_SNAP_DIST_PX = 10;

export enum TraceType {
  None,
  Snap,
  Force,
}

export interface SnapResult {
  wc: Coord | null;
  references: Array<Flatten.Line | Flatten.Point> | null;
  candidates: Array<Flatten.Point | Flatten.Line>;
}

export interface SnapSurface {
  surface: Flatten.Line;
  trace: TraceType;
  references: Array<Flatten.Line | Flatten.Point>;
  offset: Coord;
}

export interface SnapSurfaceResult {
  surfaces: SnapSurface[];
  obviousPoints: Flatten.Point[];
}

export function addOffsetToShape<T extends Flatten.Point | Flatten.Line>(
  shape: T,
  offset: Coord,
): T {
  if (shape instanceof Flatten.Point) {
    return Flatten.point(shape.x + offset.x, shape.y + offset.y) as T;
  } else {
    return Flatten.line(
      Flatten.point(shape.pt.x + offset.x, shape.pt.y + offset.y),
      Flatten.vector(shape.norm.x, shape.norm.y),
    ) as T;
  }
}

// Gets the points and basis vectors to snap onto.
export function getSnapSurfaces(
  context: CanvasContext,
  snapTargets: SnapTarget[][],
  originSnapTargets: PointSnapTarget[],
  originAnchorWC?: Coord,
): SnapSurfaceResult {
  const result: SnapSurface[] = [];
  const obviousPoints: Flatten.Point[] = [];
  if (!context) {
    return { surfaces: result, obviousPoints };
  }
  const pipeVectors: Array<{
    vector: Flatten.Vector;
    references: Array<Flatten.Line | Flatten.Point>;
  }> = [];

  const lineTargets = snapTargets
    .flat()
    .filter((t): t is LineSnapTarget => t.type === "line");

  const pointTargets = snapTargets
    .flat()
    .filter((t): t is PointSnapTarget => t.type === "point");

  for (const t of lineTargets) {
    const pv = Flatten.vector(t.wcB.x - t.wcA.x, t.wcB.y - t.wcA.y);
    const pl = Flatten.line(
      Flatten.point(t.wcA.x - t.offset.x, t.wcA.y - t.offset.y),
      pv.rotate90CCW(),
    );
    pipeVectors.push({
      vector: pv,
      references: [pl],
    });
    result.push({
      trace: TraceType.Snap,
      surface: pl,
      references: [pl],
      offset: t.offset,
    });
  }

  const effectiveSnapTargets = Array.from(pointTargets);
  effectiveSnapTargets.push(...originSnapTargets);

  for (const target of effectiveSnapTargets) {
    let wc = coordSub(target.wc, target.offset);
    let point = Flatten.point(wc.x, wc.y);
    obviousPoints.push(point);

    let trace = TraceType.None;

    if (originSnapTargets.includes(target)) {
      if (originAnchorWC) {
        wc = originAnchorWC;
        point = Flatten.point(wc.x, wc.y);
      }
      trace = TraceType.Force;
    } else if (!originSnapTargets.length) {
      trace = TraceType.Snap;
    }

    let hasNeighbours = false;
    // Pipes immediately connecting it
    for (const angle of target.validAnglesRad) {
      const pv = Flatten.vector(Math.sin(angle), -Math.cos(angle));
      const pl = Flatten.line(
        Flatten.point(wc.x - target.offset.x, wc.y - target.offset.y),
        pv.rotate90CCW(),
      );
      result.push({
        surface: Flatten.line(point, pv.rotate90CCW()),
        trace,
        references: [pl],
        offset: target.offset,
      });
      result.push({
        surface: Flatten.line(point, pv),
        trace,
        references: [pl],
        offset: target.offset,
      });

      if (originSnapTargets.includes(target)) {
        result.push({
          surface: Flatten.line(point, pv.rotate(Math.PI / 4)),
          trace: TraceType.Snap,
          references: [pl],
          offset: target.offset,
        });
        result.push({
          surface: Flatten.line(point, pv.rotate((Math.PI * 3) / 4)),
          trace: TraceType.Snap,
          references: [pl],
          offset: target.offset,
        });
      }
      hasNeighbours = true;
    }

    // coordinate axis
    result.push({
      surface: Flatten.line(point, Flatten.vector(0, 1)),
      trace,
      references: [],
      offset: target.offset,
    });
    result.push({
      surface: Flatten.line(point, Flatten.vector(1, 0)),
      trace,
      references: [],
      offset: target.offset,
    });

    // Pipes have the option to go 45 degrees
    if (originSnapTargets.includes(target)) {
      if (!hasNeighbours) {
        result.push({
          surface: Flatten.line(point, Flatten.vector(1, 1)),
          trace: TraceType.Snap,
          references: [],
          offset: target.offset,
        });

        result.push({
          surface: Flatten.line(point, Flatten.vector(-1, 1)),
          trace: TraceType.Snap,
          references: [],
          offset: target.offset,
        });
      }
    }

    // Parallel to existing pipes
    for (const pv of pipeVectors) {
      result.push({
        surface: Flatten.line(point, pv.vector.rotate90CCW()),
        trace,
        references: [...pv.references],
        offset: target.offset,
      });
    }
  }

  return { surfaces: result, obviousPoints };
}

export function resultWithoutObviousReferences(
  obviousPoints: Flatten.Point[],
  result: SnapResult,
) {
  if (!result.wc) {
    return result;
  }
  const thisPoint = Flatten.point(result.wc.x, result.wc.y);

  let isObvious = false;
  for (const p of obviousPoints) {
    if (thisPoint.distanceTo(p)[0] < EPS) {
      isObvious = true;
    }
  }

  if (isObvious) {
    result.references = [];
  }
  return result;
}

export function snapPoint(
  context: CanvasContext,
  snapTargets: SnapTarget[][],
  pointWc: Coord,
  originSnapTargets: PointSnapTarget[],
  originAnchorWC?: Coord,
): SnapResult {
  const { surfaces, obviousPoints } = getSnapSurfaces(
    context,
    snapTargets,
    originSnapTargets,
    originAnchorWC,
  );

  const point = Flatten.point(pointWc.x, pointWc.y);

  // if the current point is obvious, don't waste time drawing lines

  // snap to any of the intersections
  let closestDist: number | null = null;
  const candidates: Array<Flatten.Point | Flatten.Line> = [];
  let closestResult: SnapResult = {
    wc: null,
    references: null,
    candidates,
  };
  if (!context) {
    return resultWithoutObviousReferences(obviousPoints, closestResult);
  }
  for (const a of surfaces) {
    for (const b of surfaces) {
      const it = a.surface.intersect(b.surface);
      if (it.length === 1) {
        candidates.push(it[0]);
        const p = it[0];
        const d = it[0].distanceTo(point);
        const reward = context.viewPort.toWorldLength(
          CONNECTABLE_SNAP_RADIUS_PX,
        );
        if (closestDist === null || d[0] - reward < closestDist) {
          closestDist = d[0] - reward;
          closestResult = {
            wc: { x: p.x, y: p.y },
            references: [
              a.surface,
              b.surface,
              ...a.references,
              ...b.references,
            ],
            candidates,
          };
        }
      }
    }
    // snap to any of the intersections by their reference lines
  }

  // Snap perpendicular projection to snap surfaces.
  if (originSnapTargets.length) {
    let owc = originSnapTargets[0].wc;

    if (originAnchorWC) {
      owc = originAnchorWC;
    }

    for (const l of surfaces) {
      const projection = l.surface.distanceTo(Flatten.point(owc.x, owc.y))[1]
        .start;

      const it = projection.distanceTo(point);
      if (closestDist === null || it[0] < closestDist) {
        closestDist = it[0];
        candidates.push(it[1].start);
        closestResult = {
          wc: { x: projection.x, y: projection.y },
          references: [addOffsetToShape(l.surface, l.offset), ...l.references],
          candidates,
        };
      }
    }
  }

  // If forced snap, snap to points even if we just touch their reference line.
  // This is lowest priority since it is very available.
  const forceds = surfaces.filter((t) => t.trace === TraceType.Force);

  for (const forced of forceds) {
    for (const l of surfaces) {
      const projection = l.surface.distanceTo(point);
      if (
        context.viewPort.toScreenLength(projection[0]) < LINE_SNAP_DIST_PX &&
        !(l.trace === TraceType.Force)
      ) {
        if (closestDist === null || projection[0] < closestDist) {
          const snapPosition = l.surface.intersect(forced.surface);
          if (snapPosition.length > 1.0) {
            closestDist = projection[0];
            candidates.push(projection[1].start);
            closestResult = {
              wc: { x: snapPosition[0].x, y: snapPosition[0].y },
              references: [
                addOffsetToShape(l.surface, l.offset),
                ...l.references,
              ],
              candidates,
            };
          }
        }
      }
    }
  }

  if (
    closestDist !== null &&
    context.viewPort.toScreenLength(closestDist) < CONNECTABLE_SNAP_RADIUS_PX
  ) {
    return resultWithoutObviousReferences(obviousPoints, closestResult);
  }

  // no point? Then snap to nearest line
  closestDist = null;
  closestResult = {
    wc: null,
    references: null,
    candidates,
  };

  for (const l of surfaces) {
    if (l.trace === TraceType.Snap) {
      const it = l.surface.distanceTo(point);
      if (closestDist === null || it[0] < closestDist) {
        closestDist = it[0];
        candidates.push(it[1].start);
        closestResult = {
          wc: { x: it[1].start.x, y: it[1].start.y },
          references: [
            addOffsetToShape(l.surface, l.offset),
            ...l.references.map((r) => addOffsetToShape(r, l.offset)),
          ],
          candidates,
        };
      }
    }
  }

  if (
    closestDist !== null &&
    context.viewPort.toScreenLength(closestDist) < CONNECTABLE_SNAP_RADIUS_PX
  ) {
    return resultWithoutObviousReferences(obviousPoints, closestResult);
  }

  // finally, any forced traces
  closestDist = null;
  closestResult = {
    wc: null,
    references: null,
    candidates,
  };

  for (const l of surfaces) {
    if (l.trace === TraceType.Force) {
      const it = l.surface.distanceTo(point);
      if (closestDist === null || it[0] < closestDist) {
        closestDist = it[0];
        candidates.push(it[1].start);
        closestResult = {
          wc: { x: it[1].start.x, y: it[1].start.y },
          references: [l.surface, ...l.references],
          candidates,
        };
      }
    }
  }

  return resultWithoutObviousReferences(obviousPoints, closestResult);
}
