export interface PaperSize {
  name: PaperSizeName | "Not detected";
  heightMM: number;
  widthMM: number;
}

export const PAPER_SIZES: Record<PaperSizeName, PaperSize> = {
  A0: { name: "A0", heightMM: 841, widthMM: 1189 },
  A1: { name: "A1", heightMM: 594, widthMM: 841 },
  A2: { name: "A2", heightMM: 420, widthMM: 594 },
  A3: { name: "A3", heightMM: 297, widthMM: 420 },
  A4: { name: "A4", heightMM: 210, widthMM: 297 },
  A5: { name: "A5", heightMM: 149, widthMM: 210 },
  B0: { name: "B0", heightMM: 1000, widthMM: 1414 },
  B1: { name: "B1", heightMM: 707, widthMM: 1000 },
  B2: { name: "B2", heightMM: 500, widthMM: 707 },
  B3: { name: "B3", heightMM: 354, widthMM: 500 },
  B4: { name: "B4", heightMM: 250, widthMM: 354 },
  B5: { name: "B5", heightMM: 177, widthMM: 250 },
  C0: { name: "C0", heightMM: 917, widthMM: 1297 },
  C1: { name: "C1", heightMM: 648, widthMM: 917 },
  C2: { name: "C2", heightMM: 458, widthMM: 648 },
  C3: { name: "C3", heightMM: 324, widthMM: 458 },
  C4: { name: "C4", heightMM: 229, widthMM: 324 },
  C5: { name: "C5", heightMM: 162, widthMM: 229 },
  "Junior Legal": { name: "Junior Legal", heightMM: 127, widthMM: 203 },
  Legal: { name: "Legal", heightMM: 216, widthMM: 356 },
  Letter: { name: "Letter", heightMM: 216, widthMM: 279 },
  Tabloid: { name: "Tabloid", heightMM: 279, widthMM: 432 },
  RA0: { name: "RA0", heightMM: 860, widthMM: 1220 },
  RA1: { name: "RA1", heightMM: 610, widthMM: 860 },
  RA2: { name: "RA2", heightMM: 430, widthMM: 610 },
  RA3: { name: "RA3", heightMM: 305, widthMM: 430 },
  RA4: { name: "RA4", heightMM: 215, widthMM: 305 },
  RA5: { name: "RA5", heightMM: 153, widthMM: 215 },
  "ANSI A": { name: "ANSI A", heightMM: 215.9, widthMM: 279.4 },
  "ANSI B": { name: "ANSI B", heightMM: 279.4, widthMM: 431.8 },
  "ANSI C": { name: "ANSI C", heightMM: 431.8, widthMM: 558.8 },
  "ANSI D": { name: "ANSI D", heightMM: 558.8, widthMM: 863.6 },
  "ANSI E": { name: "ANSI E", heightMM: 863.6, widthMM: 1117.6 },
  "ARCH A": { name: "ARCH A", heightMM: 228.6, widthMM: 304.8 },
  "ARCH B": { name: "ARCH B", heightMM: 304.8, widthMM: 457.2 },
  "ARCH C": { name: "ARCH C", heightMM: 457.2, widthMM: 609.6 },
  "ARCH D": { name: "ARCH D", heightMM: 609.6, widthMM: 914.4 },
};

export const DEFAULTS_FOR_EACH_RATIO = [
  "Letter",
  "ANSI C",
  "ARCH C",
  "C3",
  "A1",
  "ARCH D",
  "ANSI D",
  "Tabloid",
  "Legal",
];

export const COVER_SHEET_SUPPORTED = [
  "A0",
  "A1",
  "A2",
  "A3",
  "RA0",
  "RA1",
  "RA2",
  "B0",
  "B1",
  "B2",
  "ANSI C",
  "ANSI D",
  "ANSI E",
  "ARCH C",
  "ARCH D",
];

export type PaperSizeName =
  | "A0"
  | "A1"
  | "A2"
  | "A3"
  | "A4"
  | "A5"
  | "RA0"
  | "RA1"
  | "RA2"
  | "RA3"
  | "RA4"
  | "RA5"
  | "B0"
  | "B1"
  | "B2"
  | "B3"
  | "B4"
  | "B5"
  | "C0"
  | "C1"
  | "C2"
  | "C3"
  | "C4"
  | "C5"
  | "Junior Legal"
  | "Legal"
  | "Letter"
  | "Tabloid"
  | "ANSI A"
  | "ANSI B"
  | "ANSI C"
  | "ANSI D"
  | "ANSI E"
  | "ARCH A"
  | "ARCH B"
  | "ARCH C"
  | "ARCH D";

// reversed
export const SENSIBLE_METRIC_SCALES: [string, [number, number]][] = [
  ["1:10", [1, 10]],
  ["1:15", [1, 15]],
  ["1:20", [1, 20]],
  ["1:25", [1, 25]],
  ["1:30", [1, 30]],
  ["1:40", [1, 40]],
  ["1:50", [1, 50]],
  ["1:75", [1, 75]],
  ["1:100", [1, 100]],
  ["1:125", [1, 125]],
  ["1:150", [1, 150]],
  ["1:200", [1, 200]],
  ["1:250", [1, 250]],
  ["1:300", [1, 300]],
  ["1:400", [1, 400]],
  ["1:500", [1, 500]],
  ["1:600", [1, 600]],
  ["1:750", [1, 750]],
  ["1:1000", [1, 1000]],
  ["1:1250", [1, 1250]],
  ["1:1500", [1, 1500]],
  ["1:2000", [1, 2000]],
  ["1:2500", [1, 2500]],
  ["1:3000", [1, 3000]],
  ["1:4000", [1, 4000]],
  ["1:5000", [1, 5000]],
  ["1:10000", [1, 10000]],
];

export const SENSIBLE_IMPERIAL_SCALES: [string, [number, number]][] = [
  ['1 1\u20442"=1\'-0"', [1, 8]],
  ['1"=1\'-0"', [1, 12]],
  ['3\u20444"=1\'-0"', [1, 16]],
  ['1\u20442"=1\'-0"', [1, 24]],
  ['3\u20448"=1\'-0"', [1, 32]],
  ['1\u20444"=1\'-0"', [1, 48]],
  ['3\u204416"=1\'-0"', [1, 64]],
  ['1\u20448"=1\'-0"', [1, 96]],
  ['3\u204432"=1\'-0"', [1, 128]],
  ['1\u204416"=1\'-0"', [1, 192]],
  ['1\u204432"=1\'-0"', [1, 384]],
  ['1\u204464"=1\'-0"', [1, 768]],
  ['1\u2044128"=1\'-0"', [1, 1536]],
];
