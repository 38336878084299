import Flatten from "@flatten-js/core";
import * as TM from "transformation-matrix";
import { PolygonSegment } from "../../../../common/src/api/calculations/heatloss/roof-calculation/roof-types";
import { rotatePointRelativeToCenter } from "../../../../common/src/api/calculations/heatloss/roof-calculation/utils";
import {
  CoreContext,
  DrawingLayout,
  PipeConfiguration,
} from "../../../../common/src/api/calculations/types";
import { StandardFlowSystemUids } from "../../../../common/src/api/config";
import { movePerpendicularByDistanceCW } from "../../../../common/src/api/coreObjects/utils";
import { CalculationData } from "../../../../common/src/api/document/calculations-objects/calculation-field";
import { getFlowSystemLayouts } from "../../../../common/src/api/document/calculations-objects/utils";
import { ConnectableEntityConcrete } from "../../../../common/src/api/document/entities/concrete-entity";
import { PlantType } from "../../../../common/src/api/document/entities/plants/plant-types";
import { isRadiatorPlant } from "../../../../common/src/api/document/entities/plants/utils";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { Coord } from "../../../../common/src/lib/coord";
import { GlobalStore } from "../../../../common/src/lib/globalstore/global-store";
import {
  MeasurementSystem,
  Precision,
  Units,
  UnitsContext,
  convertMeasurementSystem,
  displayValueForEdit,
} from "../../../../common/src/lib/measurements";
import { DEFAULT_FONT_NAME } from "../../config";
import { UIState } from "../../store/document/types";
import { FIELD_HEIGHT } from "../lib/object-traits/calculated-object-const";
import { DrawingContext } from "../lib/types";
import { DrawingMode } from "../types";
import { HoverableObjectConcrete } from "./concrete-object";
import DrawableConduit from "./drawableConduit";

const DEFAULT_ANGLES = [
  0,
  Math.PI / 4,
  -Math.PI / 4,
  Math.PI / 2,
  -Math.PI / 2,
  (Math.PI * 3) / 4,
  (-Math.PI * 3) / 4,
  Math.PI,
];

export function createCalculationBoxes(options: {
  wc: Coord;
  angle: number;
  scale: number;
  height?: number;
  distanceCandidates: number[];
  angles?: number[];
  data?: CalculationData[];
}): TM.Matrix[] {
  const { wc, angle, scale, distanceCandidates, data } = options;
  let { height, angles } = options;
  if (angles === undefined) {
    angles = DEFAULT_ANGLES;
  }
  if (height === undefined) {
    if (data !== undefined) {
      height = data.length * FIELD_HEIGHT;
    } else {
      height = FIELD_HEIGHT;
    }
  }
  return angles
    .map((delta) => {
      return distanceCandidates.map((d) =>
        TM.transform(
          TM.identity(),
          TM.translate(wc.x, wc.y),
          TM.rotate(angle + Math.PI + delta),
          TM.translate(0, -d),
          TM.scale(scale),
          TM.translate(0, -height! / 2),
          TM.rotate(-angle - Math.PI - delta),
        ),
      );
    })
    .flat();
}

export function determineConnectableConfigurationCosmetic(
  globalStore: GlobalStore,
  value: ConnectableEntityConcrete,
): PipeConfiguration | null {
  if (value.type === EntityType.SYSTEM_NODE) {
    const parent = globalStore.get(value.parentUid!);
    if (parent.entity.type === EntityType.PLANT) {
      if (parent.entity.plant.type === PlantType.RETURN_SYSTEM) {
        for (const outlet of parent.entity.plant.outlets) {
          if (outlet.outletReturnUid === value.uid) {
            return PipeConfiguration.RETURN_IN;
          } else if (outlet.outletUid === value.uid) {
            return PipeConfiguration.RETURN_OUT;
          }
        }
        for (const preheat of parent.entity.plant.preheats) {
          if (preheat.inletUid === value.uid) {
            return PipeConfiguration.RETURN_OUT;
          } else if (preheat.returnUid === value.uid) {
            return PipeConfiguration.RETURN_IN;
          }
        }
      } else if (isRadiatorPlant(parent.entity.plant)) {
        if (parent.entity.plant.outletUid === value.uid) {
          return PipeConfiguration.RETURN_IN;
        } else if (parent.entity.inletUid === value.uid) {
          return PipeConfiguration.RETURN_OUT;
        }
      }
    }
  }

  const connectionUids = globalStore.getConnections(value.uid);
  for (const cUid of connectionUids) {
    const p = globalStore.get<DrawableConduit>(cUid);
    if (p.effectiveConfiguration) {
      return p.effectiveConfiguration;
    }
  }

  return null;
}

export function isFlowSystemActive(
  context: CoreContext,
  uiState: UIState,
  systemUid: StandardFlowSystemUids | string,
) {
  if (uiState.showAllLayouts) {
    return true;
  }
  const { layouts } = getFlowSystemLayouts(
    context.drawing.metadata.flowSystems[systemUid],
  );
  if (uiState.drawingMode === DrawingMode.History) return true;
  return layouts.includes(uiState.drawingLayout);
}

export function isLayoutActive(
  _context: CoreContext,
  uiState: UIState,
  layout: DrawingLayout,
) {
  if (uiState.isEmbedded) {
    return true;
  }
  return uiState.drawingLayout === layout;
}

export function applyHoverEffects(
  context: DrawingContext,
  object: HoverableObjectConcrete,
) {
  if (object.isHovering) {
    context.ctx.globalAlpha = 0.5;
  }
}

export function slopeToColor(slopeDeg: number): string {
  // Ensure slopeDeg is within the 0-90 range
  slopeDeg = Math.max(0, Math.min(slopeDeg, 90));

  const greyValue = Math.round(20 + 235 * (slopeDeg / 90));
  const greyHex = greyValue.toString(16).padStart(2, "0");
  return `#${greyHex}${greyHex}${greyHex}`;
}

export function breakLineIntoSegments(
  line: [Coord, Coord],
  arr: number[],
): [Coord, Coord][] {
  const lineLength = Math.hypot(line[1].x - line[0].x, line[1].y - line[0].y);

  let startCoord: Coord = line[0];
  const segments: [Coord, Coord][] = [];

  arr.forEach((ratio) => {
    const segmentLength = lineLength * ratio;

    const direction = {
      x: (line[1].x - line[0].x) / lineLength,
      y: (line[1].y - line[0].y) / lineLength,
    };

    const endCoord: Coord = {
      x: startCoord.x + direction.x * segmentLength,
      y: startCoord.y + direction.y * segmentLength,
    };

    segments.push([startCoord, endCoord]);
    startCoord = endCoord;
  });

  return segments;
}

export const DEFAULT_GLOBAL_SCALE_ADJUSTMENT = 0.08;
export function drawLine(
  line: [Coord, Coord],
  ctx: CanvasRenderingContext2D,
  lineWidth: number,
  withArrow: boolean = false,
): void {
  const oldStrokeStyle = ctx.strokeStyle;
  const oldFillStyle = ctx.fillStyle;

  ctx.strokeStyle = "black";
  ctx.fillStyle = "black";

  const oldTransform = ctx.getTransform();
  ctx.beginPath();
  ctx.lineWidth = lineWidth;
  ctx.moveTo(line[0].x, line[0].y);
  ctx.lineTo(line[1].x, line[1].y);
  ctx.stroke();

  if (withArrow) {
    // arrowhead size depends on the lineWidth
    const arrowheadLength = 4 * lineWidth;
    const angle = Math.atan2(line[1].y - line[0].y, line[1].x - line[0].x);

    // draw arrowhead
    ctx.beginPath();
    ctx.moveTo(line[1].x, line[1].y);
    ctx.lineTo(
      line[1].x - arrowheadLength * Math.cos(angle - Math.PI / 6),
      line[1].y - arrowheadLength * Math.sin(angle - Math.PI / 6),
    );
    ctx.lineTo(
      line[1].x - arrowheadLength * Math.cos(angle + Math.PI / 6),
      line[1].y - arrowheadLength * Math.sin(angle + Math.PI / 6),
    );
    ctx.lineTo(line[1].x, line[1].y);
    ctx.lineTo(
      line[1].x - arrowheadLength * Math.cos(angle - Math.PI / 6),
      line[1].y - arrowheadLength * Math.sin(angle - Math.PI / 6),
    );
    ctx.lineWidth = lineWidth;
    ctx.stroke();
    ctx.fillStyle = "black";
    ctx.fill();
  }

  ctx.closePath();
  ctx.setTransform(oldTransform);

  ctx.strokeStyle = oldStrokeStyle;
  ctx.fillStyle = oldFillStyle;
}
export function drawMeasureText(
  midpoint: Coord,
  angle: number,
  lengthConvert: string,
  ctx: CanvasRenderingContext2D,
  fontSize: number,
  fontName: string,
): void {
  const oldStrokeStyle = ctx.strokeStyle;
  const oldFillStyle = ctx.fillStyle;

  ctx.strokeStyle = "black";
  ctx.fillStyle = "black";

  const oldTransform = ctx.getTransform();
  ctx.beginPath();
  ctx.font = `${fontSize}px ${fontName}`;
  ctx.textAlign = "center";
  ctx.translate(midpoint.x, midpoint.y); // Move to the midpoint
  ctx.rotate(angle); // Rotate the context
  ctx.fillTextStable(lengthConvert, 0, 0, undefined, "middle");
  ctx.closePath();
  ctx.setTransform(oldTransform);

  ctx.strokeStyle = oldStrokeStyle;
  ctx.fillStyle = oldFillStyle;
}

export function drawDimensionBaseLevel(
  context: DrawingContext,
  ctx: CanvasRenderingContext2D,
  coords: [Coord, Coord],
  verticalHeightWithScale: number,
  verticalHeightWithOutScale: number,
) {
  const oldTransform = ctx.getTransform();
  let scale = 1;
  try {
    scale = DEFAULT_GLOBAL_SCALE_ADJUSTMENT / context.vp.currToScreenScale(ctx);
  } catch {
    //noop
  }

  const architectureSettings = {
    verticalLen: verticalHeightWithScale * scale + verticalHeightWithOutScale,
    lineWidth: 20 * scale,
    measureFont: 215 * scale,
  };

  const architectureBaseLine = movePerpendicularByDistanceCW(
    coords[0],
    coords[1],
    0,
  );
  const architectureMeasurementLine = movePerpendicularByDistanceCW(
    coords[0],
    coords[1],
    architectureSettings.verticalLen,
  );

  const perpendicularLines = [
    movePerpendicularByDistanceCW(
      architectureMeasurementLine[0],
      architectureBaseLine[0],
      0,
    ),
    movePerpendicularByDistanceCW(
      architectureMeasurementLine[1],
      architectureBaseLine[1],
      0,
    ),
  ];
  const lengthM =
    Math.hypot(
      architectureMeasurementLine[1].x - architectureMeasurementLine[0].x,
      architectureMeasurementLine[1].y - architectureMeasurementLine[0].y,
    ) / 1e3;
  const textRatio = (scale / (lengthM * 1000)) * 1100;

  perpendicularLines.forEach((line) =>
    drawLine(line, ctx, architectureSettings.lineWidth),
  );

  const angle = Math.atan2(
    architectureMeasurementLine[1].y - architectureMeasurementLine[0].y,
    architectureMeasurementLine[1].x - architectureMeasurementLine[0].x,
  );
  const midpoint = {
    x:
      (architectureMeasurementLine[0].x + architectureMeasurementLine[1].x) / 2,
    y:
      (architectureMeasurementLine[0].y + architectureMeasurementLine[1].y) / 2,
  };

  /**
   * Break the segment into three [40%, 20%, 40%]
   * Then draw measurement on center, line on the side
   */
  const lineRatio = Math.min(textRatio, 0.9);
  if (lineRatio < 0.9) {
    const units = context.drawing.metadata.units;
    const [displayUnit, displayValue] = convertMeasurementSystem(
      units,
      Units.Meters,
      lengthM,
      undefined,
      UnitsContext.NONE,
    );
    const formattedDisplayValue = displayValueForEdit(
      displayUnit,
      displayValue,
      Precision.DISPLAY_SHORT,
    );
    const finalDisplayValue =
      units.lengthMeasurementSystem === MeasurementSystem.METRIC
        ? `${formattedDisplayValue} m`
        : formattedDisplayValue;
    drawMeasureText(
      midpoint,
      angle,
      finalDisplayValue,
      ctx,
      architectureSettings.measureFont,
      DEFAULT_FONT_NAME,
    );
  }

  const segments =
    lineRatio < 0.9
      ? breakLineIntoSegments(architectureMeasurementLine, [
          0.5 - lineRatio / 2,
          lineRatio,
          0.5 - lineRatio / 2,
        ])
      : breakLineIntoSegments(architectureMeasurementLine, [0.49, 0.02, 0.49]);
  drawLine(
    [segments[0][1], segments[0][0]],
    ctx,
    architectureSettings.lineWidth,
    true,
  );
  drawLine(segments[2], ctx, architectureSettings.lineWidth, true);

  ctx.setTransform(oldTransform);
}

/**
 * Create linear gradient for polygon that represent the slope direction of polygon.
 * https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/createLinearGradient/mdn-canvas-lineargradient.png
 */
export function getLinerGradientCoords(segment: PolygonSegment): {
  start: Coord;
  end: Coord;
} {
  if (segment.slopeDeg === 0) {
    const start = segment.polygonCw[0];

    // Create a very steep gradient by moving the end point a tiny distance
    const end = {
      x: start.x + 0.01,
      y: start.y,
    };

    return { start, end };
  }
  const bounds = {
    xMin: Math.min(...segment.polygonCw.map((p) => p.x)),
    xMax: Math.max(...segment.polygonCw.map((p) => p.x)),
    yMin: Math.min(...segment.polygonCw.map((p) => p.y)),
    yMax: Math.max(...segment.polygonCw.map((p) => p.y)),
  };

  // Center of the bounding box
  const center = {
    x: (bounds.xMin + bounds.xMax) / 2,
    y: (bounds.yMin + bounds.yMax) / 2,
  };
  let start = { x: center.x, y: bounds.yMax };
  let end = { x: center.x, y: bounds.yMin };

  // Rotate the line
  start = rotatePointRelativeToCenter(
    start,
    segment.slopeDirectionDegCW + 90,
    center,
  );
  end = rotatePointRelativeToCenter(
    end,
    segment.slopeDirectionDegCW + 90,
    center,
  );

  const flattenPolygon = new Flatten.Polygon();
  const points = [
    new Flatten.Point(bounds.xMin, bounds.yMin),
    new Flatten.Point(bounds.xMin, bounds.yMax),
    new Flatten.Point(bounds.xMax, bounds.yMax),
    new Flatten.Point(bounds.xMax, bounds.yMin),
  ];
  flattenPolygon.addFace(points);

  const startLine = new Flatten.Segment(
    new Flatten.Point(start.x, start.y),
    new Flatten.Point(center.x, center.y),
  );
  const endLine = new Flatten.Segment(
    new Flatten.Point(end.x, end.y),
    new Flatten.Point(center.x, center.y),
  );

  const startIntersection = flattenPolygon.intersect(startLine);
  const endIntersection = flattenPolygon.intersect(endLine);

  let startPoint = {
    x: start.x,
    y: start.y,
  };
  let endPoint = {
    x: end.x,
    y: end.y,
  };

  if (startIntersection.length > 0) {
    startPoint = { x: startIntersection[0].x, y: startIntersection[0].y };
  }
  if (endIntersection.length > 0) {
    endPoint = { x: endIntersection[0].x, y: endIntersection[0].y };
  }

  return {
    start: startPoint,
    end: endPoint,
  };
}
