import { EntityPopupContent } from "./types";

export type LiveWarning =
  | "UNCONNECTED_PIPES"
  | "UNCONNECTED_FIXTURE"
  | "RING_MAIN_NON_RECIRCULATION"
  | "UNCONNECTED_PLANT"
  | "INVALID_CAP_END"
  | "MISSING_BALANCING_VALVE_FOR_RETURN"
  | "MISSING_LOCKSHIELD_VALVE_FOR_RETURN"
  | "OVERLAPPING_PIPES";

export const LiveWarnings: Record<LiveWarning, EntityPopupContent> = {
  UNCONNECTED_PIPES: {
    title: "Unconnected Pipes",
    description: "This pipe run is unconnected.",
    details: [
      {
        type: "video",
        src: "https://h2x-public-videos.s3.us-west-1.amazonaws.com/Transparent+Pipe+Warning.mp4",
      },
    ],
  },
  UNCONNECTED_FIXTURE: {
    title: "Unconnected Fixture",
    description: "There are one or more unconnected points at the fixture.",

    details: [
      {
        type: "markdown",
        markdown: `
To resolve this, drag or draw a pipe over the fixture, until you see the coloured cap disappear.
If the pipe will not connect, it is because you are trying to connect a system that is not a default on the fixture i.e. you are trying to connect hot water to a warm water connection point
To allow this connection, click on the fixture and choose 'Yes' below 'Allow Other Systems to Connect' in the properties. Then, drag the pipe to the connection point.
`,
      },
      {
        type: "video",
        src: "https://h2x-public-videos.s3.us-west-1.amazonaws.com/Unconnected+Fixture+Warning.mp4",
      },
    ],
  },
  RING_MAIN_NON_RECIRCULATION: {
    title: "Ring Main Warning",
    description: "The pipe layout is in a ring main formation.",
    details: [
      {
        type: "markdown",
        markdown: `
The pipe layout is in a ring main formation and there is no industry standard way to calculate the diversified flow rate. [Article: Sizing a Ring Main](https://www.h2xengineering.com/sizing-a-ring-main-with-a-diversified-flow-rate/)
`,
      },
      {
        type: "video",
        src: "https://h2x-public-videos.s3.us-west-1.amazonaws.com/Ring+Main+Warning+(Non+Recirculation).mp4",
      },
    ],
  },
  UNCONNECTED_PLANT: {
    title: "Unconnected Plant",
    description: "The plant is missing pipe connection(s).",
    details: [
      {
        type: "markdown",
        markdown: `
To resolve this, drag or draw a pipe over the plant, until you see the coloured cap disappear.
If the pipe will not connect, it is because you are trying to connect a system that doesn't correspond to the plant's configured inlet/outlets.
To allow this connection, click on the plant and choose the correct inlet and/or outlet in the properties. Then, drag the pipe to the connection point.
`,
      },
      {
        type: "video",
        src: "https://h2x-public-videos.s3.us-west-1.amazonaws.com/Transparent+Plant+Warning.mp4",
      },
    ],
  },
  INVALID_CAP_END: {
    title: "Unconnected Cap End",
    description:
      "The dangling cap end is not connected to a pipe or inlet/outlet.",
    details: [
      {
        type: "video",
        src: "https://h2x-public-videos.s3.us-west-1.amazonaws.com/Red+Circle+Warning.mp4",
      },
    ],
  },
  MISSING_BALANCING_VALVE_FOR_RETURN: {
    title: "Missing Balancing Valve",
    description:
      "Each circuit on a recirculation system requires a balancing valve.",
    details: [
      {
        type: "markdown",
        markdown: `
Prior to where the two circuits connect, ensure there is a balancing valve on each circuit.
Ensure there are no fixtures downstream of a balancing valve as this can not be calculated.
`,
      },
      {
        type: "video",
        src: "https://h2x-public-videos.s3.us-west-1.amazonaws.com/Balancing+Valve+Warning.mp4",
      },
    ],
  },
  MISSING_LOCKSHIELD_VALVE_FOR_RETURN: {
    title: "Missing Lockshield Valve",
    description:
      "Each circuit on a recirculation system requires a lockshield valve.",
    details: [
      {
        type: "markdown",
        markdown: `
Prior to where the two circuits connect, ensure there is a lockshield valve on each circuit.
Ensure there are no fixtures downstream of a lockshield valve as this can not be calculated.
`,
      },
      {
        type: "video",
        src: "https://h2x-public-videos.s3.us-west-1.amazonaws.com/Balancing+Valve+Warning.mp4",
      },
    ],
  },
  OVERLAPPING_PIPES: {
    title: "Overlapping Pipes",
    description: "At least two pipes are overlapping",
  },
};
