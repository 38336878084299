import { BiPolynomial, Polynomial } from "../../lib/polynomials";
import { assertType, cloneSimple } from "../../lib/utils";
import { DuctFittingsTable, PriceTable } from "./price-table";

const BASE_PRICES: DuctFittingsTable["Aluminium"] = {
  "Elbow - Circular (Mitred)": [50, 0, 0.005],
  "Elbow - Circular (Smooth)": [70, 0, 0.007],
  "Elbow - Circular (Multi Piece)": [60, 0, 0.006],
  "Elbow - Rectangular (Mitred)": [[60], [0, 0.006]],
  "Elbow - Rectangular (Mitred with Vanes)": [[70], [0, 0.007]],
  "Elbow - Rectangular (Smooth)": [[80], [0, 0.008]],
  "Elbow - Rectangular (Smooth with 1 Vane)": [[85], [0, 0.0085]],
  "Elbow - Rectangular (Smooth with 2 Vanes)": [[87], [0, 0.0087]],
  "Elbow - Rectangular (Smooth with 3 Vanes)": [[90], [0, 0.009]],
  "Transition - (Circular biggest)": [20, 0, 0.002],
  "Transition - (Rectangular biggest)": [[25], [0, 0.0025]],
  "Takeoff - Circular (mitred)": [30, 0, 0.003],
  "Takeoff - Circular (shoe)": [35, 0, 0.0035],
  "Takeoff - Circular (bell)": [40, 0, 0.004],
  "Takeoff - Rectangular (mitred)": [[35], [0, 0.0035]],
  "Takeoff - Rectangular (shoe)": [[40], [0, 0.004]],
  "Takeoff - Rectangular (bell)": [[45], [0, 0.0045]],
  "Symmetrical Tee - Circular (Mitred)": [50, 0, 0.005],
  "Symmetrical Tee - Circular (Breech)": [55, 0, 0.0055],
  "Symmetrical Tee - Circular (Y Piece)": [60, 0, 0.006],
  "Symmetrical Tee - Rectangular (Mitred)": [[60], [0, 0.006]],
};

function round(num: number): number {
  if (num === 0) {
    return 0;
  }
  let order = 0;
  while (Math.abs(num) > 100) {
    order--;
    num /= 10;
  }
  while (Math.abs(num) < 10) {
    order++;
    num *= 10;
  }

  if (Math.abs(num) > 30) {
    // nearest 5
    num = Math.round(num / 5) * 5;
  } else {
    num = Math.round(num);
  }

  num *= Math.pow(10, -order);
  return num;
}

function multiplyAndRound(
  entry: DuctFittingsTable["Aluminium"],
  factor: number,
) {
  entry = cloneSimple(entry);
  for (const key in entry) {
    assertType<keyof typeof entry>(key);
    if (Array.isArray(entry[key][0])) {
      const arr = entry[key] as BiPolynomial;
      for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < arr[i].length; j++) {
          arr[i][j] = round(arr[i][j] * factor);
        }
      }
    } else {
      const arr = entry[key] as Polynomial;
      for (let i = 0; i < entry[key].length; i++) {
        entry[key][i] = round(arr[i] * factor);
      }
    }
  }
  return entry;
}

export const DUCT_FITTINGS: PriceTable["DuctFittings"] = {
  Aluminium: multiplyAndRound(BASE_PRICES, 1.3),
  "Carbon Steel": multiplyAndRound(BASE_PRICES, 1.5),
  "Fibrous Glass Duct": multiplyAndRound(BASE_PRICES, 0.6),
  "Flexible Duct": multiplyAndRound(BASE_PRICES, 0.7),
  "Galvanised Steel": multiplyAndRound(BASE_PRICES, 1.0),
  "Hot Dip Galvanised Steel": multiplyAndRound(BASE_PRICES, 1.1),
  PVC: multiplyAndRound(BASE_PRICES, 0.8),
  "Semi-Rigid": multiplyAndRound(BASE_PRICES, 1.4),
};
