import { assertType, assertUnreachable } from "../../../lib/utils";
import { DrawableEntityConcrete } from "../../document/entities/concrete-entity";
import {
  ManifoldPlant,
  PlantConcrete,
  PlantType,
  RadiatorPlant,
  SpecifyRadiatorPlant,
} from "../../document/entities/plants/plant-types";
import { EntityType } from "../../document/entities/types";

import { ALL_CATALOGS } from "..";
import { getPropertyByString } from "../../../lib/utils";
import {
  DrawingState,
  SelectedMaterialManufacturer,
} from "../../document/drawing";
import PlantEntity from "../../document/entities/plants/plant-entity";
import {
  Catalog,
  Manufacturer,
  ManufacturerConcrete,
  PipeSpec,
} from "../types";
import {
  ComponentId,
  UnderfloorHeatingSpec,
} from "../underfloor-heating/ufh-types";
import {
  MANUFACTURERED_PART_TYPES,
  ManufacturerDirectoryEntry,
  ManufactureredPartTypeSpec,
} from "./types";

function enumerateObj(obj: any, path: string[]): Record<string, string>[] {
  if (path.length === 0) {
    return [{}];
  }
  const [key, ...rest] = path;

  if (key.match(/\{.*\}/)) {
    const variable = key.replace(/\{|\}/g, "");
    const keys = Object.keys(obj);

    const result: Record<string, string>[] = [];

    for (const key of keys) {
      const nextParams = enumerateObj(obj[key], rest);
      for (const nextParam of nextParams) {
        result.push({ ...nextParam, [variable]: key });
      }
    }

    return result;
  }

  if (!(key in obj)) {
    return [];
  }
  return enumerateObj(obj[key], rest);
}

function getCatalogValue(
  catalog: Catalog,
  path: string,
  params: Record<string, string>,
): any {
  for (const key of Object.keys(params)) {
    path = path.replace(new RegExp(`\\{${key}\\}`, "g"), params[key]);
  }

  return getPropertyByString(catalog, path);
}

export function substituteParams(
  template: string,
  params: Record<string, string>,
): string {
  return template.replace(
    /\{.*\}/g,
    (match) => params[match.replace(/\{|\}/g, "")],
  );
}

function enumerateManufacturerEntriesHelper(
  catalog: Catalog,
  spec: ManufactureredPartTypeSpec,
): ManufacturerDirectoryEntry[] {
  const pathParams = enumerateObj(catalog, spec.path.split("."));

  const result: ManufacturerDirectoryEntry[] = [];

  for (const pathParam of pathParams) {
    let manufacturers = getCatalogValue(
      catalog,
      spec.path,
      pathParam,
    ) as ManufacturerConcrete[];

    if (spec.filter) {
      manufacturers = manufacturers.filter(spec.filter);
    }

    let name: string;
    if ("namePath" in spec) {
      name = getCatalogValue(catalog, spec.namePath, pathParam);
    } else {
      name = substituteParams(spec.name, pathParam);
    }

    result.push({
      manufacturers: manufacturers.map((manufacturer) => ({
        name: manufacturer.name,
        uid: manufacturer.uid,
        displayOnWizard: Boolean(manufacturer.displayOnWizard),
        isGeneric: Boolean(manufacturer.isGeneric),
      })),
      partType: substituteParams(spec.partType, pathParam),
      partName: name,
      catalogPath: substituteParams(spec.path, pathParam),
      selectedManufacturerPath: substituteParams(
        spec.selectedManufacturerPath,
        pathParam,
      ),
    });
  }

  return result;
}

export function enumerateManufacturerEntries(
  catalogs: Catalog[],
  partTypes: ManufactureredPartTypeSpec[],
): ManufacturerDirectoryEntry[] {
  const byPartType: Map<string, ManufacturerDirectoryEntry> = new Map();

  for (const catalog of catalogs) {
    for (const entry of partTypes) {
      const entries = enumerateManufacturerEntriesHelper(catalog, entry);
      for (const entry of entries) {
        if (!byPartType.has(entry.partType)) {
          byPartType.set(entry.partType, entry);
        } else {
          const current = byPartType.get(entry.partType)!;
          current.manufacturers.push(
            ...entry.manufacturers.filter(
              (manufacturer) =>
                !current.manufacturers.some(
                  (currentManufacturer) =>
                    currentManufacturer.uid === manufacturer.uid,
                ),
            ),
          );
        }
      }
    }
  }

  return Array.from(byPartType.values());
}

export function getManufacturerDirectory() {
  return enumerateManufacturerEntries(ALL_CATALOGS, MANUFACTURERED_PART_TYPES);
}

export function getManufacturerRecordForManifoldComponent(
  entity: DrawableEntityConcrete,
  catalog: Catalog,
  subCatalogUid: keyof UnderfloorHeatingSpec,
  componentId?: ComponentId | null,
): Manufacturer<any> | undefined {
  assertType<PlantEntity>(entity);
  assertType<ManifoldPlant>(entity.plant);
  const manufacturers = getManufacturersByPlant(
    entity.plant,
    catalog,
    subCatalogUid,
  );

  if (componentId) {
    const [manufacturerUid] = componentId;
    return manufacturers.find((m) => m.uid === manufacturerUid);
  }

  return getManufacturerRecord(entity, catalog, subCatalogUid);
}

export function getManufacturerRecord(
  entity: DrawableEntityConcrete,
  catalog: Catalog,
  subCatalogUid?: keyof UnderfloorHeatingSpec,
): Manufacturer<any> | undefined {
  switch (entity.type) {
    case EntityType.PLANT: {
      const manufacturers = getManufacturersByPlant(
        entity.plant,
        catalog,
        subCatalogUid,
      );
      const plant = entity.plant;

      switch (plant.type) {
        case PlantType.PUMP:
        case PlantType.PUMP_TANK:
        case PlantType.FILTER:
        case PlantType.RO:
          return manufacturers.find((m) => m.uid === plant.manufacturer);
        case PlantType.RADIATOR:
          switch (plant.radiatorType) {
            case "fixed":
              return;
            case "specify":
              return manufacturers.find((m) => m.uid === plant.manufacturer);
            default:
              assertUnreachable(plant);
          }
        case PlantType.MANIFOLD:
          subCatalogUid = subCatalogUid ?? "manifold";
          switch (subCatalogUid) {
            case "manifold":
              return manufacturers.find(
                (m) => m.uid === plant.manifoldManufacturer,
              );
            case "actuator":
              return manufacturers.find(
                (m) => m.uid === plant.actuatorManufacturer,
              );
            case "ballValve":
              return manufacturers.find(
                (m) => m.uid === plant.ballValveManufacturer,
              );
            case "pumpPack":
              return manufacturers.find(
                (m) => m.uid === plant.pumpPackManufacturer,
              );
            case "pump":
              return manufacturers.find(
                (m) => m.uid === plant.pumpManufacturer,
              );
            case "blendingValve":
              return manufacturers.find(
                (m) => m.uid === plant.blendingValveManufacturer,
              );
            // It's for the room, not the manifold
            case "edgeExpansionFoam":
              return undefined;
            default:
              assertUnreachable(subCatalogUid, false);
          }
        case PlantType.TANK:
        case PlantType.RETURN_SYSTEM:
        case PlantType.PUMP_TANK:
        case PlantType.DRAINAGE_PIT:
        case PlantType.CUSTOM:
        case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
        case PlantType.VOLUMISER:
        case PlantType.AHU:
        case PlantType.AHU_VENT:
        case PlantType.FCU:
        case PlantType.UFH:
        case PlantType.DUCT_MANIFOLD:
          return;
      }
      assertUnreachable(plant);
      return;
    }
    // TODO: all other entities.
  }
  return;
}

export function getManufacturersByPlant(
  plant: PlantConcrete,
  catalog: Catalog,
  subCatalogUid?: keyof UnderfloorHeatingSpec,
): Manufacturer<any>[] {
  switch (plant.type) {
    case PlantType.PUMP:
      return catalog.pump.manufacturer;
    case PlantType.PUMP_TANK:
      return catalog.pumpTank.manufacturer;
    case PlantType.FILTER:
      switch (plant.filterType) {
        case "softener":
          return catalog.filters.softener.manufacturer;
        case "backwash":
          return catalog.filters.backwash.manufacturer;
        case "cartridge":
          return catalog.filters.cartridge.manufacturer;
        case "uv":
          return catalog.filters.uv.manufacturer;
        case "backwash-rainwater":
          return catalog.filters.backwashRainwater.manufacturer;
      }
      assertUnreachable(plant);
    case PlantType.RO:
      return catalog.roPlant.manufacturer;
    case PlantType.RADIATOR:
      return catalog.heatEmitters.radiators.manufacturer;
    case PlantType.MANIFOLD:
      const subCat = subCatalogUid ?? "manifold";
      if (subCat in catalog.underfloorHeating) {
        assertType<keyof UnderfloorHeatingSpec>(subCat);
        return catalog.underfloorHeating[subCat].manufacturer;
      }
    case PlantType.TANK:
    case PlantType.RETURN_SYSTEM:
    case PlantType.PUMP_TANK:
    case PlantType.DRAINAGE_PIT:
    case PlantType.CUSTOM:
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
    case PlantType.VOLUMISER:
    case PlantType.AHU:
    case PlantType.AHU_VENT:
    case PlantType.FCU:
    case PlantType.UFH:
    case PlantType.DUCT_MANIFOLD:
      return [];
  }
  assertUnreachable(plant);
}

export function hasCustomManufacturers(
  manufacturers: Manufacturer<any>[],
): boolean {
  return manufacturers.some((m) => m.uid !== "generic");
}

export function getPipeSpec(
  material: string,
  catalog: Catalog,
  drawing: DrawingState,
): { [key: string]: PipeSpec } {
  const manufacturer =
    drawing.metadata.catalog.pipes.find((m) => m.uid === material)
      ?.manufacturer || "generic";

  return catalog.pipes[material].pipesBySize[manufacturer];
}

export function closestPipeSize(
  diameter: number,
  material: string,
  catalog: Catalog,
  drawing: DrawingState,
  mode: "at-least" | "at-most" | "closest" = "at-least",
): number {
  const pipeSpec = getPipeSpec(material, catalog, drawing);

  const pipeSizes = Object.keys(pipeSpec).map((s) => Number(s));

  switch (mode) {
    case "at-least":
      return Math.min(...pipeSizes.filter((s) => s >= diameter).map(Number));
    case "at-most":
      return Math.max(...pipeSizes.filter((s) => s <= diameter).map(Number));
    case "closest":
      return pipeSizes.reduce((prev, curr) =>
        Math.abs(curr - diameter) < Math.abs(prev - diameter) ? curr : prev,
      );
  }
  assertUnreachable(mode);
}

export function getSelectedManufacturer(
  selectedMaterials: SelectedMaterialManufacturer[],
  uid: string,
): string {
  if (
    !selectedMaterials?.length ||
    !selectedMaterials.find(
      (obj: SelectedMaterialManufacturer) => obj.uid === uid,
    )
  ) {
    return "generic";
  }

  return selectedMaterials.find(
    (obj: SelectedMaterialManufacturer) => obj.uid === uid,
  )?.manufacturer!;
}

export function radiatorModelNameShortener(
  radiatorPlant: SpecifyRadiatorPlant,
): string {
  return radiatorPlant.rangeType;
}

export function radiatorDimension(radiatorPlant: RadiatorPlant): string {
  switch (radiatorPlant.radiatorType) {
    case "fixed":
      if (radiatorPlant.heightMM && radiatorPlant.widthMM)
        return `${radiatorPlant.heightMM}H x ${radiatorPlant.widthMM}W`;
      break;
    case "specify":
      if (radiatorPlant.heightMM.value && radiatorPlant.widthMM.value)
        return `${radiatorPlant.heightMM.value}H x ${radiatorPlant.widthMM.value}W`;
      break;
    default:
      assertUnreachable(radiatorPlant);
  }
  return "";
}
