// Find out whether tanks are connected to flow sources or not.
// This is so, later on, tanks that are connected to flow sources, will be
// connected start to end.

import { isPlantTank } from "../document/entities/plants/utils";
import { EntityType } from "../document/entities/types";
import CalculationEngine from "./calculation-engine";
import { TraceCalculation } from "./flight-data-recorder";
import { FLOW_SOURCE_ROOT_NODE } from "./utils";

// Assumption: Netowrk objects have already been made, but flow graph has not.
export class TankCalculations {
  @TraceCalculation("Identifying tanks that are connected to flow sources")
  static determineTankHydration(engine: CalculationEngine) {
    const oldFlowGraph = engine.flowGraph;
    engine.configureLUFlowGraph({ tanksAlwaysFlowSources: true });

    engine.flowGraph.dfs(FLOW_SOURCE_ROOT_NODE, (node) => {
      const obj = engine.globalStore.get(node.connectable);
      if (obj && obj.entity.type === EntityType.SYSTEM_NODE) {
        const parent = engine.globalStore.get(obj.entity.parentUid!);
        if (
          parent.entity.type === EntityType.PLANT &&
          isPlantTank(parent.entity) &&
          obj.entity.uid === parent.entity.inletUid
        ) {
          const calc = engine.globalStore.getOrCreateCalculation(parent.entity);
          calc.isInletHydrated = true;
        }
      }
    });

    engine.flowGraph = oldFlowGraph;
  }
}
