import Flatten from "@flatten-js/core";
import { Coord } from "../../../../lib/coord";
import { dotProduct } from "../../../coreObjects/lib/geometry-utils";

/**
 * Width  => Horizontal
 * Length => Vertical
 *
 * Assumption Stage: assumption we made to roof, adjust to the closest rectangular shape
 */
export type RoofRectangleAssumption = {
  leftTop: {
    flatten: Flatten.Point;
    coord: Coord;
  };
  rightTop: {
    flatten: Flatten.Point;
    coord: Coord;
  };
  rightBottom: {
    flatten: Flatten.Point;
    coord: Coord;
  };
  leftBottom: {
    flatten: Flatten.Point;
    coord: Coord;
  };
  xDimensionM: number;
  yDimensionM: number;
};

/**
 * Segmentation Stage: The breakdown of the roof into individual slope sections.
 */
export type SlopeDirection = number;
export type PolygonSegment = {
  polygonCw: Coord[];
  slopeDeg: number;
  slopeDirectionDegCW: SlopeDirection;
  lowestHeightM: number;
  slopeAreaConversionRatio: number;
  externalWallAreaM2: number;
  externalWallConversionRatio: number;
  volumeM3: number;
  volumeConversionRatio: number;
};

/**
 * This interface represent the the adjustment on assumption roof to actual roof,
 * reflecting the actual roof shape projected by the imaginary roof.
 */
export interface PolygonSegmentAfterAdjustment extends PolygonSegment {
  roofAreaM2: number;
}

/**
 * Calculation Stage: The abstraction layer used for the heat-loss calculation
 */
export enum RoofComponentType {
  ROOFTOP = "ROOFTOP",
  WINDOW = "WINDOW",
}

export interface CalculatedRoofComponentBase {
  type: RoofComponentType.ROOFTOP | RoofComponentType.WINDOW;
  polygonCw: Coord[];
  slopeDirectionDegCW: number;
  slopeDeg: number;
  lowestHeightM: number;
  slopeAreaConversionRatio: number;
  volumeConversionRatio: number;
}

/**
 * Lowest point on segment is always on vertex, if there're multiple such point available,
 * then any of them would work. As the projected distance from any point to
 * lowest point onto the slope direction will be the same
 */
export function findingLowestCoordInRoofComponent(
  segment: CalculatedRoofComponentBase,
): Coord {
  // Proposed algo
  // 1. Find the slope direction => vector
  // 2. Pick an extreme point, that is grantees to be the lowest, calculate the projected distance between vertex to that point
  // 3. Smallest distanced vertex win
  const directionRadians = (segment.slopeDirectionDegCW * Math.PI) / 180;
  const directionVector: Coord = {
    x: Math.cos(directionRadians),
    y: Math.sin(directionRadians),
  };

  const extremeLowPoint: Coord = {
    x: -(10 ** 8) * directionVector.x,
    y: -(10 ** 8) * directionVector.y,
  };
  let lowestPoint: Coord = { x: 0, y: 0 };
  let minDistance: number = Infinity;

  segment.polygonCw.forEach((vertex) => {
    // Calculate the projected distance from the vertex to the extreme point
    const distance = dotProduct(
      {
        x: vertex.x - extremeLowPoint.x,
        y: vertex.y - extremeLowPoint.y,
      },
      {
        x: directionVector.x,
        y: directionVector.y,
      },
    );

    if (distance < minDistance) {
      minDistance = distance;
      lowestPoint = vertex;
    }
  });

  return lowestPoint;
}

export function calculateOnSlopeHeightM(
  segment: CalculatedRoofComponentBase,
  point: Coord,
): number {
  // Find the lowest point in the roof component
  const lowestPoint = findingLowestCoordInRoofComponent(segment);
  const directionRadians = (segment.slopeDirectionDegCW * Math.PI) / 180;
  const directionVector: Coord = {
    x: Math.cos(directionRadians),
    y: Math.sin(directionRadians),
  };

  // Find the vector from the lowest point to the given point
  const pointVector: Coord = {
    x: point.x - lowestPoint.x,
    y: point.y - lowestPoint.y,
  };
  const projectedDistanceM = dotProduct(pointVector, directionVector) / 1000;
  const slopeRadians = (segment.slopeDeg * Math.PI) / 180;
  const heightAtPoint =
    segment.lowestHeightM + projectedDistanceM * Math.tan(slopeRadians);
  return heightAtPoint;
}

export interface RoofTopComponent extends CalculatedRoofComponentBase {
  type: RoofComponentType.ROOFTOP;
  externalWallConversionRatio: number;
  externalWallMaterialUid: string;
  roofMaterialUid: string;
}

export interface WindowComponent extends CalculatedRoofComponentBase {
  type: RoofComponentType.WINDOW;
  windowMaterialUid: string;
}

export type CalculateRoofComponentConcrete = RoofTopComponent | WindowComponent;
